import React from 'react';

import { Button, Checkbox, Divider, Form, Icon, Input,
    Message, Modal, Popup, Table, TextArea } from 'semantic-ui-react'

import {
    openDB,

    queryFetchTx,
    decomposeTx,
    queryFetchDecodeTx,
    findContent,

    parseTheOutputs,
    parseTheInputs,

    readSetting,
    saveSetting,

    getGuestPostRef,

    decryptData,

    findNextTxFromProvider,

    findADialogByStartingTxId,
    findADialogById,

    mySleep,
    simpleDecode,
} from './buildShizzle.js';

import {
    hexStringToAscii,
} from './commonFuncs';

import './index.css';

/**
 * Used for viewing existing (already-published) video transactions
 *   (referenced by their txId)
 */
class VideoViewerModal extends React.PureComponent {
    constructor(props) {
        super(props);

        this.fontFamily          = "verdana"

        //FIXME: the purples are currently duplicated in index.css
        this.bshzPurple             = '#8313e2';
        this.bshzYellow             = "#fff300";
        this.bshzLtPurp             = '#e7d0fb';
        this.bshzLightYellow        = "#fffccc";

        this.shizzleVerse        = <><b>ShizzleVerse</b><small>&trade;</small></>;

        this.state =  {
            publishedVideoTxId: '',

            mediaType: 'video',

            videoTxId: '',
            recordedVideoURL: null,

            showFailedQuery: false,

            //base64: null,
        }

        this.handleVideoTxIdChange  = this.handleVideoTxIdChange.bind(this);
        this.setRefV                = this.setRefV.bind(this);
        this.viewThisTxId           = this.viewThisTxId.bind(this);
        this.closeUs                = this.closeUs.bind(this);

        this.closeFailedQueryModal  = this.closeFailedQueryModal.bind(this);
    }

    handleVideoTxIdChange(event, val) {
		//console.log("handleFfmpegArgs: event: ", event)
		//console.log("handleFfmpegArgs: val: ", val)
		console.log("handleVideoTxIdChange: val.value: ", val.value)

        // Clear the URL - so we can get ready to grab a new reference to the <video>
        // once we've got a new tx, and it's data
		this.setState({videoTxId: val.value, recordedVideoURL: null})
	}

    // copied from vidRecorder.js
    //
    //FIXME: rename to setVideoPlaybackReference()
    setRefV(element) {
        console.warn("setRefV(): refV: ", element)
        console.warn("setRefV(): recordedVideoURL: ", this.state.recordedVideoURL)

        this.setState({ videoPlayerRef: element }, () => {
            console.warn("state.recordedVideoURL:", this.state.recordedVideoURL)
            console.warn("state.videoPlayerRef:", this.state.videoPlayerRef)

            if (this.state.videoPlayerRef !== null) {
                this.state.videoPlayerRef.src = this.state.recordedVideoURL
                console.log("we did it! We set player.src to " + this.state.recordedVideoURL)

                // alternative approach using dataURI (base64)
                //console.error("base 64 info of length " + this.state.base64.base64.length)
                //console.error("base 64 info of mType " + this.state.base64.mType)
                //this.state.videoPlayerRef.src = 'data:' + this.state.base64.mType + ';base64,' + this.state.base64.base64
                //this.state.videoPlayerRef.type = this.state.base64.mType
                //console.error("We set the player .type to " + this.state.base64.mType)
            }
        })
    };

    //FIXME: see how videoLibrary uses commonFuncs fetchPublishedVideo()
    //       That is almost identical to this
    async viewThisTxId() {
        console.warn("Will attempt to load, then display txId " + this.state.videoTxId)

        // taken from contentPresenter's theChildMessageHandler()
        // see also: videoLibrary.js, surfer.js's componentDidMount(), and .tempVideoWork()

        const txId = this.state.videoTxId    //fields[0]
        const inOut = 'out' //fields[1]
        const inOutNum = 0  //fields[2]
        const chunkNum = 1  //fields[3]

        if ( inOut !== 'in' && inOut !== 'out' ) {
          console.warn("CONTENT ERROR: INVALID in/out type specified: " + inOut + ". Should be 'in', or 'out'.")
          return; //continue
        }

        //FIXME: allow ranges of chunks, outputs? All chunks? All outputs?
        console.log("  Will extract chunk #" + chunkNum + " of " + inOut + "[" + inOutNum + "] of " + txId)

        const rawTx = await queryFetchTx(txId, await openDB())
        if ( rawTx === null || typeof rawTx === 'number' ) {
          console.warn("vVM: theChildMessageHandler() Ignoring tx (txid " + txId + "). It's not there.")

          this.setState({showFailedQuery: true})
          //FIXME: do anything else?
          return; //continue
        }

        // We decompose AFTER we've checked that the tx is still relevant
        const tx = decomposeTx(rawTx)

        console.log("  BTW: inOutNum is " + inOutNum)
        console.log("  BTW: inOutNum+1 is " + (inOutNum+1))

        if ( inOut === 'out' && (inOutNum + 1) > tx.outputs.length ) {
          console.warn("CONTENT ERROR: Invalid OUT index: " + inOutNum + ". Exceeds number of outputs: " + tx.outputs.length)
          return; //continue
        }
        if ( inOut === 'in' && (inOutNum + 1) > tx.inputs.length ) {
          console.warn("CONTENT ERROR: Invalid IN index: " + inOutNum + ". Exceeds number of inputs: " + tx.inputs.length)
          return; //continue
        }
        if ( inOutNum < 0 ) {
          console.warn("CONTENT ERROR: Invalid in/out index: " + inOutNum)
          return; //continue
        }

        console.log(" -.-.-.-.-.-.-")
        console.log("vVM: theChildMessageHandler(): decomposition of tx: " + txId + ": ", tx)
        console.log("vVM: theChildMessageHandler(): got " + tx.inputs.length + " inputs, and "
                    + tx.outputs.length + " outputs for bshz tx request")

        let inOuts
        if ( inOut === 'out' ) {
          inOuts = parseTheOutputs( tx.outputs, true )
        } else {
          inOuts = parseTheInputs( tx.inputs, true )
        }

        console.log("Done parsing inputs, or outputs of tx " + txId)
        console.log("will deliver chunk # " + chunkNum + " from in/out #" + inOutNum)

        //FIXME: allow delivery of more than 1 chunk or output?

        const chunks = inOuts[inOutNum]
        console.log("BTW: there are " + chunks.length + " chunks in this in/out")

        const theData = chunks[chunkNum]

        console.log("BTW: chunk # " + (chunkNum-1) + " from in/out #" + inOutNum + " has a length of " + chunks[chunkNum-1].length)
        console.log("chunk # " + chunkNum + " from in/out #" + inOutNum + " has a length of " + theData?.length + "  <---")
        console.log("BTW: chunk # " + (chunkNum+1) + " from in/out #" + inOutNum + " has a length of " + chunks[chunkNum+1]?.length)


        const videoType = hexStringToAscii(chunks[Number(chunkNum) + 1])
        console.log("This is of videoType: ", videoType)
        //FIXME: rename to mediaClass
        const mediaType = videoType.startsWith('audio') ? 'audio' : 'video'

        // TypeError: Failed to construct 'Blob': The provided value cannot be converted to a sequence.

        const videoBuff = Buffer.from(theData, "hex")

        // NOTE the [] around the videoBuff !!
        const videoBlob = new Blob([videoBuff], { type: videoType });
        const videoUrl = URL.createObjectURL(videoBlob);

        // FAILED attempt to solve/workaround safari problems playing webm opus
        // (which MediaSource.isTypeSupported() claims is 'supported' for playback)
        // Thought the problem was blob-specific. Probably not
        //const videoBase64 = {base64: videoBuff.toString('base64'), mType: videoType}

        console.warn("chunks had length of " + chunks.length)

        console.warn("blob has size of " + videoBlob.size)
        console.warn("url: " + videoUrl)

        console.log("Here's the recorded blob: ", videoBlob)
        console.log("Here's the recorded blob url: ", videoUrl)

        this.setState({ recordedVideoURL: videoUrl,
                        recordedBlob: videoBlob,
                        recordedBuff: videoBuff,
                        mediaType: mediaType,   // 'audio' or 'video'

                        //base64: videoBase64,
                      })
    }

    closeUs() {
        if ( this.props.closeIt && this.props.closeIt !== null ) {
            this.props.closeIt()
        } else {
            throw new Error("FIXME: videoViewerModal needs a closeIt property")
        }
    }

    async componentDidMount() {

        console.warn("videoViewerModal componentDidMount()")

        if ( this.props.parent && this.props.registerClient ){
            this.props.registerClient(this.props.parent, this, "videoViewerModal")
        }

    } // componentDidMount()

    componentWillUnmount() {
        console.log("videoViewerModal about to unmount. Will UNregister...")

        if ( this.props.parent && this.props.unregisterClient ) {
            this.props.unregisterClient(this.props.parent, this, "videoViewerModal")
        }

        // fix Warning: Can't perform a React state update on an unmounted component
        // see: https://stackoverflow.com/questions/53949393/cant-perform-a-react-state-update-on-an-unmounted-component
        this.setState = (state,callback)=>{
            return;
        };
    }

    closeFailedQueryModal() {
        this.setState({showFailedQuery: false})
    }

    render() {

        // ***  DEVICE-DEPENDENT STYLING  ***
        // For mobile, "detect" landscape screen mode
        const landscape = this.props.isMobile ? window.innerWidth > window.innerHeight : false
        // For mobile, shrink the About, Security, and Glossary modals
        const modalClassName   = this.props.isMobile ?
                                        (landscape ?
                                                "modalMobileLandscape"
                                            :
                                                "modalMobilePortrait"
                                        )
                                    :
                                        ""
        const modalContentClassName = this.props.isMobile ? "modalContentMobile" : ""
        const modalBottomClassName  = this.props.isMobile ? "modalBottomMobile"  : ""

        const maybeSuggestClickingButton = this.state.videoTxId && !this.state.recordedVideoURL ?
                                    <div>
                                        <br></br>
                                        Click the 'Play it' button to load the audio/video transaction.
                                    </div>
                                :
                                    <></>
        const playerClause = this.state.mediaType === 'audio' ?
                            <>
                                <audio className="recorded" ref={this.setRefV} controls >
                                </audio>
                            </>
                        :
                            <>
                                <video className="recorded" width="320" height="240" ref={this.setRefV} controls autoPlay>
                                </video>
                            </>
        const maybePublishedTxId = this.state.videoTxId ?
                    <>
                        Published audio/video txId to view: <b style={{color:'blue'}}>{this.state.videoTxId}</b>

                        <div className="video-player">
					        {!this.state.recordedVideoURL ?
							    (
                                    <></>
                                )
                                    :
                                (
                                    <div className="recorded-player">
                                        <br></br>
                                        Playing blob url {this.state.recordedVideoURL}
                                        <br></br>
                                        Blob size: {this.state.recordedBlob.size} bytes
                                        <br></br>

                                        {playerClause}
                                    </div>
                                )
                            }
                        </div>
                    </>
                :
                    <>
                        Please specify a transaction which contains audio/video data.
                    </>

        const maybeFailedQuery = this.state.showFailedQuery ?
            <>
                   <Modal dimmer='blurring' size='large' centered className={modalClassName}  open={true}
                                    style={{backgroundColor: this.bshzPurple, borderRadius: '20px', height: "auto"}}>

                        <Modal.Header style={{textAlign: 'center', backgroundColor: this.bshzPurple, borderRadius: '20px'}}>
                            <span style={{color: this.bshzYellow}}> Unknown Transaction</span>
                        </Modal.Header>

                        <Modal.Content className={modalContentClassName} scrolling style={{backgroundColor: this.bshzLtPurp}}>

                        <p>
                            We couldn't find the transaction you specified:
                            <br></br>
                            &nbsp; &nbsp;<b style={{color:"blue"}}>{this.state.videoTxId}</b>
                            <br></br>
                            <br></br>
                            Did you mistype it?
                        </p>
                        <br></br>

                        <Button disabled={this.state.videoTxId.length < 28} positive onClick={this.closeFailedQueryModal} content='Okay'/>

                        <br></br>

                        </Modal.Content>

                    </Modal>
            </>
                :
            <></>

        const disableViewItBtn = this.state.videoTxId.length < 28 || this.state.recordedVideoURL !== null
        const viewerModal =
                <>
                    <Modal dimmer='blurring' size='large' centered className={modalClassName}  open={true}
                                    style={{backgroundColor: this.bshzPurple, borderRadius: '20px', height: "auto"}}>

                        <Modal.Header style={{textAlign: 'center', backgroundColor: this.bshzPurple, borderRadius: '20px'}}>
                            <span style={{color: this.bshzYellow}}> View Audio/Video Transactions</span>
                        </Modal.Header>

                        <Modal.Content className={modalContentClassName} scrolling style={{backgroundColor: this.bshzLtPurp}}>

                        <p>
                            Use this to play <b style={{color:'blue'}}>already-published</b> Audio/Video transactions (from the blockchain)
                        </p>

                        {maybePublishedTxId}
                        <br></br>
                        <Input value={this.state.videoTxId} style={{width:'408px'}}
													placeholder="Enter an audio/video txId to view"
													onChange={this.handleVideoTxIdChange}  />

                        <Button disabled={disableViewItBtn} positive onClick={this.viewThisTxId} content='Play it'/>
                        <br></br>
                        {maybeSuggestClickingButton}
                        <br></br>
                        <Button disabled={false} negative onClick={this.closeUs} content='Back'/>
                        </Modal.Content>

                    </Modal>
                </>

        return(
            <>
                {viewerModal}
                {maybeFailedQuery}
            </>
        )
    }
}

export default VideoViewerModal;
