import React from 'react';


import { Button, Checkbox, Container, Divider, Dropdown, Form, Grid,
  Input, Menu, Message, Modal, Popup, Radio, TextArea } from 'semantic-ui-react'

class SurveyQuestion extends React.Component {
  constructor(props) {
    super(props);

    const windowURL = window.location.href // returns the absolute URL of a page
    console.warn("AD: our URL (may be useful for innerFrame logic): ", windowURL)

    const windowPathname = window.location.pathname
    const pathSplit = windowPathname.split('/')
    console.log("AD: window pathname split : ", pathSplit)

    this.state =  { answerVal: null
                  }

    this.answerChanged          = this.answerChanged.bind(this);
  }

  answerChanged(e) {
    console.log('the answerChanged: ' + e.target.value);

    // set LOCAL state - to update display
    this.setState({ answerVal: e.target.value });

    console.log("survey question: props a: ", this.props.a)

    // set PARENT state - to eventually SUBMIT
    this.props.cv(this.props.a, e.target.value)
  }

  render() {
    const answerVal = this.state.answerVal

    const varName = this.props.a                                                        // a1 through a 13
    const questionNumber = varName.slice(varName.lastIndexOf('a') +1, varName.length)   // 1 through 13
    const qNumColor   = this.props.opt === 'y' ?
                        answerVal === null ? 'black' : 'grey'
                      :
                        answerVal === null ? '#FF5858' : 'grey'
    const qColor      = answerVal === null ? 'black' : 'grey'

    const qWeight     = answerVal === null ? 'bold' : 'normal'
    const radioWeight = answerVal === null ? 'bold' : 'normal'

    // <Form.Field label='Maybe'        type='radio' value='1' control='input' checked={answerVal==='1'} onChange={this.answerChanged}/>
    return (
      <>
        <div style={{marginLeft: 0, marginBottom: 5}}>
        <span style={{ color: qNumColor, fontWeight: qWeight }}> {questionNumber} - </span>
        <span style={{ color: qColor}}> {this.props.theQuestion} </span>
        </div>

        <Form.Group style={{padding: 0, marginBottom: 15, marginLeft: 0}}>
          &nbsp;
          <Form.Field>
            <label>
              <input type='radio' value='0' checked={answerVal==='0'} onChange={this.answerChanged}/>
              <span style={{fontWeight: radioWeight, color: qColor}}>
                &nbsp;No
              </span>
            </label>
          </Form.Field>
          &nbsp; &nbsp;
          <Form.Field              >
            <label>
              <input type='radio' value='1' checked={answerVal==='1'} onChange={this.answerChanged}/>
              <span style={{fontWeight: radioWeight, color: qColor}}>
                &nbsp;Maybe
              </span>
            </label>
          </Form.Field>
          &nbsp; &nbsp;
          <Form.Field>
            <label>
              <input type='radio' value='2' checked={answerVal==='2'} onChange={this.answerChanged}></input>
              <span style={{fontWeight: radioWeight, color: qColor}}>
                &nbsp;Sure
              </span>
            </label>
          </Form.Field>
          &nbsp; &nbsp;
          <Form.Field>
            <label>
              <input type='radio' value='3' checked={answerVal==='3'} onChange={this.answerChanged}></input>
              <span style={{fontWeight: radioWeight, color: qColor}}>
                &nbsp;Yes please!
              </span>
            </label>
          </Form.Field>
        </Form.Group>
      </>
    );
  }
}

export default SurveyQuestion;
