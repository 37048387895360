import React from 'react';

import { Button, Checkbox, Dropdown, Form, Icon, Input, List, Modal, Popup, Radio, Segment } from 'semantic-ui-react'

import Toast    from './toast';
import GifterModal from './gifterModal';

import './index.css';

import {NETWORK,
        HOLD_BACK_BLOCKHEIGHT_FOR_TESTING,
        //TARGET_RATE,
        GENESIS_APPEND,
        SATS_PER_LETTER
       } from './harnessConstants'

import {
    buildOnAppend,
    postProcessAppend,
    buildOnClaimOrPublish,

    openDB,
    //findLatestContinue,
    //findLatestContinue2,
    getClosestToLimb,

    simpleDecode,           // vastly quicker, simpler version of queryFetchDecodeTx()

    addARawTx,              // we use these two for express-claimed append txs
    deleteRawTx,

    queryFetchDecodeTx,
    findNextTx,
    //getTheNextAddress,
    //getNextDialogAddress,
    //getNextDialog,

    //registerASubscription,
    //unRegisterASubscription,
    registerDomainOfMine,
    //unRegisterDomainOfMine,
    //findADomainOfMine,
    findAllDomainsOfMine,

    //outdateDomainOfMine,

    registerBuiltDomain,

    allocateAvailableRabin,
    allocateAvailableAddress,

    getAvailableRabinsCount,
    getAvailableP2PKHsCount,

    //findADialog,
    //findAllTheDialogs,
    //getASubscription,

    getEncryptedKeysFromPKH,
    decryptData,

    mySleep,
} from './buildShizzle.js';

import {
	getDecodedTx,
} from "./shizzleIDB"

import ProgressListModal from './progressListModal.js'

import { convertAddressToPKH, validateShizzleTree } from './commonFuncs';

/** Only needed for bsv. As we share more between console and web, that may change. */
import {
	bsv,
	//int2Asm					//FIXME: not exported?
//} = require('../../../../../NEWNEW/boilerplate2/boilerplate/node_modules/scryptlib');
} from 'scryptlib' ;

// helps us build a chain of txs for claiming an asset
const { getBestUtxoChoice } = require('./contractSupport.js')
const { fetchUtxos, sendSerializeTx } = require('./providers.js')


//FIXME: re-evaluate if 4th param is helpful
async function queryFunction(a, b, queryProvider = false, targetOptimization = null) {
    if ( queryProvider ) {
        return await queryFetchDecodeTx(a, b, true, targetOptimization)
    } else {
        return await getDecodedTx(b, a)
    }
}

class Claimer extends React.PureComponent {
    constructor(props) {
        super(props);

        this.BitSchnitzelLLC     = <>BitSchnitzel LLC</>;
        this.shizzleVerse        = <><b>ShizzleVerse</b><small>&trade;</small></>;
        this.bitShizzle          = <><b>BitShizzle</b><small>&trade;</small></>;
        this.bitShizzleItalic    = <><b>Bit<i>Shizzle</i></b><small>&trade;</small></>;
        this.shizzleVerseNotBold = <>ShizzleVerse<small>&trade;</small></>;
        this.bitShizzleNotBold   = <>BitShizzle<small>&trade;</small></>;

        //FIXME: the purples are currently duplicated in index.css
        this.bshzPurple             = '#8313e2';
        this.bshzYellow             = "#fff300";
        this.bshzLtPurp             = '#e7d0fb';
        this.bshzLightYellow        = "#fffccc";
        this.bshzLessLightYellow    = "#efecbc";

        // if we ever use the PLM, we'll also fill this with its reset function
        // as soon as it calls back to us
        this.plmResetFunc = null

        // Approximation - based on 20x 1-sat-output appends, and DEFAULT_FEE_PER_KB of 2 (generally 0.02 sat/byte), and 1-sat outputs
        const satsPerLetter = SATS_PER_LETTER // now taken from harnessConstants.js
                              //402       // changed to 402 on July 17, 2023 - with fanout of 20 ('t'), and DEFAULT_FEE_PER_KB of 1.4 (empirically with 16-letter name) <-----
                                        //    final few txs had ~ 1.262 s/B
                                        // changed to 581 on July 17, 2023 - with fanout of 20 ('t'), and DEFAULT_FEE_PER_KB of 2 (empirically with 11-letter name)
                                        //13100     // now with fanout to 't' (fanout 20), and DEFAULT_FEE_PER_KB of 62 (generally 0.0519 sats/B)  (on Feb 16, 2023)
                                        //9299  // calculated empirically, by claiming a 20-character domain (building all of them)
                                        // 9939 with DEFAULT_FEE_PER_KB of 62 (generally 0.05373)

        this.satsPerPenny = 1000000 / this.props.bsvPriceUSD
        const penniesPerSat = this.props.bsvPriceUSD / 1000000
        console.log("sats per US Penny: " + this.satsPerPenny)
        console.log("US PENNIES per sat: " + penniesPerSat)

        this.USPenniesPerLetter = Math.round(100 * penniesPerSat * satsPerLetter)/100  // 0.43¢ per letter at $49.54/BSV
        console.log("US PENNIES per letter: " + this.USPenniesPerLetter)

        // the Android mobile Input field can only fit this many characters: 38
        this.maxDomainLength = 38

        this.state = {
            initializedDB: false,       // true after querying the Genesis tx

            showDbInitializationToast: false,

            nameToClaim: '',
            claimSearchTitle: '',
            searchWasInitiated: false,   // used for reseting things for follow-on searches

            openTheProgressList: false,  // when set to true, initiates work from the ProgressListModal
                                         //FIXME: maybe change to beginWork?
            jobCanceled: false,

            showPropagationToast: false,
            propagatedTxId: '',
            showRegistrationSuccess: false,

            showLoopPropagationToast: false,
            loopTxId: '',
            loopTxStr:      '',
            loopTxLimbName: '',
            loopNextOutIdx: -1,
            limbWhichIsPropagating: '',

            // results of query
            furthestTxId: null,
            furthestTxDecoded: '',
            found: false,
            owned: true,
            reachedLimb: '',
            extraVerbage: '',
            buildingOnModeP: false,     // distinguished between modes of building 1(false), 6(true)

            numDomainsOwned: 0,
            myDomains: [],
            claimMore: false,
            alreadyOwnedByUser: false,  // used by handleNameChange
            claimedList: [],            // just .name. includes forFriend, and outdated entries
            justMyList: [],             // just .name. .forFriend, and .outdated entries removed

            disableBuild: false,        // disable build/claim button once pressed
            showBuildingToast: false,

            freeRabins: 0,
            freeP2PKHs: 0,

            expired: false,
            newOwnerCount: 1,  // typical for claiming

            claimOperation: 'build',
            claimProgress: '...',

            forAFriend: false,
            keyUsedToClaim: '',
            pToEnc: '',
            qToEnc: '',

            optionalAdditionalBalance: 0,   // new param, for gifting EXTRA satoshis with a domain
            showPennyPitchModal: false,
            giftType: 0,
            additionalPenniesToGift: 0,
            mentionMyDomainInGift: false,
            myDomainToMention: '',

            userClaimedSomething: false,
            mostRecentClaim: "???",
            mostRecentOwnerCount: 0,
        }

        this.handleNameChange      = this.handleNameChange.bind(this);
        this.checkOnClaim          = this.checkOnClaim.bind(this);
        this.closeProgressList     = this.closeProgressList.bind(this);

        this.toastPropagateDone    = this.toastPropagateDone.bind(this);

        this.justClaimAndPublish   = this.justClaimAndPublish.bind(this);
        this.buildPlusClaimAsset   = this.buildPlusClaimAsset.bind(this);
        this.loopBuildThenClaimAsset = this.loopBuildThenClaimAsset.bind(this);
        this.setupBuildLoop        = this.setupBuildLoop.bind(this);

        this.expressClaim          = this.expressClaim.bind(this);

        this.resetSearch           = this.resetSearch.bind(this);

        this.doWork                = this.doWork.bind(this);
        this.workCanceled          = this.workCanceled.bind(this);
        this.walkTheAppends        = this.walkTheAppends.bind(this);

        this.registerBuiltNode     = this.registerBuiltNode.bind(this);

        this.showRegistrationSuccessModal     = this.showRegistrationSuccessModal.bind(this);
        this.handleRegistrationSuccessModalOk = this.handleRegistrationSuccessModalOk.bind(this);

        this.handleClaimMore                  = this.handleClaimMore.bind(this);
        this.handleNoClaim                    = this.handleNoClaim.bind(this);

        this.handleCloseThisClick             = this.handleCloseThisClick.bind(this);

        this.makeThisAGift                    = this.makeThisAGift.bind(this);
        this.launchGifter                     = this.launchGifter.bind(this);
        this.closeGifter                      = this.closeGifter.bind(this);

        this.closePennyPitchModal             = this.closePennyPitchModal.bind(this);
        this.handleGiftTypeChange             = this.handleGiftTypeChange.bind(this);
        this.editGiftAmount                   = this.editGiftAmount.bind(this);
        this.toggleGifterMentionDomain        = this.toggleGifterMentionDomain.bind(this);
        this.handleFromFriendChange           = this.handleFromFriendChange.bind(this);

        // not needed. it uses self-passed reference
        //this.toastLoopPropagateDone           = this.toastLoopPropagateDone.bind(this);
    }

    editGiftAmount() {
        this.setState({showPennyPitchModal: true})
    }
    makeThisAGift(e, d) {
        const showPennyPitchModal = d.checked
        const optionalAdditionalBalance = d.checked ? this.state.optionalAdditionalBalance : 0
        this.setState({ forAFriend: d.checked,
                        showPennyPitchModal: showPennyPitchModal,
                        optionalAdditionalBalance: optionalAdditionalBalance
                    })
        if ( !d.checked ) {
            // user changed their mind. Clear selection
            this.setState({
                    optionalAdditionalBalance: 0,
                    giftType: 0,
                    additionalPenniesToGift: 0,
                    mentionMyDomainInGift: false,
                    myDomainToMention: ''
            })
        }
    }
    async launchGifter() {
        const rabinPkhUsedToClaim = this.state.keyUsedToClaim
        console.warn("will launch gifter with rabinPKH of ", rabinPkhUsedToClaim)

        const db = await openDB()

        const obj = await getEncryptedKeysFromPKH(db, rabinPkhUsedToClaim);
        console.log("launchGifter(): got encrypted json object: ", obj);

        let rabinPrivKeyP
        let rabinPrivKeyQ
        try {
            const plainBundle = await decryptData(obj.blob, this.props.bundle.pwd);

            //console.log("launchGifter(): decrypted json string:", plainBundle);

            const bundledObject = JSON.parse(plainBundle);

            rabinPrivKeyP = bundledObject.p
            rabinPrivKeyQ = bundledObject.q

        } catch ( error ) {
            console.log("launchGifter(): Bad decrypt. Probably provided wrong password.");

            alert("There's something wrong. launchGifter() ailed to retrieve keys. Bad password?")
            return false
        }

        this.setState({showGifter: true, pToEnc: rabinPrivKeyP, qToEnc: rabinPrivKeyQ})
    }
    closeGifter() {
        this.setState({showGifter: false})

        // ALSO close the registration success modal
        this.handleRegistrationSuccessModalOk()
    }

    handleClaimMore() {
        this.setState({claimMore: true})
    }
    handleNoClaim( ownsAssets, advance = true ) {
        // cancel/close, and advance
        //this.setState({claimMore: false})

        console.log("claimer: handleNoClaim() ownsAssets: ", ownsAssets)
        console.log("claimer: handleNoClaim() mostRecentClaim: ", this.state.mostRecentClaim)
        console.log("claimer: handleNoClaim() mostRecentOwnerCount: ", this.state.mostRecentOwnerCount)
        console.log("claimer: handleNoClaim() advance: ", advance)

        // advance the user citizenLevel, AND close this down
        this.props.claimedCallback(ownsAssets, this.state.mostRecentClaim, this.state.mostRecentOwnerCount, advance)
    }

    async componentDidMount() {

        console.warn("Claimer componentDidMount()")
console.log("props.balancePennies: ", this.props.balancePennies)
console.log("props.bestUtxoPennies: ", this.props.bestUtxoPennies)
console.log("props.bsvPriceUSD: ", this.props.bsvPriceUSD)

/*
        const iv = eccryptoJS.randomBytes(4)
        console.log("gifterModal generated four random bytes to encrypted key with: ", iv)
        console.log("    iv[0]: ", iv[0])
        console.log("    iv[1]: ", iv[1])
        console.log("    iv[2]: ", iv[2])
        console.log("    iv[3]: ", iv[3])

        var myByteArray = window.crypto.getRandomValues(new Uint8Array(4))
        console.log("OR myByteArray: ", myByteArray)

        const hexRandos = myByteArray[0]
                        + myByteArray[1] * 256
                        + myByteArray[2] * 256 * 256

        console.warn("  Hex randos: " + hexRandos)

        alert("read console")
/* */

        const db = await openDB()

        // determine if this is a brand NEW user
        // IOW: do we need to query for the genesis tx?
        // IOW: try our DB first. If nothing there, query our tx-provider
        const genesis = await queryFunction(GENESIS_APPEND, db, false)
        if ( genesis === null ) {
            this.setState({showDbInitializationToast: true})

            console.warn("NOTE: brand-new user. We need to prime the pump (query the genesis tx)")
            await queryFunction(GENESIS_APPEND, db, true)

            this.setState({showDbInitializationToast: false})
        }

        // make sure we don't have an OLD genesis (in IDB), while
        // runing a NEW browser which uses a NEWER genesis/root
        // THIS CHECK IS only for during testing
        // Once fully-deployed, we should never update the genesis/root
        validateShizzleTree()

        // Check if the user ALREADY has claimed an asset or more
        const myDomains = await findAllDomainsOfMine( db )

        // includes forFriend, and outdated
        // ONLY the .name
        let claimedList = []        // used by domainsOwnedList (for a PopUp) in render()
        // forFriend, and outdated removed
        // .key, .value, ,.text
        let justMyList = []         // used by optionalDomainDropDown in render()
        let q = 0
        myDomains.forEach(element => {
            claimedList.push( element.name + ":" + element.ownerCount )

            if ( element.outdated || element.forFriend ) {
                //continue
            } else {
                justMyList.push(
                                {
                                    key: q,
                                    value: element.name,
                                    text: element.name
                                })
            }
            q++
        });

        const freeRabinsCount = await getAvailableRabinsCount(db)
        const freeP2PKHsCount = await getAvailableP2PKHsCount(db)
        console.log("You have ", freeP2PKHsCount, " p2pkhs, and ", freeRabinsCount, " rabins")
        //alert("You have " + freeP2PKHsCount + " p2pkhs, and " + freeRabinsCount + " rabins")

        // NOTE: these numbers should be the same (or smaller than) the
        //       check in assetManager's render()
        if ( freeP2PKHsCount < 18 ) {
            alert("NOTE: your wallet is running low on unallocated Builder Payout keys: " + freeP2PKHsCount
                + " remaining. To remedy this, please open, then click your Wallet in the side-panel.")
            this.handleNoClaim( justMyList.length > 0, false )
        }
        if ( freeRabinsCount < 18 ) {
            alert("NOTE: your wallet is running low on unallocated Publisher keys: " + freeRabinsCount
                + " remaining. To remedy this, please open, then click your Wallet in the side-panel.")
            this.handleNoClaim( justMyList.length > 0, false)
        }

        console.warn("balancePennies: ", this.props.balancePennies)
        console.warn("USPenniesPerLetter: ", this.USPenniesPerLetter)
        console.warn("bestUtxoPennies: ", this.props.bestUtxoPennies)
        if ( this.props.balancePennies < this.USPenniesPerLetter + 1.0 ) {
            alert('NOTE: your wallet is running low on funds. It would be best if you topped it off before attempting to claim anything. Please open, then click your Wallet in the side-panel.')
            this.handleNoClaim( justMyList.length > 0, false )
        } else if ( this.props.bestUtxoPennies < this.USPenniesPerLetter + 1.0 ) {
            alert('NOTE: your wallet may have too much loose change. It would be best if you consolidated your coins before attempting to claim anything. Please open, then click your Wallet in the side-panel.')
            this.handleNoClaim( justMyList.length > 0, false )
        }

        console.warn("BTW: my domains: ", myDomains)
        console.warn("BTW: JUST my domains (no 'forFriend', nor 'outdated'): ", justMyList)
        this.setState({ initializedDB: true,

                        numDomainsOwned: justMyList.length,
                        myDomains: myDomains,
                        claimedList: claimedList,
                        justMyList: justMyList,

                        freeRabins: freeRabinsCount,
                        freeP2PKHs: freeP2PKHsCount,
                    })
    }

    // needed because we may call handleNoClaim() from componentWillMount(), above
    componentWillUnmount() {
        console.log("Claimer about to unmount.")

        // fix Warning: Can't perform a React state update on an unmounted component
        // see: https://stackoverflow.com/questions/53949393/cant-perform-a-react-state-update-on-an-unmounted-component
        this.setState = (state,callback)=>{
            return;
        };
    }

    showRegistrationSuccessModal(title, text) {
        this.setState({showRegistrationSuccess: true})
    }
    handleRegistrationSuccessModalOk() {
        // Close the Success modal
        this.setState({ showRegistrationSuccess: false })

        console.log("CLAIMER: we'll callback WITH success, and WITH the name: " + this.state.nameToClaim)
        // advance the user citizenLevel, AND close this down
        this.props.claimedCallback(true, this.state.nameToClaim.toLowerCase(), this.state.mostRecentOwnerCount)
    }
    handleCloseThisClick() {
        //IF userClaimedSomething, FIRST call this.props.claimedCallback()
        this.props.claimedCallback( this.state.justMyList.length > 0 || this.state.userClaimedSomething,
                                    this.state.mostRecentClaim,
                                    this.state.mostRecentOwnerCount,
                                    this.state.mostRecentClaim !== '???'
                                  )

        // Now handled using state.myDomains.length>0 passed to props.claimedCallback()
        //this.props.closeThis()
    }

    closePennyPitchModal() {
        this.setState({showPennyPitchModal: false})
    }
    handleGiftTypeChange(e, {value}) {
        let additionalPennies
        if ( value === 0 ) {
            additionalPennies = 0
        } else if ( value === 1 ) {
            additionalPennies = 5
        } else if ( value === 2 ) {
            additionalPennies = 10
        } else if ( value === 3 ) {
            additionalPennies = 15
        } else if ( value === 4 ) {
            additionalPennies = 25
        } else if ( value === 5 ) {
            additionalPennies = 35
        } else if ( value === 6 ) {
            additionalPennies = 50
        } else {
            throw new Error("CODE Error: invalid gift value: " + value)
        }

        const optionalAdditionalBalance  = Math.round( additionalPennies * this.satsPerPenny )

        this.setState({ giftType: value,
                        additionalPenniesToGift: additionalPennies,
                        optionalAdditionalBalance: optionalAdditionalBalance,
                    })
    }

    handleNameChange(event, data) {

        const v = data.value

        // regex check for valid domain name pattern
        const re = /^[a-zA-Z0-9-]+$/;

        if ( v !== '' && (!re.test(v) || v[0] === '-' || v.length > this.maxDomainLength
                                || v[0] === '0' || v[0] === '1' || v[0] === '2'
                                || v[0] === '3' || v[0] === '4' || v[0] === '5'
                                || v[0] === '6' || v[0] === '7' || v[0] === '8'
                                || v[0] === '9' ) ) {
            console.log("handleNameChange(): Ignoring value - doesn't match regex")
            return
        }

        const newNameToClaim = (event.target.value).toLowerCase()

        // If we see that the USER already owns the asset, immediately report results
        let alreadyOwnedByUser = false
        this.state.myDomains.forEach(element => {
            if ( element.name === newNameToClaim ) {
                console.warn("user already owns " + newNameToClaim)
                alreadyOwnedByUser = true;
            }
        });

        // also set furthestTxId to signal to clear any previous results display
        this.setState({ nameToClaim: newNameToClaim,
                        furthestTxId: null,
                        alreadyOwnedByUser: alreadyOwnedByUser,
                     });
    }

    async buildPlusClaimAsset() {
        this.setState({disableBuild: true, showBuildingToast: true})

        // These are the available/relevant state fields:
        //   furthestTxId: furthestTxId,
        //   furthestTxDecoded: txString,
        //   outputIndex: outputIndex,
        //
        // Also: reachedLimb: reachedLimb,

        const dtx = JSON.parse( this.state.furthestTxDecoded );
        const prevBlockNum = dtx.outputStates[this.state.outputIndex].blockNumInt    //blockNum
        console.warn("prev blockHeight: ", prevBlockNum)


        const db = await openDB()
        const addressRes = await allocateAvailableAddress(db)
        if ( addressRes === null ) {
            this.setState({showBuildingToast: false})
            //FIXME: what about disableBuild, and in the other scenarios?
            //       NO: something's wrong. Don't encourage flailing
            alert("ERROR: Your wallet may have run-out of available (un-allocated) Builder Payout addresses. Check your wallet. ABORTING")
            return
        }

        const payoutWIF = addressRes[0].address
        const payoutPKH = convertAddressToPKH(payoutWIF)
        // THIS, below, doesn't seem to work. the PKH ends-up with length of 25 bytes
        //const payoutPKHa = bsv.Script.buildPublicKeyHashOut( payoutWIF ).toHex()
        // hmm. I wonder if the .toHex() is the problem

        if ( payoutPKH === null || payoutPKH === -1 ) {
            this.setState({showBuildingToast: false})
            console.log('Failed converting address in Claimer::buildPlusClaimAsset()()');

            alert("Something went wrong while trying to handle Builder Payout address " + payoutWIF + ". Please report this.")
            return
        }

        console.warn("payoutPKH: " + payoutPKH)
        //console.warn("payoutPKH string: " + payoutPKH.toString())
        //console.warn("PayoutPKH len: " + payoutPKH.length)
        //console.warn("PayoutPKH String len: " + payoutPKH.toString().length)
        const builderRabinRes = await allocateAvailableRabin(db) // builder rabin
        console.warn("builderRabinRes: ", builderRabinRes)

        if ( builderRabinRes === null ) {
            this.setState({showBuildingToast: false})
            alert("ERROR: Your wallet may have run-out of available (un-allocated) Publisher keys. Check your wallet. ABORTING")
            return
        }
        const builderRabin = builderRabinRes[0].pkh

        //console.warn("builderRabin Len: " + builderRabin.length)
        const initialBuilderVote = "00" //this.state.builderInitialVote ? "FF" : "00"
        const preClaimOrNo = 'y'
        const preClaimRabinPKHRes = await allocateAvailableRabin(db) // publisher rabin
        if ( preClaimRabinPKHRes === null ) {
            this.setState({showBuildingToast: false})
            alert("ERROR: Your wallet may have run-out of available (un-allocated) Publisher keys. Check your wallet. ABORTING.")
            return
        }
        const preClaimRabinPKH = preClaimRabinPKHRes[0].pkh

        let theBlockHeight = prevBlockNum
        if ( !HOLD_BACK_BLOCKHEIGHT_FOR_TESTING ) {
            console.warn("We've changed this logic re: minBlockNum - since we're no-longer holding it back for testing")
            theBlockHeight = this.props.blockHeight
        }


        console.warn("Params to build:  txid: ", this.state.furthestTxId)
        console.warn("Params to build:    outputIndex: ", this.state.outputIndex)
        console.warn("Params to build:  dtx: ", dtx.outputStates[this.state.outputIndex])


        // overkill, since makeThisAGift clears optionalAdditionalBalance for non-gifts
        const optionalAdditionalBalance =  (preClaimOrNo === 'y' && this.state.forAFriend) ? this.state.optionalAdditionalBalance : 0

        try {
            // build the append tx - with an ouput 0 that's pre-claimed (K)
            // This ALSO adds multiple txo entries (which ever are appropriate)
            // NOTE: pass blockHeight of 0 if devTestMode
            const newTx = await buildOnAppend( dtx.outputStates[this.state.outputIndex],
                                            this.state.furthestTxId,
                                                this.state.outputIndex,

                                            payoutPKH + builderRabin + initialBuilderVote,  // builder state: payoutPKH + rabin + vote
                                            preClaimOrNo,
                                            preClaimRabinPKH,
                                            theBlockHeight,
                                            this.props.bundle.p,

                                            optionalAdditionalBalance
                                            );
            // NOTE: we now include builderRabin (and vote) in the builderState param

            // we now pass back THIS tx - to query it soon (after propagation delay)
            console.warn("Claimer::claimAsset(): we've built TxId ", newTx);
            //alert("We've built a tx. But we need to PAUSE for a few seconds: " + newTx)

            // shows a toast (specified to wait 7 seconds)
            // when timer is done, calls back to render-specified function toastPropagateDone()
            this.setState({propagatedTxId: newTx, showBuildingToast: false, showPropagationToast: true,
                            keyUsedToClaim: preClaimRabinPKH,
                            pToEnc: '', qToEnc: ''
                        })

        } catch (error) {
            this.setState({showBuildingToast: false})
            console.log('Failed in Claimer::buildPlusClaimAsset()()');
            //console.log('Failed on network: ' + NETWORK)
            console.error("stack: " + error.stack);
            //showError(error);    // needed?
            alert("Something went wrong while attempting to build and broadcast an APPEND")
        }

    } // buildPlusClaimAsset

    async justClaimAndPublish() {
        this.setState({disableBuild: true, showBuildingToast: true})

        //FIXME: do we want to double-check if we alreeady have the appropriate rabin? Probably
        //       If we don't, then maybe we got here because someone claimed this for us?
        //       MAYBE we'll want to determine this before even getting here - IOW: before even enabling a BUILD button

        const dtx = JSON.parse( this.state.furthestTxDecoded );
        const prevBlockNum = dtx.outputStates[this.state.outputIndex].blockNumInt    //blockNum
        console.warn("prev blockHeight: ", prevBlockNum)

        console.warn("Params to build:  txid: ", this.state.furthestTxId)
        console.warn("Params to build:    outputIndex: ", this.state.outputIndex)
        console.warn("Params to build:  dtx: ", dtx.outputStates[this.state.outputIndex])

        let theBlockHeight = prevBlockNum
        if ( !HOLD_BACK_BLOCKHEIGHT_FOR_TESTING ) {
            console.warn("We've changed this logic re: minBlockNum - since we're no-longer holding it back for testing")
            theBlockHeight = this.props.blockHeight
        }

        const rabinPkhOfPotentialBuyer = '0000000000000000000000000000000000000000'

        const authcode = ''
        const authcodePadding = ''
        const sellerRabinPubKey = ''

        const renew = false
        //const expired = false
        const normalZone = !this.state.expired
        const instaBuy = false
        console.warn("renew: " + renew + ",     normalZone: ", normalZone)

        // If building on mode 'P' or 'K', there is no price, nor ownerP2PKH
        // from which to copy
        const newInstaPriceParam = 0

//FIXME: could probabkly change to ''
        const newOwnerP2PkhParam = '0000000000000000000000000000000000000000'


        const paramPkhRes = await allocateAvailableRabin(await openDB())  /////////// // publisher rabin
        if ( paramPkhRes === null ) {
            this.setState({showBuildingToast: false})
            alert("ERROR: Your wallet may have run-out of available (un-allocated) Publisher keys. Check your wallet. ABORTING")
            return
        }
        const paramPKH = paramPkhRes[0].pkh
        console.log("allocated publisher Rabin PKH: ", paramPKH)

        const publishContent = true
        const content = "" // "Just claiming - P to p"
        const contentHexString = Buffer.from( content ).toString('hex')
        const contentLabel = ''
        const publishTxid = false
        const refTxId = ''
        const refTxLabel = ''
        const spawnUpdate = false    // NOW, when claiming (P->K), we can't spawn, nor include content
        const spawnSale = false
        const rabinPrivKeyP = ''
        const rabinPrivKeyQ = ''
        const paycode = ''

        // overkill, since makeThisAGift clears optionalAdditionalBalance for non-gifts
        //FIXME: it would be good to enforce HERE, if re-claiming (allowing non-zero additional balance)
        const optionalAdditionalBalance = this.state.forAFriend ? this.state.optionalAdditionalBalance : 0

        try {
            const newTx = await buildOnClaimOrPublish(
                                dtx.outputStates[ this.state.outputIndex ],
                                this.state.furthestTxId,
                                    this.state.outputIndex,
                                theBlockHeight,
                                paycode,     // p0   <----- ?
                                paramPKH,               // p1
                                normalZone,             // p2  post within the 'normal' zone (before zone of renewal),
                                                        //     Should be based on block, and dev setting(s)
                                rabinPkhOfPotentialBuyer,
                                authcode, authcodePadding,
                                sellerRabinPubKey, // LE Hex

                                newInstaPriceParam, //this.state.newInstaPrice,// p3: price in sats
                                newOwnerP2PkhParam, //newOwnerP2Pkh, //this.state.newOwnerP2Pkh,//     instaBuy receiving P2PKH. If buying, set NEW value to zeroes
                                instaBuy,

                                renew,                  // p4: if p2 was false, this is if we'd like to renew(T/F). sure.
                                paramPKH,               // p5: if 'p', and p2 was false, and we're re-claiming, this is the Rabin PKH to use
                                                        //     if 'p', and not renewing (p4 was false),
                                paramPKH,               // p6: keep the same PKH - but an opportunity to change it <---
                                publishContent,         // p7: publish content?
                                contentHexString,       // p8: content
                                contentLabel,// p9: content label
                                publishTxid,            // p10: publish a txid?
                                refTxId,     // p11: txId
                                refTxLabel,  // p12: label for tx
                                spawnUpdate, // p13: spawn update
                                spawnSale,   // p14: spawn sale/negotiations

                                optionalAdditionalBalance,   // new param: must be zero if new mode is NOT 'K'. Must be positive if new mode IS 'K'

                                rabinPrivKeyP,
                                rabinPrivKeyQ,
                                this.props.bundle.p);

            console.warn("Claimer::justClaimAndPublish(): we've built TxId ", newTx);
            //alert("We've built a tx. But we need to PAUSE for a few seconds: " + newTx)

            // shows a toast (specified to wait 7 seconds)
            // when timer is done, calls back to render-specified function toastPropagateDone()
            this.setState({propagatedTxId: newTx, showBuildingToast: false, showPropagationToast: true,
                            keyUsedToClaim: paramPKH,
                            pToEnc: '', qToEnc: ''
                        })

        } catch (error) {
            this.setState({showBuildingToast: false})
            console.log('Failed in Claimer::justClaimAndPublish()');
            //console.log('Failed on network: ' + NETWORK)
            console.error("stack: " + error.stack);
            //showError(error);    // needed?
            alert("Something went wrong while attempting to just claim an asset, and broadcast it")
        }

    } // justClaimAndPublish


    setupBuildLoop(latestTxId, latestTx, nextOutIndex) {

        // INITIAL loop setup

        // Set state of all important parameters
        // in preparation for looping

        // This is called at the conclusion of the asset SEARCH/analysis

        // The actual loop (below), is started by the user clicking CLAIM

        // SIMILAR work is also done after each iteration (in toastLoopPropagateDone())


        const dtxString = JSON.stringify( latestTx );
        this.setState({
            loopTxId:       latestTxId,
            loopTxStr:      dtxString,
            loopTxLimbName: latestTx.limbName,
            loopNextOutIdx: nextOutIndex        // which output to build on
        })
        //FIXME: consider setting state .loopParamsSet: true  - to enable CLAIM button
        //       probably not necessary
    }


    //FIXME: this may go away, eventually, now that we have expressClaim()
    //       This approach, HOWEVER, is more robust and simple - given that
    //       each step/tx is monitored for network acceptance along the way,
    //       and transaction records are updated (in the DB) as we go,
    //       rather than after-the-fact (broadcasting at the end)

    // We should have ALREADY setup INITIAL params for building
    // AND for each iteration

    //xxxx 1
    // Use the prepped state variables to:
    //    Build, broadcast, then show toast.
    //      calls back to toastLoopPropagateDone() when the toast delay is over

    //FIXME: have two different functions call this
    //       have those functions distringuish click vs func call
    //       - instead of ThIS function doing that a few lines down
    async loopBuildThenClaimAsset(e, v, a=0, b=0, c=0, d=0) {
        this.setState({disableBuild: true, showBuildingToast: true})

        console.log("loopBuildThenClaimAsse(): parameters a: " + a + "  b: " + b + "  c: " + c + "  d: " + d)
        // loop, building some extending appends, and then finally buildPlusClaimAsset()

        let loopTxId
        let loopTxStr
        let loopTxLimbName
        let loopNextOutIdx
        //FIXME: Instead of this code here,
        //       have two different functions do this, and call THIS function
        if ( a !==0  || b!== 0 || c !== 0 || d !== 0 ) {
            console.log("Called here explicitly by a function - NOT a click")
            // we need: loopTxId, loopTxStr, loopTxLimbName, loopNextOutIdx
            //  --> from func params

            loopTxId       = a
            loopTxStr      = b
            loopTxLimbName = c
            loopNextOutIdx = d
        } else {
            console.log("Called here by a CLICK - not a function")
            // we need: loopTxId, loopTxStr, loopTxLimbName, loopNextOutIdx
            //  --> from STATE
            loopTxId       = this.state.loopTxId
            loopTxStr      = this.state.loopTxStr
            loopTxLimbName = this.state.loopTxLimbName
            loopNextOutIdx = this.state.loopNextOutIdx
        }

        console.warn("We need to BUILD at least one node, before we can claim")
        console.warn("NB: we should pre-claim the final node.")

        const dtx = JSON.parse( loopTxStr )

        console.warn("Params to build:  target: ",        this.state.nameToClaim)
        console.warn("Params to build:  txid: ",          loopTxId)
        console.warn("Params to build:    outputIndex: ", loopNextOutIdx)
        console.warn("Params to build:  dtx[{that output}]: ", dtx.outputStates[ loopNextOutIdx ])


        let nextOutputIdx = loopNextOutIdx

        let preClaimOrNo = 'n'
        const target = this.state.nameToClaim
        const aboutToBuildLimb = target.substring(0, dtx.limbName.length + 1)
        console.log("about to build limb " + aboutToBuildLimb)
        // or: loopTxLimbName.length
        if ( dtx.limbName.length === (target.length - 1) ) {
            console.warn("we're ONE character short. NOW is when we need to pre-claim")
            console.warn("loopTxLimbName: " + loopTxLimbName)

            console.warn("HEADS-UP! (and read console). LimbName " + dtx.limbName + ". we're about to build on output 0. We should probably build with preClaim TRUE")
            preClaimOrNo = 'y'
        }


        //const latestLimbHexStr = dtx.outputStates[ nextOutputIdx ].namePath


        const prevBlockNum = dtx.outputStates[ nextOutputIdx ].blockNumInt    //blockNum
        console.warn("prev blockHeight: ", prevBlockNum)

        const db = await openDB()
        const addressRes = await allocateAvailableAddress(db)
        if ( addressRes === null ) {
            this.setState({showBuildingToast: false})
            alert("ERROR: Your wallet may have run-out of available (un-allocated) Builder Payout keys. Check your wallet. ABORTING")
            return
        }
        const payoutWIF = addressRes[0].address
        const payoutPKH = convertAddressToPKH(payoutWIF)
        // THIS approach doesn't seem to work. the PKH ends-up with length of 25 bytes
        //const payoutPKHa = bsv.Script.buildPublicKeyHashOut( payoutWIF ).toHex()

        if ( payoutPKH === null || payoutPKH === -1 ) {
            this.setState({showBuildingToast: false})
            console.log('Failed converting address in Claimer::loopBuildThenClaimAsset()');

            alert("Something went wrong while trying to handle Builder Payout address " + payoutWIF + ". Please report this.")
            return
        }
        console.warn("loopBuildThenClaimAsset(): allocated payout addresses: ", payoutWIF, "  PKH: ", payoutPKH)

        //console.warn("payoutPKH: " + payoutPKH)
        //console.warn("payoutPKH string: " + payoutPKH.toString())
        //console.warn("PayoutPKH len: " + payoutPKH.length)
        //console.warn("PayoutPKH String len: " + payoutPKH.toString().length)

        const builderRabsRes = await allocateAvailableRabin(db)
        if ( builderRabsRes === null ) {
            this.setState({showBuildingToast: false})
            alert("ERROR: Your wallet may have run-out of available (un-allocated) Publisher keys. Check your wallet. ABORTING")
            return
        }
        const builderRabin = builderRabsRes[0].pkh
        //alert("NOTE: loopBuildThenClaimAsset(): allocated builder rabin PKH: " + builderRabin)
        //alert("NOTE: loopBuildThenClaimAsset(): allocated payout address: " + payoutWIF)
        //alert("NOTE: loopBuildThenClaimAsset():     that's p2pkh: " + payoutPKH)
        console.warn("allocated builder rabin: " + builderRabin)
        //console.warn("builderRabin Len: " + builderRabin.length)

        const initialBuilderVote = "00" //this.state.builderInitialVote ? "FF" : "00"

        let preClaimRabinPKH
        if ( preClaimOrNo === 'y' ) {
            const preClaimRabinRes = await allocateAvailableRabin(db)  /////////// // publisher rabin
            if ( preClaimRabinRes === null ) {
                this.setState({showBuildingToast: false})
                alert("ERROR: Your wallet may have run-out of available (un-allocated) Publisher keys. Check your wallet. ABORTING")
                return
            }
            preClaimRabinPKH = preClaimRabinRes[0].pkh
            console.log("allocated preClaim Rabin: ", preClaimRabinPKH)
        } else {
            //FIXME: do we even need this? Could it be blank?
            preClaimRabinPKH = '0000000000000000000000000000000000000000'
            console.log("using zeroed-out preClaim Rabin: ", preClaimRabinPKH)
        }

        let blockHeight = prevBlockNum
        if ( !HOLD_BACK_BLOCKHEIGHT_FOR_TESTING ) {
            console.warn("We've changed this logic re: minBlockNum - since we're no-longer holding it back for testing")
            blockHeight = this.props.blockHeight
        }

        const builderState = payoutPKH + builderRabin + initialBuilderVote
        console.log("will use new builder state of: ", builderState)

        // overkill, since makeThisAGift clears optionalAdditionalBalance for non-gifts
        const optionalAdditionalBalance =  (preClaimOrNo === 'y' && this.state.forAFriend) ? this.state.optionalAdditionalBalance : 0

        try {
            // build the append tx - with an ouput 0 that's pre-claimed (K)
            // This ALSO adds multiple txo entries (which ever are appropriate)
            // NOTE: pass blockHeight of 0 if devTestMode
            const newTx = await buildOnAppend(  dtx.outputStates[ nextOutputIdx ],
                                                loopTxId,          //this.state.furthestTxId,
                                                nextOutputIdx,    //    this.state.outputIndex,

                                                builderState,         // builder state: payoutPKH + rabin + vote
                                                preClaimOrNo,
                                                preClaimRabinPKH,
                                                blockHeight,
                                                this.props.bundle.p,

                                                optionalAdditionalBalance
                                            );
            // NOTE: we now include builderRabin (and vote) in the builderState param

            // we now pass back THIS tx - to query it soon (after propagation delay)
            console.warn("Claimer::loopBuildThenClaimAsset(): we've built TxId ",
                            newTx, ". Now, must pause for propagation...");

            // shows a toast (specified to wait 7 seconds)
            // when timer is done, calls back to render-specified function toastLoopPropagateDone()
            this.setState({loopTxId: newTx,
                            showBuildingToast: false,
                            showLoopPropagationToast: true,
                            limbWhichIsPropagating: aboutToBuildLimb})

        } catch (error) {
            this.setState({showBuildingToast: false})
            console.log('Failed in Claimer::loopBuildThenClaimAsset()()');
            //console.log('Failed on network: ' + NETWORK)
            console.error("stack: " + error.stack);

            alert("Something went wrong while we were trying to claim that domain: " + target
                + ". Maybe something has changed since we last checked, OR you've entered an invalid character. Please try again.")
            this.resetSearch()
        }

    } // loopBuildThenClaimAsset

    // arrive here via click
    /**
     * Build entire chain of txs (keeping track of contract change), and THEN broadcast iteratively
     * @param {*} e
     * @param {*} v
     * @returns
     */
    async expressClaim(e, v) {
        this.setState({disableBuild: true, showBuildingToast: true})

        console.log("expressClaim()")
        // loop, building some extending appends, and then finally pre-claim

        let loopTxId       = this.state.loopTxId
        let loopTxStr      = this.state.loopTxStr
        let loopTxLimbName = this.state.loopTxLimbName
        let loopNextOutIdx = this.state.loopNextOutIdx

        console.warn("We need to BUILD at least one node, before we can claim")
        console.warn("NB: we should pre-claim the final node.")

        const dtx = JSON.parse( loopTxStr )

        console.warn("Params to build:  target: ",        this.state.nameToClaim)
        console.warn("Params to build:  txid: ",          loopTxId)

        console.warn("Params to build:    outputIndex: ", loopNextOutIdx)
        console.warn("Params to build:  dtx[{that output}]: ", dtx.outputStates[ loopNextOutIdx ])


        let nextOutputIdx = loopNextOutIdx

        const target = this.state.nameToClaim
        let numTxs = 0
        let storeThese = []
        const db = await openDB()

        const prevBlockNum = dtx.outputStates[ nextOutputIdx ].blockNumInt    //blockNum
        console.warn("prev blockHeight: ", prevBlockNum)


        const privateKey = new bsv.PrivateKey( this.props.bundle.p );
        let nextUTXOs = new Array( 50 )
        try {
            const initialUtxos = await fetchUtxos( privateKey.toAddress(), true, false, "expressClaim" );
            const bestUtxoIndex = getBestUtxoChoice(initialUtxos)
            if ( bestUtxoIndex === -1 ) {
                throw new Error("44821: FAILED to find the BEST UTXO")
            }
            nextUTXOs[0] = [ initialUtxos[ bestUtxoIndex ] ]
            console.warn("here's the FIRST nextUTXOs[] ([0]): ", nextUTXOs[0])
        } catch (error) {
            alert("CLAIMER had trouble querying for the funds in your wallet. Please try again. If that fails, you may need to wait for the provider to recover.")
            return
        }

        console.warn("initial utxos: ", nextUTXOs[0])

        // generate the 'change script' we'll use for each transaction in the chain we're about to buil

        //const privKey = new bsv.PrivateKey.fromWIF(specifiedPrivateKey)
        const specifiedPubKey = bsv.PublicKey.fromPrivateKey( privateKey )
        const specifiedBsvChangePKH = bsv.crypto.Hash.sha256ripemd160(specifiedPubKey.toBuffer('hex')).toString('hex')
        console.warn("change PKH: ", specifiedBsvChangePKH)
        const changeScript = "76a914"
                            + specifiedBsvChangePKH
                            + "88ac"


        //FIXME: ensure the best UTXO is rich enough to complete the full chain of builds


        let outputState

        let result = null
        var latestTx = dtx

        // If building off of the first fan-out (the genesis tx),
        // the limbName is misleading/wrong, so, don't use that
        const startingLen = loopTxId === GENESIS_APPEND ? 0 : dtx.limbName.length
        const numToBuild = target.length - startingLen
        for ( var len = startingLen;  len < target.length; len++ ) {

            const reachedLength = len; //latestTx.limbName.length
            const characterUponWhichToBuild = target[ reachedLength ]
            console.warn("C Having walked not far enough, we'll build on output with character "
                    + characterUponWhichToBuild)
            const addOneToOutputIdx = reachedLength < 1 ? 0 : 1
            nextOutputIdx = characterUponWhichToBuild.charCodeAt(0) - 97 + addOneToOutputIdx
            console.warn("the NEW output index (upon which to build) will be " + nextOutputIdx)

            outputState = latestTx.outputStates[ nextOutputIdx ]

            let preClaimOrNo = 'n'
            if ( len === (target.length - 1) ) {
                console.warn("we're ONE character short. NOW is when we need to pre-claim")
                console.warn("loopTxLimbName: " + loopTxLimbName)

                console.warn("HEADS-UP! (and read console). we're about to build the final tx. We should probably build with preClaim TRUE")
                preClaimOrNo = 'y'
            }

            // we're now interested in passing THESE state fields:
            //      .namePath
            //      .smallestAmount
            //      .contractSatoshis
            //      .builderVotes[]
            //      .builderRabins[]
            //      .builderPKHs[]
            //      .blockNum
            //      .stateHex
            //      .genesisBlock


            const addressRes = await allocateAvailableAddress(db)
            if ( addressRes === null ) {
                this.setState({showBuildingToast: false})
                alert("ERROR: Your wallet may have run-out of available (un-allocated) Builder Payout keys. Check your wallet. ABORTING")
                //FIXME: resetSearch()?
                return
            }
            const payoutWIF = addressRes[0].address
            const payoutPKH = convertAddressToPKH(payoutWIF)
            // THIS approach doesn't seem to work. the PKH ends-up with length of 25 bytes
            //const payoutPKHa = bsv.Script.buildPublicKeyHashOut( payoutWIF ).toHex()

            if ( payoutPKH === null || payoutPKH === -1 ) {
                this.setState({showBuildingToast: false})
                console.log('Failed converting address in Claimer::expressClaim()');

                alert("Something went wrong while trying to handle Builder Payout address " + payoutWIF + ". Please report this.")
                //FIXME: resetSearch()?
                return
            }
            console.warn("expressClaim(): allocated payout addresses: ", payoutWIF, "  PKH: ", payoutPKH)

            //console.warn("payoutPKH: " + payoutPKH)
            //console.warn("payoutPKH string: " + payoutPKH.toString())
            //console.warn("PayoutPKH len: " + payoutPKH.length)
            //console.warn("PayoutPKH String len: " + payoutPKH.toString().length)

            const builderRabsRes = await allocateAvailableRabin(db)
            if ( builderRabsRes === null ) {
                this.setState({showBuildingToast: false})
                alert("ERROR: Your wallet may have run-out of available (un-allocated) Publisher keys. Check your wallet. ABORTING")
                //FIXME: resetSearch()?
                return
            }
            const builderRabin = builderRabsRes[0].pkh
            //alert("NOTE: expressClaim(): allocated builder rabin PKH: " + builderRabin)
            //alert("NOTE: expressClaim(): allocated payout address: " + payoutWIF)
            //alert("NOTE: expressClaim():     that's p2pkh: " + payoutPKH)
            console.warn("allocated builder rabin: " + builderRabin)
            //console.warn("builderRabin Len: " + builderRabin.length)

            const initialBuilderVote = "00" //this.state.builderInitialVote ? "FF" : "00"

            let preClaimRabinPKH
            if ( preClaimOrNo === 'y' ) {
                const preClaimRabinRes = await allocateAvailableRabin(db)  /////////// // publisher rabin
                if ( preClaimRabinRes === null ) {
                    this.setState({showBuildingToast: false})
                    alert("ERROR: Your wallet may have run-out of available (un-allocated) Publisher keys. Check your wallet. ABORTING")
                    //FIXME: resetSearch()?
                    return
                }
                preClaimRabinPKH = preClaimRabinRes[0].pkh
                console.log("allocated preClaim Rabin: ", preClaimRabinPKH)
            } else {
                //FIXME: do we even need this? Could it be blank?
                preClaimRabinPKH = '0000000000000000000000000000000000000000'
                console.log("using zeroed-out preClaim Rabin: ", preClaimRabinPKH)
            }

            let blockHeight = prevBlockNum
            if ( !HOLD_BACK_BLOCKHEIGHT_FOR_TESTING ) {
                console.warn("We've changed this logic re: minBlockNum - since we're no-longer holding it back for testing")
                blockHeight = this.props.blockHeight
            }

            const builderState = payoutPKH + builderRabin + initialBuilderVote
            console.log("will use new builder state of: ", builderState)

            // overkill, since makeThisAGift clears optionalAdditionalBalance for non-gifts
            const optionalAdditionalBalance =  (preClaimOrNo === 'y' && this.state.forAFriend) ? this.state.optionalAdditionalBalance : 0

            try {
                // build the append tx - with an ouput 0 that's pre-claimed (K)
                // This ALSO adds multiple txo entries (which ever are appropriate)
                // NOTE: pass blockHeight of 0 if devTestMode
                result = await buildOnAppend(  outputState,
                                                    loopTxId,
                                                    nextOutputIdx,

                                                    builderState,         // builder state: payoutPKH + rabin + vote
                                                    preClaimOrNo,
                                                    preClaimRabinPKH,
                                                    blockHeight,
                                                    this.props.bundle.p,

                                                    optionalAdditionalBalance,

                                                    true,                    // NOTE: optional SILENT build (no broadcast)
                                                    nextUTXOs[numTxs]                // NOTE: optional pre-constructed "UTXOs"
                                                );
                // NOTE: we now include builderRabin (and vote) in the builderState param

                // we now pass back THIS tx - to query it soon (after propagation delay)
                console.log("\n\nresult: ", result)
                console.warn("Claimer::expressClaim(): we've built TxId of length", result.rawTx.length/2 + "   : ",  //newTx
                                result.rawTx, ". Now, must pause for propagation...");

                // .txid
                // .rawTx
                // .change
                console.warn("expressClaim(): back from buildOnAppend(). results are an object. see console")

            } catch (error) {
                this.setState({showBuildingToast: false})
                console.log('Failed in Claimer::loopBuildThenClaimAsset()()');
                //console.log('Failed on network: ' + NETWORK)
                console.error("stack: " + error.stack);

                alert("Something went wrong while we were trying to claim that domain: " + target
                    + ". Maybe something changed since we last checked, OR you've entered an invalid character. Please try again.")
                this.resetSearch()
                return
            }

            // preserve the rawTx for later broadcast
            const rawTx = result.rawTx

            // This was an option to broadcast transactions, asynchronously,
            // as they were constructed. This still might be viable, but
            // maybe with added constraints to avoid out-of-order sends,
            // or jamming the provider
/*
            const parent = this
            execAsync( async function() {
                console.log("Will broadcast " + aboutToBuildLimb + " - a tx of length " + rawTx.length/2 + "...")
                let txid
                try {
                    txid = await sendSerializeTx(rawTx);
                    console.error('    DONE broadcasting tx ', txid, " of length " + rawTx.length/2)
                } catch (error) {
                    //parent.setState({showLoopPropagationToast: false});
                    alert("WHOOPS: We had trouble broadcasting txid " + txid)
                }
            })
*/

            if ( rawTx !== null ) {
                console.warn("we've got a RAW tx to decode (a bit) - of length " + rawTx.length/2)

                // simplified decode without ANY queries, and maybe not all fields
                // Use this for a few fields we need to build the NEXT tx (continuity)
                latestTx = simpleDecode( rawTx )
            } else {
                alert("whoops. a null rawTx? CODING ERROR")

    //FIXME: showBuildingToast?
                //FIXME: resetSearch()?
                return
            }

            loopTxLimbName = latestTx.limbName
            console.log("expressClaim. Next step - based on output "
                        + nextOutputIdx + " of simple decode of latestTx: ", latestTx)

            // preserve these for post-build DB processing of each transaction
            storeThese[numTxs] = {
                rawTx:                 rawTx,
                prevTxid:              loopTxId,            // BEFORE we update it
                base0OutIndex:         nextOutputIdx,
                ourNextTx:             result.txid,
                outputStateScriptHash: outputState.scriptHash,

                simpleLatestTx:        latestTx
            }

            //update toast with progress
            this.setState({claimOperation: "build", claimProgress: <>{numTxs+1}/{numToBuild}</>})

            numTxs++

            // Prep for the next letter/tx

            // NOTE that we don't subtract one. The change output isn't represented in the tx object
            const changeOutputIdx = latestTx.outputStates.length

            // use .change to help calculate a new UTXO that we'll need to pass down to the bowels of ContractSupport
            console.log("using change of " + result.change + " to generate a UTXO for the next tx")

            loopTxId       = result.txid
            nextUTXOs[numTxs] = [{
                txId: loopTxId,
                outputIndex: changeOutputIdx,
                satoshis: result.change,
                script: changeScript
            }]
            //console.warn("work in progress: storeThese[" + (numTxs-1) + ": ", storeThese[numTxs-1])
            console.warn("Work In Progress: nextUTXOs: ", nextUTXOs[numTxs])

            if ( preClaimOrNo === 'y' ) {
                console.warn("Now that we're done BUILDING transactions to claim, let's take note of the key we used to actually claim this domain: ", preClaimRabinPKH)
                this.setState({keyUsedToClaim: preClaimRabinPKH,
                                pToEnc: '', qToEnc: ''
                            })
            }
        } // each letter to be built

        console.warn("done building string of txs")

        console.warn("nextUTXOs[]: ", nextUTXOs)

        //for ( let z = 0; z < numTxs; z++ ) {
        //    console.warn("nextUTXOs[" + z + "] .txId: " + nextUTXOs[z][0].txId)
        //    console.warn("             .outputIndex: " + nextUTXOs[z][0].outputIndex)
        //    console.warn("             .satoshis: " + nextUTXOs[z][0].satoshis)
        //}

        console.warn("   ====> There are " + numTxs + " transactions to now be broadcast")

        // Broadcast each tx, and peform post-build DB processing
        for ( let j = 0; j < numTxs; j++ ) {

            //update toast
            this.setState({claimOperation: "broadcast", claimProgress: <>{j+1}/{numTxs}</>})

            console.log("WILL be broadcasting a tx of length " + storeThese[j].rawTx.length/2 + "...")
            const nextU = nextUTXOs[j+1][0]
            console.warn("spent nextUTXOs[" + j + "] .txId: " + nextUTXOs[j][0].txId)
            console.warn("                   .outputIndex: " + nextUTXOs[j][0].outputIndex)
            console.warn("                   .satoshis: " + nextUTXOs[j][0].satoshis)
            console.warn("NEW nextUTXOs[" + (j+1) + "] .txId: " + nextUTXOs[j+1][0].txId)
            console.warn("                   .outputIndex: " + nextUTXOs[j+1][0].outputIndex)
            console.warn("                   .satoshis: " + nextUTXOs[j+1][0].satoshis)
            console.warn("also NEW nextUTXOs[" + (j+1) + "] .txId: " + nextU.txId)
            console.warn("                      .outputIndex: " + nextU.outputIndex)
            console.warn("                      .satoshis: " + nextU.satoshis)

            const txid = await sendSerializeTx(storeThese[j].rawTx,
                                                nextUTXOs[j],  // the SPENT utxo (array of 1)
                                                nextU.outputIndex, nextU.satoshis, specifiedBsvChangePKH);
            console.log('    Broadcast tx #' + j + ": ", txid)
            console.log('     HOPEFULLY matches ' + storeThese[j].ourNextTx)
            // compare that txid with what we stored in storeThese.something
            if ( txid !== storeThese[j].ourNextTx ) {
                alert("Coding error? malleation? Please report that " + txid + " was not what was expected. We were on tx " + (j+1) + " of " + numTxs)
                //FIXME: resetSearch()?
                this.setState({showBuildingToast: false})
                return
            }

            // 1 - STORE RAW TX in db ( so qFDTx() can get it )
            await addARawTx(db, txid, storeThese[j].rawTx)
            // alternatively, we'd pass it into qFDTx() as a final parameter

            // 2 - queryFetchDecodeTx(......, skipNetworkQueries)  - which will ** addTxo() ** for each output
            const decodedTx = await queryFetchDecodeTx(txid, db,
                                            false, //weCareAboutUpdatedOutputs
                                            null, //weCareOnlyAboutThisPath
                                            true ) //skipNetworkQueries
            console.warn("expressClaim(): take a look - newly-decoded tx: ", decodedTx)
            //console.error("    ^^^^^^^^^^^^^^^^^^^^^^^^^^^^^\n");
            //console.error("    compare with simpleLatestTx: ", storeThese[j].simpleLatestTx)

            console.warn("  about to post-process, with this object slice: " + j + ": ", storeThese[j])

            // update DB for each tx we've built
            // 4 - this **should** add the spend of that tx
            await postProcessAppend(  db,
                                storeThese[j].prevTxid,
                                storeThese[j].base0OutIndex,
                                storeThese[j].ourNextTx,
                                storeThese[j].outputStateScriptHash)

            // add to the domainsBuiltTab - registering the payoutPKH, rabinPKH, and txid
            await this.registerBuiltNode(db, decodedTx, txid )

            // we no-longer need that raw append tx. It's been processed
            await deleteRawTx(db, txid)

            // there's no need to pause in-between broadcasting transactions
            //await mySleep(400, "    sleep after a buffered broadcast")

        } // each letter-to-build of the claim

        console.warn("We've sent ALL " + numTxs + " transactions used to claim your new asset: " + this.state.nameToClaim.toUpperCase())

        console.warn("A: Done claiming. Will now register domain, maybe mentioning our identity, if forAFriend, and mention...")

        let fromWhich = null
        if ( this.state.forAFriend && this.state.mentionMyDomainInGift ) {
            if ( this.state.justMyList.length > 1 ) {
                fromWhich = this.state.myDomainToMention
            } else {
                fromWhich = this.state.justMyList[0].value
            }
            console.error("A: Add new field: fromFriend - with domain " + fromWhich)
        }

        // ALSO mention if this is for-a-friend
        await registerDomainOfMine( db,
                                    this.state.nameToClaim,
                                    this.state.newOwnerCount,
                                    this.props.blockHeight,        //XXXXX  maybe should use blockHeight var
                                    false,
                                    this.state.forAFriend,
                                    null)
        // If we SEND to friend right now, we'll mention from THIS domain/identity
        // Also: clear the Toast/progress
        this.setState({ fromWhich: fromWhich,
                        showBuildingToast: false,
                        userClaimedSomething: true,
                        mostRecentClaim: this.state.nameToClaim,
                        mostRecentOwnerCount: this.state.newOwnerCount})

        // congratulate user
        this.showRegistrationSuccessModal()

    } // expressClaim()


    resetSearch() {

        if ( this.plmResetFunc !== null ) {
            // reset the ProgressListModal so that we can
            // use it again for a different search
            this.plmResetFunc()
        } else {
            alert("CODE ERROR: we couldn't RESET the plModal. How could we use it again?")
            throw new Error("ERROR: failed reseting the Claimer's PLM")
        }

        this.setState({ searchWasInitiated: false,
                        //nameToClaim: '',

                        openTheProgressList: false,
                        jobCanceled: false,

                        // results of query
                        furthestTxId: null,
                        furthestTxDecoded: '',
                        found: false,
                        owned: true,
                        reachedLimb: '',
                        extraVerbage: '',
                        buildingOnModeP: false,

                        disableBuild: false,

                        expired: false,
                        newOwnerCount: 1,

                        forAFriend: false,
                        optionalAdditionalBalance: 0,
                        giftType: 0,
                        additionalPenniesToGift: 0,
                        mentionMyDomainInGift: false,
                        myDomainToMention: ''
                    })
    }

    checkOnClaim() {
        console.warn("CheckOnClaim()...")

        // search is conducted while using the progressListModel
        this.setState({ openTheProgressList: true,
                        claimSearchTitle: <>Checking on ShizzleVerse domain '{this.state.nameToClaim.toUpperCase()}'...</>,
                        searchWasInitiated: true,

                        jobCanceled: false,

                        // results of query
                        furthestTxId: null,
                        furthestTxDecoded: '',
                        found: false,
                        owned: true,
                        reachedLimb: '',
                        extraVerbage: '',
                    })
    }
    closeProgressList() {
        console.log("closeProgressList() called")
        this.setState({openTheProgressList: false})
    }


    ///////////////////////////////////
    workCanceled() {
        console.warn("The job was just canceled")
        this.setState({jobCanceled: true})
    }
    async walkTheAppends(txId, decodedTx, closestLimb, target, progressFunc) {
        const numAppendOutputs = 20

        let latestTxId = txId
        let latestTx = decodedTx

        const db = await openDB()

        // Walk the Appends...
        let ourIterations = "" + txId + "... "
        // setup loop to travel as far as possible
        let curLength = closestLimb.length
        console.log("  walkTheAppends(): closestLimb: ", closestLimb)
        do {
            console.warn("  walkTheAppends(): LATEST TX: ", latestTx)

            // IMPORTANT: for root/genesis, output 'a' is at idx 0
            //            after that point, there's a claim/continue/quarterly at idx 0
            const addOneToOutputIdx = curLength < 1 ? 0 : 1
            console.warn("    addOneToOutputIdx = " + addOneToOutputIdx)

            // get its Ith output scripthash (depending on path we're headed)
            const nextChar = target.substring(curLength, curLength+1)
            console.warn("    next char: " + nextChar)
            if ( nextChar === '' ) {
                console.warn("walkTheAppends(): whoops. EMPTY next char. No more chars to target " + target + ", with curLength " + curLength)
                progressFunc("walkTheAppends(): EMPTY next char. No more chars to target " + target + ", with curLength " + curLength)
                break;
            }

            console.warn("    ascii val: " + nextChar.charCodeAt(0))
            const idxToCheck = nextChar.charCodeAt(0) - 97 + addOneToOutputIdx // for 'a', 'NORMALLY' look at output 1 (output)
            console.warn("    so, look at output #" + idxToCheck )
            if ( idxToCheck > numAppendOutputs ) {
                console.error ("heads up in claimer - bad index: " + idxToCheck + " <-----")
                alert ("We're sorry. Currently, we only allow names with the letters 'A' through 'T'. Please re-start.")
                throw new Error("Was either due to upper-case, OR because we currently limit to character a-t.")
            }

            // check that scripthash for spend

            if ( latestTx.outputStates[idxToCheck] === null ) {
                console.error("hmm. outputStates[" + idxToCheck + "] is null?")
                alert("READ THE LOGS. outputStates[" + idxToCheck + "] is null")
                break;
            }

            //NOTE: 2nd param is the output state OF THE output we chose to follow
            const nextTx = await findNextTx(db,
                                            latestTx.outputStates[idxToCheck],
                                            latestTxId,
                                            idxToCheck);

            if ( this.state.jobCanceled ) {
                console.warn("    whoops. There was a cancel request during search iteration")
                break;
            }

            // nextTxId
            if ( nextTx !== null ) {
                progressFunc("We found the next tx: " + nextTx.substring(0,16) + "...")
                console.warn("    GOT SOMETHING: ", nextTx)
                latestTxId = nextTx
                latestTx = await queryFunction(nextTx, db, true)
                console.warn("    READY for next iteration. - to " + nextTx)

                ourIterations = ourIterations + nextTx + "... "
            } else {
                progressFunc("That's as far as we can go.")
                console.warn("    We've gone as far as we can go for now")
                break
            }

            progressFunc(<> &nbsp; &nbsp; We got its info (it's limb {latestTx.limbName})</>)

            // job canceled?
            if ( this.state.jobCanceled ) {
                // progressFunc()  <-------
                console.warn("    whoops. detected a cancel request while querying Tx Provider")
                break;
            }

            // if NOT spent, we'll fall short. break out of loop

            // if spent, get that tx

            curLength++

            console.log("  bottom of loop. curLength: " + curLength + ", thing: ", target)
            console.log("  btw: latest txid is ", nextTx)
            console.log("  its address: ", latestTx.address)
        } while (curLength < target.length);

        console.warn("walkTheAppends(): DONE. Now, look at output 0 IF we've reached our destination.")
        console.warn("walkTheAppends(): ourIterations: " + ourIterations)
        //IF we fall SHORT, show this to the user, and let them know

        return {
            txId: latestTxId,
            tx: latestTx
        }
    } // walkTheAppends()

    // This is the ProgressListModal's registered ourWorkToDo() function
    // It's called-back by the ProgressListModal
    // The three middle parameters are pLM functions that WE'LL call-back if/when needed
    async doWork(parent, progressFunc, finishCanceling, doneFunc, target, plmResetFunction) {

        //NOTE: hold onto this in case we later want to reset the plm
        this.plmResetFunc = plmResetFunction

        this.setState({jobCanceled: false})
        console.warn("doWork() on target limb: " + target)

        // do work in several steps
        // After each step, send a progress report, and check for cancel

        let latestTx = null
        let latestTxId = ''


        // travel there via appends, as far as we can
        progressFunc("Will now query DB to get closest to the requested limb...")

        const db = await openDB()

        // this now searches the txotab
        // It returns a simple record of the closest tx - not the full decoded tx
        let closest = await getClosestToLimb( db, target )
        console.warn("Claimer: results of getting close: ", closest)

        // owner, tx, limb are the relevant fields
        //   - if owner is 0, it's not yet claimed
        //   - if limb is short, we need to go further
        //     (though, it may have yet to be built further)
        //   - tx is from where we'll make our next move

        // This shouldn't happen anymore - since componentDidMount()
        // already checks for this, and corrects it
        if ( closest === null ) {
            // this means we need to start at the appends, starting with the root/genesis tx

            console.warn("Claimer: doWork() OK. Let's start with the genesis tx...")

            // first append fan-out
            const genesisTx = GENESIS_APPEND

            // Maybe we just need to 'walk the appends', starting at the genesis tx
            closest = {
                limb: '@',  // this will be chopped to '', just below
                owner: null,
                txid: genesisTx
                // which .outIndex? Well, maybe we really shouldn't be here.
                // MAYBE we should always start by querying the genesis (actually, the ebfras too)   <------ YES. otherwise a brand-new user can't get anywhere
            }

            alert("FIXME: if we're here, maybe we need to prime the pump - perform an INITIAL query - of the root/genesis tx - at the least. MAYBE even an EXTRA loop too (26 outputs)?")
            throw new Error("55330: CODING ERROR: must perform initial tx-provider query on the genesis/root tx first")
        }


        // If limb is short (as expected), we've got further to go
        // AND not all (or any) of it may have been built

        if ( closest.limb.length > target.length ) {
            console.warn("doWork(): we've reached limb " + closest.limb
                    + ", and it's puzzling that we had to look so hard. owner: " + closest.owner)
            throw new Error("55331: CODING ERROR: findLatestContinue should have found this, right?")
        }



//FIXME: maybe have getClosestToLimb() strip off that final .limb char?


        //// VERY IMPORTANT, now that we're using txotab for closestToLimb(), remove final char from .limb
        //// That's because we got it from a UTXO. The limb of the tx itself is one character shorter
        console.warn("doWork(): NOTE: even though getClosestToLimb claims we got to limb " + closest.limb + ", we need to REMOVE that final char")

        const walkAppendsUsingProviderStartingFromLimb = closest.limb.substring(0, closest.limb.length - 1 )


        console.warn("doWork(): got back limb " + walkAppendsUsingProviderStartingFromLimb
                    + ", owner " + closest.owner + ", txid: ", closest.txid + "  - OUTPUT INDEX " + closest.outIndex)
        progressFunc("Having consulted our idb, we got as far as: '" + walkAppendsUsingProviderStartingFromLimb + "'")


        if ( walkAppendsUsingProviderStartingFromLimb === target ) {

            alert("X: target " + target + "  We got there using getClosestToLimb. Was it claimed? Look closely. Test both cases: claimed, and not claimed <-----")
            throw new Error("IMPLEMENT look closely at result")

        }

        console.warn("doWork(): target: " + target + ". We're still short, having reached " + walkAppendsUsingProviderStartingFromLimb)
        console.warn("          The trail MIGHT extend further, unbeknownst to us")
        console.warn("          We'll now query the DB for txtab structure of latest TxId " + closest.txid)

        // we need to attempt to travel further
        // Present the FURTHEST to the user

        latestTxId = closest.txid
        // query the PROVIDER
        // We should get the latest status of the next output of concern
        //   (to see if it's spent or not)
        //FUTURE: we're not necessarily interested in EVERY output of this tx
        //        we could have an option to check the particular output,
        //        and not update the DB
        //FIXME: rather than perform this query, shouldn't we just 'walk the appends'?


        //HEADS UP: using qFDTx() 4th param (target optimization)
        //TODO: re-evaluate if this is a good idea
        latestTx = await queryFunction(latestTxId, db, true, target)

        console.warn("doWork().")
        console.warn("doWork()..")
        console.warn("doWork()...")
        console.warn("Back from tx query. Here's the latestTx: ", latestTx)

        const outIndexBuildable = latestTx.buildable[ closest.outIndex ]
        console.warn("    outIndexBuildable: ", outIndexBuildable)
        let isBuildable = outIndexBuildable === closest.outIndex
        let shouldWalkTheAppends
        let nextOutIndex
        // The next step to take is based on the status of the 'next' output we're considering.
        // We found that output having called getClosestToLimb() (a DB query), and then
        // refreshed its state by querying our provider - via queryFunction(, , true)
        if ( isBuildable ) {
            shouldWalkTheAppends = false  // there's nothing to walk. It's hasn't been built-out yet

            console.log(" index " + closest.outIndex + " IS buildabled  - of txid " + latestTxId + " - so, we WON'T 'walk the appends'")
            // NOT YET SPENT
            nextOutIndex = closest.outIndex
        } else {
            shouldWalkTheAppends = true   // It's been built-out (spent). Let's walk them (iteratively query the tx-provider)

            console.log(" index " + closest.outIndex + " is NOT buildabled  - of txid " + latestTxId + " - so, will WALK")

            progressFunc("We'll now query our Tx Provider to see if we can inch closer to the target...")

            progressFunc("We'll start from closest limb (which our DB knew of): " + walkAppendsUsingProviderStartingFromLimb)
            progressFunc("  limb length: " + walkAppendsUsingProviderStartingFromLimb.length)

            // this will query the tx-PROVIDER, to walk the appends (in case our DB is out-dated on tree progress)
            const appendRes = await this.walkTheAppends(latestTxId, latestTx, walkAppendsUsingProviderStartingFromLimb, target, progressFunc)

            latestTxId = appendRes.txId
            latestTx   = appendRes.tx
            console.warn("We got as far as txid " + latestTxId)
            console.warn("Its address is " + latestTx.address)
            console.warn("Its limbName is " + latestTx.limbName)
            console.warn("the tx structure: ", latestTx)

            // We're no longer sure upon which output we should build on next
            // Let's figure that out now

            // If we didn't reach the target:
            //   target:                ZEBRA
            //   got as far as:         ZEBR
            //   build on output that has 'R'

            // If we DID reach the target:
            //   target:    ZEBRA
            //   reached:   ZEBRA
            //   build on output 0 - the mode 'P' output

            if ( latestTx !== null && latestTx.limbName !== target) {
                console.warn("Having WALKED (querying tx-provider) we're not there yet")
                const reachedLength = latestTx.limbName.length
                const characterUponWhichToBuild = target[ reachedLength ]
                console.warn("A Having walked not far enough, we'll build on output with character "
                        + characterUponWhichToBuild)
                const addOneToOutputIdx = reachedLength < 1 ? 0 : 1
                nextOutIndex = characterUponWhichToBuild.charCodeAt(0) - 97 + addOneToOutputIdx
                console.warn("that output index is " + nextOutIndex)
            } else if ( latestTx !== null && latestTx.limbName === target) {
                console.warn("Having WALKED (querying tx-provider) we've arrived at target. Will build on output 0")
                nextOutIndex = 0
            } else {
                alert("ERROR: latestTx is null? Something's wrong. txid: ", latestTxId)
                throw new Error("Invalid tx structure (null). latestTxId = " + latestTxId)
            }
            progressFunc("Having walked the appends (querying provider), and reaching limb "
                        + latestTx.limbName
                        + ", we'll now consider output "
                        + nextOutIndex + " (base-0) of tx " + latestTxId)
        }

        let found = false
        let owned = false

        if ( !this.state.jobCanceled ) {
            // If we're starting from the genesis tx, the limbName is misleading/wrong (reported as 'a')
                                                                        // not sure if/why latestTx would be null, but, playing it safe
            const latestLimbName = latestTxId === GENESIS_APPEND ? "" : (latestTx !== null ? latestTx.limbName : '')
            if ( latestTx !== null && latestLimbName !== target) {   // Not there yet?

                // we're not there yet

                progressFunc("Having queried our Tx Provider, we got as far as '" + latestLimbName + "'")

                // if we didn't walk the appends, the isBuildable variable is still relevant
                const isBuildableIsCurrent = !shouldWalkTheAppends

                if ( latestLimbName.length === (target.length - 1) ) {

                    progressFunc("We're ONE character short. We THINK we just need to BUILD+Pre-CLAIM.")

                    if ( isBuildableIsCurrent ) {
                        console.warn("BTW: isBuildable on outIndex " + closest.outIndex + "? " + isBuildable)

                        if ( isBuildable ) {
                            // BUILD + PRE-CLAIM

                            await this.processSearchResults(latestLimbName,
                                                            latestTxId,
                                                            true, false,
                                                            'RESULT 1: Almost there: just Build+CLAIM it!',
                                                            nextOutIndex,
                                                            false)

                            found = true
                            owned = false
                        } else {
                            alert("not buildable on 1-char short. It's probaly claimed. STOP, "
                                    + "and report this. Didn't think we could get here - target " + target)
                            found = true
                            owned = true
                            //FIXME: might want final param - outIndex
                            await this.processSearchResults(latestLimbName,
                                                            latestTxId,
                                                            true, true,
                                                            'RESULT 2: Almost there, but it is owned, we think',
                                                            nextOutIndex,
                                                            false)
                        }
                    } else {
                        console.warn("One character short. We've tried 'walking the appends'.")

                        //FIXME: might want final param - outIndex
                        await this.processSearchResults(latestLimbName,
                                                        latestTxId,
                                                        true, false,
                                                        'RESULT 3: Almost there: just CLAIM it! <--- we think outIndex ' + nextOutIndex,
                                                        nextOutIndex,
                                                        false)
                            // build on index of FINAL character

                        found = true
                        owned = false
                    }

                } else {
                    progressFunc("You'll need to BUILD one or more nodes beyond this, and then CLAIM.")

                    //xxxx 0
                    // setup parameters for loop
                    this.setupBuildLoop(latestTxId, latestTx, nextOutIndex)

                    await this.processSearchResults(latestLimbName,
                                                    latestTxId,
                                                    false, false,
                                                    'RESULT 4: Partial success. build further',
                                                    nextOutIndex,
                                                    false)

                    found = false
                    owned = false
                }

            } else if ( latestTx !== null && latestTx.limbName === target) {

                // we've reached the target
                found = true

                console.warn("A: limb matches target. we walked the appends? " + shouldWalkTheAppends)
                const thisFinalOutputIsBuildable = latestTx.buildable[ 0 ] === 0
                if (thisFinalOutputIsBuildable ) {
                    //alert("THe zeroeth output of this tx (" + latestTxId + ") is buildable, SO, we CAN CLAIM IT!")
                    console.warn("THe zeroeth output of this tx (" + latestTxId + ") is buildable, with mode: "
                            + latestTx.outputStates[0].mode)
                    if ( latestTx.outputStates[0].mode === '50' ) {  // P available
                        console.warn("  zeroeth output has mode P, so, claimable")
                        owned = false
                    } else if ( latestTx.outputStates[0].mode.toLowerCase() === '4b' ) {  // K - already claimed
                        console.warn("  zeroeth output has mode K, so, already-claimed (pre-claimed)")
                        owned = true
                    } else {
                        alert("  zeroeth output has mode " + latestTx.outputStates[0].mode + ". WHAT NOW? Take note.")
                        throw new Error("Coding ERROR: taked note for path " + target)
                    }

                } else {
                    console.warn("THe zeroeth output of this tx (" + latestTxId + ") is NOT buildable, SO, it's alreeady OWNED")
                    owned = true
                }

                if ( owned ) {
                    // OWNED ASSET?
                    progressFunc("match. walked: Reached it. Asset is OWNED. BUT, maybe it's expired. Let's check beyond txid " + latestTxId)

                    //BUT, we should check if it's EXPIRED
                    // We need to attempt to "Walk the Quarterlies"
                    //This was copied from shizzleView.js
                    const finalQuarterlyRes = await this.tryGoingFurtherInQuarterlies( latestTxId, progressFunc )

                    console.warn("Claimer doWork(): after TRYING to go further in quarterlies, latestTxId is: ", latestTxId)
                    latestTxId = finalQuarterlyRes.txId

                    //FIXME: might want final param - outIndex
                    await this.processSearchResults(target,
                                                    latestTxId,
                                                    true, true,
                                                    'RESULT 5: failed. already-owned',
                                                    nextOutIndex,
                                                    false)

                    //found = true
                    //owned = true
                    console.warn("found and owned? double-check this")

                } else {
                    // UN-OWNED ASSET
                    progressFunc("A: Asset is UN-OWNED. You Could claim it.")

                    //NOTE: we only arrive here if building on mode 'P' (simply claim it)
                    await this.processSearchResults(target,
                                                    latestTxId,
                                                    true, false,
                                                    'RESULT 6: success',
                                                    nextOutIndex, // always 0. We're talking about mode P
                                                    true) //building on mode P. Distinguishes case 1 from 6
                    //found = true
                    //owned = false
                }
            } else {

                //FIXME: check if latestTx is null?

                // UN-OWNED ASSET
                progressFunc("B: Asset is UN-OWNED. You Could claim it. (Maybe needs building? Prob. Check this manually")

                //FIXME: might want final param - outIndex
                await this.processSearchResults(latestTx?.limbName,
                                                latestTxId,
                                                false, false,
                                                'RESULT 7: Partial success?',
                                                nextOutIndex,
                                                false)
                alert("STOP. didn't think we could get here. take note of console logging - for target " + target + " Take note of nextOutIndex, and if we would build on a P (doubt it)")

                found = false
                owned = false
            }
        }  // NOT cancelled

        if ( this.state.jobCanceled ) {
            progressFunc("SEARCH WAS CANCELED at some point.")
            finishCanceling(parent)

            this.setState({
                furthestTx: null,
                decodedTx: '',
                found: false,   // implies should build further
                owned: false,
                reachedLimb: '',
                extraVerbage: 'canceled'});
        } else {
            // closes the progressListModal
            doneFunc(parent, "outer we're done")

            //processSearchResults() has already set all of these
            /*
            this.setState({
                furthestTxId: latestTxId,
                furthestTx: latestTx,
                found: found,   // implies should build further
                owned: owned,
                reachedLimb: '',
                extraVerbage: 'canceled'});
            */
        }

        // we SHOULD have already called either called finishCanceling(), or doneFunc()
        console.warn("doWork(): We're either CANCELED, or DONE")
    } // doWork()


    //NOTE: copied DIRECTLY from shizzleView
    async tryGoingFurtherInQuarterlies( latestQuarterly, progressFunc ) {
        console.warn("tryGoingFurtherInQ...(): latestQuarterly: ", latestQuarterly)

        const db = await openDB()
        let latestTx
        let latestTxId = latestQuarterly
        do {

            latestTx = await queryFunction(latestTxId, db, true)

            //NOTE: 2nd param is the output state OF THE output we chose to follow
            const nextTx = await findNextTx(db,
                                          latestTx.outputStates[0],
                                          latestTxId,
                                          0);

            if ( this.state.jobCanceled ) {
                console.warn("    Cancel request during quarterly iteration")
                break;
            }

            const ownerCount = latestTx.outputStates[0].ownerCount
            const qCount     = latestTx.outputStates[0].quarterlyCount

            if ( nextTx == null ) {
                progressFunc("We're done traversing quarterlies. We're at owner #"
                            + ownerCount + ", Quarter #" + qCount)
                console.warn("    We're done traversing quarterlies")
                return {
                    status: 'success',
                    txId: latestTxId,
                }
            }

            progressFunc("We found the next Quarterly. Owner #" + ownerCount
                        + ", Quarter #" + qCount + ",  tx " + nextTx.substring(0,16) + "...")

            console.log("    Next Quarterly: ", nextTx)
            latestTxId = nextTx
            //latestTx = await queryFunction(nextTx, await openDB())
            console.warn("    READY for next quarterly check - on " + nextTx)
        } while (true);

        console.warn("tryGoingFurtherInQuarterlies(): done iterating")
    } // tryGoingFurtherInQuarterlies

    /**
     *
     * @param {*} reachedLimb
     * @param {*} furthestTxId
     * @param {*} found
     * @param {*} owned
     * @param {*} extraWords
     * @param {*} outputIndex
     * @param {*} buildingOnModeP  // boolean distinguishes between building on mode P(true), vs build+claim (mode X)(true)
     */
    async processSearchResults(reachedLimb, furthestTxId, found, owned, extraWords, outputIndex, buildingOnModeP = false) {
        const db = await openDB()
        console.log("pSR(): will query tx " + furthestTxId + "...")

        // Maybe we don't need to query the provider this time
        // We've probably done it very, very recently
        let limb = ''
        const dtx = await queryFunction(furthestTxId, db) //NOTE: had TEMPORARILY changed false to true on 10/25/23. Also in shizzleView.
        if ( dtx !== null ) {

            // Update state with 'results'

            let expired = false
            let newOwnerCount = 1
            if ( owned ) {
                console.warn(" asset is already OWNED, but current height is " + this.props.blockHeight
                            + ", and we should check if the asset has expired. ", dtx)
                if ( dtx.outputStates[0].renewalDeadlineInt < this.props.blockHeight ) {
                    console.warn("We THINK this asset is EXPIRED. User can consider re-claiming it")
                    expired = true
                    newOwnerCount = dtx.outputStates[0].ownerCount + 1
                }
            }

            var txString = JSON.stringify( dtx );
            this.setState({ furthestTxId: furthestTxId,              //FIXME: rename this
                            furthestTxDecoded: txString,             //FIXME: rename this
                            outputIndex: outputIndex,                //FIXME: rename this. Too generic
                            found: found,
                            owned: owned,
                            expired: expired,
                            //registeredToUs: registeredToUs,
                            //weSubscribeToThis: weSubscribeToThis,
                            reachedLimb: reachedLimb,
                            extraVerbage: extraWords,
                            buildingOnModeP: buildingOnModeP,

                            newOwnerCount: newOwnerCount,
                        });

        } else {
            console.error("Where's the decodexTx string?")
            //alert("ERROR: Not sure why we have NO tx to mention")
            throw new Error("IMPLEMENT return results:  hmm. available for claiming?")
        }
    }

    async registerBuiltNode(db, latestTx, newTxId ) {
        console.log("we've build txid " + newTxId + ". Its limbName is " + latestTx.limbName
                    +". WE SHOULD NOW REGISTER THIS AS A BUILT NODE OF OURS")
//FIXME: if .outputStates is undefined? <---
        // dig-out the builder payout address, and builder rabin we used
        const builderPKHs = latestTx.outputStates[0].builderPKHs
        const builderRabins = latestTx.outputStates[0].builderRabins
        const numBuilders = builderPKHs.length

        const newBuilderPKH = builderPKHs[ numBuilders - 1 ]
        const newBuilderRabin = builderRabins[ numBuilders - 1 ]
        console.log("registerBuiltNode(): new builder PKH: " + newBuilderPKH
                    + ",    new builderRabin: " + newBuilderRabin)
        await registerBuiltDomain(db, latestTx.limbName, newBuilderPKH, newBuilderRabin, newTxId)
        //console.warn("registerBuiltNode(): Here's the ownership record we created: ", builtNodeRec)
    }


    //FIXME: this may go away - now that we have expressClaim()

    // ITERATIVE loop processing
    //
    // Delay is over.
    // Shut toast
    // query for new tx
    // RE-CALCULATE
    // Then, call for next iteration:  loopBuildThenClaimAsset()
    //   we do this by setting state to again show the toast
    //   The toast has a callback that will actually call that function,
    //   and build, broadcst the next tx
    async toastLoopPropagateDone(self) {
        console.warn("toastLoopPropagateDone(): delay done. can finally query for new loop tx: " + self.state.loopTxId)

        // this is the ONLY loop state var that's up-to-date:  loopTxId
        // Now we have to re-calculate the others:
        //    loopTxStr
        //    loopTxLimbName
        //    loopNextOutIdx          <---- most important

        const newTxId = self.state.loopTxId
        let latestTx
        try {

            // query provider for the brand-new tx (which we broadcast a few seconds ago)
    //       vvvvvvvvvv
    //FIXME: since WE created it, WE could decode it, and populate the output states (NOT SPENT)
    //       ^^^^^^^^^
            latestTx = await queryFunction(newTxId, await openDB(), true)
            console.warn("Our new decoded loop tx: ", latestTx)

            // close propagation toast
            self.setState({showLoopPropagationToast: false});

        } catch (error) {
            // close propagation toast
            self.setState({showLoopPropagationToast: false});

            console.log('Failed in Claimer::toastLoopPropagateDone()()');
            console.log('Failed on network: ' + NETWORK)
            console.error("stack: " + error.stack);
            //showError(error);    // needed?
            alert("Something went wrong while attempting to retrieve our looping tx. Too QUICK (didn't delay long enough)?")
            return
        }

        const target = self.state.nameToClaim
        let nextOutIndex
        if ( latestTx !== null && latestTx.limbName !== target) {
            console.warn("Having just built a new append (and querying tx-provider) we're not there yet")
            const reachedLength = latestTx.limbName.length
            const characterUponWhichToBuild = target[ reachedLength ]
            console.warn("B Having walked not far enough, we'll build on output with character "
                    + characterUponWhichToBuild)
            const addOneToOutputIdx = reachedLength < 1 ? 0 : 1
            nextOutIndex = characterUponWhichToBuild.charCodeAt(0) - 97 + addOneToOutputIdx
            console.warn("that output index is " + nextOutIndex)

            // add to the domainsBuiltTab - registering the payoutPKH, rabinPKH, and txid
            const db = await openDB();
            await self.registerBuiltNode(db, latestTx, newTxId )


            const dtxString = JSON.stringify( latestTx );

            // Is this the right way to go about it?
            //No: the toast now has a callback when it's MOUNTED

            const loopTxId       = newTxId  // actually, unchanged
            const loopTxStr      = dtxString
            const loopTxLimbName = latestTx.limbName
            const loopNextOutIdx = nextOutIndex
            await self.loopBuildThenClaimAsset(null, null, loopTxId, loopTxStr, loopTxLimbName, loopNextOutIdx)

        } else if ( latestTx !== null && latestTx.limbName === target) {

           // We're done. We should CONGRATULATE, then clean-up: clear all of these state vars, then close modals on OK
            self.setState({loopTxId: '',
                            loopTxStr:      '',
                            loopTxLimbName: '',
                            loopNextOutIdx: -1,
                        })
            //FIXME: check that this also closes other stuff?

            // add to the domainsBuiltTab - registering the payoutPKH, rabinPKH, and txid
            const db = await openDB();
            await self.registerBuiltNode(db, latestTx, newTxId )

            console.warn("B: Done claiming. Will now register domain, maybe mentioning our identity, if forAFriend, and mention...")

            let fromWhich = null
            if ( this.state.forAFriend && this.state.mentionMyDomainInGift ) {
                if ( this.state.justMyList.length > 1 ) {
                    fromWhich = this.state.myDomainToMention
                } else {
                    fromWhich = this.state.justMyList[0].value
                }
                console.error("B: Add new field: fromFriend - with domain " + fromWhich)
            }

            // register the domain we've claimed (record ownership, locally)
            //NOTE: also done for toastLoopPropagateDone
            const domainRec = await registerDomainOfMine(db,
                                                         self.state.nameToClaim,
                                                         this.state.newOwnerCount,
                                                         this.props.blockHeight,      //XXXXX  maybe should use blockHeight var. BUT, DNE
                                                         false,
                                                         this.state.forAFriend,
                                                         null)
            // If we SEND to friend right now, we'll mention from THIS domain/identity
            this.setState({ fromWhich: fromWhich,
                            userClaimedSomething: true,
                            mostRecentClaim: self.state.nameToClaim,
                            mostRecentOwnerCount: this.state.newOwnerCount})

            console.warn("toastLoopPropagateDone(): Here's the ownership record we created: ", domainRec)

            // congratulate user
            self.showRegistrationSuccessModal()
            return
        } else {
            alert("ERROR: latestTx is null? Something's wrong in loop. txid: ", newTxId)
            throw new Error("Invalid tx structure (null) in loop. latestTxId = " + newTxId)
        }

    } // toastLoopPropagateDone()

    async toastPropagateDone(self) {
        const propagatexTxId = self.state.propagatedTxId
        console.warn("toastPropagateDone(): delay done. will finally query for new tx: " + propagatexTxId)

        //FIXME: needed?
        if ( propagatexTxId?.length > 20 ) {

            try {
                const db = await openDB();

                // query provider for the tx
                const decodedTx = await queryFunction(propagatexTxId, db, true)
                console.warn("Our new decoded tx: ", decodedTx)

                // close toast
                self.setState({showPropagationToast: false, propagatedTxId: ''})

                if ( typeof decodedTx === Number ) {
                    alert("ERROR: querying in toastPropagateDone()")
                    throw new Error("Got result " + decodedTx)
                }

                // We'll advance the user citizenLevel once hw'a clicked OK to a SUCCESS modal

                // register the domain (record ownership, locally)
                //NOTE: also done for toastLoopPropagateDone
                if ( self.state.buildingOnModeP ) {
                    console.warn("NOT registering a built node, since we built on a P mode (there was no interim build)")
                } else if ( self.state.expired ) {
                    console.warn("NOT registering a built node, since we re-claimed an expired asset, BUT **NO LONGER** unregistering (in case we already owned it - with a stale ownerCount)")
                        //await unRegisterDomainOfMine( db, self.state.nameToClaim )
                    //await outdateDomainOfMine(db, self.state.nameToClaim, ???)
                    alert("Claimer: IMPLEMENT ME: we need to set the .outdated field in an OLD domainTab entry (if it exists). We no-longer un-register a domainTab when re-claiming. What's the ownerCount?")
                    //NOTE: for now, we'll just .outdate it in the assetManager, and surfer
                } else {
                    console.warn("registering built node, since we did NOT build on a P mode")
                    await self.registerBuiltNode( db, decodedTx, propagatexTxId )
                }

                console.warn("C: Done claiming. Will now register domain, maybe mentioning our identity, if forAFriend, and mention...")

                let fromWhich = null
                if ( this.state.forAFriend && this.state.mentionMyDomainInGift ) {
                    if ( this.state.justMyList.length > 1 ) {
                        fromWhich = this.state.myDomainToMention
                    } else {
                        fromWhich = this.state.justMyList[0].value
                    }
                    console.error("C: Add new field: fromFriend - with domain " + fromWhich)
                }

                const domainRec = await registerDomainOfMine(db,
                                                             self.state.nameToClaim,
                                                             this.state.newOwnerCount,
                                                             this.props.blockHeight,    //XXXXX  maybe should use blockHeight var. BUT, DNE
                                                             false,
                                                             this.state.forAFriend,
                                                             null)
                // If we SEND to friend right now, we'll mention from THIS domain/identity
                this.setState({ fromWhich: fromWhich,
                                userClaimedSomething: true,
                                mostRecentClaim: this.state.nameToClaim,
                                mostRecentOwnerCount: this.state.newOwnerCount
                            })

                console.warn("toastPropagateDone(): Here's the ownership record we created: ", domainRec)

                // congratulate user
                self.showRegistrationSuccessModal()

            } catch (error) {
                console.log('Failed in Claimer::toastPropagateDone()()');
                console.log('Failed on network: ' + NETWORK)
                console.error("stack: " + error.stack);
                //showError(error);    // needed?
                alert("Something went wrong while attempting to retrieve your claimed asset")
            }
        } else {
            alert("ERROR: how did we get here? we have a faulty tx to query for: " + self.state.propagatedTxId)
            //FIXME: throw?
        }

    } // toastPropagateDone

    toggleGifterMentionDomain() {
        this.setState({mentionMyDomainInGift: !this.state.mentionMyDomainInGift})
    }

    handleFromFriendChange(e, v) {
        console.log("handleFromFriendChange() domain (v.value): ", v.value)

        this.setState({myDomainToMention: v.value})
    }

    render() {
        console.warn("claimer  render()")

        // ***  DEVICE-DEPENDENT STYLING  ***
        // For mobile, "detect" landscape screen mode
        const landscape = this.props.isMobile ? window.innerWidth > window.innerHeight : false
        // For mobile, shrink the About, Security, and Glossary modals
        const modalClassName   = this.props.isMobile ?
                                        (landscape ?
                                                "modalMobileLandscape"
                                            :
                                                "modalMobilePortrait"
                                        )
                                    :
                                        ""
        const modalContentClassName = this.props.isMobile ? "modalContentMobile" : ""
        const modalBottomClassName  = this.props.isMobile ? "modalBottomMobile"  : ""


        // NOTE that we no-longer disable claim button just because user already owns the asset.
        //      We ALSO need to check if the asset is EXPIRED
        const disableCheckClaimButton = this.state.nameToClaim.length < 2 || this.state.searchWasInitiated //|| this.state.alreadyOwnedByUser;
        const secondThoughts = <>
                                    <br></br>
                                        Having second thoughts?
                                    <br></br>
                                    <Button field="resetButton"
                                            disabled={this.state.disableBuild}
                                            onClick={this.resetSearch} negative>
                                        Check on a different name
                                    </Button>
                                </>
        // If the name isn't too long, put it in the CLAIM button
        // This calculation is an approximation. It depends on the characters in the name
        const nameLabel =   this.state.nameToClaim.length < 19 ?
                            <>
                                &nbsp;<b style={{color: "yellow", fontSize: "1.3rem"}}>{this.state.nameToClaim.toUpperCase()}</b>
                            </>
                        :
                            <> this name/domain</>
        const domainMention = <>Domain <span style={{fontSize: "1.3rem"}}>'{this.state.nameToClaim.toUpperCase()}'</span> is available!</>

        const additionalPenniesToGift = this.state.additionalPenniesToGift
        const mentionGiftedFunds = this.state.forAFriend ?
                            ( additionalPenniesToGift > 0 ?
                                    <> <b>plus</b> the extra <b style={{fontSize: "1.3rem"}}>{additionalPenniesToGift}¢</b> (in Bitcoin) you're sending to your friend</>
                                :
                                    <></>
                            )
                        :
                            <></>
        const mentionGiftedFunds2 = this.state.forAFriend ?
                            ( additionalPenniesToGift > 0 ?
                                    <><b style={{color:"blue"}}>+{additionalPenniesToGift}¢</b> (<a style={{cursor:"pointer"}} onClick={this.editGiftAmount}>edit amount</a>)</>
                                :
                                    <>(you can also <a style={{cursor:"pointer"}} onClick={this.editGiftAmount}>ADD FUNDS</a>)</>
                            )
                        :
                            <></>

        const charactersShort = this.state.nameToClaim.length - this.state.reachedLimb.length
        const simplePriceToBuild = <div style={{color:"red"}}>This should cost you much less than <b style={{fontSize: "1.3rem"}}>1¢</b> (in Bitcoin)</div>
        const priceIfShort = Math.round(10*charactersShort * this.USPenniesPerLetter)/10
        const totalCost = priceIfShort + additionalPenniesToGift
        const userIsShort = this.props.balancePennies < (totalCost + 1.5)    // recall: ~20000 sats is a penny
        const cantOfferGiftOf5  = this.props.balancePennies < (priceIfShort + 5 + 1.5)
        const cantOfferGiftOf10 = this.props.balancePennies < (priceIfShort + 10 + 1.5)
        const cantOfferGiftOf15 = this.props.balancePennies < (priceIfShort + 15 + 1.5)
        const cantOfferGiftOf25 = this.props.balancePennies < (priceIfShort + 25 + 1.5)
        const cantOfferGiftOf35 = this.props.balancePennies < (priceIfShort + 35 + 1.5)
        const cantOfferGiftOf50 = this.props.balancePennies < (priceIfShort + 50 + 1.5)

        const bestUtxoIsShort = this.props.bestUtxoPennies < (totalCost + 1.5)

        const rabinKeysToBuild  = charactersShort > 1 ?  charactersShort + 2 : 4
        const payoutKeysToBuild = charactersShort > 1 ?  charactersShort + 1 : 3
        const moneyIcon = <Icon size='large' color="purple" name='money bill alternate'/>
        const maybeCantAfford = userIsShort ?
                                <><br></br>Sorry, <b>you don't have enough funds</b> to claim this. You have roughly {this.props.balancePennies}¢ (in Bitcoin)</>
                            :
                                <><br></br><span style={{color:"black"}}>You have roughly {this.props.balancePennies}¢ (in Bitcoin)</span></>
        const maybeNeedConsolidation = bestUtxoIsShort ?
                                <><br></br>Sorry, <b>you need to consolidate your wallet coins</b> to claim this.<br></br>Please open, then click your Wallet icon ({moneyIcon}) in the side-panel.</>
                            :
                                <></>
        const shortOnRabins = this.state.freeRabins < rabinKeysToBuild
        const maybeNeedRabins = shortOnRabins  ?
                                <><br></br>Sorry, you need to generate more Publisher keys<br></br>(click the yellow Wallet icon ({moneyIcon}) on the top left)</>
                            :
                                <></>
        const shortOnPayouts = this.state.freeP2PKHs < payoutKeysToBuild
        const maybeNeedPayouts = shortOnPayouts ?
                                <><br></br>Sorry, you need to generate more Payout keys<br></br>(click the yellow Wallet icon ({moneyIcon}) on the top left)</>
                            :
                                <></>
        const priceToBuild = this.state.found ?
                            <></>
                        :
                            <div style={{color:"red"}}>This will cost you
                                roughly <b style={{fontSize: "1.3rem"}}>{priceIfShort}¢</b> (in Bitcoin)
                                {mentionGiftedFunds}
                                {maybeCantAfford}
                                {maybeNeedRabins}
                                {maybeNeedPayouts}
                                {maybeNeedConsolidation}
                            </div>

        // Removed slower claim functionality (replaced with expressClaim() approach):
        /*
            <Button field="buildAndClaimButton"
                    disabled={this.state.disableBuild || userIsShort || bestUtxoIsShort || shortOnPayouts || shortOnRabins}
                    onClick={this.loopBuildThenClaimAsset} positive>
                Claim {nameLabel}
            </Button>
        */

        // icon sizes: mini tiny small large big huge massive
        const expiredExplainer = <Icon  size='large' style={{color: this.bshzPurple}} name='question circle outline' />
        const popUpExpiredExplainerText = <><b>Expired Names</b><br></br>
                                         A {this.shizzleVerse} domain must be <b>renewed</b> yearly. It's a
                                         simple matter - essentially a special post/message that you
                                         need to make each year as you near the anniversary of your
                                         domain.<br></br>
                                         Failure to do this allows <b>anyone</b> the opportunity to
                                         snag your domain.
                                       </>
        const popupExpiredExplainer = <Popup style={{backgroundColor: this.bshzLightYellow}}
                                            trigger={expiredExplainer} wide="very"
                                            content={popUpExpiredExplainerText}
                                            position="top center"
                                            hideOnScroll/>

        // icon sizes: mini tiny small large big huge massive
        const giftExplainer = <Icon  size='large' style={{color: this.bshzPurple}} name='gift' />
        const popUpGiftExplainerText = <><b>Give the gift of a Shizzle!</b><br></br>
                                         A {this.shizzleVerse} domain is only as good as the friends you can Shizzle with.
                                         Now you can on-board your Shizzle-less friends in a few easy steps.
                                         And you can send them their first few bits of Bitcoin, to get them going,
                                         all in the same seamless few steps.
                                       </>
        const popupGiftExplainer = <Popup style={{backgroundColor: this.bshzLightYellow}}
                                            trigger={giftExplainer} wide="very"
                                            content={popUpGiftExplainerText}
                                            position="top center"
                                            hideOnScroll/>

        const optionalGiftShizzle = this.state.numDomainsOwned > 0 ?
                <>
                    &nbsp; &nbsp; <Checkbox label='Gift-a-Shizzle' checked={this.state.forAFriend} onClick={this.makeThisAGift}/> &nbsp;{popupGiftExplainer}
                    {mentionGiftedFunds2}
                </>
            :
                <></>
        const optionalButtonGiftMention = this.state.forAFriend ? <> &nbsp;for a <span style={{color:"yellow"}}>friend</span>!</> : ""

        // also used by a PopUp, further down
        let claimedList = this.state.claimedList
        let justMyList = this.state.justMyList

        const disableDropdown = !this.state.mentionMyDomainInGift
        const mentionMeColor = this.state.mentionMyDomainInGift ? "blue" : "grey"

        const optionalMyDomainToMentionDropdown = this.state.forAFriend && justMyList.length > 0 ?
                                (justMyList.length > 1 ?
                                        <>
                                            <Dropdown upward placeholder='Which identity should we mention?'
                                                    selection
                                                    disabled={disableDropdown}
                                                    options={justMyList}
                                                    onChange={this.handleFromFriendChange}
                                            />
                                        </>
                                    :
                                        <>
                                            <span style={{color:mentionMeColor}}>{justMyList[0].value}</span>
                                        </>
                                )
                            :
                                <>
                                </>
        const optionalIncludeSenderDomain = this.state.forAFriend ?
                                <><br></br>
                                    Would you like to mention your {this.shizzleVerse} domain name, in the gift? Mentioning
                                    your domain, within the gift, should help your friend find you, once
                                    they're setup in the {this.shizzleVerse}.
                                    <br></br>
                                    &nbsp; &nbsp; <Checkbox label='Mention my domain'
                                                            checked={this.state.mentionMyDomainInGift}
                                                            onClick={this.toggleGifterMentionDomain}/>
                                    &nbsp; &nbsp; {optionalMyDomainToMentionDropdown}
                                </>
                            :
                                <></>

        const disableClaimButton = this.state.disableBuild || userIsShort || bestUtxoIsShort || shortOnPayouts || shortOnRabins
        // We can arrive here under at least two scenarios:
        //   1: need to build+claim (in a single tx) (mode 'X')
        //   6: just need to claim (mode 'P')
        // state buildingOnModeP helps distinguish the cases: 1(false)  6(true)
        const actionButton = this.state.furthestTxId === null || this.state.jobCanceled ?
                            <><b style={{color: "blue"}}>No action to take</b></>
                        :
                            this.state.found ?
                                        (!this.state.owned ?
                                            (this.state.buildingOnModeP ?
                                                <>
                                                    <b style={{color: "blue"}}>{domainMention}</b>
                                                    <br></br>And you can claim it right now.
                                                    <br></br>
                                                    <Button field="claimButton"
                                                            disabled={disableClaimButton}
                                                            onClick={this.justClaimAndPublish} positive>
                                                        Claim {nameLabel}{optionalButtonGiftMention}
                                                    </Button> {optionalGiftShizzle}
                                                    {optionalIncludeSenderDomain}
                                                    <br></br>
                                                    {simplePriceToBuild}
                                                    {secondThoughts}
                                                </>
                                            :
                                                <>
                                                    <b style={{color: "blue"}}>{domainMention}</b>
                                                    <br></br>And you can build+claim it right now.
                                                    <br></br>
                                                    <Button field="claimButton"
                                                            disabled={disableClaimButton}
                                                            onClick={this.buildPlusClaimAsset} positive>
                                                        Claim {nameLabel}{optionalButtonGiftMention}
                                                    </Button> {optionalGiftShizzle}
                                                    {optionalIncludeSenderDomain}
                                                    <br></br>
                                                    {simplePriceToBuild}
                                                    {secondThoughts}
                                                </>
                                            )
                                    :
                                            (
                                                this.state.expired ?
                                                    <>
                                                        Domain <span style={{fontSize: "1.3rem", color: "blue"}}><b>'{this.state.nameToClaim.toUpperCase()}'</b></span> was already claimed, <b>but</b>, it has since <b style={{fontSize: "1.3rem", color: "purple"}}>EXPIRED</b>.
                                                        &nbsp;{popupExpiredExplainer}
                                                        <br></br>So, you can <b style={{color:'blue'}}>RE</b>-claim it right now - as <b style={{color:'blue'}}>owner #{this.state.newOwnerCount}</b>.
                                                        <br></br>
                                                        <Button field="claimButton"
                                                                disabled={disableClaimButton}
                                                                onClick={this.justClaimAndPublish} positive>
                                                            <b style={{color:'blue'}}>RE</b>-Claim {nameLabel}{optionalButtonGiftMention}
                                                        </Button> {optionalGiftShizzle}
                                                        {optionalIncludeSenderDomain}
                                                        <br></br>
                                                        {simplePriceToBuild}
                                                        {secondThoughts}
                                                    </>
                                                :
                                                    <>
                                                        <b style={{color: "blue", fontSize: "1.3rem"}}>Sorry. That name is claimed already.</b>
                                                        <br></br>
                                                        <Button field="resetButton"
                                                                onClick={this.resetSearch} negative>
                                                            Ok. Let's try a different name
                                                        </Button>
                                                    </>
                                            )
                                        )
                                :
                                    <>
                                        <b style={{color: "blue"}}>{domainMention}</b>
                                        <br></br>And you'll help build-out a section of the ShizzleTree if you claim it.
                                        <br></br>
                                        <Button field="expressClaimButton"
                                                        disabled={disableClaimButton}
                                                        onClick={this.expressClaim} positive>
                                                    Claim {nameLabel}{optionalButtonGiftMention}
                                        </Button> {optionalGiftShizzle}
                                        {optionalIncludeSenderDomain}
                                        <br></br>
                                        {priceToBuild}
                                        {secondThoughts}
                                    </>

        const dontShowResults = this.state.furthestTxId === null || this.state.jobCanceled

        const resultsDisplay =  dontShowResults ?
                                //FIXME: ponder showing DISABLED buttons at this point
                                <></>
                            :
                                <>
                                    <br></br>

                                    {actionButton}
                                </>

        const maybeInitialToast = this.state.showDbInitializationToast ?
                <>
                    <Toast displayText={"Initializing Database..."}/>
                </>
            :
                <></>

        //FIXME: experiment with decreasing this from 7000
        //       And note if testnet is less forgiving that mainnet
        //       6500 seemed fine
        //       6100 may have been too fast
        const maybePropagateToast = this.state.showPropagationToast ?
                <>
                    <Toast
                            displayText={"Waiting for transaction to propagate..."}
                            durationMillis={6400}
                            done={this.toastPropagateDone}
                            parent={this}
                    />
                </>
            :
                <></>

        const maybeBuildingToast = this.state.showBuildingToast ?
                <>
                    <Toast displayText={<>Please wait while we <b>{this.state.claimOperation}</b> a
                                        transaction... <span style={{color:"blue"}}>
                                            (</span>{this.state.claimProgress}<span style={{color:"blue"}}>)
                                            </span><br></br>(longer names may take longer)</>}
                    />
                </>
            :
                <></>

        const maybeLoopPropagateToast = this.state.showLoopPropagationToast ?
                this.state.limbWhichIsPropagating === this.state.nameToClaim ?
                    <>
                        <Toast
                                displayText={"We've built the TARGET asset '" + this.state.nameToClaim.toUpperCase()
                                            + "'. Just waiting for this final transaction to propagate..."}
                                durationMillis={6400}
                                done={this.toastLoopPropagateDone}
                                parent={this}
                        />
                    </>
                :
                    <>
                        <Toast
                                displayText={"We've built interim asset '" + this.state.limbWhichIsPropagating.toUpperCase()
                                            + "'. Now waiting for the transaction to propagate..."}
                                durationMillis={6400}
                                done={this.toastLoopPropagateDone}
                                parent={this}
                        />
                    </>
            :
                <></>

        // icon sizes: mini tiny small large big huge massive
        const giftExplainer2 = <Icon  size='large' style={{color: this.bshzPurple}} name='gift' />
        const popUpGiftExplainerText2 = <><b>Gifting Domains</b><br></br>
                                         You can claim a {this.shizzleVerse} domain for a friend,
                                         and send it to them (and even some Bitcoin) in an email.
                                         Once they redeem it you two can begin communicating through
                                         the {this.shizzleVerse}!
                                       </>
        const popupGiftExplainer2 = <Popup style={{backgroundColor: this.bshzLightYellow}}
                                            trigger={giftExplainer2} wide="very"
                                            content={popUpGiftExplainerText2}
                                            position="top center"
                                            hideOnScroll/>
        const optionalGiftColor = this.state.claimMore || this.state.numDomainsOwned === 0 ? "#FFAAFF" : "purple"
        const optionalGiftVerbage = this.state.numDomainsOwned > 0 ?
                                    <>, OR,<br></br>
                                        <span style={{color:optionalGiftColor}}>even claim one for a friend?</span>
                                        &nbsp; {popupGiftExplainer2}
                                    </>
                                :
                                    <></>

        const maybePluralDomains = this.state.numDomainsOwned > 1 ? <>domains</> : <>domain</>
        const domainsOwnedListLink = <a>{this.state.numDomainsOwned} {maybePluralDomains}</a>

        const domainsOwnedList = <>
                                    <b>Your Domains/Identities/Sites</b>
                                    <Segment raised style={{overflow: 'auto', maxHeight: 220, backgroundColor:this.bshzLightYellow }}>
                                        <List selection bulleted items={claimedList} style={{backgroundColor:this.bshzLightYellow}}/>
                                    </Segment>
                                </>
        const domainsOwnedListPopup = <Popup style={{backgroundColor: this.bshzLessLightYellow}}
                                             trigger={domainsOwnedListLink} wide="very"
                                             on='hover'
                                             hoverable
                                             content={domainsOwnedList}
                                             position="bottom center"
                                            />

        const topHalfMaybeBlueColor = this.state.claimMore || this.state.numDomainsOwned === 0 ? "#BBBBFF" : "blue"
        const dontBotherOption = this.state.numDomainsOwned > 0 && dontShowResults ?
                    <div>
                        <p style={{color:topHalfMaybeBlueColor}}>
                        We notice, however, that you've already claimed {domainsOwnedListPopup}.
                        </p>

                        <b style={{color:topHalfMaybeBlueColor}}>Would you like to claim/own another domain{optionalGiftVerbage}</b>
                        <br></br>

                        <Button content="YES" disabled={this.state.claimMore}
                            onClick={this.handleClaimMore} positive>
                        </Button>
                        <Button content="NO" disabled={this.state.claimMore}
                            onClick={() => this.handleNoClaim(true)} negative>
                        </Button>
                        <br></br>
                        <br></br>
                    </div>
                :
                    <>
                    </>

        const claimMoreColor = (this.state.claimMore || this.state.numDomainsOwned === 0) && !this.state.expired ? "black" : "#BBBBBB"
        const maybeAlreadyOwned = this.state.alreadyOwnedByUser ?
                                    <> &nbsp;<b style={{color: "red", fontSize: "1.3rem"}}>You already own this</b></>
                                :
                                    <><span style={{fontSize: "1.3rem"}}>&nbsp;</span></>
        const optionalClaim =
                < div style={{color:claimMoreColor}}>
                    <p>
                        Please enter the name of the account/domain/name that you'd
                        like to claim. Note that longer names, generally, cost more to claim
                        (but they're all pretty cheap).
                    </p>
                    <Input  disabled={this.state.searchWasInitiated || !this.state.initializedDB || (!this.state.claimMore && this.state.numDomainsOwned > 0)}
                            value={this.state.nameToClaim} style={{width:'300px'}}
                            placeholder="Enter a domain you'd like to claim"
                            onChange={this.handleNameChange} autoFocus>
                        <input style={{borderRadius: '50px'}} />
                    </Input> &nbsp; ({this.state.nameToClaim.length} chars)
                    <br></br>
                    <p>
                    Names can have characters A-Z, 0-9, and dash, but cannot begin
                    with dash, nor a number. They must be at least 2 characters long.
                    At this time we're accepting names up to {this.maxDomainLength} characters long.
                    Don't worry about upper-case or lower-case - it's all the same.
                    </p>
                    <Button disabled={disableCheckClaimButton} field="searchButton"
                            onClick={this.checkOnClaim} positive>
                        Check if this name is available
                    </Button>
                </div>

        const whatCanYouDoNow = this.state.forAFriend ?
                            <>
                                <p> You must now <b style={{color:"blue"}}>send</b> this domain to your friend, so
                                    that they can enjoy all of the benefits of
                                    the {this.shizzleVerse}.
                                </p>
                                <p>
                                    Once your friend accepts your gift, and completes
                                    the final step, you can begin communicating with them through the {this.shizzleVerse}.
                                </p>
                                <Button onClick={this.launchGifter} positive>
                                    Let's SEND this gift to my friend right now
                                </Button>
                            </>
                        :
                            <>
                                <p>You can now use this domain/name/identity to:</p>
                                <ul style={{margin: "5px 0px 15px 0px"}}>
                                    <li>Post/Tweet/Blog on your own Bitcoin account</li>
                                    <li>Comment directly on the postings of others</li>
                                    <li>Subscribe to the feeds of other 'Shizzlers'</li>
                                    <li>Take notes (encrypted postings to yourself)</li>
                                    <li>Publish arbitrary smart contracts</li>
                                    <li>Converse directly with other 'Shizzlers'</li>
                                    <li>Build your own Bitcoin website</li>
                                    <li>Sell your account/domain/name (after one year)</li>
                                </ul>
                            </>

        const optionalGifter = this.state.showGifter ?
                                <>
                                    <GifterModal closeMe={this.closeGifter}
                                        pkhUsed={this.state.keyUsedToClaim}
                                        pUsed={this.state.pToEnc}
                                        qUsed={this.state.qToEnc}
                                        domain={this.state.nameToClaim}
                                        fromWhich={this.state.fromWhich}
                                    />
                                </>
                            :
                                <></>

        // Keep user moving forward if gifting to a friend. IOW: don't encourage
        // user to stop the gift-giving process mid-way
        const successOkButton = this.state.forAFriend ?
                        <>
                        </>
                    :
                        <>
                            <div style={{textAlign: 'center'}}>
                                <Button positive onClick={this.handleRegistrationSuccessModalOk} content='OK'/>
                            </div>
                        </>

        const giftType = this.state.giftType
        const includeFundsButton = giftType === 0 ?
                <>No, don't include any funds</>
            :
                <>Yes, include some satoshis</>
        const includeFundsButtonPositive = giftType === 0 ?
                false
            :
                true
        const includeFundsButtonNegative = !includeFundsButtonPositive

        const includeFundsText = cantOfferGiftOf5 ?
                    <>
                        <p>
                            Sorry, but it looks like you currenty don't have additional
                            funds to bundle with the domain you're sending to your friend.
                        </p>
                    </>
                :
                    <>
                        <p>
                            Would you like to <span style={{color:"blue"}}>include some funds</span> with the domain you're sending
                            to your friend?
                            <br></br>
                            <br></br>
                            Bundling satoshis with your gift will help get your friend off and
                            running quickly - without having to worry about how to get their
                            hands on Bitcoin. And it can save you having to hold their hand.
                            Even <span style={{color:"blue"}}>5¢-worth</span> could last them
                            a while in the {this.shizzleVerse},
                        </p>
                    </>
        const additionalSatoshis = this.state.optionalAdditionalBalance

        const bitcoinSymbol = '\u20BF'
        //WARNING: on chrome, bitcoin symbol seems to only work with fontSizes: 1.2rem, 1.8rem, 16px
        //         though, commonReact's getBitcoinDomainName() has success with other sizes
        const userDomainSmall = <>
                            <b style={{color:this.bshzPurple, fontSize:"1.2rem", verticalAlign:"5%"}}><span style={{verticalAlign:"-5%"}}>{bitcoinSymbol}</span>@</b>
                            <b style={{color:"blue", fontSize:"1.3rem"}}>{this.state.nameToClaim.toLowerCase()}</b>
                        </>

        const topHalfColor = this.state.claimMore || this.state.numDomainsOwned === 0 ? "#BBBBBB" : "black"
        return(
                <>
                <Modal size='small' centered className={modalClassName}  open={true} style={{backgroundColor: this.bshzPurple, borderRadius: '20px'}}>
                <Modal.Header style={{textAlign: 'center', backgroundColor: this.bshzPurple, borderRadius: '20px'}}>
                    <span style={{color: this.bshzYellow}}> Claim a Bitcoin Name </span>
                </Modal.Header>
                <Modal.Content className={modalContentClassName} scrolling style={{backgroundColor: this.bshzLtPurp}}>

                    <p style={{color:topHalfColor}}>
                        Now that you've got a sufficiently-funded wallet, it's time
                        to lay claim to your very own piece of the {this.shizzleVerse}.
                    </p>
                    <div>
                        {dontBotherOption}
                    </div>

                    {optionalClaim}

                    {resultsDisplay}
                </Modal.Content>
                <Modal.Actions className={modalBottomClassName} style={{backgroundColor: this.bshzPurple, borderRadius: '0px 0px 20px 20px'}}>
                    <div style={{textAlign: 'center'}}>
                        <Button negative onClick={this.handleCloseThisClick} content='Cancel'/>
                    </div>
                </Modal.Actions>
                </Modal>

                    {maybeInitialToast}
                    {maybeBuildingToast}
                    {maybePropagateToast}
                    {maybeLoopPropagateToast}
                    <ProgressListModal
                            fleeting={true}
                            openProgressList={this.state.openTheProgressList}
                            domain={this.state.nameToClaim}
                            ourWorkToDo={this.doWork}
                            onCancel={this.workCanceled}
                            onDone={this.closeProgressList}
                            title={this.state.claimSearchTitle}
                            description="Since we currently operate in a proof-of-concept 'Serverless' mode, this will require a few steps..."/>


                    <Modal size='small' centered className={modalClassName}  open={this.state.showRegistrationSuccess} style={{backgroundColor: this.bshzPurple, borderRadius: '20px'}}>
                        <Modal.Header style={{textAlign: 'center', backgroundColor: this.bshzPurple, borderRadius: '20px'}}>
                            <span style={{color: this.bshzYellow}}> Registration Success! </span>
                        </Modal.Header>
                        <Modal.Content className={modalContentClassName} scrolling style={{backgroundColor: this.bshzLtPurp}}>

                            <p>
                                You've successfully claimed domain {userDomainSmall} (as <b style={{color:'blue'}}>owner #{this.state.newOwnerCount}</b>)
                                {this.state.forAFriend ? <> - for a <b style={{color:"purple"}}>friend</b></> : <></>}.
                            </p>

                            {whatCanYouDoNow}

                        </Modal.Content>
                        <Modal.Actions className={modalBottomClassName} style={{backgroundColor: this.bshzPurple, borderRadius: '0px 0px 20px 20px'}}>
                            {successOkButton}
                        </Modal.Actions>
                    </Modal>

                    <Modal size='small' centered className={modalClassName}  open={this.state.showPennyPitchModal} style={{backgroundColor: this.bshzPurple, borderRadius: '20px'}}>
                        <Modal.Header style={{textAlign: 'center', backgroundColor: this.bshzPurple, borderRadius: '20px'}}>
                            <span style={{color: this.bshzYellow}}> Add Some Satoshis? </span>
                        </Modal.Header>
                        <Modal.Content className={modalContentClassName} scrolling style={{backgroundColor: this.bshzLtPurp}}>

                            {includeFundsText}
                            <div style={{padding: "0px 10px 0px 10px"}}>
                                <Form>
                                    <Form.Group inline>
                                    <label>Amount to send</label>
                                    <Form.Field
                                        control={Radio}
                                        label='Nothing'
                                        value={0}
                                        checked={giftType ===0}
                                        onChange={this.handleGiftTypeChange}
                                    />
                                    <Form.Field
                                        control={Radio}
                                        label='5¢'
                                        value={1}
                                        checked={giftType === 1}
                                        onChange={this.handleGiftTypeChange}
                                        disabled={cantOfferGiftOf5}
                                    />
                                    <Form.Field
                                        control={Radio}
                                        label='10¢'
                                        value={2}
                                        checked={giftType === 2}
                                        onChange={this.handleGiftTypeChange}
                                        disabled={cantOfferGiftOf10}
                                    />
                                    <Form.Field
                                        control={Radio}
                                        label='15¢'
                                        value={3}
                                        checked={giftType === 3}
                                        onChange={this.handleGiftTypeChange}
                                        disabled={cantOfferGiftOf15}
                                    />
                                    <Form.Field
                                        control={Radio}
                                        label='25¢'
                                        value={4}
                                        checked={giftType === 4}
                                        onChange={this.handleGiftTypeChange}
                                        disabled={cantOfferGiftOf25}
                                    />
                                    <Form.Field
                                        control={Radio}
                                        label='35¢'
                                        value={5}
                                        checked={giftType === 5}
                                        onChange={this.handleGiftTypeChange}
                                        disabled={cantOfferGiftOf35}
                                    />
                                    <Form.Field
                                        control={Radio}
                                        label='50¢'
                                        value={6}
                                        checked={giftType === 6}
                                        onChange={this.handleGiftTypeChange}
                                        disabled={cantOfferGiftOf50}
                                    />
                                    </Form.Group>
                                </Form>
                            </div>
                            <br></br>
                            We'll include <span style={{color:"blue"}}>{additionalSatoshis} satoshis</span> with
                            the gift to your friend<span style={{color:"blue"}}>{additionalSatoshis > 0 ? " (from your wallet)" : ""}</span>.

                        </Modal.Content>
                        <Modal.Actions className={modalBottomClassName} style={{backgroundColor: this.bshzPurple, borderRadius: '0px 0px 20px 20px'}}>
                            <div style={{textAlign: 'center'}}>
                                <Button positive={includeFundsButtonPositive}
                                        negative={includeFundsButtonNegative}
                                        onClick={this.closePennyPitchModal}
                                        content={includeFundsButton}/>
                            </div>
                        </Modal.Actions>
                    </Modal>

                    {optionalGifter}
                </>
        )
    }
}

export default Claimer;
