"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var getOption_1 = require("./getOption");
function getFacialHairColor(options, random) {
    var facialHairColor = [];
    if (getOption_1.getOption('facialHairColor', 'auburn', options)) {
        facialHairColor.push('Auburn');
    }
    if (getOption_1.getOption('facialHairColor', 'black', options)) {
        facialHairColor.push('Black');
    }
    if (getOption_1.getOption('facialHairColor', 'blonde', options)) {
        facialHairColor.push('Blonde', 'BlondeGolden');
    }
    if (getOption_1.getOption('facialHairColor', 'brown', options)) {
        facialHairColor.push('Brown', 'BrownDark');
    }
    if (getOption_1.getOption('facialHairColor', 'pastel', options)) {
        facialHairColor.push('PastelPink');
    }
    if (getOption_1.getOption('facialHairColor', 'platinum', options)) {
        facialHairColor.push('Platinum');
    }
    if (getOption_1.getOption('facialHairColor', 'red', options)) {
        facialHairColor.push('Red');
    }
    if (getOption_1.getOption('facialHairColor', 'gray', options)) {
        facialHairColor.push('SilverGray');
    }
    return random.pickone(facialHairColor);
}
exports.getFacialHairColor = getFacialHairColor;
