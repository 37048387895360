import {
  analyzeContentPrefixFlag,
} from './buildShizzle.js';

import {
  //encryptMessage,
  decryptContent,
} from './commonReact';


/**
 * Examines the first few chars of the content, for a 'prefix flag'
 * If there is one, it could indicate that the content (which follows the flag)
 * is encrypted. If it is encrypted, it decrypts it, and sets two new fields:
 *     .contentAltered     (bool)
 *     .alteredContent     (decoded hex string)
 *
 * The resulting dtx is stringified, and state.decodedTx is set to this string
 *
 * @param {*} dtx
 * @returns the stringified state
 */
export async function maybeDecryptContent(dtx, getKeyForDecryption = null) {
  console.log("shizzleView maybeDecryptContent()")
  const analysis = analyzeContentPrefixFlag(dtx)
  console.warn("shizzleView maybeDecryptContent(): analysis of content prefix flag: ", analysis)

  let contentEncrypted = false
  if ( analysis.needToDecrypt ) {

      console.warn("shizzleView: maybeDecryptContent(): AH. Will use a parent/surfer callback - so that we might decode this content.")

      if ( getKeyForDecryption !== null ) {

        //FIXME: try/catch?
        const sharedKey = getKeyForDecryption()

        console.log("shizzleView ABOUT to decrypt. Here's the current dtx: ", dtx)

        if ( sharedKey ) {
          const utf8Text = await decryptContent( analysis.contentToPresent, sharedKey )

          if ( !utf8Text ) {
            console.warn("Ah. We didn't actually decode anything. Perhaps this isn't from our world (our dialog, or a domain of ours)")
            console.error("FIXME?: Fix so that we don't even BOTHER to decode this?")

            alert("VIEWER failed content decryption. It's probably not intended for your viewing.")

          } else {
            console.warn("shizzleView maybeDecryptContent():   decrypted utf8-format result: ", utf8Text)

            console.warn("shizzleView: maybeDecryptContent(): did we dynamically get a shared key we can use to finally decrypt? Check console for DECRYPTED content.")

            console.warn("We're ABOUT to use the decrypted post. Checkout dtx for .decodedTx,     .contentAltered, and .alteredContent BEFORE we modify it (ABOVE)")

            //NOTE: logic copied from shizzleNav's handleRetrievedDecryptionKey()

            dtx.contentAltered = true
            dtx.alteredContent = Buffer.from(utf8Text, 'utf8').toString('hex')
            dtxString = JSON.stringify( dtx );
          }

        } else {
          alert("CONTENT NOTE: we asked parent/surfer for a key, but didn't get one back")
        }
      } else {
        alert("CONTENT CODE ERROR: we need to decrypt content, but have no callback with which to obtain the keys.")
      }

      contentEncrypted = true
  } else if ( analysis.contentAttached ) {
    console.warn("BTW: .attachedContent: ", analysis.attachedContent)

    console.log("shizzleView: adding content attachment (len " + analysis.attachedContent.length + ") to dtx (as hex string)")

    dtx.contentAltered = true
    dtx.contentAttached = true
    dtx.attachedContent = analysis.attachedContent
    dtx.alteredContent = Buffer.from( analysis.contentToPresent, 'utf8' ).toString('hex')
  } else if ( analysis.referencedMediaTxId ) {
    console.warn("BTW: .referencedMedaTxId: ", analysis.referencedMediaTxId )

    dtx.referencedMediaTxId = analysis.referencedMediaTxId
    dtx.contentAltered = true
    dtx.alteredContent = Buffer.from( analysis.contentToPresent, 'utf8').toString('hex')
  }

  return {
    dtxString: JSON.stringify( dtx ),
    contentEncrypted: contentEncrypted,
  }
}
