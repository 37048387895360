import React from 'react';

import { Button, Divider, Icon, Input, Modal, Popup, Segment } from 'semantic-ui-react'

import ShizzleView      from './shizzleView';
import WalletImporter   from './walletImporter';
import WalletGenerator  from './walletGenerator';
import WalletVerifier   from './walletVerifier';

import Toast            from './toast';

import { encryptData, decryptData,
         openDB, checkIfDomainExpiredOrRenewable, queryFetchDecodeTx, saveEncryptedKeys,
         getOfficialWalletJson, findAllMyBuiltDomains,
         findAllDomainsOfMine,
         registerDomainOfMine} from './buildShizzle.js';
import { getDecodedTx, } from "./shizzleIDB"
import { execAsync, int2Asm, validateShizzleTree } from './commonFuncs';
import { NETWORK, TARGET_RATE, GENESIS_APPEND, BOUNTY_MULTIPLIER_CONTINUE } from './harnessConstants'

import { bshzColors } from './bshzColors';

// we use these when verifying gifted keys
const { generatePrivKey,
	privKeyToPubKey, privKeyToPubKey2,
	sign, sign2,
	verify,
	simpleHash
} = require("rabinsig");
const { bsv } = require('scryptlib');

class GiftRedeemerModal extends React.PureComponent {
    constructor(props) {
        super(props);

        this.handleModalOk             = this.handleModalOk.bind(this);
        this.decryptKey                = this.decryptKey.bind(this);

        this.handlePinChange           = this.handlePinChange.bind(this);
        this.handleGibberishChange     = this.handleGibberishChange.bind(this);

        this.handleErrorOk             = this.handleErrorOk.bind(this);
        this.handleFindNewDomain       = this.handleFindNewDomain.bind(this);

        this.registerViewerCallback    = this.registerViewerCallback.bind(this);
        this.viewResponseCallback      = this.viewResponseCallback.bind(this);

        this.handleWalletInvestigation = this.handleWalletInvestigation.bind(this);

        this.saveGiftedKeys            = this.saveGiftedKeys.bind(this);

        this.handleGenerateWallet      = this.handleGenerateWallet.bind(this);
        this.handleRestoreOption       = this.handleRestoreOption.bind(this);
        this.closeRestorer             = this.closeRestorer.bind(this);
        this.userHasWallet             = this.userHasWallet.bind(this);
        this.returnBundle              = this.returnBundle.bind(this);
        this.userRemembersPwd          = this.userRemembersPwd.bind(this);

        this.generateFirstTransaction  = this.generateFirstTransaction.bind(this);

        this.showGenericModal          = this.showGenericModal.bind(this);
        this.handleGenericModalOk      = this.handleGenericModalOk.bind(this);

        this.userDeletedWallet         = this.userDeletedWallet.bind(this);
        this.fullResetPerformed        = this.fullResetPerformed.bind(this);


        this.BitSchnitzelLLC     = <>BitSchnitzel LLC</>;
        this.shizzleVerse        = <span style={{color:"blue"}}><b>ShizzleVerse</b><small>&trade;</small></span>;
        this.bitShizzle          = <><b style={{color:"blue"}}>BitShizzle</b><small>&trade;</small></>;
        this.bitShizzleItalic    = <><b>Bit<i>Shizzle</i></b><small>&trade;</small></>;
        this.shizzleVerseNotBold = <>ShizzleVerse<small>&trade;</small></>;
        this.bitShizzleNotBold   = <>BitShizzle<small>&trade;</small></>;

        this.penniesPerSat = this.props.bsvPriceUSD / 1000000

        this.state = {
            furthestQuarterlyTxId: null,  // new var to be more quarterly-aware

            // while these were found to not be properly initialized,
            // we see they're not really intended for use, OR, should be
            // guaranteed to have value when needed :(
            latestTxId: '',
            dtx: '',

            pin: '',
            cyphText: '',

            showBadDecode: false,
            showSuccessModal: false,
            showQueryModal: false,

            domain: '',

            pkhUsed: '',
            pUsed:   '',
            qUsed:   '',

            disableWalletGenerationButton: true,
            foundDomainInGoodState: false,

            showWalletGenerator: false,
            showRestoreModal:    false,

            showVerifier:       false,

            showFinalStep:      false,

            showGenericOkModal: false,
            genModalTitle:      '',
            genModalText:       '',

            showDbInitializationToast: false,

            hasBeenRegistered: false,

            initialSats: 0,
            sentBy: null,
        }
    }

    async componentDidMount() {
    }

    componentWillUnmount() {
        console.log("giftRedeemerModal about to unmount. ")

        // fix Warning: Can't perform a React state update on an unmounted component
        // see: https://stackoverflow.com/questions/53949393/cant-perform-a-react-state-update-on-an-unmounted-component
        this.setState = (state,callback)=>{
            return;
        };
    }

    userDeletedWallet() {
        //alert("You've deleted your wallet. We might now need to do something extra - like allow you to create a NEW wallet")

        this.handleGenerateWallet()
    }

    async fullResetPerformed() {
        //alert("The WalletVerifier performed a full reset. Now what? Implement something - maybe create a new wallet. Maybe START OVER")

        this.props.closeMe( true )  // restart = true
    }

    //FIXME: maybe we don't want this
    handleModalOk() {
        this.props.closeMe()
    }

    handleErrorOk() {
        this.setState({showBadDecode: false})
    }

    async handleFindNewDomain() {

        const db = await openDB()

        // determine if this is a brand NEW user
        // IOW: do we need to query for the genesis tx?
        // IOW: try our DB first. If nothing there, query our tx-provider
        const genesis = await getDecodedTx(db, GENESIS_APPEND)
        if ( genesis === null ) {
            this.setState({showDbInitializationToast: true})

            console.warn("NOTE: brand-new user. We need to prime the pump (query the genesis tx)")
            await queryFetchDecodeTx(GENESIS_APPEND, db, true, null)
        }

        this.setState({showSuccessModal: false, showQueryModal: true, showDbInitializationToast: false})

        // make sure we don't have an OLD genesis (in IDB), while
        // runing a NEW browser which uses a NEWER genesis/root
        // THIS CHECK IS only for during testing
        // Once fully-deployed, we should never update the genesis/root
        validateShizzleTree()
    }

    async decryptKey() {
console.error(" PIN: ", this.state.pin)
console.error("  as hex: ", Number(this.state.pin).toString(16))

        try {
            // decrypt using the hex format of the pin (which is how it was encrypted)
            const plainBundle = await decryptData(this.state.cyphText, Number(this.state.pin).toString(16) )

            //console.warn("useEnteredPasswordToDecrypt(): plainBundle", plainBundle)
            //alert("useEnteredPasswordToDecrypt(): we were able to decrypt wallet blob!")

            //console.log("here is the decrypted string: ", plainBundle)
            const contents = JSON.parse(plainBundle)
            //console.warn("Here's the json-parsed structure: ", contents)
            console.warn("  You've unlocked the blob for gifted domain: " + contents.domain)

            // Verify that the gifted rabin keys match the declared PKH

            const derivedRabinPubKeyBigInt = privKeyToPubKey2(contents.pUsedToClaim, contents.qUsedToClaim)
            const derivedRabinPubKeyLE = int2Asm(derivedRabinPubKeyBigInt)       // used for executing contract - when updating content

            //NOTE: below taken from buildShizzleTx.js
            const derivedRabinPubKeyLEBuffer = Buffer.from(derivedRabinPubKeyLE, 'hex');
            const derivedPKH = bsv.crypto.Hash.sha256ripemd160(derivedRabinPubKeyLEBuffer).toString('hex')

            if ( derivedPKH !== contents.pkhUsed ) {
                alert("Something is very wrong here. The keys gifted by your friend don't seem to match the PKH which they claim they should.")
//FiXME: need an error modal
                return
            }

            console.error("pkhUsed: ", contents.pkhUsed)
            //console.error("pUsedToClaim: ", contents.pUsedToClaim)
            //console.error("qUsedToClaim: ", contents.qUsedToClaim)

            if ( contents.domain.length > 38 ) {
                alert("Your friend tried to gift you a domain that we don't currently support (it's too long). How'd they manage that?")
//FIXME: need an error modal
                return
            }

            if ( contents.domain.length < 2 ) {
                alert("Your friend tried to gift you an invalid domain name (it's too short). How'd they manage that?")
//FIXME: need an error modal
                return
            }

            const n = contents.domain
            // regex check for valid domain name pattern
            const re = /^[a-zA-Z0-9-]+$/;

            if ( n !== '' && (!re.test(n) || n[0] === '-'
                                    || n[0] === '0' || n[0] === '1' || n[0] === '2'
                                    || n[0] === '3' || n[0] === '4' || n[0] === '5'
                                    || n[0] === '6' || n[0] === '7' || n[0] === '8'
                                    || n[0] === '9' ) ) {
                console.log("decryptKey(): Invalid domain name")
                alert("Your friend seems to have tried to gift to you an invalid domain name. How'd they manage that?")
//FIXME: need an error modal
                return
            }

            // we've already verified that p, and q fields exist,
            // AND that they derive pkhUsed. SOON, after walking to the domain,
            // we'll verify it's controled by THAT rabin pkhUsed")

            this.setState({ showSuccessModal: true,
                            domain: contents.domain,
                            pkhUsed: contents.pkhUsed,
                            pUsed: contents.pUsedToClaim,
                            qUsed: contents.qUsedToClaim,
                            sentBy: contents.sentBy,
                            })

        } catch (error) {
            console.warn("Wrong PIN or blob? ", error)
    /*
            this.props.showOkModal("Wrong Password", <>You've entered the <span style={{color: "red"}}>wrong password</span> for
                                                        your wallet, OR, you've selected the <span style={{color: "red"}}>wrong
                                                        file</span>, OR, <span style={{color: "red"}}>you're not using
                                                        https://</span>. Please check, and try again.</>)
    */
            this.setState({pin: '', showBadDecode: true})
            //showImportSwirly: false});
        }

    }

    /**
     * Taken from walletGenerator, this saves AND ALLOCATES the keys received from
     * a friend.
     */
    async saveGiftedKeys(pkh, p, q, pwd) {
        // this is necessary - to serialize a BigInt
        const record = {p: p.toString(), q: q.toString()} //, pkh: thisKey.pkh}
        const recordJSON = JSON.stringify(record);
        const rabinPKH = pkh

        let cyphTxt
        try {
            cyphTxt = await encryptData(recordJSON, pwd);
        } catch (error) {
            alert("Please make sure you're using https://")
            throw error
        }
        console.log("cypher text is ", cyphTxt);

        // save to PUBLISHER rabin table (null variant), allocated (1)
        // Save them as ALLOCATED because our friend ALSO has them
        // We intend to use them but once: to redeem the gift, and
        // make our first 'post' (transaction)
        await saveEncryptedKeys(await openDB(), rabinPKH, cyphTxt, null, 1);
    }

    handlePinChange(e, v) {
        console.log("handlePinChange: pin: " + v.value)
        this.setState({pin: v.value})
    }

    handleGibberishChange(e, v) {
        console.log("handleGibberishChange: gibberish: " + v.value)
        this.setState({cyphText: v.value})
    }

    registerViewerCallback() {
        console.error("registerViewerCallback: IMPLEMENT ME")
    }

    // called-back by shizzleView, when a jump-to-domain completes (successfully, or not)
    async viewResponseCallback(self, found, dtx, latestTxId = null, furthestQuarterlyTxId = null) {

        console.error("viewResponseCallback: found: " + found)
        console.error("viewResponseCallback: latestTxId: ", latestTxId)
        console.error("viewResponseCallback: dtx: ", dtx)

        // NOW: we have to check if it's spent, right? OR, RATHER, just check the address")

        if ( found && dtx ) {
            console.log("-------------")
            console.log("address: ", dtx?.address)
            console.log("mode: " + dtx.outputStates[0].mode)

            if ( dtx.address ) {
                const addressPath = dtx.address.split('/').slice(2).join('/')
                console.log("Look at this: " + addressPath)
                console.log("split length: " + addressPath.split('/').length)
                if ( addressPath.split('/').length === 3 && addressPath.endsWith("/00000") ) {

                    const mode =  dtx.outputStates[0].mode

//FIXME: alternatively, check if < 100?
                    const initialSats = Buffer.from(dtx.outputStates[0].contractSatoshis, "hex").readUIntLE(0,6)
                                            - BOUNTY_MULTIPLIER_CONTINUE

                    const actualPkhUsed = dtx.outputStates[0].ownerRabinPKH

                    if ( mode !== '4b' && mode !== '4B' ) {
                        console.error("Hmm. DOES THIS MAKE SENSE? We think your friend may have given-up on you, and redeemed the domain for themselves. IMPLEMENT some modal suggesting they talk with their friend, OR go through the REGULAR on-ramping.")
                        alert("OR, you're just visiting this gift-redeeming link a 2nd time.")
                        alert("Hmm. DOES THIS MAKE SENSE? We think your friend may have given-up on you, and redeemed the domain for themselves. IMPLEMENT some modal suggesting they talk with their friend, OR go through the REGULAR on-ramping.")
//FIXME: maybe check if user has the key (is here a 2nd time)?
                        return
//FIXME: implement a modal
                    } else if ( actualPkhUsed !== this.state.pkhUsed ) {
                        console.error("Whoops. This domain is controlled by a different key than what was provided by your friend. You cannot unlock this domain.")
                        alert("WHoops. this domain is controlled by a different key than what was provided by your friend. You cannot unlock this domain.")
//FIXME: implement a modal
                        return
                    } else {
                        console.log("Super. The domain is controlled by the keys gifted to you by your friend. All is well, so far")

                        console.warn("Super! We're at the start of the claim (for owner #" + dtx.outputStates[0].ownerCount + ")")

                        console.warn("Any sats bundled with this domain? " + initialSats)

                        const me = this
                        execAsync( async function() {

                            // this will dismount the shizzleView, so, we had to do
                            // this OUTSIDE of viewResponseCallback() - which is
                            // called/executed by ShizzleView
                            me.setState({   disableWalletGenerationButton: false,
                                            foundDomainInGoodState: true,
                                            latestTxId: latestTxId,
                                            dtx: JSON.stringify( dtx ),
                                            initialSats: initialSats,

                                            furthestQuarterlyTxId: furthestQuarterlyTxId  // new var to be more quarterly-aware
                                        })
                        })
                    }

                } else {
                    alert("Whoops. It's possible that your friend may have given-up on you, and redeemed the domain for themselves. OR you've taken the first steps to redeem their gift, but have yet to finish. We'll go through the next few steps - in case it's the latter.")
//FIXME: implement a modal?
//FIXME: add instructions to help determine. Maybe: if you CAN post, it's yours. If NOT, then talk to your friend.

                    const me = this
                    execAsync( async function() {

                        // this will dismount the shizzleView, so, we had to do
                        // this OUTSIDE of viewResponseCallback() - which is
                        // called/executed by ShizzleView
                        me.setState({   disableWalletGenerationButton: false,
                                        foundDomainInGoodState: false,
                                        latestTxId: latestTxId,
                                        dtx: JSON.stringify( dtx ),
                                        initialSats: 0,

                                        furthestQuarterlyTxId: furthestQuarterlyTxId  // new var to be more quarterly-aware
                                    })
                    })
                }

                const quarterlyStats = await checkIfDomainExpiredOrRenewable(await openDB(), furthestQuarterlyTxId, this.props.blockHeight)
                if (quarterlyStats?.expired) {
                    const oldOwnerCount = dtx.outputStates[0].ownerCount
                    alert("WARNING: This domain has EXPIRED. While your friend may have intended to gift this domain to you, it may be re-claimed by ANYONE at ANY time. " +
                          "In the steps that follow, it may even appear to function for some time. If so, we recommend using whatever funds may be available (if any) to re-claim the domain - IF that is your goal. " +
                          "Any effort you put into this domain may be for naught - unless you attend to this matter first." +
                          "\n\nThe OLD owner (perhaps you or your friend, sort of) was owner #" + oldOwnerCount + ". The next person to claim this domain will be owner #" + (oldOwnerCount+1) + "." +
                          "\n\nTo re-claim this domain ('" + dtx.limbName + "'), you'll need to contact your friend, or use the tools of this site as best you can.")
                }

            } else {
                alert("Something went wrong when we tried to find your domain. We're not sure how to proceed.")
//FIXME: implement a modal
            }

            //check foundFollowable?
            //      unspendable[]?  followable[]?
            // NO: address told us we were at the end

        } else {
            alert("whoops. we didn't find domain " + this.state.domain + " :(")
//FIXME: implement a modal
        }
    }

    // See if the user already has a wallet:
    //   - funds:                officialP2pkhTab          officialWalletJson
    //   - builder payout limbs: domainsBuiltTab
    //   - domains:              domainTab
    // If ANY of these have an entry, we need to allow user to preseve them
    // Use must now Verify (or use Delete option withing the verifier)
    async handleWalletInvestigation() {
        const db = await openDB()

        // funds
        const officialWalletJson = await getOfficialWalletJson(db)

        // builderPayout
        const builtDomains = await findAllMyBuiltDomains(db)

        // domains
        const myDomains = await findAllDomainsOfMine(db)

        // if ANY are found:           state.showVerifier
        //    show Verifier            call userHasWallet()
        // if NONE are found, though:  state.showWalletGenerator
        //    show generator           call handleGenerateWallet()
        console.log("investigation:  walletJson: ",   officialWalletJson)
        console.log("investigation:  builtDomains: ", builtDomains)
        console.log("investigation:  myDomains: ",    myDomains)

        //console.error("investigation:  walletJson.length: ",   officialWalletJson.length)
        //console.error("investigation:  builtDomains.length: ", builtDomains.length)
        //console.error("investigation:  myDomains.length: ",    myDomains.length)

        if ( builtDomains.length > 0 || myDomains.length > 0 ) {
            // user already has a wallet
            alert("We see you already have a browser wallet - with some thing(s) of value. If you can unlock it, THAT's where we'll save your new domain - given by your friend")
            alert("If you CAN'T unlock it, you could DELETE it (assuming you have no funds in it, or have already backed it up).")
            this.userHasWallet()

        } else if ( officialWalletJson.length > 2 ) {
            const walletParsed = JSON.parse(officialWalletJson)
            console.log("walletParsed: ", walletParsed)
            console.log("walletParsed[0].address: ", walletParsed[0].address)

            if ( walletParsed[0].address.length > 29 ) {
                alert("You appear to ALREADY have a wallet address. We wouldn't want to over-write it. If you can unlock it, THAT's where we'll save your new domain - given by your friend")
                alert("If you CAN'T unlock it, you could DELETE it (assuming you have no funds in it, or have already backed it up).")
                this.userHasWallet()
            } else {
                alert("We're not sure why you have a wallet with no valid funding address. We'll generate a wallet address for you.")
                this.handleGenerateWallet()
            }

        } else {
            // user has no existing wallet
            this.handleGenerateWallet()
        }
    }

    // We need to generate a wallet for the user
    handleGenerateWallet() {
        this.setState({ showQueryModal: false,
                        showWalletGenerator: true})
    }

    // Generator might invoke this if user wants to restore a wallet
    handleRestoreOption() {
        console.log("redeemer: Let's import (restore) a wallet")
        this.setState({showWalletGenerator: false, showRestoreModal: true})
    }
    closeRestorer() {
        console.warn("redeemer: closeRestorer()")
        this.setState({showRestoreModal: false})
    }

    async userHasWallet() {
        console.log("Things went well in the redeemer (generating wallet). Now we'll show the verifier.")

//FIXME: maybe we need not play with this right now? Maybe this was just for on-ramping
/*
        const good = await this.props.checkCitizenLevel() === 1
        if ( good ) {
            //increase citizen level - at bitShizzleApp
            await this.props.changeCitizenLevel(1)
        }

        console.log("citizenLevel IS " + this.props.citizenLevel)
*/
        this.setState({ showQueryModal: false,
                        showWalletGenerator: false,
                        showVerifier: true})
    }

    returnBundle(theBundle) {
        //console.error("Got a bundle: ", theBundle)
        this.bundle = theBundle

        // pass the bundle upwards - so user can post from surfer
        this.props.returnBundle( theBundle )

        console.error("pkhUsed: ", this.state.pkhUsed)
        //console.error("pUsed: ", this.state.pUsed)
        //console.error("qUsed: ", this.state.qUsed)

        this.saveGiftedKeys( this.state.pkhUsed, this.state.pUsed, this.state.qUsed, theBundle.pwd)
    }

    async userRemembersPwd() {
        console.log("userRemembersPwd() Now, SHOW the next step (building a tx)")

        // Check if user has ALREADY registered domain.
        // If so, we'll mention (in render()) to visit,
        // in general, from https://bitshizzle.com
        // NOT with the /?gift   query param

        // domains
        const myDomains = await findAllDomainsOfMine(await openDB())
        console.log("USER registered domains: ", myDomains)

        let hasBeenRegistered = false
        for ( let i in myDomains ) {
            console.log("domain: ", myDomains[i])
            if ( myDomains[i].name === this.state.domain ) {
                console.warn("We see that the user has ALREADY registered this domain")
                hasBeenRegistered = true
                break;
            }
        }


        this.setState({showVerifier: false, showFinalStep: true, hasBeenRegistered: hasBeenRegistered})
    }

    //FIXME: Misleading name. Rename to prepareForTakingControl() ?
    async generateFirstTransaction() {
        console.log("About to  make first transaction (in surfer). Will jump to domain" + this.state.domain)

        const dtx = JSON.parse(this.state.dtx)
        const ownerCount = dtx.outputStates[0].ownerCount

        //NOTE: we're registering this EVEN THOUGH we haven't yet posted
        //      and gained exclusive control. The 'fromFriend' can later
        //      help us detect and correct if our friend gives up on us,
        //      and posts before we do
        await registerDomainOfMine( await openDB(),
                                    this.state.domain,
                                    ownerCount,  //ownerCount
                                    this.props.blockHeight,
                                    false, // outdated?
                                    false, // forAFriend
                                    this.state.sentBy   // FROM a friend
                                  )

        this.props.changeCitizenLevel(4)
        this.props.handleJumpToSurfer(this.state.domain, ownerCount, true) // fromGiftRedeemer = true (to mountFunder)
    }


    showGenericModal(title, text) {
        this.setState({showGenericOkModal: true, genModalTitle: title, genModalText: text})

        // moved to render()
        //this.refOkButton.current.focus();
    }
    handleGenericModalOk() {
        this.setState({showGenericOkModal: false, genModalTitle: '', genModalText: ''})
    }

    render() {
        const bitcoinSymbol = '\u20BF'
        const userDomainSmall = <>
                            <b style={{color:bshzColors.purple, fontSize:"1.2rem", verticalAlign:"5%"}}><span style={{verticalAlign:"-5%"}}>{bitcoinSymbol}</span>@</b>
                            <b style={{color:"blue", fontSize:"1.3rem"}}>{this.state.domain.toLowerCase()}</b>
                        </>

        console.warn("giftRedeemerModal  render()")

        // ***  DEVICE-DEPENDENT STYLING  ***
        // For mobile, "detect" landscape screen mode
        const landscape = this.props.isMobile ? window.innerWidth > window.innerHeight : false
        // For mobile, shrink the About, Security, and Glossary modals
        const modalClassName   = this.props.isMobile ?
                                        (landscape ?
                                                "modalMobileLandscape"
                                            :
                                                "modalMobilePortrait"
                                        )
                                    :
                                        ""
        const modalContentClassName = this.props.isMobile ? "modalContentMobile" : ""
        const modalBottomClassName  = this.props.isMobile ? "modalBottomMobile"  : ""

        const disableRedeem = this.state.cyphText.length < 100 || this.state.pin.length < 12
        const oneTimeDecryptButton = <>
                                        <Button disabled={disableRedeem} onClick={this.decryptKey} positive>
                                                Redeem your piece of the <b>{this.shizzleVerseNotBold}</b>
                                        </Button>
                                    </>

        const sverseExplanation =
        <>
            <p>
                The {this.shizzleVerseNotBold} is an emerging World Wide Web - built, and run on Bitcoin.
            </p>
            <p>
                Just like the World Wide Web, no single entity owns or controls the {this.shizzleVerseNotBold}.
                It grows from the creativity of anyone and everyone who stakes a claim to a <b>Bitcoin</b> identity or website.
            </p>
            <p>
                A Bitcoin identity (part of the {this.shizzleVerseNotBold}) is like a Twitter+Email account,
                except that it's built with Bitcoin, and you can <b>own</b> it.
            </p>
        </>
        const sversePopup =
        <Popup style={{backgroundColor: bshzColors.ltYellow}} trigger={this.shizzleVerse} wide="very" content={sverseExplanation} hideOnScroll/>

        const instructions =
                <>
                    <h1 style={{color:"blue"}}>Greetings!</h1>
                    <div>
                       You might be here because you have a friend who wants to connect with you
                        through the {sversePopup}. If so, they've claimed a little piece of it for
                        you, and would like to give it to you (don't worry - it wasn't expensive).
                        You can redeem it right now in one simple step.
                    </div>
                    <br></br>
                    <div>
                        They <i>should</i> have sent you two things, separately:
                        <ul>
                            <li>A text message containing a secret 12-digit PIN
                            </li>
                            <li>An email containing an encrypted blob of gibberish
                            </li>
                        </ul>

                        Check your phone and inbox. If you've received <b>both</b> of these, enter them below. If not, you'll need to
                        talk with your friend, or maybe check your spam folder.
                    </div>
                    <div>
                        <Input  placeholder="Enter secret 12-digit PIN here"
                                onChange={this.handlePinChange}
                                style={{width:"280px", padding:"5px 5px 5px 0px"}}
                                size='big'/><br></br>
                        <Input  placeholder="Paste encrypted blob (gibberish) here"
                                onChange={this.handleGibberishChange}
                                style={{width:"400px", padding:"5px 5px 10px 0px"}}
                                size='big'/>
                    </div>
                </>

        const wocUrl = this.state.foundDomainInGoodState ?
                            "https://classic-test.whatsonchain.com/tx/" + this.state.latestTxId
                        :
                            ""

        // avoid tab-napping
        // see: https://stackoverflow.com/questions/17711146/how-to-open-link-in-new-tab-on-html#17711167
        const wocLink = this.state.foundDomainInGoodState ?
                            <a href={wocUrl} target="_blank" rel="noopener noreferrer" >Link to a Transaction Browser</a>
                        :
                            <></>

        const inTheWeedsDetail =
                    <>
                        <br></br>
                        <br></br>
                        FYI: this is the Bitcoin transaction holding your domain: {wocLink}. We mention
                        this to remind that the {this.shizzleVerse} is built on the Bitcoin blockchain.
                    </>

        const initialPennies = Math.round( this.state.initialSats * this.penniesPerSat )
        const initialSatsMention1 = this.state.initialSats === 0 ?
                                <></>
                            :
                                <>
                                    <br></br>
                                    <br></br>
                                    <div style={{fontSize:"1.2rem"}}>
                                        <b>And</b>, your friend also bundled some Bitcoin with it - worth
                                        roughly <b style={{color:"blue"}}>{initialPennies}¢</b> !!
                                    </div>
                                </>

        const shizzleViewOption = this.state.foundDomainInGoodState ?
                        <>
                            We found your domain, {userDomainSmall}, in a <b>good</b> state (your friend did not redeem it for themselves).

                            {initialSatsMention1}
                            <br></br>
                            <br></br>
                            Next, you must have a browser wallet - to store your domain...
                        </>
                    :
                        <>
                            <ShizzleView    parent={this}
                                            show={true}
                                            submode={true}
                                            domainToView={this.state.domain}

                                            registerSubmodeCallback={this.registerViewerCallback}
                                            subModeResponse={this.viewResponseCallback}
                                            />
                        </>

        const maybeInitialToast = this.state.showDbInitializationToast ?
                <>
                    <Toast displayText={"Initializing Database..."}/>
                </>
            :
                <></>

        const checkingOnDomainTitle = this.state.foundDomainInGoodState ?
                            <>Your domain is READY for you!</>
                        :
                            <>Checking on your new domain...</>

        // WalletGenerator might call props.restoreWallet() (if user chooses to
        // restore a wallet) - which should caause us to display this
        const restoreWalletModal = this.state.showRestoreModal ?
                    <WalletImporter closeWalletImportModal={this.closeRestorer}
                                    userNowHasWallet={this.userHasWallet}
                                    showOkModal={this.showGenericModal}

                                    isMobile={this.props.isMobile}
                                    />
                    : null;

        const optionalAlreadyRegisteredMention = this.state.hasBeenRegistered ?
                        <>
                            <b>We notice that you've been here already to redeem {userDomainSmall}.</b> Hopefully
                                the steps below are clear. Going forward (after you've redeemed your gift), you can
                                visit {this.bitShizzle} at https://bitshizzle.com
                            <Divider />
                        </>
                    :
                        <></>

        const initialSatsMention = this.state.initialSats === 0 ?
                    <></>
                :
                    <>
                        <br></br>
                        <br></br>
                        Lastly, your friend was nice enough to gift this domain to you. In doing so, your
                        friend also included a few small bits of
                        Bitcoin <span style={{color:"blue"}}>({this.state.initialSats} satoshis, worth roughly {initialPennies}¢)</span>. This
                        will enable you to make a few transactions/posts. But, just know that at some point
                        you'll probably need to <b>add</b> more Bitcoin to your wallet.
                    </>

        const optionalFromFriendMention = this.state.sentBy === null ?
                                        <><br></br></>
                                    :
                                        <Segment>
                                            <b>Also,</b> your friend, who sent this, is already a member of the {this.shizzleVerse}. When
                                            you're ready, you can look-up your friend at
                                            the {this.shizzleVerse} domain <b style={{color:bshzColors.purple, fontFamily:"courier", fontSize:"1.2rem", verticalAlign:"5%"}}><span style={{verticalAlign:"-5%"}}>{bitcoinSymbol}</span>@</b>
                                            <b style={{color:"green", fontSize:"1.6rem", fontFamily:"courier"}}>{this.state.sentBy?.toLowerCase()}</b>.
                                        </Segment>
        return(
                <>
                    {maybeInitialToast}

                    {restoreWalletModal}

                    <Modal size='small' centered className={modalClassName}  open={true} style={{backgroundColor: bshzColors.purple, borderRadius: '20px'}}>
                        <Modal.Header style={{textAlign: 'center', backgroundColor: bshzColors.purple, borderRadius: '20px'}}>
                            <span style={{color: bshzColors.yellow}}> You've got a present! </span>
                        </Modal.Header>
                        <Modal.Content className={modalContentClassName} scrolling style={{backgroundColor: bshzColors.ltPurple}}>

                            {instructions}

                            {oneTimeDecryptButton}

                        </Modal.Content>
                        <Modal.Actions className={modalBottomClassName} style={{backgroundColor: bshzColors.purple, borderRadius: '0px 0px 20px 20px'}}>
                            <div style={{textAlign: 'center'}}>
                                <Button negative onClick={this.handleModalOk} content='Never Mind'/>
                            </div>
                        </Modal.Actions>
                    </Modal>

                    <Modal size='small' centered className={modalClassName}  open={this.state.showBadDecode} style={{backgroundColor: bshzColors.purple, borderRadius: '20px'}}>
                        <Modal.Header style={{textAlign: 'center', backgroundColor: bshzColors.purple, borderRadius: '20px'}}>
                            <span style={{color: bshzColors.yellow}}> Something Went Wrong <Icon name='frown' size="large" style={{color:"yellow"}}/> </span>
                        </Modal.Header>
                        <Modal.Content className={modalContentClassName} scrolling style={{backgroundColor: bshzColors.ltPurple}}>

                            Please check that you entered the <b style={{color:"blue"}}>correct</b> secret PIN, and pasted the <b style={{color:"blue"}}>entire</b> blob of gibberish.

                        </Modal.Content>
                        <Modal.Actions className={modalBottomClassName} style={{backgroundColor: bshzColors.purple, borderRadius: '0px 0px 20px 20px'}}>
                            <div style={{textAlign: 'center'}}>
                                <Button positive onClick={this.handleErrorOk} content='OK'/>
                            </div>
                        </Modal.Actions>
                    </Modal>

                    <Modal size='small' centered className={modalClassName}  open={this.state.showSuccessModal} style={{backgroundColor: bshzColors.purple, borderRadius: '20px'}}>
                        <Modal.Header style={{textAlign: 'center', backgroundColor: bshzColors.purple, borderRadius: '20px'}}>
                            <span style={{color: bshzColors.yellow}}> Success! <Icon name='smile' size="large" style={{color:"yellow"}}/> </span>
                        </Modal.Header>
                        <Modal.Content className={modalContentClassName} scrolling style={{backgroundColor: bshzColors.ltPurple}}>

                            <div>
                                You've successfully <b style={{fontSize:"1.2rem"}}>retrieved the keys</b> to your very own {sversePopup} domain, gifted to you by a friend:

                                <div style={{textAlign: "center", fontFamily: "courier"}} >
                                    <b style={{color:bshzColors.purple, fontSize:"1.8rem", verticalAlign:"5%"}}><span style={{verticalAlign:"-5%"}}>{bitcoinSymbol}</span>@</b>
                                    <b style={{color:"blue", fontSize:"2.4rem"}}>{this.state.domain.toLowerCase()}</b>
                                </div>
                            </div>
                            <br></br>
                            <div>
                                But, <b style={{color:"red"}}>you're not done</b>. While your friend was nice enough to give you this domain, they still have access to it. They
                                have a copy of the secret PIN, and the encrypted blob they sent to you. In order to be a <b>full</b> citizen of the {sversePopup}, you'll
                                need to take a few more painless steps.
                            </div>

                            {optionalFromFriendMention}

                            <div>
                                Next, we'll help you to:
                                <ul>
                                    <li>
                                        Find your domain on the blockchain
                                    </li>
                                    <li>
                                        Create your own password-encrypted browser wallet
                                    </li>
                                    <li>
                                        Secure <b>exclusive control</b> of your domain using your wallet
                                    </li>
                                </ul>

                                Along the way, <b>we'll need to save portions of the blockchain to your browser database</b>.
                                <br></br>
                                <br></br>
                                <Button positive onClick={this.handleFindNewDomain}>
                                    Next: find my domain
                                </Button>
                            </div>

                        </Modal.Content>
                        <Modal.Actions className={modalBottomClassName} style={{backgroundColor: bshzColors.purple, borderRadius: '0px 0px 20px 20px'}}>
                        </Modal.Actions>
                    </Modal>

                    <Modal size='small' centered className={modalClassName}  open={this.state.showQueryModal} style={{backgroundColor: bshzColors.purple, borderRadius: '20px'}}>
                        <Modal.Header style={{textAlign: 'center', backgroundColor: bshzColors.purple, borderRadius: '20px'}}>
                            <span style={{color: bshzColors.yellow}}> {checkingOnDomainTitle} </span>
                        </Modal.Header>
                        <Modal.Content className={modalContentClassName} scrolling style={{backgroundColor: bshzColors.ltPurple}}>

                            {shizzleViewOption}

                            <br></br>
                            <br></br>

                            <Button positive disabled={this.state.disableWalletGenerationButton}
                                    onClick={this.handleWalletInvestigation}>
                                NEXT: my browser wallet
                            </Button>

                        </Modal.Content>
                        <Modal.Actions className={modalBottomClassName} style={{backgroundColor: bshzColors.purple, borderRadius: '0px 0px 20px 20px'}}>
                        </Modal.Actions>
                    </Modal>

                    <Modal size='small' centered className={modalClassName}  open={this.state.showWalletGenerator} style={{backgroundColor: bshzColors.purple, borderRadius: '20px'}}>
                        <Modal.Header style={{textAlign: 'center', backgroundColor: bshzColors.purple, borderRadius: '20px'}}>
                            <span style={{color: bshzColors.yellow}}> Let's build a wallet for you... </span>
                        </Modal.Header>
                        <Modal.Content className={modalContentClassName} scrolling style={{backgroundColor: bshzColors.ltPurple}}>

                            <WalletGenerator    userNowHasWallet={this.userHasWallet}
                                                showOkModal={this.showGenericModal}
                                                isMobile={this.props.isMobile}
                                                restoreWallet={this.handleRestoreOption}
                                                />

                        </Modal.Content>
                        <Modal.Actions className={modalBottomClassName} style={{backgroundColor: bshzColors.purple, borderRadius: '0px 0px 20px 20px'}}>
                        </Modal.Actions>
                    </Modal>

                    <Modal size='small' centered className={modalClassName}  open={this.state.showVerifier} style={{backgroundColor: bshzColors.purple, borderRadius: '20px'}}>
                        <Modal.Header style={{textAlign: 'center', backgroundColor: bshzColors.purple, borderRadius: '20px'}}>
                            <span style={{color: bshzColors.yellow}}> Verify Your Wallet Password</span>
                        </Modal.Header>
                        <Modal.Content className={modalContentClassName} scrolling style={{backgroundColor: bshzColors.ltPurple}}>

                            <WalletVerifier userRemembersPwd={this.userRemembersPwd}
                                            userDeletedWallet={this.userDeletedWallet}
                                            getOfficialWallet={this.props.getOfficialWalletRecord}
                                            showOkModal={this.showGenericModal}
                                            returnBundle={this.returnBundle}

                                            isMobile={this.props.isMobile}

                                            finishReset={this.fullResetPerformed}
                                            />

                        </Modal.Content>
                        <Modal.Actions className={modalBottomClassName} style={{backgroundColor: bshzColors.purple, borderRadius: '0px 0px 20px 20px'}}>
                        </Modal.Actions>
                    </Modal>

                    <Modal size='small' centered className={modalClassName}  open={this.state.showFinalStep} style={{backgroundColor: bshzColors.purple, borderRadius: '20px'}}>
                        <Modal.Header style={{textAlign: 'center', backgroundColor: bshzColors.purple, borderRadius: '20px'}}>
                            <span style={{color: bshzColors.yellow}}> The FINAL Step </span>
                        </Modal.Header>
                        <Modal.Content className={modalContentClassName} scrolling style={{backgroundColor: bshzColors.ltPurple}}>

                            {optionalAlreadyRegisteredMention}

                            You're almost done. Lastly, you'll need to create your very first Bitcoin
                            transaction. This will pass control of your domain from the keys that your
                            friend provided you, to the keys that are in your new browser wallet.

                            <br></br>
                            <br></br>
                            We're about to throw you into the deep end. You'll use the {this.bitShizzle} browser
                            to make your very first post to the {sversePopup}. Don't worry about what to
                            say. Say anything. What's important is that you post <b>something</b>. Until you do
                            this, you won't have <b>exclusive</b> control of your domain - your friend could change
                            their mind, and redeem the gifted domain to themselves.

                            <br></br>
                            <br></br>
                            Right after posting, you'll be asked to set your <b style={{color:"blue"}}>Fee
                            Preferences</b>. Read the descriptions carefully. You'll definitely want to set
                            the <span style={{color:"blue"}}>'Price others must pay'</span> setting <b>before</b> you
                            make your second post.

                            {initialSatsMention}

                            <br></br>
                            <br></br>
                            <b style={{color:"blue"}}>You must now POST something - to take exclusive CONTROL.</b>

                            <br></br>
                            <br></br>
                            <Button positive onClick={this.generateFirstTransaction}>
                                NEXT: let me make my first POST
                            </Button>

                        </Modal.Content>
                        <Modal.Actions className={modalBottomClassName} style={{backgroundColor: bshzColors.purple, borderRadius: '0px 0px 20px 20px'}}>
                        </Modal.Actions>
                    </Modal>

                    <Modal size='small' centered className={modalClassName}  open={this.state.showGenericOkModal} style={{backgroundColor: bshzColors.purple, borderRadius: '20px'}}>
                        <Modal.Header style={{textAlign: 'center', backgroundColor: bshzColors.purple, borderRadius: '20px'}}>
                            <span style={{color: bshzColors.yellow}}> {this.state.genModalTitle}</span>
                        </Modal.Header>
                        <Modal.Content className={modalContentClassName} scrolling style={{backgroundColor: bshzColors.ltPurple}}>

                            {this.state.genModalText}

                        </Modal.Content>
                        <Modal.Actions className={modalBottomClassName} style={{backgroundColor: bshzColors.purple, borderRadius: '0px 0px 20px 20px'}}>
                            <div style={{textAlign: 'center'}}>
                                <Button ref={this.refOkButton} positive onClick={this.handleGenericModalOk} content='OK'/>
                            </div>
                        </Modal.Actions>
                    </Modal>
                </>
        )
    }
}

export default GiftRedeemerModal;
