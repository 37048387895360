"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var getOption_1 = require("./getOption");
function getEyebrowType(options, random) {
    var eyebrowType = [];
    if (getOption_1.getOption('eyebrow', 'angry', options)) {
        eyebrowType.push('Angry', 'AngryNatural');
    }
    if (getOption_1.getOption('eyebrow', 'default', options)) {
        eyebrowType.push('Default', 'DefaultNatural');
    }
    if (getOption_1.getOption('eyebrow', 'flat', options)) {
        eyebrowType.push('FlatNatural');
    }
    if (getOption_1.getOption('eyebrow', 'raised', options)) {
        eyebrowType.push('RaisedExcited', 'RaisedExcitedNatural');
    }
    if (getOption_1.getOption('eyebrow', 'sad', options)) {
        eyebrowType.push('SadConcerned', 'SadConcernedNatural');
    }
    if (getOption_1.getOption('eyebrow', 'unibrow', options)) {
        eyebrowType.push('UnibrowNatural');
    }
    if (getOption_1.getOption('eyebrow', 'up', options)) {
        eyebrowType.push('UpDown', 'UpDownNatural');
    }
    return random.pickone(eyebrowType);
}
exports.getEyebrowType = getEyebrowType;
