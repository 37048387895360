
import React from 'react';
//import ReactDOM from 'react-dom';

// this line was key
//import 'semantic-ui-css/semantic.min.css'

import { Button, Checkbox, Container, Divider, Dropdown, Form, Grid,
         Input, Menu, Message, Modal, Popup, Radio, TextArea } from 'semantic-ui-react'

//import './index.css';

import {MenuCard,
        TxStatsDisplay,
        TxSearchBar,
        TxBuildPane,
        LockingScriptOptions } from './shizzleComponents'

import {
    testGetBulkUnspent,

    saveEncryptedKeys,
    getEncryptedKeysFromPKH,

    buildShizzleLockingTx,
    encryptData,
    decryptData,
    getRabinsJson,

    getEncryptedKeyFromAddress,

    getCurrentBlockInfo,
    findLatestContinue,
    findLatestAuction,

    openDB,
    getTxidFromAddr,
    recursivelyDeleteDescendantSpends,
    queryTxs,
    queryLabels,
    checkDanglingPaths,
    queryFetchTx,
    decomposeTx,
    queryFetchDecodeTx,
    analyzeContentPrefixFlag,
    checkToUpdateIP4Mapping,
    findContent,

    parseTheOutputs,

    findNextTx,
    findPrevTx,

    validateRabinPrivateKeys,
    generateRabinPrivateKeyPlus
} from './buildShizzle.js';

//import ShizzleView from './shizzleView.js';
import ContentPresenter from './contentPresenter.js';

import {
  hexByteToAscii,
  hexStringToAscii,
  execAsync
} from './commonFuncs.js';

import { APP_TITLE, ASSET_CHECK_INTERVAL_MS } from './harnessConstants'

import {
  reviewRenewalStatus,
  adjustRenewalStatus
} from './commonReact';

import ContractBuilder from './contractBuilder.js'

import { BsvKeyRetriever } from './keyUtils.js'

import * as eccryptoJS from 'eccrypto-js';

import './index.css';

const {
	bsv,
} = require('bsv');

// ContractBuilder, and ShizzleComponents (TxStatsDisplay) use window.ourShizzleNav
function setShizzleNav(shizzleNav) {
  window.ourShizzleNav = shizzleNav
}



function nameFromMode(mode) {
  console.log("nameFromMode(): " + mode);
  const asciiMode = hexByteToAscii( mode );
  switch ( asciiMode ) {
    case 'X':
      return 'append';
    case 'K':
    case 'P':
    case 'p':
      return 'continue';
    case 'U':
      return 'update';
    case '0':
      return 'transient-0';
    case '1':
      return 'transient-1';
    case '2':
      return 'transient-2';
    case '3':
      return 'transient-3';
    case '4':
      return 'transient-4';
    case '5':
      return 'transient-5';
    case '6':
      return 'transient-6';
    case '7':
      return 'transient-7';
    case '8':
      return 'transient-8';
    case '9':
      return 'transient-9';
    case 'G':
      return 'bitgroup';
    case 'g':
      return 'admingroup';
    case 'N':
      return 'askbid';
    case 'e':
      return 'EBFRA'   // Speaker
    case 'E':
      return 'EBFRA'   // Announcement
    case 'b':
      return 'builderPrep'
    case 'D':
      return 'dialog'
    default:
      console.error("nameFromMode: Invalid mode " + mode);
      throw new Error('14402');
  }
}

/**
 * When instantiating, include these props:
 *     parent
 *     show
 *     handleSwitchToShizzleView
 *     registerCient
 */
//NOTE: using PureComponent to avoid periodic re-renders due just to APP state change
class ShizzleNav extends React.PureComponent {
  constructor(props) {
    super(props);
    document.title = APP_TITLE

    this.handleSearchChange    = this.handleSearchChange.bind(this);
    this.handleSearchSubmitted = this.handleSearchSubmitted.bind(this);
    this.handleDeleteSubmitted = this.handleDeleteSubmitted.bind(this);
    this.handleOptionChosen    = this.handleOptionChosen.bind(this);
    this.handleBuildTypeChosen = this.handleBuildTypeChosen.bind(this);
    this.handleFollow          = this.handleFollow.bind(this);
    this.handleBuildOnOutput   = this.handleBuildOnOutput.bind(this);
    this.handlePrevious        = this.handlePrevious.bind(this);
    this.handleGuestPrevious   = this.handleGuestPrevious.bind(this);
    this.handleNewlyBuiltTx    = this.handleNewlyBuiltTx.bind(this);
    this.closeBuilderModal     = this.closeBuilderModal.bind(this);
    this.jumpToTx              = this.jumpToTx.bind(this);
    this.periodicAssetStatusCheck = this.periodicAssetStatusCheck.bind(this);
    this.expeditePeriodicCheck = this.expeditePeriodicCheck.bind(this);

    this.getAddressForKeyForDecryption       = this.getAddressForKeyForDecryption.bind(this);
    this.handleRetrievedDecryptionKey        = this.handleRetrievedDecryptionKey.bind(this);
    this.closeBsvDecryptionKeyRetrieverModal = this.closeBsvDecryptionKeyRetrieverModal.bind(this);


    // register self - to allow others to reference us (for example - to call expeditePeriodicCheck() from a Builder)
//    console.warn("About to setShizzleNav() in shizzleNav ctor")
//FIXME: it's possible we should only do this in componentDidMount()
    setShizzleNav(this)

    // from: https://www.surajsharma.net/blog/current-url-in-react
    const windowURL = window.location.href // returns the absolute URL of a page
    console.warn("SNav: our URL (may be useful for innerFrame logic): ", windowURL)

    const windowPathname = window.location.pathname
    const pathSplit = windowPathname.split('/')
    console.log("SNav: window pathname split : ", pathSplit)
    const viewMode = pathSplit.length > 0 ? pathSplit[1] : 'nav'    // nav or view
    console.warn("SNav:   initial viewMode: " + viewMode)

    if ( pathSplit.length > 2 && pathSplit[2].length > 0 ) {
      console.warn("SNav: MAYBE we're being asked to look at shizzle asset URL bshz://" + windowPathname)
      const parent = this
      execAsync( async function() {
        const pathSnippet = pathSplit.slice(2).join('/')
        console.warn("SNav async: We're about to look-up this path: bshz://" + pathSnippet)

        const db = await openDB()
        const requestedTxid = await getTxidFromAddr(db, "bshz://" + pathSnippet)
        if ( requestedTxid === null ) {
          alert("SNav async: Sorry. The requested shizzle address (bshz://" + pathSnippet + ") is not currently cached (or doesn't even exist).\n\n"
              + "For now, you'll need to journey there manually. Stay tuned for improvements, or drop a line for feedback.")
          // clear the invalid URL path
          window.history.pushState("", "", "/")
        } else {
          // open box to enter a tx to search for
          parent.handleOptionChosen("jump");

          // Manually feed the tx into the search box, and then trigger the 'search'
          console.warn("SNav async: SETTING searchEntry to " + requestedTxid)
          parent.setState({searchEntry: requestedTxid})
          parent.handleSearchSubmitted()
        }
      })
    }

    this.state = {
      txToConsider: '',   // THIS sets up a loader (swirling thing if non-blank)
      searchEntry: '',     // ONLY 'Fetch & Decode' should write to this
      searchEntry2: '',    // local (latest) copy of what to query for (updated by build/follow)
      buildLocking: false,
      jumpTo: false,
      buildType: '',
      renewalStatus: null,
      swipeText:     null,
      previousAsset: null,
      periodicCheckTimerId: null,
      assetCheckInterval: ASSET_CHECK_INTERVAL_MS,     // for periodicAssetCheck()

    // may be set in periodicAssetStatusCheck
    auctionGranted: false,
    auctionWaiting: false,
    auctionExecuted: false,
    leadingContinueTx: null,
    leadingAuctionDtx: null,

      openBuilderModal: false,
      outputIndexChosen: null,

      blocksBeyondMin: 0,
      leadingContinueDTx: null,       //FIXME: when do we blank this? or, do we only blank swipeText?

      leadingAuctionTx: null,
      leadingAuctionLimb: null,

      addressBarPath: windowPathname, // could be used to easily specify a bshz path
      windowURL:      windowURL,      // could be helpful to contentFrame logic

      openModalForFundingDecryptionKey: false,
      keyAddressForDecryption: ''
    };
  }

  /*
  componentWillUnmount() {
    // fix Warning: Can't perform a React state update on an unmounted component
    // see: https://stackoverflow.com/questions/53949393/cant-perform-a-react-state-update-on-an-unmounted-component
    this.setState = (state,callback)=>{
        return;
    };
  }
  */

  /**
   * Periodically check for the given/current limb/asset:
   *     - the latest Continue tx
   *     - the latest Update tx(s)         <---- not yet?
   *     - the latest Transient tx(s)      <---- not yet?
   *     - the latest Auction tx
   * (IOW: Have any of them advanced?)
   *
   * THIS WILL call back to the parent - parent.setState()
   * with any, or several of EIGHT different state parameters:
   *        leadingAuctionTx,
   *        leadingAuctionLimb,
   *        leadingAuctionDTx,
   *        auctionGranted
   *        renewalStatus,
   *        leadingContinueTx,
   *        leadingContinueDTx,
   *        swipeText
   *
   * @param {} parent
   * @param {} db
   * @returns
   */
  async periodicAssetStatusCheck(parent, db) {
    let odtx
    try {
      if ( parent.state.decodedTx ) {
        // 'current' tx (we're looking at)
        odtx = JSON.parse(parent.state.decodedTx)
      } else {
        console.log("periodicAssetStatusCheck(): checking decodedTx too early, or there's not yet a tx to decode. undefined")
        console.error("NOT FINALLY SETTING leadingContinueDTx 11")
        return
      }
    } catch (error) {
      console.error("periodicAssetStatusCheck(): checking weird decodedTx? ", error)
      console.warn("    decodedTx: ", parent.state.decodedTx)
      console.error("NOT FINALLY SETTING leadingContinueDTx 10")
      return
    }

    if ( odtx === null ) {
      console.error("periodicAssetStatusCheck(): null odtx? Maybe we're at the root.")

      console.error("NOT FINALLY SETTING leadingContinueDTx 9")
      return
    }

    const out0 = odtx.outputStates[0]
    const mode0 = out0.mode
    // If             X
    if ( mode0 === '58' ) {
      console.log("periodicAssetStatusCheck(): This is an append. There's nothing to monitor."); //    FIXME: <---- maybe monitor unclaimed asset.")

      console.error("NOT FINALLY SETTING leadingContinueDTx 8")
      return
    }
    // if E or e
    if ( mode0 === '45'|| mode0 === '65' ) {
      //FIXME: will need to monitor elsewhere, less frequently.")
      console.log("periodicAssetStatusCheck(): This is an EBFRA. There's nothing to monitor.");

      console.error("NOT FINALLY SETTING leadingContinueDTx 7")
      return
    }
    // If it's an unclaimed asset, it could get spent. So, we check periodically for that too.

    console.log("periodicAssetStatusCheck(): will check on leading continue of asset/limb '"
              + odtx.limbName + "' (every " + (parent.state.assetCheckInterval/1000) + "secs)...")


    //FIXME: is it useful that we're looking at the CURRENT tx here (immediately below)?
    //       Instead should we consider the LATEST/LEADING continue (and update, and auction, and even the transients)?

    //FIXME: are any of these useful?
    if ( parent.state ) {
      // if D
      if ( mode0 === '44' ) {
        console.error("--> Won't HERE track status on this (or any) dialog. IMPLEMENT: separate tracking for Dialogs")
//FIXME: should we carry the limb here? OR should we always carry it forward when decoding?
//       Right now we don't know for which limb it applies
        console.error("NOT FINALLY SETTING leadingContinueDTx 6")
        return
      }
      const blockNumHex = out0.blockNum
      const blockNumInt = Buffer.from(blockNumHex, 'hex').readUInt32LE(0)
      const ageInBlocks = parent.props.blockHeight - blockNumInt
      console.warn("periodicAssetStatusCheck(): blockHeight: " + parent.props.blockHeight)
      console.log("periodicAssetStatusCheck(): blockNumInt of current tx under examination: " + blockNumInt)
      console.log("periodicAssetStatusCheck(): age of current tx under examination: " + ageInBlocks)
    } else {
      console.warn("periodicAssetStatusCheck(): No parent.state? <=====")
      console.error("NOT FINALLY SETTING leadingContinueDTx 5")
      return
    }

    const oldRenewalStatus = parent.state.renewalStatus

    //FIXME: is it useful that we're looking at the mode of the CURRENT tx?

    // If THIS tx state is NOT 'K', and the blockHeight matches, stop checking for now
    if ( oldRenewalStatus !== null && oldRenewalStatus.limbName === odtx.limbName
        && oldRenewalStatus.reviewedAtBlockHeight === parent.props.blockHeight
        && (mode0 !== '4b' && mode0 !== '4B') && parent.props.blockHeight < oldRenewalStatus.blockWhenStatusMustChange ) {  // !K

      //FIXME: add checks:    && ( canBuildOnContinue || couldBeRenewed || expired )
      //       or something like that.
      //       We want to avoid frequent unnecessary queries

      console.log("periodicAssetStatusCheck(): BALKING. Not much has probably changed since last review: same limb, and blockHeight.")
      console.log("periodicAssetStatusCheck():     block when status may change: " + oldRenewalStatus.blockWhenStatusMayChange)
      console.error("NOT FINALLY SETTING leadingContinueDTx 4")
      return
    } else if ( oldRenewalStatus !== null ) {
      console.log("   NOT BALKING: something may have changed (will check). Block when status may change: " + oldRenewalStatus.blockWhenStatusMayChange)
    }

    // Consult the 'danglingPaths' list to find the newest Continue tx, to help calculate/generate,
    // further below, our 'renewalStatus'
    const latestContinueRes = await findLatestContinue(db, odtx.limbName, out0.ownerCount)
    let latestContinue = null
    if ( latestContinueRes !== null ) {
      latestContinue = latestContinueRes.result
      const ownerChanged = latestContinueRes.ownerChanged
      if ( ownerChanged ) {
        alert("NOTE: the owner has changed since we last checked on this domain")
      }
    }

    console.log("periodicAssetStatusCheck(): latestContinue: ", latestContinue)
    if ( latestContinue === null ) {
      console.warn("periodicAssetStatusCheck(): null latestContinue? Maybe this is an unclaimed asset - limb "
              + odtx.limbName + ", OR, we're testing with multiple independent transaction trees (funding roots).")

      //FIXME: so, then shouldn't we still do the swipeText. regardless?
      //parent.setState({swipeText: null})
      console.error("NOT FINALLY SETTING leadingContinueDTx 3")
      return
    }

    //FIXME: this final false, below, in call to qFDTx(, , false), could go away if we filter-out
    //       lots of frequent checks, up above ^
    const leadingContinueDecodedTx = await queryFetchDecodeTx(latestContinue.tx, db, false)
    if ( leadingContinueDecodedTx === null || typeof leadingContinueDecodedTx === 'number' ) {
      console.error("periodicAssetStatusCheck(): trouble getting txid " + latestContinue.tx)
      // This isn't some txid someone just typed in. It's something we've been following
      // Something may be wrong with Tx-Provider connectivity. Let's stop right here.
      console.error("NOT FINALLY SETTING leadingContinueDTx 2")
      return
    }
    console.warn("periodicAssetStatusCheck(): leading continue dtx (" + latestContinue.tx + "):", leadingContinueDecodedTx)

    // what really matters is:
    //         - when can it be built upon?
    //         - when can it be renewed?
    //         - when can it be swiped?
    let newRenewalStatus
    if ( window.devTestMode ) {
      const chosenMode = hexByteToAscii( leadingContinueDecodedTx.outputStates[ 0 ].mode );
      newRenewalStatus = adjustRenewalStatus(leadingContinueDecodedTx,
                                            leadingContinueDecodedTx.outputStates[ 0 ].blockNumInt,
                                            chosenMode,
                                            parseInt(parent.state.blocksBeyondMin)).status
    } else {
      newRenewalStatus = reviewRenewalStatus(leadingContinueDecodedTx, parent.props.blockHeight)
    }
    console.warn("periodicAssetStatusCheck():    NEW Renewal Status: ", newRenewalStatus)



    console.warn("periodicAssetStatusCheck(): Calculating latest auction for limb " + odtx.limbName + "... (too often?)")

    //NOTE: lastCheck may be bogus
    const latestAuction = await findLatestAuction(db, odtx.limbName)

    console.warn("periodicAssetStatusCheck(): latest auction: ", latestAuction)
    let auctionGranted = false
    let auctionWaiting = false
    let auctionExecuted = false
    let lATx  = null
    let lADTx = null
    let lAL   = null
    if ( latestAuction !== null ) {
      console.warn("periodicAssetStatusCheck(): we have a leadingAuctionTx, so, MAYBE decoding it <-----<<")
      const auctionLimb = latestAuction.limb
      const continueLimb = latestContinue.limb

      const auctionAddressParts = latestAuction.address.split("/", 8)
      const auctionOwnerCount = parseInt( auctionAddressParts[3] )
      const auctionQC = parseInt( auctionAddressParts[4] )

      const leadingContinueOwnerCount = leadingContinueDecodedTx.outputStates[ 0 ].ownerCount
      const leadingContinueQC = leadingContinueDecodedTx.outputStates[ 0 ].quarterlyCount

      console.error("  >> periodicAssetStatusCheck(): leading auctionOC: " + auctionOwnerCount)
      console.warn( "  >> periodicAssetStatusCheck(): leading auctionQC: " + auctionQC)
      console.warn( "  >> periodicAssetStatusCheck(): leading auctionLimb: " + auctionLimb)

      console.warn( "  >> periodicAssetStatusCheck(): leading continueOC: " + leadingContinueOwnerCount)
      console.warn( "  >> periodicAssetStatusCheck(): leading continueQC: " + leadingContinueQC)
      console.warn( "  >> periodicAssetStatusCheck(): leading continueLimb: " + continueLimb)

      let determineMostAdvanced = false
      if ( continueLimb === auctionLimb ) {
        if (  auctionOwnerCount === leadingContinueOwnerCount &&
              auctionQC === leadingContinueQC ) {
          console.warn("periodicAssetStatusCheck(): latest auction and continue OC and QC match. All good.")
        } else {
          console.warn("periodicAssetStatusCheck(): latest auction and continue OC and QC DON'T match. Which is more advanced?")
          console.error("periodicAssetStatusCheck(): don't match? AuctionOwnerCounbt: " + auctionOwnerCount)
          console.warn("periodicAssetStatusCheck(): don't match? leadingContinueOwnerCount: " + leadingContinueOwnerCount)
          console.warn("periodicAssetStatusCheck(): don't match? auctionQC: " + auctionQC)
          console.warn("periodicAssetStatusCheck(): don't match? leadingContinueQC: " + leadingContinueQC)
          determineMostAdvanced = true
        }
      } else {
        console.warn("periodicAssetStatusCheck(): latest auction and continue limbs don't match. Which is more advanced?")
        console.error("periodicAssetStatusCheck(): don't match? continueLimb: " + continueLimb)
        console.error("periodicAssetStatusCheck(): don't match? auctionLimb: " + auctionLimb)
        determineMostAdvanced = true
      }

      if ( determineMostAdvanced ) {
        //NOTE: it should be rare, if ever, that auction would be more advanced than continue
        //      It would only represent a hiccup in our scanning - NOT reality

        console.error("periodicAssetStatusCheck(): something didn't match: limb, OC, or QC. IMPLEMENT ME: determine which is more advanced (in OC, QC): leading Continue, or leading Auction");
        console.warn("periodicAssetStatusCheck(): for now, clearing leadingAuction, leadingContinue state variables.");

        //lATx = null
        //lAL = null
        //lADTx = null
        //auctionGranted = false   // remove since defaults to this
        //auctionWaiting = false   // remove since defaults to this
        //auctionExecuted = false  // remove since defaults to this
      } else {

        const leadingAuctionDecodedTx = await queryFetchDecodeTx(latestAuction.tx, db, false)
        if ( leadingAuctionDecodedTx === null || typeof leadingAuctionDecodedTx === 'number' ) {
          console.error("periodicAssetStatusCheck(): trouble getting txid " + latestAuction.tx)
          console.error("NOT FINALLY SETTING leadingContinueDTx 1")
          return
        }
        const leadingAuctionSubModeHex = leadingAuctionDecodedTx.outputStates[0].subMode
        if ( !leadingAuctionSubModeHex || leadingAuctionSubModeHex === null ) {
          console.error(" pASC: hmm. decoded leading auction: ", leadingAuctionDecodedTx)
        } else {
          const leadingAuctionSubMode = hexByteToAscii(leadingAuctionSubModeHex)
          if ( leadingAuctionSubMode === 'G' ) {
            console.warn(" WOO HOO! we see that the leading auction (matching with leading continue) has the top bidder GRANTing rights to execute.")
            auctionGranted = true
          } else if ( leadingAuctionSubMode === 'W' ) {
            console.warn(" WOO HOO! we see that the leading auction (matching with leading continue) is WAITING for rights to execute.")
            auctionWaiting = true
          } else if ( leadingAuctionSubMode === 'E' ||
                      leadingAuctionSubMode === 'e' ) {
            console.warn(" WOO HOO! we see that the leading auction (matching with leading continue) has been EXECUTED.")
            auctionExecuted = true
          }
        }

        lATx = latestAuction.tx
        lAL = auctionLimb
        lADTx = leadingAuctionDecodedTx
      }
    } else {
      //lATx = null
      //lAL = null
      //lADTx = null
      //auctionGranted = false   // remove since defaults to this
      //auctionWaiting = false   // remove since defaults to this
      //auctionExecuted = false  // remove since defaults to this
    }

    // We combine these .setState()s parameters above, with swipeText
    // (and anything else), further below - resulting in fewer calls to setState(),
    // leading to FEWER calls to render() (half? more efficient)

    // initialize to current values
    let rs    = parent.state.renewalStatus
    let lCTx  = parent.state.leadingContinueTx
    let lCDTx = parent.state.leadingContinueDTx
    let sT    = parent.state.swipeText

    if ( JSON.stringify(newRenewalStatus) !== JSON.stringify(parent.state.renewalStatus) ) {
      console.log("periodicAssetStatusCheck(): new status doesn't match old status (comparing stringifies). Setting state...")
      // update the rendering
      rs = newRenewalStatus
      lCTx = latestContinue.tx
      lCDTx = leadingContinueDecodedTx
    }

    if ( newRenewalStatus === null ) {
      console.log("periodicAssetStatusCheck(): since renewalStatus is null, clearing swipeText (setting state)")
      sT = null
    } else if ( parent.state.swipeText !== newRenewalStatus.swipeText ) {
      console.log("periodicAssetStatusCheck(): since swipe text has changed, setting new state")
      // update the rendering
      sT = newRenewalStatus.swipeText
    }

    //TODO: setState of the leading continue:
    //        leadingContinueDecodedTx                      DONE
    //            leadingContinueBlockHeight                ??
    //                - determine when we can post another
    //            renewalDeadline                           ??
    //        leadingUpdateDecodedTx                        ??
    //            leadingUpdateBlockHeight                  ??
    //                - determine when we can post another  ??
    //            leadingUpdateDeadline                     ??

    parent.setState({
        leadingAuctionTx:   lATx,
        leadingAuctionLimb: lAL,
        leadingAuctionDTx:  lADTx,
        auctionGranted:     auctionGranted,
        auctionWaiting:     auctionWaiting,
        auctionExecuted:    auctionExecuted,
        renewalStatus:      rs,
        swipeText:          sT,
        leadingContinueTx:  lCTx,
        leadingContinueDTx: lCDTx
    })
    console.error("FINALLY SETTING leadingContinueDTx")

    //FIXME: soon, move blockHeight-updating logic here, from TxStatsDisplay ?

    await checkDanglingPaths(db, odtx.limbName)
  } // periodicAssetStatusCheck()


  // If we've switched to a different asset, we don't want to wait for the
  // asset check timer to fire. We want to check the asset immediately (and periodically thereafter)
  async expeditePeriodicCheck() {
    const timerId = this.state.periodicCheckTimerId
    if ( timerId === null ) {
      console.warn("expeditPeriodicCheck(): timerId is null. skip it.")
      return
    }

    console.log("expeditePeriodicCheck(): We'll cancel the interval, run the check, then set the interval again")
    // It was previously setup in componentDidMount()
    clearInterval( timerId )

    // run it right now, then set it up to run periodically
    await this.periodicAssetStatusCheck(this, this.state.dbConnection);

    const parent = this
    const newTimerId =  setInterval(async function() {
                          await parent.periodicAssetStatusCheck(parent, parent.state.dbConnection);
                        }, this.state.assetCheckInterval);

    this.setState({periodicCheckTimerId: newTimerId})
  }

  componentWillUnmount() {
    console.error("ShizzleNav about to unmount. Will UNregister...")

    this.props.unregisterClient(this.props.parent, this, "shizzleNav")

    // fix Warning: Can't perform a React state update on an unmounted component
    // see: https://stackoverflow.com/questions/53949393/cant-perform-a-react-state-update-on-an-unmounted-component
    this.setState = (state,callback)=>{
        return;
    };
  }

  async componentDidMount() {
    console.warn("SN componentDidMount()")
    this.props.registerClient(this.props.parent, this, "shizzleNav")

    console.log("ShizzleNav componentDidMount(): OPENING DB  <-------");

    //FIXME: can/should this be const?
    const db = await openDB();
    console.log("ShizzleNav componentDidMount(): SETTING DB in state <-------");

    this.setState({dbConnection: db});
    console.log("ShizzleNav componentDidMount(): DB is SET in state <-------");

    // set timer for checking dangling paths,
    // and especially for checking the leading continue

    //FIXME: maybe we want to move the timer from TxStatsDisplay

    const parent = this
    // for checking the Continue (and update) state, we shouldn't need
    // to check this that often - especially if it was recently built upon

    // run it right now, then set it up to run periodically
    await this.periodicAssetStatusCheck(this, db)

    //NOTE: this could be cancelled (and re-instated) in expeditePeriodicCheck()
    //FIXME: also, periodically check the blockHeight, and disseminate it
    const timerId = setInterval(async function() {
                      await parent.periodicAssetStatusCheck(parent, db);
                    }, this.state.assetCheckInterval);
    this.setState({periodicCheckTimerId: timerId})
  } // componentDidMount()

  // update/save the txid we'll probably want to fetch
  handleSearchChange(entry) {
    console.log("Setting SE to " + entry)

    this.setState({searchEntry: entry});
  }

  // Fetch and decode THIS tx (searchEntry)
  async handleSearchSubmitted(event) {
    if ( this.state.searchEntry.length === 64 ) {

      // phase 1:  update tx shown in TxStatsDisplay, and begin query
      console.log("hSS(): setting txToConsider to it (" + this.state.searchEntry + ")");

      const ret = await this.saveAndFetch(this.state.searchEntry)
      if ( ret === null || typeof ret === 'number' ) {
        console.log("FAILED search for tx")
        alert("ERROR: failed to retrieve the transaction " + this.state.searchEntry + ": " + ret)
      }
    } else {
      console.log("wrong length for txid: " + this.state.searchEntry.length)
      if ( this.state.searchEntry.length !== 0 ) {
        alert("A transaction id needs to be 64 hexadecimal characters. It's currently " + this.state.searchEntry.length)
      }
    }
  }

  // Delete this tx (searchEntry) (and its descendants) from db
  async handleDeleteSubmitted(event) {
    if ( this.state.searchEntry.length === 64 ) {

      // phase 1:  update tx shown in TxStatsDisplay, and begin query
      console.warn("handleDeleteSubmitted(): about to delete descendants of (" + this.state.searchEntry + ")");

      // deletes any faded or bogus children, AND the decoded form of this tx
      await recursivelyDeleteDescendantSpends(this.state.searchEntry, this.state.dbConnection)

      console.log("DONE deleting descendant spends of " + this.state.searchEntry + " WILL RE-DRAW it")


      //FIXME: MAYBE we should delete our decodedTx record of searchEntry (current tx)
      //       This way we'll re-evaluate the confirmedOutputs[], followable[],
      //       buildable[], foundBuildable, and foundFollowable.


      // we'll want to re-draw parent
      await this.saveAndFetch(this.state.searchEntry)
      console.log("DONE re-drawing base tx " + this.state.searchEntry)
    } else {
      console.log("handleDeleteSubmitted(): wrong length for txid: " + this.state.searchEntry.length)
      if ( this.state.searchEntry.length !== 0 ) {
        alert("A transaction id needs to be 64 hexadecimal characters. You've specified " + this.state.searchEntry.length)
      }
    }
  }

  getAddressForKeyForDecryption( dtx ) {

    console.warn("SN: getAddressForKeyForDecryption(): BTW: decodedTx: ", dtx)

    // get SENDER's public key, and OUR private key

    console.warn("getAddressForKeyForDecryption():  dialogMostRecentOwnerPubKey:    " + dtx.dialogMostRecentOwnerPubKey)
    console.warn("getAddressForKeyForDecryption():  dialogMostRecentVisitorPubKey: " + dtx.dialogMostRecentVisitorPubKey)

    if ( typeof dtx.dialogMostRecentVisitorPubKey == 'undefined'
          || typeof dtx.dialogMostRecentVisitorPubKey == 'undefined'
          || ( dtx.dialogSentBy !== 'guest' && dtx.dialogSentBy !== 'owner' )
       ) {
      throw new Error("73124: trouble finding required parameters regarding " + this.state.searchEntry2)
    }

    let pubKeyToFindOurAddressThenPriv
    if ( dtx.dialogSentBy === 'guest' ) {
      console.warn("getAddressForKeyForDecryption(): Visitor sent this, so, will use OUR (owner) privKey - and address dbKey can be found from OWNER pubkey")
      pubKeyToFindOurAddressThenPriv = dtx.dialogMostRecentOwnerPubKey
    } else if ( dtx.dialogSentBy === 'owner' ) {
      console.warn("getAddressForKeyForDecryption(): Owner sent this, so, will use OUR (guest/visitor) privKey - and address dbKey can be found from VISITOR pubkey")
      pubKeyToFindOurAddressThenPriv = dtx.dialogMostRecentVisitorPubKey
    } else {
      console.warn("getAddressForKeyForDecryption(): Hmm. Not sure which participant sent this. We can't decrypt this.")
      throw new Error("73123: CODING ERROR: not sure which participant posted this. So, can't decrypt it.")
    }

    // recover needed address (the dbKey) from OUR pubkey
    const ourBsvPubKey = bsv.PublicKey.fromHex(pubKeyToFindOurAddressThenPriv)
    const addressToUseToFindPriv = bsv.Address.fromPublicKey(ourBsvPubKey, 'testnet').toString()
    //const addressToUse = bsvPubKey.toAddress().toString()
    console.log("SN: getAddressForKeyForDecryption(): our address to use: " + addressToUseToFindPriv)

    return addressToUseToFindPriv
  } // getAddressForKeyForDecryption()

  // fetch tx that's soon to be in **searchEntry2**
  async saveAndFetch(se2) {
    if ( typeof se2 !== 'undefined' && se2 !== null && se2.length === 64 ) {
      console.log("saveAndFetch(): fetching requested transaction: " + se2);

      // query and decode the tx
      const txState = await queryFetchDecodeTx( se2, this.state.dbConnection );
      if ( txState === null || typeof txState === 'number' ) {
        //alert("ERROR: failed to retrieve the transaction " + se2 + ": " + txState)
        console.warn("saveAndFetch() failed for txid " + se2 + ": " + txState)

        // clean-up (stop the swirling loading thing)
        this.setState({searchEntry2: '', txToConsider: '', decodedTx: null});
        //this.setState({txToConsider: ''});

        //FIXME: maybe the alert should happen at the CALL to saveAndFetch()
        //       The resolution could be different, depending on from where it was called
        return txState
      }

      console.log("saveAndFetch(): Copying requested txid to searchEntry2, and txToConsider. Clearing display.");

      // First, save new value for searchEntry2, and clear the display we're about to update
      // MOVED to setState further below
      //this.setState({searchEntry2: se2, txToConsider: se2, decodedTx: null});

//FIXME: review if searchEntry2 and txToConsider are redundant

      console.log("saveAndFetch(): SETTING theTx to " + se2 + "   BTW, state: " + txState)
      window.theTx = se2
if ( typeof txState == 'undefined' ) {
  alert("whoops! undefined txState?")
}
      //FIXME: if guest-post, analyzeContent() should realize we need to use input1Params.content
      const analysis = analyzeContentPrefixFlag(txState)

      //NOTE: did we mean to setState() here?
      if ( analysis.contentAltered ) {
        console.error("CODE INSPECTION WARRANTED: the content was altered, but we might not be preserving the analysis (incorrectly setting state)")
      }
      //NOTE: did we mean to setState() here? Moved to the setState() further below
      //txState.contentAltered = analysis.contentAltered
      //txState.alteredContent = analysis.contentToPresent

      let addressOfKeyForDecryption = null
      if ( analysis.needToDecrypt ) { //FIXME: rename contentAltered?

        //FIXME: what if it's a guest-post? use input1Params.content

        // Display this for now. We'll soon have the decrypted content
        //txState.alteredContent = 'Beginning process of decrypting payload...'

        // This value is used by the BsvKeyRetriever (parameter addressOfKeyToGet)
        // when we setState() of keyAddressForDecryption below

        try {
          //NOTE: this assumes this post is a DIALOG. Nowadays, it could be
          //      a transient self-post, and shizzleNav doesn't handle that
          addressOfKeyForDecryption = this.getAddressForKeyForDecryption(txState)
        } catch (error) {
          //FIXME: instead of throw/catch, do something else?
          console.warn("ShizzleNav: NOTE: we don't have the key to decrypt.")
          console.warn("ShizzleNav: we're probably here because this.getAddressForKeyForDecryption assumed this was a DIALOG entry, but it's NOT.")
          alert("NOTE: if this tx ISN'T a dialog post, we (shizzleNav) don't yet handle decryption for it.")
        }
      }

      // phase 2:  display decoded tx in TxStatsDisplay
      //console.log("saveAndFetch(): finally got response, then decoded state: ", txState);
      var stateString = JSON.stringify( txState );
//FIXME: can this fail - if the content is screwy on purpose?

      if ( analysis.needToDecrypt ) {
        // setState() so that we render a pop-up (<BsvKeyRetriever>) to collect the password.
        // When satisfied, that will call return a BSV private key to handleRetrievedDecryptionKey(),
        // where we'll finish decrypting.
        this.setState({
                        searchEntry2: se2,
                        txToConsider: se2,

                        decodedTx: stateString,
                        openModalForFundingDecryptionKey: true,
                        keyAddressForDecryption: addressOfKeyForDecryption,

                        contentAltered: analysis.contentAltered,
                        alteredContent: analysis.alteredContent
                      })
      } else {
        // update state for rendering
        this.setState({
                        searchEntry2: se2,
                        txToConsider: se2,
                        decodedTx: stateString,

                        contentAltered: analysis.contentAltered,
                        alteredContent: analysis.alteredContent
                      });
      }

      // Set the address bar URL (pathname). The hostname was set once - in ShizzleNav CTOR
      // ( This strips off leading 'bshz:/' )
      if ( txState.address ) {
        console.warn("being asked: saveAndFetch() pushing nav/ and " + txState.address.split('/').slice(2).join('/'))
        try {
          // NOTE: not sure why I found it necessary to first clear the state,
          //       and THEN push the desired address. It wasn't always this way
          window.history.pushState("", "", "/")
          window.history.pushState("", "", "nav/" + txState.address.split('/').slice(2).join('/'))
          //alert("Was able to push browser state when address is " + txState.address)
        } catch (error) {
          alert("whoops. failed pushing browser state when address is " + txState.address + "  ERROR: ", error)
        }

      } else {
        console.warn("Tx has no state (probably legacy). Blanking the address bar path")
        window.history.pushState("", "", "/")
      }

    } else {
      console.log("saveAndFetch(): se2 is invalid: ", se2)
      alert('Please enter a transaction ID to fetch and decode');
      return null
    }

    return true
  } // saveAndFetch()

//FIXME: better name
  handleOptionChosen(value) {
    console.log("HandleOptionChosen: an option, " + value
              + ",  was selected");
    if ( value === "build" ) {
      //console.log("will set buildLocking");
      this.setState({ buildLocking: true, jumpTo: false });
    }
    if ( value === "jump" ) {
      //console.log("will set jumpTo");
      this.setState({ jumpTo: true, buildLocking: false }); 
    }
    if ( value === "visit" ) {
      this.props.handleSwitchToShizzleView()
    }
  }

  handleBuildTypeChosen(value) {
    console.log("handleBuildTypeChosen: " + value);
    this.setState({buildType: value});
  }

  async handleFollow(chosenOutIndex) {
    console.log("HandleFollow(): got outIndex of " + chosenOutIndex);
    // state.searchEntry2 gets updated soon. Take note of parent now.
    const parentTx = this.state.searchEntry2;
    console.log("HandleFollow(): will now query db for tx beyond which we'll look: " + parentTx);

    //FIXME: also, maybe add a separate button to click, to initiate db lookup

    const odtx = JSON.parse( this.state.decodedTx );
    if ( odtx === null ) {
      alert("Trouble finding the next tx. Something faded?")
    } else {
      console.log("HandleFollow(): object decodedTx: ", odtx)

      //NOTE: 2nd param is the output state OF THE output we choose to follow
      const nextTx = await findNextTx(this.state.dbConnection,
                                      odtx.outputStates[chosenOutIndex],
                                      this.state.searchEntry2,
                                      chosenOutIndex);
      if ( nextTx !== null ) {
        console.log("HandleFollow(): soon will set searchEntry2 to nextTx: " + nextTx);

        const ret = await this.saveAndFetch(nextTx)
        //FIXME: this could fail if the tx never confirmed for some reason (re-org, fade-away,)
        if (ret  === null || typeof ret === 'number') {
          // NOTE: this was all triggered within handleFollowClick().
          console.log("FAILED follow of " + nextTx);
          alert("ERROR: failed to follow output " + chosenOutIndex + " of transaction " + parentTx
                + ". We thought it led to tx " + nextTx + ", but got back " + ret)

          if ( ret === 404 ) {
            alert(  "Since this was a 404 (the follow-on tx doesn't actually exist), "
                  + "we think our db may have relied on a tx that never actually confirmed. "
                  + "We're going to re-fresh our db, and re-draw the parent tx.")
            //FIXME: first we need the lib to have taken corrective action (delete tx, and ALL of its children)
            await this.saveAndFetch(parentTx)
          }

          return
        }

      } else {
        console.log("HandleFollow(): Invalid (null) next tx. Perhaps this tx has invalid decoded outputs: ", parentTx);
      }
    }
  }

  // Here the GCD tell's us when a build output was clicked, and which one.
  // We then pass the choice to a modal we enable
  handleBuildOnOutput(value) {
    console.log("handleBuildOnOutput(): got value (outputIndex) of " + value);
    console.log("handleBuildOnOutput(): should now build on that output of " + this.state.searchEntry2);

    console.log("BTW: decodedTx as object: ", JSON.parse(this.state.decodedTx));
    console.log("BTW: chose output's mode: ", JSON.parse(this.state.decodedTx).outputStates[value].mode);
    const chosenMode = JSON.parse(this.state.decodedTx).outputStates[value].mode;

    // open modal. pass the mode, decodedTx (has the 0th mode, and the chosen mode),
    //         the txId (needed?), and the chosen output index.
    this.setState({chosenMode: chosenMode, outputIndexChosen: value, openBuilderModal: true});
  }

  async handlePrevious() {
    console.log("handlePrevious(): button clicked. BTW: currentTxId is " + this.state.searchEntry2);

    const odtx = JSON.parse( this.state.decodedTx );
    if ( odtx === null ) {
      alert("Trouble finding the previous tx. Something faded?")
    } else {
      //console.warn("handlePrevious(): JSON-parsed: ", odtx)
      const prevTx = odtx.input0PrevTxId
      console.warn("handlePrevious(): previous txid: ", prevTx)

      // Do a DB lookup. Where it might get more difficult:
      //   If we jumped into a tx, and we don't have records from whence it came.
      //   That won't be the normal course of action, but we may need to eventually solve it.
      //   Eventually, users may present a tx, and want to know if it's a valid part of the tree.

      //const prevTx = await findPrevTx(this.state.dbConnection, this.state.searchEntry2);
      //console.log("handlePrevious(): found previous txid: " + JSON.stringify(prevTx));
      if ( prevTx !== null ) {
        await this.saveAndFetch(prevTx);
      }
    }
  }

  async handleGuestPrevious() {
    console.log("handleGuestPrevious(): button clicked. BTW: currentTxId is " + this.state.searchEntry2);

    const odtx = JSON.parse( this.state.decodedTx );
    //console.warn("handleGuestPrevious(): JSON-parsed: ", odtx)
    if ( odtx === null ) {
      alert("Trouble finding the previous GUEST tx. Something faded?")
    } else {
      const prevTx = odtx.input1PrevTxId
      console.warn("handleGuestPrevious(): previous GUEST txid: ", prevTx)

      // Do a DB lookup. Where it might get more difficult:
      //   If we jumped into a tx, and we don't have records from whence it came.
      //   That won't be the normal course of action, but we may need to eventually solve it.
      //   Eventually, users may present a tx, and want to know if it's a valid part of the tree.

      //const prevTx = await findPrevTx(this.state.dbConnection, this.state.searchEntry2);
      //console.log("handleGuestPrevious(): found previous txid: " + JSON.stringify(prevTx));
      if ( prevTx !== null ) {
        await this.saveAndFetch(prevTx);
      }
    }
  }

  async handleNewlyBuiltTx(val) {
    //console.log("SNav: handleNewlyBuiltTx(): got val ", val);

    this.closeBuilderModal();

    if ( val && val !== null ) {
      //console.log("SNav: Since we get a value, we'll pop-up something, and refresh/re-draw");
      console.log("SNav: We must've built a tx. Let's pop-up something, and maybe pause a few seconds (15 on testnet)");


      //FIXME: delay this by triggering it within render()?

      alert("We've built a tx, but we need to pause a few seconds for it to propagate...");
      //console.log("SNav: done with alert. REFRESHING...");
      await this.saveAndFetch(val);
    } else {
      console.log("SNav: nothing useful in val.");
    }
  }

  // closes the modal
  closeBuilderModal() {
    console.log("SNav::closeBuilderModal");

    // the modal's 'open' is set to this state
    this.setState({openBuilderModal: false});
  }

  // This would be used by ContentPresenter (as jumpToLinkedAsset())if user clicks on a shizzle link
  // (pointing to a shizzle address)
  async jumpToTx(txid) {
    //NOTE: we couldn't just call saveAndFetch() directly. 'this' was a problem, within saveAndFetch()
    console.log("SNav::jumpToTx() tx: ", txid)

    const ret = await this.saveAndFetch(txid)
      if ( ret === null || typeof ret === 'number' ) {
        console.warn("FAILED search for tx for JUMP")
      }
  }

  async handleRetrievedDecryptionKey(us, results) {
    console.warn("handleRetrievedDecryptionKey(): final step of decrypting content/payload. results: ", results)

    //alert("handleRetrievedDecryptionKey() Look at those results. Should get single result, right? What now?")

    // We hope to use the (single) retrieved key to help decrypt content

    if ( results.length === 0 ) {
      console.error("WRONG password? OR, no such key found in DB. MODAL instead of alert - to maybe try again?")
      alert("Empty result retrieving key. Bad password, or no such key found in DB?")
      return
    }

    const dtxString = us.state.decodedTx
    if ( typeof dtxString == 'undefined' || !dtxString ) {
      console.error("hmm. invalid dtx after retrieving decryption key?! ", us.state.decodedTx)
      // Can this even happen?
      return
    }

    const odtx = JSON.parse( dtxString );
    if ( odtx === null ) {
      console.error("Trouble finding the tx after retrieving decryption key?!")
      // Can this even happen?
      return
    }

    // We're here because we need to decrypt
    // The cipher bundle (iv + ciphertext) was placed here
    const payloadHex = odtx.alteredContent

    let ourPriv = results[0]
    const ourPrivKeyHeX = new bsv.PrivateKey.fromWIF(ourPriv)
    //console.log("privKeyHex: ", privKeyHeX.toHex())
    const ourPrivToUseBuffer = Buffer.from( ourPrivKeyHeX.toHex(), 'hex')

    let senderPubKeyToUse
    if ( odtx.dialogSentBy === 'guest' ) {
      senderPubKeyToUse = odtx.dialogMostRecentVisitorPubKey
      console.warn("handleRetrievedDecryptionKey(): Guest sent this, so, will use GUEST pubkey: " + senderPubKeyToUse)
    } else if ( odtx.dialogSentBy === 'owner' ) {
      senderPubKeyToUse = odtx.dialogMostRecentOwnerPubKey
      console.warn("handleRetrievedDecryptionKey(): Owner sent this, so, will use OWNER pubkey: " + senderPubKeyToUse)
    } else {
      console.warn("handleRetrievedDecryptionKey(): Hmm. Not sure which participant sent this. We can't decrypt this.")
      throw new Error("73127: CODING ERROR: not sure which participant posted this. So, can't decrypt it.")
    }

    console.warn("sendersPubKeyToUse: ", senderPubKeyToUse)
    const senderPubToUseBuffer = Buffer.from( senderPubKeyToUse, 'hex')
    //console.warn("senderPubToUseBuffer: ", senderPubToUseBuffer)

    // We have our priv key, and the sender's pub key
    // generate shared key

    // Diffie Hellman shared keys are derived from the Private Key of one person,
    // and the Public Key of another. The MAGIC is that BOTH people can do this
    // to arrive at the SAME shared number/key - even though they're using
    // different numbers.
    // In our case we're using keypairs on the Secp256k1 curve.
    const sharedKey = eccryptoJS.derive(
      ourPrivToUseBuffer,
      senderPubToUseBuffer
    );
    //console.log("decryptThisPayload(): sharedKey: ", sharedKey)
    //console.warn("SN: dTP(): our shared Key: ", sharedKey.toString('hex'))


    console.log("SN: hRDK(): " + payloadHex)
    const iv = payloadHex.substring(0, 32) //payloadHex.substring(12, (12+32))
    console.log("SN: hRDK(): iv: " + iv)
    const cipherText = payloadHex.substring(32) //payloadHex.substring(44)
    console.log("SN: hRDK(): cipherText: " + cipherText + '\n')


    // set an aesKey from that
    const aesKey = sharedKey
    const ivBuff = Buffer.from(iv, 'hex')
    const cipherBuff = Buffer.from(cipherText, 'hex')

    let decrypted
    try {
      decrypted = await eccryptoJS.aesCbcDecrypt(ivBuff, aesKey, cipherBuff);
    } catch (err) {
      console.error("hRDK(): error decrypting: ", err)
      console.error("Trouble AES-decrypting. WRONG PASSWORD or key? MODAL TO INFORM USER?")
      return
    }
    console.log("hRDK(): decrypted ascii message: " + decrypted)

    // NOW, we finish what saveAndFetch() started when it opened a BsvKeyRetriever modal
    // (by setting state on openModalForFundingDecryptionKey, and keyAddressForDecryption)
    // We'll update the state of decodedTx with the decrypted content.

    console.log("hRDK(): finishing up: updating state of decodedTx with decrypted 'alteredContent' of tx: " + us.state.searchEntry2)

    // Update the decoded tx state to include the decrypted content
//FIXME: did we mean to setState here?
    odtx.contentAltered = true
    odtx.alteredContent = Buffer.from(decrypted).toString('hex')
    var stateString = JSON.stringify( odtx );
    us.setState({decodedTx: stateString});

    // Set the address bar URL (pathname). The hostname was set once - in ShizzleNav CTOR
    // ( This strips off leading 'bshz:/' )
    console.log("NOTE: we THOUGHT we might set address bar to " + odtx.address.split('/').slice(2).join('/')
          + "\nPerhaps it was done already (in saveAndFetch()) while the BsvKeyRetriever modal was being displayed.")
  }

  closeBsvDecryptionKeyRetrieverModal() {
    console.warn("closeBsvDecryptionKeyRetrieverModal(): Closing modal that gets decryption key from bsv funding keypair...")
    //alert("Closing BsvDecryptionKeyRetrieverModal. \nShould we do some cleanup? \nClear some state? Was it canceled, or successful?")
    this.setState({ openModalForFundingDecryptionKey: false })
  }

  render() {  // ShizzleNav
    const entryMade = this.state.txToConsider !== ''; // if we've been here already
    let loading = false;
    if ( !this.state.decodedTx ) {
      loading = entryMade
    }

    const dtx = this.state.decodedTx;
    let contractType;
    let chosenIndex = this.state.outputIndexChosen === null ? 0 : this.state.outputIndexChosen;
    let chosenMode;
    let modeName;
    let limbText = '';
    let swipeText = this.state.swipeText
    let guestPost = false
    let guestLimb = null

    let previousButton = <Button onClick={this.handlePrevious}>Previous Tx</Button>
    let guestPreviousButton = null

    let sentByMention = ''
    if ( dtx && dtx !== null ) {
      console.log("SNav render() loading: " + loading)
      console.log("SNav render() entryMade: " + entryMade)
      console.log("SNav render() blocksBeyondMin is " + this.state.blocksBeyondMin)
      console.log("SNav render() leadingContinueTx is ", this.state.leadingContinueTx)
      console.log("SNav render() leadingContinueDTx is ", this.state.leadingContinueDTx)
      console.log("SNav render() blockHeight is ", this.props.blockHeight)
      console.log("SNav render() current swipeText: ", swipeText)
      console.log("SNav render() chosenIndex: " + chosenIndex)

      const odtx = JSON.parse( dtx );
      console.log("SNav render() tx " + this.state.txToConsider + "  odtx: ", odtx)
      console.log("hostGuestMode: " + odtx.input0Params.hostGuestMode)
      if ( odtx.input0Params.hostGuestMode === 1 || odtx.input0Params.hostGuestMode === 4 ) {
        guestPost = true
        console.warn("ShizzleNav: Rendering a guest-post")
        guestLimb = hexStringToAscii( odtx.outputStates[1].namePath )
        previousButton = <Button onClick={this.handlePrevious}>Previous HOST Tx ('{odtx.limbName}')</Button>
        guestPreviousButton =
          <Button onClick={this.handleGuestPrevious}>Previous GUEST Tx ('{guestLimb}')</Button>
      }

      // Did we just jump to a new asset? If so, re-generate any swipeText
      if ( odtx.limbName !== this.state.previousAsset ) {
        //FIXME: maybe rename from previousAsset to assetName?
        //FIXME: might this fire late, and wipe-out a warning (after it's been correctly set)?
        console.warn("SNav render(): we're in a new asset (from " + this.state.previousAsset
                    + ", now " + odtx.limbName + "). Clearing swipeText.")
        swipeText = null  // immediately clear display

        // React warns us to not update state within render(),
        // so, we'll do it asynchronously
        const parent = this
//        execAsync( async function() {
//          console.warn("ASYNC: FINALLY clearing state 'swipeText', and updating 'previousAsset' to '" + odtx.limbName + "'")
//          parent.setState({swipeText: null, previousAsset: odtx.limbName})
//          await parent.expeditePeriodicCheck()
//        })
      } else {
        console.error("SNav render(): swipeText time...")
        // calculate swipeText right now, but based on cached 'leadingContinueDTx'
        // Snippet taken from periodicAssetStatusCheck():
        //FIXME: avoid calculating this for every render()? Maybe only if devTestMode or bbm changes?
        if ( this.state.leadingContinueDTx !== null ) {
          let newRenewalStatus
          if ( window.devTestMode ) { //this.state.devTestMode ) {
            console.log("SNav render(): devTestMode calculation of renewal status...")
            const chosenMode = hexByteToAscii( this.state.leadingContinueDTx.outputStates[ 0 ].mode );
            newRenewalStatus = adjustRenewalStatus(this.state.leadingContinueDTx,
                                                  this.state.leadingContinueDTx.outputStates[ 0 ].blockNumInt,
                                                  chosenMode,
                                                  parseInt(this.state.blocksBeyondMin)).status
          } else {
            console.log("SNav render(): normal calculation of renewal status...")
            newRenewalStatus = reviewRenewalStatus(this.state.leadingContinueDTx, this.props.blockHeight)
          }
          console.warn("SNav render():    NEW Renewal Status: ", newRenewalStatus)
          if ( newRenewalStatus === null ) {
            console.warn("SNav render(): perhaps UNCLAIMED? setting null swipeText")
            swipeText = null
            console.error("SNav render(): swipeText NULL ?!!!")
          } else {
            swipeText = newRenewalStatus.swipeText
            console.error("SNav render(): swipeText set: ", swipeText)
          }
          console.log("fresh swipeText: ", swipeText)
        } else {
          console.error("SNav render(): swipeText??? this.state.leadingContinueDTx is NULL")
          console.error("SNav render(): this.state.renewalStatus: ", this.state.renewalStatus)
        }
      }

      console.warn("shizzleNav: odtx: ", odtx)
      console.warn("shizzleNav: chosen index: " + chosenIndex)
      if ( chosenIndex && this.state.openBuilderModal ) {
        const isDialog = odtx.outputStates[chosenIndex].mode === '44'

        //NOTE we're having output 0's mode sort of define/label the entire Tx.
        //     Semantics.
        contractType = odtx.outputStates[0].contract;

        // Identify what kind of contract output we're about to build on,
        // to inform the user
        console.log("getting mode of output " + chosenIndex);
        chosenMode = odtx.outputStates[ chosenIndex ].mode;
        modeName = nameFromMode(chosenMode);
//FIXME: this is getting complicated. Maybe just carry the limb names (all two)?
        limbText = isDialog ?
                  <>
                      <span style={{color: 'blue'}}>
                        <strong>
                          a Dialog between two parties
                        </strong>
                      </span>
                  </>
                      :
                  <>
                      limb '<span style={{color: 'blue'}}>
                        <strong>
                          { hexStringToAscii(odtx.outputStates[chosenIndex].namePath) }
                        </strong>
                      </span>'
                  </>
      }

//FIXME: watch out if dialog is on output 2, or even 3 (if spawning while guest poster/owner is spawning)
      if ( odtx.dialogSentBy === 'owner' ) {
        sentByMention = <>
                          <span style={{color: 'blue'}}>
                              This transaction was sent (and ENCRYPTED) by the Owner of this dialog (from domain '{odtx.outputStates[0].ownerName}').
                              We'll need to retrieve the private BSV key that <strong>we (the Visitor)</strong> most-recently used to fund a transaction on this ongoing Dialog.
                              THAT will be used to help decrypt the message that was sent.
                          </span>
                          <p></p>
                        </>
      } else if ( odtx.dialogSentBy === 'guest' ) {
        sentByMention = <>
                          <span style={{color: 'blue'}}>
                              This transactioin was sent (and ENCRYPTED) by the Visitor of this dialog (from domain '{odtx.outputStates[0].visitorName}').
                              We'll need to retrieve the private BSV key that <strong>we (the Owner)</strong> most-recently used to fund a transaction on this ongoing Dialog.
                              THAT will be used to help decrypt the message that was sent.
                          </span>
                          <p></p>
                        </>
      }
    } // odtx
    let article = 'a'
    if (modeName === 'append') {
      article = 'an'
    }

    const bsvDecryptionKeyGetter = this.state.openModalForFundingDecryptionKey ?
                              <BsvKeyRetriever parent={this}
                                                extraVerbage={sentByMention}
                                                addressOfKeyToGet={this.state.keyAddressForDecryption}
                                                resultsHandler={this.handleRetrievedDecryptionKey}
                                                closeBsvRetriever={this.closeBsvDecryptionKeyRetrieverModal}/>
                            : null;

    //console.log("SETTING theTx to " + this.state.txToConsider)
    //window.theTx=this.state.txToConsider

    //NOTE: we don't want closeOnDocumentClick, nor closeOnDimmerClick
    return (
      !this.props.show ?
      null
        :
      <div className="main">
        <link rel="icon" type="image/svg+xml" href="/assets/images/favicon.svg"></link>
<link rel="icon" type="image/png" href="/assets/images/favicon.png"></link>

        {
          this.state.buildType === '' ? ( null ) : ( <TxBuildPane type={this.state.buildType} />
              )
        }
        <div style={{padding: "20px"}}>
        <p>This is the ShizzleNav. It's a technical tool to help delve into the BitShizzle transaction tree.</p>

        <Grid celled>
          <Grid.Row>
            <Grid.Column width={2}>
            </Grid.Column>
            <Grid.Column width={14}>
              <MenuCard onOptionChosen={this.handleOptionChosen} parentSNav={this}/>
            </Grid.Column>
          </Grid.Row>


          <Grid.Row>
            <Grid.Column width={2}>
            </Grid.Column>
            <Grid.Column width={3}>
              {
                  this.state.jumpTo ? (
                    <div className="tx-search-bar">
                      <TxSearchBar onSearchChange={this.handleSearchChange}
                                  value={this.state.searchEntry}
                                  onSearchSubmitted={this.handleSearchSubmitted}
                                  onDeleteSubmitted={this.handleDeleteSubmitted} />
                    </div>
                  ) : ( null )
              }
              {
                  this.state.buildLocking ? ( <LockingScriptOptions
                      onBuildTypeChosen={this.handleBuildTypeChosen} /> ) : ( null )
              }
            </Grid.Column>
          </Grid.Row>


          <Grid.Row>
            <Grid.Column width={2}>

              <Button.Group vertical>
                {previousButton}
                {guestPreviousButton}
              </Button.Group>
            </Grid.Column>

            <TxStatsDisplay txToDisplay={this.state.txToConsider}
                            txStateToPresent={this.state.decodedTx}
                            blockHeight={this.props.blockHeight}
                            onTxToFollow={this.handleFollow}
                            swipeText={swipeText}
                            onBuildOutSelected={this.handleBuildOnOutput}/>
          </Grid.Row>
        </Grid>

        <div>
          {loading && <div className="ui active inline loader">Retrieving transaction content...</div>}
          {entryMade && !loading && <ContentPresenter contentPresenterTx={this.state.txToConsider}
                                                      parent={this}
                                                      decodedTx={this.state.decodedTx}
                                                      jumpToLinkedAsset={this.jumpToTx}
                                                      guestPost={guestPost}/>}
        </div>
        </div>

        <Modal dimmer='blurring' open={this.state.openBuilderModal}>
          <Modal.Header className="modaledge">
            Building {article} {modeName} transaction on {limbText}
            <br></br>
            Output {this.state.outputIndexChosen} &nbsp;
                of '{contractType}' Tx {this.state.txToConsider}
          </Modal.Header>

          <ContractBuilder  decodedTx={dtx}
                            blockHeight={this.props.blockHeight}
                            chosenMode={this.state.chosenMode}
                            outputChosen={this.state.outputIndexChosen}
                            txOnWhichToBuild={this.state.txToConsider}
                            renewalStatus={this.state.renewalStatus}
                            handleNewTx={this.handleNewlyBuiltTx}
                            onCancel={this.closeBuilderModal}
                            bbm={this.state.blocksBeyondMin}/>
        </Modal>

        {bsvDecryptionKeyGetter}
      </div>
    );
  } // ShizzleNav render()
}

export default ShizzleNav;

