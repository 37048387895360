import React from 'react';

import { Button, Checkbox, Container, Divider, Dropdown, Form, Grid,
    Input, Menu, Message, Modal, Popup, Radio, TextArea } from 'semantic-ui-react'

import { openDB, getAllSubscriptions } from './buildShizzle';

class SubscriptionsModal extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            //domainToAdd: '',
            subscriptionsArray: []
            };

      this.handleCancel  = this.handleCancel.bind(this);
      this.buildListFromArray                  = this.buildListFromArray.bind(this);
      this.listClicked                         = this.listClicked.bind(this);
    }

    async componentDidMount() {
        console.warn("subscriptionsModal componentDidMount()")

        const db = await openDB();
        const subscriptionsArray = await getAllSubscriptions(db)

        console.log("subscriptionsModal componentDidMount(): subscriptions: ", subscriptionsArray)

        this.setState({subscriptionsArray: subscriptionsArray})
    }

    handleCancel() {
        this.props.closeModal(this.props.parent)
    }

    listClicked(e, idx) {
        if (e.type === 'click') {
            console.log('listClicked: Left click');
        } else if (e.type === 'contextmenu') {
            console.log('listClicked: Right click');
        }
        const domainThatWasClicked = this.state.subscriptionsArray[idx].name.toLowerCase()
        console.warn("subsModal listClicked(): The list was CLICKED!")
        console.warn("subsModal listClicked(): idx: " + idx)
        console.warn("domain: " + domainThatWasClicked)

        this.props.domainClickHandler(this.props.parent, domainThatWasClicked)
        this.handleCancel()
        /*
        this.setState({domainToVisit: domainThatWasClicked,
            reachedLimb: '',
            finalQuarterlyTxId: null, decodedTx: ''})
        */
    }

    //FIXME: extra/share this?
    buildListFromArray(arr) {
        if ( arr === null ) {
            return null
        }

        let listArray=[]
        for ( let i = 0; i < arr.length; i++ ) {
            listArray[i] = {
                            idx:        i,
                            name:       arr[i].name,
                            ownerCount: arr[i].ownerCount
            }
            console.log("    DM buildListFromArray(): adding name " + listArray[i].name)
        }
        const keysList = listArray.map((key) =>
                        <li className="hoverLink" key={key.idx} onClick={       (event) => this.listClicked(event, key.idx)}
                                                                onContextMenu={ (event) => this.listClicked(event, key.idx)}>
                                <span style={{color: 'green'}}>{key.name}</span>
                            &nbsp; (owner #{key.ownerCount})</li>
                );

        return keysList
    }

    render() {
        const subsArray = this.state.subscriptionsArray

        console.log("subscriptionsModal render(): subsArray: ", subsArray)
        const arrList = this.buildListFromArray(subsArray)

        return <>
               <Modal open={true} size='small' dimmer='blurring'>
                    <Modal.Header className="modaledge">Your subscriptions</Modal.Header>
                    <Modal.Content>
                        Below is a list of all domains which you've registered to FOLLOW.
                        <br></br>
                        SOON: a button to REFRESH - to check for new txs from your subscriptions?
                        <br></br>
                        <Divider />
                        {arrList}
                        <Divider />

                        <Modal.Actions className="modaledge">
                            <Button field="cancelButton" onClick={this.handleCancel} positive>Done</Button>
                        </Modal.Actions>

                    </Modal.Content>
                </Modal>
               </>
    }
}

export default SubscriptionsModal;
