import React, { createRef } from 'react';

import { Button, Checkbox, Divider, Dropdown, Icon,
    Input, Message, Modal, Popup, Table, Segment, Sidebar, Sticky, Transition } from 'semantic-ui-react'

import ShizzleMasthead  from './shizzleMasthead';
import ShizzleView      from './shizzleView';
import Footer           from './footer';
import FeeSettingsModal from './feeSettingsModal';

import SiteStatusModal  from './siteStatusModal.js';

import DialogManager    from './dialogManager';

import GuestPostManager from './guestPostManager';

import PostPopup        from './postPopup.js';

import Claimer          from './claimer.js';

import { bshzColors }   from './bshzColors';

import {
    //saveEncryptedKeys,
    getEncryptedKeysFromPKH,
    //getP2PKHsJson,
    openDB,

    readSetting,
    saveSetting,

    getTxsWithProfileInfo,


    allocateAvailableRabin,

    //encryptData,
    decryptData,

    findAllDomainsOfMine,

    outdateDomainOfMine,

    checkIfDomainExpiredOrRenewable,


    findLatestUTXO,

    findADialog,

    addGuestPostRef,
    getGuestPostRef,

    registerDomainOfMine,

    //buildOnAppend,
    buildOnClaimOrPublish,
    buildOnAnUpdate,
    buildOnATransient,
    queryFetchDecodeTx,
    mySleep,
    simpleDecode,
} from './buildShizzle.js';

import {
    hexByteToAscii,
    hexStringToAscii,
} from './commonFuncs';

import {  encryptMessage, getBitcoinDomainName, satsFromMilliPennies  } from './commonReact';

import { PAYLOAD_ESCAPE_FLAG } from './harnessConstants'

import './index.css';

import CoolSites from './coolSites';

import * as eccryptoJS from 'eccrypto-js';

import VideoRecorderModal from './videoRecorderModal';

// device-specific custom sidebar
// see this: https://stackoverflow.com/questions/52345724/why-custom-css-class-does-not-work-on-react-semantic-ui-elements
import './componentStyles.css'
import AvatarBuilder from './avatarBuilder.js';

// for private keys, for encrypting
const {  bsv  } = require('scryptlib');

function execAsync2( f, msecs ) {
    setTimeout(f, msecs)
}

// from https://stackoverflow.com/questions/40031688/javascript-arraybuffer-to-hex
function buf2hex(buffer) { // buffer is an ArrayBuffer
    return [...new Uint8Array(buffer)]
        .map(x => x.toString(16).padStart(2, '0'))
        .join('');
}

class Surfer extends React.PureComponent {
    constructor(props) {
        super(props);

        this.stickyRef = createRef()
        this.fontFamily          = "verdana"
        this.balancePennies      = 0.0
        this.bestUtxoPennies     = 0.0

        this.viewerDomainCallback        = null  // ref to shizzleView function that kicks-off traveling to the tip of a specified domain
        this.viewerButtonCallback        = null  // ref to a shizzleView function to click/advance back/fwd for the current domain
        this.setViewerReportOnGuestPosts = null  // ref to a shizzleView function to instruct if the viewer should report on the guest-posts it sees
        this.viewerRef = null                    // ref to our shizzleView function

        this.shizzleVerse        = <><b>ShizzleVerse</b><small>&trade;</small></>;

        this.GUEST_POSTS_PROHIBITED = 4294967295

        this.setOfFavoriteDomains = new Set()

        this.setOfAnnouncedDomains = new Set()
        this.setOfNewestDomains = new Set()      // TEMPORARY list of domains - which we learn about by 'scanning' for guestPosts at BANNO


        // for email-like tracking
        this.dialogsToRespondTo       = new Set()
        this.dialogsYetToHearBackFrom = new Set()
        this.activeDialogs            = new Set()
        this.closedDialogs            = new Set()


//FIXME: document what these are for
        this.setOfClaimed = new Set()
        this.setForFriends = new Set()

        this.recentSites = []

        // These two are populated in saveCoolSitesCallbacks
        this.coolSitesRef = null               // a reference to the <CoolSites>
        this.coolSitesUpdateFavesFunc = null   // call this to update the FAVORITES list - of CoolSites
        this.coolSitesUpdateRecentsFunc = null

        this.state =  {
                        showPostPopup: false,

                        strangerDomain: '',
                        strangerSearch: '',

                        requestedDomain: null,
                        display404: false,
                        displayNotMuchToSee: false,
                        claimedRecords: [],

                        latestTxId: null,   //FIXME: take care to advance this properly after
                                            // building an end-of-the-line tx
                        furthestQuarterlyTxId: null,  // new var to be more quarterly-aware
                        furthestQuarterlyDtx: null,
                        furthestQuarterlyStats: {
                            expired: false,
                            renewable: false,
                            canBuildNewQuarterly: false,
                            currentQuarterlyBlockHeight: 0,
                            furthestQuarterlyDtx: null,

                            priceInSats: -1,
                            ownerP2PKH: "?????",
                        },
                        quarterliesWithProfileInfo: [],

                        showSiteStatusModal: false,
                        domainPriceInSats: 0,         // This is for SETTING a price
                        purchaseThisDomain: false,

                        selectedDomain: '',
                        selfDomainRequest: true,      //NOTE: assume the first time, we arrive at one of OUR domains
                        ourIdentity: '',
                        ourOwnerCount: 0,
                        onOurCurrentIdentity: false,
                        defaultID: '',
                        defaultIdOwnerCount: 0,       // This is new, but long overdue
                        isOurDomain: false,

                        // Holds onto the tip of OUR current IDENTITY
                        // Used for when we want to build a guest post
                        // (which requires ourDtx, AND ourDtxLatestId)  // but, maybe rename to that ourLatestTxId
                        ourDtx: null,
                        ourDtxLatestId: '',

                        weHaveATransient: false,
                        guestPostingCanHappen: false,
                        satsPerGuestPost: -1,          // when visiting a domain

                        showFeeThresholdModal: false,
                        feeSettingsFirstTime: false,

                        guestPostPriceForThisIdentity: 100000000,
                        maxMilliPenniesToComment: 0,

                        showSwirly: false,
                        swirlyText: '',

                        hasAnnounced: false,
                        buildAnnouncement: false,
                        showYouWereAnnounced: false,

                        search: true,           //WARNING: side-effect: we also use this to control display of <CoolSites>
                        idSearch: false,

                        offerToVisitAnnounce: false,
                        scanDomains: false,
                        jumpBackToThis: '',

                        showNewDomains: false,

                        content: '',

                        idDropList: [],
                        surfDropList: [],

                        isAFave: false,
                        favoriteDomains: [],

                        suitableForDialogInvite: false,
                        inviteToDialog: false,
                        // IF suitable for dialog invite, we set these two
                        // EITHER in viewResponseCallback(), or postNavNote()
                        dialogTxId: null,
                        dialogDtx:  null,

                        dialogsOfInterest: '[]',  // identity-specific array of dialogIds
                        showDialogManager: false,
                        showDialogIconInMasthead: false,

                        commotionForActiveDialog: false,
                        commotionForNewDialog:    false,
                        commotionText:            '',

                        addPhotoToPost:           false, // Cannot be combined with encryptSelfPost
                        showPhotoModal:           false,
                        photoToAdd:               null,  // URL
                        photoName:                null,
                        photoBlob:                null,  // actually an array


                        addVideoToPost:           false,
                        videoToAdd:               null,
                        videoDescription:         null,
                        showVideoLibrary:         false,


                        encryptSelfPost:          false, // Whether or not to encrypt the self-post the user is about to make

                        dialogStartsHere:         false, // updated at viewResponseCallback(), and postNavNote()
                        aPartyToThisDialog:       false,
                        theDialogId:              0,
                        jumpingToDialog:          false,

                        showGuestPostManager:     false,

                        showVideoModal:           false,
                        // This gets loaded by videoRecorderModal's vidRecorder
                        // IFF the user loads it
                        ffMpeg:                   null,

                        postIconAnimation:        'flash',
                        jigglePostIcon:           true,
                        userHasClickedPostPopupSeveralTimes:  false,

                        showDrawer:               false,

                        weShouldRenderSurfElsewhereChoice: true,

                        showIdentityOption:       false,
                        showClaimer:              false,

                        showForceNewIdentityModal: false,

                        currentAvatar:             <></>,

        }


        this.signInOutCallback          = this.signInOutCallback.bind(this);
        this.footerFocus                = this.footerFocus.bind(this);

        this.recordBalance              = this.recordBalance.bind(this);

        this.handleSurfDomainInputChange= this.handleSurfDomainInputChange.bind(this);
        this.handleSurfDomainDropdownChange
                                        = this.handleSurfDomainDropdownChange.bind(this);
        this.handleSearchChange         = this.handleSearchChange.bind(this);

        this.registerViewerCallback     = this.registerViewerCallback.bind(this);
        this.pokeTheViewerWithStranger  = this.pokeTheViewerWithStranger.bind(this);
        this.pokeTheViewer              = this.pokeTheViewer.bind(this);
        this.checkSetAvatar             = this.checkSetAvatar.bind(this);
        this.viewResponseCallback       = this.viewResponseCallback.bind(this);
        this.vRCPostProcess             = this.vRCPostProcess.bind(this);
        this.handleAnnounceSuccess      = this.handleAnnounceSuccess.bind(this);

        this.purchaseDomain             = this.purchaseDomain.bind(this);
        this.setDomainForSale           = this.setDomainForSale.bind(this);
        this.forceBuildNextQuarterly    = this.forceBuildNextQuarterly.bind(this);
        this.handleSubmitPost           = this.handleSubmitPost.bind(this);
        this.handleSubmitGuestPost      = this.handleSubmitGuestPost.bind(this);

        this.handleVideoRecordClick     = this.handleVideoRecordClick.bind(this);
        this.closeVideoRecorderModal    = this.closeVideoRecorderModal.bind(this);

        this.advanceTheViewer           = this.advanceTheViewer.bind(this);

        this.handleAssetChange          = this.handleAssetChange.bind(this);
        this.handleJumpToOwnDomain      = this.handleJumpToOwnDomain.bind(this);

        this.showSiteStatus             = this.showSiteStatus.bind(this);
        this.closeSiteStatusModal       = this.closeSiteStatusModal.bind(this);

        this.handleFeeSettingsClick     = this.handleFeeSettingsClick.bind(this);
        this.closeFeeSettingsModal      = this.closeFeeSettingsModal.bind(this);
        this.handleTxProviderClick      = this.handleTxProviderClick.bind(this);

        this.handleAnnounceClick        = this.handleAnnounceClick.bind(this);
        this.closeYouWereAnnounced      = this.closeYouWereAnnounced.bind(this);

        this.receiveGuestPostReports    = this.receiveGuestPostReports.bind(this);
        this.scanForDomains             = this.scanForDomains.bind(this);
        this.closeNewDomainsModal       = this.closeNewDomainsModal.bind(this);

        this.goToCoolSite               = this.goToCoolSite.bind(this);
        this.addToRecents               = this.addToRecents.bind(this);
        this.populateTwoSetsAndDropLists= this.populateTwoSetsAndDropLists.bind(this);

        this.toggleFavorite             = this.toggleFavorite.bind(this);
        this.saveCoolSitesCallbacks     = this.saveCoolSitesCallbacks.bind(this);
        this.updateCoolSitesFaves       = this.updateCoolSitesFaves.bind(this);
        this.updateCoolSitesRecents     = this.updateCoolSitesRecents.bind(this);

        this.checkForAuthorization      = this.checkForAuthorization.bind(this);

        this.onOurCurrentIdentity       = this.onOurCurrentIdentity.bind(this);
        this.assetCheck                 = this.assetCheck.bind(this);

        this.postNavNote                = this.postNavNote.bind(this);
        this.checkForSpawnedDialog      = this.checkForSpawnedDialog.bind(this);
        this.checkIfInvitable           = this.checkIfInvitable.bind(this);

        this.guestPostIconClick         = this.guestPostIconClick.bind(this);
        this.closeGuestPostManager      = this.closeGuestPostManager.bind(this);

        this.dialogIconClick            = this.dialogIconClick.bind(this);
        this.closeDialogManager         = this.closeDialogManager.bind(this);
        this.checkForDialogsOfOurIdentity = this.checkForDialogsOfOurIdentity.bind(this);
        this.jumpToOneDialog            = this.jumpToOneDialog.bind(this);

        this.handleDialogListEvents     = this.handleDialogListEvents.bind(this);
        this.getDialogsYetToHearBackFrom= this.getDialogsYetToHearBackFrom.bind(this);
        this.getDialogsToRespondTo      = this.getDialogsToRespondTo.bind(this);
        this.getActiveDialogs           = this.getActiveDialogs.bind(this);
        this.getClosedDialogs           = this.getClosedDialogs.bind(this);

        this.addDialogToListAndSetting  = this.addDialogToListAndSetting.bind(this);

        this.closeDialogCommotionModal  = this.closeDialogCommotionModal.bind(this);
        this.closeActiveDialogCommotionModal = this.closeActiveDialogCommotionModal.bind(this);

        this.toggleMakeDefaultID        = this.toggleMakeDefaultID.bind(this);

        this.deriveSharedKey            = this.deriveSharedKey.bind(this);

        this.guestPostCallback          = this.guestPostCallback.bind(this);

        this.openPostPopup              = this.openPostPopup.bind(this);
        this.submitRegularOrGuestPost   = this.submitRegularOrGuestPost.bind(this);
        this.handlePostData             = this.handlePostData.bind(this);
        this.closePostPopup             = this.closePostPopup.bind(this);

        this.reanimatePostIcon          = this.reanimatePostIcon.bind(this);

        this.maybeEncryptMsg            = this.maybeEncryptMsg.bind(this);

        this.openDrawer                 = this.openDrawer.bind(this);
        this.closeDrawer                = this.closeDrawer.bind(this);

        this.noteCoolSitesCategory      = this.noteCoolSitesCategory.bind(this);

        this.toggleShowIdentityOption   = this.toggleShowIdentityOption.bind(this);

        this.openClaimer                = this.openClaimer.bind(this);
        this.closeClaimer               = this.closeClaimer.bind(this);
        this.userClaimedAsset           = this.userClaimedAsset.bind(this);

        this.receiveFFMpegRef           = this.receiveFFMpegRef.bind(this);
    }

    // currently called from ShizzleView
    async guestPostCallback(db, txId, statei) {
        // 1) list the two domains (and ownerCount)
        // 2) compare against a list of OUR domains
        // 3) IF one matches, record this txId using NEW guestPostTab access functions
        console.warn("Surfer guestPostCallback(): guest-posting tx with two parties. Here's the state: ", statei)

        console.log("WE SHOULD examine txId " + txId + " closely, and see if EITHER party to this Guest-Posting tx is a domain of ours")

        const hostDomain = hexStringToAscii( statei[0].namePath )
        const hostOwnerCount = statei[0].ownerCount
        const guestDomain = hexStringToAscii( statei[1].namePath )
        const guestOwnerCount = statei[1].ownerCount

        console.warn("Surfer guestPostCallback(): owner: ", hostDomain + ":" + hostOwnerCount)
        console.warn("Surfer guestPostCallback(): guest: ", guestDomain + ":" + guestOwnerCount)

        const allMyDomains = await findAllDomainsOfMine(db)
        console.warn("Surfer guestPostCallback(): BTW: all of my domains: ", allMyDomains)

        let weOwnTheHost = false
        let weOwnTheGuest = false
        let myGuestDomain = null
        let myHostDomain = null
        for ( let i = 0; i < allMyDomains.length; i++ ) {
            const thisDomain = allMyDomains[i]
            const thisMyDomain = thisDomain.name
            const thisMyOwnerCount = thisDomain.ownerCount

            if ( thisMyDomain === hostDomain && thisMyOwnerCount === hostOwnerCount ) {
                weOwnTheHost = true
                myHostDomain = thisMyDomain
                console.log("We own the host")
            }
            if ( thisMyDomain === guestDomain && thisMyOwnerCount === guestOwnerCount ) {
                weOwnTheGuest = true
                myGuestDomain = thisMyDomain
                console.log("We own the guest")
            }

            if ( weOwnTheGuest && weOwnTheHost ) {
                // we've found-out all we need to know
                break
            }
        }

        // NOTE: we're not careful about if we own BOTH guest AND host
        if ( weOwnTheGuest || weOwnTheHost ) {
            console.log("Adding a GuestPost reference...")
            // NOTE: we don't store the ownerCount
            await addGuestPostRef(  db,
                                    txId,
                                    weOwnTheGuest ? myGuestDomain : myHostDomain,  // ourDomain
                                    weOwnTheGuest ? hostDomain : guestDomain,      // otherDomain
                                    weOwnTheGuest, //weBuiltIt
                                    false, //ranValidation
                                    true, //isValid             FIXME: defaulting to true for now
                                    true) //couldBuildDialog    FIXME: defaulting to true for now

            // If we own the other domain, add a reference to it as well
            if ( weOwnTheGuest && weOwnTheHost ) {
                await addGuestPostRef(  db,
                                        txId,
                                        myHostDomain,  // ourDomain
                                        guestDomain,      // otherDomain
                                        true, // weBuiltIt <------- ponder this
                                        false, //ranValidation
                                        true, //isValid
                                        true) //couldBuildDialog
            }
        }

        //FIXME: weBuiltIt? depends on if we're the guest or host
        //       isValid? Requires effort to check. Can we default to value '?' ?
        //       couldBuildDialog? ??? true for now - but we're supposed to check if HOST is unspent, AND one of guest outputs is unspent

    }

    async toggleMakeDefaultID() {
        let newDefaultID = ''
        let newDefaultIdOwnerCount = 0
        if ( this.state.defaultID === '' ) {
            // was blank, so, set to current ID
            newDefaultID = this.state.ourIdentity
            newDefaultIdOwnerCount = this.state.ourOwnerCount
        } else if ( this.state.defaultID === this.state.ourIdentity ) {
            // was currentID, so, clear it
            newDefaultID = ''
            newDefaultIdOwnerCount = 0
        } else {
            // was something else, so, set it to current ID
            newDefaultID = this.state.ourIdentity
            newDefaultIdOwnerCount = this.state.ourOwnerCount
        }

        const db = await openDB()
        // save change to settings (IDB)
        await saveSetting(db, 'defaultIdentity', newDefaultID)
        await saveSetting(db, 'defaultIdentityOwnerCount', newDefaultIdOwnerCount)

        this.setState({
            defaultID: newDefaultID,
            defaultIdOwnerCount: newDefaultIdOwnerCount,
        })
    }

    /**
     * Called-back by DialogManager, telling us to move a dialog from
     * one list to another.
     * @param {*} dialogEventType
     * @param {*} dialogId
     */
    async handleDialogListEvents(dialogEventType, dialogId) {
        console.log("HANDLE DIALOGUE EVENTS(): " + dialogEventType + " for dialogue " + dialogId)

        let saveToRespondTo = false
        let saveHearBackFrom = false
        let saveActive = false
        let saveClosed = false
        if ( dialogEventType === "fromRespondToActive" ) {
            console.warn("handleDialogListEvents(): we need to move dialogue " + dialogId + " from 'respond', to 'active'")
            this.dialogsToRespondTo.delete(dialogId)
            this.activeDialogs.add(dialogId)

            saveToRespondTo = true
            saveActive = true
        } else if ( dialogEventType === "fromHearBackToActive" ) {
            console.warn("handleDialogListEvents(): we need to move dialogue " + dialogId + " from 'hearBack', to 'active'")
            this.dialogsYetToHearBackFrom.delete(dialogId)
            this.activeDialogs.add(dialogId)

            saveHearBackFrom = true
            saveActive = true
        } else if ( dialogEventType === 'activeToClose' ) {
            console.warn("handleDialogListEvents(): we need to move dialogue " + dialogId + " from 'active', to 'closed'")
            this.activeDialogs.delete(dialogId)
            this.closedDialogs.add(dialogId)

            saveActive = true
            saveClosed = true
        } else if ( dialogEventType === 'addToHearBack' ) {
            this.dialogsYetToHearBackFrom.add( dialogId )
            saveHearBackFrom = true
        } else if ( dialogEventType === 'addToRespondTo' ) {
            this.dialogsToRespondTo.add( dialogId )
            saveToRespondTo = true
        } else if ( dialogEventType === 'addToActive' ) {
            this.activeDialogs.add( dialogId )
            saveActive = true
        } else if ( dialogEventType === 'removeFromRespond' ) {
            this.dialogsToRespondTo.delete( dialogId )
            saveToRespondTo = true
        } else if ( dialogEventType === 'removeFromHearBack' ) {
            this.dialogsYetToHearBackFrom.delete( dialogId )
            saveHearBackFrom = true
        } else if ( dialogEventType === 'removeFromActive' ) {
            this.activeDialogs.delete( dialogId )
            saveActive = true
        } else if ( dialogEventType === 'addToClosed' ) {
            this.closedDialogs.add(dialogId)
            saveClosed = true
        } else {
            console.error("UNEXPECTED dialogEventType: ", dialogEventType, " - for dialogue " + dialogId)
            alert("CODE ERROR handling unknown dialogue event")
        }

        if ( saveToRespondTo || saveHearBackFrom || saveActive || saveClosed ) {
            const db = await openDB()

            // save new state for appropriate sets
            if ( saveToRespondTo ) {
                const jsonRespondToDialogs = JSON.stringify(Array.from( this.dialogsToRespondTo ))
                await saveSetting(db, "dialogsToRespondTo", jsonRespondToDialogs)
            }
            if ( saveHearBackFrom ) {
                const jsonDialogs = JSON.stringify(Array.from( this.dialogsYetToHearBackFrom ))
                await saveSetting(db, "dialogsYetToHearBackFrom", jsonDialogs)
            }
            if ( saveActive ) {
                const jsonActiveDialogs = JSON.stringify(Array.from( this.activeDialogs ))
                await saveSetting(db, "activeDialogs", jsonActiveDialogs)
            }
            if ( saveClosed ) {
                const jsonClosedDialogs = JSON.stringify(Array.from( this.closedDialogs ))
                await saveSetting(db, "closedDialogs", jsonClosedDialogs)
            }
        }
    }

    // Check which, if any, dialogs are relevant for the CURRENT identity
    async checkForDialogsOfOurIdentity(reevaluate = false) {

        console.error("IMPLEMENT: Don't forget to CLEAR, and re-evaluate this state when changing identity")

        if ( reevaluate || !this.state.showDialogIconInMasthead ) {

            let clearIt = false

            // FILTER-out dialogs related to OTHER ASSETS owned by this user
            // We want to concentrate on our current IDENTITY
            if (    this.dialogsToRespondTo.size > 0       ||
                    this.dialogsYetToHearBackFrom.size > 0 ||
                    this.activeDialogs.size > 0            ||
                    this.closedDialogs.size > 0
            ) {
                const dialogRecs = await findADialog(await openDB(), this.state.ourIdentity, this.state.ourOwnerCount)

                if ( dialogRecs.length > 0 ) {
                    const dialogIdsOfInterest = new Set()
                    for ( let d = 0; d < dialogRecs.length; d++ ) {
                        const dlg = dialogRecs[d]
                        if ( dlg.name === this.state.ourIdentity ) {
                            console.warn("Look at dialogue id " + dlg.dialogId)
                            console.log( "  otherGuy: " + dlg.otherGuy)
                            dialogIdsOfInterest.add( dlg.dialogId)
                        }
                    }

                    console.warn("This asset, " + this.state.ourIdentity + ", has " + dialogRecs.length + " dialogs of interest (check above for details)")

                    // dialogIdsOfInterest

                    //       to be heard from       - would prompt us to check on these txIds
                    //       to answer to           - would prompt us to respond
                    //       active                 - would prompt us to check on, and/or respond

                    if ( dialogIdsOfInterest.size > 0 ) {
                        console.warn("dialogsOfInterest: ", dialogIdsOfInterest )
                        console.warn("SETTING state.dialogsOfInterest: " + JSON.stringify( Array.from(dialogIdsOfInterest) ))
                        this.setState({ showDialogIconInMasthead: true,
                                        dialogsOfInterest: JSON.stringify( Array.from(dialogIdsOfInterest) )})
                    }
                } else {
                    // There are no dialogs relevant to this identity of ours, but,
                    // Only clear it if it was ALREADY set
                    clearIt = this.state.showDialogIconInMasthead
                }
            } else {
                // There are no dialogs to speak of, but,
                // Only clear it if it was ALREADY set
                clearIt = this.state.showDialogIconInMasthead
            }

            if ( clearIt ) {
                this.setState({ showDialogIconInMasthead: false,
                                dialogsOfInterest: '[]'
                })
            }

            // Also see, related: checkForSpawnedDialog()

        }

    }

    closeGuestPostManager() {
        this.setState({showGuestPostManager: false})
    }
    guestPostIconClick() {
        this.setState({showGuestPostManager: true})
    }

    dialogIconClick() {
        this.setState({showDialogManager: true})
    }
    closeDialogManager() {
        this.setState({showDialogManager: false, jumpingToDialog: false})
    }
    jumpToOneDialog(e, dialogId) {
        console.log("jumpToOneDialog() BTW: state.jumpingToDialog = " + this.state.jumpingToDialog)
        console.log("We'll jump to dialogue " + dialogId)
        this.setState({jumpingToDialog: true})
    }

    // Called by footer when one of its modals show, or hide
    // Use this knowledge to disable (or not) buttons on this page
    footerFocus(trueOrFalse) {
        //alert("Footer in focus? " + trueOrFalse)
        this.setState({footerModalInFocus: trueOrFalse})
    }

    // similar to onramp
    handleAssetChange(e, v) {
        //console.warn("hac change(): v.value (domain index): ", v.value)
        console.warn("handleAssetChange():  domain: ", this.state.claimedRecords[v.value].name)

        this.setState({selectedDomain: this.state.claimedRecords[v.value].name})
        //alert("Change: " + event.target.value)
        //alert("value: " + v.value)
    }

    async signInOutCallback(dropCitizenLevel = true) {
        if ( this.props.citizenLevel === 1 ) {
            console.error("SURFER: Maybe we shouldn't even be here by now")
            // alert?

        } else if ( this.props.citizenLevel > 1 ) {
            console.log("SURFER: We should FORGET official key record. And jump back to onramp")

            //this.setState({showVerifier: false, selectedDomain: null})
            //this.bundle = null    // not used
            this.balancePennies = 0
            this.bestUtxoPennies = 0

            //NOTE: we moved this level drop to props.handleJumpToHome
            //      Think we found it necessary, since the level drop
            //      ITSELF may have been unmounting something
            //await this.props.changeCitizenLevel(1)

            if ( this.props.surfFrom === 'onRamp' ) {
                //alert("log-out back to onRamp")
                this.props.handleJumpBackToOnRamp(this.props.parent, dropCitizenLevel) // implies to drop citizenLevel to 1
            } else if ( this.props.surfFrom === 'home' ) {
                //alert("log-out back to home. IMPLEMENT the jump")
                this.props.handleJumpToHome(null, null, false, dropCitizenLevel)   // drop citizenLevel to 1: true
            }
        }
    }

    // called by BitShizzleApp
    recordBalance(balancePennies, bestUtxoPennies) {
        this.balancePennies = balancePennies
        console.warn("The APP tells us we have roughly " + balancePennies + "¢ - in Bitcoin")

        this.bestUtxoPennies = bestUtxoPennies
        console.warn("The APP tells us our best UTXO has roughly " + bestUtxoPennies + "¢ - in Bitcoin")
        //alert("surfer now knows bestUtxoPennies is " + bestUtxoPennies)
    }

    async componentDidMount() {
        // If we got here because we just filled-out a survey, let's scroll back up to the top
        window.scrollTo(0, 0)
        //document.getElementById("theTopElem").scrollIntoView()

        if ( this.props.parent && this.props.registerClient ){
            this.props.registerClient(this.props.parent, this, "surfer")
        }

        // get initial balance from app
        this.balancePennies = this.props.balancePennies
        this.bestUtxoPennies = this.props.bestUtxoPennies
//alert("SURFER: mounted. Got property (initial) balancePennies: " + this.props.balancePennies)

        // get ready to receive wallet balance updates from app
        if ( this.props.returnBalanceCallback ) {
            this.props.returnBalanceCallback( this, this.recordBalance )
        }

        // soon we'll use this to build transactions
        if ( typeof this.props.bundle !== 'undefined' ) {
            // .p   .address   .pwd
            console.error("surfer mounted, with props.bundle: ", this.props.bundle)
            //alert("surfer mounted. check console for bundle...")
        } else {
            alert("surfer mounted, but we don't have props.bundle?")
        }

        const db = await openDB()

        const claimedRecords = await findAllDomainsOfMine(db);
        if ( this.props.ourDomain === null ) {
            alert("ERROR: null props.ourDomain")
            throw new Error("surfer needs an 'ourDomain' property")
        }

        // Find the HIGHEST ownership count for a given asset name - which we own
        let ourOwnerCount = 0
        console.warn("surfer mounting: props.ourDomain " + this.props.ourDomain)
        console.warn("surfer mounting: props.ourOwnerCount " + this.props.ourOwnerCount)
        if ( typeof this.props.ourOwnerCount === 'undefined' || this.props.ourOwnerCount == 0 ) {
            console.warn("SURFER: mounting. Since an ourOwnerCount wasn't specified (or is 0), we'll look for the LATEST ownerCount WE have/own")
            for ( let d = 0; d < claimedRecords.length; d++ ) {
                const record = claimedRecords[d]
                if ( record.name === this.props.ourDomain ) {
                    const thisOwnerCount = record.ownerCount
                    if ( thisOwnerCount > ourOwnerCount ) {
                        ourOwnerCount = thisOwnerCount
                    }
                }
            }
        } else {
            ourOwnerCount = this.props.ourOwnerCount
        }

        if ( ourOwnerCount === 0 ) {
            alert("PROBLEM? We didn't find an owned asset. Are you just surfing without identity?")
        } else {
            console.warn("surfer mounting. ourOwnerCount is " + ourOwnerCount + " for domain " + this.props.ourDomain)
        }

        // get the 'default' identity
        const defID = await readSetting(db, "defaultIdentity")
        let defaultID = ''
        if ( defID !== null ) {
            defaultID = defID.value

            console.warn("Retrieved default identity:  ", defaultID)
        }

        // get the 'default' identity owner count
        const defIDOC = await readSetting(db, "defaultIdentityOwnerCount")
        let defaultIDOwnerCount = 0
        if ( defIDOC !== null ) {
            defaultIDOwnerCount = defIDOC.value

            console.warn("Retrieved default identity owner count:  ", defaultIDOwnerCount)
        } else {
            console.error("SURFER: retrieved NO default identity owner count")
        }

        // get a list of any Favorite domains
        const faves = await readSetting(db, "favoriteDomains")
        let favorites = null
        if ( faves !== null ) {
            favorites = JSON.parse( faves.value )

            for ( let i = 0; i < favorites.length; i++ ) {
                console.log("adding favorite domain: " + favorites[i])
                this.setOfFavoriteDomains.add( favorites[i] )
            }

            console.warn("populated SET of favorite domains:  ", this.setOfFavoriteDomains)

            // trigger re-render of coolSites
            this.updateCoolSitesFaves()
        }

        const dlgsToRespondTo = await readSetting(db, "dialogsToRespondTo")
        let dialogs = null
        if ( dlgsToRespondTo !== null ) {
            dialogs = JSON.parse(dlgsToRespondTo.value)

            for ( let i = 0; i < dialogs.length; i++ ) {
                console.log("adding DIALOGUE to respond to: " + dialogs[i])
                this.dialogsToRespondTo.add( dialogs[i] )
            }
        }

        const dlgsYetToHearBackFrom = await readSetting(db, "dialogsYetToHearBackFrom")
        let dialogs2 = null
        if ( dlgsYetToHearBackFrom !== null ) {
            dialogs2 = JSON.parse(dlgsYetToHearBackFrom.value)

            for ( let i = 0; i < dialogs2.length; i++ ) {
                console.log("adding DIALOGUE to hear back from: " + dialogs2[i])
                this.dialogsYetToHearBackFrom.add( dialogs2[i] )
            }
        }

        const activeDlgs = await readSetting(db, "activeDialogs")
        let dialogs3 = null
        if ( activeDlgs !== null ) {
            dialogs3 = JSON.parse(activeDlgs.value)

            for ( let i = 0; i < dialogs3.length; i++ ) {
                console.log("adding active DIALOG: " + dialogs3[i])
                this.activeDialogs.add( dialogs3[i] )
            }
        }

        const closedDlgs = await readSetting(db, "closedDialogs")
        let dialogs4 = null
        if ( closedDlgs !== null ) {
            dialogs4 = JSON.parse(closedDlgs.value)

            for ( let i = 0; i < dialogs4.length; i++ ) {
                console.log("adding active DIALOG: " + dialogs4[i])
                this.closedDialogs.add( dialogs4[i] )
            }
        }

        let offerToVisitAnnounce = false

        // get a list of any already-saved announced domains
        const announcedDoms = await readSetting(db, "announcedDomains")
        console.log("here are the already-announced domains: ", announcedDoms)
        let announcedDomains = null
        if ( announcedDoms !== null ) {
            announcedDomains = JSON.parse( announcedDoms.value )

            for ( let i = 0; i < announcedDomains.length; i++ ) {
                console.log("adding announced domain: " + announcedDomains[i])
                this.setOfAnnouncedDomains.add( announcedDomains[i] )
            }
            console.warn("populated SET of announced domains:  ", this.setOfAnnouncedDomains)

            // When did we last set this setting?
            const nowStamp = Math.floor(Date.now() / 1000)
            const secondsSince = nowStamp - announcedDoms.dateSet
            console.warn("timeSince setting announcedDomains: " + secondsSince + " seconds")

    //FIXME: put this number in harnessConstants?
            if ( secondsSince > 200 ) {  // half a day (43200 secs)
                console.warn("it's been a while since you've set the announcedDomains (visited announcements). Will offer chance to do it again.")
                offerToVisitAnnounce = true
            }
        } else {
            console.warn("You've NEVER yet been to 'announcements'. Will offer you the chance.")
            offerToVisitAnnounce = true
        }

        const numPostPopupClicksSetting = await readSetting(await openDB(), "numPostPopupClicks")
        if ( numPostPopupClicksSetting !== null ) {

            const numPostPopupClicks = numPostPopupClicksSetting.value

            // If user has clicked postPopup more than 4 times, set this state
            this.setState({ userHasClickedPostPopupSeveralTimes: numPostPopupClicks > 4 })
        } else {
            // initialize the setting
            await saveSetting(await openDB(), "numPostPopupClicks", 0)
        }

        this.setState({ claimedRecords:   claimedRecords,
                        ourIdentity:      this.props.ourDomain,
                        ourOwnerCount:    ourOwnerCount,  // taken from this.props.ourOwnerCount, if present
                        defaultID:        defaultID,
                        defaultIdOwnerCount: defaultIDOwnerCount,

                        // this gets reset if user scans, or if user announces
                        offerToVisitAnnounce: offerToVisitAnnounce,

                        favoriteDomains:  favorites? favorites.value : favorites,
                      })


        //FIXME: link to where we found this logic?

        // Opera 8.0+
        const isOpera = (!!window.opr && !!window.opr.addons) || !!window.opera || navigator.userAgent.indexOf(' OPR/') >= 0;

        // Firefox 1.0+
        const isFirefox = typeof InstallTrigger !== 'undefined';

        // Safari 3.0+ "[object HTMLElementConstructor]"
        const isSafari = /constructor/i.test(window.HTMLElement) || (function (p) { return p.toString() === "[object SafariRemoteNotification]"; })(!window['safari'] || (typeof window.safari !== 'undefined' && window.safari.pushNotification));

        // Internet Explorer 6-11
        const isIE = /*@cc_on!@*/false || !!document.documentMode;

        // Edge 20+
        const isEdge = !isIE && !!window.StyleMedia;

        // Chrome 1 - 71
        const isChrome = !!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime);

        // Blink engine detection
        const isBlink = (isChrome || isOpera) && !!window.CSS;

        const isBrave = (navigator.brave && await navigator.brave.isBrave() || false)

        let videoTxid = "77a591e9bfc4af45fabd4b078388bf8397106a49162e75e683822189d9aabcfe"
        if ( isBrave) {
            videoTxid="dae9ce9e6991bd6537a6340f6cf0e2be04ecc995e3181d6bc8840b4e15494939"
            console.warn("BROWSER TYPE: brave")
        } else if ( isSafari ) {
            videoTxid="29a202d35a7730ba6e915b0f0666120554be8a16a0614b3854fb415c2a03b7f9"
            console.warn("BROWSER TYPE: safari")
        } else if ( isChrome ) {
            videoTxid="b7227e2a702be5ae41c959a4486afc111c41d4e8b03f0f83a2eea261dfcde02c"
            console.warn("BROWSER TYPE: chromeish")
        } else if ( isOpera ) {
            console.warn("BROWSER TYPE: opera")
        } else if ( isFirefox ) {
            console.warn("BROWSER TYPE: firefox?")
        } else if ( isEdge ) {
            console.warn("BROWSER TYPE: edge?")
        } else {
            console.error("BROWSER TYPE: ??")
        }

    } // componentDidMount()

    componentWillUnmount() {
        console.log("SURFER about to unmount. Will UNregister...")

        if ( this.props.parent && this.props.unregisterClient ) {
            this.props.unregisterClient(this.props.parent, this, "surfer")
        }

        this.setOfAnnouncedDomains.clear()
        this.setOfNewestDomains.clear()

        this.setOfClaimed.clear()
        this.setForFriends.clear()

        this.setOfFavoriteDomains.clear()

        // fix Warning: Can't perform a React state update on an unmounted component
        // see: https://stackoverflow.com/questions/53949393/cant-perform-a-react-state-update-on-an-unmounted-component
        this.setState = (state,callback)=>{
            return;
        };
    }

    // NOTE: the owner count is NOT specified, so, we'll use the LATEST ownerCount that we own(ed)
    handleJumpToOwnDomain(domain) {

        if ( domain === null ) {
            alert("ERROR: handleJumpToOwnDomain() specified null?")
            throw new Error("invalid domain specified")
        }

        if ( this.state.selectedDomain === null ) {
            alert("handleJumpToOwnDomain() state of selectedDomain is null? a problem")
            throw new Error("invalid state.selectedDomain: null")
        }

        // Find the HIGHEST ownership count for a given asset name - ALL of which we own,
        // since we're iterating through a list of OUR assets+ownerCounts
        let ourOwnerCount = 0
        let foundAny = false
        console.warn("handleJumpToOurDomain(): looking for record(s) of domain " + domain + " (among those we OWN)...")
        console.warn("And here are the records through which we're searching: ", this.state.claimedRecords)
        for ( let d = 0; d < this.state.claimedRecords.length; d++ ) {
            console.warn("handleJumpToOurDomain(): record #" + d + "  name: " + this.state.claimedRecords[d].name)
            if ( domain === this.state.claimedRecords[d].name ) {
                foundAny = true
                console.warn("handleJumpToOurDomain(): name/domain match. Let's compare this ownerCount " + this.state.claimedRecords[d].ownerCount +
                            " with ours " + ourOwnerCount)
                const thisOwnerCount = this.state.claimedRecords[d].ownerCount

                if ( thisOwnerCount > ourOwnerCount ) {
                    ourOwnerCount = thisOwnerCount
                    console.warn("handleJumpToOurDomain(): ourOwnerCount now " + ourOwnerCount)
                }
            }
        }

        if ( !foundAny ) {
            alert("Whoops. we found NO record of " + domain)
            throw new Error("CODE ERROR: trouble finding claim record for domain " + domain)
        }

        console.warn("SURER handleJFumpToOwnDomain(): jump to our: " + domain + " (ownerCount " + ourOwnerCount + ")")

        // clear some settings, now that we're changing identities
        this.setState({ ourIdentity: domain,
                        ourOwnerCount: ourOwnerCount,
                        scanDomains: false,
                        showDrawer: false,
                        showIdentityOption: false,
                      })
        this.pokeTheViewer(null, null, domain, true)
    }

    // manually-typed change to dropdown's field
    handleSearchChange(event, x) {
        console.log("handleSearchChange() event.target.value: ", event.target.value)
        console.warn("handleSearchChange() setting strangerSearch")
        this.setState({ strangerSearch: event.target.value.trim().toLowerCase(),
                        strangerDomain: event.target.value.trim().toLowerCase()
                    })
        event.preventDefault()
    }

    handleSurfDomainDropdownChange(event, x) {
        if ( !x.value ) {
            alert("handleSurfDomainDropdownChange. hmm. blank choice? tossing")
            return
        }

        console.log("event.target.className: ", event.target.className)

        if ( event.target.className === 'search' ) {
            console.warn("IGNORING search selection")
            return
        }

        //console.warn("event: ", event)
        //console.log("x.value: ", x.value + " <-----")
        //console.log("x.options: ", x.options)
        //console.log("x.options[x.value]: ", x.options[x.value - 1])
        //        const value = x.options[x.value - 1].text
        console.log("handleSurfDomainDropdownChange(): our value: ", x.value + "   setting strangerDomain, and strangerSearch <---")

        this.setState({strangerDomain: x.value,
                       strangerSearch: x.value,
                      })
    }

    handleSurfDomainInputChange(event, x) { //recently changed the default value
        console.log("handleSurfDomainInputChange: " + event.target.value)

        // only allow suitable characters: a-z  0-9, - ?
        const re = /^[a-zA-Z0-9]+$/;

        if ( event.target.value.length > 38 ) {
            console.warn("ignoring invalid domain (length " + event.target.value.length + "): " + event.target.value)
        } else if ( event.target.value === '' || re.test(event.target.value) ) {
            //console.warn("domain bar entry altered: " + event.target.value)

            const firstChar = event.target.value[0]
            if (  firstChar === '0' || firstChar === '1' || firstChar === '2' ||
                firstChar === '3' || firstChar === '4' || firstChar === '5' ||
                firstChar === '6' || firstChar === '7' || firstChar === '7' ||
                firstChar === '9' || firstChar === '-'
               ) {
                return
            }

            console.log("   -> setting strangerSearch: ", event.target.value)
            this.setState({ strangerDomain: event.target.value,
                            strangerSearch: event.target.value,
                          })

        } else {
            console.warn("ignoring invalid domain: " + event.target.value)
        }
    }

    // This function was registered with the ShizzleView instance
    // The viewer calls it to give references to THREE function callbacks the surfer can make:
    //     - viewerDomainCallback            - used by pokeTheViewer(), to jump to a domain
    //     - viewerButtonCallback            - used to advance the viewer after building a tx
    //     - viewerSetReportGuestPostsFunc   - used to tell viewer if we're interested in reports of
    //                                         guest-posts (when scanning 'banno')
    registerViewerCallback(self, viewerRef, callbackA, callbackB, viewerSetReportGuestPostsFunc) {
        console.warn("registerViewerCallback(): called")
        if ( callbackA && callbackB ) {
            self.viewerDomainCallback = callbackA
            self.viewerButtonCallback = callbackB
            self.viewerRef = viewerRef
        } else {
            throw new Error("Coding error in shizzleView - registering callbacks")
        }

        if ( viewerSetReportGuestPostsFunc ) {
            // hold onto viewer's function which we can call
            self.setViewerReportOnGuestPosts = viewerSetReportGuestPostsFunc
        }
    }

    // At least one setting is dependent on our current identity
    //        ( gpPriceFor_{currentID} )
    // NEWER: ( gpPriceFor_{currentID}_{currentOwnerCount} )
    //WARNING: we're setting a GuestPost price for a given identity
    //         WITHOUT specifying an owner count
    async maybeUpdateFeeSettings(thisIsFirstPost = false) {
        const db = await openDB()

        const mmptc = await readSetting(db, "maxMilliPenniesToComment")

        if ( !this.state.ourIdentity || this.state.ourIdentity.length < 2 ) {
            throw new Error("invalid identity: " + this.state.ourIdentity)
        }

        // NOTE: this is only used/useful at certain times
        // NOTE: we've added a '_{ourOwnerCount}' to the gpPriceFor_ setting
        const ggpfti2 = await readSetting(db, "gpPriceFor_" + this.state.ourIdentity + "_" + this.state.ourOwnerCount)
        const ggpfti = ggpfti2 === null ? await readSetting(db, "gpPriceFor_" + this.state.ourIdentity) : ggpfti2

        // Only force user to review settings after their FIRST post
        // Spreads out the clutter just a bit - to avoid overwhelming
        // when making the FIRST post
        if ( mmptc === null || ggpfti === null || ggpfti.value === this.GUEST_POSTS_PROHIBITED ) {
            console.log("At least one of the two relevant fee settings has not yet been set, OR, guest posts are prohibited (default value). We MIGHT ask the user to review the settings (if this is the first post).")

            if ( thisIsFirstPost ) {
                this.setState({showFeeThresholdModal: true, feeSettingsFirstTime: true})
            } else {
                if ( mmptc !== null ) {
                    console.log("maxMilliPenniesToComment isn't null. settings state")
                    this.setState({ maxMilliPenniesToComment: mmptc.value })
                } else if ( ggpfti !== null ) {
                    console.log("guestPostPriceForThisIdentity isn't null. settings state")
                    this.setState({ guestPostPriceForThisIdentity: ggpfti.value })
                }
            }

        } else {
            console.log("You've declared that you're willing to pay up to " + mmptc.value
                + " milli-pennies when commenting on the sites of others.")
            console.log("You've declared that others must pay " + ggpfti.value
                + " sats to post on your current identity.")

            this.setState({ maxMilliPenniesToComment: mmptc.value,
                            guestPostPriceForThisIdentity: ggpfti.value
                          })
        }
    }

    // A sanity check that we can even spend from this domain
    // This could fail if we try to redeem something that's
    // already been redeemed by someone else
    async checkForAuthorization(db, dtx) {
        const rabinToSpend = dtx.outputStates[0].ownerRabinPKH

        const obj = await getEncryptedKeysFromPKH(db, rabinToSpend);
        if ( obj === null ) {
            console.error("checkForAuthorization(): Failed retrieving keys to spend this asset. We probably don't actually own this.");

            if ( this.state.claimedRecords !== null ) {

                //console.error(" BTW: claimedRecords: ", this.state.claimedRecords)
                //console.error(" ourDomain: ", this.props.ourDomain)

                for ( let d = 0; d < this.state.claimedRecords.length; d++ ) {
                    const recordName = this.state.claimedRecords[d].name
                    if ( recordName !== this.props.ourDomain ) {
                        continue
                    }
                    const forFriend = this.state.claimedRecords[d].forFriend
                    const fromFriend = this.state.claimedRecords[d].fromFriend
                    const outdated = this.state.claimedRecords[d].outdated

                    //FIXME: consider ERASING from domain list, so, can't select as an option?
                    if ( forFriend ) {
                        alert("We notice that this domain may have ORIGINALLY been claimed for a friend. Perhaps your friend has redeemed your gift?")
                    } else if ( fromFriend ) {
                        alert("We notice that this domain may have ORIGINALLY been sent to you, as a gift, from a friend. Perhaps your friend has redeemed it for themselves?")
                    } else if ( outdated) {
                        alert("We notice that this domain MIGHT have expired. Perhaps you're using an OLD gift-redemption code?")
                    }
                }
            }

            alert("We failed to retrieve the required keys. You probably don't actually own this domain.")

            // Jump back to homepage
            this.signInOutCallback()
            return false
        } else {

            // we shouldn't actually arrive here

            try {
                await decryptData(obj.blob, this.props.bundle.pwd);
                console.warn("successfully retrieved keys")
            } catch (error) {
                console.error("checkForAuthorization(): Bad decrypt. You probably don't REALLY own this.");

                alert("We failed to decrypt the retrieved keys. You probably don't actually own this asset.")

                // Jump back to homepage
                this.signInOutCallback()
                return false
            }
        }

        return true
    }

    // Check outputs for a SPAWNED dialog
    async checkForSpawnedDialog(db, latestTxId, dtx, calledFromViewResponseCallback) {

        let aPartyToThis = false
        let ownDialog = false
        let guestInDialog = false
        let theDialogId = 0
        const spawnsADialog = dtx.outputStates[2]?.contract === 'dialog'
                           || dtx.outputStates[3]?.contract === 'dialog'
        if ( spawnsADialog ) {
            console.warn("THIS Tx spawns a dialog! Check if we know about it. Check if it's been spent. Check its participants.")
            const outNum = dtx.outputStates[2].contract === 'dialog' ? 2 : 3
            const dialogState = dtx.outputStates[outNum]

            //Q: should we extract its address - to best track it?      <-------<<

            let dialogRecs = []
            let theDialogRec = null
            let weHadNotYetResponded = false
            let awaitingDialogResponse = false
            if ( dialogState.ownerName === this.state.ourIdentity ) {

                //FIXME: and owner count?

                console.warn("We OWN this dialogue")
                ownDialog = true

                dialogRecs = await findADialog(db, dialogState.ownerName, dialogState.ownerOwnerCount)
                console.warn("We found " + dialogRecs.length + " dialogsRecs based on owner (us): ", dialogRecs)
            } else if (dialogState.visitorName === this.state.ourIdentity ) {
                console.warn("We're a GUEST in this dialogue")
                guestInDialog = true

                dialogRecs = await findADialog(db, dialogState.visitorName, dialogState.visitorOwnerCount)
                console.warn("We found " + dialogRecs.length + " dialogsRecs based on visitor (us): ", dialogRecs)
            } else {
                console.log("visitor: " + dialogState.visitorName + ",   ownerName: " + dialogState.ownerName + "  (our ID: " + this.state.ourIdentity + ")")
                console.warn("We're NOT a party to this dialogue")
            }

            if ( ownDialog || guestInDialog ) {
                aPartyToThis = true
                console.warn("Dialogue starting txId: " + latestTxId)

                for ( let d = 0; d < dialogRecs.length; d++ ) {
                    if ( dialogRecs[d].startingTxId === latestTxId ) {
                        theDialogRec = dialogRecs[d]
                        theDialogId = theDialogRec.dialogId
                        console.log("relevant dialogue rec: ", theDialogRec)
                        console.log("we found THE record. Check console. DialogId: " + theDialogId)
                    }
                }

                if ( theDialogId === null ) {
                    alert("ERROR? We have no IDB record of this dialogue. Perhaps something is wrong. It should have ALREADY been created in qFDTx().")
                    throw new Error("Failed to find spawned dialogue record")
                }

                // was this dialog sitting in one of the initial Sets, waiting for 'activation'?
                awaitingDialogResponse = ownDialog ? this.dialogsYetToHearBackFrom.has( theDialogId ) : false
                weHadNotYetResponded = guestInDialog ? this.dialogsToRespondTo.has( theDialogId ) : false

                console.log("checkForSpawnedDialog(): awaitingDialogResponse: " + awaitingDialogResponse)
                console.log("checkForSpawnedDialog(): weHadNotYetResponded:   " + weHadNotYetResponded)
                console.log("checkForSpawnedDialog(): outNum: " + outNum)

                const commotionText = <>with <b style={{color:'blue'}}>{dialogState.ownerName.toUpperCase()}</b> (dialogid: {theDialogId})</>
                console.log("created commotionText - just in case: '" + commotionText + "'")

                if ( dtx.buildable[outNum] === outNum ) {
                    // UNSPENT, so, check our lists

                    console.log("checkForSpawnedDialog(): base-0 output " + outNum + " is BUILDABLE (not spent)")

                    if ( guestInDialog ) {
                        console.warn("   this.dialogsToRespondTo: ", this.dialogsToRespondTo)
                        if ( weHadNotYetResponded ) {
                            console.warn("  We ALREADY know about this dialogue id (to respond to): " + theDialogId)
                        } else {
                            console.warn("  This dialogue id is NEW to us (to respond to): " + theDialogId)


                            await this.addDialogToListAndSetting(theDialogId, this.dialogsToRespondTo, "dialogsToRespondTo", db)


                            console.log("Will CAUSE A COMMOTION - show a modal <---")
                            this.setState({ commotionForNewDialog: true,
                                            commotionText: commotionText,
                                         })
                        }
                    } else {
                        console.warn("   this.dialogsYetToHearBackFrom: ", this.dialogsYetToHearBackFrom)

                        if ( awaitingDialogResponse ) {
                            console.warn("  We ALREADY know about this dialogue id (to hear back from): " + theDialogId)
                        } else {
                            console.warn("  This dialogue id is NEW to us (to hear back from): " + theDialogId)

                            await this.addDialogToListAndSetting(theDialogId, this.dialogsYetToHearBackFrom, "dialogsYetToHearBackFrom", db)
                        }
                    }

                    // unspent, so, should be part of 'haventRespondedTo', or 'haventHeardBack'
                    console.log("BTW: this dialogue seems to be UNSPENT. Check console for info/recs.")

                } else {
                    // it's been spent, so, check our lists
                    console.log("checkForSpawnedDialog(): base-0 output " + outNum + " is NOT buildable (it's SPENT)")

                    // HERE is where we can move from ToRespondTo, or YetToHearBackFrom
                    //      to active - IFF it's in one of those two sets
                    //      otherwise, it's already active

                    // was this sitting in the dialogsToRespondTo set?
                    if ( weHadNotYetResponded ) {
                        console.error("NOTE: we're making an ASSUMPTION that because it was spent, the WE had responded. That might not be true. The owner could have sent an ADDITIONAL message.")
                        console.warn("NOTE: we've implemented a commotion - letting user know that the owner may have sent more than 1 message (or we're RESTORING a WALLET), and we're automatically moving this dialogue to 'ACTIVE'")

                        //FIXME: ponder if this is all worthwhile - finding out if WE responded.
                        //       It might be, if we want to be able to RECOVER a WALLET.
                        //       SO, we should probably add iterative reads, and analysis
                        //       ALSO: should consider adding some flag, recoveringWallet, and spend TIME recovering FULLY

                        // spent, so, move to the activeDialogs list
                        this.dialogsToRespondTo.delete(theDialogId)
                        this.activeDialogs.add(theDialogId)

                        // save new state for BOTH sets
                        const jsonRespondToDialogs = JSON.stringify(Array.from( this.dialogsToRespondTo ))
                        await saveSetting(db, "dialogsToRespondTo", jsonRespondToDialogs)
                        const jsonActiveDialogs = JSON.stringify(Array.from( this.activeDialogs ))
                        await saveSetting(db, "activeDialogs", jsonActiveDialogs)

                        //NOTE: we probably SHOULD update settings. dialogManager no longer does
                        console.error("PAY ATTENTION HERE 1. Not sure if we should be updating the IDB dialogue lists 'settings' here")
                        console.warn("ATTENTION!  HMM. NOT SURE IF WHAT we're about to say is true:    Surfer: NOTE: we moved this dialogue from 'to respond to' to ACTIVE, but the settings had ALREADY been updated: " + theDialogId + " WHEN? WHERE?")
                        console.warn("MAYBE we hit this code branch when THE OTHER GUY advanced the dialogue.   CHECK NOW if dialogue " + theDialogId + " has been REMOVED from 'toRespondTo' settings, and added to 'active'")

                        //NOTE special text mentioning moving to ACTIVE
                        this.setState({ commotionForActiveDialog: true,
                                        commotionText: commotionText,
                                       })

                    }      // Was this sitting in the dialogsYetToHearBackFrom set?
                    else if ( awaitingDialogResponse ) {
                        // spent, so, move to the activeDialogs list
                        this.dialogsYetToHearBackFrom.delete(theDialogId)
                        this.activeDialogs.add(theDialogId)

                        // save new state for BOTH sets
                        const jsonHearBackDialogs = JSON.stringify(Array.from( this.dialogsYetToHearBackFrom ))
                        await saveSetting(db, "dialogsYetToHearBackFrom", jsonHearBackDialogs)
                        const jsonActiveDialogs = JSON.stringify(Array.from( this.activeDialogs ))
                        await saveSetting(db, "activeDialogs", jsonActiveDialogs)

                        //NOTE: we probably SHOULD update settings. dialogManager no longer does
                        console.error("PAY ATTENTION HERE 2. Not sure if we shoule be updating the IDB dialogue lists 'settings' here")
                        console.warn("NOTE: we moved this dialogue from 'awaiting response' to ACTIVE, and saved the settings: " + theDialogId)
                    } else {
                        const isActive = this.activeDialogs.has( theDialogId )
                        const isClosed = this.closedDialogs.has( theDialogId )

                        console.log("checkForSpawnedDialog(): isActive: " + isActive + ",   isClose: " + isClosed)
                        console.log("NOTE: We haven't found dialogue " + theDialogId + " in yetToHearBackFrom, nor toRespondTo. We should check ACTIVE, and CLOSED...")
                        if ( !isActive && !isClosed ) {
                            console.warn("We should probably add this dialogue to SOME LIST - prob based on if we're owner, or visitor")
                            if ( guestInDialog ) {
                                console.warn("we're a guest, so, we should prob add it to the toRespondTo list")

                                //WARNING: simply copied from ABOVE (the not-yet-spent logic)
                                console.warn("  This already-built-on dialogue id is NEW to us (to respond to): " + theDialogId)

                                await this.addDialogToListAndSetting(theDialogId, this.dialogsToRespondTo, "dialogsToRespondTo", db)


                                console.warn("We'll now CAUSE A COMMOTION (for this extra-messaged dialog) - show a modal <---")
                                this.setState({ commotionForNewDialog: true,
                                                commotionText: commotionText,
                                             })
                            } else {
                                console.warn("we're the owner, so, we should prob add it to the toHearBackFrom list. HOW could we even arrive here? MAYBE we're RESTORING our wallet?")

                                await this.addDialogToListAndSetting(theDialogId, this.dialogsYetToHearBackFrom, "dialogsYetToHearBackFrom", db)

                            }
                            //alert("could we have done this SOONER?")
                        } else {
                            console.log("dialogue " + theDialogId + " is either ACTIVE, or CLOSED. Ignoring")
                        }




                        //FIXME: LATER, if we're restoring from a wallet, we could find it's already CLOSED. Also, consider if we'd want commotions or not



                    }
                }

            } // if we're a party to this dialog

        } else {

            //FUTURE: detect and analyze SPENT/active dialogs

        }

        return {
            dialogStartsHere:    spawnsADialog,
            aPartyToThisDialog:  aPartyToThis,
            theDialogId:         theDialogId,
        }

    } // checkForSpawnedDialog()

    async addDialogToListAndSetting(theDialogId, theSet, theSettingName, db) {

        // SAVE to set
        theSet.add( theDialogId )

        const jsonDialogs = JSON.stringify(Array.from( theSet ))
        console.log("Here's the set in JSON form (which we'll save to IDB) for setting " + theSettingName + ": ", jsonDialogs);
        await saveSetting(db, theSettingName, jsonDialogs)

        //NOTE: MAYBE won't cause a commotion. We created this dialog
        //console.warn("SHOULD we  CAUSE A COMMOTION? - show a modal? Didn't we CREATE this dialog? THough, if we're RECOVERING from a restored wallet...")

        console.warn("We've updated IDB, and our live set (). check it out. dialogId: " + theDialogId)
        this.setState({ //commotionForNewDialog: true,
                        showDialogIconInMasthead: false},
                        this.checkForDialogsOfOurIdentity)
    }

    // To be called in two scenarios:
    //   - viewResponseCallback()  (when we arrive at a domain)
    //   - when we navigate to a particular tx
    // BUT, if downCounter is 0, don't bother (but, could mention)
    //      WELL, I believe we take care of this by checking .buildable[]
    checkIfInvitable(hasATransient, dtx) {
        let suitableForDialogInvite = false
        let dialogInvitee = null

        //FIXME: prob don't really need to pass in hasATransient
        if ( hasATransient && dtx.outputStates[1] ) {
            if (   dtx.outputStates[0].contract === 'transient'
                && dtx.outputStates[1].contract === 'transient'
                && dtx.outputStates[0].namePath !== dtx.outputStates[1].namePath    // must be a guest-post
                && this.assetCheck( hexStringToAscii( dtx.outputStates[0].namePath ), dtx.outputStates[0].ownerCount )  // host must be ours
                && this.state.ourIdentity !== hexStringToAscii(dtx.outputStates[1].namePath)      // <--- this is subtle, and we're still not checking ownerCount here
                && dtx.buildable[0] === 0
                && (dtx.buildable[1] === 1        // either output 1 must be buildable, OR output 2 (if it EXISTS and is a TRANSIENT (not a DIALOG) )
                    || (dtx.outputStates[2]?.contract === 'transient' && dtx.buildable[2] && dtx.buildable[2] === 2) )    // check if the spawn (base-0 output #2) is a buildable TRANSIENT spawn (not a Dialog spawn)
            ) {
                if ( this.state.ourIdentity === hexStringToAscii( dtx.outputStates[0].namePath ) ) {
                    suitableForDialogInvite = true

                    dialogInvitee = hexStringToAscii( dtx.outputStates[1].namePath )

                    console.warn("We think this tx (at the asset tip) is suitable for inviting to a dialogue. It's an unspent. Invitee: " + dialogInvitee)
                } else {
                    console.warn("NOTE: this tx (at the asset tip) may be suitable for inviting to a dialogue - BUT, from a DIFFERENT asset of yours - " + hexStringToAscii( dtx.outputStates[0].namePath) )
                }
            } else {
                console.log("check for suitable for inviting: no")
            }
        } else {
            console.log("suitable for inviting? no")
        }

        return {
            suitableForDialogInvite: suitableForDialogInvite,
            dialogInvitee:           dialogInvitee
        }
    }

    // This was registered with the shizzleView
    // It's called by shizzleView any time it successfully complete a navigation (fwd or back)
    async postNavNote(self, txid, dtx) {
        // NOT positive that this is a good idea - processing a tx every time we move
        // Tread lightly - we already set state based on the TIP of domain being surferd, and TIP of our identity
        console.error("IMPLEMENT: perform some simple checks for: previously un-noticed dialogs. WHAT ELSE is useful?")
        console.warn("surfer notified that the viewer has navigated to tx " + txid)

        const {dialogStartsHere, aPartyToThisDialog, theDialogId} = await self.checkForSpawnedDialog(await openDB(), txid, dtx, false)


        const hasATransient = dtx.outputStates[0].contract === 'transient'
                          || (dtx.outputStates[0].contract === 'update' && dtx.outputStates[1].contract === 'transient')

        const invitableAnalysis = self.checkIfInvitable(hasATransient, dtx)
        const suitableForDialogInvite = invitableAnalysis.suitableForDialogInvite
        const dialogInvitee           = invitableAnalysis.dialogInvitee

        if ( suitableForDialogInvite ) {
            console.error("post nav note: suitable for dialogue invite: ", suitableForDialogInvite)
            console.error("post nav note: invitee: ", dialogInvitee)
        }
        // Since we just got here, user hasn't yet had a chance to ponder this
        this.setState({ inviteToDialog: false,
                        suitableForDialogInvite: suitableForDialogInvite,
                        dialogInvitee: dialogInvitee,
                        dialogTxId: txid,
                        dialogDtx: dtx,

                        content: '',     // we just arrived here. There shouldn't be any content from a prev tx/look

                        dialogStartsHere:   dialogStartsHere,
                        aPartyToThisDialog: aPartyToThisDialog,
                        theDialogId:        theDialogId,
                    })
    } //postNavNote()

    async checkSetAvatar(db, furthestQuarterlyDtx) {
        const settingLabel = "txsWithProfileInfoFor_" + furthestQuarterlyDtx.limbName + ":" + furthestQuarterlyDtx.outputStates[0].ownerCount
        console.error("Checking for profile txs 'setting' for " + settingLabel)
        const profileTxsSetting = await readSetting(db, settingLabel)
        if (profileTxsSetting === null ) {
            console.warn("We've got no txs yet with profile info (for this setting: " + settingLabel + ")")
        } else {
            console.error("txs with profile info: ", profileTxsSetting.value)
            alert("We've found some txs with profile info - for " + settingLabel + " (check the console)")

            //IMPLEMENT ME:
            //  - order entries by path
            //  - successively build-up a profile based on all of the contributions (in order)
            //  - could also check backwards for any CLEAR-entries command
            
            let profileEntries = []
            if ( profileTxsSetting !== null ) {
                profileEntries = JSON.parse( profileTxsSetting.value )
            }

            //WARNING: for now, we'll just take the FINAL entry
            //         In the future, for where it matters, we could build-up state
            //         in certain ways
            //DECIDED: we won't preserve what the state WAS at previous stages. We'll only
            //         be concerned with what the state is NOW. So, we SHOULD *maybe*
            //         hold onto a 'current state/value' for a given parameter, and
            //         its corresponding PATH. If we ever exceed the path, we could
            //         wipe-out the old state, and advance the PATH
            let finalProfile = null
            for ( const profileEntry of profileEntries ) {
                console.warn("  - found profile entry with txId " + profileEntry.txId + ", and path: " + profileEntry.path)

                const thatTx = await queryFetchDecodeTx(profileEntry.txId, db)
                if ( thatTx === null || typeof thatTx === 'number' ) {
                    alert("ERROR: Big problem in vRC(). Please report txId " + thatTx)
                    throw new Error("Code or DB Error - searching for the profile tx " + thatTx)
                }
                console.warn("We have shzlProfile info for PATH " + profileEntry.path + ":", thatTx.shzlProfile)

                finalProfile = thatTx.shzlProfile

            }
            console.error("BTW: hashVal: "      + finalProfile.avatar.hash)
            console.error("BTW: style: "        + finalProfile.avatar.style)
            console.error("BTW: eyes: "         + finalProfile.avatar.eyes)
            console.error("BTW: eyebrow: "      + finalProfile.avatar.eyebrow)
            console.error("BTW: mouth: "        + finalProfile.avatar.mouth)
            console.error("BTW: clothes: "      + finalProfile.avatar.clothes)
            console.error("BTW: clothesColor: " + finalProfile.avatar.clothesColor)
            console.error("BTW: hairColor: "    + finalProfile.avatar.hairColor)
            console.error("BTW: top: "          + finalProfile.avatar.top)
            console.error("BTW: skin: "        + finalProfile.avatar.skin)

            let currentAvatar = <></>
            if ( finalProfile !== null ) {
                currentAvatar = <>
                                    <AvatarBuilder  size={'100px'}
                                                    justRender={true}
                                                    hashVal={finalProfile.avatar.hash}
                                                    style={finalProfile.avatar.style}
                                                    eyes={finalProfile.avatar.eyes}
                                                    eyebrow={finalProfile.avatar.eyebrow}
                                                    mouth={finalProfile.avatar.mouth}
                                                    clothes={finalProfile.avatar.clothes}
                                                    clothesColor={finalProfile.avatar.clothesColor}
                                                    hairColor={finalProfile.avatar.hairColor}
                                                    top={finalProfile.avatar.top}
                                                    skin={finalProfile.avatar.skin}
                                    />
                                </>
            }

            alert("We've iterated through each of the profile entries. Check console.\n\nFIXME: first consider if we need to ORDER by path")

            this.setState({currentAvatar: currentAvatar})
        }
    } // checkSetAvatar()

    // called-back by shizzleView, when a jump-to-domain completes (successfully, or not)
    // (see ShizzleView's props.subModeResponse())
    async viewResponseCallback(self, found, dtx, latestTxId = null, furthestQuarterlyTxId = null, quarterliesWithProfileInfo = []) {
        console.warn("surfer: viewResponseCallback(): we got a result from shizzleView: success/found: " + found)
        console.warn("surfer: viewResponseCallback(): furthestQuarterlyTxId: ", furthestQuarterlyTxId)
        console.warn("surfer: viewResponseCallback(): quarterliesWithProfileInfo: ", quarterliesWithProfileInfo)
        console.warn("surfer: viewResponseCallback(): dtx we found: ", dtx)
        console.warn("surfer:                         latestTxId: " + latestTxId)
        console.warn("surfer: BTW: state.ourIdentity: " + this.state.ourIdentity + " : " + this.state.ourOwnerCount)

        let requestedDomain = this.state.requestedDomain
        const ourDomain = this.props.ourDomain
        console.warn("  requestedDomain: " + requestedDomain)
        console.warn("  props.ourDomain: " + ourDomain)
        console.warn("  this.state.SELF domain request: " + this.state.selfDomainRequest)

        let firstLanding = false
        if ( requestedDomain === null ) {
            firstLanding = true
            requestedDomain = ourDomain
            console.warn("viewResponseCallback(): we think this is our first JUMP (to our own domain: " + ourDomain + ").")
        }

        const db = await openDB()

        // Has this identity/domain guest-posted on the 'announce' domain?
        //NOTE: this assumes behavior - that if we're NOT here because of a
        //      selfDomainRequest, that we've ALREADY set state.hasAnnounced
        let hasAnnounced = this.state.hasAnnounced
        if ( this.state.selfDomainRequest ) {
            if ( requestedDomain === null ) {
                alert("ERROR: what? we should have a state.requestedDomain")
                throw new Error("ERROR: we should have a state.requestedDomain")
            }
            console.warn("NOTE: we came here because we selected " + requestedDomain + " from a dropdown of OUR own domains.")

            const hasAnnouncedSetting = await readSetting(db, "announcedFor_" + this.state.ourIdentity)
            if (hasAnnouncedSetting === null ) {
                console.warn("You've not yet announced your domain: " + this.state.ourIdentity)

                hasAnnounced = false
                await saveSetting(db, "announcedFor_" + this.state.ourIdentity, false)
            } else {
                hasAnnounced = hasAnnouncedSetting.value
            }
        }

        //FIXME: rename selfDomainRequestOrFirstLanding?
        const selfDomainRequest = this.state.selfDomainRequest || firstLanding
        console.warn("  selfDomainRequest: " + selfDomainRequest)
        console.warn("  requestedDomain: " + requestedDomain)


        let furthestQuarterlyDtx = null
        let furthestQuarterlyStats = null
        if ( furthestQuarterlyTxId === null ) {
            console.warn("surfer vRC(): skipping domain check. We have no valid quarterly")
        } else {
            const quarterlyStats = await checkIfDomainExpiredOrRenewable(db, furthestQuarterlyTxId, this.props.blockHeight)

            console.log("viewResponseCallback(): quarterlyStats: ", quarterlyStats)
            console.log("viewResponseCallback(): qs.furthestQuarterlyDtx: ", quarterlyStats.furthestQuarterlyDtx)
            console.log("viewResponseCallback(): qs.furthestQuarterlyDtx.limbName: ", quarterlyStats.furthestQuarterlyDtx.limbName)

            furthestQuarterlyDtx = quarterlyStats.furthestQuarterlyDtx
            furthestQuarterlyStats = quarterlyStats

            // check "settings" of avatar
            // setState of currentAvatar to a *rendered* avatar - if we find profile/avatar settings
            this.checkSetAvatar(db, furthestQuarterlyDtx)
        }

        const isOurDomain = this.assetCheck( furthestQuarterlyDtx?.limbName, furthestQuarterlyDtx?.outputStates[0].ownerCount )
        // Should we use this.assetCheck, or, this.onOurCurrentIdentity() ?
        // well, let's use both:
        //    for onOurIdentity, we should call this.onOurCurrentIdentity()
        //    for what siteStatus uses (isOurDomain), we'll call assetCheck()

        //FIXED: we weren't checking the ownerCount here. Now we are
        //const onOurIdentity = this.state.ourIdentity === requestedDomain
        let onOurIdentity = this.onOurCurrentIdentity(furthestQuarterlyDtx, true)
        console.warn("on our identity? " + onOurIdentity + "   requestedDomain: " + requestedDomain)

        // Is there a problem?
        //FIXME: document the subtle difference
        if ( selfDomainRequest !== onOurIdentity ) {

            console.warn("Hmm. selfDomainRequest != onOurIdentity: " + selfDomainRequest + ", " + onOurIdentity)

            if ( isOurDomain ) {
                alert("We've arrived at your domain " + furthestQuarterlyDtx.limbName + ":" + furthestQuarterlyDtx.outputStates[0].ownerCount +
                        " but we were aiming for " + requestedDomain + ":" + this.state.ourOwnerCount + ".\n\n" +
                        "We'll be setting your identity to this - since you own it."
                        //"We don't yet support surfing of old/expired/sold domains."
                )
                onOurIdentity = true
                this.setState({ ourIdentity: furthestQuarterlyDtx.limbName,
                                ourOwnerCount: furthestQuarterlyDtx.outputStates[0].ownerCount
                              }
                )
                alert("We'll attempt to recover by changing your current identity to where we are right now (since you own it as well).\n" +
                    "Please be sure to NOT set the older identity as your default."
                )
            } else {
                alert("We've arrived at domain " + furthestQuarterlyDtx.limbName + ":" + furthestQuarterlyDtx.outputStates[0].ownerCount +
                        " but we were aiming for an ownerCount of " + this.state.ourOwnerCount + ".\n" +
                        "You don't own this newer version of the domain, and we don't yet support surfing of outdated domains."
                )

                // if we have OTHER non-outdated claims/domains, offer a drop-down (in a modal, using assetDropdown_) to choose one
                //    (see state.showForceNewIdentityModal, below)

                // MAYBE have to outdate our domain NOW (claimedrecords?), then re-generate a drop-down list?
                // Double-check if this domain was already marked as outdated.
                // If not, mark it so.
                let foundOurId = false
                let nonOutdatedClaimsCount = 0
                for ( let d = 0; d < this.state.claimedRecords.length; d++ ) {
                    const recordName = this.state.claimedRecords[d].name
                    const ownerCount = this.state.claimedRecords[d].ownerCount
                    const outdated   = this.state.claimedRecords[d].outdated
                    const forFriend  = this.state.claimedRecords[d].forFriend

                    if ( recordName === this.state.ourIdentity &&
                         ownerCount === this.state.ourOwnerCount) {

                        foundOurId = true
                        if ( outdated ) {
                            alert("This domain was ALREADY marked as outdated - even though we don't yet allow surfing such domains.")
                            this.populateTwoSetsAndDropLists()
                        } else {
                            alert("We'll now mark this identity of yours as OUTDATED: " + this.state.ourIdentity + ":" + this.state.ourOwnerCount)
                            outdateDomainOfMine(db, this.state.ourIdentity, this.state.ourOwnerCount)
                            const claimedRecs = this.state.claimedRecords
                            claimedRecs[d].outdated = true
                            this.setState({claimedRecords: claimedRecs}, this.populateTwoSetsAndDropLists)
                        }
                    } else {
                        if ( !outdated && !forFriend ) {
                            nonOutdatedClaimsCount++
                        }
                    }
                }

                if ( !foundOurId ) {
                    throw new Error("CODE ERROR 2833: couldn't find our ID: " + this.state.ourIdentity + ":" + this.state.ourOwnerCount)
                }
                if ( nonOutdatedClaimsCount > 0 ) {
                    // give user drop-down to choose an alternate IFF they have multiple ids
                    this.setState({showForceNewIdentityModal: true})
                } else {
                    alert("We couldn't find an alternative, non-outdated, ID you own, for you to choose. You may need to CLAIM a domain/identity.")
                    this.signInOutCallback(false)  // back to home (or onramp), but don't lock wallet
                }

                return
            }
        }

        if ( selfDomainRequest ) {
            // we've set our identity. Re-evaluate dialogs of interest
            await this.checkForDialogsOfOurIdentity( true )
        }




        let displayNotMuchToSee = false
        if ( selfDomainRequest && (dtx.outputStates[0].mode === '4B' || dtx.outputStates[0].mode === '4b') ) {
            console.warn("displaying 'Not-much-to-see-yet modal...'")
            displayNotMuchToSee = true
        }
        // avoid 'displaying' domains that haven't yet been reached, claimed (modes 'X', or 'P'), or 'built' (mode 'K')
        else if ( !(found && dtx.address) ||
                ( !selfDomainRequest &&
                    (dtx.outputStates[0].mode === '50' || dtx.outputStates[0].mode === '4B' || dtx.outputStates[0].mode === '4b')
                )
            ) {


            console.warn("displaying 404")
            this.setState({display404: true,
                           search: true,     // enable user to search for somewhere else

                           isOurDomain:          isOurDomain,    // do we own this site/identity we're visiting

                           onOurCurrentIdentity: onOurIdentity,  // are we CURRENTLY assuming the site/identity we're visiting?
                           dialogStartsHere:     false,
                           aPartyToThisDialog:   false,
                           theDialogId:          0,


                           furthestQuarterlyTxId:      furthestQuarterlyTxId,  // new var to be more quarterly-aware
                           furthestQuarterlyDtx:       furthestQuarterlyDtx,
                           furthestQuarterlyStats:     furthestQuarterlyStats,

                           quarterliesWithProfileInfo: quarterliesWithProfileInfo,
                        })
            return
        }

        const hasATransient = dtx.outputStates[0].contract === 'transient'
                          || (dtx.outputStates[0].contract === 'update' && dtx.outputStates[1].contract === 'transient')
        console.log("This is/has a TRANSIENT tx? " + hasATransient)

        // check for spawned dialogs
        const {dialogStartsHere, aPartyToThisDialog, theDialogId} = await this.checkForSpawnedDialog(db, latestTxId, dtx, true)

//FIXME: why are we looking at address parts? Shouldn't we just look at .limb, and .ownerCount?
//       COULD IT BE because different contract types might have different names for these fields? TBD  <------ check on this
        const addressParts = dtx.address.split('/')
        console.warn("surfer found address parts: ", addressParts)

        // Could this even happen?
        if ( !(addressParts[2] && addressParts[3]) ) {
            alert("ERROR: what's the limb, and owner count?   NOTE: FQ info NOT being set.")

            this.setState({ display404:           false,
                            displayNotMuchToSee:  false,
                            isOurDomain:          isOurDomain,
                            onOurCurrentIdentity: onOurIdentity,
                            dialogStartsHere:     dialogStartsHere,
                            aPartyToThisDialog:   aPartyToThisDialog,
                            theDialogId:          0,


                            furthestQuarterlyTxId:      null,  // new var to be more quarterly-aware
                            furthestQuarterlyDtx:       null,
                            furthestQuarterlyStats:     null,

                            quarterliesWithProfileInfo: [],
                        })

            return
        }

        let thisIsOurFirstSelfPost = false
        if ( onOurIdentity && addressParts.length === 5 && addressParts[4] === '00001' ) {
            console.warn("We think this is the first post on our identity, so, has no transient")
            thisIsOurFirstSelfPost = true
        }

        // At least one setting is dependent on our current identity
        // Now that we've arrived here, re-evaluate
        await this.maybeUpdateFeeSettings(thisIsOurFirstSelfPost)

        // what if this requestedDomain is a GUEST here
        // Well, this is just a check. It's not critical.
        const limb = addressParts[2]
        const ownerCount = parseInt(addressParts[3])

        console.warn("surfer jumped to limb " + limb + ", owner #" + ownerCount)
        console.warn("we should compare limb, owner to these claimed records: ", this.state.claimedRecords)

        const ownershipRecords = this.state.claimedRecords
        let selfOwned = false
        let previouslyOwned = false
        for ( let i = 0; i < ownershipRecords.length; i++ ) {
            if ( ownershipRecords[i].name === limb ) {
                if ( ownershipRecords[i].ownerCount === ownerCount ) {
                    console.warn("You own this asset (" + limb + ", ownerCount " + ownerCount + ")")
                } else {
                    console.warn("You USED TO own this asset (" + limb + "). You were owner "
                                + ownershipRecords[i].ownerCount + ". This is now owned by owner #"  + ownerCount
                                + ". Marking your old record as OUTDATED.")
                    console.warn("ABOUT to mark domain " + limb + " as OUTDATED. okay?")
                    await outdateDomainOfMine(db, limb, ownershipRecords[i].ownerCount)

                    // continue, since we may have owned this more than once
                }
            }
        }

        console.warn("self-owned: " + selfOwned + "    previously-owned: " + previouslyOwned)
        if ( previouslyOwned && !selfOwned ) {
            console.warn("NOTE: you USED to own asset (" + limb + ", ownerCount " + ownerCount + "). You no-longer do.")
        }

        //FIXME: rename to userCouldGuestPostOntoActualDomain
        let guestPostingCanHappen = false
        let satsPerGuestPost = -1

        // GUEST POSTS CAN HAPPEN iff:
        //   1 - we're surfing a domain that isn't our CURRENT domain (our current Identity)
        //       (use onOurIdentity)
        //   2 - the tip of this domain (where we've arrive just now) is a transient
        //       (use hasATransient)
        //   3 - THIS DOMAIN allows guest posts (specified in THIS transient)
        //       (use guestPostingCanHappen)

        let ourDtxToSet         = this.state.ourDtx
        let ourDtxLatestIdToSet = this.state.ourDtxLatestId

        // if it's NOT ours, identify if we can post (guest-post) on it
        if ( !onOurIdentity && hasATransient ) {
            // check if guest-posting is allowed, and if so, at what price
            //    FIRST: check if this has a TRANSIENT output <-----
            console.warn("viewResponseCallback(): checking if guest-posting is allowed...")
//FIXME: this might share some code/approach (working backwards through outputs) with handleSubmitPost()

            const numOutputs = dtx.outputStates.length
            console.log("surfer viewResponseCallack(): There are " + numOutputs + " outputs to choose from")


            //FIXME: will need to double-check that we're not at the FINAL transient


            // work BACKWARDS through outputs
            let relevantTransientOutputIdx = -1
            for ( let i = numOutputs-1; i > -1; i-- ) {
                const type = dtx.outputStates[i].contract
                const outputDomainName = hexStringToAscii( dtx.outputStates[i].namePath )
                console.log("  output " + i + ": " + dtx.outputStates[i].contract + " for domain " + outputDomainName)


                //FIXME: avoid EBFRAs, payouts, change, final-transients, final updates, and too-early Update, and too-early Quarterlies
                if ( type === 'update' || type === 'continue' || type === 'expand' ) {
                    console.warn("    Bleh. Reached a expando, update or continue. NOT a transient")
                    break
                } else if ( type === 'transient' ) {
                    if ( outputDomainName === requestedDomain ) {
                        relevantTransientOutputIdx = i
                        console.log("    FOUND our relevant transient output!")
                        break
                    } else {
                        console.log("    this output is transient, but of a DIFFERENT domain")
                    }
                } else if ( type === 'EBFRA Speaker' ) {
                    console.warn("    Ignoring base-0 output #" + i +": EBFRA speaker")
                } else if ( type === 'dialog' ) {
                    console.warn("    viewResponseCallback(): Ignoring base-0 output #" + i +": DIALOGUE")
                } else {
                    console.error("ERROR: Please make sure to prohibit building off of this contract type: " + type)
                    alert("CODING ERROR for post: read the console to learn what went wrong while searching for a transient output")
                    return
                }
            }
            if ( relevantTransientOutputIdx !== -1 ) {
                const relevantOutput = dtx.outputStates[relevantTransientOutputIdx]
                //FIXME: set state so we know this while rendering
                if ( relevantOutput.guestPostsAllowed ) {
                    console.warn("Yay. The tip of this asset has a transient (base-0 output #"
                        + relevantTransientOutputIdx + ") which allows guest-posting - for " + relevantOutput.guestPostPrice + " sats")
                    console.warn("Yay. The tip of the requested STRANGER domain (" + requestedDomain + ") has a transient (base-0 output #"
                        + relevantTransientOutputIdx + ") which allows guest-posting - for " + relevantOutput.guestPostPrice + " sats")
                    guestPostingCanHappen = true
                    satsPerGuestPost = relevantOutput.guestPostPrice
                }
            }

        } else {
            console.log("we've arrived somewhere that is EITHER our current Identity (" + this.state.ourIdentity + "), or, doesn't have a TRANSIENT at its tip")

            // If this is our identity, AND it's a transient, remember this, so that we
            // can LATER (while visiting)
            if ( onOurIdentity ) {
                // avoid an extra setState() call

                if ( hasATransient ) {
                    ourDtxToSet = dtx
                    ourDtxLatestIdToSet = latestTxId
                    //this.setState({ ourDtx: JSON.stringify(dtx),
                    //                ourDtxLatestId: latestTxId})
                } else {
                    ourDtxToSet = null
                    ourDtxLatestIdToSet = ''
                    //this.setState({ ourDtx: '',
                    //                ourDtxLatestId: ''})
                }
            }
        }


        // Check for the inability to actually spend from this domain
        // Check for a FAILED attempt to redeem: we DON'T have the keys required to spend this tx
        // This could happend if we tried to redeem something we gifted, but it was already redeemed
        // OR maybe this could happen if we tried to redeem something our friend (the giver) has already redeemed
        if ( onOurIdentity) {
            const zeroethOutputMode = dtx.outputStates[0].mode
            if ( zeroethOutputMode === '70' ) { // 'p'
                if ( ! await this.checkForAuthorization(db, dtx) ) {
                    return false
                }
            }
        }

        if ( !this.state.buildAnnouncement && !this.state.scanDomains ) {
            // since we're not here for special cases (announcements, nor scanning)
            // build site-specific sets, and dropLists needed for render()
            // BUT, we have to do it BEFORE setting search to TRUE
            // (because that's when we display the coolSites widget)
            this.populateTwoSetsAndDropLists()

            //if ( !this.setOfClaimed.has( requestedDomain ) ) {
                console.warn("Adding to recents: " + requestedDomain)
                console.log("BTW: setOfClaimed: ", this.setOfClaimed)
                this.addToRecents( requestedDomain )
            //}

        }


        // if this has a guest post on it (and we're the host), set flag that we COULD invite to dialog (respond to gp)
        const invitableAnalysis = this.checkIfInvitable(hasATransient, dtx)
        const suitableForDialogInvite = invitableAnalysis.suitableForDialogInvite
        const dialogInvitee           = invitableAnalysis.dialogInvitee

        this.setState({
                        furthestQuarterlyTxId:      furthestQuarterlyTxId,  // new var to be more quarterly-aware
                        furthestQuarterlyDtx:       furthestQuarterlyStats.furthestQuarterlyDtx,
                        furthestQuarterlyStats:     furthestQuarterlyStats,

                        quarterliesWithProfileInfo: quarterliesWithProfileInfo,

                        ourDtx:                ourDtxToSet,
                        ourDtxLatestId:        ourDtxLatestIdToSet,

                        requestedDomain:       requestedDomain,     // if this is the first landing, this is now set to props.ourDomain

                        isOurDomain:           isOurDomain,
                        onOurCurrentIdentity:  onOurIdentity,
                        guestPostingCanHappen: guestPostingCanHappen,
                        satsPerGuestPost:      satsPerGuestPost,

                        // If WE have a transient (our identity does), then we can guest-post elsewhere
                        // (on domains that have a transient, AND allow guest-posts).
                        // If this is OUR id/site we've landed on, take note if we have a transient,
                        // OTHERWISE, keep the same setting
                        weHaveATransient:      selfDomainRequest ? hasATransient : this.state.weHaveATransient,

                        latestTxId:            latestTxId,
                        dtx:                   dtx,

                        hasAnnounced:          hasAnnounced,

                        display404:            false,
                        displayNotMuchToSee:   displayNotMuchToSee,

                        search: true,
                        idSearch: false,

                        isAFave: this.setOfFavoriteDomains.has( requestedDomain ),

                        suitableForDialogInvite: suitableForDialogInvite,
                        inviteToDialog: false,
                        dialogInvitee:  dialogInvitee,
                        dialogTxId:     latestTxId,
                        dialogDtx:      dtx,

                        dialogStartsHere:   dialogStartsHere,
                        aPartyToThisDialog: aPartyToThisDialog,
                        theDialogId:        theDialogId,

                    },
                    this.vRCPostProcess) // pass this function, so that the setState() takes effect first

        ////// Post-arrival special processing for two cases:
        //////   1 - 'announcing' a site/domain/identity (also scans domains)
        //////   2 - scanning domains

    } // viewResponseCallback

    onOurCurrentIdentity(furthestQuarterlyDtx = this.state.furthestQuarterlyDtx, logThis = false) {

        if ( logThis ) {
            console.log("surfer onOurCurrentIdentity(): furthest.limbName: ", furthestQuarterlyDtx?.limbName)
            console.log("surfer onOurCurrentIdentity(): state.ourIdentity: ", this.state.ourIdentity)
            console.log("surfer onOurCurrentIdentity(): state.ourOwnerCount: ", this.state.ourOwnerCount)
            console.log("surfer onOurCurrentIdentity(): furthest.outputStates[0].ownerCount: ", furthestQuarterlyDtx?.outputStates[0].ownerCount)
        }

        return furthestQuarterlyDtx?.limbName === this.state.ourIdentity
            && furthestQuarterlyDtx?.outputStates[0].ownerCount  === this.state.ourOwnerCount
    }

    assetCheck(domain, ownerCount) {
        for ( let d = 0; d < this.state.claimedRecords.length; d++ ) {
            if ( this.state.claimedRecords[d].name === domain &&
                 this.state.claimedRecords[d].ownerCount === ownerCount &&
                 !this.state.claimedRecords[d].forFriend
                ) {
                    return true
                }
        }
        return false
    }

    /**
     * Called synchronously only AFTER final setState() in viewResponseCallback() takes effect.
     * Only takes action if we're bulding an 'announcement', or scanning for domains
     *
     * @returns nothing
     */
    async vRCPostProcess() {

        if ( this.state.buildAnnouncement || this.state.scanDomains ) {
            // Ask shizzleView to STOP reporting on guestPosts (we're at the tip of 'BANNO' asset)
            if ( this.setViewerReportOnGuestPosts ) {
                console.log("About to tell viewer that we're NO-LONGER interested in guest posts")
                this.setViewerReportOnGuestPosts(this.viewerRef, false)

                console.warn("will now save this domains list to IDB: ", this.setOfAnnouncedDomains)
                // save the list of announce domains to IDB
                const jsonDoms = JSON.stringify(Array.from( this.setOfAnnouncedDomains ));
                console.log("Here's the set in JSON form (which we'll save to IDB): ", jsonDoms);
                await saveSetting(await openDB(), "announcedDomains", jsonDoms)

                // consider moving/embedding this somewhere else? meh
                const jumpBackToThis = this.state.jumpBackToThis
                this.setState({offerToVisitAnnounce: false, jumpBackToThis: ''})

                if ( ! this.state.buildAnnouncement ) {
                    const me = this
                    execAsync2( async function() {
                        // show an EXTRA modal - that mentions how many announced domains we learned about.
                        // we delay it, for 1 second, so that the progressListModal
                        // gets rendered FIRST, then gets overwritten by this extra modal
                        me.setState({showNewDomains: true})
                    }, 750) //800ms works. maybe getting close to 'too soon'?

// commented-out at 5:15pm on 1/6/22
//                    await mySleep(200, "Now, pausing real quick...")

                    // WHERE should we be jumping now that we've scanned for new domain announcements?
                    // We've kept track with state.jumpBackToThis

                    let thisIsOurs = jumpBackToThis === this.state.ourIdentity

                    // this will also clear state.scanDomains
                    // NOTE: ourIdentity should never have changed during all of this
                    me.pokeTheViewer(null, null, jumpBackToThis, thisIsOurs)

                    // we're NOT building an 'announcement'. We're done here. Jump back.
                    return
                }
            }
        } // if announce, or scan

        // IF we've arrived at a site where we'll be ANNOUNCING ourselves,
        // finish the chain of steps:
        //  - ask shizzleView to STOP reporting on guest-posts
        //    (a side-effect of handleJumpToOwnDomain() calling pokeTheViewer() )
        //  - build a guest-post (on BANNO/ANNOUNCEMENT)
        //  - update the {'announcedFor_'+identity} setting
        //  - show a modal
        //  - jump back to our identity
        //
        // We setup this so that the final setState({}) in viewResponseCallback() has propagated.
        // So, we can now submit/build a guest post - and pass along a function to THEN jump back to our identity
        if ( this.state.buildAnnouncement ) {

            console.warn("about to announce " + this.state.ourIdentity)

            // We pass a function to handleSubmitGuestPost() so that any setState(), called within, really completes
            // before we take the final step - calling handleJumpToOwnDomain()

            const me = this
            console.log("Will guest post (ANNOUNCE) " + me.state.ourIdentity)

            try {
                console.warn("vrcPostProcess(): calling handleSubmitGuestPost() (to announce), with a finalProcessing (2nd) param...")
                const ret = await me.handleSubmitGuestPost(null, null, function () {
                        console.warn("vRCPostProcess() FINAL processing: we've FINALLY finished guest-posting an ANNOUNCEMENT. Will now jump BACK to our domain.")

                        // this should execute when we're ALL DONE
                        me.handleJumpToOwnDomain(me.state.ourIdentity)
                    })
                console.log("    DONE announcing " + me.state.ourIdentity)
                console.log("return value from handleSubmitGuestPost: " + ret)

            } catch (error) {
                me.setState({buildAnnouncement: false})

                console.error("trouble building a guest post? " + error)
                alert("WHOOPS: We had trouble building a guest post (ANNOUNCEMENT) for " + me.state.ourIdentity)

                me.handleJumpToOwnDomain(me.state.ourIdentity)
            }

        } // if buildAnnouncement

    } // vRCPostProcess()

    closeYouWereAnnounced() {
        // closes the one-time modal
        this.setState({showYouWereAnnounced: false})
    }
    closeNewDomainsModal() {
        // not necessary. We clear before scanning
        this.setOfNewestDomains.clear()

        // closes the one-time modal
        this.setState({showNewDomains: false})
    }
    closeDialogCommotionModal() {
        this.setState({ commotionForNewDialog: false,
                        commotionText: ''
        })
    }
    closeActiveDialogCommotionModal() {
        this.setState({ commotionForActiveDialog: false,
                        commotionText: ''
        })
    }

    // our shizzleView instance here reports the domain names of
    // guest-posts to the 'banno' domain (at some point: the annoucements domain)
    receiveGuestPostReports(name) {
        const alreadyHasIt = this.setOfAnnouncedDomains.has(name)

        this.setOfAnnouncedDomains.add(name)
        //console.error("after add: ", this.setOfAnnouncedDomains)

        // avoid mentioning repeats
        if ( !alreadyHasIt ) {
            this.setOfNewestDomains.add(name)
            console.warn("Surfer: got a report on a NEW domain being announced: " + name)
        }
    }

    // Jump to a 'stranger' domain - not our current Identity (theoretically, it could be OWNED by us)
    pokeTheViewerWithStranger() {
        console.log("pokeTheViewerWithStranger()")

        console.log("state.strangerSearch: " + this.state.strangerSearch)
        console.log("state.strangerDomain: " + this.state.strangerDomain)

        if ( this.state.strangerSearch === this.state.ourIdentity ) {
            console.warn("Whoops. That's the user's identity Let's just go back HOME")
            alert("You're trying to jump back to YOUR identity. We're going to return you home.")

            this.setState({selectedDomain: this.state.ourIdentity},
                    this.handleJumpToOwnDomain(this.state.ourIdentity))
            return
        }

        if ( this.state.strangerSearch.trim().length > 1 ) {
            console.log("pokeTheViewerWithStranger() strangerSearch is " + this.state.strangerSearch)
            this.pokeTheViewer(null, null, this.state.strangerSearch, false)
        } else if ( this.state.strangerDomain.trim().length > 1 ) {
            this.pokeTheViewer(null, null, this.state.strangerDomain.trim(), false)
        }
    }

    // called from THREE places:
    //      handleJumpToOwnDomain(), and
    //      direct button press from stranger options
    //      handleAnnounceClick() (with a SPECIAL 4th param)
    //
    // when jump is complete, we end-up called-back by shizzleView, at viewResponseCallback(), above
    pokeTheViewer(x, y, requestedDomain, isOurs=false, scanDomains=false, optionalAnnouncementContent = null) {

        console.warn("pokeTheViewer(): will attempt domain: " , requestedDomain)

        if ( this.viewerDomainCallback && this.viewerRef ) {
            console.warn("surfer now jumping...")

            // remember this request
            this.setState({ requestedDomain: requestedDomain,
                            selfDomainRequest: isOurs,    // if this is set, we're setting our ID.
                            strangerDomain: '',
                            strangerSearch: '',
                            selectedDomain: '', // clear dropdown

                            search:         false,
                            idSearch:       true,

                            scanDomains:    scanDomains,

                            //WARNING: optionalAnnouncementContent is a special FLAG
                            content: optionalAnnouncementContent ?         // normally, clear
                                        optionalAnnouncementContent : '',
                            buildAnnouncement: optionalAnnouncementContent ? true : false,  // normally, false

                            currentAvatar: <></>,
                        })

            // kicks-off a jump to the requestedDomain
            // When done, it calls-back to viewResponseCallback()
            this.viewerDomainCallback( this.viewerRef, requestedDomain )
        } else {
            console.error("Surfer NOT jumping to " + requestedDomain + " (no callback)?")
            alert("Error trying to jump to a domain: no viewer callback, or reference")
            throw new Error("error in pokeTheViewer - no callback or reference specified")
        }
    } // pokeTheViewer

    async advanceTheViewer() {
        console.warn("advanceTheViewer()")

        if ( this.viewerButtonCallback && this.viewerRef ) {
            console.warn("surfer now advancing the viewer...")

            await this.viewerButtonCallback( this.viewerRef, "next" )

        } else {
            console.error("Surfer NOT advancing (no callback)?")
        }
    }

    /**
     * NOTE: finalProcessing() defaults to blank function, but will be called after guest post succeeds, OR fails
     *
     * NOTE: two dummy params to allow direct calls, and .onClick() calls to co-exist
     */
    async handleSubmitGuestPost(event = null, blah=null, finalProcessing = function() { }) {

        console.log("handleSubmitGuestPost(): Let's see if we can reconstitute ourDtx - for OUR identity: " + this.state.ourIdentity)

        const dtx = this.state.inviteToDialog ? this.state.dialogDtx : this.state.ourDtx

        const numOutputs = dtx.outputStates.length
        console.log("surfer handleSubmitGuestPost(): There are " + numOutputs + " outputs to choose from")

        // on/from which output OF OURs should we build (guest-post)?
        let bestOutput = -1
        let bestOutputType = null
        let prevRabinPKH
        let transientMode = ''
        // work BACKWARDS through outputs
        for ( let i = numOutputs-1; i > -1; i-- ) {
            console.log("  output " + i + ": " + dtx.outputStates[i].contract)
            const buildable = dtx.buildable[i] === i
            console.log("    buildable? " + buildable)

            const type = dtx.outputStates[i].contract
            const mode = dtx.outputStates[i].mode

            //FIXME: avoid EBFRAs, payouts, change, final-transients, final updates, and too-early Update, and too-early Quarterlies
            if ( type === 'update' || type === 'continue' || type === 'expand' ) {
                console.error("ERROR: we can't guest-post off of " + type + ". We should NOT have arrived here while trying to guest-post")
                alert("ERROR: why did we think we could guest post? read the console")

                finalProcessing()
                return false

            } else if ( type === 'transient' ) {
                const outputDomainName = hexStringToAscii( dtx.outputStates[i].namePath )
                if ( outputDomainName === this.state.ourIdentity ) {//requestedDomain ) {
                    if ( ! buildable ) {
                        console.warn("SKIPPING our output " + i + ". It's not buildable")
                    } else {
                        console.warn("SUPER: we can guest-post off of our " + type + ", output "
                                        + i + ". We should be ready to build!")
                        bestOutput = i
                        bestOutputType = type
                        prevRabinPKH = dtx.outputStates[bestOutput].rabinPKH
                        transientMode = hexByteToAscii(dtx.outputStates[bestOutput].mode)
                        break
                    }
                } else {
                    console.warn("NOT guest-posting off of this transient output "
                                + i + ". It's for the wrong domain (" + outputDomainName + ")")
                }
            } else if ( type === 'dialog' ) {
                console.warn("    hsGp(): Ignoring base-0 output #" + i +": DIALOGUE")
            } else if ( type === 'EBFRA Speaker' ) {
                console.warn("IGNORING base-0 output #" + i +": EBFRA speaker")
            } else {
                console.error("ERROR: while trying to guest post. Make sure to prohibit building off of this contract type: " + type)
                alert("ERROR: read the console to learn what may have gone wrong while trying to guestpost")

                finalProcessing()
                return false
            }
        }
        console.warn("handleSubmitGuestPost(): note. setting guestBestOutputNum to " + bestOutput + ". OKay?")


        if ( bestOutput === -1 ) {

            if ( this.state.inviteToDialog ) {
                alert("We need to abort. We're trying to invite to Dialog, but can't find an output upon which to build. IMPLEMENT recovery, options/suggestions")
                throw new Error("trouble finding useable output for inviting to dialogue")
            }
            console.error("handleSubmitGuestPost(): Couldn't EASILY find an appropriate output from which to guestPost (from txId " + this.state.ourDtxLatestId + ")")

            const lookingForDomain = this.state.ourIdentity
            console.log("    FROM which asset are we trying to build ? " + lookingForDomain)

            let lookingForOwnerCount
            if ( hexStringToAscii( dtx.outputStates[0].namePath) === lookingForDomain ) {
                lookingForOwnerCount = dtx.outputStates[0].ownerCount
            } else if ( hexStringToAscii( dtx.outputStates[1]?.namePath) === lookingForDomain ) {
                lookingForOwnerCount = dtx.outputStates[1].ownerCount
            } else {
                alert("hmm. trouble finding our appropriate owner count")
                throw new Error("Had trouble finding our appropriate asset ownerCount")
            }

            const db = await openDB()
            const result = await findLatestUTXO(db, lookingForDomain, lookingForOwnerCount)

            console.error("result of findLatestUTXO() for our domain " + lookingForDomain
                        + ", owner " + lookingForOwnerCount + ": ", result)

            if ( result !== null && !result.ownerChanged ) {
                // consult result.result
                //      .tx should have the txid
                const newLatestTxId = result.result.tx
                console.error("--------------------")
                console.error("handleSubmitGuestPost(): EVASIVE guest-posting MANEUVERS (because of finalPost): jumping to latestUTXO: " + newLatestTxId)

                //FIXME: show a swirly while we query + pause? meh

                const newTx = await queryFetchDecodeTx(newLatestTxId, db)
                if ( newTx === null || typeof newTx === 'number' ) {
                    alert("ERROR: Big guest-post problem. Please report txId " + newLatestTxId)
                    throw new Error("Code or DB Error - searching for the tx " + newLatestTxId)
                }
                console.error("got the NEW guest-posting tx: ", newTx)

                console.warn("We're about to take evasive guest-post maneuvers from a new txid: " + newLatestTxId)

                const me = this

                // call this function again - AFTER setting state with NEW latest tx
                // NOTE that we pass-along a post-processing function
                this.setState({  ourDtxLatestId: newLatestTxId,
                                 ourDtx: newTx,
                              },
                                    () => this.handleSubmitGuestPost(  event, null, finalProcessing ) //NOTE: added required null (2nd param)
                            )
                return true

            } else {
                alert("While attempting a guest-post, is this ownership expired?")
                this.setState({disablePubButton: false})

                finalProcessing()
                return false
            }
        }

        const latestGuestTxIdOnWhichToBuild = this.state.inviteToDialog ? this.state.dialogTxId : this.state.ourDtxLatestId
        const guestDtx = this.state.inviteToDialog ? this.state.dialogDtx : this.state.ourDtx
        const guestBestOutputNum = bestOutput
        const guestOutputStateOnWhichToBuild = dtx.outputStates[ bestOutput ]

//alert("Guest Output on which to build: " + bestOutput + "   inviteToDialog: " + this.state.inviteToDialog + "   dialogInvitee: " + this.state.dialogInvitee)
console.warn("Guest Output on which to build: " + bestOutput + "   inviteToDialog: " + this.state.inviteToDialog + "   dialogInvitee: " + this.state.dialogInvitee)

        console.log("handleSubmitGuestPost(): param latestGuestTxId...: " + latestGuestTxIdOnWhichToBuild)
        console.log("handleSubmitGuestPost(): param guestDtx: ", guestDtx)
        console.log("handleSubmitGuestPost(): param guestBestOutputNum: " + guestBestOutputNum)
        console.log("handleSubmitGuestPost(): param guestOutputStateOnWhichToBuild: ", guestOutputStateOnWhichToBuild)

        return await this.handleSubmitPost( event,
                                            null,
                                            true,
                                            latestGuestTxIdOnWhichToBuild,
                                            guestDtx,
                                            guestBestOutputNum,
                                            guestOutputStateOnWhichToBuild,
                                            finalProcessing)
    } // handleSubmitGuestPost

    closeVideoRecorderModal() {
        this.setState({showVideoModal: false})
    }
    handleVideoRecordClick(aBool) {
        this.setState({showVideoModal: true, chooseMimeTypeBasedOnSupportForPlayback: aBool})  // chooseMime... temp exploration
    }

    // called-back by siteStatusModal
    purchaseDomain() {
        console.warn("We're about to build a new quarterly, WHILE PAYING " + this.state.furthestQuarterlyStats.priceInSats + " satoshis TO BUY IT.")
        this.setState(  {
                            purchaseThisDomain: true
                        },
                        () => this.forceBuildNextQuarterly()
                     )
    }

    // called-back by siteStatusModal
    setDomainForSale(domainPriceInSats, avatarProps = null) {
        alert("We're about to build a new quarterly, while SETTING its price in satoshis: " + domainPriceInSats)
        this.setState(  {
                            domainPriceInSats: domainPriceInSats
                        },
                        () => this.forceBuildNextQuarterly(avatarProps)
                     )
    }

    // called-back by siteStatusModal
    // Build the next/furthest Quarterly - sometimes with canned content
    //     There may also be avatarProps included as a parameter
    // Be sure to spawn a Periodical
    // This relies on state.furthestQuarterlyTxId, and state.furthestQuarterlyDtx already being set
    async forceBuildNextQuarterly(avatarProps = null) {

        //NOTE: this was extracted from handleSubmitPost() - where we need to take evasive
        //      maneuvers to find an available UTXO (though, it should be a TRANSIENT)

        const newTx = this.state.furthestQuarterlyDtx
        const newLatestTxId = this.state.furthestQuarterlyTxId

        console.log("fBNQ(): got the furthest QUARTERLY tx: ", newTx)

        console.warn("We're about to take evasive maneuvers from the furthest quarterly: " + newLatestTxId)

        const finalProcessing =  async function() {
                                    console.warn("fBNQ(): finalProcessing")

                                    if ( typeof this === 'undefined' ) {
                                        alert("Something went wrong while trying to build a Quarterly.")
                                        return
                                    }

                                    console.warn("fBNQ(): finalProcessing: state.latestTxId is now: ", this.state.latestTxId)
                                    console.warn("state.dtx is now: ", this.state.dtx)
                                    console.log("we're DONE building a new quarterly, we're now going to re-evaluate the furthestQuarterly...")

                                    const newFurthestQuarterlyTxId = this.state.latestTxId
                                    console.warn("Using latestTxId as new furthestQuarterlyTxId: ", newFurthestQuarterlyTxId)

                                    // snippet taken from showSiteStatus()
                                    const qs = await checkIfDomainExpiredOrRenewable(await openDB() , newFurthestQuarterlyTxId, this.props.blockHeight)

                                    if ( qs !== null ) {
                                        console.warn("forceBuildNextQuarterly(): finalProcessing will now setState using fqti: ", newFurthestQuarterlyTxId)
                                        console.warn("forceBuildNextQuarterly(): finalProcessing will now setState using qs: ", qs)

                                        let jumpToNewPurchase = false
                                        // we've just purchased this
                                        if ( this.state.purchaseThisDomain ) {
                                            const dtx = qs.furthestQuarterlyDtx
                                            console.warn("fBNQ(): BTW: this is dtx: ", dtx)
                                            console.warn("fBNQ(): and limbName is " + dtx.limbName)
                                            console.warn("fBNQ(): and NEW ownerCount is " + dtx.outputStates[0].ownerCount)

                                            const domainRec = await registerDomainOfMine(await openDB(),
                                                                                        dtx.limbName,
                                                                                        dtx.outputStates[0].ownerCount,
                                                                                        this.props.blockHeight,
                                                                                        false,  // not outDated
                                                                                        false,  // not forAFriend
                                                                                        null)   // nor fromFriend
                                            console.warn("fBNQ(): registered domainRec: ", domainRec)
                                            alert("You've succeeded in purchasing domain " + dtx.limbName + ":" + dtx.outputStates[0].ownerCount + ".\n\nCongratulations!")

                                            jumpToNewPurchase = true
                                        }

                                        this.setState({ furthestQuarterlyStats: qs,
                                                        furthestQuarterlyTxId: newFurthestQuarterlyTxId,
                                                        furthestQuarterlyDtx: qs.furthestQuarterlyDtx,
                                                        domainPriceInSats: 0, // once a quarterly is built, change this to zero (NOT for sale)
                                                        purchaseThisDomain: false,
                                                      },
                                                        () =>   {
                                                                    console.warn("fBNQ(): final processing: we've set state, and it's taken effect:")
                                                                    console.log("  FRESH?: state.furthestQuarterlyTxId is now: ", this.state.furthestQuarterlyTxId)
                                                                    console.log("  FRESH?: state.furthestQuarterlyDtx is now: ", this.state.furthestQuarterlyDtx)
                                                                    console.log("  FRESH?: state.furthestQuarterlyStats is now: ", this.state.furthestQuarterlyStats)

                                                                    if ( jumpToNewPurchase ) {
                                                                        console.warn("final finalProcessing: userClaimedAsset(true, " + qs.furthestQuarterlyDtx.limbNam + ")")
                                                                        this.userClaimedAsset(true, qs.furthestQuarterlyDtx.limbName, qs.furthestQuarterlyDtx?.outputStates[0].ownerCount)
                                                                    }
                                                                }
                                                    )
                                    } else {
                                        if ( this.state.purchaseThisDomain ) {
                                            console.error("We ran into trouble in the final processing for your purchase of this domain")
                                            alert("We ran into trouble in the final processing for your purchase of this domain")
                                        } else {
                                            console.error("We ran into trouble in the final processing for building a new Quarteryly")
                                            alert("We ran into trouble in the final processing for building a new Quarteryly")
                                        }
                                    }

                                    console.log("qs: ", qs)
                                    console.warn("We're DONE updating latest site stats. Check qs above <-----")
                                }

        const dateTimeStampString = new Date().toString()
        // Here we use a 'magic' property: 'shzlProfile'. It currently has special meaning within the code:
        //   - buildShizzle looks for it (within content) when decoding a tx
        //     - adds a .shzlProfile field
        //     - ALSO calls saveTxsWithProfileInfo() to append that txId, and path to a growing list (IDB setting)
        //       (MAYBE THIS IS TOO MUCH? MAYBE NOT)
        //   - shizzleView builds a list of txs which contain a shzlProfile property
        const contentString = avatarProps === null ?
                "This is an automatically-generated 'Quarterly' transaction - to allow for the efficient and orderly production of transactions attributable to this domain.<br><br>Generated: " + dateTimeStampString
                        :
                JSON.stringify({shzlProfile: avatarProps, content:"This is some text content while setting some avatar props..."})

        if ( avatarProps !== null ) {
            console.warn("Surfer: here's the JSON we'll include as content for the next quarterly:" , {avatarProps: avatarProps, content:"This is some text content while setting some avatar props..."})
            console.warn("Surfer: here's the JSON **stringified** - which we'll include as content for the next quarterly:" , contentString)
            alert("ABOUT TO BUILD quarterly with avatar props!! Cancel if testing...")
            alert("last chance to cancel")
        }

        // call this tx-building (post-submitting) function (to build a Quarterly tx) - AFTER setting
        // state with NEW latest tx (the furthest/tip quarterly)
        // NOTE that we ARE defining a final-processing function - to re-evaluate the
        // 'furthestQuarterly' state variables AFTER broadcasting a new Quarterly
        this.setState({ latestTxId: newLatestTxId,
                        dtx: newTx,
                        content: contentString,
                      },
                                () => this.handleSubmitPost(  null,       //event
                                                              'forceQuarterly',  // force-build a quarterly. WARNING: Tricky short-circuit!!
                                                              false,    //guest post?
                                                              null,     // latest guest txId
                                                              null,     // guest dtx
                                                              null,     // guest best output num
                                                              null,     // guest output state
                                                              finalProcessing   //call checkIfDomainExpiredOrRenewable() so we can re-evaluate siteStats / furthestQ...
                                                           )
                    )
    }

    /**
     * NOTE: finalProcessing() defaults to blank function, but will be called after post succeeds, OR fails
     *
     * NOTE: two dummy params to allow direct calls, and .onClick() calls to co-exist
     */
    async handleSubmitPost( event,
                            blah = null,
                            isAGuestPost = false,
                            latestGuestTxIdOnWhichToBuild = null,
                            guestDtx = null,
                            guestBestOutputNum = null,
                            guestOutputStateOnWhichToBuild = null,
                            finalProcessing = function() { }) {

        this.setState({disablePubButton: true})

        console.log("handleSubmitPost(): isAGuestPost: ", isAGuestPost)
        console.warn("handleSubmitPost(): inviteToDialog: " + this.state.inviteToDialog)
        console.log("handleSubmitPost(): finalProcessing: ", finalProcessing)
        //event.preventDefault()

        console.log("handleSubmitPost(): We should build on txId: " + this.state.inviteToDialog ? this.state.dialogTxId : this.state.latestTxId)
//xxxxx maybe grab this txid, hold onto it
        const dtx = this.state.inviteToDialog ? this.state.dialogDtx : this.state.dtx
        console.log("handleSubmitPost(): Here's the latest dtx: ", dtx)

        const db = await openDB()

        // If we're FORCING the building of a Quarterly, only look at the first (zeroeth) output
        //TRICKY WARNING: if forced to build a quarterly, we only loop through output 0!
        const numOutputs = blah === null ? dtx.outputStates.length : 1
        console.log("surfer handleSubmitPost(): There are " + numOutputs + " outputs to choose from")

        if ( blah !== null && blah !== 'forceQuarterly' ) {
            console.error("Unexpected parameter value: ", blah)
            console.error("We have a CODE ERROR")
            throw new Error("Unexpected parameter when posting")
        }

        let bestOutput = -1
        let bestOutputType = null
        //FIXME: should rename and re-purpose as NEXTRabinPKH
        let prevRabinPKH
        let newRabinPKH
            let rabinPKHparamX  // an attempt to be more clear when calling buildOnClaimOrPublish()
            let rabinPKHparamY
        let transientMode = ''
        let buildOnMode
        let specialCase = false
        let swapParamsForDialog = false
        // work BACKWARDS through outputs
        for ( let i = numOutputs-1; i > -1; i-- ) {
            console.log("  output " + i + ": " + dtx.outputStates[i].contract)
            const buildable = dtx.buildable[i] === i
            console.log("    buildable? " + buildable)

            const type = dtx.outputStates[i].contract
            const mode = dtx.outputStates[i].mode

            //FIXME: avoid EBFRAs, payouts, change, final-transients, final updates, and too-early Update, and too-early Quarterlies
            if ( type === 'EBFRA Speaker' ) {
                console.warn("Ignoring base-0 output #" + i + ": EBFRA speaker")
            } else if ( !buildable ) {
                console.warn("Ignoring base-0 output #" + i + " - since it's NOT even BUILDABLE")
            } else if ( type === 'update' ) {
                console.warn("SUPER: we can build off of " + type + ". We should be ready to build!")
                bestOutput = i
                bestOutputType = type
                prevRabinPKH = dtx.outputStates[bestOutput].ownerRabinPKH

                break
            } else if ( type === 'transient' ) {
                const domainNameOfThisTransientOutput = hexStringToAscii( dtx.outputStates[i].namePath )
                if ( isAGuestPost ) {
                    console.warn("NOTE: we're going to build a GUEST POST, so, we need to find a transient that IS the requested domain: " + this.state.requestedDomain)
                    if ( domainNameOfThisTransientOutput === this.state.requestedDomain ) {
                        console.warn("building guest post on host output " + i + ", since it's the target domain: " + this.state.requestedDomain)
                        console.warn("SUPER: we can build off of " + type + ". We should be ready to build!")
                        bestOutput = i
                        bestOutputType = type
                        prevRabinPKH = dtx.outputStates[bestOutput].rabinPKH
                        //transientMode = hexByteToAscii(dtx.outputStates[bestOutput].mode)
                        break
                    } else {
                        console.warn("SKIPPING building GUEST post on host output " + i + ", since it's NOT the target domain (" + this.state.requestedDomain + "), it's domain " + domainNameOfThisTransientOutput)
                    }
                } else {
                    if ( this.state.inviteToDialog && guestDtx === null ) {
                        console.warn("SPECIAL case: we're about to RESPOND to a guest-post with a DIALOGUE invite")
                        if ( domainNameOfThisTransientOutput === this.state.requestedDomain ) {
                            console.warn("building guest post on host output " + i + ", since it's the target domain: " + this.state.requestedDomain)
                            console.warn("SUPER: we can build off of " + type + ". We should be ready to build!")
                            if ( i !== 0 ) {
                                alert(" TROUBLE. WRONG output num? " + i + " STOP NOW")
                            }
                            bestOutput = i
                            bestOutputType = type
                            prevRabinPKH = dtx.outputStates[bestOutput].rabinPKH

                            const outputNumToTargetForInvite = dtx.buildable[2] === 2 ? 2 : 1
                            if ( dtx.outputStates[ outputNumToTargetForInvite ].mode === '44' ) {
                                console.log("STOP. We ALMOST targeted output " + outputNumToTargetForInvite + " for dialogue invite, but realized it's ALREADY a dialogue")
                                continue
                            }
                            latestGuestTxIdOnWhichToBuild = this.state.dialogTxId
                            guestDtx = dtx
                            guestBestOutputNum = outputNumToTargetForInvite
                            guestOutputStateOnWhichToBuild = dtx.outputStates[ guestBestOutputNum ]

                            console.warn("Will target guestBestOutputNum " + guestBestOutputNum + " for a guest-post (invite to Dialog).. AND will set isAGuestPost to true. bestOutput: " + i)

                            isAGuestPost = true
                            specialCase = true

                            console.error("should the code BREAK from the for-loop here (for a transient)? Perhaps not. It may just be taking not of important params.")
                        } else {
                            console.error("domainNameOfThisTransientOutput: " + domainNameOfThisTransientOutput)
                            console.error(" this.state.requestedDomain: " + this.state.requestedDomain)
                            console.log("SKIPPING special case guest-posting from output " + i + ", since it's of domain " + domainNameOfThisTransientOutput + ". We're actually looking for " + this.state.requestedDomain)
                        }
                    } else {
                        console.warn("NOTE: we're going to build a REGULAR POST, so, we need to find a transient that IS the our identity: " + this.state.ourIdentity)
                        if ( domainNameOfThisTransientOutput === this.state.ourIdentity ) {
                            console.warn("SUPER: we can build off of " + type + ". We should be ready to build!")
                            bestOutput = i
                            bestOutputType = type
                            prevRabinPKH = dtx.outputStates[bestOutput].rabinPKH
                            transientMode = hexByteToAscii(dtx.outputStates[bestOutput].mode)
                            break
                        } else {
                            console.warn("SKIPPING building a REGULAR post on output " + i + ", since it's NOT our identity (" + this.state.ourIdentity + "), it's of domain " + domainNameOfThisTransientOutput)
                        }
                    }
                }
            } else if ( type === 'dialog' ) {
                console.warn("    hsp() 1: Ignoring base-0 output #" + i +": DIALOGUE")
                alert("NOTE: handleSubmitPost() 1:  dialogue on output " + i + " !!!")
            } else if ( type === 'expand' ) {
                if ( mode === '4b' ) {  // K
                    //alert("We can build off of base-0 output #" + i +": "  + type + " - mode " + mode)
                    bestOutput = i
                    bestOutputType = type
                    buildOnMode = 'K'

                    // HERE we'll always choose a brand-new rabin
                    // this will ensure a gifted domain transfers to new ownerCount

                    prevRabinPKH = dtx.outputStates[bestOutput].ownerRabinPKH
                    console.error("NOTE: the K-mode output upon which we're building has rabin PKH of " + dtx.outputStates[bestOutput].ownerRabinPKH)
                    const newRabinRes = await allocateAvailableRabin(db) // new rabin
                    console.warn("Allocated a NEW RabinRes: ", newRabinRes)
                    //alert("Read console to double check we're CHANGING the rabin")

                    if ( newRabinRes === null ) {
                        //this.setState({showBuildingToast: false})
                        alert("ERROR: Your wallet may have run-out of available (un-allocated) Publisher keys. Check your wallet. ABORTING")

                        finalProcessing()
                        return false
                    }

                    //FIXME: review the proper use of these three parameters
                    newRabinPKH = newRabinRes[0].pkh
                    rabinPKHparamX = newRabinPKH
                    rabinPKHparamY = newRabinPKH
                    //prevRabinPKH = newRabinPKH   //WARNING: confusing/bad variable names - PROBABLY the result of confusing logic in shizzleBuilder
                    break
                } else if ( mode === '58' ) {  // X
                    console.warn("Ignoring base-0 output #" + i + ": " + type + " - mode " + mode)
                    newRabinPKH = prevRabinPKH
                    rabinPKHparamX = newRabinPKH
                    rabinPKHparamY = newRabinPKH

                    // we don't break, but then why are we setting the rabin?
                    // Probably because this is the final iteration
                } else if ( mode === '44' ) {
                    console.warn("    hsp() 2: Ignoring base-0 output #" + i +": DIALOGUE")
                    alert("NOTE: handleSubmitPost(): pay attention to dialogue on output " + i + " !!! We'll ignore it, for now.")
                } else {
                    console.error("CODE ERROR: Make sure to prohibit building off of a contract " + type + ", mode: " + mode)
                    alert("Surfer post: read the console to learn what went wrong.")

                    finalProcessing()
                    return false
                }

            } else if ( type === 'continue' ) {
                if ( mode === '70' ) {  // p
                    bestOutput = i
                    bestOutputType = type
                    buildOnMode = 'p'

                    prevRabinPKH = dtx.outputStates[bestOutput].ownerRabinPKH
                    newRabinPKH = prevRabinPKH

                    if ( this.state.purchaseThisDomain ) {
                        console.warn("building on 'p', so, we'll allocate a new rabin")

                        // code below copied from handling of build-on-K', a few lines up

                        const newRabinRes = await allocateAvailableRabin(db) // new rabin
                        console.warn("Allocating a NEW RabinRes: ", newRabinRes)

                        if ( newRabinRes === null ) {
                            //this.setState({showBuildingToast: false})
                            alert("ERROR: Your wallet may have run-out of available (un-allocated) Publisher keys. Check your wallet. ABORTING purchase.")

                            finalProcessing()
                            return false
                        }

                        newRabinPKH = newRabinRes[0].pkh
                        console.warn("building on 'p' (through a PURCHASE), so, we've allocated a new rabin: " + newRabinPKH)
                    }

                    rabinPKHparamX = newRabinPKH   // silly build param
                    rabinPKHparamY = newRabinPKH   // silly build param

                    console.warn("should the code BREAK from the for-loop here (for a continue)? Well, it's probably the final iteration anyway.")
                } else {
                    console.error("INTERESTING: take note of base-0 output #" + i +": "  + type + " - mode " + mode)
                    alert("INTERESTING: LOGIC missing to handle this? (see console)")

                    finalProcessing()
                    return false
                }
            } else {
                console.error("ERROR: Make sure to prohibit building off of this contract type: " + type)
                alert("Surfer post: read the console to learn what went wrong")

                finalProcessing()
                return false
            }
        }  // each output

        console.warn("We're ready to BUILD!!!  on base-0 output " + bestOutput
                    + " of contract type " + bestOutputType + " on txid " + (this.state.inviteToDialog ? this.state.dialogTxId : this.state.latestTxId))

        if ( bestOutput === -1 ) {
            console.error("handleSubmitPost(): Couldn't EASILY find an appropriate output when trying to build on txId " + (this.state.inviteToDialog ? this.state.dialogTxId : this.state.latestTxId))
            const lookingForDomain = isAGuestPost ? this.state.requestedDomain : this.state.ourIdentity

            console.log("    On which asset are we trying to build ? " + lookingForDomain)

            let lookingForOwnerCount
            if ( hexStringToAscii( dtx.outputStates[0].namePath) === lookingForDomain ) {
                lookingForOwnerCount = dtx.outputStates[0].ownerCount
                console.log("    handleSubmitPost(): out0. looking for ownerCount: " + lookingForOwnerCount)
            } else if ( hexStringToAscii( dtx.outputStates[1]?.namePath) === lookingForDomain ) {
                lookingForOwnerCount = dtx.outputStates[1].ownerCount
                console.log("    handleSubmitPost(): out1. looking for ownerCount: " + lookingForOwnerCount)
            } else {
                alert("hmm. trouble finding appropriate owner count")
                throw new Error("Had trouble finding appropriate asset ownerCount")
            }
            console.log(" handleSubmitPost(): lookinging for domain " + lookingForDomain)
            const result = await findLatestUTXO(db, lookingForDomain, lookingForOwnerCount)

            console.error("result of findLatestUTXO() for domain " + lookingForDomain
                        + ", owner " + lookingForOwnerCount + ": ", result)

            if ( result !== null && !result.ownerChanged ) {
                // consult result.result
                //      .tx should have the txid
                const newLatestTxId = result.result.tx
                console.error("--------------------")
                console.error("handleSubmitPost(): EVASIVE MANEUVERS (because of finalPost): jumping to latestUTXO: " + newLatestTxId)

                //FIXME: show a swirly while we query + pause? meh

                const newTx = await queryFetchDecodeTx(newLatestTxId, db)
                if ( newTx === null || typeof newTx === 'number' ) {
                    alert("ERROR: Big problem. Please report failure to find txId " + newLatestTxId)
                    throw new Error("Code or DB Error - searching for tx " + newLatestTxId)
                }
                console.warn("got the NEW tx: ", newTx)

                console.warn("We're about to take evasive maneuvers from a new txid: " + newLatestTxId)

                console.log("about to setState().   finalProcessing: ", finalProcessing)

                // call this function again - AFTER setting state with NEW latest tx
                // NOTE that we pass-along any final-processing function
                this.setState({ latestTxId: newLatestTxId,
                                dtx: newTx,
                              },
                                        () => this.handleSubmitPost(  event,
                                            blah,
                                            isAGuestPost,
                                            latestGuestTxIdOnWhichToBuild,
                                            guestDtx,
                                            guestBestOutputNum,
                                            guestOutputStateOnWhichToBuild,

                                            finalProcessing
                                        )
                            )

                return true

            } else {
                alert("ERROR: Trouble submitting post - has this ownership expired?")
                this.setState({disablePubButton: false})

                finalProcessing()
                return false
            }
        }

        // {p: bsvFundingPrivKey,  address: fundingAsAddress,  pwd: ...}
//        console.log("here's the bundle: ", this.props.bundle)

        if ( !specialCase && isAGuestPost && this.state.inviteToDialog ) {
            console.log("NOT special case, but IS a guestPost, AND inviting to Dialog, SO, maybe will swap some params soon...")
        } else if ( specialCase && isAGuestPost && this.state.inviteToDialog ) {
            console.warn("special case, guestPost. So, WILL swap")
            swapParamsForDialog = true
        }

        if ( isAGuestPost ) {
            console.log("handleSubmitPost(): recall guest param: latestGuestTxId...: " + latestGuestTxIdOnWhichToBuild)
            console.log("handleSubmitPost(): recall guest param: guestBestOutputNum: " + guestBestOutputNum)
            console.log("handleSubmitPost(): recall guest param: guestOutputStateOnWhichToBuild: ", guestOutputStateOnWhichToBuild)

            if ( !swapParamsForDialog ) {
                console.log("!swap")

                //TypeError: Cannot read property '0' of undefined
                prevRabinPKH = guestDtx.outputStates[guestBestOutputNum].rabinPKH
            } else {
                console.log("swap")
                prevRabinPKH = dtx.outputStates[bestOutput].rabinPKH
            }
            console.warn("Let's be careful to use the GUEST prevRabinPKH to authorize this post: " + prevRabinPKH)
        }

        // THIS give us the authorization to unlock/spend the previous tx/output
        const obj = await getEncryptedKeysFromPKH(db, prevRabinPKH);
        console.log("handleSubmitPost(): got encrypted json object: ", obj);

        // These MUST be derived from the PREVIOUS rabin
        let rabinPrivKeyP
        let rabinPrivKeyQ
        try {
            const plainBundle = await decryptData(obj.blob, this.props.bundle.pwd);

            //console.log("handleSubmitPost(): decrypted json string:", plainBundle);
            //console.log("handleSubmitPost(): DECRYPTED bundle for rabin PKH " , prevRabinPKH)

            const bundledObject = JSON.parse(plainBundle);

            rabinPrivKeyP = bundledObject.p
            rabinPrivKeyQ = bundledObject.q
            console.log("handleSubmitPost(): retrieved keys...")
        } catch ( error ) {
            console.error("handleSubmitPost(): Bad decrypt for rabin PKH " + prevRabinPKH + ". Wrong password? Failed to backup wallet when necessary?");

            alert("Something went VERY wrong. We failed to retrieve the necessary Rabin keys. We can't sign/authorize this transaction. Is it possible you failed to backup your wallet when necessary (after generating new keys)?")

            finalProcessing()
            return false
        }

        if ( isAGuestPost && bestOutputType !== 'transient' ) {
            //console.error("latestTxIdOnWhichToBuild: ", latestTxIdOnWhichToBuild)
            console.error("PROBLEM: bestOutputType: ", bestOutputType)
            console.error("dtx: ", dtx)
            console.error("guestDtx: ", guestDtx)
            console.error("latestGuestTxIdOnWhichToBuild: ", latestGuestTxIdOnWhichToBuild)
            console.error("guestOutputStateOnWhichToBuild: ", guestOutputStateOnWhichToBuild)
            //guestOutputStateOnWhichToBuild
            alert("ERROR: We're sorry. We don't (right now) see a transient output on which you could guest-post. Please try again later.")

            finalProcessing()
            return false
        }


        this.setState({ showSwirly: true,
                        swirlyText: this.state.buildAnnouncement ?
                                    'Building, and broadcasting your Announcement...'
                                :
                                    'Building, and broadcasting your post...'
                      })


        let hex1_flag = ''
        let hex2_len = ''
        let hex3_attachment = ''
        let hex4_ref = ''
        if ( this.state.addPhotoToPost ) {
            console.warn("handleSubmitPost(): looking to prepend image/attachment of size ", this.state.photoBlob.length , " bytes.")
            hex1_flag = Buffer.from( PAYLOAD_ESCAPE_FLAG +'IA' ).toString('hex')
            console.warn("hex1: ", hex1_flag)
            hex2_len      = this.state.photoBlob.length.toString(16)
            console.warn("handleSubmitPost(): INITIAL attachmentLenHex: ", hex2_len)

            // use exactly 4 bytes (8 hex chars) for length
            while (hex2_len.length < 8) {
                hex2_len = '0' + hex2_len;
                //console.warn("handleSubmitPost():  attachmentLenHex: ", hex2_len)
            }
            console.warn("handleSubmitPost(): FINAL attachmentLenHex: 0x" + hex2_len)

            console.warn("here's the photoBlob (actually passed as an arrayBuffer from loaders): ", this.state.photoBlob)
            hex3_attachment = buf2hex(this.state.photoBlob)
            console.log("photoHexString: " + hex3_attachment)
            console.warn("BYTE length of: " + hex3_attachment.length / 2)
        } else if ( this.state.addVideoToPost ) {
            console.warn("handleSubmitPost(): looking to prepend reference of media txId ", this.state.videoToAdd + ".")
            hex1_flag = Buffer.from( PAYLOAD_ESCAPE_FLAG +'MR' ).toString('hex')
            console.warn("hex1: ", hex1_flag)

            hex4_ref      = this.state.videoToAdd
            if ( this.state.videoToAdd.length !== 64 ) {
                alert("Unexpected video TxId length (should be 64 chars): " + this.state.videoToAdd.length + " for txId " + this.state.videoToAdd + ". ABORTING post.")
                return
            }
            console.warn("handleSubmitPost(): hex4_ref: ", hex4_ref)
        }

        const contentAsHexString =  hex1_flag +                     // either blank, or addPhoto, or addVideo
                                    hex2_len + hex3_attachment +    // if photo attachment: len, and raw data
                                    hex4_ref +                      // if a/v recording reference: txId (64 chars for 32 bytes)
                                    Buffer.from(this.state.content, 'utf8').toString('hex')

        console.log("handleSubmitPost(): about to build contract of type " + bestOutputType + "...")
        let newTxId
        let weSpawnedATransient = false
        let theTransientResults = null
        try {
            if ( bestOutputType === 'update' ) {
                console.warn("Building on an update")

                const allowGuestPosts = this.state.guestPostPriceForThisIdentity !== this.GUEST_POSTS_PROHIBITED

                // see contractBuilder for an other example of this call
                newTxId = await buildOnAnUpdate(dtx.outputStates[bestOutput],
                                        this.state.latestTxId,  //tx on which to build
                                        bestOutput,    // output on which we're building
                                        false,   // p1 claimBounty
                                        true,   // p2 publish content
                                        contentAsHexString,  // p3
                                        '',         //p4 contentName
                                        '',    //p5 refTxId
                                        '',     //p6 refTxLabel
                                        true,   // re-use the rabin
                                        '',     // actual PKH to use (if not re-using?)
                                        false,   //finalPost   FIXME: what if it IS?
                                        true,   // spawn something
                                        true,   // spawnTransient? FIXME: what's the diff?
                                        '4',    // tval.   FIXME: or number 4?
                                        allowGuestPosts,   // allowGuestPosts
                                        this.state.guestPostPriceForThisIdentity,     // set price for OTHERS to guest-post
                                        '',     // Group: group name
                                        0,      // Group: user bounty
                                        0,      // Group: maxPostLen
                                        0,      // Group: sats per post
                                        rabinPrivKeyP,
                                        rabinPrivKeyQ,
                                        this.props.bundle.p  // bsv funding priv key
                                )
                console.warn("SUCCESS building new UPDATE? new txid: " + newTxId + "  Don't forget to PAUSE to allow PROPAGATION")

                weSpawnedATransient = true  // will cause us to update state.weHaveATransient
            } else if ( bestOutputType === 'transient' ) {
                console.warn("Building on a transient")

//FIXME: we may need to look at the GUEST downCount - if its a guestPost  <------------------

//FIXME: test with /look at   guestDtx

                const downCount = dtx.outputStates[bestOutput].downCounterInt
                let finalPost = false
                if ( downCount === 1 && !isAGuestPost) {
                    // This is only a finalPost if NOT a guest-post
                    // (guest-posts don't advance the count)
                    finalPost = true
                    console.log("NOTE: This will be the finalPost")
                } else if ( downCount === 0 ) {
                    alert("It looks like we're about to build on a finalPost. Probably not fine. Pay attention.")
                    //FIXME: this shouldn't happen. Should have already found something more suitable. THROW
                }

                let latestTxIdOnWhichToBuild = this.state.inviteToDialog ? this.state.dialogTxId : this.state.latestTxId
                let hostTxId = ''
                let hostTxState = ''
                let hostTxOutput = ''
                let stateOnWhichToBuild = dtx.outputStates[bestOutput]
                let outputOnWhichToBuild = bestOutput
                if ( isAGuestPost ) {
                    // LHS is the guest-poster (other)
                    // set transient mode to determine if we spawn
                    latestTxIdOnWhichToBuild =      latestGuestTxIdOnWhichToBuild
                    stateOnWhichToBuild  =          guestOutputStateOnWhichToBuild
                    outputOnWhichToBuild =          guestBestOutputNum

                    transientMode = hexByteToAscii(guestDtx.outputStates[ guestBestOutputNum ].mode)

                    if ( this.state.inviteToDialog ) {
                        console.error("NOTE: since inviting to dialog, we MUST build HOST and GUEST from the SAME tx <----- NEW double-checking logic")
                        // Since we're ALSO spawning a Dialog, we must make sure that the input txids of both domains are identical
                        // DON'T set hostTxId to this.state.latestTxid
                        hostTxId =                      latestTxIdOnWhichToBuild
                    } else {
                        // LHS is the host (owner)
                        hostTxId =                      this.state.latestTxId
                    }
                    hostTxState =                   dtx.outputStates[bestOutput]
                    hostTxOutput =                  bestOutput

                    if ( swapParamsForDialog ) {
                        const hTxId          = latestTxIdOnWhichToBuild
                        const hTxState       = stateOnWhichToBuild
                        const hTxOutput      = outputOnWhichToBuild

                        latestTxIdOnWhichToBuild = hostTxId
                        stateOnWhichToBuild      = hostTxState
                        outputOnWhichToBuild     = hostTxOutput

                        hostTxId     = hTxId
                        hostTxState  = hTxState
                        hostTxOutput = hTxOutput
                        console.log("swapped-out params for building a dialog-spawning guest-post")
                        console.warn("AFTER any swap, transient mode: " +  hexByteToAscii( stateOnWhichToBuild.mode ) )
                    }

                    console.warn("surfer. latestTxIdOnWhichToBuild: " + latestTxIdOnWhichToBuild)

                    transientMode = hexByteToAscii( stateOnWhichToBuild.mode )

                }

                let maybeEncryptedContent
                try {
                    maybeEncryptedContent = await this.maybeEncryptMsg( this.state.encryptSelfPost, dtx, contentAsHexString, true )
                } catch (error) {
                    console.error("Error while encrypting: " + error)
                    alert("We couldn't encrypt your content, so, we won't send it.")
                    return
                }

                theTransientResults = await buildOnATransient(stateOnWhichToBuild,
                                        latestTxIdOnWhichToBuild,  //tx on which to build
                                        outputOnWhichToBuild,    // output on which we're building
                                        false,   // p1 claimBounty
                                        false,   // p2 tip owner?
                                        0,          // p3 tip amount
                                        true,   // p4 - publish T/F
                                        maybeEncryptedContent,  // p3
                                        '',         //p4 contentName label
                                        false,      // refTxId?
                                        '',    //p5 refTxId
                                        '',     //p6 refTxLabel
                                        true,   // re-use the rabin
                                        '',     // actual PKH to use (if not re-using?)
                                        finalPost,   //finalPost?   FIXME: what if it IS?
                                        transientMode !== '0',   // spawn something?  <----------- <-----

                                        isAGuestPost,   // guest post?
                                        this.state.inviteToDialog,    // launch Dialog?
                                        hostTxId,   // hostTxId (if it's a GP)
                                        hostTxState,     //host txState
                                        hostTxOutput,     // host TxOutput

                                        rabinPrivKeyP,
                                        rabinPrivKeyQ,
                                        this.props.bundle.p  // bsv funding priv key
                                )
                newTxId = theTransientResults.txid
                                //txid,
                                //rawTx,
                                //change

            } else if ( bestOutputType === 'continue' && buildOnMode === 'p' ) {
                // note, it might not be necessary to guarantee 'p', but, would it would be good to
                // check for (catch) other (forgotten) cases - like auction-related, or VOTING-related,
                // further below, so that we can provide tailored handling later
                console.warn("Building on a full-on quarterly")

                const optionalAdditionalBalance = 0 // we're not re-claiming. That's a job for Claimer, not Surfer

                console.warn("BTW: bestOutput: ", bestOutput)
                console.warn("BTW: latestTxId: ", this.state.latestTxId)
                console.warn("BTW: blockHeight: ", this.props.blockHeight)
                console.warn("BTW: contentAsHexString: ", contentAsHexString)
                console.warn("BTW: previous tx output blockNumInt: ", dtx.outputStates[bestOutput].blockNumInt)


                if ( this.state.purchaseThisDomain ) {
                    console.warn("BTW: this is this.state: ",)
                    if ( !confirm("You're about to PURCHASE this domain - for " + this.state?.furthestQuarterlyStats.priceInSats + " satoshis.\n\n" +
                          "We'll send the funds to the current/old owner's p2pkh: " + this.state?.furthestQuarterlyStats.ownerP2PKH) ) {

                        // changed mind about purchase
                        return
                    }

                    // Soon, we also add the domain, newOwnerCount to some table(s)

                    // if insta-buy, rabin has alredy been changed to buyer's newly-allocated rabinPKH, below
                    console.warn("Since we're BUYING this domain, we've ALREADY allocated our own (new) Rabin to control/authorize it")
                }

                console.warn("BTW: newRabinPKH: ", newRabinPKH)

                // if this quarterly is renewable, mention it
                const renew = this.state.furthestQuarterlyStats?.renewable && this.state.onOurCurrentIdentity
                console.warn("Building Quarterly with renew: " + renew)
                // yes, this seems redundant
                const normalZone = !renew

                const domainPriceInSats = renew ?                           // this is SETTING a new price (when placing domain up for sale)
                            this.state.domainPriceInSats
                        :
                            0

                console.warn("building new quarterly, and SETTING sale price:  domainPriceInSats: ", domainPriceInSats)

                let derivedSellerP2PKH
                if ( renew && domainPriceInSats > 0 ) {
                    console.warn("we'll set the p2pkh based on the address for the proceeds of any future sale: " + this.props.bundle.address)

                    // generate the seller p2pkh - so we can publish it for a future instaBuy-er

                    const privKeyX = new bsv.PrivateKey.fromWIF( this.props.bundle.p )
                    const pubKeyX = bsv.PublicKey.fromPrivateKey( privKeyX )
                    console.log("derived seller pubKey: ", pubKeyX.toString())
                    derivedSellerP2PKH = bsv.crypto.Hash.sha256ripemd160(pubKeyX.toBuffer('hex')).toString('hex')
                    console.log("Derived seller p2pkh: ", derivedSellerP2PKH, '\n');
                    if ( derivedSellerP2PKH.length !== 40 ) {
                        throw new Error("58554: Length of derived P2PKH is " + derivedSellerP2PKH.length +
                                    " But expected 40!")
                    }

                    const publicAddress = pubKeyX.toAddress().toString()
                    console.log("Seller's public address: " + publicAddress)

                    if ( publicAddress !== this.props.bundle.address ) {
                        throw new Error("ERROR: the derived seller address (" + publicAddress + ") doesn't match that of your bundle (" + this.props.bundle.address + ")")
                    }

                    console.warn("We're setting the p2pkh for the proceeds of any future sale: " + derivedSellerP2PKH)
                }
                const sellerP2PKH = renew && domainPriceInSats > 0 ?
                            derivedSellerP2PKH
                        :
                            '0000000000000000000000000000000000000000'

                console.warn("building new quarterly, with sellerP2PKH: ", sellerP2PKH)
                const instaBuy = this.state.purchaseThisDomain
                console.warn("building new quarterly, with instaBuy: ", instaBuy)

                if ( renew ) {
                    console.warn("While renewing, SETTING domainPriceInSats: " + domainPriceInSats + ", and sellerP2PKH: " + sellerP2PKH)
                }

                //NOTE: if instaBuy:
                //          - NO content
                //          - NO spawn of update
                const spawnUpdate = !instaBuy
                const contentHexIfNotInstaBuying = instaBuy ? '' : contentAsHexString
                const publishContentIfNotInstaBuying = !instaBuy

                newTxId = await buildOnClaimOrPublish(dtx.outputStates[bestOutput],
                                        this.state.latestTxId,  //tx on which to build
                                        bestOutput,    // output on which we're building
                                        this.props.blockHeight, // old hard-coded for testing: dtx.outputStates[bestOutput].blockNumInt,
                                        '',                     // p0   paycode
                                        newRabinPKH,               // p1
                                        normalZone,             // p2  post within the 'normal' zone (before zone of renewal),
                                                                //     Should be based on block, and dev setting(s)
                                        '0000000000000000000000000000000000000000', //  ''?  rabinPkhOfPotentialBuyer,
                                        '',                     // authcode
                                        '',                     // authcode padding
                                        '',                     // this.state.sellerRabinPubKey, // LE Hex

                                        domainPriceInSats,                  //newInstaPriceParam, //this.state.newInstaPrice,// p3: price in sats
                                        sellerP2PKH,                 //newOwnerP2PkhParam, //newOwnerP2Pkh, //this.state.newOwnerP2Pkh,//     instaBuy receiving P2PKH. If buying, set NEW value to zeroes
                                        instaBuy,              //instaBuy,

                                        renew,              //renew,                  // p4: if p2 was false, this is if we'd like to renew(T/F). sure.
                                        rabinPKHparamX,     // p5: if 'p', and p2 was false, and we're re-claiming, this is the Rabin PKH to use
                                                            //     if 'p', and not renewing (p4 was false),
                                        rabinPKHparamY,     // p6: keep the same PKH - but an opportunity to change it <---
                                        publishContentIfNotInstaBuying,               //publishContent,         // p7: publish content?
                                        contentHexIfNotInstaBuying,     // p8: content
                                        '',                     // p9: content label
                                        '',                 //publishTxid,            // p10: publish a txid?
                                        '',                 //this.state.refTxId,     // p11: txId
                                        '',                 //this.state.refTxLabel,  // p12: label for tx
                                        spawnUpdate,               //this.state.spawnUpdate, // p13: spawn update
                                        false,              //this.state.spawnSale,   // p14: spawn sale/negotiations

                                        optionalAdditionalBalance,

                                        rabinPrivKeyP,
                                        rabinPrivKeyQ,
                                        this.props.bundle.p  // bsv funding priv key
                                )
            } else if ( bestOutputType === 'expand' && buildOnMode === 'K' ) {
                console.warn("Building on a claimed quarterly")

                const optionalAdditionalBalance = 0 // we're not re-claiming. That's a job for Claimer, not Surfer

                newTxId = await buildOnClaimOrPublish(dtx.outputStates[bestOutput],
                                        this.state.latestTxId,  //tx on which to build
                                        bestOutput,    // output on which we're building
                                        dtx.outputStates[bestOutput].blockNumInt,
                                        '',                     // p0   paycode
                                        newRabinPKH,               // p1
                                        true,                   // p2  post within the 'normal' zone (before zone of renewal),
                                                                //     Should be based on block, and dev setting(s)
                                        '0000000000000000000000000000000000000000', //  ''?  rabinPkhOfPotentialBuyer,
                                        '',                     // authcode
                                        '',                     // authcode padding
                                        '',                     // this.state.sellerRabinPubKey, // LE Hex

                                        0,                  //newInstaPriceParam, //this.state.newInstaPrice,// p3: price in sats
                                        '0000000000000000000000000000000000000000',                 //newOwnerP2PkhParam, //newOwnerP2Pkh, //this.state.newOwnerP2Pkh,//     instaBuy receiving P2PKH. If buying, set NEW value to zeroes
                                        false,              //instaBuy,

                                        false,              //renew,                  // p4: if p2 was false, this is if we'd like to renew(T/F). sure.
                                        rabinPKHparamX,     // p5: if 'p', and p2 was false, and we're re-claiming, this is the Rabin PKH to use
                                                            //     if 'p', and not renewing (p4 was false),
                                        rabinPKHparamY,     // p6: keep the same PKH - but an opportunity to change it <---
                                        true,               //publishContent,         // p7: publish content?
                                        contentAsHexString,     // p8: content
                                        '',                     // p9: content label
                                        '',                 //publishTxid,            // p10: publish a txid?
                                        '',                 //this.state.refTxId,     // p11: txId
                                        '',                 //this.state.refTxLabel,  // p12: label for tx
                                        true,               //this.state.spawnUpdate, // p13: spawn update
                                        false,              //this.state.spawnSale,   // p14: spawn sale/negotiations

                                        optionalAdditionalBalance,

                                        rabinPrivKeyP,
                                        rabinPrivKeyQ,
                                        this.props.bundle.p  // bsv funding priv key
                                )

            } else {

                console.error("What? build on " + bestOutputType + " ??")
                console.error("What? buildOnMode: ", buildOnMode)
                this.setState({showSwirly: false})

                alert("hmm. CODING ERROR: please report - attempted to build on " + bestOutputType + ", mode " + buildOnMode)

                finalProcessing()
                return false
            }
        } catch (error) {
            console.error(" error while building a post. stack: ", error)

            this.setState({showSwirly: false})

            if ( error.toString().includes("Network Error") ) {
                alert("We've experienced a Network Error while building a transaction. Please check your connection.")
            } else {
                alert("Error when building. Most likely we tried to build on a STALE output.")
                alert("As a workaround, please REFRESH your copy of the current domain you're surfing (by surfing elsewhere, and then returning), before re-trying. Thank you.")
            }

            this.setState({disablePubButton: false})

            if (this.state.buildAnnouncement ) {
                alert("handleSubmitPost(); We're not sure what went wrong while attempting to announce your site, "
                      + this.state.ourIdentity + ". It probably collided with some other announcement. ")
            }

            finalProcessing()
            return false
        }

        console.warn("results of build - newTxId: ", newTxId)
        let success = false
        let weHaveATransient = this.state.weHaveATransient || weSpawnedATransient
        if ( newTxId ) {

            // clear any photo-attachment state
            this.setState({ addPhotoToPost: false,
                            showPhotoModal: false,
                            photoToAdd: null,
                            photoName:  null,
                            photoBlob:  null,

                            addVideoToPost: false,
                            videoToAdd: null,
                          })

            let newTx

            //FIXME: at some point take a hint from claimer's expressClaim(), by
            //       broadcasting, and decoding tx WITHOUT querying the network
            // 10/31/22: increased from 3100, then from 3400  - to 3800
            // 11/4/22:  increased from 3800 to 4000
            // 2/5/24: increased from 4000 to 4100
            await mySleep(4100, "pausing to allow new tx to propagate...")

            //FIXME: add a modal/toast for pause?
            //       OR, just pretend it came in, and do it in the background - with a timer?

            do {
                // NOTE: we don't define a guestPostCallback, since we KNOW
                //       if we need to add a reference, and we do it just below
                newTx = await queryFetchDecodeTx(newTxId, db)
                if ( newTx === null || typeof newTx === 'number' ) {
                    console.error("I think we didn't pause long enough for propagation.")
                    await mySleep(2000, "Pausing again for propagation...")
                }
                console.warn("result of qFDTx(): ", newTx)
            } while ( newTx === null || typeof newTx === 'number' );



            if ( isAGuestPost ) {
                console.error("handleSubmitPost(): we've just broadcast, then decoded a guest-post OF OURS. We should register this in guestPostTab")

                const hostDomain = hexStringToAscii( newTx.outputStates[0].namePath )
                const hostOwnerCount = newTx.outputStates[0].ownerCount

                console.warn("handleSubmitPost(): here's newTx: ", newTx)
                const ourDomainName = hexStringToAscii( newTx.outputStates[1].namePath )
                const weBuiltIt = true
                const isValid = true
                const couldBuildDialog = true
                console.log("Surfer handleSubmitPost(): about to add a guest post ref for txId " + newTxId)
                await addGuestPostRef(  db, newTxId,
                                        ourDomainName,
                                        hostDomain,       //otherDomain
                                        weBuiltIt,
                                        false,            //ranValidation,
                                        isValid,
                                        couldBuildDialog)

                // ALSO, if we own the other party/domain, add a ref for it too
                const allMyDomains = await findAllDomainsOfMine(db)
                for ( let i = 0; i < allMyDomains.length; i++ ) {
                    const thisDomain = allMyDomains[i]
                    const thisMyDomain = thisDomain.name
                    const thisMyOwnerCount = thisDomain.ownerCount
                    if ( thisMyDomain === hostDomain && thisMyOwnerCount === hostOwnerCount ) {
                        console.log("We own the host")

                        await addGuestPostRef(  db, newTxId,
                                                hostDomain,     // host - which we ALSO own
                                                ourDomainName,    //otherDomain -  guest
                                                weBuiltIt,
                                                false,            //ranValidation,
                                                isValid,
                                                couldBuildDialog)
                        // we've found-out all we need to know
                        break
                    }
                }

                //alert("handleSubmitPost(): check the guestPostTab - for txId " + newTxId)
            }

            // use this.viewerButtonCallback() (like viewerDomainCallback())
            // to instruct viewer to advance one tx
            await this.advanceTheViewer()

            if ( bestOutputType === 'transient' && this.state.buildAnnouncement ) {
                // take note of success
                this.handleAnnounceSuccess()
            }

            // FIXED: we need to advance the state of the GUEST regardless of
            //        whether or not this was a guest post

            // If we just built a guest-post, advance the state of the GUEST
            //let ourDtxLatestId = isAGuestPost ? newTxId : this.state.ourDtxLatestId
            //let ourDtx         = isAGuestPost ? JSON.stringify(newTx) : this.state.ourDtx

            // No. Advance the GUEST regardless. WE posted SOMETHING,
            // so, our next guest-post would be from HERE regardless
            let ourDtxLatestId = newTxId
            let ourDtx         = newTx


            // HERE is where we could recognize if we JUST NOW made our first post if:
            //     - has no transient (wasn't a guest-post)
            //     - address parts has length 5
            //     - addressParts[4] is 00001
            const hasATransient = newTx.outputStates[0].contract === 'transient'
                                || (newTx.outputStates[0].contract === 'update'
                                 && newTx.outputStates[1].contract === 'transient')
            let showFeeThresholdModal = false
            if ( !hasATransient ) {
                const addressParts = newTx.address.split('/')
                if ( addressParts.length === 5 && addressParts[4] === '00001' ) {
                    console.warn("We think we just-now created our 1st post. NOW is when we should show the FeeSettingsModal")
                    showFeeThresholdModal = true
                }
            }

console.warn("handleSubmitPost(): setting state - near end. finalProcessing: ", finalProcessing)

            // If we build again, it should be from this tx
            // OR if this is end-of-the-line, we should build from a Continue
            //NOTE: this won't be sufficient if this is an end-of-the-line Update
            this.setState({ latestTxId: newTxId,
                            dtx: newTx,

                            ourDtxLatestId: ourDtxLatestId,
                            ourDtx: ourDtx,

                            content: '',
                            weHaveATransient: weHaveATransient,

                            displayNotMuchToSee: false, // since we've built a post, there's certainly something to see

                            showFeeThresholdModal: showFeeThresholdModal,
                            feeSettingsFirstTime: showFeeThresholdModal,  // This is right. We're showing the modal BECAUSE it's the first time

                            disablePubButton: false,
                            showSwirly: false,

                            //encryptSelfPost: false,  //preserve value chosen in postPopup - DON'T update here
                          }, finalProcessing)

            success = true
        } else {
            console.error("We had trouble building a new post/tweet/transaction")

            this.setState({disablePubButton: false, showSwirly: false})

            alert("We're sorry. We had trouble broadcasting your post or transaction.\n"
                + "One of your Transaction Providers may just be a little slow, OR, something was wrong about what we tried to do.\n"
                + "Please try again.")

            finalProcessing()
        }

        return success
    } // handleSubmitPost()

    /**
     * Content passed-in as utf8, BUT returned as hex string
     *
     * @param {*} yesEncrypt
     * @param {*} dtx
     * @param {*} content
     * @param {*} isActuallyHex OPTIONAL
     * @returns
     */
    async maybeEncryptMsg(yesEncrypt, dtx, content, isActuallyHex = false) {
        let contentToSend

        if ( yesEncrypt ) {

            console.error("maybeEncryptMsg(): we should ENCRYPT")
            //console.warn("OUR priv key: ", this.state.bsvPrivKey)
            console.warn(" dtx: ", dtx)

            const sharedKey = this.deriveSharedKey()

            console.warn("surfer: Will encrypt this message: " + content)
            console.warn("surfer: content is hex: " + isActuallyHex)

            const result = await encryptMessage( sharedKey, content, isActuallyHex )
            if ( !result.success ) {
                throw new Error("Failed to encrypt")
            }
            contentToSend = result.ciphertext
        } else {
            console.log("Will use the CLEAR text (in hex)")
            if ( isActuallyHex ) {
                contentToSend = content
            } else {
                // convert content to a HEX string
                contentToSend = Buffer.from(content, 'utf8').toString('hex')
            }
        }

        return contentToSend
    }

    // We use this, but ALSO, shizzleView will callback to here - to get the sharedKey
    deriveSharedKey() {
        console.log("surfer: deriveSharedKey(): HERE's where surfer will provide a key for decryption")

console.error("SURFER: deriveSharedKey(): this.props.bundle: ", this.props.bundle)
if ( typeof this.props.bundle === 'undefined' ) {
    alert("SURFER: deriveSharedKey(): why don't we have this.props.bundle yet? Review console. This is a problem. About to throw UNHANDLED PROMISE REJECTION because of this.")
}

        const privKeyHex = new bsv.PrivateKey.fromWIF( this.props.bundle.p ) // bsv funding priv key
        const specifiedPubKey = bsv.PublicKey.fromPrivateKey( privKeyHex )   // bsv funding pub key
        console.log("typeof specifiedPubKey: " + typeof specifiedPubKey)

        const pubToUseBuffer = Buffer.from( specifiedPubKey.toString(), 'hex')
        const privToUseBuffer = Buffer.from( privKeyHex.toHex(), 'hex')

        const sharedKey = eccryptoJS.derive( privToUseBuffer, pubToUseBuffer );


        console.warn("surfer deriveSharedKey: DYNAMICALLY-calculated shared key: ", sharedKey)

        return sharedKey
    }

    async handleAnnounceSuccess() {

            console.log("Anouncement was a success. set the setting.")

            // save the setting: ANNOUNCED for this identity
            // When we jump back, and arrive at viewResponseCallback(),
            // this will be re-read, noticed, then cause us to set
            // state.hasAnnounced (for the 'current' identity)
            await saveSetting(await openDB(), "announcedFor_" + this.state.ourIdentity, true)
            // this updates state.hasAnnounced when we arrive in our own asset in viewResponseCallback()

            this.setState({buildAnnouncement: false})

            const me = this
            execAsync2( async function() {
                // show an EXTRA modal - that says we've announced
                // we delay it, for 1 second, so that the progressListModal
                // gets rendered FIRST, then gets overwritten by THIS modal
                me.setState({showYouWereAnnounced: true})
            }, 500)
            //NOTE: we wait so long since after building a tx, we pause 4 seconds for propagation

    }

    // FORMERLY: would calculate and display fresh stats for the site (IOW: using latest blockHeight)
    // CURRENTLY: calculate stats, then activate the display of optional <SiteStatusModal> to display them
    async showSiteStatus() {
        const furthestQDtx = null

        const qs = await checkIfDomainExpiredOrRenewable(await openDB() , this.state.furthestQuarterlyTxId, this.props.blockHeight, furthestQDtx)

        this.setState({ furthestQuarterlyStats: qs, showSiteStatusModal: (qs !== null) })

        if ( qs === null ) {
            alert("There's not much to show about a site/domain/identity that doesn't really exist.")
        }
    }
    closeSiteStatusModal() {
        this.setState({ showSiteStatusModal: false})
    }

    // NOTE: state may ALSO be set when user sets his identity,
    //       if we see there's no setting for maxMilliPenniesToComment
    handleFeeSettingsClick() {
        this.setState({showFeeThresholdModal: true})
    }

    async closeFeeSettingsModal() {

        // re-read the settings. They may have been tweaked
        await this.maybeUpdateFeeSettings()

        this.setState({showFeeThresholdModal: false, feeSettingsFirstTime: false})
    }

    handleTxProviderClick() {
        alert("The current *main* Transaction Provider is WhatsOnChain.com. We make limited use of Bitails.io.\n\nPlease check back later for statistics, and limited control options.")
    }

    scanForDomains() {
        console.log("scanForDomains(): We need to visit BANNO, but NOT announce")

        // this is near-identical to handleAnnounceClick(), but we *don't*
        // include a 5th param when calling pokeTheViewer(), so we *won't* guest-post (announce)

        this.setOfNewestDomains.clear()

        if ( this.setViewerReportOnGuestPosts ) {
            console.warn("To 'scan for domains', we're about to tell viewer that we're interested in guest posts")
            this.setViewerReportOnGuestPosts(this.viewerRef, true)
        }

        // keep track of where to return when we're done
        this.setState({jumpBackToThis: this.state.requestedDomain})

        this.pokeTheViewer(null, null, "banno", false, true) // final param ('scanDomains') is true
    }

    // Let the ShizzleVerse know this identity/site exists
    handleAnnounceClick() {

        // build a guest-post on the 'announce' domain
        //  - get to the tip of announce (for now, 'banno')
        //  - build a guest post from our identity, onto host domain
        //  - save setting
        //  - return back to our identity

        // Kicks off a chain of events, because 'buildAnnouncement' will soon be set
        //   - set state.buildAnnouncement within pokeTheViewer( , , , "announcing...")
        //     BECAUSE of that optional 4th param
        //   - kicks-off viewer (via pokeTheViewer), heading for 'banno'
        //   - when done arriving at banno, viewer calls back to viewResponseCallback()
        //   - at the end of vRC(), after setting state, it notices state.buildAnnouncement
        //   - if state.buildAnnouncement, clear it (via jump...()), then execAsync() call to handleSubmitGuestPost()
        //   - if SUCCESSFUL, we save the setting: "announcedFor_"+me.state.ourIdentity
        //   - REGARDLESS: we JUMP back to our IDENTITY
        if ( this.setViewerReportOnGuestPosts ) {
            console.warn("About to tell viewer that we're interested in guest posts")
            this.setViewerReportOnGuestPosts(this.viewerRef, true)
        }

        // we use the 4th param (in relevant calls, not this one) to be more explicit that it's OURS
        this.pokeTheViewer(null, null, "banno", false, true, "announcing: MY SITE!")
    }

    goToCoolSite(site) {
        console.warn("Surfer heading to site: " + site.toLowerCase())

        let thisIsOurs = site === this.state.ourIdentity

        this.pokeTheViewer(null, null, site.toLowerCase(), thisIsOurs)
    }

    addToRecents(site) {
        console.warn("addToRecents: prev list: ", this.recentSites)

        for ( let i = 0; i < this.recentSites.length; i++ ) {
            if ( this.recentSites[i] === site ) {
                console.warn(" RECENT SITES [" + i + "] already has " + site)
                this.recentSites.splice(i, 1)
                console.warn("  SO, first we REMOVED it: ", this.recentSites)
                break
            }
        }
        if ( this.recentSites.length > 10 ) {
            const oldest = this.recentSites.shift()
            console.log("Was long enough, so, after SHIFTing-off the oldest site (" + oldest + "): ", this.recentSites)
        }

        this.recentSites.push(site)
        console.log("addToRecents(): after adding new site: ", this.recentSites)

        // trigger update of coolSite
        this.updateCoolSitesRecents()

        this.setState({recentSites: this.recentSites})
    }

    /**
     * Populates this.setOfClaimed, this.setForFriends,
     * state.idDropList, and state.surfDropList
     */
    populateTwoSetsAndDropLists() {

        console.warn("POPULATING two sets and the ID drop list...")

        // created in ctor, cleared also in unmount
        this.setOfClaimed.clear()
        this.setForFriends.clear()

        let idDropList = []
        let i = 0
        // RECALL state.claimedRecords includes those claimed for friends
        // And we won't want them in the assetDropdown (assets of ours)
        // But it would be nice to include them as destinations, in the surfDropdown,
        // and even call them out as such
        // (yet avoid duplicates there - if they ever announce)
        for ( let d = 0; d < this.state.claimedRecords.length; d++ ) {
            const recordName = this.state.claimedRecords[d].name
            const forAFriend = this.state.claimedRecords[d].forFriend
            const outdated = this.state.claimedRecords[d].outdated
            const extraText = outdated ? " (outdated)" : ""

            //NOTE: undefined 'forFriend' is construed as a false - which is good

            // don't put a for-a-friend identity in the asset dropdown,
            // AND don't put it in the setOfClaimed, so that lower down, we don't exclude it from the surfDropdown
            if ( forAFriend ) {
                this.setForFriends.add(recordName)
                continue
            }

            // populate the set, for use in surf dropdown, further below
            this.setOfClaimed.add( recordName )

            // don't put our current identity in the asset dropdown
            if ( this.state.ourIdentity === recordName ) {
                continue
            }

            idDropList[i++] =   {
                                key: d,
                                value: d,
                                text: recordName + extraText,
                                disabled: outdated
                            }
        }

        const announcedEntries = this.setOfAnnouncedDomains.values()
        console.log("announcedEntries: ", announcedEntries)
        console.log("for a friend: ", this.setForFriends)
        let surfDropList = []
        let z = 0

        // we intend to ADD the setForFriends to the dropdown,
        // but we don't want duplicates if any have ALSO been announced

        for ( var elem of announcedEntries ) {
             // omit our own identities,          and current domain
            if ( this.setOfClaimed.has( elem ) || elem === this.state.requestedDomain ) {
                continue
            }

            // avoid duplicates (friends who've announced)
            if ( this.setForFriends.has( elem ) ) {
                // this domain-for-a-friend has ALSO announced
                // Let's add it to the surfDropdown LATER, with ALL of our friends
                continue
            }

            surfDropList[z++] = {
                            key: z,
                            value: elem,
                            text: elem,
                            disabled: false
                          }
        } // all announcedEntries

        // Now that we have surfDropdown announced element/options
        // (which exclude any friends), we'll add in ALL of our friends

        const friendEntries = this.setForFriends.values()
        for ( var elem of friendEntries ) {
            // omit current domain
            if ( elem === this.state.requestedDomain ) {
                continue
            }
            surfDropList[z++] =   {
                                    key: z,
                                    value: elem,
                                    text: elem + " (friend)",
                                    disabled: false
                              }
        }

        // used in render()
        this.setState({ idDropList: idDropList,
                        surfDropList: surfDropList,
                      })
    }

    async toggleFavorite() {
        console.warn("ToggleFavorite() - for site " + this.state.requestedDomain)

        console.warn("  CURRENT favoriteDomains: ", this.state.favoriteDomains)

        let isAFave
        if ( this.setOfFavoriteDomains.has( this.state.requestedDomain ) ) {
            this.setOfFavoriteDomains.delete( this.state.requestedDomain )

            console.warn (" REMOVED site from favorites")

            isAFave = false
        } else {
            this.setOfFavoriteDomains.add( this.state.requestedDomain )
            console.warn(" ADDED site to favorites")

            isAFave = true
        }

        const db = await openDB()

        console.warn("will now save this FAVORITE domains list to IDB: ", this.setOfFavoriteDomains)
        // save the list of favorite domains to IDB
        const jsonDoms = JSON.stringify(Array.from( this.setOfFavoriteDomains ));
        console.log("Here's the set in JSON form (which we'll save to IDB): ", jsonDoms);
        await saveSetting(db, "favoriteDomains", jsonDoms)

        // trigger re-render of coolSites
        this.updateCoolSitesFaves()

        this.setState({ favoriteDomains: Array.from( this.setOfFavoriteDomains ),
                        isAFave: isAFave
         })

    } // toggleFavorite()

    updateCoolSitesFaves( ) {
        if ( this.coolSitesRef !== null && this.coolSitesUpdateFavesFunc !== null ) {
            console.warn("SURFER sending updated set of favorites: ", this.setOfFavoriteDomains)
            this.coolSitesUpdateFavesFunc( this.coolSitesRef, Array.from( this.setOfFavoriteDomains ) )
        } else {
            alert("surfer  updateCoolSitesFaves() called too early")
        }
    }

    updateCoolSitesRecents( ) {
        if ( this.coolSitesRef !== null && this.coolSitesUpdateRecentsFunc !== null ) {
            console.warn("SURFER sending updated set of RECENTS: ", this.recentSites)

            // use .slice() to get a new object - to force a re-render()
            this.coolSitesUpdateRecentsFunc( this.coolSitesRef, this.recentSites.slice() )
        } else {
            alert("surfer  coolSitesUpdateRecents() called too early")
        }
    }

    saveCoolSitesCallbacks(me, coolSitesRef, callbackA, callbackB) {
        console.warn("saveCoolSitesCallbacks()  <---")

        me.coolSitesRef = coolSitesRef
        me.coolSitesUpdateFavesFunc = callbackA
        me.coolSitesUpdateRecentsFunc = callbackB
    }

    getDialogsYetToHearBackFrom() {
        //console.log("returning dYTHBF. postNum: ", postNum)
        return Array.from(this.dialogsYetToHearBackFrom)
    }
    getDialogsToRespondTo() {
        //console.log("returning dTRT. postNum: ", postNum)
        return Array.from(this.dialogsToRespondTo)
    }
    getActiveDialogs() {
        //console.log("returning aD. postNum: ", postNum)
        return Array.from(this.activeDialogs)
    }
    getClosedDialogs() {
        //console.log("returning cD. postNum: ", postNum)
        return Array.from(this.closedDialogs)
    }

    async openPostPopup() {

        // read setting 'numPostPopupClicks', INCREMENT it, then save
        const numPostPopupClicksSetting = await readSetting(await openDB(), "numPostPopupClicks")
        console.log("setting:  numPostPopupClicks: ", numPostPopupClicksSetting)

        // we know the setting exists. We made sure in componentDidMount()
        const numPostPopupClicks = numPostPopupClicksSetting.value + 1
        await saveSetting(await openDB(), "numPostPopupClicks", numPostPopupClicks)

        // If user has clicked postPopup more than 4 times, set this state
        this.setState({ showPostPopup: true, userHasClickedPostPopupSeveralTimes: numPostPopupClicks > 4 })

    }
    submitRegularOrGuestPost() {

        if ( this.state.onOurCurrentIdentity ) {
            this.handleSubmitPost()
        } else {
            this.handleSubmitGuestPost()
        }
    }
    handlePostData(params) {
        console.warn("Params received from postPopup: ", params)

        this.closePostPopup()

        // Use these parameters to submit the post/guest-post
        //  - setState from most of the parameters
        //  - AFTER state has rippled, call submitPost / submitGuestPost,
        //    as determined by our this.state.onOurCurrentIdentity

        this.setState({
            inviteToDialog:     params.inviteToDialog,

            photoName:          params.photoName,
            photoBlob:          params.photoBlob,
            addPhotoToPost:     params.addPhotoToPost,
            photoToAdd:         params.photoToAdd,

            encryptSelfPost:    params.encryptSelfPost,

            videoToAdd:         params.videoToAdd,
            addVideoToPost:     params.addVideoToPost,
            videoURL:           params.videoURL,

            content:            params.postText,
        }, this.submitRegularOrGuestPost)

    }
    closePostPopup() {
        this.setState({showPostPopup: false})
    }

    reanimatePostIcon() {

        // loop to re-start animation every few seconds
        // alternating the animation type
        const parent = this
        execAsync2( async function() {
            await new Promise(resolve => setTimeout(resolve, 1250))

            // alternate between animations
            const animType    = parent.state.jigglePostIcon  ? "jiggle" : "bounce"   // "flash",  "tada"

            // toggle the animation on/off
            parent.setState({ jigglePostIcon: ! parent.state.jigglePostIcon,
                              postIconAnimation: animType,
                            })
        }, 250)

    }
    openDrawer() {
        console.warn("opening drawer...")
        this.setState({showDrawer: !this.state.showDrawer})
    }
    closeDrawer() {
        // also clear selection positions
        this.setState({showDrawer: false, showIdentityOption: false})
    }

    // called by CoolSites - to let us know if WE should render the 'surf elsewhere' option
    noteCoolSitesCategory(weShouldRenderSurfElsewhereChoice) {
        this.setState({weShouldRenderSurfElsewhereChoice: weShouldRenderSurfElsewhereChoice})
    }

    toggleShowIdentityOption() {
        this.setState({showIdentityOption: !this.state.showIdentityOption})
    }

    // newly-added Claimer (in side-panel)
    openClaimer() {
        this.setState({showClaimer: true})
    }
    closeClaimer() {
        this.setState({showClaimer: false})
    }
    //NOTE: mostRecentOwnerCount not yet used
    //FIXME: rename to claimerCallback()?
    async userClaimedAsset(boolUserOwnsAssets, mostRecentAsset='???', mostRecentOwnerCount=0, advance = true) {
        console.log("userClaimedAsset() ownsAssets: " + boolUserOwnsAssets)
        console.log("userClaimedAsset() mostRecent: " + mostRecentAsset)
        console.log("userClaimedAsset() advance: " + advance)

        // NOTE: it probably makes sense to compare mostRecentAsset
        //       with '???' HERE, not later
        if ( advance && boolUserOwnsAssets ) {
            console.warn("YES! The user claimed an asset: ", mostRecentAsset)

            const db = await openDB()

            const claimedRecords = await findAllDomainsOfMine(db);

            // update state of ALL of our claimed domains,
            // and THEN re-generate drop-down lists,
            // and THEN jump to the newly-claimed asset
            this.setState({ claimedRecords:   claimedRecords },
                                () =>   {
                                            this.populateTwoSetsAndDropLists()

                                            // ACTUALLY JUMP THERE - so we can update display to MAYBE NOT say it's expired
                                            if ( mostRecentAsset !== '???' ) {
                                                alert("We've updated your records to reflect your ownership of this domain.\n\n" +
                                                      "We're going to 'visit' it right now, so that you can 'set it up'. We're changing your 'identity' to this new domain you've claimed.")
                                                this.handleJumpToOwnDomain(mostRecentAsset)
                                            } else {
                                                console.warn("userClaimedAsset() called WITHOUT param 'mostRecentAsset', so, I guess we're done")
                                            }
                                        }
                        )

        } else {
            console.warn("No, the user probably gave up before claiming")
        }

        this.closeClaimer()
    }

    // avoid user needing to re-load ffmpeg tool each time he uses the Recording Studio
    receiveFFMpegRef(ref) {
        console.warn("SURFER has received an ffmpeg reference from a child")
        this.setState({ffMpeg: ref})
    }

    render() {
        // when the walletModal, or a footer modal is showing,
        // disable the SURFING buttons
        //   props.disableButtons is set when walletModal is showing
        const aModalIsShowing = this.state.footerModalInFocus || this.props.disableButtons

        //console.error("render(): state.furthestQuarterlyStats: ", this.state.furthestQuarterlyStats)

        // ***  DEVICE-DEPENDENT STYLING  ***
        // For mobile, "detect" landscape screen mode
        const landscape = this.props.isMobile ? window.innerWidth > window.innerHeight : false
        // For mobile, shrink the About, Security, and Glossary modals
        const modalClassName   = this.props.isMobile ?
                                        (landscape ?
                                                "modalMobileLandscape"
                                            :
                                                "modalMobilePortrait"
                                        )
                                    :
                                        ""
        const modalContentClassName = this.props.isMobile ? "modalContentMobile" : ""
        const modalBottomClassName  = this.props.isMobile ? "modalBottomMobile"  : ""


        // We use a local callback (instead of a props.handleSignInOutClick)
        // because we have our own WalletVerifier to show
        const pwdCallback = this.props.citizenLevel > 0 ?
                                            this.signInOutCallback
                                        :
                                            null

        const disableJumpToOur = (this.state.claimedRecords?.length > 1 && this.state.selectedDomain === '') || this.state.showFeeThresholdModal
        // icon size: mini tiny small large big huge massive
        const changeIdButtonLabel = this.state.claimedRecords?.length > 1 ?
                                    <>Assume</>
                                :
                                    <>Return Home</>
        const ourDomainToJumpTo = this.state.claimedRecords?.length > 1 ?
                                        this.state.selectedDomain
                                    :
                                        this.state.ourIdentity

        const changeIdButton = !this.state.selfDomainRequest || this.state.claimedRecords?.length > 1 ?
                        <>
                            <Button disabled={disableJumpToOur}
                                    onClick={() => {this.setState({showForceNewIdentityModal: false}, this.handleJumpToOwnDomain(ourDomainToJumpTo)) }}
                                    style={{backgroundColor: "blue", color: "yellow", borderRadius: "8px", padding: 8, margin: 0}} >
                                {changeIdButtonLabel}
                            </Button>
                        </>
                    :
                        <></>
        const returnHomeOption = !this.state.selfDomainRequest ?
                                    <> &nbsp; <Button style={{backgroundColor:"white", color:"blue", border: "2px solid blue", borderRadius:"15px"}}
                                                onClick={() => this.handleJumpToOwnDomain(this.state.ourIdentity)}>
                                                    Return Home
                                              </Button>
                                    </>
                                    //<> &nbsp; <button onClick={() => this.handleJumpToOwnDomain(this.state.ourIdentity)}> Return Home </button></>
                                :
                                    <></>

        const weAreAtOurDefaultID = this.state.claimedRecords?.length === 1 ||
                                    (this.state.defaultID === this.state.ourIdentity &&
                                     this.state.defaultIdOwnerCount === this.state.ourOwnerCount
                                    )
        const currentDefaultIDMention =  this.state.claimedRecords?.length > 1 && !weAreAtOurDefaultID && this.state.defaultID !== '' ?
                                            <>&nbsp;(default: {this.state.defaultID} : {this.state.defaultIdOwnerCount})</>
                                        :
                                            <></>
        const setDefaultIdentityOption = this.state.claimedRecords?.length > 1 ?
                                        (weAreAtOurDefaultID ?
                                        <></>
                                        :
                                        <>
                                            &nbsp;<Checkbox label='Set as default' checked={weAreAtOurDefaultID} onClick={this.toggleMakeDefaultID}/>
                                        </>)
                                    :
                                        <></>
        const yourIdMaybeDefault = weAreAtOurDefaultID ? 'Default Identity' : 'Your Identity'
        let clickMeToSeeIdentityOptions = this.state.claimedRecords?.length > 1 ?
                    <>
                        <div style={{color:"blue", fontSize:"0.8rem", padding: 0, margin: "-4px"}}>
                            &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; ^ click for more options
                        </div>
                    </>
                :
                    <></>
        const identityClause =  <>
                                <span onClick={this.toggleShowIdentityOption}>
                                    <Icon link size='large' style={{color: bshzColors.purple}} name='user' onClick={this.toggleShowIdentityOption}/>&nbsp; {yourIdMaybeDefault}
                                    &nbsp;<b style={{color:"blue", fontSize:"1.3rem"}}>{this.state.ourIdentity.toUpperCase()}</b>:{this.state.furthestQuarterlyDtx?.outputStates[0].ownerCount}
                                </span>
                                </>

        let assetDropDown
        let assetDropDown_ = <></>
        if ( this.state.claimedRecords?.length > 1 && this.props.citizenLevel === 4 ) {
            assetDropDown_ =  <>
                                    <Dropdown upward placeholder='Choose another'
                                            style={{padding: "5px 0px 0px 0px", fontSize:'1.0rem'}}
                                            selection
                                            options={this.state.idDropList}
                                            search={this.state.idSearch}
                                            onChange={this.handleAssetChange}
                                    /> &nbsp;{changeIdButton}
                              </>
            assetDropDown = this.state.showIdentityOption ?
                            <>  {identityClause}
                                {setDefaultIdentityOption}
                                <br></br>
                                <div style={{padding: '8 0 0 0', margin: 0}}>
                                    <span style={{fontSize: '1.0rem'}}>Other identities:</span> {currentDefaultIDMention}
                                    <br></br>
                                    {assetDropDown_}
                                </div>
                            </>
                        :
                            <>
                                {identityClause}
                                {clickMeToSeeIdentityOptions}
                            </>

        } else if ( this.state.claimedRecords?.length === 1 && this.props.citizenLevel === 4 ) {
            assetDropDown =
                                <>
                                    <Icon link size='large' style={{color: bshzColors.purple}} name='user outline' />&nbsp;
                                    Your Identity: <b style={{color:"blue", fontSize:"1.3rem"}}>{this.state.claimedRecords[0].name.toUpperCase()}</b>
                                </>
        } else {
            assetDropDown = <>
                                <Icon link size='large' style={{color: bshzColors.purple}} name='user outline' />&nbsp;
                                You're here as a VISITOR (and might have NO claimed identity).  claimedRecord: {this.state.claimedRecords?.length}  citizenLevel: {this.props.citizenLevel}.
                            </>
        }

        const maybeForceNewIdentityModal = this.state.showForceNewIdentityModal ? //this.state.claimedRecords?.length > 1 && this.props.citizenLevel === 4 ) {
                    <>

                    <Modal dimmer='blurring' size='large' centered className={modalClassName}  open={true}
                                    style={{backgroundColor: bshzColors.purple, borderRadius: '20px'}}>
                        <Modal.Header style={{textAlign: 'center', backgroundColor: bshzColors.purple, borderRadius: '20px'}}>
                            <span style={{color: bshzColors.yellow}}> Choose an alternative identity </span>
                        </Modal.Header>
                        <Modal.Content className={modalContentClassName} scrolling style={{backgroundColor: bshzColors.ltPurple}}>

                        <br></br>
                        <br></br>
                        You had selected a domain ({this.state.ourIdentity}:{this.state.ourOwnerCount}) that
                        we've since realized is <b>outdated</b>.
                        <br></br>
                        <br></br>
                        Since we don't currently allow surfing outdated domains, and since you own OTHER domains, you can choose another.
                        <br></br>
                        <br></br>
                        <div style={{textAlign: 'center'}}>
                            Please choose an identity/domain of yours that isn't outdated.
                            <br></br>
                            <br></br>
                            {assetDropDown_}
                        </div>

                        </Modal.Content>
                    </Modal>
                    </>
                :
                    <></>

        //NOTE: this is for self-jumps
        const maybeOtherOwnedOptions = <div style={{padding: 0}}>{assetDropDown}</div>

        const goButton2Disabled = (this.state.strangerDomain.length < 2 && this.state.strangerSearch.length < 2)
                                    || this.state.showFeeThresholdModal

        let surfDropDown = null
        if ( this.setOfAnnouncedDomains.size > 0 ) {

            surfDropDown = <> <Dropdown upward placeholder='Type or choose'

                                        selection
                                        options={this.state.surfDropList}

                                        search={this.state.search}

                                        // search results displayed
                                        onChange={this.handleSurfDomainDropdownChange}
                                        value={this.state.strangerDomain}

                                        // manually typing a domain (might not appear in dropdown)
                                        onSearchChange={this.handleSearchChange}
                                        searchQuery={this.state.strangerSearch}

                                        style={{backgroundColor:'#e0e0e0'}}
                                        //clearable
                                        //selectOnNavigation={false}
                                        //onClick={this.handleSurfClick}
                                        //text={this.state.strangerDomain}
                                        //onLabelClick={this.handleLabelClick}
                                        //selectedLabel={this.state.domainInputted}
                              />
                            </>
        }
        const strangerInputOrDropDown = this.setOfAnnouncedDomains.size < 1 ?
                            <>
                                    <Input placeholder='Enter a domain name'
                                        value={this.state.strangerSearch}
                                        style={{width: "330px", padding: 0, margin: 0}}

                                        onChange={this.handleSurfDomainInputChange} />
                            </>
                        :
                            <>
                            {surfDropDown}
                            </>

        // icon sizes: mini tiny small large big huge massive
        const scanExplainer = <Icon  size='large' style={{color: bshzColors.purple}} name='question circle outline' />
        const popUpScanExplainerText = <>By <i>occasionally</i> scanning for domains, your BitShizzle browser
                                        will be made aware of new sites that have 'announced' since your last scan.
                                        <br></br>
                                        If you're not sure what to do next, you might want to scan now - to find domains to visit.
                                        <br></br>
                                        So far, we've <i>heard</i> approximately {this.setOfAnnouncedDomains.size} domain
                                        announcements.
                                        </>
        const popupScanExplainer = <Popup style={{backgroundColor: bshzColors.ltYellow}} trigger={scanExplainer} wide="very" content={popUpScanExplainerText} hideOnScroll/>

        const scanDomainsOption = this.state.offerToVisitAnnounce ?
                                <>
                                    <Icon link size='large' style={{color: bshzColors.purple}} name='binoculars' onClick={this.scanForDomains}/> &nbsp;
                                    <span onClick={this.scanForDomains}>
                                        Scan for domains
                                    </span>
                                    &nbsp; {popupScanExplainer}
                                </>
                            :
                                <>
                                    <Icon link size='large' style={{color: bshzColors.purple}} name='binoculars' disabled/> &nbsp;
                                    <span style={{color:'grey'}}>
                                        Scan for domains
                                    </span>
                                    &nbsp; {popupScanExplainer}
                                </>
        const strangerOptions = this.state.weShouldRenderSurfElsewhereChoice ?
                                <>
                                <div style={{padding: "0px 10px 0px 10px"}}>
                                    <b style={{color:'black', fontSize:"1.3rem"}}>Surf Elsewhere</b>
                                    <br></br>

                                    {strangerInputOrDropDown}
                                    <Button type="submit"
                                            style={{backgroundColor:'black', color: "yellow"}}
                                            disabled={goButton2Disabled}
                                            onClick={this.pokeTheViewerWithStranger}>
                                        GO
                                    </Button>
                                    {returnHomeOption}
                                </div>
                                </>
                            :
                                <></>

        //FIXME: props.ourDomain probably not sufficient if we select a DIFFERENT domain of ours
        const actualDomain = this.state.display404 ? this.state.requestedDomain : (this.state.requestedDomain ? this.state.requestedDomain : this.props.ourDomain)

        const ownershipColor =   this.state.onOurCurrentIdentity ? "blue" : "green"
        const ownershipMention = this.state.onOurCurrentIdentity ? <b>in your very own</b> : <>as a <i>guest</i> in</>
        // This is for when viewing YOUR OWN site, and it's not yet ready
        const maybeNotMuchToShow = this.state.displayNotMuchToSee && this.state.onOurCurrentIdentity ?
                        <div style={{textAlign: "center", backgroundColor: "#e0e0e0", }}>
                            <br></br>
                            <h1 style={{color:"red"}}>
                                There's not much to see.
                                <br></br>
                                <b>You haven't yet posted anything.</b>
                            </h1>
                            <div>
                                ShizzleVerse domain <b style={{color: "blue", fontSize: "1.3rem",}}>'{this.state.requestedDomain}'</b> is basically blank - <b>so far</b>.
                                <br></br>
                                Feel free to post something.
                            </div>
                            <br></br>
                            <br></br>
                        </div>
                    :
                        <></>

        const iconName = this.state.isAFave ? "star" : "star outline"
        // icon sizes: mini tiny small large big huge massive
        const favesExplainer = <Icon size='small' style={{color: bshzColors.purple}} name={iconName} onClick={this.toggleFavorite}/>
        const popUpFavesExplainerText = <> Click to Save/Un-Save your favorite domains </>
        const popupFavesExplainer = <Popup style={{backgroundColor: bshzColors.ltYellow}} trigger={favesExplainer} wide="very" content={popUpFavesExplainerText} hideOnScroll/>


        // icon sizes: mini tiny small large big huge massive
        const expirationExplainer = <Icon  size='small' style={{color: bshzColors.purple, cursor: "pointer"}} name='question circle outline' />
        const popUpExpirationExplainerText =  <>
                                                Since this domain/site has EXPIRED (has not been renewed by the previous owner), anyone can now re-claim it, and assume ownership/control of it.
                                                <br></br>
                                                <br></br>
                                                To (re)claim this site, you'd start by clicking '<span style={{color:'blue'}}>Claim a <b>new</b> identity</span>' in the side/control panel [click the <Icon name='target'/> on the top-left of the screen].
                                              </>
        const popupExpirationExplainer = <Popup style={{backgroundColor: bshzColors.ltYellow}} trigger={expirationExplainer} wide="very" content={popUpExpirationExplainerText} hideOnScroll/>


        // icon sizes: mini tiny small large big huge massive
        const extendQuarterlyExplainer = <Icon  size='small' style={{color: bshzColors.purple, cursor: "pointer"}} name='question circle outline' />
        const popUpExtendQuarterlyExplainerText =  <>
                                                Enough time has passed since you last posted a 'Quarterly' (or setup your domain/ID). You may now update your site with a new Quarterly post.
                                                Doing so, when you can, will help keep your domain efficient. It's nothing more than making a post.
                                                <br></br>
                                                <br></br>
                                                To do this, select '<span style={{color:'blue'}}>Site Status</span>' in the side/control panel [click the <Icon name='target'/> on the top-left of the screen].
                                              </>
        const popupExtendQuarterlyExplainer = <Popup style={{backgroundColor: bshzColors.ltYellow}} trigger={extendQuarterlyExplainer} wide="very" content={popUpExtendQuarterlyExplainerText} hideOnScroll/>


        const isForSale = this.state.furthestQuarterlyStats?.priceInSats > 0
        const showIfQuarterlyActionable = this.state.furthestQuarterlyStats?.expired ?
                    <div style={{ backgroundColor: bshzColors.ltYellow}}>This site/domain has <b style={{color: 'red'}}>EXPIRED</b>, so, ANYONE can re-claim it right NOW &nbsp; {popupExpirationExplainer}</div>
                :
                    this.state.furthestQuarterlyStats?.renewable && this.state.onOurCurrentIdentity ?
                            <div>This site/domain is currently <b style={{color: 'red'}}>RENEWABLE</b>, so, you can (and probably should) now RENEW it</div>
                        :
                            this.state.furthestQuarterlyStats?.canBuildNewQuarterly && this.state.onOurCurrentIdentity ?
                                    <div>The Quarterly for this site/domain is now EXTENDABLE &nbsp; {popupExtendQuarterlyExplainer}</div>
                                :
                                    isForSale ?
                                            <div>This site/domain is currently <b style={{color: 'red'}}>FOR SALE</b></div>
                                        :
                                            <></>

        const siteIsActionable = //this.state.furthestQuarterlyStats.expired ||
                                    ( this.state.furthestQuarterlyStats?.renewable && this.state.onOurCurrentIdentity ) ||
                                    ( this.state.furthestQuarterlyStats?.canBuildNewQuarterly && this.state.onOurCurrentIdentity ) ||
                                    ( this.state.furthestQuarterlyStats?.priceInSats > 0 )
        const siteStatusIconColor = this.state.furthestQuarterlyStats?.expired ? 'red' : siteIsActionable ? 'green' : bshzColors.purple
        const siteStatusWordColor = this.state.furthestQuarterlyStats?.expired ? 'red' : siteIsActionable ? 'green' : 'black'
        const siteStatusBackColor = this.state.furthestQuarterlyStats?.expired || siteIsActionable ? 'yellow' : ''
        const sidePanelIconColor = this.state.furthestQuarterlyStats?.expired ? 'red' : siteIsActionable ? 'green' : 'yellow'
        const siteStatus =  <>
                                <Icon name='chart bar' link size='large' onClick={this.showSiteStatus} style={{color: siteStatusIconColor}} /> &nbsp;
                                <span style={{color: siteStatusWordColor, backgroundColor: siteStatusBackColor}} onClick={this.showSiteStatus}>Site Status</span>
                            </>

        const maybeShow404 = this.state.display404 ?
                        <div id='theTopElem' style={{textAlign: "center", backgroundColor: "#e0e0e0", }}>
                            <br></br>
                            <h1 style={{color:"red"}}>
                                We're sorry...
                                <br></br>
                                <b>404 - Not Found</b>
                            </h1>
                            <div>
                                That ShizzleVerse domain (<b style={{color: "blue", fontSize: "1.3rem",}}>'{this.state.requestedDomain}'</b>) does not exist - <b>yet</b>.
                            </div>
                            <br></br>
                            <br></br>
                        </div>
                    :
                        <>
                            <div id='theTopElem' style={{color: "blue", fontSize: "1.45rem", padding: "10px"}}>
                                Enjoy the ride {ownershipMention} domain <b style={{color: ownershipColor}}>{actualDomain.toUpperCase()}</b><span style={{fontSize:"1.1rem"}}>:{this.state.furthestQuarterlyDtx?.outputStates[0].ownerCount}</span>
                                &nbsp; {popupFavesExplainer}
                                {showIfQuarterlyActionable}
                                <br></br>
                                {this.state.currentAvatar}
                            </div>
                        </>
        const maybeShowSiteStatusModal = this.state.showSiteStatusModal ?
                    <>
                        <SiteStatusModal    siteName={this.state.furthestQuarterlyDtx.limbName}
                                            ownerCount={this.state.furthestQuarterlyDtx.outputStates[0].ownerCount}
                                            qStats={this.state.furthestQuarterlyStats}
                                            ourId={this.state.ourIdentity}
                                            closeIt={this.closeSiteStatusModal}
                                            blockHeight={this.props.blockHeight}

                                            isOurDomain={this.state.isOurDomain}
                                            onOurCurrentIdentity={this.onOurCurrentIdentity()}

                                            extendQuarterly={this.forceBuildNextQuarterly}
                                            setForSale={this.setDomainForSale}
                                            purchaseTheDomain={this.purchaseDomain}
                                            bsvPriceUSD={this.props.bsvPriceUSD}

                                            isMobile={this.props.isMobile}
                        />
                    </>
                :
                    <></>

        // icon sizes: mini tiny small large big huge massive
        const feeSettingsExplainer = <Icon  size='large' style={{color: bshzColors.purple, cursor: "pointer"}} name='question circle outline' onClick={this.handleFeeSettingsClick} />
        const popUpFeeSettingsExplainerText = <>To adjust your preferences on Posting/Commenting fees:
                                                <br></br>
                                                 - What you're willing to pay/tip when commenting on other domains
                                                 <br></br>
                                                 - How much to charge others to post on <b>your</b> current identity/domain
                                              </>
        const popupFeeSettingsExplainer = <Popup style={{backgroundColor: bshzColors.ltYellow}} trigger={feeSettingsExplainer} wide="very" content={popUpFeeSettingsExplainerText} hideOnScroll/>

        const dialogHiLite = <span style={{color:'blue'}}>Dialogue</span>
        const dialogsHiLite = <span style={{color:'blue'}}>Dialogues</span>





        //NOTE: we don't yet ENCRYPT video, so, don't imply we do
        //FIXME: option to encrypt video
        //const videoButtonLabel = this.state.inviteToDialog ?
        //                            null
        //                        :
        //                            this.state.encryptSelfPost ?
        //                                'Audio/Video Studio'   //'Record a private video'
        //                            :
        const videoButtonLabel = <>
                                    <span onClick={() => this.handleVideoRecordClick(false)}>
                                        <Icon name='microphone' style={{color:bshzColors.purple}} size='small'/><Icon name='video camera'style={{color:bshzColors.purple}} size='small'/>&nbsp;
                                        <span>Recording Studio</span>
                                    </span>
                                    <span onClick={() => this.handleVideoRecordClick(true)}>
                                        <Icon name='microphone' style={{color:bshzColors.purple}} size='small'/><Icon name='video camera'style={{color:bshzColors.purple}} size='small'/>&nbsp;
                                        <span>Recording Studio</span>
                                    </span>
                                </>






        // we also disable the Post-on-your site button if you want a photo, but haven't specified it
        const disablePostButton = this.state.disablePubButton || this.state.showFeeThresholdModal || this.state.content.length < 1
                                        || (this.state.addPhotoToPost && this.state.photoToAdd === null )
                                        || (this.state.addVideoToPost && this.state.videoToAdd === null )

        const bgColor = this.state.selfDomainRequest ? bshzColors.ltPurple : bshzColors.ltBluePurple
        const popupHeaderTitle = this.state.selfDomainRequest ? 'Post to your site' : 'Comment on this site'
        const introMentionNotEncrypted = this.state.selfDomainRequest ? 'the world?' : 'this site (and the world)?'
        const infoMentionNotEncrypted  = this.state.selfDomainRequest ? 'all the world.' : "this site's owner (and the world)."
        const privacyMention = this.state.selfDomainRequest ? 'Encrypt post (private to you)' : 'Encrypt post (disabled for comments)'
        const maybePostPopup = this.state.showPostPopup ?
                <>
                    <PostPopup  isMobile={this.props.isMobile}
                                close={this.closePostPopup}
                                processPost={this.handlePostData}

                                suitableForDialogInvite={this.state.suitableForDialogInvite}
                                dialogInvitee={this.state.dialogInvitee}

                                initialEncrypt={this.state.encryptSelfPost}
                                headerTitle={popupHeaderTitle}
                                introText='What would you like to say to'
                                introMentionNotEncrypted={introMentionNotEncrypted}
                                introMentionEncrypted='yourself'
                                infoText='This post will be visible to'
                                infoMentionNotEncrypted={infoMentionNotEncrypted}
                                infoMentionEncrypted='just yourself.'

                                bgColor={bgColor}
                                allowEncryption={this.state.selfDomainRequest}
                                privacyMention={privacyMention}
                                />
                    <br></br>
                </>
                    :
                null

        const postPopupIcon = <Icon className="hoverLink" name='plus circle' onClick={this.openPostPopup} size='big' style={{color:'blue'}}/>
        const animatedPostIcon = this.state.userHasClickedPostPopupSeveralTimes ?
                <>
                    {postPopupIcon}
                </>
            :
                <span>
                    <Transition transitionOnMount
                            visible={this.state.jigglePostIcon}
                            animation={ this.state.postIconAnimation }
                            duration={1000}
                            onComplete={this.reanimatePostIcon} >
                        {postPopupIcon}
                    </Transition>
                </span>

        // This regards a post the user has yet to make
        //Form size = mini tiny small large big huge massive
        //NOTE: if we make form 355px, the dialogCheckbox's popupInviteExplainer icon wraps to next line
        const postOnOwnSite = this.state.onOurCurrentIdentity ?
                                <>
                                    <div style={{padding: "10px"}}>
                                        Would you like to say something? &nbsp;
                                        {maybePostPopup}
                                        {animatedPostIcon}

                                        <br></br>

                                    </div>
                                </>
                        :
                                <></>

        const maybeVideoRecorderModal = this.state.showVideoModal ?
                    <>
                        <VideoRecorderModal  closeVideoModal={this.closeVideoRecorderModal}
                                             theKey={this.props.bundle.p}
                                             bsvPriceUSD={this.props.bsvPriceUSD}

                                             receiveFFMpegRef={this.receiveFFMpegRef}
                                             ffMpeg={this.state.ffMpeg}

                                             playbackNotRequired={this.state.chooseMimeTypeBasedOnSupportForPlayback}
                                             />
                    </>
                :
                    <></>

        let gpPrice = ''
        if ( this.state.satsPerGuestPost > -1 ) {
            ////const penniesPerSat = this.props.bsvPriceUSD / 1000000
            //console.log("penniesPerSat: " + this.props.bsvPriceUSD / 1000000)
            console.log("satsPerPenny: " + 1000000 / this.props.bsvPriceUSD)

            //FIXME: move this WHOLE shebang to where we SET state.satsPerGuestPost?
            //       OR if we get an updated BSV price?

            const gpPriceInDollars = this.props.bsvPriceUSD * this.state.satsPerGuestPost / 100000000
            if ( gpPriceInDollars < 1.0 ) {
                const gpPriceInPennies = this.props.bsvPriceUSD * this.state.satsPerGuestPost / 1000000
                if ( gpPriceInPennies < 1.0 ) {
                    const gpPriceInMilliPennies = this.props.bsvPriceUSD * this.state.satsPerGuestPost / 1000
                    if ( gpPriceInMilliPennies < 1.0 ) {
                        gpPrice = <>less than a <b>milli</b>-penny (US)</>
                    } else {
                        gpPrice = <>approx {gpPriceInMilliPennies.toFixed(0)} <b>milli</b>-pennies (US)</>
                    }
                } else if ( gpPriceInPennies < 100 ) {
                    gpPrice = <>approx {gpPriceInPennies.toFixed(1)}¢ (US)</>
                }
            } else {
                gpPrice = <>approx ${gpPriceInDollars.toFixed(2)} (US)</>
            }
        }

        // icon sizes: mini tiny small large big huge massive
        const feeExplainer = <Icon  size='large' style={{color: bshzColors.purple}} name='question circle outline' />
        const popUpFeeExplainerText = <>
                                        Domain owners can charge a fee for others to post/comment on their site - or even prohibit it altogether. Setting a fee may discourage spammers.
                                        <br></br>
                                        Open the sidepanel (click the bullseye icon, up top) to adjust your fee preferences.
                                      </>
        const popupFeeExplainer = <Popup style={{backgroundColor: bshzColors.ltYellow}} trigger={feeExplainer} wide="very" content={popUpFeeExplainerText} hideOnScroll/>

        // icon sizes: mini tiny small large big huge massive
        const commentSetupExplainer = <Icon  size='large' style={{color: bshzColors.purple}} name='question' circular />
        const popUpCommentExplainerText = <>To enable posting on <b>other</b> sites/domains/identities, you need to:<br></br>
                                            - Check your fee settings to declare if, and how much, you'd be willing to pay for the privilege of posting on another site<br></br>
                                            - Post at least twice, on your own domain/identity/site
                                          </>
        const popupCommentExplainer = <Popup style={{backgroundColor: bshzColors.ltYellow}} trigger={commentSetupExplainer} wide="very" content={popUpCommentExplainerText} hideOnScroll/>

        const maxSats = satsFromMilliPennies(this.state.maxMilliPenniesToComment, this.props.bsvPriceUSD)
        const tooRichForYou = this.state.guestPostingCanHappen && (this.state.satsPerGuestPost > maxSats)














        //if ( this.state.disablePubButton )      console.warn("Disable comment because disablePubButton")
        //if ( this.state.showFeeThresholdModal ) console.warn("Disable comment because showFeeThresholdModal")
        //if ( this.state.content.length < 1 )    console.warn("Disable comment because too short")
        //const disableCommentButton = this.state.disablePubButton || this.state.showFeeThresholdModal //|| this.state.content.length < 1

        // If this site isn't your current identity, and guestPosting can happen on it, then offer it as an option
        const guestBoxColor = this.state.suitableForDialogInvite && this.state.inviteToDialog ? bshzColors.ltPurple : "#e8e8e8"



        const guestPostOption = !this.state.selfDomainRequest ?
                        (this.state.guestPostingCanHappen  ?
                                (!tooRichForYou ?
                                ( this.state.weHaveATransient ?
                                        <>
                                            <div style={{padding: "10px"}}>
                                                Would you like to post a comment on this site? &nbsp;
                                                {maybePostPopup}
                                                {animatedPostIcon}


                                                <br></br>
                                                <br></br>




                                                <span style={{color:"blue"}}><b>Comment Fee</b>: {this.state.satsPerGuestPost} sats ({gpPrice})</span> {popupFeeExplainer}
                                                <br></br>


                                            </div>
                                        </>
                                    :
                                        <div style={{padding: "10px"}}>
                                            You're <b style={{color:"red"}}>not yet set-up</b> to comment on <i>other</i> sites. &nbsp;{popupCommentExplainer}
                                        </div>
                                )
                                    :
                                <>
                                    <div style={{padding: "10px"}}>
                                        This site charges more for visitor comments (<b style={{color:"red"}}>{this.state.satsPerGuestPost}</b> satoshis) than
                                        you may be comfortable with (<b style={{color:"blue"}}>{maxSats.toFixed(0)}</b> sats) - as specified in your fee settings.

                                    </div>
                                </>
                                )
                        :
                                <>
                                    <div style={{padding: "10px", color:"blue"}}>The site you're visiting isn't currently set-up to accept comments.</div>
                                </>
                        )
                :
                        ( this.state.weHaveATransient ?
                            <span  style={{padding: "10px"}}>You're setup to comment on other sites.</span>
                                :
                            <div style={{padding: "10px", color:"blue"}}>You <b style={{color:"red"}}>aren't yet set-up</b> to comment on <i>other</i> sites. &nbsp;{popupCommentExplainer}</div>
                        )

        const maybeCommentThresholdModal = this.state.showFeeThresholdModal ?
                            <>
                                <FeeSettingsModal
                                    bsvPriceUSD={this.props.bsvPriceUSD}
                                    siteName={this.state.ourIdentity}
                                    siteOwnerCount={this.state.ourOwnerCount}
                                    closeMe={this.closeFeeSettingsModal}
                                    firstTime={this.state.feeSettingsFirstTime}
                                />
                            </>
                        :
                            <>
                            </>

        // icon sizes: mini tiny small large big huge massive
        const announceExplainer = <Icon  size='large' style={{color: bshzColors.purple}} name='question circle outline' />
        const popUpAnnounceExplainerText = <>'Announcing' your site/domain tells others that you exist, and that you might not mind their attention.</>
        const popupAnnounceExplainer = <Popup style={{backgroundColor: bshzColors.ltYellow}} trigger={announceExplainer} wide="very" content={popUpAnnounceExplainerText} hideOnScroll/>

        const bullhornIcon = <Icon size='large' style={{color: bshzColors.purple}} name='bullhorn' />
        const maybeAnnounce = this.state.selfDomainRequest && this.state.weHaveATransient && !this.state.hasAnnounced ?
                            <>
                                <Icon size='large' style={{color: bshzColors.purple}} name='bullhorn' /> &nbsp;
                                <span onClick={this.handleAnnounceClick}>Announce your site</span>
                            </>
                        :
                            <>
                                <Icon size='large' style={{color: bshzColors.purple}} name='bullhorn' disabled/> &nbsp;
                                <span style={{color:'grey'}}>Announce your site</span>
                            </>

        const newlyScannedDomainsMention = this.setOfNewestDomains.size > 0 ?
                        <>
                            <Message style={{backgroundColor: "#e0e0e0"}}>
                                <Message.Header>Newly-Scanned Domains</Message.Header>
                                <Message.List items={Array.from(this.setOfNewestDomains)} />
                            </Message>
                        </>
                    :
                        <></>

        const dialogRules = <>  A {dialogHiLite} is like a one-on-one conversation. You can start one with someone if they've commented directly
                                on a comment of yours (or commented on your domain), <b>and</b> you act quickly to
                                respond directly to that comment.
                                <br></br>
                                <b>Click</b> to examine your current Dialogues.
                            </>
        const dialogHoverText = JSON.parse(this.state.dialogsOfInterest).length > 0 ?
                            <>
                                <h3>Dialogues</h3>
                                {this.state.ourIdentity.toUpperCase()} has at least {JSON.parse(this.state.dialogsOfInterest).length} {dialogsHiLite} with other ShizzleVersians (that we've noticed).
                                <br></br>
                                {dialogRules}
                             </>
                        :
                            <>
                                <h3>Dialogues</h3>
                                Your identity, <b style={{color:'blue'}}>{this.state.ourIdentity.toUpperCase()}</b>, has
                                no {dialogsHiLite} with other ShizzleVersians - yet (that we've noticed).
                                <br></br>
                                {dialogRules} Don't worry, we'll help you to spot these opportunities.
                            </>

        const jumpToSingleDialog = this.state.aPartyToThisDialog && this.state.jumpingToDialog ?
                                        this.state.theDialogId
                                    :
                                        null

        const aPartyToJumping    = this.state.aPartyToThisDialog && this.state.jumpingToDialog
        const maybeDialogManager = this.state.showDialogManager || aPartyToJumping ?
                            <>
                                <DialogManager
                                        jumpToSingleDialog={jumpToSingleDialog}

                                        isMobile={this.props.isMobile}

                                        ourIdentity={this.state.ourIdentity}
                                        dialogsOfInterest={JSON.parse( this.state.dialogsOfInterest )}

                                        getDialogsYetToHearBackFrom={this.getDialogsYetToHearBackFrom}
                                        getDialogsToRespondTo={ this.getDialogsToRespondTo }
                                        getActiveDialogs={ this.getActiveDialogs }
                                        getClosedDialogs={ this.getClosedDialogs }

                                        closeManager={this.closeDialogManager}

                                        bundle={this.props.bundle}

                                        dialogCallbackToParent={this.handleDialogListEvents}
                                />
                            </>
                        :
                            <></>

        const showIfAPartyToThis     = this.state.aPartyToThisDialog ?
                                                                        <>
                                                                            You, {getBitcoinDomainName(this.state.ourIdentity, true)}, are a party to this Dialogue.
                                                                            <br></br>
                                                                            <Button style={{margin:"8px 0px", padding:"8px ", backgroundColor: bshzColors.purple, color:bshzColors.yellow}} onClick={(e) => this.jumpToOneDialog(e, this.state.theDialogId)}>Check it out</Button>
                                                                        </>
                                                                    :
                                                                        <> You, {getBitcoinDomainName(this.state.ourIdentity, true)}, are NOT a party to this Dialogue.
                                                                        </>
        const dialogIcon = <Icon name='mail outline' size='large' style={{color:bshzColors.purple}}/>
        const dialogExplainerText = <>  A {dialogHiLite} is a running conversation between two ShizzleVersians. It's a follow-on option anytime you receive
                                        a 'comment' from a fellow ShizzleVersian. Once started, it can be public, or private (encrypted).
                                        <br></br>
                                        On <b>this</b> particular post, you or this other party took the opportunity to start a {dialogHiLite}.
                                    </>
        const popupDialogExplainer = <Popup style={{backgroundColor: bshzColors.ltYellow}} position="top center" trigger={dialogIcon} content={dialogExplainerText} hideOnScroll/>
        const showIfDialogStartsHere = this.state.dialogStartsHere ?
                                            <div>
                                                <b style={{color:bshzColors.purple}}>A Dialogue starts here.</b> &nbsp;
                                                {popupDialogExplainer}
                                                <br></br>
                                                {showIfAPartyToThis}
                                            </div>
                                        :
                                            <></>

        const guestPostHoverText =  <>
                                        <h3>Guest Posts</h3>
                                        GuestPosts are posts/comments that others make directly on your domain, or which you make directly on theirs.
                                        <br></br>
                                        You can set a price for others to post on your domain. Similarly, others may set a price for you to post on theirs.
                                        <br></br>
                                        GuestPosts are also opportunities to start a Dialogue.
                                        <br></br>
                                        <b>Click</b> to review any GuestPosts you're a party to.
                                    </>

        const maybeGPManager = this.state.showGuestPostManager ?
                                <>
                                    <GuestPostManager
                                            ourIdentity={this.state.ourIdentity}
                                            ownerCount={this.state.ourOwnerCount}
                                            closeManager={this.closeGuestPostManager}
                                            dialogsOfInterest={JSON.parse( this.state.dialogsOfInterest )}

                                            getDialogsYetToHearBackFrom={this.getDialogsYetToHearBackFrom}
                                            getDialogsToRespondTo={ this.getDialogsToRespondTo }
                                            getActiveDialogs={ this.getActiveDialogs }
                                            getClosedDialogs={ this.getClosedDialogs }
                                            bundle={this.props.bundle}
                                            dialogCallbackToParent={this.handleDialogListEvents}
                                    />
                                </>
                            :
                                <>
                                </>

        const maybeShowPriceAndBalance = false ?
                    <></>
                :
                    <>
                        <p>
                            &nbsp; &nbsp;Bitcoin Price (USD): ${this.props.bsvPriceUSD}<br></br>
                            &nbsp; &nbsp;Current Wallet Balance (USD): {this.balancePennies}¢<br></br>
                            &nbsp; &nbsp;Best UTXO balance (USD): {this.bestUtxoPennies}¢<br></br>
                            &nbsp; &nbsp;current blockHeight: {this.props.blockHeight}<br></br>
                        </p>
                    </>

        const claimerOption =  this.state.showIdentityOption ?
                            <></>
                        :
                            <div style={{padding: 0, margin: '15px 0px 4px 2px'}}>
                                <span onClick={this.openClaimer}>
                                <Icon link size='large' style={{color: bshzColors.purple}} name='user plus' onClick={this.openClaimer}/>&nbsp;Claim a <b>new</b> identity
                                </span>
                            </div>
        const maybeClaimer = this.state.showClaimer ?
                            <>
                                <Claimer    getOfficialWallet={this.props.getOfficialWalletRecord}
                                            bundle={this.props.bundle}
                                            claimedCallback={this.userClaimedAsset}
                                            bsvPriceUSD={this.props.bsvPriceUSD}
                                            balancePennies={this.balancePennies}
                                            bestUtxoPennies={this.bestUtxoPennies}

                                            isMobile={this.props.isMobile}

                                            blockHeight={this.props.blockHeight}
                                />
                            </>
                        :
                            <></>

        //FIXME: ponder if the Surfer should registerClient:
        //          registerClient={this.registerBRunnerClient}
        //          unregisterClient={this.unregisterBRunnerClient}
        //       Probably - so it can get periodic heartbeats/prompts to query subcription UTXOs

        //NOTE: we now ALWAYS show the dialog icon - we just alter the hover text depending on whether or not there are dialogs to peruse


        const sidebarClassname = this.props.isMobile ? (landscape ? 'my_phone_landscape_sidebar' : 'my_phone_sidebar') : 'my_sidebar'

        const walletIcon = <Icon link size='large' style={{color: bshzColors.purple}} name='money bill alternate' onClick={this.props.handleWalletClick}/>
        const logOutIcon = <Icon link size='large' style={{color: bshzColors.purple}} name='toggle on' onClick={pwdCallback}/>

        // from APP:
        // <Sidebar className={sidebarClassname} style={{padding: 0, position: 'fixed', top: "92.5px"}}
        return(
            <>
            <div id="container"  >
                <div id="header">
                        <ShizzleMasthead blank={true} qualityTxs={this.state.qualityTxs}
                                        passwordVerified={this.props.citizenLevel > 1}
                                        logInOutCallback={pwdCallback}

                                        sidebarCallback={this.openDrawer}

                                        guestPostIconClickCallback={this.guestPostIconClick}
                                        guestPostHoverText={guestPostHoverText}

                                        dialogIconClickCallback={this.dialogIconClick}
                                        dialogHoverText={dialogHoverText}
                                        showDialogIcon={true}

                                        sidebarIconColor={sidePanelIconColor}
                                        />
                </div>
                <div id="body" ref={this.stickyRef} >
                    <div style={{paddingTop: "90px", backgroundImage: 'url("paleGreySmoke.jpg")'}} className="cont">

                    <Sidebar.Pushable style={{ transform: "none" }}>
                    <Sticky context={this.stickyRef} offset={92} bottomOffset={0}>
                        <Sidebar className={sidebarClassname} style={{padding: 8, position: 'fixed', top: "92px", bottom: "0px", overflowY: "auto"}}
                                    animation='overlay'
                                    direction='right'
                                    icon='labeled'
                                    onHide={() => this.closeDrawer()}

                                    visible={this.state.showDrawer}
                                >
                                    <div style={{padding: 0, fontSize:'1.2rem'}}>
                                    <Segment style={{overflow: 'auto', maxHeight: '100%', backgroundColor:bshzColors.ltPurple, padding: 8 }}>

                                        <Icon name='window close outline' onClick={this.closeDrawer} size='large' color='black'/>
                                        <br></br>
                                        <br></br>

                                        {siteStatus}
                                        <br></br>
                                        <Divider style={{padding: 0, margin: "13px 0px 8px 0px"}}/>

                                        {walletIcon} &nbsp;
                                        <span onClick={this.props.handleWalletClick}>Your Wallet</span>
                                        <br></br>
                                        <br></br>
                                        {maybeAnnounce} &nbsp;{popupAnnounceExplainer}
                                        <br></br>
                                        <br></br>
                                        {scanDomainsOption}
                                        <br></br>
                                        <br></br>
                                        {videoButtonLabel}

                                        <br></br>

                                        <Divider style={{padding: 0, margin: "13px 0px 8px 0px"}}/>

                                        {maybeOtherOwnedOptions}

                                        {claimerOption}

                                        <Divider style={{padding: 0, margin: "13px 0px 10px 0px"}}/>

                                        <span onClick={this.handleFeeSettingsClick}>Fee preferences </span>{popupFeeSettingsExplainer}
                                        <br></br>
                                        <br></br>

                                        <span onClick={this.handleTxProviderClick}>Transaction Providers</span>

                                        <br></br>

                                        <Divider style={{padding: 0, margin: "13px 0px 8px 0px"}}/>

                                        {logOutIcon} &nbsp;
                                        <span onClick={pwdCallback}>Logout</span>
                                    </Segment>
                                    </div>
                        </Sidebar>
                        </Sticky>
                        <Sidebar.Pusher  >

                        {maybeShowPriceAndBalance}

                        {maybeShow404}
                        {maybeShowSiteStatusModal}
                        <Table style={{borderSpacing:"0px", border:'none', margin:'0px 5px', width:"100%", backgroundColor:"#00000000"}} >
                            <Table.Body><Table.Row>
                                <Table.Cell style={{width:"75%", border:'none', padding:'0px'}}>
                                    <ShizzleView    parent={this}
                                            blockHeight={this.props.blockHeight}
                                            show={true}
                                            submode={true}
                                            domainToView={this.props.ourDomain}

                                            registerSubmodeCallback={this.registerViewerCallback}
                                            subModeResponse={this.viewResponseCallback}

                                            guestPostReport={this.receiveGuestPostReports}

                                            postNavNotification={this.postNavNote}

                                            getKeyForDecryption={this.deriveSharedKey}

                                            guestPostWeAreAPartyToCallback={this.guestPostCallback}
                                            />
                                </Table.Cell>
                                <Table.Cell style={{width:"25%", padding:'15px'}}>
                                    {showIfDialogStartsHere}
                                </Table.Cell>

                            </Table.Row></Table.Body>
                        </Table>
                        {maybeNotMuchToShow}


                        {postOnOwnSite}

                        {guestPostOption}

                        <Divider/>
                        </Sidebar.Pusher>
                    </Sidebar.Pushable>

                        {strangerOptions}

                        <CoolSites  parent={this}
                                    categorySetCallback={this.noteCoolSitesCategory}
                                    goToSite={this.goToCoolSite}
                                    registerCallbacks={this.saveCoolSitesCallbacks}
                                    currentSite={this.state.requestedDomain}
                        />

                        {maybeDialogManager}
                        {maybeVideoRecorderModal}


                    </div>
                </div>

                <Footer enforceTerms={true}
                        topOfFooter=''
                        isMobile={this.props.isMobile}
                        jiggleFooter={true}
                        sendEmail={this.props.sendEmail}

                        footerFocus={this.footerFocus}
                        />

            </div>
            {maybeCommentThresholdModal}

            {maybeClaimer}

            {maybeGPManager}

            {maybeForceNewIdentityModal}

            <Modal dimmer='blurring' size='mini' centered className={modalClassName}  open={this.state.showSwirly}
                            style={{backgroundColor: bshzColors.purple, borderRadius: '20px', height: "auto"}}>
                <Modal.Content className={modalContentClassName} scrolling style={{backgroundColor: bshzColors.ltPurple}}>

                  <div style={{textAlign: 'center'}}>
                    <Icon size='large'  loading name='spinner' />
                  </div>

                  <div style={{textAlign: 'center'}}>
                    {this.state.swirlyText}
                  </div>

                </Modal.Content>
            </Modal>


            <Modal dimmer='blurring' size='tiny' centered className={modalClassName}  open={this.state.showYouWereAnnounced}
                            style={{backgroundColor: bshzColors.purple, borderRadius: '20px'}}>
                        <Modal.Header style={{textAlign: 'center', backgroundColor: bshzColors.purple, borderRadius: '20px'}}>
                            <span style={{color: bshzColors.yellow}}> Announcing: Your Site! </span>
                        </Modal.Header>
                <Modal.Content className={modalContentClassName} scrolling style={{backgroundColor: bshzColors.ltPurple}}>

                  <div style={{textAlign: 'center'}}>
                    Your site/domain/identity, <b style={{color:"blue", fontSize: "1.3rem"}}>{this.state.ourIdentity.toUpperCase()}</b>, has been announced to
                    the {this.shizzleVerse}. This puts you 'on the map', making it easier for
                    others to be aware of your presence.
                  </div>

                </Modal.Content>

                <Modal.Actions className={modalBottomClassName} style={{backgroundColor: bshzColors.purple, borderRadius: '0px 0px 20px 20px'}}>
                            <div style={{textAlign: 'center'}}>
                                <Button positive onClick={this.closeYouWereAnnounced} content='Got it'/>
                            </div>
                </Modal.Actions>
            </Modal>

            <Modal dimmer='blurring' size='tiny' centered className={modalClassName}  open={this.state.showNewDomains}
                            style={{backgroundColor: bshzColors.purple, borderRadius: '20px'}}>
                        <Modal.Header style={{textAlign: 'center', backgroundColor: bshzColors.purple, borderRadius: '20px'}}>
                            <span style={{color: bshzColors.yellow}}> Results of Scan </span>
                        </Modal.Header>
                <Modal.Content className={modalContentClassName} scrolling style={{backgroundColor: bshzColors.ltPurple}}>

                  <div style={{textAlign: 'center'}}>
                      We found <span style={{color:"blue", fontSize: "1.3rem"}}>{this.setOfNewestDomains.size}</span> new domains while scanning.
                  </div>
                    {newlyScannedDomainsMention}

                </Modal.Content>

                <Modal.Actions className={modalBottomClassName} style={{backgroundColor: bshzColors.purple, borderRadius: '0px 0px 20px 20px'}}>
                            <div style={{textAlign: 'center'}}>
                                <Button positive onClick={this.closeNewDomainsModal} content='Got it'/>
                            </div>
                </Modal.Actions>
            </Modal>

            <Modal dimmer='blurring' size='tiny' centered className={modalClassName}  open={this.state.commotionForNewDialog}
                            style={{backgroundColor: bshzColors.purple, borderRadius: '20px'}}>
                        <Modal.Header style={{textAlign: 'center', backgroundColor: bshzColors.purple, borderRadius: '20px'}}>
                            <span style={{color: bshzColors.yellow}}> We've Noticed a New DIALOGUE </span>
                        </Modal.Header>
                <Modal.Content className={modalContentClassName} scrolling style={{backgroundColor: bshzColors.ltPurple}}>

                    Take Note: you probably have not yet responded to this new dialogue {this.state.commotionText} which we've noticed.

                </Modal.Content>

                <Modal.Actions className={modalBottomClassName} style={{backgroundColor: bshzColors.purple, borderRadius: '0px 0px 20px 20px'}}>
                            <div style={{textAlign: 'center'}}>
                                <Button positive onClick={this.closeDialogCommotionModal} content='Got it'/>
                            </div>
                </Modal.Actions>
            </Modal>

            <Modal dimmer='blurring' size='tiny' centered className={modalClassName}  open={this.state.commotionForActiveDialog}
                            style={{backgroundColor: bshzColors.purple, borderRadius: '20px'}}>
                        <Modal.Header style={{textAlign: 'center', backgroundColor: bshzColors.purple, borderRadius: '20px'}}>
                            <span style={{color: bshzColors.yellow}}> We've Noticed an ACTIVE Dialog</span>
                        </Modal.Header>
                <Modal.Content className={modalContentClassName} scrolling style={{backgroundColor: bshzColors.ltPurple}}>

                    This new dialogue ({this.state.commotionText}) already has a post, so, we're AUTOMATICALLY assuming you've responded to it, but
                    that might not be true. The owner/creator of the Dialogue may have simply posted more than once - without
                    you having responded.
                    <br></br>
                    <br></br>
                    The bottom line is that this dialogue will now be marked as ACTIVE - whether you've responded to it or not.

                </Modal.Content>

                <Modal.Actions className={modalBottomClassName} style={{backgroundColor: bshzColors.purple, borderRadius: '0px 0px 20px 20px'}}>
                            <div style={{textAlign: 'center'}}>
                                <Button positive onClick={this.closeActiveDialogCommotionModal} content='Got it'/>
                            </div>
                </Modal.Actions>
            </Modal>

            </>
        )
    }
}

export default Surfer;