const {
	bsv,
} = require('scryptlib');

const {
    USE_BITAILS_FULLY,

    API_NETWORK
} = require('./harnessConstants');

const {
    printError,
} = require( './commonFuncs' );

/////////////
/* *
import {
	bsv,
	buildContractClass,
	getPreimage,
	toHex,
	num2bin,
	Bytes,
	signTx,
	PubKey,
	Sig,
	//int2Asm					//FIXME: not xported?
} from 'scryptlib'

import {
    //generatePrivKey,
    //privKeyToPubKey, privKeyToPubKey2,
    //sign,
    sign2,
    verify,
    simpleHash
} from "rabinsig";

import  {
    DEFAULT_FEE_PER_KB,
    FINAL_POST_FEE_PER_KB,
    TIP_TRANSIENT_FEE_PER_KB,
    BOUNTY_CLAIM_FEE_PER_KB,

    API_NETWORK
} from './harnessConstants'

import {
	int2Asm,
	oneByteLE,
	twoByteLE,
	fourByteLE,
	fiveByteLE,
	eightByteLE,
    hexByteToAscii,
    hexStringToAscii,
	swapEndian
} from './commonFuncs' ;
/** */

//const { showError } = require('./helper.js');

const axios = require("axios");
//const { mySleep } = require('./buildShizzle');  //FIXME: circular?

// Used to pace queries to WoC
function mySleep(ms, message) {
	if ( message.length !== 0 ) {
		console.log("Sleeping " + ms + " ms " + message)
	}
    return new Promise(resolve => setTimeout(resolve, ms))
}

const PRICE_API_PREFIX = 'https://api.blockchair.com/bitcoin-sv'
const PRICE_API_PREFIX_WOC = 'https://api.whatsonchain.com/v1/bsv/main/exchangerate'

const WOC_API_PREFIX = 'https://api.whatsonchain.com/v1/bsv/'; // + API_NETWORK;   3/19/24: removed network from prefix. We now use each function's 'network' param
//const BITAILS_SITE = 'https://test-api.bitails.net'    abandoned for .io
//const BITAILS_SITE = 'https://test-api.bitails.io'     we now piece it together within each provider function:   https:// + network + prefix
const BITAILS_API_PREFIX = 'api.bitails.io'


const USE_WOC = 0 //true

let initialized = false

//export
function initProviders() {
    if ( window.whichProvider ) {
        alert("ERROR in app. window.whichProvider should not yet have been already set. Initialized twice?")
        throw new Error("Invalid initial state for transaction provider")
    }

    // init with RANDOM provider (WoC, or Bitails)
    window.nextProvider = Math.random() > 0.5 ? 0 : 1    // 0: WoC   1: Bitails

    window.wocRequestCalledFrom = ''
    window.btRequestCalledFrom = ''

    window.wocRequestPending = false
    window.btRequestPending = false

    window.totalElapsedWoc = 0
    window.totalElapsedBt = 0
    window.timeSentWoc  = 0
    window.timeSentBt   = 0

    window.numWocReqs = 0
    window.numBtReqs = 0

    window.numWocSleeps = 0
    window.numBtSleeps = 0

    initialized = true
}

async function getProvider(calledFrom = "???") {

    // If we're running from console, just use WoC
    if ( !initialized && !window) {
        return USE_WOC
    }

    let nextProvider = window.nextProvider

    if ( nextProvider === 0 ) {

        let attempts = 1
        while ( window.wocRequestPending ) {
                    //FIXME: report on time since sent
                window.numWocSleeps++
                await mySleep(75, 'provider: (WoC request still pending) sleeping for 75ms...  attempts: ' + attempts)
                attempts++
                if ( attempts % 10 === 0 ) {
                    console.warn("WoC: We've SLEPT " + attempts + " times...  WoC request STILL pending - from " + window.wocRequestCalledFrom)
                }
        }

        window.wocRequestPending = true
        window.wocRequestCalledFrom = calledFrom
        window.nextProvider = 1
        //window.numWocReqs++
        console.log("provider: chose 0 (WoC). Called from " + calledFrom)

        window.timeSentWoc = Date.now()
        return 0

    } else if ( nextProvider === 1 ) {

        let attempts = 1
        while ( window.btRequestPending ) {
                        //FIXME: report on time since sent?
            window.numBtSleeps++
            await mySleep(75, 'provider: (Bitails request still pending) sleeping for 75ms...  attempts: ' + attempts)
            attempts++
            if ( attempts % 10 === 0 ) {
                console.warn("Bitails: We've SLEPT " + attempts + " times...  Bt request STILL pending - from " + window.btRequestCalledFrom)
            }
        }

        window.btRequestPending = true
        window.btRequestCalledFrom = calledFrom
        window.nextProvider = 0
        //window.numBtReqs++
        console.log("provider: chose 1 (bitails). Called from " + calledFrom)

        window.timeSentBt = Date.now()
        return 1

    } else {
        alert("ERROR: invalid next provider: " + nextProvider)
        throw new Error("invalid tx provider: " + nextProvider)
    }
}

function releaseProvider(which, calledFrom = "???", tallyThis = true) {
    if ( !initialized && !window )
        return

    console.log("provider: releasing " + which + ". Called from " + calledFrom)
    if ( which === 0 ) {
        if ( window.wocRequestPending ) {
            window.wocRequestCalledFrom = ''
            if ( tallyThis ) {
                window.totalElapsedWoc = window.totalElapsedWoc + (Date.now() - window.timeSentWoc)
                window.numWocReqs++
            }
            window.wocRequestPending = false
        } else {
            alert("release ERROR: " + which + " wasn't pending. Called from " + calledFrom)
            //throw new Error("CODING ERROR while releasing provider")
        }
    } else if ( which === 1 ) {
        if ( window.btRequestPending ) {
            window.btRequestCalledFrom = ''
            if ( tallyThis ) {
                window.totalElapsedBt = window.totalElapsedBt + (Date.now() - window.timeSentBt)
                window.numBtReqs++
            }
            window.btRequestPending = false
        } else {
            alert("release ERROR: " + which + " wasn't pending. Called from " + calledFrom)
            //throw new Error("CODING ERROR while releasing provider")
        }
    } else {
        alert("ERROR: release: invalid provider released: " + which + ". Called from " + calledFrom)
        //throw new Error("invalid provider release: " + which)
    }

    // report stats every 10 requests
    if ( (window.numWocReqs + window.numBtReqs) % 2 === 0 ) {
        console.warn("Provider requests tally:  WoC: " + window.numWocReqs + "   Bitails: " + window.numBtReqs
                    + "\n  numWocSleep: " + window.numWocSleeps + "   numBtSleeps: " + window.numBtSleeps)
        console.warn("========> Avg provider request duration:   WoC: " + Math.round(window.totalElapsedWoc / window.numWocReqs) +
                    "ms in " + window.numWocReqs + " reqs.   Avg elapsed Bt: " +
                    Math.round(window.totalElapsedBt / window.numBtReqs) + "ms in " + window.numBtReqs + " reqs")
    }
}

//export /* */
async function getCurrBlockInfo(network = 'test') {

    const useWoC = (await getProvider("getCurrBlockInfo")) === 0 //USE_WOC
    const bitails_network_prefix = network === 'test' ? 'test-' : ''

    try {
        let blockHeight
        if ( useWoC ) {
            // https://developers.whatsonchain.com/#get-raw-transaction-data
            let { data: raw }  = await axios.default.get(`${WOC_API_PREFIX}/${network}/chain/info`);
            blockHeight = raw.blocks

            console.warn(" NOTE: WoC reports block height " + blockHeight + " <-----")
        } else {
            //alert("USING bitails for getCurrBlockInfo? useWoc: " + useWoC)
            // https://test-api.bitails.io/network/info
            let { data: raw }  = await axios.default.get(`https://${bitails_network_prefix}${BITAILS_API_PREFIX}/network/info`);
            blockHeight = raw.blocks

            console.warn(" NOTE: Bitails reports block height " + blockHeight + " <-----")
        }

        releaseProvider( useWoC ? 0 : 1, "getCurrBlockInfo" )

        // return the block height
        return blockHeight;
    } catch (error) {
        releaseProvider( useWoC ? 0 : 1, "getCurrBlockInfo ERROR ")
        console.error("getCurrBlockInfo failed. caught it, released provider. re-throwing.")
        throw error
    }
};

async function getPriceInfo() {

    const useWoC = true

    if ( !useWoC ) {

        // https://developers.whatsonchain.com/#get-raw-transaction-data
        let { data: raw }  = await axios.default.get(`${PRICE_API_PREFIX}/stats`);

        //console.log("BlockChair currency stats: ", raw)

        // .data.market_price_usd
        if ( raw.data && raw.data.market_price_usd ) {
            return raw.data.market_price_usd
        } else {
            console.error("BlockChair /stats response: ", raw)
        }
    } else {

        //https://api.whatsonchain.com/v1/bsv/<network>/exchangerate
        let { data: raw2 }  = await axios.default.get(`${PRICE_API_PREFIX_WOC}`);

        //console.log("WoC currency stats: ", raw2)

        // { currency: USD,  rate: 50.000 }
        if ( raw2.currency && raw2.currency === "USD" && raw2.rate ) {
            return parseFloat(raw2.rate).toFixed(2)
        } else {
            console.error("WoC /exchangerate response: ", raw2)
        }
    }

    throw new Error("BSV price (USD) not found in response")
};

//export /* */
async function getRawInfo( txid ) {
    try {
        console.log('\ngetRawInfo(): Getting info on txid ', txid);
        const rawTx = await getRawTx(txid);
        //console.log('Raw hex: ', rawTx);
        return rawTx;
    } catch (error) {
        console.log('Failed in getRawInfo (instead of re-throwing, returning response status, else null)')
        printError(error)
        //alert("Failed retrieving raw info for transaction " + txid)

        // It could be helpful to know if it's a 404, or whatever
        if ( error.response ) {
            return error.response.status;
        }
        return null
    }
}

/**
 * For funding transactions
 * @param {*} address
 * @returns
 */
//export /* */
async function fetchUtxos(address, forceWoC = true, forceQuery = false, calledFrom = "???", network = 'test') {
    const bitails_network_prefix = network === 'test' ? 'test-' : ''
    //console.log("fetching UTXOs for address " + address + "...");

    if ( calledFrom === "???" ) {
        console.error("CODE ERROR: likely that provider function called without care. Network: " + network)
        alert("CODE ERROR: likely that provider function called without care. Network: " + network)
        throw new Error("CODE ERROR likely in fetchUtxos(). network: " + network)
    }

    if ( !forceQuery ) {
        if ( window.localUTXOs.length !== 0 ) {
            console.log("fetchUtxos(): localUTXOs ALREADY has values: ", window.localUTXOs)

            // copy entire array
            const utxos = window.localUTXOs.slice()

            console.warn("fetchUtxos() will use cached values - WON'T query providers")

            return utxos

        } else {
            if ( calledFrom !== "app's getOfficialWalletRecord" && calledFrom !== "scanUTXOs" ) {
                console.error("fetchUtxos() called from " + calledFrom)
                alert("we're in fetchUtxos(), there are no UTXOs, but we weren't called from the APP. Something is wrong.")
                throw new Error("CODE ERROR in fetchUtxos() - can't find local cached, but we need it (!forceQuery)")
            }
            console.warn("fetchUtxos() ACTUALLY querying providers because CAN'T FIND local cached. Should be the ONLY time (mostly) for now")
        }
    } else {
        console.error("fetchUtxos(): We've been asked to really query - by calling function '" + calledFrom + "'()")
        if (    calledFrom !== 'scanUTXOs' &&
                calledFrom !== 'handleRetrieveBsvKeys' &&
                calledFrom !== 'handleBitcoinPrivKeyChange' &&
                calledFrom !== "app's getOfficialWalletRecord" &&
                calledFrom !== 'payoutChosen'   // has NOTHING to do we standard cached UTXOs
                && calledFrom !== 'get miner incoming payments'
            ) {

            console.error("fetchUtxos() called from " + calledFrom)
            alert("we're in fetchUtxos(), we're FORCED to query, but we weren't called from scanUTXOs(). Something is wrong.")
            throw new Error("CODE ERROR in fetchUtxos() - forced to query by the wrong function: " + calledFrom)
        }
    }

    console.warn("fetchUtxos() ACTUAL QUERY of providers - called from " + calledFrom + ",   forceQuery? " + forceQuery)

    if (forceWoC) {
        console.warn("fetchUtxos(): FORCING WoC")
    }

    // if we're avoiding Bitails, no need to loop
    let maxTries = USE_BITAILS_FULLY ? 2 : 1

    //NEW: try BOTH providers, if necessary OR requested
    let successCount = 0
    for ( let i = 0; i < maxTries; i++ ) {

        let useWoC = (await getProvider("fetchUtxos " + address)) === 0 //USE_WOC

        // don't allocate if forcing, or !USE_BITAILS_FULLY
        while ( !useWoC && (!USE_BITAILS_FULLY || forceWoC) ) {
            console.error("fetchUtxos() had allocated Bitails, but, we're NOT using them for THIS function. Will RELEASE, then continue...")
            releaseProvider( 1, "fetchUtxos because don't want Bitails " + address, false)

            await mySleep(200, 'fetchUtxos() quick sleep before trying a new provider')
            useWoC = (await getProvider("fetchUtxos " + address)) === 0
        }

        try {
            let utxoArray
            if ( useWoC ) {
                console.log("querying WoC for utxos...")

                // test with:   curl "https://api.whatsonchain.com/v1/bsv/test/address/moM78sM4JD6LP69yukvdu435Dxn4UfsJbt/unspent"
                let { data: utxos } = await axios.default.get(`${WOC_API_PREFIX}/${network}/address/${address}/unspent`);
                utxoArray = utxos.map((utxo) => ({
                    txId: utxo.tx_hash,
                    outputIndex: utxo.tx_pos,
                    satoshis: utxo.value,
                    script: bsv.Script.buildPublicKeyHashOut(address).toHex(),
                    //FROZEN: false,
                }));
            } else {
                console.log("querying Bitails for utxos...")

                // test with:   curl "https://test-api.bitails.io/address/moM78sM4JD6LP69yukvdu435Dxn4UfsJbt/unspent"
                let { data: utxos } = await axios.default.get(`https://${bitails_network_prefix}${BITAILS_API_PREFIX}/address/${address}/unspent`);

                //console.warn(" DATA DATA DATA Bitails.unspent[]: ", utxos.unspent)
                utxoArray = utxos.unspent.map((utxo) => ({
                    txId: utxo.txid,
                    outputIndex: utxo.vout,
                    satoshis: utxo.satoshis,
                    script: bsv.Script.buildPublicKeyHashOut(address).toHex(),
                    //FROZEN: false,
                }));
            }

            releaseProvider( useWoC ? 0 : 1, "fetchUtxos " + address )

            //console.warn(" got, and mapped utxos' fields : ", utxoArray)

            successCount++

            return utxoArray;

        } catch (error) {
            releaseProvider( useWoC ? 0 : 1, "fetchUtxos ERROR for address " + address )
            console.warn("ERROR caught while useWoc " + useWoC + ": ", error.toString())

            //alert("contractSupport: temp - fetchUtxos()  failed. useWoC: " + useWoC + ".  error: " + error.toString())

            if ( error.toString().includes("Network Error") ) {
                //alert("We've experienced a Network Error. Please check your connection")

                //FIXME: this let's the user know WHY things aren't working (no network connection).
                //       They will still need to refresh once the issue clears.
                //       IOW: we don't currently handle most network errors cleanly.
                window.theApp.handleNetworkError()   // slide-out a modal
            }
        }
    }

    if ( successCount < 1 ) {
        alert("PROBLEM: NEITHER provider returned UTXOs. ABORTING")
        return []
        throw new Error("Neither provider returned any UTXOs")
    } else {
        alert("CODE ERROR: Should have returned by now")
        throw new Error("Code error in funding fetch")
    }
};  // fetchUtxos()

// same as helper's sendTx, but already serialized
async function sendSerializeTx(txhex,
                        spentUTXOs = [{hmm: '???'}],
                        newUtxoOutIdx = -1,
                        changeSats = 0,
                        changePKH = '????',
                        network = 'test') {

    const bitails_network_prefix = network === 'test' ? 'test-' : ''
    //console.log("sendSerializeTx: sending hex string of length: ", txhex.length)

    if ( spentUTXOs[0]?.hmm === '???' || changePKH === '????' ) {
        alert("NOTE: sendSerializeTx() isn't managing UTXOs for calls from this (check console for where)")
        throw new Error("CODE ERROR: not all calls to sendSerializeTx() pass a spentUTXOs array.")
    }

    console.warn("SEND SERIALIZE: spentUTXOs: ", spentUTXOs)
    console.warn("  newUtxoOutIdx: ", newUtxoOutIdx)
    console.warn("  sats change: ", changeSats)

    let txIdToReturn
    let successes = 0
    let errorToThrow
    for ( let i = 0; i < 2; i++ ) {
        const useWoC = (await getProvider("sendSerializeTx " + txhex.substring(100, 108))) === 0 //USE_WOC

        console.warn("sendSerializeTx() attempt " + (i+1) + " of 2...   useWoC: " + useWoC)

        try {
            if ( useWoC ) {

                const { data: txid } = await axios.default.post(`${WOC_API_PREFIX}/${network}/tx/raw`, {
                    txhex,
                });

                console.log("RETURNED from WoC. txid: ", txid)

                releaseProvider( 0, "sendSerializeTx " + txhex.substring(100, 108) )

                txIdToReturn = txid
                successes++
            } else {

                const raw = txhex

                // Bitails
                const { data: responseData } = await axios.default.post(`https://${bitails_network_prefix}${BITAILS_API_PREFIX}/tx/broadcast`, {
                    raw,
                });

                //NOTE: Bitails request will NOT throw an error. Error is embedded in response

                console.log("RETURNED from Bitails. responseData: ", responseData)

                if ( responseData.error ) {
                    console.error("Bitails reports error (will throw an error to keep consistent behavior with WoC): ", responseData.error)
                    //NOTE: Bitails behavior (reporting an error) is different from WoC behavior - which throws when broadcast fails
                    //      We throw an error here to have consistent behavior with other tx provider - WoC
                    throw new Error(responseData.error?.message)
                }
                if ( !responseData.txid ) {
                    console.error("BTW: there was no txid returned by Bitails")
                    throw new Error("Bitails response missing txId")
                } else {
                    console.error("BTW: Bitails returned txid " + responseData.txid)
                }

                releaseProvider( 1, "sendSerializeTx " + txhex.substring(100, 108) )

                txIdToReturn = responseData.txid
                successes++
            }
        } catch (error) {
            //NOTE: Bitails path explicity throws, above, when we see Bitails reported an error
            //      WoC broadcast, however, results in an error thrown implicitly (for a malformed tx)

            releaseProvider( useWoC ? 0 : 1, "sendSerializeTx ERROR " + txhex.substring(100, 108) )
            console.warn("ERROR caught: ", error)
            //console.warn("ERROR .code: ", error.code)
            console.warn("ERROR .message: ", error.message)
            console.warn("ERROR.toString(): ", error.toString())
            console.error("BROADCAST failed. caught it, released provider. provider: " + useWoC)

            console.error("contractSupport: sendSerializeTx() [ Try " + (i+1) + " of 2 ]  Broadcast failed. useWoC: " + useWoC + ".  error: " + error.toString() + "\n")

            // NOTE: only Bitails appearts to pass along the error type/message
            // 258 txn-mempool-conflict
            const lowerCaseError = error?.message?.toLowerCase()
            if ( lowerCaseError.includes("conflict") ) {
                alert("We've detected a mempool conflict. Maybe we built this Tx with a stale (spent) UTXO")

                alert("Add (mempool conflict-resolving) logic to understand this, and get a DIFFERENT UTXO to use - from another provider")

                console.error("sendSerializeTx(): mempool conflict error")

                // If either attempt results in mempool conflict, NEITHER can succeed.
                // The network has spoken.
                throw new Error("mempool conflict")
            }

            errorToThrow = new Error(error)
        }
    } // loop over BOTH providers

    if ( successes === 0 ) {
        console.error("IMPLEMENT ME: rather than throw, we should just inform user that this post failed")

        alert("PROBLEM: BOTH providers failed to broadcast. ABORTING")
        throw errorToThrow
    }

    console.log("sendSerializeTx(): NOTE: num successes: " + successes)

    // an out index of -1 means there is NO new UTXO (change was zero)
    const newUTXO = newUtxoOutIdx === -1 ?
                null
            :
                {   txId: txIdToReturn,
                    outputIndex: newUtxoOutIdx,
                    satoshis: changeSats,
                    script: '76a914' + changePKH + '88ac',  // WoC calls this the 'scriptPublicKey'. Bitails calls it the 'script'
                    FROZEN: false
                }

    if ( successes > 0 ) {
        console.log("sendSerializeTx(): BROADCAST SUCCESS. txId: ", txIdToReturn)

        console.log("sendSerializeTx():      SPENT utxo(s) []: ", spentUTXOs)
        console.log("sendSerializeTx():              NEW utxo: ", newUTXO)

        // helps avoid constantly querying providers for info we should already have
        manageUTXOs(spentUTXOs, newUTXO)

        console.log("Hooray! At least 1 provider ACCEPTED the spend. We SHOULDN'T have a problem spending from it.")
    }

    return txIdToReturn

};  // sendSerializeTx()

/**
 * Maintains a LOCAL copy of funding UTXOs.
 * Avoids constantly querying providers for info that we should be
 * able to manage ourselves.
 *
 * EXCEPT if there are spends going on behind our back (perhaps in a different browser/tab?)
 * THAT would play havoc with our utxo management
 *
 * @param {*} spentUTXOs - to be removed from local cache
 * @param {*} newUTXO - to be added to local cache
 */
function manageUTXOs(spentUTXOs, newUTXO) {

    console.warn("manageUTXOs(): here's our global utxo object: ", window.localUTXOs)
    console.warn("manageUTXOs(): here's our SPENT utxo object: ", spentUTXOs)

    for ( const spent of spentUTXOs ) {
        let found = false
        console.log("Let's see if we spent this utxo: " + spent.outputIndex + ":" + spent.txId.slice(0, 10) + "...")

        for ( let i = 0; i < window.localUTXOs.length; i++ ) {
            const old = window.localUTXOs[i]
            console.log("    comparing against OLD: "+ old.outputIndex + ":" + old.txId.slice(0, 10) + "...")

            if ( spent.txId === old.txId ) {
                console.warn("    We found a TxId match for spent utxo with txId " + spent.txId.slice(0, 10) + "...")
                if ( spent.outputIndex === old.outputIndex) {
                    console.warn("      Great: it ALSO matches on output index " + old.outputIndex + ". Will REMOVE THIS UTXO!!")

                    // remove this single utxo
                    window.localUTXOs.splice(i, 1)
                    found = true
                    break
                } else {
                    console.log("      Whoops: it has the wrong outputIndex...")
                }
            }
        }
        if ( !found ) {
            console.error("WHAT?! didn't find this spent within localUTXOs: ", spent)
            alert("ERROR: We may have a local UTXO-management problem.\n"
                + "Do you have your 'account' open in more than one browser/tab/phone?\n"
                + "You should only run this in ONE place at a time. Thank you."
            )

            throw new Error("CODE ERROR: problem managing funding UTXOs. Please report this.")
        }
    } // each spent utxo

    if ( newUTXO !== null ) {
        console.log("adding new UTXO: ", newUTXO)
        window.localUTXOs.push(newUTXO)
    }
    console.warn("manageUTXOs(): we're done managing UTXOs: ", window.localUTXOs)
}

/**
 * Send to Tx-Provider an array of script hashes
 * Expect back status of spent/unspent
 * ALTERNATIVE (Bitails): query a range of outputs for a given tx, and note the spent/unspent status of the outputs
 *                        mimic the result format from provider WoC
 * @param {*} bulkScripts
 * @returns spent/unspent status of each scripthash
 */
//export /* */
//                                usedInWoCQuery       usedInBitailsQuery
async function getBulkUnspentScripts(bulkScripts, txid, mapsToTxoIndex, forceWoC = false, network = 'test') {

    const bitails_network_prefix = network === 'test' ? 'test-' : ''
    let useWoC = (await getProvider("getBulkUnspentScripts " + txid)) === 0 //USE_WOC

    // don't allocate if forcing, or !USE_BITAILS_FULLY
    while ( !useWoC && (!USE_BITAILS_FULLY || forceWoC) ) {
        console.error("getBulkUnspentScripts() had allocated Bitails, but, we're NOT using them for THIS function. Will RELEASE, then continue...")
        releaseProvider( 1, "getBulkUspentScripts because don't want Bitails " + txid, false)

        await mySleep(200, 'getBulkUnspentScripts() quick sleep before trying a new provider')
        useWoC = (await getProvider("getBulkUnspentScripts " + txid)) === 0
    }

    try {
        if ( useWoC ) {
            const {
                data: raw
            } = await axios.post(`${WOC_API_PREFIX}/${network}/scripts/unspent`, {
                // array of script hashes
                scripts: bulkScripts
            })
            releaseProvider( useWoC /*|| forceWoC*/ ? 0 : 1, "getBulkUnspentScripts " + txid )

            console.log("getBulkUnspentScripts(): result :  ", raw)

            return raw
        } else {

            // ALTERNATIVE query - Bitails of the tx itself

            //NOTE: we may be querying for MORE outputs than were asked

            const numIdxs = mapsToTxoIndex.length
            const firstOutputIdx = mapsToTxoIndex[0]
            const finalOutputIdx = mapsToTxoIndex[ numIdxs - 1 ]
            if ( numIdxs !== (finalOutputIdx - firstOutputIdx + 1)) {
                console.warn("getBulkUnspentScripts(): note: we're Bitails-querying for more outputs than with WoC: " + numIdxs + " <-----")
            }

            let { data: raw2 } = await axios.get(`https://${bitails_network_prefix}${BITAILS_API_PREFIX}/tx/${txid}/outputs/${firstOutputIdx}/${finalOutputIdx}`);
            releaseProvider( useWoC ? 0 : 1, "getBulkUnspentScripts " + txid )

            console.log("getBulkUnspentScripts(): raw response from Bitails for txid " + txid)
            console.log("getBulkUnspentScripts(): Bitails sent info on " + raw2.length + " outputs")
            console.log("getBulkUnspentScripts(): raw2: ", raw2)
            let j = 0
            let results = []
            for ( let i = 0; i < raw2.length; i++ ) {
                //console.log("result " + i + ":   for base-0 output " + raw2[i].index)
                if ( mapsToTxoIndex[j] === raw2[i].index ) {
                    //console.log("  We care about this result. j-index is " + j + ".  btW: spent: " + raw2[i].spent)

                    // mimic results from WoC
                    if ( raw2[i].spent ) {
                        results.push({script: bulkScripts[j], error: '', unspent: []})
                    } else {
                        results.push({script: bulkScripts[j], error: '', unspent: [{ tx_pos: raw2[i].index, tx_hash: txid, value: raw2[i].satoshis }]})
                    }

                    //console.warn("BTW: bulkScripts[" + j + "] is " + bulkScripts[j])
                    j++
                } else {
                    //console.log("  (we don't really care about this result)")
                }
                //console.log("      raw2[" + i + "].spent: " + raw2[i].spent + "  index: " + raw2[i].index + "   satoshis: " + raw2[i].satoshis)
            }
            console.log("getBulkUnspentScripts(): we care about " + j + " outputs. mapsToTxoIndex has  " + mapsToTxoIndex.length + " entries")

            console.warn("getBulkUnspentScripts(): SO, here is the alternative result: ", results)

            return results
        }
    } catch (error) {
        releaseProvider( useWoC ? 0 : 1, "getBulkUnspentScripts " + txid)
        console.error("getBulkUnspentScripts failed. caught it, released provider. re-throwing: ", error)
        throw error
    }
}

async function sendDustyTx(tx, network = 'test') {
    const {
        data: txid
    } = await axios.post(`${WOC_API_PREFIX}/${network}/tx/raw`, {
        // when serializing, disable check for dusty outputs. The miners now accept 135 sats,
        // but the bsv library thinks that's dusty
        txhex: tx.serialize({disableDustOutputs: true})
    })
    return txid
}

//FIXME: copied from scrypttest
async function getRawTx(txid, network = 'test') {

    const bitails_network_prefix = network === 'test' ? 'test-' : ''
    let useWoC = (await getProvider("getRawTx " + txid)) === 0 //USE_WOC

    //NEW: try BOTH providers, if necessary
    for ( let i = 0; i < 2; i++ ) {

        let rawAsciiHex
        try {
            if ( useWoC ) {
                // https://developers.whatsonchain.com/#get-raw-transaction-data
                const { data: raw }  = await axios.default.get(`${WOC_API_PREFIX}/${network}/tx/${txid}/hex`);
                //
                // curl --location --request GET  "https://api.whatsonchain.com/v1/bsv/test/tx/7318ad01dd464dc7dbe96e6f462726fdaef4f4d2a222cfe4c722487dbf0e946e/hex"

                rawAsciiHex = raw
            } else {
                // https://docs.bitails.io/#download-transaction
                // https://api.bitails.io/download/tx/{txid}

                const { data: rawBinary } = await axios.default.get(`https://${bitails_network_prefix}${BITAILS_API_PREFIX}/download/tx/${txid}`,
                                                                {
                                                                    responseType: 'arraybuffer',
                                                                    headers: {
                                                                        'Content-Type': "application/octet-stream"
                                                                    }
                                                                }
                                                            );
                //
                // curl "https://test-api.bitails.io/download/tx/{txid}"
                // curl "https://test-api.bitails.io/download/tx/7318ad01dd464dc7dbe96e6f462726fdaef4f4d2a222cfe4c722487dbf0e946e"
                // curl "https://test-api.bitails.io/download/tx/4004cb72ace416ea2f29dffa730ba54b9956d0cd220f36f70680a952cecd5447"

                //console.log("RESPONSE: ", response)
                rawAsciiHex = Buffer.from( new Uint8Array(rawBinary) ).toString("hex")
            }
            releaseProvider( useWoC ? 0 : 1, "getRawTx " + txid )

            console.log("Got " + rawAsciiHex.length/2 + " bytes")

            return rawAsciiHex;
        } catch (error) {
            releaseProvider( useWoC ? 0 : 1, "getRawTx " + txid)
            console.error("getRawTx: error: ", error)
            console.error("getRawTx failed. caught it, released provider. re-throwing.")

            if ( i === 1 ) {
                alert("PROBLEM: BOTH providers failed to get a raw tx. ABORTING")
                throw error
            }

            console.error("Will RE-TRY getRawTx() - hopefully with a different provider")
            await mySleep(200, 'getRawTx() sleeping for 200ms...  before getting a new provider')

            const newUseWoC = (await getProvider("getRawTx " + txid)) === 0
            if ( newUseWoC === useWoC ) {
                console.error("Whoops. we got the SAME provider again! Will release, and grab once more...")

                releaseProvider( useWoC ? 0 : 1, "getRawTx " + txid)
                await mySleep(120, 'getRawTx() sleeping for 120ms...  before getting a new provider')

                console.log("let's now get yet another  new provider...")
                newUseWoC = (await getProvider("getRawTx " + txid)) === 0
                console.log("Ok. we got YET another provider")
                //alert("NOTE: we had to get a new provider TWICE. Hit return to continue...")
            }
            useWoC = newUseWoC
        }
    }
}  // getRawTx()

async function sendTxYield(txhex, network = 'test') {
    const { data: txid } = await axios.default.post(`${WOC_API_PREFIX}/${network}/tx/raw`, {
        txhex,
    });
    return txid;
};

//FIXME: where's this from?
//export /* */
async function sendLockingTx(theLockingScript, privKey, amountInContract, dataFeeRateDivider = 20) {
    const fundingAddress = privKey.toAddress()

    //  FUNDING now accomplished using:   new bsv.Transaction().from( utxos ) below
    //  But we need the CHANGE address/script
    //
    // derive PKH from Private Key
    const fundingPubKey = bsv.PublicKey.fromPrivateKey(privKey).toBuffer();
    const fundingPKH = bsv.crypto.Hash.sha256ripemd160(fundingPubKey);

    const fundingPKHstr = fundingPKH.toString('hex');

    const utxos = await fetchUtxos( fundingAddress, false, false, "sendLockingTx" );

    if ( !utxos ) {
        console.error("sendLockingTx: got back no UTXOs. NETWORK ERROR? Will throw...")

        throw new Error("No UTXOs provided by provider. Empty wallet?")
    }

    let tx
    let approxFee
    let change = 0
    const manuallySetChange = true
    const satsPerKB = Math.round( 1000 / dataFeeRateDivider )
    let spentUTXOs
    if ( manuallySetChange ) {
        console.log("theLockingScript.chunks: ", theLockingScript.chunks)
        //console.log("script.chunks[3]: ", theLockingScript.chunks[3])
        //console.log("script.chunks[3].buf: ", theLockingScript.chunks[3].buf)
        console.log("script.chunks[3].len: ", theLockingScript.chunks[3].len)

        approxFee = Math.round( (theLockingScript.chunks[3].len) / dataFeeRateDivider ) + 22 // add 22 sats to cover incidental bytes
        console.log("approxFee in sats (" + satsPerKB + " sats/K): " + approxFee)   // now 40sats/K (divide by 25). 50sats/K worked (divide by 20)

        let bestFund = -1
        let bestIndex = null
        utxos.forEach ( (utxo, index, arr) => {
            console.log("This utxo.satoshis looks like this: ", utxo.satoshis)
            if ( utxo.satoshis > approxFee ) {
                console.log("THIS utxo (index of " + index + ") seems fine. has " + utxo.satoshis)
                if ( bestFund === -1 ) {
                    console.log("USING IT")
                    bestFund = utxo.satoshis
                    bestIndex = index
                } else if ( utxo.satoshis < bestFund ) {
                    console.log("AND it's better than (less than) " + bestFund)
                    bestFund = utxo.satoshis
                    bestIndex = index
                }
            }
        });
        if ( bestFund === -1 ) {
            console.warn("We didn't find a good SINGLE utxo. May need multiple :/")
            alert("We need to use multiple input UTXOs to reach the required fee. Let's see how that goes...")
            //throw new Error("Can't find good SINGLE UTXO")

            let totalFunds = 0
            let newSet = []
            utxos.forEach ( (utxo, index, arr) => {
                console.log("THIS utxo.satoshis looks like this: ", utxo.satoshis)
                if ( totalFunds < approxFee ) {
                    console.log("THIS utxo (index of " + index + ") seems fine. has " + utxo.satoshis)
                    totalFunds += utxo.satoshis
                    newSet.push( utxo )

                    console.warn(" added utxo. new totalFunds is " + totalFunds)
                } else {
                    console.warn("Not needed. we got there")
                }
            });
            if ( totalFunds < approxFee ) {
                alert("We don't have enought funds. Will need to SOLICIT more...  IMPLEMENT ME.")
                throw new Error("Can't find enough funds")
            }

            spentUTXOs = newSet.slice()
            tx = new bsv.Transaction().from( newSet )

            change = totalFunds - approxFee
        } else {
            console.warn("Got bestFund " + bestFund)
            console.warn("best index: ", bestIndex)
            console.warn("Got best utxo ", [ utxos[bestIndex] ])

            spentUTXOs = [ utxos[bestIndex] ]
            tx = new bsv.Transaction().from( [ utxos[bestIndex] ] )

            change = bestFund - approxFee
        }

        console.warn("ACTUAL CHANGE: " + change + " sats")

    } else {
        spentUTXOs = utxos.slice()
        // option 2: build the tx from EVERY utxo
        tx = new bsv.Transaction().from(utxos)

    }

    //FIXME: move tx build back to here. redefine utxos up there


    tx.addOutput(new bsv.Transaction.Output({
      script: new bsv.Script( theLockingScript ),  // call to new bsv.Script() was important when moving to browser
      satoshis: amountInContract,
    }))
	//tx.outputs[0].setScript( theLockingScript ); //This also works - built off placeholder new bsv.Script()

    let changeOutIndex = -1
    if ( change > 0 ) {
        changeOutIndex = 1
        const changeScriptPubKey = bsv.Script.fromASM("OP_DUP OP_HASH160 " + fundingPKHstr + " OP_EQUALVERIFY OP_CHECKSIG");
        tx.addOutput(new bsv.Transaction.Output({
            script: changeScriptPubKey,
            satoshis: change,
        }));
    }

    //console.log("tx before signing: ", tx)

    tx.sign(privKey)

    console.log("about to send signed tx: ", tx)

    const serialized = tx.serialize({disableDustOutputs: true})
    console.log("serialized: ", serialized)


    const lockingTxId = await sendSerializeTx(  serialized,
                                                spentUTXOs,
                                                changeOutIndex,
                                                change,
                                                fundingPKHstr);
    //const lockingTxId = await sendDustyTx(tx)

    console.log("sendLockingTx(): sent txid " + lockingTxId)

    return lockingTxId
};

// NOT USED
// called by checkForExpectedUTXO() - which isn't used
//export /* */
async function getUnspentScript (scriptHash, network = 'test') {
    // https://developers.whatsonchain.com/#get-script-unspent-transactions
	//console.log('querying API at ' + `${WOC_API_PREFIX}/${network}/script/${scriptHash}/unspent`)
	let { data: utxos }  = await axios.default.get(`${WOC_API_PREFIX}/${network}/script/${scriptHash}/unspent`);

	utxos = utxos.map((utxo) => ({
		txId: utxo.tx_hash,
		outputIndex: utxo.tx_pos,
		satoshis: utxo.value,
		height: utxo.height
	}));

    return utxos;
};

/**
 * WARNING: if using provider Bitails, the blockHeight for a given entry will be 1
 * ALSO:    if tx was submitted via one provider, it's possible the other provider hasn't yet heard of it.
 *
 * @param {*} scriptHash
 * @returns
 */
//export /* */
async function getSpentScript(scriptHash, weBelieveItShouldExist, network = 'test') {

    const bitails_network_prefix = network === 'test' ? 'test-' : ''

    // if we're avoiding Bitails, no need to loop
    let maxTries = USE_BITAILS_FULLY ? 2 : 1

    // in general, if we try twice, we'll get different providers
    // in practice, there's a race with background runner
    for ( let i = 0; i < maxTries; i++ ) {
        let useWoC = (await getProvider("getSpentScript " + scriptHash)) === 0 //USE_WOC

        // don't allocate if forcing, or !USE_BITAILS_FULLY
        while ( !useWoC && !USE_BITAILS_FULLY ) {
            console.error("getSpentScript() had allocated Bitails, but, we're NOT using them for THIS function. Will RELEASE, then continue...")
            releaseProvider( 1, "getSpentScript because don't want Bitails " + scriptHash, false)

            await mySleep(200, 'getSpentScript() quick sleep before trying a new provider')
            useWoC = (await getProvider("getSpentScript " + scriptHash)) === 0
        }

        try {
            let mappedTxos = []
            if ( useWoC ) {
                //console.log('querying API at ' + `${WOC_API_PREFIX}/${network}/script/${scriptHash}/history`)

                let { data: txos }  = await axios.default.get(`${WOC_API_PREFIX}/${network}/script/${scriptHash}/history`);
                //
                // curl "https://api.whatsonchain.com/v1/bsv/test/script/ef1d6a40b2f5cb05ac9c58a5f09e426fb8682232bbc5c214fe542bf67d15793e/history"
                // curl "https://api.whatsonchain.com/v1/bsv/test/script/190d82fdaf446b1a51d88be2b02393d5d583cf3f278f4bceb1560a2e52635683/history"
                //     prettify:   | node -p "JSON.stringify( JSON.parse(require('fs').readFileSync(0) ), 0, 1 )"
                console.warn("getSpentScript(): raw WoC txos:      ", txos)

                if ( txos && txos !== null ) {
                    mappedTxos = txos.map((txo) => ({
                        txId: txo.tx_hash,
                        blockHeight: txo.height
                    }));
                }
            } else {
                let { data: txos2 } = await axios.default.get(`https://${bitails_network_prefix}${BITAILS_API_PREFIX}/scripthash/${scriptHash}/history`);
                //
                // curl "https://test-api.bitails.io/scripthash/ef1d6a40b2f5cb05ac9c58a5f09e426fb8682232bbc5c214fe542bf67d15793e/history"
                // curl "https://test-api.bitails.io/scripthash/190d82fdaf446b1a51d88be2b02393d5d583cf3f278f4bceb1560a2e52635683/history"

                console.warn("getSpentScript(): raw Bitails txos: ", txos2)

                if ( txos2 && txos2 !== null ) {
                    mappedTxos = txos2.history.map((txo) => ({
                        txId: txo.txid,
                        blockHeight: 1        // <----- We're using a bogus value for Bitails
                    }));
                }
            }

            releaseProvider( useWoC ? 0 : 1, "getSpentScript " + scriptHash )

            console.warn("getSpentScript(): mapped txos: ", mappedTxos)

            return mappedTxos;
        } catch (error) {
            releaseProvider( useWoC ? 0 : 1, "getSpentScript " + scriptHash)
            console.error("getSpentScript failed. caught it, released provider. useWoC: " + useWoC + ". NOT re-throwing.")

            if ( error.toString().includes("Network Error") ) {
                //alert("We've experienced a Network Error. Please check your connection")

                //FIXME: this let's the user know WHY things aren't working (no network connection).
                //       They will still need to refresh once the issue clears.
                //       IOW: we don't currently handle most network errors cleanly.
                window.theApp.handleNetworkError()   // slide-out a modal

            } else {
                console.error("contractSupport: temp - getSpentScript()  Query failed. useWoC: " + useWoC + ".  error: " + error.toString())

                if ( i === 1 ) {
                    alert("PROBLEM: BOTH providers failed to get a spent script. ABORTING")
                    throw error
                }
            }

        }
    }

    alert("PROBLEM: failed to get a spent script. Throwing...")
    throw new Error("failed to get spent script info for " + scriptHash )
};  // getSpentScript()

// node utility needs this, but abhors function export
/* */
//export {
module.exports = {
        fetchUtxos,
            sendSerializeTx,

        initProviders,

        getPriceInfo,
        getCurrBlockInfo,
            getBulkUnspentScripts,
        getRawInfo,

        sendLockingTx,
        getUnspentScript,
        getSpentScript,
}
/* */