// linkJumper.js

// component to travel/jump to and display a particular tx/post - shared by a friend


import {Component} from 'react';

import { Button, Checkbox, Icon, Loader, Modal, Popup } from 'semantic-ui-react'

import { execAsync, hexByteToAscii, hexStringToAscii } from './commonFuncs.js';

import { justOpenDB, findNextTxFromProvider } from './buildShizzle.js';

import { decomposeRawTx, parseInputs, decodeScriptsToState, getUsefulInputParams } from './shizzleBuilder.js';

import { maybeDecryptContent }  from './contentFuncs.js';

import { getRawInfo } from './providers.js';

import { IDB_NAME, GENESIS_APPEND } from './harnessConstants'

import { bshzColors } from './bshzColors';

import ContentPresenter from './contentPresenter.js';

//import cytoscape from 'cytoscape';
import CytoscapeComponent from 'react-cytoscapejs';

// Used to pace queries to WoC
function mySleep(ms, message) {
	if ( message.length !== 0 ) {
		console.log("Sleeping " + ms + " ms " + message)
	}
    return new Promise(resolve => setTimeout(resolve, ms))
}

class LinkJumper extends Component {
  constructor(props) {
    super(props);

    const pathLen = this.props.path.length;

    //this.props.bsvPriceUSD    // will be used to convert satoshi payments to dollars, cents (based on price for a given day)

    this.searchStarted = false;       // used (with state.searchStarted) to trigger from render()

    this.state = {
      idbSupported: false,            // not strictly necessary for linkJumper
      dbPerms: false,
      haveAskedForPerms: false,
      showAreYouSureModal: false,     // to maybe DELETE IDB data

      showLoader: true,

      searchStarted: false,           // used (with this.searchStarted) to trigger from render()

      explainMore: false,

      phase: 0,
      deepestPhase: pathLen < 4 ?
                        3
                      :
                        pathLen < 6 ?
                            4
                          :
                            pathLen < 8 ?
                                5
                              :
                                pathLen < 9 ?
                                    6
                                  :
                                    pathLen < 10 ?
                                        7
                                      :
                                        pathLen < 11 ?
                                            8
                                          :
                                            9,


      deepestTLevel: pathLen < 8 ?
                        4
                      :
                        pathLen < 9 ?
                            3
                          :
                            pathLen < 10 ?
                                2
                              :
                                pathLen < 11 ?
                                    1
                                  :
                                    0,
      linkRHS: [
          // [0]
          <>
            { pathLen < 4 ?
                      <>{this.props.path[0]}/{this.props.path[1]}/{this.props.path[2]}</>  //domain + OwnerCount + quarterly
                    :
                      pathLen < 6 ?
                              <>{this.props.path[0]}/{this.props.path[1]}/{this.props.path[2]}/{this.props.path[3]}/{this.props.path[4]}</>   //d+oc+q +periodical
                            :
                              pathLen < 7 ?
                                      <>{this.props.path[0]}/{this.props.path[1]}/{this.props.path[2]}/{this.props.path[3]}/{this.props.path[4]}/{this.props.path[5]}</>   //d+oc+q +periodical + T4
                                    :
                                      pathLen < 8 ?
                                              <>{this.props.path[0]}/{this.props.path[1]}/{this.props.path[2]}/{this.props.path[3]}/{this.props.path[4]}/{this.props.path[5]}/{this.props.path[6]}</>    //d+oc+q +periodical + T4 + T3
                                            :
                                              pathLen < 9 ?
                                                      <>{this.props.path[0]}/{this.props.path[1]}/{this.props.path[2]}/{this.props.path[3]}/{this.props.path[4]}/{this.props.path[5]}/{this.props.path[6]}/{this.props.path[7]}</> //d+oc+q +periodical + T4 + T3 + T2
                                                    :
                                                      pathLen < 10 ?
                                                              <>{this.props.path[0]}/{this.props.path[1]}/{this.props.path[2]}/{this.props.path[3]}/{this.props.path[4]}/{this.props.path[5]}/{this.props.path[6]}/{this.props.path[7]}/{this.props.path[8]}</>   //d+oc+q +periodical + T4 + T3 + T2 + T1
                                                            :
                                                              <>{this.props.path[0]}/{this.props.path[1]}/{this.props.path[2]}/{this.props.path[3]}/{this.props.path[4]}/{this.props.path[5]}/{this.props.path[6]}/{this.props.path[7]}/{this.props.path[8]}/{this.props.path[9]}</>  //d+oc+q +periodical + T4 + T3 + T2 + T1 + T0
            }
          </>,
          // [1]
          <>
            { pathLen < 4 ?
                      <>{this.props.path[1]}/{this.props.path[2]}</>  // OwnerCount + quarterly
                    :
                      pathLen < 6 ?
                              <>{this.props.path[1]}/{this.props.path[2]}/{this.props.path[3]}/{this.props.path[4]}</>   //oc+q +periodical
                            :
                              pathLen < 7 ?
                                      <>{this.props.path[1]}/{this.props.path[2]}/{this.props.path[3]}/{this.props.path[4]}/{this.props.path[5]}</>   //oc+q +periodical + T4
                                    :
                                      pathLen < 8 ?
                                              <>{this.props.path[1]}/{this.props.path[2]}/{this.props.path[3]}/{this.props.path[4]}/{this.props.path[5]}/{this.props.path[6]}</>    //oc+q +periodical + T4 + T3
                                            :
                                              pathLen < 9 ?
                                                      <>{this.props.path[1]}/{this.props.path[2]}/{this.props.path[3]}/{this.props.path[4]}/{this.props.path[5]}/{this.props.path[6]}/{this.props.path[7]}</> //oc+q +periodical + T4 + T3 + T2
                                                    :
                                                      pathLen < 10 ?
                                                              <>{this.props.path[1]}/{this.props.path[2]}/{this.props.path[3]}/{this.props.path[4]}/{this.props.path[5]}/{this.props.path[6]}/{this.props.path[7]}/{this.props.path[8]}</>   //oc+q +periodical + T4 + T3 + T2 + T1
                                                            :
                                                              <>{this.props.path[1]}/{this.props.path[2]}/{this.props.path[3]}/{this.props.path[4]}/{this.props.path[5]}/{this.props.path[6]}/{this.props.path[7]}/{this.props.path[8]}/{this.props.path[9]}</>  //oc+q +periodical + T4 + T3 + T2 + T1 + T0
            }
          </>,
          // [2]
          <>
            { pathLen < 4 ?
                      <>{this.props.path[2]}</>  // quarterly
                    :
                      pathLen < 6 ?
                              <>{this.props.path[2]}/{this.props.path[3]}/{this.props.path[4]}</>   // q +periodical
                            :
                              pathLen < 7 ?
                                      <>{this.props.path[2]}/{this.props.path[3]}/{this.props.path[4]}/{this.props.path[5]}</>   // q +periodical + T4
                                    :
                                      pathLen < 8 ?
                                              <>{this.props.path[2]}/{this.props.path[3]}/{this.props.path[4]}/{this.props.path[5]}/{this.props.path[6]}</>    // q +periodical + T4 + T3
                                            :
                                              pathLen < 9 ?
                                                      <>{this.props.path[2]}/{this.props.path[3]}/{this.props.path[4]}/{this.props.path[5]}/{this.props.path[6]}/{this.props.path[7]}</> // q +periodical + T4 + T3 + T2
                                                    :
                                                      pathLen < 10 ?
                                                              <>{this.props.path[2]}/{this.props.path[3]}/{this.props.path[4]}/{this.props.path[5]}/{this.props.path[6]}/{this.props.path[7]}/{this.props.path[8]}</>   // q +periodical + T4 + T3 + T2 + T1
                                                            :
                                                              <>{this.props.path[2]}/{this.props.path[3]}/{this.props.path[4]}/{this.props.path[5]}/{this.props.path[6]}/{this.props.path[7]}/{this.props.path[8]}/{this.props.path[9]}</>  // q +periodical + T4 + T3 + T2 + T1 + T0
            }
          </>,
          // [3]
          <>
            { pathLen < 4 ?
                      null
                    :
                      pathLen < 6 ?
                              <>{this.props.path[3]}/{this.props.path[4]}</>   // periodical
                            :
                              pathLen < 7 ?
                                      <>{this.props.path[3]}/{this.props.path[4]}/{this.props.path[5]}</>   // periodical + T4
                                    :
                                      pathLen < 8 ?
                                              <>{this.props.path[3]}/{this.props.path[4]}/{this.props.path[5]}/{this.props.path[6]}</>    // periodical + T4 + T3
                                            :
                                              pathLen < 9 ?
                                                      <>{this.props.path[3]}/{this.props.path[4]}/{this.props.path[5]}/{this.props.path[6]}/{this.props.path[7]}</> // periodical + T4 + T3 + T2
                                                    :
                                                      pathLen < 10 ?
                                                              <>{this.props.path[3]}/{this.props.path[4]}/{this.props.path[5]}/{this.props.path[6]}/{this.props.path[7]}/{this.props.path[8]}</>   // periodical + T4 + T3 + T2 + T1
                                                            :
                                                              <>{this.props.path[3]}/{this.props.path[4]}/{this.props.path[5]}/{this.props.path[6]}/{this.props.path[7]}/{this.props.path[8]}/{this.props.path[9]}</>  // periodical + T4 + T3 + T2 + T1 + T0
            }
          </>,
          // [4] not used
          <></>,
          // [5]
          <>
            {
              pathLen < 6 ?
                      null
                    :
                      pathLen < 8 ?
                              <>{this.props.path[5]}/{this.props.path[6]}</>    // /T/T4
                            :
                              pathLen < 9 ?
                                      <>{this.props.path[5]}/{this.props.path[6]}/{this.props.path[7]}</> // /T/T4 + T3
                                    :
                                      pathLen < 10 ?
                                              <>{this.props.path[5]}/{this.props.path[6]}/{this.props.path[7]}/{this.props.path[8]}</>   // /T/T4 + T3 + T2
                                            :
                                              pathLen < 11 ?
                                                      <>{this.props.path[5]}/{this.props.path[6]}/{this.props.path[7]}/{this.props.path[8]}/{this.props.path[9]}</>  // /T/T4 + T3 + T2 + T1
                                                    :
                                                      <>{this.props.path[5]}/{this.props.path[6]}/{this.props.path[7]}/{this.props.path[8]}/{this.props.path[9]}/{this.props.path[10]}</>  // /T/T4 + T3 + T2 + T1 + T0
            }
          </>,
          // [6] not used
          <>
          </>,
          // [7]
          <>
            {
              pathLen < 8 ?
                      null
                    :
                      pathLen < 9 ?
                              <>{this.props.path[7]}</> // T3
                            :
                              pathLen < 10 ?
                                      <>{this.props.path[7]}/{this.props.path[8]}</>   // T3 + T2
                                    :
                                      pathLen < 11 ?
                                              <>{this.props.path[7]}/{this.props.path[8]}/{this.props.path[9]}</>  // T3 + T2 + T1
                                            :
                                              <>{this.props.path[7]}/{this.props.path[8]}/{this.props.path[9]}/{this.props.path[10]}</>  // T3 + T2 + T1 + T0
            }
          </>,
          // [8]
          <>
            {
              pathLen < 9 ?
                      null
                    :
                      pathLen < 10 ?
                              <>{this.props.path[8]}</>   // T2
                            :
                              pathLen < 11 ?
                                      <>{this.props.path[8]}/{this.props.path[9]}</>  // T2 + T1
                                    :
                                      <>{this.props.path[8]}/{this.props.path[9]}/{this.props.path[10]}</>  // T2 + T1 + T0
            }
          </>,
          // [9]
          <>
            {
              pathLen < 10 ?
                      null
                    :
                      pathLen < 11 ?
                              <>{this.props.path[9]}</>  // T1
                            :
                              <>{this.props.path[9]}/{this.props.path[10]}</>  // T1 + T0
            }
          </>,
          // [10]
          <>
            {
              pathLen < 11 ?
                      null
                    :
                      <>{this.props.path[10]}</>  // T0
            }
          </>,
          //[11] not used ?
          <>
          </>

      ],

      contentReady: false,
      txId: null,
      decodedTx: '',      // this will be an attempt at a decode INDEPENDENT of qFDTx() - STRINGIFIED
      contentInfo: null,
      content: null,

      isGuestPost: false,

      contentPending: false,
      contentEncrypted: false
    };

    this.checkForPerms                  = this.checkForPerms.bind(this);
    this.toggleDbPerms                  = this.toggleDbPerms.bind(this);
    this.revokePermission               = this.revokePermission.bind(this);
    this.closeAreYouSureModal           = this.closeAreYouSureModal.bind(this);
    this.startTheSearch                 = this.startTheSearch.bind(this);
    this.findThePost                    = this.findThePost.bind(this);
    this.closeLoader                    = this.closeLoader.bind(this);

    this.explainMore                    = this.explainMore.bind(this);
    this.closeExplainMore               = this.closeExplainMore.bind(this);

    this.walkNameTreeWithoutDB          = this.walkNameTreeWithoutDB.bind(this);
    this.walkQuarterliesWithoutDB       = this.walkQuarterliesWithoutDB.bind(this);
    this.walkPeriodicalsWithoutDB       = this.walkPeriodicalsWithoutDB.bind(this);
    this.walkTransientsWithoutDB        = this.walkTransientsWithoutDB.bind(this);

    this.buildDecodedTx                 = this.buildDecodedTx.bind(this);
  }

  async componentDidMount() {
    console.warn("linkJumper: componentDidMount() props.path = " + this.props.path + " !!! <-----")
    console.warn("linkJumper: componentDidMount() props.rhs = " + this.props.rhs + " !!! <-----")


    const pathLen = this.props.path.length;
    console.warn("BTW: pathLen is " + pathLen)
    console.warn("this.state.deepestTLevel: " + this.state.deepestTLevel)

    // Check if the user already has a BitShizzle IDB setup
    // This would indicate he's already granted permission
    await this.checkForPerms()

    //NOTE: we kick-off the search from render()
  }

  //NOTE: this is copied from app's dbStuff()
  async checkForPerms() {
    const dbName = IDB_NAME //'BitShizzle7';

    try {
      const isExisting = (await window.indexedDB.databases()).map(db => db.name).includes(dbName);
      if ( isExisting ) {
        console.log("old-school detection: IDB EXISTS")
      } else {
        console.log("old-school detection: IDB does NOT exist (so we don't yet have permission to create it). window: ", window)
      }

      this.setState({dbPerms: isExisting, idbSupported: true})

    } catch (error) {
      // FIREFOX doesn't like window.indexedDB.database()
      // see: https://stackoverflow.com/questions/22956440/cannot-open-indexeddb-in-firefox
      // and: https://ckon.wordpress.com/2016/02/14/mutation-operation-error/
      //
      // This will detect it for firefox:
      if (window.indexedDB) {
        console.log("Your (mozilla?) environment supports IndexedDB. Will now check for the BitShizzle IDB...");

        const justDBExists = await justOpenDB()
        console.warn("componentDidMount() FF: justDBExists: ", justDBExists)

        if ( !justDBExists ) {
          console.warn("IDB supported, but our IDB does NOT yet exist. We have no permission")
          //this.couldNotFindDB()
        } else {
          console.log("IDB supported, AND our DB exists. Will finish-up...")
          //await this.finishAppSetup()
        }

        this.setState({dbPerms: justDBExists, idbSupported: true})

      } else {
        // Indexed DB is not supported

        console.error("ERROR: old-school detection: does NOT exist. Nor does window.indexedDB. We think your browser doesn't support indexedDB.")

        // Not strictly necessary to use IDB when link-jumping
        //alert("ERROR: We think your browser doesn't support IndexedDB - which is necessary to use this site")

        //this.setState({showDbTerms: true})
      }
    }

  }

  toggleDbPerms() {
    if ( this.state.dbPerms ) {

      // don't IMMEDIATELY turn-off permissions
      this.setState({showAreYouSureModal: true})
    } else {
      this.setState({dbPerms: !this.state.dbPerms})
    }
  }
  revokePermission() {
    this.setState({dbPerms: false})
    this.closeAreYouSureModal()

    //FIXME: should DELETE the IDB
  }
  closeAreYouSureModal() {
    this.setState({showAreYouSureModal: false})
  }

  // extracted, simplified from buildShizzle's queryFetchDecodeTx()
  buildDecodedTx(rawOutputs, inputChunks) {

    let statei = []
    // extract useful input parameters - part of what unlocks input 0
    // Based on the mode of OUTPUT 0 (which 'defines' the tx contract), we know what
    // input parameters to expect
    let input1Params = '';
    let input0Params
    //let input2Params = '';

    for ( let i = 0; i < rawOutputs.length; i++ ) {
      const script = rawOutputs[0].script
      const outValue = Buffer.from(rawOutputs[i].value, 'hex').readUIntLE(0, 6)  //FIXME: should look at all 8 bytes
      //const outState = decodeScriptsToState(out0Script, rawOutputs[0].value, true, inputChunks[0])

      // boring payout, or change?
      //WARNING: this logic must match a little bit below
      if ( script.length === 50 &&
          script.toUpperCase().startsWith("76A914") &&     // DUP, HASH160  PKHLen
          script.toUpperCase().endsWith("88AC")) {         // EQUALVERIFY, CHECKSIG
        // Payout or Change
        console.log(  "output #" + i + ":    script len: " + script.length
              + "    value: " + outValue )
        console.log("    FOR THE RECORD, here's the payout/change script: ", script)
        // Note that payouts and change are most likely ALWAYS exactly 50 bytes

        // the remaining outputs are payouts, change (and/or EBFRA?)
        break;

      } else {
        console.warn("Preliminary decode of output " + i + " - to get its scripthash")
        // we now pass in the inputChunks for the 0th input - to potentially supply the
        // .specialOp input field for when decoding Continue (to maybe add a /voteN to the address)
        statei[i] = decodeScriptsToState(script, rawOutputs[i].value, true, inputChunks[0])
      }

      input0Params = getUsefulInputParams(inputChunks[0], hexByteToAscii( statei[0].mode ) );
      if ( input0Params.hostGuestMode === 1 || input0Params.hostGuestMode === 4 ) {
        input1Params = getUsefulInputParams(inputChunks[1], hexByteToAscii( statei[0].mode ));
      }

      const mode = hexByteToAscii(statei[i].mode);
    }

    const shizzleAddress = statei[0].address
    const limb = statei[0].namePath ?
              hexStringToAscii( statei[0].namePath )
            :
              '';

    //FIXME: the end of qFDTx() checks for shizzleProfile info
    console.error("LinkJumper could, and probably should, check for shizzleProfile info along the way")

    const newDecodedTx = {
      //state: statei[0],		//redundant It's already contained in outputStates[0]
      outputStates:     statei,
      address:          shizzleAddress,
      //confirmedOutputs: confirmed,
      //bogusOutputs:     bogus,
      limbName:         limb,
      //buildable:        buildable,
      //followable:       followable,
      //unspendable:      unspendable,
      //foundBuildable:   foundBuildable,
      //foundFollowable:  foundFollowable,

      input0Params:     input0Params,			// content, txid, labels, pcode  (for tx, and hostTx)
      //input0PrevTxId:   prev0TxId,
      //input1PrevTxId:   prev1TxId,			// might only be useful for guest posts (Transients only)
      input1Params:     input1Params,			// in host/guest mode, this would be the guest's input params

      //input2Params:     input2Params,   *****
      //dialogNamePath:   dialogNamePath,       // carried-forward
                          // TODO: carry-forward most-recent owner PKey, guest PK, and OUR p2pkh priv
      //dialogSentBy:     dialogSentBy,
      //fundingPK:        fundingPK,    ***** // currently copied from input2Params.fundingPubKey
      //dialogMostRecentOwnerPubKey:    dialogMostRecentOwnerPubKey,
      //dialogMostRecentVisitorPubKey: dialogMostRecentVisitorPubKey,
      //rawOutputs: rawOutpushzlProfilets	*****			// has both the .scripts, and .values

      //shzlProfile:      shzlProfile,  *****
    }

    return newDecodedTx
  }

  async walkNameTreeWithoutDB(startingTxId, destDomain) {

    console.warn("destination domain: ", destDomain)

    let queryFailed = false
    let trailEndsPrematurely = false

    let currentDomain = ''
    let domainStillShort = true

    let currentTxId = startingTxId
    let prevTxId = null
    let finalMode = ''
    let outputIsAppend = true
    do {
      let i = currentDomain.length
      const maybeAddOneToOutIdx = i > 0 ? 1 : 0

      const rawTx = await getRawInfo(currentTxId);

      if ( rawTx === null || typeof rawTx === 'number' ) {
        // we return more than null - the response status code, if present
        //FIXME: WHY did we fail? If 404, AND the DB has record of it, then maybe we need
        //       to delete the record, AND its children (recursively).
        //       At the very least, we should take note of a 404 (schedule for investigation, or deletion)
        console.error("walkNameTreeWithoutDB(): failed getting raw tx info: " + rawTx)

        if ( rawTx === 404 ) {
          console.warn("walkNameTreeWithoutDB(): traditionally would have removed 'faded' tx: " + currentTxId);
          alert("We think there's something wrong with the link you're using.")
        } else {
          alert("ERROR: there's something wrong with the link you've provided")
        }

        queryFailed = true
        break;
      }

      // parse it, to get the state
      const decomposition = decomposeRawTx(rawTx, true)
      let rawOutputs = decomposition.outputs
      let rawInputs = decomposition.inputs

      const inputChunks = parseInputs(rawInputs, true)

      console.warn("linkJumper: there are " + rawInputs.length + " inputs")
      console.warn("linkJumper: there are " + rawOutputs.length + " outputs")

      let nextOutIdx
      let nextDomainChar = ''
      if ( domainStillShort ) {
        nextDomainChar = destDomain[i]
        console.warn("nextDomainChar: ", nextDomainChar)

        // choose output based on which letter/char we're looking for now
        nextOutIdx = nextDomainChar.charCodeAt(0) - 'a'.charCodeAt(0) + maybeAddOneToOutIdx
      } else {
        console.warn("NOW: looking immediately at output 0 of this tx")
        nextOutIdx = 0
      }

      console.warn("  nextOutIdx: ", nextOutIdx)

      const nextOutScript = rawOutputs[nextOutIdx].script

      //NOTE: decoding specialOp based on INPUT param #13 of 'ZEROETH' input
      const outputState = decodeScriptsToState(nextOutScript, rawOutputs[i].value, true, inputChunks[0])
      console.warn("state: ", outputState)

      const nextPath = outputState.namePath
      console.warn("nextPath: ", nextPath + ", or " + hexStringToAscii( nextPath ))

      currentDomain = currentDomain + nextDomainChar

      console.warn("currentDomain is now: ", currentDomain)

      console.warn("SCRIPT HASH (of this output): ", outputState.scriptHash)


      await mySleep(50, 'walkNameTreeWithoutDB() quick sleep before finding next tx')

      const nextTxId = await findNextTxFromProvider( outputState, currentTxId );
      console.warn("Next txId: ", nextTxId)

      // if there is no NEXT, we should recognize, and break")
      trailEndsPrematurely = nextTxId === null


//FIXME: use LENGTHS, not strings (just in case)
      domainStillShort = currentDomain !== destDomain


      finalMode = hexByteToAscii(outputState.mode)
      prevTxId = currentTxId
      currentTxId = nextTxId

      if ( !domainStillShort ) {
        if ( finalMode === 'X' ) {
          console.warn("We've found the domain, but it's mode is X - so, next tx is still APPEND, and we'll want output 0.\n\nIOW: ONE MORE TIME ")
          outputIsAppend = true
        } else if ( finalMode === 'K' ) {
          console.warn("ownerCount:", outputState.ownerCount, " We've found the domain, but it's mode is K - so, PRE-CLAIMED: THIS output state is Quarterly")
          outputIsAppend = false
        } else if ( finalMode === 'P' ) {
          console.warn("ownerCount:", outputState.ownerCount, " We've found the domain, but it's mode is P - so, CLAIMABLE: THIS output state is (SHORTENED?) Quarterly")
          outputIsAppend = false
        } else {
          alert("VERY WRONG. ERROR. Unexpected/invalid mode: ", finalMode)
          throw new Error("Problem with contract code?!")
        }
      }

      console.warn("current output address: ", outputState.address)
      console.warn("================ tree iteration ================")

    } while ( !queryFailed && !trailEndsPrematurely && (domainStillShort || outputIsAppend) );

    console.warn("DONE walking")
    console.log("trailEndsPrematurely: ", trailEndsPrematurely)
    console.log("queryFailed: ", queryFailed + "\n")

    console.log("finalMode - of CURRENT state: ", finalMode )
    console.log("outputIsAppend: ", outputIsAppend)
    console.log("current txId: ", prevTxId)
    console.log("next txId: ", currentTxId)

    if ( ! queryFailed && !trailEndsPrematurely ) {
      console.warn("End of nameTree. AFTER making sure we've got the correct OWNER COUNT, we'll need to follow the path on this domain now")
    } else if ( trailEndsPrematurely ) {
      alert("It appears that the trail ends prematurely. There may be something wrong with the link you were given")
    } else {
      alert("Something went wrong. We should determine if it's a problem with the provider, or with the link you were given.")
    }

    return {
        success: !queryFailed && !trailEndsPrematurely,
        nextTx: currentTxId
    }
  }

  async walkQuarterliesWithoutDB(startingTxId, path) {

    console.warn("looking for ownerCount of " + parseInt(path[1], 10) )
    console.warn("looking for quarterlyCount of " + parseInt(path[2], 10) )

    const destOwnerCount = parseInt(path[1], 10)
    if ( destOwnerCount < 1 || 255 < destOwnerCount ) {
      alert("malformed path. destination ownerCount: ", destOwnerCount)
      return { success: false, fullyDone: false, currentTx: startingTxId, nextTx: null }
    }

    const destQuarterlyCount = parseInt(path[2], 10)
    if ( destQuarterlyCount < 0 || 2000 < destOwnerCount ) {   //FIXME: shouldn't have an upper bound.
                                                                //FIXME: NOT SURE of the lower bound. 0 okay?
      alert("malformed path. destination QUARTERLYCount: ",  destQuarterlyCount)
      return { success: false, fullyDone: false, currentTx: startingTxId, nextTx: null }
    }

    let queryFailed = false
    let trailEndsPrematurely = false

    let fullyDone = false // we would set to true if we're NOT looking beyond quarterlies

    let ownerCountStillShort = true
    let quarterlyCountStillShort = false
    let currentTxId = startingTxId
    let prevTxId = null
    let contentInfo = null
    let decodedTx = null
    do {
      const rawTx = await getRawInfo(currentTxId);

      if ( rawTx === null || typeof rawTx === 'number' ) {
        // we return more than null - the response status code, if present
        //FIXME: WHY did we fail? If 404, AND the DB has record of it, then maybe we need
        //       to delete the record, AND its children (recursively).
        //       At the very least, we should take note of a 404 (schedule for investigation, or deletion)
        console.error("walkQuarterliesWithoutDB(): failed getting raw tx info: " + rawTx)

        if ( rawTx === 404 ) {
          console.warn("walkQuarterliesWithoutDB(): traditionally would have removed 'faded' tx: " + currentTxId);
          alert("We think there's something wrong with the link you're using.")
        } else {
          alert("ERROR: there's something wrong with the link you've provided")
        }

        queryFailed = true
        break;
      }

      // parse it, to get the state
      const decomposition = decomposeRawTx(rawTx, true)
      let rawOutputs = decomposition.outputs
      let rawInputs = decomposition.inputs

      const inputChunks = parseInputs(rawInputs, true)

      console.warn("We're analyzing current txId ", currentTxId)
      console.warn("linkJumper: there are " + rawInputs.length + " inputs")
      console.warn("linkJumper: there are " + rawOutputs.length + " outputs")

      const out0Script = rawOutputs[0].script
      const out0State = decodeScriptsToState(out0Script, rawOutputs[0].value, true, inputChunks[0])

      console.warn("output0 state: ", out0State)
      console.warn("ownerCount: ", out0State.ownerCount)
      console.warn("QUARTERLYCount: ", out0State.quarterlyCount)


      await mySleep(50, 'walkQuarterliesWithoutDB() quick sleep before finding next tx')


      let nextTxId
      ownerCountStillShort = out0State.ownerCount < destOwnerCount
      if ( !ownerCountStillShort ) {

        this.setState({phase: 3})

        // FINALLY calculate the quarterlyCount
        quarterlyCountStillShort = out0State.quarterlyCount < destQuarterlyCount

        console.warn("QCStill short: ", quarterlyCountStillShort)
      }

      let stateToUse = out0State
      if ( !ownerCountStillShort && !quarterlyCountStillShort ) {


        console.warn("we've reached the correct owner and quarter. Should now MAYBE look at output 1 (base-0)")

        if ( path[3] && path[3] === 'U' ) {
          const out1Script = rawOutputs[1].script
          const out1State = decodeScriptsToState(out1Script, rawOutputs[1].value, true, inputChunks[0])
          stateToUse = out1State

          console.warn("BTW: output1 state: ", out1State)
          console.warn("We've been asked to look at periodicals. Must proceed further - using output 1 !!!! (see console)")
        } else {
          console.warn("We have NOT been asked to go further. We will FULLY stop here")
          //alert("We have NOT been asked to go further. We will FULLY stop here")
          fullyDone = true

          const inputParams = getUsefulInputParams(inputChunks[ 0 ], hexByteToAscii( stateToUse.mode ) );

          contentInfo = {inputParams: inputParams, decomposition: decomposition, inputChunks: inputChunks}
        }

        decodedTx = this.buildDecodedTx(rawOutputs, inputChunks)
      }



//FIXME: if we're at the right count, we should look at out1 for the NEXT tx
      nextTxId = await findNextTxFromProvider( stateToUse, currentTxId );
      console.warn("Next txId: ", nextTxId)
      prevTxId = currentTxId
      currentTxId = nextTxId

      // if there is no NEXT, we should recognize, and break")
      trailEndsPrematurely = !fullyDone && nextTxId === null

      console.warn("current output address: ", stateToUse.address) //out0State.address)
      console.warn("================ Q iteration ================")

    } while ( !queryFailed && !trailEndsPrematurely && (ownerCountStillShort || quarterlyCountStillShort) );

    console.warn("DONE walking the QUARTERLIES")
    console.log("trailEndsPrematurely: ", trailEndsPrematurely)
    console.log("queryFailed: ", queryFailed + "\n")

    console.log("fullyDone: ", fullyDone + "\n")

    console.log("current txId: ", prevTxId)
    console.log("next txId: ", currentTxId)

    return {
      success: !queryFailed && !trailEndsPrematurely,
      fullyDone: fullyDone,

      currentTx: prevTxId,
      nextTx: currentTxId,

      contentInfo: contentInfo,
      dtx:    decodedTx,
    }
  }

  async walkPeriodicalsWithoutDB(startingTxId, path) {

    console.warn("looking for periodicCount of " + parseInt(path[4], 10) )

    const destPeriodicCount = parseInt(path[4], 10)
    if ( destPeriodicCount < 1 || 1000 < destPeriodicCount ) {
      alert("malformed path. destination PERIODIC count: ",  destPeriodicCount)
      alert("ALSO: fill-out the return value more")
      return { success: false, nextTx: null }
    }


    let queryFailed = false
    let trailEndsPrematurely = false

    let fullyDone = false // we would set to true if we're NOT looking beyond periodicals

    let periodicCountStillShort = true
    let currentTxId = startingTxId
    let prevTxId = null
    let contentInfo = null
    let decodedTx = null
    do {
      const rawTx = await getRawInfo(currentTxId);

      if ( rawTx === null || typeof rawTx === 'number' ) {
        // we return more than null - the response status code, if present
        //FIXME: WHY did we fail? If 404, AND the DB has record of it, then maybe we need
        //       to delete the record, AND its children (recursively).
        //       At the very least, we should take note of a 404 (schedule for investigation, or deletion)
        console.error("walkPeriodicalsWithoutDB(): failed getting raw tx info: " + rawTx)

        if ( rawTx === 404 ) {
          console.warn("walkPeriodicalsWithoutDB(): traditionally would have removed 'faded' tx: " + currentTxId);
          alert("We think there's something wrong with the link you're using.")
        } else {
          alert("ERROR: there's something wrong with the link you've provided")
        }

        queryFailed = true
        break;
      }

      // parse it, to get the state
      const decomposition = decomposeRawTx(rawTx, true)
      let rawOutputs = decomposition.outputs
      let rawInputs = decomposition.inputs

      const inputChunks = parseInputs(rawInputs, true)

      console.warn("We're analyzing current txId ", currentTxId)
      console.warn("linkJumper: there are " + rawInputs.length + " inputs")
      console.warn("linkJumper: there are " + rawOutputs.length + " outputs")

      const out0Script = rawOutputs[0].script
      const out0State = decodeScriptsToState(out0Script, rawOutputs[0].value, true, inputChunks[0])

      console.warn("output0 state: ", out0State)
      console.warn("PERIODICCount: ", out0State.periodicCount)


      await mySleep(50, 'walkPeriodicalsWithoutDB() quick sleep before finding next tx')


      let nextTxId
      periodicCountStillShort = out0State.periodicCount < destPeriodicCount

      let stateToUse = out0State


      if ( !periodicCountStillShort ) {


        console.warn("we've reached the correct period. Should now MAYBE look at output 1 (base-0) (Transients)")

        if ( path[5] && path[5].length > 0 ) {
          console.warn("BTW: path[5]: ", path[5])
          const out1Script = rawOutputs[1].script
          const out1State = decodeScriptsToState(out1Script, rawOutputs[1].value, true, inputChunks[0])
          stateToUse = out1State

          console.warn("BTW: output1 state: ", out1State)
          console.warn("We've been asked to look at TRANSIENTS. Must proceed further - using output 1 !!!! (see console). BTW: current txId is " + currentTxId)
          //alert("We've been asked to look at TRANSIENTS. Must proceed further - using output 1 !!!! (see console). BTW: current txId is " + currentTxId)
        } else {
          console.warn("We have NOT been asked to go further, into transients. We will FULLY stop here.")
          //alert("We have NOT been asked to go further, into transients. We will FULLY stop here.")
          fullyDone = true

          const inputParams = getUsefulInputParams(inputChunks[ 0 ], hexByteToAscii( stateToUse.mode ) );

          contentInfo = {inputParams: inputParams, decomposition: decomposition, inputChunks: inputChunks}
        }

        decodedTx = this.buildDecodedTx(rawOutputs, inputChunks)
      }



//FIXME: if we're at the right count, we should look at out1 for the NEXT tx
      nextTxId = await findNextTxFromProvider( stateToUse, currentTxId );
      console.warn("Next txId: ", nextTxId)
      prevTxId = currentTxId
      currentTxId = nextTxId


      // if there is no NEXT, we should recognize, and break")
      trailEndsPrematurely = !fullyDone && nextTxId === null

      console.warn("current output address: ", stateToUse.address) //out0State.address)
      console.warn("================ Periodical iteration ================")

    } while ( !queryFailed && !trailEndsPrematurely && periodicCountStillShort );

    console.warn("DONE walking the PERIODICALS")
    console.log("trailEndsPrematurely: ", trailEndsPrematurely)
    console.log("queryFailed: ", queryFailed + "\n")

    console.log("fullyDone: ", fullyDone + "\n")

    console.log("current txId: ", prevTxId)
    console.log("next txId: ", currentTxId)

    return {
      success: !queryFailed && !trailEndsPrematurely,
      fullyDone: fullyDone,

      currentTx: prevTxId,
      nextTx: currentTxId,

      contentInfo: contentInfo,
      dtx:    decodedTx,
    }

  }

  async walkTransientsWithoutDB(startingTxId, path, level, tLevel, finalLevel ) {
    const ourDomain = path[0]
    console.warn("WALK TRANSIENTS. Following ('our') domain of " + ourDomain + ", and CURRENT level (of path array) is " + level)
    console.warn("Path: ", path)
    console.warn("T-Level: ", tLevel)
    console.warn("deepestTLevel: " + this.state.deepestTLevel)

    console.warn("Walk TRANSIENTS. Following 'OUR' domain of " + ourDomain + ", and CURRENT level (of path array) is " + level + ". STARTING at txId " + startingTxId)
    //alert("Walk TRANSIENTS. Following 'OUR' domain of " + ourDomain + ", and CURRENT level (of path array) is " + level + ". STARTING at txId " + startingTxId)
    console.warn("walkTransients: final level ? " + (finalLevel ? 'true' : 'false') )
    //alert("walkTransients: final level ? " + (finalLevel ? 'true' : 'false'))

    const destUpCount = parseInt(path[level], 10)
    if ( destUpCount === NaN ) {
      alert("ERROR: we need to implement code for jumping to guest posts - or something else.")
      return
    }
    if ( destUpCount < 0 || 255 < destUpCount ) {
      alert("malformed path. destination up count: ",  destUpCount)
      alert("IMPLEMENT ME: fill-out the return value more")
      return { success: false, nextTx: null }
    }

    console.warn("At THIS transient level (" + tLevel + "), we're looking for an upCount of " + destUpCount + ". We're starting at txId " + startingTxId)

    let queryFailed = false
    let trailEndsPrematurely = false

    // ??? maybe not usefule for transients
    let fullyDone = false // we would set to true if we're NOT looking beyond this Transient level ?

    let upCountStillShort = true

    let currentTxId = startingTxId
    let prevTxId = null
    let contentInfo = null
    let decodedTx = null
    do {
      const rawTx = await getRawInfo(currentTxId);

      if ( rawTx === null || typeof rawTx === 'number' ) {
        // we return more than null - the response status code, if present
        //FIXME: WHY did we fail? If 404, AND the DB has record of it, then maybe we need
        //       to delete the record, AND its children (recursively).
        //       At the very least, we should take note of a 404 (schedule for investigation, or deletion)
        console.error("walkTransientsWithoutDB(): failed getting raw tx info: " + rawTx)

        if ( rawTx === 404 ) {
          console.warn("walkTransientsWithoutDB(): traditionally would have removed 'faded' tx: " + currentTxId);
          alert("We think there's something wrong with the link you're using.")
        } else {
          alert("ERROR: there's something wrong with the link you've provided")
        }

        queryFailed = true
        break;
      }

      // parse it, to get the state
      const decomposition = decomposeRawTx(rawTx, true)
      let rawOutputs = decomposition.outputs
      let rawInputs = decomposition.inputs

      const inputChunks = parseInputs(rawInputs, true)

      console.warn("We're analyzing current txId ", currentTxId)
      console.warn("linkJumper: there are " + rawInputs.length + " inputs")
      console.warn("linkJumper: there are " + rawOutputs.length + " outputs")

      const out0Script = rawOutputs[0].script
      const out0State = decodeScriptsToState(out0Script, rawOutputs[0].value, true)

      console.warn("output0 state: ", out0State)

      let stateToUse = out0State
      let indexOfNextOutput = 1  // Which output # (base-0) to use if we need to go to the NEXT (deeper) level

      let weAreGuestPosting = false
      let weAreHosting = false

      console.warn("BTW: rawOutputs: ", rawOutputs)

      if ( rawOutputs.length === 1 || rawOutputs.length > 1 && rawOutputs[1].script.length < 200 ) {
        console.warn("There's only ONE output ! So, we must be posting, and, it must be the FINAL level. let's check...")
        //alert("There's only ONE output ! So, we must be posting, and, it must be the FINAL level. let's check...")
        if ( !finalLevel ) {
          console.error("ERROR: supposedly this isn't the final level. hmm. ALGORITHM NEEDS FIXING/REVIEW <-----\n\nPlease PONDER")
          console.warn("BTW: this.state.deepestTLevel: " + this.state.deepestTLevel)
          alert("ERROR: supposedly this (level " + level + ") isn't the final level. hmm. ALGORITHM NEEDS FIXING/REVIEW <-----\n\nPlease PONDER")
          return {success: false}
        }

        stateToUse = out0State
        //indexOfNextOutput = 1 //IF
        console.warn("statusToUse: out0State (and FINAL level) - lets just run as far as we need at THIS level")
      } else {

        console.warn("There are MORE than 1 output? " + rawOutputs.length)


        if ( hexStringToAscii(out0State.namePath) !== ourDomain ) {
          console.warn("0th output name not ours. Here we are making a GuestPost at domain " + hexStringToAscii(out0State.namePath))
          //alert("0th output name not ours. Here we are making a GuestPost at domain " + hexStringToAscii(out0State.namePath))
          weAreGuestPosting = true
          //stateToUse = out1State      well, we don't have it yet
          indexOfNextOutput = 2
        }

        //WARNING: it seems like we might need to pick the CORRECT inputChunks - for input #1 (base-0). Can we be sure to do that?
        const out1Script = rawOutputs[1]?.script
        const out1State = decodeScriptsToState(out1Script, rawOutputs[1].value, true)

        if ( weAreGuestPosting ) {
          stateToUse = out1State
          console.warn("We are guest-posting, BUT, state to use NEXT might be NEXT output (indexOfNextOutput " + indexOfNextOutput + " - if we've reached dest upCount)")
          //alert("We are guest-posting, BUT, state to use NEXT might be NEXT output (indexOfNextOutput " + indexOfNextOutput + " - if we've reached dest upCount)")
        } else {

          // WE aren't guest-posting

          if ( hexStringToAscii(out1State.namePath) !== ourDomain ) {
            console.warn("2nd output (#1 base-0) name not ours. Here we are HOSTING. Someone ELSE (" + hexStringToAscii(out1State.namePath) + ") is GuestPosting on our domain ")
            weAreHosting = true
            //alert("2nd output (#1 base-0) name not ours. Here we are HOSTING. Someone ELSE (" + hexStringToAscii(out1State.namePath) + ") is GuestPosting on our domain ")
            stateToUse = out0State
            //indexOfNextOutput = 0    WARNING: we should NOT want to go deeper at THIS point
//FIXME: add a check here - that we don't WANT to go deeper here
          } else {
            console.warn("2nd output (#1 base-0) IS ours. So, it's just our spawn")
            console.warn("We are NOT guest-posting, and we're NOT hosting. 2nd output (#1 base-0) IS ours. So, it's just our spawn")
            //alert("We are NOT guest-posting, and we're NOT hosting. 2nd output (#1 base-0) IS ours. So, it's just our spawn")
            indexOfNextOutput = 1
          }

        }
      }

      const maxDownCounterInt = parseInt( stateToUse.maxDownCounterHex )
      console.warn("downCount: ", stateToUse.downCounterInt)
      const upCounterInt = maxDownCounterInt - stateToUse.downCounterInt
      console.warn("upCount: ", upCounterInt)

      upCountStillShort = upCounterInt < destUpCount






      await mySleep(100, 'walkTransientsWithoutDB() quick sleep before finding next tx')


      let nextTxId



      // we need to choose the correct NEXT state to use
      // IF this is a guest-post, it could be output #2 (base-0)
      if ( !upCountStillShort ) {

        console.warn("we've reached the correct upCount (in the OUTPUT). Should now MAYBE look at output " + indexOfNextOutput + " (base-0)")

        if ( !finalLevel ) {
          console.warn("Since the up-count is no-longer short (in the output state), AND it's NOT the final level, we'll be looking at output #" + indexOfNextOutput + " (base-0) of the current tx: " + currentTxId)
          //alert("Since the up-count is no-longer short (in the output state), AND it's NOT the final level, we'll be looking at output #" + indexOfNextOutput + " (base-0) of the current tx: " + currentTxId)
          const outNScript = rawOutputs[indexOfNextOutput].script
          const outNState = decodeScriptsToState(outNScript, rawOutputs[indexOfNextOutput].value, true, inputChunks[indexOfNextOutput])
          stateToUse = outNState

          console.warn("BTW: outputN state: ", outNState)
          console.warn("   and address of outN: ", outNScript.address)
          console.warn("We've been asked to look at deeper transients. Must proceed further - using output 1 !!!! (see console)")
        } else {
          console.warn("ACTUALLY, we have NOT been asked to go to deeper transients. We will FULLY stop here\n\nWill use content from input 0 (or 1 if we code to handle guest-post content)")
          //alert("ACTUALLY, we have NOT been asked to go to deeper transients. We will FULLY stop here\n\nWill use content from input 0 (or 1 if we code to handle guest-post content)")
          fullyDone = true

          const idxToUse =  weAreGuestPosting ? 1 : 0
          const inputParams = getUsefulInputParams(inputChunks[ idxToUse ], hexByteToAscii( stateToUse.mode ) );

          contentInfo = {inputParams: inputParams, decomposition: decomposition, inputChunks: inputChunks, idxToUse: idxToUse, guestPosting: weAreGuestPosting, hosting: weAreHosting}
        }

        decodedTx = this.buildDecodedTx(rawOutputs, inputChunks)
      }


      nextTxId = await findNextTxFromProvider( stateToUse, currentTxId );
      console.warn("Next txId: ", nextTxId)
      prevTxId = currentTxId
      currentTxId = nextTxId


      // if there is no NEXT, we should recognize, and break")
      trailEndsPrematurely = !fullyDone && nextTxId === null

      console.warn("current output address: ", stateToUse.address) //out0State.address)
      console.warn("================ Q iteration ================")

    } while ( !queryFailed && !trailEndsPrematurely && upCountStillShort );


    console.warn("DONE walking THIS Transient level")
    console.log("trailEndsPrematurely: ", trailEndsPrematurely)
    console.log("queryFailed: ", queryFailed + "\n")

    console.log("fullyDone: ", fullyDone + "\n")

    console.log("current txId: ", prevTxId)
    console.log("next txId: ", currentTxId)

    if ( fullyDone ) {
      console.warn("NOTE: since we're fullyDone for transients, we won't look at NEXT tx, we need to look at content info (input params) of CURRENT tx: ", prevTxId )
      //alert("NOTE: since we're fullyDone for transients, we won't look at NEXT tx, we need to look at content (input params) of CURRENT tx")
    } else {
      console.warn("We're done with this transient level " + tLevel + ", but not FULLY done. We'll now look deeper, at next txId: " + currentTxId)
      //alert("We're done with this transient level " + tLevel + ", but not FULLY done. We'll now look deeper, at next txId: " + currentTxId)
    }

    return {
      success: !queryFailed && !trailEndsPrematurely,
      fullyDone: fullyDone,

      currentTx: prevTxId,
      nextTx: currentTxId,

      contentInfo: contentInfo,
      dtx:    decodedTx,
    }
  }

  // kick-off the search - asynchronously
  startTheSearch() {
    console.warn("startTheSearch()  props.path: ", this.props.path)
    console.warn("startTheSearch()  props.rhs: ",  this.props.rhs)

    // really, state.searchStarted takes too long to ripple
    this.searchStarted = true

    const self = this
    execAsync( async function() {
      self.setState({searchStarted: true},
                    async () => {
                      const {success, content, txId, dtx} = await self.findThePost( self.props.path )

                      if ( success ) {
                          self.setState({contentPending: true, contentReady: true, content: content, txId: txId},
                              async function() {
                                  const {dtxString, contentEncrypted} = await maybeDecryptContent( dtx, self.props.getKeyForDecryption )

                                  self.setState({ decodedTx: dtxString,
                                                  contentPending: false })
                              }
                          );
                      } else {
                        alert("LinkJumper: SOMETHING WENT WRONG")
                      }
                    }
                  );
    }, 10);
  }

  // Initial logic will AVOID IDB entirely - to simulate experience of a NEWBIE
  async findThePost(path) {

    console.warn("findThePost(): path: ", path)
    console.warn("findThePost()... starting with genesis tx: ", GENESIS_APPEND)

    this.setState({phase: 1})

    const {success, nextTx} = await this.walkNameTreeWithoutDB(GENESIS_APPEND, path[0])
    //random mainnet tx (will fail testnet query): 40ff70b97334699d71a552ab5ea97c996fa8d64a9e0dc0041ec1701b10ed6914
    //const {success, nextTx} = await this.walkNameTreeWithoutDB("40ff70b97334699d71a552ab5ea97c996fa8d64a9e0dc0041ec1701b10ed6914", path[0])

    if ( !success ) {
      alert("oh well :(   Failed to find the domain")
      return {success: false}
    }

    this.setState({phase: 2})

    console.warn("NOW, we must walk the quarterlies...")
    const {success: success2, fullyDone, currentTx: currentTx2, nextTx: nextTx2, contentInfo: contentInfo, dtx: decodedTx2} = await this.walkQuarterliesWithoutDB(nextTx, path)

    if (!success2) {
      alert("oh well :(  Failed to find the owner and/or quarterly")
      return {success: false}
    }

    if ( fullyDone ) {
      console.warn("BTW: contentInfo: ", contentInfo)
      return {success: true, content: contentInfo.inputParams.content, txId: currentTx2, dtx: decodedTx2 }
    }

    this.setState({phase: 4})

    console.warn("GREAT so far. About to walk the periodicals (much like quarterlies)...")
    const {success: success3, fullyDone: fullyDone3, currentTx: currentTx3, nextTx: nextTx3, contentInfo: contentInfo3, dtx: decodedTx3} = await this.walkPeriodicalsWithoutDB(nextTx2, path)

    if (!success3) {
      alert("oh well :(  Failed to find the periodical")
      return {success: false}
    }

    if ( fullyDone3 ) {
      console.warn("BTW: contentInfo3: ", contentInfo3)
      return {success: true, content: contentInfo3.inputParams.content, txId: currentTx3, dtx: decodedTx3 }
    }

    this.setState({phase: 5})

    console.warn("Deepest T level: " + this.state.deepestTLevel)

    //T4
    console.warn("GREAT3 so far. Next, walk the ZIG-ZAGGING transients of level 4. Will look at tx " + nextTx3)
    const {success: success4, fullyDone: fullyDone4, currentTx: currentTx4, nextTx: nextTx4, contentInfo: contentInfo4, dtx: decodedTx4} = await this.walkTransientsWithoutDB(nextTx3, path, 6, 4, 4 === this.state.deepestTLevel)

    if (!success4) {
      alert("oh well :(  Failed to find the T4")
      return {success: false}
    }

    if ( fullyDone4 ) {
      console.warn("BTW: contentInfo4: ", contentInfo4)
      return {success: true, content: contentInfo4.inputParams.content, txId: currentTx4, dtx: decodedTx4 }
    }

    this.setState({phase: 6})

    //T3
    console.warn("GREAT4 so far. Next, walk the ZIG-ZAGGING transients of level 3. Will look at tx " + nextTx4)
    const {success: success5, fullyDone: fullyDone5, currentTx: currentTx5, nextTx: nextTx5, contentInfo: contentInfo5, dtx: decodedTx5} = await this.walkTransientsWithoutDB(nextTx4, path, 7, 3, 3 === this.state.deepestTLevel)

    if (!success5) {
      alert("oh well :(  Failed to find the T3")
      return {success: false}
    }

    if ( fullyDone5 ) {
      console.warn("BTW: contentInfo5: ", contentInfo5)
      return {success: true, content: contentInfo5.inputParams.content, txId: currentTx5, dtx: decodedTx5 }
    }

    this.setState({phase: 7})

    //T2
    console.warn("GREAT5 so far. Next, walk the ZIG-ZAGGING transients of level 2. Will look at tx " + nextTx5)
    const {success: success6, fullyDone: fullyDone6, currentTx: currentTx6, nextTx: nextTx6, contentInfo: contentInfo6, dtx: decodedTx6} = await this.walkTransientsWithoutDB(nextTx5, path, 8, 2, 2 === this.state.deepestTLevel)

    if (!success6) {
      alert("oh well :(  Failed to find the T2")
      return {success: false}
    }

    if ( fullyDone6 ) {
      console.warn("BTW: contentInfo6: ", contentInfo6)
      return {success: true, content: contentInfo6.inputParams.content, txId: currentTx6, dtx: decodedTx6 }
    }

    this.setState({phase: 8})

    //T1
    console.warn("GREAT6 so far. Next, walk the ZIG-ZAGGING transients of level 2. Will look at tx " + nextTx6)
    const {success: success7, fullyDone: fullyDone7, currentTx: currentTx7, nextTx: nextTx7, contentInfo: contentInfo7, dtx: decodedTx7} = await this.walkTransientsWithoutDB(nextTx6, path, 9, 1, 1 === this.state.deepestTLevel)

    if (!success7) {
      alert("oh well :(  Failed to find the T1")
      return {success: false}
    }

    if ( fullyDone7 ) {
      console.warn("BTW: contentInfo7: ", contentInfo7)
      return {success: true, content: contentInfo7.inputParams.content, txId: currentTx7, dtx: decodedTx7 }
    }

    this.setState({phase: 9})

    //T0
    console.warn("GREAT7 so far. Next, walk the ZIG-ZAGGING transients of level 2. Will look at tx " + nextTx7)
    const {success: success8, fullyDone: fullyDone8, currentTx: currentTx8, nextTx: nextTx8, contentInfo: contentInfo8, dtx: decodedTx8} = await this.walkTransientsWithoutDB(nextTx7, path, 10, 0, 0 === this.state.deepestTLevel)

    if (!success8) {
      alert("oh well :(  Failed to find the T0")
      return {success: false}
    }

    if ( fullyDone8 ) {
      console.warn("BTW: contentInfo8: ", contentInfo8)
      return {success: true, content: contentInfo8.inputParams.content, txId: currentTx8, dtx: decodedTx8 }
    }

    this.setState({phase: 10})

    alert("ERROR: We should've been done by now")
  }

  closeLoader() {
    this.setState({showLoader: false})
  }

  explainMore() {
    this.setState({showMore: true})
  }
  closeExplainMore() {
    this.setState({showMore: false})
  }

  render() {

    if ( ! this.state.searchStarted && !this.searchStarted ) {
      this.startTheSearch( )
    }

    // ***  DEVICE-DEPENDENT STYLING  ***
    // For mobile, "detect" landscape screen mode
    const landscape = this.props.isMobile ? window.innerWidth > window.innerHeight : false
    // For mobile, shrink the About, Security, and Glossary modals
    const modalClassName   = this.props.isMobile ?
                                    (landscape ?
                                            "modalMobileLandscape"
                                        :
                                            "modalMobilePortrait"
                                    )
                                :
                                    ""
    const modalContentClassName = this.props.isMobile ? "modalContentMobile" : ""
    const modalBottomClassName  = this.props.isMobile ? "modalBottomMobile"  : ""

    const progressDisplay = <>
                              <b style={{color:'blue'}}>{this.state.phase}/{this.state.deepestPhase}</b>
                            </>

    // icon sizes: mini tiny small large big huge massive
    const shzvrsExplainer = <Icon  size='large' style={{color: bshzColors.purple}} name='question circle outline' />
    const shzvrsExplainerText =
                                <>
                                  <p>
                                    It's early days for the <b style={{color:'blue'}}>ShizzleVerse</b>, a
                                    Bitcoin-powered world-wide web - where you actually <b>own</b> your identity, and
                                    profit directly from followers who appreciate your content.
                                  </p>
                                  <p>
                                    You're fetching Bitcoin-stored date "by yourself". In the future, service
                                    providers will compete to give you a faster experience.
                                  </p>
                                </>
    //<Label onClick={this.explainMore} color='violet' style={{color:bshzColors.purple}}>Learn more</Label>
    const popupShzVrsExplainer =  <>
                                    <Popup style={{backgroundColor: bshzColors.ltYellow}} trigger={shzvrsExplainer} wide="very" content={shzvrsExplainerText} hideOnScroll/>
                                    <span onClick={this.explainMore} style={{color:'blue', cursor:'pointer'}}>Learn more</span>
                                  </>

    const maybeShowMore = this.state.showMore ?

          <Modal size='large' dimmer='blurring' centered className={modalClassName}  open={true} style={{backgroundColor: bshzColors.purple, borderRadius: '20px'}}>

            <Modal.Header style={{textAlign: 'center', backgroundColor: bshzColors.purple, borderRadius: '20px'}}>
                <span style={{color: bshzColors.yellow}}> More about the ShizzleVerse </span>
            </Modal.Header>

            <Modal.Content className={modalContentClassName} scrolling style={{backgroundColor: bshzColors.ltPurple}}>
              What is going on? This is the <b style={{color:'blue'}}>ShizzleVerse</b>, and someone has shared a link
              to some content posted there, and we're retrieving it. You can think of it as a World Wide Web built
              on <b style={{color:'blue'}}>Bitcoin</b>, but it's even <b>more</b> than that.
              <br></br><br></br>
              Content posted on the <b style={{color:'blue'}}>ShizzleVerse</b> is different from what you're used to, because
              it's posted by people who <b>own</b> their digital identities.

            </Modal.Content>

            <Modal.Actions className={modalBottomClassName} style={{backgroundColor: bshzColors.purple, borderRadius: '0px 0px 20px 20px'}}>
                      <div style={{textAlign: 'center'}}>
                        <Button positive onClick={ this.closeExplainMore }
                                content='Got it'/>
                        <Button positive style={{color:'yellow'}} onClick={ this.props.handleJumpToHome }
                                content='Get started in the ShizzleVerse'/>
                      </div>
            </Modal.Actions>
          </Modal>
        :
          null

    const snailSymbol     = <span style={{fontSize:'2.0rem'}}>&#x1F40C;</span>
    const snailSymbolDim  = <span style={{fontSize:'2.0rem', opacity:'0.4'}}>&#x1F40C;</span>
    const turtleSymbol    = <span style={{fontSize:'2.0rem'}}>&#x1F422;</span>
    const turtleSymbolDim = <span style={{fontSize:'2.0rem', opacity:'0.4'}}>&#x1F422;</span>
    const rocketSymbol    = <span style={{fontSize:'2.0rem'}}>&#x1F680;</span>
    const rocketSymbolDim = <span style={{fontSize:'2.0rem', opacity:'0.4'}}>&#x1F680;</span>
    const theTurtle = this.state.dbPerms ? turtleSymbolDim : turtleSymbol
    const theRocket = this.state.dbPerms ? rocketSymbol : rocketSymbolDim
    const permissionCheckbox =  <>
                                  Allow us to save data to your browser: &nbsp;
                                  {theTurtle} &nbsp;
                                  <Checkbox toggle checked={this.state.dbPerms} onClick={this.toggleDbPerms}/>
                                  &nbsp; {theRocket}
                                  
                                </>

    // icon sizes: mini tiny small large big huge massive
    const scanExplainer = <Icon  size='large' style={{color: bshzColors.purple}} name='question circle outline' />
    const popUpScanExplainerText = this.state.dbPerms ?
                                  <>
                                    <p>
                                      By giving permission for this application to store some
                                      Bitcoin data in your browser, we may be able to speed-up things a bit.
                                    </p>
                                    <p>
                                      It doesn't take up much room, and you can have us delete it at any time.
                                    </p>
                                  </>
                                :
                                  <>
                                    <p>
                                      By giving permission for this application to store some
                                      Bitcoin data in your browser, we'll be able to speed-up things
                                      the <b>next</b> time.
                                    </p>
                                    <p>
                                      It doesn't take up much room, and you can have us delete it at any time.
                                    </p>
                                  </>
    const popupScanExplainer = <Popup style={{backgroundColor: bshzColors.ltYellow}} trigger={scanExplainer} wide="very" content={popUpScanExplainerText} hideOnScroll/>

    const maybeShowSpeedupOption = this.state.dbPerms ?
          <>
            We're cacheing intermediate transactions to your browser database.
          </>
        :
          <>
            Want to speed-up ShizzleVerse links?
          </>

    //const destinationLink = <span style={{color: bshzColors.purple}}>shizzleverse://{this.props.rhs}</span>
    const destinationLink =
    this.state.phase === 0 ?
            <>
            <span style={{color: bshzColors.purple}}>shizzleverse://<span style={{color: 'grey'}}>{this.state.linkRHS[0]}</span>
            </span>
            </>
          :
            this.state.phase === 1 ?  // searching for domain
                    <>
                    <span style={{color: bshzColors.purple}}>shizzleverse://<b style={{color:'blue'}}>{this.props.path[0]}</b></span>
                    <span style={{color: 'grey'}}>/{this.state.linkRHS[1]}</span>
                    </>
                  :
                    this.state.phase === 2 ?  // searching for ownerCount
                            <>
                            <span style={{color: bshzColors.purple}}>shizzleverse://{this.props.path[0]}/</span>
                            <b style={{color: 'blue'}}>{this.props.path[1]}</b>
                            <span style={{color: 'grey'}}>/{this.state.linkRHS[2]}</span>
                            </>
                          :
                            this.state.phase === 3 ?  // searching for Quarterly
                                    <>
                                    <span style={{color: bshzColors.purple}}>shizzleverse://{this.props.path[0]}/{this.props.path[1]}/</span>
                                    <b style={{color: 'blue'}}>{this.props.path[2]}</b>
                                    <span style={{color: 'grey'}}>/{this.state.linkRHS[3]}</span>
                                    </>
                                  :
                                    this.state.phase === 4 ?  // searching for /U/Periodical
                                            <>
                                            <span style={{color: bshzColors.purple}}>shizzleverse://{this.props.path[0]}/{this.props.path[1]}/{this.props.path[2]}/</span>
                                            <b style={{color: 'blue'}}>{this.props.path[3]}/{this.props.path[4]}</b>
                                            <span style={{color: 'grey'}}>/{this.state.linkRHS[5]}</span>
                                            </>
                                          :
                                            this.state.phase === 5 ?  // searching for /T/T4
                                                    <>
                                                      <span style={{color: bshzColors.purple}}>shizzleverse://{this.props.path[0]}/{this.props.path[1]}/{this.props.path[2]}/{this.props.path[3]}/{this.props.path[4]}/</span>
                                                      <b style={{color: 'blue'}}>{this.props.path[5]}/{this.props.path[6]}</b>
                                                      <span style={{color: 'grey'}}>/{this.state.linkRHS[7]}</span>
                                                    </>
                                                  :
                                                    this.state.phase === 6 ?  // searching for T3
                                                            <>
                                                              <span style={{color: bshzColors.purple}}>shizzleverse://{this.props.path[0]}/{this.props.path[1]}/{this.props.path[2]}/{this.props.path[3]}/{this.props.path[4]}/{this.props.path[5]}/{this.props.path[6]}/</span>
                                                              <b style={{color: 'blue'}}>{this.props.path[7]}</b>
                                                              <span style={{color: 'grey'}}>/{this.state.linkRHS[8]}</span>
                                                            </>
                                                          :
                                                            this.state.phase === 7 ?  // searching for T2
                                                                    <>
                                                                      <span style={{color: bshzColors.purple}}>shizzleverse://{this.props.path[0]}/{this.props.path[1]}/{this.props.path[2]}/{this.props.path[3]}/{this.props.path[4]}/{this.props.path[5]}/{this.props.path[6]}/{this.props.path[7]}/</span>
                                                                      <b style={{color: 'blue'}}>{this.props.path[8]}</b>
                                                                      <span style={{color: 'grey'}}>/{this.state.linkRHS[9]}</span>
                                                                    </>
                                                                  :
                                                                    this.state.phase === 8 ?  // searching for T1
                                                                            <>
                                                                              <span style={{color: bshzColors.purple}}>shizzleverse://{this.props.path[0]}/{this.props.path[1]}/{this.props.path[2]}/{this.props.path[3]}/{this.props.path[4]}/{this.props.path[5]}/{this.props.path[6]}/{this.props.path[7]}/{this.props.path[8]}/</span>
                                                                              <b style={{color: 'blue'}}>{this.props.path[9]}</b>
                                                                              <span style={{color: 'grey'}}>/{this.state.linkRHS[10]}</span>
                                                                            </>
                                                                          :
                                                                            this.state.phase === 9 ?  // searching for T0
                                                                                    <>
                                                                                      <span style={{color: bshzColors.purple}}>shizzleverse://{this.props.path[0]}/{this.props.path[1]}/{this.props.path[2]}/{this.props.path[3]}/{this.props.path[4]}/{this.props.path[5]}/{this.props.path[6]}/{this.props.path[7]}/{this.props.path[8]}/{this.props.path[9]}/</span>
                                                                                      <b style={{color: 'blue'}}>{this.props.path[10]}</b>
                                                                                    </>
                                                                                  :                           // search complete
                                                                                    <>
                                                                                      <span style={{color: bshzColors.purple}}>shizzleverse://{this.props.path[0]}/{this.props.path[1]}/{this.props.path[2]}/{this.props.path[3]}/{this.props.path[4]}/{this.props.path[5]}/{this.props.path[6]}/{this.props.path[7]}/{this.props.path[8]}/{this.props.path[9]}/{this.props.path[10]}</span>
                                                                                    </>
    // loader size:   mini tiny small medium large big huge massive
    //FIXME: also try: <Icon size='huge' loading name='circle notch' color='purple'/>
    // also: cog, sun outline, sun, sync, spinner, compass,
    //       volleyball ball, plus circle, minus circle,plus, chain, ban, play,
    //       certificate, adjust, compress
    const maybeLoader = this.state.showLoader ?
                            <>
                                <div style={{backgroundColor:bshzColors.ltPurple}}>
                                  <Icon size='huge' loading name='plus circle' color='purple'/>
                                  <br></br>
                                  <Loader indeterminate inline style={{color: "blue"}} active size='big'>
                                    Now retrieving the ShizzleVerse link...
                                    <br></br>
                                    {destinationLink}
                                    <br></br>
                                    {progressDisplay}
                                    <br></br>
                                  </Loader>
                                  <br></br>
                                  <br></br>

                                </div>
                            </>
                          :
                            <>showLoader is false</>

    const maybeShowIdbOptions = this.state.idbSupported && !this.state.contentReady ?
          <>
            <div style={{margin:'0px 0px -10px 0px', padding:'0px'}}>
            {maybeShowSpeedupOption}  &nbsp; {popupScanExplainer}
            </div>
            <div style={{margin:'-10px 0px 0px 0px', padding:'0px'}}>
            {permissionCheckbox}
            </div>
          </>
        :
          <></>

    //FIXME: if we're not yet done with the search, offer option to
    //       revoke permission AFTER we're done retrieving the post
    const maybeShowAreYouSure = this.state.showAreYouSureModal ?

          <Modal size='fullscreen' centered className={modalClassName}  open={true} style={{backgroundColor: bshzColors.purple, borderRadius: '20px'}}>

            <Modal.Header style={{textAlign: 'center', backgroundColor: bshzColors.purple, borderRadius: '20px'}}>
                <span style={{color: bshzColors.yellow}}> Delete Cached Data ? </span>
            </Modal.Header>

            <Modal.Content className={modalContentClassName} scrolling style={{backgroundColor: bshzColors.ltPurple}}>
              Are you <b>sure</b> you want to <b>delete</b> Bitcoin data from your browser (data which may help us retrieve the next link you look for)?
            </Modal.Content>

            <Modal.Actions className={modalBottomClassName} style={{backgroundColor: bshzColors.purple, borderRadius: '0px 0px 20px 20px'}}>
                      <div style={{textAlign: 'center'}}>
                        <Button negative onClick={ this.revokePermission }
                                content='Yes'/>
                        <Button positive onClick={ this.closeAreYouSureModal }
                                content='Never mind'/>
                      </div>
            </Modal.Actions>
          </Modal>
        :
          null



    const myElements = [ // flat array of nodes and edges
          { // node n1
            group: 'nodes', // 'nodes' for a node, 'edges' for an edge
            // NB the group field can be automatically inferred for you but specifying it
            // gives you nice debug messages if you mis-init elements
      
      
            data: { // element data (put json serialisable dev data here)
              id: 'n1', // mandatory (string) id for each element, assigned automatically on undefined
              parent: 'nparent', // indicates the compound node parent id; not defined => no parent
              // (`parent` can be effectively changed by `eles.move()`)
            },
      
            // scratchpad data (usually temp or nonserialisable data)
            scratch: {
              _foo: 'bar' // app fields prefixed by underscore; extension fields unprefixed
            },
      
            position: { // the model position of the node (optional on init, mandatory after)
              x: 100,
              y: 100
            },
      
            selected: false, // whether the element is selected (default false)
      
            selectable: true, // whether the selection state is mutable (default true)
      
            locked: false, // when locked a node's position is immutable (default false)
      
            grabbable: true, // whether the node can be grabbed and moved by the user
      
            pannable: false, // whether dragging the node causes panning instead of grabbing
      
            classes: ['foo', 'bar'], // an array (or a space separated string) of class names that the element has
      
            // DO NOT USE THE `style` FIELD UNLESS ABSOLUTELY NECESSARY
            // USE THE STYLESHEET INSTEAD
            style: { // style property overrides 
              'background-color': 'red'
            }
          },
      
          { // node n2
            data: { id: 'n2' },
            renderedPosition: { x: 200, y: 200 } // can alternatively specify position in rendered on-screen pixels
          },
      
          { // node n3
            data: { id: 'n3', parent: 'nparent' },
            position: { x: 123, y: 234 }
          },
      
          { // node n4
            data: { id: 'n4' },
            position: { x: 223, y: 334 }
          },
      
          { // node nparent
            data: { id: 'nparent' }
          },
      
          { // edge e1
            data: {
              id: 'e1',
              // inferred as an edge because `source` and `target` are specified:
              source: 'n1', // the source node id (edge comes from this node)
              target: 'n2'  // the target node id (edge goes to this node)
              // (`source` and `target` can be effectively changed by `eles.move()`)
            },
      
            pannable: true // whether dragging on the edge causes panning
          },
      
          { // edge e1
            data: {
              id: 'e2',
              // inferred as an edge because `source` and `target` are specified:
              source: 'n3', // the source node id (edge comes from this node)
              target: 'n4'  // the target node id (edge goes to this node)
              // (`source` and `target` can be effectively changed by `eles.move()`)
            },
      
            pannable: true // whether dragging on the edge causes panning
          },
          { // edge e1
            data: {
              id: 'e3',
              // inferred as an edge because `source` and `target` are specified:
              source: 'n2', // the source node id (edge comes from this node)
              target: 'n3'  // the target node id (edge goes to this node)
              // (`source` and `target` can be effectively changed by `eles.move()`)
            },
      
            pannable: true // whether dragging on the edge causes panning
          }
        ]
    // THIS 'WORKS' (renders):
    //<CytoscapeComponent elements={myElements} style={ { width: '600px', height: '600px' } } />;


    //jumpToLinkedAsset={ this.jumpToTx }
    //shareLinkToContent={ this.shareLinkToPost }
    //{hexStringToAscii(this.state.content)}  simplest display of content
    const loaderOrContent = this.state.contentReady  ?
          <>
            <div style={{textAlign: 'center', backgroundColor:bshzColors.ltPurple}}>
              <br></br>
              <span style={{color: 'blue'}}>shizzleverse://{this.props.rhs}</span>
            </div>
            <div style={{backgroundColor:bshzColors.ltPurple}}>
              <br></br>
              <ContentPresenter
                  contentPresenterTx={this.state.txId}
                  domainOfInterest={this.props.path[0]}
                  parent={this}
                  decodedTx={this.state.decodedTx}
                  guestPost={this.state.isGuestPost}

                  submode={true}
                  contentPending={this.state.contentPending}
                  contentEncrypted={this.state.contentEncrypted}
                  />
              <br></br>
            </div>
          </>
        :
          <>
            <div style={{textAlign: 'center'}}>

                <br></br>
                Please wait while we retrieve the <b style={{color:'blue'}}>Bitcoin transactions</b> that lead to this post...

                <br></br>
                <br></br>

                {maybeLoader}

            </div>
          </>

    const absoluteBuffer = this.state.contentReady ?
        <>
        </>
      :
        <>
          <br></br>
          <br></br>
          <br></br>
        </>
    const absoluteLearnMore =
        <div style={{position:'absolute', margin:'8px'}}>
          {absoluteBuffer}
          {popupShzVrsExplainer}
        </div>

    // NOTE: the pop-up explainer icon has absolute position:
    //       see: https://stackoverflow.com/questions/16236142/two-divs-on-same-position
    return (
      <>
      <div style={{position:'relative'}}>
        {absoluteLearnMore}
        {loaderOrContent}
      </div>

      {maybeShowIdbOptions}

      {maybeShowAreYouSure}
      {maybeShowMore}
      </>
          )
  }
}

export default LinkJumper;
