import React from 'react';
import { Grid, Icon, Popup, Transition } from 'semantic-ui-react'

import { openDB, readSetting, saveSetting, } from './buildShizzle.js';

import { execAsync } from './commonFuncs';

class ShizzleMasthead extends React.PureComponent {
    constructor(props) {
        super(props);

        this.bshzPurple             = '#8313e2';
        this.bshzYellow             = "#fff300";
        this.bshzLtPurp             = '#e7d0fb';
        this.bshzLightYellow        = "#fffccc";

        this.shizzleVerseNotBold = <>ShizzleVerse<small>&trade;</small></>;
        this.bitShizzleNotBold   = <>BitShizzle<small>&trade;</small></>;

        this.browserExplanation =
        <>
            <p>
                The {this.bitShizzleNotBold} Bitcoin browser allows you to claim, explore, and build on Bitcoin Identities and WebSites in the {this.shizzleVerseNotBold}.
            </p>
        </>

        this.state = {
                userHasClickedLogoutSeveralTimes: false,  //NOTE: at LEAST
                jiggleLogin:       true,
                loginAnimation:    'flash',

                userHasSeenWallet: false,
                jiggleWallet:      true,
                walletAnimation:   'flash', //'shake',

                userHasSeenSidebar:false,
                jiggleSidebar:     true,
                sidebarAnimation:  'flash',

                userHasSeenDialogs: false,
                jiggleDialogs:      true,
                dialogAnimation:    'flash'
        }

        this.handleWalletClick   = this.handleWalletClick.bind(this);
        this.handleSidebarClick  = this.handleSidebarClick.bind(this);
        this.handleLogInOutClick = this.handleLogInOutClick.bind(this);

        this.handleGuestPostClick= this.handleGuestPostClick.bind(this);

        this.handleDialogClick   = this.handleDialogClick.bind(this);

        this.reanimateLogin      = this.reanimateLogin.bind(this);
        this.reanimate           = this.reanimate.bind(this);
    }

    // Verify (or forget) password
    async handleLogInOutClick() {
        console.log("Log in/out click. Already been verified? ", this.props.passwordVerified)

        // only count logOUTs (since THEN we KNOW user has already succeeded in logging-in)
        if ( this.props.passwordVerified ) {

            // read setting 'numLogoutClicks', INCREMENT it, then save
            const numLogoutClicksSetting = await readSetting(await openDB(), "numLogoutClicks")
            console.log("logged-out setting:  numLogoutClicks: ", numLogoutClicksSetting)

            // we know the setting exists. We made sure in componentDidMount()
            const numLogoutClicks = numLogoutClicksSetting.value + 1
            await saveSetting(await openDB(), "numLogoutClicks", numLogoutClicks)

            // If user has clicked logout more than 4 times, set this state
            this.setState({ userHasClickedLogoutSeveralTimes: numLogoutClicks > 4 })
        }

        this.props.logInOutCallback()
    }

    // Wallet functions: import/export, send/receive
    async handleWalletClick() {
        if ( !this.state.userHasSeenWallet ) {
            this.setState({userHasSeenWallet: true})
            await saveSetting(await openDB(), "seenWallet", true)
        }
        console.warn("SETTINGS (wallet). calling props.walletCallback()")
        this.props.walletCallback()
    }

    // Wallet functions: import/export, send/receive
    async handleSidebarClick() {
        if ( !this.state.userHasSeenSidebar ) {
            this.setState({userHasSeenSidebar: true})
            await saveSetting(await openDB(), "seenSidebar", true)
        }
        console.warn("SETTINGS (sidebar). calling props.sidebarCallback()")
        this.props.sidebarCallback()
    }

    async handleGuestPostClick() {
        console.warn("GuestPost icon clicked! Calling callback...")
        this.props.guestPostIconClickCallback()
    }

    async handleDialogClick() {
        if ( !this.state.userHasSeenDialogs ) {
            this.setState({userHasSeenDialogs: true})
            await saveSetting(await openDB(), "seenDialogs", true)
        }
        console.warn("Dialog icon clicked! Calling callback...")
        this.props.dialogIconClickCallback()
    }

    reanimateLogin() {

        // loop to re-start animation every few seconds
        // alternating the animation type
        const parent = this
        execAsync( async function() {
            await new Promise(resolve => setTimeout(resolve, 500))

            // alternate between animations
            const animType    = parent.state.jiggleLogin  ? "jiggle" : "bounce"   // "flash",  "tada"

            // toggle the animation on/off
            parent.setState({ jiggleLogin: ! parent.state.jiggleLogin,
                              loginAnimation: animType,
                            })
        })

    }

    reanimate() {
        // loop to re-start animation every 3500 seconds
        // alternating the animation type
        const parent = this
        execAsync( async function() {
            await new Promise(resolve => setTimeout(resolve, 2000))

            // alternate between animations
            const animType     = parent.state.jiggleWallet  ? "jiggle" : "bounce" // "flash" : "bounce" //"jiggle" : "tada"
            const dlgAnimType  = parent.state.jiggleDialogs ? "jiggle" : "bounce" // "flash" : "bounce" //"jiggle" : "tada"
            const sideAnimType = parent.state.jiggleSidebar ? "jiggle" : "bounce" // "flash" : "bounce" //"jiggle" : "tada"

            // toggle the animation on/off
            parent.setState({ jiggleWallet: ! parent.state.jiggleWallet,
                              walletAnimation: animType,

                              jiggleSidebar: !parent.state.jiggleSidebar,
                              sidebarAnimation: sideAnimType,

                              jiggleDialogs: ! parent.state.jiggleDialogs,
                              dialogAnimation: dlgAnimType,
                            })
        })

    }

    async componentDidMount() {
        const numLogoutClicksSetting = await readSetting(await openDB(), "numLogoutClicks")
        if ( numLogoutClicksSetting !== null ) {

            const numLogoutClicks = numLogoutClicksSetting.value

            // If user has clicked logout more than 4 times, set this state
            this.setState({ userHasClickedLogoutSeveralTimes: numLogoutClicks > 4 })
        } else {
            // initialize the setting
            await saveSetting(await openDB(), "numLogoutClicks", 0)
        }

        const userHasSeenWallet = await readSetting(await openDB(), "seenWallet")
        if ( userHasSeenWallet !== null ) {
            console.log("The user HAS seen the wallet (clicked the icon)")
            this.setState({userHasSeenWallet: true})
        }

        const userHasSeenSidebar = await readSetting(await openDB(), "seenSidebar")
        if ( userHasSeenSidebar !== null ) {
            console.log("The user HAS seen the sidebar (clicked the icon)")
            this.setState({userHasSeenSidebar: true})
        }

        const userHasSeenDialogs = await readSetting(await openDB(), "seenDialogs")
        if ( userHasSeenDialogs !== null ) {
            console.log("The user HAS seen the dialogs (clicked the icon)")
            this.setState({userHasSeenDialogs: true})
        }
    }

    componentWillUnmount() {
        // fix Warning: Can't perform a React state update on an unmounted component
        // see: https://stackoverflow.com/questions/53949393/cant-perform-a-react-state-update-on-an-unmounted-component
        this.setState = (state,callback)=>{
            return;
        };
    }

    render() {

        const sverseExplanation =
        <>
            <p>
                The {this.shizzleVerseNotBold} is an emerging World Wide Web - built, and run on Bitcoin.
            </p>
            <p>
                Just like the World Wide Web, no single entity owns or controls the {this.shizzleVerseNotBold}.
                It grows from the creativity of anyone and everyone who stakes a claim to a <b>Bitcoin</b> identity or domain name.
            </p>
            <p>
                A Bitcoin identity (part of the {this.shizzleVerseNotBold}) is like a Twitter+Email account,
                except that it's built with Bitcoin, and you can <b>own</b> it.
            </p>
        </>

        // offset the background image to match the homepage scrolling
        const bgPos = "0px " + Math.floor(  -1 * this.props.scrollY + 11 ) + "px"
        //const bgPos = "0px 11px"

        const visible = this.props.effectVisible
        const tagline = this.props.qualityTxs ?
                <>
                    Quality Bitcoin Transactions &nbsp;-&nbsp; since 19:30
                </>
            :
                <>
                    Your&nbsp;
                    <Popup style={{backgroundColor: this.bshzLightYellow}} trigger={<span>Gateway</span>} wide="very" content={this.browserExplanation} hideOnScroll/>
                    &nbsp;to the&nbsp;
                    <Popup style={{backgroundColor: this.bshzLightYellow}} trigger={<span><b>ShizzleVerse</b><small>&trade;</small></span>} wide="very" content={sverseExplanation} hideOnScroll/>
                </>

        const headerClass =  this.props.blank ? "fixed-header-blank cont stack-top" : "fixed-header cont stack-top"
        const bgCol = this.props.blank ? "white" : "#00000000"

        //tried: "log out"   (arrow pointing out of box)
        //       "sign in"   (arrow pointing into box)
        //const logInOrOutSymbol =  this.props.passwordVerified ? "log out" : "sign in"
        const logInOrOutSymbol =  this.props.passwordVerified ? "toggle on" : "toggle off"
        const logInOrOutDescription = this.props.passwordVerified ? "Log out (forget password)" : "Log in (verify wallet password)"
        const logIOIcon =  <Icon link size='large' color="yellow" name={logInOrOutSymbol} onClick={this.handleLogInOutClick}/>
        const logInOutIcon =<span>
                                <Transition transitionOnMount
                                        visible={this.state.jiggleLogin}
                                        animation={!this.props.passwordVerified ? this.state.loginAnimation : ''}
                                        duration={1000}
                                        onComplete={this.reanimateLogin} >
                                    {logIOIcon}
                                </Transition>
                            </span>
        const loginPopup = this.props.logInOutCallback === null ?
                            <></>
                        :
                            <Popup  style={{backgroundColor: this.bshzLightYellow}}
                                    position="bottom right"
                                    trigger={this.state.userHasClickedLogoutSeveralTimes ? logIOIcon : logInOutIcon }
                                    wide="very"
                                    content={logInOrOutDescription}
                                    hideOnScroll/>

        const homeDescription = "Back to Homepage"
        const homeIcon = <Icon link size='large' color="yellow" name='home' onClick={this.props.handleJumpToHome}/>
        const homePopup = <></>
        /*
                this.props.handleJumpToHome ?
                    <Popup  style={{backgroundColor: this.bshzLightYellow}}
                            position="bottom right"
                            trigger={homeIcon}
                            wide="very"
                            content={homeDescription}
                            hideOnScroll/>
                :
                    <></>
        */

        const wIcon = <Icon link size='large' color="yellow" name='money bill alternate' onClick={this.handleWalletClick}/>
        const walletIcon =  this.state.userHasSeenWallet ?
                            wIcon
                        :
                            <span>
                                <Transition transitionOnMount
                                            visible={this.state.jiggleWallet}
                                            animation={this.state.walletAnimation}
                                            duration={800}
                                            onComplete={this.reanimate} >
                                    {wIcon}
                                </Transition>
                            </span>
        const walletPopup = !this.props.walletCallback || this.props.walletCallback === null ?
                            <></>
                        :
                            <Popup  style={{backgroundColor: this.bshzLightYellow}}
                                    position="bottom right"
                                    trigger={walletIcon}
                                    wide="very"
                                    content="This is your browser wallet"
                                    hideOnScroll/>

        const beIcon = <Icon link size='large' color={this.props.sidebarIconColor ? this.props.sidebarIconColor : 'yellow' } name='bullseye' onClick={this.handleSidebarClick}/>
        const bullseyeIcon =  this.state.userHasSeenSidebar ?
                            beIcon
                        :
                            <span>
                                <Transition transitionOnMount
                                            visible={this.state.jiggleSidebar}
                                            animation={this.state.sidebarAnimation}
                                            duration={800}
                                            onComplete={this.reanimate} >
                                    {beIcon}
                                </Transition>
                            </span>
        const sidebarPopup = this.props.sidebarCallback === null ?
                            <>null sidebar callback !</>
                        :
                            this.props.passwordVerified ?
                                <Popup  style={{backgroundColor: this.bshzLightYellow}}
                                    position="bottom right"
                                    trigger={bullseyeIcon}
                                    wide="very"
                                    content="Opens the side control panel"
                                    hideOnScroll/>
                            :
                                <></>

        const guestPostIcon = <Icon link size='large' color="yellow" name='comment' onClick={this.handleGuestPostClick}/>
        const guestPostPopup = this.props.guestPostIconClickCallback && this.props.guestPostIconClickCallback !== null ?
                                    <Popup  style={{backgroundColor: this.bshzLightYellow}}
                                            position="bottom left"
                                            trigger={guestPostIcon}
                                            wide="very"
                                            content={this.props.guestPostHoverText}
                                            hideOnScroll/>
                                :
                                    <></>

        const dlgIcon = <Icon link size='large' color="yellow" name='envelope' onClick={this.handleDialogClick}/>
        const dialogIcon =  this.state.userHasSeenDialogs ?
                            dlgIcon
                        :
                            <span>
                                <Transition transitionOnMount
                                            visible={this.state.jiggleDialogs}
                                            animation={this.state.dialogAnimation}
                                            duration={1500}
                                            onComplete={this.reanimate} >
                                    {dlgIcon}
                                </Transition>
                            </span>
        const showDialogIconPropertyDefined = typeof this.props.showDialogIcon !== 'undefined'
        const dontShowDialogIcon =  typeof this.props.dialogIconClickCallback === 'undefined' ||
                                    !showDialogIconPropertyDefined || !this.props.showDialogIcon
        const dialogPopup = dontShowDialogIcon ?
                            <></>
                        :
                            <Popup  style={{backgroundColor: this.bshzLightYellow}}
                                    position="bottom left"
                                    trigger={dialogIcon}
                                    wide="very"
                                    content={this.props.dialogHoverText}
                                    hideOnScroll/>


        //NOTE: stack-top sets z-index to 9 - placing it above main body of page
        //jiggle, **flash, *shake, tada?, bounce  *pulse?
        //bounce, shake, jiggle worked on Grid.Row.
        //     Glow, browse, drop   too, but, not interesting
        return (
            <>
                <div className={headerClass} style={{marginTop: "-17px", backgroundPosition: bgPos, backgroundColor: bgCol }}>
                <table style={{width: "100%", padding: 0, margin: 0, marginBottom: "-20px"}}>
                    <tbody>
                    <tr>
                        <td style={{width: "14%"}}></td>
                        <td style={{width: "30%", textAlign: "right", paddingTop: 0, paddingBottom: 0, marginTop: 0, marginBottom: 0}}>
                                <img src="logo192.png" width="85px" height="85px" alt="BitShizzle Logo" />
                        </td>
                        <td style={{width: "30%", textAlign: "left", paddingTop: 0, paddingBottom: 0, marginTop: 0, marginBottom: 0, padding: 0}}>
                                <Grid.Row style={{height: "20px"}}>
                                    &nbsp;
                                </Grid.Row>

                                <Grid.Row style={{height: "48px"}}>
                                    <span style={{color: this.bshzPurple, fontSize: "300%"}}>
                                        Bit<i>Shizzle</i><small style={{ verticalAlign: "35%", fontSize: "65%"}}><small>&trade;</small></small>
                                    </span>
                                </Grid.Row>
                                <Transition visible={visible} animation='flash' duration={800}>
                                <Grid.Row style={{height: "40px", marginTop: -5}}>
                                    &nbsp;
                                    <span style={{color: this.bshzPurple, fontSize: "90%"}}>
                                    Own Your Bitcoin Identity
                                    </span>
                                </Grid.Row>
                                </Transition>
                        </td>
                        <td style={{width: "24%"}}> &nbsp; </td>
                    </tr>
                    </tbody>
                </table>

                <div style={{padding: 2, marginTop: -2, marginBottom: 0, textAlign: "center", color: this.bshzYellow, backgroundColor: this.bshzPurple}}>
                    {tagline}
                </div>
                <div className="settings-gear" style={{textAlign: "left"}}>
                    {homePopup}
                    {loginPopup}
                    {sidebarPopup}
                    {walletPopup}
                </div>
                <div className="right-side-icons" style={{textAlign: "right"}}>
                    {guestPostPopup}{dialogPopup}
                </div>

                </div>
            </>
        )
    }
}

export default ShizzleMasthead;