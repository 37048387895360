// minerPayments.js

// simple modal component to display payments to a miner - extracting coins (UTXOs), and timestamps from WoC queries


//  BUT: we need to know the BSV PRICE for each day - in order to convert payments to income in DOLLARS
//       The user can hand-enter prices for each day, or we can query CoinCodex.

// persists data to TWO+ IDB 'settings' tables:
//    - "unspentMinerPayouts_" + {minerAddress}
//    - "datePriceData"


import React, {Component} from 'react';

import { Button, Checkbox, Divider, Dropdown, Input, List, ListItem, Loader, Modal, Table } from 'semantic-ui-react'

// https://www.npmjs.com/package/react-semantic-ui-datepickers
// https://github.com/arthurdenner/react-semantic-ui-datepickers
import SemanticDatepicker from 'react-semantic-ui-datepickers';
import 'react-semantic-ui-datepickers/dist/react-semantic-ui-datepickers.css';


//const { buildBalanceReducingTx, buildConsolidatingTx } = require('./contractSupport.js')

import { getCurrentBlockInfo, getCurrentPriceInfo, openDB, getP2PKHsJson, readSetting, saveSetting, findAllSettings, deleteSetting } from './buildShizzle.js';

import { convertAddressToPKH } from './commonFuncs';

import { bshzColors } from './bshzColors';
//import { calcPenniesFromSats } from './commonReact';

const axios = require("axios");

const payoutsSettingLabelPrefix = 'unspentMinerPayouts_'


// Used to pace queries to WoC
// copied from providers.js
function mySleep(ms, message) {
	if ( message.length !== 0 ) {
		console.log("Sleeping " + ms + " ms " + message)
	}
    return new Promise(resolve => setTimeout(resolve, ms))
}

function compareByBlockTime( a, b ) {
  if ( a.blocktime < b.blocktime ){
    return -1;
  }
  if ( a.blocktime > b.blocktime ){
    return 1;
  }
  return 0;

}

class MinerPayments extends React.Component {
  constructor(props) {
    super(props);

    // props.bsvPriceUSD    - used for converting satoshi payments to dollars, cents (based on price for a given day)

    this.state = {
      showMinerModal: true,

      minerAddress: props.minerAddress,
      minerPKH: '',

      validAddress: false,
      disableButtons: false,

      progressText: '',

      showSummary: false,
      summaryText: '',

      totalBSV: 0,

      showPriceModal: false,
      dropList: null,
      paymentAnalysisResults: null,

      priceToUse: 0,
      dateToUse: '',

      showEndDateSetter: false,
      limitEndDate: false,
      endDateLimit: null,
      disableSetEndDateButton: true,
      rawEndDateValue: '',

      showStartDateSetter: false,
      limitStartDate: false,
      startDateLimit: null,
      disableSetStartDateButton: true,
      rawStartDateValue: '',

      showRecentAddressList: false,
      recentAddressLabelsSet: null,
      recentAddressesList: null,  // to be rendered within a list
    };

    this.closeIt                    = this.closeIt.bind(this);

    this.handleMinerAddress         = this.handleMinerAddress.bind(this);
    this.queryMinerAddress          = this.queryMinerAddress.bind(this);
      this.doTheQuery               = this.doTheQuery.bind(this);
      this.doReview                 = this.doReview.bind(this);
      this.collectPriceData         = this.collectPriceData.bind(this);

    this.persistUtxos               = this.persistUtxos.bind(this);
    this.getPersistedUtxos          = this.getPersistedUtxos.bind(this);
    this.deduplicatePersistedUtxos  = this.deduplicatePersistedUtxos.bind(this);

    this.autoFillPriceData          = this.autoFillPriceData.bind(this);

    this.queryForPaymentParams      = this.queryForPaymentParams.bind(this);
    this.reviewCachedPayments       = this.reviewCachedPayments.bind(this);

    this.logSatsMapElements         = this.logSatsMapElements.bind(this);
    this.logBlocksMapElements       = this.logBlocksMapElements.bind(this);

    this.fetchPaymentsToAddress     = this.fetchPaymentsToAddress.bind(this);

    this.closeSummary               = this.closeSummary.bind(this);
    this.closePriceModal            = this.closePriceModal.bind(this);

    this.handleDateChange           = this.handleDateChange.bind(this);
    this.handlePriceData            = this.handlePriceData.bind(this);
    this.setPriceData               = this.setPriceData.bind(this);
    this.clearPrice                 = this.clearPrice.bind(this);
    this.getPersistedDatePriceData  = this.getPersistedDatePriceData.bind(this);
    this.persistDatePriceData       = this.persistDatePriceData.bind(this);

    this.getDatePriceMap            = this.getDatePriceMap.bind(this);

    this.toggleStartDateLimit         = this.toggleStartDateLimit.bind(this);
    this.editStartDate                = this.editStartDate.bind(this);
    this.toggleShowStartDateModal     = this.toggleShowStartDateModal.bind(this);
    this.onStartDateChange            = this.onStartDateChange.bind(this);

    this.toggleEndDateLimit         = this.toggleEndDateLimit.bind(this);
    this.editEndDate                = this.editEndDate.bind(this);
    this.toggleShowEndDateModal     = this.toggleShowEndDateModal.bind(this);
    this.onEndDateChange            = this.onEndDateChange.bind(this);

    this.checkFilterStartDate       = this.checkFilterStartDate.bind(this);
    this.checkFilterEndDate         = this.checkFilterEndDate.bind(this);

    //this.maybeAddLabelToRecents     = this.maybeAddLabelToRecents.bind(this);
    this.updateRecentAddressLabels  = this.updateRecentAddressLabels.bind(this);
    this.showOtherAddresses         = this.showOtherAddresses.bind(this);
    this.handleAddressChosenFromList= this.handleAddressChosenFromList.bind(this);
    this.closeRecents               = this.closeRecents.bind(this);
  }

  async componentDidMount() {
    if ( this.props.minerAddress !== '' ) {
      this.handleMinerAddress( null, {value: this.props.minerAddress})
    }

    // Scour the IDB 'settingsAndAcks' table for aptly-named miner payments 'settings'
    const allSettings = await findAllSettings( await openDB() )
    console.log("All settings: ", allSettings)
    console.log("label: ", payoutsSettingLabelPrefix)
    const settingsOfInterest = new Set()
    for ( let setting of allSettings ) {
      if ( setting.name.startsWith( payoutsSettingLabelPrefix ) ) {
        settingsOfInterest.add( setting.name )
      }
    }

    console.warn("Settings of interest: ", settingsOfInterest)
    this.setState({recentAddressLabelsSet: settingsOfInterest})
  }

  async updateRecentAddressLabels( candidateLabel ) {

    this.state.recentAddressLabelsSet.add( candidateLabel )

    console.warn("recentAddressLabelsSet: ", this.state.recentAddressLabelsSet)
    //this.setState({recentAddressLabels: settingsOfInterest})
  }

  async handleAddressChosenFromList( address ) {
    console.log("handling address " + address)
    this.handleMinerAddress( null, {value: address})
    this.closeRecents()
  }

  async showOtherAddresses() {
    const labelsArray = Array.from( this.state.recentAddressLabelsSet )
//xxxxx
    const recentsList = labelsArray.map( (label) =>
      <ListItem key={label} style={{color:'blue'}}
                onClick={() => {this.handleAddressChosenFromList( label.substring(20) )}}>
        {label.substring(20)}
      </ListItem>
    );

    this.setState({recentAddressesList: recentsList, showRecentAddressList: true})
  }
  closeRecents() {
    this.setState({showRecentAddressList: false})
  }

  closeIt() {
    this.setState({showMinerModal: false})
  }

  // maps dateStamp to price
  // [ {date: '...', price: xxx}, {...}, ...]
  async getPersistedDatePriceData() {
    const datePriceJson = await readSetting(await openDB(), 'datePriceData')

    console.log("===>  datePriceJson: ", datePriceJson)

    if ( datePriceJson === null ) {
      return []
    }

    const datePriceObjArray = JSON.parse( datePriceJson.value )
    console.warn("json-parsed date-price array: ", datePriceObjArray)

    return datePriceObjArray
  }

  async persistDatePriceData(datePriceObjArray) {

    for ( const elem of datePriceObjArray ) {
      console.warn("persisting elem of { date: " + elem.date + ", price: " + elem.price + " }")
    }

    const datePriceJsonStr = JSON.stringify( datePriceObjArray )
    await saveSetting(await openDB(), 'datePriceData', datePriceJsonStr)

  }

  async getPersistedUtxos(label) {
    const minerUtxosJson = await readSetting(await openDB(), label)

    console.log("===>  minerUtxosJSON: ", minerUtxosJson)

    if ( minerUtxosJson === null ) {
      return []
    }

    const minerCoins = JSON.parse( minerUtxosJson.value )
    console.warn("json-parsed miner coins: ", minerCoins)

    return minerCoins
  }

  // handles inserting an ARRAY of utxos
  // WARNING: does not check for duplicates
  async persistUtxos( utxos, label ) {
    const db = await openDB()
    const minerUtxosJsonStr = await readSetting(db, label)

    console.log("minerPayments: persisted miner coins (stringified) BEFORE adding any: ", minerUtxosJsonStr)

    let minerCoins = []
    if ( minerUtxosJsonStr !== null ) {
        minerCoins = JSON.parse( minerUtxosJsonStr.value )
    }

    for ( const utxo of utxos ) {
      //NOTE: .satoshis is actually BSV <-----
      console.warn("persisting utxo of txId " + utxo.txId + " : " + utxo.outputIndex + "  sats: " + utxo.satoshis)
      minerCoins.push( utxo )
    }

    const newMinerUtxosJsonStr = JSON.stringify( minerCoins )
    await saveSetting(db, label, newMinerUtxosJsonStr)
  }

  handleMinerAddress(e, v) {

    //console.log("handleMinerAddress(): address now set to ", v.value);

    const pkh = convertAddressToPKH(v.value, "mainnet")
    if ( pkh === -1 ) {
      //FIXME: WARN user that this address is for the WRONG network
      this.setState({minerAddress: v.value, minerPKH: '', validAddress: false})
    } else if ( pkh !== null ) {
      this.setState({minerAddress: v.value, minerPKH: pkh, validAddress: true})
    } else {
      this.setState({minerAddress: v.value, minerPKH: '', validAddress: false})
    }
  }

  async fetchPaymentsToAddress(addr, network = 'test', startingBlockHeight = 0) {

    /*
    Parameter	Description
    network               The selected network: main or test.

    address               The address

    order                 Ordering: asc or desc; default is desc.

    limit                 Between 1 and 1000; default is 100.

    height                Starting block height for history; default is 0.

    token                 Provided next page token.
    */

    console.log("fetchPaymentsToAddress():")

    const API_PREFIX = 'https://api.whatsonchain.com/v1/bsv/'
    const limit = 30

    try {
      //WARNING: desc(end) doesn't seem to work as described. Will use asc(end)
      console.log("address confirmed history (from a starting height) QUERY STRING: ", `${API_PREFIX}/${network}/address/${addr}/confirmed/history?height=${startingBlockHeight}&limit=${limit}&order=asc`)
      let { data: {address, script, result} } = await axios.default.get(`${API_PREFIX}/${network}/address/${addr}/confirmed/history?height=${startingBlockHeight}&limit=${limit}&order=asc`);
      //let { data: {address, script, result} } = await axios.default.get(`${API_PREFIX}/${network}/address/${addr}/confirmed/history?height=836635&limit=5&order=asc`);

      console.warn("address: ", address)
      //console.warn("data: ", data)
      console.warn("script: ", script)
      console.warn("result:", result)

      let utxoArray = result.map((utxo) => ({
          txId: utxo.tx_hash,
          blockHeight: utxo.height,
          //outputIndex: utxo.tx_pos,
          //satoshis: utxo.value,
          //script: bsv.Script.buildPublicKeyHashOut(address).toHex(),
      }));

      console.log("utxoArray: ", utxoArray)

      if ( utxoArray.length === limit ) {
        alert("We query for utxos in limited batches (we're using a FREE api, and don't want to abuse it).\n\n"
            + "You'll want to repeat this query again, as we probably didn't get ALL of your utxos")
      }

      console.warn("We're about to query for details of " + utxoArray.length + " NEW utxos.")

      return utxoArray
    } catch (error) {
      console.error("error caught from query: ", error)
      alert("Whoops. No new payments were found. We MAY be up-to-date on payments (or, there was an error in the query)")
      return []
    }
  }

  async autoFillPriceData(cachedPaymentsReview) {
    const cpr = cachedPaymentsReview
/*
              numPayments: utxos.length,
              numDates: numDates,
              totalBSV: Math.round(( totalSats + Number.EPSILON) * 100000000) / 100000000,
              totalDollars: Math.round(( paymentsInDollars + Number.EPSILON) * 100) / 100,
              maxBlockHeight: maxBlockHeight,
              satsDateMap: satoshisDateMap,
              blockCountDateMap: blocksCountDateMap,
              txidMap: txidMap,
              daysWithoutPrice: unpricedDays,
              pricelessDays: pricelessDays,
*/
    console.warn("autoFillPriceData: BTW: cachedPaymentsReview = ", cachedPaymentsReview)
    //alert("We'll now grab Coincodex price data for days which we're missing price "
    //    + "data (when payments to " + this.state.minerAddress + " were made).")

    for ( const day of cpr.pricelessDays ) {
      console.warn("We need data for: " + day)
    }
    const firstDay = cpr.pricelessDays[0]
    const lastDay = cpr.pricelessDays[cpr.daysWithoutPrice - 1]
    console.warn("first day we need: " + firstDay)
    console.warn("last day we need: " + lastDay)

    const PRICE_PROVIDER_API_PREFIX = 'https://coincodex.com/api/coincodex'

    const queryString = `${PRICE_PROVIDER_API_PREFIX}/get_coin_history/bchsv/${firstDay}/${lastDay}/4`
    console.warn("BTW: here's the coin history QUERY STRING:\n\t", queryString)
    try {

      let { data: bchsv } = await axios.default.get(queryString);
      console.warn("priceDataArray: ", bchsv.BCHSV)

      const bchsvArray = bchsv.BCHSV
      console.warn("there are " + bchsvArray.length + " timestamped-price elements to examine...")

      const datePriceObjArray = await this.getPersistedDatePriceData()

      const entriesOfPriceless = []
      let numFound = 0
      for ( const day of cpr.pricelessDays ) {   
        let found = false
        for ( const elem of bchsvArray ) {
          if ( found ) {
            console.warn("Done with day " + day + ", so, breaking from inner loop <----")
            break
          }
          //console.log("element: ", elem)
          const tstamp = elem[0]
          //const price = elem[1]    moved to within matching conditional (below)
          let d = new Date(0)
          d.setUTCSeconds(tstamp)

          //FIXME: could peruse the typically-multiple readings for a given day, and choose the non-midnight one
          const dateOfPrice = d.toISOString().split('T')[0]
          //console.warn("--> datestamp: ", dateOfPrice)


          // appears as: 2024-01-21T12:00:00.000Z     (also: 06:00, 12:00, 18:00)  <---- inconsistent number of readings in response
          //console.warn("    Full timestamp: ", d.toISOString())


          //console.log("    price: ", price)


          if ( day === dateOfPrice ) {
            console.warn("--> datestamp: ", dateOfPrice)
            console.log("THIS MATCHES a priceless day!!!")
            const price = elem[1]
            console.log("    price: ", price)
            found = true
            entriesOfPriceless.push({date: dateOfPrice, price: price})
            datePriceObjArray.push({date: dateOfPrice, price: price})
            numFound++
            break
          }
        } // each response element

        if ( !found ) {
          console.error("  We Couldn't find data for THIS DAY: ", day)
        }

        console.log("---------")
      } // each 'priceless day'

      console.warn("SO, here are the entries we've filled: ", entriesOfPriceless)
      alert("check console. We found (and filled) " + numFound + " missing dates..")

      await this.persistDatePriceData( datePriceObjArray )

      // bounce the summary display - while we do a fresh review
      this.setState({showSummary: false}, this.doReview)

    } catch (error) {
      console.error("error caught from query: ", error)
      alert("Whoops. Check price api code, or provider")
      //return []
    }
  } // autoFillPriceData()

  async queryForPaymentParams(txId, height, network = 'main') {
    const API_PREFIX = 'https://api.whatsonchain.com/v1/bsv/'

    console.log("payment QUERY STRING: ", `${API_PREFIX}/${network}/tx/hash/${txId}`)
    let { data: response } = await axios.default.get(`${API_PREFIX}/${network}/tx/hash/${txId}`);

    console.warn("qFPP(): response: ", response)
    console.warn("response.blockHeight: ", response.blockheight)

    const txJson = response //JSON.parse( response )
    console.warn("json-parsed transaction: ", txJson)


    if ( txJson.blockheight !== height ) {
      console.warn("The response did NOT seem to be for the correct height. We got this instead: " + txJson.blockheight + " (instead of " + height + ")\n\nWill proceed anyway...")
      alert("The response did NOT seem to be for the correct height. We got this instead: " + txJson.blockheight + " instead of " + height + "\n\nWill proceed anyway...")
    }
    if ( txJson.txid !== txId ) {
      console.warn("The response did NOT seem to be for our txId. We got this instead: " + txJson.txid + ".\n\nWill BAIL for txId " + txId)
      alert("The response did NOT seem to be for our txId. We got this instead: " + txJson.txid + ".\n\nWill BAIL for txId " + txId)
      return
    }


    console.warn("retrieved blockTime: ", txJson.blocktime)
    var utcSeconds = txJson.blocktime;
    var d = new Date(0); // The 0 there is the key, which sets the date to the epoch
    d.setUTCSeconds(utcSeconds);
    console.log("Date: ", d, "\n\n")

    console.warn("retrieved time (perhaps a guess within the 10-ish minutes): ", txJson.time)
    console.warn("There are " + txJson.vout.length + " outputs to this tx")
    console.warn("Will look for a vout targeting PKH " + this.state.minerPKH)
    const vouts = txJson.vout
    //const vouts2 = response.vout
    console.warn("vouts: ", vouts)
    //console.error("vouts2: ", vouts2)
    //console.error("vouts[0]: ", vouts[0])
    let i = 0
    let foundOut = -1
    let foundSats = -1
    for ( const idx in vouts) {
      const vout = vouts[idx]
      //console.log("   loop: i: ", i)
      //console.log("   loop: idx: ", idx)
      //console.log("   loop:   vout: ", vout)
      //console.log("     vout.scriptPubKey: ", vout.scriptPubKey)
      //console.log("                     .hex: ", vout.scriptPubKey.hex)

      //if ( i != idx ) {
      //  console.error(" i != idx ")
      //  console.error(i + " != " + idx)
      //}

      const scriptPubKey = vout.scriptPubKey
      //console.log("scriptPubKey: ", scriptPubKey)
      const hex = scriptPubKey?.hex
      //console.log(".hex: ", hex)
      if ( hex.includes(this.state.minerPKH) ) {
        console.warn("  vout " + i + " points to the miner PKH. .n is " + vout.n)
        console.warn("       and it has .value of  " + (vout.value))
        foundOut = vout.n
        foundSats = vout.value      //FIXME:          TYPO: not foundSats, but, foundBSVamount              XXXX
        break
      }

      ++i
    }

    if ( foundOut === -1 ) {
      console.warn("found no vout related to PKH " + this.state.minerPKH + " - for THIS tx: " + txId
          + "\n\nPerhaps this is SENDING/emptying BSV from a previous payment.  <--")
    }

    const res = {
        outputIndex:  foundOut,
        satoshis:     foundSats,    //FIXME:          TYPO: BSV, not sats  (see above) <-------<<<          XXXX
        blocktime:    txJson.blocktime,
        blockDateStamp:   d,
    }

    console.warn("  RETURNING result: ", res)

    console.log("we've queried for important params of txId " + txId + "\n")

    return res
  }

  logSatsMapElements(value, key, map) {
    const theVal = Math.round((value + Number.EPSILON) * 100000000) / 100000000
    console.log("satoshisDateMap: " + `map.get('${key}') = ${theVal}`);
  }

  logBlocksMapElements(value, key, map) {
    console.log("blocksCountDateMap: " + `map.get('${key}') = ${value}`);
  }

  async getDatePriceMap() {
    const datePriceObjArray = await this.getPersistedDatePriceData()
    console.log("getDatePriceMap(): BTW: datePriceObjArray: ", datePriceObjArray)
    const datePriceMap = new Map()  // we use this temp map to construct an array (dropList)
    //let i = 0


//xxxx build-up a list of dates with duplicate price data

    for ( const elem of datePriceObjArray ){
      //console.warn("date element " + i + "(base-0)  of " + datePriceObjArray.length + ": ", elem)

      if ( typeof datePriceMap.get(elem.date) === 'undefined' ) {
        datePriceMap.set(elem.date, elem.price)
      } else {
        alert("gDPM(): date " + elem.date + " has multiple prices set (persisted in date-price 'setting')")
      }
      //++i
    }

    return datePriceMap
  }

  async collectPriceData() {
    // don't allow user to initiated a new query while one is ongoing
    this.setState({disableButtons: true})
    const paymentAnalysisResults = await this.reviewCachedPayments([])

    console.log("results of review: ", paymentAnalysisResults)
    /*
    return {
      numPayments: utxos.length,
      numDates: numDates,
      totalBSV: Math.round(( totalSats + Number.EPSILON) * 100000000) / 100000000,
      maxBlockHeight: maxBlockHeight,
      satsDateMap: satoshisDateMap,
      blockCountDateMap: blocksCountDateMap,
      txidMap: txidMap,
    }
    */


    console.warn("There are " +paymentAnalysisResults.satsDateMap.size + " date entries")
    //satoshisDateMap.forEach(this.logSatsMapElements)
    //blocksCountDateMap.forEach(this.logBlocksMapElements)

    // build a map of date/price-data - to help us then build an array
    const datePriceObjArray = await this.getPersistedDatePriceData()
    console.log("collectPriceData: BTW: datePriceObjArray: ", datePriceObjArray)
    let datePriceMap = new Map()  // we use this temp map to construct an array (dropList)
    let i = 0

    for ( const elem of datePriceObjArray ){
      //console.warn("date element " + i + "(base-0)  of " + datePriceObjArray.length + ": ", elem)

      if ( typeof datePriceMap.get(elem.date) === 'undefined' ) {
        datePriceMap.set(elem.date, elem.price)
      } else {
        alert("cPD(): date " + elem.date + " has multiple prices set (persisted in date-price 'setting')")
      }
      ++i
    }

    const dateMapKeyIterator = paymentAnalysisResults.satsDateMap.keys()

    let dropList = []
    let datesIterResult = dateMapKeyIterator.next()
    let numDates = 0
    while ( !datesIterResult.done ) {
      ++numDates
      const theDate = datesIterResult.value

      //console.warn("Here's relevant date #" + numDates + ": " + theDate)

      let price = '?'
      const infoForThisDate = datePriceMap.get(theDate)
      if ( typeof infoForThisDate === 'undefined' ) {

      } else {
        console.warn("price for " + theDate + ": ", infoForThisDate + " <---")
        price = infoForThisDate
      }

      dropList.push(  {
        key: numDates++,
        value: theDate,
        text: <>{theDate}: &nbsp; ${price}</>,
        disabled: false
      })

      datesIterResult = dateMapKeyIterator.next()
    }

    this.setState({showPriceModal: true, dropList: dropList, paymentAnalysisResults: paymentAnalysisResults})
  }

  async handleDateChange(e,v) {
    console.warn("handleDateChange(): new date: ", v.value)

    const datePriceObjArray = await this.getPersistedDatePriceData()
    console.log("handleDateChange(): collectPriceData: BTW: datePriceObjArray: ", datePriceObjArray)

    // Find a good starting value for this day's price (if it exists)
    let i = 0
    let startingPrice = ''
    let numFound = 0
    for ( const elem of datePriceObjArray ){

      //console.warn("     date element " + i + " of " + datePriceObjArray.length + ": ", elem)
      if ( elem.date === v.value ) {
        console.warn("FOUND: date in element " + i + "(base-0). price: " + elem.price)
        startingPrice = elem.price
        numFound++
        if ( numFound === 2 ) {
          alert("hDC(): NOTE: this date has multiple price entries. You should CLEAR it, then re-enter the price.")
        }
        //break
      }
      i++
    }

    this.setState({dateToUse: v.value, priceToUse: startingPrice})
  }

  handlePriceData(e, v) {
    console.log("handlePriceData(): price now set to ", v.value);
    console.warn("event.target.value: ", e.target.value)
    this.setState({priceToUse: e.target.value}) //v.value})
  }

  async setPriceData() {
    console.log("setPriceData(): SHOULD set price to: " + this.state.priceToUse)

    const datePriceObjArray = await this.getPersistedDatePriceData()

    // We no-longer assume the date doesn't ALREADY have a price associated with it
    // Scan, and remove any duplicates
    //WARNING: this scan code is ALSO in clearPrice(), below
    let idx = datePriceObjArray.length -1
    for (let i = 0; idx > -1; idx--) {
      console.log("   item " + idx + ": ", datePriceObjArray[idx])
      const thisDate = datePriceObjArray[idx].date
      if ( thisDate === this.state.dateToUse ) {
        console.warn("    First, removing item " + idx)
        datePriceObjArray.splice(idx, 1)
      }
    }


    // Now that we're sure it has no duplicates, add the date, price to the array
    datePriceObjArray.push( {date: this.state.dateToUse, price: this.state.priceToUse} )

    await this.persistDatePriceData( datePriceObjArray )

    console.warn("NOW, run the price review AGAIN: collectPriceData() - so we can integrate the new data into it (dropdown, and logic)")

    await this.collectPriceData()

  }
  async clearPrice() {
    console.log("clearPrice(): Will CLEAR price on date: " + this.state.dateToUse)

    const datePriceObjArray = await this.getPersistedDatePriceData()

    console.warn("array BEFORE removing date: ", datePriceObjArray)

    //WARNING: this scan code is ALSO in setPriceData(), above
//FIXME: extract for use ALSO in setPriceData()
    let idx = datePriceObjArray.length -1
    for (let i = 0; idx > -1; idx--) {
      console.log("   item " + idx + ": ", datePriceObjArray[idx])
      const thisDate = datePriceObjArray[idx].date
      if ( thisDate === this.state.dateToUse ) {
        console.warn("    SHOULD remove item " + idx)
        datePriceObjArray.splice(idx, 1)
      }
    }

    console.warn("array AFTER removing (ALL occurrences of) date: ", datePriceObjArray)

    await this.persistDatePriceData( datePriceObjArray )

    console.warn("NOW, running the price review AGAIN: collectPriceData() - so we can integrate the new data (dropdown, and logic)")

    // bounce this 'showPriceModal' boolean to ensure re-draw (re-analysis of price info)
    this.setState({showPriceModal: false, priceToUse: 0}, () => {
                        this.collectPriceData()
                        this.setState({showPriceModal: true})
                      }
                )
  }

  closePriceModal() {
    this.setState({showPriceModal: false, dropList: null, paymentAnalysisResults: null, disableButtons: false, priceToUse: 0})
  }

  async doReview() {
    // don't allow user to initiated a new query while one is ongoing
    this.setState({disableButtons: true})

    let startDateLimit = null
    let startLimitMention = null
    if ( this.state.limitStartDate ) {
      startDateLimit = this.state.startDateLimit
      startLimitMention = <>
                      We only included payments <b>on or after</b> <span style={{color:'blue'}}>{this.state.startDateLimit}</span>
                      <br></br>
                    </>
    }

    let endDateLimit = null
    let endLimitMention = null
    if ( this.state.limitEndDate ) {
      endDateLimit = this.state.endDateLimit
      endLimitMention = <>
                      We only included payments <b>before</b> <span style={{color:'blue'}}>{this.state.endDateLimit}</span>
                      <br></br>
                    </>
    }

    const results = await this.reviewCachedPayments([], endDateLimit, startDateLimit)
    //const results = await this.reviewCachedPayments([], "2024-04-01")

    console.log("reviewing address - equivalent to PKH " + this.state.minerPKH)
    console.log("results of review: ", results)
    /*
    return {
      numPayments: utxos.length,
      numDates: numDates,
      totalBSV: Math.round(( totalSats + Number.EPSILON) * 100000000) / 100000000,
      maxBlockHeight: maxBlockHeight,
      satsDateMap: satoshisDateMap,
      blockCountDateMap: blocksCountDateMap,
      txidMap: txidMap,
    }
    */

    let optionToFillPricesAutomatically = <></>
    if ( results.daysWithoutPrice > 0 ) {
      //FIXME: add an ACK/setting that user acknowledges the price data is not guaranteed
      optionToFillPricesAutomatically =
            <>
              <br></br>
              If you'd like, we can attempt to acquire prices for the days you have missing.
              <br></br>
              We'll use data from <a href="https://coincodex.com" target="_blank" rel="noopener noreferrer"><b>CoinCodex.com</b></a> - since
              they offer a FREE service for price data.
              <br></br>
              We make no claim about the accuracy of this price data.
              And, the times at which these prices were sampled may differ significantly
              from the particular times when you received payments.
              <br></br>
              <Button onClick={() => this.autoFillPriceData(results)} content='Grab Price Data from CoinCodex' positive/>
            </>
    }

    const listItems = results.pricelessDays.map((day) =>
      <li key={day}>{day}</li>
    );
    const addendum = <ul>{listItems}</ul>
    const caveat = results.daysWithoutPrice > 0 ?
              <>
                <b style={{color:'red'}}>
                Without complete price data, your summary will be incomplete (wrong).
                </b>
                {optionToFillPricesAutomatically}
              </>
            :
              <></>
    const missingPriceMentionColor = results.daysWithoutPrice > 0 ? 'red' : 'blue'

    const bsvAtTodaysPrice = Math.round(( results.totalBSV * this.props.bsvPriceUSD + Number.EPSILON) * 100) / 100
    const diffInDollars = bsvAtTodaysPrice - results.totalDollars
    const gainInDollars = Math.round(( diffInDollars + Number.EPSILON) * 100) / 100
    const percentageGain = Math.round(( diffInDollars / results.totalDollars + Number.EPSILON) * 1000) / 10
    const summaryText = <>
                          This address has received a total
                          of {results.totalBSV} BSV (income of ${results.totalDollars}), in {results.numPayments} payments,
                          over {results.numDates} days.
                          <br></br>
                          <br></br>
                          {startLimitMention}
                          {endLimitMention}
                          <br></br>
                          At <b style={{color:'blue'}}>today's</b> price (${this.props.bsvPriceUSD}), they're worth ${bsvAtTodaysPrice} (a {percentageGain}% gain of ${gainInDollars}).
                          <br></br>
                          <br></br>
                          There are <b style={{color:missingPriceMentionColor}}>{results.daysWithoutPrice}</b> days for which we're missing price data.
                          <br></br>
                          {addendum}
                          {caveat}
                        </>

    this.setState({showSummary: true, summaryText: summaryText})
  }
  closeSummary() {
    this.setState({showSummary: false, disableButtons: false})
  }

  async deduplicatePersistedUtxos() {

    const label = payoutsSettingLabelPrefix + this.state.minerAddress

    const utxos = await this.getPersistedUtxos(label)     //.txId, .blockHeight

    //utxos.sort(compareByBlockTime)

    console.warn("There are " + utxos.length + " cached UTXOs")


    let txidMap = new Map()
    let i = 0
    const newUtxos = []
    for ( const utxo of utxos ){

      if ( txidMap.get(utxo.txId) ) {
        console.error("deduplicatePersistedUtxos(): OKAY: found a duplicate, persisted utxo - for txId ", utxo.txId)
        alert("will REMOVE duplicate persisted utxo: " + utxo.txId)
      } else {
        txidMap.set(utxo.txId, true)

        const uniqueUtxo = {
          txId:           utxo.txId,
          blockHeight:    utxo.blockHeight,
          satoshis:       utxo.satoshis,
          outputIndex:    utxo.outputIndex,
          blockTime:      utxo.blockTime,
          blockDateStamp: utxo.blockDateStamp
        }

        console.warn("created NEW utxo to use: ", uniqueUtxo)

        newUtxos.push(uniqueUtxo)
      }
    }

    console.warn("newUtxos: ", newUtxos)

    const db = await openDB()

    const newMinerUtxosJsonStr = JSON.stringify( newUtxos )

    await deleteSetting(db, label)
    await saveSetting(db, label, newMinerUtxosJsonStr)
  }

  // analyze payment data
  // Allow all payments BEFORE (but NOT including) endDate
  // Allow all payments on and after startDate
  async reviewCachedPayments(utxos = [], endDate = null, startDate = null) {

    console.log('reviewCachedPayments() utxos: ', utxos)
    console.warn("restrict by start date " + startDate)
    console.warn("restrict by end date " + endDate)
    if ( utxos.length === 0 ) {
      console.log("was empty, so, lets get utxos")
      utxos = await this.getPersistedUtxos(payoutsSettingLabelPrefix + this.state.minerAddress)     //.txId, .blockHeight
    }

    utxos.sort(compareByBlockTime)

    console.warn("There are " + utxos.length + " cached UTXOs (now sorted)")

    let satoshisDateMap = new Map()
    let blocksCountDateMap = new Map()
    let txidMap = new Map()
    let i = 0
    let totalSats = 0
    let maxBlockHeight = 0
    let paymentsOfInterestCount = 0
    for ( const utxo of utxos ){
      ++i

      // FILTER-OUT dates AFTER the endDate (if not null)
      const dateSnippet = utxo.blockDateStamp.substr(0, 10)
      console.log("date substring: ", dateSnippet)

      // comparing strings: https://stackoverflow.com/questions/10198257/comparing-2-strings-alphabetically-for-sorting-purposes
      if ( startDate !== null && startDate.localeCompare( dateSnippet ) > 0 ) {
        // SKIP this date (1 means startDate is after dateSnippet)
        console.warn("Compared startDate " + startDate + " to dateSnippet " + dateSnippet + ": ", startDate.localeCompare( dateSnippet ) + " - SKIPPING" )
        continue
      } else if ( endDate !== null && endDate.localeCompare( dateSnippet ) < 1 ) {
        // SKIP this date (0 or -1 means endDate is equal to, or before dateSnippet)
        console.warn("Compared endDate " + endDate + " to dateSnippet " + dateSnippet + ": ", endDate.localeCompare( dateSnippet ) + " - SKIPPING")
        continue
      } else {
        console.warn("Proceeding with dateSnippet " + dateSnippet + " <========")
      }

      ++paymentsOfInterestCount


      totalSats += utxo.satoshis
      if ( utxo.blockHeight > maxBlockHeight ) {
        maxBlockHeight = utxo.blockHeight
      }

      console.warn("utxo " + i + " of " + utxos.length)
      console.log("  .txId: ", utxo.txId)
      console.log("  .blockHeight: ", utxo.blockHeight)
      console.log("  .outputIndex: ", utxo.outputIndex)
      console.log("  .satoshis: ", utxo.satoshis)
      console.log("  .blocktime: ", utxo.blocktime)
      console.log("  .blockDateStamp: ", utxo.blockDateStamp)
      console.log(" running total of sats: " + totalSats)

      if ( txidMap.get(utxo.txId) ) {
        console.error("reviewCachedPayments(): WHOOPS: found a duplicate, persisted utxo - for txId ", utxo.txId)
        alert("Duplicate persisted utxo found: " + utxo.txId)
        alert("FIXME: remove the duplicate persisted utxo")
      } else {
        txidMap.set(utxo.txId, true)
      }

      const satsOnDateSoFar = satoshisDateMap.get(dateSnippet)
      console.log("sats on date so far: ", satsOnDateSoFar)
      if ( typeof satsOnDateSoFar === 'undefined' ) {
        satoshisDateMap.set(dateSnippet, utxo.satoshis)
        console.warn("sats: First time for " + dateSnippet + ": " + utxo.satoshis + "sats  <----<<")
      } else {
        satoshisDateMap.set(dateSnippet, satsOnDateSoFar + utxo.satoshis)
        console.warn("sats: Update for " + dateSnippet + ": " + (satsOnDateSoFar + utxo.satoshis) + "sats  <--")
      }

      const blocksOnDateSoFar = blocksCountDateMap.get(dateSnippet)
      console.log("blocks on date so far: ", blocksOnDateSoFar)
      if ( typeof blocksOnDateSoFar === 'undefined' ) {
        blocksCountDateMap.set(dateSnippet, 1)
        console.warn("blocks: First time for " + dateSnippet + ": 1 block              <----<<")
      } else {
        blocksCountDateMap.set(dateSnippet, blocksOnDateSoFar + 1)
        console.warn("blocks: Update for " + dateSnippet + ": " + (blocksOnDateSoFar + 1) + "sats  <--")
      }
      console.log("------------------")
    } // loop over payments

    console.warn("There are " +satoshisDateMap.size + " date entries")
    satoshisDateMap.forEach(this.logSatsMapElements)
    blocksCountDateMap.forEach(this.logBlocksMapElements)

    const blocksMapKeyIterator = blocksCountDateMap.keys()

    const datePriceMap = await this.getDatePriceMap()
    console.log("datePriceMap: ", datePriceMap)
    
    let datesIterResult = blocksMapKeyIterator.next()
    let numDates = 0
    let unpricedDays = 0
    let paymentsInDollars = 0
    let pricelessDays = []
    while ( !datesIterResult.done ) {
      ++numDates
      const theDate = datesIterResult.value

      const satsTotalForDay = Math.round((satoshisDateMap.get(theDate) + Number.EPSILON) * 100000000) / 100000000
      console.warn("On date " + theDate + ": " + blocksCountDateMap.get(theDate) + " payments/blocks, " +
                   " totaling "  + satsTotalForDay + " BSV")

      let dollarsToday = 0
      const bsvPriceOnThisDate = datePriceMap.get(theDate)
      if ( typeof bsvPriceOnThisDate !== 'undefined' && bsvPriceOnThisDate > 0 ) {
        dollarsToday = bsvPriceOnThisDate * satsTotalForDay
        console.log("      (while BSV was at a price of $" + bsvPriceOnThisDate + ", so, received $" + dollarsToday + " on this day)")
      } else {
        unpricedDays++
        pricelessDays.push(theDate)
      }
      paymentsInDollars += dollarsToday

      datesIterResult = blocksMapKeyIterator.next()
    }

    console.warn("Address has received a total of " + Math.round(( totalSats + Number.EPSILON) * 100000000) / 100000000 + " BSV over " + numDates + " days")
    console.warn("Address has received a total of $" + Math.round(( paymentsInDollars + Number.EPSILON) * 100) / 100 + " over " + numDates + " days")
    if ( unpricedDays > 0 ) {
      console.warn("There were " + unpricedDays + " days, however, without a price  <====")
    }
    console.warn("highest block: ", maxBlockHeight)


    return {
              numPayments: paymentsOfInterestCount,
              numDates: numDates,
              totalBSV: Math.round(( totalSats + Number.EPSILON) * 100000000) / 100000000,
              totalDollars: Math.round(( paymentsInDollars + Number.EPSILON) * 100) / 100,
              maxBlockHeight: maxBlockHeight,
              satsDateMap: satoshisDateMap,
              blockCountDateMap: blocksCountDateMap,
              txidMap: txidMap,
              daysWithoutPrice: unpricedDays,
              pricelessDays: pricelessDays,
           }
  }

  async queryMinerAddress() {
    // don't allow user to initiated a new query while one is ongoing
    this.setState({disableButtons: true}, this.doTheQuery)
  }

  //FIXME: avoid duplicates between already-persisted, and newly-queried payments
  async doTheQuery() {

    //alert("buttons disabled? " + (this.state.disableButtons ? 'true' : 'false'))

    let label = payoutsSettingLabelPrefix + this.state.minerAddress
    let havePersisted = false

    //let utxos = await this.getPersistedUtxos('unspentMinerPayments')
    let oldUtxos = await this.getPersistedUtxos(label)     //.txId, .blockHeight
    let txidMap = null

    let newUtxos

    let startingBlock = 0
    if ( oldUtxos.length === 0 ) {
      console.warn("We found no PERSISTED utxos for address " + this.state.minerAddress + ", so, will QUERY a provider starting at block 0...")

    } else {
      const cacheSummary = await this.reviewCachedPayments(oldUtxos)
      console.warn("cache summary: ", cacheSummary)
      console.log("maxBlockHeight of cache: ", cacheSummary.maxBlockHeight)

      startingBlock = cacheSummary.maxBlockHeight + 1
      txidMap = cacheSummary.txidMap

      console.warn("We found persisted UTXOs, so, will query starting at blockHeight " + (startingBlock))
      havePersisted = true

    }

    // query for confirmed history of payments to miner address - starting at block 'startingBlock'
    // Q: could this have duplicates of oldUtxos?
    //NOTE: this ALSO returns SPENDS. We remove them below, and only save/persist the inboundUtxos[]
    newUtxos = await this.fetchPaymentsToAddress(this.state.minerAddress.trim(), 'main', startingBlock)  // .txId, .blockHeight

    console.warn("doTheQuery(): oldUtxos: ", oldUtxos)

    console.warn("new miner 'payments': ", newUtxos)

    let outbountCount = 0
    // // GET blockHeight, timestamp for each txId/payment
    let highestBlockHeight = 0
    let i = 0
    let inboundUtxos = []    // similar to newUtxos, but ignores/culls OUTBOUND/spending txs
    for ( const utxo of newUtxos ) {

      this.setState({progressText:  <>
                                      <div>
                                        <span style={{color:'blue'}}>Now querying for details of <b style={{fontSize:'1.3rem'}}>{(i+1)} of {newUtxos.length}</b> NEW payments...</span>
                                        <br></br>
                                        (starting at block {startingBlock})
                                        <br></br>
                                        (Proceeding slowly since we're not paying a transaction-provider for their services.)
                                      </div>
                                    </>})

      if ( !this.state.showMinerModal ) {
        if ( confirm("Shall we stop the queries (and lose our recent progress)?") ) {
          return
        } else {
          this.setState({showMinerModal: true})
        }
      }

      if ( txidMap?.get( utxo.txId ) ) {
        console.error("Problem (duplicate) with this utxo, of txId " + utxo.txId + " (will skip it)")
        alert("doTheQuery(): our query of NEW payments found a duplicate with persisted utxos")
        continue
      }

      // .txId
      // .outputIndex
      // .satoshis
      console.warn("payment utxo " + i + ": ", utxo)
      let res
      if ( typeof utxo.blockHeight === 'undefined' ) {
        console.log("    (utxo " + i + " has NO .blockHeight). We'll ignore it. FIXME: <---- WHY not blockHeight?")
        alert("PROBLEM with utxo " + i + " (no blockHeight)?")
      } else {
        //console.log("    (utxo " + i + " HAS a .blockHeight)")

        //alert("will query for params (outputIndex, satoshis) of txId " + utxo.txId)
        console.warn("will query for params (outputIndex, satoshis) of txId " + utxo.txId)
        await mySleep(350, 'queryMinerAddress(): quick sleep before next tx...')
        try {
          res = await this.queryForPaymentParams(utxo.txId, utxo.blockHeight)

          if ( res.outputIndex === -1 ) {
            console.warn("Found an outbound utxo (which we'll ignore): ", newUtxos[i])
            outbountCount++
          } else {

            if ( utxo.blockHeight > highestBlockHeight ) {
              highestBlockHeight = utxo.blockHeight
              console.warn("New highest block...")
            }

            newUtxos[i].satoshis       = res.satoshis          // FIXME: mis-named. Actually BSV, not satoshis   <--- XXXX
            newUtxos[i].outputIndex    = res.outputIndex
            newUtxos[i].blocktime      = res.blocktime
            newUtxos[i].blockDateStamp = res.blockDateStamp

            inboundUtxos.push(newUtxos[i])
          }
        } catch (error) {
          alert("Error while querying for " + utxo.txId + "\n\nWill stop here, for now.")

          this.setState({disableButtons: false})
          return
        }
      }

      ++i
    }

    console.log("BTW: the highestBlockHeight (perhaps new) we encountered was ", highestBlockHeight)

    console.error("BTW: newUtxos[] has length " + newUtxos.length + ", but inboundUtxos[] has length " + inboundUtxos.length)
    if ( outbountCount > 0 ) {
      alert("While inspecting each tx for this address, we found " + outbountCount+ " OUTBOUND txs. We won't persist them.")
    }

    if ( !havePersisted ) {
      console.warn("saving/persisting JUST these utxos - since we had none persisted already")

      const newMinerUtxosJsonStr = JSON.stringify( inboundUtxos ) //newUtxos )
      await saveSetting(await openDB(), label, newMinerUtxosJsonStr)
    } else {
      // ADD new utxos
      this.persistUtxos( inboundUtxos, label ) //newUtxos, label )
    }

    await this.updateRecentAddressLabels(label)

    this.setState({disableButtons: false, progressText: <></>})
  }

  editStartDate() {
    console.log("editStartDate() BTW: startDateLimit: ", this.state.startDateLimit)
    console.log("editStartDate() BTW: rawStartDateValue: ", this.state.rawStartDateValue)

    this.setState({showStartDateSetter: true})
  }
  toggleStartDateLimit() {
    console.log("toggleStartDateLimit() BTW: startDateLimit: ", this.state.startDateLimit)
    console.log("toggleStartDateLimit() BTW: rawStartDateValue: ", this.state.rawStartDateValue)

    if ( this.state.startDateLimit === null ) {
      console.warn("start date string was null, so, let's display the modal - BUT, disable the SET")

      this.setState({showStartDateSetter: true, disableSetStartDateButton: true})
    } else if ( this.state.showStartDateSetter ) {
      console.warn("start date string was NOT null, but, showStartDateSetter was true, so, CLOSING the START date the modal")
      this.setState({showStartDateSetter: false})
    } else if ( this.state.limitStartDate ) {
      console.warn("start date string was NOT null, AND, showStartDateSetter was false, BUT limitStartDate was true, so, maybe CLEAR the limit box")
      this.setState({limitStartDate: false})
      return
    } else {
      console.warn("limitStartDate was false, so, just CHECK the box?")
      this.setState({limitStartDate: true})
      return
    }

    //console.log("PROCEEDING")

    this.setState({limitStartDate: !this.state.limitStartDate })
  }
  toggleShowStartDateModal() {
    console.log("toggleShowStartDateModal()  - just the MODAL")
    this.setState({ showStartDateSetter: !this.state.showStartDateSetter})
  }
  onStartDateChange(event, data) {
    console.log("onStartDateChange():   value: ", data.value)

    console.log("  OLD start date limit: ", this.state.startDateLimit)

    const newDate = new Date(data.value).toISOString().split('T')[0]
    console.log("  New start date limit: ", newDate)

    const disableSet = "1970-01-01" === newDate

    this.setState({startDateLimit: newDate, disableSetStartDateButton: disableSet, rawStartDateValue: data.value});
  }

  editEndDate() {
    console.log("editEndDate() BTW: endDateLimit: ", this.state.endDateLimit)
    console.log("editEndDate() BTW: rawEndDateValue: ", this.state.rawEndDateValue)

    this.setState({showEndDateSetter: true})
  }
  toggleEndDateLimit() {
    console.log("toggleEndDateLimit() BTW: endDateLimit: ", this.state.endDateLimit)
    console.log("toggleEndDateLimit() BTW: rawEndDateValue: ", this.state.rawEndDateValue)

    if ( this.state.endDateLimit === null ) {
      console.warn("end date string was null, so, let's display the modal - BUT, disable the SET")

      this.setState({showEndDateSetter: true, disableSetEndDateButton: true})
    } else if ( this.state.showEndDateSetter ) {
      console.warn("end date string was NOT null, but, showEndDateSetter was true, so, CLOSING the date the modal")
      this.setState({showEndDateSetter: false})
    } else if ( this.state.limitEndDate ) {
      console.warn("end date string was NOT null, AND, showEndDateSetter was false, BUT limitEndDate was true, so, maybe CLEAR the limit box")
      this.setState({limitEndDate: false})
      return
    } else {
      console.warn("limitEndDate was false, so, just CHECK the box?")
      this.setState({limitEndDate: true})
      return
    }

    //console.log("PROCEEDING")

    this.setState({limitEndDate: !this.state.limitEndDate })
  }
  toggleShowEndDateModal() {
    console.log("toggleShowEndDateModal()  - just the MODAL")
    this.setState({ showEndDateSetter: !this.state.showEndDateSetter})
  }
  onEndDateChange(event, data) {
    console.log("onEndDateChange():   value: ", data.value)

    console.log("  OLD end date limit: ", this.state.endDateLimit)

    const newDate = new Date(data.value).toISOString().split('T')[0]
    console.log("  New end date limit: ", newDate)

    const disableSet = "1970-01-01" === newDate

    this.setState({endDateLimit: newDate, disableSetEndDateButton: disableSet, rawEndDateValue: data.value});
  }

  // Check if a given START date is ok - COMPARED AGAINST any END DATE LIMIT
  checkFilterStartDate(dateToConsider) {

    const newDate = new Date(dateToConsider).toISOString().split('T')[0]

    console.log("checkFilterStartDate(): date to consider:", newDate)

    //const startDateLimit = typeof this.state.startDateLimit === 'undefined' ? null : this.state.startDateLimit
    const endDateLimit   = typeof this.state.endDateLimit   === 'undefined' ? null : this.state.endDateLimit

    //console.error("startDate: ", startDateLimit)
    console.warn("endDateLimit: ", endDateLimit)

    // comparing strings: https://stackoverflow.com/questions/10198257/comparing-2-strings-alphabetically-for-sorting-purposes
    //if ( startDateLimit !== null && startDateLimit.localeCompare( newDate ) > 0 ) {
      // SKIP this date (1 means startDate is after dateSnippet)
    //  console.warn("Compared startDate " + startDateLimit + " to dateSnippet " + newDate + ": ", startDateLimit.localeCompare( newDate ) + " - SKIPPING" )
    //  return false
    //} else
    if ( endDateLimit !== null && endDateLimit.localeCompare( newDate ) < 1 ) {
      // SKIP this date (0 or -1 means endDate is equal to, or before dateSnippet)
      console.warn("Compared endDate " + endDateLimit + " to dateSnippet " + newDate + ": ", endDateLimit.localeCompare( newDate ) + " - SKIPPING")
      return false
    } else {
      console.warn("Allowing dateSnippet " + newDate + " <========")
    }

    return true
  }

  // Check if a given END date is ok - COMPARED AGAINST any START DATE LIMIT
  checkFilterEndDate(dateToConsider) {

    const newDate = new Date(dateToConsider).toISOString().split('T')[0]

    console.log("checkFilterEndDate(): date to consider:", newDate)

    const startDateLimit = typeof this.state.startDateLimit === 'undefined' ? null : this.state.startDateLimit
    //const endDateLimit   = typeof this.state.endDateLimit   === 'undefined' ? null : this.state.endDateLimit

    console.warn("startDateLimit: ", startDateLimit)
    //console.error("endDateLimit: ", endDateLimit)

    // comparing strings: https://stackoverflow.com/questions/10198257/comparing-2-strings-alphabetically-for-sorting-purposes
    if ( startDateLimit !== null && startDateLimit.localeCompare( newDate ) > 0 ) {
      // SKIP this date (1 means startDate is after dateSnippet)
      console.warn("Compared startDate " + startDateLimit + " to dateSnippet " + newDate + ": ", startDateLimit.localeCompare( newDate ) + " - SKIPPING" )
      return false
    //} else
    //if ( endDateLimit !== null && endDateLimit.localeCompare( newDate ) < 1 ) {
      // SKIP this date (0 or -1 means endDate is equal to, or before dateSnippet)
    //  console.warn("Compared endDate " + endDateLimit + " to dateSnippet " + newDate + ": ", endDateLimit.localeCompare( newDate ) + " - SKIPPING")
    //  return false
    } else {
      console.warn("Allowing dateSnippet " + newDate + " <========")
    }

    return true
  }

  render() {

    // ***  DEVICE-DEPENDENT STYLING  ***
    // For mobile, "detect" landscape screen mode
    const landscape = this.props.isMobile ? window.innerWidth > window.innerHeight : false
    // For mobile, shrink the About, Security, and Glossary modals
    const modalClassName   = this.props.isMobile ?
                                    (landscape ?
                                            "modalMobileLandscape"
                                        :
                                            "modalMobilePortrait"
                                    )
                                :
                                    ""
    const modalContentClassName = this.props.isMobile ? "modalContentMobile" : ""
    const modalBottomClassName  = this.props.isMobile ? "modalBottomMobile"  : ""


    let priceModalText = <></>
    if ( this.state.showPriceModal ) {
      const datePriceDropDown = <Dropdown upward placeholder='Choose a date'
                                            style={{padding: "12px 0px 0px 0px", fontSize:'1.0rem', minWidth:'275px'}}
                                            selection
                                            options={this.state.dropList}
                                            search={this.state.idSearch}
                                            onChange={this.handleDateChange}
                                    />

      const disableButtons = this.state.dateToUse === ''
      priceModalText = <>
                      This address has received payments,
                      over {this.state.paymentAnalysisResults.numDates} days.
                      <br></br>
                      You can enter a price for any of these days.
                      <br></br>
                      Click the dropdown below to review (or set) what price has been entered for each day...
                      <br></br>
                      <br></br>
                      <br></br>
                      <br></br>
                      <br></br>
                      <br></br>
                      <br></br>
                      <br></br>
                      {datePriceDropDown}&nbsp;
                      <Input placeholder='Enter a price for this day'
                            onChange={this.handlePriceData}
                            value={this.state.priceToUse}
                            style={{maxWidth:'179px'}}
                            />&nbsp;
                      <Button onClick={this.setPriceData} content='set price' positive disabled={disableButtons}/>&nbsp;
                      <Button onClick={this.clearPrice} content='CLEAR price' negative disabled={disableButtons}/>
                    </>

    }

    const maybePriceModal = this.state.showPriceModal ?
          <>
          <Modal size='large' centered className={modalClassName}  open={true} style={{backgroundColor: bshzColors.purple, borderRadius: '20px'}}>

              <Modal.Header style={{textAlign: 'center', backgroundColor: bshzColors.purple, borderRadius: '20px'}}>
                  <span style={{color: bshzColors.yellow}}> Price Data </span>
              </Modal.Header>

              <Modal.Content className={modalContentClassName} style={{backgroundColor: bshzColors.ltPurple}}>

                    {priceModalText}

              </Modal.Content>

              <Modal.Actions className={modalBottomClassName} style={{backgroundColor: bshzColors.purple, borderRadius: '0px 0px 20px 20px'}}>
                      <div style={{textAlign: 'center'}}>
                        <Button onClick={ this.closePriceModal }
                                content='Okay' positive/>
                      </div>
              </Modal.Actions>

          </Modal>
          </>
            :
          <></>

    const maybeSummaryReview = this.state.showSummary ?
          <>
          <Modal size='small' centered className={modalClassName}  open={true} style={{backgroundColor: bshzColors.purple, borderRadius: '20px'}}>

              <Modal.Header style={{textAlign: 'center', backgroundColor: bshzColors.purple, borderRadius: '20px'}}>
                  <span style={{color: bshzColors.yellow}}> Summary for {this.state.minerAddress} </span>
              </Modal.Header>

              <Modal.Content className={modalContentClassName} scrolling style={{backgroundColor: bshzColors.ltPurple}}>

                    {this.state.summaryText}

              </Modal.Content>

              <Modal.Actions className={modalBottomClassName} style={{backgroundColor: bshzColors.purple, borderRadius: '0px 0px 20px 20px'}}>
                      <div style={{textAlign: 'center'}}>
                        <Button onClick={ this.closeSummary }
                                content='Back' positive/>
                      </div>
              </Modal.Actions>

          </Modal>
          </>
            :
          <></>

    const maybeLoader = this.state.disableButtons ?
                            <>
                              <Loader inline style={{color: "black"}}>
                                {this.state.progressText}
                              </Loader>
                            </>
                          :
                            <></>

    //const domainPriceEquiv = <>({calcPenniesFromSats(this.state.domainPrice, this.props.bsvPriceUSD)})</>

    const minerInputField = this.state.disableButtons ?
                <Input  field='minerAddress' placeholder="Enter your miner's Bitcoin payment address"
                            value={this.state.minerAddress} style={{width: "325px"}}
                            disabled
                            autoFocus />
                    :
                <Input  field='minerAddress' placeholder="Enter your miner's Bitcoin payment address"
                            value={this.state.minerAddress} style={{width: "325px"}}
                            onChange={this.handleMinerAddress}
                            autoFocus />

    const startDateToShow = this.state.limitStartDate ?
              <b style={{color:'blue'}}>
                {this.state.startDateLimit}
              </b>
            :
              this.state.startDateLimit !== null ?
                  <span style={{color:'grey'}}>
                    {this.state.startDateLimit}
                  </span>
                :
                  <></>//'is null'
    const endDateToShow = this.state.limitEndDate ?
              <b style={{color:'blue'}}>
                {this.state.endDateLimit}
              </b>
            :
              this.state.endDateLimit !== null ?
                  <span style={{color:'grey'}}>
                    {this.state.endDateLimit}
                  </span>
                :
                  <></>//'is null'
    const maybeEditStartButton = this.state.limitStartDate && typeof this.state.rawStartDateValue !== undefined ?
            <>
              &nbsp; <Button onClick={this.editStartDate} content="Edit" style={{backgroundColor:'blue', color:'yellow', borderRadius: '15px', padding:'7px'}}/>
            </>
          :
            null
    const maybeEditEndButton = this.state.limitEndDate && typeof this.state.rawEndDateValue !== undefined ?
            <>
              &nbsp; <Button onClick={this.editEndDate} content="Edit" style={{backgroundColor:'blue', color:'yellow', borderRadius: '15px', padding:'7px'}}/>
            </>
          :
            null
    const limitStartDateCheckbox = this.state.disableButtons || !this.state.validAddress ?
              <>
                <Checkbox label='Limit start date' checked={false} disabled />
                &nbsp; &nbsp;{startDateToShow}
              </>
            :
              <>
                <Checkbox label='Limit start date' checked={this.state.limitStartDate} onClick={this.toggleStartDateLimit}/>
                &nbsp; &nbsp;{startDateToShow} {maybeEditStartButton}
              </>
    const limitEndDateCheckbox = this.state.disableButtons || !this.state.validAddress ?
              <>
                <Checkbox label='Limit end date' checked={false} disabled />
                &nbsp; &nbsp;{endDateToShow}
              </>
            :
              <>
                <Checkbox label='Limit end date' checked={this.state.limitEndDate} onClick={this.toggleEndDateLimit}/>
                &nbsp; &nbsp;{endDateToShow} {maybeEditEndButton}
              </>
    // REMOVED since we may have plugged any holes (opportunities for duplicate txIds)
    //<Button onClick={ this.deduplicatePersistedUtxos } content='de-duplicate utxos' color='blue' disabled={this.state.disableButtons || !this.state.validAddress}/>
    const actionButtons =
              <>
                <Button onClick={ this.queryMinerAddress } content='Query for payments' positive disabled={this.state.disableButtons || !this.state.validAddress}/>
                <Button disabled={this.state.recentAddressLabelsSet?.size < 1} onClick={ this.showOtherAddresses } content="recent addresses" style={{backgroundColor:'blue', color:'yellow', borderRadius: '15px', padding:'7px'}}/>
                <br></br>
                <Table style={{border:'none', backgroundColor:"#00000000"}}>
                  <Table.Body>
                    <Table.Row>
                      <Table.Cell rowSpan={2} collapsing>
                        <Button onClick={ () => this.doReview([]) } content='Summary' positive disabled={this.state.disableButtons || !this.state.validAddress}/>
                      </Table.Cell>
                      <Table.Cell style={{padding:'0px'}}>
                        {limitStartDateCheckbox}
                      </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell  style={{padding:'0px', border:'none'}}>
                        {limitEndDateCheckbox}
                      </Table.Cell>
                    </Table.Row>
                  </Table.Body>
                </Table>

                <Button onClick={ this.collectPriceData } content='Review Price Data' color='blue' disabled={this.state.disableButtons || !this.state.validAddress}/>
              </>

    const clearStartDate = () => this.setState({startDateLimit: null, showStartDateSetter: false, limitStartDate: false, rawStartDateValue: undefined});

    const maybeShowStartDateSetter =
          <Modal size='small' centered className={modalClassName}  open={this.state.showStartDateSetter} style={{backgroundColor: bshzColors.purple, borderRadius: '20px'}}>

              <Modal.Header style={{textAlign: 'center', backgroundColor: bshzColors.purple, borderRadius: '20px'}}>
                <span style={{color: bshzColors.yellow}}> Set Payment Review Start Date LIMIT</span>
              </Modal.Header>

              <Modal.Content className={modalContentClassName} scrolling style={{backgroundColor: bshzColors.ltPurple}}>

                <br></br>
                Enter the first date you want the review/analysis to include:
                <br></br>
                <SemanticDatepicker
                    onChange={this.onStartDateChange}
                    format="YYYY-MM-DD"
                    value={this.state.rawStartDateValue}
                    keepOpenOnClear={true}
                    filterDate={this.checkFilterStartDate}
                /> &nbsp;
                <Button onClick={this.toggleShowStartDateModal}
                                content='SET' positive disabled={this.state.disableSetStartDateButton}/>
                <Button onClick={clearStartDate}
                                content='Clear/Cancel' negative/>
                <br></br>
                <br></br>
                <br></br>
                <br></br>
                <br></br>
                <br></br>
                <br></br>
                <br></br>
                <br></br>
                <br></br>
                <br></br>
                <br></br>
                <br></br>
              </Modal.Content>

          </Modal>

    const clearEndDate = () => this.setState({endDateLimit: null, showEndDateSetter: false, limitEndDate: false, rawEndDateValue: undefined});

    const maybeShowEndDateSetter =
          <Modal size='small' centered className={modalClassName}  open={this.state.showEndDateSetter} style={{backgroundColor: bshzColors.purple, borderRadius: '20px'}}>

              <Modal.Header style={{textAlign: 'center', backgroundColor: bshzColors.purple, borderRadius: '20px'}}>
                <span style={{color: bshzColors.yellow}}> Set Payment Review Date LIMIT</span>
              </Modal.Header>

              <Modal.Content className={modalContentClassName} scrolling style={{backgroundColor: bshzColors.ltPurple}}>

                <br></br>
                Enter the first date you <b>DON'T</b> want the review/analysis to include:
                <br></br>
                <SemanticDatepicker
                    onChange={this.onEndDateChange}
                    format="YYYY-MM-DD"
                    value={this.state.rawEndDateValue}
                    keepOpenOnClear={true}
                    filterDate={this.checkFilterEndDate}
                /> &nbsp;
                <Button onClick={this.toggleShowEndDateModal}
                                content='SET' positive disabled={this.state.disableSetEndDateButton}/>
                <Button onClick={clearEndDate}
                                content='Clear/Cancel' negative/>
                <br></br>
                <br></br>
                <br></br>
                <br></br>
                <br></br>
                <br></br>
                <br></br>
                <br></br>
                <br></br>
                <br></br>
                <br></br>
                <br></br>
                <br></br>
              </Modal.Content>

          </Modal>
    const maybeShowRecentAddresses = this.state.showRecentAddressList ?
          <Modal size='small' centered className={modalClassName}  open={true} style={{backgroundColor: bshzColors.purple, borderRadius: '20px'}}>

              <Modal.Header style={{textAlign: 'center', backgroundColor: bshzColors.purple, borderRadius: '20px'}}>
                <span style={{color: bshzColors.yellow}}> Recent Addresses </span>
              </Modal.Header>

              <Modal.Content className={modalContentClassName} scrolling style={{backgroundColor: bshzColors.ltPurple}}>

                <br></br>
                Choose a recent address...
                <List bulleted>
                  {this.state.recentAddressesList}
                </List>
              </Modal.Content>

              <Modal.Actions className={modalBottomClassName} style={{backgroundColor: bshzColors.purple, borderRadius: '0px 0px 20px 20px'}}>
                      <div style={{textAlign: 'center'}}>
                        <Button onClick={ this.closeRecents }
                                content='Back' negative/>
                      </div>
              </Modal.Actions>

          </Modal>
        :
          null

    //price for domain: {qs.priceInSats} satoshis ({calcPenniesFromSats(qs.priceInSats, this.props.bsvPriceUSD)})

    //<input style={{borderRadius: '50px'}} />  // put inside the <Input></Input>

    return (
      <>

          <Modal size='large' centered className={modalClassName}  open={this.state.showMinerModal} style={{backgroundColor: bshzColors.purple, borderRadius: '20px'}}>

              <Modal.Header style={{textAlign: 'center', backgroundColor: bshzColors.purple, borderRadius: '20px'}}>
                  <span style={{color: bshzColors.yellow}}> Miner Payments Tool </span>
              </Modal.Header>

              <Modal.Content className={modalContentClassName} scrolling style={{backgroundColor: bshzColors.ltPurple}}>

                <br></br>
                Enter a miner payment address here.

                <br></br>
                Then click QUERY to begin queries to retrieve:<br></br>
                &nbsp; &nbsp; txIds, outIndex, sats, blockHeight, and blocktime<br></br>

                <br></br>
                Then click REVIEW to analyze and summarize the data:
                <br></br>

                <br></br>
                {minerInputField}

                <br></br>
                {this.state.minerAddress}

                {maybeLoader}
                <br></br>
                {actionButtons}

              </Modal.Content>

              <Modal.Actions className={modalBottomClassName} style={{backgroundColor: bshzColors.purple, borderRadius: '0px 0px 20px 20px'}}>
                      <div style={{textAlign: 'center'}}>
                        <Button onClick={ this.closeIt }
                                content='Back' negative/>
                      </div>
              </Modal.Actions>

          </Modal>

          {maybeSummaryReview}
          {maybePriceModal}

          {maybeShowEndDateSetter}
          {maybeShowStartDateSetter}

          {maybeShowRecentAddresses}
      </>
          )
  }
}

export default MinerPayments;
