"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
function getOption(key, value, options) {
    var optionValue = options[key];
    if (optionValue) {
        return optionValue && optionValue === value;
    }
    return true;
}
exports.getOption = getOption;
