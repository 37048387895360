import React from 'react';

import { Popup } from 'semantic-ui-react'

// https://react-icons.github.io/react-icons/
// https://www.npmjs.com/package/react-icons
import { BsArrowsFullscreen, BsFillShareFill } from "react-icons/bs";
//<BsArrowsFullscreen />
//<BsCurrencyBitcoin />
//<BsFillShareFill />
//<BsBullseye />

import {
    //testGetBulkUnspent,

    checkIP4Mapping,

    //saveEncryptedKeys,
    //getEncryptedKeysFromPKH,

    //buildShizzleLockingTx,
    //encryptData,
    //decryptData,
    //getRabinsJson,

    //getCurrentBlockInfo,
    //findLatestContinue,
    //findLatestAuction,

    openDB,
    getTxidFromAddr,
    //recursivelyDeleteDescendantSpends,
    //queryTxs,
    //queryLabels,
    //checkDanglingPaths,
    queryFetchTx,
    decomposeTx,
    queryFetchDecodeTx,
    findContent,

    parseTheOutputs,
    parseTheInputs,

    //findNextTx,
    //findPrevTx,
    //buildOnBuilderPrep,
    //buildOnAppend,
    //buildOnClaimOrPublish,
      //applyBuilderFeeReduction,
      //announceBuilderFeeReductionAuth,
      //buildOnAskBid,
    //buildOnAnUpdate,
    //buildOnATransient,
    //buildOnDialog,
    //buildOnABitGroup,
    //buildOnTheAdminGroup,
    //validateRabinPrivateKeys,
    //generateRabinPrivateKeyPlus
} from './buildShizzle.js';

import {
    hexByteToAscii,
    hexStringToAscii,
    requestFullScreen
} from './commonFuncs';

import { qrcode } from "qrcode-generator";

import { bshzColors }   from './bshzColors';

class ContentPresenter extends React.Component {
    constructor(props) {
      super(props);
      this.callFrame              = this.callFrame.bind(this);
      this.theChildMessageHandler = this.theChildMessageHandler.bind(this);
      this.sendDataToChild        = this.sendDataToChild.bind(this);

      this.showLinkToPost         = this.showLinkToPost.bind(this);

      this.refToIFrm = React.createRef()

      this.state = {
      }
    }

    sendDataToChild(id, content, arg) {
      //MAYBE: three options: ascii in json format, raw image, just ascii
      let actualData = content
      let deliveryTag = "txTxt"
      try {
        const jsonData = Buffer.from(content, 'hex').toString('utf8') // convert hex content to utf8
                         //hexStringToAscii( content ) // convert hex content to ascii - for json parsing
        const jsonContent = JSON.parse(jsonData);
        console.log("sendDataToChild(): here's the json we see: ", jsonContent)
        console.log("sendDataToChild(): since the content is json-parseable, just deliver raw/ascii content")

        // deliver with a message type that directs child to interpret as json (with maybe html, and maybe js)
        actualData = jsonData
        deliveryTag = "txJSON"

      } catch (err) {
        console.log("sendDataToChild(): since the content is NOT JSON-parseable, deliver data as a base-64 data url?")

        //FIXME: assuming it's suitable for display as an image might be presumptuous
        //       Maybe the CONTENT should specify?

        try {
          // convert to base64
          const img64 = Buffer.from(content, 'hex').toString('base64')
          //NOTE: later we prefix with: "data:image/jpg;base64,"

          //console.warn("sendDataToChild():   first few bytes of img64: " + img64.substring(0, 44))

          // Deliver data/content to innerFrame
          // see:     window.addEventListener('message', (event) => {...
          actualData = img64
          deliveryTag = "txdata"
        } catch (error) {
          // Not sure if this would ever get called

          console.log("error: ", error)

          console.warn("CONTENT ERROR?: invalid image content from address or label " + arg
                      + ". Will deliver as text: ", content)
          deliveryTag = "txTxt"
        }
      }
      this.callFrame(id, deliveryTag, actualData)
    }

    // Receives requests from embedded Child/iFrame javascript (based on events: clicks, timers, and markup, etc.)
    //   - fetch a tx
    //   - fetch a bshz asset: bshz://{asset}/{x}/{y}//{z}...
    //   - jump to bshz point: bshz://{asset}/{x}/{y}//{z}...
    async theChildMessageHandler(event) {

      console.log("theChildMessageHandler(): BTW: the contentPresenter Tx is " + this.props.contentPresenterTx)

      const concerningTx = this.props.contentPresenterTx

      if ( event.origin === "null" ) {

        var actualFrame = document.getElementById('contentFrame');
        if ( actualFrame === null ) {
          console.error("couldn't find actual iFrame? IGNORING event/message")
          return
        }

        // Since child (iFrame) sending from a sandbox, the event/message origin is lost.
        // see: https://stackoverflow.com/questions/37838875/postmessage-from-a-sandboxed-iframe-to-the-main-window-origin-is-always-null
        // So, to authenticate the child's event/message, we compare event.source with the known iFrame.contentWindow
        if ( event.origin === 'null' ) {
            if (event.source === actualFrame.contentWindow ) {
              console.log("YAY. authenticated origin WITHOUT .origin (used event.source compared with iFrame.contentWindow")
            } else {
              console.error("theChildMessageHandler(): null event.origin AND wrong iFrame.contentWindow? Sounds shady. IGNORING")
              //alert ( "theChildMessageHandler(): null event.origin AND wrong iFrame.contentWindow? Sounds shady. IGNORING")
              return
            }
        }
      }

      //console.log("Parent GOT a posted message!!! Will examine content...")
      //console.log("  event.data: ", event.data)
      if ( event.data && event.data.mTypeFromChild && event.data.mesg ) {
        const eData = event.data
        console.log("!! Message from child. message type: " + eData.mTypeFromChild)
        console.log("!! message data: ", eData.mesg)
        console.log(" eData itself: ", eData)
        console.log("!! child id: " + eData.id)

        const regardingTx = eData.regarding
        console.log("!! Message from child is regarding " + regardingTx)
        if ( regardingTx !== this.props.contentPresenterTx && eData.mTypeFromChild !== 'alert' ) {
          console.error("stale eData?: ", eData)
          alert("We think this is a stale request - regarding a previous tx " + regardingTx + ". It's not equal to PRESENTER tx " + this.props.contentPresenterTx )
          return
        }

        let limb
        let ownerCount
        if ( this.props.decodedTx ) {
            const decodedTxObj = JSON.parse( this.props.decodedTx );

            limb = decodedTxObj.limbName
            ownerCount = decodedTxObj.outputStates[0].ownerCount
        }

        const mtype = eData.mTypeFromChild.toLowerCase()

        // each element could be one of 4 types:
        //     ljump             - only onclick()
        //     ajump             - only onclick()
        //     ip4jump           - only onclick(), and very rare ( more of a PoC/test for intended service of https://bitshizzle.com/jump/{domain} )
        //     shizzleArray      - during render, with possible sub-values (array element values):
        //         script, OR link
        //         txdata
        //         adata
        //         ldata

        if ( mtype === 'ajump' ) {   // jump to tx at an 'address'

          console.log("ADDRESS Jumper: jump to another asset ("
                      + eData.mesg + "), AND maybe ignore other messages")

          // convert address to txid
          const jumpTxid = await getTxidFromAddr(await openDB(), eData.mesg)
          if (jumpTxid === null) {
            console.warn("FAILED to get tx for address " + eData.mesg)
            return
          } else {
            console.log("ajump: GOT tx " + jumpTxid + " - for jump address " + eData.mesg)

            this.props.jumpToLinkedAsset(jumpTxid, this.props.parent);
          }

        } // address jumper
        else if ( mtype === 'ljump' ) {   // jump to a labeled transaction. WARNING: labels can be re-assigned/updated
          const label = eData.mesg

          console.log("LABEL Jumper: jump to another asset (label '"
                      + label + "'), AND maybe ignore other messages")

          if ( this.props.decodedTx ) {
            console.log("limb: " + limb + ",  ownerCount: " + ownerCount)

            const contentTx = await findContent(await openDB(), ownerCount, limb, label)
            if ( contentTx !== null ) {
              console.log("found tx for label '" + label + "', so, address is " + contentTx.address)
              // convert address to txid
              const jumpTxid = await getTxidFromAddr(await openDB(), contentTx.address)
              if (jumpTxid === null) {
                console.warn("FAILED to get tx for address " + contentTx.address)
                return
              } else {
                console.log("ljump: GOT tx " + jumpTxid + " - for jump address " + contentTx.address)

                this.props.jumpToLinkedAsset(jumpTxid);
              }
            } else {
              console.warn("FAILED to find tx for label '" + label + "'")
            }
          } else {
            console.warn("We don't have the current context, to complete the jump.")
          }
        } // labelJumper
        else if ( mtype === '4jump' ) {   // jump to a mapped ip address. WARNING: ip address can be updated

          if ( this.props.decodedTx ) {
            const record = await checkIP4Mapping( await openDB(), limb, ownerCount)
            if ( record !== null ) {
              console.log("Found mapping record: ", record)
              const ipByte0 = parseInt(record.ip4Mapping.slice(0,2), 16)
              const ipByte1 = parseInt(record.ip4Mapping.slice(2,4), 16)
              const ipByte2 = parseInt(record.ip4Mapping.slice(4,6), 16)
              const ipByte3 = parseInt(record.ip4Mapping.slice(6,8), 16)
              const hostAddress = "https://" + ipByte0 + '.' + ipByte1 + '.' + ipByte2 + '.' + ipByte3
              console.warn("conventional popup host address: ", hostAddress)
              alert("Jumping to registered IPv4 address for ShizzleVerse domain bshz://" + limb + "/" + ownerCount + "\n    " + hostAddress
                  + "\nNOTE: A browser pop-up will now be leaving the ShizzleVerse, and so you are about to view a 'conventional' "
                  + "internet site. There is a higher level of risk when surfing from a 'ShizzleJump'. "
                  + "There is no readily-available authenticated immutability - as there is within the ShizzleVerse.")
              // example: google is https://142.250.80.78
              window.open(hostAddress, "_blank", "width=750,height=610,resizeable")
            }
          } else {
            console.warn("We don't have the current context, to complete the ip4Mapping query.")
          }

        } else if ( mtype === 'shizzlearray' ) {

          // There are 6 possible tag/request types to expect in the array
          //   txdata, or txvideo
          //   Adata
          //   Ldata
          //   script
          //   link

          const shizzTags = eData.mesg
          console.warn("cMH(): parent got a shizzlearray request: ", shizzTags)
          for ( let i = 0; i < shizzTags.length; i++ ) {
            const id   = shizzTags[i].id
            const type = shizzTags[i].type.toLowerCase()
            const arg  = shizzTags[i].arg
            console.log("    shizzTag " + i + " id: " + id)
            console.log("    shizzTag " + i + " type: " + type)
            console.log("    shizzTag " + i + " arg: " + arg)

            if ( type === 'script' || type === 'link' ) {   // reference labeled content. WARNING: labels can be re-assigned/updated
              const label = arg

              console.log("SCRIPT reference of LABELed content: load (label '"
                          + label + "'), AND maybe ignore other messages")

              if ( this.props.decodedTx ) {
                const decodedTxObj = JSON.parse( this.props.decodedTx );

                const limb = decodedTxObj.limbName
                const ownerCount = decodedTxObj.outputStates[0].ownerCount

                console.log("limb: " + limb + ",  ownerCount: " + ownerCount)

                const contentTx = await findContent(await openDB(), ownerCount, limb, label)
                if ( contentTx !== null ) {
                  console.log("found record for label '" + label + "', and content has length " + contentTx.content.length)
                  // convert content to ascii?
                  const utf8ContentToSend = escape(Buffer.from(contentTx.content, 'hex').toString('utf8'))
                  console.log("   But let's send this as ESCAPED utf8 of length" + utf8ContentToSend.length)

                  try {
                    // Deliver data/content to innerFrame
                    // type: "script" or "link"
                    this.callFrame(id, type, utf8ContentToSend)
                  } catch (error) {
                    console.log("error: ", error)
                    console.warn("CONTENT ERROR: invalid script/label content from label " + label)
                  }

                } else {
                  console.warn("FAILED to find record for script label '" + label + "'")
                }
              } else {
                console.warn("We don't have the current context, to complete the script label load.")
              }

            }
            else if ( type === 'txdata' || type === 'txvideo' ) { // image/data contained in 'external' (perhaps non-bshz) transaction, specified by in/output#, chunk#
              // .in puts, and .out puts
              const fields = arg.split(".", 4)
              if ( fields.length !== 4 ) {
                console.warn("CONTENT ERROR: For tx " + regardingTx)
                console.warn("CONTENT ERROR: INVALID tx specification. Has " + fields.length + " fields. Should have 4. Maybe this is a beta page (prior to BitShizzle's release)")
                console.warn("CONTENT ERROR:  message: ", arg)
                continue
              }
              const txId = fields[0]
              const inOut = fields[1]
              const inOutNum = fields[2]
              const chunkNum = fields[3]

              if ( inOut !== 'in' && inOut !== 'out' ) {
                console.warn("CONTENT ERROR: INVALID in/out type specified for element with id " + id + ": " + inOut + ". Should be 'in', or 'out'.")
                continue
              }

              //FIXME: allow ranges of chunks, outputs? All chunks? All outputs?
              console.log("  Will extract chunk #" + chunkNum + " of " + inOut + "[" + inOutNum + "] of " + txId)

              const rawTx = await queryFetchTx(txId, await openDB())
              if ( rawTx === null || typeof rawTx === 'number' ) {
                console.warn("theChildMessageHandler() Ignoring tx for request id " + id + " (txid " + txId + "). It's not there.")

                //FIXME: do anything else?
                continue
              }

              //TODO: check if this is still a concern. If so, document when and why
              if ( concerningTx !== this.props.contentPresenterTx ) {
                console.error("Child (content) request is now stale. DUMPING response data.")
                return
              }

              // We decompose AFTER we've checked that the tx is still relevant
              const tx = decomposeTx(rawTx)

              console.log("  BTW: inOutNum is " + inOutNum)
              console.log("  BTW: inOutNum+1 is " + (inOutNum+1))

              if ( inOut === 'out' && (inOutNum + 1) > tx.outputs.length ) {
                console.warn("CONTENT ERROR: Invalid OUT index: " + inOutNum + " for element with id " + id + ". Exceeds number of outputs: " + tx.outputs.length)
                continue
              }
              if ( inOut === 'in' && (inOutNum + 1) > tx.inputs.length ) {
                console.warn("CONTENT ERROR: Invalid IN index: " + inOutNum + " for element with id " + id + ". Exceeds number of inputs: " + tx.inputs.length)
                continue
              }
              if ( inOutNum < 0 ) {
                console.warn("CONTENT ERROR: Invalid in/out index for element with id " + id + ": " + inOutNum)
                continue
              }

              console.log(" -.-.-.-.-.-.-")
              console.log("theChildMessageHandler(): decomposition of tx: " + txId + ": ", tx)
              console.log("theChildMessageHandler(): got " + tx.inputs.length + " inputs, and "
                          + tx.outputs.length + " outputs for bshz tx request with id " + id)

              let inOuts
              if ( inOut === 'out' ) {
                inOuts = parseTheOutputs( tx.outputs, true )
              } else {
                inOuts = parseTheInputs( tx.inputs, true )
              }

              console.log("Done parsing inputs, or outputs of tx " + txId)
              console.log("will deliver chunk # " + chunkNum + " from in/out #" + inOutNum)

              //FIXME: allow delivery of more than 1 chunk or output?

              const chunks = inOuts[inOutNum]
              console.log("BTW: there are " + chunks.length + " chunks in this in/out")

              const theData = chunks[chunkNum]

              console.log("chunk # " + chunkNum + " from in/out #" + inOutNum + " has a length of " + theData?.length)

//FIXME: consider using sendDataToChild()
              try {

                //FIXME: consider passing a Blob instead of base64 buffer <----

                if ( type === 'txdata' ) {
                  // convert to base64
//FIXME: pass array buffer, not string, then have embedded logic do the same as for txVideo
//       Actually, merge them (embedded logic) back together <----
                  const img64 = Buffer.from(theData, 'hex').toString('base64')
                  //NOTE: later we prefix with: "data:image/jpg;base64,"

                  // Deliver data/content to innerFrame
                  // Pass type of: 'txdata', or 'txvideo'
                  this.callFrame(id, type, img64)
                } else if ( type === 'txvideo' ) {
                  // The embedded/static iframe logic will generate a blob from this buffer
                  const videoBuff = Buffer.from(theData, 'hex')
                  console.log("sending video buff of length " + videoBuff.length + " (to child frame)")

                  console.log("typeof chunkNum: " + typeof chunkNum)
                  if ( chunks.length == (Number(chunkNum) + 2) ) {
                    const videoType = hexStringToAscii(chunks[Number(chunkNum) + 1])
//console.log("RAW videoType: ", chunks[Number(chunkNum) + 1])
                    console.log("Will inject videoType: ", videoType)
if ( videoType === null ) {
  console.error("NOTE: NOTE: NOTE: for some reason we've arrived at a mimeType of null.\n\nThe EMBEDDED javascript will thus default to h264. PERHAPS we should avoid this?")
}
                    this.callFrame(id, type, videoBuff, videoType)
                  } else if ( chunks.length == (Number(chunkNum) + 4) ) {
                    const videoType = hexStringToAscii(chunks[Number(chunkNum) + 1])
//console.log("RAW videoType: ", chunks[Number(chunkNum) + 1])
                    console.log("Will inject videoType1: ", videoType)

                    const theData2 = chunks[Number(chunkNum) + 2]
                    const videoBuff2 = Buffer.from(theData2, 'hex')
                    const videoType2 = hexStringToAscii(chunks[Number(chunkNum) + 3])
                    console.log("AND will inject videoType2: ", videoType2)
                    this.callFrame(id, type, videoBuff, videoType, videoBuff2, videoType2)
                  } else {
                    //FIXME: review what this is about
                    alert("Chunk type was NOT embedded. there are " + chunks.length + " chunks, and we used chunk " + chunkNum)
                    this.callFrame(id, type, videoBuff)
                  }

                }
              } catch (error) {
                console.log("error: ", error)
                //FIXME give more info?
                console.warn("CONTENT ERROR: invalid image content from txid " + txId + " output " + inOutNum + " chunk " + chunkNum)

                //FIXME: could try txJSON, or txTxt?
                //       But, actually, I think we had to expect hex format. hmm.
              }

            } // if "txdata" or "txvideo"
            else if ( type === 'adata' ) {
              console.warn("childMessageHandler(): we're being asked ('adata') to retrieve content from a shizzle address " + arg)

              const contentTxid = await getTxidFromAddr(await openDB(), arg)
              if (contentTxid === null) {
                console.warn("CONTENT ERROR: FAILED to get tx for content address " + arg + ". Perhaps that tx is older than the address-to-txid mapping (in txtab)?")
                continue
              } else {
                console.warn("GOT txid " + contentTxid + " - for content address " + arg)

                // Retrieve the tx, decode, extract the content

                const dtx = await queryFetchDecodeTx( contentTxid, await openDB() )
                if ( dtx === null || typeof dtx === 'number' ) {
                  console.error("childMessageHandler(): trouble getting txid " + contentTxid)
                  continue
                }

                console.log("childMessageHandler(): BTW: dtx: ", dtx)

                // If it's a guest-post get content from the SECOND input
                const guestPost = dtx.input0Params.hostGuestMode === 1 || dtx.input0Params.hostGuestMode === 4
                const inParams = guestPost ? dtx.input1Params : dtx.input0Params;
                console.log("childMessageHandler(): retrieved (adata) tx is a guestPost? " + guestPost)

                const content = inParams.content  // 'raw' data delivered as 2-character ascii hex numbers

                //FIXME: maybe check if content starts with 'B'inary or 'A'scii? <-----
                //       IF it starts with 'B'inary, maybe we return an image
                //       IF it starts with 'A'scii, maybe we return the ascii (presumably
                //       ascii or html to be inserted within the document/content's existing content)
                //         BUT: if the content contains bshzType attribute, can/should we interpret THAT?
                //              THAT might be too much to handle

                this.sendDataToChild(id, content, arg)
              }
            }
            else if ( type === 'ldata' ) {
              const label = arg

              console.log("ldata: Looking for content with label '" + label + "'")

              if ( this.props.decodedTx ) {
                const decodedTxObj = JSON.parse( this.props.decodedTx );

                const limb = decodedTxObj.limbName
                const ownerCount = decodedTxObj.outputStates[0].ownerCount

                console.log("limb: " + limb + ",  ownerCount: " + ownerCount)

                const contentRec = await findContent(await openDB(), ownerCount, limb, label)
                if ( contentRec !== null ) {
                  console.log("found tx for label '" + label + "', so, address is " + contentRec.address)
                  console.log("labeled ('" + label + "') content has true length " + contentRec.content/2)

                  const content = contentRec.content
                  const address = contentRec.address
                  const contentTxid = await getTxidFromAddr(await openDB(), address) // FIXME: only doing this right now to maintain common code below

                  this.sendDataToChild(id, content, arg)

                } else {
                  console.warn("FAILED to find tx for label '" + label + "'")
                }
              } else {
                console.warn("We don't have the current context, to complete the jump.")
              }
            }
            else {
              console.error('CODE ERROR: Unrecognized/unhandled shizzle tag/type: ' + type)
            }
          } // for-loop over each shizzleTag in the delivered array

        } // shizzlearray
        else if ( mtype === 'alert' ) {
          alert("alert from iframe/child:\n\n" + eData.mesg)
        } else {
          console.warn("CONTENT ERROR: unrecognized message type from child/content: " + mtype)
          return
        }

        // HERE is where/when we can send a FINAL event - and actually load the FINAL part
        // for now, make it htmlPart

      } // have needed parameters
    } // childMessageHandler()

    // Use this to deliver to child/iFrame ( in render()'s cPlus embedded script )
    callFrame(id, msgType, msg, metadata = null, msg2 = null, metadata2 = null) {
      console.log("calling frame - sending it data with tag/type " + msgType)

      const theFrame = document.getElementById('contentFrame')
      if ( theFrame !== null ) {
        // see:  https://stackoverflow.com/questions/61548354/how-to-postmessage-into-iframe
        //
        // This gets received by render()'s cPlus embedded script
        theFrame.contentWindow.postMessage({  id: id,
                                              mesgType: msgType,
                                              mesg: msg,
                                              meta: metadata,
                                              mesg2: msg2,        // experimental SECOND video
                                              meta2: metadata2    // experimental SECOND mimeType
                                          }, '*');
      } else {
        console.warn("callFrame(): You probably clicked too fast. Won't deliver message of type " + msgType)
      }
    }

    // Once rendered, check if we should generate a PaymentCode QR Code
    componentDidMount() {

      const pcodeHolder = document.getElementById('pcodeHolder');
      const decodedTx = this.props.decodedTx;

      let inParams = null;
      let decodedTxObj = null;
      if ( decodedTx ) {
        decodedTxObj = JSON.parse( this.props.decodedTx );
        inParams = decodedTxObj.input0Params;
      }

      console.log("ContentPresenter componentDidMount(): pcodeHolder: ", pcodeHolder);

      if ( pcodeHolder !== null ) {
        const pcode = inParams.pcode;
        if ( pcode.length > 0 ) { // if pcode &&
          console.log("ContentPresenter componentDidMount(): pcode: ", pcode);

          // see this: https://www.npmjs.com/package/qrcode-generator
          // and this: https://npm.runkit.com/qrcode-generator
          var qr = qrcode(4, 'L');
          qr.addData({pcode});
          qr.make();
          // .createImgTag(cellSize, margin)
          pcodeHolder.innerHTML = qr.createImgTag(6, 8);
        }
      }

    } // componentDidMount()

    componentWillUnmount() {
/*
      //FIXME: consider moving listener-registration from render(), to componentWillMount(),
      //       and removing listeners HERE
        console.error("CONTENT PRESENTER unmounting")

        // fix Warning: Can't perform a React state update on an unmounted component
        // see: https://stackoverflow.com/questions/53949393/cant-perform-a-react-state-update-on-an-unmounted-component
        this.setState = (state,callback)=>{
            return;
        };
*/
    }

    // From https://dev.to/pulljosh/how-to-load-html-css-and-js-code-into-an-iframe-2blc
    //getGeneratedPageURL( html, css, js) {}

    // Let the parent get the address, and copy the link to clipboard
    async showLinkToPost() {
      await this.props.shareLinkToContent( this.props.contentPresenterTx )
    }

    render() {  // contentPresenter
      // ***  DEVICE-DEPENDENT STYLING  ***
      // For mobile, "detect" landscape screen mode
      const landscape = this.props.isMobile ? window.innerWidth > window.innerHeight : false
      // For mobile, shrink the About, Security, and Glossary modals
      const modalClassName   = this.props.isMobile ?
                                      (landscape ?
                                              "modalMobileLandscape"
                                          :
                                              "modalMobilePortrait"
                                      )
                                  :
                                      ""
      const modalContentClassName = this.props.isMobile ? "modalContentMobile" : ""
      const modalBottomClassName  = this.props.isMobile ? "modalBottomMobile"  : ""

      let cont = null;
      let tx = null;
      let pc = null;

      let bgMain = null;
      let bgPost = null;
      let bgTip = null;
      let bgNumAdded = null;

      if ( this.props.decodedTx ) {
        let bgOtherUser = null;
        let bgSpawned = null;

        let utf8Content = null;
        let contentDivClass = 'scrollFull'
        const decodedTxObj = JSON.parse( this.props.decodedTx );
        let guestPostMention = null

        const ourOrigin = 'https://localhost:3000';
        const actualSite = 'https://bitshizzle.com';

        // If it's a guest-post mention that, and make background a different color: blue
        if ( this.props.guestPost ) {
          const hostDomain = hexStringToAscii( decodedTxObj.outputStates[0].namePath )
          const guestDomain = hexStringToAscii( decodedTxObj.outputStates[1].namePath )

          contentDivClass = 'scrollGuest'

          // create a link for originalHostAddress
          const hostAddress = decodedTxObj.outputStates[0].address
          const guestAddress = decodedTxObj.outputStates[1].address
          const guestNPos = hostAddress.lastIndexOf("guest")
          const originalHostAddress = hostAddress.substring(0, guestNPos - 1)//.slice(0, -1) // slice off final '/' at the end
          const href = ourOrigin + originalHostAddress.substring(6)
          //console.warn("guestNPos is " + guestNPos)
          console.warn("We should display content from the guest-post - at original address: " + originalHostAddress)
          //console.warn("href: " + href)

          //FIXME: IF property domainOfInterest is defined, use that
          //       to choose an address
          guestPostMention =  this.props.submode ?
                              <div>
                                  A <b>comment</b> from domain <b style={{color:'blue'}}>{guestDomain}</b>
                                  &nbsp;regarding <b style={{color:'blue'}}>{hostDomain}</b>
                              </div>
                          :
                              <div>This is a <b>Guest-Post</b> from domain/limb <b>'{guestDomain}'</b>
                                  &nbsp;regarding a post on domain <b>'{hostDomain}'</b>
                                  <br></br>(original host address upon which it was built: <a href={href}>{originalHostAddress}</a>)
                                  <br></br>(the GUEST address: <a href={href}>{guestAddress}</a>)
                              </div>
        }

        // If it's a guest-post get content from the SECOND input
        const inParams = this.props.guestPost ? decodedTxObj.input1Params : decodedTxObj.input0Params;
        //FIXME: at some point we actually have to look at prevOut <-----

        // maybe only used for BitGroup?
        const outParams = decodedTxObj.outputStates[0];

        if ( decodedTxObj.contentAltered ) {
          console.warn("ContentPresenter: we've detected that the content has been 'altered' (decrypted)")
        }

        let attachedImagePart = ''
        let referencedMediaPart = ''

        if ( decodedTxObj.contentAttached ) {
          console.log("content attached. real length:  ", decodedTxObj.attachedContent.length/2)
          const attachmentBase64 = Buffer.from(decodedTxObj.attachedContent, 'hex').toString('base64')
          console.log("content attached. base-64 length:  ", attachmentBase64.length)

          attachedImagePart =
                `
                <img src='data:image/png;base64,` + attachmentBase64 + `' alt='attached image' />
                <br></br>
                `
        } else if ( decodedTxObj.referencedMediaTxId ) {
          console.warn("This tx references a Media tx: " + decodedTxObj.referencedMediaTxId)

          //NOTE: child elements (<source src='' type='' />) will be injected into the <video> tag
          referencedMediaPart =
                `
                <video width='320' height='240' bshzType='txvideo' bshzArg='` + decodedTxObj.referencedMediaTxId + `.out.0.1' controls >
                  Your browser does not support the video tag.
                </video>
                <br></br>
                `

          console.warn("HERE's the injected part: " + referencedMediaPart)
        }

        // If the content has been pre-processed, use the resultant 'altered' content
        const contentHex = decodedTxObj.contentAltered ?
                            decodedTxObj.alteredContent // for Dialog, a hex string
                          :
                            inParams.content;

        const contentExists = contentHex && contentHex.length > 0
        if ( !contentExists ) {
          console.log("contentPresenter render(): null contentHex")
        } else {
          utf8Content = Buffer.from(contentHex, 'hex').toString('utf8')
                         //hexStringToAscii( contentHex )      // <--- this was introducing artifact around some emojis
        }

        const contentName = inParams.contentName;
        const contentNameExists = contentName && contentName.length > 0
        if ( contentNameExists ) {
          console.log("contentName: " + contentName);
        }

        if ( contentExists || contentNameExists ) {

          //FIXME: consider moving listener-registration to componentWillMount(), and removing in componentWillUnmount()
          //       Test using dynamic content (which utilizes the child message handler)

          // Track any listener we add. Avoid duplicates by first deleting any we've already added
          if ( !window.listenersCollection ) {
            window.listenersCollection = new Set();
            //console.log("CREATED a new global listeners collection")
          }
          window.listenersCollection.forEach((listener) => {
            //console.log("FOUND a listener. deleting it")
            window.removeEventListener("message", listener)
            window.listenersCollection.delete( listener )
          });

          // Get ready to receive requests from child/iFrame
          window.listenersCollection.add( this.theChildMessageHandler )
//alert("added childMessageHandler")
          window.addEventListener("message", this.theChildMessageHandler)

          console.log("ContentPresenter  render():  laying-out iframe boilerplate to hold/present content...")
          //FIXME: maybe a control panel to specify/toggle:
          //       allow-popups  allow-modals  allow-forms

          // Inject well-known script function (shizzleBRequest()) that iFrame content code can call
          //   - perhaps to 'retrieve' a referencedTx struct, or content of a referenced label
          // If we take this approach, of referencing a label from a well-known json field, why not do the same with referencedTx?
          //   Maybe because formally referencing a tx is more formal, and less subjective.
          //
          // see also:  https://stackoverflow.com/questions/61548354/how-to-postmessage-into-iframe
          // see also:  https://stackoverflow.com/questions/34424845/adding-script-tag-to-react-jsx

          // If content has JSON elements of 'html' or 'js', render these within the iFrame.
          // Otherwise, or if there are errors, just use (display) the raw source

          // FIXME: what if it's an image? Shouldn't it contain info on its format?
          //        Could we assume it's hex-encoded? Must we then specify format (jpeg, png, mpeg)?
          //        What of future image/video/hologram formats?

          console.log("ContentPresenter(): utf8Content: " + utf8Content)
          let type = null
          let version = 0
          let htmlPart = utf8Content
          let jsPart = ''
          try {
            const jsonContent = JSON.parse(utf8Content);
            type     = jsonContent.type
            version  = jsonContent.ver
            htmlPart = jsonContent.html
            jsPart   = jsonContent.js

            //FIXME: check for other fields/parts
            //       toFetch?
            //       hints: deleteAssetsBeforeX

          } catch (err) {
            console.warn("error json-parsing ascii content (may be truncated): ", err.toString())

            // only add <img...> attached image file (or <video...> referenced txId) if content is simple (not JSON)
            htmlPart = attachedImagePart + referencedMediaPart + htmlPart
          }
          console.log("ContentPresenter(): type: " + type)
          console.log("ContentPresenter(): version: " + version)
          console.log("ContentPresenter(): htmlPart: " + htmlPart)
          console.log("ContentPresenter(): jsPart: " + jsPart)

          if ( typeof htmlPart === 'undefined' ) {
            htmlPart = ''
            console.error("redefined undefined htmlPart to be blank")
          }
          if ( typeof jsPart === 'undefined' ) {
            jsPart = ''
            console.error("redefined undefined jsPart to be blank")
          }

          // Inject a few functions to:
          //   - request content referenced in special markup (to parent)
          //   - replace certain markup with jumps to other assets/"pages" (once DOM loaded)
          //   - add listener for responses from parent
          const cPlus =
                    `<html>
                      <head>
                        <style>
                          .bshzLink:hover {
                            cursor: pointer;
                          }
                        </style>
                        <script>
                          ourTx='` + this.props.contentPresenterTx + `';
                          function alertParent(id, msg) {
                            parent.parent.postMessage( {id: id, regarding: ourTx, mTypeFromChild: 'alert', mesg: msg}, '*')
                            console.log('innerFrame posting alert msg regarding theTx (ourTx) ' + ourTx + ' to parent...')
                          }
                          function shizzleBRequest(id, type, msg){
                            parent.parent.postMessage( {id: id, regarding: ourTx, mTypeFromChild: type, mesg: msg}, '*')
                            console.log('innerFrame posting message (type: ' + type + ') regarding theTx (ourTx) ' + ourTx + ' to parent...')
                          };
 \
                          function replaceWithJump(id, shizzle, argAttr, jumpType) {
                            console.log('replaceWithJump() - type: ' + jumpType);
 \
                            const existingClass = shizzle.getAttribute('class')
                            if ( existingClass === null ) {
 \                            // set pointing cursor
                              shizzle.setAttribute('class', 'bshzLink');
                            } else {
                              console.warn('element ALREADY has class attribute. Will not set to bshzLink')
                            }
 \
                            const existingLogic = shizzle.getAttribute('onclick')
                            console.warn('rwj(): onclick attr BEFORE: ' + existingLogic)
 \                          // bshz link/jump markups each get hardcoded logic (inserted) to communicate with the shizzle browser
                            if ( existingLogic === null ) {
                              shizzle.setAttribute('onclick', 'shizzleBRequest(\"' + id + '\", \"' + jumpType + '\" + \"jump\", \"' + argAttr + '\")');
                              console.log('rwj(): done INJECTING onclick and class attributes into that element')
                            } else {
                              console.warn('element ALREADY has onclick logic. Will leave it alone - not add onclick logic')
                            }
 \
                          }
 \
 \                        // pop-up a window to the specified link (Image)
                          function shizzleipop(link) {
                            console.warn('shizzleIPop(): got link to: ' + link)
                            const popSrc = escape(\"<html><body><img src='\" + link + \"' width='100%' height='auto'></body></html>\")
                            console.warn('using iframe src: ' + popSrc)
                            const outPop = \"<iframe width='100%' height='100%' src='data:text/html,\" + popSrc + \"' alt='some pic' \
                                    title='Image Viewer' \
                                    frameborder='0' \
                                    allowfullscreen></iframe>\"
                            var w = window.open('', '', 'width=750,height=610,resizeable,scrollbars').document.write(outPop);
                          }
 \
 \                        // pop-up a window to the specified link (YouTube, or Vimeo)
                          function shizzlevpop(link) {
                            console.warn('shizzleVPop(): got link to: ' + link)
                            const outPop = \"<iframe width='360' height='270' src='\" + link + \"' \
                                    title='YouTube video player' \
                                    frameborder='0' \
                                    allow='accelerometer; \
                                        autoplay; \
                                        encrypted-media; \
                                        gyroscope; \
                                        picture-in-picture' \
                                    allowfullscreen></iframe>\"
                            var w = window.open('', '', 'width=375,height=305,resizeable,scrollbars').document.write(outPop);
                          }
 \
                          function replaceWithPopUpLink(shizzle, argAttrLink, popType) {
                            console.log('replaceWithPopUpLink(): ' + popType)
 \                          // bshz vpop and ipop markups each get hardcoded logic (inserted)
 \                          // to open a pop-up linking to specified video, or image URL
  \                         // This calls either function shizzlevpop(), or function shizzleipop()
                            shizzle.setAttribute('onclick', 'shizzle' + popType + '(\"' + argAttrLink + '\")')
 \
                            const existingClass = shizzle.getAttribute('class')
                            if ( existingClass === null ) {
 \                            // set pointing cursor on hover
                              shizzle.setAttribute('class', 'bshzLink');
                            } else {
                              console.warn('This element ALREADY has a class attribute. Will not set to bshzLink')
                            }
 \
                            console.log('rwwfl(): done INJECTING onclick and class attributes into that element')
                          }
 \
                          function callDynamicFunc(function_name) {
                            console.log('callDynamicFunc() - about to call content-specified after-content-loaded function: func_ ' + function_name)
                            window['func_' + function_name]();
                          }
 \
                          function elementLoadedHookInstaller(event) {
                            if ( event.currentTarget.bshzloadedhook !== null ) {
                              console.warn('Now calling content-specified hook func named: ' + event.currentTarget.bshzloadedhook)
                              callDynamicFunc(event.currentTarget.bshzloadedhook)
                            } else {
                              console.error('no hook to call? ' + event.currentTarget)
                            }
                          }
 \
 \                        // Here we handle the response to shizzle tag processing
 \                        // by the parent
 \                        //                                'txvideo'           'src'         dataURL      SECOND data URL
                          function injectRetrievedVideo(id, helpfulOriginalTag, newAttribute, newAttrData, url2 = null) {
                            const elem = document.getElementById(id)
                            if ( elem && elem !== null ) {
                              console.log('dcfs()   CHILD FOUND THE PROPER VIDEO ELEMENT for ' + helpfulOriginalTag + ' delivery')
                              console.log('dcfs()    newAttribute: ', newAttribute)
                              console.log('dcfs()    here is the outer: ', elem.outerHTML)
                              console.log('dcfs()    here is the inner: ', elem.innerHTML)
 \
 \                            // set attribute {newAttribute} set to retrieved data {newAttrData}
                              if ( newAttribute !== '' ) {
                                console.log('dcfs()  setting attribute ' + newAttribute + ' with data of length '
                                          + newAttrData.length )
                                //elem.setAttribute(newAttribute, newAttrData)
                                const sourceFragment = document.createElement('source')
                                sourceFragment.setAttribute('src', newAttrData)
                                elem.appendChild(sourceFragment)
                                if ( url2 !== null ) {
                                  const sourceFragment2 = document.createElement('source')
                                  sourceFragment2.setAttribute('src', url2)
                                  elem.appendChild(sourceFragment2)
                                }
 \
                                if ( helpfulOriginalTag === 'txvideo' ) {
                                  console.warn('newAttrData: ' + newAttrData)

                                  elem.addEventListener('error', function(err){
                                    // This craps out in Safari 6
                                    console.error(elem.error, err);
                                    console.error('WHOA: error: ', elem.error)
                                    console.error('WHOA: err: ', err)

                                    alertParent(id, 'VIDEO ERROR.  code: ' + elem.error.code + '   message: ' + elem.error.message);
                                  });
                                }
                              }
 \
 \                            // CRUCIAL for loading/running scripts from content:
 \                            //     It may be necessary to delay running a script until the element into
 \                            //     into which it was loaded signals 'load'. This 'bshzLoadedHook' attribute
 \                            //     offers a mechanism to wait for that.
 \                            // IF a bshzLoadedHook attribute was defined, add a listener to call it back when element is loaded
                              const hookAttr = elem.getAttribute('bshzLoadedHook');
                              if ( hookAttr !== null ) {
                                elem.addEventListener('load', elementLoadedHookInstaller, false)
                                console.warn('dcfs() adding hook function for when element is done loading: ' + hookAttr)
                                elem.bshzloadedhook = hookAttr
                              }
 \
                            } else {
                              console.error('dcfs() innerFrame CHILD Couldnt find proper element to deliver content: id ' + event.data.id)
                              alertParent(id, 'BIG PROBLEM injecting retrieved video - could not find id '+id)
                              return
                            }
                          }
 \
 \                        // Here we handle the response to shizzle tag processing
 \                        // by the parent
                          function injectRetrievedContent(id, helpfulOriginalTag, newAttribute, newAttrData) {
                            const elem = document.getElementById(id)
                            if ( elem && elem !== null ) {
                              console.log('dcfs()   CHILD FOUND THE PROPER ELEMENT for ' + helpfulOriginalTag + ' delivery')
                              console.log('dcfs()    here is the outer: ', elem.outerHTML)
                              console.log('dcfs()    here is the inner: ', elem.innerHTML)
 \
 \                            // set attribute {newAttribute} set to retrieved data {newAttrData}
                              if ( newAttribute !== '' ) {
                                console.log('dcfs()  setting attribute ' + newAttribute + ' with data of length '
                                          + newAttrData.length )
                                elem.setAttribute(newAttribute, newAttrData)
 \
                                if ( helpfulOriginalTag === 'txvideo' ) {
                                  console.warn('newAtrrData: ' + newAttrData)

                                  elem.addEventListener('error', function(err){
                                    // This craps out in Safari 6
                                    console.error(elem.error, err);
                                    console.error('WHOA: error: ', elem.error)
                                    console.error('WHOA: err: ', err)

                                    alertParent(id, 'VIDEO ERROR.  code: ' + elem.error.code + '   message: ' + elem.error.message);
                                  });
                                }
                              }
 \
 \                            // CRUCIAL for loading/running scripts from content:
 \                            //     It may be necessary to delay running a script until the element into
 \                            //     into which it was loaded signals 'load'. This 'bshzLoadedHook' attribute
 \                            //     offers a mechanism to wait for that.
 \                            // IF a bshzLoadedHook attribute was defined, add a listener to call it back when element is loaded
                              const hookAttr = elem.getAttribute('bshzLoadedHook');
                              if ( hookAttr !== null ) {
                                elem.addEventListener('load', elementLoadedHookInstaller, false)
                                console.warn('dcfs() adding hook function for when element is done loading: ' + hookAttr)
                                elem.bshzloadedhook = hookAttr
                              }
 \
                            } else {
                              console.error('dcfs() innerFrame CHILD Couldnt find proper element to deliver content: id ' + event.data.id)
                              alertParent(id,'BIG PROBLEM injecting retrieved content. Could not find id ' + id)
                              return
                            }
                          }
 \
                          function runOnStart() {
                            const elems = document.querySelectorAll('[bshzType]')
                            console.warn('we found ' + elems.length + ' elements with bshzType attributes')
 \
 \                          // Assign an id to each shizzle tag. Keep track of each.
 \                          // Some will receive immediate onclick logic added
 \                          // Some will be processed by the parent - response handled in injectRetrievedContent()
                            let listOfShizzleIDs = []
                            let z = 0
                            for ( const e of elems ) {
                              console.warn('   Here is shizzle tag/element #' + z + ':', e)
                              const attrsOfE = e.attributes
                              console.log('      This has ', attrsOfE.length + ' attributes')
                              let argAttr = null
                              let typeAttr = null
                              for ( let i = 0; i < attrsOfE.length; i++ ) {
                                if ( attrsOfE[i].name === 'bshztype' ) {
                                  typeAttr = attrsOfE[i].value
                                  console.log('            bshztype is ' + typeAttr)
                                } else if ( attrsOfE[i].name === 'bshzarg' ) {
                                  argAttr = attrsOfE[i].value
                                  console.log('            bshzarg is ' + argAttr)
                                } else {
                                  console.log('          NON-SHIZZLE attribute ' + attrsOfE[i].name + ' has value ' + attrsOfE[i].value)
                                }
                              }
                              z++
                              if ( typeAttr === null || (argAttr === null && typeAttr !== 'ip4jump') ) {
                                console.warn('type or arg is null. INVALID shizzle element')
                                continue
                              }
 \
                              const id = 'bshzId' + Math.random().toString(16).slice(2)  \ //NOTE: first 2 chars are "0."
                              e.setAttribute('id', id);
                              console.log('  --> innerFrame found shizzle tag ' + (z-1) + ': id ' + id + '  type ' + typeAttr + '  arg ' + argAttr)
 \
                              if ( typeAttr === 'ipop' || typeAttr === 'vpop' ) {
                                console.warn('innerFrame reviewing shizzle tags, found type ' + typeAttr + '. Handling it now - arg is ' + argAttr)
                                replaceWithPopUpLink(e, argAttr, typeAttr)
 \                              // video and image frame links do not need shizzle browser to 'retrieve' anything special
 \                              // so, we need not include this element in the array we pass up to it
                              } else if ( typeAttr === 'ljump' ) {
                                console.log('innerFrame    --> found L JUMP element')
                                console.log('innerFrame      -> argAttr: ' + argAttr)
                                replaceWithJump(id, e, argAttr, 'L')
                              } else if ( typeAttr === 'ajump' ) {
                                console.log('innerFrame    --> found A JUMP element')
                                console.log('innerFrame      -> argAttr: ' + argAttr)
                                replaceWithJump(id, e, argAttr, 'A')
                              } else if ( typeAttr === 'ip4jump' ) {
                                console.warn('innerFrame reviewing shizzle tags, found type ' + typeAttr + '. Handling it now.')
                                replaceWithJump(id, e, argAttr, '4')
                              } else {
                                listOfShizzleIDs.push({id: id, type: typeAttr, arg: argAttr})
                              }
                            }
 \
                            console.warn('innerFrame sending ARRAY of shizzle tags for processing (even if empty): ', listOfShizzleIDs);
                            parent.parent.postMessage( {id: 0, regarding: ourTx, mTypeFromChild: 'shizzlearray', mesg: listOfShizzleIDs}, '*')
 \
                          } //runOnStart()
 \
 \                        // Receive individual shizzle tag processing responses from Parent
                          window.addEventListener('message', (event) => {
                            console.log('    --> innerFrame got message/response')
 \                          // verify origin
                            if ( event.origin !== 'null' && event.origin !== '` + ourOrigin + `' && !event.origin.startsWith('https://192.168.') && event.origin !== '` + actualSite + `' ) {
                              console.error('innerFrame IGNORING posted message from origin: ' + event.origin)
                              console.error('REMOVE THIS:  note ourOrigin: ` + ourOrigin + `')
                              console.error('note event.origin: ', event.origin)
                              console.log(' AND, here is the event: ', event)
                              alertParent(0, 'child ignoring message from questionable origin: ' + event.origin)
                              return;
                            }
\
                            if (event.data && event.data.mesgType) {
                              console.log('innerFrame got response of type ' + event.data.mesgType +
                                          ' - regarding id ' + event.data.id)
\
\                             //NOTE: the ORIGINAL content tag/arg may have been 'adata', or 'ldata',
\                             //      BUT, when the content is delivered here by parent, it will have
\                             //      a delivery tag of txTxt, txJSON, or txdata (or script, or link)
                              if (event.data.mesgType === 'script') {
                                console.log('innerFrame got requested SCRIPT data of length: ' + event.data.mesg.length)
                                //console.log('content: ' + event.data.mesg)
                                injectRetrievedContent(event.data.id, 'script', 'src', 'data:text/javascript,' + event.data.mesg)
\
                              } else if (event.data.mesgType === 'link') {
                                console.log('innerFrame got requested LINK data of length: ' + event.data.mesg.length)
                                //console.log('content: ' + event.data.mesg)
                                injectRetrievedContent(event.data.id, 'link', 'href', 'data:text/css,' + event.data.mesg)
\
                              } else if (event.data.mesgType === 'txdata') {
                                console.log('innerFrame got chunk of requested data-containing tx. Data length: ' + event.data.mesg.length)
\
\                                // find proper element, and replace it with
\                                // specified new element type, and set a specific attribute with specified data
                                injectRetrievedContent(event.data.id, 'txdata', 'src', 'data:image/jpg;base64,' + event.data.mesg)
\
                              } else if (event.data.mesgType === 'txvideo') {
                                console.log('innerFrame got chunk of requested txvideo data-containing tx. Data length: ' + event.data.mesg.length)
\
\                                // find proper element, and replace it with
\                                // specified new element type, and set a specific attribute with specified data
\                                // was:    'data:video/webm;base64,' + event.data.mesg
\
                                const meta = event.data.meta === null ? 'video/webm;codecs=h264' : event.data.meta
                                console.warn('will use mimeType of ' + meta)
                                if ( event.data.meta === null ) {
                                  console.error('NOTE: child using DEFAULT mimeType: ' + meta)
                                }
\                                // NOTE the [] around the video data (.mesg) !!
                                const videoBlob = new Blob( [ event.data.mesg ], { type: meta});
                                console.warn('generated from message of length' + event.data.mesg.length)
                                console.warn('Generated blob ', videoBlob);
                                const url = URL.createObjectURL(videoBlob);
                                console.warn('will pass/inject video blob url of ', url);

                                let url2 = null
                                if ( event.data.mesg2 !== null && event.data.meta2 !== null ) {
                                  const videoBlob2 = new Blob( [ event.data.mesg2 ], { type: event.data.meta2});
                                  console.warn('ALSO generated from message2 of length' + event.data.mesg2.length)
                                  console.warn('ALSO Generated blob2 ', videoBlob2);
                                  url2 = URL.createObjectURL(videoBlob2);
                                  console.warn('will pass/inject video blob url2 of ', url2);
                                }
                                //injectRetrievedContent(event.data.id, 'txvideo', 'src', url);
                                injectRetrievedVideo(event.data.id, 'txvideo', 'src', url, url2);
\                                //URL.revokeObjectURL(videoBlob);
\
                              } else if ( event.data.mesgType === 'txJSON' ) {
                                console.log('innerFrame: got chunk of requested tx - JSON-encoded. Data length: ' + event.data.mesg.length)
                                console.log('innerframe: the json were about to parse: ', event.data.mesg)
\
                                try {
                                  const jsonContent = JSON.parse( event.data.mesg );
                                  console.log('innerFrame: parsed the JSON from parent. Using/injecting html part into a span')
                                  const htmlPart = jsonContent.html
\
\                                  //IMPORTANT NOTE: injected html is NOT examined for bshzType attribute
                                  injectRetrievedContent(event.data.id, 'txdata', '', '')
\
                                } catch (err) {
                                  console.error('innerFrame: trouble parsing txJSON message from parent')
                                }
                              } else if ( event.data.mesgType === 'txTxt' ) {
                                console.log('innerFrame: got chunk of requested tx - delivered as ascii/text. Data: ' + event.data.mesg)
                                injectRetrievedContent(event.data.id, 'txdata', '', '')
\
                              } else {
                                console.error('innerFrame: unrecognized message type: ' + event.data.mesgType)
                              }
                            } else {
                              console.error('NOTE: child got message with questionable fields');
                              alertParent(0, 'child got message with questionable fields');
                            }
\
                          });
\
 \                        // Identify and process all elements that have a bshzType attribute
                          if(document.readyState !== 'loading') {
                            // this mitigates safari race
                            runOnStart();
                          } else {
                            document.addEventListener('DOMContentLoaded', (event) => {
                                runOnStart()
                            })
                          }
 \
 \
                        </script>` + jsPart +
                     `</head>
                      <body>` +
                        htmlPart +
                     `</body>
                    </html>`;
          //console.warn("child content (before escaping): " + cPlus)
          const contentPlus = escape( cPlus )
          // vestigial intermediate iframe
          const srcdoc =
                    `<html>
                      <head>
                        <script>
                          window.addEventListener("message", (event) => {
 \                          // verify origin
                            if ( event.origin !== '` + ourOrigin + `' ) {
                              console.error("middle frame: CHILD IGNORING posted message from origin: " + event.origin)
                              return;
                            } else {
                              console.log("CHILD got message. ORIGIN of message: ", event.origin)
                              if (event.data && event.data.mesgType) {
                                console.log("CHILD got message of type " + event.data.mesgType)
                                console.log("CHILD got message regarding id " + event.data.id)
 \
                                console.log("FORWARDING to child...")
                                document.getElementById('innerFrame').contentWindow.postMessage(event.data, "*")
 \
                              }
                            }
                          }, false);
 \
                          console.log("Child done setting-up event listener")
                          document.addEventListener("DOMContentLoaded", (event) => {
                            console.log('DOM is ready. Placing base content')
                            const content = "` + contentPlus + `";
                            document.getElementById('innerFrame').src = 'data:text/html,' + content;
                            console.log('Base content placed')
                          });
                          console.log("Child done setting-up listeners")
 \
                        </script>
                      </head>
                      <body>
                        <div className="scrollFull">
                        <iframe style="width: 100%;height: 875px;" id="innerFrame" title="inner frame">
                        </iframe>
                        </div>
                      </body>
                    </html>`

          // Don't allow guest-posts to use javascript, modals, nor forms
          //FIXME: If *WE'RE* make the guest-post, allow/support javascript, modals, and forms
          //       That requires understanding the 'domain-of-interest'
          //FIXME: ponder something similar for BitPost
          //FIXME: why allow forms for content at all? TBD
          const allowJsModalsAndForms = this.props.guestPost ? '' : ' allow-scripts allow-modals allow-forms'
          const sandboxParams = "allow-popups-to-escape-sandbox allow-popups" + allowJsModalsAndForms

          const frameHeight = this.props.submode ? "200" : "545"
          const cssHeight   = this.props.submode ? "212px" : "550px" // fixme: override scrollGuest, or scrollFull
          const innerCssHeight   = this.props.submode ? "188px" : "534px" // fixme: override scrollGuest, or scrollFull
          const frameWidth  =  "100%"
          const cssWidth  =    "100%"  // for subMode, WAS at 75%, but now within a table, not needed
          // NO allow-same-origin
          //FIXME: review <div className="scrollFull"> (above) vs  <div className={contentDivClass}> (below)

          const contentBorderWidth = '6px'  //this.state.byTheUser ? '8px' : '8px'
          const contentBorderColor = this.props.contentEncrypted ? 'pink' : 'lightGrey'
          //NOTE: on safari, we found that we had to swap-out null : iframe to
          //      prevent stale (encrypted) content
          const ifrm = this.props.contentPending ?
                        <>
                        </>
                      :
                            <iframe sandbox={sandboxParams} id="contentFrame" width={frameWidth} height={frameHeight}
                                    title="content frame" srcDoc={cPlus}  ref={this.refToIFrm}  style={{backgroundColor: '#fee9ff'}}>
                            </iframe>

          const fullScreenExplainerText = <>Full Screen</>
          const fullScreenExplainer = <BsArrowsFullscreen onClick={() => requestFullScreen(this.refToIFrm)} style={{height:'20px', width:'20px', color: bshzColors.purple,cursor:'pointer'}}/>
          const popUpFullScreen = <Popup style={{backgroundColor: bshzColors.ltYellow}} trigger={fullScreenExplainer} wide="very" content={fullScreenExplainerText} hideOnScroll/>

          const shareLinkExplainerText = <>Share this post</>
          const shareLinkExplainerIcon = <BsFillShareFill onClick={this.showLinkToPost}   style={{height:'20px', width:'20px', color: bshzColors.purple, cursor:'pointer'}} />
          const popUpShareLink = <Popup style={{backgroundColor: bshzColors.ltYellow}} trigger={shareLinkExplainerIcon} wide="very" content={shareLinkExplainerText} hideOnScroll/>

          cont =
            <>
              {this.props.submode ? <></> : <>Content Label: {contentName}</>}
              {guestPostMention}

              <div className={contentDivClass} style={{ height: cssHeight,
                                                        width: cssWidth,
                                                        borderWidth: contentBorderWidth,
                                                        borderStyle: 'solid',
                                                        borderColor: contentBorderColor}}>
                    {ifrm}
              </div>
              <div style={{ margin:'3px', padding:'0px'}}>
                &nbsp;{popUpFullScreen} &nbsp;{popUpShareLink}
              </div>
            </>;
        } // content/name exists

        const txId = inParams.txId;
        const txIdExists = txId && txId.length > 0;
        const txDescr = inParams.txDescription;
        const txDescrExists = txDescr && txDescr.length > 0;

        if ( txIdExists || txDescrExists ) {
          tx =
            <>
              Reference Tx (Label: {txDescr} )
              <div className="scroll0">
                {txId}
              </div>
            </>;
        }

        const pcode = inParams.pcode;
        const pcodeExists = pcode && pcode.length > 0;
        if ( pcodeExists ) {
          pc =
            <>
              Payment Code:
              <div className="scroll0">
                {pcode}
              </div>
              <div id="pcodeHolder"></div>
            </>;
        }

        // num users?         out state   <----
        // post num?          out state   <----
        // maxPostLen
        // satsPerPost
        // maxBlock
        // startedAtPost
        //FIXME: NOTE: there may be a max post num (for sub-groups). Embedded in code?
        // which user posted?  ???

        const bgStartedAtPost      = outParams.startedAtPost;
        const bgGroupName          = outParams.groupName;
        const bgNumUsers           = outParams.numUsers;
        const postNum              = outParams.postNum;
        const bgSatsPerPost        = outParams.satsPerPost;
        const unlockedContract     = inParams.unlockedContract;
        const bitGroupUserNum      = inParams.bgUser;                 // 0 doesn't show-up
        const bitGroupCmd          = inParams.bgCmd;
        const bitgroupPost         = inParams.bgPost;
        const bitgroupOtherUserNum = inParams.bgOtherUser;            // 0 doesn't show-up
        const bgNewUserName        = inParams.bgNewUserName;
        const bitgroupSpawnedGroup = inParams.bgSpawnedGroup;
        const bgNumProfilesAdded   = inParams.bgNumProfilesAdded;
        const bgAddedProfiles      = inParams.bgAddedProfiles;
        const bgTipAmount          = inParams.bgTipAmount;
        const bgTipToUserNum       = inParams.bgTipToUserNum;
        const finalPost          = inParams.finalPost;
        const claimingBounty     = inParams.claimingBounty;

        // The 'other user' is only interesting if it's different than 'user'
        if ( bitgroupOtherUserNum && bitgroupOtherUserNum !== bitGroupUserNum ) {
          bgOtherUser =
            <div>
              Other User: {bitgroupOtherUserNum}
            </div>
        }
        if ( bgTipAmount && bgTipAmount > 0 ) {
          bgTip =
            <div>
              Tip to user # {bgTipToUserNum} of {bgTipAmount} satoshis
            </div>
        }

        //FIXME: consider allowing uber mods to admin. maybe they already can
        //FIXME: maybe a separate count for numUsers in the subGroup? so far, IIRC, it's the same
        //FIXME: if it started at post num other than 0, it's a SUB group:
        //             so, mention SUB group.
        //             It either:
        //               a) has a name, and maybe lasts longer  <----- what's the limit? startedAtPost + X?
        //               b) no name, and lasts shorter          <----- what's the limit? startedAtPost + X?
        //FIXME: is there a block limit for BitGroup main line? if so, maybe publish it

        if ( bgStartedAtPost ) {
          let thisIsSub = true
          if ( bgStartedAtPost === '0' || bgStartedAtPost === 0) {
            thisIsSub = false;
          }

          const bitGroupCmdAscii = hexByteToAscii( bitGroupCmd );
          let userNumClause = null;
          let postNumClause = null;
          let commandClause = null;
          let addedUserClause = null;
          if ( unlockedContract === 'bitgroup' ) {
            // blank-out userNum, postNum, command for admin
            userNumClause = <div>Group User #: {bitGroupUserNum}
                            </div>;
            postNumClause = <div>
                              Post #: {postNum}
                            </div>;
            //FIXME: translate command char to english
            commandClause = <div>
                              BitGroup Command: {bitGroupCmdAscii}
                            </div>;
            if ( bgNewUserName.length > 0 ) {
              addedUserClause = <div>
                                 Added new user: {bgNewUserName}
                                </div>;
            }

            //FIXME: for admin, maybe adjust PostNum to 'Following post num'?
          } else {
            // unlocked an adminGroup

            //FIXME: anything useful to mention? BACK FROM admin?
          }

          let bitGroupNameAscii = hexStringToAscii( bgGroupName );
          //NOTE: bitgroup doesn't require a name on the main line. Max name len is 63, though.
          if ( bitGroupNameAscii.length === 0 ) {
            bitGroupNameAscii = "<un-named)>"
          }
          let mainline = null;
          if ( !thisIsSub ) {
            mainline = <>
                        BitGroup: '{bitGroupNameAscii}'
                        </>
          } else {
            mainline =  <div>
                          SubGroup: '{bitGroupNameAscii}' &nbsp; Started at main post: #{bgStartedAtPost}
                        </div>
          }

          if ( bitgroupSpawnedGroup && bitgroupSpawnedGroup.length > 0 ) {
            let subGroupAscii = '<un-named>'
            if ( bitgroupSpawnedGroup.length === 2 ) {
              console.log(" ");
              console.log("    --> SPAWNING RIGHT NOW - unnamed");
              console.log(" ");
            } else {
              console.log(" ");
              console.log("    --> SPAWNING RIGHT NOW - NAMED");
              console.log(" ");
              subGroupAscii = hexStringToAscii( bitgroupSpawnedGroup );
              console.log("        name is " + subGroupAscii)
            }
            //FIXME: better/more-intuitive presentation

            bgSpawned =
                        <div>
                          Spawning SUB Group: {subGroupAscii}
                          <div> &nbsp; &nbsp; (Started at this main post: #{postNum}) </div>
                        </div>;
          } else {
            console.log(" ")
            console.log("    --> NOT spawning right now")
            console.log(" ")
          } // not spawning right now

          bgMain =
            <div>
              {mainline}
              {bgSpawned}
              <div>
              Number of users: {bgNumUsers}
              </div>
              {postNumClause}
              <div>
              Sats per post: {bgSatsPerPost}
              </div>
              {userNumClause}
              {bgOtherUser}
              {commandClause}
              {addedUserClause}
            </div>
        }
        if ( bitgroupPost && bitgroupPost.length > 0 ) {
          const bitgroupPostAscii = hexStringToAscii( bitgroupPost );
          console.log("post text: " + bitgroupPostAscii)
          bgPost =
            <div>
              Group Post:
              <div className="scroll">
                {bitgroupPostAscii}
              </div>
            </div>
        }

        if ( bgNumProfilesAdded ) {
          bgNumAdded =
            <div>
              Added BitGroup users: {bgNumProfilesAdded}
              <div>profiles: {bgAddedProfiles}</div>
            </div>
        }
      } // decoded tx

      return <>
              {cont}
              {tx}
              {pc}
              {bgMain}
              {bgPost}
              {bgTip}
              {bgNumAdded}
             </>;
    } // ContentPresenter render()
}

export default ContentPresenter;