
import {
	buildHardcodedBuilderPrepStateData,
	buildHardcodedAppendStateData,
	buildHardcodedEbfraStateData,
	buildHardcodedContinueStateData,
	buildHardcodedUpdateStateData,
	buildHardcodedTransientStateData,
	buildHardcodedBitGroupStateData,
	buildHardcodedAdministerGroupStateData,
	buildHardcodedAskBidStateData,
	buildHardcodedDialogStateData,

	buildAndSendBuilderPrepLockingTxWithThisInitialState,
	buildAndSendAppendLockingTxWithThisInitialState,
	buildAndSendEbfraLockingTxWithThisInitialState,
	buildAndSendContinueLockingTxWithThisInitialState,
	buildAndSendUpdateLockingTxWithThisInitialState,
	buildAndSendTransientLockingTxWithThisInitialState,
	buildAndSendBitGroupLockingTxWithThisInitialState,
	buildAndSendAdministerGroupLockingTxWithThisInitialState,
	buildAndSendAskBidLockingTxWithThisInitialState,
	buildAndSendDialogLockingTxWithThisInitialState,

	libBuildOnBuilderPrepOutput,
	libBuildOnAppendOutput,
	libClaimAndPublish,
		libApplyBuilderFeeReduction,
		libAnnounceBuilderFeeReductionAuth,
	libBuildOnAskBid,
	libBuildOnUpdate,
	libBuildOnTransient,
	libBuildOnDialog,
	libBuildOnBitGroup,
	libBuildOnAdminGroup,
	validateRabinPrivKeys,

	decomposeRawTx,
	parseOutputs,
	parseInputs,
	getUsefulInputParams,
	decodeScriptsToState,

	//convertFromBase64,

	//checkForExpectedUTXO,
	getScriptHashMinedBlockHeight,

	findTheNextTx,
	findThePrevTx,
	doesDbHaveTxoSpend
} from './shizzleBuilder';

import {
	int2Asm,
    hexByteToAscii,
    hexStringToAscii,
	swapEndian,

	printError,
} from './commonFuncs';

/** Only needed for bsv. As we share more between console and web, that may change. */
import {
	bsv,
} from 'scryptlib' ;

import { //int2Asm,
	getRawInfo,
		//getDetailedTx,
	getCurrBlockInfo,
	getPriceInfo,

	getBulkUnspentScripts,

	getSpentScript,
} from "./providers";

import { generatePrivKey,
	//privKeyToPubKey, privKeyToPubKey2,
	//sign, sign2,
	//verify,
	//simpleHash
    } from "rabinsig";


import {
	justOpenShizzleDB,
	openShizzleDB,
	//emptyDB,
	//closeShizzleDB,

	getIP4Mapping,
	maybeUpdateIP4Mapping,

	registerSubscription,
	unRegisterSubscription,
	findAllSubscriptions,
	findASubscription,
	registerDomainOwnership,
	markDomainOutdated,
	unRegisterDomain,
	findDomain,
	getAllDomains,
	clearAllDomains,

	addBuiltDomain,
	findBuiltDomain,
	getAllMyBuiltDomains,
	clearBuiltDomains,

	createDialog,
	clearAllDialogs,
	findDialog,
	findDialogById,
	findDialogByStartingTxId,
	modADialogFurthest,
	findAllDialogs,
	addDialogTxo,
	addDialogTxos,
	findDialogTxo,

	addInternalTx,
	addTxo,
	addLabeledContent,
	addDanglingPath,
	addUTXO,
	addSpentTxo,
	addRawTx,
	removeRawTx,
	addExternalTx,
	addDecodedTx,

	recordQueryForUnconfirmedTxoSpend,
	getUnconfirmedTxoSpendQueryTime,
	removeUnconfirmedTxoSpend,
	removeDanglingPath,
	removeFadedTx,

	queryAll,
	queryContentLabels,
	getDanglingPaths,
	getAllDanglingPaths,

	addSetting,
	findSetting,
	getAllSettings,
	removeSetting,

	addVideo,
	getVideoRecord,
	removeVideo,
	getAllVideoTxIds,

	addGuestPostEntry,
	findGuestPostEntry,
	findGuestPostEntryByTxId,
	editGuestPostEntry,
	editGuestPostHideFlag,
	editGuestPostReadFlag,
	editGuestPostValidFlag,
	editGuestPostCouldBuildDialogFlag,
	editGuestPostLastCheckedTime,
	removeGuestPostEntry,

	queryP2PKHs,
	addEncryptedP2PKHKey,
	clearEncryptedP2PKHKeys,
	findAllEncryptedP2pkhKeys,
	findEncryptedKeyFromAddress,

	countAvailableRabins,
	countAvailableP2PKHs,
	queryRabins,
	queryOfficialWallet,
	addEncryptedWalletKey,
	deleteEncryptedWalletKeys,
	clearEncryptedKeys,
	addEncryptedKeys,
	findAndAllocateAvailableRabin,
	markEncryptedKeyRabinAllocated,
	findEncryptedKeysFromPKH,
	findAndAllocateAvailableAddress,
	markEncryptedKeyAddressAllocated,
	findEncryptedKeysFromAddress,

	findTxoFromTxidAndIndex,
	findNextTxFromSpentTxo,
	findPrevFromCurrent,

	getRawTx,
	getTxidFromAddress,
	getNextAddress,
	findNextDialog,

	getExternalTx,
	removeUtxoByScriptHash,
	getDecodedTx,
	findContentFromLimbAndLabel,

	getLatestUTXO,
	getLatestContinue2,
	getLatestContinue,
		//updateLatestClosestToLimbCall,
		//updateLatestGetDanglingPathsCall,
		updateLatestGetLatestContinueCall,
		//getLatestClosestToLimbCall,
		//getLatestGetDanglingPathsCall,
		//getLatestGetLatestContinueCall,
	findClosestToLimb,
	getLatestAuction,

	//removeDecodedTx,
	//showDBTxs,
	//showDBTxos,
	//showDBSpentTxos
} from "./shizzleIDB"

import { AES } from "./encdec"

import { FOURTH_OF_YEAR_IN_BLOCKS, PAYLOAD_ESCAPE_FLAG, THIRD_OF_YEAR_IN_BLOCKS, TOO_MANY_UTXOS } from './harnessConstants.js'

//WARNING: must match shizzleBuilder's value
const SMALLEST_OUTPUT = 1

function shmalert(m) {
    //alert(a)
	console.error("BS ALERT: " + m)
}

export async function testGetBulkUnspent() {

	const scripts = [	"329bbfbfdc346be48e26e47f6b8e82f6ab9506b092eca001d12c20f97d352821",
						"a48ba3b3b80a63b40d1be78e4034887012092e3d945eef688fbb8e0f070e7ad1",
						"6d830a08ed7c3c4eb436552db257d3824c162affb6a113693ad078c86486b8ef",
						"3882535f137e961e98345c041037a859fe0b2aaa26b48ca896a38faebc0745bf",
						"1d6c7c215ce8d18902113f69c46475782bd34f8f1149b9ee2cb8712f0b354ac5",
					//This one was spent
						"25c777d98bdd4b0fb6f4e05656451845f9a979245a6eaa58464620a3e9055b96"
					]
	try {
		const results = await getBulkUnspentScripts(scripts)

		console.warn("testGetBulkUnspent(): results: ", results)

		for ( let i = 0; i < results.length; i++ ) {
			console.warn("result " + i + " for scripthash " + results[i].script + ":")
			console.warn("    error: " + results[i].error)
			console.warn("    unspent: ", results[i].unspent)
			if ( results[i].unspent.length === 0 ) {
				console.warn("    THAT WAS SPENT")
			}
		}
	} catch (error) {
		printError( error )
	}
}

export function generateRabinPrivateKeyPlus() {
	const keys = generatePrivKey();
	const rabinPubKeyBigInt = keys.p * keys.q;

	const rabinPubKeyLE = int2Asm(rabinPubKeyBigInt)       // used for executing contract - when updating content
	const rabinPubKeyLEBuffer = Buffer.from(rabinPubKeyLE, 'hex');
	const rabinPKH = bsv.crypto.Hash.sha256ripemd160(rabinPubKeyLEBuffer).toString('hex')

	return {
		p: keys.p,
		q: keys.q,
		rabinPubkeyBI: rabinPubKeyBigInt,
		rabinPubkeyLE: rabinPubKeyLE,
		rabinPKH: rabinPKH
	}
}

export async function getCurrentBlockInfo() {
	return await getCurrBlockInfo()
}

export async function getCurrentPriceInfo() {
	return await getPriceInfo()
}

export async function findLatestUTXO(db, limb, owner) {

	const result = await getLatestUTXO(db, limb);
	if ( result !== null  ) {
		console.warn("findLatestUTXO(): the owner count was actually ", result.owner)

		// this keeps track of calls
		//updateLatestGetLatestContinueCall(db, limb, owner)

		let ownerChanged = false
		if ( owner !== result.owner ) {
			ownerChanged = true
			console.warn("findLatestContinue(): for limb/domain '" + limb + "', old ownerCount: " + owner + "  new ownerCount: " + result.owner)
		}
		return	{
					result: result,
					ownerChanged: ownerChanged
				}
	} else {
		return null
	}
}

export async function findLatestContinue(db, limb, owner) {

	const result = await getLatestContinue(db, limb);
	if ( result !== null  ) {
		console.warn("findLatestContinue(): the owner count was actually ", result.owner)

		updateLatestGetLatestContinueCall(db, limb, owner)

		let ownerChanged = false
		if ( owner !== result.owner ) {
			ownerChanged = true
			console.warn("findLatestContinue(): for limb/domain '" + limb + "', old ownerCount: " + owner + "  new ownerCount: " + result.owner)
		}
		return	{
					result: result,
					ownerChanged: ownerChanged
				}
	} else {
		return null
	}
}

export async function findLatestContinue2(db, limb, owner) {
	if ( owner === 0 ) {
		console.warn("findLatestContinue2(): not sure what the current owner is")
	}

	const result = await getLatestContinue2(db, limb);
	if ( result !== null ) {
		console.warn("findLatestContinue2(): the owner count was actually ", result.owner)

		updateLatestGetLatestContinueCall(db, limb, result.owner)

		let ownerChanged = false
		if ( owner !== result.owner ) {
			ownerChanged = true
		}
		return	{
					result: result,
					ownerChanged: ownerChanged
				}
	} else {
		return null
	}
}

/**
 * A DB-only series of queries to the dangles table
 */
export async function getClosestToLimb(db, limb) {
	return await findClosestToLimb(db, limb);
}

export async function findLatestAuction(db, limb) {

	let auction = await getLatestAuction(db, limb);

	if ( auction !== null ) {
		const owner = auction.owner
		let lastCheck = auction.lastCheck

		let keepGoing
		let address = auction.address
		let txid = auction.tx
		// walk from tx to tx (always output 0) until we find it UNSPENT
		do {
			keepGoing = false

			//NOTE: copied from checkDanglingPaths()

			console.log("findLatestAuction(): Looking at auction: address ", address + " (tx " + txid + ")")

			let decodedTx = await getDecodedTx(db, txid);
			if ( decodedTx !== null ) {

				// We have the decoded tx, but let's check the spend status of the outputs

				console.log("findLatestAuction(): ALREADY HAVE DECODED TX " + txid + ": ", decodedTx)
				console.log("findLatestAuction(): BUT, will check its outputs. Maybe there's more now.")
				// Alternative form of qFDTx(), when we've already decoded the tx, but
				// still need to examine the spend status of each output
				//NOTE: this might call removeDanglingPath()
				decodedTx = await checkDecodedTxOutputs(db, txid, decodedTx);
			} else {
				console.error("findLatestAuction(): hmm. we don't have a decoded form of " + txid)
				console.log("findLatestAuction(): so, will queryFetchDecodeTx() it now...")

				//NOTE: this might call addDanglingPath()
				decodedTx = await queryFetchDecodeTx(txid, db, false)

				// filler (sort of) for auction, returned further below
				lastCheck = Math.floor(Date.now() / 1000)
			}

			if ( decodedTx !== null ) {
				if ( decodedTx.foundFollowable ) {
					// need to check on output 0 of THIS tx
					keepGoing = true  // the output was spent (followable)
					const nextTxid = await findNextTx(db, decodedTx.outputStates[0], txid, 0)
					if ( nextTxid === null ) {
						throw new Error("findLatestAuction(): failed to find next, even though .foundFollowable for txid " + txid)
					}
					console.warn("findLatestAuction(): updating txid from " + txid + " to " + nextTxid)
					txid = nextTxid
				} else {
					console.warn("findLatestAuction(): no followable. returning now")
				}

				address = decodedTx.outputStates[0].address
			} else {
				console.warn("findLatestAuction(): no decodedTx. returning now")
			}

			auction = {
						tx: txid,               // most recent
						address: address,       // most recent
						lastCheck: lastCheck,   //NOTE: likely bogus
						limb: limb,             // constant
						owner: owner            // constant
			}
		} while ( keepGoing )

		console.warn("findLatestAuctino(): returning latest auction: ", auction)
	}

	return auction
}

export async function justOpenDB() {
	return await justOpenShizzleDB();
}

export async function openDB() {
	return await openShizzleDB();
}

export async function getTxidFromAddr(theDB, address) {
	return await getTxidFromAddress(theDB, address)
}

export async function getTheNextAddress(theDB, limb, address, owner, rvs) {
	return await getNextAddress(theDB, limb, address, owner, rvs)
}

export async function queryTxs(theDB) {
	console.log("queryTxs(): ");
	await queryAll(theDB);
}

export async function queryLabels(theDB) {
	console.log("queryLabels(): ");
	await queryContentLabels(theDB);
}

export async function addARawTx(theDB, txId, rawTx) {
	await addRawTx(theDB, txId, rawTx)
}
export async function deleteRawTx(theDB, txId) {
	await removeRawTx(theDB, txId)
}

export async function registerASubscription(theDB, name, ownerCount) {
	await registerSubscription(theDB, name, ownerCount)
}

export async function unRegisterASubscription(theDB, name) {
	await unRegisterSubscription(theDB, name)
}

export async function getAllSubscriptions(theDB) {
	return await findAllSubscriptions(theDB)
}

export async function getASubscription(theDB, name, ownerCount) {
	return await findASubscription(theDB, name, ownerCount)
}

// new field (approxBlockHeight), so, give a default value for now
// new field (outdated) initialized to true
export async function registerDomainOfMine(theDB, name, ownerCount,
											approxBlockHeightWhenRegistered /*= 1517450*/,
											outdated = false,
											forAFriend = false,
											fromFriend = null) {   // null, or name

	return await registerDomainOwnership(theDB, name, ownerCount,
										approxBlockHeightWhenRegistered,
										outdated,
										forAFriend,
										fromFriend)
}

// if found, flips the .outdated field to false
export async function outdateDomainOfMine(theDB, name, ownerCount) {
	return await markDomainOutdated(theDB, name, ownerCount)
}

export async function unRegisterDomainOfMine(theDB, name) {
	await unRegisterDomain(theDB, name)
}

export async function findADomainOfMine(theDB, name, ownerCount) {
	return await findDomain(theDB, name, ownerCount)
}

export async function findAllDomainsOfMine(theDB) {
	return await getAllDomains(theDB)
}
export async function deleteAllDomainsOfMine(theDB) {
	return await clearAllDomains(theDB)
}
//////////
export async function registerBuiltDomain(theDB, name, payoutPKH, rabinPKH, txid) {
	return await addBuiltDomain(theDB, name, payoutPKH, rabinPKH, txid)
}
export async function getBuiltDomain(theDB, name) {
	return await findBuiltDomain(theDB, name)
}
export async function findAllMyBuiltDomains(theDB) {
	return await getAllMyBuiltDomains(theDB)
}
export async function deleteBuiltDomains(theDB) {
	return await clearBuiltDomains(theDB)
}


export async function createADialog(theDB, ownerName, ownerCount, visitorName, visitorOwnershipCount, startingTxId,
									furthestTxId,
									furthestScriptHash,
									ownerPubKey = null,
									visitorPubKey = null) {
	console.log("createADialog(): ownerName " + ownerName + ", ownerCount: " + ownerCount
				+ ",  visitorName " + visitorName + ", visitorOwnershipCount: " + visitorOwnershipCount
				+ ", startingTxId: " + startingTxId
				+ ", furthestTxId: " + furthestTxId);
	return await createDialog(theDB, ownerName, ownerCount, visitorName, visitorOwnershipCount, startingTxId,
									furthestTxId,
									furthestScriptHash,
									ownerPubKey, visitorPubKey);
}

export async function deleteAllDialogs(theDB) {
	return await clearAllDialogs(theDB)
}

export async function findADialogByStartingTxId(theDB, startingTxId) {
	console.log("findADialogByStartingTxId(): startingTxId: " + startingTxId)
	return await findDialogByStartingTxId(theDB, startingTxId);
}

export async function findADialog(theDB, ownerOrVisitorName, ownerOrVisitorOwnershipCount) {
	console.log("findADialog(): ownerOrVisitorName " + ownerOrVisitorName
				+ ", ownerOrVisitorOwnershipCount " + ownerOrVisitorOwnershipCount);
	return await findDialog(theDB, ownerOrVisitorName, ownerOrVisitorOwnershipCount);
}
export async function findADialogById(theDB, id) {
	console.log("findADialogById(): dialogId " + id);
	return await findDialogById(theDB, id);
}

export async function modDialogFurthest(theDB, id, newFurthestPostNum, newFurthestTxId, newFurthestScriptHash) {
	console.log("modDialogFurthest(): dialogId " + id);
	return await modADialogFurthest(theDB, id, newFurthestPostNum, newFurthestTxId, newFurthestScriptHash);
}


export async function findAllTheDialogs(theDB, ownerOrVisitorName, ownerOrVisitorOwnershipCount) {
	console.log("findAllTheDialogs(): ownerOrVisitorName " + ownerOrVisitorName
				+ ", ownerOrVisitorOwnershipCount " + ownerOrVisitorOwnershipCount);
	return await findAllDialogs(theDB, ownerOrVisitorName, ownerOrVisitorOwnershipCount);
}

export async function addADialogTxo(theDB, dialogId, postNum, txId) {
	console.log("addADialogTxo(): dialogId " + dialogId
			+ ", postNum: " + postNum
			+ ", txId: " + txId);
	await addDialogTxo(theDB, dialogId, postNum, txId);
}

export async function getDialogTxo(theDB, dialogId) {
	console.log("getDialogTxo(): dialogId " + dialogId);
	return await findDialogTxo(theDB, dialogId);
}

export async function getNextDialog(theDB, dialogId, postNum, rvs) {
	return await findNextDialog(theDB, dialogId, postNum, rvs)
}


/**
 * Dangling paths are unspent contract outputs. We'd like to limit their number.
 */
export async function checkDanglingPaths(theDB, limb = null) {
	console.log("checkDanglingPaths():");

	let dangles
	if ( limb !== null ) {
		dangles = await getDanglingPaths(theDB, limb);
		console.warn("Will randomly check one of " + dangles.length + " dangling paths for limb " + limb + ": ", dangles)

		// identify the BitGroup subset of dangles
		let bgDanglesCount = 0
		for ( let p in dangles ) {
			const parts = dangles[p].address.split('/')
			if ( parts.length > 7 && parts[7] === 'G' ) {
				//console.log("        bitgroup parts: ", parts)
				bgDanglesCount++
			}
		}

		// BitGroups have necessary dangles (subgroups), so, we exclude those from the count when enforcing limits.
		// It's Transient and Update dangles we worry about more (there could be MANY more of them).
		const nonBGCount = dangles.length - bgDanglesCount
		if ( nonBGCount > TOO_MANY_UTXOS ) {
			//FIXME/NOTE: until we enforce/allow a SINGLE root, we might be aggregating dangling
			//            paths from multiple trees
			console.warn("Asset " + limb + " has " + nonBGCount + " non-BitGroup dangles.")
			alert("Note that asset '" + limb + "' has more non-BitGroup txs with unspent (dangling) shizzle outputs than we recommend (<7).\n"
				+ "\nThere are " + nonBGCount + ". Please terminate some.")
			//FIXME: add some link, or modal, to help explain what that means
		}

		//FIXME: maybe we should have some different feature to monitor BitGroup dangles
		//FIXME: increase this max
		if ( bgDanglesCount >  4 ) {
			alert("NOTE that there are " + bgDanglesCount + " '" + limb + "' BitGroup transactions with unspent (dangling) shizzle outputs.\n"
				+ "\nThis isn't necessarily wrong or bad, but it impacts performance - the more unspent outputs we need to monitor.")
		}
	} else {
		dangles = await getAllDanglingPaths(theDB);
		console.warn("Will randomly check one of the " + dangles.length + " (global) dangling paths: ", dangles)
	}

	// Choose a random dangle to check on
	const d = Math.floor( Math.random() * dangles.length )

	//FIXME: maybe:
	//    - use a lock to prevent others from writing to the db concurrently
	//    - change the color of something while it's running? Or pop-up a dialog?
	const address = dangles[d].address
	const txid = dangles[d].tx
	console.log("checkDanglingPaths(): Looking at random dangle [" + d + "]: address ", address + " (tx " + txid + ")")

	const decodedTx = await getDecodedTx(theDB, txid);
	if ( decodedTx !== null ) {

		// We have the decoded tx, but let's check the spend status of the outputs

		console.log("checkDanglingPaths(): ALREADY HAVE DECODED TX " + txid + ": ", decodedTx)
		// Alternative form of qFDTx(), when we've already decoded the tx, but
		// still need to examine the spend status of each output
		await checkDecodedTxOutputs(theDB, txid, decodedTx);
	} else {
		console.error("checkDanglingPaths(): hmm. we don't have a decoded form of " + txid + ", SO, let's fetch it from provider")

		// We now overcome that issue
		await queryFetchDecodeTx(txid, theDB)
	}
}


export async function saveSetting(db, name, value) {
	return addSetting(db, name, value);
}

/**
 * Returns NULL if no such setting yet
 */
export async function readSetting(db, name) {
	try {
		console.log("readSetting()...")
		return findSetting(db, name);
	} catch (error) {
		console.error("Find setting '" + name + "' failed. Will return null.")
		alert("Does this happen?")
		return null
	}
}
export async function findAllSettings(db) {
	return getAllSettings(db)
}

export async function deleteSetting(db, name) {
	return removeSetting(db, name)
}

export async function saveVideoInfo(db, txId, description) {
	return addVideo(db, txId, description)
}
export async function readVideoInfo(db, txId) {
	return getVideoRecord(db, txId)
}
export async function deleteVideoInfo(db, txId) {
	return removeVideo(db, txId)
}
export async function getAllVideoInfos(db) {
	return getAllVideoTxIds(db)
}


/**
 * Track Guest Posts - for communicating, for tips, and for possible Dialog spawning
 * Defaults to    hide: false
 * Two other fields:
 *     markedAsRead	     initialized to FALSE
 *     lastCheckedTime   initialized to current time
 */
export async function addGuestPostRef(db, txId,
										ourDomainName, otherDomain,
										/*hide,*/
										weBuiltIt,
										ranValidation, isValid,
										couldBuildDialog) {
	return addGuestPostEntry(db, txId,
							ourDomainName, otherDomain,
							/*hide,*/
							weBuiltIt,
							ranValidation, isValid,
							couldBuildDialog);
}

export async function getGuestPostRef(db, ourDomainName, showHidden) {
	return findGuestPostEntry(db, ourDomainName, showHidden);
}
export async function getGuestPostRefByTxId(db, txId) {
	return findGuestPostEntryByTxId(db, txId);
}

// Generic mod - could change any, all, or none of the fields (hide, isValid, couldBuildDialog)
export async function modifyGuestPostRef(db, txId, hide, isValid, couldBuildDialog, markAsRead, updateCheckTime) {
	return editGuestPostEntry(db, txId, hide, isValid, couldBuildDialog, markAsRead, updateCheckTime)
}
export async function modifyGuestPostHideFlag(db, txId, hide) {
	return editGuestPostHideFlag(db, txId, hide)
}
export async function modifyGuestPostMarkedAsReadFlag(db, txId, alreadyReadThis) {
	return editGuestPostReadFlag(db, txId, alreadyReadThis)
}
export async function modifyGuestPostIsValidFlag(db, txId, isValid, updateCheckTime) {
	return editGuestPostValidFlag(db, txId, isValid, updateCheckTime)
}
// also automatically updates the lastCheckedTime
export async function modifyGuestPostCouldBuildDialogFlag(db, txId, couldBuildDialog) {
	return editGuestPostCouldBuildDialogFlag(db, txId, couldBuildDialog)
}
// needed? Or just use function above?
export async function modifyGuestPostLastCheckedTime(db, txId, lastCheckedTime) {
	return editGuestPostLastCheckedTime(db, txId, lastCheckedTime)
}

// If we want to ignore/hide, this could be one way to do it.
// Would we need/want to index by txId?
export async function deleteGuestPostRef(db, txId) {
	return removeGuestPostEntry(db, txId)
}


//////// if officialWallet, network = 't'estnet, or 'm'ainnet
// NOTE: now, if targeting p2pkhTab, will also set new field {alloc: 0}
export async function saveEncryptedP2PKHKey(db, address, encryptedBlob,
											officialWallet = false,
											network = 't',
											alloc = 0) {
	return addEncryptedP2PKHKey(db, address, encryptedBlob, officialWallet, network, alloc);
}
export async function deleteEncryptedP2PKHKeys(db, officialWallet = false, network = 't') {
	return clearEncryptedP2PKHKeys(db, officialWallet, network);
}

export async function getAllEncryptedP2PkhKeys(db, officialWallet = false) {
	return findAllEncryptedP2pkhKeys(db, officialWallet);
}

// not used
export async function getEncryptedKeyFromAddress(db, address) {
	return findEncryptedKeyFromAddress(db, address);
}


export async function getP2PKHsJson(db, official = false) {
	return await queryP2PKHs(db, official);
}

export async function saveEncryptedWallet(db, address, encryptedBlob, network = 't') {
	return addEncryptedWalletKey(db, address, encryptedBlob, network);
}
export async function deleteEncryptedWallet(db, network = 't') {
	return deleteEncryptedWalletKeys(db, network);
}

// NOTE: now, will also set new field {alloc: 0}
export async function saveEncryptedKeys(db, pkh, encryptedBlob, tableVariant=null, alloc = 0) {
	return addEncryptedKeys(db, pkh, encryptedBlob, tableVariant, alloc);
}


export async function allocateAvailableRabin(db, numToReturn = 1) {
	return findAndAllocateAvailableRabin(db, numToReturn)
}
//FIXME: maybe no one needs this
export async function setEncryptedKeysRabinAllocated(db, pkh, tableVariant=null) {
	return markEncryptedKeyRabinAllocated(db, pkh, tableVariant);
}
export async function getEncryptedKeysFromPKH(db, pkh, tableVariant=null) {
	return findEncryptedKeysFromPKH(db, pkh, tableVariant);
}


export async function allocateAvailableAddress(db, numToReturn = 1) {
	return findAndAllocateAvailableAddress(db, numToReturn)
}
//FIXME: maybe no one needs this
export async function setEncryptedKeysAddressAllocated(db, address) {
	return markEncryptedKeyAddressAllocated(db, address);
}
export async function getEncryptedKeysFromAddress(db, address) {
	//FIXME: no table variant?
	return findEncryptedKeysFromAddress(db, address);
}

export async function deleteEncryptedKeys(db, tableVariant = null) {
	return clearEncryptedKeys(db, tableVariant);
}

export async function getAvailableRabinsCount(db, tableVariant=null) {
	return await countAvailableRabins(db, tableVariant);
}
export async function getAvailableP2PKHsCount(db) {
	return await countAvailableP2PKHs(db);
}

export async function getRabinsJson(db, tableVariant=null) {
	return await queryRabins(db, tableVariant);
}

export async function getOfficialWalletJson(db) {
	return await queryOfficialWallet(db);
}

//////////
//FIXME: anyone calling these should catch errors
//       IF browser-based, could warn to use https://
export async function encryptData(message, password, passwordBits=null, iterations=null) {
	return await AES().encrypt(message, password, passwordBits, iterations);
}
export async function decryptData(encrypted, password) {
	return await AES().decrypt(encrypted, password);
}
//////////

export async function buildShizzleLockingTx(theDB, type) {
	console.log("buildShizzleLockingTx(): building locking tx, of type: " + type)
	let txid;
	if ( type === 'BuilderPrep' ) {
		txid = await buildBuilderPrepLockingTx( theDB );
	} else if ( type === 'Append' ) {
		txid = await buildAppendLockingTx( theDB );
	} else if ( type === 'Ebfra' ) {                    //FIXME: Ebfra Speaker
		txid = await buildEbfraLockingTx( theDB );
	} else if ( type === 'Continue' ) {
		txid = await buildContinueLockingTx( theDB );
	} else if ( type === 'Update' ) {
		txid = await buildUpdateLockingTx( theDB );
	} else if ( type === 'Transient' ) {
		txid = await buildTransientLockingTx( theDB );
	} else if ( type === 'BitGroup' ) {
		txid = await buildBitGroupLockingTx( theDB );
	} else if ( type === 'Administer' ) {
		txid = await buildAdministerGroupLockingTx( theDB );
	} else if ( type === 'Sales' ) {
		txid = await buildAskBidLockingTx( theDB );
	} else if ( type === 'Dialog' ) {
		txid = await buildDialogLockingTx( theDB );
	} else {
		console.error("Unrecognized type: " + type);
		throw new Error('11002: unrecognized contract type: ' + type);
	}

	return txid;
}


async function buildBuilderPrepLockingTx(theDB) {

    console.log("\nbuildBuilderPrepLockingTx(): FIXME: solicit initial path <---\n")
    const initStateData = buildHardcodedBuilderPrepStateData()
    const contractAmount = SMALLEST_OUTPUT

    var txid = await buildAndSendBuilderPrepLockingTxWithThisInitialState(initStateData, contractAmount)

    console.log("\n\nNOT INSERTing NEW BuilderPrep (FUNDING) tx ("
                +	txid + ") into the DB: (not txTab,) txoTab, but NOT spentTxoTab.\n\n")
    //await addInternalTx(theDB, txid, txid)
//    await addTxo(theDB, txid, 0, 'X')
    // we don't bother recording which TXO's built this. This is a root Tx
    console.log("Done NOT inserting ROOT (funding) BuilderPrep tx into DB.")

    return txid
}

async function buildAppendLockingTx(theDB) {

    console.log("\nbuildAppendLockingTx(): FIXME: solicit initial path <---\n")
    const initStateData = buildHardcodedAppendStateData()
    const contractAmount = SMALLEST_OUTPUT

    var txid = await buildAndSendAppendLockingTxWithThisInitialState(initStateData, contractAmount)

    console.log("\n\nNOT INSERTing NEW Append (FUNDING) tx ("
                +	txid + ") into the DB: (not txTab,) txoTab, but NOT spentTxoTab.\n\n")
    //await addInternalTx(theDB, txid, txid)
//    await addTxo(theDB, txid, 0, 'X')
    // we don't bother recording which TXO's built this. This is a root Tx
    console.log("Done NOT inserting ROOT (funding) APPEND tx into DB.")

    return txid
}

async function buildEbfraLockingTx(theDB) {

    console.log("\nbuildEbfraLockingTx(): FIXME: solicit initial path <---\n")
    const initStateData = buildHardcodedEbfraStateData()
    const contractAmount = SMALLEST_OUTPUT
    var txid = await buildAndSendEbfraLockingTxWithThisInitialState(initStateData, contractAmount)

    console.log("\n\nNOT INSERTing NEW Ebfra (FUNDING) tx ("
                +	txid + ") into the DB: (not txTab,) txoTab, but NOT spentTxoTab.\n\n")
    //await addInternalTx(theDB, txid, txid)
//    await addTxo(theDB, txid, 0, 'X')
    // we don't bother recording which TXO's built this. This is a root Tx
    console.log("Done NOT inserting ROOT (funding) EBFRA tx into DB.")

    return txid
}

async function buildContinueLockingTx(theDB) {

	console.log("\nbuildContinueLockingTx(): FIXME: solicit initial path <---\n")
	const initStateData = buildHardcodedContinueStateData()
	const contractAmount = SMALLEST_OUTPUT

	var txid = await buildAndSendContinueLockingTxWithThisInitialState(initStateData, contractAmount)

	console.log("\n\nNOT INSERTing NEW Continue (FUNDING) tx ("
				+	txid + ") into the DB: (not txTab,) txoTab, but NOT spentTxoTab.\n\n")
	//await addInternalTx(theDB, txid, txid)
	//NOTE: this assumes that buildHardcodedContinueStateData() sets mode to 'P'
//	await addTxo(theDB, txid, 0, 'P')  // used to be 'C' - but that didn't match the mode
	// we don't bother recording which TXO's built this. This is a root Tx
	console.log("Done NOT inserting ROOT (funding) CONTINUE tx into DB.")

	return txid
}

async function buildUpdateLockingTx(theDB) {
	console.log("\nbuildUpdateLockingTx(): FIXME: solicit initial path <---\n")
	const initStateData = buildHardcodedUpdateStateData()
	const contractAmount = SMALLEST_OUTPUT

	var txid = await buildAndSendUpdateLockingTxWithThisInitialState(initStateData, contractAmount)

	console.log("\n\nNOT INSERTing NEW Update (FUNDING) tx ("
				+	txid + ") into the DB: (not txTab,) txoTab, but not spentTxoTab.\n\n")
	//await addInternalTx(theDB, txid, txid)
//	await addTxo(theDB, txid, 0, 'U')
	// we don't bother recording which TXO's built this. This is a root Tx
	console.log("Done NOT inserting ROOT (funding) UPDATE tx into DB.")

	return txid
}

async function buildTransientLockingTx(theDB) {

	console.log("\nbuildTransientLockingTx(): FIXME: solicit initial path <---\n")

	console.log("\nThere are 10 kinds of Transient contracts: T0-T9.\n\t- T0-T4 are smaller/cheaper for miner fees, "
			+	"but don't allow you to name your content, nor referencex Tx.\n"
			+	"\t- T5-T9 allow you to name your content, and reference Tx, but have higher miner fees.\n")
	console.log("T4 can spawn T3, which can spawn T2, which can spawn T1. T4 allows the most number of posts/transactions - of these four.\n")
	console.log("T8 can spawn T7, which can spawn T6, which can spawn T5. T8 allows the most number of posts/transactions - of these four.\n")

	//let whichT = "" + getOptionNumber("Which Transient # do you choose? ", 8, 1)
	let whichT = 4 //"" + 1

	const initStateData = buildHardcodedTransientStateData(whichT)
	const contractAmount = SMALLEST_OUTPUT

	var txid = await buildAndSendTransientLockingTxWithThisInitialState(whichT, initStateData, contractAmount)

	console.log("\n\nNOT INSERTing NEW Transient (FUNDING) tx ("
				+	txid + ") into the DB: (not txTab,) txoTab, but not spentTxoTab.\n\n")

	//await addInternalTx(theDB, txid, txid)
//	await addTxo(theDB, txid, 0, whichT) // used to be 'T'
	// we don't bother recording which TXO's built this. This is a root Tx
	console.log("Done NOT inserting ROOT (funding) TRANSIENT tx into DB.")

	return txid
}

async function buildBitGroupLockingTx(theDB) {

	console.log("\nbuildBitGroupLockingTx(): FIXME: solicit initial path, and lots of parameters <---\n")

	const initStateData = buildHardcodedBitGroupStateData()
	const contractAmount = SMALLEST_OUTPUT

	var txid = await buildAndSendBitGroupLockingTxWithThisInitialState(initStateData, contractAmount)

	console.log("\n\nNOT INSERTing NEW BitGroup (FUNDING) tx ("
				+	txid + ") into the DB: (not txTab,) txoTab, but not spentTxoTab.\n\n")
	//await addInternalTx(theDB, txid, txid)
//	await addTxo(theDB, txid, 0, 'G')
	// we don't bother recording which TXO's built this. This is a root Tx
	console.log("Done NOT inserting ROOT (funding) BitGROUP tx into DB.")

	return txid
}

async function buildAdministerGroupLockingTx(theDB) {

	const initStateData = buildHardcodedAdministerGroupStateData()
	const contractAmount = SMALLEST_OUTPUT

	var txid = await buildAndSendAdministerGroupLockingTxWithThisInitialState(initStateData, contractAmount)

	console.log("\n\nNOT INSERTing NEW AdministerGroup (FUNDING) tx ("
				+	txid + ") into the DB: (not txTab,) txoTab, but not spentTxoTab.\n\n")
	//await addInternalTx(theDB, txid, txid)
//	await addTxo(theDB, txid, 0, 'g')
	// we don't bother recording which TXO's built this. This is a root Tx
	console.log("Done NOT inserting ROOT (funding) AdministerGroup tx into DB.")

	return txid
}

async function buildDialogLockingTx(theDB) {

	const initStateData = buildHardcodedDialogStateData()
	const contractAmount = SMALLEST_OUTPUT

	var txid = await buildAndSendDialogLockingTxWithThisInitialState(initStateData, contractAmount)

	console.log("\n\nNOT INSERTing NEW Dialog (FUNDING) tx ("
				+	txid + ") into the DB: (not txTab,) txoTab, but not spentTxoTab.\n\n")
	//await addInternalTx(theDB, txid, txid)
//	await addTxo(theDB, txid, 0, 'g')
	// we don't bother recording which TXO's built this. This is a root Tx
	console.log("Done NOT inserting ROOT (funding) Dialog tx into DB.")

	return txid
}

//buildAskBidLockingTx() simplified from buildShizzleTx's: chooseFixedOrCopiedInitialStateForNegotiations()
async function buildAskBidLockingTx(theDB) {

	var whichChoice = "1"

	var contractAmount
	var initStateData
	switch ( whichChoice ) {
		case '1':
			initStateData = buildHardcodedAskBidStateData()
			contractAmount = SMALLEST_OUTPUT
			break;
		case '2':
			break;
		default:
			console.log("Unrecognized choice: " + whichChoice)
			throw new Error('11000'); //exit()
	}

	//FIXME: finishTheAskNowThatWeHavePrevState(),
	//		 or askBidAsk()? 
	//       grab price from some shared func?
//finishTheAsk

	var txid = await buildAndSendAskBidLockingTxWithThisInitialState(initStateData, contractAmount)

	console.log("\n\nNOT INSERTing NEW AskBid (FUNDING) tx ("
			+	txid + ") into the DB: (not txTab,) txoTab, but not spentTxoTab.\n\n")
	//await addInternalTx(theDB, txid, txid)
//	await addTxo(theDB, txid, 0, 'N')
	// we don't bother recording which TXO's built this. This is a root Tx
	console.log("Done NOT inserting ROOT (funding) ASKBID tx into DB.")

	return txid
}


export async function buildOnBuilderPrep(theOutputState, prevTxid, base0OutIndex,
							 newBuilderState, specifiedPrivateKey) {
	console.log("buildShizzle::buildOnBuilderPrep(): will call lib fn to build");

	const theDb = await openDB();

	//FIXME: maybe we want access to the rawTx too - not just the txid
	//       We'd need to modify all buildShizzleTx.js's calls to this fn too
	const ourNextTx = await libBuildOnBuilderPrepOutput(theOutputState, prevTxid, base0OutIndex,
			newBuilderState, theDb, addRawTx, specifiedPrivateKey);

	if ( ourNextTx ) {

		// add some db entries:
		//       addInternalTx (for txTab)
		//         txo = findTxoFromTxidAndIndex()
		//       addSpentTxo
		//console.log("buildShizzle::buildOnBuilderPrep(): INSERTing NEW append tx ("
		//		+	ourNextTx + ") into the DB: (not txTab,) spentTxoTab.\n\n")
		//await addInternalTx(theDb, ourNextTx, ourNextTx)

		console.log("buildShizzle::buildOnBuilderPrep(): NOW: looking for old TXO which was just spent from tx " + prevTxid
				+ " (outIndex " + base0OutIndex + ")( which was used to create this new Tx " + ourNextTx + " )")
		const txo = await findTxoFromTxidAndIndex(theDb, prevTxid, base0OutIndex)
		console.log("buildShizzle::buildOnBuilderPrep(): Found txotab row: " + JSON.stringify(txo));
		console.log("buildShizzle::buildOnBuilderPrep(): We'll now record that THAT TXO was just now spent to build the new "
					+ "Append tx: " + ourNextTx)

		await addSpentTxo(theDb, txo.txoid, ourNextTx)
		//FIXME: is this too early? should we wait until we SEE/hear the tx on the network? or it confirms?
		await removeUtxoByScriptHash(theDb, theOutputState.scriptHash)

		console.log("buildShizzle::buildOnBuilderPrep(): done adding spent txo");
		if ( !ourNextTx.startsWith('11111111111111111111111111111111') ) {
			const now4Floor = Math.floor(Date.now() / 1000)
			console.log("   SO, recording UNCONFIRMED txo spend of " + txo.txoid + " at time " + now4Floor);
			await recordQueryForUnconfirmedTxoSpend(theDb, txo.txoid, now4Floor)
		} else {
			console.log("buildShizzle::buildOnBuilderPrep(): It's a bogus tx, so, we won't bother recording it as 'unconfirmed'.")
		}

	}

	return ourNextTx;
}

export async function buildOnAppend(theOutputState, prevTxid, base0OutIndex,
							 newBuilderState, preClaimYorN, preClaimRabinPKH,
							 currentBlockHeight, specifiedPrivateKey,

							 optionalAdditionalBalance,

							 optionalSilentBuild = false,
							 optionalUTXOs = null) {
	console.log("buildShizzle::buildOnAppend(): will call lib fn to build");

	const theDb = await openDB();

	//FIXME: maybe we want access to the rawTx too - not just the txid
	//       We'd need to modify all buildShizzleTx.js's calls to this fn too
	const ourNextTx = await libBuildOnAppendOutput(theOutputState, prevTxid, base0OutIndex, null, null,
			newBuilderState, preClaimYorN, preClaimRabinPKH, theDb, addRawTx,
			currentBlockHeight, specifiedPrivateKey,

			optionalAdditionalBalance,

			optionalSilentBuild,
			optionalUTXOs);

	// If we're only building, without broadcasting, return an object with: the constructed
	// transaction, its hash, and its change - without performing any post-broadcast DB operations
	if ( optionalSilentBuild ) {
		console.warn("buildOnAppend(): back from libBuildOnAppendOutput(). results to return: ", ourNextTx)
		//alert("buildOnAppend(): read console for ourNextTx")
		return ourNextTx
	}

	// add some db entries:
	//       addInternalTx (for txTab)
	//         txo = findTxoFromTxidAndIndex()
	//       addSpentTxo
	//console.log("buildShizzle::buildOnAppend(): INSERTing NEW append tx ("
	//		+	ourNextTx + ") into the DB: (not txTab,) spentTxoTab.\n\n")
	//await addInternalTx(theDb, ourNextTx, ourNextTx)

	if ( ourNextTx ) {
		postProcessAppend(theDb, prevTxid, base0OutIndex, ourNextTx, theOutputState.scriptHash)
	}

	return ourNextTx;
}

// extracted from the end of buildOnAppend(), above
export async function postProcessAppend(theDb, prevTxid, base0OutIndex, ourNextTx, scriptHash) {

	console.log("postProcessAppend(): NOW: looking for old TXO which was just spent from tx " + prevTxid
			+ " (outIndex " + base0OutIndex + ")( which was used to create this new Tx " + ourNextTx + " )")
	const txo = await findTxoFromTxidAndIndex(theDb, prevTxid, base0OutIndex)

	if ( txo === null ) {
		console.error("null txo. Did we not wait long enough, somewhere")
		alert("postProcessAppend() findTxoFromTxidAndIndex returned null txo for prevTxid " + prevTxid + "  outIndex "
			+ base0OutIndex + "  which supposedly created new tx: " + ourNextTx)
		throw new Error("null txo in post-processing for append")
	}

	console.log("postProcessAppend(): Found txotab row: " + JSON.stringify(txo));
	console.log("postProcessAppend(): We'll now record that THAT TXO was just now spent to build the new "
				+ "Append tx: " + ourNextTx)

	await addSpentTxo(theDb, txo.txoid, ourNextTx)
	await removeUtxoByScriptHash(theDb, scriptHash)

	console.log("postProcessAppend(): done adding spent txo");
	if ( !ourNextTx.startsWith('11111111111111111111111111111111') ) {
		const now4Floor = Math.floor(Date.now() / 1000)
		console.log("   SO, recording UNCONFIRMED txo spend of " + txo.txoid + " at time " + now4Floor);
		await recordQueryForUnconfirmedTxoSpend(theDb, txo.txoid, now4Floor)
	} else {
		console.log("postProcessAppend(): It's a bogus tx, so, we won't bother recording it as 'unconfirmed'.")
	}
}

export async function buildOnClaimOrPublish(theOutputState, prevTxid, base0OutIndex,
									 blockHeight,
									 param0paycode,
									 param1PKH, param2NormalZone,

									 rabinPkhOfPotentialBuyer,
									 authcode, authcodePadding,
									 sellerRabinPubKeyLE,

									 param3Price, newOwnerP2Pkh,
									 instaBuy,

									 param4Renew, param5PKH,
									 param6PKH, param7PublishContentBool, param8Content, param9ContentLabel,
									 param10PublishTxidBool, param11RefTxid, param12RefTxidLabel,
									 param13SpawnUpdateBool, param14SpawnSaleBool,

									 optionalAdditionalBalance,

									 privKeyP, privKeyQ,
									 specifiedPrivateKey) {
	const theDb = await openDB();

	// non-interactive use of function - static params obtained from UI
	const ourNextTx = await libClaimAndPublish(theOutputState, prevTxid, base0OutIndex,
												blockHeight,
												null, // getPaycodeFromUser,
												null, // getPKHFromUser,
												null, // getYesNoFromUser,
												null, // readline.question,
												null, // getContentFromUser,
												null, // getTxIdFromUser,
												null, // solicitKeysForSigning,
												param0paycode,
												param1PKH, param2NormalZone,

												rabinPkhOfPotentialBuyer,
												authcode, authcodePadding,
												sellerRabinPubKeyLE,

												param3Price, newOwnerP2Pkh,
												instaBuy,

												param4Renew,

												param5PKH,
												param6PKH, param7PublishContentBool, param8Content, param9ContentLabel,
												param10PublishTxidBool, param11RefTxid, param12RefTxidLabel,
												param13SpawnUpdateBool, param14SpawnSaleBool,

												optionalAdditionalBalance,

												privKeyP,
												privKeyQ,
												theDb,
												addRawTx,
												specifiedPrivateKey);


	if ( ourNextTx ) {

		// Add some db entries:
		//       addInternalTx (for txTab)
		//         txo = findTxoFromTxidAndIndex()
		//       addSpentTxo
	//	console.log("buildShizzle::buildOnClaimOrPublish(): INSERTing NEW continue tx ("
	//			+	ourNextTx + ") into the DB: (not txTab,) spentTxoTab.\n\n")
		//await addInternalTx(theDb, ourNextTx, ourNextTx)

		console.log("buildShizzle::buildOnClaimOrPublish(): NOW: looking for old TXO which was just spent from tx " + prevTxid
				+ " (outIndex " + base0OutIndex + ")( which was used to create this new Tx " + ourNextTx + " )")
		const txo = await findTxoFromTxidAndIndex(theDb, prevTxid, base0OutIndex)
		console.log("buildShizzle::buildOnClaimOrPublish(): Found txotab row: " + JSON.stringify(txo));
		console.log("buildShizzle::buildOnClaimOrPublish(): We'll now record that THAT TXO was just now spent to build the new "
					+ "Continue tx: " + ourNextTx)

		//FIXME: if we're not adding ourNextTx via addInternalTx(), why add this (which references it)?
		await addSpentTxo(theDb, txo.txoid, ourNextTx)
		await removeUtxoByScriptHash(theDb, theOutputState.scriptHash)

		console.log("buildShizzle::buildOnClaimOrPublish(): done adding spent txo");
		if ( !ourNextTx.startsWith('11111111111111111111111111111111') ) {
			const now4Floor = Math.floor(Date.now() / 1000)
			console.log("   SO, recording UNCONFIRMED txo spend of " + txo.txoid + " at time " + now4Floor);
			await recordQueryForUnconfirmedTxoSpend(theDb, txo.txoid, now4Floor)
		} else {
			console.log("buildShizzle::buildOnClaimOrPublish(): It's a bogus tx, so, we won't bother recording it as 'unconfirmed'.")
		}

	}

	return ourNextTx;
}



export async function applyBuilderFeeReduction(theOutputState, prevTxid, base0OutIndex,
									 authcode,               // sig
									 authcodePadding,        // padding
									 sellerRabinPubKeyLE,    // pubKey
									 specifiedPrivateKey) {
	const theDb = await openDB();

	// non-interactive use of function - static params obtained from UI
	const ourNextTx = await libApplyBuilderFeeReduction(theOutputState, prevTxid, base0OutIndex,
												authcode,         // sig
												authcodePadding,  // padding
												sellerRabinPubKeyLE, // LE Hex   (pubkey)
												theDb,
												addRawTx,
												specifiedPrivateKey);

	if ( ourNextTx ) {

		// Add some db entries:
		//       addInternalTx (for txTab)
		//         txo = findTxoFromTxidAndIndex()
		//       addSpentTxo
	//	console.log("buildShizzle::applyBuilderFeeReduction(): INSERTing NEW continue tx ("
	//			+	ourNextTx + ") into the DB: (not txTab,) spentTxoTab.\n\n")
		//await addInternalTx(theDb, ourNextTx, ourNextTx)

		console.log("buildShizzle::applyBuilderFeeReduction(): NOW: looking for old TXO which was just spent from tx " + prevTxid
				+ " (outIndex " + base0OutIndex + ")( which was used to create this new Tx " + ourNextTx + " )")
		const txo = await findTxoFromTxidAndIndex(theDb, prevTxid, base0OutIndex)
		console.log("buildShizzle::applyBuilderFeeReduction(): Found txotab row: " + JSON.stringify(txo));
		console.log("buildShizzle::applyBuilderFeeReduction(): We'll now record that THAT TXO was just now spent to build the new "
					+ "Continue tx: " + ourNextTx)

		//FIXME: if we're not adding ourNextTx via addInternalTx(), why add this (which references it)?
		await addSpentTxo(theDb, txo.txoid, ourNextTx)
		await removeUtxoByScriptHash(theDb, theOutputState.scriptHash)

		console.log("buildShizzle::applyBuilderFeeReduction(): done adding spent txo");
		if ( !ourNextTx.startsWith('11111111111111111111111111111111') ) {
			const now4Floor = Math.floor(Date.now() / 1000)
			console.log("   SO, recording UNCONFIRMED txo spend of " + txo.txoid + " at time " + now4Floor);
			await recordQueryForUnconfirmedTxoSpend(theDb, txo.txoid, now4Floor)
		} else {
			console.log("buildShizzle::applyBuilderFeeReduction(): It's a bogus tx, so, we won't bother recording it as 'unconfirmed'.")
		}

	}

	return ourNextTx;
}

export async function announceBuilderFeeReductionAuth(theOutputState, prevTxid, base0OutIndex,
						blockHeightInt,
							theVote,
							privKeyP,
							privKeyQ,
							specifiedPrivateKey) {
	const theDb = await openDB();

	// non-interactive use of function - static params obtained from UI
	const ourNextTx = await libAnnounceBuilderFeeReductionAuth(theOutputState, prevTxid, base0OutIndex,
											blockHeightInt,
												theVote,
												privKeyP,
												privKeyQ,
												theDb,
												addRawTx,
												specifiedPrivateKey);

	if ( ourNextTx ) {

		// Add some db entries:
		//       addInternalTx (for txTab)
		//         txo = findTxoFromTxidAndIndex()
		//       addSpentTxo
	//	console.log("buildShizzle::applyBuilderFeeReduction(): INSERTing NEW continue tx ("
	//			+	ourNextTx + ") into the DB: (not txTab,) spentTxoTab.\n\n")
		//await addInternalTx(theDb, ourNextTx, ourNextTx)

		console.log("buildShizzle::announceBuilderFeeReductionAuth(): NOW: looking for old TXO which was just spent from tx " + prevTxid
				+ " (outIndex " + base0OutIndex + ")( which was used to create this new Tx " + ourNextTx + " )")
		const txo = await findTxoFromTxidAndIndex(theDb, prevTxid, base0OutIndex)
		console.log("buildShizzle::announceBuilderFeeReductionAuth(): Found txotab row: " + JSON.stringify(txo));
		console.log("buildShizzle::announceBuilderFeeReductionAuth(): We'll now record that THAT TXO was just now spent to build the new "
					+ "Continue tx: " + ourNextTx)

		await addSpentTxo(theDb, txo.txoid, ourNextTx)
		await removeUtxoByScriptHash(theDb, theOutputState.scriptHash)

		console.log("buildShizzle::announceBuilderFeeReductionAuth(): done adding spent txo");
		if ( !ourNextTx.startsWith('11111111111111111111111111111111') ) {
			const now4Floor = Math.floor(Date.now() / 1000)
			console.log("   SO, recording UNCONFIRMED txo spend of " + txo.txoid + " at time " + now4Floor);
			await recordQueryForUnconfirmedTxoSpend(theDb, txo.txoid, now4Floor)
		} else {
			console.log("buildShizzle::announceBuilderFeeReductionAuth(): It's a bogus tx, so, we won't bother recording it as 'unconfirmed'.")
		}

	}

	return ourNextTx;
}

export async function buildOnAskBid(	theOutputState, prevTxid, base0OutIndex,

								operationHex,
								newSubMode,
								regardingEntry,
								bidRefundP2PKH,
								newBid,

								rabinPKH,  // must match these, below
								privKeyP,
								privKeyQ,
								specifiedPrivateKey) {

	const theDb = await openDB();

	// non-interactive use of function - static params obtained from UI


	//FIXME: if subMode is 'N' (or W, or G?), look at askBidAsk() in buildShizzleTx?
	//
	//       Look at both askBidAsk()   ( which calls getPriceThenFinishAskNowThatWeHavePrevState() ),
	//       and askBidHandleFirstAsk()  in buildShizzleTx.js


	//FIXME: fill with MORE appropriate params - once the interface is defined
	const ourNextTx = await libBuildOnAskBid(	theOutputState, prevTxid, base0OutIndex,

												operationHex,
												newSubMode,
												regardingEntry,
												bidRefundP2PKH,
												newBid,

												rabinPKH,
												privKeyP,
												privKeyQ,
												theDb,
												addRawTx,
												specifiedPrivateKey);

	if ( ourNextTx ) {

		console.log("buildShizzle::buildOnAskBid(): NOW: looking for old TXO which was just spent from tx " + prevTxid
				+ " (outIndex " + base0OutIndex + ")( which was used to create this new Tx " + ourNextTx + " )")
		const txo = await findTxoFromTxidAndIndex(theDb, prevTxid, base0OutIndex)
		console.log("buildShizzle::buildOnAskBid(): Found txotab row: " + JSON.stringify(txo));
		console.log("buildShizzle::buildOnAskBid(): We'll now record that THAT TXO was just now spent to build the new "
					+ "AskBid tx: " + ourNextTx)

		await addSpentTxo(theDb, txo.txoid, ourNextTx)
		await removeUtxoByScriptHash(theDb, theOutputState.scriptHash)

		console.log("buildShizzle::buildOnAskBid(): done adding spent txo");
		if ( !ourNextTx.startsWith('11111111111111111111111111111111') ) {
			const now4Floor = Math.floor(Date.now() / 1000)
			console.log("   SO, recording UNCONFIRMED txo spend of " + txo.txoid + " at time " + now4Floor);
			await recordQueryForUnconfirmedTxoSpend(theDb, txo.txoid, now4Floor)
		} else {
			console.log("buildShizzle::buildOnAskBid(): It's a bogus tx, so, we won't bother recording it as 'unconfirmed'.")
		}

	}

	return ourNextTx;
}

export async function buildOnAnUpdate(theOutputState, prevTxid, base0OutIndex,
								param1YN, param2YN, param3, param4, param5Tx,
								param6, param7YN, param8PKH, param9YN, param10YN,
								param11SpawnTransient,
								param11Opt, param11bAllowGuestPosts, param11cGuestPostPrice,
								param12groupN, param13userBounty, param14maxPostLen, param15satsPerPost,
								privKeyP,
								privKeyQ,
								specifiedPrivateKey){

	const theDb = await openDB();

	console.log("buildOnAnUpdate: allow guest posts: " + param11bAllowGuestPosts)
	console.log("buildOnAnUpdate: guest post price: " + param11cGuestPostPrice)


	// non-interactive use of function - static params obtained from UI
	const ourNextTx = await libBuildOnUpdate(theOutputState, prevTxid, base0OutIndex,
											null, // getYesNoFromUser,
											null, // getContentFromUser,
											null, // getTxIdFromUser,
											null, // getPKHFromUser,
											null, // getOptionNumber,
											null, // readline.question,
											null, // solicitKeysForSigning,
											param1YN, param2YN, param3, param4, param5Tx,
											param6, param7YN, param8PKH, param9YN, param10YN,
											param11SpawnTransient,
											param11Opt, param11bAllowGuestPosts, param11cGuestPostPrice,
											param12groupN, param13userBounty, param14maxPostLen, param15satsPerPost,
											privKeyP,
											privKeyQ,
											theDb,
											addRawTx,
											specifiedPrivateKey);

	if ( ourNextTx ) {

		// Add some db entries:
		//       addInternalTx (for txTab)
		//         txo = findTxoFromTxidAndIndex()
		//       addSpentTxo
	//	console.log("buildShizzle::buildOnAnUpdate(): INSERTing NEW update tx ("
	//			+	ourNextTx + ") into the DB: (not txTab,) spentTxoTab.\n\n")
		//await addInternalTx(theDb, ourNextTx, ourNextTx)

		console.log("buildShizzle::buildOnAnUpdate(): NOW: looking for old TXO which was just spent from tx " + prevTxid
				+ " (outIndex " + base0OutIndex + ")( which was used to create this new Tx " + ourNextTx + " )")
		const txo = await findTxoFromTxidAndIndex(theDb, prevTxid, base0OutIndex)
		console.log("buildShizzle::buildOnAnUpdate(): Found txotab row: " + JSON.stringify(txo));
		console.log("buildShizzle::buildOnAnUpdate(): We'll now record that THAT TXO was just now spent to build the new "
					+ "Update tx: " + ourNextTx)

		await addSpentTxo(theDb, txo.txoid, ourNextTx)
		await removeUtxoByScriptHash(theDb, theOutputState.scriptHash)

		console.log("buildShizzle::buildOnAnUpdate(): done adding spent txo");
		if ( !ourNextTx.startsWith('11111111111111111111111111111111') ) {
			const now4Floor = Math.floor(Date.now() / 1000)
			console.log("   SO, recording UNCONFIRMED txo spend of " + txo.txoid + " at time " + now4Floor);
			await recordQueryForUnconfirmedTxoSpend(theDb, txo.txoid, now4Floor)
		} else {
			console.log("buildShizzle::buildOnAnUpdate(): It's a bogus tx, so, we won't bother recording it as 'unconfirmed'.")
		}

	}

	return ourNextTx;
}

export async function buildOnATransient(theOutputState, prevTxid, base0OutIndex,
								param1ClaimBountyYN, param2TipOwnerYN, param3TipAmount, param4PublishContentYN,
								param5ContentHex, param6ContentName, param7PublishTxYN, param8Tx,
								param9TxDescr, param10SameRabinYN, param11NewPKH, param12FinalPostYN,
								param13SpawnSubTransYN,
								param14GuestPostYN,
							param14bAddDialogOutput,
								param15HostTxId,
								param16HostTxState,
									param16bHostTxOutputIdx,
								privKeyP,
								privKeyQ,
								specifiedPrivateKey){

	const theDb = await openDB();

	// non-interactive use of function - static params obtained from UI
	const results = await libBuildOnTransient(theOutputState, prevTxid, base0OutIndex,
				null, // solicitYN,
				null, // solicitAnswer,
				null, // solicitContent,
				null, // solicitTx,
				null, // solicitPKH,
				null, // solicitKeys

				param1ClaimBountyYN, param2TipOwnerYN, param3TipAmount, param4PublishContentYN,
				param5ContentHex, param6ContentName, param7PublishTxYN, param8Tx,
				param9TxDescr, param10SameRabinYN, param11NewPKH, param12FinalPostYN,
				param13SpawnSubTransYN,
				param14GuestPostYN,
						param14bAddDialogOutput,
					param15HostTxId,
					param16HostTxState,
					param16bHostTxOutputIdx,

				privKeyP,
				privKeyQ,
				theDb,
				addRawTx,
				queryFetchDecodeTx,  // MAYBE we pass this in to accomodate guest posts?
				specifiedPrivateKey);


	if ( results.txid ) {

		const ourNextTx = results.txid

		// Add some db entries:
		//       addInternalTx (for txTab)
		//         txo = findTxoFromTxidAndIndex()
		//       addSpentTxo
	//	console.log("buildShizzle::buildOnATransient(): INSERTing NEW transient tx ("
	//			+	ourNextTx + ") into the DB: (not txTab,) spentTxoTab.\n\n")
		//await addInternalTx(theDb, ourNextTx, ourNextTx)

		console.log("buildShizzle::buildOnATransient(): NOW: looking for old TXO which was just spent from tx " + prevTxid
				+ " (outIndex " + base0OutIndex + ")( which was used to create this new Tx " + ourNextTx + " )")
		const txo = await findTxoFromTxidAndIndex(theDb, prevTxid, base0OutIndex)
		console.log("buildShizzle::buildOnATransient(): Found txotab row: " + JSON.stringify(txo));
		console.log("buildShizzle::buildOnATransient(): We'll now record that THAT TXO was just now spent to build the new "
					+ "Transient tx: " + ourNextTx)

		await addSpentTxo(theDb, txo.txoid, ourNextTx)
		await removeUtxoByScriptHash(theDb, theOutputState.scriptHash)

		console.log("buildShizzle::buildOnATransient(): done adding spent txo");
		if ( !ourNextTx.startsWith('11111111111111111111111111111111') ) {
			const now4Floor = Math.floor(Date.now() / 1000)
			console.log("   SO, recording UNCONFIRMED txo spend of " + txo.txoid + " at time " + now4Floor);
			await recordQueryForUnconfirmedTxoSpend(theDb, txo.txoid, now4Floor)
		} else {
			console.log("buildShizzle::buildOnATransient(): It's a bogus tx, so, we won't bother recording it as 'unconfirmed'.")
		}

	}

	// return more than just the txid
	return results
			//txid,
			//rawTx,
			//change

} // buildOnATransient()

/**
 * Builds a Dialog transaction - from the output of the TIP of the dialog
 * @param {*} theOutputState
 * @param {*} prevTxid
 * @param {*} base0OutInde
 * @param {*} param1YNClaimBounty
 * @param {*} param2YNPublishContent
 * @param {*} param3HexContent
 * @param {*} whichParticipant
 * @param {*} blockHeight
 * @param {*} param9YNFinalPost
 * @param {*} privKeyP
 * @param {*} privKeyQ
 * @param {*} specifiedPrivateKey
 * @returns
 */
export async function buildOnDialog(theOutputState, prevTxid, base0OutIndex,
								param1YNClaimBounty,            // claimBounty
								param2YNPublishContent,            // publish content? FIXME: not needed
								param3HexContent,              //the content?
						whichParticipant,
						blockHeight,
								param9YNFinalPost,
								privKeyP,
								privKeyQ,
								specifiedPrivateKey){

	const theDb = await openDB();

	// non-interactive use of function - static params obtained from UI
	const ourNextTx = await libBuildOnDialog(theOutputState, prevTxid, base0OutIndex,
											param1YNClaimBounty, param2YNPublishContent, param3HexContent,
									whichParticipant,
									blockHeight,
											param9YNFinalPost,
											privKeyP,
											privKeyQ,
											theDb,
											addRawTx,
											specifiedPrivateKey);

	// Add some db entries:
	//       addInternalTx (for txTab)
	//         txo = findTxoFromTxidAndIndex()
	//       addSpentTxo
//	console.log("buildShizzle::buildOnDialog(): INSERTing NEW transient tx ("
//			+	ourNextTx + ") into the DB: (not txTab,) spentTxoTab.\n\n")
	//await addInternalTx(theDb, ourNextTx, ourNextTx)

	console.log("buildShizzle::buildOnDialog(): NOW: looking for old TXO which was just spent from tx " + prevTxid
			+ " (outIndex " + base0OutIndex + ")( which was used to create this new Tx " + ourNextTx + " )")
/*
	const txo = await findTxoFromTxidAndIndex(theDb, prevTxid, base0OutIndex)

	if ( txo === null ) {
		console.error("Hmm. we WANTED to record that a given TXO was just now spent to build the our "
		+ "Dialog tx: " + ourNextTx + ". UNFORTUNTAELY, we never found a txoTab entry for it. BUT, it was a DIALOG output, so, we need to modify our logic.")
		alert("hmm. DialogBuilder couldn't find a txo for txid " + prevTxid + ", and outIndex " + base0OutIndex)

//FIXME: add a dialogTxoTab entry: dialogId, postNum, txid
//FIXME: ALSO: update the furthestPostNum
		alert("MAYBE: we shouldn't expect to find one, but we should just add a dialogTxoTab entry")
	} else {
		console.log("buildShizzle::buildOnDialog(): Found txotab row: " + JSON.stringify(txo));

		console.log("buildShizzle::buildOnDialog(): We'll now record that THAT TXO was just now spent to build the new "
					+ "Dialog tx: " + ourNextTx)

		await addSpentTxo(theDb, txo.txoid, ourNextTx)
		const removed = await removeUtxoByScriptHash(theDb, theOutputState.scriptHash)
		console.error("NOTE: we modified removeUtxoByScriptHash() to return true/false. result: " + removed)

		console.log("buildShizzle::buildOnDialog(): done adding spent txo");

		if ( !ourNextTx.startsWith('11111111111111111111111111111111') ) {
			const now4Floor = Math.floor(Date.now() / 1000)
			console.log("   SO, recording UNCONFIRMED txo spend of " + txo.txoid + " at time " + now4Floor);
			await recordQueryForUnconfirmedTxoSpend(theDb, txo.txoid, now4Floor)
		} else {
			console.log("buildShizzle::buildOnDialog(): It's a bogus tx, so, we won't bother recording it as 'unconfirmed'.")
		}
	}
*/
	console.warn("results of building Dialog - new txId: ", ourNextTx)
	return ourNextTx;
} // buildOnDialog()

export async function buildOnABitGroup(theOutputState, prevTxid, base0OutIndex,
								param1WhichUser,     param2claimBountyOption1_2_or_4, param3ffwdToClaimAsNonOwner,
								param4adminToGroup,  param5PerformAdmin,              param6optionalFunc,
								param7OtherUserNum,  param8newUserRabinPKH,           param9newUserP2PKH,
								param10newUserLevel, param10bNewUserName,             param11Quit,
								param12contentToPost,
								param13FinalPost,    param14spawnSubGroup,            param15TipSomeone,
								param16Tipee,        param17tipAmount,                param18PayForNamedSubThread,
								param19subthreadName,
								privKeyP,
								privKeyQ, specifiedPrivateKey){

	const theDb = await openDB();

	// non-interactive use of function - static params obtained from UI
	const ourNextTx = await libBuildOnBitGroup(theOutputState, prevTxid, base0OutIndex,
												null, // readline.question,
												null, // getOptionNumber,
												null, // getYesNoFromUser,
												null, // getContentFromUser,
												null, // solicitKeysForSigning,

												param1WhichUser,
												param2claimBountyOption1_2_or_4,
												param3ffwdToClaimAsNonOwner,
												param4adminToGroup,
												param5PerformAdmin,
												param6optionalFunc,
												param7OtherUserNum,
												param8newUserRabinPKH,
												param9newUserP2PKH,
												param10newUserLevel,
												param10bNewUserName,
												param11Quit,
												param12contentToPost,
												param13FinalPost,
												param14spawnSubGroup,
												param15TipSomeone,
												param16Tipee,
												param17tipAmount,
												param18PayForNamedSubThread,
												param19subthreadName,

												privKeyP,
												privKeyQ,
												theDb,
												addRawTx,
												specifiedPrivateKey);

	if ( ourNextTx ) {

		// Add some db entries:
		//       addInternalTx (for txTab)
		//         txo = findTxoFromTxidAndIndex()
		//       addSpentTxo
	//	console.log("buildShizzle::buildOnABitGroup(): INSERTing NEW bitgroup tx ("
	//			+	ourNextTx + ") into the DB: (not txTab,) then spentTxoTab.\n\n")
		//await addInternalTx(theDb, ourNextTx, ourNextTx)

		console.log("buildShizzle::buildOnABitGroup(): NOW: looking for old TXO which was just spent from tx " + prevTxid
				+ " (outIndex " + base0OutIndex + ")( which was used to create this new Tx " + ourNextTx + " )")
		const txo = await findTxoFromTxidAndIndex(theDb, prevTxid, base0OutIndex)
		console.log("buildShizzle::buildOnABitGroup(): Found txotab row: " + JSON.stringify(txo));
		console.log("buildShizzle::buildOnABitGroup(): We'll now record that THAT TXO was just now spent to build the new "
					+ "BitGroup tx: " + ourNextTx)

		await addSpentTxo(theDb, txo.txoid, ourNextTx)
		await removeUtxoByScriptHash(theDb, theOutputState.scriptHash)

		console.log("buildShizzle::buildOnABitGroup(): done adding spent txo");
		if ( !ourNextTx.startsWith('11111111111111111111111111111111') ) {
			const now4Floor = Math.floor(Date.now() / 1000)
			console.log("   SO, recording UNCONFIRMED txo spend of " + txo.txoid + " at time " + now4Floor);
			await recordQueryForUnconfirmedTxoSpend(theDb, txo.txoid, now4Floor)
		} else {
			console.log("buildShizzle::buildOnABitGroup(): It's a bogus tx, so, we won't bother recording it as 'unconfirmed'.")
		}

	}

	return ourNextTx;
}

export async function buildOnTheAdminGroup(theOutputState, prevTxid, base0OutIndex,
								param1numProfilesToAdd,
								param2addedUsersArray,
								param3addedUserNames,

								privKeyP,
								privKeyQ,
								specifiedPrivateKey){

	const theDb = await openDB();

	// non-interactive use of function - static params obtained from UI
	const ourNextTx = await libBuildOnAdminGroup(theOutputState, prevTxid, base0OutIndex,
												null, // getOptionNumber,
												null, // solicitKeysForSigning,

												param1numProfilesToAdd,
												param2addedUsersArray,
												param3addedUserNames,

												privKeyP,
												privKeyQ,

												theDb,
												addRawTx,
												specifiedPrivateKey);

	if ( ourNextTx ) {

		// Add some db entries:
		//       addInternalTx (for txTab)
		//         txo = findTxoFromTxidAndIndex()
		//       addSpentTxo
	//	console.log("buildShizzle::buildOnTheAdminGroup(): INSERTing NEW adminGroup tx ("
	//			+	ourNextTx + ") into the DB: (not txTab,) then spentTxoTab.\n\n")
		//await addInternalTx(theDb, ourNextTx, ourNextTx)

		console.log("buildShizzle::buildOnTheAdminGroup(): NOW: looking for old TXO which was just spent from tx " + prevTxid
				+ " (outIndex " + base0OutIndex + ")( which was used to create this new Tx " + ourNextTx + " )")
		const txo = await findTxoFromTxidAndIndex(theDb, prevTxid, base0OutIndex)
		console.log("buildShizzle::buildOnTheAdminGroup(): Found txotab row: " + JSON.stringify(txo));
		console.log("buildShizzle::buildOnTheAdminGroup(): We'll now record that THAT TXO was just now spent to build the new "
					+ "AdminGroup tx: " + ourNextTx)

		await addSpentTxo(theDb, txo.txoid, ourNextTx)
		await removeUtxoByScriptHash(theDb, theOutputState.scriptHash)

		console.log("buildShizzle::buildOnTheAdminGroup(): done adding spent txo");
		if ( !ourNextTx.startsWith('11111111111111111111111111111111') ) {
			const now4Floor = Math.floor(Date.now() / 1000)
			console.log("   SO, recording UNCONFIRMED txo spend of " + txo.txoid + " at time " + now4Floor);
			await recordQueryForUnconfirmedTxoSpend(theDb, txo.txoid, now4Floor)
		} else {
			console.log("buildShizzle::buildOnTheAdminGroup(): It's a bogus tx, so, we won't bother recording it as 'unconfirmed'.")
		}

	}

	return ourNextTx;
}

export function validateRabinPrivateKeys(p, q, pkh) {
	return validateRabinPrivKeys(p, q, pkh);
}

export async function recursivelyDeleteDescendantSpends(txid, theDB) {
	await removeFadedTx(txid, theDB); //FIXME: swap param order?
	console.log("recursivelyDeleteDescendantSpends(): done with tx " + txid)
}

// Used to pace queries to WoC
export function mySleep(ms, message) {
	if ( message.length !== 0 ) {
		console.log("Sleeping " + ms + " ms " + message)
	}
    return new Promise(resolve => setTimeout(resolve, ms))
}

/**
 * Retrieve tx, then decompose it to inputs and outputs. Intended for non-shizzle transactions
 * (transactions not in the shizzle tree) that may be referenced
 *
 * @param {*} startingTxid
 * @param {*} theDB
 * @returns
 */
export async function queryFetchTx(startingTxid, theDB) {
	console.log("===== QUERY, FETCH TX ================");

	// check if we have this tx. If not, later we'll save tx, and txos
	//FIXME: use txTab - and have it store the STATE (of all outputs, and maybe some inputs)
	console.log("queryFetchTx(): checking if we already have some knowledge of this tx: " + startingTxid);

	// If a txo is not confirmed, we keep track in a separate table.
	// Each time we query WoC for the txo status, we also keep track of the query
	// (to avoid hammering them), and assume it's still unconfirmed.

	// query for external tx - first, locally
	//FIXME: and what if a cached PENDING external tx never confirms? An eventual
	//       culling policy should eventually remove it.
	let rawTx = await getExternalTx(theDB, startingTxid)
	if ( rawTx === null ) {
		console.log("queryFetchTx(): tx " + startingTxid + " was not in externalTxTab cache.")
		if ( startingTxid.startsWith('11111111111111111111111111111111') ) {
			alert("Whoops. The content is trying to use a bogus tx which we haven't cached.")
			return null
		}
		console.log("queryFetchTx(): tx " + startingTxid + "  Will query provider, and then cache the result/rawTx.")
		rawTx = await getRawInfo(startingTxid);

		if ( rawTx !== null && typeof rawTx !== 'number' && typeof rawTx !== 'undefined') {
			//FIXME: how do we know if/when to purge transactions from internal cache?
			//       Perhaps monitor count/size periodically? Or, provide manual monitoring tools.
			await addExternalTx(theDB, startingTxid, rawTx)
		}
	}

	if ( rawTx === null || typeof rawTx === 'number' || typeof rawTx === 'undefined' ) {
		// we return more than null - the response status code, if present
		//FIXME: WHY did we fail? If 404, AND the DB has record of it, then maybe we need
		//       to delete the record, AND its children (recursively).
		//       At the very least, we should take note of a 404 (schedule for investigation, or deletion)
		console.error("queryFetchTx(): failed getting raw tx info: " + rawTx)

		if ( rawTx === 404 ) {
			console.warn("queryFetchTx(): tx not found: " + startingTxid);
			// not necessarily a shizzle tx. We shouldn't expect to
			// have descenedants which we should delete from our db
			//await removeFadedTx(startingTxid, theDB);
		}

		if ( typeof rawTx == 'undefined' ) {
			console.warn("qFT(): returned rawTx was actually 'undefined'. Let's return null, instead")
			return null
		}
		return rawTx
	}
	console.log("qFT(): raw tx obtained for " + startingTxid + " - len " + rawTx.length)  //FIXME: divide len by 2?

	return rawTx

	// parse it, to get the state
	const decomposition = decomposeRawTx(rawTx)
	//let rawOutputs = decomposition.outputs
	//let rawInputs = decomposition.inputs

	return decomposition
}

export function decomposeTx( tx ) {
	return decomposeRawTx(tx)
}


/**
 * Decode a transaction without ANY queries to a provider (disregard the network state).
 * This decode is good-enough for advanced construction of a chain of append transactions,
 * but is not exhaustive in providing EVERY field normally provided by queryFetchDecodeTx().
 *
 * @param {*} rawTx
 * @returns
 */
export function simpleDecode( rawTx ) {
	console.warn("simpleDecode()  rawTx has length " + rawTx.length/2)

    const decomposition = decomposeRawTx(rawTx)
    let rawOutputs = decomposition.outputs
    let rawInputs = decomposition.inputs

	const inputChunks = parseInputs(rawInputs, true)

	const numOutputs = rawOutputs.length

	console.error("simpleDecode() IMPLEMENT optimization: allow to decode only 1 of the " + numOutputs + " outputs")

	let statei = []
	for (let i = 0; i < numOutputs; i++ ) {

        const script = rawOutputs[i].script
        const outValue = Buffer.from(rawOutputs[i].value, 'hex').readUIntLE(0, 6)  //FIXME: should look at all 8 bytes

        if ( script.length === 50 &&
                script.toUpperCase().startsWith("76A914") &&     // DUP, HASH160  PKHLen
                script.toUpperCase().endsWith("88AC")) {         // EQUALVERIFY, CHECKSIG
            // Payout or Change
            console.log(  "output #" + i + ":    script len: " + script.length
                        + "    value: " + outValue )
            console.log("    FOR THE RECORD, here's the payout/change script: ", script)
            // Note that payouts and change are most likely ALWAYS exactly 50 bytes
        } else {
            console.warn("Preliminary decode of output " + i + " - to get its scripthash")
            // we now pass in the inputChunks for the 0th input - to potentially supply the
            // .specialOp input field for when decoding Continue (to maybe add a /voteN to the address)
            statei[i] = decodeScriptsToState(script, rawOutputs[i].value, true, inputChunks[0])

			console.warn("BTW namePath is " + statei[i].namePath + ", or " + hexStringToAscii(statei[i].namePath))
        }
	}
	console.log("   simpleDecode: ", statei)

	return {
		outputStates:     statei,

		limbName: statei[0].namePath ?
									hexStringToAscii( statei[0].namePath )
								:
									''
	}

}

export async function checkIfDomainExpiredOrRenewable(theDB, furthestQuarterlyTxId, currentBlockHeight, dtx = null) {
	console.log("checkIfDomainExpiredOrRenewable(): currentMinBlockHeight: ", currentBlockHeight)
	console.log("checkIfDomainExpiredOrRenewable(): furthestQuarterlyTxId: ", furthestQuarterlyTxId)
	console.log("checkIfDomainExpiredOrRenewable(): PASSED-IN dtx: ", dtx)

	if ( furthestQuarterlyTxId === null ) {
		return null
	}

	let decodedTx = dtx === null ?
							await getDecodedTx(theDB, furthestQuarterlyTxId)
						:
							dtx

	console.log("decode of furthestQuarterly: ", decodedTx)

	const decodedBlockNumInt = decodedTx.outputStates[0].blockNumInt
	console.log("furthestQuarterly has output #0 with .blockNumInt of ", decodedBlockNumInt)
	console.log("furthestQuarterly is " + (currentBlockHeight - decodedBlockNumInt) + " blocks old")
	const renewalDeadline = decodedTx.outputStates[0].renewalDeadlineInt
	console.log(".renewalDeadlineInt is " + renewalDeadline)
	const currentBlocksUntilRenewalDeadline = renewalDeadline - currentBlockHeight
	console.log("current blocksUntilRenewalDeadline is " + currentBlocksUntilRenewalDeadline)

	const oneIntervalSinceLastPost = decodedBlockNumInt + FOURTH_OF_YEAR_IN_BLOCKS
	const fourMonthsBeforeDeadline = renewalDeadline - THIRD_OF_YEAR_IN_BLOCKS  // must be GREATER than this to be renewable


	//   quarterlyBuildable/postable     renewable    expired
	let renewable = false
	let expired = false
	let quarterlyBuildable = false      // can we make a new Quarterly?
	if ( currentBlocksUntilRenewalDeadline < 0 ) {
		console.error("This domain has ALREADY expired")
		expired = true
	} else if ( oneIntervalSinceLastPost < currentBlockHeight ) {
		quarterlyBuildable = true
	}

	renewable = fourMonthsBeforeDeadline < currentBlockHeight && !expired

	//WARNING: these could be undefined
	const potentialSaleFlag     = decodedTx.outputStates[0].potentialSaleFlag
	const potentialSaleMinBlock = decodedTx.outputStates[0].potentialSaleMinBlock
	const priceInSats           = decodedTx.outputStates[0].priceInSatsDecimal
	const ownerP2PKH			= decodedTx.outputStates[0].ownerP2PKH

	console.log("cideor(): expired:    ", expired)
	console.log("cideor(): renewable:  ", renewable)
	console.log("cideor(): buildable:  ", quarterlyBuildable)
	console.log("cideor(): potentialSaleFlag:     ", potentialSaleFlag)
	console.log("cideor(): potentialSaleMinBlock: ", potentialSaleMinBlock)
	console.log("cideor(): priceInSats:           ", priceInSats)
	//alert("checkIfDomainExpiredOrRenewable(): Will check if domain is expired, or renewable. check console for two parameters")

	//FIXME: also, check:
	//           - if we can BUILD a new Quarterly (check prevBlockHeight + FOURTH_OF_YEAR_IN_BLOCKS)
	//           - if it's for insta-sale                  <-----
	//           - if it's frozen/waiting for an auction action    <-----
	//           - blocks UNTIL can be renewed
	//           - blocks UNTIL can build a new quarterly
	//           - blocks UNTIL will expire

	//quarterly stats
	return {
		expired: expired,
		renewable: renewable,
		canBuildNewQuarterly: quarterlyBuildable,

		currentQuarterlyBlockHeight: decodedBlockNumInt,
		minBlockHeightForNextQuarterly: oneIntervalSinceLastPost+1,

		// blocksUntilRenew,          or, THIS is the flag
		// blockUntilExpire,          or, THIS is the flag
		// blocksUntilNewQuarterly,   or, THIS is the flag
		// forInstaSale,
		// auctionPending,

		potentialSaleFlag: potentialSaleFlag,
		potentialSaleMinBlock: potentialSaleMinBlock,
		priceInSats: priceInSats,
		ownerP2PKH: ownerP2PKH,

		furthestQuarterlyDtx: decodedTx,
	}
}

/**
 * Retrieves the raw transaction, decodes its input and output scripts
 * (if there's not already a copy of the decoded tx),
 * then queries for the spend status of each output of interest.
 * FIXME: rename to what? queryFetchProcess() ?
 */
//FIXME: re-evaluate if 4th param is worthwhile
export async function queryFetchDecodeTx(startingTxid, theDB,
										weCareAboutUpdatedOutputs = true,
										weCareOnlyAboutThisPath = null,
										skipNetworkQueries = false,
										guestPostWeAreAPartyToCallback = null) {
	console.log("===== QUERY, FETCH, DECODE TX ================  wCAUO: " + weCareAboutUpdatedOutputs + "  wCOATP: " + weCareOnlyAboutThisPath);

	// check if we have this tx. If not, later we'll save tx, and txos
	//FIXME: use txTab - and have it store the STATE (of all outputs, and maybe some inputs)
	console.log("queryFetchDecodeTx(): checking if we already have DECODED form of this tx: " + startingTxid);

	// already have decoded tx?
	const decodedTx = await getDecodedTx(theDB, startingTxid);
	if ( decodedTx !== null ) {
		console.log("queryFetchDecodeTx(): ALREADY HAVE DECODED TX " + startingTxid + ": ", decodedTx)

		if ( !weCareAboutUpdatedOutputs ) {
			// The latest continue may be fetched frequently, but it can only be
			// built on every three months. If we're just checking latest continue,
			// skip the rest.
			// FIXME: though, the other outputs could advance sooner
			return decodedTx
		}

		// We have the decoded tx, but let's check the spend status of the outputs

		let forAnAppend = false
		if ( decodedTx.outputStates.length > 1 && decodedTx.outputStates[1].mode === '58' ) {
			console.warn("This check is for an APPEND. Let's try a shortcut of sorts...")
			forAnAppend = true
		}
		// Alternative form of qFDTx(), when we've already decoded the tx, but
		// still need to examine the spend status of each output
		//
		// Final parameter, weCareOnlyAboutThisPath, is a potential optimization
		return await checkDecodedTxOutputs(theDB, startingTxid, decodedTx, forAnAppend, weCareOnlyAboutThisPath);
	}

	// We don't have the decoded tx in IDB

	// a 'bogus' tx 'confirms' immediately - since we don't broadcast it
	const startingTxIsBogus = startingTxid.startsWith('11111111111111111111111111111111');

	// If a txo is not confirmed, we keep track in a separate table.
	// Each time we query WoC for the txo status, we also keep track of the query
	// (to avoid hammering them), and assume it's still unconfirmed.

	// query for tx (existing, or just-built)
	//FIXME: at some point we should store entire Txs (or decoded state) in DB
	//       Maybe the inputs too? TBD, and it also depends on the contract type
	//		 Yet at some point will we want to verify this tx is confirmed. hmm.
	let rawTx
	if ( startingTxIsBogus || skipNetworkQueries ) {
		console.log("getting raw tx using getRawTx(db...) - for " + startingTxid)
		// We've got the raw bytes of all bogus transactions - so we can decode it again
		rawTx = await getRawTx(theDB, startingTxid);

		//FIXME: test what happens if not found. what does it return? <---
	} else {
		console.log("getting raw tx using getRawInfo(provider) - for " + startingTxid)

		// NEW behavior: if we say that we don't care about updated outputs then don't
		// perform network queries for brand-new txs (not already decoded - not available via getDecodedTx() )
		// The parameter skipNetworkQueries would ALSO normally have us retrieve
		// a self-constructed raw tx from our IDB. This new behavior allows us
		// to avoid that
		if ( !weCareAboutUpdatedOutputs ) {
			console.warn("Since we don't care about updated outputs, will skip network queries")
			skipNetworkQueries = true
		}
		rawTx = await getRawInfo(startingTxid);
	}

	//NOTE: we could get here if it's in the Non-Final mempool: https://wiki.bitcoinsv.io/index.php/Transaction_Pools#Types_of_transaction_pool
	if ( rawTx === 404 ) {
		console.warn("qFDTx(): hmm. maybe we should verify there's a small problem - and try an alternative query")
		//const alternativeTx = await getDetailedTx(startingTxid)
		//console.log("    Results of alternative query: ", alternativeTx)
	}

	console.log("qFDTx(): rawTx:", rawTx)
	if ( rawTx === null || typeof rawTx === 'number' ) {
		// we return more than null - the response status code, if present
		//FIXME: WHY did we fail? If 404, AND the DB has record of it, then maybe we need
		//       to delete the record, AND its children (recursively).
		//       At the very least, we should take note of a 404 (schedule for investigation, or deletion)
		console.error("queryFetchDecodeTx(): failed getting raw tx info: " + rawTx)

		if ( rawTx === 404 ) {
			console.log("queryFetchDecodeTx(): removing 'faded' tx: " + startingTxid);
			await removeFadedTx(startingTxid, theDB);

			//FIXME: consider deleting dangling tx?
		}
		return rawTx
	}
	console.log("qFDTx(): raw tx obtained - len " + rawTx.length/2 + " bytes")

	// parse it, to get the state
	const decomposition = decomposeRawTx(rawTx)
	let rawOutputs = decomposition.outputs
	let rawInputs = decomposition.inputs

/********  CODE TO HELP ENFORCE A MAX # OF INPUTS
 
	//NOTE: rawInputs[i] has elements { txId, outputIdx, script }
	//            the txId and outputIdx tells us what tx(s) we spent to build this tx
	//            We could use this info to retrieve the PARENT tx(s) (two parents if guest-posting)
	// BTW: "buildShizzle::buildOnAnUpdate(): NOW: looking for old TXO which was just spent from tx"
	// Having the parent(s) we could then update **their** status
	//   - marking their txo(s) as spent
	//   - potentially removing them from the list of dangling paths

	console.warn("qFDTx(): This is the 1st outpoint (of " + rawInputs.length + ") for tx " + startingTxid + " (BigEndian form):")
	console.log("qFDTx():     rawInputs[0].txId is      " + rawInputs[0].txId)
	console.log("qFDTx():     rawInputs[0].outputIdx is " + rawInputs[0].outputIdx)
	let outpointsToHash = rawInputs[0].txId + rawInputs[0].outputIdx

	if ( rawInputs.length > 1 ) {
		console.warn("qFDTx(): This is the 2nd outpoint (base-0) (BigEndian form):")
		console.log("qFDTx():     rawInputs[1].txId is      " + rawInputs[1].txId)
		console.log("qFDTx():     rawInputs[1].outputIdx is " + rawInputs[1].outputIdx)
		outpointsToHash += (rawInputs[1].txId + rawInputs[1].outputIdx)
	}
	if ( rawInputs.length > 2 ) {
		console.warn("qFDTx(): This is the 3rd outpoint (base-0) (BigEndian form):")
		console.log("qFDTx():     rawInputs[2].txId is      " + rawInputs[2].txId)
		console.log("qFDTx():     rawInputs[2].outputIdx is " + rawInputs[2].outputIdx)
		outpointsToHash += (rawInputs[2].txId + rawInputs[2].outputIdx)
	}
	console.log("qFDTx(): outpointsToHash = " + outpointsToHash)
	var hashPrevouts = bsv.crypto.Hash.sha256sha256( Buffer.from(outpointsToHash,'hex') ).toString('hex');
	console.warn('qFDTx(): HASH of previous outpoints (aka hashPrevouts): ', hashPrevouts);

//IMPLEMENT ME: right here we MIGHT need to iterate over each input, and find its txotab - based on txId AND outIndex
//              MAYBE only needed if this tx is an APPEND, guest-posting TRANSIENT, and maybe dialog?
//              or maybe: non-owner auction, bitPost, or Continue
//                - If it comes from a txo in our DB, it's not a funding input
//                  If base tx is a transient, we can then determine if input is guest-post, or host
//                - If NOT from a txo in our DB, it's a funding input

//FIXME: MAYBE we only need to do this (below) if the CURRENT tx is a guest-posting transient (or maybe a dialog)?
	for ( let inIdx = 0; inIdx < rawInputs.length; inIdx++ ) {
		//const prev0TxId
		//const prev0OutIdx
		const inputTxId   = swapEndian( rawInputs[ inIdx ].txId )
		const inputOutIdx = Buffer.from( rawInputs[inIdx].outputIdx, 'hex' ).readUInt32LE(0)
		const txo = await findTxoFromTxidAndIndex(theDB, inputTxId, inputOutIdx)
		if ( txo === null ) {
			console.warn("  Input #" + inIdx + " is the FUNDING input")
		} else {
			console.warn("  Input #" + inIdx + " is a CONTRACT output (not the funding): ", txo)
			console.warn("  Input #" + inIdx + " has contract mode " + txo.mode + " for limb/domain '" + txo.limb + "'")
			//FIXME: for append (and most every type?), assign based on this info
			//FIXME: OR, do we only absolutely NEED to know prev0/1 etc for Dialog, and guest-posts?
		}
	}
******************/

	//FIXME: add logic to deduce info IF this is a guest-posting transient
//FIXME: replace later logic re: prev0TxId, prev0IOutIdx, prev1... etc. with above
//       ALSO: revisit where and how (and why) it's used

	// WAIT: we maintain a lists of dangling spends. We're going to check them periodically
	//       anyway.

	//const inputChunks = parseInputs(rawInputs, false)
	const inputChunks = parseInputs(rawInputs, true)
	//let state = decodeScriptsToState(rawOutputs[0].script, rawOutputs[0].value, true);

	console.log("------- DECODE ----------------------------");
	const numOutputs = rawOutputs.length
	var confirmed = []
	var bogus = []
	var buildable = []
	var foundBuildable = false
	var followable = []
	var foundFollowable = false
	var unspendable = []
	var statei = []

	//NOTE: looking JUST for txo record of output 0
	const aTxo = await findTxoFromTxidAndIndex(theDB, startingTxid, 0);  // We're actually looking for ANY txo record for this txid (any output index)
	console.log("queryFetchDecodeTx(): aTxo is ", aTxo);
	const dbHasKnowledgeOfThisTx = aTxo ? (aTxo !== null) : false;   // has OUTPUTS for this tx
	console.log("    dbHasKnowledgeOfThisTx is " + dbHasKnowledgeOfThisTx + " <=====");



	//FIXME: new concern: we need to determine early WHICH input is
	//       the 'primary' input, and which is secondary (if applicable),
	//       or funding
	//       It is impossible to be guaranteed of the order of inputs
	//       (funding vs primary) from a contract-enforcement perspective.
	//       We must look to the spending outpoints which are the source of
	//       the inputs.
	//       ALSO, we need to be sure that a complex (large) funding
	//       input isn't mistaken for a relevant input
	//       If we concentrate on authenticating the previous TxId,
	//       we should be alright. The previous ouput index, though,
	//       might be helpful in certain scenarios.
	//  We need to distinguish FUNDING inputs
	//  We need to disambiguate inputs 0 vs 1



	// This will be helpful if we're ever traversing an asset backwards
	const prev0TxId = swapEndian(rawInputs[0].txId)
	const prev0OutIdx = Buffer.from( rawInputs[0].outputIdx, 'hex' ).readUInt32LE(0)
	console.warn("prev0TxId is " + prev0TxId)
	console.warn("prev0OutIdx is " + prev0OutIdx)

	if ( prev0OutIdx !== 0 ) {
		//alert("Heads-up. Input 0 comes from a txid:N output, where N is not 0. This definitely can happen as a result of guest-posts")
	}

	let prev1TxId = null
	let prev1OutIdx   = null
	//FIXME: consider if we should look a [0].hostGuestMode?
	//       How best to distinguish rawInputs[1] from simple funding?
	if ( rawInputs.length > 1 ) {
		console.warn("rawInputs.length: " + rawInputs.length)
		console.error("CHECK if more than 1 *contract* input...")
		console.warn("rawInputs[1]: ", rawInputs[1])
		prev1TxId = swapEndian( rawInputs[1].txId )
		console.warn("prev1TxId is " + prev1TxId)
		prev1OutIdx   = Buffer.from( rawInputs[1].outputIdx, 'hex' ).readUInt32LE(0)
		console.warn("prev1OutIdx is " + prev1OutIdx)

		console.log("NOTE: we're going to need TWO URLs/addresses: for HOST, and for GUEST-POST")
	}

	if ( prev0OutIdx !== null && prev0OutIdx !== 1 ) {
		//alert("Heads-up. Input 1 comes from a txid:N output, where N is not 1")
	}





	// To peform a BULK query, we need the SCRIPTHASH of each output
	// Though, right now we only perform BULK queries for append txs

	// Since this tx is newly-decoded, the status of EVERY output is unknown
	let weKnowThisWasSpentYetDontHaveRecord = []

	// PRELIMINARY decode of each output - so we can find the .scripthash of each (contract output)
	for (let i = 0; i < numOutputs; i++ ) {
		weKnowThisWasSpentYetDontHaveRecord[i] = null
		const script = rawOutputs[i].script
		const outValue = Buffer.from(rawOutputs[i].value, 'hex').readUIntLE(0, 6)  //FIXME: should look at all 8 bytes

		// boring payout, or change?
		//WARNING: this logic must match a little bit below
		if ( script.length === 50 &&
				script.toUpperCase().startsWith("76A914") &&     // DUP, HASH160  PKHLen
				script.toUpperCase().endsWith("88AC")) {         // EQUALVERIFY, CHECKSIG
			// Payout or Change
			console.log(  "output #" + i + ":    script len: " + script.length
						+ "    value: " + outValue )
			console.log("    FOR THE RECORD, here's the payout/change script: ", script)
			// Note that payouts and change are most likely ALWAYS exactly 50 bytes
		} else {
			console.warn("Preliminary decode of output " + i + " - to get its scripthash")
			// we now pass in the inputChunks for the 0th input - to potentially supply the
			// .specialOp input field for when decoding Continue (to maybe add a /voteN to the address)
			statei[i] = decodeScriptsToState(script, rawOutputs[i].value, true, inputChunks[0])
		}
	}

	// Let's save time, for APPENDS, by performing a bulk query of all UTXOs

	console.warn("qFDTx(): statei[] has length " + statei.length)
	console.warn("qFDTX(): numOutputs is " + numOutputs)
	let forAnAppend = numOutputs > 1 && typeof statei[1] !== 'undefined' && statei[1].mode === '58'
	if ( forAnAppend && !startingTxIsBogus && !skipNetworkQueries ) {
		// let's remove any outputs for which:
		//     - the output value is 0
		let scriptHashesToQuery = []
		let mapsToTxoIndex = []
		let querySize = 0
		for (let i = 0; i < statei.length; i++ ) {
			const outValueLE = statei[i].contractSatoshis
			const outValue = Buffer.from( outValueLE, 'hex' ).readUIntLE(0, 6)  //FIXME: should look at all 8 bytes

			// Maybe ONLY a change script would have this.
			// But knowning this, we COULD simplify
			if ( outValue === 0 ) {
				continue
			}

			scriptHashesToQuery[ querySize ] = statei[i].scriptHash
			mapsToTxoIndex[ querySize ] = i
			querySize++
		}

		console.log("qFDTx() querySize: ", querySize)
		// fails for BITAILS query if querySize is 0
		if ( querySize > 0 ) {
			weKnowThisWasSpentYetDontHaveRecord = await performBulkQueryOfUTXOs(startingTxid,
																				scriptHashesToQuery,
																				mapsToTxoIndex,
																				weKnowThisWasSpentYetDontHaveRecord)
		}
	}

	console.warn("\nweKnowThisWasSpentYetDontHaveRecord[] = ", weKnowThisWasSpentYetDontHaveRecord)

	// Finish processing this transaction

	// extract useful input parameters - part of what unlocks input 0
	// Based on the mode of OUTPUT 0 (which 'defines' the tx contract), we know what
	// input parameters to expect
	let input1Params = '';
	let input0Params
	let input2Params = '';

	// Examine each output
	// Here is where we COULD probably benefit from using a bulk scriptHash query
	// to weed-out UNSPENT TXOs
	let dialogSentBy = ''
	let dialogNamePath = ''
	let dialogMostRecentOwnerPubKey = ''
	let dialogMostRecentVisitorPubKey = ''
	let decodedPrevTx = null
	for (let i = 0; i < numOutputs; i++ ) {
		const script = rawOutputs[i].script
		const outValue = Buffer.from(rawOutputs[i].value, 'hex').readUIntLE(0, 6)  //FIXME: should look at all 8 bytes

		// boring payout, or change?
		//WARNING: this logic must match a little bit above
		if ( script.length === 50 &&
				script.toUpperCase().startsWith("76A914") &&     // DUP, HASH160  PKHLen
				script.toUpperCase().endsWith("88AC")) {         // EQUALVERIFY, CHECKSIG
			// Payout or Change
			console.log(  "    output #" + i + ":    script len: " + script.length
						+ "    value: " + outValue )
			console.log(" FOR THE RECORD, here's the script: ", script)
			// Note that payouts and change are most likely ALWAYS exactly 50 bytes
		} else {
			confirmed[i] = false
			bogus[i] = startingTxIsBogus    //false

			// we now pass in the inputChunks for the 0th input - to potentially supply the
			// .specialOp input field for when decoding Continue (to maybe add a /voteN to the address)
			//BUT, we've already done this - in the preliminary step, just above

			//statei[i] = decodeScriptsToState(script, rawOutputs[i].value, true, inputChunks[0])

			// E or e   means EBFRA
			if ( statei[i].mode === '45' || statei[i].mode === '65' ) {
				console.warn("This is an EBFRA output: ", statei[i])
//FIXME: show more?
			} else if (statei[i].mode === '44' ){
				console.warn("This is a Dialog output: ", statei[i])
				console.warn("======> output #" + i + ":    contract: '" + statei[i].contract + "',   mode: " + statei[i].mode	+ " ('" + hexByteToAscii(statei[i].mode) + "')"
				+ "    ownerName: " + statei[i].ownerName + ",  ownerAddress: ", statei[i].ownerAddress
				+ "    visitorName: " + statei[i].visitorName + ",  visitorAddress: ", statei[i].visitorAddress
				+ "    POST num: " + statei[i].postNum
				+ "    script len: " + script.length
				+ "    value: " + outValue + " sats")
			} else {
				console.warn("======> output #" + i + ":    contract: '" + statei[i].contract + "',  namePath: '" + statei[i].namePath + "' ('" + hexStringToAscii(statei[i].namePath) + "')"
						+ "    mode: " + statei[i].mode	+ " ('" + hexByteToAscii(statei[i].mode) + "')"
						+ "    script len: " + script.length
						+ "    value: " + outValue + " sats")
			}

			let newDialog = null
			// We need input params for input #0 now, to see if this is a guest/host tx
			if ( i === 0 ) {

				// NO LONGER TRUE. We NOW embed explicit addresses (for both owner, and visitor)

				// WARNING: are we demanding the useful info must be in input 0?
				//          This might not be enforced by contracts

				input0Params = getUsefulInputParams(inputChunks[0], hexByteToAscii( statei[0].mode ) );
				if ( input0Params.hostGuestMode === 1 || input0Params.hostGuestMode === 4
					|| statei[i].mode === '44' ) {

					// use special callback - IF WE're a party to this GP
					if ( guestPostWeAreAPartyToCallback ) {
						await guestPostWeAreAPartyToCallback(theDB, startingTxid, statei)
					} else {
						console.warn("buildShizzle qFDTx() 1: there's no GP callback to call - for txId " + startingTxid)
						console.warn("That's probably fine. But if not, check the following stack trace:")
						console.trace()
						//alert("qFDTx() - no guest-post callback defined so far? Hmm. Look at txId " + startingTxid)
					}

					//NOTE: for Host, this could be the Nth-consecutive tx that this transient is hosting
					//      See if the PREVIOUS tx (input0PrevTxId) has an address that ends
					//      with /guestNNNNN
					//      For Dialog, we want the previous address, so we can manually set THIS
					//      address ( .../dialogNNN ) (unlike others, address isn't embedded in a Dialog)
					// WRONG: address is NOW embedded in Dialog <-----
					const prevHostTxId = prev0TxId

					// NOTE the slight recursion. We probably don't care about updated outputs here (3rd param)
					decodedPrevTx = await queryFetchDecodeTx(prevHostTxId, theDB, false)

					if ( input0Params.hostGuestMode === 1 || input0Params.hostGuestMode === 4 ) {
						// since this might be a dialog-spawning tx, we need input1Params (sub-trans-spawning-info)
						input1Params = getUsefulInputParams(inputChunks[1], hexByteToAscii( statei[0].mode ));
						const guestPosterSpawnsSubTrans = input1Params.spawnSub
						const addForSubSpawn = guestPosterSpawnsSubTrans ? 1 : 0

						console.warn("NOW: output 0's address is " + statei[0].address)
						if (  input0Params.hostGuestMode === 4 ) {
							console.error("some of these steps, below, we may be able to remove - since Dialog now has more state available to us")
							dialogSentBy = 'owner'
							dialogNamePath = hexStringToAscii( statei[1].namePath + '2D' + statei[0].namePath )
							console.warn("qFDTx(): picking-")
							dialogMostRecentVisitorPubKey = decodedPrevTx.input2Params.fundingPubKey
							console.warn("xxxBTW: Start of a dialog. sent by: owner (obviously). mostRecentOTHERGuyPubKey: "
										+ dialogMostRecentVisitorPubKey)
							console.warn("Start of a dialog. set the dialogNamePath to " + dialogNamePath)


							console.error("c) RIGHT HERE/NOW we could/should extract, and save, the TWO public keys for the dialog we're about to create.")

							//FIXME: why not just grab it regardless (assuming there IS a third input)
							if ( input1Params.hostGuestMode === 3  ) {
								console.warn("b) DECODING TRANSIENT that's creating a NEW Dialog!! We'll want to grab the public key of the guest-poster's FUNDING input")
								if ( input2Params === '' ) {
									input2Params = getUsefulInputParams(inputChunks[2], 'Z' ); //NOTE: the 'Z' is a pseudo mode - to signal special case (Dialog spawn from Transient)
								}
								//

								console.warn("  about to CREATE A DIALOG!!!!!: input2Params.fundingPubKey: " + input2Params.fundingPubKey)

							} else{
								alert("PROBLEM. stop right here. if input0 has hostGuestMode of 4, input1 should have hostGuestMode of 3, right?")
								throw new Error("LOGIC problem - handling new Dialog")
							}


							// seed the Dialog address, hostName, guestName
							// The OWNER/HOST of the dialog is the GUEST here  <-- tricky
							statei[2 + addForSubSpawn].address = statei[1].address + "/dialog0"
							statei[2 + addForSubSpawn].ownerName  = hexStringToAscii( statei[1].namePath )
							statei[2 + addForSubSpawn].visitorName = hexStringToAscii( statei[0].namePath )
							statei[2 + addForSubSpawn].namePath = statei[1].namePath

							console.warn("queryFetchDecodeTx(): Creating a new dialog record");
							newDialog = await createADialog(theDB,
												statei[2 + addForSubSpawn].ownerName,   statei[1].ownerCount,
												statei[2 + addForSubSpawn].visitorName, statei[0].ownerCount,
												startingTxid,

												// furthestTxid, and furthestScriptHash help us quickly detect new posts
												startingTxid,
												statei[2 + addForSubSpawn].scriptHash,

												input2Params.fundingPubKey,
												decodedPrevTx.fundingPK
												);
							console.warn("qfdtx(): newDialog: ", newDialog)
							console.error("qFDTx(): new dialog has owner dialogId of : ", newDialog.ownerDialogId
										+ ", and visitor dialogId of " + newDialog.visitorDialogId)
							statei[2 + addForSubSpawn].dialogIdForOwner   = newDialog.ownerDialogId
							statei[2 + addForSubSpawn].dialogIdForVisitor = newDialog.visitorDialogId

							statei[2 + addForSubSpawn].ownerOwnerCount   = statei[1].ownerCount
							statei[2 + addForSubSpawn].visitorOwnerCount = statei[0].ownerCount
						}
					} else {

						shmalert("qFDTx() about to do dialog-specific work. Some of this might not be needed. Or, might not need to be based on PREVIOUS tx <---")
						dialogNamePath = decodedPrevTx.dialogNamePath
						// This is a DIALOG, but NOT the spawn

						console.error("This is a dialog, and we will base its address on that of the prev tx, address, but WHICH output?")

						console.error("  .fundingPK from PREVIOUS: " + decodedPrevTx.fundingPK)  // <----- used for what? Document this

						// process Dialog address just like that of a guest-post (which we did up above a bit)
						console.warn("qFDTx(): carrying-forward the namePath. here's the prev tx decoded: ", decodedPrevTx)

						const prevParts = decodedPrevTx.address.split('/')
						const endOfPrevAddress = prevParts[ prevParts.length - 1 ]
						console.warn("endOfPrevAddress: " + endOfPrevAddress + " - length " + endOfPrevAddress.length)
						let ownerDialogId = 0
						let visitorDialogId = 0
						if ( !endOfPrevAddress.startsWith('dialog' ) ) {
							const prevInput1Params = decodedPrevTx.input1Params
							const addForSubSpawn = prevInput1Params.spawnSub ? 1 : 0
							console.error("B (prev) addForSubSpawn: " + addForSubSpawn + " <-------")
							console.error("==> This is the FIRST consecutive Dialog tx. and addForSubSpawn is " + addForSubSpawn) //, so, will use prev output 1. Let's get it NOW.")
							console.error("xxxBTW: address of prev output 1: " + decodedPrevTx.outputStates[1].address)
							console.error("xxxBTW: parts of address of output 1: " + decodedPrevTx.outputStates[1].address.split('/'))

							console.error("xxxBTW: address of prev output 2+: " + decodedPrevTx.outputStates[2+addForSubSpawn].address)
							console.error("xxxBTW: parts of address of output 2+: " + decodedPrevTx.outputStates[2+addForSubSpawn].address.split('/'))

							statei[0].namePath = decodedPrevTx.outputStates[1].namePath
							console.error("xxxBTW: namePath should probably be: " + decodedPrevTx.outputStates[1].namePath)
							console.error("xxxBTW: OR namePath should probably be: " + decodedPrevTx.outputStates[2+addForSubSpawn].namePath)

							shmalert("We're about to derive some tx fields that MAYBE could've been derived in shizzleBuilder's decodeDialogScriptsToState()... BUT, may still want to append /dialog1 onto output 0's address. TBD")
							statei[0].address           = decodedPrevTx.outputStates[1].address + "/dialog1"

							statei[0].ownerName         = decodedPrevTx.outputStates[2 + addForSubSpawn].ownerName
							statei[0].visitorName       = decodedPrevTx.outputStates[2 + addForSubSpawn].visitorName
							ownerDialogId               = decodedPrevTx.outputStates[2 + addForSubSpawn].dialogIdForOwner
							visitorDialogId             = decodedPrevTx.outputStates[2 + addForSubSpawn].dialogIdForVisitor
							statei[0].ownerOwnerCount   = decodedPrevTx.outputStates[2 + addForSubSpawn].ownerOwnerCount
							statei[0].visitorOwnerCount = decodedPrevTx.outputStates[2 + addForSubSpawn].visitorOwnerCount
						} else {

							shmalert(" MAYBE we're about to do special processing to grab a PREVIOUS dialog's state. MAYBE this is no longer needed. MAYBE. MAYBE we could IDB-MAP these fields to the owner+visitor. BUT, shouldn't we also consider ownerCounts? <--- ")

							console.error("==> This is NOT the first consecutive Dialog tx, so, will use previous output 0 to grab state (carry forward)")
							statei[0].namePath = decodedPrevTx.outputStates[0].namePath
							// This is not the first /dialog
							const lengthOfWordDialog = 6
							const ordinal = endOfPrevAddress.substring(lengthOfWordDialog, endOfPrevAddress.length)
							console.warn("The previous tx was a Dialog tx too - with order " + ordinal)
							const orderNum = parseInt(ordinal)
							//console.log("  that number " + orderNum)
							const truncatedParts = prevParts.slice(0, prevParts.length - 1)
							const merged = truncatedParts.join('/')
							statei[0].address = merged + "/dialog" + (orderNum + 1)
							statei[0].ownerName         = decodedPrevTx.outputStates[0].ownerName
							statei[0].visitorName       = decodedPrevTx.outputStates[0].visitorName

							ownerDialogId               = decodedPrevTx.outputStates[0].dialogIdForOwner
							visitorDialogId             = decodedPrevTx.outputStates[0].dialogIdForVisitor
							statei[0].ownerOwnerCount   = decodedPrevTx.outputStates[0].ownerOwnerCount
							statei[0].visitorOwnerCount = decodedPrevTx.outputStates[0].visitorOwnerCount
						}

						statei[0].dialogIdForOwner   = ownerDialogId
						statei[0].dialogIdForVisitor = visitorDialogId

						console.error("==> Because this is a dialog, we need input1Params (for funding info). Let's get it NOW. BTW, inputChunks[1]: ", inputChunks[1])
						//NOTE: this is a dialog, so we ASSUME the second input is the funding. Technically, it could be EITHER input
						input1Params = getUsefulInputParams(inputChunks[1], 'Z' ); //NOTE: the 'Z' is a pseudo mode - to signal special case (dialog)
						console.error("==> Done getting input1Params: ", input1Params)

						if ( input0Params.dialogSenderRabinPKH === statei[0].ownerRabinPKH ) {
							dialogSentBy = 'owner'
							console.warn("This Dialog message is from the Owner. Setting dialogMostRecentOwner... using inputXParams.fundingPubKey")
							//FIXME: may also need to track OUR OWN most-recent P2PKH privKey - at some point
							dialogMostRecentOwnerPubKey = input1Params.fundingPubKey    // <------ input 1 for funding
							dialogMostRecentVisitorPubKey  = decodedPrevTx.dialogMostRecentVisitorPubKey
							console.warn("qFDTx(): since sent by the owner, setting new dialogMostRecentOwnerPubKey: "
										+ dialogMostRecentOwnerPubKey)
							console.warn("qFDTx(): since sent by the owner, carrying-forward prev tx's dialogMostRecentVisitorPubKey: "
										+ dialogMostRecentVisitorPubKey)

						} else {
							dialogSentBy = 'guest'
							console.warn("This Dialog message is from the Guest")
							//FIXME: may also need to track OUR OWN most-recent P2PKH privKey - at some point
							dialogMostRecentOwnerPubKey = decodedPrevTx.dialogMostRecentOwnerPubKey
							dialogMostRecentVisitorPubKey = input1Params.fundingPubKey  // <------ input 1 for funding
							console.warn("qFDTx(): since not sent by the owner, copying prev dialogMostRecentOwnerPubKey: "
										+ dialogMostRecentOwnerPubKey)
							console.warn("qFDTx(): since not sent by the owner, setting dialogMostRecentVisitorPubKey: "
										+ dialogMostRecentVisitorPubKey)
						}
					}
				}

				console.warn("queryFetchDecodeTx(): i is 0. Will now finally add tx to txTab");
				await addInternalTx(theDB, startingTxid, startingTxid, statei[0].address);

				// AFTER the for-loop, examine inputs
				// IF there's a guest-post, addInternalTx with the statei[1].address too

			} // if i == 0

			//NOTE: this should guarantee that if/when a Dialog is spawn immediately after this,
			//      we'll have the funding PK of THIS (then previous) tx
			//FIXME: why not just grab it regardless (assuming there IS a third input). Or, is that what we're doing?
			if ( input0Params.hostGuestMode === 2 || input1Params.hostGuestMode === 2 ) {

//XXX could check for special GP callback - IF WE're a party to the the GP
//				if ( guestPostWeAreAPartyToCallback ) {
//					await guestPostWeAreAPartyToCallback(theDB, startingTxid, statei)
//				} else {
//					console.error("buildShizzle qFDTx() 2: there's no GP callback to call - for txId " + startingTxid)
//					alert("qFDTx() - no callback so far?")
//				}

				console.warn("a) DECODING TRANSIENT that's GUEST-POSTING! We'll want to grab the public key of the guest-poster's FUNDING input")
				if ( input2Params === '' ) {
					input2Params = getUsefulInputParams(inputChunks[2], 'Z' ); //NOTE: the 'Z' is a pseudo mode - to signal special case (dialog)
				}

				// NOTE: near the end of this function (down below), we'll use input2Params.fundingPubKey to set tx.fundingPK
			}


			//FIXME: rename to dontQueryTxProvider (or inverted: queryTxProvider)
			let txoSpent = false;
			if ( outValue === 0 ) {
				console.log("    THIS OUTPUT HAS NO VALUE. (Final post?) Will assume it can't be spent.");
				unspendable[i] = i
				// This should be either Update, Transient, or BitGroup, or Auction (final tx)

				//NOTE: while this IS unspendable, below, we'll allow a txoTab entry to be created
				//      This will facilitate traversal - removing special cases for finalPost
			}

			let spentTxoRecord = null

			//NOTE: new logic. EVEN IF unspendable, create a txotab entry
			//      The goal is to facilitate traversal
			{


				if ( !dbHasKnowledgeOfThisTx ) {
					// by adding these entries HERE, we hope that updateTxoConfirmedStatus() can add a spentTxo record - if needed

					console.warn("qFDTx(): db has no knowledge of this tx, but will start to add db entries EARLIER than updateTxoConfirmedStatus...")
					//FIXME: what does that mean above ("EARLIER than updateTxoConfirmedStatus...")?

					const mode = hexByteToAscii(statei[i].mode);
					console.log("Adding txo for output # " + i + " of tx " + startingTxid + ", mode " + mode);
					// maybe for Dialog namePath, do/calculate something special, on-the-fly
					console.warn("qFDTx() BTW: here's the state for this output (check on namePath) ( outIdx " + i + ") : ", statei[i])
					let txoNamePath   = statei[i].namePath
					let txoAddress    = statei[i].address
					let txoOwnerCount = statei[i].ownerCount
					if ( mode === 'D' && i === 0 ) {

						// if this is a dialog output, we've set the namePath to the owner's namePath - for now

						//txoAddress = "bshz://someaddress"
						console.error("This is a dialog. will add to dialogTxoTab. BTW: dialog's namePath: " + txoNamePath)
						console.warn("  BTW: decodedPrevTx: ", decodedPrevTx)
						await addDialogTxos(theDB, statei[i].dialogIdForOwner, statei[i].dialogIdForVisitor, statei[i].postNum, startingTxid)
						console.warn("BTW Dialog is between " + statei[0].ownerName + " and " + statei[0].visitorName)
						console.error("case A: we called addDialogTxos() for Dialog on base-0 output #" + i + "   - txid " + startingTxid)
					} else if ( newDialog !== null ) {
						console.error("This is the START of a dialog (on outIndex 0? " + i + "). will add to dialogTxoTab. BTW: (unused?) dialogNamePath: " + dialogNamePath)
						console.error("newDialog: ", newDialog)
						await addDialogTxos(theDB, newDialog.ownerDialogId, newDialog.visitorDialogId, 0, startingTxid)
						console.warn("NOTE: using this as txo namePath on index " + i + " : " + txoNamePath)
						console.error("case B: we called addDialogTxos() for Dialog on base-0 output #" + i + "   - txid " + startingTxid)
					} else {
						console.warn("BTW namePath is " + txoNamePath + ", or " + hexStringToAscii(txoNamePath))
					}

					if ( mode === 'D' && i !== 0) {
						console.warn("This is a Dialog output. We WON'T create a txotab entry for it. We've ALREADY created a dialogTxoTab entry for it")

						console.warn("should add IDB dialog txos for tx " 	+ startingTxid + "   owner dialogId " + statei[i].dialogIdForOwner
																+ "  visitor " + statei[i].dialogIdForVisitor + "  post " + statei[i].postNum)


						await addDialogTxos(theDB, statei[i].dialogIdForOwner, statei[i].dialogIdForVisitor, statei[i].postNum, startingTxid)
						console.error("case C: we called addDialogTxos() for Dialog on base-0 output #" + i + " --- 0th post?   - txid " + startingTxid)
					} else {
						// We've added limb, address, owner
						// eventually we'd remove non-essential (non-continue) records for old owners
						//    (preserving the path of continues: modes K, P, p)
						console.warn("now adding txo for output " + i + ' of tx ' + startingTxid + "   namepath: " + txoNamePath + ", ownerCount: " + txoOwnerCount)
						await addTxo(theDB, hexStringToAscii( txoNamePath ),
											txoAddress, txoOwnerCount, startingTxid, i, mode)
					}
				}
				else if ( outValue !== 0 ) { //NOTE: we might KNOW about this, but not have all the records

					// Check if we need to query Tx-Provider.
					// If so, throttle queries a little (don't check the same tx too often)

					// Local db query for spend
					//FIXME: rename txoIfSpent(), or getTxoIfSpent()?
					spentTxoRecord = await doesDbHaveTxoSpend(theDB,
																startingTxid,
																i,
																findTxoFromTxidAndIndex,
																findNextTxFromSpentTxo);
					console.log("queryFetchDecodeTx(): got back spent txo record of ", spentTxoRecord)

					// It was spent, but did it confirm? We track unconfirmed separately
					if ( spentTxoRecord !== null ) {
						if ( spentTxoRecord.txIdItFunds.startsWith('11111111111111111111111111111111') ) {
							console.log("queryFetchDecodeTx(): BTW: the spend on output " + i + " is a bogusTx")
							bogus[i] = true
							confirmed[i] = true
							txoSpent = true
						} else {
							console.log("queryFetchDecodeTx(): found a DB entry of a spend on outIndex " + i + ", but let's check if it's unconfirmed");
							const now = Math.floor(Date.now() / 1000);

							// Did we record the txo spend as UNCONFIRMED? If so, when did we last query the provider?
							// There's a chance it never confirmed, so, we should re-query
							const queryTime = await getUnconfirmedTxoSpendQueryTime(theDB, spentTxoRecord.txoKeyId);
							if ( queryTime !== null ) {
								console.log("queryFetchDecodeTx(): now - queryTime: ", now - queryTime)

								//FIXME: parameterize this 60-seconds. Increase. (Most unconfirmed txo spends will confirm at
								//       some point). If it never confirms (fee problem?), it could take several days to disappear,
								//       so, this param could easily be 10 minutes, or 10 hours. But, we want to give SOME
								//       visibility into any issues with non-confirming transactions
								if ( now - queryTime > 90 ) {
									console.log("It's been MORE THAN 60 seconds since we've queried for this txo spend status "
											+ "(which was still unconfirmed), so, we'll query again")
								} else {
									console.log("We've recently queried the provider about this unconfirmed txo (for tx "
											+ startingTxid + "), so, let's leave it alone for now")
									txoSpent = true;
								}
							} else {
								console.log("There's no record of this txo being unconfirmed, so, we'll assume it's confirmed (spent)")
								txoSpent = true;
								confirmed[i] = true
							}
						}
					} else {
						console.log("db doesn't know of a spend for outIndex " + i + ", SO, we'll check WOC...")
					}
				} // dbHasKnowledgeOfThisTx

			}

			// preserve logic that would otherwise have been disturbed by
			// now allowing txotab entries for final posts (above)
			if ( outValue !== 0 ) {

				// Here is where we add a lot of latency. This can benefit from
				// an earlier bulk query of all known UTXOs



				// Don't bother querying provider if it's a bogusTx, or if we already know it was spent
				// OR if skipNetworkQueries
				// NOTE: if we have no record of a spend, we'll query the provider EVERY time
				//       Potential opportunity to cache results (pace queries)
				if ( skipNetworkQueries ) {
					// Note that this mode may immediately result in stale spending
					// state. If used to build a chain of appends, there will exist a
					// path of spends not reflected in the buildable[], followable[],
					// and foundFollowable state.

					//followable[i] = i
					buildable[i] = i
					txoSpent = false
					foundFollowable = false
				} else if ( !txoSpent && !startingTxIsBogus ) {
					if ( weKnowThisWasSpentYetDontHaveRecord !== null
						 && weKnowThisWasSpentYetDontHaveRecord[i] === false ) {
						console.warn("NORMALLY WE WOULD query for this output, #" + i + ", but "
									+ "we've already perfomed a BULK query, and this "
									+ "output is UNSPENT. This saves on latency - for each output query we avoid. Yay.")
					} else {
						try {
							console.log("queryFetchDecodeTx(): db doesn't know of a spend for outIndex " + i
									+ ", OR, we haven't seen it confirm (or haven't checked recently), SO, check WOC...");
							console.log("  BTW: will check on scriptHash " + statei[i].scriptHash)

							const status = await updateTxoConfirmedStatus(theDB, statei[i].scriptHash, startingTxid, spentTxoRecord,   i, statei[i]);
							confirmed[i] = status.spent && !status.unconfirmed
							if ( status.spent ) {
								console.warn("qFDTx(): changing txoSpent to true (whether it confirmed, or not). Setting followable, and clearing buildable")
								followable[i] = i
								buildable[i] = null
								txoSpent = true
								foundFollowable = true
							}
						} catch ( err ) {
							if ( err instanceof ScriptHashNotFoundError ) {
								return 404
							} else {
								throw err
							}
						}

						//FIXME: let's be more sophisticated. Each iteration/output doesn't necessarily result
						//       in a query to the provider, right? Some may have confirmed already, or we've
						//       put-off a query since it was recently made.

						// pace our queries to WoC - for each output of this tx
						// But, don't bother sleeping if this was the final output (no more potential queries to come)
						// Simple logic: if not the final output, sleep
						// BUT, it's only APPEND txs that have more than 3 outputs to query
						// Anything else need not be paced - they'd only have a max of 3 outputs,
						// so, querying for each in rapid succession wouldn't violate the current
						// max-queries-per-second.
						if ( forAnAppend && i !== (numOutputs - 1) ) {
							await mySleep(50, "sleep at qFDTx") 	// decreased from 330 ms on Oct 25, 2022
													// decreased from 310 on Oct 26
													// reverted to 330 on Oct 27. We've been seeing Network Errors (for WoC)
													// increased to 350 on Oct 27 (for WoC)
													// DECREASE to 150, then 100, then 75, then 50 on 10/31/22
													//   - since we now have simple provider management (alternating providers)
						}
					}
				}
			} // non-zero value script

			const outMode = statei[i].mode
			const thisIsPrep        =  outMode === '62'                      // 'b' builderPrep
			const thisIsPub 		=  outMode === '50'                      // 'P' claimable
			const thisIsPubPreClaim =  outMode === '4b' || outMode === '4B'  // 'K' pre-claimed
			const thisIsExpandable  =  outMode === '58'                      // 'X' expandable
			const thisIsContinue	=  outMode === '70'                      // 'p' publish/CONTINUE
			const thisIsAskBid  	=  outMode === '4e' || outMode === '4E'  // 'N' negotiate sale
			const thisIsUpdate		=  outMode === '55'                      // 'U' update
			const thisIsTransient	=  outMode === '30'
									|| outMode === '31'                      // ascii '1' Transient
									|| outMode === '32'
									|| outMode === '33'
									|| outMode === '34'
									|| outMode === '35'
									|| outMode === '36'
									|| outMode === '37'
									|| outMode === '38'
									|| outMode === '39'
			const thisIsBitGroup    =  outMode === '47'                      // 'G' group
			const thisIsAdminGroup  =  outMode === '67'                      // ascii 'g' AdministerGroup
			const thisIsEBFRA       =  outMode === '65' || outMode === '45'
			const thisIsDialog      =  outMode === '44'

			if (   !thisIsPrep && !thisIsPub    && !thisIsPubPreClaim && !thisIsExpandable && !thisIsContinue && !thisIsAskBid
				&& !thisIsUpdate && !thisIsTransient   && !thisIsBitGroup   && !thisIsAdminGroup && !thisIsEBFRA && !thisIsDialog ) {
					console.log("                  ????  <-----<< what?")
					throw new Error("11001: Unrecognized mode (" + outMode + ") for output " + i + " (base-0) of transaction " + startingTxid)
			}

			if ( unspendable[i] === i ) {
				console.log("  unspendable...")
				if ( thisIsUpdate ) {
					console.log("                  UNSPENDABLE/final Update")
				} else if ( thisIsTransient ) {
					console.log("                  UNSPENDABLE/final Transient")
				} else if ( thisIsBitGroup ) {
					console.log("                  UNSPENDABLE/final BitGroup")
				} else if ( thisIsEBFRA ) {
					console.log("                  UNSPENDABLE/final EBFRA")
				} else if ( thisIsDialog ) {
					console.log("                  UNSPENDABLE/final Dialog")
				} else {
					console.warn("Unspendable (final post of this line): mode " + outMode)
				}
			} else if ( !txoSpent ) { 	// because there are more than 1 found (via WoC API), this must have been spent
				console.log("  !txoSpent...")
				buildable[i] = i
				foundBuildable = true

				//FIXME: check out presentAppendOptionsBasedOnState() for how it handles console logging

				if (thisIsPub) {
					console.log("                  Publishing Point - UNCLAIMED (UNspent)");
				} else if ( thisIsPubPreClaim ) {
					console.log("                  Publishing Point - PRE-CLAIMED (UNspent)");
				} else if ( thisIsExpandable ) {
					console.log("                  EXPANDABLE output  <UNspent>");
				} else if ( thisIsContinue ) {
					console.log("                  CONTINUE output    <UNspent>");
				} else if ( thisIsAskBid ) {
					console.log("                  ASKBID output      <UNspent>");
				} else if ( thisIsUpdate ) {
					console.log("                  UPDATE output      <UNspent>");
				} else if ( thisIsTransient ) {
					console.log("                  TRANSIENT output   <UNspent>");
				} else if ( thisIsBitGroup ) {
					console.log("                  BITGROUP output    <UNspent>");
				} else if ( thisIsAdminGroup ) {
					console.log("                  ADMINGROUP output  <UNspent>");
				} else if ( thisIsEBFRA ) {
					console.log("                  EBFRA output       <UNspent>");
				} else if ( thisIsPrep ) {
					console.log("                  BuilderPrep output <UNspent>");
				} else if ( thisIsDialog ) {
					console.log("                  Dialog output      <UNspent>");				}
			} else {
				console.log("   txoSpent...")
				followable[i] = i
				foundFollowable = true

				if (thisIsPub) {
					console.log("                  Publishing Point - CLAIMED (SPENT)");
				} else if ( thisIsPubPreClaim ) {
					console.log("                  Publishing Point - PRE-CLAIMED (SPENT)");
				} else if ( thisIsExpandable ) {
					console.log("                  EXPANDABLE output  <SPENT>");
				} else if ( thisIsContinue ) {
					console.log("                  CONTINUE output    <SPENT>");
				} else if ( thisIsAskBid ) {
					console.log("                  ASKBID output      <SPENT>");
				} else if ( thisIsUpdate ) {
					console.log("                  UPDATE output      <SPENT>");
				} else if ( thisIsTransient ) {
					console.log("                  TRANSIENT output   <SPENT>");
				} else if ( thisIsBitGroup ) {
					console.log("                  BITGROUP output    <SPENT>");
				} else if ( thisIsAdminGroup ) {
					console.log("                  ADMINGROUP output  <SPENT>");
				} else if ( thisIsEBFRA ) {
					console.log("                  EBFRA output       <SPENT>");
				} else if ( thisIsPrep ) {
					console.log("                  BuilderPrep output <SPENT>");
				} else if ( thisIsDialog ) {
					console.log("                  Dialog output      <SPENT>");				}
			}

			if ( thisIsDialog ) {
				console.error("TAKE ACTION: for output " + i + ": may need to carry-forward most-recent OWNER pubKey, "
							+ "most-recent GUEST pubKey, and most-recent OUR p2pkh privkey. sent by "
							+ dialogSentBy)
			}
		} // contract output (not payout or change)
		console.log("------------------");
	} // each output

	const shizzleAddress = statei[0].address
	console.log("qFDTx(): shizzleAddress: " + shizzleAddress)

	if ( foundBuildable && foundFollowable ) {
		console.log("\nBTW: From this tx, you could SPEND (BUILD off of) an output, OR FOLLOW a SPENT output.")
		console.log("    Buildable indexes: " + buildable)
		console.log("    Followable indexes: " + followable)
	} else if ( foundBuildable ) {
		console.log("\nBTW: From this tx, you could SPEND an output (BUILD/GROW on 'X', CLAIM+PUBLISH on 'P', or PUBLISH on 'K'), etc.")
		console.log("    Buildable indexes: " + buildable)
	} else if ( foundFollowable ) {
		console.log("\nBTW: From this tx, you could FOLLOW a SPENT output.")
		console.log("    Followable indexes: " + followable)
	} else {
		console.log("\nHmm. Perhaps there are no CONTRACT outputs here? That happens at the finalPost of a line (Update, Transient, or BitGroup (and maybe Auction))")
		console.log("BTW: here's what statei[0] looks like: ", statei[0])
	}

	if ( input0Params.hostGuestMode === 1 || input0Params.hostGuestMode === 4 ) {
		console.log("Ah hah. hostGuestMode is " + input0Params.hostGuestMode + ", so, let's ALSO look at input #1 (base=0)...")
		if ( input1Params === '' ) {
			console.error("==> FINALLY getting input1Params. Let's get it NOW.")
			input1Params = getUsefulInputParams(inputChunks[1], hexByteToAscii( statei[0].mode ) );
		} else {
			console.error("==> Don't need to get input1Params. Already got it")
		}
		// get the publicKey used to fund this transaction


		if ( input0Params.hostGuestMode === 4 ) {
			if ( input2Params === '' ) {
				console.error("==> FINALLY getting input2Params. Let's get it NOW.")
				//NOTE: this is a guest-posting transient, so we ASSUME the third input is the funding. Technically, it could be ANY input
				input2Params = getUsefulInputParams(inputChunks[2], 'Z' );
			} else {
				console.error("==> Don't need to get input2Params. Already got it")
			}
			dialogMostRecentOwnerPubKey = input2Params.fundingPubKey
			console.warn("Since spawning a dialog, will capture the dialogMostRecentOwnerPubKey: "
						+ dialogMostRecentOwnerPubKey)
		}

		// The guest-post needs to be recorded too - distinct from the host
		console.warn("queryFetchDecodeTx(): Since this has a GUEST-POST, will now ALSO add this tx " +
						" with the GUEST shizzle's address too: " + statei[1].address);
		await addInternalTx(theDB, startingTxid, startingTxid, statei[1].address);

		//FIXME: ok that if we build a dialog off this, we don't add an extra address?
	}

	const limb = statei[0].namePath ?
						hexStringToAscii( statei[0].namePath )
					:
						'';

	if ( input0Params.content !== '' && input0Params.contentName !== '' ) {
		console.log("qFDTx(): We've got labeled content that we could store - label: "
				+ input0Params.contentName + "  with content of length " + input0Params.content.length)

		alert("Will save labeled content to db - IF appropriate (newer than existing)")

		// save content with key contentName - to new table
		// BUT: First check for any existing record.
		//      If it's already saved, check the associated shizzleAddress, and compare
		//      with THIS shizzleAddress. Only the LATEST should be saved
		//FIXME: maybe we'd save the EARLIEST shizzleAddress, and the latest.
		//FIXME: eventually, we only call this while travelling the path, towards the tip,
		//       and we need not worry if this is an AUTHENTIC tx

		// asset, contentName, shizzleAddress,    (ownerCount?,) content

		// Retrieve asset/contentName - see if it already exists, and what its address is
		// replace with new content if THIS address is "greater" (newer)

		const blockNum = Buffer.from(statei[0].blockNum, 'hex').readUInt32LE(0)
		const label = input0Params.contentName
		const content = input0Params.content
		const record = await findContentFromLimbAndLabel(theDB, statei[0].ownerCount, limb, label)
		if ( record !== null ) {
			console.log("qFDTx(): FOUND existing record for limb " + limb + ", label " + label + ": ", record)

			// compare its address with new address

			alert("Comparing stored address " + record.address + " with candidate address " + statei[0].address)

			const oldFields = record.address.split('/')
			const newFields = statei[0].address.split('/')

			console.log("old fields: ", oldFields)
			console.log("new fields: ", newFields)

			let replace = false
			if ( oldFields[3] < newFields[3] ) {
				console.log("candidate address is newer in field 3: " + newFields[3])
				replace = true
			} else if ( oldFields[3] === newFields[3] ) {
				if ( oldFields[4] < newFields[4] || (!oldFields[4] && newFields[4])) {
					console.log("candidate address is newer in field 4: " + newFields[4])
					replace = true
				} else if ( oldFields[4] === newFields[4] ) {
					if ( oldFields[6] < newFields[6] || (!oldFields[6] && newFields[6])) {
						console.log("candidate address is newer in field 6: " + newFields[6])
						replace = true
					} else if ( oldFields[6] === newFields[6] ) {
						if ( oldFields[8] < newFields[8] || (!oldFields[8] && newFields[8])) {
							console.log("candidate address is newer in field 8: " + newFields[8])
							replace = true
						} else if ( oldFields[8] === newFields[8] ) {
							if ( oldFields[9] < newFields[9] || (!oldFields[9] && newFields[9])) {
								console.log("candidate address is newer in field 9: " + newFields[9])
								replace = true
							} else if ( oldFields[9] === newFields[9] ) {
								if ( oldFields[10] < newFields[10] || (!oldFields[10] && newFields[10])) {
									console.log("candidate address is newer in field 10: " + newFields[10])
									replace = true
								} else if ( oldFields[10] === newFields[10] ) {
									if ( oldFields[11] < newFields[11] || (!oldFields[11] && newFields[11])) {
										console.log("candidate address is newer in field 11: " + newFields[11])
										replace = true
									} else if ( oldFields[11] === newFields[11] ) {
										if ( oldFields[12] < newFields[12] || (!oldFields[12] && newFields[12])) {
											console.log("candidate address is newer in field 12: " + newFields[12])
											replace = true
										} else {
											console.log("candidate address is OLDER in field 12: " + newFields[12])
										}
									}
								} else {
									console.log("candidate address is OLDER in field 10: " + newFields[10])
								}
							} else {
								console.log("candidate address is OLDER in field 9: " + newFields[9])
							}
						} else {
							console.log("candidate address is OLDER in field 8: " + newFields[8])
						}
					} else {
						console.log("candidate address is OLDER in field 6: " + newFields[6])
					}
				} else {
					console.log("candidate address is OLDER in field 4: " + newFields[4])
				}
			} else {
				console.log("candidate address is OLDER in field 3: " + newFields[3])
			}

			//FIXME: and if it's a BitGroup? No labels, so, prob don't care


			if ( replace ) {
				console.log("REPLACING record. lid is " + record.lid)
				await addLabeledContent(theDB, false, statei[0].ownerCount, limb, label, shizzleAddress, blockNum, content, record.lid)
			} else {
				console.log("leaving record unchanged")
			}
		} else {
			console.log("qFDTx(): NO record found for limb " + limb + ", label " + label)
			console.log("   SAVING labeled content (label " + label + "), for owner #" + statei[0].ownerCount + " of asset " + limb + ", with address of " + shizzleAddress)
			await addLabeledContent(theDB, true, statei[0].ownerCount, limb, label, shizzleAddress, blockNum, content)
		}
	}

	// if this is a Quarterly (or update?) with 'content', check for profile
	// info in the content
	let shzlProfile = null
	if ( input0Params.content !== '' /* && is a quarterly */) {
		try {
			// copied from analyzeContentPrefixFlag(), a little further down
			let asciiContent = Buffer.from( input0Params.content, 'hex' ).toString('ascii')
			const contentJson = JSON.parse( asciiContent )
			console.warn("The content has a JSON-parseable format: ", input0Params.content)

			if ( contentJson.hasOwnProperty('shzlProfile') || contentJson.hasOwnProperty('avatar')) {
				if ( contentJson.hasOwnProperty('shzlProfile') )
					console.log("has shzlProfile")

				if ( contentJson.hasOwnProperty('avatar') )
					console.log("has avatar")

				console.warn("full contentJson: ", contentJson)
				console.warn("shzlProfile info: ", contentJson.shzlProfile)
				shzlProfile = contentJson.shzlProfile

				alert("We've detected a 'shzlProfile', or 'profileProps', or 'avatar' JSON key in the content")

				await saveTxsWithProfileInfo(theDB, limb, statei[0].ownerCount, startingTxid, shizzleAddress)
			} else {
				console.warn("Although the content is in a JSON format, it doesn't have a 'shzlProfile' key")
				alert("DEBUG: check the console for content format")
			}
		} catch (error) {
			console.warn("The content does not have a JSON-parseable format")
		}
	}

	const fundingPK = input2Params === '' ? '' : input2Params.fundingPubKey
	const newDecodedTx = {
		//state: statei[0],		//redundant It's already contained in outputStates[0]
		outputStates:     statei,
		address:          shizzleAddress,
		confirmedOutputs: confirmed,
		bogusOutputs:     bogus,
		limbName:         limb,
		buildable:        buildable,
		followable:       followable,
		unspendable:      unspendable,
		foundBuildable:   foundBuildable,
		foundFollowable:  foundFollowable,

		input0Params:     input0Params,			// content, txid, labels, pcode  (for tx, and hostTx)
		input0PrevTxId:   prev0TxId,
		input1PrevTxId:   prev1TxId,			// might only be useful for guest posts (Transients only)
		input1Params:     input1Params,			// in host/guest mode, this would be the guest's input params

		input2Params:     input2Params,
		dialogNamePath:   dialogNamePath,       // carried-forward
												// TODO: carry-forward most-recent owner PKey, guest PK, and OUR p2pkh priv
		dialogSentBy:     dialogSentBy,
		fundingPK:        fundingPK,    // currently copied from input2Params.fundingPubKey
		dialogMostRecentOwnerPubKey:    dialogMostRecentOwnerPubKey,
		dialogMostRecentVisitorPubKey: dialogMostRecentVisitorPubKey,
		//rawOutputs: rawOutpushzlProfilets				// has both the .scripts, and .values

		shzlProfile:      shzlProfile,
	}

	// here we analyze the contentPrefixFlag ONLY to see if there's a ipv4 mapping
	const ip4JumpMapping = analyzeContentPrefixFlag(newDecodedTx).ip4JumpMapping
	if ( ip4JumpMapping !== '' ) {
		console.warn("qFDTx(): this NEW tx defines an ipv4 mapping. We may need to apply it.")

		// get address of CURRENT ip4JumpMapping, and compare it to THIS address
		// If this one is NEWER, update the mapping
		await checkToUpdateIP4Mapping(theDB, newDecodedTx.address, ip4JumpMapping)
	}

	if ( startingTxIsBogus ) {
		let bad = false
		for (let i = 0; i < numOutputs; i++ ) {
			if ( newDecodedTx.bogusOutputs[i] === false ) {
				console.error("WOOPS. we've got output " + i + " as not bogus, but base tx IS BOGUS: " + startingTxid)
				bad = true
			}
		}
		if ( bad ) {
			console.log("   And here's the decodedTx: ", newDecodedTx)
			throw new Error("BAD LOGIC")
		}
	}

	// Saving the decodedTx means we won't have to query for it (from a provider), and decode it again
	await addDecodedTx(theDB, startingTxid, newDecodedTx);

	// If there's no shizzle address, it's too early to add a Dangling tx record.
	// This may be at the root of the shizzle tree
	if ( foundBuildable && statei[0].address && statei[0].mode !== '44' ) {
		console.log("queryFetchDecodeTx(): we found an UNSPENT output, so, creating a DANGLING tx record for address " + statei[0].address)
		await addDanglingPath(theDB, hexStringToAscii( statei[0].namePath ), startingTxid, statei[0].ownerCount, statei[0].address)
		let scriptHashes = []
		let addresses = []
		let limbs = []
		let modes = []
		for ( let z = 0; z < buildable.length; z++ ) {
			scriptHashes[z] = null
			if ( buildable[z] === z ) {
				scriptHashes[z] = statei[z].scriptHash
				addresses[z]    = statei[z].address
				modes[z]        = hexByteToAscii( statei[z].mode )
				let namePath = statei[z].namePath
				if ( modes[z] === 'D' ) {
					console.log("special processing for Dialog:")
					console.warn("DIALOG output. have namePath components already ? (Dialog at output" + z + "):  owner: " + statei[z].ownerName + ",  visitor: " + statei[z].visitorName)
					console.error("MAYBE we could do this in shizzleBuilder's decodeDialogScriptsToState(). OR for some OTHER IDB tab. IS a Dialog utxoTab entry EVEN USEFUL?")
					namePath = statei[1].namePath + '2D' + statei[0].namePath
					console.error("giving namePath to dialog (but maybe not needed anymore)?: " + namePath)
					console.warn("actually, we may still need this loop here regardless. For calling addUTXO(), below.")

//FIXME: owner count? setup that too
				}
//xxxxx
				console.warn("prepping for utxo record. output " + z
						+ " of tx " + startingTxid + ". namePath: " + namePath)
				limbs[z]        = hexStringToAscii( namePath )
				console.warn("   adding a UTXO for address " + addresses[z] + ", outIdx " + z)
			} else {
				console.error("NOT prepping for utxo record for output " + z + " - it's NOT buildable  <===")
			}
		}
//FIXME: the owner # isn't correct
//       It depends on whether this is an append (and at what level),
//       or anything else
//       If this is a guestPost, or Dialog, the owner count would depend on WHICH output we're considering
//       FIXME: make the ownerCount parameter an ARRAY - like limbs
		await addUTXO(theDB, limbs,
						startingTxid,
						statei[0].ownerCount,
						addresses, scriptHashes, modes)
	}

	return newDecodedTx
} // qFDTx()

// Uses the 'settingsAndAcksTab' IDB table to store txs (and their shizzle path)
// which contain profile/avatar info
export async function saveTxsWithProfileInfo(db, domain, ownerCount, startingTxid, shizzleAddress) {
	const label = 'txsWithProfileInfoFor_' + domain + ':' + ownerCount
	console.warn("saveTxsWithProfileInfo(): using label " + label)

	const profileTxsJsonStr = await readSetting(db, label)

	console.log(" ===> profileTxsJson: ", profileTxsJsonStr)

	let profileTxs = []
	if ( profileTxsJsonStr !== null ) {
		profileTxs = JSON.parse( profileTxsJsonStr.value )
	}

	profileTxs.push( {txId: startingTxid, path: shizzleAddress})

	const newProfileTxsJsonStr = JSON.stringify( profileTxs )
	await saveSetting(db, label, newProfileTxsJsonStr)
}
export async function getTxsWithProfileInfo(db, domain, ownerCount) {
	const label = 'txsWithProfileInfoFor_' + domain + ':' + ownerCount
	console.warn("getTxsWithProfileInfo(): using label " + label)

	const profileTxsJsonStr = await readSetting(db, label)

	console.log(" ===> got profileTxsJson: ", profileTxsJsonStr)

	let profileTxs = []
	if ( profileTxsJsonStr !== null ) {
		profileTxs = JSON.parse( profileTxsJsonStr.value )
	}

	return profileTxs
}

/**
 * Check if content/payload begins with special escape flag.
 * That can be used to signal that special processing is required:
 *   decryption
 *   special parsing (FUTURE)
 *
 * @param {*} txState
 * @returns
 */
export function analyzeContentPrefixFlag(txState) {

    console.log("analyzeContentPrefixFlag(): ", txState)

    let needToDecrypt = false
//FIXME: maybe need to handle DIALOG spawns.
//       And one day guest-posts - if they ever contain encryption
    console.error("IMPLEMENT ME: one day guest-posts might be encrypted (on input1). SNav analyzeContentPrefixFlag()")
    let inputToUse = txState && txState.input0Params;
    let contentToPresent = ''
    let contentAltered = false
    let ip4JumpMapping = ''
    let unrecognizedFormat = false

    let contentAttached = false
    let attachedContent = null

	let referencedMediaTxId = null

	let contentType = ''

    if ( inputToUse && typeof inputToUse !== 'undefined' ) {
      // If the first 4 bytes are PAYLOAD_ESCAPE_FLAG ('*$^&'), that's a special flag.
      // If they are, check the next two bytes. They'll signal how the
      // content should be treated.
      //
      // If the next two bytes are ascii 'EC', then the remainder of the
      // content should be interpreted as AES-encrypted, with the
      // first 16 bytes being the IV (initialization vector)
      // The shared encryption key is obtained from the Eliptic Curve Diffie-Helman
      // using the most-recently-used (BSV) funding keys of the sender and receiver.
      console.warn("analyzeContentPrefixFlag(): check for special content flag...")

      let asciiContent = Buffer.from( inputToUse.content, 'hex' ).toString('ascii')
                         //hexStringToAscii( inputToUse.content )

      if ( asciiContent.startsWith( PAYLOAD_ESCAPE_FLAG ) ) {

        // If it starts with the escape flag, scan the next two bytes for the TYPE
        if ( asciiContent.startsWith( PAYLOAD_ESCAPE_FLAG + 'IA' ) ) {
            console.warn("analyzeContentPrefixFlag(): SPECIAL flag + IA! (Image Attached) Let's extract it...")

            // extract the LENGTH of the attachment
            const fourByteLengthOfAttachment = inputToUse.content.substring( 12, 12+8 )
            const attachmentLen = parseInt(fourByteLengthOfAttachment, 16)
            console.warn(" LENGTH of attachment: " +  attachmentLen);

            // extract the attachment
            contentAltered = true
            contentAttached = true
            attachedContent = inputToUse.content.substring(20, 20 + attachmentLen*2)

            // extract the REMAINDER (the TEXT portion of the content)
            //FIXME: we convert to string here, but later, we probably convert to hex, then back again?
            contentToPresent = Buffer.from( inputToUse.content.substring(20 + attachmentLen*2), 'hex' ).toString('utf8')
                               //hexStringToAscii( inputToUse.content.substring(20 + attachmentLen*2) )


            if ( asciiContent.startsWith( PAYLOAD_ESCAPE_FLAG + 'IAH' ) ) {
                contentType = 'html'
            //} else if ( asciiContent.startsWith( PAYLOAD_ESCAPE_FLAG + 'IAJ' ) ) {
            //    contentType = 'json'
            } else {
                contentType = 'plain'
            }

        } else if ( asciiContent.startsWith( PAYLOAD_ESCAPE_FLAG + 'MR' ) ) {
            console.warn("analyzeContentPrefixFlag(): SPECIAL flag + MR! (Media Reference) Let's extract the already-published txId...")
            const thirtyTwoByteTxId = inputToUse.content.substring( 12, 12+64 )

            console.warn("analyzeContentPrefixFlag(): extracted media reference to txId " + thirtyTwoByteTxId)

            contentAltered = true
            referencedMediaTxId = thirtyTwoByteTxId

            // extract the REMAINDER (the TEXT portion of the content) - skipping past the 6-byte (12 hex chars) flag, and 32-byte (64 hex chars) txId
            //FIXME: we convert to string here, but later, we probably convert to hex, then back again?
            contentToPresent = Buffer.from( inputToUse.content.substring(76), 'hex').toString('utf8')
                               //hexStringToAscii( inputToUse.content.substring(76) )


            if ( asciiContent.startsWith( PAYLOAD_ESCAPE_FLAG + 'MRH' ) ) {
                contentType = 'html'
            //} else if ( asciiContent.startsWith( PAYLOAD_ESCAPE_FLAG + 'MRJ' ) ) {
            //    contentType = 'json'
            } else {
                contentType = 'plain'
            }

        } else if ( asciiContent.startsWith( PAYLOAD_ESCAPE_FLAG + 'EC' ) ) {
          console.warn("analyzeContentPrefixFlag(): SPECIAL flag + type!!! ECDH - AES encrypted. Let's decrypt...")

          needToDecrypt = true

          //NOTE: this is a HEX STRING. In other cases we stick with ascii, IIRC
          contentToPresent = inputToUse.content.substring(12)
          contentAltered = true


          if ( asciiContent.startsWith( PAYLOAD_ESCAPE_FLAG + 'ECH' ) ) {
              contentType = 'html'
          } else if ( asciiContent.startsWith( PAYLOAD_ESCAPE_FLAG + 'ECJ' ) ) {
              contentType = 'json'
          } else {
              contentType = 'plain'
          }

        } else if ( asciiContent.startsWith( PAYLOAD_ESCAPE_FLAG + 'i4' ) ) {
          console.warn("Special Case ( PAYLOAD_ESCAPE_FLAG + 'i4'): Defines an ip4 'ShizzleJump' mapping")
          const fourByteIP4Address = inputToUse.content.substring( 12, 12+8 ) // .content is in ascii hexadecimal (double-length)
          console.warn("  If this is the LATEST tx, we'll map the jump to ip4 address: " + fourByteIP4Address)
          contentAltered = true
          ip4JumpMapping = inputToUse.content.substring(12, 12+8) // 8 ascii hex chars representing 4 bytes of IPv4 addressing
          //FIXME: verify it's ALL hexadecimal, and MAYBE just 4 bytes?
          //       OR, allow more content to be included? hmm. prob not

          contentToPresent = '' //inputToUse.content.substring(20)

        } else if ( asciiContent.startsWith( PAYLOAD_ESCAPE_FLAG + 'i6' ) ) {
          console.warn("Special Case ( PAYLOAD_ESCAPE_FLAG + 'i6'): Defines an ip6 'ShizzleJump' mapping")
          const sixteenByteIP6Address = inputToUse.content.substring( 12, 12+32 ) // .content is in ascii hexadecimal (double-length)
          console.warn("  If this is the LATEST tx, we'll map the jump to ip6 address: " + sixteenByteIP6Address)
		  contentAltered = true
          contentToPresent = ''
          //FIXME: verify it's ALL hexadecimal, and MAYBE just 16 bytes?
          //       OR, allow more content to be included? hmm. prob not
          //FIXME: how to test?
        } else if ( asciiContent.startsWith( PAYLOAD_ESCAPE_FLAG + 'H' ) ) {
            contentType = 'html'
        } else if ( asciiContent.startsWith( PAYLOAD_ESCAPE_FLAG + 'J' ) ) {
            contentType = 'json'
			//FIXME: we prob shouldn't combine this with certain others: MR, IA
        } else if ( asciiContent.startsWith( PAYLOAD_ESCAPE_FLAG + '*$' ) ) {
          // Any content that wants to start with PAYLOAD_ESCAPE_FLAG ('*$^&'), and not be interpretted as a special type,
          // must signal so with the use of ascii sequence '*$' as bytes 5-6.
          console.warn("Special Case ('*$^&*$'): content escape flag was a rare case - starting with PAYLOAD_ESCAPE_FLAG ('*$^&'). Skipping the first 4 bytes of content.")
          contentToPresent = Buffer.from( inputToUse.content, 'hex' ).toString('utf8').substring(4)
                             //asciiContent.substring(4)
          contentAltered = true
          contentType = 'plain'
          //FIXME: now what?
        } else if ( asciiContent.startsWith( PAYLOAD_ESCAPE_FLAG + '^&') ) {
          console.warn("Special Case ( PAYLOAD_ESCAPE_FLAG + '^&'): Reserved for 4-byte Type flag")
          const fourByteTypeHex = Buffer.from(asciiContent.substring(6,9)).toString('hex')
          console.warn("Special Case: 4-byte type at bytes 6-9 (base-0): " + fourByteTypeHex )
          console.error("              This case is currently RESERVED, and not yet in use. Regardless, we're skipping the first 10 bytes of content/payload.")
          contentToPresent = Buffer.from( inputToUse.content, 'hex' ).toString('utf8').substring(10)
                             //asciiContent.substring(10)
          contentAltered = true
		  unrecognizedFormat = true
        } else {
          // all bets are off. The two-byte 'type' following the special 4-byte PAYLOAD_ESCAPE_FLAG ('*$^&')
          // isn't recognized. Proceed without special treatment
          console.error("CONTENT ERROR: unrecognized pre-fix escape string sequence: 0x" + Buffer.from(asciiContent.substring(0,6)).toString('hex'))
          unrecognizedFormat = true
        }
      } else {
        console.log("analyzeContentPrefixFlag(): content does NOT start with escape flag")
        contentType = 'plain'
      }
    }

    return {

	  //NOTE: .alteredContent is something that's introduced in shizzleView's processSearchResults()

      contentAltered:   contentAltered,      // if this is true, caller should use .contentToPresent
      contentToPresent: contentToPresent,    // stripped-down content
      contentAttached:	contentAttached,	 // if an Image is Attached (flag+IA+len+attach+normalContent)
      attachedContent:  attachedContent,

      referencedMediaTxId: referencedMediaTxId, // if referencing an already-published a/v recording (txId)

      needToDecrypt:    needToDecrypt,
      inputToUse:       inputToUse,   //FIXME: not sure if this is needed, or even valid

      ip4JumpMapping:   ip4JumpMapping,
      unrecognized:     unrecognizedFormat,

	  contentType:      contentType,
    }
} // analyzeContentPrefixFlag()

export async function checkIP4Mapping(theDB, domain, ownerCount) {
	return await getIP4Mapping(theDB, domain, ownerCount)
}

async function checkToUpdateIP4Mapping(theDB, txAddress, ip4JumpMapping) {
	const addressParts = txAddress.split('/')
	console.warn("Part of address: ", addressParts)
	const domain = addressParts[2]
	const ownerCount = addressParts[3]
	console.warn("  domain: ", domain)
	console.warn("  ownerCount: ", ownerCount)

	return await maybeUpdateIP4Mapping(theDB, domain, ownerCount, txAddress, ip4JumpMapping)
}

export async function findContent(theDB, ownerCount, limb, label) {
	return await findContentFromLimbAndLabel(theDB, ownerCount, limb, label)
}

export function parseTheOutputs(rawOutputs, silent) {
	return parseOutputs(rawOutputs, silent)
}
export function parseTheInputs(rawInputs, silent) {
	return parseInputs(rawInputs, silent)
}

class ScriptHashNotFoundError extends Error {
	constructor(message) {
	  super(message);
	  this.name = "ScriptHashNotFoundError";
	}
}

// WARNING: this function will throw if using Bitails, AND scriptHashesToQuery is empty
async function performBulkQueryOfUTXOs(txid, scriptHashesToQuery, mapsToTxoIndex, weKnowThisWasSpentYetDontHaveRecord, forceWoC = false) {
	console.log("pBQoUs(): numOutputs = " + weKnowThisWasSpentYetDontHaveRecord.length)

	console.log("performBulkQueryOfUTXOs(): we're ready to bulk-query the provider for this Tx's UTXOS: ", scriptHashesToQuery)
	try {
		console.log("pBQoUs(): mapsToTxoIndex has length " + mapsToTxoIndex.length)
		console.log("pBQoUs(): mapsToTxoIndex: ", mapsToTxoIndex)
		console.log("pBQoUs(): mapsToTxoIndex: will look at outputs from ", mapsToTxoIndex[0] + " to " +  mapsToTxoIndex[mapsToTxoIndex.length - 1])

		let sHTQ // = [...scriptHashesToQuery]
		let mTTI // = [...mapsToTxoIndex]
		let sHTQ2 = null
		let mTTI2 = null

		// If we have more than 20 scripts/outputs, break it
		// into TWO seperate queries (arrays)
		if ( scriptHashesToQuery.length > 20 ) {
			sHTQ = scriptHashesToQuery.slice(0, 20)
			mTTI = mapsToTxoIndex.slice(0,20)

			sHTQ2 = scriptHashesToQuery.slice(20)
			mTTI2 = mapsToTxoIndex.slice(20)
		} else {
			sHTQ = scriptHashesToQuery
			mTTI = mapsToTxoIndex
		}

		//WARNING: one, or all of the providers may have an issue with querying
		//         more than 20 scripthashes simultaneously
		const results = await getBulkUnspentScripts( sHTQ, txid, mTTI, forceWoC )

		console.log("performBulkQueryOfUTXOs(): bulk results for txId " + txid + ": ", results)

		for ( let j = 0; j < results.length; j++ ) {
			console.log("result " + j + " for scripthash " + results[j].script + ":")
			console.log("    any error?: " + results[j].error)
			console.log("    unspent: ", results[j].unspent)
//FIXME: check error field
			const actualIndex = mapsToTxoIndex[j]
			if ( results[j].unspent.length === 0 ) {
				console.log("    THAT WAS SPENT - but note, we need to calc the real index")
				console.log("      mapsToTxoIndex[" + j + "] = " + actualIndex )
				weKnowThisWasSpentYetDontHaveRecord[ actualIndex ] = true
			} else {
				console.log("    That is still unspent - but note, we'd need to calc the real index")
				console.log("      mapsToTxoIndex[" + j + "] = " + actualIndex )
				// setting to false means we won't bother requerying a provider later
				weKnowThisWasSpentYetDontHaveRecord[ actualIndex ] = false
			}
		}

		if ( scriptHashesToQuery.length > 20 ) {
			console.error("SECOND BULK BATCH QUERY of unspent scripts/outputs (after quick sleep). LENGTH: ", sHTQ2.length)
			await mySleep(80, "sleep at performBulkQueryOfUTXOs")
			const results2 = await getBulkUnspentScripts( sHTQ2, txid, mTTI2, forceWoC )

			console.log("performBulkQueryOfUTXOs(): SECOND bulk results for txId " + txid + ": ", results2)

			for ( let j = 0; j < results2.length; j++ ) {
				console.log("result2 " + j + " for scripthash " + results2[j].script + ":")
				console.log("    any error?: " + results2[j].error)
				console.log("    unspent: ", results2[j].unspent)
	//FIXME: check error field
				const actualIndex = mapsToTxoIndex[j+20]
				console.warn("  SECOND BULK. actualIndex: " + actualIndex)
				if ( results2[j].unspent.length === 0 ) {
					console.log("    THAT WAS SPENT - but note, we need to calc the real index")
					console.log("      mapsToTxoIndex[" + (20+j) + "] = " + actualIndex )
					weKnowThisWasSpentYetDontHaveRecord[ actualIndex ] = true
				} else {
					console.log("    That is still unspent - but note, we'd need to calc the real index")
					console.log("      mapsToTxoIndex[" + (20+j) + "] = " + actualIndex )
					// setting to false means we won't bother requerying a provider later
					weKnowThisWasSpentYetDontHaveRecord[ actualIndex ] = false
				}
			}
		}

	} catch (error) {
		console.error("error performing bulk query: ", error)
		printError( error )

		alert("trouble performing a bulk query. Will re-throw this error")
		throw error
	}

	if ( scriptHashesToQuery.length > 20 ) {
		console.warn("DONE with multi-query: full results: ", weKnowThisWasSpentYetDontHaveRecord)
	}

	return weKnowThisWasSpentYetDontHaveRecord
}

/**
 * Examine/update outputs for spends
 *
 * @param {*} theDB
 * @param {*} startingTxid
 * @param {*} decodedTx
 * @param {*} weCareOnlyAboutThisPath   a potential optimization
 */
async function checkDecodedTxOutputs(theDB, startingTxid, decodedTx, forAnAppend = false,
									weCareOnlyAboutThisPath = null) {

	const startingTxIsBogus = startingTxid.startsWith('11111111111111111111111111111111');
	const numOutputs = decodedTx.outputStates.length
	console.log("checkDecodedTxOutputs(): we're going to check on " + numOutputs + " outputs " +
				"of tx " + startingTxid)

//FIXME: better way to initialize this? <===============
	let weAlreadyKnowThisWasSpentYetDontHaveRecord = []
	for (let o = 0; o < numOutputs; o++ ) {
		weAlreadyKnowThisWasSpentYetDontHaveRecord[ o ] = null
	}

	//BUT: let's see if we can avoid LOTS of queries - if it's an append tx
//FIXME: put this in a function
	if ( forAnAppend && !startingTxIsBogus ) {
		console.warn("This outputs-check is for an APPEND (but not a bogus tx).")

		// let's remove any outputs for which:
		//     - the output value is 0
		//     - we KNOW have been spent
		let scriptHashesToQuery = []
		let mapsToTxoIndex = []
		let querySize = 0
		for (let i = 0; i < numOutputs; i++ ) {

			// a potential optimization
			const thisOutputPath = hexStringToAscii(decodedTx.outputStates[i].namePath)
			const thisPathLen = thisOutputPath.length
			const thisPathIsSubString = weCareOnlyAboutThisPath !== null
									 && weCareOnlyAboutThisPath.substring(0, thisPathLen) === thisOutputPath
			//console.log("thisOutputPath: " + thisOutputPath + "  isSubString: " + thisPathIsSubString)
			//console.log("  the substring: " + weCareOnlyAboutThisPath.substring(0, thisPathLen))
			if ( weCareOnlyAboutThisPath !== null && !thisPathIsSubString ) {
				console.warn("(target " + weCareOnlyAboutThisPath + ") We don't care about path " + thisOutputPath + " - output idx " + i)
				continue
			}

			const outputState = decodedTx.outputStates[i];
			const outValueLE = outputState.contractSatoshis
			const outValue = Buffer.from( outValueLE, 'hex' ).readUIntLE(0, 6)  //FIXME: should look at all 8 bytes
			if ( outValue === 0 ) {
				continue
			}

			let spentTxoRec = await doesDbHaveTxoSpend(theDB,
										startingTxid,
										i,
										findTxoFromTxidAndIndex,
										findNextTxFromSpentTxo);
			console.log("checkDecodedTxOutputs(): check for APPEND output " + i + " of " + startingTxid.substring(0,10) +
					"..." + startingTxid.substring(54, 64) + ", got back spent txo record of ", spentTxoRec)

			// record of spend, yet tx decode says buildable (not spent)?
			if ( spentTxoRec !== null && (decodedTx.buildable[i] === i || decodedTx.followable[i] !== i ) ) {
				console.error("checkDecodedTxOutputs(): append check found weird state. case 1")
			} else // NO record of spend, yet tx decode says spent
			  if ( spentTxoRec === null && (decodedTx.buildable[i] !== i || decodedTx.followable[i] === i ) ) {
				console.error("checkDecodedTxOutputs(): append check found weird state. case 2")
			} else if ( spentTxoRec === null ) {
				console.warn("checkDecodedTxOutputs(): no record of spend for i = " + i
							+ ", so, let's include it in a bulk query - scriptHash " + decodedTx.outputStates[i].scriptHash)
				scriptHashesToQuery[ querySize ] = decodedTx.outputStates[i].scriptHash
				mapsToTxoIndex[ querySize ] = i
				querySize++
			} else {
				console.warn("checkDecodedTxOutputs(): We see i = " + i
				+ " was spent, so, we'll leave it out of query")
			}
		}

		console.log("checkDecodedTxOutputs() querySize: ", querySize)
		// fails for BITAILS query if querySize is 0
		if ( querySize > 0 ) {
			console.warn("checkDecodedTxOutputs(): ready to bulk-query the provider for this Tx's UTXOS: ", scriptHashesToQuery)
			weAlreadyKnowThisWasSpentYetDontHaveRecord = await performBulkQueryOfUTXOs(startingTxid,
																						scriptHashesToQuery,
																						mapsToTxoIndex,
																						weAlreadyKnowThisWasSpentYetDontHaveRecord)
		}
	} // special bulk query for appends

	let alteredRecord = false

	// Note that an output could be SPENT, yet we don't have a spentTxoRecord for it
	//FIXME: so, explain what this would mean
	let foundUnspentOutput = false

	//FIXME: why (this first statement)?

	// If this gets set to true, it means there's an output which we have NOT YET FOLLOWED.
	// If that's true, we probably don't want to delete any dangling path record - at some
	// point we'll want to pick-up where we left off (a dangling path), and follow further.
	// If this stays set to false, it means that a dangling path record at this tx can be
	// removed, since we've already followed all of its contract outputs - creating new
	// dangling path records in the process.
	let anOutputHadNullSpentTxoRecord = false

	let alertNetworkConnection = false

	// Examine each output
	for (let i = 0; i < numOutputs; i++ ) {

		// a potential optimization
		const thisOutputPath = hexStringToAscii(decodedTx.outputStates[i].namePath)
		const thisPathLen = thisOutputPath.length
		const thisPathIsSubString = weCareOnlyAboutThisPath !== null
								 && weCareOnlyAboutThisPath.substring(0, thisPathLen) === thisOutputPath
		if ( weCareOnlyAboutThisPath !== null && !thisPathIsSubString ) {
			//console.warn("2 (target " + weCareOnlyAboutThisPath + ") We don't care about path " + thisOutputPath + " - output idx " + i)
			continue
		} else {
			//console.warn("2 (target " + weCareOnlyAboutThisPath + ") Apparently we care about path " + thisOutputPath + " - output idx " + i)
		}

		let outputNowSpent = false
		console.log("  Examining output #" + i + "  (base-0)")
		const outputState = decodedTx.outputStates[i];
		const outValueLE = outputState.contractSatoshis
		const outValue = Buffer.from( outValueLE, 'hex' ).readUIntLE(0, 6)  //FIXME: should look at all 8 bytes


		if ( outValue === 0 ) {
			console.log("    THIS OUTPUT HAS NO VALUE. Will assume it can't be spent.");

			//NOTE: if it's a finalPost, it's the result of a destroyed dangling path
			//FIXME: do we need to record anything?

		} else {
			console.log("    Output " + i + " has value of " + outValue);
			if ( startingTxIsBogus && decodedTx.bogusOutputs[i] !== true ) {
				console.warn("Woops. output " + i + " should be marked bogus (and confirmed?) BECAUSE PARENT IS BOGUS")
				decodedTx.bogusOutputs[i] = true
				alteredRecord = true
			}

			// We try to minimize/pace/cache queries for output spends
			let queryTheTxProvider = true
			let spentTxoRecord = await doesDbHaveTxoSpend(theDB,
														startingTxid,
														i,
														findTxoFromTxidAndIndex,
														findNextTxFromSpentTxo);
			console.log("checkDecodedTxOutputs(): for output " + i + " of " + startingTxid.substring(0,10) +
						"..." + startingTxid.substring(54, 64) + ", got back spent txo record of ", spentTxoRecord)


			// Not sure why this would happen, EXCEPT if we removeFadedTx(), MAYBE.
			// IF no record of spend, yet confirmedOutputs[i], fill-in that spentTxo record
			// FIXME: WELL, BTW, .confirmedOutputs[] is really DERIVED FROM spentTxo and unconfirmedSpendTab <-----
			// It's a duplication that could confuse matters
			if ( !startingTxIsBogus && spentTxoRecord === null && decodedTx.confirmedOutputs[i] ) {
				console.warn(" ")
				console.error("checkDecodedTxOutput(): NO RECORD of spend, yet confirmedOutput[" + i + "] is true. "
							+ "Will call findNextTx(). MAYBE it will addSpentTxo().")
				console.log("checkDecodedTxOutputs(): btw, here's the decodedTx for tx " + startingTxid + ": ", decodedTx)
				const nextTxid = await findNextTx(theDB, decodedTx.outputStates[i], startingTxid, i, true)
				console.warn(" --> BACK from findNextTx(). Next txid (output " + i + ") is " + nextTxid)
				console.warn(" ")
				if ( nextTxid !== null ) {
					console.log("  Trying AGAIN to get spentTxoRecord, since we think we may have just now added it...")
					spentTxoRecord = await doesDbHaveTxoSpend(theDB,
															startingTxid,
															i,
															findTxoFromTxidAndIndex,
															findNextTxFromSpentTxo);
					if ( spentTxoRecord === null ) {
						console.error("checkDecodedTxOutput(): We had hoped to have found a spentTxo record which shows that output "
									+ i + " of tx " + startingTxid + " now funds " + nextTxid)
					} else {
						console.warn("checkDecodeTxOutput(): Ok. Found the spentTxoRecord!")
					}
				} else {
					console.warn("checkDecodedTxOutput(): nextTxid is null. MAYBE we need to correct/clear confirmedOutputs[i]?")
					console.warn("cDTO(): here's the current decodedTx (we're about to alter): ", decodedTx)

					//FIXME: now that we've fixed a bug in spentTxoTabFunc(), this may never happen again
					//WELL: if we clear the DB, this may happen
					console.error("checkDecodedTxOutput(): nextTxid is null. Cleaning up: Clearing .confirmedOutputs[" + i + "] for tx " + startingTxid)
					decodedTx.confirmedOutputs[i] = false
					console.warn("CDTO: look at this closely NOW: ", decodedTx)

					alteredRecord = true
				}
			}


			if ( startingTxIsBogus && spentTxoRecord !== null && !decodedTx.confirmedOutputs[i] ) {
				console.warn("WOOPSY: the base tx is bogus, and we know we've spent output " + i +
							 ", but it's still marked not confirmed. FIXING IT.")
				decodedTx.confirmedOutputs[i] = true
				alteredRecord = true
			}
			if ( spentTxoRecord !== null && decodedTx.buildable[i] === i ) {
				console.warn("WOOPSY: we know we've spent output " + i + ", but it's still marked buildable. FIXING IT.")
			console.error("a decodedTx:", decodedTx)
			console.error("a0 decodedTx.buildable: ", decodedTx.buildable)
				decodedTx.buildable[i] = null        // maybe this is the only one that matters in the browser
			console.error("a1 decodedTx.followable: ", decodedTx.followable)
				decodedTx.followable[i] = i          //FIXME: not needed
			console.error("a2 decodedTx.foundFollowable: ", decodedTx.foundFollowable)
				decodedTx.foundFollowable = true     //FIXME: not needed
			console.error("a3")
				alteredRecord = true
				outputNowSpent = true
			}

//FIXME: need to use ? in && spentTxoRecord.txIdItFunds?.startsWith('11... below?
			console.warn("a spentTxoRecord:", spentTxoRecord)
			if ( spentTxoRecord !== null && spentTxoRecord.txIdItFunds.startsWith('11111111111111111111111111111111')
					&& decodedTx.bogusOutputs[i] === false ) {
				console.warn("WHOA: we know spend of output " + i + " is a Bogus Tx, but it's not marked as such. FIXING IT.")
				decodedTx.bogusOutputs[i] = true
				alteredRecord = true
				alert('NOTE: we\'re fixing a record (setting it as "bogus")')
			}

			console.log("checkDecodedTxOutputs(); i is " + i + " check confirmedOutputs[i] for decodedTx: ", decodedTx)

			// It was spent, but did it confirm? We track unconfirmed separately
			//FIXME: instead of checking .confirmedOutputs[] maybe we should be
			//       checking unconfirmedSpendTab  <-------
//FIXME: need to use ? in !decodedTx?.confirmedOutputs[i] && !decodedTx?.bogusOutputs[i] below?
			if ( spentTxoRecord !== null && !decodedTx.confirmedOutputs[i] && !decodedTx.bogusOutputs[i] ) {
				console.log("checkDecodedTxOutputs(): found a DB entry of a spend on outIndex " + i + ", but it's unconfirmed");
				const now = Math.floor(Date.now() / 1000);

				// Did we record the txo spend as UNCONFIRMED? If so, when did we last query the provider?
				// There's a chance it never confirmed, so, we should re-query
				const queryTime = await getUnconfirmedTxoSpendQueryTime(theDB, spentTxoRecord.txoKeyId);
				if ( queryTime !== null ) {
					console.log("checkDecodedTxOutputs(): now - queryTime: ", now - queryTime)

					//FIXME: parameterize this 90-seconds. Increase. (Most unconfirmed txo spends will confirm at
					//       some point). If it never confirms (fee problem?), it could take several days to disappear,
					//       so, this param could easily be 10 minutes, or 10 hours. But, we want to give SOME
					//       visibility into any issues with non-confirming transactions
					if ( now - queryTime > 90 ) {
						console.log("It's been MORE THAN 90 seconds since we've queried for this txo spend status "
								+ "(which was still unconfirmed), so, we'll query again")
					} else {
						console.log("We've recently queried the provider about this unconfirmed txo (for tx "
								+ startingTxid + "), so, let's leave it alone for now")
						queryTheTxProvider = false
					}
				} else {
					console.log("There's no record of this txo being unconfirmed, so, we'll assume it's confirmed (spent)")
					queryTheTxProvider = false
					decodedTx.confirmedOutputs[i] = true
					alteredRecord = true
				}
			}



			//NOTE: THIS, below, might be the heart of our latency - always checking on UTXOs
			//FIXME: it might make sense to avoid frequent checks. But what's the best way to avoid that?
			//       Should we keep track of most recent check time (and update the DB each time)?
			//FIXME: it might make sense to check on EBFRA Speakers very rarely (once a day)
			//       And maybe only within some special thread/job (like a periodicAssetCheck)


			// We mitigate the latency (for appends) by having used (above) a bulk
			// query of all the outputs for which we have no record of a spend.
			// Any UTXO found to not have been spent - by that bulk query mere
			// milliseconds ago - need not be re-queried now, individually
			if ( queryTheTxProvider && forAnAppend && !startingTxIsBogus ) {
				if ( weAlreadyKnowThisWasSpentYetDontHaveRecord[i] === false ) {
					// having called performBulkQueryOfUTXOs()

					// we just peeked, and found that this isn't actually spent.
					// There's no reason to re-query the provider for this output
					queryTheTxProvider = false
					console.warn("WE THOUGHT we were going to query the provider, but the "
								+"results of a (bulk) sneak-peak query told us not to "
								+"bother for output " + i)
					console.log("BTW: weAlreadyKnowThisWasSpentYetDontHaveRecord is ", weAlreadyKnowThisWasSpentYetDontHaveRecord)
				}
			}

			// NOTE: if we have no record of a spend, we'll query the provider EVERY time
				//NOTE: changed first && from ||
			if ( queryTheTxProvider && !decodedTx.confirmedOutputs[i] && !decodedTx.bogusOutputs[i] ) {

				try {
					console.log("checkDecodedTxOutputs(): db doesn't know of a spend for outIndex " + i
							+ ", OR, we haven't seen it confirm (or haven't checked recently), SO, check WOC...");
					console.log("cDTxO(): BTW: will check on scriptHash " + decodedTx.outputStates[i].scriptHash + ", and BTW, startingTxid is " + startingTxid)

					// it WAS unconfirmed. What about now?
					// unfortunately, we're getting back a value here which is the opposite logic ( .unconfirmed, instead of .confirmed )
					const newOutputStatus = await updateTxoConfirmedStatus(theDB, decodedTx.outputStates[i].scriptHash, startingTxid, spentTxoRecord,   i, decodedTx.outputStates[i]);
					if ( newOutputStatus.networkError ) {
						console.warn("cDTxO(): network error")
						alertNetworkConnection = true
					} else if ( newOutputStatus.spent ) {
						if ( !newOutputStatus.unconfirmed ) {
							alteredRecord = true
							decodedTx.confirmedOutputs[i] = true
							console.warn("checkDecodedTxOutputs(): UPDATING (setting to TRUE) confirmation status of output " + i + " of tx " + startingTxid)
						}
						if ( decodedTx.followable[i] !== i ) {
							console.warn("checkDecodedTxOutputs(): updating: setting FOLLOWABLE, and clearing buildable for output " + i + " of tx " + startingTxid)
							decodedTx.followable[i] = i
							decodedTx.buildable[i] = null
							alteredRecord = true
							outputNowSpent = true
						}
					}  else {
						console.log("checkDecodedTxOutputs() output " + i + " is NOT confirmed, so, technically we've FOUND an UNSPENT output")
						foundUnspentOutput = true
					}
				} catch ( err ) {
					if ( err instanceof ScriptHashNotFoundError ) {
						return 404
					} else {
						console.error("checkDecodedTxOutputs(): error while updating txo status: ", err)

						//FIXME: we see this, but can we return something so the UI thread
						//       can know to alert?
						throw err
					}
				}

				//FIXME: let's be more sophisticated. Each iteration/output doesn't necessarily result
				//       in a query to the provider, right? Some may have confirmed already, or we've
				//       put-off a query since it was recently made.

				// pace our queries to WoC - for each output of this tx
				// But, don't bother sleeping if this was the final output (no more potential queries to come)
				// BUT, it's only APPEND txs that have more than 3 outputs to query
				// Anything else need not be paced - they'd only have a max of 3 outputs,
				// so, querying for each in rapid succession wouldn't violate the current
				// max-queries-per-second.
				if ( forAnAppend && i !== (numOutputs - 1) ) {
					await mySleep(50, "sleep at checkDecodedTxOutputs")   // decreased from 330 on Oct 16, 2022 (while using Bitails)
											 // decreased from 310 on Oct 26
											 // reverted to 330 on Oct 27. We've been seeing Network Errors (for WoC)
											 // increased to 350 on Oct 27 (for WoC)
											 // DECREASE to 150, then 100, then 75, then 50 on 10/31/22
											 //   - since we now have simple provider management (alternating providers)
				}
			} else if ( spentTxoRecord === null ) { //FIXME: ELSE if?
				foundUnspentOutput = true
			}
			//FIXME: review just above, and just below. Consistent?

			if ( spentTxoRecord === null ) {
				console.log("checkDecodedTxOutputs(): NOTE: we don't yet know where this output goes (what it funds)")
				anOutputHadNullSpentTxoRecord = true
			}
		} // output had value assigned to it

		if ( outputNowSpent ) {
			console.warn("Output " + i + " was spent. REMOVING UTXO record with scriptHash of " + decodedTx.outputStates[i].scriptHash)
			removeUtxoByScriptHash(theDB, decodedTx.outputStates[i].scriptHash)
		}
	} // each output

	if ( alertNetworkConnection ) {
		alert("Network Error. Check your connection.")
	}

	// Review .buildable[] and .followable[] fields
	let foundBuildable = false
	let foundFollowable = false
	for (let j = 0; j < numOutputs; j++ ) {

		// check for foundBuildable, and foundFollowable
		if ( decodedTx.buildable[j] === j ) {
			foundBuildable = true
		}
		if ( decodedTx.followable[j] === j ) {
			foundFollowable = true
		}

		if ( foundFollowable && !decodedTx.foundFollowable ) {
			console.warn("checkDecodedTxOutputs(): NOTE: output " + j + " foundFollowable is changing to " + foundFollowable)
			if ( !alteredRecord ) {
				console.error("checkDecodedTxOutputs(): output " + j + " foundFollowable is changing, but alteredRecord isn't set. Setting it.")
				alteredRecord = true
			}
		}
		if ( foundBuildable && !decodedTx.foundBuildable ) {
			console.warn("checkDecodedTxOutputs(): NOTE: output " + j + " foundBuildable is changing to " + foundBuildable)
			if ( !alteredRecord ) {
				console.error("checkDecodedTxOutputs(): output " + j + " foundBuildable is changing, but alteredRecord isn't set. Setting it.")
				alteredRecord = true
			}
		}
	}

	decodedTx.foundFollowable = foundFollowable
	decodedTx.foundBuildable = foundBuildable

	if ( alteredRecord ) {
		console.log(" ")
		console.warn("checkDecodedTxOutputs(): updating record of decodedTx (more confirmed outputs?): ", decodedTx)
		await addDecodedTx(theDB, startingTxid, decodedTx);
		console.log("Updated")
		console.log(" ")

		// IF this is a dangling tx, AND the number of UNSPENT === 0, MAYBE delete
		if ( !foundUnspentOutput ) {
			console.log("checkDecodedTxOutputs(): we can't find an unspent output. (they've all been spent)")

			// BUT, if we don't have a record of HOW all of them were spent, then we shouldn't yet delete a dangling path record for this tx
			if ( anOutputHadNullSpentTxoRecord ) {
				console.log("We won't be deleting any dangling record of this tx yet. At least one output had a null spentTxoRecord.")
				//KEEP THIS FOR NOW. It was an alert
				console.error("At least one output hasn't yet been followed, so, we won't yet delete any dangling record of path " + decodedTx.outputStates[0].address)
			} else {
				console.log("Let's delete any dangling record of this tx (if it has a shizzle address).")
				if ( decodedTx.outputStates[0].address ) {
					if ( 0 !== await removeDanglingPath(theDB, decodedTx.outputStates[0].address) ) { //NOTE: we don't care about limb, owner
						//KEEP THIS FOR NOW. It was an alert
						console.error("NOTE: we finally removed a DANGLING path: " + decodedTx.outputStates[0].address)
					} // else that address was already removed from danglingPathsTab
				} else {
					alert("NOTE: there is NO shizzle address for this tx. We were looking to remove it from danglingPathsTab.")
				}
			}
		}
	}

	return decodedTx    // we've updated the .confirmedOutputs[] field ? some field?
}  // checkDecodedTxOutputs()

async function handleUnconfirmedSpentTxo(theDB, spentTxoRecord, startingTxid) {
	const now5 = Math.floor(Date.now() / 1000);
	console.log(" ")
//FIXME: add ? before .startsWith()?
	if ( spentTxoRecord.txIdItFunds.startsWith('11111111111111111111111111111111') ) {
		console.log("handleUnconfirmedSpentTxo(): NOT recording UNCONFIRMED BOGUS SPEND for txo " + spentTxoRecord.txoKeyId
				+ " - which funds " + spentTxoRecord.txIdItFunds)

		//FIXME: If it funds a BOGUS tx, but getScriptHashMinedBlockHeight returns 0,
		//       what does this mean?
		//       Doesn't it mean that the spentTxo is wrong?
		console.error("handleUnconfirmedSpentTxo(): for txid " + startingTxid +
					+ " we have a spentTxo record of ", spentTxoRecord
					+ " yet, it shows that it funds a bogus tx: " + spentTxoRecord.txIdItFunds)
		alert("handleUnconfirmedSpentTxo(): Please read the log. Did this really happen?")
		//FIXME: I don't this this could really happen (call getScriptHashMinedBlockHeight() with a bogus tx, and end-up here no less)
	} else {
		console.log("handleUnconfirmedSpentTxo(): Recording QUERY for UNCONFIRMED SPEND for txo " + spentTxoRecord.txoKeyId
				+ " - which funds " + spentTxoRecord.txIdItFunds)
		await recordQueryForUnconfirmedTxoSpend(theDB, spentTxoRecord.txoKeyId, now5)
	}
}

/**
 * Query the Tx provider - checking on confirmation status of a spend
 * ( called by queryFetchDecodeTx(), and checkDecodedTxOutputs() )
 *
 * NEW SIDE EFFECT: We now also attempt to create missing spentTxo record <-----<<
 *
 * @param {*} theDB
 * @param {*} scriptHash
 * @param {*} startingTxid
 * @param {*} spentTxoRecord
 * @returns true IF confirmed spend
 */
async function updateTxoConfirmedStatus(theDB, scriptHash, startingTxid, spentTxoRecord,   outputIndexI, outputStateOfI) {

	/*
	here's where we need to check (with provider) if it's confirmed or not.
	If not, make sure to record this query (update the query time)
	if confirmed, be sure to delete any record of unconfirmed
	*/
	let spent = false;
	let unconfirmed = false;
	let firstQueryYieldedNeg2 = false;

	console.log(" ")
	console.log(" -=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-")
	let txoStatus = await getScriptHashMinedBlockHeight(scriptHash, startingTxid)

	if ( txoStatus === -2 ) {
		console.warn("updateTxoConfirmedStatus(): output scripthash not found? remove 'faded' tx: " + startingTxid
				+ ", OR, just the spentTxoRecord? ", spentTxoRecord);

		console.error("We had more trouble than we had expected - checking for spend of an output of tx " + startingTxid + ". Let's de-escalate the problem, and not assume the worst.")

		console.warn("Note: we had queried for scriptHash " + scriptHash)
		//alert("We had more trouble than we had expected - checking for spend of an output of tx " + startingTxid + ". We're going to RETRY this query. It may yield a better result this time.")
		console.warn("We had more trouble than we had expected - checking for spend of an output of tx " + startingTxid + ". We're going to RETRY this query. It may yield a better result this time.")
		firstQueryYieldedNeg2 = true

		// TRY ONCE MORE - now that we've paused a bit
		// This may also use a different Tx Provider, and so yield a better result
		txoStatus = await getScriptHashMinedBlockHeight(scriptHash, startingTxid)

		// PONDER: is it worth retrying for OTHER results (-3)?
	}

//FIXME: if network error, maybe catch from gSHMBH(), and return networkError: true
	if ( txoStatus === -3 ) {
		if (firstQueryYieldedNeg2) {
			alert("Things may have gotten WORSE on this second try: -3")
			alert("There could be a network condition. You may need to retry this operation.")
		}
		return { networkError: true }
	} else if ( txoStatus === -2 ) {
		console.warn("updateTxoConfirmedStatus(): output scripthash not found? remove 'faded' tx: " + startingTxid
				+ ", OR, just the spentTxoRecord? ", spentTxoRecord);

		console.error("We had more trouble than we had expected - checking for spend of an output of tx " + startingTxid + ". Let's de-escalate the problem, and not assume the worst.")

		// At worst, if calling removeFadedTx() is overkill, we'll end-up
		// re-fetching the parent tx

		//TEMP: this may have been too harsh. We now have TWO providers, and it may take a little longer for the to be in sync.
		//      Let's just treat this like a NO spend
		if ( false ) {
			// Maybe a re-org. Maybe it was never actually mined. callback to cleanup.
			await removeFadedTx(startingTxid, theDB);

			//NOTE: checkDecodedTxOutputs(), or qFDTx() will catch this, and return 404
			throw new ScriptHashNotFoundError()
			//return 404;
		}

		console.warn("Note: we had queried for scriptHash " + scriptHash)

		if ( firstQueryYieldedNeg2 ) {
			console.warn("A second try yielded the SAME results.")
			alert("We might need to look at the providers. We might be getting INCONSISTENT results. Case A")
		} else {
			alert("We had more trouble than we had expected - checking for spend of an output of tx " + startingTxid + ". Let's de-escalate the problem, and not assume the worst.")
			//alert("We MIGHT need to look at the providers. We might be getting INCONSISTENT results. Case B")
			alert("Please take the time to RECORD this txId (to aid in any follow-on troubleshooting which might be helpful): ", startingTxid)
		}

	} else if ( txoStatus === -1 ) {    // NO spend
		if (firstQueryYieldedNeg2) {
			//alert("Things may have IMPROVED on this second try: -1 (no spend)")
			console.error("Things may have IMPROVED on this second try: -1 (no spend)")
		}

		// no spend
		console.log("updateTxoConfirmedStatus(): no spend found")

		//FIXME: we could ponder slowing these queries too
		//       recordQueryForUnspentTxo

	} else if ( txoStatus === 0 ) {   // UNCONFIRMED spend
		if (firstQueryYieldedNeg2) {
			console.warn("Things may have IMPROVED on this second try: 0 (unconfirmed spend)")
		}

		// unconfirmed spend
		spent = true
		unconfirmed = true
		if ( spentTxoRecord !== null ) {
			//FIXME: better name?
			handleUnconfirmedSpentTxo(theDB, spentTxoRecord, startingTxid)
		} else {
			console.warn("updateTxoConfirmedStatus(): UNCONFIRMED: we don't have a spentTxoRecord for output " + outputIndexI
						+ " of this tx (which the provider claims has been spent): " + startingTxid + ". We'll dig deeper.")

			// hopefully create a spentTxo record - side effect
			//FIXME: NOTE: a few lines up we queried the provider, to get a status.
			//             This next call may resulting in querying the provider for the scriptHash - to find the NEXT
			//             Maybe this is redundant. maybe we could re-use the results of the query above (pass it to findNextTx())
			//             Though, maybe thats an unwarranted complication.
			//FIXME: maybe we take some code from these next two functions, and be less roundabout.
			//FIXME: rename findNextTx()? maybe findAndRecordNextTx()
			const nextTxid = await findNextTx(theDB, outputStateOfI, startingTxid, outputIndexI)

			if ( nextTxid !== null ) {
				const newSpentTxoRecord = await doesDbHaveTxoSpend(theDB,
																startingTxid,
																outputIndexI,
																findTxoFromTxidAndIndex,
																findNextTxFromSpentTxo);
				if ( newSpentTxoRecord === null ) {
					console.error("updateTxoConfirmedStatus(): We had hoped to have found a spentTxo record which shows output "
								+ outputIndexI + " of tx " + startingTxid + " now funds " + nextTxid + " (and set it unconfirmed)")
				} else {
					console.warn("updateTxoConfirmedStatus(): NEW spentTxoRecord (output " + outputIndexI
					+ " of tx " + startingTxid + "): ", newSpentTxoRecord + " - now funds " + nextTxid + " (unconfirmed)")
					handleUnconfirmedSpentTxo(theDB, newSpentTxoRecord, startingTxid)
				}
			} else {
				console.error("updateTxoConfirmedStatus(): We had expected to find the next transaction - spending output "
								+ outputIndexI + " of tx " + startingTxid + ". The provider claims that output was spent (unconfirmed).")
			}
		}

	} else if ( txoStatus === 1 ) {  // CONFIRMED spend
		if (firstQueryYieldedNeg2) {
			console.warn("Things may have IMPROVED on this second try: 1 (confirmed spend)")
		}

		// confirmed spend
		spent = true;
		console.log("updateTxoConfirmedStatus(): It looks like the tx has confirmed. Let's DELETE the unconfirmedSpend")

		if ( spentTxoRecord !== null ) {
			// remove record that marks it as UNconfirmed. It's now confirmed.
			await removeUnconfirmedTxoSpend(theDB, spentTxoRecord.txoKeyId)
		} else {
			console.warn("updateTxoConfirmedStatus(): CONFIRMED: we don't have a spentTxoRecord for output " + outputIndexI
						+ " of this tx (which the provider claims has been spent): " + startingTxid + ". We'll dig deeper.")

			// hopefully create a spentTxo record - side effect
			// See notes for analogous unconfirmed case, above
			const nextTxid = await findNextTx(theDB, outputStateOfI, startingTxid, outputIndexI)

			if ( nextTxid !== null ) {
				const newSpentTxoRecord = await doesDbHaveTxoSpend(theDB,
																startingTxid,
																outputIndexI,
																findTxoFromTxidAndIndex,
																findNextTxFromSpentTxo);
				if ( newSpentTxoRecord === null ) {
					console.error("updateTxoConfirmedStatus(): We had hoped to have found a spentTxo record which shows output "
								+ outputIndexI + " of tx " + startingTxid + " now funds " + nextTxid + " (confirmed)")
				} else {
					console.warn("updateTxoConfirmedStatus(): NEW spentTxoRecord (output " + outputIndexI
								+ " of tx " + startingTxid + "): ", newSpentTxoRecord + " - now funds " + nextTxid + " (confirmed)")
				}
			} else {
				console.error("updateTxoConfirmedStatus(): We had expected to find the next transaction - spending output "
								+ outputIndexI + " of tx " + startingTxid + ". The provider claims that output was spent (confirmed).")
			}
		}
	} else {
		if (firstQueryYieldedNeg2) {
			alert("Things seem to have degraded further on this second try: " + txoStatus)
			alert("We might need to look at the providers. Case B")
		}

		throw new Error("10021: coding error. Invalid return value: " + txoStatus)
	}
	console.log("updateTxoConfirmedStatus(): returning  spent:       " + spent)
	console.log("updateTxoConfirmedStatus(): returning  unconfirmed: " + unconfirmed)
	console.log(" -=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-")
	console.log(" ")

	return	{ spent: spent,
			  unconfirmed: unconfirmed
			}

}  // updateTxoConfirmedStatus()


/** */
//SNIPPET copied from shizzleBuilder's findTheNextTx()

/**
 * Query provider for a SPEND on the given tx
 * @param {*} theState
 * @param {*} theTxId     (txId)
 * @returns txid or null
 */
export async function findNextTxFromProvider(theState, theTxId) {

	console.warn("NEW:  GENERIC code, below, to check for spend of DIALOG output")

	// let's query the provider for the tx that follows

	let foundTxId = null

	///////// COPIED DIRECTLY from shizzleBuilder findTheNextTx() (or some such)

	//NOTE: this uses WoC to find the next Tx (the one that spends an output)
	//console.log('\n\nLet\s check for txos - based on our output #0 scriptHash ' + theState.scriptHash)
	var txoObjects = await getSpentScript(theState.scriptHash);
	console.log("findNextTxFromProvider(): txoObjects: ", txoObjects);
	var numTxs = txoObjects.length;
	console.log("findNextTxFromProvider(): The scriptHash has this many TXOs: " + numTxs);
	let numFound = 0
	let parentHeight = 0;
	for ( var i = 0; i < numTxs; i++ ) {
		const candidate = txoObjects[i]
		if ( candidate.txId !== theTxId ) {
			console.log("  --> This one fits the bill: " + candidate.txId
					+ "  with height " + candidate.blockHeight)
			foundTxId = candidate.txId
			numFound++
		} else {
			console.log("  --> This one MATCHES the parent tx we're investigating: " + candidate.txId)
			console.log("(so, not it. It's the parent). parent tx has a height of: " + candidate.blockHeight)
			parentHeight = candidate.blockHeight
		}
	}

	console.warn("findNextTxFromProvider():  numFound: " + numFound + ",  foundTx: " + foundTxId)

	return foundTxId
}
/**/

export async function findNextTx(theDB, theState, theTx, outputIdx, weBelieveItShouldExist = false) {
	// call shared function while also passing two env-specific
	// db functions.
	// RECALL: there are two DB environments:
	//     console (uses sqlite3)
	//     web     (uses browser IndexedDB)

	return await findTheNextTx( theDB,
			findTxoFromTxidAndIndex, findNextTxFromSpentTxo, addSpentTxo,
			theState, theTx, outputIdx, weBelieveItShouldExist);

}

export async function findPrevTx(theDB, currentTxId) {
	return await findThePrevTx( theDB, currentTxId, findPrevFromCurrent);
}

/**
module.exports = {
	testGetBulkUnspent,

	buildShizzleLockingTx,
//	buildAppendLockingTx,
//  buildEbfraLockingTx,
//	buildContinueLockingTx,
//	buildUpdateLockingTx,
//	buildTransientLockingTx,         // this one needs interaction :(
//	buildBitGroupLockingTx,
//	buildAdministerGroupLockingTx,
//	buildAskBidLockingTx,

	buildOnBuilderPrep,
	buildOnAppend,
	buildOnClaimOrPublish,
	applyBuilderFeeReduction,
	announceBuilderFeeReductionAuth,
	buildOnAskBid,
	buildOnAnUpdate,
	buildOnATransient,
	buildOnDialog,
	buildOnABitGroup,
	buildOnTheAdminGroup,
	validateRabinPrivateKeys,

	generateRabinPrivateKeyPlus,

	getCurrentBlockInfo,
	getCurrentPriceInfo,
	findLatestContinue,
	findLatestContinue2,
	getClosestToLimb,
	findLatestAuction,

	openDB,
	getTxidFromAddr,
	getTheNextAddress,
	recursivelyDeleteDescendantSpends,
	queryTxs,
	queryLabels,

	checkDanglingPaths,
	queryFetchTx,
	decomposeTx,
	queryFetchDecodeTx,
	findContent,

	parseTheOutputs,
	parseTheInputs,

	registerASubscription,
	unRegisterASubscription,
	getAllSubscriptions,
	registerDomainOfMine,
	findADomainOfMine,
	findAllDomains

	createADialog,
	findADialog,
	findAllTheDialogs,
	addADialogTxo,
	getDialogTxo,

	saveSetting,
	readSetting,
	removeSetting,

	saveEncryptedP2PKHKey,
	getEncryptedKeyFromPwd,
	//getEncryptedKeyFromP2PKH,
	getP2PKHsJson,

	saveEncryptedKeys,
	markEncryptedKeyPkhAllocated,
	getEncryptedKeysFromPKH,
	getRabinsJson,
	getOfficialWalletJson,

	encryptData,
	decryptData,

	findNextTx,
	findPrevTx
}
/**/