import React from 'react';

import './index.css';

import { Button, Form, Icon, Modal, Select } from 'semantic-ui-react';

import { openDB, getAllVideoInfos } from './buildShizzle.js';

import Toast    from './toast';

import { fetchPublishedVideo } from './commonFuncs';

// Load an ALREADY-PUBLISHED video txId
//   (selected from IDB records with associated descriptions)

class VideoLibrary extends React.Component {
  constructor(props) {
    super(props);


    this.setVideoRef           = this.setVideoRef.bind(this);

    this.returnVideoToParent   = this.returnVideoToParent.bind(this);
    this.removeVideoFromParent = this.removeVideoFromParent.bind(this);

    this.handleVideoSelection  = this.handleVideoSelection.bind(this);
    this.viewThisTxId          = this.viewThisTxId.bind(this);

    this.copyVideoTxToClipboard= this.copyVideoTxToClipboard.bind(this);
    this.quickToast            = this.quickToast.bind(this);
    this.quickToastDone        = this.quickToastDone.bind(this);

    //FIXME: the purples are currently duplicated in index.css
    this.bshzPurple             = '#8313e2';
    this.bshzYellow             = "#fff300";
    this.bshzLtPurp             = '#e7d0fb';
    this.bshzLightYellow        = "#fffccc";

    this.state = {
                  videoRef: null,

                  videoToAdd: null,
                  videoURL: null,
                  mediaType: '',

                  videoLoaded: false,
                  videoDescription: null,

                  vidOptions: [],
                  justDescriptions: [],

                  showDropdown: true,   // used for a hacky way to clear the dropdown sort-of selection

                  showQuickToast: false,
                  toastText: '',
                  toastDuration: 800,
                  suppressLoader: false,

                };
  }

  componentWillUnmount() {
  }

  async componentDidMount() {

    const publishedVids = await getAllVideoInfos(await openDB())

    console.warn("BTW: publishedVids: ", publishedVids)

    const numPublishedVids = publishedVids.length
    const options = new Array( numPublishedVids )
    const justDescriptions = new Array( numPublishedVids )
    let existingDescription = ''

    for( let i=0; i< numPublishedVids; i++) {
      const rec = publishedVids[i]
      console.warn("videoLibrary cDM(): i = ", i)
      console.warn(" pV[" + i + "]: ", rec)

      options[i] = {  key: rec.txId,
                      text: <><b>{rec.txId.substring(0,8)}</b>... &nbsp;{rec.desc}</>,
                      value: rec.txId}
      justDescriptions[i] = rec.desc

      if ( this.props.existingVideoToAdd === rec.txId ) {
        console.log("existingVideoToAdd matches this option. Will set description...")
        existingDescription = rec.desc
      }
    }

    console.warn("Here are the options: ", options)

    this.setState({ vidOptions: options, justDescriptions: justDescriptions });

    if ( this.props.existingVideoToAdd !== null ) {
      console.warn("videoLibrary: We were passed an EXISTING filename: " + this.props.existingVideoToAdd)

      this.viewThisTxId( this.props.existingVideoToAdd )

      // load the already-specified video
      this.setState({ videoToAdd: this.props.existingVideoToAdd, videoDescription: existingDescription,  videoLoaded: true})
    }
  }  // componentDidMount()


  // NOTE: this is in vidRecorder, and maybe elsewhere (videoViewerModal?)   ??? REMOVE?
  setVideoRef(element) {
    console.warn("setVideoRef(): refV: ", element)
    console.warn("setVideoRef(): state.videoUrl: ", this.state.videoURL)

    this.setState({ videoRef: element }, () => {
      console.warn("state.videoToAdd:", this.state.videoToAdd)
      console.warn("state.videoRef:", this.state.videoRef)

      if (this.state.videoRef !== null) {
        this.state.videoRef.src = this.state.videoURL

        console.log("we did it! We set player.src to " + this.state.videoURL)
      }
    })
  };

  returnVideoToParent() {
    console.log("LOADER: about to return photo to parent.")

    const videoTxId = this.state.videoToAdd
    console.log("will use video with txId: ", videoTxId)
    console.log("will use video with description: ", this.state.videoDescription)

    this.props.returnVideo(this.state.videoToAdd, this.state.videoDescription, this.state.videoURL)

    this.setState({ videoLoaded: true })
  }

  removeVideoFromParent() {
    console.log("LIBRARY: about to REMOVE video from parent.")

    this.props.removeVideo()
    this.setState({ videoLoaded: false })
  }

  //Copied, modified from videoViewerModal.js
  async viewThisTxId(videoTxId) {
    console.warn("Will attempt to load, then display txId " + videoTxId)

    const videoParams = await fetchPublishedVideo(videoTxId)

    // BLANK the video, THEN reset it
    this.setState({mediaType: null}, () => {

        //alert("we've blanked the video - before re-setting it")
        this.setState({ videoURL: videoParams.mediaURL,
          //recordedBlob: videoBlob,
          //recordedBuff: videoBuff,
          mediaType: videoParams.mediaType,   // 'audio' or 'video'
        })

      }
    )

  }  // viewThisTxId

  handleVideoSelection(e, selection) {
    console.warn("handleVideoSelection: e: ", e)
    console.warn("handleVideoSelection: selection: ", selection)
    console.warn("handleVideoSelection: selection.value: ", selection.value)

    if ( selection.value.length > 10 ) {

      // HACK ALERT: to override the default selection of the first option, we try to detect
      //             if the user selected NOTHING (clicked away from choices/dropdown)
      //             WITHOUT first selecting something.
      //             By removing then re-instantiating the <Form.Group> and <Form.Field>
      //             within render(), it returns the dropdown to an UNSET state.
      if ( e.detail === 0 ) {
        console.warn("NOT actually selected? Perhaps we can remove, THEN re-instantiate the <Form.Group> - to return it to an un-selected state")
        e.preventDefault()

        this.setState({showDropdown: false}, () => {
          this.setState({showDropdown: true})
        })
        return
      }

      // go through EVERY video option, find the selected on, and get the description

      const vidOptions = this.state.vidOptions
      let desc = ''
      for ( let i in vidOptions ) {
        const v = vidOptions[ i ]
        console.warn("handleVideoSelection(): CONSIDERING OPTION: ", v)
        if ( v.value === selection.value ) {
          console.warn("THAT's what was chose, so, DESCRIPTION is ", this.state.justDescriptions[ i ])
          desc = this.state.justDescriptions[ i ]
          break;
        }
      }
      if ( desc === '' ) {
        alert (" hmm. Trouble")
      }


      this.setState({videoToAdd: selection.value, videoDescription: desc})

      this.viewThisTxId(selection.value)
    }
  }

  // show a 'toast' for a specified period
  quickToast(toastText, duration=2000, withoutLoader = false) {
      console.warn("quickToast()")
      this.setState({showQuickToast: true, toastText: toastText, toastDuration: duration, suppressLoader: withoutLoader})
      // once expired, quickToastDone() will be called
  }
  // called-back by toast, once it's expired  (see maybeQuickToast)
  quickToastDone(self) {

      self.setState({showQuickToast: false, suppressLoader: false})
  }

  async copyVideoTxToClipboard() {
    try {
        await navigator.clipboard.writeText(this.state.videoToAdd);

        this.quickToast("Video TxId copied to clipboard", 1100, true)
    } catch (error ) {
        alert("SORRY. We couldn't copy the transaction ID")
    }
  }

  render() {

    //FIXME: Copied from onramp.js.   can we share this somehow?
    // ***  DEVICE-DEPENDENT STYLING  ***
    // For mobile, "detect" landscape screen mode
    const landscape = this.props.isMobile ? window.innerWidth > window.innerHeight : false
    // For mobile, shrink the About, Security, and Glossary modals
    const modalClassName   = this.props.isMobile ?
                                    (landscape ?
                                            "modalMobileLandscape"
                                        :
                                            "modalMobilePortrait"
                                    )
                                :
                                    ""
    const modalContentClassName = this.props.isMobile ? "modalContentMobile" : ""
    const modalBottomClassName  = this.props.isMobile ? "modalBottomMobile"  : ""

    const disableTheUseButton = this.state.videoLoaded || this.state.videoURL === null
    const disableTheRemoveButton = !this.state.videoLoaded
    const maybeRemovalButton =
                          <>
                              <Button negative disabled={disableTheRemoveButton}
                                      onClick={this.removeVideoFromParent}>
                                DE-SELECT recording
                              </Button>
                          </>

    const doneIsPositive = this.state.videoLoaded && this.state.videoURL !== null
    const doneButtonLabel = doneIsPositive ? "USE this recording" : "Use NO recording"


    const optionalElipsis = this.state.videoDescription?.length > 23 ?
                              <>
                                <b style={{color:'blue'}}>...</b>
                              </>
                            :
                              <></>
    const playerClause = this.state.mediaType === 'audio' || this.state.mediaType === 'video' ?
  //                  <>
  //                      <audio className="recorded" ref={this.setVideoRef} controls >
  //                      </audio>
  //                  </>
  //              :
                    <>
                        <video className="recorded" width="320" height="240" ref={this.setVideoRef} controls autoPlay>
                        </video>
                        <br></br>
                        {this.state.videoToAdd ?
                          <>
                          Recording Tx {this.state.videoToAdd.substring(0,10)}... &nbsp; [{this.state.videoDescription?.substring(0,23)}{optionalElipsis}]
                          &nbsp; <Icon name='clone outline' onClick={this.copyVideoTxToClipboard}/>
                          <br></br>
                          </>
                          :
                          <></>
                        }
                    </>
                  :
                    <></>

    console.log("render: vidOptions: ", this.state.vidOptions)

    const maybeQuickToast = this.state.showQuickToast ?
                <Toast  durationMillis={this.state.toastDuration}
                    displayText={this.state.toastText}
                    noLoader={this.state.suppressLoader}
                    done={this.quickToastDone}
                    parent={this}/>
            :
                <></>

    return  <>
             <Modal  dimmer='blurring' size='large' centered className={modalClassName} open={true}
                      style={{backgroundColor: this.bshzPurple, borderRadius: '20px', height: "auto"}}>

                <Modal.Header style={{textAlign: 'center', backgroundColor: this.bshzPurple, borderRadius: '20px'}}>
                  <span style={{color: this.bshzYellow}}> Choose a recording to include</span>
                </Modal.Header>

                <Modal.Content className={modalContentClassName} scrolling style={{backgroundColor: this.bshzLtPurp}}>
                  While you may have <b>published</b> a recording to the blockchain using the <b>Recording Studio</b>, until
                  you include/reference it in one of your posts, no one will notice it.
                  <br></br>
                  <br></br>
                  <p>
                    Use this to include an <b style={{color:'blue'}}>already-published</b> recording with your post.
                    <br></br>
                    Use the <Button style={{color:'purple', backgroundColor:'yellow'}} size='mini'>Recording Studio</Button> to add
                    to your library of published recordings.
                  </p>

                  <Form>
                    {this.state.showDropdown ?
                        <Form.Group widths='equal'>
                          <Form.Field control={Select} fluid label='published recordings:' placeholder='choose a recording'
                                      disabled={this.state.videoLoaded}
                                      options={this.state.vidOptions}
                                      clearable={true}
                                      onChange={this.handleVideoSelection}/>
                        </Form.Group>
                      :
                        <></>
                    }
                  </Form>
                  <br></br>
                  <br></br>

                  <br></br>

                  {playerClause}

                  <br></br>
                  <Button positive disabled={disableTheUseButton} onClick={this.returnVideoToParent}>
                    SELECT recording
                  </Button>
                  {maybeRemovalButton}

                  <br></br>
                  <div style={{textAlign: 'center'}}>
                    <Button positive={doneIsPositive} negative={!doneIsPositive} onClick={this.props.closeLibrary} content={doneButtonLabel}/>
                  </div>
                </Modal.Content>

             </Modal>
             {maybeQuickToast}
            </>
  }
} // VideoLibrary

export default VideoLibrary;
