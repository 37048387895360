import React from 'react';

import { Button, Checkbox, Modal } from 'semantic-ui-react'

import VidRecorder from './vidRecorder.js';

import { makeDataTx } from "./contractSupport";

import { TARGET_RATE } from './harnessConstants.js';

import {
    openDB,

    readSetting,
    saveSetting,
} from './buildShizzle.js';

import './index.css';

const {  bsv  } = require('scryptlib');

class VideoRecorderModal extends React.PureComponent {
    constructor(props) {
        super(props);

        this.fontFamily          = "verdana"

        //FIXME: the purples are currently duplicated in index.css
        this.bshzPurple             = '#8313e2';
        this.bshzYellow             = "#fff300";
        this.bshzLtPurp             = '#e7d0fb';
        this.bshzLightYellow        = "#fffccc";

        this.shizzleVerse        = <><b>ShizzleVerse</b><small>&trade;</small></>;

        // our fee rate policy:  inverse of sats/kb    200kb/sat = 1/(5 sats/kb)
        //                                             750kb/sat = 1/(1.33 sats/kb)
        //                                             800kb/sat = 1/(1.25 sats/kb)
        //this.dataFeeRateDivider = 800   This is really just 1 / TARGET_RATE
        // We've tried values of:
        //          25 (40  sats/kb) .04   sat/B:  9051e0d2f74bedbccf9e7744130627f4ed980c56dccf3d5ea4034a8e6c07c089  by GorillaPool - in next testnet block mined  [160 cents for 10 minutes?]
        //          40 (25  sats/kb) .025  sat/B: efce67a4ff1fad45dfd31f6cee2637137302184450360fd1c373f2a950b90c09  by Taal        - in next testnet block mined  [100 cents for 10 minutes]
        //          50 (20  sats/kb) .02   sat/B:
        //         100 (10  sats/kb) .01   sat/B: 85ecbf941d2d5d12bd7a982e21618afd40d466978b42ff2ad74ccce768ec3d1e  by GorillaPool - in next testnet block mined  [ 40 cents for 10 minutes]
        //         200 ( 5  sats/kb) .005  sat/B: b96e63691517f312abe63cb2f63f667a7f18362947315ec2d8cee4d4f52f4c8d  by GorillaPool - in next testnet block mined  [ 20 cents for 10 minutes]
        //         500 ( 2  sats/kb) .002  sat/B: 9b6a32fc81942b7e4278cbacdb3dfa9c200f06ad41b6ae267eea86b599521420  by GorillaPool - in next testnet block mined  [  8 cents for 10 minutes]
        //         750 (1.3 sats/kb) .0013 sat/B: 572f662d7f92e4be8b3a006b17e5fc3d87e686c3b99372d0dfd36586b427a306  by Taal        - in next testnet block mined  [  6 cents for 10 minutes? or 5.3?]
        //         800 (1.25sats/kb) .00125sat/B: 7273a0c09975688200f38626ae1d9b2ae83b2273fc3f825577bf9a0ca8739250  by GorillaPool - in next testnet block mined  [  5 cents for 10 minutes]

        this.state =  {
            frameRateModal: <></>,

            publishedVideoTxId: null,

            showVideoAdvisory: true,
        }


        this.closeVideoRecModal         = this.closeVideoRecModal.bind(this);
        this.postVideo                  = this.postVideo.bind(this);

        this.adjustFrameRateModal       = this.adjustFrameRateModal.bind(this);

        this.buf2hex                    = this.buf2hex.bind(this);

        this.closeVideoAdvisory         = this.closeVideoAdvisory.bind(this);
        this.flipVideoBrokenSetting     = this.flipVideoBrokenSetting.bind(this);
    }

    adjustFrameRateModal(me, modal) {
        me.setState({frameRateModal: modal})
    }

    async postVideo(event, vidBlob, mimeType, vidBlob2= null, mimeType2 = null) {
        console.warn("postVideo()")
        console.warn("IMPLEMENT ME: build tx for video of size " + vidBlob.size)
        console.warn("IMPLEMENT ME: build tx for video of type " + typeof vidBlob)
        console.log("And here's the blob: ", vidBlob)

        //FIXME: helpful to pass a blobArray? why not just an array?  <-----
        const blobArray = await vidBlob.arrayBuffer()
        console.log("And here's the blob array: ", blobArray)

        console.log("And the blob array has byteLength: ", blobArray.byteLength)
        //alert("blob array has byteLength " + blobArray.byteLength)

        // vestigial note: copying arrayBuffers:
        //     https://stackoverflow.com/questions/10100798/whats-the-most-straightforward-way-to-copy-an-arraybuffer-object
        //const transcodedData = this.props.getTranscodedData ? this.props.getTranscodedData() : null

        let dataSizeToPublish = blobArray.byteLength
        const usedMimeType = mimeType
        const mimeHex = Buffer.from(usedMimeType).toString('hex')

        let extra = "" // used if we need to embed TWO video formats (mimeTypes) in one tx
        if ( vidBlob2 !== null ) {
            if ( mimeType2 !== null ) {

                const blobArray2 = await vidBlob2.arrayBuffer()
                console.log("And here's the blob array2: ", blobArray2)

                console.log("And the blob array2 has byteLength: ", blobArray2.byteLength)

                dataSizeToPublish += blobArray2.byteLength

                const mimeHex2 = Buffer.from(mimeType2).toString('hex')
                extra = " " + this.buf2hex(blobArray2) + " " + mimeHex2

                console.log("mimeType: ", mimeType)
                console.log("mimeType2: ", mimeType2)
                console.log("mimeHex: ", mimeHex)
                console.log("mimeHex2: ", mimeHex2)

                alert("We're going to embed TWO video formats (mimeTypes) in a single tx. (check console)")
            } else {
                alert("Whoops. Code error? We received a 2nd video blob, but no mimeType")
                return
            }
        }

        // optionally include/bundle a SECOND (transcoded) video
        //FIXME: can/should we use something like above's Buffer.from(...).toString('hex') ?
        const dataAsm = this.buf2hex(blobArray) + " " + mimeHex + extra

        console.log("raw data (Asm) (with mime) has len " + dataAsm.length)

        console.log("data of size: " + dataSizeToPublish + " bytes.  mimeType: " + usedMimeType)

        console.warn("mimeType hex: ", mimeHex)


        console.warn("Will now post " + dataSizeToPublish + " bytes of video of mimeType " + usedMimeType)

        const privKey = new bsv.PrivateKey.fromWIF(this.props.theKey)

        const approxFee = Math.round(  dataSizeToPublish  * TARGET_RATE ) + 22 // add 22 sats to cover incidental bytes
        const satsPerKB = 1000 * TARGET_RATE
        alert("LAST CHANCE TO QUIT before broadcasting a recording tx. approxFee in sats (" + satsPerKB + " sats/kb): " + approxFee)

        try {
            const videoTx = await makeDataTx(privKey, dataAsm, true, 1 / TARGET_RATE)

            console.log("NEW VIDEO TXID: " + videoTx)
            this.setState({publishedVideoTxId: videoTx})
            //console.warn("new audio/video txid: " + videoTx + "   Add an ENCRYPT video option?  SAVE THIS TxId !! <--")
            return {success: true, txId: videoTx}
        } catch (error) {
            console.error("trouble building audio/video tx: ", error)
            console.error("IMPLEMENT ME: add a MODAL instead of this alert")

            this.setState({publishedVideoTxId: null})
            return {success: false, txId: ''}
        }
        // successfull video testnet tx:
        //
        //  thumds up 8.mp4 ?
        //  video/webm; codecs=h264   (plays through WoC on brave)
        //  fc7d57761732fe8c2475d2b1e9cf31ffd3bea88134a13e3099327a14bc1e2b75
        //
        // 2nd success:
        //  thumbs up 9.mp4
        //  video/webm; codecs=h264   (plays through WoC on brave)
        //  8ac85b6441fdd2db75d2507c6a58d174d53135e4cb2535bc63c6238e49d9ea73
        //
        // Safari success:
        //  snap peace 3.mp4
        //  video/mp4;codecs=avc1  perhaps      PLAYS though WoC ON BRAVE, not on safari??
        //  df3391cd099bf57fbee6c2a25d3fa2544561c3cafde1d07cf7dd453b6ea25cd1
        //  file:///Users/bill/Downloads/snap%20peace%203.mp4


        // recorded on safari, plays 1 sec on BRAVE WoC
        // 98238780d156ce241eeca1fa50958c2123c4f21664c1a3041b54156fa3e369af

        // 5-secs to publish 2-sec video
        // Thumb snap success from safari 2 secs.mp4
        // 73fd46c513cf29f69835a3d0fb6da786211405e4da416c2cff60f6befc0c83d0
    }

    // NOTE: also in fileChooserReader.js
    // https://stackoverflow.com/questions/40031688/javascript-arraybuffer-to-hex
    buf2hex(buffer) { // buffer is an ArrayBuffer
        return [...new Uint8Array(buffer)]
            .map(x => x.toString(16).padStart(2, '0'))
            .join('');
    }

    closeVideoRecModal() {
        if ( this.props.closeVideoModal && this.props.closeVideoModal !== null ) {
            this.props.closeVideoModal()
        } else {
            alert("FIXME: videoRecorderModal needs a closeVideoModal property")
        }
    }

    async componentDidMount() {

        console.warn("videoRecorderModal componentDidMount()")

        if ( this.props.parent && this.props.registerClient ){
            this.props.registerClient(this.props.parent, this, "videoRecorderModal")
        }

        const db = await openDB()

        // get the 'knowsVideoIsBroken' setting
        const knowsVideo = await readSetting(db, "knowsVideoIsBroken")
        let knowsBroken = false
        if ( knowsVideo !== null ) {
            knowsBroken = knowsVideo.value

            console.warn("Retrieved knowsVideoIsBroken settting:  ", knowsBroken)
        }
        this.setState({understandsVideoBroken: knowsBroken})

    } // componentDidMount()

    componentWillUnmount() {
        console.log("videoRecorderModal about to unmount. Will UNregister...")

        if ( this.props.parent && this.props.unregisterClient ) {
            this.props.unregisterClient(this.props.parent, this, "videoRecorderModal")
        }

        // fix Warning: Can't perform a React state update on an unmounted component
        // see: https://stackoverflow.com/questions/53949393/cant-perform-a-react-state-update-on-an-unmounted-component
        this.setState = (state,callback)=>{
            return;
        };
    }

    closeVideoAdvisory() {
        this.setState({showVideoAdvisory: false})
    }

    async flipVideoBrokenSetting() {
        // save change to settings (IDB)
        await saveSetting(await openDB(), 'knowsVideoIsBroken', !this.state.understandsVideoBroken)

        // save updated state
        this.setState({understandsVideoBroken: !this.state.understandsVideoBroken})
    }

    render() {

        // ***  DEVICE-DEPENDENT STYLING  ***
        // For mobile, "detect" landscape screen mode
        const landscape = this.props.isMobile ? window.innerWidth > window.innerHeight : false
        // For mobile, shrink the About, Security, and Glossary modals
        const modalClassName   = this.props.isMobile ?
                                        (landscape ?
                                                "modalMobileLandscape"
                                            :
                                                "modalMobilePortrait"
                                        )
                                    :
                                        ""
        const modalContentClassName = this.props.isMobile ? "modalContentMobile" : ""
        const modalBottomClassName  = this.props.isMobile ? "modalBottomMobile"  : ""


        const maybeFrameRateModal = this.state.frameRateModal

        const maybePublishedTxId = this.state.publishedVideoTxId ?
                    <>
                        Published recording txId (save this): {this.state.publishedVideoTxId}
                    </>
                :
                    <>
                        You've not yet published any recording during this 'session'
                    </>

        const maybeProTip = this.state.showVideoAdvisory && !this.state.understandsVideoBroken ?
            <>
                   <Modal dimmer='blurring' size='large' centered className={modalClassName}  open={true}
                                    style={{backgroundColor: this.bshzPurple, borderRadius: '20px', height: "auto"}}>

                        <Modal.Header style={{textAlign: 'center', backgroundColor: this.bshzPurple, borderRadius: '20px'}}>
                            <span style={{color: this.bshzYellow}}> Internet Video is Broken-ish </span>
                        </Modal.Header>

                        <Modal.Content className={modalContentClassName} scrolling style={{backgroundColor: this.bshzLtPurp}}>

                        <p>
                            Hi there. This is just to let you know that Video on the internet is <b>not ideal</b>.
                            You might not know this because large companies like Google, Facebook, and YouTube, work hard
                            to make video work for you. They hide the problems - and they do a very good job of it. In return
                            they make money from all of us. That's one of the ways in which the ShizzleVerse is different: it
                            can't be controlled by large corporations. Of course, that's both good and bad.
                            <br></br>
                            <br></br>
                            <span style={{color:'blue'}}>
                            The problem is that there are <b>multiple audio/video formats</b>, and most browsers don't support
                            all of them. After recording from your device, if you want <b>everyone</b> to
                            be able to play it, you <b>might</b> need to take an <b>extra step</b>, and convert your
                            recording (depending on your device or browser). This can be slow (depending on your device). Google's
                            not here to help us. There is no massive server farm to convert your recordings to other
                            formats - like YouTube does. We're on our own for now.
                            </span>
                            <br></br>
                            <br></br>
                            The good news is that this will get easier. The ShizzleVerse is an open protocol powered
                            by Bitcoin. Anyone can improve it, and get rewarded for their efforts. In the mean time, keep
                            your eyes open for problems/opportunities. The ShizzleVerse wants to know how you think things
                            can be improved.
                            <br></br>
                            <br></br>
                            Thanks for listening.
                        </p>

                        <Button positive onClick={this.closeVideoAdvisory} content='Got it'/>
                        &nbsp; &nbsp; &nbsp;Don't show this again &nbsp;
                        <Checkbox label='' checked={this.state.understandsVideoBroken} onClick={this.flipVideoBrokenSetting}/>

                        <br></br>

                        </Modal.Content>

                    </Modal>
            </>
                :
            <></>

        // original: 
        //                         <VRecorder   postVidBlob={this.postVideo}
        //                                      closeIt={this.closeVideoRecModal} />
        const videoModal =
                <>
                    <Modal dimmer='blurring' size='large' centered className={modalClassName}  open={true}
                                    style={{backgroundColor: this.bshzPurple, borderRadius: '20px', height: "auto"}}>

                        <Modal.Header style={{textAlign: 'center', backgroundColor: this.bshzPurple, borderRadius: '20px'}}>
                            <span style={{color: this.bshzYellow}}> Record and Publish Audio/Video </span>
                        </Modal.Header>

                        <Modal.Content className={modalContentClassName} scrolling style={{backgroundColor: this.bshzLtPurp}}>

                            <VidRecorder    postVidBlob={this.postVideo}
                                            closeIt={this.closeVideoRecModal}
                                            bsvPriceUSD={this.props.bsvPriceUSD}

                                            adjustFrameRateModal={this.adjustFrameRateModal}
                                            receiveFFMpegRef={this.props.receiveFFMpegRef}
                                            ffMpeg={this.props.ffMpeg}
                                            parent={this}

                                            playbackNotRequired={this.props.playbackNotRequired}
                            />

                            <div style={{textAlign: 'center'}}>
                                {maybePublishedTxId}
                            </div>
                        </Modal.Content>

                    </Modal>

                    {maybeFrameRateModal}
                </>


        return(
            <>
                {videoModal}
                {maybeProTip}
            </>
        )
    }
}

export default VideoRecorderModal;
