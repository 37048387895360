//encdec.js
// taken from MIT-licensed https://github.com/themikefuller/Web-Cryptography

export function AES() {

    let aes = {};

    aes.encrypt = async (message, password, passwordBits, iterations) => {

      let rounds = iterations || 500000;
      let msg = new TextEncoder().encode(message);
      let pass;

      if (password) {
        pass = await crypto.subtle.importKey('raw', new TextEncoder().encode(password), {
          "name": "PBKDF2"
        }, false, ['deriveBits']);

        //console.warn("   AES() ENCRYPT 1   derived pass: ", pass)
      } else if (passwordBits) {
        pass = await crypto.subtle.importKey('raw', new Uint8Array(passwordBits),{
          "name": "PBKDF2"
        },false,['deriveBits'])

        //console.warn("   AES() ENCRYPT 2   derived pass from BITS (Uint8Array): ", pass)
      }

      let salt = crypto.getRandomValues(new Uint8Array(32));
      let iv = crypto.getRandomValues(new Uint8Array(12));

      let bits = await crypto.subtle.deriveBits({
        "name": "PBKDF2",
        "salt": salt,
        "iterations": rounds,
        "hash": {
          "name": "SHA-256"
        }
      }, pass, 256);

      let key = await crypto.subtle.importKey('raw', bits, {
        "name": "AES-GCM"
      }, false, ['encrypt']);

      let enc = await crypto.subtle.encrypt({
        "name": "AES-GCM",
        "iv": iv
      }, key, msg);

      let iterationsHash = btoa(rounds.toString());

      let saltHash = btoa(Array.from(new Uint8Array(salt)).map(val => {
        return String.fromCharCode(val)
      }).join(''));

      let ivHash = btoa(Array.from(new Uint8Array(iv)).map(val => {
        return String.fromCharCode(val)
      }).join(''));

      let encHash = btoa(Array.from(new Uint8Array(enc)).map(val => {
        return String.fromCharCode(val)
      }).join(''));

      return iterationsHash + '.' + saltHash + '.' + ivHash + '.' + encHash;

    };


    aes.decrypt = async (encrypted, password, passwordBits) => {

      let parts = encrypted.split('.');
      let rounds = parseInt(atob(parts[0]));

      /**/
      console.warn("aes.decrypt(): parts: [rounds, salt, iv, enc]", parts)
      console.warn("aes.decrypt(): rounds: ", rounds)
      console.warn("aes.decrypt(): salt: ", parts[1])
      console.warn("aes.decrypt(): iv: ", parts[2])
      //console.warn("aes.decrypt(): enc: ", parts[3])
      /**/

      let salt = new Uint8Array(atob(parts[1]).split('').map(val => {
        return val.charCodeAt(0);
      }));

      let iv = new Uint8Array(atob(parts[2]).split('').map(val => {
        return val.charCodeAt(0);
      }));

      let enc = new Uint8Array(atob(parts[3]).split('').map(val => {
        return val.charCodeAt(0);
      }));

      let pass;

      if (password) {
        console.warn("aes.decrypt(): getting pass")

        pass = await crypto.subtle.importKey('raw', new TextEncoder().encode(password), {
          "name": "PBKDF2"
        }, false, ['deriveBits']);
      }

      if (passwordBits) {
        pass = await crypto.subtle.importKey('raw', new Uint8Array(passwordBits), {
          "name": "PBKDF2"
        }, false, ['deriveBits']);
      }

      console.warn("aes.decrypt(): getting bits")
      let bits = await crypto.subtle.deriveBits({
        "name": "PBKDF2",
        "salt": salt,
        "iterations": rounds,
        "hash": {
          "name": "SHA-256"
        }
      }, pass, 256);

      console.warn("aes.decrypt(): getting key")
      let key = await crypto.subtle.importKey('raw', bits, {
        "name": "AES-GCM"
      }, false, ['decrypt']);

      /*
      console.warn("aes.decrypt(): decrypting with iv: ", iv)
      console.warn("aes.decrypt(): decrypting with key: ", key)
      console.warn("aes.decrypt(): decrypting with enc: ", enc)
      */

      let dec = await crypto.subtle.decrypt({
        "name": "AES-GCM",
        "iv": iv
      }, key, enc);

      //console.warn("aes.decrypt(): decoding...")
      const res = new TextDecoder().decode(dec)
      //console.warn("aes.decrypt(): returning...")

      return res
      return (new TextDecoder().decode(dec));

    };

    return aes;

}
