import React from 'react';

import { Button, Checkbox, Container, Divider, Dropdown, Form, Grid,
    Input, Menu, Message, Modal, Popup, Radio, TextArea } from 'semantic-ui-react'

import {
    openDB,
    registerDomainOfMine,
    findADomainOfMine,
    findAllDomainsOfMine
} from './buildShizzle.js';

/*
const handleDomainRegistrationFieldChange = (event, data, object, extraObject) => {
    const field = data.field;
    const value = data.value;
    const checked = data.checked;

    switch(field) {
        case 'domainToAdd':
            object.setState({domainToAdd: value})
            console.log("domainToAdd: " + value)
            break;
        default:
            console.log("CODE ERROR: unrecognized field type: " + field
                    + ", with value " + value + ", checked " + checked);
            throw new Error('13101: CODE ERROR: ' + field);
            break;
    }
}
*/

class DomainModal extends React.Component {
    constructor(props) {
      super(props);

      this.state = {
        //domainToAdd: '',
        domainsArray: []
      };

      this.handleCancel                        = this.handleCancel.bind(this);
      //this.handleDomainRegistrationFieldChange = this.handleDomainRegistrationFieldChange.bind(this);
      //this.registerADomainOfMine               = this.registerADomainOfMine.bind(this);
      this.buildListFromArray                  = this.buildListFromArray.bind(this);
      this.listClicked                         = this.listClicked.bind(this);
    }

    handleCancel() {
        this.props.closeModal(this.props.parent)
    }
/*
    async registerADomainOfMine(event, data) {
        event.preventDefault();
        console.warn("registerADomainOfMine(): Will register " + this.state.domainToAdd)

        const db = await openDB();
        const domainRec = await registerDomainOfMine(db, this.state.domainToAdd, 1, approxBlockHeightWhenClaimed)
        console.warn("Here's the record we created: ", domainRec)

        const domainsArray = await findAllDomainsOfMine(db)

        console.log("domainModal registerADomainOfMine(): UPDATED domains: ", domainsArray)

        this.setState({domainsArray: domainsArray})
    }
*/

    async componentDidMount() {
        console.warn("domainModal componentDidMount()")

        const db = await openDB();
        const domainsArray = await findAllDomainsOfMine(db)

        console.log("domainModal componentDidMount(): domains: ", domainsArray)

        this.setState({domainsArray: domainsArray})
    }

    listClicked(e, idx) {
        if (e.type === 'click') {
            console.log('listClicked: Left click');
        } else if (e.type === 'contextmenu') {
            console.log('listClicked: Right click');
        }
        const domainThatWasClicked = this.state.domainsArray[idx].name.toLowerCase()
        console.warn("domainModal listClicked(): The list was CLICKED!")
        console.warn("domainModal listClicked(): idx: " + idx)
        console.warn("domain: " + domainThatWasClicked)

        this.props.domainClickHandler(this.props.parent, domainThatWasClicked)
        this.handleCancel()
        /*
        this.setState({domainToVisit: domainThatWasClicked,
            reachedLimb: '',
            finalQuarterlyTxId: null, decodedTx: ''})
        */
    }

    //FIXME: extra/share this?
    buildListFromArray(arr) {
        if ( arr === null ) {
            return null
        }

        let listArray=[]
        for ( let i = 0; i < arr.length; i++ ) {
            listArray[i] = {
                            idx:        i,
                            name:       arr[i].name,
                            ownerCount: arr[i].ownerCount
            }
            console.log("    DM buildListFromArray(): adding name " + listArray[i].name)
        }
        const keysList = listArray.map((key) =>
                        <li className="hoverLink" key={key.idx} onClick={       (event) => this.listClicked(event, key.idx)}
                                                                onContextMenu={ (event) => this.listClicked(event, key.idx)}>
                                <span style={{color: 'green'}}>{key.name}</span>
                            &nbsp; (owner #{key.ownerCount})</li>
                );

        return keysList
    }

    render() {
        //const registerButtonDisabled = false
        const domainsArray = this.state.domainsArray

        console.log("domainModal render(): domainsArray: ", domainsArray)
        const arrList = this.buildListFromArray(domainsArray)
/*
        <form>
        <label name='domainToAdd'>
            Add/register a BitShizzle domain which you <strong>already</strong> own: &nbsp;
            <Input placeholder='Enter the name of a domain you own'
                    field='domainToAdd'
                    value={this.state.domainToAdd}
                    className="limb-input-field"
                    onChange={ (event, data) => handleDomainRegistrationFieldChange(event, data, this) } />
            &nbsp; &nbsp;
            <Button type="submit" content="Register" disabled={registerButtonDisabled} onClick={this.registerADomainOfMine} />
        </label>
        </form>
*/
        return <>
                <Modal open={true} size='small' dimmer='blurring'>
                    <Modal.Header className="modaledge">Your Domains</Modal.Header>
                    <Modal.Content>
                        Below is a list of all Domains that you own - which you've registered.
                        <br></br>
                        <Divider />
                        {arrList}
                        <Divider />



                        <Modal.Actions className="modaledge">
                            <Button field="cancelButton" onClick={this.handleCancel} positive>Done</Button>
                        </Modal.Actions>

                    </Modal.Content>
                </Modal>
               </>
    }
}

export default DomainModal;
