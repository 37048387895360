import React from 'react';

import { Button, Checkbox, Container, Divider, Dropdown, Icon,
    Input, Modal, Popup, Segment, Sidebar, Sticky, TextArea, Transition } from 'semantic-ui-react'

import ShizzleMasthead from './shizzleMasthead';
import Footer      from './footer';
//import ShizzleWhy      from './shizzleWhy';

import './index.css';
import WalletGenerator from './walletGenerator';
import WalletVerifier  from './walletVerifier';
import WalletFunder    from './walletFunder';
import Claimer         from './claimer';

import WalletImporter from './walletImporter';

import { findAllDomainsOfMine, openDB, readSetting, } from "./buildShizzle.js"

import { bshzColors } from './bshzColors';

class OnRamp extends React.PureComponent {
    constructor(props) {
        super(props);

        this.fontFamily          = "verdana"
        this.balancePennies      = 0.0
        this.bestUtxoPennies     = 0.0

        // will use these to force focus as 'citizenShipLevel' changes (user progress in on-ramping)
        this.refButtonA = React.createRef();    // WalletGenerator
        this.refButtonB = React.createRef();    // WalletVerifier
        this.refButtonC = React.createRef();    // WalletFunder
        this.refButtonD = React.createRef();    // Claimer

        // will use this to force focus when the 'GenericOkModal' is shown
        this.refOkButton = React.createRef();


        this.BitSchnitzelLLC     = <>BitSchnitzel LLC</>;
        this.shizzleVerse        = <><b>ShizzleVerse</b><small>&trade;</small></>;
        this.bitShizzle          = <><b>BitShizzle</b><small>&trade;</small></>;
        this.bitShizzleItalic    = <><b>Bit<i>Shizzle</i></b><small>&trade;</small></>;
        this.shizzleVerseNotBold = <>ShizzleVerse<small>&trade;</small></>;
        this.bitShizzleNotBold   = <>BitShizzle<small>&trade;</small></>;

        //FIXME: the purples are currently duplicated in index.css
        this.bshzPurple             = '#8313e2';
        this.bshzYellow             = "#fff300";
        this.bshzLtPurp             = '#e7d0fb';
        this.bshzLightYellow        = "#fffccc";

        this.emptyLightCircle = <Icon  size='big' style={{color: this.bshzLtPurp}} name='circle outline' />
        this.emptyDarkCircle  = <Icon  size='big' style={{color: this.bshzPurple}} name='circle outline' />
        this.filledDarkCircle = <Icon  size='big' style={{color: this.bshzPurple}} name='check circle' />
        this.lightArrow       = <Icon  size='big' style={{color: this.bshzLtPurp}} name='arrow down' />
        this.darkArrow        = <Icon  size='big' style={{color: this.bshzPurple}} name='arrow down' />

        //WARNING: set this to a value that divides evenly into 1000, but doesn't exceed:
        //             25, 50, 100, 200, 1000
        //         Trade-off: lower number mean more processing, but better
        //                    magical scolling effect behind masthead
        this.intervalMillis = 100  // how often to tick the clock
        //NOTE: assumes millisCount (every intervalMillis) hits these exactly
        //NOTE: 33250 is exactly how long the transitions in quoteSlider take:
        //          6x(longInterval + shortInterval) + finalInterval + shortInterval + 750 delay
        this.animationPeriod = 33000

        this.state =  {
                        showGenerate: false,
                            showRestoreModal: false,
                        showVerifier: false,
                            //understandsPwdBoxChecked: false,
                        mountFunder: false,
                        showFunder: true,
                            showFunderCancelButton: false,
                        showClaimer: false,

                        showGenericOkModal: false,

                        showServerErrorModal: false,

                        genModalTitle: '',
                        genModalText: '',

                        userDomains: [],
                        dropList: [],            // a non-outdated subset of userDomains - formatted differently
                        selectedDomain: null,
                        selectedOwnerCount: 0,
                        defaultID: '',
                        defaultIdOwnerCount: 0,
                        defaultDomainIdx: -1,

                        isAnExpert: false,

                        showDrawer: false,
        }

        this.signInOutCallback          = this.signInOutCallback.bind(this);

        this.fullResetPerformed         = this.fullResetPerformed.bind(this);

        this.handleRestoreOption        = this.handleRestoreOption.bind(this);
        this.closeRestorer              = this.closeRestorer.bind(this);

        this.toggleShowWalletSetupModal = this.toggleShowWalletSetupModal.bind(this);
        this.toggleShowPwdVerifyModal   = this.toggleShowPwdVerifyModal.bind(this);
        this.toggleShowWalletFunderModal= this.toggleShowWalletFunderModal.bind(this);

        this.setUserWantsToAddFunds     = this.setUserWantsToAddFunds.bind(this);

        this.showWalletSetupModal       = this.showWalletSetupModal.bind(this);
        this.verifyPassword             = this.verifyPassword.bind(this);
        this.showFunderModal            = this.showFunderModal.bind(this);

        this.claimDomain                = this.claimDomain.bind(this);
        this.openClaimer                = this.openClaimer.bind(this);
        this.closeClaimer               = this.closeClaimer.bind(this);

        this.userClaimedAsset           = this.userClaimedAsset.bind(this);

        this.userHasWallet              = this.userHasWallet.bind(this);
        this.userRemembersPwd           = this.userRemembersPwd.bind(this);
        this.userDeletedWallet          = this.userDeletedWallet.bind(this);
        this.walletIsGood               = this.walletIsGood.bind(this);

        this.showGenericModal           = this.showGenericModal.bind(this);
        this.handleGenericModalOk       = this.handleGenericModalOk.bind(this);

        this.handleServerErrorModalOk   = this.handleServerErrorModalOk.bind(this);

        this.returnBundle               = this.returnBundle.bind(this);
        this.recordBalance              = this.recordBalance.bind(this);
        this.recordBalanceFromFunder    = this.recordBalanceFromFunder.bind(this);
        this.recordBalanceFromApp       = this.recordBalanceFromApp.bind(this);

        this.footerFocus                = this.footerFocus.bind(this);

        this.handleAssetChange          = this.handleAssetChange.bind(this);
        this.scanAssetList              = this.scanAssetList.bind(this);
        this.scanThenBuildDropDown      = this.scanThenBuildDropDown.bind(this);

        this.openDrawer                 = this.openDrawer.bind(this);
        this.closeDrawer                = this.closeDrawer.bind(this);
        this.logoutThenCloseDrawer      = this.logoutThenCloseDrawer.bind(this);
        this.handleExploreClick         = this.handleExploreClick.bind(this);

    }

    // Called by footer when one of its modals show, or hide
    // Use this knowledge to disable (or not) buttons on this page
    footerFocus(trueOrFalse) {
        //alert("Footer in focus? " + trueOrFalse)
        this.setState({footerModalInFocus: trueOrFalse})
    }

    // NOTE: copied to surfer
    handleAssetChange(e, v) {
        console.warn("typeof v.value: " + typeof v.value)
        console.warn("typeof this.state.defaultDomainIdx: " + typeof this.state.defaultDomainIdx)
        console.warn("hac change(): v.value (domain index): ", v.value)
        console.warn("handleAssetChange():  domain: ", this.state.userDomains[v.value].name)

        this.setState({selectedDomain:     this.state.userDomains[v.value].name,
                       selectedOwnerCount: this.state.userDomains[v.value].ownerCount,
                       defaultDomainIdx:   v.value,
                     })
        //alert("Change: " + event.target.value)
        //alert("value: " + v.value)
    }

    async signInOutCallback() {
        if ( this.props.citizenLevel === 1 ) {
            console.log("onramp: we should SHOW the verifier")
            this.setState({showVerifier: true})
        } else if ( this.props.citizenLevel > 1 ) {
            console.log("onramp: We should FORGET official key record, and disappear the verifier.")

            this.setState({showVerifier: false, mountFunder: false, selectedDomain: null, selectedOwnerCount: 0})
            this.bundle = null   //FIXME: ok?
            this.balancePennies = 0
            this.bestUtxoPennies = 0
            await this.props.changeCitizenLevel(1)
        }
    }


    // Called by generator (no current wallet) if user wants to import instead
    handleRestoreOption() {
        console.log("Let's import (restore) a wallet")
        this.setState({showGenerate: false, showRestoreModal: true})
    }
    closeRestorer() {
        console.warn("closeRestorer()")
        this.setState({showRestoreModal: false})
    }

    openClaimer() {
        this.setState({showClaimer: true})
    }
    closeClaimer() {
        this.setState({showClaimer: false})

        // scroll down, now that user should read/see the Congratulations box
        //window.scrollBy(0, 300)
        window.scrollBy({
            top: 400,
            left: 0,
            behavior: 'smooth'
        })
    }

    async componentDidMount() {
        // If we got here because we just filled-out a survey, let's scroll back up to the top
        //window.scrollTo(0, 0)
        // this seems to work (scroll UP)
        window.scrollBy({
            top: -500,
            left: 0,
            behavior: 'smooth'
        })

        console.warn("OnRamp componentDidMount()")
        //this.props.registerClient(this.props.parent, this, "onRamp")

        // we might learn of the balance from the APP
        if ( this.props.returnBalanceCallback ) {
            this.props.returnBalanceCallback( this, this.recordBalanceFromApp )
        }

        if ( this.props.bundle ) {
            //alert("onRamp mounted. And inherited bundle: ", this.props.bundle)
            this.bundle = this.props.bundle
        }

        //  <Dropdown placeholder='Choose Asset' search selection options={assetOptions} />

        const db = await openDB()

        // get the 'default' identity
        const defID = await readSetting(db, "defaultIdentity")
        let defaultID = ''
        if ( defID !== null ) {
            defaultID = defID.value

            console.warn("Retrieved default identity:  ", defaultID)
        }

        // get the 'default' identity owner count
        const defIDOC = await readSetting(db, "defaultIdentityOwnerCount")
        let defaultIDOwnerCount = 0 //FIXME: or, should this be -1?
        if ( defIDOC !== null ) {
            defaultIDOwnerCount = defIDOC.value

            console.warn("Retrieved default identity owner count:  ", defaultIDOwnerCount)
        }

        //WARNING: we don't yet USE the defaultIDOwnerCount
        //         we SHOULD use it when jumping to one of our identities
        this.setState({ defaultID:           defaultID,
                        defaultIdOwnerCount: defaultIDOwnerCount,
            }, this.scanThenBuildDropDown //this.scanAssetList // also performed in claimedCallback this.userClaimedAsset
        )
    } // componentDidMount()

    async scanThenBuildDropDown(preferThisAsset='???', preferThisOwnerCount=0) {

        await this.scanAssetList(preferThisAsset, preferThisOwnerCount, function() {

            let defaultDomainIdx = -1
            let defaultIfValidDefaultNotFound = -1
            if ( this.state.userDomains?.length > 1 && this.props.citizenLevel === 4 ) {
                let dropList = []
                let i = 0
                for ( let d = 0; d < this.state.userDomains.length; d++ ) {
                    const outdated = this.state.userDomains[d].outdated
                    const extraText = outdated ? " (outdated)" : ""

                    // exclude friends from the list of identities we could assume
                    if ( this.state.userDomains[d].forFriend ) {
                        continue
                    }

                    if ( !outdated ) {
                        if ( this.state.defaultID === this.state.userDomains[d].name &&
                                this.state.defaultIdOwnerCount === this.state.userDomains[d].ownerCount ) {
                            defaultDomainIdx = d
                            console.warn("setting defaultDomainIdx to " + d + " - " + this.state.userDomains[d].name)
                        } else {
                            defaultIfValidDefaultNotFound = d
                            console.warn("setting defaultIfValidDefaultNotFound to " + d + " - " + this.state.userDomains[d].name)
                            console.log("BTW: entry  " + d + " has .ownerCount of " + this.state.userDomains[d].ownerCount)
                            console.log("BTW: entry  " + d + " has .outdated of " + this.state.userDomains[d].outdated)
                        }

                    } else if ( this.state.defaultID === this.state.userDomains[d].name &&
                            this.state.defaultIdOwnerCount === this.state.userDomains[d].ownerCount ) {
                        alert("CODE DEFICIENCY: (for entry " + d + ") Default ID " + this.state.defaultID + ":" + this.state.defaultIdOwnerCount
                            + " is OUTDATED. We should (but don't yet) CLEAR this as a default identity.")
                    }

                    dropList[i++] =   {
                                        key: d,
                                        value: d,
                                        text: this.state.userDomains[d].name + extraText,
                                        disabled: outdated
                                    }
                }
                if ( defaultDomainIdx === -1 && defaultIfValidDefaultNotFound !== -1 ) {
                    defaultDomainIdx = defaultIfValidDefaultNotFound
                    console.warn("setting from defaultIfValidDefaultNotFound: " + defaultDomainIdx + " - " + this.state.userDomains[defaultDomainIdx].name)
                }

                if ( defaultDomainIdx !== -1 ) {
                    this.setState({ selectedDomain:     this.state.userDomains[defaultDomainIdx].name,
                                    selectedOwnerCount: this.state.userDomains[defaultDomainIdx].ownerCount,
                                })
                }
                this.setState({defaultDomainIdx: defaultDomainIdx,
                            dropList: dropList})
                console.warn("We've set defaultDomainIdx to " + defaultDomainIdx)
            }
        }) // scanAssetList() with finalProcessing
    } // scanThenBuildDropDown()

    // This doesn't check if a domain is outdated, but scanThenBuildDropDown() does
    // Calls finalProcessing() after setState() has rippled
    async scanAssetList(preferThisAsset='???', preferThisOwnerCount=0, finalProcessing = () => {}) {
        // Check if the user ALREADY has claimed an asset or more
        const db = await openDB()
        const myDomains = await findAllDomainsOfMine( db )
        //alert("user already has " + myDomains.length + " domains")

        console.warn("onRamp scanAssetList(): preferThisAsset: ", preferThisAsset)
        console.warn("onRamp scanAssetList(): preferThisOwnerCount: ", preferThisOwnerCount)
        this.setState({userDomains: myDomains})

        if ( preferThisAsset !== '???' ) {
            this.setState({selectedDomain: preferThisAsset,
                           selectedOwnerCount: preferThisOwnerCount
                          }, finalProcessing
            )
        } else if ( this.state.defaultID !== '' ) {
            console.warn("onRamp scanAssetList(): using defaultID " + this.state.defaultID)
            console.warn("onRamp scanAssetList(): using defaultIdOwnerCount " + this.state.defaultIdOwnerCount)
            this.setState({selectedDomain: this.state.defaultID,
                           selectedOwnerCount: this.state.defaultIdOwnerCount
                          }, finalProcessing
            )
        } else if ( myDomains.length >= 1 ) {
            console.warn("onRamp scanAssetList(): using myDomains[0]: " + myDomains[0].name + ":" + myDomains[0].ownerCount)
            console.warn("onRamp scanAssetList(): using defaultIdOwnerCount " + this.state.defaultIdOwnerCount)
            this.setState({selectedDomain: myDomains[0].name,
                           selectedOwnerCount: myDomains[0].ownerCount
                          }, finalProcessing
            )
        }
    }

    componentWillUnmount() {
        console.log("OnRamp about to unmount. Will UNregister...")

        //this.props.unregisterClient(this.props.parent, this, "onRamp")

        // fix Warning: Can't perform a React state update on an unmounted component
        // see: https://stackoverflow.com/questions/53949393/cant-perform-a-react-state-update-on-an-unmounted-component
        this.setState = (state,callback)=>{
            return;
        };
    }

    returnBundle(theBundle) {
        //console.error("Got a bundle: ", theBundle)
        this.bundle = theBundle
        this.props.returnBundle( theBundle )
    }

    showGenericModal(title, text) {
        this.setState({showGenericOkModal: true, genModalTitle: title, genModalText: text})

        // moved to render()
        //this.refOkButton.current.focus();
    }
    handleGenericModalOk() {
        this.setState({showGenericOkModal: false, genModalTitle: '', genModalText: ''})
    }

    handleServerErrorModalOk() {
        this.setState({showServerErrorModal: false})
    }

    toggleShowWalletSetupModal() {
        if ( this.state.showGenerate ) {
            this.setState({showGenerate: false})
        } else {
            this.setState({showGenerate: true})
        }
    }
    showWalletSetupModal() {
        console.log("setup/generate Wallet")
        this.setState({showGenerate: true}) //, understandsBoxChecked: false})
    }

    setUserWantsToAddFunds(value) { // value: true or false (whether to show cancel button)
        console.log("onRamp: setUserWantsToAddFunds(): ", value + " <=====")
        this.setState({showFunderCancelButton: value})
    }

    //FIXME: rename? Could come here if wallet-save FAILS
    async userHasWallet() {
        console.warn("onRamp: USER HAS WALLET. close GENERATE WALLET modal")
        console.log("has wallet. close generator modal. citizenLevel WAS " + this.props.citizenLevel)

        // close the password-soliciting modal
        this.setState({showGenerate: false})

        //NOTE: should really be called props.checkCitizenShouldBeLevel1()
        const good = await this.props.checkCitizenLevel() === 1
        if ( good ) {
            //increase citizen level - at bitShizzleApp
            await this.props.changeCitizenLevel(1)
        }

        console.log("citizenLevel IS " + this.props.citizenLevel)
    }

    toggleShowPwdVerifyModal() {
        if ( this.state.showVerifier ) {
            this.setState({showVerifier: false})
        } else {
            this.setState({showVerifier: true})
        }
    }
    verifyPassword() {
        console.log("verify password")
        this.setState({showVerifier: true}) //, understandsPwdBoxChecked: false}) FIXME
    }

    async userClaimedAsset(ownsAssets, mostRecentAsset='???', mostRecentOwnerCount=0, advance = true) {
        console.log("OR: userClaimedAsset()  owns assets? " + ownsAssets + "  advance? " + advance)

        if ( advance ) {
            // 0: has no wallet
            // 1: has a wallet
            // 2: password entered/verified
            // 3: good wallet (enough money)
            // 4: user owns an asset
            if ( ownsAssets ) {
                await this.props.changeCitizenLevel(4)
            } else {
                await this.props.changeCitizenLevel(3)
            }

            await this.scanThenBuildDropDown(mostRecentAsset, mostRecentOwnerCount)

        }
        this.closeClaimer()
    }

    async fullResetPerformed() {
        await this.props.changeCitizenLevel(0)
        this.setState({showVerifier: false, userDomains: [], selectedDomain: null, selectedOwnerCount: 0})
        this.bundle = null
        this.balancePennies = 0
        this.bestUtxoPennies = 0
        //alert("onRamp: all done with Full Reset")
    }

    //NOTE: we also take note of the isAnExpert record
    async userRemembersPwd() {
        console.log("onRamp: user has demonstrated knowledge of valid pwd")
        console.log("onRamp: props: ", this.props)
        await this.props.changeCitizenLevel(2)

        //FIXME: TBD if this is expert stuff is necessary here (in onRamp)
        //       MAYBE we use the variable to automate clicking the FUND button   <-----

        let isAnExpert
        const expertRecord = await readSetting(await openDB(), "isAnExpert")
        console.log("onRamp: userRemembersPwd(): current isAnExpert record: ", expertRecord)
        if ( expertRecord === null ) {
            isAnExpert = false
            console.log("onRamp: null setting for isAnExpert record")
        } else {
            isAnExpert = expertRecord.value
            console.log("onRamp: new setting for isAnExpert: TRUE")
        }

        // if in 'expert' mode, auto-click the funder modal/button
        // ALSO, IF/when walletFunder reports the walletIsGood(),
        // if we're an expert, we'll auto-click the Claimer there (further below)

        if ( isAnExpert ) {
            console.warn("onRamp: userRemembersPwd():  because an expert, will automatically show the funder modal...")
 //           this.showFunderModal()
        }

        this.setState({showVerifier: false, isAnExpert: isAnExpert})
    }

    async userDeletedWallet() {
        console.log("onRamp: user has DELETED his wallet")
        console.log("onRamp: props: ", this.props)
        await this.props.changeCitizenLevel(0)
        this.bundle = null
        this.balancePennies = 0
        this.bestUtxoPennies = 0
        this.setState({showVerifier: false, userDomains: [], selectedDomain: null, selectedOwnerCount: 0})
    }

    // our Funder is reporting that the wallet funding is sufficient
    // Called-back by our WalletFunder - as props.walletIsSufficient()
    async walletIsGood(isSufficient, closeTheFunderModal, parent) {
        console.log("onRamp: the user's wallet is sufficiently-funded (he's ready to proceed)? ", isSufficient)

        if ( !isSufficient ) {
            alert("NOTE: your wallet appears to be low on funding.\n\n"
                + "You'll need to add funds (not much) to be more than a VISITOR.")
        } else {
            await this.props.changeCitizenLevel(3)
            console.warn("onRamp INCREASED citizelLevel to 3 (from 2).")
        }

        if ( closeTheFunderModal ) {
            //await this.props.changeCitizenLevel(3)

            console.log("onRamp: walletIsGood(): closing Funder modal...")
            this.toggleShowWalletFunderModal()

            if ( this.state.isAnExpert ) {
                console.warn(' ')
                console.warn("User is an EXPERT, so, auto-clicking CLAIM...")
                this.claimDomain()
            }
        } else {
            console.warn("onRamp: we USED TO set wallet level here to TWO (claiming it was insufficient, because we weren't CLOSING it. WHAT?!)")
            console.warn("onRamp: Setting the user's citizenLevel to 3 - since his wallet is sufficient")
            console.log("Compare this with walletModal logic")
            //await this.props.changeCitizenLevel(3)
        }

    }

    toggleShowWalletFunderModal() {
        if ( this.state.showFunder ) {
            //setUserWantsToAddFunds
            this.setState({showFunder: false, setUserWantsToAddFunds: false})
        } else {
            this.setState({showFunder: true})
        }
    }
    showFunderModal() {
        console.log("Wallet Funder")
        this.setState({mountFunder: true, showFunder: true}); //, understandsBoxChecked: false})
    }

    claimDomain() {
        console.log("claim domain")
        //alert("Implement claim-domain")

        this.openClaimer()
    }

    // called by either the WalletFunder, or BitShizzleApp
    recordBalanceFromApp(balancePennies, bestUtxoPennies) {
        console.warn("ONRAMP: recordBalanceFromApp()")
        this.recordBalance(balancePennies, bestUtxoPennies)
    }
    recordBalanceFromFunder(balancePennies, bestUtxoPennies) {
        this.recordBalance(balancePennies, bestUtxoPennies)

        console.warn("ONRAMP: recordBalanceFromFunder(). We learned of the balance from our own walletFunder, and now we'll tell the APP what the balance is")
        this.props.returnBalanceToApp(balancePennies, bestUtxoPennies)
    }

    recordBalance(balancePennies, bestUtxoPennies) {
        this.balancePennies = balancePennies
        console.warn("The WalletFunder (or the APP) tells us we have roughly " + balancePennies + "¢ - in Bitcoin")

        this.bestUtxoPennies = bestUtxoPennies
        console.warn("The WalletFunder (or the APP) tells us our best UTXO has roughly " + bestUtxoPennies + "¢ - in Bitcoin")
        //alert("onramp now know bestUtxoPennies is " + bestUtxoPennies)
    }

    openDrawer() {
        console.warn("opening drawer...")
        this.setState({showDrawer: !this.state.showDrawer})
    }
    closeDrawer() {
        // also clear selection positions
        this.setState({showDrawer: false})
    }
    logoutThenCloseDrawer() {
        this.signInOutCallback()
        //if ( this.props.handleSignInOutClick ) {
        //    this.props.handleSignInOutClick(this.props.parent)
        //}
        this.closeDrawer()
    }

    //FIXME: we could/should specify the version/ownerCount we're looking for
    //FIXME: this doesn't check if domain is outdated
    async handleExploreClick() {
        let domainToJumpTo = ''
        let ownerCountToUse = 0

        if ( this.state.selectedDomain !== null ) {
            domainToJumpTo = this.state.selectedDomain
            ownerCountToUse = this.state.selectedOwnerCount
        } else if ( this.state.defaultID !== '') {
            domainToJumpTo = this.state.defaultID
            ownerCountToUse = this.state.defaultIdOwnerCount
        } else {
            domainToJumpTo = this.state.userDomains[0].name
            ownerCountToUse = this.state.userDomains[0].ownerCount
        }

        // we already know user has domain(s)
        await this.props.changeCitizenLevel(4)

        this.props.handleJumpToSurfer(domainToJumpTo, ownerCountToUse)
    }

    render() {

        //console.error("userDomains: ", this.state.userDomains)

        // when the walletModal, or a footer modal is showing,
        // disable the on-ramping buttons
        //   props.disableButtons is set when walletModal is showing
        const aModalIsShowing = this.state.footerModalInFocus || this.props.disableButtons

        // ***  DEVICE-DEPENDENT STYLING  ***
        // For mobile, "detect" landscape screen mode
        const landscape = this.props.isMobile ? window.innerWidth > window.innerHeight : false
        // For mobile, shrink the About, Security, and Glossary modals
        const modalClassName   = this.props.isMobile ?
                                        (landscape ?
                                                "modalMobileLandscape"
                                            :
                                                "modalMobilePortrait"
                                        )
                                    :
                                        ""
        const modalContentClassName = this.props.isMobile ? "modalContentMobile" : ""
        const modalBottomClassName  = this.props.isMobile ? "modalBottomMobile"  : ""

        const buttonA = <> &nbsp; &nbsp;
                            <Button disabled={aModalIsShowing} ref={this.refButtonA} animated='fade'
                                    onClick={this.showWalletSetupModal}
                                    style={{backgroundColor: "blue", color: "yellow", borderRadius: "30px"}} >
                                <Button.Content visible>
                                    &nbsp; &nbsp; &nbsp; GO &nbsp; &nbsp; &nbsp;
                                </Button.Content>
                                <Button.Content hidden>
                                    Setup
                                </Button.Content>
                            </Button>
                        </>
        const buttonB = <> &nbsp; &nbsp; &nbsp;
                            <Button disabled={aModalIsShowing} ref={this.refButtonB} animated='fade'
                                    onClick={this.verifyPassword}
                                    style={{backgroundColor: "blue", color: "yellow", borderRadius: "30px"}} >
                                <Button.Content visible>
                                    &nbsp; &nbsp; GO &nbsp; &nbsp;
                                </Button.Content>
                                <Button.Content hidden>
                                    Login
                                </Button.Content>
                            </Button>
                        </>
        const buttonC = <> &nbsp; &nbsp; &nbsp;
                            <Button disabled={aModalIsShowing} ref={this.refButtonC} animated='fade'
                                    onClick={this.showFunderModal}
                                    style={{backgroundColor: "blue", color: "yellow", borderRadius: "30px"}} >
                                <Button.Content visible>
                                    &nbsp; &nbsp; GO &nbsp; &nbsp;
                                </Button.Content>
                                <Button.Content hidden>
                                    Fund
                                </Button.Content>
                            </Button>
                        </>
        const buttonD = <> &nbsp; &nbsp; &nbsp;
                            <Button disabled={aModalIsShowing} ref={this.refButtonD} animated='fade'
                                    onClick={this.claimDomain}
                                    style={{backgroundColor: "blue", color: "yellow", borderRadius: "30px"}} >
                                <Button.Content visible>
                                    &nbsp; &nbsp; GO &nbsp; &nbsp;
                                </Button.Content>
                                <Button.Content hidden>
                                    Claim
                                </Button.Content>
                            </Button>
                        </>

        // NOTE: copied to surfer too
        let assetDropDown
        if ( this.state.userDomains?.length > 1 && this.props.citizenLevel === 4 ) {

            console.warn("dropList: ", this.state.dropList)
            //FIXME: ponder using || logic for disabled prop, below
            assetDropDown = <> Identity to use:   <Dropdown upward placeholder='Choose your ID'
                                                        disabled={this.state.defaultDomainIdx === -1 && this.state.selectedDomain === null}
                                                        selection
                                                        options={this.state.dropList}
                                                        defaultValue={this.state.defaultDomainIdx}
                                                        value={this.state.defaultDomainIdx}
                                                        search
                                                        onChange={this.handleAssetChange}
                                                />
                            </>
console.warn(" this.state.defaultDomainIdx: " + this.state.defaultDomainIdx)
console.warn(" this.state.selectedDomain: " + this.state.selectedDomain)
console.warn(" this.state.selectedOwnerCount: " + this.state.selectedOwnerCount)


        } else if ( this.state.userDomains?.length === 1 && this.props.citizenLevel === 4 ) {
            assetDropDown = <> Domain Claimed: <span style={{color: "blue", fontSize: "1.3rem"}}>{this.state.userDomains[0].name.toUpperCase()}</span></>
            // FIXME: set surf option?
        } else {
            assetDropDown = <></>
            // FIXME: disable surf option
        }

        const disableGo = this.state.userDomains?.length > 1 ?
                                    this.state.selectedDomain === null
                                :
                                    false

        const buttonE = <> &nbsp; &nbsp;
                            <Button disabled={aModalIsShowing || disableGo} animated='fade' onClick={() => this.props.handleJumpToSurfer(this.state.selectedDomain, this.state.selectedOwnerCount)}
                                    style={{backgroundColor: "blue", color: "yellow", borderRadius: "30px"}} >
                                <Button.Content visible>
                                    &nbsp; &nbsp; GO &nbsp; &nbsp;
                                </Button.Content>
                                <Button.Content hidden>
                                    &gt;&gt; &nbsp; Start &nbsp; &lt;&lt;
                                </Button.Content>
                            </Button>
                        </>

        //   <Button onClick={this.toggleOverlay} > toggle overlay </Button>
        //console.error("citizenLevel = ", this.props.citizenLevel)
        let circle1 = this.props.citizenLevel < 1 ?
                            this.emptyDarkCircle : this.filledDarkCircle
        let step1Color = this.bshzPurple
        let button1 = this.props.citizenLevel === 0 ? <>{buttonA}</> : <></>
        let arrow1  = this.props.citizenLevel < 1 ?
                            this.lightArrow : this.darkArrow

        let circle2 = this.props.citizenLevel < 1 ?
                            this.emptyLightCircle : 
                                this.props.citizenLevel < 2 ?
                                    this.emptyDarkCircle :this.filledDarkCircle
        let step2Color = this.props.citizenLevel < 1 ?
                            this.bshzLtPurp : this.bshzPurple
        let button2 = this.props.citizenLevel === 1 ? <>{buttonB}</> : <></>
        let arrow2  = this.props.citizenLevel < 2 ?
                            this.lightArrow : this.darkArrow

        let circle3 = this.props.citizenLevel < 2 ?
                            this.emptyLightCircle : 
                                this.props.citizenLevel < 3 ?
                                    this.emptyDarkCircle :this.filledDarkCircle
        let step3Color = this.props.citizenLevel < 2 ?
                            this.bshzLtPurp : this.bshzPurple
        let button3 = this.props.citizenLevel === 2 ? <>{buttonC}</> : <></>
        let arrow3  = this.props.citizenLevel < 3 ?
                            this.lightArrow : this.darkArrow

        let circle4 = this.props.citizenLevel < 3 ?
                            this.emptyLightCircle :
                                this.props.citizenLevel < 4 ?
                                    this.emptyDarkCircle :this.filledDarkCircle
        let step4Color = this.props.citizenLevel < 3 ?
                            this.bshzLtPurp : this.bshzPurple
        let button4 = this.props.citizenLevel === 3 ? <>{buttonD}</> : <></>
        let arrow4  = this.props.citizenLevel < 4 ?
                            this.lightArrow : this.darkArrow

        let step5Color = this.props.citizenLevel < 4 ?
                            this.bshzLtPurp : this.bshzPurple
        let button5 = this.props.citizenLevel === 4 ? <>{buttonE}</> : <></>

        let step5BackgroundColor = this.props.citizenLevel < 4 ?
                            "#ffffff" : this.bshzLightYellow

        const tagGenerate = <span style={{color: step1Color}}>Setup Your Browser Wallet </span>
        const popUpTextGenerate = <>Generate a wallet to hold funds you'll need for many things you'll want to do on {this.bitShizzle}. This is also where
                                    you can move funds you may earn from fans/followers.</>
        const popupGenerate = <Popup style={{backgroundColor: this.bshzLightYellow}} trigger={tagGenerate} wide="very" content={popUpTextGenerate} hideOnScroll/>

        const tagVerify = <span style={{color: step2Color}}>Verify Your Wallet Password </span>
        const popUpTextVerify = <>Verify that you've properly recorded your wallet password.</>
        const popupVerify = <Popup style={{backgroundColor: this.bshzLightYellow}} trigger={tagVerify} wide="very" content={popUpTextVerify} hideOnScroll/>

        const tagFund = <span style={{color: step3Color}}>Fund Your Browser Wallet </span>
        const popUpTextFund = <>Send Bitcoin to your wallet. Many things you'll do on {this.bitShizzle} will cost some miniscule amount of Bitcoin.</>
        const popupFund     = this.props.citizenLevel >= 2 ?
                                <Popup style={{backgroundColor: this.bshzLightYellow}} trigger={tagFund} wide="very" content={popUpTextFund} hideOnScroll/>
                                    :
                                <>{tagFund}</>

        const tagClaim  = <span style={{color: step4Color}}>Claim a Name/Domain </span>
        const popUpTextClaim = <>Claim your very own Bitcoin Account/Domain/Name. This isn't actually <i>required</i>,
                                    but until you do, you won't be able fully participate (comment, post, subscribe to, nor communicate with other 'Shizzlers').
                                </>
        const popupClaim     = this.props.citizenLevel >= 3 ?
                                <Popup style={{backgroundColor: this.bshzLightYellow}} trigger={tagClaim} wide="very" content={popUpTextClaim} hideOnScroll/>
                                    :
                                <>{tagClaim}</>

        const funderCancelButton = this.props.citizenLevel < 3 || this.state.showFunderCancelButton ?
                    <Button negative onClick={this.toggleShowWalletFunderModal} content='Cancel'/>
                            :
                    <>&nbsp;</>



        const restoreWalletModal = this.state.showRestoreModal ?
                    <WalletImporter closeWalletImportModal={this.closeRestorer}
                                    userNowHasWallet={this.userHasWallet}
                                    showOkModal={this.showGenericModal}

                                    isMobile={this.props.isMobile}
                                    />
                    : null;

        // We use a local callback (instead of a props.handleSignInOutClick)
        // because we have our own WalletVerifier to show
        const pwdCallback = this.props.citizenLevel > 0 ?
                                            this.signInOutCallback
                                        :
                                            null

        // Focus on the current step button Setup/Verify/Fund/Claim
        if ( this.props.citizenLevel === 0 && !this.state.showRestoreModal && !this.state.showGenerate && !this.state.showVerifier && !this.state.showGenericOkModal ) {
            console.log(" ----> will focus on refButtonA <----")
            this.refButtonA?.current?.focus();

        } else if ( this.props.citizenLevel === 1 && !this.state.showGenerate && !this.state.showFunder && !this.state.showVerifier && !this.state.showGenericOkModal ) {
            console.log(" ----> will focus on refButtonB <----")
            this.refButtonB?.current?.focus();

        } else if ( this.props.citizenLevel === 2 && !this.state.showFunder && !this.state.showVerifier && !this.state.showGenericOkModal ) {
            console.log(" ----> will focus on refButtonC <----")
            this.refButtonC?.current?.focus();
        } else if ( this.props.citizenLevel === 3 && !this.state.showFunder && !this.state.showGenericOkModal) {
            console.log(" ----> will focus on refButtonD <----")
            this.refButtonD?.current?.focus();
        } else if ( this.state.showGenericOkModal ) {
            console.log(" ----> will focus on refOkModal <----")
            this.refOkButton?.current?.focus();
        }

        const maybeShowPriceAndBalance = <></>
        /*
                :
                    <>
                        <p>
                            &nbsp; &nbsp;Bitcoin Price (USD): ${this.props.bsvPriceUSD}<br></br>
                            &nbsp; &nbsp;Current Wallet Balance (USD): {this.balancePennies}¢<br></br>
                            &nbsp; &nbsp;Best UTXO balance (USD): {this.bestUtxoPennies}¢<br></br>
                        </p>
                    </>
        */

        const disableJump = this.state.selectedDomain === null //&& this.state.defaultID === ''         // FIMXE:  & myDomains.length === 0?
        const surfOption = disableJump ?
                    <>
                        <span style={{color:'grey'}}>Explore</span>
                    </>
                :
                    <>
                        <span onClick={this.handleExploreClick}>Explore</span>
                    </>

        const walletIcon = <Icon link size='large' color="purple" name='money bill alternate' onClick={this.props.handleWalletClick}/>
        const logOutIcon = <Icon link size='large' color="purple" name='toggle on' onClick={this.logoutThenCloseDrawer}/>
        const surfIcon   = <Icon link size='large' color="purple" name='rocket' onClick={this.citizenOptions} disabled={disableJump}/>

        const maybeSurf = this.state.userDomains.length > 0 ?
                        <>
                            <br></br>
                            {surfIcon} &nbsp;
                            {surfOption}
                            <br></br>
                        </>
                    :
                        <></>

        const maybeClaimer = this.state.showClaimer ?
                    <>
                    <Claimer    getOfficialWallet={this.props.getOfficialWalletRecord}
                                    bundle={this.bundle}
                                    claimedCallback={this.userClaimedAsset}
                                    bsvPriceUSD={this.props.bsvPriceUSD}
                                    balancePennies={this.balancePennies}
                                    bestUtxoPennies={this.bestUtxoPennies}

                                    isMobile={this.props.isMobile}

                                    blockHeight={this.props.blockHeight}
                                    />

                    </>
                :
                    <></>

        // This is actually a different instance than if triggered
        // by the side-panel (which results in the APP instantiating one)
        const maybeWalletFunder = this.state.mountFunder ?
                    <>
                    <WalletFunder   parent={this}
                                    showFunder={this.state.showFunder}
                                    closeFunder={this.toggleShowWalletFunderModal}
                                    getOfficialWallet={this.props.getOfficialWalletRecord}
                                    walletIsSufficient={this.walletIsGood}
                                    setUserWillAddFunds={this.setUserWantsToAddFunds}
                                    bundle={this.bundle}
                                    bsvPriceUSD={this.props.bsvPriceUSD}
                                    fromSettings={false}
                                    returnBalance={this.recordBalanceFromFunder}

                                    isMobile={this.props.isMobile}
                                    />
                    </>
                :
                    <></>

        const sidebarClassname = this.props.isMobile ? (landscape ? 'my_phone_landscape_sidebar' : 'my_phone_sidebar') : 'my_sidebar'

        // removed masthead wallet callback prop, in favor of sidebar:
        //                              walletCallback={this.props.handleWalletClick}
        console.error("OnRamp: mountFunder: " + this.state.mountFunder + ",   showFunder: ", this.state.showFunder)
        return(
            <>
            <div id="container">
                <div id="header">
                        <ShizzleMasthead blank={true} qualityTxs={this.state.qualityTxs}
                                        passwordVerified={this.props.citizenLevel > 1}
                                        logInOutCallback={pwdCallback}

                                        sidebarCallback={this.openDrawer}
                                        handleJumpToHome={this.props.handleJumpToHome}
                                        />
                </div>
                <div id="body">
                    <div style={{paddingTop: "90px", backgroundImage: 'url("paleGreySmoke.jpg")'}} className="cont">
                        <p></p>

                        <h1> &nbsp; Getting Started...</h1>
                        {maybeShowPriceAndBalance}

                        <Sidebar.Pushable style={{ transform: "none" }}>
                            <Sticky context={this.stickyRef} offset={92} bottomOffset={0}>
                                <Sidebar className={sidebarClassname} style={{padding: 8, position: 'fixed', top: "92px", bottom: "0px", overflowY: "auto"}}
                                        animation='overlay'
                                        direction='right'
                                        icon='labeled'
                                        onHide={() => this.closeDrawer()}

                                        visible={this.state.showDrawer}
                                >
                                    <div style={{padding: 0, fontSize:'1.2rem'}}>
                                    <Segment style={{overflow: 'auto', maxHeight: '100%', backgroundColor:bshzColors.ltPurple, padding: 8 }}>

                                        <Icon name='window close outline' onClick={this.closeDrawer} size='large' color='black'/>
                                        <br></br>
                                        <br></br>
                                        {walletIcon} &nbsp;
                                        <span onClick={this.props.handleWalletClick}>Your Wallet</span>
                                        <br></br>

                                        {maybeSurf}

                                        <Divider style={{padding: 0}}/>

                                        {logOutIcon} &nbsp;
                                        <span onClick={this.logoutThenCloseDrawer}>Logout</span>
                                    </Segment>
                                    </div>
                                </Sidebar>
                            </Sticky>
                        </Sidebar.Pushable>

                        <div style={{padding: "0px 5px 0px 12px"}}><span style={{color: "blue", fontSize: "1.4rem"}}>Welcome! </span>You're about to join the ShizzleVerse community - in four easy steps. Enjoy the ride.</div> <br></br>
                        &nbsp; &nbsp; &nbsp; &nbsp; {circle1} &nbsp;
                        {popupGenerate} {button1}<br></br><br></br>
                        &nbsp; &nbsp; &nbsp; &nbsp; {arrow1}
                        <br></br><br></br>
                        &nbsp; &nbsp; &nbsp; &nbsp; {circle2} &nbsp;
                        {popupVerify} {button2}<br></br><br></br>
                        &nbsp; &nbsp; &nbsp; &nbsp; {arrow2}
                        <br></br><br></br>
                        &nbsp; &nbsp; &nbsp; &nbsp; {circle3} &nbsp;
                        {popupFund} {button3}<br></br><br></br>
                        &nbsp; &nbsp; &nbsp; &nbsp; {arrow3}
                        <br></br><br></br>
                        &nbsp; &nbsp; &nbsp; &nbsp; {circle4} &nbsp;
                        {popupClaim} {button4}<br></br><br></br>
                        &nbsp; &nbsp; &nbsp; &nbsp; {arrow4}
                        <br></br>
                        &nbsp; &nbsp;
                        <table style={{ marginLeft: "20px", borderCollapse: "collapse"}}>
                            <colgroup>
                                <col  span="1" style={{width: "450px", border: "1px solid "+step5Color, backgroundColor: step5BackgroundColor}} />
                                <col  span="1" style={{width: "50px"}}/>
                                <col  span="1" style={{width: "120px"}}/>
                            </colgroup>
                            <tbody>
                            <tr style={{marginLeft: "20px"}}>
                                <td style={{padding: "15px 10px 0px 15px" }}>
                                    <span style={{color: step5Color}}><b>Congratulations!</b> You're now a full citizen of the ShizzleVerse.</span>
                                </td>
                            </tr>
                            <tr>
                                <td style={{padding: "5px 15px 0px 15px", color: step5Color }}>
                                    You can now:
                                        <ul style={{margin: "5px 0px 15px 0px"}}>
                                            <li>Post/Tweet/Blog on your own Bitcoin account</li>
                                            <li>Comment directly on the postings of others</li>
                                            <li>Subscribe to the feeds of other 'Shizzlers'</li>
                                            <li>Take notes (encrypted postings to yourself)</li>
                                            <li>Publish arbitrary smart contracts</li>
                                            <li>Converse directly with other 'Shizzlers'</li>
                                            <li>Build your own Bitcoin website</li>
                                            <li>Sell your account/domain/name (after one year)</li>
                                        </ul>
                                </td>
                                <td >&nbsp;</td>
                                <td><br></br> &nbsp;<br></br><br></br></td>
                            </tr>
                            <tr>
                                <td></td>
                                <td></td>
                                <td></td>
                            </tr>

                            </tbody>
                        </table>
                        &nbsp; &nbsp;{assetDropDown}
                        {button5}
                    </div>
                    {restoreWalletModal}
                </div>

                <Footer enforceTerms={true}
                        topOfFooter=''
                        isMobile={this.props.isMobile}
                        jiggleFooter={true}
                        sendEmail={this.props.sendEmail}

                        footerFocus={this.footerFocus}
                        />


                <Modal size='small' centered className={modalClassName}  open={this.state.showGenerate} style={{backgroundColor: this.bshzPurple, borderRadius: '20px'}}>
                <Modal.Header style={{textAlign: 'center', backgroundColor: this.bshzPurple, borderRadius: '20px'}}>
                    <span style={{color: this.bshzYellow}}> Setup Your Browser Wallet</span>
                </Modal.Header>
                <Modal.Content className={modalContentClassName} scrolling style={{backgroundColor: this.bshzLtPurp}}>

                    <WalletGenerator    userNowHasWallet={this.userHasWallet}
                                        showOkModal={this.showGenericModal}
                                        restoreWallet={this.handleRestoreOption}
                                        isMobile={this.props.isMobile}/>

                </Modal.Content>
                <Modal.Actions className={modalBottomClassName} style={{backgroundColor: this.bshzPurple, borderRadius: '0px 0px 20px 20px'}}>
                    <div style={{textAlign: 'center'}}>
                        <Button negative onClick={this.toggleShowWalletSetupModal} content='Cancel'/>
                    </div>
                </Modal.Actions>
                </Modal>


                <Modal size='small' centered className={modalClassName}  open={this.state.showVerifier} style={{backgroundColor: this.bshzPurple, borderRadius: '20px'}}>
                <Modal.Header style={{textAlign: 'center', backgroundColor: this.bshzPurple, borderRadius: '20px'}}>
                    <span style={{color: this.bshzYellow}}> Verify Your Wallet Password</span>
                </Modal.Header>
                <Modal.Content className={modalContentClassName} scrolling style={{backgroundColor: this.bshzLtPurp}}>

                    <WalletVerifier userRemembersPwd={this.userRemembersPwd}
                                    userDeletedWallet={this.userDeletedWallet}
                                    getOfficialWallet={this.props.getOfficialWalletRecord}
                                    showOkModal={this.showGenericModal}
                                    returnBundle={this.returnBundle}
                                    finishReset={this.fullResetPerformed}

                                    isMobile={this.props.isMobile}
                                    />

                </Modal.Content>
                <Modal.Actions className={modalBottomClassName} style={{backgroundColor: this.bshzPurple, borderRadius: '0px 0px 20px 20px'}}>
                    <div style={{textAlign: 'center'}}>
                        <Button negative onClick={this.toggleShowPwdVerifyModal} content='Cancel'/>
                    </div>
                </Modal.Actions>
                </Modal>



                {maybeWalletFunder}


                {maybeClaimer}


                <Modal size='small' centered className={modalClassName}  open={this.state.showGenericOkModal} style={{backgroundColor: this.bshzPurple, borderRadius: '20px'}}>
                <Modal.Header style={{textAlign: 'center', backgroundColor: this.bshzPurple, borderRadius: '20px'}}>
                    <span style={{color: this.bshzYellow}}> {this.state.genModalTitle}</span>
                </Modal.Header>
                <Modal.Content className={modalContentClassName} scrolling style={{backgroundColor: this.bshzLtPurp}}>

                    {this.state.genModalText}

                </Modal.Content>
                <Modal.Actions className={modalBottomClassName} style={{backgroundColor: this.bshzPurple, borderRadius: '0px 0px 20px 20px'}}>
                    <div style={{textAlign: 'center'}}>
                        <Button ref={this.refOkButton} positive onClick={this.handleGenericModalOk} content='OK'/>
                    </div>
                </Modal.Actions>
                </Modal>
            </div>
            </>
        )
    }
}

export default OnRamp;