"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var random_1 = require("@dicebear/avatars/lib/random");
var avataaars_1 = require("avataaars");
var React = require("react");
var utils_1 = require("./utils");
var Avatar = (function (_super) {
    __extends(Avatar, _super);
    function Avatar() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Avatar.prototype.render = function () {
        var _a = this.props, className = _a.className, _b = _a.hash, hash = _b === void 0 ? 'hash' : _b, _c = _a.size, size = _c === void 0 ? 260 : _c, _d = _a.options, options = _d === void 0 ? {} : _d;
        var width = size;
        var height = size;
        var random = new random_1.default(hash);
        var hairColor = utils_1.getHairColor(options, random);
        var facialHairColor = options.facialHairColor ? utils_1.getFacialHairColor(options, random) : hairColor;
        return (React.createElement("div", { className: className, style: { width: width, height: height } },
            React.createElement(avataaars_1.default, { style: { width: '100%', height: '100%' }, avatarStyle: utils_1.getAvatarStyle(options), topType: utils_1.getTopType(options, random), accessoriesType: utils_1.getAccessoriesType(options, random), hairColor: hairColor, facialHairType: utils_1.getFacialHairType(options, random), facialHairColor: facialHairColor, clotheType: utils_1.getClotheType(options, random), clotheColor: utils_1.getClotheColor(options, random), eyeType: utils_1.getEyeType(options, random), eyebrowType: utils_1.getEyebrowType(options, random), mouthType: utils_1.getMouthType(options, random), skinColor: utils_1.getSkinColor(options, random) })));
    };
    return Avatar;
}(React.PureComponent));
exports.Avatar = Avatar;
