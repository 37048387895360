//
// WalletFunder
//
// Manage UTXOs of a single key - consolidating if deemed necessary.
// Require balance is at a reasonable level - offloading funds if deemed too rich,
// or prompting user for more funds if deemed too low.
// Also allow freezing and thaawing individual coins.
//

import React from 'react';

import { Button, Checkbox, Divider, Form, Icon, Input, Modal, Radio, Table } from 'semantic-ui-react'

import Toast    from './toast';

import  { readSetting, openDB, saveSetting } from './buildShizzle';

import './index.css';

import {QRCodeSVG} from 'qrcode.react';

import QrScanModal from './qrScanModal.js';

import { convertAddressToPKH, persistUtxoFrozen, persistUtxoUnFrozen } from './commonFuncs.js';

import { NETWORK,
         TARGET_RATE,
         MIN_WALLET_SATS,
         MAX_WALLET_UTXO_COUNT,
         MAX_WALLET_DOLLARS_A, MAX_WALLET_DOLLARS_B } from './harnessConstants'

//const { io } = require('socket.io-client')

const { buildBalanceReducingTx, buildConsolidatingTx } = require('./contractSupport.js')
const { fetchUtxos } = require('./providers.js')

/**
 * Compares a FRESH scan of utxos agains an already-cached copy.
 * Notices new arrivals, and caches them.
 * Notices missing/spent utxos, and alerts user about them.
 * Re-freezes fresh copies of already-cached-and-frozen utxos.
 *
 * @param {*} freshUtxos
 * @returns
 */
function compareUtxosAgainstLocalCache(freshUtxos) {
    freshUtxos.forEach( ( item, index ) => {
        console.warn("FRESH utxo[" + index + "]:  " + item.satoshis + " sats   outputIndex: " + item.outputIndex + "   txId: " + item.txId.slice(0,12) + "...")
    })
    console.log(" ")
    if ( window.localUTXOs ) {
        window.localUTXOs.forEach( (item, index) => {
            console.warn("CACHED utxo[" + index + "]:  " + item.satoshis + " sats   outputIndex: " + item.outputIndex + "   txId: " + item.txId.slice(0,12) + "...")
        })
    } else {
        alert("??? we found no cache of utxos??? CODE ERROR")
        return
    }

    console.log(" ")
    console.warn("For each old/cached utxo, does it still exist?")
    const spentUTXOs = []
    const newUTXOs = []
    window.localUTXOs.forEach( (cachedItem, cachedIndex) => {
        let found = false
        for ( const freshItem of freshUtxos ) {
            if ( cachedItem.txId === freshItem.txId &&
                 cachedItem.outputIndex === freshItem.outputIndex ) {
                    found = true
                    console.log("found cached item " + cachedIndex + " in the fresh utxos. It still exists.")
                    break
            }
        }
        if ( !found ) {
            console.warn("We could NOT find this cached item (#" + cachedIndex + ") in freshUtxos:  outputIndex " + cachedItem.outputIndex + " of tx " + cachedItem.txId.slice(0,12) + "...")
            spentUTXOs.push( cachedItem )
        }
    })

    console.log(" ")
    console.warn("For each fresh utxo, is it NEW?")
    freshUtxos.forEach( (freshItem, freshIndex) => {
        let found = false
        for ( const cachedItem of window.localUTXOs ) {
            if ( cachedItem.txId === freshItem.txId && cachedItem.outputIndex === freshItem.outputIndex ) {
                found = true
                console.log("found fresh item " + freshIndex + " in the cached utxos. It's not new.")

                // preserve any user preference for FREEZING a coin
                if ( cachedItem.FROZEN ) {
                    console.error("FREEZING fresh copy of cached utxo: " + cachedItem.outputIndex + " : " + cachedItem.txId)
                    //alert("note: we noticed one fresh copy of cached utxo should be FROZEN")
                    freshItem.FROZEN = true
                }
                break
            }
        }
        if ( !found ) {
            console.warn("We could NOT find this fresh item (#" + freshIndex + ") in cache:  outputIndex " + freshItem.outputIndex + " of tx " + freshItem.txId.slice(0,12) + "...  That's FINE. It's been received from outside.")
            newUTXOs.push( freshItem)
        }
    })

    console.log(" ")
    console.warn("After full analysis, we found " + newUTXOs.length + " brand-new UTXOs (received from outside)")

    if ( newUTXOs.length > 0 ) {
        let totalSats = 0
        for ( const newUtxo of newUTXOs ) {
            totalSats += newUtxo.satoshis

            window.localUTXOs.push(newUtxo)
        }
        alert("We've found " + newUTXOs.length + " new coins in your wallet:  " + totalSats + " satoshis added")
    }

    if ( spentUTXOs.length > 0 ) {
        console.warn("We found " + spentUTXOs.length + " UTXOs that have gone missing. They've slipped through the cracks of our code, and are un-accounted for. NOT GOOD")
        alert("We've detected that some cached UTXOs are no longer accounted for. Please report this. This is a CODE ERROR.")
        alert("The OTHER possibility is that you're operating in more than 1 browser or device. If so, then this makes sense.")
        alert("This condition has never been seen before. Please report this.")
        alert("IMPLEMENT ME: WARNING: missing UTXOs should be marked as such.")
    } else {
        console.warn("We found that NO UTXOs have gone missing. All is well, so far.")
    }
}

/**
 * Sift through UTXOs, to determine various important matters
 * Identify individual coins/utxos (that aren't frozen)
 *
 * @param {*} utxos
 * @param {*} minSats
 * @param {*} maxSats1
 * @param {*} maxSats2
 * @param {*} extraSatsForOverageReduction
 * @param {*} simplestFee - fee for 1-input, 1-output transaction
 * @returns
 */
function analyzeUTXOs(utxos, minSats, maxSats1, maxSats2, extraSatsForOverageReduction, simplestFee) {

    if ( utxos.length === 0 ) {
        return {
            sufficient: false,
            runningLow: true,
            plenty: false,
            tooRich: false,
            needsConsolidating: false,
            bestUTXO: null,
            largestUtxoFunds: 0,
            satsSum: 0,
            overage: 0,
            numUTXOs: 0,

            dustyUtxoCount: 0,
            dustyFundsTotal: 0,
            nonDustyUTXOs: [],

            frozenCoinsCount: 0,
            frozenFundsTotal: 0,
        }
    }

    // arbitrary threshold: UTXOs with funds less than this
    // amount, are 'dusty' - perhaps not worthy of use at the current fee rate
    // For fee rate of 0.05 sats/byte, that works out to 30 sats
    const dustyThreshold = 3 * simplestFee

    let overage = 0

    let frozenCoinsCount = 0
    let frozenFundsTotal = 0

    let largestUtxoFunds = 0
    let satsSum = 0
    let dustyUtxoCount = 0
    let dustyFundsTotal = 0
    let goodUTXOs = []
    let bestUTXO = null
    for ( let i = 0; i < utxos.length; i++ ) {
        const thisUTXO = utxos[i]
        const theseFunds = thisUTXO.satoshis

        if ( thisUTXO.FROZEN ) {
            // skip frozen coins
            frozenCoinsCount++
            frozenFundsTotal += theseFunds

            console.warn("utxo " + i + " (" + theseFunds + "sats) is FROZEN, so, segregating it:\n", thisUTXO)

        // segregate dusty UTXOs
        // DON'T add them into satsSum <----
        } else if ( theseFunds < dustyThreshold ) {
            dustyUtxoCount++
            dustyFundsTotal += theseFunds

            console.warn("utxo " + i + " (" + theseFunds + "sats) is DUSTY, so, segregating it:\n", thisUTXO)

        } else {
            satsSum += theseFunds
            goodUTXOs.push( thisUTXO )

            console.warn("utxo " + i + " (" + theseFunds + "sats) is FINE, so, putting it in GOOD list:\n", thisUTXO)

            if ( theseFunds > largestUtxoFunds ) {
                largestUtxoFunds = theseFunds
                bestUTXO = thisUTXO
            }
        }
    }

    console.warn("      analyzeUTXOs(): Total funding, in " + goodUTXOs.length + " UTXOS: " + satsSum + " sats")
    if ( bestUTXO !== null ) {
        console.warn("      analyzeUTXOs(): Having looked through " + utxos.length
                + " UTXOs for that address, we found that the largest funding was for "
                + largestUtxoFunds + " sats (outpoint "
                + bestUTXO.txId + " : " + bestUTXO.outputIndex)
    }

    // Can we proceed with this address?
    //     - are there enough funds?
    //     - is there a SINGLE utxo with enough funds?
    //FIXME: rule-of-thumb to judge what might be 'viable' for funding most transactions
    let sufficient = false
    const runningLow = satsSum < minSats * 2 // was .25 * maxSats
    const plenty = satsSum > minSats * 15 || satsSum > maxSats2 * 0.5
    console.log("plenty is ", plenty)
    let needsConsolidating = false
    const maxUtxoCount = MAX_WALLET_UTXO_COUNT // max GOOD UTXOs. we now ignore any dusty UTXOs
    // NOTE: if we're not so quick to consolidate, we have MIGHT have more opportunites
    //       for UTXOs that have already confirmed. TBD

    // Check if wallet PROBABLY can proceed - very rough check
    //   Could proceed even if there are many utxos (after consolidating)
    if ( largestUtxoFunds > minSats || (satsSum/goodUTXOs.length > 500 && satsSum > (minSats+100)) ) {
        // at worst, we might have to consolidate
        console.log("analyzeUTXOs(): building entry with .numUTXOs of " + goodUTXOs.length)
        sufficient = true

        // Don't bother consolidating if we DON'T have a large enough UTXO (MINIMUM for proceeding)
        //  - unless there are LOTS of utxos, OR, we MUST
        if ( (largestUtxoFunds < minSats && goodUTXOs.length > 2*maxUtxoCount) ||
             (largestUtxoFunds >= minSats && goodUTXOs.length > maxUtxoCount ) ) {
            needsConsolidating = true
            console.warn("=======> we should consolidate these coins")
        }
    } else if ( satsSum/goodUTXOs.length > 500 && goodUTXOs.length > 2 * maxUtxoCount ) {
        console.warn("=======> Even though there aren't enough funds yet, we should consolidate these coins: " + utxos.length + " of them")
        needsConsolidating = true
    }

    //alert("largest: " + largestUtxoFunds + ",  maxSats1: " + maxSats1)
    //FIXME: is it possible that all of these rules-of-thumb are too rough?
    //       Is it possible we would't set 'sufficient' to true, but might set 'tooRich' to true?
    //       (or some other seemingly-nonsensical result?)
    let tooRich = false
    if ( largestUtxoFunds > maxSats1 || (satsSum/goodUTXOs.length > 500 && satsSum > maxSats2) ) {
        // at worst, we might have to consolidate
        console.log("analyzeUTXOs(): This wallet has too much in it: " + satsSum)
        tooRich = true

        // very rough estimate
        //NOTE: we over-report overage to avoid frequently hitting up against the limit
        overage = satsSum - maxSats1 + extraSatsForOverageReduction
        console.log("analyzeUTXOs(): overage: " + overage)
    }

    return {
        sufficient: sufficient,
        runningLow: runningLow,
        plenty: false,                     // sweet spot
        tooRich: tooRich,
        needsConsolidating: needsConsolidating,
        bestUTXO: bestUTXO,                 // not really needed
        largestUtxoFunds: largestUtxoFunds, // not really needed
        satsSum: satsSum,
        overage: overage,                   // ballpark excess sats
        numUTXOs: goodUTXOs.length, // utxos.length,   <---- only report on USEFUL UTXOs

        dustyUtxoCount: dustyUtxoCount,
        dustyFundsTotal: dustyFundsTotal,
        nonDustyUTXOs: goodUTXOs,

        frozenCoinsCount: frozenCoinsCount,
        frozenFundsTotal: frozenFundsTotal,
    }
}

class WalletFunder extends React.PureComponent {
    constructor(props) {
        super(props);

        this.bitShizzle          = <><b>BitShizzle</b><small>&trade;</small></>;

        //FIXME: the purples are currently duplicated in index.css
        this.bshzPurple             = '#8313e2';
        this.bshzYellow             = "#fff300";
        this.bshzLtPurp             = '#e7d0fb';
        this.bshzLightYellow        = "#fffccc";

        // NOTE: it takes 12 sats to send from 1 input, to 2 outputs
        //       based on 0.05 sats/byte  (ends-up settling at 0.05309 sats/byte)
        //       and where 1-input, 2-output P2PKH tx is 226 bytes
        //
        //       226 bytes * 0.05309 sats/byte = 12
        //
        //       WRONG. Fees have dropped. Let's just calculate.
        //
        //       Whoops: with fee rate now so low (.0013 s/B), use min 1 sat
        this.feeSats_1_Input_2_Outputs = Math.max( Math.round( 226 * TARGET_RATE ), 1 )

        // 192 bytes for 1-output tx
        //       Whoops: with fee rate so low (.0013 s/B), use min 1 sat
        this.feeSats_1_Input_1_Output  = Math.max( Math.round( 192 * TARGET_RATE ), 1 )

        console.log("fee for 1 output: (round) ", this.feeSats_1_Input_1_Output)
        console.log("fee for 2 outputs: (round) ", this.feeSats_1_Input_2_Outputs)

        this.state = {
            understandsBoxChecked: false,
            pwd:                    '',
            showPasswordInputField: false,
            walletAnalysis: null,

            willAddFunds:  false,
            willSendFunds: false,
            emptyTheWallet: false,
            amountToSend: '',

            sendToAddress: '',
            sendToPKH:     '',

            showSentModal: false,
            showConsolidatedModal: false,

            showToastThenScan: false,  // used by soonScan(), and toastDone()
            toastText: '',
            toastDuration: 800,
            showQuickToast: false,     // used by quickToast(), and quickToastDone()
            suppressLoader: false,

            isAnExpert: false,

            showAdvancedFeatures: false,   // use this to freeze/unfreeze P2PKH UTXOs
            selectedUtxoIndex:    0,

            showTheQRScanner: false,

            showWrongNetworkModal: false,
        }

        this.advancedWalletFeatures         = this.advancedWalletFeatures.bind(this);
        this.closeAdvancedWalletFeatures    = this.closeAdvancedWalletFeatures.bind(this);
        this.toggleUtxoFreeze               = this.toggleUtxoFreeze.bind(this);

        this.setWillAddFunds     = this.setWillAddFunds.bind(this)
        this.setWillSendFunds    = this.setWillSendFunds.bind(this)
        this.scanUTXOs           = this.scanUTXOs.bind(this)
        this.handleSendAddress   = this.handleSendAddress.bind(this)
        this.reduceWalletBalance = this.reduceWalletBalance.bind(this)
        this.consolidateCoins    = this.consolidateCoins.bind(this)

        this.toggleEmptyTheWallet= this.toggleEmptyTheWallet.bind(this)
        this.handleAmountToSend  = this.handleAmountToSend.bind(this)

        this.handleSentModalOk   = this.handleSentModalOk.bind(this);

        this.handleConsolidatedModalOk      = this.handleConsolidatedModalOk.bind(this);
        this.handleWrongNetworkModalOk      = this.handleWrongNetworkModalOk.bind(this);

        this.soonScan            = this.soonScan.bind(this);
        this.toastDone           = this.toastDone.bind(this);

        this.quickToast          = this.quickToast.bind(this);
        this.quickToastDone      = this.quickToastDone.bind(this);

        this.reportBalanceToParent = this.reportBalanceToParent.bind(this)

        this.copyWalletAddressToClipboard   = this.copyWalletAddressToClipboard.bind(this);

        this.showQRScanner                  = this.showQRScanner.bind(this);
        this.closeQRScanner                 = this.closeQRScanner.bind(this);
        this.reportScannedAddress           = this.reportScannedAddress.bind(this);

        this.satsPerDollar = (100000000 / this.props.bsvPriceUSD ).toFixed(0)

        // This SHOULD be related to the prevailing miner fee rates. [ see harnessConstants.js ]
        this.minWalletSats  =    MIN_WALLET_SATS   // was 100000, now 60000

        //alert("Satoshis per US Dollar: " + this.satsPerDollar.replace(/\B(?=(\d{3})+(?!\d))/g, ',')) // toLocaleString('en-US') )

        this.maxWalletSats1 =    Math.floor( this.satsPerDollar * MAX_WALLET_DOLLARS_A) //3.00
                                //38000

        this.maxWalletSats2 =    Math.floor( this.satsPerDollar * MAX_WALLET_DOLLARS_B) //3.001 )  // a little extra to allow for consolidation
                                //38100

        //alert("max1: " + this.maxWalletSats1)
        //alert("max2: " + this.maxWalletSats2)

        // How much EXTRA to reduce wallet balance if it's gotten too high
        // settle to reduce to 95% of full
        this.extraSatsForOverageReduction = Math.floor( this.satsPerDollar * 0.05 )
                                            //100

        //alert("extra: " + this.extraSatsForOverageReduction)
    }

    advancedWalletFeatures() {

        alert("Here you may freeze, or un-freeze coins on THIS browser. If you also use this wallet on other browsers or devices, you will find that coin status there is unaffected.\n\n" +
              "SIMILARLY: saving your wallet to a file will NOT save the frozen/un-frozen status of coins."
            )

        this.setState({showAdvancedFeatures: true})
    }
    closeAdvancedWalletFeatures() {
        this.setState({showAdvancedFeatures: false})

        if (!this.state.walletAnalysis?.sufficient) {
            alert("Heads up! Your wallet funds seem to be insufficient.\n\nHave you frozen too many coins?\n\nYou may need to add more funds (or un-freeze some).")
        }
    }
    async toggleUtxoFreeze() {
        const currVal = window.localUTXOs[ this.state.selectedUtxoIndex ]?.FROZEN

        window.localUTXOs[ this.state.selectedUtxoIndex ].FROZEN = !currVal

        // persist these settings in IDB
        if ( !currVal ) {
            await persistUtxoFrozen( window.localUTXOs[this.state.selectedUtxoIndex] )
        } else {
            await persistUtxoUnFrozen( window.localUTXOs[this.state.selectedUtxoIndex] )
        }

        // re-analyze utxos - given any recent change in UTXO status
        await this.scanUTXOs(false)

        // The modal won't re-draw unless we alter some state (which we haven't done)
        // Bounce this flag to accomplish that
        this.setState({showAdvancedFeatures: false}, () => {this.setState({showAdvancedFeatures: true})})
    }

    toggleEmptyTheWallet() {
        console.log("Toggling emptyTheWallet. Was " + this.state.emptyTheWallet)
        if ( !this.state.emptyTheWallet ) {
            this.setState({emptyTheWallet: true, amountToSend: ''})
        } else {
            this.setState({emptyTheWallet: false, amountToSend: ''})
        }
    }
    handleAmountToSend(e, v) {
        console.log("amount to send?: ", v.value)

        if ( !v.value.includes(".") && (v.value === '' || v.value >= 0) && v.value.length < 11 ) {
            this.setState({amountToSend: v.value})
            console.log("   Yes. amount to send: ", v.value)
        } else {
            e.preventDefault();
        }
    }

    async componentDidMount() {

        let isAnExpert
        const expertRecord = await readSetting(await openDB(), "isAnExpert")
        console.log("walletFunder: componentDidMount(): current isAnExpert record: ", expertRecord)
        if ( expertRecord === null ) {
            isAnExpert = false
            console.log("walletFunder: null setting for isAnExpert record")
        } else {
            isAnExpert = expertRecord.value
            console.log("walletFunder: new setting for isAnExpert: TRUE")
        }


        console.warn("WF componentDidMount(): will scanUTXOs()...")
        // this might result in an otherwise-unprompted consolidation
        const abortExpertStreamline = await this.scanUTXOs(false)

        if ( isAnExpert && !this.props.fromSettings && !abortExpertStreamline ) {
            console.warn("walletFunder EXPERT:   walletAnalysis: ", this.state.walletAnalysis)
            console.log("   analysis.sufficient: " + this.state.walletAnalysis?.sufficient)
            console.log("   analysis.tooRich: " + this.state.walletAnalysis?.tooRich)

            if ( this.state.walletAnalysis ) {
                if (    this.state.walletAnalysis !== null &&
                        this.state.walletAnalysis.sufficient && !this.state.walletAnalysis.tooRich ) {

                        // we're DONE with the wallet
                        this.props.walletIsSufficient(true, true, this.props.parent)
                } else {
                    console.warn("You're an EXPERT, but we have issues to deal with. Will proceed as if we're NOT an expert...")
                    isAnExpert = false
                }

            } else {
                alert("You're an expert user, and yet we don't have a wallet analysis yet. REPORT THIS. (Temporarily aborting EXPERT setting for right now)")

                // move on for now
                isAnExpert = false
            }
        }

        const walletRecord = await this.props.getOfficialWallet();
        this.setState({walletRecord: walletRecord, isAnExpert: isAnExpert})
    }

    // triggered manually by user
    async consolidateCoins() {

        const utxos = await fetchUtxos(this.state.walletRecord.address, true, false, 'consolidateCoins')
        console.log("WalletFunder: consolidateCoins(): Here are the utxos to analyze: ", utxos)

        const utxoAnalysis = analyzeUTXOs(utxos,
                                    this.minWalletSats,
                                    this.maxWalletSats1,
                                    this.maxWalletSats2,
                                    this.extraSatsForOverageReduction,
                                    this.feeSats_1_Input_1_Output)
        console.warn("WalletFunder: analysis of wallet: ", utxoAnalysis)

        console.warn("NOTE: We think we need to consolidate the wallet a little (" + utxoAnalysis.nonDustyUTXOs.length + " coins). ")

        if ( utxoAnalysis.nonDustyUTXOs.length > 1 ) {
            const txInfo = await buildConsolidatingTx(this.props.bundle.p,
                                    utxoAnalysis.nonDustyUTXOs,   //  <----- we only use GOOD utxos
                                    utxoAnalysis.satsSum)

            if ( txInfo.raw === null ) {
                //NOTE: may have failed due to MAX_RATE (or MIN_RATE)
                alert("2 Whoops: we had trouble building a consolidation tx. We should check the logs for why, AND, we should avoid looping here.")
            } else {
                console.log("sent consolidation tx. txid " + txInfo.txid)
                //this.setState({showRequestedConsolidationModal: true})   //FIXME: did we mean to use showConsolidatedModal here?
                this.quickToast("We've consolidated the coins in your wallet.....", 2000)
            }
        } else {
            this.quickToast("There was only one coin - we didn't consolidate anything.", 2000)
        }


    }

    // Called to either force-reduce balance (overage), or generically send funds elsewhere
    // If this function cannot find a SINGLE utxo rich enough to send the requested amount
    // AND the required miner fee, it will INSTEAD consolidate the UTXOs.
    //
    // ASSUMES: 1 input, 2 outputs   OR    1 input, 1 output
    // ASSUMES: a typical tx costs 12 sats   (and 1-output costs 10)  - at 0.05 sats/byte
    async reduceWalletBalance( amountToSendWithFraction, emptyTheWallet ) {
        //const amountToSend = this.state.walletAnalysis?.overage

        // might've had a fractional satoshi?
        const amountToSend = Math.floor( amountToSendWithFraction )

        console.warn("reduceWalletBalance(): will build and broadcast a balance-reducing tx. amountToSend: ", amountToSend)
        // sendToPKH
        console.warn("Will send to PKH " + this.state.sendToPKH)

        const walletRecord = await this.props.getOfficialWallet();
        const utxos0 = await fetchUtxos(walletRecord.address, true, false, 'reduceWalletBalance')
        console.log("WalletFunder: reduceWalletBalance(): Here are the utxos we could use:", utxos0)

        const utxoAnalysis = analyzeUTXOs(  utxos0,
                                            this.minWalletSats,
                                            this.maxWalletSats1,
                                            this.maxWalletSats2,
                                            this.extraSatsForOverageReduction,
                                            this.feeSats_1_Input_1_Output)
        const utxos = utxoAnalysis.nonDustyUTXOs

        let fundsSent = false
        if ( emptyTheWallet && utxos.length > 0 ) {
            console.log("Will build a tx to EMPTY the wallet. We want NO change")

            //NOTE: must trigger all funds to go ELSEWHERE, not consolidated BACK to our wallet
            const txInfo = await buildConsolidatingTx(  this.props.bundle.p,
                                                        utxos,
                                                        utxoAnalysis.satsSum,
                                                        this.state.sendToPKH)    // <----- send ELSEWHERE
            if ( txInfo.raw === null ) {
                //NOTE: may have failed due to MAX_RATE (or MIN_RATE)
                alert("3 Whoops: we had trouble building a consolidation tx. We should check the logs for why, AND, we should avoid looping here.")
            } else {
                console.log("sent emptying txid " + txInfo.txid)
                //alert("NOTE: We've just now EMPTIED the " + utxos.length
                //            + " coins in your wallet.")
                fundsSent = true
            }
        } else if ( utxos.length > 0 ) {
            let goodUTXO = null
            //let actualAmountSent = amountToSend

            console.warn("fee for 1-in, 1-out: ", this.feeSats_1_Input_1_Output)
            console.warn("fee for 1-in, 2-out: ", this.feeSats_1_Input_2_Outputs)

            // The fee to pay for 1-input, 1-output is slightly less than for 2 outputs
            const totalSpentWith1Output  = amountToSend + this.feeSats_1_Input_1_Output
            const totalSpentWith2Outputs = amountToSend + this.feeSats_1_Input_2_Outputs
            let actualFee = 300

            console.warn("  total spent if 1 output: ", totalSpentWith1Output)
            console.warn("  total spent if 2 outputs: ", totalSpentWith2Outputs)

            // any single UTXO have the amount we need?
            for ( let i = 0; i < utxos.length; i++ ) {
                const theseFunds = utxos[i].satoshis

                //NOTE: we've TRIED allowing for no-fee, 1-in, 1-out txs
                //      Testnet miners didn't want them

                console.warn("  considering utxo with funds: ", theseFunds)

                // account for the miner fee when searching
                // for a sufficiently-funded UTXO
                if ( theseFunds === totalSpentWith1Output ) {
                    goodUTXO = utxos[i]
                    console.warn("!! We found a UTXO with the EXACT right amount (amt+fee) (no change): " + theseFunds)
                    //actualAmountSent = totalSpentWith1Output
                    actualFee = this.feeSats_1_Input_1_Output

                    // we're DONE searching. This UTXO is perfect
                    break;

                } else if ( theseFunds >= totalSpentWith2Outputs ) {
                    console.warn("We found a sufficent UTXO. idx: " + i)
                    goodUTXO = utxos[i]
                    //actualAmountSent = totalSpentWith2Outputs
                    actualFee = this.feeSats_1_Input_2_Outputs
                }
            }

            if ( goodUTXO !== null ) {
                console.warn("We'll build a balance-reducing tx funded by single UTXO: ", goodUTXO)

                // we build from a SINGLE utxo
                const txInfo = await buildBalanceReducingTx(this.props.bundle.p,
                                                            [ goodUTXO ],
                                                            goodUTXO.satoshis,
                                                            amountToSend,
                                                            this.state.sendToPKH,
                                                            actualFee)
                if ( txInfo.raw === null ) {
                    //FIXME: display a modal instead of an alert
                    //NOTE: may have failed due to MAX_RATE (or MIN_RATE)
                    alert("0 Whoops: We had trouble reducing your balance (sending funds). We should check the logs for why, AND, we should avoid looping here.")
                } else {
                    console.log("built and broadcast a 'reduction' tx, with txid " + txInfo.txid)
                    //alert("SUCCESS: We've just now reduced your wallet balance. Will now re-scan.")

                    fundsSent = true
                }

                // We no longer report balance to parent HERE.
                // That's ok, since this is soon followed-up by a report made in scanUTXOs()
                //this.reportBalanceToParent(utxoAnalysis.satsSum - actualAmountSent)
            } else if ( utxos.length > 1 ) {

                // no 'goodUTXO' was found. (a SINGLE UTXO wasn't enough)

                console.warn("Hmm. We need to consolidate UTXOs. There are currently " + utxos.length)
                console.warn("The non-dusty UTXOs add to " + utxoAnalysis.satsSum + " sats")
                console.warn("Here are the UTXOs we're consolidating: ", utxos)

                const txInfo = await buildConsolidatingTx(this.props.bundle.p, utxos, utxoAnalysis.satsSum)

                if ( txInfo.raw === null ) {
                    //NOTE: may have failed due to MAX_RATE (or MIN_RATE)
                    alert("4 Whoops: we had trouble building a consolidation tx. We should check the logs for why, AND, we should avoid looping here.")
                } else {
                    console.log("sent txid " + txInfo.txid)

                    // Show a special modal that explains that funds were consolidated
                    // When user later clicks OK, we show a toast (pausing), then finally re-scan
                    this.setState({showConsolidatedModal: true})

                }
            } else {

                // We typically can't end-up here. Though, user could spend coins OUTSIDE of this app

                alert("WARNING: We couldn't build an appropriate transaction. Are there enough funds?")
                //FIXME: show the UTXOs?

                //FIXME: throw? show error modal?
            }

        } else {
            console.error("reduceWalletBalance(): Whoops. where'd the UTXOs go? Found none.")
            alert("WARNING: We found no UTXOs (coins) in your wallet.")
            //FIXME: throw? show error modal?
        }

        // If we sent funds, as directed by the user, show a special modal
        // If we've merely consolidated, this won't execute
        if ( fundsSent ) {
            // show a "we've sent funds" modal
            //NOTE: this results in an OK button, which ALSO,
            //      later shows a toast (pausing), THEN scans
            // Also: blank-out the sentToAddress, so we don't accidentally use it again
            this.setState({showSentModal: true, willSendFunds: false, amountToSend: '', sendToAddress: ''})
        }

    }

    // show a 'toast' for a specified period, THEN scan utxos
    soonScan(toastText, duration=2000) {
        console.warn("soonScan()")
        this.setState({showToastThenScan: true, toastText: toastText, toastDuration: duration})
        // once expired, toastDone() will be called
    }
    // called-back by toast, once it's expired  (see maybeToast)
    async toastDone(self) {
        self.setState({showToastThenScan: false})

        // now that it's expired, scan the utxos
        await self.scanUTXOs(true)
    }


    // show a 'toast' for a specified period
    quickToast(toastText, duration=2000, withoutLoader = false) {
        console.warn("quickToast()")
        this.setState({showQuickToast: true, toastText: toastText, toastDuration: duration, suppressLoader: withoutLoader})
        // once expired, quickToastDone() will be called
    }
    // called-back by toast, once it's expired  (see maybeQuickToast)
    async quickToastDone(self) {

        // re-analyze utxos - given any recent change in UTXO status
        // this will update state on the coins (and allow re-fresh of display, if necessary)
        await this.scanUTXOs(false)

        self.setState({showQuickToast: false, suppressLoader: false})
    }

    async scanUTXOs(forceQuery = false) {

        const walletRecord = await this.props.getOfficialWallet();
        console.log("WF scanUTXOs(): official wallet record: ", walletRecord)
        console.log("WF scanUTXOs(): official wallet address: ", walletRecord.address)

        let utxoAnalysis = null
        let consolidationWasNeeded = false
        let abortExpertStreamline = false


            const utxos = await fetchUtxos(walletRecord.address, true, forceQuery, 'scanUTXOs')
            console.log("WalletFunder: scanUTXOs(): Here are the utxos to analyze: ", utxos)

            // sometimes we actually query the providers
            // when we do, we need to check that our accounting (cache) makes sense (alert if not)
            // And also update the local cache (with newly-arrived coins), and re-freeze any coins as appropriate
            if ( forceQuery ) {
                compareUtxosAgainstLocalCache(utxos)
            }

            utxoAnalysis = analyzeUTXOs(utxos,
                                        this.minWalletSats,
                                        this.maxWalletSats1,
                                        this.maxWalletSats2,
                                        this.extraSatsForOverageReduction,
                                        this.feeSats_1_Input_1_Output)
            console.warn("WalletFunder: analysis of wallet: ", utxoAnalysis)


            //FIXME: is it possible the utxos don't have enough funds to consolidate?
            //       Can we prioritize minimum-balance over consolidation?

            // NOTE: we COULD delay consolidating when first logging-in - by adding the && forceQuery
            //       but, that probably isn't necessary
            if ( utxoAnalysis?.needsConsolidating /*&& forceQuery*/ ) {
                consolidationWasNeeded = true
                console.warn("NOTE: We think we need to consolidate the wallet a little ("
                            + utxoAnalysis.nonDustyUTXOs?.length + " coins, " + utxoAnalysis.satsSum + " sats). ")
                console.log("And THESE are the nonDustyUTXOs we're starting with: ", utxoAnalysis.nonDustyUTXOs)


                // HERE is an opportunity to consolidate all BUT the largest UTXO
                // (since we think the time is right)
                // We're not here because the user wants to consolidate
                // We're not here because there's too much in the wallet
                let satSum = 0
                let theUtxos
                // We'll only exclude the largest UTXO if there are multiple OTHER utxos
                // to consolidate (so, if we REALLY need those funds, we'll use them)
                // (We probably wouldn't even be here unless .needsConsolidation was true - which implies MANY utxos)
                if ( utxoAnalysis.nonDustyUTXOs.length > 2 ) {
                    console.warn("We're going to EXCLUDE the largest utxo from this consolidation (new algo)...")
                    const utxosToScan = utxoAnalysis.nonDustyUTXOs     //  <----- we only use GOOD utxos
                    console.warn("HERE are the utxosToScan: ", utxosToScan)

                    let utxosToConsolidate = []
                    let fundsInLargestUtxo = 0
                    let idxOfLargestUtxo = -1
                    for ( let i = 0; i < utxosToScan.length; i++ ) {
                        const thisUTXO = utxosToScan[i]
                        const theseFunds = thisUTXO.satoshis
                        satSum += theseFunds
                        if ( theseFunds > fundsInLargestUtxo ) {
                            idxOfLargestUtxo = i
                            fundsInLargestUtxo = theseFunds
                        }
                        utxosToConsolidate.push( thisUTXO )
                    }

                    //console.warn("HERE are the utxosToConsolidate: ", utxosToConsolidate)

                    // Now that we're sure of which UTXO is the largest...
                    // remove LARGEST utxo from array
                    //FIXME: only do this if there's a utxo with >0 sats? meh
                    utxosToConsolidate.splice(idxOfLargestUtxo, 1)

                    console.warn("Having removed/spliced-out the largest utxo, HERE are the REVISED utxosToConsolidate: ", utxosToConsolidate)

                    // ALSO:
                    //   reduce the satsSum
                    //   use utxosToConsolidate, instead of .nonDustyUTXOs
                    theUtxos = utxosToConsolidate
                    satSum -= fundsInLargestUtxo

                    console.warn("scanUTXOs(): will exclude largest utxo in the consolidation, reducing satsSum to ", satSum)
                    //console.warn("   new utxo list to use:", theUtxos)
                } else {
                    console.warn("boring consolidation (included the largest utxo)")
                    theUtxos = utxoAnalysis.nonDustyUTXOs             //  <----- we only use GOOD utxos
                    satSum = utxoAnalysis.satsSum
                }

                const txInfo = await buildConsolidatingTx(this.props.bundle.p,
                                        theUtxos,
                                        satSum)

                if ( txInfo.raw === null ) {
                    //NOTE: may have failed due to MAX_RATE (or MIN_RATE)
                    alert("1 Whoops: we had trouble building a consolidation tx. We should check the logs for why, AND, we should avoid looping here.")
                    consolidationWasNeeded = false
                } else {
                    console.log("sent consolidation tx. txid " + txInfo.txid)
                }

                if ( utxoAnalysis.sufficient ) {
                    console.log("WalletFunder: Good address")
                } else {
                    console.log("It's not clear that the funds at this address (" + walletRecord.address + ") are enough.")
                }
            } else {
                console.warn("Regardless, analysis of UTXOs: ", utxoAnalysis)
            }



        if ( consolidationWasNeeded ) {
                // show a 'toast' (pause), then re-scan (call this again)
                this.quickToast("We've consolidated your coins. Please wait while it propagates the network...", 4000)

                //NOTE: it's theoretically possible that props.walletIsSufficient(false)
                //      is called, further below, before soonScan() results in a re-scan
                //      Is that important? Probably not.

                abortExpertStreamline = true
        }

        if ( utxoAnalysis?.sufficient ) {
            console.log("You actually don't have to FUND this wallet. It probably has enough for now.")
            const closeTheModal = false
            //FIXME: rename to setWalletIsSufficient(), probably
            this.props.walletIsSufficient( true, closeTheModal, this.props.parent )

            if ( utxoAnalysis.tooRich ) {
                //alert("Heads Up. No sense adding funds. Hopefully killing any CANCEL button now...")
                this.setWillAddFunds(false)

                // don't rely on some previously-entered address
                this.setState({sendToAddress: ''})
                abortExpertStreamline = true
            }
        } else {
            // reduce user 'level', suggesting he add funds if he's 'onramping'
            this.props.walletIsSufficient( false, false, this.props.parent )
            abortExpertStreamline = true
        }

        this.setState({walletAnalysis: utxoAnalysis, officialAddress: walletRecord.address})

        if ( utxoAnalysis.bestUTXO ) {
            //alert("wallet funder REPORTING balance to parent - walletModal: (" + utxoAnalysis.satsSum + " sats)")
            this.reportBalanceToParent(utxoAnalysis.satsSum, utxoAnalysis.bestUTXO?.satoshis)
        } else {
            if ( this.state.showAdvancedFeatures ) {
                alert("Be careful about freezing ALL of your coins. You won't be able to build any transactions without funds.")
            }
            //alert("WALLET FUNDER: if we have an EMPTY wallet, maybe report to parent - with null 2nd arg <---- and have recipient be careful with that 2nd arg (balance should thus be zero)")
        }

        return abortExpertStreamline
    } // scanUTXOs()

    async componentWillUnmount() {
        //console.error("Closing socket")
        //this.socket.close(1000, "Done.");

        // fix Warning: Can't perform a React state update on an unmounted component
        // see: https://stackoverflow.com/questions/53949393/cant-perform-a-react-state-update-on-an-unmounted-component
        this.setState = (state,callback)=>{
            return;
        };
    }

    reportBalanceToParent(satsSum, bestUtxoSats) {
        // report back the wallet balance (in US pennies)
        if ( this.props.returnBalance && this.props.bsvPriceUSD && this.props.bsvPriceUSD !== 0 ) {
            const UsPennyValue = ((satsSum * this.props.bsvPriceUSD) / 1000000).toFixed(1)
            console.log("calculated total value of " + UsPennyValue + "¢")

            const bestUtxoPennyValue = ((bestUtxoSats * this.props.bsvPriceUSD) / 1000000).toFixed(1)
            console.log("calculated best UTXO value of " + bestUtxoPennyValue + "¢")

            this.props.returnBalance( UsPennyValue, bestUtxoPennyValue )
        }
    }

    setWillAddFunds(value) {
        console.log("WF setWillAddFunds(): User wishes to add funds to wallet: ", value)
        //this.setState({willAddFunds: true})
        this.props.setUserWillAddFunds(value)
        this.setState({willAddFunds: value})
    }

    //FIXME: rename to setWillReduceFunds()
    setWillSendFunds(value) {
        console.log("WF setWillSendFunds(): User wishes to reduce/SEND funds from wallet? ", value)
        //this.setState({willAddFunds: true})

        if ( value === false ) {
            console.warn("Since the use is NOT sending, let's clear the amountToSend")
            this.setState({willSendFunds: false, amountToSend: '', emptyTheWallet: false, sendToAddress: ''})
        } else {
            this.setState({willSendFunds: true})
        }
    }

    handleSendAddress(e, v) {

        //console.log("handleSendAddress(): address now set to ", e.target.value);
        console.log("handleSendAddress(): address now set to ", v.value);

        const pkh = convertAddressToPKH(v.value)
        if ( pkh === -1 ) {
            // WARN user that this address is for the WRONG network
            this.setState({sendToAddress: v.value, sendToPKH: '', showWrongNetworkModal: true})
        } else if ( pkh !== null ) {
            this.setState({sendToAddress: v.value, sendToPKH: pkh})
        } else {
            this.setState({sendToAddress: v.value, sendToPKH: ''})
        }
    }

    async handleConsolidatedModalOk() {
        this.setState({showConsolidatedModal: false});

        // enforced PAUSE for effect
        this.quickToast("We've consolidated your wallet coins....", 2000)
    }
    handleWrongNetworkModalOk() {
        this.setState({showWrongNetworkModal: false})
    }

    async handleSentModalOk() {
        this.setState({showSentModal: false, emptyTheWallet: false, amountToSend: ''});

        // enforced PAUSE for effect
        this.quickToast("Your balance has been updated...", 1500)
    }

    async copyWalletAddressToClipboard() {
        try {
            await navigator.clipboard.writeText(this.state.officialAddress);

            this.quickToast("Address copied to clipboard", 900, true)
        } catch (error ) {
            alert("SORRY. We couldn't copy your wallet address.\nPlease copy it MANUALLY - then paste and save it somewhere.")
        }
    }

    showQRScanner() {
        this.setState({showTheQRScanner: true})
    }
    closeQRScanner() {
        this.setState({showTheQRScanner: false})
    }
    // called-back by child <QrScanModal/>
    reportScannedAddress(address) {
        console.log("scanned address: ", address)
        this.closeQRScanner()

        this.handleSendAddress(null, {value: address})
    }

    render() {

        // copied from onRamp:
        // ***  DEVICE-DEPENDENT STYLING  ***
        // For mobile, "detect" landscape screen mode
        const landscape = this.props.isMobile ? window.innerWidth > window.innerHeight : false
        // For mobile, shrink the About, Security, and Glossary modals
        const modalClassName   = this.props.isMobile ?
                                        (landscape ?
                                                "modalMobileLandscape"
                                            :
                                                "modalMobilePortrait"
                                        )
                                    :
                                        ""
        const modalContentClassName = this.props.isMobile ? "modalContentMobile" : ""
        const modalBottomClassName  = this.props.isMobile ? "modalBottomMobile"  : ""



        const walletAnalysis = this.state.walletAnalysis
        let sufficientOkButton = <></>
        let mostOfTheText      = <></>;
        let totalFundsMention  = <></>
        let fillInMostOfTheText;
        const bsvPriceUSD = this.props.bsvPriceUSD

        //console.warn("walletFunder render():  walletAnalysis: ", walletAnalysis)

        let analysisText = ""
        if ( walletAnalysis?.satsSum === 0 ) {
            analysisText =  <>
                                It's time to send some Bitcoin (<b style={{color: "blue"}}>BSV</b>,&nbsp;
                                <b>not</b> BTC) to your newly-created wallet.
                            </>
            fillInMostOfTheText = true
        } else if ( walletAnalysis?.satsSum > 0 && !walletAnalysis?.sufficient ) {
            analysisText =  <>
                                That's probably <b>not</b> enough to proceed
                                (minimum {this.minWalletSats} sats in a single coin).
                                You'll need to top-up your wallet (add more funds) if
                                you wish to proceed.
                            </>
            fillInMostOfTheText = true
        } else if ( walletAnalysis?.sufficient && !walletAnalysis?.tooRich
                    && !this.state.willAddFunds ) {

            const certainWord = walletAnalysis.plenty ? 'definitely' : 'probably'
            const addMoreIfYouWant =  walletAnalysis.plenty ? '' : ', but you can add more funds if you wish'
            const lowishBalanceMention = walletAnalysis.runningLow ?
                            <> (<b>It's on the low side.</b>) </>
                                :
                            <></>
            analysisText =  <>
                                <p>
                                That's {certainWord} enough to proceed{addMoreIfYouWant}.
                                {lowishBalanceMention}
                                </p>
                            </>

            fillInMostOfTheText = false
        } else if ( walletAnalysis?.sufficient && !walletAnalysis?.tooRich
                    && this.state.willAddFunds ) {
            const certainWord = walletAnalysis.plenty ? 'definitely' : 'probably'
            analysisText =  <>
                                That's {certainWord} enough to proceed,
                                but you've decided to top-off your wallet. If
                                you change your mind, you can click 'Cancel', and proceed to the next step.
                            </>
            fillInMostOfTheText = true
        } else if ( walletAnalysis?.tooRich) {
            analysisText =  <>
                                <p></p>
                                    <span style={{color: "red", fontSize: "2rem"}}>
                                        WHOA!
                                    </span>
                                <p></p>
                                <p>
                                That's actually <b>too much</b>.
                                We think it's too risky to hold so much in
                                a new wallet that's relatively un-tested.
                                </p>
                                <p>
                                Please reduce your balance so that it holds less than
                                three dollars (USD) worth of Bitcoin. Thank you for your understanding.
                                </p>
                            </>
            fillInMostOfTheText = false
        } else {
            analysisText =  <>
                                hmm. Wallet status: {walletAnalysis}
                            </>
            fillInMostOfTheText = false
        }

        const coinWord = walletAnalysis?.numUTXOs > 1 ? "coins" : "coin"

        if ( this.state.isAnExpert && !this.props.fromSettings ) {
            totalFundsMention = <> You're an expert. We're trying to streamline your steps... </>
        } else if ( walletAnalysis?.satsSum > 0 ) {
            const UsPennyValue = (walletAnalysis.satsSum * bsvPriceUSD / 1000000).toFixed(1)
            const balanceToShow = UsPennyValue >= 100 ?
                        <>${(UsPennyValue / 100).toFixed(2)}</>
                    :
                        <>{UsPennyValue}¢</>

            const usFormatSatSum = walletAnalysis.satsSum.toLocaleString('en-US')
            const great = walletAnalysis.plenty && !fillInMostOfTheText && !walletAnalysis.tooRich ?
                    //green = #59b659
                    <div style={{color: "#59b659", fontSize: "2rem"}}>Great.</div>
                        :
                    <></>
            totalFundsMention = <>
                {great}
                <p>
                    You have <b style={{color: "blue"}}>{usFormatSatSum} satoshis</b> (<b style={{color: "blue"}}>{balanceToShow}</b> USD), in {walletAnalysis.numUTXOs} {coinWord}, in your wallet.
                </p>
            </>
        } else if ( walletAnalysis?.dustyUtxoCount > 0 ) {
            totalFundsMention = <p>
                                    Your wallet is essentially <b>EMPTY</b>.
                                </p>
        } else {
            totalFundsMention = <p>
                                    Your wallet is <b>EMPTY</b>.
                                </p>
        }

        if ( fillInMostOfTheText && !this.state.willSendFunds && (!this.props.fromSettings || this.state.willAddFunds) ) {
            const wocUrl = "https://test.whatsonchain.com/address/" + this.state.officialAddress;
            // avoid tab-napping
            // see: https://stackoverflow.com/questions/17711146/how-to-open-link-in-new-tab-on-html#17711167
            const wocLink = <a href={wocUrl} target="_blank" rel="noopener noreferrer" >{this.state.officialAddress}</a>;
            mostOfTheText =
                <>
                    <p> <span style={{color: "red"}}><b>REMINDER: </b></span>
                        Your encrypted wallet is stored in <b>this browser</b>. Unless you copy it to another device or browser,
                        you'll only be able to access it from <b>this</b> particular browser on <b>this device</b>. And unless you copy it to another device,
                        if this device is ever lost or damaged, you'll lose access to your Bitcoin, and any assets you've claimed, <b>forever</b>.
                    </p>
                    <Divider />
                    <p style={{color: "red"}}>Do not put more than a small amount in this wallet
                        (worth no more than $3 USD).
                    </p>
                    <b>NOTE:</b> The BitShizzle browser will actively manage this wallet
                          (sometimes consolidating "loose change").
                          Also, if it detects that the wallet is too rich, it will <b>require</b> you to reduce its balance.
                    <br></br><br></br>
                    <div style={{textAlign: 'center'}}>
                         Your wallet address: {wocLink} &nbsp;  <Icon name='clone outline' onClick={this.copyWalletAddressToClipboard}/>
                    </div>
                    <br></br>
                    <div style={{textAlign: 'center'}}>
                        <QRCodeSVG value={this.state.officialAddress} size={200}/>
                    </div>
                </>
        }

        //<Button positive onClick={this.scanUTXOs} content="I've sent funds. Please RE-SCAN."/>
        const rescanButton = !fillInMostOfTheText || (this.props.fromSettings && !this.state.willAddFunds) ?
                <></>
                    :
                <div style={{textAlign: 'center'}}>
                    <Button positive onClick={() => this.soonScan("Re-scanning wallet balance...", 2800)} content="I've added more funds. Please RE-SCAN."/>
                    <br></br>
                    <p style={{color: "blue", textAlign: 'center'}}>[ Balance: {this.state.walletAnalysis?.satsSum} sats ]</p>
                </div>

        // FORCED REDUCTION (overage)
        const satsOverage = this.state.walletAnalysis?.overage.toLocaleString('en-US') // how much to reduce a too-high balance
        const disableSend = this.state.sendToPKH === '' || (this.state.sendToAddress === this.state.walletRecord?.address)
        const walletReductionFields = !this.state.walletAnalysis?.tooRich ?
                <></>
                    :
                <>
                    <Divider />
                    <p>
                        <span style={{color: "blue", fontSize: "1.8rem"}}>
                            Let's reduce your wallet's funds...
                        </span>
                    </p>
                    <p>
                        We need you to send the excess satoshis (<b style={{color: "blue"}}>{satsOverage} sats</b>) to
                        a <b> different</b> address of yours, in some <b>other</b> wallet.
                    </p>
                    Address (<b>of yours</b>) to send to: &nbsp;
                    <Input  field='sendToAddress' placeholder='Enter your OTHER Bitcoin address'
                            value={this.state.sendToAddress} style={{width: "325px"}}
                            onChange={this.handleSendAddress}
                            autoFocus>
                        <input style={{borderRadius: '50px'}} />
                    </Input>
                    <div style={{color: "red"}}>(If you don't have the keys for this address, you'll lose these funds forever)</div>
                    <br></br>
                    <div style={{textAlign: 'left'}}>
                        <Button disabled={disableSend} positive
                                onClick={() => this.reduceWalletBalance(walletAnalysis?.overage, false)}
                                content="Send/offload my excess funds"/>
                    </div>
                </>

        if ( (walletAnalysis?.sufficient && !walletAnalysis?.tooRich && !this.state.willAddFunds)
                || (this.props.fromSettings && !this.state.willAddFunds && !this.state.willSendFunds) ) {

            const fundsNonZero = walletAnalysis?.satsSum > 0
            const sendButton = this.props.fromSettings && fundsNonZero ?
                            <>
                                <Button positive onClick={() => this.setWillSendFunds(true)} content="SEND (from this wallet)"/>
                                &nbsp;
                            </>
                        :
                            <></>

            //FIXME: magic number: 1000
            //FIXME; if not enough funds or UTXOs, say why disabled
            const disableConsolidate = walletAnalysis?.numUTXOs < 2 || walletAnalysis?.satsSum < 1000
            const consolidateButton = this.props.fromSettings && fundsNonZero  ?
                            <>
                                <Button color="blue" style={{color: 'yellow'}} onClick={this.consolidateCoins} content="Consolidate Coins"
                                        disabled={disableConsolidate}/>
                                &nbsp;
                            </>
                        :
                            <></>

            // If wallet is too rich, we'll FORCE a reduction, so, we won't need these 3 buttons:
            //     Add, Reduce, Done
            // If there's plenty (or too rich) don't bother allowing to Receive more
            const recvButton = !walletAnalysis?.plenty ?
                    <><Button positive onClick={() => this.setWillAddFunds(true)} content="RECEIVE (to this wallet)"/>
                    &nbsp;</>
                :
                    <></>

            // REMOVED from bottom of sufficientOkButton (below 'Advanced' button):
            /*
            <br></br>
            <Button positive style={{color:"yellow"}}
                    onClick={() => this.props.walletIsSufficient(true, true, this.props.parent)}
                    content='DONE'
                    autoFocus/>
            */

            const maybeAdvancedButton = this.props.fromSettings && fundsNonZero ?
                        <>
                            <br></br>
                            <Button positive style={{color:"yellow", backgroundColor:'grey'}}
                                    onClick={this.advancedWalletFeatures}
                                    content='Advanced'
                            />
                        </>
                    :
                        null
            // If too rich, will FORCE user to offload (elsewhere)
            if ( !walletAnalysis?.tooRich ) {
                sufficientOkButton =
                    <div style={{textAlign: 'center'}}>
                        {recvButton}
                        {sendButton}
                        {consolidateButton}
                        <br></br>
                        {maybeAdvancedButton}
                        <br></br>
                    </div>
            }
        }


        const sufficientMention = walletAnalysis?.sufficient ?
                    <>You have {(walletAnalysis?.satsSum - this.minWalletSats).toLocaleString('en-US')} more satoshis than the minimum to proceed.</>
                :
                    <>You are {(this.minWalletSats - walletAnalysis?.satsSum).toLocaleString('en-US')} satoshis below the minimum to proceed.</>

        const amountColor = this.state.emptyTheWallet ? "grey" : "black"
        const emptyColor  = this.state.emptyTheWallet ? "black" : "grey"
        const sendingTooMany = !this.state.emptyTheWallet && this.state.amountToSend !== '' && this.state.amountToSend > (walletAnalysis?.satsSum - 10)
        const warnAboutAmount = sendingTooMany ?
                                    <>
                                        <div style={{textAlign: "center", color: "red"}}>(If you attempt to send
                                            too much, you won't have enough for the miner fee)</div>
                                    </>

                                :
                                    <>
                                        <div> &nbsp; </div>
                                    </>
        const disableReduce = (this.state.sendToPKH === '' /*|| (this.state.sendToAddress === this.state.walletRecord?.address)*/)
                                || (this.state.amountToSend < 1 && !this.state.emptyTheWallet)

        const approxSendValue = (this.state.amountToSend * bsvPriceUSD / 1000000).toFixed(1)
        const walletSendFields = this.state.willSendFunds ?
                <>
                    <Divider />
                    <p>
                        <span style={{color: "blue", fontSize: "1.8rem"}}>
                            Sending funds FROM your wallet
                        </span>
                    </p>

                    {sufficientMention}
                    <p>You can always send some (or all) of your funds to another Bitcoin (BSV) address.</p>
                    <p></p>
                    Address to send to:<br></br>
                    <Input  field='sendToAddress' placeholder='Enter a valid receiving address'
                            value={this.state.sendToAddress} style={{width: "310px"}}
                            onChange={this.handleSendAddress}
                            autoFocus>
                        <input style={{borderRadius: '50px'}} />
                    </Input>&nbsp;&nbsp;
                    <Button onClick={this.showQRScanner}
                            style={{backgroundColor:'blue', color:'yellow', borderRadius: '15px'}}
                            disabled={this.state.sendToAddress!=='' ? true : false}>
                        Scan Address QR
                    </Button>
                    <br></br>
                    {this.state.sendToPKH === '' && this.state.sendToAddress.length > 0 ? <span style={{color:'red'}}>&nbsp; &nbsp;not a valid address</span> : null}
                    <br></br>
                    <span style={{color: amountColor}} >How many satoshis to send: &nbsp;</span>
                    <Input  disabled={this.state.emptyTheWallet}
                            field='amountToSend' placeholder='satoshi amount'
                            value={this.state.amountToSend} style={{width: "135px"}}
                            onChange={this.handleAmountToSend}>
                        <input style={{borderRadius: '50px'}} />
                    </Input>
                    &nbsp; {approxSendValue}¢ (USD)
                    <br></br>
                    {warnAboutAmount}

                    <Radio  toggle
                            checked={this.state.emptyTheWallet}
                            onChange={this.toggleEmptyTheWallet} >
                    </Radio> <span style={{color: emptyColor}}> &nbsp; Empty wallet (send ALL funds)</span>

                    <br></br><br></br>

                    <div style={{textAlign: 'left'}}>
                        <Button disabled={disableReduce} positive
                                onClick={() => this.reduceWalletBalance(this.state.amountToSend, this.state.emptyTheWallet)}
                                content="Send"/>
                        <Button negative onClick={() => this.setWillSendFunds(false)} content='Cancel'/>
                    </div>
                </>

            :
                <></>

        const sentSomeModal =
                <>
                    <Modal size='small' centered className={modalClassName}  open={this.state.showSentModal} style={{backgroundColor: this.bshzPurple, borderRadius: '20px'}}>
                    <Modal.Header style={{textAlign: 'center', backgroundColor: this.bshzPurple, borderRadius: '20px'}}>
                        <span style={{color: this.bshzYellow}}> Satoshis were SENT </span>
                    </Modal.Header>
                    <Modal.Content className={modalContentClassName} scrolling style={{backgroundColor: this.bshzLtPurp}}>

                        We've sent your funds as directed.

                    </Modal.Content>
                    <Modal.Actions className={modalBottomClassName} style={{backgroundColor: this.bshzPurple, borderRadius: '0px 0px 20px 20px'}}>
                        <div style={{textAlign: 'center'}}>
                            <Button positive onClick={this.handleSentModalOk} content='OK'/>
                        </div>
                    </Modal.Actions>
                    </Modal>
                </>
        const consolidatedSomeModal =
                <>
                    <Modal size='small' centered className={modalClassName}  open={this.state.showConsolidatedModal} style={{backgroundColor: this.bshzPurple, borderRadius: '20px'}}>
                    <Modal.Header style={{textAlign: 'center', backgroundColor: this.bshzPurple, borderRadius: '20px'}}>
                        <span style={{color: this.bshzYellow}}> Coins were CONSOLIDATED </span>
                    </Modal.Header>
                    <Modal.Content className={modalContentClassName} scrolling style={{backgroundColor: this.bshzLtPurp}}>

                        WHOOPS. We had to consolidate the coins in your wallet a little.

                        You'll need to RE-TRY your send.

                    </Modal.Content>
                    <Modal.Actions className={modalBottomClassName} style={{backgroundColor: this.bshzPurple, borderRadius: '0px 0px 20px 20px'}}>
                        <div style={{textAlign: 'center'}}>
                            <Button positive onClick={this.handleConsolidatedModalOk} content="Okay. I understand it didn't SEND."/>
                        </div>
                    </Modal.Actions>
                    </Modal>
                </>

        const selectedUtxoIndex = this.state.selectedUtxoIndex
        const utxos = window.localUTXOs
        let advancedOutput = null
        if ( this.state.showAdvancedFeatures && utxos ) {

            advancedOutput =    <>
                                    {utxos.map((item, index) => (
                                        <>
                                        { index !== selectedUtxoIndex ?
                                        <div key={index}>
                                            <p>{index}: &nbsp;&nbsp;{utxos[index].txId.slice(0,8)}...{utxos[index].txId.slice(-8)} : {utxos[index].outputIndex} &nbsp; &nbsp;{utxos[index].satoshis} sats &nbsp; &nbsp;<b style={{color:'blue'}}>{utxos[index].FROZEN ? 'FROZEN' : ''}</b></p>
                                        </div>
                                            :
                                        <div key={index} style={{backgroundColor: 'yellow'}}>
                                            <p>{index}: &nbsp;&nbsp;{utxos[index].txId.slice(0,8)}...{utxos[index].txId.slice(-8)} : {utxos[index].outputIndex} &nbsp; &nbsp;{utxos[index].satoshis} sats &nbsp; &nbsp;<b style={{color:'blue'}}>{utxos[index].FROZEN ? 'FROZEN' : ''}</b></p>
                                        </div>
                                        }
                                        </>
                                    ))}
                                </>
        } else {
            advancedOutput = "??? we found no cache of utxos??? CODE ERROR"
        }
        const toggleButtonLabel = utxos[selectedUtxoIndex]?.FROZEN ? 'UN-Freeze' : 'FREEZE'
        const buttonColor = utxos[selectedUtxoIndex]?.FROZEN ? 'red' : 'blue'

        const maybeAdvancedFeaturesModal = this.state.showAdvancedFeatures ?
                <>
                    <Modal size='large' centered className={modalClassName}  open={true} style={{backgroundColor: this.bshzPurple, borderRadius: '20px'}}>
                    <Modal.Header style={{textAlign: 'center', backgroundColor: this.bshzPurple, borderRadius: '20px'}}>
                        <span style={{color: this.bshzYellow}}> Advanced Wallet Features </span>
                    </Modal.Header>
                    <Modal.Content className={modalContentClassName} scrolling style={{backgroundColor: this.bshzLtPurp}}>

                        <p style={{color:'blue'}}>Use this to FREEZE and UN-FREEZE your P2PKH coins.</p>
                        <p style={{color:'blue'}}>Coins that are FROZEN won't be used to fund the transactions
                            you'll build for the various operations you may perform on your domain(s). Nothing
                            is actually done to the coins. They just won't be chosen for use - unless/until you
                            declare them UN-FROZEN.</p>

                        {advancedOutput}
                        <br></br>
                        Full TxId: <span style={{color: 'purple'}}>{utxos[selectedUtxoIndex]?.txId}</span>
                        <br></br>
                        <br></br>

                        <Table unstackable style={{borderSpacing:"0px", border:'none', margin:'0px 5px', width:"100%", backgroundColor:"#00000000"}} >
                            <Table.Body><Table.Row>
                                <Table.Cell style={{border:'none', padding:'0px'}} collapsing>
                                    <Button style={{margin: '3px', padding: '10px 0px 10px 10px'}} positive onClick={() => this.setState({selectedUtxoIndex: (selectedUtxoIndex-1+utxos.length) % utxos.length})}>
                                        <Icon name='chevron up'/>
                                    </Button>
                                    <br></br>
                                    <Button style={{margin: '3px', padding: '10px 0px 10px 10px'}} positive onClick={() => this.setState({selectedUtxoIndex: (selectedUtxoIndex+1+utxos.length) % utxos.length})}>
                                        <Icon name='chevron down'/>
                                    </Button>
                                </Table.Cell>
                                <Table.Cell>
                                    <Button style={{backgroundColor: buttonColor, color:'yellow'}} onClick={this.toggleUtxoFreeze} content={toggleButtonLabel}/>
                                </Table.Cell>
                            </Table.Row></Table.Body>
                        </Table>

                    </Modal.Content>
                    <Modal.Actions className={modalBottomClassName} style={{backgroundColor: this.bshzPurple, borderRadius: '0px 0px 20px 20px'}}>
                        <div style={{textAlign: 'center'}}>
                            <Button negative onClick={this.closeAdvancedWalletFeatures} content="BACK"/>
                        </div>
                    </Modal.Actions>
                    </Modal>
                </>
            :
                <></>

        const nonExpertDisplay = this.state.isAnExpert && !this.props.fromSettings ?
                            <></>
                        :
                            <>
                                {analysisText}
                                <p></p>
                                {sufficientOkButton}

                                {mostOfTheText}

                                {rescanButton}
                            </>
        const displayWhenSendingOrNot = this.state.willSendFunds ?
                <>
                    {walletSendFields}
                </>
            :
                <>
                    {nonExpertDisplay}
                    {walletReductionFields}
                </>

        const display = this.state.walletAnalysis === null ?
                <></>  // blank until wallet analysis is performed
                    :
                <>
                    {totalFundsMention}
                    {displayWhenSendingOrNot}
                </>

        const maybeToast = this.state.showToastThenScan ?
                        <Toast  durationMillis={this.state.toastDuration}
                            displayText={this.state.toastText}
                            done={this.toastDone}
                            parent={this}/>
                    :
                        <></>
        const maybeQuickToast = this.state.showQuickToast ?
                <Toast  durationMillis={this.state.toastDuration}
                    displayText={this.state.toastText}
                    noLoader={this.state.suppressLoader}
                    done={this.quickToastDone}
                    parent={this}/>
            :
                <></>

        const maybeShowQRScanner = this.state.showTheQRScanner ?
                    <QrScanModal closeQRScanner={this.closeQRScanner}
                                 reportScannedAddress={this.reportScannedAddress}
                                 isMobile={this.props.isMobile}/>
                :
                    null;

        const maybeShowWrongNetworkModal = this.state.showWrongNetworkModal ?
                <>
                    <Modal size='small' centered className={modalClassName}  open={true} style={{backgroundColor: this.bshzPurple, borderRadius: '20px'}}>
                    <Modal.Header style={{textAlign: 'center', backgroundColor: this.bshzPurple, borderRadius: '20px'}}>
                        <span style={{color: this.bshzYellow}}> Wrong Network ! </span>
                    </Modal.Header>
                    <Modal.Content className={modalContentClassName} scrolling style={{backgroundColor: this.bshzLtPurp}}>

                        WHOOPS. That's an address for the <b style={{color:'red'}}>WRONG</b> network.
                        <br></br>
                        <br></br>
                        We're currently operating on the Bitcoin (BSV) <b style={{color:'blue'}}>"{NETWORK}"</b> network, but the address
                        you've entered is for a different network.

                    </Modal.Content>
                    <Modal.Actions className={modalBottomClassName} style={{backgroundColor: this.bshzPurple, borderRadius: '0px 0px 20px 20px'}}>
                        <div style={{textAlign: 'center'}}>
                            <Button positive onClick={this.handleWrongNetworkModalOk} content="Okay"/>
                        </div>
                    </Modal.Actions>
                    </Modal>
                </>
            :
                null

        return(
                <>
                    <Modal size='large' centered className={modalClassName}  open={this.props.showFunder} style={{backgroundColor: this.bshzPurple, borderRadius: '20px'}}>
                        <Modal.Header style={{textAlign: 'center', backgroundColor: this.bshzPurple, borderRadius: '20px'}}>
                            <span style={{color: this.bshzYellow}}> Your Browser Wallet </span>
                        </Modal.Header>

                        <Modal.Content className={modalContentClassName} scrolling style={{backgroundColor: this.bshzLtPurp}}>

                        {display}

                        </Modal.Content>

                        <Modal.Actions className={modalBottomClassName} style={{backgroundColor: this.bshzPurple, borderRadius: '0px 0px 20px 20px'}}>
                            <div style={{textAlign: 'center'}}>
                                <Button negative onClick={this.props.closeFunder} content='Okay. Back.'/>
                            </div>
                        </Modal.Actions>
                    </Modal>

                    {sentSomeModal}
                    {consolidatedSomeModal}

                    {maybeToast}
                    {maybeQuickToast}

                    {maybeAdvancedFeaturesModal}

                    {maybeShowQRScanner}

                    {maybeShowWrongNetworkModal}
                </>
        )
    }
}

export default WalletFunder;
