"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var getOption_1 = require("./getOption");
function getHairColor(options, random) {
    var hairColor = [];
    if (getOption_1.getOption('hairColor', 'auburn', options)) {
        hairColor.push('Auburn');
    }
    if (getOption_1.getOption('hairColor', 'black', options)) {
        hairColor.push('Black');
    }
    if (getOption_1.getOption('hairColor', 'blonde', options)) {
        hairColor.push('Blonde', 'BlondeGolden');
    }
    if (getOption_1.getOption('hairColor', 'brown', options)) {
        hairColor.push('Brown', 'BrownDark');
    }
    if (getOption_1.getOption('hairColor', 'pastel', options)) {
        hairColor.push('PastelPink');
    }
    if (getOption_1.getOption('hairColor', 'platinum', options)) {
        hairColor.push('Platinum');
    }
    if (getOption_1.getOption('hairColor', 'red', options)) {
        hairColor.push('Red');
    }
    if (getOption_1.getOption('hairColor', 'gray', options)) {
        hairColor.push('SilverGray');
    }
    return random.pickone(hairColor);
}
exports.getHairColor = getHairColor;
