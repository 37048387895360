import React from 'react';

import { Button, Checkbox, Container, Divider, Dropdown, Form, Grid, Icon,
    Input, Menu, Message, Modal, Popup, Radio, TextArea } from 'semantic-ui-react'

import {
    //saveEncryptedKeys,
    //getEncryptedKeysFromPKH,
    getP2PKHsJson,
    openDB,

    encryptData,
    //decryptData,
    getRabinsJson,
    getOfficialWalletJson,
    findAllDomainsOfMine,

    findAllMyBuiltDomains,
} from './buildShizzle.js';


class WalletExporter extends React.Component {
  constructor(props) {
    super(props);

    //FIXME: the purples are currently duplicated in index.css
    this.bshzPurple             = '#8313e2';
    this.bshzYellow             = "#fff300";
    this.bshzLtPurp             = '#e7d0fb';
    this.bshzLightYellow        = "#fffccc";

    //NOTE: also used in walletGenerator
    this.maxPasswordLength = 36;

    this.handleExport = this.handleExport.bind(this);

    this.solicitPassword = this.solicitPassword.bind(this);
    this.handlePwdChange = this.handlePwdChange.bind(this);

    this.copyTheGibberish= this.copyTheGibberish.bind(this);

    this.state =  {
                    recordsToShow: null,

                    fundingKeyRecords: '',
                    fundingRecordsCount: 0,

                    payoutRecords: '',
                    numPayouts: 0,

                    publisherRecords: '',
                    numPubKeys: 0,

                    domainsClaimedRecs: '',
                    numClaimed: 0,

                    domainsBuiltRecs: '',
                    numBuilt: 0,

                    totalRecordsCount: 0,

                    showPasswordInputField: false,
                    pwd: '',

                    showSuccessModal: false,
                  };
  }

  handlePwdChange(event, data) {

    const v = data.value

    //NOTE: this logic also appears in WalletGenerator
    // regex check for hex pattern
    // gleaved from this: https://unix.stackexchange.com/questions/391866/regex-for-password-restricting-special-characters
    const re = /^[a-zA-Z0-9#@$?!%^&*()+{}=]+$/;

    if ( v !== '' && (!re.test(v) || v[0] === '-' || v.length > this.maxPasswordLength
                            || v[0] === '0' || v[0] === '1' || v[0] === '2'
                            || v[0] === '3' || v[0] === '4' || v[0] === '5'
                            || v[0] === '6' || v[0] === '7' || v[0] === '8'
                            || v[0] === '9' ) ) {
        console.log("handleNameChange(): Ignoring value - doesn't match regex")
        return
    }

    //console.log("handlePwdChange(): pwd now set to ", event.target.value);

    this.setState({pwd: v }) //event.target.value});
  }

  solicitPassword(event) {
    event.preventDefault()

    this.setState({showPasswordInputField: true})
  }

  async handleExport() {
    console.log("handleExport()")

    const options = {
      types: [
        {
          description: 'Text Files',
          accept: {
            'text/plain': ['.txt'],
          },
        },
      ],
    };

    let records           = '';
    let payoutRecords     = '';
    let publisherRecords  = '';
    //let builderRabRecords = '';
    let domainsClaimedRecs= '';
    let domainsBuiltRecs  = '';
    let completeWallet    = ''
    try {
      records           = this.state.fundingKeyRecords;
      payoutRecords     = this.state.payoutRecords
      publisherRecords  = this.state.publisherRecords
      //builderRabRecords = this.state.builderRabRecords
      domainsClaimedRecs= this.state.domainsClaimedRecs
      domainsBuiltRecs  = this.state.domainsBuiltRecs

      // we've pre-fetched the records - in componentDidMount()

      ///// COPIED from WalletGenerator:
      //NOTE: we're saving the address withing the record. Necessary?
      //    const bundled = {p: this.state.generatedPrivKeyWIF, address: addressToSave}
      //    const bundledPlainText = JSON.stringify(bundled);

      const date = Date()
      const bundleToEncrypt = {
                                bundleTime:        date,
                                officialFunds:     records,
                                publisherKeys:     publisherRecords,
                                builderPayoutKeys: payoutRecords,
                                //builderRabins:     builderRabRecords,
                                domainsBuilt:      domainsBuiltRecs,           //domainsBuiltTab
                                domainsClaimed:    domainsClaimedRecs,         //domainTab
                              }
      const bundleJsonToEncrypt = JSON.stringify(bundleToEncrypt);

      //console.warn("\n===> 1: bundled JSON to encrypt is", bundleJsonToEncrypt);
      //alert("WARNING: DELETE this console.warn() you see in the console. It's for testing only.")

      //console.log("Pwd: ", this.state.pwd)
      let cyphText
      try {
          cyphText = await encryptData(bundleJsonToEncrypt, this.state.pwd);
      } catch (error) {
          alert("wallet export encryption failed. Please make sure you're using https://")
          throw error
      }

      // Package the complete wallet:   metadata, and cypher blob

      completeWallet = JSON.stringify({
                                        bshzWalletVersion: '1.0',
                                        network:  'test',
                                        created: date,
                                        blob:    cyphText
                                     })
      console.log("completeWallet: ", completeWallet)

      const fileHandle = await window.showSaveFilePicker(options);

      // FileSystemWritableFileStream.
      const writable = await fileHandle.createWritable();

      //console.log("Will write these records: " + records)

      // Write to the stream.
      //await writable.write(records);    // whoops. that was only the FUNDING
      await writable.write(completeWallet);

      // Close and write to disk.
      await writable.close();

      this.props.closeWalletExportModal();
    } catch (error) {
      console.error("Wallet Export Error: " + error);
      //alert('We had trouble trying to write the Wallet Key file. Perhaps your browser doesn\'t support this: ' + error);

      console.warn("Your browser doesn't support writing to a file. We'll build json to DISPLAY for copy/paste")

      // Have the user manually copy and paste to a file
      this.setState({
                      recordsToShow: completeWallet
                    });
    }
  }

  async componentDidMount() {
    var db = await openDB();

    //TODO: over time we will expand what the Official Wallet is
    //      Right now it is a single record - per NETWORK (main, testnet)
    //const records = await getRabinsJson(db);
    const fundsRecord = await getOfficialWalletJson(db);
    const fundingRecs = JSON.parse(fundsRecord)
    console.log("walletExporter: number of OFFICIAL p2pkh elements: ", fundingRecs.length);


    const payoutsRecords = await getP2PKHsJson(db)
    const payoutsContents = JSON.parse(payoutsRecords)
    console.log("walletExporter: number of Builder Payout elements: ", payoutsContents.length)


    const rabinRecords = await getRabinsJson(db);
    const rabinContents = JSON.parse(rabinRecords)
    console.log("walletExporter: number of Rabin Publisher (and builder) elements: ", rabinContents.length);

    //const builderRabinRecords = await getRabinsJson(db, "builder");
    //const builderRabinContents = JSON.parse(builderRabinRecords)
    //console.log("walletExporter: number of Rabin BUILDER elements: ", builderRabinContents.length);

    const claimedRecords = await findAllDomainsOfMine(db);
    //const claimedContents = JSON.parse(claimedRecords)
    console.log("walletExporter: number of claimed domain elements: ", claimedRecords.length)

    const builtDomainsRecords = await findAllMyBuiltDomains(db);
    //const builtDomainsContents = JSON.parse(builtDomainsRecords)
    console.log("walletExporter: number of built domain elements: ", builtDomainsRecords.length)

    console.error("walletExporter: sift through [address+network]. EXCLUDE other network?")

    console.warn("funds records: " + fundingRecs.length)
    console.warn("payout records: " + payoutsContents.length)
    console.warn("rabin records: " + rabinContents.length)
    console.warn("claimed records: " + claimedRecords.length)
    console.warn("built domains: " + builtDomainsRecords.length)
    const totalElementCount = fundingRecs.length +
                              payoutsContents.length +
                              rabinContents.length +
                              claimedRecords.length +
                              builtDomainsRecords.length
    console.warn("total records: " + totalElementCount)

    this.setState({ recordsToShow: null,
                    fundingKeyRecords: fundsRecord,                fundingRecordsCount: fundingRecs.length,
                    payoutRecords: payoutsContents,         numPayouts: payoutsContents.length,
                    publisherRecords: rabinRecords,         numPubKeys: rabinContents.length,
                    //builderRabRecords: builderRabinRecords, numBuilderKeys: builderRabinContents.length,

                    domainsClaimedRecs: claimedRecords,     numClaimed: claimedRecords.length,
                    domainsBuiltRecs: builtDomainsRecords,  numBuilt: builtDomainsRecords.length,

                    totalRecordsCount: totalElementCount,
                  });

    //NEW: prep these:  (FIXME: re-evaluate/balance)
    //       0- network  ("test", or "main" - only one)             (FIXME: implemented?)
    //       1- Publisher (and Builder) RabinPool JSON (rabinTab)   (10x6  = 60 rabin keys)
    //       2- BUILDER payout (p2pkh) JSON                         (10x5  = 50 p2pkh keys)
    //       3- assets built JSON                                   (10x10 = 100 nodes - key count based on this)
    //       4- assets claimed JSON (with owner#)                   (10    = 10 names - key count based on this)
  }

  async copyTheGibberish() {
    //console.log("records to copy: ", this.state.recordsToShow)
    try {
      await navigator.clipboard.writeText(this.state.recordsToShow);

      this.setState({showSuccessModal: true})
    } catch (error ) {
      alert("SORRY. We couldn't copy your wallet.\nPlease try again, copying it MANUALLY - then paste and save it somewhere.")

      this.props.closeWalletExportModal()
    }
  }

  render() {
    // copied from onRamp:
    // ***  DEVICE-DEPENDENT STYLING  ***
    // For mobile, "detect" landscape screen mode
    const landscape = this.props.isMobile ? window.innerWidth > window.innerHeight : false
    // For mobile, shrink the About, Security, and Glossary modals
    const modalClassName   = this.props.isMobile ?
                                    (landscape ?
                                            "modalMobileLandscape"
                                        :
                                            "modalMobilePortrait"
                                    )
                                :
                                    ""
    const modalContentClassName = this.props.isMobile ? "modalContentMobile" : ""
    const modalBottomClassName  = this.props.isMobile ? "modalBottomMobile"  : ""

    const disableEncryptButton = this.state.pwd.length < 8;
    //NOTE: we have identical password rules description in walletGenerator
    const verifierTextB = this.state.recordsToShow !== null ?
        <></>
      :
        <>
            <p>
                We can't export this wallet until you've entered a password to <b>encrypt</b> it with.
            </p>

            <Form>
                Please enter a <b style={{color: 'blue'}}>file password</b> (at least 8 chars) we'll use to
                save/export/encrypt/protect this wallet file. It can be different from
                your <b style={{color: 'green'}}>wallet password</b> - which you'll always need to use this wallet.
                <br></br>
                <Input value={this.state.pwd} style={{width:'308px'}}
                      placeholder="Enter a FILE password to protect your backup"
                      onChange={this.handlePwdChange} autoFocus>
                  <input style={{borderRadius: '50px'}} />
                </Input>
                <br></br>
                Your password can be up to {this.maxPasswordLength} characters long, may
                have numbers, upper and lower case letters, and any of these special characters
                #@$?!%^&amp;*()+=&#123;&#125;
                <br></br>
                <Button disabled={disableEncryptButton} field="checkButton" onClick={this.handleExport} positive>
                    ENCRYPT exported wallet with this password
                </Button>
            </Form>
        </>

    const solicitPassword = this.state.showPasswordInputField ? verifierTextB : <></>;




    //FIXME: future: indicate how many keys are being saved
    const supported = this.state.recordsToShow === null;
    const recordsToShow = supported ? null
                                    : <Message style={{overflowWrap: 'break-word'}}><Container>{this.state.recordsToShow}</Container></Message>;
    const buttonsToShow = !supported ?
                          <>
                            Your browser doesn't seem to support writing to a file. If you
                            wish to proceed, you can copy and paste the following gibberish
                            to a file, OR, email it to yourself. If your browser ever deletes
                            your wallet, or if your device breaks, this gibberish below is what
                            you'll need to restore them.
                            <p></p>
                            If you're not sure where/how to save this text/gibberish as a file,&nbsp;
                            <b>paste it into an email</b>, and <b style={{color: 'blue'}}>send it to yourself</b>, but
                            you'll need to <b style={{color: 'red'}}>remember the password</b> you used, just now, to encrypt it.
                            <p></p>
                            <Button field="copied" onClick={this.copyTheGibberish} positive>
                              <Icon name='clone outline' /> &nbsp;
                              Copy to clipboard
                            </Button>
                            &nbsp; &nbsp;
                          </>
                        :
                          <>
                            These keys are encrypted with a password you've provided. Exporting them helps
                            protect you if your machine breaks, or the browser clears your data.
                            Later, if needed, you can re-import them (restoring your wallet) using the exported file.
                            <p></p>
                            Unfortunately, not all browsers support writing to a file. If yours doesn't,
                            you should be able to copy and paste the data to a text file.
                            <p></p>
                            And <b style={{color: 'red'}}>remember</b>, this backup is useless if you forget/lose your password.
                            <p></p>
                            <Button field="export" onClick={this.solicitPassword} positive disabled={this.state.showPasswordInputField}>
                              Export <span style={{color:'blue', fontSize:'1.2rem'}}>{this.state.totalRecordsCount}</span> Password-Encrypted Records
                            </Button>
                            <Button field="cancelButton" onClick={this.props.closeWalletExportModal} negative>CANCEL</Button>
                          </>

    return <>
              <Modal size='small' centered className={modalClassName}  open={true} style={{backgroundColor: this.bshzPurple, borderRadius: '20px'}}>
                <Modal.Header style={{textAlign: 'center', backgroundColor: this.bshzPurple, borderRadius: '20px'}}>
                <span style={{color: this.bshzYellow}}> Export/Backup Your Wallet </span>
                </Modal.Header>
                <Modal.Content className={modalContentClassName} scrolling style={{backgroundColor: this.bshzLtPurp}}>
                  {buttonsToShow}
                  {recordsToShow}

                  {solicitPassword}
                </Modal.Content>
              </Modal>

              <Modal size='tiny' centered className={modalClassName}  open={this.state.showSuccessModal} style={{backgroundColor: this.bshzPurple, borderRadius: '20px'}}>
                <Modal.Header style={{textAlign: 'center', backgroundColor: this.bshzPurple, borderRadius: '20px'}}>
                <span style={{color: this.bshzYellow}}> Wallet Copied </span>
                </Modal.Header>
                <Modal.Content className={modalContentClassName} scrolling style={{backgroundColor: this.bshzLtPurp}}>

                <div style={{textAlign: 'center'}}>
                  Your encrypted browser wallet was copied to the <b>clipboard</b>.

                  <h2 style={{color:'red'}}>But you're not done yet!</h2>
                  You need to <b>paste & save</b> it somewhere secure <b style={{color:'red'}}>right now</b>.
                  <br></br>
                  <br></br>
                  <br></br>
                  <Button positive onClick={this.props.closeWalletExportModal}>
                    Okay. I've pasted & saved it somewhere safe.
                  </Button>
                  <br></br>
                  <br></br>
                  <Button negative onClick={this.props.closeWalletExportModal}>
                    Never mind. I know I haven't saved my encrypted wallet yet.
                  </Button>
                </div>

                </Modal.Content>
              </Modal>
          </>
  }
} // WalletExporter

export default WalletExporter;