import React from 'react';

import { Icon, Modal } from 'semantic-ui-react'

class OverScreen extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            narrow: false,
        }

        this.narrowScreen       = this.narrowScreen.bind(this);
        this.animationEndHandler = this.animationEndHandler.bind(this);
    }

    componentDidMount() {
        // parent will use this to begin slide-away/hiding animation when network issue clears
        this.props.giveRefToParent(this)
    }

    // Called by parent, we start animation to slide-away/hide this screen
    // Once DONE, the animationEndHandler() will tell parent to fully
    // close/remove us from view
    narrowScreen() {
        this.setState({narrow: true})
    }

    // once sliding-to-the-left/hiding animation is done,
    // tell parent it's time to kill/remove us
    animationEndHandler(event ){
        console.warn("animationEndHandler: ", event.animationName)

        if (event.animationName === 'hideToTheLeft') {
            this.props.closeOv()
        }
    };

    render() {

        const cssId = this.state.narrow ? "myNarrowOverlay" : "myOverlay"
        return(
            <>
                <Modal  open={true} size='tiny' dimmer='blurring' id={cssId} onAnimationEnd={(event) => this.animationEndHandler(event)}>

                    <div id="myOverlayCanvas" style={{color:"white", textAlign: 'center'}}>

                        <Icon   className="warningCircle"
                                name="warning circle"
                                size="big"
                                onClick={this.props.checkStatus}>
                        </Icon>
                        <br></br>
                        <br></br>
                        We've detected a <span style={{color:"red"}}>NETWORK ERROR.</span>
                        <br></br>
                        <br></br>
                        Please check your connection.
                        <br></br>
                        <br></br>
                    </div>
                </Modal>
            </>
        )
    }
}

export default OverScreen;
