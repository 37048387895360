
const APP_TITLE = "BitShizzle"

const IDB_NAME = "BitShizzle7"

// if true, we also use bitails for queries related to UNCONFIRMED transactions:
//     - fetchUtxos()
//     - getSpentScript()
//     - getBulkSpentScript
const USE_BITAILS_FULLY = false

// first fanout

// Mar 7, 2023  - NEW MAC - re-compiled. built in PURPLE (modern env). This works
const GENESIS_APPEND = '0e4f84b6455dc773d68ad2d94d0c6bb9aed7ea5d4fbf33339809d66b86998f96'
// NEW MAC - contracts built in PURPLE env, with MODERN sCrypt, and boilerplate
//const GENESIS_APPEND = 'bd48e615198f1f75f4fac69560e0db9c3ca6814d98d5ac375d44ef01f8188b75'
// Fixed placement of new state in spawned transient
//const GENESIS_APPEND = '06cbf82f862653ad2a2fd0d8c4ee48a0755d6ccded7829807debf39f5f5d7176'


// Feb 18, 2023 - added consecutivePostsHosted state to transient (modding Update to spawn new state)
//const GENESIS_APPEND = '1e66b44b68c8c3533d7c02255db66b8e5f73dae8bb726d16c334d193e95ff130'
// Feb 16, 2023 - adjusted dialog finalPost to not use MAX_INT
//const GENESIS_APPEND = 'ea16956be3bc7a3e63b68b2b7b4531f7a696b154d4091fe0c6bdc888dcca9e8c'
// Jan 17, 2023 - re-vamped Dialog (don't decrement downCounter in OTHER param)
//const GENESIS_APPEND  = 'b7addbbed9549290a75b152cd3558cbc2ffd46f70237448842a547779cf3adfa'
// Jan 17, 2023 - re-vamped Dialog (fixed owner downCounter in guest mode)
//const GENESIS_APPEND  = '1a158b81c2d4223aa98079c0f3f4646ba2cb4dc185a7c2d885b8e48b0d9eac61'
// Jan 17, 2023 - re-vamped Dialog (untangled guest vs owner fields)
//const GENESIS_APPEND  = '22a2024b8d91c9333e8a49bb180cddf15090e22912dc2defe29fd7963beaa623'
// Jan 14, 2023 - re-vamped Dialog (less-zealous downCounter extraction from Transient)
//const GENESIS_APPEND  = '56d6dcda1a918cc9ffaa426a91d616837b5a32e3aa9d565561f8228d95908bf9'
// Jan 14, 2023 - re-vamped Dialog (fixing downCounter extraction from Transient)
//const GENESIS_APPEND  = 'ccf818c75a94a900d7d5f7ab504256a5742b7133399c0834b3857d024b4d0454'
// Jan 13, 2023 - re-vamped Dialog (THRID try)
//const GENESIS_APPEND  = '4ea5b090418237caa9df766c43057dfa76f78eb401de918aed90ea0dcd96d116'
// Jan 13. 2023 - re-vamped Dialog (SECOND try)
//const GENESIS_APPEND  = 'f0aa5845f1032b6c3295117d00a586772ed7d865449a9bb6c77478b393ee3b94'
// Jan 13. 2023 - re-vamped Dialog (first try)
//const GENESIS_APPEND  = 'e410526d353db1f96761d7e8170866a9685534ed49a75d9f0343c7a6d1070fa3'
// Jan 7, 2023 - fixed transient contract to use correct amount for guest-post spawn
//const GENESIS_APPEND  = '94879b8c2ac253c19340ea9667fdb9d1f8170e13692a9743d2d2b8a45f653534'
// decreased max downCounter to 6. More finalPost+gp fixes (12/18)
//const GENESIS_APPEND = '7395f1f289d6451ed9c694f97ce6e9d51bb79fb265a4d442cc86b09c43cb40fa'
// more fixes for finalPost while guest-posting, and slow-down max downCounter growth (12/16)
//const GENESIS_APPEND = 'f901e8338eabb1272cda8f8258f773fd2192abc4ce2426a6e1287e8b28dbfe51'
// fixes for finalPost while guest-posting   (12/16)
//const GENESIS_APPEND = '5d38fdf54d558715d6344ae5197cd951abf993cdad46398ae74b3b7e64e6e4c9'
// now with contract support for adding ADDITIONAL balance to K  (12/5/2022)
//const GENESIS_APPEND = '5c64a781d2262aa6f667bf30a18628a81fa204cd032fe2ebf8b9b7f775570a52'
// now with claim-to-K support - even from P    (on Nov 22, 2022)
//const GENESIS_APPEND = '46fd068724b74649f58ea8b528367f5a64c941addde6c66660243839c317ce69'
// now with new immediately-spend-spawned-update feature (on Nov 14, 2022)
//const GENESIS_APPEND = 'e41cdd31d9918d8016bece259e8cfbb3cbb9b97c697c900b49df0f37330dd3cb'
// whooops. again on Nov 2:
//const GENESIS_APPEND = '01d3d18b3d926662f4ef9a50c3c01aeb95b53ce13e901abcf9dd811934585897'
// created with restore guest-posting on Nov 2, 2022:
//const GENESIS_APPEND = '011cf00e0c53e3b200260dbf221295188c6d87f2e86ff6fa05e0b86245f77ac0'
// created with SMALLEST_OUTPUT of 1 on October 13, 2022
//const GENESIS_APPEND = 'c0091274fe04cc643864a549336e2d3f53c050918cd2861933aa41ea77aef351'
// created with 16 outputs on OCtobe 13 2022 (still SMALLEST_OUTPUT of 5) - but never used this
//const GENESIS_APPEND = 'f7246797b174ca7c32301b1f59b9453640d881a20660f609c119eb515a395121'
// created on testnet October 11 - with cheaper contract outputs (5 sats)
//const GENESIS_APPEND = 'b159222a75dd4b6393f017ba3cf2343001f1e757ebb2fabdfb449aa652a34201'
// created on testnet October 6, 2022  ~3:35pm
//const GENESIS_APPEND =    '5666f6176c2a48b935eb6280cc25c456aa4fa4d7ce4d882b07d381cafbff9e8e'
// created on Testnet Sept 20, 2022  ~2pm
//const GENESIS_APPEND =  'adbd2a33691add36752146708071c72284364564d76e3c2e00c268b5fdbb9af4'
// created on Testnet Sept 5, 2022
//const GENESIS_APPEND = '476338343ea5537c3f73134759260884832529b0621dd0d66b76ffdb4201bf6b'
//   one tx too soon? need fan=out? '87cb195d75005b51060a918ea3c2d36e54d59b4ac367fa380066c4fc9676a2f9'
//const GENESIS_APPEND = '056f94f00bf4f08d2990768d81d8d9130007a220249bcea3d16ccfba81c6a435'
    //'59b2328a3308e9cede5b0d92ba3b5adaf15b4ffcd3b08e2200014728cd596fb9'

// These should match newBshzUtil.scrypt

// So far used ONLY by Claimer
// Setting to true is helpful when wanting to:
//   - claim with an OLD blockheight (for testing) - IF append tree setup with old heights
// Setting to false is helpful for:
//   - testing re-claiming
const HOLD_BACK_BLOCKHEIGHT_FOR_TESTING = false


// FIXME: shizzleBuilder should provide these values to UI layer

//FIXME: eventually use 365.25 etc
//export /* */
const THIRD_OF_YEAR_IN_BLOCKS = 365 * 48;
//export /* */
const FOURTH_OF_YEAR_IN_BLOCKS = 365 * 36;

//export /* */
const BLOCKS_IN_ONE_YEAR   = 365 * 144;

// For PUBLISHING, we require that MIN_BLOCKS_PER_UPDATE passes between publishings
//export /* */
const MIN_BLOCKS_PER_UPDATE = [547, 468, 400, 342, 292, 250, 213, 182, 156, 133, 114, 97, 83, 71, 61, 52];

//export /* */
const CLAIM_PAYOUT_MULTIPLIER = 5;  // this is for claiming, or pre-claiming an asset

//export /* */
const TRANSIENT_PAYOUT_MULTIPLIER = 5;

//export /* */
const BITGROUP_PAYOUT_MULTIPLIER = 2;

//export /* */
const BOUNTY_MULTIPLIER_DIALOG    = 2;
//export /* */
const BOUNTY_MULTIPLIER_TRANSIENT = 3;
//export /* */
const BOUNTY_MULTIPLIER_UPDATE    = 4;
//export /* */
const BOUNTY_MULTIPLIER_CONTINUE  = 5;
//export /* */
const BOUNTY_MULTIPLIER_BITGROUP  = 3;  //FIXME: change?

//export /* */
const NAMED_GROUP_SUBTHREAD_FEE_MULTIPLIER = 5; // multiply this by satsPerPost to get price of named group
//export /* */
const MAX_POSTS_FOR_UNNAMED_GROUP_SUBTHREAD = 500;

//export /* */
const BLOCKNUM_SIZE = 4;
//export /* */
const PKH_LEN = 20;
//export /* */
const MAX_PKHS = 6;     // The number of "BUILDER" PKHs (and Rabins, and votes) to hold on to - to payout
                        // for the privilege of claiming a domain, or publishing content
                        // When released, this might be 10.

const TOO_MANY_UTXOS = 16   // This relates to how many TRANSIENT utxos there are. NOT related to wallet

const RUNNER_INTERVAL_MS      = 120000//300000   // TEMP. was 120000
const ASSET_CHECK_INTERVAL_MS = 600000  // used only by shizzleNav

//export /* */
const MAX_NUM_BIDS = 5;    // must match newAskBid.scrypt's MAX_BIDS


// These don't appear in scrypts, but it's helpful to centralize them here

//export /* */
// NOTE: this number is used by the bsv library, but it's highly inaccurate (faulty?)
//       That's why we've found we need to ITERATE when applying fees
//const DEFAULT_FEE_PER_KB = 82 // Change again on June 29, from 390 to 107 - when we dropped our target to 0.052
                                // Changed on June 29, 2022 from 524 to 390 - realizing our calls to evaluateFee() had been passing tx len of DOUBLE actual len
                                // Changed again on Nov 2 (later) from 1020 to 524 (aiming for just above .25 sat/B)
                                // Changed on Nov 2, 2021 from 2040 to 1020 (aiming for .5 sat/B)
                                // changed on Feb 22, 2021 from 1200 to 2040
                                // Trying to get end-results sat/B to be 1

// affects fees when claiming (among others)
const DEFAULT_FEE_PER_KB = 1.4  // first changed from 107 on 10/11/22 because we saw a lot of 0.087 sat/B  // a kludgy parameter. Not exact by any stretch
                                // 80 got us 0.06567
                                // 70 got us 0.05745 sats/B
                                // 65 gets us 0.05337 sats/B
                                // 62 gets us 0.05???? sats/B
                                // 58 was in use until July 17, 2023
                                // July 17 attempt of 5 (resulted in ~4.5 s/B)
                                // July 17 attempt of 2 (resulted in ~1.8 s/B)
                                // July 17 attempt of 1.4 (results in ~1.26 s/B)

// mostly affects normal posts (transients)
// (sats/Byte)
const MIN_RATE    = 0.00125     // 0.05 was in use  (worked fine)
const TARGET_RATE = 0.0013      // 0.052 was in use (worked fine)
const MAX_RATE    = 0.00143     // 0.00135 was looping (paid too much) within evaluateFee()     // 0.054 was in use (worked fine)

const SATS_PER_LETTER = 402             // changed to 402 on July 17, 2023 - with fanout of 20 ('t'), and DEFAULT_FEE_PER_KB of 1.4 (empirically with 16-letter name) <-----
                                        //    final few txs had ~ 1.262 s/B
                                        // changed to 581 on July 17, 2023 - with fanout of 20 ('t'), and DEFAULT_FEE_PER_KB of 2 (empirically with 11-letter name)
                                        //13100     // now with fanout to 't' (fanout 20), and DEFAULT_FEE_PER_KB of 62 (generally 0.0519 sats/B)  (on Feb 16, 2023)
                                        //9299  // calculated empirically, by claiming a 20-character domain (building all of them)
                                        // 9939 with DEFAULT_FEE_PER_KB of 62 (generally 0.05373)

//FIXME: maybe we can use TARGET_RATE in a calc to replace magic 60000 sats
const MIN_WALLET_SATS = Math.max( 60000, SATS_PER_LETTER * 100 )

// revert to 16 (what we've committed to git)?
const MAX_WALLET_UTXO_COUNT = 10     // max # of non-dusty coins
                                     // though, nowadays, there's probably no such thing as dusty

// limit funds at-risk. Don't allow wallet to hold too much
const MAX_WALLET_DOLLARS_A = 15.00  //3.00
const MAX_WALLET_DOLLARS_B = 15.01  //3.01

//FIXME: perhaps can reduce these by a factor of 40 (or more) too
//export /* */
const FINAL_POST_FEE_PER_KB = 9000
//export /* */
const TIP_TRANSIENT_FEE_PER_KB = 1000
//export /* */
const BOUNTY_CLAIM_FEE_PER_KB = 17000


//		int SPECIAL_OP_claimExpiredAsset = 1;

const CONTINUE_SPECIAL_OP_CLAIM_EXPIRED_ASSET = 1
const CONTINUE_SPECIAL_OP_APPLY_AUTHCODE = 2
const CONTINUE_SPECIAL_OP_INSTA_BUY = 3
const CONTINUE_SPECIAL_OP_BUILDER_VOTES_BASE = 4  //(apply ebfra/'vote') 4: builder 0 votes.  5: builder 1 votes.  6: builder 2 votes...

//export /* */
const NETWORK = "testnet"
const API_NETWORK = "test"    // WoC uses "test", or "main"

const PAYLOAD_ESCAPE_FLAG = '*$^&'

/* */
module.exports = {
    APP_TITLE,
    IDB_NAME,
    GENESIS_APPEND,

    USE_BITAILS_FULLY,

    HOLD_BACK_BLOCKHEIGHT_FOR_TESTING,          /// IMPORTANT for testing. Change for Prod

    THIRD_OF_YEAR_IN_BLOCKS,
    FOURTH_OF_YEAR_IN_BLOCKS,
    BLOCKS_IN_ONE_YEAR,
    MIN_BLOCKS_PER_UPDATE,
    CLAIM_PAYOUT_MULTIPLIER,

    TRANSIENT_PAYOUT_MULTIPLIER,
    BITGROUP_PAYOUT_MULTIPLIER,

    BOUNTY_MULTIPLIER_DIALOG,
    BOUNTY_MULTIPLIER_TRANSIENT,
    BOUNTY_MULTIPLIER_UPDATE,
    BOUNTY_MULTIPLIER_CONTINUE,
    BOUNTY_MULTIPLIER_BITGROUP,

    NAMED_GROUP_SUBTHREAD_FEE_MULTIPLIER,
    MAX_POSTS_FOR_UNNAMED_GROUP_SUBTHREAD,

    BLOCKNUM_SIZE,
    PKH_LEN,
    MAX_PKHS,

    TOO_MANY_UTXOS,
    MAX_NUM_BIDS,

    RUNNER_INTERVAL_MS,
    ASSET_CHECK_INTERVAL_MS,

    DEFAULT_FEE_PER_KB, // used for claiming Transient bounty too
    MIN_RATE,
    TARGET_RATE,
    MAX_RATE,

    SATS_PER_LETTER,
    MIN_WALLET_SATS,
    MAX_WALLET_UTXO_COUNT,

    MAX_WALLET_DOLLARS_A,
    MAX_WALLET_DOLLARS_B,

    FINAL_POST_FEE_PER_KB,
    TIP_TRANSIENT_FEE_PER_KB,
    BOUNTY_CLAIM_FEE_PER_KB,

    CONTINUE_SPECIAL_OP_CLAIM_EXPIRED_ASSET,
    CONTINUE_SPECIAL_OP_APPLY_AUTHCODE,
    CONTINUE_SPECIAL_OP_INSTA_BUY,
    CONTINUE_SPECIAL_OP_BUILDER_VOTES_BASE,

    NETWORK,
    API_NETWORK,

    PAYLOAD_ESCAPE_FLAG
}
/* */
