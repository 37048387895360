// BackgroundRunner

import React from 'react';
import { openDB } from './buildShizzle';

/**
 * When instantiating, include these props:
 *     runnerCheckInterval     - how often to run
 *     handleRef               - where to send a reference to ourselves
 *     periodicCall            - parent func to call periodically
 */
class BackgroundRunner extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            networkError: false,
            runnerTimerId: null,
            clientsArray: []
        }

        this.periodicRun                  = this.periodicRun.bind(this);
        this.addBackgroundRunnerClient    = this.addBackgroundRunnerClient.bind(this)
        this.removeBackgroundRunnerClient = this.removeBackgroundRunnerClient.bind(this)

        this.setNetworkError              = this.setNetworkError.bind(this)
        this.clearNetworkError            = this.clearNetworkError.bind(this)
        this.reviewNetworkStatus          = this.reviewNetworkStatus.bind(this)
    }

    componentDidMount() {

        const intervalMillis = parseInt(this.props.runnerCheckInterval)
        console.warn("BR cDM(): initial timer interval: ", intervalMillis)

        const me = this

        this.props.handleRef(this)

        //NOTE: this could be cancelled (and re-instated) in expeditePeriodicCheck()
        const timerId = setInterval(async function() {
                                        await me.periodicRun(me, intervalMillis);
                                    }, intervalMillis);

        // if network error, we cancel this timer, and shorten it
        // And when network clears, we restore this interval
        // Once we've saved the timerId to state, perform first call to periodicRun()

        console.warn("componentDidMount: BTW runner timerId: "+ timerId)

        // perform the INITIAL periodic callback
        this.setState(
                        {runnerTimerId: timerId},
                        () => this.periodicRun(me, intervalMillis)
                     )
    }

    // called by parent to see what the network status HAD BEEN
    // (to help determine if anything has CHANGED)
    reviewNetworkStatus() {
        if ( this.state.networkError ) {
            console.error("BR: We HAD HAD a network error.   <-----")
            // We HAD a network problem, but it's fine now
            // We'll let the PARENT 'clear' the network error

            return true
        }

        console.log("BR: NOPE. We had NO network error.")
        return false
    }

    setNetworkError() {
        console.error("SETTING NETWORK ERROR condition")

        //IMPLEMENT ME: explicitly inform client - so they can take client-specific action(s) ?

        // reduce re-try period to 10 seconds, from 60 (or 20)
        const intervalMillis = parseInt(this.props.networkCheckInterval)

        const me = this

        console.log("setNetworkError(): clearing old backgroundRunner runnerTimerId " + this.state.runnerTimerId)

        clearInterval(this.state.runnerTimerId)

        //NOTE: this could be cancelled (and re-instated) in expeditePeriodicCheck()

        // when network clears, we restore this interval
        const timerId = setInterval(async function() {
                                        await me.periodicRun(me, intervalMillis);
                                    }, intervalMillis);

        console.log("setNetworkError() new backgroundRunner runnerTimerId: ", timerId)

        this.setState({networkError: true, runnerTimerId: timerId})
    }

    clearNetworkError() {
        console.error("CLEARING NETWORK ERROR condition. Restoring timer interval of " + this.props.runnerCheckInterval)

        const intervalMillis = parseInt(this.props.runnerCheckInterval)

        const me = this

        console.log("clearNetworkError(): clearing old backgroundRunner timerId: ", this.state.runnerTimerId)
        clearInterval(this.state.runnerTimerId)

        //NOTE: this could be cancelled (and re-instated) in expeditePeriodicCheck()
        const timerId = setInterval(async function() {
                                        await me.periodicRun(me, intervalMillis);
                                    }, intervalMillis);
        console.log("clearNetworkError(): NEW timer id: ", timerId)

        this.setState({networkError: false, runnerTimerId: timerId})

        //IMPLEMENT ME: explicitly inform client - so they can take client-specific action(s) ?
    }

    // clients call this to receive periodic service
    addBackgroundRunnerClient(us, clientRef, name) {
        const clientsArray = us.state.clientsArray

        console.log("addBackgroundRunnerClient(): should ADD client named " + name)
        console.log("addBackgroundRunnerClient(): clients so far: " + clientsArray.length)

        clientsArray.push({name: name, client: clientRef})

        console.log("addBackgroundRunnerClient(): clients NOW: ", clientsArray.length)

        us.setState({clientsArray: clientsArray})
    }

    // clients call this to be removed from service
    removeBackgroundRunnerClient(us, clientRef, name) {
        const clientsArray = us.state.clientsArray

        console.log("removeBackgroundRunnerClient(): should REMOVE client named " + name)
        console.log("removeBackgroundRunnerClient(): clients so far: ", clientsArray.length)

        for ( let i = 0; i < clientsArray.length; i++ ) {
            if ( clientsArray[i].name === name ) {
                console.log("rBRC() - should REMOVE client " + i + ": ", clientsArray[i])
                clientsArray.splice(i, 1)
            }
        }

        console.log("removeBackgroundRunnerClient(): clients NOW: ", clientsArray.length)

        us.setState({clientsArray: clientsArray})
    }

    async periodicRun(me, intervalMillis) {

        console.warn("BackgroundRunner  periodicRun(): FIRST, will callback parent/app function...")
        console.log("    this.state.runnerTimerId: ", this.state.runnerTimerId, ", with intervalMills of ", intervalMillis)

        const networkCheckIntervalMillis = parseInt(this.props.networkCheckInterval)
        // sanity check that the period/interval matches the network status
        if ( intervalMillis === networkCheckIntervalMillis ) {
            if ( !this.state.networkError ) {
                alert("Hmm. Is it possible there are TWO timers running in parallel? state.networkError is CLEAR. PLEASE REPORT THIS.")
                //FIXME: How to resolve? If we remove/cancel a timer, be sure that SOMETHING is running
            }
        } else {
            if ( this.state.networkError ) {
                alert("Hmm. Is it possible there are TWO timers running in parallel? state.networkError is SET. PLEASE REPORT THIS.")
                //FIXME: How to resolve? If we remove/cancel a timer, be sure that SOMETHING is running
            }
        }

        await this.props.periodicCall(me)

        const clientsArray = me.state.clientsArray
        if ( clientsArray.length > 1 ) {
            //alert("NOTE: we have more than 1 client to service. Probably an ERROR")
            throw new Error("44332: CODE ERROR: too many clients: " + clientsArray.length)
        }
        console.log(("BR periodicRun(): there are " + clientsArray.length + " clients we *could* now service/ping"))
        for ( let i = 0; i < clientsArray.length; i++ ) {
            console.log("BR periodicRun() - NOW, we should service client #" + i + ": ", clientsArray[i])
        }
    }

    render() {
        return null
    }
}
export default BackgroundRunner;
