import React from 'react';

import {  Modal, Loader  } from 'semantic-ui-react'


class Toast extends React.Component {
  constructor(props) {
    super(props);

    //FIXME: the purples are currently duplicated in index.css
    this.bshzPurple             = '#8313e2';
    this.bshzYellow             = "#fff300";
    this.bshzLtPurp             = '#e7d0fb';
    this.bshzLightYellow        = "#fffccc";

    this.closeToast = this.closeToast.bind(this);
    this.timerDone  = this.timerDone.bind(this);

    this.timerId = null;

  }

  async componentDidMount() {

    const waitMillis = this.props.durationMillis ?
            this.props.durationMillis
          :
            750

    // pass this/me?
    this.timerId = setTimeout(() => this.timerDone(), waitMillis);

  }

  timerDone() {
    this.timerId = null
    console.warn("TOAST: timer done")
    this.closeToast()
  }

  closeToast() {
    console.warn("closeToast()")
    //this.setState({showExpshoortModal: false})
    if ( this.props.done ) {
      if ( this.props.parent ) {
        console.warn("closeToast() calling our props.done()... NOTE: ")
        this.props.done( this.props.parent )
      } else {
        console.error("IMPLEMENTATION ERROR: property 'parent' not defined for <Toast>")
      }
    }
  }

  render() {
  
    //COPIED FROM ONRAMP

    // ***  DEVICE-DEPENDENT STYLING  ***
    // For mobile, "detect" landscape screen mode
    const landscape = this.props.isMobile ? window.innerWidth > window.innerHeight : false
    // For mobile, shrink the About, Security, and Glossary modals
    const modalClassName   = this.props.isMobile ?
                                    (landscape ?
                                            "modalMobileLandscape"
                                        :
                                            "modalMobilePortrait"
                                    )
                                :
                                    ""
    const modalContentClassName = this.props.isMobile ? "modalContentMobile" : ""
    //const modalBottomClassName  = this.props.isMobile ? "modalBottomMobile"  : ""


    console.warn("Toast: ", this.props.displayText)

    const maybeLoader = this.props.noLoader ?
                                <>
                                  <div style={{textAlign: 'center'}}>
                                    {this.props.displayText}
                                  </div>
                                </>
                              :
                                <>
                                  <Loader inline style={{color: "black"}}>
                                    {this.props.displayText}
                                  </Loader>
                                </>

    // mini, tiny, small, large, fullscreen
    return <>
            <Modal dimmer='blurring' size='tiny' centered className={modalClassName}  open={true} style={{backgroundColor: this.bshzPurple, borderRadius: '20px'}}>
                <Modal.Content className={modalContentClassName} scrolling style={{backgroundColor: this.bshzLtPurp}}>

                  {maybeLoader}

                </Modal.Content>
            </Modal>
          </>
  }
} // Toast

export default Toast;