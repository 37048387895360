import React from 'react';
import ReactDOM from 'react-dom/client';

import './index.css';

// this line was key
import 'semantic-ui-css/semantic.min.css'

import BitShizzleApp from './bitShizzleApp.js'

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <>
    <BitShizzleApp />
  </>
);
