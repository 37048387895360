import React from 'react';

import { Button, Checkbox, Container, Divider, Dropdown, Form, Grid,
    Input, Loader, Menu, Message, Modal, Popup, Radio, TextArea } from 'semantic-ui-react'

import { execAsync } from './commonFuncs';

class ProgressListModal extends React.Component {
  constructor(props) {
    super(props);

    //FIXME: the purples are currently duplicated in index.css
    this.bshzPurple             = '#8313e2';
    this.bshzYellow             = "#fff300";
    this.bshzLtPurp             = '#e7d0fb';
    this.bshzLightYellow        = "#fffccc";

    this.doSomethingAsync       = this.doSomethingAsync.bind(this);
    this.handleModalButtonClick = this.handleModalButtonClick.bind(this);
    this.handleModalCancelClick = this.handleModalCancelClick.bind(this);
    this.logReportOfProgress    = this.logReportOfProgress.bind(this);

    this.resetModal             = this.resetModal.bind(this);

    this.state = {
      doneFuncCalled: false,
      asyncThingRecentlyStarted: false,
      doneWithAsync: false,  // enables green OK button
      stepNum: 0,
      modalContent: '',
      canceled: false,
    };
  }

  componentWillUnmount() {
      // fix Warning: Can't perform a React state update on an unmounted component
      // see: https://stackoverflow.com/questions/53949393/cant-perform-a-react-state-update-on-an-unmounted-component
      this.setState = (state,callback)=>{
          return;
      };
  }

  logReportOfProgress(newReport) {
    console.warn("logReportOfProgress():  " + newReport)
    this.setState({modalContent: <>{this.state.modalContent} <br></br> &nbsp; &#8226; {newReport} </>})

    // each time we display more content, scroll down
    document.getElementById('plm')?.scrollBy({
      top: 100,
      left: 0,
      behavior: 'smooth'
    })
  }

  // called by user/client when progress is 100% complete
  // We would now disable the 'Cancel' button
  fullyDone(parentRef, logEntry) {
    parentRef.setState({doneFuncCalled: true})


    // IFF this is a FLEETING modal. we should NOW make it disappear
    if ( parentRef.props.fleeting ) {
      // tell owner/parent to hide us (instead of waiting for user to hit 'Ok')
      parentRef.props.onDone()
    }
  }

  async doSomethingAsync() {
    // enable the CANCEL button
    this.setState({doneFuncCalled: false})

    console.warn("ProgressList doSomethingAsync() with " + this.props.domain + "...")

    this.setState({canceled: false})

    await this.props.ourWorkToDo( this,
                                  this.logReportOfProgress,
                                  this.finishTheCancel,
                                  this.fullyDone,
                                  this.props.domain,
                                  this.resetModal            // a function to call if/before re-using this modal
                                )
/*
  //FIXME: use swirl:   ui active inline loader
  //FIXME: ALSO: Use colors to show progress
*/

    //console.log("doSomethingAsync():  ENABLING OK BUTTON <=====")
    this.setState({doneWithAsync: true, canceled: false})  // enable green OK button
  }

  // if you plan to maybe use the PLModal again
  // we pass a ref to this function when the work commences (when we call this.props.ourWorkToDo())
  resetModal() {
    this.setState({asyncThingRecentlyStarted: false, modalContent: '...'})
  }

  handleModalButtonClick() {
    console.warn("handleModalButtonClick: MODAL CLICK - so, aTRS will get reset...")

    //FIXME: why not prevent default?

    this.setState({asyncThingRecentlyStarted: false, modalContent: '...'})
    this.props.onDone()
  }

  //NOTE: this INITIATES the cancel
  handleModalCancelClick() {
    console.warn("handleModalCancelClick: CANCELLING...")

    //this.setState({canceled: true, asyncThingRecentlyStarted: false, modalContent: '...'})


    // do something ASYNC to stop it, THEN call .onDone() later, when finished
    console.warn('PL --> calling onCancel()')
    this.props.onCancel()
  }

  finishTheCancel(parent) {
    console.warn("PL: FINALLY canceled...")
    parent.setState({canceled: true, /*asyncThingRecentlyStarted: false,*/ modalContent: '...'})

    console.warn('PL --> calling onDone()')
    parent.props.onDone()
  }

  render() {

        // copied from onRamp:
        // ***  DEVICE-DEPENDENT STYLING  ***
        // For mobile, "detect" landscape screen mode
        const landscape = this.props.isMobile ? window.innerWidth > window.innerHeight : false
        // For mobile, shrink the About, Security, and Glossary modals
        const modalClassName   = this.props.isMobile ?
                                        (landscape ?
                                                "modalMobileLandscape"
                                            :
                                                "modalMobilePortrait"
                                        )
                                    :
                                        ""
        const modalContentClassName = this.props.isMobile ? "modalContentMobile" : ""
        const modalBottomClassName  = this.props.isMobile ? "modalBottomMobile"  : ""


    if ( !this.state.asyncThingRecentlyStarted && this.props.openProgressList ) {
      console.log("ProgressList render(): NOT recentlyStarted. Doing so, now...")
      console.warn(" this.props.openProgressList: " + this.props.openProgressList)
      const parent = this
      execAsync( async function() {
        //NOTE: by setting PRIOR, MAYBE we avoid an infinite loop
        parent.setState({asyncThingRecentlyStarted: true, doneWithAsync: false, canceled: false})

        // initiate some work
        await parent.doSomethingAsync()
      })
      console.log("ProgressList render() called execAsync()...")
    } else if ( this.state.asyncThingRecentlyStarted && this.props.openProgressList ) {
      //console.log("   ---> BTW: recentlyStarted, and progressList open ,-----")
    }

    if ( this.state.doneWithAsync ) {
      //console.log("PL: DONE WITH ASYNC <-- so, GREEN OK ENABLED")

    }
    const pleaseWait = this.state.doneWithAsync ?
                        <></>
                      :
                        <Loader  inline style={{color: "black"}}>
                          Please wait...
                        </Loader>

    // If we're in 'fleeting' mode, don't show the modal action buttons (cancel, Ok)
    const modalActionButtons = this.props.fleeting ?
                            <></>
                          :
                            <>
                              <Modal.Actions className={modalBottomClassName} style={{backgroundColor: this.bshzPurple, borderRadius: '0px 0px 20px 20px'}}>
                                <Button disabled={!this.state.doneWithAsync} content='OK' positive onClick={this.handleModalButtonClick}/>
                                <Button disabled={this.state.doneFuncCalled} onClick={ this.handleModalCancelClick } content='CANCEL' negative/>
                              </Modal.Actions>
                            </>

    //FIXME: ALSO: Use colors to show progress
    return (
      <Modal  dimmer='blurring' open={this.props.openProgressList}
              className={modalClassName} style={{backgroundColor: this.bshzPurple, borderRadius: '20px'}}>
        <Modal.Header style={{textAlign: 'center', backgroundColor: this.bshzPurple, borderRadius: '20px'}}>
          <span style={{color: this.bshzYellow}}> {this.props.title} </span>
        </Modal.Header>

        <Modal.Content id="plm" scrolling className={modalContentClassName} style={{backgroundColor: this.bshzLtPurp}}>
            <div>
              {this.props.description}
            </div>
            <div>
              {this.state.modalContent}
            </div>

            <p></p>
            {pleaseWait}
        </Modal.Content>

        {modalActionButtons}

      </Modal>
    );
  }
}  // ProgressList

export default ProgressListModal;
