// shizzleView.js

// simple interface to search for, and view a single asset/domain

import React from 'react';

import { Button, Divider, Icon, Input } from 'semantic-ui-react'

import Identicon from 'react-identicons'

import Toast    from './toast';

import {
  openDB,
  //findLatestContinue,
  findLatestContinue2,
  getClosestToLimb,
  queryFetchDecodeTx,
  findNextTx,
  getTheNextAddress,
  //getNextDialogAddress,
  getNextDialog,

  registerASubscription,
  unRegisterASubscription,
  registerDomainOfMine,
  unRegisterDomainOfMine,
  findADomainOfMine,
  findAllDomainsOfMine,
  //findADialog,
  //findAllTheDialogs,
  getASubscription,
  mySleep
} from './buildShizzle.js';

import {
	getDecodedTx,
} from "./shizzleIDB"

import {
  hexByteToAscii,
  hexStringToAscii,
  //swapEndian,
  //execAsync
} from './commonFuncs';

import { maybeDecryptContent }  from './contentFuncs.js';

import ProgressListModal from './progressListModal.js'
import ContentPresenter from './contentPresenter.js';

import DialogModal from './dialogModal.js';
import DomainModal from './domainModal.js';
import SubscriptionsModal from './subscriptionsModal.js';

import { GENESIS_APPEND, APP_TITLE } from './harnessConstants.js'

import './index.css';

async function queryFunction(a, b, queryProvider = false) {
  if ( queryProvider ) {
    return await queryFetchDecodeTx(a, b)
  } else {
    return await getDecodedTx(b, a)
  }
}


/**
 * When instantiating, include these props:
 *     parent
 *     show
 *     handleSwitchToShizzleNav
 *     registerCient
 */
class ShizzleView extends React.PureComponent {
  constructor(props) {
    super(props);
    document.title = APP_TITLE

    this.handleViewerClick     = this.handleViewerClick.bind(this);
    this.handleDomainBarChange = this.handleDomainBarChange.bind(this);
    this.goToClickedDomain     = this.goToClickedDomain.bind(this);
    this.goToClickedDialog     = this.goToClickedDialog.bind(this);
    this.goToDomain            = this.goToDomain.bind(this);
    this.closeProgressList     = this.closeProgressList.bind(this);
    this.workCanceled          = this.workCanceled.bind(this);
    this.doWork                = this.doWork.bind(this);
    this.walkTheAppends        = this.walkTheAppends.bind(this);
    this.tryGoingFurtherInQuarterlies = this.tryGoingFurtherInQuarterlies.bind(this);
    this.tryGoingFurtherInUpdates = this.tryGoingFurtherInUpdates.bind(this);
    this.tryGoingFurtherInTransients = this.tryGoingFurtherInTransients.bind(this);
    this.handlePrevious        = this.handlePrevious.bind(this);
    this.handleForward         = this.handleForward.bind(this);
    this.setAddressBar         = this.setAddressBar.bind(this);
    this.nullProgressFunc      = this.nullProgressFunc.bind(this);

    this.openDialogMenu        = this.openDialogMenu.bind(this);
    this.openDomainMenu        = this.openDomainMenu.bind(this);
    this.openSubscriptionsMenu = this.openSubscriptionsMenu.bind(this);
    this.registerADomainOfMine = this.registerADomainOfMine.bind(this);
    this.unRegisterADomainOfMine = this.unRegisterADomainOfMine.bind(this);
    this.subscribeToDomain     = this.subscribeToDomain.bind(this);
    this.unSubscribeToDomain   = this.unSubscribeToDomain.bind(this);
    this.getRelevantOutputIndex= this.getRelevantOutputIndex.bind(this);
    this.getOwnerCountAndLimb  = this.getOwnerCountAndLimb.bind(this);

    this.processSearchResults  = this.processSearchResults.bind(this);

    this.submodeSetDomainFunction = this.submodeSetDomainFunction.bind(this);
    this.submodeClickButton       = this.submodeClickButton.bind(this);
    this.plmReset = null

    this.setReportOnGuestPosts    = this.setReportOnGuestPosts.bind(this);
    this.reportGuestPostFunc   = this.reportGuestPostFunc.bind(this);

    this.jumpToTx              = this.jumpToTx.bind(this);
    this.reCalcOurDomains      = this.reCalcOurDomains.bind(this);

    this.handlePostTweetClick  = this.handlePostTweetClick.bind(this);

    this.shareLinkToPost       = this.shareLinkToPost.bind(this);
    this.quickToast            = this.quickToast.bind(this);
    this.quickToastDone        = this.quickToastDone.bind(this);

    const windowURL = window.location.href // returns the absolute URL of a page
    console.warn("SView: our URL (may be useful for innerFrame logic): ", windowURL)

    const windowPathname = window.location.pathname
    const pathSplit = windowPathname.split('/')
    console.log("SView: window pathname split : ", pathSplit)
    const viewMode = pathSplit.length > 0 ? pathSplit[1] : 'nav'    // nav or view
    console.warn("SView:   initial viewMode: " + viewMode)

    if ( pathSplit.length > 2 && pathSplit[2].length > 0 ) {

      //NOTE: we're currently passive wrt the address bar
      //      ShizzleNav reacts to the address bar

      console.warn("SView: MAYBE we're being asked to look at shizzle asset URL bshz://" + windowPathname)

      //if ( viewMode === 'nav' ) {
      //  console.warn("Will change to 'nav' view...")
      //  this.props.handleSwitchToShizzleNav()
      //}
    }

    this.state = {
      currentTxId: null,
      decodedTx: '',
      found: false,
      owned: false,
      reachedLimb: '',
      extraVerbage: '',

      domainToVisit: '',
      foundOwnerCount: 0,     // set only when reporting submodeResponse
      openTheProgressList: false,
      jobCanceled: false,
      count: 0,            // for progressModalList   - for unique lines?  //FIXME: rename to more descriptive

      dialogMenuOpen:        false,
      domainMenuOpen:        false,
      subscriptionsMenuOpen: false,

      registeredToUs: false,
      weSubscribeToThis: false,

      txClass: 'regular',

      showQuickToast:         false,
      toastText:              '',
      toastDuration:          800,
      suppressLoader:         false,
    };


    // We found this necessary for WoC
    //this.initialSleepForProviders = 300
    //this.iterativeSleepForProviders = 250
    //REDUCED on 10/31/22 since we now have simple provider management (alternating providers)
    //this.initialSleepForProviders = 100
    //this.iterativeSleepForProviders = 50
    //REDUCE again on same day
    //this.initialSleepForProviders = 75
    //this.iterativeSleepForProviders = 25
    //REDUCE again on same day
    this.initialSleepForProviders = 50
    this.iterativeSleepForProviders = 40     // 6/30/23  20ms was too quick. Increased to 40ms
  }

  componentWillUnmount() {
    console.warn("ShizzleView about to unmount. Will UNregister...")

    if ( this.props.parent && this.props.unregisterClient ) {
console.log("shizzleView cWU() start")
      this.props.unregisterClient(this.props.parent, this, "shizzleView")
console.log("shizzleView cWU() end")
    }

    // fix Warning: Can't perform a React state update on an unmounted component
    // see: https://stackoverflow.com/questions/53949393/cant-perform-a-react-state-update-on-an-unmounted-component
    this.setState = (state,callback)=>{
        return;
    };
  }

  async reCalcOurDomains() {

    const domainsArray = await findAllDomainsOfMine(await openDB())

    console.log("reCalcOurDomains(): ", domainsArray)

    // Maybe this can help us when we look to display our dialogs
//FIXME: but, we'd want to update it when adding/removing domains
    this.setState({ourDomainsArray: domainsArray})
  }

  async componentDidMount() {

    console.warn("SV componentDidMount()")

    if ( this.props.parent && this.props.registerClient ){
      this.props.registerClient(this.props.parent, this, "shizzleView")
    }

    if ( this.props.submode ) {
      console.error("SHIZZLE VIEW is in a special mode - SUB mode")

      if ( this.props.registerSubmodeCallback && this.props.parent ) {

        // Give parent access to some functions of ours
        // NOTE: we now register THREE callback functions
        this.props.registerSubmodeCallback( this.props.parent,
                                            this,
                                            this.submodeSetDomainFunction, // kicks-off walking to the tip of a domain
                                            this.submodeClickButton,       // back/fwd
                                            this.setReportOnGuestPosts)    // whether to report on announced domains (guest-posts on 'banno')
      }

      // we want to INSTANTLY start searching through a specified domain
      this.setState({domainToVisit: this.props.domainToView, openTheProgressList: true})
    }

    await this.reCalcOurDomains()
  }

  // Here, the parent can direct us to go to a domain
  submodeSetDomainFunction(self, domain) {
    console.warn("shizzleView submodeSetDomainFunction() We've been told to jump to domain: " + domain)

    this.plmReset()

    self.goToClickedDomain(self, domain)
  }

  setReportOnGuestPosts(self, value) {
    console.error("shizzleView(): changing setting of reportOnGuestPosts: " + value)
    self.setState({reportOnGuestPosts: value})
  }

  // The parent can 'control' our display (forward, or back a tx), by calling this
  async submodeClickButton(self, buttonType, extraParam = null) {
    console.warn("shizzleView submodeClickButton(). we've been passed buttonType: " + buttonType)
    if ( buttonType === 'next' ) {
      console.warn("jumping FORWARD one tx")
      await this.handleForward(false)
    } else if ( buttonType === 'prev' ) {
      console.warn("jumping BACKWARD one tx")
      await this.handleForward(true)
    } else {
      console.error("unrecognized operation: " + buttonType)
    }
  }

  openDialogMenu() {
    console.warn("openDialogMenu(): opening...")
    this.setState({dialogMenuOpen: true})
  }

  openDomainMenu() {
    console.warn("openDomainMenu(): opening...")
    this.setState({domainMenuOpen: true})
  }

  openSubscriptionsMenu() {
    console.warn("openSubscriptionsMenu(): opening...")
    this.setState({subscriptionsMenuOpen: true})
  }

  closeDialogMenu(us) {
    console.warn("closeDialogMenu(): closing...")
    us.setState({dialogMenuOpen: false})
  }

  closeDomainMenu(us) {
    console.warn("closeDomainMenu(): closing...")
    us.setState({domainMenuOpen: false})
  }

  closeSubscriptionsMenu(us) {
    console.warn("closeSubscriptionsMenu(): closing...")
    us.setState({subscriptionsMenuOpen: false})
  }

  handlePostTweetClick() {
    console.warn("handlePostTweetClick():")

    this.setState({openBuilderModal: true})
  }

  async handleViewerClick() {
    console.warn("viewer future function button clicked")

    console.warn("====================================================")
/*
    // Generate an ECDH object for geekA
    const geekA = crypto.createECDH('secp256k1'); //'secp521r1');
    const geekB = crypto.createECDH('secp256k1'); //secp521r1');

    // Create a private key of geekA
    geekA.setPrivateKey("thisisasecretkey!");

    // Create a private key of geekB
    geekB.setPrivateKey("thisisanotherkey!");
  
    // Get the private keys of both the geeks
    let geekAPrivateKey = geekA.getPrivateKey();
    let geekBPrivateKey = geekB.getPrivateKey();
  
    console.log("Private Key of Geek A is:",
            geekAPrivateKey);
    console.log("Private Key of Geek B is:",
            geekBPrivateKey);
*/


    // INSTRUCTIVE: testing call to findLatestContinue2()
    //const res1 = await findLatestContinue2(await openDB(), this.state.domainToVisit, owner);
    //console.error("res1: ", res1)

    // It's possible the search for the logical next address
    // ignored a UTXO that was recently spent without our knowledge
    // FIXME: We could check for an UNSPENT
/* */

    console.warn("====================================================")
  }

  handleDomainBarChange(event) {

    // only allow suitable characters: a-z  0-9, - ?
    const re = /^[a-zA-Z0-9]+$/;

    //NOTE: we ALSO clear the currentTxId, and decodedTx
    //      This will clear any previous site presentation

    if ( event.target.value === '' || re.test(event.target.value)) {
      console.warn("domain bar entry altered: " + event.target.value)

      this.setState({domainToVisit: event.target.value.toLowerCase(),
        reachedLimb: '',
        currentTxId: null,
        decodedTx: '',
        registeredToUs: false,
        weSubscribeToThis: false,
        found: false,
        owned: false
      })
    } else {
      console.warn("ignoring invalid domain: " + event.target.value)
      this.setState({currentTxId: null, decodedTx: '', reachedLimb: ''})
    }
  }

  goToClickedDialog(us, dialogTxId, dialogId) {
    console.warn("goToClickedDialog: " + dialogTxId + " w/ dialogId " + dialogId)

    us.jumpToTx(dialogTxId, us, 'dialog', dialogId)
  }

  goToClickedDomain(us, domain) {
    console.warn("goToClickedDomain: " + domain )

    this.setState({
        domainToVisit: domain,
        reachedLimb: '',
        currentTxId: null,
        decodedTx: '',
        openTheProgressList: true,
        count: this.state.count+1
    })
  }

  goToDomain(event) {
    console.warn("goToDomain: " + this.state.domainToVisit )

    this.setState({openTheProgressList: true, count: this.state.count+1})
    event.preventDefault();
  }

  workCanceled() {
    console.warn("The job was just canceled")
    this.setState({jobCanceled: true})
  }

  reportGuestPostFunc(name) {
    if ( this.state.reportOnGuestPosts && this.props.guestPostReport ) {
      console.error("shizzleView found a guest-poster to report: " + name)
      this.props.guestPostReport(name)
    }
  }

  async tryGoingFurtherInTransients( latestUpdate, progressFunc, modeToSearch = 0 ) {

    await mySleep(this.initialSleepForProviders, "tGFIT() 0")
    console.warn("tryGoingFurtherInTransients...(): latestTx: ", latestUpdate)
    progressFunc("ENTERING tryGoingFurtherInTransients... with modeToSearch " + modeToSearch + ", latestTx " + latestUpdate)

    const db = await openDB()
    let latestTx
    let latestTxId = latestUpdate
    let foundAny = false
    do {
      console.log("top of tGFIT(): latestTxId: ", latestTxId)
      latestTx = await queryFetchDecodeTx(latestTxId, db,
                                          true,
                                          null,
                                          false,
                                          this.props.guestPostWeAreAPartyToCallback ?
                                              this.props.guestPostWeAreAPartyToCallback
                                                :
                                              () => { console.error("shizzleView's tryGoingFurtherInTransients() found no property 'guestPostWeAreAPartyToCallback'")}
                                         )

      let useIndex = 0
      const hgMode = latestTx.input0Params.hostGuestMode
      const guestPost = hgMode === 1 || hgMode === 4
      let weAreTheGuest = false
      if ( guestPost ) {
        const guestLimb = hexStringToAscii( latestTx.outputStates[1].namePath )
        if ( this.state.domainToVisit === guestLimb ) {
          progressFunc("GUEST POST is the domain we're interested in. Will use index 1")
          useIndex = 1
          weAreTheGuest = true
        } else {
            // maybe reports on the domain of the guest-poster, visiting the domain-to-visit
            this.reportGuestPostFunc( guestLimb )
        }
      }
      // most of the time we're traveling, using index 0
      // At first, though, when spawn, we're on index 1
      // OR, if we're the guest-post, we're on index 1+

      // BUT, if we're on the guestPost, AND we're traveling the spawn, +1

      let modeAsAnInt = Buffer.from(latestTx.outputStates[useIndex].mode, "hex").readUInt8(0)
      console.log("tGFIT(): mode as int: " + modeAsAnInt)
      // If we're entering from a different T level, or from Update, use outIdx 1
      if ( modeAsAnInt < 48 || modeAsAnInt > 57 || modeToSearch > 0 ) {
        if ( modeToSearch > 0 && guestPost && weAreTheGuest ) {
          progressFunc("Entering from other level, AND we're the GUEST")
        }
        if ( modeToSearch > 0 && guestPost && !weAreTheGuest ) {
          //console.error("Entering from other level, BUT we're the HOST. Latest txId: " + latestTxId)
          progressFunc("We're done trying Transients. The transient was a host, and so, had no spawn to follow.")
          return {
            status: 'done',
            txId: latestTxId
          }
        }
        useIndex++
        console.log(" Mode was " + modeAsAnInt + ", and modeToSearch is "
                    + modeToSearch + ", so, using out index " + useIndex)
        if ( !latestTx.outputStates[useIndex] ) {
          console.warn("WHOOPS. There is no index " + useIndex + " for txId " + latestTxId)
          console.warn("  returning 'nope'. Won't provide the most-recently-found good txid")
          return {
            status: 'nope',
            //txId: latestTxId
          }
        }
        modeAsAnInt = Buffer.from(latestTx.outputStates[useIndex].mode, "hex").readUInt8(0)
        progressFunc("For this iteration, using out index " + useIndex)

        // reset this. It was only needed to recognize the FIRST iteration in a line
        modeToSearch = 0
      }
      progressFunc("  T  modeAsAnInt " + modeAsAnInt + " search... using index " + useIndex)

//FIXME: check that 2nd param for undefined before calling?

      //NOTE: 2nd param is the output state OF THE output we chose to follow
      const nextTx = await findNextTx(await openDB(),
                                      latestTx.outputStates[ useIndex ],
                                      latestTxId,
                                      useIndex);

      if ( this.state.jobCanceled ) {
        console.warn("    Cancel request during Transient iteration")
        break;
      }

      const ownerCount = latestTx.outputStates[useIndex].ownerCount
      const qCount     = latestTx.outputStates[useIndex].quarterlyCount
      const pCount     = latestTx.outputStates[useIndex].periodicCount
      const dCount     = latestTx.outputStates[useIndex].downCounterInt

      const normalizedMode = modeAsAnInt - 48

      if ( nextTx == null ) {
        if ( foundAny ) {
          progressFunc("We're done traversing Transients-" + normalizedMode
                      + ". We're at Owner #"
                      + ownerCount + ", Quarter #" + qCount
                      + ", period #" + pCount
                      + ", downCounter " + dCount)
          if ( latestTx.outputStates[useIndex + 1] && latestTx.outputStates[useIndex + 1].mode ) {
            progressFunc("  BTW output[" + (useIndex + 1) + "].mode: " + latestTx.outputStates[useIndex + 1].mode)
            const nextModeAsAnInt = Buffer.from(latestTx.outputStates[useIndex + 1].mode, "hex").readUInt8(0)
            progressFunc(" modeAsInt: " + modeAsAnInt + "  NEXT modeAsInt: " + nextModeAsAnInt)
            if ( nextModeAsAnInt === modeAsAnInt - 1 ) {
              if ( guestPost && !weAreTheGuest ) {
                progressFunc("We won't look deeper. We're the HOST, so, we couldn't have spawned here")
              } else {
                progressFunc("We're going to look at this sub-transient SPAWN...")

                const subRes = await this.tryGoingFurtherInTransients(latestTxId, progressFunc, nextModeAsAnInt)

                latestTxId = subRes.txId
                console.warn("NOTE: Done. took latestTxId from subRes: " + latestTxId)
              }
            }
          } else {
            progressFunc(" (and there was no output[" + (useIndex + 1) + "].mode to try)" )
          }
        } else {
          progressFunc("We're done trying Transients. We found none - at this level.")
        }
        console.warn("    We're done traversing Transients")
        return {
          status: 'success',
          txId: latestTxId
        }
      }
      foundAny = true
      progressFunc("We found the next Transient-" + normalizedMode
                + ". Owner #" + ownerCount
                + ", Quarter #" + qCount + ", period #" + pCount
                + ', downCount ' + dCount + ", tx "
                + nextTx.substring(0,16) + "...")

      console.log("    Next Transient: ", nextTx)
      latestTxId = nextTx
      console.warn("    READY for next iteration/Transient check - on " + latestTxId)

      await mySleep(this.iterativeSleepForProviders, "tGFIT() i")
    } while (true);

    console.warn("tryGoingFurtherInTransients...(): done iterating")
  } // tryGoingFurtherInTransients

  async tryGoingFurtherInUpdates( latestQuarterly, progressFunc ) {
    console.warn("tryGoingFurtherInUpdates(): latestQuarterly: ", latestQuarterly)

    await mySleep(this.initialSleepForProviders, "tGFIU() 0")

    let periodicalsWithProfileInfo = []

    let latestTx
    let latestTxId = latestQuarterly
    let foundAny = false
    const db = await openDB()
    do {

      // Wait. If we're about to check for an Update/Periodical, this had better NOT be an append
      // And we should ALREADY HAVE this tx - so we should at least (first) verify it's the
      // CORRECT contract type: (prob a quarterly)
      //latestTx = await queryFunction(latestTxId, await openDB(), true)
      latestTx = await getDecodedTx(db, latestTxId)
      if ( latestTx !== null ) {
        if ( latestTx.outputStates[0].mode !== '55' && latestTx.outputStates[1]?.mode !== '55' ) {
          console.warn("tGFIU(): NOT AN ERROR, but, we can't expect to find an UPDATE "
                      + "in this kind of contract: " + latestTx.outputStates[0].mode + ". We're done traversing.")
          return {
            status: 'done',
            txId: latestTxId,
            periodicalsWithProfileInfo: periodicalsWithProfileInfo,
          }
        } else {
            //NOTE: no need to update any output status. We'll be doing that soon enough, in findNextTx(), below
        }
      } else {
        console.warn("tGFIU(): no record of this tx. Let's query the provider for it.")

        console.error(" .")
        console.warn("TAKE NOTE FROM HERE ON")

        latestTx = await queryFetchDecodeTx(latestTxId, db, true)

        console.warn("tGFIU() back from qFDTx()")
        console.error(" .")
      }

      // most of the time we're traveling, using index 0
      // At first, though, when spawn, we're on index 1
      let useIndex = 0
      if ( latestTx.outputStates[0].mode !== '55' ) {
        // This is probably a continue, checking to travel its update spawn
        progressFunc("traveling updates. Probably came here from a Continue. Let's check if there even IS a spawned Update...")

        if ( latestTx.outputStates[1] && latestTx.outputStates[1].mode === '55' ) {
          progressFunc("(There was a spawned update.)")
          useIndex = 1
        } else {
          progressFunc("We're done trying Updates. It seems the quarterly had no spawned Update.")
          return {
            status: 'done',
            txId: latestTxId,
            periodicalsWithProfileInfo: periodicalsWithProfileInfo, // probably []
          }
        }
      }
      //NOTE: 2nd param is the output state OF THE output we chose to follow
      const nextTx = await findNextTx(db,
                                      latestTx.outputStates[ useIndex ],
                                      latestTxId,
                                      useIndex);

      if ( this.state.jobCanceled ) {
        console.warn("    Cancel request during periodic iteration")
        break;
      }

      const ownerCount = latestTx.outputStates[useIndex].ownerCount
      const qCount     = latestTx.outputStates[useIndex].quarterlyCount
      const pCount     = latestTx.outputStates[useIndex].periodicCount


      // This approach assumes a user can build-up profile/avatar info
      // This approach can be used for other types of user info
      if ( latestTx?.shzlProfile !== null ) {
        // record the tx AND its path
        // a quarterly would be overridden if we happen upon a new ownerCount
        // a periodical would be overridden when we happen upon a new quarterly
        // a top-level transient would be overridden by a new periodical
        // lower-level transients are overriden by new higher-level transients
        //NOTE: we're relying on preserving the order of these entries - as we travel further from Genesis tx
        periodicalsWithProfileInfo.push({txId: latestTxId, path: latestTx.address})
        //alert("shizzleView: tryGoingFurtherInQ...() This tx has shzlProfile info: " + latestTxId)
      }





      if ( nextTx == null ) {
        let status
        if ( foundAny ) {
          progressFunc("We're done traversing Updates. We're at Owner #"
                      + ownerCount + ", Quarter #" + qCount + ", period #" + pCount)
          status = 'keepGoing'
        } else {
          progressFunc("We're done trying Updates. We found none for this quarterly.")
          status = 'done'
        }
        console.warn("    We're done traversing Updates")
        return {
          status: status,
          txId: latestTxId,
          periodicalsWithProfileInfo: periodicalsWithProfileInfo, //??????? We probably continue with Transients
        }
      }
      foundAny = true

      progressFunc("We found the next Update. Owner #" + ownerCount
                + ", Quarter #" + qCount + ", period #" + pCount + ", tx "
                + nextTx.substring(0,16) + "...")

      console.log("    Next Update: ", nextTx)
      latestTxId = nextTx
      //latestTx = await queryFunction(nextTx, await openDB())
      console.warn("    READY for next periodic check - on " + nextTx)

      await mySleep(this.iterativeSleepForProviders, "tGFIU() i")
    } while (true);

    console.warn("tryGoingFurtherInUpdates...(): done iterating")
  } // tryGoingFurtherInUpdates

  //NOTE: recently copied to claimer
  // returns object {status, txId, quarterliesWithProfileInfo}
  async tryGoingFurtherInQuarterlies( latestQuarterly, progressFunc ) {
    console.warn("tryGoingFurtherInQ...(): latestQuarterly: ", latestQuarterly)

    let quarterliesWithProfileInfo = []

    let latestTx
    let latestTxId = latestQuarterly
    let firstTime = true
    do {

      // the first time, we already have the tx (from walking the appends. It's an append), so,
      // we don't care about updates (3rd param) - we don't want to query for ALL of the outputs
      latestTx = await queryFetchDecodeTx(latestTxId, await openDB(), !firstTime)
      firstTime = false

      console.warn("tryGoingFurtherInQ...(): let's find the NEXT tx (on output 0)...")

      //NOTE: 2nd param is the output state OF THE output we chose to follow
      const nextTx = await findNextTx(await openDB(),
                                      latestTx.outputStates[0],
                                      latestTxId,
                                      0);

      if ( this.state.jobCanceled ) {
        console.warn("    Cancel request during quarterly iteration")
        break;
      }

      const ownerCount = latestTx.outputStates[0].ownerCount
      const qCount     = latestTx.outputStates[0].quarterlyCount

      // This approach assumes a user can build-up profile/avatar info
      // This approach can be used for other types of user info
      if ( latestTx?.shzlProfile !== null ) {
        // record the tx AND its path
        // a quarterly would be overridden if we happen upon a new ownerCount
        // a periodical would be overridden when we happen upon a new quarterly
        // a top-level transient would be overridden by a new periodical
        // lower-level transients are overriden by new higher-level transients
        //NOTE: we're relying on preserving the order of these entries - as we travel further from Genesis tx
        quarterliesWithProfileInfo.push({txId: latestTxId, path: latestTx.address})
        //alert("shizzleView: tryGoingFurtherInQ...() This tx has shzlProfile info: " + latestTxId)
      }

      // FIXME: ===?
      if ( nextTx == null ) {
        progressFunc("We're done traversing quarterlies. We're at owner #"
                    + ownerCount + ", Quarter #" + qCount)
        console.warn("    tGFIQ(): We're done traversing quarterlies")
        return {
          status: 'success',
          txId: latestTxId,
          quarterliesWithProfileInfo: quarterliesWithProfileInfo,
        }
      }

      progressFunc("We found the next Quarterly. Owner #" + ownerCount
                + ", Quarter #" + qCount + ",  tx " + nextTx.substring(0,16) + "...")

      console.log("    Next Quarterly: ", nextTx)
      latestTxId = nextTx
      //latestTx = await queryFunction(nextTx, await openDB())
      console.warn("    READY for next quarterly check - on " + nextTx)

      await mySleep(this.iterativeSleepForProviders, "tGFIQ() i")
    } while (true);

    console.warn("tryGoingFurtherInQuarterlies(): done iterating")

    return {
      status: 'interrupted',
      txId: null
    }
  } // tryGoingFurtherInQuarterlies


  ///////////////////////////////////
  async walkTheAppends(txId, decodedTx, closestLimb, target, progressFunc) {
    let latestTxId = txId
    let latestTx = decodedTx

    // Walk the Appends...
    let ourIterations = "" + txId + "... "
    // setup loop to travel as far as possible
    let curLength = closestLimb.length
    console.log("  walkTheAppends(): closestLimb: ", closestLimb)
    do {
      console.warn("  walkTheAppends(): LATEST TX: ", latestTx)

      // IMPORTANT: for root/genesis, output 'a' is at idx 0
      //            after that point, there's a claim/continue/quarterly at idx 0
      const addOneToOutputIdx = curLength < 1 ? 0 : 1
      console.warn("    addOneToOutputIdx = " + addOneToOutputIdx)

      // get its Ith output scripthash (depending on path we're headed)
      const nextChar = target.substring(curLength, curLength+1)
      console.warn("    next char: " + nextChar)
      if ( nextChar === '' ) {
        console.warn("walkTheAppends(): whoops. EMPTY next char. No more chars to target " + target + ", with curLength " + curLength)
        progressFunc("walkTheAppends(): EMPTY next char. No more chars to target " + target + ", with curLength " + curLength)
        break;
      }

      console.warn("    ascii val: " + nextChar.charCodeAt(0))
      const idxToCheck = nextChar.charCodeAt(0) - 97 + addOneToOutputIdx // for 'a', 'NORMALLY' look at output 1 (output)
      console.warn("    so, look at output #" + idxToCheck )

      // check that scripthash for spend

      if ( latestTx.outputStates[idxToCheck] === null ) {
        console.error("hmm. outputStates[" + idxToCheck + "] is null?")
        alert("READ THE LOGS. outputStates[" + idxToCheck + "] is null")
        break;
      }

      //NOTE: 2nd param is the output state OF THE output we chose to follow
      const nextTx = await findNextTx(await openDB(),
                                      latestTx.outputStates[idxToCheck],
                                      latestTxId,
                                      idxToCheck);

      if ( this.state.jobCanceled ) {
        console.warn("    whoops. There was a cancel request during search iteration")
        break;
      }

      // nextTxId
      if ( nextTx !== null ) {
        progressFunc("We found the next tx: " + nextTx.substring(0,16) + "...")
        console.warn("    GOT SOMETHING: ", nextTx)
        latestTxId = nextTx

        // when 'walking the appends' towards some target, we don't want
        // to query every append output (3rd param)
        latestTx = await queryFetchDecodeTx(nextTx, await openDB(), false)
        console.warn("    READY for next iteration. - to " + nextTx)
console.log("BTW: latestTx: ", latestTx)

        ourIterations = ourIterations + nextTx + "... "
      } else {
        progressFunc("That's as far as we can go.")
        console.warn("    We've gone as far as we can go for now")
        break
      }

      progressFunc(<> &nbsp; &nbsp; We got its info (it's limb {latestTx.limbName})</>)

      // job canceled?
      if ( this.state.jobCanceled ) {
        // progressFunc()  <-------
        console.warn("    whoops. detected a cancel request while querying Tx Provider")
        break;
      }

      // if NOT spent, we'll fall short. break out of loop

      // if spent, get that tx

      curLength++

      console.log("  bottom of loop. curLength: " + curLength + ", thing: ", target)
      console.log("  btw: latest txid is ", nextTx)
      console.log("  its address: ", latestTx.address)
    } while (curLength < target.length);

    console.warn("DONE. Now, look at output 0 IF we've reached our destination.")
    console.warn("ourIterations: " + ourIterations)
    //IF we fall SHORT, show this to the user, and let them know

    return {
      txId: latestTxId,
      tx: latestTx
    }
  } // walkTheAppends()

  // This is the ProgressListModal's registered ourWorkToDo() function
  // It's called-back by the ProgressListModal
  // Parameters progressFunc, finishCanceling, doneFunc, and plmReset are pLM functions that WE'LL call-back if/when needed
  async doWork(parent, progressFunc, finishCanceling, doneFunc, target, plmReset) {
    this.plmReset = plmReset
    this.setState({jobCanceled: false})
    console.warn("doWork() on " + target)

    // do work in several steps
    // After each step, send a progress report, and check for cancel

    const db = await openDB()

    let latestTx = null
    let latestTxId = ''
    //NOTE: we might now know which owner applies here
    //      we'd normally pass the ownerCount to better-track last call to findLatestContinue
    const latestContinueRes = await findLatestContinue2( db, target, 0 )
    let latestContinue = null
    if ( latestContinueRes !== null ) {
      latestContinue = latestContinueRes.result
      console.warn("ShizzleView doWork(): latestContinue: ", latestContinue)

      const ownerChanged = latestContinueRes.ownerChanged
      if ( ownerChanged ) {
        //alert("NOTE: We're not sure if the owner has changed. We didn't know what to expect.")
        console.warn("ShizzleView doWork(): not sure if the owner has changed. We didn't know what to expect.")
      }
    }

    if ( latestContinue === null ) {
      // travel there via appends, as far as we can
      progressFunc("latestContinue was null. Will now walk the Appends...")

      let closest = await getClosestToLimb( db, target)
      //console.warn("results of getting close: ", closest)

      // owner, tx, limb are the relevant fields
      //   - if owner is 0, it's not yet claimed
      //   - if limb is short, we need to go further
      //     (though, it may have yet to be built further)
      //   - tx is from where we'll make our next move
      if ( closest === null ) {
        // this means we need to start at the appends, starting with the root/genesis tx

        console.warn("ShizzleView doWork() OK. Let's start with the genesis tx...")

        // first append fan-out
        const genesisTx = GENESIS_APPEND

        // Maybe we just need to 'walk the appends', starting at the genesis tx
        closest = {
          limb: '',
          owner: null,
          tx: genesisTx
        }
        //throw new Error("55330: CODING ERROR: null closest limb (probably because of misleading 'test' other-than-quarterly txs)")
      }

      console.warn("doWork(): got back limb " + closest.limb
                  + ", owner " + closest.owner + ", tx: ", closest.txid)
      progressFunc("Having consulted our idb, we got as far as: '" + closest.limb + "'")

      // If limb is short (as expected), we've got further to go
      // AND not all (or any) of it may have been built
      if ( closest.limb.length > target.length ) {
        console.warn("doWork(): we've reached limb " + closest.limb
                  + ", and it's puzzling that we had to look so hard. owner: " + closest.owner)
        throw new Error("55331: CODING ERROR: findLatestContinue should have found this, right?")
      }

      console.warn("doWork(): we're still short, having reached " + closest.limb)
      console.warn("          The trail MIGHT extend further, unbeknownst to us")
      console.warn("          tx: " + closest.txid)

      // we need to attempt to travel further
      // Present the FURTHEST to the user

      latestTxId = closest.txid
      //FIXME: maybe put this in the loop, and remove the call at the bottom of loop
      latestTx = await queryFunction(latestTxId, db, true)

      console.warn("doWork() at start of loop")

      const limbLen = closest.limb.length
      const useableLimb = closest.limb.substring(0, limbLen - 1)

      progressFunc("Will now query our Tx Provider to inch closer to the target...")

      progressFunc("Will walk the appends with closest limb " + closest.limb + " .  useableLimb: " + useableLimb + "  TARGET: " + target)
      progressFunc("Should walk the appends with length " + closest.limb.length)

      console.warn("NOTE: nowadays 'closest.limb' is the state on an OUTPUT. Let's remove that final char...")

      console.warn("Will walk the appends starting at limb " + useableLimb)

      const appendRes = await this.walkTheAppends(latestTxId, latestTx, /*closest.limb*/ useableLimb, target, progressFunc)

      latestTxId = appendRes.txId
      latestTx   = appendRes.tx
      console.warn("We got as far as txid " + latestTxId)
      console.warn("Its address is " + latestTx.address)
      console.warn("the tx: ", latestTx)
      console.warn("Its limbName is " + latestTx.limbName)
    } else {
      progressFunc("Our DB already knew of a quarterly for the target domain (" + target + "). Off to a good start.")
    }

    // MAYBE go further in quarterlies

    // Relevant latestContinue fields:
    //   limb
    //   address
    //   owner:  if this is 0, don't bother looking any further
    //   tx

    // load this tx, and see if we can follow it further

    // EVEN though we got here, it's possible we could go further
    // ALSO, we need to travel the UPDATES, then BITGROUPs, or various TRANSIENTS

    if ( !this.state.jobCanceled ) {
      if ( latestTx !== null && latestTx.limbName !== target) {
        console.warn("we're done. will process results. latestTxId is ", latestTxId)

        progressFunc("Having queried our Tx Provider, we got as far as '" + latestTx.limbName + "'")
        progressFunc("You could choose to BUILD and CLAIM a limb beyond this")

        await this.processSearchResults(latestTxId, false, false, 'Partial success')
      } else                     //FIXME: confusing/error-prone because field (below) is .owner, not .ownerCount
      if ( (latestContinue !== null && latestContinue.owner !== 0) ||
            (latestTx !== null && latestTx.limbName === target ) ) { //&& latestTx.outputStates[0].ownerCount !== 0 ) ) {    <=------ removed on 10/26/22 after full reset
        //FIXME: these 3 lines also used when we iteratively walk the appends to the target asset
        //       Share common code? Or, put final line into tryGoingFurther...()?

        // we could now travel/query to the latest continue

        const txidToUse = latestContinue === null ? latestTxId : latestContinue.tx
  //FIXME: mention address
        progressFunc("We're at the target domain, but let's try going further in Quarterlies...")
        // returns object: {status, txId, quarterliesWithProfileInfo}
        const finalQuarterlyRes = await this.tryGoingFurtherInQuarterlies( txidToUse, progressFunc )


// here we've gotten as far as we can in Quarterlies


        if ( !this.state.jobCanceled ) {
          //FIXME: check result.status

          progressFunc("doWork: Now trying Updates/Periodicals...")

          const latestUpdateRes = await this.tryGoingFurtherInUpdates( finalQuarterlyRes.txId, progressFunc )

          if ( !this.state.jobCanceled ) {

            let latestRes
            let latestTransientRes = null
            if ( latestUpdateRes.status !== 'done' ) {
              // try transients

              progressFunc("Now trying Transients...")

// be more thoughtful here. Calls itself
//XXXX MAYBE: pass in a BLANK array right HERE - that gets carried around recursively, with transientWithProfileInfo pushed in along the way/walk
              latestTransientRes = await this.tryGoingFurtherInTransients( latestUpdateRes.txId, progressFunc )

              latestRes = latestTransientRes
              if ( !this.state.jobCanceled && latestTransientRes.status === 'nope' ) {
                // update didn't have a transient
                console.warn(" whoopsie. There was no sub-index for transients. Use current Update result.")
                latestRes = latestUpdateRes
              }

              //FIXME: check for transient cancelled?

            } else {
              progressFunc("DONE. Won't try transients, since we found no Updates")
              latestRes = latestUpdateRes
            }

            if ( !this.state.jobCanceled ) {
              console.log("doWork: let's process our results.  BTW: finalQuarterlyRes: ", finalQuarterlyRes)
              // Our work is done. Trigger display of our target/final tx, AND note the furthest quarterly RESULT
              // RECALL: that finalQuarterlyResult contains a status, txId, and array of profile-relevant entries
              //         that exist in a STRAIGHT PATH to the latest tx, from a given owner's origin  <-----
              await this.processSearchResults(latestRes.txId, true, true, 'Success!', finalQuarterlyRes, latestUpdateRes, latestTransientRes)
            }
          }
        } else {
          // progressFunc()  <-------
          progressFunc("Canceled while walking quarterliess")
        }
      } else {
        if ( latestContinue === null ) {
          throw new Error("33840: CODING ERROR: latestContinue is null. latestTxId is " + latestTxId)
        }
        // UN-OWNED ASSET
        progressFunc("Asset is UN-OWNED. You Could claim it.")

    //FIXME: we could ponder calling processQuarterlySearchResult JUST at the bottom,
    //       but set the true, false params in 3 different places
        await this.processSearchResults(latestContinue.tx, true, false, 'Partial success')
      }
    }

    if ( this.state.jobCanceled ) {
      progressFunc("SEARCH WAS CANCELED at some point.")
      finishCanceling(parent)

      this.setState({currentTxId: null,
        decodedTx: '',
        found: false,
        owned: false,
        reachedLimb: '',
        extraVerbage: 'canceled'});
    } else {
      doneFunc(parent, "outer we're done")
    }

    // we SHOULD have already called either called finishCanceling(), or doneFunc()
    console.warn("doWork(): We're either CANCELED, or DONE")


  } // doWork()
  ///////////////////////////////////


  // Called when a progressListModal-initiated walk of a domain has concluded (reached the tip),
  // or been cancelled.
  // At the end, this.props.subModeResponse() is called (if registered/implemented) to inform
  // the PARENT of the results. It now ALSO reports the results of the furthest quarterly encountered:
  //     resultOfFurthestQuarterly
  async processSearchResults(tipTxId, found, owned, extraWords,
                              resultOfFurthestQuarterly = null,
                              resultOfFurthestPeriodical = null,
                              resultOfFurthestTransient = null) {
    const db = await openDB()
    console.log("shizzleView pSR(): will now process results of search. BTW: resultOfFurthestQuarterly: ", resultOfFurthestQuarterly)
    console.log("shizzleView pSR(): will query tx " + tipTxId + "...")

    // Maybe we don't need to query the provider this time
    // We've probably done it very, very recently
    let limb = ''
    const dtx = await queryFunction(tipTxId, db) // NOTE: Has TEMPORARILY changed false to true on 10/25/23. Also in claimer.
    if ( dtx !== null ) {
      // if it's not in our subscription list, and we haven't yet registered this,
      //     offer a button to register, or follow/subscribe
      // if we have registered it, mention it
      // if it's in our subscription list, mention it

      let registeredToUs = false
      let weSubscribeToThis = false
      if ( found && owned ) {
        console.log("checking if we should offer buttons to subscribe, or register ownership...")

        // is it already owned by us?
        const outputFromWhichToTakeOwnerCount = this.getRelevantOutputIndex( dtx )

        const ownerCount = dtx.outputStates[ outputFromWhichToTakeOwnerCount ].ownerCount
        const limb = hexStringToAscii( dtx.outputStates[ outputFromWhichToTakeOwnerCount ].namePath )
        if ( limb !== this.state.domainToVisit ) {
          throw new Error("11727 - whoops on domain to visit: " + this.state.domainToVisit + " vs " + limb)
        }

        const registrationSearchResult = await findADomainOfMine(db, limb, ownerCount)
        console.warn("results of our domain-registration check: ", registrationSearchResult)

        if ( registrationSearchResult === null ) {
          // We don't have this domain (and latest ownerCount) registered (yet),
          // so, offer to register ownership
          registeredToUs = false
        } else {
          for ( let i = 0; i < registrationSearchResult.length; i++ ) {
            if (registrationSearchResult[i].name === this.state.domainToVisit) {
              registeredToUs = true
              break;
            }
          }
        }


        // do we already subscribe to it?
        const subscriptionSearchResult = await getASubscription(db, limb, ownerCount)
        console.warn("results of our subscription check: ", subscriptionSearchResult)

        if ( subscriptionSearchResult === null ) {
          // We're not subscribed to this domain (and latest ownerCount),
          // and we haven't registered ownership, so, offer to subscribe/follow
          weSubscribeToThis = false
        } else {
          for ( let i = 0; i < subscriptionSearchResult.length; i++ ) {
            if (subscriptionSearchResult[i].name === this.state.domainToVisit) {
              weSubscribeToThis = true
              break;
            }
          }
        }
      }


      // Call maybeDecryptContent() AFTER state.contentPending is true
      this.setState({contentPending: true}, async function() {
            const {dtxString, contentEncrypted} = await maybeDecryptContent( dtx, this.props.getKeyForDecryption )

            this.setState({
                          contentPending: false,
                          contentEncrypted: contentEncrypted,
                          currentTxId: tipTxId,
                          decodedTx: dtxString,
                          found: found,
                          owned: owned,
                          registeredToUs: registeredToUs,
                          weSubscribeToThis: weSubscribeToThis,
                          reachedLimb: limb,
                          extraVerbage: extraWords});

            // Since we're presenting real content, update the address bar
            // But, don't set the address bar if we're in special 'submode' (controlled by surfer, or something)
            if ( found && owned && !this.props.submode ) {
              this.setAddressBar(dtx)
            }

            if ( this.props.subModeResponse ) {
              this.setState({foundOwnerCount: dtx.outputStates[0].ownerCount})
              const furthestQuarterlyTxId = resultOfFurthestQuarterly === null || resultOfFurthestQuarterly.status !== 'success' ?
                          null
                        :
                          resultOfFurthestQuarterly.txId
              const quarterliesWithProfileInfo = resultOfFurthestQuarterly === null || resultOfFurthestQuarterly.status !== 'success' ?
                          []
                        :
                          resultOfFurthestQuarterly.quarterliesWithProfileInfo

              const furthestPeriodicalTxId = resultOfFurthestPeriodical === null || resultOfFurthestPeriodical.status !== 'done' ?
                          null
                        :
                          resultOfFurthestPeriodical.txId
              const periodicalsWithProfileInfo = resultOfFurthestPeriodical === null || resultOfFurthestPeriodical.status !== 'done' ?
                          []
                        :
                          resultOfFurthestPeriodical.periodicalsWithProfileInfo

              const furthestTransientTxId = resultOfFurthestTransient === null || resultOfFurthestTransient.status !== 'success' ?
                          null
                        :
                          resultOfFurthestTransient.txId
              const transientsWithProfileInfo = resultOfFurthestTransient === null || resultOfFurthestTransient.status !== 'success' ?
                          []
                        :
                          resultOfFurthestTransient.transientsWithProfileInfo


              alert("IMPLEMENT ME: (in shizzleView) furthestPeriodicalTxId, periodicalsWithProfileInfo, furthestTransientTxId, transientsWithProfileInfo")
              alert("AND, review: how does decoding a tx affect all this? Did we add code in there (looking for shz_profile info), that makes any of this now useless? or does it empower it?")
              // KEY, perhaps:
              //
              // ah. Perhaps: decoding a tx will result in a .shzl_profile, but HERE, we identify only the RELEVANT (in-path) txs with profile info
              // Thus, as long as the owner maintains his tree in this way (promoting params when possible), user doesn't have to decode EVERY tx in the tree


              console.warn("View: letting caller know we're done... NOTE: furthestQuarterlyTxId: ", furthestQuarterlyTxId)
              console.log("BTW: dtx: ", dtx)
              this.props.subModeResponse(this.parent, found, dtx, tipTxId, furthestQuarterlyTxId, quarterliesWithProfileInfo)
            }
          })

    } else if ( this.props.subModeResponse ) {
      this.setState({foundOwnerCount: 0})
      console.error("shizzleView: Where's the decodexTx string?")
      alert("ERROR?: TBD. In submode, so, letting caller know that the domain search failed")
      this.props.subModeResponse(this.parent, false, null, null, null, [])
    }
  } // processSearchResults

  setAddressBar(tx) {
    let theAddress = tx.outputStates[0].address
    try {
      console.log("setAddressBar(): hostGuestMode: " + tx.input0Params.hostGuestMode)
      if ( tx.input0Params.hostGuestMode === 1 || tx.input0Params.hostGuestMode === 4 ) {
        const guestLimb = hexStringToAscii( tx.outputStates[1].namePath )
        if ( guestLimb === this.state.domainToVisit ) {
          theAddress = tx.outputStates[1].address
          console.warn("setAddressBar(): Switching to GUEST address - for domain " + guestLimb)
        }
      }

      window.history.pushState("", "", "/")
      window.history.pushState("", "", "view/" + theAddress.split('/').slice(2).join('/'))
    } catch (error) {
      console.error("setAddressBar(): error while pushing address " + theAddress + " to browser state? ", error)
      alert("whoops. tried pushing browser state when address is " + theAddress)
    }
  }

  closeProgressList() {
    console.log("closeProgressList() called")
    this.setState({openTheProgressList: false})
  }

  // show a 'toast' for a specified period
  quickToast(toastText, duration=2000, withoutLoader = false) {
      console.warn("quickToast()")
      this.setState({showQuickToast: true, toastText: toastText, toastDuration: duration, suppressLoader: withoutLoader})
      // once expired, quickToastDone() will be called
  }
  // called-back by toast, once it's expired
  quickToastDone(self) {
      self.setState({showQuickToast: false, suppressLoader: false})

      // now that it's expired, do NOTHING
  }
  // current txId passed by child (contentPresenter)
  async shareLinkToPost(txId) {
    console.warn("ShizzleView  shareLinkToPost(): ", txId)
    //console.log("decoded tx: ", this.state.decodedTx)
    const txState = await queryFunction( txId, await openDB(), false );
    const txAddress = txState.address.split("//")[1]
    console.warn("tx address: ", txAddress)

    const linkPath = "https://localhost:3000/?j=" + txAddress

    try {
        await navigator.clipboard.writeText( linkPath );
        this.quickToast("Link copied to clipboard", 900, true)
    } catch (error ) {
      // sometimes:
      //   NotAllowedError: The request is not allowed by the user agent or the platform in
      //                    the current context, possibly because the user denied permission.
      // see: https://stackoverflow.com/questions/62327358/javascript-clipboard-api-safari-ios-notallowederror-message
      // tl;dr: "You are trying to copy to the clipboard as a result of an API call
      //         instead of in the context of a user interaction. This is not allowed."
      console.warn("shareLinkToPost(): error: ", error)
      alert("Use this link to share the post:\n\n"
          + linkPath
      )
      return
    }
  }

  // This would be used by ContentPresenter if user clicks on a shizzle link
  // (pointing to a shizzle address)
  //   example:   <bshz bshzType='ajump' bshzArg='bshz://abc/1/2'> click here </bshz>
  // OR, maybe if user selects a dialog from the dialogModal
  // 'mode' can be 'regular', or 'dialog'
  async jumpToTx(txid, parent, txClass='regular', dialogId=0) {
    //NOTE: we couldn't just call saveAndFetch() directly. 'this' was a problem, within saveAndFetch()
    console.log("SSNav::jumpToTx() tx: ", txid)

    // query and decode the tx
    const txState = await queryFunction( txid, await openDB(), true );
    if ( txState === null || typeof txState === 'number' ) {
      //alert("ERROR: failed to retrieve the transaction " + txid + ": " + txState)
      console.error("jumpToTx() failed for txid " + txid + ": " + txState)

      // clean-up (stop the swirling loading thing)
      //this.setState({searchEntry2: '', txToConsider: '', decodedTx: null});
      //this.setState({txToConsider: ''});
      return null
      //FIXME: return something?
    }


    // set state, to jump to alternate location
    //  ( see original example in ShizzleNav's saveAndFetch() )
//FIXME: we should really CALCULATE found, owned, or reached?
    parent.setState({ currentTxId: txid,
                    decodedTx: JSON.stringify( txState ),
                    found: true,
                    owned: true,
                    txClass: txClass,
                    dialogId: dialogId })


    // Set the address bar URL (pathname). The hostname was set once - in ShizzleNav CTOR
    // ( This strips off leading 'bshz://nav', or maybe in the future, 'bshz://view' )
    if ( txState.address ) {
      try {
        window.history.pushState("", "", "/")
        window.history.pushState("", "", "view/" + txState.address.split('/').slice(2).join('/'))
      } catch (error) {
        alert("whoops. tried pushing browser state when address is " + txState.address)
      }

    } else {
      console.warn("Tx has no state (probably legacy). Blanking the address bar path")
      window.history.pushState("", "", "/")
    }
  } // jumpToTx()

  nullProgressFunc(text) {
    console.warn("nullProgressFunc(): " + text)
  }

  /**
   * Jump to the LOGICAL next transaction (for the domainToVisit)
   * If parameter 'reverse' is true, walk BACKWARDS instead.
   *
   * @param {*} reverse
   * @returns
   */
  async handleForward(reverse = false) {
    console.log("FORWARD clicked. BTW: currentTxId is " + this.state.currentTxId)

    console.warn("Reverse? ", reverse)

    const odtx = JSON.parse( this.state.decodedTx );
    let guestPost = false
    let guestLimb = ''

    if ( !odtx ) {
      console.error("handleForward(): no decodedTx to render?")
      alert("ERROR: There's no decoded tx to calc fwd/prev.")
      return
    }

//FIXME: if we're ALREADY at a finalPost, do we need special logic,
//       or can we just search, as normal?

    console.log("hostGuestMode: " + odtx.input0Params.hostGuestMode)
    let weAreTheGuest = false
    if ( odtx.input0Params.hostGuestMode === 1 || odtx.input0Params.hostGuestMode === 4 ) {
      guestPost = true
      console.warn("handleForward: Rendering a guest-post")
      guestLimb = hexStringToAscii( odtx.outputStates[1].namePath )
      if ( this.state.domainToVisit === guestLimb ) {
        weAreTheGuest = true
      }

      // IF guest-poster is spawning, Dialog output is #3, else #2 (base-0)
    }

    let address
    let owner
    if ( guestPost && weAreTheGuest ) {
      console.warn("Using the GUEST address")
      address = odtx.outputStates[1].address
      owner   = odtx.outputStates[1].ownerCount
    } else {
      console.warn("Using the HOST address")
      address = odtx.outputStates[0].address
      owner   = odtx.outputStates[0].ownerCount
    }
    console.warn("handleForward() limbName " + this.state.domainToVisit)
    console.warn("handleForward() current address " + address)
    console.warn("handleForward() current owner " + owner)

    let postNum = 0
    console.warn("handleForward() current txClass: " + this.state.txClass)
    if ( this.state.txClass === 'dialog' ) {
      if (guestPost) {
        postNum = 0
      } else {
        postNum = odtx.outputStates[0].postNum
      }
    }
    const db = await openDB()
    const results = this.state.txClass === 'dialog' ?
                        await getNextDialog(db,
                                            this.state.dialogId,
                                            postNum,
                                            reverse)
                    :
                        await getTheNextAddress(db, this.state.domainToVisit, address, owner, reverse)

    if ( results === null ) {
      console.log("can't go forward (by simply searching an ordered list)")
      console.log("odtx: ", odtx)
      //FIXME: if we're going FORWARD, and this is just before a 'finalPost',
      //       find the finalPost the old-fashioned way - follow the spend
      //       use special logic to look for the first of the next at a higher level
      return
    }

    const nextTxId = results.txid
    console.warn("handleForward(): nextTxId = " + nextTxId)

    //NOTE: Since we're getting this from IDB, we know that it's ALREADY
    //      been decoded, so, there's no need to specify a Guest-Post callback
    let nextTx = await queryFetchDecodeTx(nextTxId, await openDB())
    if ( !nextTx ) {
      alert("hmm. trouble getting nextTx.")
      return; //FIXME: ok?
    }
    console.warn("handleForward(): nextTx = ", nextTx)
    var txString = JSON.stringify( nextTx );
    this.setState({currentTxId: nextTxId,
                    decodedTx: txString})
    if ( !this.props.submode ) {
      this.setAddressBar(nextTx)
    }

    // set contentPending true.
    // THEN decrypt
    // THEN set contentPending false
    this.setState({contentPending: true}, async function() {
            const {dtxString, contentEncrypted} = await maybeDecryptContent( nextTx, this.props.getKeyForDecryption )
            this.setState({
                contentPending: false,
                contentEncrypted: contentEncrypted,
                decodedTx: dtxString,
            })
          })

    // give parent a heads-up as to which tx we've navigated
    if ( this.props.postNavNotification ) {
      this.props.postNavNotification(this.props.parent, nextTxId, nextTx)
    }

  } // handleForward()

  async handlePrevious() {
    console.log("Previous clicked. BTW: currentTxId is " + this.state.currentTxId)

    const odtx = JSON.parse( this.state.decodedTx );
    let guestPost = false
    let guestLimb = ''

    if ( !odtx ) {
      console.error("handlePrevious(): no decodedTx to render?")
      alert("ERROR: There's no decoded tx to calc prev.")
      return
    }

    console.log("hostGuestMode: " + odtx.input0Params.hostGuestMode)
    let weAreTheGuest = false
    if ( odtx.input0Params.hostGuestMode === 1 || odtx.input0Params.hostGuestMode === 4 ) {
      guestPost = true
      console.warn("handlePrevious: Rendering a guest-post")
      guestLimb = hexStringToAscii( odtx.outputStates[1].namePath )
      if ( this.state.domainToVisit === guestLimb ) {
        weAreTheGuest = true
      }
    }

    let prevTxId
    let currentMode
    if ( guestPost && weAreTheGuest ) {
      prevTxId = odtx.input1PrevTxId
      console.warn("Using the GUEST prevTxId: " + prevTxId)
      currentMode = odtx.outputStates[1].mode
    } else {
      prevTxId = odtx.input0PrevTxId
      console.warn("Using the HOST prevTxId: " + prevTxId)
      currentMode = odtx.outputStates[0].mode
    }
    const currentAsciiMode = hexByteToAscii(currentMode)
    const currentModeAsInt = Buffer.from(currentMode, "hex").readUInt8(0)
    console.warn("handlePrevious(): currentModeAsInt: " + currentModeAsInt)
    console.warn("handlePrevious(): just BEFORE going back: mode is " + currentAsciiMode)

    if ( prevTxId !== null ) {

      let prevTx = await queryFetchDecodeTx(prevTxId, await openDB())
      let fetchThePrev = false // we're up-to-date so far
      if ( !prevTx ) {
        alert("hmm. trouble.")
        return; //FIXME: ok?
      }
      const prevMode = prevTx.outputStates[0].mode
      const prevAsciiMode = hexByteToAscii( prevMode )
      const prevModeAsInt = Buffer.from(prevMode, "hex").readUInt8(0)
      if ( currentAsciiMode === 'p' ) {
        if (  prevAsciiMode === 'P' || prevAsciiMode === 'K' ) {
          // DONE. too far? maybe should have checked/disabled sooner?
          console.error("DONE going back. too far? check/disable sooner?")
        } else {
          // travel Updates
          console.warn("Travel the Updates now...")

          const updateRes = await this.tryGoingFurtherInUpdates(prevTxId, this.nullProgressFunc)
          console.warn("   RESULTS of traveling Updates: ", updateRes)
          if ( updateRes.status === 'nope' ) {
            // Continue didn't have an Update
            console.warn(" whoopsie. There was no spawned Update. Use current Continue result. prevTxId is still " + prevTxId)
          } else {
            prevTxId = updateRes.txId
            console.error("WE've GOT A BETTER 'PREV' (an Update)!! (but must fetch it): " + prevTxId)
            fetchThePrev = true
            console.error("  Could NOT find a better trans. Prev is still " + prevTxId)
          }
        }
      } else if ( currentAsciiMode === 'U' ) {
        // P can spawn an update
        if ( prevAsciiMode === 'P' ) {
          // DONE. too far? maybe should have checked sooner?
        } else if ( prevAsciiMode === 'p' ) {
          // Back to Continue. DONE
          console.warn("DONE going back on Updates.")
        } else if ( prevAsciiMode === 'U' ) {
          // travel Transients
          console.error("Travel the Transients now... BUT, not sure if prev mode as int is T4, or T9")
          const transRes = await this.tryGoingFurtherInTransients(prevTxId, this.nullProgressFunc, 51)
          console.warn("   RESULTS of traveling Transients: ", transRes)
          if ( transRes.status === 'success' ) {
            prevTxId = transRes.txId
            console.error("WE've GOT A BETTER 'PREV' (a transient)!! (but must fetch it): " + prevTxId)
            fetchThePrev = true
          } else {
            console.error("  Could NOT find a better trans. Prev is still " + prevTxId)
          }
        }
      } else if ( currentModeAsInt >= 48 && currentModeAsInt <= 57 ) {  // ANY level (except 0???)
        console.warn("We're CURRENTLY Integer mode " + currentModeAsInt + " (T" + currentAsciiMode + ")")
        if ( prevModeAsInt === currentModeAsInt ) {  // if prev matches current
          // travel transients one level lower

          if ( prevModeAsInt !== 48 ) {
            console.warn("Travel the SUB-Transients now...")
            const subTransRes = await this.tryGoingFurtherInTransients(prevTxId, this.nullProgressFunc, prevModeAsInt - 1)
            console.log("   RESULTS of traveling SUB-Transients: ", subTransRes)
            if ( subTransRes.status === 'success' ) {
              prevTxId = subTransRes.txId
              console.error("WE've GOT A BETTER 'PREV'!! (but must fetch it): " + prevTxId)
              fetchThePrev = true
            } else {
              console.error("  Could NOT find a better sub trans. Prev is still " + prevTxId)
            }
          } else {
            console.warn("We're as low as we can go (can't travel subs). DONE")
          }
        } else {
          // back to Update. DONE
          console.warn("DONE going back on Transients.")
        }
      } else {
        throw new Error("IMPLEMENT ME: we need 'prev' logic for mode " + currentMode)
      }

      if ( fetchThePrev ) {
        prevTx = await queryFetchDecodeTx(prevTxId, await openDB())
        if ( !prevTx ) {
          alert("hmm. trouble now.")
          return; //FIXME: ok?
        }
      }

      var txString = JSON.stringify( prevTx );
      this.setState({currentTxId: prevTxId,
                      decodedTx: txString})

      if ( !this.props.submode ) {
        this.setAddressBar(prevTx)
      }

    } else {
      alert("Hmm. trouble getting prev.")
    }
  } // handlePrevious()

  async unRegisterADomainOfMine(event) {
    event.preventDefault();
    console.warn("unRegisterADomainOfMine(): Will UN-register " + this.state.domainToVisit)

    const db = await openDB();
    const domainRec = await unRegisterDomainOfMine(db, this.state.domainToVisit)
    console.warn("Here's the record we created: ", domainRec)

    this.setState({registeredToUs: false})
  }

  async registerADomainOfMine(event) {
    event.preventDefault();

    const ownerCount = this.getOwnerCountAndLimb().ownerCount

    console.warn("registerADomainOfMine(): Will register " + this.state.domainToVisit)


    const db = await openDB();
    const domainRec = await registerDomainOfMine(db, this.state.domainToVisit, ownerCount)
    console.warn("Here's the record we created: ", domainRec)

    this.setState({registeredToUs: true})
  }

  getRelevantOutputIndex (odtx) {

    console.log("getRelevantOutputIndex(): [0].contract: " + odtx.outputStates[0].contract)
    console.log("getRelevantOutputIndex(): hStA(0.namePath) = " + hexStringToAscii( odtx.outputStates[0].namePath ))
    console.log("getRelevantOutputIndex(): this.state.domainToVisit = " + this.state.domainToVisit)

    const relevantOutputIndex =
        odtx.outputStates[0].contract !== 'transient' ?
            0
          :
            hexStringToAscii( odtx.outputStates[0].namePath ) === this.state.domainToVisit ?
                0
              :
                1
    console.warn("getRelevantOutputIndex(): relevantOutputIndex = " + relevantOutputIndex)

    return relevantOutputIndex
  }

  getOwnerCountAndLimb() {
    // Results and state variables were set in processSearchResult()

    if ( this.state.decodedTx === '' ) {
      console.warn("getOwnerCountAndLimb(): trouble. No decodedTx.")
      return
    }

    const odtx = JSON.parse( this.state.decodedTx );
    if ( !odtx ) {
      console.warn("getOwnerCountAndLimb(): trouble")
      //alert?
      return
    }

    let ownerCount
    let limb
    if ( odtx.outputStates[0].contract === 'dialog' ) {
      console.warn("Dialog, so, namePath prob irrelevant")
      ownerCount = odtx.outputStates[0].ownerOwnerCount
      limb = odtx.outputStates[0].ownerName
      if ( limb !== this.state.domainToVisit && odtx.outputStates[0].visitorName !== this.state.domainToVisit ) {
        throw new Error("11728+1 - whoops on domain to visit: " + this.state.domainToVisit + " vs " + limb)
      }
    } else {
      const outputFromWhichToTakeOwnerCount = this.getRelevantOutputIndex(odtx)
      ownerCount = odtx.outputStates[ outputFromWhichToTakeOwnerCount ].ownerCount
      limb = hexStringToAscii( odtx.outputStates[ outputFromWhichToTakeOwnerCount ].namePath )

      if ( limb !== this.state.domainToVisit ) {
        throw new Error("11728 - whoops on domain to visit: " + this.state.domainToVisit + " vs " + limb)
      }
    }

    return {
              ownerCount: ownerCount,
              limb:       limb
           }
  }

  async subscribeToDomain(event) {
    event.preventDefault();

    const ownerCount = this.getOwnerCountAndLimb().ownerCount

    console.warn("subscribeToDomain(): Will subscribe to " + this.state.domainToVisit + " - owner " + ownerCount)

    const db = await openDB();
    await registerASubscription(db, this.state.domainToVisit, ownerCount)
    console.warn("Registered.")

    this.setState({weSubscribeToThis: true})
  }

  async unSubscribeToDomain(event) {
    event.preventDefault();

    console.warn("unSubscribeToDomain(): Will UNsubscribe to " + this.state.domainToVisit)

    const db = await openDB();
    await unRegisterASubscription(db, this.state.domainToVisit)
    console.warn("UN-registered.")

    this.setState({weSubscribeToThis: false})
  }

  render() {
	  console.warn("ShizzleView render()  Current asset: " + this.state.domainToVisit)

    const goButtonDisabled = this.state.domainToVisit.length < 1
    const theCurrentTxId = this.state.currentTxId
    const found = this.state.found
    const owned = this.state.owned

    //console.warn("decodedTx: ", this.state.decodedTx)

    let txPresentation = null
    let ownerCount = 0
    let limb = ''
    if ( this.state.decodedTx === '' || theCurrentTxId === null ) {
      txPresentation = null
    } else {
      const odtx = JSON.parse( this.state.decodedTx );
      let guestPost = false
      //FIXME: what's a good init value? check index.js
      let guestLimb = ''
      if ( !odtx ) {
        console.error("ShizzleView(): no decodedTx to render?")
        alert("ERROR: There's no decoded tx.")
        return
      } else {
        console.log("shizzleView(): odtx.input0Params: ", odtx.input0Params)
      }

      console.log("hostGuestMode: " + odtx.input0Params.hostGuestMode)
      if ( odtx.input0Params.hostGuestMode === 1 || odtx.input0Params.hostGuestMode === 4 ) {
        guestPost = true
        console.warn("ShizzleView: Rendering a guest-post")
        guestLimb = hexStringToAscii( odtx.outputStates[1].namePath )
      }

      if ( !found ) {
        txPresentation = <></>
      } else if ( !owned ) {
        txPresentation = <>
                                  <strong style={{color: 'blue'}}>
                                    '{odtx.limbName}'
                                  </strong>&nbsp;
                                    was found, but it's not yet been claimed. &nbsp; {theCurrentTxId}
                                </>
      } else {

        // found, and owned

        console.log("ShizzleView render(): object decodedTx: ", odtx)

        /* <ContentPresenter contentPresenterTx={this.state.txToConsider}
                          decodedTx={this.state.decodedTx}
                          jumpToLinkedAsset={this.jumpToTx}
                          guestPost={guestPost}/>
        */

        let addressofInterest
        if ( guestPost ) {
          // looking for this.state.domainToVisit
          console.warn("guest limb: " + guestLimb)
          console.warn("visiting: " + this.state.domainToVisit)
          if ( guestLimb === this.state.domainToVisit ) {
            addressofInterest = odtx.outputStates[1].address
          } else {
            addressofInterest = odtx.outputStates[0].address
          }
        } else {
          console.warn("NOT a guest post")
          addressofInterest = odtx.address
        }

        let disablePrev = false
        let startOfDomainMention = null
        const addressParts = addressofInterest.split('/')
        if ( addressParts.length === 5 ) {
          console.warn("This is a quarterly.")
          if ( addressParts[3] === '001' &&
              addressParts[4] === '00001' ) {
                console.log("This is the START of the domain. Disable Prev.")
                disablePrev = true
                startOfDomainMention = <>
                                        This is where the domain began. It's history starts here.<br></br>
                                       </>
              }
        }

        const disableFwd = false
        let previousButton = <span style={{verticalAlign: '10%'}}><Button disabled={disablePrev} onClick={() => this.handleForward(true)}><Icon name="arrow left"></Icon>Prev Tx</Button></span>
        let forwardButton = <span style={{verticalAlign: '10%'}}><Button disabled={disableFwd} onClick={() => this.handleForward(false)}>Next Tx<Icon name="arrow right"></Icon></Button></span>

        /* It appears that swapping between two ContentPresenters (based on state.contentPending) was overkill
           It's the <iframe> within the ContentPresenter that was the real culprit of presenting stale (encrypted) content
        const staticContentPresenter =
                                      <>
                                        <ContentPresenter contentPresenterTx={theCurrentTxId}
                                                          domainOfInterest={this.state.domainToVisit}
                                                          parent={this}
                                                          decodedTx={`{"input0Params":{"content":"48"},"outputStates":["h"]}`}
                                                          guestPost={false}
                                                          jumpToLinkedAsset={ this.jumpToTx }
                                                          submode={this.props.submode}
                                                  contentPending={true}
                                                          contentEncrypted={false}
                                                          />
                                      </>
        */

        const presenter =
                              <>
                                <ContentPresenter contentPresenterTx={theCurrentTxId}
                                                  domainOfInterest={this.state.domainToVisit}
                                                  parent={this}
                                                  decodedTx={this.state.decodedTx}
                                                  guestPost={guestPost}
                                                  jumpToLinkedAsset={ this.jumpToTx }
                                                  shareLinkToContent={ this.shareLinkToPost }
                                                  submode={this.props.submode}
                                          contentPending={this.state.contentPending}
                                                  contentEncrypted={this.state.contentEncrypted}
                                                  />
                              </>


        const rndString = 'identicon ' + this.state.domainToVisit.toLowerCase() + ':' + this.state.foundOwnerCount

        const siteIdenticon = <span style={{margin:'0px 0px 0px 0px', padding: '0px 0px 0px 0px', verticalAlign:'-60%'}}>
                                <Identicon string={rndString} size='40'/>
                              </span>;

        const navigationAndIdenticon =
              <>

                      {previousButton} &nbsp;&nbsp;

                      {siteIdenticon} &nbsp; &nbsp;

                      {forwardButton}

              </>
        //FIXME: at some point will need jumpToLinkedAsset, and guestPost (for real)
        //       Neither should be difficult
        txPresentation =
              <>
                <div style={{fontSize:'0.72rem'}}>
                  {addressofInterest}
                </div>
                <div style={{fontSize:'0.63rem'}}>
                  {theCurrentTxId}
                </div>
                <br></br>
                {navigationAndIdenticon}

                {startOfDomainMention}

                {presenter}
              </>
        const aCaL = this.getOwnerCountAndLimb()
        ownerCount = aCaL.ownerCount
        limb       = aCaL.limb
      }
    } // we have some results to present

    const title = <>Heading to the latest post for <b>{this.state.domainToVisit.toUpperCase()}</b></>
    //FIXME: when switching back to ShizzleNav, clear

    const dialogMenuView = this.state.dialogMenuOpen ?
                            <>
                              <DialogModal parent={this}
                                           domain={limb}
                                           ownerCount={ownerCount}
                                           dialogClickHandler={this.goToClickedDialog}
                                           closeModal={this.closeDialogMenu}/>
                            </>
                        :
                            <>
                            </>
    const domainMenuView = this.state.domainMenuOpen ?
                            <>
                              <DomainModal parent={this}
                                           domainClickHandler={this.goToClickedDomain}
                                           closeModal={this.closeDomainMenu}/>
                            </>
                        :
                            <>
                            </>
    const subscriptionsMenuView = this.state.subscriptionsMenuOpen ?
                            <>
                              <SubscriptionsModal parent={this}
                                                  domainClickHandler={this.goToClickedDomain}
                                                  closeModal={this.closeSubscriptionsMenu}/>
                            </>
                        :
                            <>
                            </>
    const subscriptionButton = !this.state.weSubscribeToThis ?
                              !this.state.registeredToUs && found && owned ?
                                    <>
                                      <br></br>
                                      If you like this asset ('{this.state.domainToVisit}'), you may wish to &nbsp;
                                      <Button positive content="Subscribe to it" onClick={this.subscribeToDomain} />
                                    </>
                                  :
                                    <>
                                    </>
                          :
                              <>
                                <br></br>
                                You are currently subscribed to this domain.<br></br>
                                If you grow tired of this domain, you can &nbsp;
                                <Button positive content="UN-subscribe to it" onClick={this.unSubscribeToDomain} />
                              </>
    const registrationButton = !this.state.registeredToUs ?
                              (!this.state.weSubscribeToThis && this.state.owned && this.state.found ?
                                      <>
                                        <br></br>
                                        If you actually own/control this asset ('{this.state.domainToVisit}'), you should &nbsp;
                                        <Button positive content="Register ownership" onClick={this.registerADomainOfMine} />
                                      </>
                                    :
                                      <>
                                      </>
                              )
                          :
                              <>
                                <br></br>
                                You've registered this domain - as something you own/control. <br></br>
                                If you no-longer own/control it, you should &nbsp;
                                <Button positive content="UN-Register ownership" onClick={this.unRegisterADomainOfMine} />
                              </>
    const currentDomain = this.state.domainToVisit === '' ?
                                  'none selected'
                                :
                                  this.state.domainToVisit
    const displayOfCurrentDomain = this.state.found && this.state.owned ?
                                  <>
                                    <span style={{color: 'blue'}}>
                                        {currentDomain}
                                    </span>
                                  </>
                                :
                                  <>
                                    <span style={{color: 'red'}}>
                                        {currentDomain}
                                    </span>
                                  </>
    const disableDialogs = this.state.domainToVisit === '' || !this.state.found || !this.state.owned
    const currentDomainIsOurs = this.state.registeredToUs
    const mentionIfOurs = currentDomainIsOurs ? ' (your domain)' : null
    const mentionIfSubscribed = this.state.weSubscribeToThis ? ' (we subscribe to this)' : null
    const dialogViewButtonWording = currentDomainIsOurs ?
                                        <>
                                          View your dialogs FROM this domain of yours
                                        </>
                                      :
                                        <>
                                          View your dialogs WITH this domain - from ANY of yours
                                        </>

    const maybeTypicalUpperDisplay = this.props.submode ?
            <>
            </>
          :
            <>

              This is the ShizzleViewer <br></br>
              It's the best way to view and follow BitShizzle domains. <br></br>


              <button onClick={this.handleViewerClick}>Some future function</button>
              <p></p>
              <button onClick={this.handlePostTweetClick}>Post/Tweet something</button>
              <p></p>
              <button onClick={this.openSubscriptionsMenu}>View/Select your subscriptions</button>
              <br></br>
              <button onClick={this.openDomainMenu}>View/Select your domains</button>
              <p></p>Current domain: {displayOfCurrentDomain} {mentionIfOurs}{mentionIfSubscribed}
              <br></br>
              &nbsp; &nbsp; <button disabled={disableDialogs} onClick={this.openDialogMenu}> {dialogViewButtonWording} </button>
              <p></p>
              <button onClick={this.props.handleSwitchToShizzleNav}>Switch to ShizzleNav</button>
              <Divider />

              <form>
                <label name='searchLabel'>
                  BitShizzle domain to visit: &nbsp;
                  <Input placeholder='Enter a domain name'
                            value={this.state.domainToVisit}
                            style={{width: "200px"}}
                            className="limb-input-field"
                            onChange={this.handleDomainBarChange} />
                  &nbsp; &nbsp;
                  <Button type="submit" content="GO/Fetch" disabled={goButtonDisabled} onClick={this.goToDomain} />
                </label>
              </form>

              {subscriptionButton}
              {registrationButton}
            </>

    const maybeTypicalLowerDisplay = this.props.submode ?
            <>
            </>
          :
            <>
              {dialogMenuView}
              {domainMenuView}
              {subscriptionsMenuView}
            </>

    const maybeQuickToast = this.state.showQuickToast ?
            <>
            <Toast  durationMillis={this.state.toastDuration}
                displayText={this.state.toastText}
                noLoader={this.state.suppressLoader}
                done={this.quickToastDone}
                parent={this}/>
            </>
        :
            <></>
    return (
      !this.props.show ?
          null
        :
          <>
            <div style={{padding: "5px"}}>
              {maybeTypicalUpperDisplay}

              {txPresentation}

              {maybeTypicalLowerDisplay}
            </div>

            <ProgressListModal openProgressList={this.state.openTheProgressList}
                          fleeting={this.props.submode}
                          domain={this.state.domainToVisit}
                          ourWorkToDo={this.doWork}
                          onCancel={this.workCanceled}
                          onDone={this.closeProgressList}
                          title={title}
                          description="Since we currently operate in a proof-of-concept 'Serverless' mode, this will require a few steps..."/>
            {maybeQuickToast}
          </>
    ) // return ()
  } // render()
} // shizzleView

// ========================================

export default ShizzleView;
