// siteStatusModal.js

// simple modal to display important stats of a site

import React, {Component} from 'react';

import { Button, Checkbox, Divider, Input, Modal } from 'semantic-ui-react'

import AvatarBuilder from './avatarBuilder';

import { bshzColors } from './bshzColors';
import { getBitcoinDomainName, calcPenniesFromSats } from './commonReact';

/**
 * Displays important stats about a site/domain, but also aware of the 'status' of
 * the domain's most-advanced Quarterly (with respect to the current blockHeight).
 * Thus, it's aware if a domain:
 *   - is expired
 *   - can be renewed
 *   - can extend its furthest Quarterly
 *
 * Based on the status, this modal will present these options to the user,
 * and if selected, trigger the parent (surfer) to initiated them.
 */
class SiteStatusModal extends React.Component {
  constructor(props) {
    super(props);

    this.bitShizzle          = <><b>BitShizzle</b><small>&trade;</small></>;

    this.buildNewQuarterly                                  = this.buildNewQuarterly.bind(this)
    this.renewOwnership                                     = this.renewOwnership.bind(this)
    this.closeOfferForSaleModal                             = this.closeOfferForSaleModal.bind(this)
    this.toggleAcknowledgeUnderstandingDueDiligenceForSale  = this.toggleAcknowledgeUnderstandingDueDiligenceForSale.bind(this);
    this.adjustDomainPrice                                  = this.adjustDomainPrice.bind(this);
    this.placeDomainForSale                                 = this.placeDomainForSale.bind(this);
    this.showPurchaseOption                                 = this.showPurchaseOption.bind(this);
    this.purchaseDomain                                     = this.purchaseDomain.bind(this);
    this.cancelPurchase                                     = this.cancelPurchase.bind(this);
    this.cancelBuild                                        = this.cancelBuild.bind(this);
    this.displayAvatarModal                                 = this.displayAvatarModal.bind(this);
    this.closeAvatarModal                                   = this.closeAvatarModal.bind(this);
    this.setAvatar                                          = this.setAvatar.bind(this);

    this.state = {
      showOfferForSaleModal: false,
      acknowledgedUnderstandingDueDiligenceForSale: false,

      domainPrice: 1000000000,
      invalidPrice: false,

      showPurchaseModal: false,
      showProfileOptionsModal: false,
      showAvatarModal: false,

      avatarProps: null,    // props of null means don't mention avatar in the quarterly we build
                            // props of {blankAvatar: bool, ...} tells if we should define/mention in the quarterly we build
    };


    // props:
    //  .siteName
    //  .siteOwnerCount

  }

  displayAvatarModal() {
    this.setState({showAvatarModal: true})
  }
  closeAvatarModal(proceed = false /*(avatarProps*/) {
    this.setState({showAvatarModal: false})

    alert("closeAvatarModal: Will now decide if we should automatically proceed from here - with or without avatar that's been set. Proceed = " + (proceed ? 'true' : 'false'))

    if ( proceed ) {

      console.warn("closeAvatarModal(): state.avatarProps to use: ", this.state.avatarProps)
      alert("ONCE IMPLEMENTED, we'll automatically proceed to build the next quarterly. CHECK THE PROPS in console, BTW.\n\nNow calling buildNewQuarterly()...")






      //FIXME:
      // call buildNewQuarterly, or renewOwnership() - but WHICH ONE?

      this.buildNewQuarterly()



    } else {
      alert("we've closed the avatar builder, but, we haven't been instructed to proceed with automatically building the next quarterly")
      // probably because we canceled-out of avatar builder
    }
  }

  cancelBuild() {
    this.setState({showProfileOptionsModal: false})
  }

  //NOTE: by building the next quarterly, the parent will eventually negate its need to display this siteStatusModal
  //      Hmm. Maybe not. It seems to just be updated with new status
  buildNewQuarterly() {

    if ( this.props.onOurCurrentIdentity ) {
      // Require passing through profile modal first - if this is our identity
      // Once through, close, and proceed
      if ( !this.state.showProfileOptionsModal ) {
console.warn("buildNewQuarterly(): setting showProfileOptionsModal to true")
        this.setState({showProfileOptionsModal: true})
        return
      } else {
        this.setState({showProfileOptionsModal: false})
      }
    }

    console.log("siteStatusModal: we believe we can extend to a new quarterly. Will now trigger parent to do so...")
    if ( this.props.extendQuarterly ) {

      if ( this.state.avatarProps === null ) {
        alert("NOTE: we won't be including any avatarProps in this new quarterly (they're null)")
      } else {
        console.warn("avatarProps to include in new quarterly: ", this.state.avatarProps)
        alert("Note: we're building a new quarterly WITH props (see console)")
      }

      this.props.extendQuarterly(this.state.avatarProps)
    } else {
      alert("CODE ERROR: missing a property within <SiteStatusModal>")
      throw new Error("CODE ERROR: parent of <SiteStatusModal> must define an 'extendQuarterly' function/property")
    }

  }

    //NOTE: by building the next quarterly, the parent will eventually negate its need to display this siteStatusModal
  renewOwnership(offerForSale = false) {
    console.log("siteStatusModal: we believe we can renew ownership in this site. Will now trigger parent to do so...")

    if (offerForSale) {
      this.setState({showOfferForSaleModal: true})
      return
    }

    if ( this.props.extendQuarterly ) {

      if ( this.state.avatarProps === null ) {
        alert("NOTE: we won't be including any avatarProps in this new quarterly (they're null)")
      } else {
        console.warn("avatarProps to include in new quarterly: ", this.state.avatarProps)
        alert("Note: we're building a new quarterly WITH props (see console)")
      }

      this.props.extendQuarterly(this.state.avatarProps)
    } else {
      alert("CODE ERROR: we're missing a property within <SiteStatusModal>")
      throw new Error("CODE ERROR: the parent of <SiteStatusModal> must define an 'extendQuarterly' function/property")
    }

  }

  //NOTE: by building the next quarterly (part of offering domain for sale), the parent will eventually negate the need to display this siteStatusModal
  placeDomainForSale() {
    console.warn("about to set domain for sale")

    this.setState({showOfferForSaleModal: false})

    if ( this.props.setForSale ) {

      if ( this.state.avatarProps === null ) {
        alert("NOTE: we won't be including any avatarProps in this new quarterly (they're null)")
      } else {
        console.warn("avatarProps to include in new quarterly: ", this.state.avatarProps)
        alert("Note: we're building a new quarterly WITH props (see console)")
      }

      this.props.setForSale(this.state.domainPrice, this.state.avatarProps)
    } else {
      alert("CODE ERROR: missing a property within <SiteStatusModal>")
      throw new Error("CODE ERROR: parent of <SiteStatusModal> must define an 'siteForSale' function/property")
    }
  }

  purchaseDomain() {

    if ( this.props.purchaseTheDomain ) {
      alert("You're about to purchase this domain for " + this.props.qStats.priceInSats + " satoshis.")

      this.props.purchaseTheDomain()
    } else {
      alert("CODE ERROR: missing a property within <SiteStatusModal>")
      throw new Error("CODE ERROR: parent of <SiteStatusModal> must define an 'purchaseThisDomain' function/property")
    }

    this.setState({showPurchaseModal: false})
  }
  cancelPurchase() {
    this.setState({showPurchaseModal: false})
  }

  showPurchaseOption() {
    if ( confirm("You wish to purchase this domain for " + this.props.qStats.priceInSats + " satoshis. FIRST, be sure you know what that price means in your own local currency.") ) {
      let yesPurchaseIt = true
      if ( this.props.isOurDomain ) {
        yesPurchaseIt = false
        if ( confirm("NOTE: you already own this domain. But, since you've placed it up for sale, " +
              "if you've changed your mind, you can purchase it right now (from yourself) to prevent anyone else from purchasing it.") ) {
          yesPurchaseIt = true
        }
      }

      this.setState({showPurchaseModal: yesPurchaseIt})
    }
  }

  closeOfferForSaleModal() {
    this.setState({showOfferForSaleModal: false, acknowledgedUnderstandingDueDiligenceForSale: false})
  }

  toggleAcknowledgeUnderstandingDueDiligenceForSale() {
    if ( !this.state.acknowledgedUnderstandingDueDiligenceForSale ) {

      // each time we check the Acknowledge checkbox, scroll down a bit
      document.getElementById('forSale')?.scrollBy({
        top: 180,
        left: 0,
        behavior: 'smooth'
      })
    }
    this.setState({acknowledgedUnderstandingDueDiligenceForSale: !this.state.acknowledgedUnderstandingDueDiligenceForSale})
  }

  adjustDomainPrice(event) {
    const re = /^[0-9\b]+$/;
    console.log("price input: " + event.target.value)
    if (event.target.value === '' || re.test(event.target.value)) {
      if ( event.target.value.length < 11 && Number(event.target.value) < 4294967295) {
        const lenLessThan1 = event.target.value.length < 1
        this.setState({domainPrice: event.target.value,
            invalidPrice: lenLessThan1,
        })
      }
    }
  }

  // a blank avatar clears-out any existing setting (in the quarterly)
  setAvatar(props = { blankAvatar: true }
                  /*
                    {
                      hash: null,
                      top: '',
                      hairColor: '',
                      eyes: '',
                      skin: '',
                      eyebros: '',
                      mouth: '',
                      clothes: '',
                      clothesColor: '',
                      style: ''
                    }
                  */) {
  
    console.log("setAvatar(): avatar props: ", props)

    this.setState({avatarProps: {
                                    avatar: props
                                }
                  },
                  // build next quarterly
                  () => this.closeAvatarModal(true)
        )

    //alert("SiteStatusModal: setAvatar(): Now what? call props.extendQuarterly()?")
  }

  render() {

    // ***  DEVICE-DEPENDENT STYLING  ***
    // For mobile, "detect" landscape screen mode
    const landscape = this.props.isMobile ? window.innerWidth > window.innerHeight : false
    // For mobile, shrink the About, Security, and Glossary modals
    const modalClassName   = this.props.isMobile ?
                                    (landscape ?
                                            "modalMobileLandscape"
                                        :
                                            "modalMobilePortrait"
                                    )
                                :
                                    ""
    const modalContentClassName = this.props.isMobile ? "modalContentMobile" : ""
    const modalBottomClassName  = this.props.isMobile ? "modalBottomMobile"  : ""

    const qs = this.props.qStats

    if ( qs === null )
    {
      return (<></>)
    }

    const blocksUntilNextQ = Math.max(qs.minBlockHeightForNextQuarterly - this.props.blockHeight, 0)

    //const blocksUntil = blocksUntilNextQ > 0 ? blocksUntilNextQ : 0
    /*
    console.log( "Site/domain/identity: " + this.props.siteName + " (owner #" + this.props.ownerCount + ")" +
          "\nSite Expired:       " + qs.expired +
          "\nSite Renewable:     " + qs.renewable +

          "\n\nCURRENT blockHeight:             " + this.props.blockHeight +
          "\nSite quarterly blockHeight:      " + qs.currentQuarterlyBlockHeight +
          "\nBlocks since current Q:          " + (this.props.blockHeight - qs.currentQuarterlyBlockHeight) +
          "\nSite min blockHeight for next Q: " + qs.minBlockHeightForNextQuarterly +
          "\nmin blocks until next Q:         " +  blocksUntilNextQ +
          "\nSite can build new Quarterly:    " + qs.canBuildNewQuarterly +

          "\n\nposted price in sats:            " + postedPriceInSats +
          "\nposted owner P2PKH:              " + postedOwnerP2PKH
          )
    */

    //console.warn("qStats: ", this.props.qStats)

    const maybeButtonToBuildOnQuarterly = qs.canBuildNewQuarterly && !qs.renewable ?
              (this.props.onOurCurrentIdentity ?
                    <>
                      <Button disabled={false} content='Build new quarterly' positive onClick={this.buildNewQuarterly}/>
                    </>
                  :
                    <></>
              )
            :
              <></>
    const quarterlyCountSixOrGreater = qs.furthestQuarterlyDtx?.outputStates[0].quarterlyCount >= 6
    const maybeShowOptionToPlaceForSale = quarterlyCountSixOrGreater ?
                <>
                  &nbsp; &nbsp;
                  <Button disabled={false} color='yellow' onClick={() => this.renewOwnership(true)}>
                      <span style={{color: 'green'}}>
                        Renew AND place domain For Sale
                      </span>
                  </Button>
                </>
              :
                <></>
    const maybeButtonToRenew = qs.renewable ?
              (this.props.onOurCurrentIdentity ?
                    <>
                      <Button disabled={false} positive onClick={() => this.renewOwnership()}>
                          <span style={{color: 'yellow'}}>
                            Renew ownership
                          </span>
                      </Button>
                      {maybeShowOptionToPlaceForSale}
                    </>
                  :
                    <></>
              )
            :
              <></>

    const maybeMentionBuildingQuarterly = qs.canBuildNewQuarterly && !qs.renewable && !qs.expired ?
              (this.props.isOurDomain ?
                      (this.props.onOurCurrentIdentity ?
                            <>It is now possible (and a good idea) to <b>extend</b> the Quarterlies.</>
                          :
                            <>While it's now possible (and a good idea) to <b>extend</b> the Quarterlies for this domain,
                              you need to first assume its identity. Your current identity is something else.
                            </>
                      )
                    :
                      <>It's now possible for the OWNER of this domain to extend the Quarterlies.</>
              )
            :
              <></>

    const maybeMentionSettingSalePrice = quarterlyCountSixOrGreater ?
                      <>
                        <br></br>
                        <br></br>
                        <div style={{backgroundColor: 'yellow', padding:'8px'}}>
                            Alternatively, while renewing, you can simultaneously offer your domain
                            for sale - at a price you specify (in <b>satoshis</b>).
                        </div>
                      </>
                    :
                      <></>
    const maybeMentionRenewal = qs.renewable  && !qs.expired?
              (this.props.isOurDomain ?
                      (this.props.onOurCurrentIdentity ?
                            <>It is now possible (and a good idea) to <b>renew</b> your ownership of this domain.
                              <br></br>
                              <br></br>
                              <b style={{color:'red'}}>Failure to renew</b> in a timely manner will create an <b style={{color:'red'}}>opportunity for someone else to
                              claim your domain</b>. They would then have control over it, and you would be shut-out.
                              {maybeMentionSettingSalePrice}
                            </>
                          :
                            <>While it's now possible (and a good idea) to <b>renew</b> your ownership of this domain,
                              you need to first assume its identity. Your current identity is something else ({this.props.siteName?.toUpperCase()}:{this.props.ownerCount}).
                            </>
                      )
                    :
                      <>It's now possible for the <b>OWNER</b> of this domain to renew ownership.</>
              )
            :
              <></>

    const adviceToReclaim = <>
                              <br></br><br></br>
                              If you wish to continue controlling it, you'll need to RE-CLAIM it. You can do
                              this by clicking on the side-panel's 'Claim a new identity' option. <b>Until you do</b>,
                              anyone could pre-empt you, claim this domain and become the new owner - permanently.
                            </>
    const maybeMentionExpiration = qs.expired ?
              (this.props.isOurDomain ?
                      (this.props.onOurCurrentIdentity ?
                            <>Your ownership of this domain has <b style={{color:'red'}}>EXPIRED</b>.
                              {adviceToReclaim}
                            </>
                          :
                          <>
                            Your ownership of domain {this.props.siteName} has <b style={{color:'red'}}>EXPIRED</b>.
                            {adviceToReclaim}
                        </>
                      )
                    :
                      <>Domain {this.props.siteName} has <b>expired</b>.
                        <br></br><br></br>
                        If you wish, you can claim it as your own, and
                        become the new owner, by clicking on the side-panel's 'Claim a new identity' option.
                      </>
              )
            :
              <></>

    const domainPriceEquiv = <>({calcPenniesFromSats(this.state.domainPrice, this.props.bsvPriceUSD)})</>
    const price1Color = this.state.acknowledgedUnderstandingDueDiligenceForSale ?
                  "blue"
              :
                  "grey"
    const price2Color = this.state.acknowledgedUnderstandingDueDiligenceForSale ?
                  "black"
              :
                  "grey"
    const priceSetter =
                    <>
                        <div style={{color: price1Color}}> &nbsp; How many satoshis will you charge for the sale of your domain?
                                <br></br>
                                &nbsp; &nbsp; <Input type="text" value={this.state.domainPrice}
                                        disabled={!this.state.acknowledgedUnderstandingDueDiligenceForSale}
                                        onChange={this.adjustDomainPrice}
                                        style={{width:"125px"}}/> <span style={{color: price2Color}}>satoshis &nbsp; &nbsp;{domainPriceEquiv}</span>
                        </div>
                    </>
    const warningColor = this.state.acknowledgedUnderstandingDueDiligenceForSale ?
            "blue"
        :
            "red"

    const maybeSelfPurchaseMention = this.props.isOurDomain ?
              <>
                <div style={{backgroundColor: 'lightGrey', padding:'8px'}}>
                NOTE: you already own this domain. But, since you've placed it up for sale,
                if you've changed your mind, you can purchase it right now (from yourself) to prevent anyone else from purchasing it.
                </div>
              </>
            :
              <></>

    const maybeShowPurchaseModal = this.state.showPurchaseModal ?
              <>
                <Modal size='small' centered className={modalClassName}  open={true} style={{backgroundColor: bshzColors.purple, borderRadius: '20px'}}>

                    <Modal.Header style={{textAlign: 'center', backgroundColor: bshzColors.purple, borderRadius: '20px'}}>
                        <span style={{color: bshzColors.yellow}}> PURCHASING domain {getBitcoinDomainName(this.props.siteName, false)} (from owner #{this.props.ownerCount})</span>
                    </Modal.Header>

                    <Modal.Content className={modalContentClassName} scrolling style={{backgroundColor: bshzColors.ltPurple}}>
                        Since this site was placed up for sale at the price
                        of {qs.priceInSats} satoshis ({calcPenniesFromSats(qs.priceInSats, this.props.bsvPriceUSD)}), by
                        its current owner, ANYONE can purchase it for the specified price.
                        <br></br>
                        <br></br>
                        <div style={{backgroundColor: 'yellow', padding:'8px'}}>
                          <b style={{color:'red'}}>WARNING:</b> Our understanding of the current price
                          of BSV could be WRONG. Do your own due diligence before purchasing this domain.
                        </div>

                        <br></br>
                        {maybeSelfPurchaseMention}
                        <br></br>
                        <Button disabled={false} positive onClick={this.purchaseDomain}>
                          <span style={{color: 'yellow'}}>
                            Purchase this domain NOW
                          </span>
                        </Button>
                    </Modal.Content>

                    <Modal.Actions className={modalBottomClassName} style={{backgroundColor: bshzColors.purple, borderRadius: '0px 0px 20px 20px'}}>
                        <div style={{textAlign: 'center'}}>
                          <Button onClick={ this.cancelPurchase } content='Cancel' negative/>
                        </div>
                    </Modal.Actions>
                </Modal>
              </>
            :
              <></>

    const maybeShowOfferForSale = this.state.showOfferForSaleModal ?
              <>
                <Modal size='small' centered className={modalClassName}  open={true} style={{backgroundColor: bshzColors.purple, borderRadius: '20px'}}>

                    <Modal.Header style={{textAlign: 'center', backgroundColor: bshzColors.purple, borderRadius: '20px'}}>
                        <span style={{color: bshzColors.yellow}}> Placing domain {getBitcoinDomainName(this.props.siteName, false)} (owner #{this.props.ownerCount}) for SALE</span>
                    </Modal.Header>

                    <Modal.Content id="forSale" className={modalContentClassName} scrolling style={{backgroundColor: bshzColors.ltPurple}}>
                        You can now offer your domain/site for sale at a price that you specify (in Bitcoin SV satoshis). <b>Anyone</b> can
                        respond to this offer and claim ownership of your domain - by simultaneously paying to
                        you the price you specify, to the address you specify.
                        <p></p>
                        <b style={{color:'red'}}>WARNING: </b> if/when you specify your price in BSV satoshis, it is <b>your</b> responsibility
                        to understand the price of BSV. We make an effort here to present its value in US Dollars, but our efforts are susceptible to
                        attacks, delays, and mistakes. Do <b>NOT</b> offer your domain for sale without first doing your <b>own</b> due diligence, and
                        being clear on its value in your own local currency.
                        <p></p>
                        <b style={{color:'red'}}>WARNING: </b> You're setting a price in <b style={{color:'blue'}}>satoshis</b>, and the offer may
                        stand for <b>months</b>. If during this time the price of BSV declines in your local currency, the price of your offer will
                        decline <b>in dollars</b> - because you're setting it in satoshis. If you do not understand this, please do NOT offer your domain for sale.
                        <p></p>
                        Any sale, and transfer of ownership is irrevocable. Any mistakes in price, or your receive address cannot be rectified after
                        the <b>OFFER</b> is made. Once you click the 'Place domain for sale' button, nothing can be changed - short of you
                        buying-back your own domain <b>before</b> anyone else does.

                        <Divider />

                        <span style={{color:warningColor}}>By checking this box, you certify that you've read the warnings above, and you agree that {this.bitShizzle} cannot guarantee
                        the value, in any currency, of the offer in satoshis you're about to make.</span>
                        <p></p>
                        <div style={{textAlign: 'center'}}>
                          <Checkbox   checked={this.state.acknowledgedUnderstandingDueDiligenceForSale}
                                      onClick={this.toggleAcknowledgeUnderstandingDueDiligenceForSale}
                          /> &nbsp; I understand that I am responsible for understanding the value of my offer for sale
                        </div>
                        <br></br>
                        {priceSetter}
                        <br></br>
                        Your offer will be <b>irrevocable</b>: &nbsp;
                        <Button  ref={this.readyButton} color='green' onClick={this.placeDomainForSale} disabled={!this.state.acknowledgedUnderstandingDueDiligenceForSale || this.state.invalidPrice}>
                            <span style={{color: 'yellow'}}>
                                Place domain for SALE at this price in SATOSHIS
                            </span>
                        </Button>
                        <br></br>
                        <br></br>
                        Current price of BSV: ${this.props.bsvPriceUSD} USD
                    </Modal.Content>

                    <Modal.Actions className={modalBottomClassName} style={{backgroundColor: bshzColors.purple, borderRadius: '0px 0px 20px 20px'}}>
                        <div style={{textAlign: 'center'}}>
                          <Button onClick={ this.closeOfferForSaleModal } content='Back' negative/>
                        </div>
                    </Modal.Actions>
                </Modal>
              </>
            :
              <></>

    const ownershipMention = this.props.isOurDomain ?
                <>You own this domain.<br></br></>
              :
                <>You do not own this domain.<br></br></>
    const maybeShowOptionToBuy = qs.priceInSats > 0 /*&& !this.props.isOurDomain*/ ?
                <>
                  <Divider />
                  {ownershipMention}
                  <span style={{color:'blue'}}>This domain is for SALE at a price of {qs.priceInSats} satoshis ({calcPenniesFromSats(qs.priceInSats, this.props.bsvPriceUSD)}).</span>
                  <br></br>
                  <br></br>
                  <Button disabled={false} color='yellow' onClick={() => this.showPurchaseOption()}>
                          <span style={{color: 'green'}}>
                            Purchase this domain
                          </span>
                  </Button>
                </>
              :
                <></>
    const maybePrice = qs.priceInSats > 0 ?
              <>
                    <p></p>
                    price for domain: {qs.priceInSats} satoshis ({calcPenniesFromSats(qs.priceInSats, this.props.bsvPriceUSD)})
                    <br></br>
              </>
            :
              <>
                    <p></p>
                    This domain is not currently for sale.
                    <br></br>
              </>

    const maybeShowProfileOptionsModal = this.state.showProfileOptionsModal ?
              <>
                <Modal size='small' centered className={modalClassName}  open={true} style={{backgroundColor: bshzColors.purple, borderRadius: '20px'}}>

                    <Modal.Header style={{textAlign: 'center', backgroundColor: bshzColors.purple, borderRadius: '20px'}}>
                        <span style={{color: bshzColors.yellow}}> Profile Options for {getBitcoinDomainName(this.props.siteName, false)} (owner #{this.props.ownerCount})</span>
                    </Modal.Header>

                    <Modal.Content className={modalContentClassName} scrolling style={{backgroundColor: bshzColors.ltPurple}}>

                        Since you're doing some maintenance on your domain, now's a good time to consider if
                        you'd like to set (or change) your <b style={{color:'blue'}}>avatar</b>.
                        <br></br>
                        <br></br>


                        <Button disabled={false} positive style={{backgroundColor:'blue'}} onClick={this.displayAvatarModal}>
                          <span style={{color: 'white'}}>
                            Yes, let's also Set/Reset my avatar
                          </span>
                        </Button>
                        <Button disabled={false} positive onClick={this.buildNewQuarterly}>
                          <span style={{color: 'yellow'}}>
                            No, thanks. But, let's proceed
                          </span>
                        </Button>
                    </Modal.Content>

                    <Modal.Actions className={modalBottomClassName} style={{backgroundColor: bshzColors.purple, borderRadius: '0px 0px 20px 20px'}}>
                        <div style={{textAlign: 'center'}}>
                          <Button onClick={ this.cancelBuild } content='Back' negative/>
                        </div>
                    </Modal.Actions>
                </Modal>
              </>
            :
              <>
              </>


    const maybeShowAvatarModal = this.state.showAvatarModal ?
              <>
                <Modal size='small' centered className={modalClassName}  open={true} style={{backgroundColor: bshzColors.purple, borderRadius: '20px'}}>

                    <Modal.Header style={{textAlign: 'center', backgroundColor: bshzColors.purple, borderRadius: '20px'}}>
                        <span style={{color: bshzColors.yellow}}> Set Avatar for {getBitcoinDomainName(this.props.siteName, false)} (owner #{this.props.ownerCount})</span>
                    </Modal.Header>

                    <Modal.Content className={modalContentClassName} scrolling style={{backgroundColor: bshzColors.ltPurple}}>

                        <br></br>
                        Use the buttons below to build your <b style={{color:'blue'}}>avatar</b>.
                        <br></br>
                        <br></br>

                        <AvatarBuilder useThisAvatar={this.setAvatar} />

                    </Modal.Content>

                    <Modal.Actions className={modalBottomClassName} style={{backgroundColor: bshzColors.purple, borderRadius: '0px 0px 20px 20px'}}>
                        <div style={{textAlign: 'center'}}>
                          <Button onClick={ () => this.closeAvatarModal(false) } content='Back/Cancel' negative/>
                        </div>
                    </Modal.Actions>
                </Modal>
              </>
            :
              <>
              </>
    return (
      <>

          <Modal size='small' centered className={modalClassName}  open={true} style={{backgroundColor: bshzColors.purple, borderRadius: '20px'}}>

              <Modal.Header style={{textAlign: 'center', backgroundColor: bshzColors.purple, borderRadius: '20px'}}>
                  <span style={{color: bshzColors.yellow}}> Status of domain {getBitcoinDomainName(this.props.siteName, false)} (owner #{this.props.ownerCount})</span>
              </Modal.Header>

              <Modal.Content className={modalContentClassName} scrolling style={{backgroundColor: bshzColors.ltPurple}}>

                    Site Expired: {qs.expired ? 'true' : 'false'}<br></br>
                    Site Renewable: {qs.renewable ? 'true' : 'false'}<br></br>
                    New Quarterly can be built: {qs.canBuildNewQuarterly ? 'true' : 'false'}<br></br>
                    <br></br>
                    CURRENT blockHeight: {this.props.blockHeight}<br></br>
                    Site quarterly blockHeight: {qs.currentQuarterlyBlockHeight}<br></br>
                    Blocks since current Q: {(this.props.blockHeight - qs.currentQuarterlyBlockHeight)}<br></br>
                    Min blockHeight until next Q permitted: {qs.minBlockHeightForNextQuarterly}<br></br>
                    Min blocks until next Q permitted: {blocksUntilNextQ}<br></br>

                    {maybePrice}
                    <br></br>
                    isOurDomain: {this.props.isOurDomain ? 'true' : 'false'}<br></br>
                    isOurCurrentIdentity: {this.props.onOurCurrentIdentity ? 'true' : 'false'}
                    <br></br>
                    <br></br>
                    <div style={{color:'blue'}}>
                      {maybeMentionBuildingQuarterly}
                      {maybeMentionRenewal}
                      {maybeMentionExpiration}
                    </div>
                    {maybeShowOptionToBuy}

                    <br></br>
              </Modal.Content>

              <Modal.Actions className={modalBottomClassName} style={{backgroundColor: bshzColors.purple, borderRadius: '0px 0px 20px 20px'}}>
                      <div style={{textAlign: 'center'}}>
                        {maybeButtonToBuildOnQuarterly}
                        {maybeButtonToRenew} &nbsp; <Button onClick={ this.props.closeIt } content='Back' negative/>
                      </div>
              </Modal.Actions>

          </Modal>

          {maybeShowOfferForSale}
          {maybeShowPurchaseModal}
          {maybeShowProfileOptionsModal}
          {maybeShowAvatarModal}
      </>
          )
  }
}

export default SiteStatusModal;
