import React from 'react';

import './ad.css';

import { Button, Checkbox, Container, Divider, Dropdown, Form, Grid,
  Input, Menu, Message, Modal, Popup, Radio, TextArea } from 'semantic-ui-react'
import SurveyQuestion from './surveyQuestion';

class ShizzleAd extends React.Component {
  constructor(props) {
    super(props);

    this.bshzPurple = '#8313e2';
    this.bshzYellow = "#fff300";
    this.bshzLtPurp = '#e7d0fb';

    const windowURL = window.location.href // returns the absolute URL of a page
    console.warn("AD: our URL (may be useful for innerFrame logic): ", windowURL)

    const windowPathname = window.location.pathname
    const pathSplit = windowPathname.split('/')
    console.log("AD: window pathname split : ", pathSplit)

    this.state =  { answerVal: '0',
                    a1: null,
                    a2: null,
                    a3: null,
                    a4: null,
                    a5: null,
                    a6: null,
                    a7: null,
                    a8: null,
                    a9: null,
                    a10: null,
                    a11: null,
                    a12: null,
                    a13: null,
                    a14: null,
                    userEmail: '',
                    submitted: false,
                    ownsBusiness: null
                  }

    this.answerChanged          = this.answerChanged.bind(this);
    this.changeValue            = this.changeValue.bind(this);
    this.postSurvey             = this.postSurvey.bind(this);
    this.handleEmail            = this.handleEmail.bind(this);

    this.ownsBusinessChanged    = this.ownsBusinessChanged.bind(this);
  }

  answerChanged(e) {
    console.log('answerChanged: ' + e.target.value);

    this.setState({ answerVal: e.target.value });
  }

  async postSurvey(e) {
    console.log("IMPLEMENT ME: submit form")

    e.preventDefault()

    console.log("email so far: " + this.state.userEmail)

    console.log(" email: ", this.state.userEmail)
    console.log("validate email: ", this.validateEmail(this.state.userEmail))

    this.setState({submitted: true})

    console.log("SENDING some data")

    //NOTE: /m2 is proxied to the Node server - specified in react's package.json
    //const url = 'http://bitshizzle.com/m2'
    //const url = '/m2'
    const url = '/survey/'
    const postBody = JSON.stringify({
                      "a1": this.state.a1,
                      "a2": this.state.a2,
                      "a3": this.state.a3,
                      "a4": this.state.a4,
                      "a5": this.state.a5,
                      "a6": this.state.a6,
                      "a7": this.state.a7,
                      "a8": this.state.a8,
                      "a9": this.state.a9,
                      "a10": this.state.a10,
                      "a11": this.state.a11,
                      "a12": this.state.a12,
                      "a13": this.state.a13,
                      "a14": this.state.a14,
                      "email": this.state.userEmail,
                      "from": "survey1"
                    })
    console.warn("sending/POSTing survey results: ", postBody)

    //NOTE: is there a limitation with fetch() - can't XS post?
    //      We do fine elsewhere - reaching WoC
    const response = await fetch(url, { 
      method: 'POST', 
      headers: { 
          'Content-type': 'application/json'
      }, 
      body: postBody
    })

    console.warn("Sent survey responses")

    //const resTxt = await response.text(); 
    const resData = await response.json();
    if(resData.status === 'fail'){
      //FIXME: maybe don't want to alert
      alert("Message failed to send.")
    } else {
      console.log("BTW: response json: ", resData)
    }
  }

  // accept an answer for a particular survey question
  changeValue(answer, value) {
    console.log('answer: ', answer);
    console.log('changeValue: ' + value);

    //NOTE: special format for a dynamic key name
    //      see: https://stackoverflow.com/questions/29280445/reactjs-setstate-with-a-dynamic-key-name#29281499
    this.setState({[answer]: value})
  }

  handleEmail(e,v) {
    console.log("handleEmail: ", v.value)
    this.setState({userEmail: v.value})
  }

  validateEmail(email) {
    const res = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return res.test(String(email).toLowerCase());
  }

  ownsBusinessChanged(e) {
    console.log("OWNS BUSINESS: ", e.target.value)
    this.setState({ownsBusiness: e.target.value})
  }

  render() {
    const answerVal = this.state.answerVal

    const a1 = this.state.a1
    const a2 = this.state.a2
    const a3 = this.state.a3
    const a4 = this.state.a4
    const a5 = this.state.a5
    const a6 = this.state.a6
    const a7 = this.state.a7
    const a8 = this.state.a8
    const a9 = this.state.a9
    const a10 = this.state.a10
    const a11 = this.state.a11
    const a12 = this.state.a12
    const a13 = this.state.a13
    const a14 = this.state.a14
    const userEmail = this.state.userEmail
    const emailValid = this.validateEmail(userEmail)
    const emailColor = emailValid ? 'blue' : 'red'
    const partialEmail = (userEmail.trim().length > 0 && !emailValid)

    const first10NotAnswered = (a1 === null) ||
                               (a2 === null) ||
                               (a3 === null) ||
                               (a4 === null) ||
                               (a5 === null) ||
                               (a6 === null) ||
                               (a7 === null) ||
                               (a8 === null) ||
                               (a9 === null) ||
                               (a10=== null)
                            // ||
                            //(a10 === null) ||
                            //(a11 === null) ||
                            //(a12 === null) ||
                            //(a13 === null)
    const buttonDisabled = first10NotAnswered || partialEmail || this.state.submitted

    const isLoading = partialEmail
    const emailBoxColor = (userEmail.trim().length > 0 && !emailValid) ? '#FF5858' : 'black'

    const unansweredTxt = first10NotAnswered ?
                            <span style={{color: '#FF5858'}}> &nbsp; (<i>please</i> answer the first ten questions)</span>
                              :
                            <> &nbsp; This information will never be shared.</>
    const emailMention = partialEmail ?
                            <span style={{color: '#FF5858'}}>(not a valid email format)</span>
                              :
                            null
    const checkEmail = emailValid ? <><p></p><p><b style={{color: "green"}}>Please check your email</b> (or spam folder) to confirm your interest in receiving updates.</p></> : null

    const optionalBusinessQuestions =
        <>
          <h3>As a business/website owner/controller, would you like to:</h3>
          <SurveyQuestion cv={this.changeValue} a='a11' opt='y' theQuestion='Establish a business presence on a Bitcoin platform' />
          <SurveyQuestion cv={this.changeValue} a='a12' opt='y' theQuestion='Conduct business logic on a Bitcoin platform' />
          <SurveyQuestion cv={this.changeValue} a='a13' opt='y' theQuestion='Build on top of a Bitcoin platform (with libraries, SDKs, etc)' />
          <SurveyQuestion cv={this.changeValue} a='a14' opt='y' theQuestion='Launch apps, contracts, or NFTs on top of an open Bitcoin platform' />
        </>

    const optionalBusinessSwitcher =
          this.state.ownsBusiness ?
                <>
                  {optionalBusinessQuestions}
                </>
            :
                <>
                  <div><b>Optional:</b> Do you own or control a business or website?</div>&nbsp; &nbsp;
                  <input type='radio' value={false} checked={this.state.ownsBusiness===false} onChange={this.ownsBusinessChanged}/>
                  <span>
                    &nbsp;No
                  </span>
                  &nbsp; &nbsp;
                  <input type='radio' value={true} checked={this.state.ownsBusiness===true} onChange={this.ownsBusinessChanged}/>
                  <span>
                    &nbsp;Yes
                  </span>
                </>

    return (
      <>
        <div style={{padding: "20px"}}>
          <h1><b></b><span style={{color: this.bshzPurple}}>Bit<i>Shizzle</i><small>&trade;</small></span> &nbsp;Not hype. Just getting our bearings.</h1>
          <Divider />

          <p>We’re building an open Bitcoin communications platform, on-chain. You can help us build the <i>right</i> one.</p>

          <div style={{margin: 0, maxWidth: "500px"}}>
            <h3>How much do you value these features?</h3>

            <p></p>
            <Form onSubmit={this.postSurvey} style={{ width: "500px", margin: 0}}>
              <SurveyQuestion cv={this.changeValue} a='a1'  theQuestion='Owning a Bitcoin Identity' />
              <SurveyQuestion cv={this.changeValue} a='a2'  theQuestion='Owning or visiting Bitcoin Websites' />
              <SurveyQuestion cv={this.changeValue} a='a3'  theQuestion='Owning or using Bitcoin Group Chats' />
              <SurveyQuestion cv={this.changeValue} a='a4'  theQuestion='Private communications (Bitcoin "email")' />
              <SurveyQuestion cv={this.changeValue} a='a5'  theQuestion='Private payments' />
              <SurveyQuestion cv={this.changeValue} a='a6'  theQuestion='Hidden communications' />
              <SurveyQuestion cv={this.changeValue} a='a7'  theQuestion='Collecting small fees for helping to build the ShizzleVerse&trade;' />
              <SurveyQuestion cv={this.changeValue} a='a8'  theQuestion='Giving and receiving small tips for posts and comments' />
              <SurveyQuestion cv={this.changeValue} a='a9' theQuestion='Re-selling a Bitcoin site you no-longer wish to own' />
              <SurveyQuestion cv={this.changeValue} a='a10' theQuestion='Reducing spam' />

              {optionalBusinessSwitcher}

              <h3>
                Would you like to receive updates on BitShizzle's progress?
              </h3>
              <Input type='email' loading={isLoading} field='userEmail' placeholder='Enter your email'
                            value={this.state.userEmail} style={{width: "300px"}}
                            onChange={ this.handleEmail }>
                <input style={{borderRadius: '50px'}} />
              </Input> {emailMention}
              <p></p>
              <p></p>

              <Button primary disabled={buttonDisabled} type="submit">
                <span style={{color: 'white'}}>
                  SUBMIT
                </span>
              </Button>
              {unansweredTxt}
            </Form>
          </div>
        </div>

        &nbsp;
        <Divider style={{margin: 0}}/>
        <div style={{textAlign: "center"}}><small>&copy; 2022 BitSchnitzel LLC</small></div>


        <Modal size='small' open={this.state.submitted}>
          <Modal.Header style={{textAlign: 'center', backgroundColor: this.bshzPurple}}><span style={{color: this.bshzYellow}}>Thank you for your answers!</span></Modal.Header>
          <Modal.Content style={{textAlign: 'center', backgroundColor: this.bshzLtPurp}}>
            This will help us focus on what's most important.
            {checkEmail}

            <p></p>
            <p></p>

            <Button  color='green' onClick={this.props.handleJumpToHome}>
                <span style={{color: 'white'}}>
                  Okay
                </span>
            </Button>
          </Modal.Content>
        </Modal>
      </>
    );
  }
}

export default ShizzleAd;
