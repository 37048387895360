
import {
  hexByteToAscii,
  //hexStringToAscii,
  //execAsync
} from './commonFuncs';

import { //NETWORK,
  PAYLOAD_ESCAPE_FLAG } from './harnessConstants'

import * as eccryptoJS from 'eccrypto-js';

// NOTE: we expect a json-parsed state, and a number blockHeight
//FIXME: maybe rename to reviewAssetStatus(), and have it also examine latest Update,
//       and also add canBuildOnContinue, and canBuildOnUpdate.
//       ( this means that somewhere we will need a findLatestUpdate() )
//FIXME: maybe, also add a check (somewhere) to the outputs [0], or buildable/followable, to verify it's not spent
//FIXME: rename odtx to latestContinueDecodedTx
function reviewRenewalStatus(odtx, blockHeight) {
  console.log("reviewRenewalStatus(): blockHeight: " + blockHeight)
  console.log("reviewRenewalStatus(): odtx: ", odtx)

  const renewalDeadlineHex = odtx.outputStates[ 0 ].renewalDeadline
  if ( renewalDeadlineHex === undefined || renewalDeadlineHex === null || renewalDeadlineHex.length < 8 ) {
    console.warn("reviewRenewalStatus(): returning null. renewalDeadlineHex is "
                + renewalDeadlineHex + ". Unclaimed?")
    return null
  }

  if ( odtx.outputStates[0].mode === '50' ) {
    console.warn("reviewRenewalStatus(): mode is 'P', there is no renewal status")
    return null
  }
  const renewalDeadlineInt = Buffer.from( renewalDeadlineHex, "hex").readUInt32LE(0)
  const blocksUntilDeadline = renewalDeadlineInt - blockHeight


  //const ageOfTx = blockHeight - odtx.blockNumInt
  const blockNumHex = odtx.outputStates[0].blockNum
  const blockNumInt = Buffer.from(blockNumHex, 'hex').readUInt32LE(0)

  //FIXME: maybe we should also check buildable[0]

  console.log("reviewRenewalStatus(): blocksUntilDeadline: " + blocksUntilDeadline)
  //console.log("reviewRenewalStatus(): ageOfTx: " + ageOfTx + " blocks")

  const mode = hexByteToAscii( odtx.outputStates[ 0 ].mode )
  const allowedToBeSwiped = mode === 'K' || mode === 'p'

  let swipeText = null
  let couldBeSwiped = false
  let couldBeRenewed = true

  const blocksUntilText = <>(in {blocksUntilDeadline} blocks)</>
  if ( allowedToBeSwiped ) {
    if ( blocksUntilDeadline >= 17520 ) {
      couldBeRenewed = false
      swipeText = null
    } else if ( blocksUntilDeadline > 4032 ) {
      swipeText = <span> This asset can now be renewed! It is WITHIN four months before it MUST be renewed. </span>
    } else if ( blocksUntilDeadline > 2016 ) {
      swipeText = <span style={{color: 'red'}}><b>NOTE:</b> This asset is nearing a renewal deadline (2-4 weeks). </span>
    } else if ( blocksUntilDeadline > 1008 ) {
      swipeText = <span style={{color: 'red'}}><b>NOTE:</b> This asset is WITHIN two weeks of a renewal deadline {blocksUntilText}. </span>
    } else if ( blocksUntilDeadline > 576 ) {
      swipeText = <span style={{color: 'red'}}><b>NOTE:</b> This asset is WITHIN one week of a renewal deadline {blocksUntilText}. </span>
    } else if ( blocksUntilDeadline > 288 ) {
      swipeText = <span style={{color: 'red'}}><b>NOTE:</b> This asset will EXPIRE in LESS THAN 4 days {blocksUntilText} - unless it is renewed. </span>
    } else if ( blocksUntilDeadline > 144 ) {
      swipeText = <span style={{color: 'red'}}><b>NOTE:</b> This asset will EXPIRE tomorrow {blocksUntilText}. </span>
    } else if ( blocksUntilDeadline > 0 ) {
      swipeText = <span style={{color: 'red'}}><b>NOTE:</b> This asset will EXPIRE some time in the next few hours {blocksUntilText}. </span>
    } else {
      couldBeSwiped = true
      couldBeRenewed = false
      swipeText = <span style={{color: 'red'}}><b>NOTE:</b> This asset ('{odtx.limbName}') may be re-claimed (swiped) by ANYONE. It has EXPIRED. </span>
    }
  }

  let preClaimed
  let blockWhenWeCanPost
  if ( odtx.mode === '4B' || odtx.mode === '4b' ) {  // 'K'
    // Could post at any time
    preClaimed = true
    blockWhenWeCanPost = 0
  } else {
    preClaimed = false
    blockWhenWeCanPost = blockNumInt + 13140  // 3 months after post
  }

  const canPost = blockWhenWeCanPost <= blockHeight && !couldBeSwiped
  //const canRenew = (renewalDeadlineInt - 17520) <= blockHeight

  // if BEFORE fourMonthsBeforeRenewalDeadline
  if ( blockHeight < (renewalDeadlineInt - 17520) ) {
    // zone 2: must wait 3 months between posts
    //         Has it been that long?
  } else if ( false ) { // change to check for less than renewal deadline
    // zone of renewal

  } // add: } else { expired }

  const blockWhenWeCanRenew = renewalDeadlineInt - 17520
  // earliest point when:
  //     we could post
  //     we could renew
  let blockWhenStatusMayChange = Math.min(blockWhenWeCanPost, blockWhenWeCanRenew)
  if ( preClaimed || canPost || couldBeRenewed || couldBeSwiped ) {
    blockWhenStatusMayChange = 0
  }

  return {
          limbName:              odtx.limbName,
          reviewedAtBlockHeight: blockHeight,

          needsUpdate:           odtx.followable[0] === 0, // "shouldn't" happen
          //outputAge:
          //canBuildOnContinue:  ,
          canPost:               canPost,
          couldBeRenewed:        couldBeRenewed,
          expired:               couldBeSwiped,
          swipeText:             swipeText,
          blocksUntilDeadline:   blocksUntilDeadline,

          // Not sure if we need this
          renewalDeadlineInt:    renewalDeadlineInt,

          //blockWhenWeCanPost:

          //lowest of deadline, when we can post, when we can renew
          blockWhenStatusMayChange:  blockWhenStatusMayChange

          //FIXME: does the contract behave smart if we post just before 3rd quarter is over?
         }
} // reviewRenewalStatus()

/**
 * Use minimal, and blocksBeyondMinimal, to re-calculate the status, AND the blockHeight
 *
 * NOTE: most of these modes should never be encountered. renewalStatus is for output #0 ON THE MAIN LINE
 */
 function adjustRenewalStatus(dtx, blockHeight, chosenMode, bbm, silent = false) {
  if ( chosenMode === 'p' ) {
    blockHeight += (365 * 36)   // FOURTH_OF_YEAR_IN_BLOCKS
    if ( !silent ) console.warn("adjustRenewalStatus(): adding a fourth of a year in blocks, resulting in " + blockHeight)
  } else if ( chosenMode === 'U' ) {
    console.error("adjustRenewalStatus(): FIXME: why mode " + chosenMode + "?")
    alert("adjustRenewalStatus(): mode " + chosenMode + " should never apply here")
  } else if ( chosenMode === '1' || chosenMode === '2' || chosenMode === '3' || chosenMode === '4' ||
              chosenMode === '5' || chosenMode === '6' || chosenMode === '7' || chosenMode === '8' ||
              chosenMode === 'G' || chosenMode === 'g') {
    console.error("adjustRenewalStatus(): FIXME: why mode " + chosenMode + "?")
    //alert("adjustRenewalStatus(): mode " + chosenMode + " should never apply here")
  } else if ( chosenMode === 'P' ) {
    console.warn("adjustRenewalStatus(): will adjust blockHeight for mode 'P' - even though there's no 'renewal' to affect")
  } else if ( chosenMode === 'K' ) {
    console.warn("adjustRenewalStatus(): will adjust blockHeight for mode 'K'")
    //alert("adjustRenewalStatus() FIXME: not yet implemented for K?")
  } else {
    alert("adjustRenewalStatus(): should mode " + chosenMode + " ever apply here?")
    console.error("adjustRenewalStatus(): FIXME: why mode " + chosenMode + "?")
  }

  blockHeight += bbm
  if ( !silent ) console.warn("adjustRenewalStatus(): adding " + bbm + " blocks, resulting in height " + blockHeight)

  return { status: reviewRenewalStatus(dtx, blockHeight),
           height: blockHeight
         }
}

//export /* */
function getBitcoinDomainName( name, setTheColor = false ) {
    const bitcoinSymbol = '\u20BF'
    //FIXME: the purples are currently duplicated in index.css
    const bshzPurple             = '#8313e2';

    //WARNING: on chrome, we've found that using fontSize of 1.2rem, 1.8rem, or 16px works
    //         FINE to display the bitcoin symbol, BUT fontSizes 1.0, 1.3, 1.4, 1.5, 1.6, 1.7 did NOT.
    if ( setTheColor ) {
        return  <>
                    <b style={{color:bshzPurple, fontSize:"1.2rem", verticalAlign:"5%"}}>
                        <span style={{verticalAlign:"-5%"}}>
                            {bitcoinSymbol}
                        </span>
                        @
                    </b>
                    <b style={{color:"blue", fontSize:"1.3rem"}}>
                        {name.toLowerCase()}
                    </b>
                </>
    } else {
        return  <>
                    <b style={{fontSize:"1.2rem", verticalAlign:"5%"}}>
                        <span style={{verticalAlign:"-5%"}}>
                            {bitcoinSymbol}
                        </span>
                        @
                    </b>
                    <b style={{fontSize:"1.3rem"}}>
                        {name?.toLowerCase()}
                    </b>
                </>
    }
}

/**
 * Encrypts AND prepends a special PAYLOAD_ESCAPE_FLAG (to the outgoingContent in the returned object)
 *
 * @param {*} sharedKey
 * @param {*} outgoingContent
 * @returns
 */
async function encryptMessage(sharedKey, outgoingContent, isActuallyHex = false) {

  //console.error("\nencryptContent(): shared key: ", sharedKey + "\n")
    let hexContent
    let originalLen
    let mBytes
    if ( isActuallyHex ) {
      // we use this to double-check, further down
      hexContent = outgoingContent

      console.warn("Will encrypt this HEX message: " + hexContent)
      mBytes = eccryptoJS.hexToBuffer(hexContent);

      originalLen = hexContent.length / 2
    } else {
      // we use this to double-check, further down
      hexContent = Buffer.from(outgoingContent, 'utf8').toString('hex')

      console.warn("Will encrypt this UTF8 message: " + outgoingContent)
      mBytes = eccryptoJS.utf8ToBuffer(outgoingContent);

      originalLen = outgoingContent.length
    }

    const aesKey = sharedKey
    const iv = eccryptoJS.randomBytes(16)
    const msgBytes = mBytes
    const ciphertextBuffer = await eccryptoJS.aesCbcEncrypt(iv, aesKey, msgBytes);


    // DOUBLE-CHECK we can undo-this

    const decrypted = await eccryptoJS.aesCbcDecrypt(iv, aesKey, ciphertextBuffer);
    console.log("decrypted ascii message: " + decrypted)
    if ( Buffer.from(decrypted, 'utf8').toString('hex') !== hexContent ) {
        console.error("Decrypted doesn't match the original")
        console.error("Decrypted length: " + decrypted.length)
        console.error("original length: " + originalLen)
        console.error("decrypted hex: " + Buffer.from(decrypted, 'utf8').toString('hex'))
        console.error("original hex:  " + hexContent)
        alert("Read the logs. Trouble encrypting+decrypting. Won't send the transaction")
        return {
            success: false
        }
    }

    const hexCipherOnly = ciphertextBuffer.toString('hex') //Buffer.from(ciphertextBuffer).toString('hex')
    console.log("BTW: cipher text is this many bytes: " + (hexCipherOnly.length/2))
    console.log("BTW: hex cipher: " + hexCipherOnly + '\n')

    const hexIvOnly = Buffer.from(iv).toString('hex')
    console.log("BTW: iv is this many bytes: " + (hexIvOnly.length/2))
    console.log("IV: " + hexIvOnly + '\n')

    // prepend PAYLOAD_ESCAPE_FLAG ('*$^&'), and flagged type (encryption: 'EC'),
    // 16 bytes of initialization vector, then the ciphertext
    const buf1 = Buffer.from( PAYLOAD_ESCAPE_FLAG +'EC' )
    const buf2 = Buffer.from( iv )
    const buf3 = Buffer.from( ciphertextBuffer )
    const bufArray = [buf1, buf2, buf3]
    const completeBuff = Buffer.concat(bufArray)
    const buffCompleteHexString = completeBuff.toString('hex') //Buffer.from(completeBuff).toString('hex')
    console.log("completeBuffHex: ", buffCompleteHexString)
    console.log("len completeBuffHex: ", buffCompleteHexString.length/2)

    const buff1HexString = buf1.toString('hex') //Buffer.from(buf1).toString('hex')
    const buff2HexString = buf2.toString('hex') //Buffer.from(buf2).toString('hex')
    const buff3HexString = buf3.toString('hex') //Buffer.from(buf3).toString('hex')
    console.log("buf1Hex (flag+type EC): ", buff1HexString)
    console.log("buf2Hex (iv): ", buff2HexString)
    console.log("buf3Hex: (ciphertext)", buff3HexString)
    console.log("len buf1Hex: ", buff1HexString.length/2)
    console.log("len buf2Hex: ", buff2HexString.length/2)
    console.log("len buf3Hex: ", buff3HexString.length/2)

    return {
        success: true,
        ciphertext: buffCompleteHexString
    }
}

/**
 * returns UTF8 decrypted, or null if not
 *
 * @param {*} cipherBlob - a hex string
 * @param {*} sharedKey
 * @returns a utf8 string, or null
 */
async function decryptContent(cipherBlob, sharedKey) {

    const payloadHex = cipherBlob

    // THIS PORTION extracted/adapted from ShizzleNav's handleRetrievedDecryptionKey()
//console.error("\ndecryptContent(): shared key: ", sharedKey + "\n")
    let decrypted = null
    try {
        console.log("decryptContent(): " + payloadHex)
        const iv = payloadHex.substring(0, 32) //payloadHex.substring(12, (12+32))
        console.log("decryptContent(): iv: " + iv)
        const cipherText = payloadHex.substring(32) //payloadHex.substring(44)
        console.log("decryptContent(): cipherText: " + cipherText + '\n')


        // set an aesKey from that
        const aesKey = sharedKey
        const ivBuff = Buffer.from(iv, 'hex')
        const cipherBuff = Buffer.from(cipherText, 'hex')

        const dec = await eccryptoJS.aesCbcDecrypt(ivBuff, aesKey, cipherBuff);
        console.log("decryptContent(): success. typeof decrypted: " + typeof dec)
        console.log("decryptContent(): success. Here's the decrypted object (Buffer): ", dec)
        console.log("decryptContent(): success. Here's the decrypted object (Buffer): ", dec.toString('utf8'))

        decrypted = Buffer.from(dec, 'hex').toString('utf8')
        console.log("decryptContent(): success. Here's the .toString(): " + decrypted )
    } catch (err) {
        console.error("decryptContent(): error decrypting: ", err)
        console.error("Trouble AES-decrypting. WRONG PASSWORD or key? MODAL TO INFORM USER?")

        console.warn("decryptContent(): failure case: original cipherBlob: ", cipherBlob)
    }
    console.log("decryptContent(): decrypted ascii message: ", decrypted)

    return decrypted
}


function calcPenniesFromSats(sats, bsvPriceUSD) {
  let gpPriceEquiv

  const gpPriceInDollars = bsvPriceUSD * sats / 100000000

  if ( gpPriceInDollars < 1.0 ) {
      const gpPriceInPennies = bsvPriceUSD * sats / 1000000

      if ( gpPriceInPennies < 1.0 ) {
          const gpPriceInMilliPennies = bsvPriceUSD * sats / 1000

          if ( gpPriceInMilliPennies < 1.0 ) {
              gpPriceEquiv = <>less than a <b>milli</b>-penny (US)</>
          } else {
              gpPriceEquiv = <>currently approx {gpPriceInMilliPennies.toFixed(0)} <b>milli</b>-pennies (US)</>
          }
      } else if ( gpPriceInPennies < 100 ) {
          gpPriceEquiv = <>currently approx {gpPriceInPennies.toFixed(1)}¢ (US)</>
      }
  } else {
      gpPriceEquiv = <>currently approx ${gpPriceInDollars.toFixed(2)} (US)</>
  }

  return gpPriceEquiv
}
function calcFromMilliPennies(milliPennies, showVeryLittle = false) {
  let dollarEquiv

  if ( milliPennies < 100 ) {
      if ( showVeryLittle ) {
        return <>very little</>
      } else {
        return <></>
      }
  } else if ( milliPennies < 1000 ) {
      const cents = milliPennies / 1000
      return <>roughly {cents.toFixed(1)}¢</>
  } else if ( milliPennies < 100000 ) {
      const cents = milliPennies / 1000
      return <>roughly {cents.toFixed(1)}¢</>
  } else {
      const dollars = milliPennies / 100000
      return <>roughly ${dollars.toFixed(2)}</>
  }
}

//FIXME: ALSO in surfer
function satsFromMilliPennies(milliPennies, bsvPriceUSD) {
  //const milliPennies = bsvPriceUSD * sats / 1000    // 100 milllion sats in a BSV

  return milliPennies * 1000 / bsvPriceUSD
}

function milliPenniesFromSats(sats, bsvPriceUSD) {
  //const milliPennies = bsvPriceUSD * sats / 1000    // 100 milllion sats in a BSV

  return bsvPriceUSD * sats / 1000
}

export {
    reviewRenewalStatus,
    adjustRenewalStatus,
    getBitcoinDomainName,
    encryptMessage,
    decryptContent,

    calcFromMilliPennies,
    calcPenniesFromSats,
    satsFromMilliPennies,
    milliPenniesFromSats
};
