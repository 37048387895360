"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var getOption_1 = require("./getOption");
function getMouthType(options, random) {
    var mouthType = [];
    if (getOption_1.getOption('mouth', 'concerned', options)) {
        mouthType.push('Concerned');
    }
    if (getOption_1.getOption('mouth', 'default', options)) {
        mouthType.push('Default');
    }
    if (getOption_1.getOption('mouth', 'disbelief', options)) {
        mouthType.push('Disbelief');
    }
    if (getOption_1.getOption('mouth', 'eating', options)) {
        mouthType.push('Eating');
    }
    if (getOption_1.getOption('mouth', 'grimace', options)) {
        mouthType.push('Grimace');
    }
    if (getOption_1.getOption('mouth', 'sad', options)) {
        mouthType.push('Sad');
    }
    if (getOption_1.getOption('mouth', 'scream', options)) {
        mouthType.push('ScreamOpen');
    }
    if (getOption_1.getOption('mouth', 'serious', options)) {
        mouthType.push('Serious');
    }
    if (getOption_1.getOption('mouth', 'smile', options)) {
        mouthType.push('Smile');
    }
    if (getOption_1.getOption('mouth', 'tongue', options)) {
        mouthType.push('Tongue');
    }
    if (getOption_1.getOption('mouth', 'twinkle', options)) {
        mouthType.push('Twinkle');
    }
    if (getOption_1.getOption('mouth', 'vomit', options)) {
        mouthType.push('Vomit');
    }
    return random.pickone(mouthType);
}
exports.getMouthType = getMouthType;
