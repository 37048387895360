import React from 'react';

import { FFmpeg } from '@ffmpeg/ffmpeg';
import { fetchFile, toBlobURL } from '@ffmpeg/util';

import { Button, Checkbox, Divider, Form, Icon, Input, Modal, Popup, Radio, Table, TableBody, TableRow } from 'semantic-ui-react'

import { bshzColors }   from './bshzColors';

import {  milliPenniesFromSats, satsFromMilliPennies, calcPenniesFromSats, calcFromMilliPennies } from './commonReact';
import { TARGET_RATE } from './harnessConstants';
import VideoLoader from './videoLoader';
import VideoViewerModal from './videoViewerModal';

import { deduceMimeType } from './commonFuncs';

import {
	openDB,
	saveVideoInfo,
	getAllVideoInfos,
} from './buildShizzle.js';

// see this: https://www.sitepoint.com/mime-types-complete-list/
// but this one is HUGE: https://www.digipres.org/formats/mime-types/
// NOTE: it's probably not necessary to switch between <audio/> and <video/> when recording
//       audio-only vs a+v. It may have been helpful, though.
const audioOnlyMimeTypes = [
							'audio/mp3',  //not Brave
							'audio/wav',  //not Brave	// It would be wonderful if there were a universal RAW format
							'audio/wav codecs=pcm',
							'audio/x-ogg-pcm',
							'audio/x-oggpcm',
							'audio/x-ogg-flac',
							'audio/x-oggflac',
							'audio/x-aiff',
							'audio/32kadpcm',
							'audio/adpcm',
							'application/octet-stream',
							'audio/pcm',
							'audio/pcma',
							'audio/pcma-wb',
							'audio/pcmu',
							'audio/pcmu-wb',
							'audio/vorbis',
							'audio/x-wav',
							'audio/x-wav; codecs=pcm',  //made this up
							'audio/x-wav; version="1 generic"',
							'audio/x-wav; version="1 pcm encoding"',
							'audio/x-wav; version="2 pcm encoding"',
							'audio/mp4',  					//not Brave. SAFARI. but haven't tried it
							'audio/mp4;codecs=pcm',
							'audio/mp4;codecs=adpcm',
							'audio/mp4;codecs=opus',
							'audio/mp4;codecs=vorbis',
							'audio/mp4;codecs=octet-stream',
							'audio/mp4;codecs=pcma',
							'audio/mp4;codecs=pcma-wb',
							'audio/mp4;codecs=pcmu',
							'audio/mp4;codecs=pcmu-wb',

							'audio/mp4;codecs=avc1',			//SAFARI might like this  <---- try this  CAN RECORD on safari, play on BRAVE !!!!!!
							'audio/mp4;codecs=mp4a',			// safari supports for RECORDING. yet STRANGELY not for playback?

							'audio/x-matroska;codecs=avc1',


							'audio/basic',
							'audio/x-au',
							'audio/voc',
							'audio/midi',
							'audio/aac',
							'audio/mpeg3',
							'audio/mpeg',
							'audio/opus',
							'audio/ogg',
							'audio/webm',							// what if we only had to decipher the CONTAINER, but the data was RAW?
							'audio/3gpp',

							// 2 new tries
							'audio/webm;codecs=opus',				// <--- BRAVE supports this
							'audio/ogg;codecs=opus',

							'audio/webm;codecs=pcm',					//BRAVE can RECORD this
							'audio/webm;codecs=adpcm',
							'audio/webm;codecs=octet-stream',
							'audio/webm;codecs=vorbis',
							'audio/webm;codecs=pcma',
							'audio/webm;codecs=pcma-wb',
							'audio/webm;codecs=pcmu',
							'audio/webm;codecs=pcmu-wb',
							'audio/webm;codecs=mp4a',	// crazy idea
							'audio/webm;codecs=avc1',
							'audio/webm;codecs=h264',
							'audio/webm;codecs=aac',
						   ];
const mimeTypes = [	
					// AUDIO move to audioOnlyMimeTypes


					// VIDEO+audio
					'video/ogg; codecs=vp8,opus',         	// ?? is this real?

					'video/x-matroska; codecs=vp8,opus',
					'video/x-matroska;codecs=avc1',        	// Brave, android, chrome
					'video/webm;codecs=h264,vp9,opus',		// Brave, android, chrome

					//FIXME: explore this: https://www.file-recovery.com/mp4-signature-format.htm
					'video/mp4',  							// SAFARI. but haven't tried it

					'video/webm; codecs=vorbis,vp8', // NEW attempt: to convert safari mp4 to this <-----  !!
					'video/webm; codecs=vp8,opus',         	// firefox, Brave, android, chrome
					'video/webm; codecs=vp9,opus',         	// Brave, android, chrome
					'video/webm; codecs=h264',             	// Brave, android, chrome



					'video/mp4;codecs=h264',				// NO safari support

					'video/mp4;codecs=mp4a',				// safari supports for RECORDING. yet STRANGELY not for playback?
					'video/mp4;codecs=avc1',               	// Safari (BTW: avc1 is h264)
					'video/mp4;codecs=avc1,mp4a',


					'video/webm; codecs=avc1',				// Brave (maybe it's the same as with h264? TBD)
															// TRY to default ot this on chrome/brave. MAYBE safari will play it <---
					'video/webm; codecs=vp9,opus',         	// Brave, android, chrome
					'video/webm; codecs=vp9,vorbis',         	// Brave, android, chrome
					'video/webm; codecs=vp8,vorbis',
					'video/webm; codecs=h264,opus',
					'video/webm; codecs=h264,avc1',  // does this transcode to mp4 FASTER?
					'video/webm; codecs=avc1,opus',

					'video/webm; codecs=h264,adpcm',  //ATTEMPT to get MT-transcoding with audio AND video concurrently
					'video/webm; codecs=avc1,adpcm',
					'video/webm; codecs=h264,vorbis',
					'video/webm; codecs=avc1,vorbis',
					'video/webm; codecs=h264,pcm',		// acceptable to chrome
					'video/webm; codecs=avc1,pcm',	// <--- gets chosen by chrome
					'video/webm; codecs=h264,aac',

					'video/webm; codecs=h264,avc1,opus',  // does this transcode to mp4 FASTER?
					//'video/webm; codecs=vp9',   // android actually records a matroska avc1,opus
					'video/webm; codecs=av1,opus',
					'video/webm; codecs=vp8,aac',
					'video/webm; codecs=vp9,aac',
					//'video/x-matroska',
				];

//adapted from vRecorder.jsx
class VidRecorder extends React.PureComponent {
	constructor(props) {
		super(props)

		//FIXME: the purples are currently duplicated in index.css
		this.bshzPurple             = '#8313e2';
		this.bshzYellow             = "#fff300";
		this.bshzLtPurp             = '#e7d0fb';
		this.bshzLightYellow        = "#fffccc";

		this.timerId = null
		this.liveVideoFeed = React.createRef()
		this.mediaRecorder = React.createRef()

		// parent keeps a reference to ffmpeg if/when it's loaded from here
		let isFFMpegLoaded = false
		if ( this.props.ffMpeg === null ) {
			this.ff = new FFmpeg()
			console.warn("VidRecorder ctor: new ff: ", this.ff)
		} else {
			console.warn("vidRecorder got an ffmpeg prop")
			this.ff = this.props.ffMpeg
			isFFMpegLoaded  = true
		}
		// we scrape ffmpeg console for progress estimates
		this.messageRef = React.createRef()

		this.state = {

			supportedAudioMime: null,
			supportedAudioSuffix: '',
			supportedMime: null,
			supportedSuffix: '',

			hasBeenPublished: false,

			permission: true,
			showCameraSwirly: true,
			recordingMentionColor: 'lightGreen',
			recordingMentionBgColor: '#ff0000',

			videoStartStamp: Date.now(),
			recordingDuration: 0,

			captureVideo: true,	// else audio-only   FIXME: confusing name. means INCLUDE VIDEO
			avType: 3,  // 1: audio-only, 2: video-only, 3: a+v

			recordingStatus: "inactive",
			stream: null,

			videoPlayerRef: null,
			recordedVideoURL: null,
			recordedBlob: null,
			mimeType: '',

			videoChunks: [],
			vStream: null,
			aStream: null,

			videoPlayerRef2: null,
			transcodedVideoURL: null,
			transcodedVideoBlob: null,

			videoFromLoader: false,
			showVideoLoader: false,

			frameRate: 0,
			fpsVal: 12,
			showFrameRateSelector: false,

			publishInProgress:        false,
			showPubResult:            false,
			pubTxId:                  '',
			videoDescription:         '',
			peekAtVideo:              false,

			ffmpegLoaded: isFFMpegLoaded,
			showFFMpegLoader: false,
			showTranscoderToast: false,

			multiThreadedTranscoder:	true,

			videoDevices: [],
			chosenVideoDeviceId: 0,
			videoDeviceList: null,
			showVideoDeviceModal: false,
		}

		this.setRefV				= this.setRefV.bind(this);
		this.setRefV2				= this.setRefV2.bind(this);

		this.evaluateAudioOnlyMimeTypes = this.evaluateAudioOnlyMimeTypes.bind(this);

		this.closeStreams			= this.closeStreams.bind(this);
		this.getCameraPermission	= this.getCameraPermission.bind(this);
		this.startRecording			= this.startRecording.bind(this);
		this.stopRecording			= this.stopRecording.bind(this);

		this.adjustFrameRate		= this.adjustFrameRate.bind(this);
		this.handleFpsChange		= this.handleFpsChange.bind(this);
		this.doneAdjustingFrameRate = this.doneAdjustingFrameRate.bind(this);

		this.periodicTask			= this.periodicTask.bind(this);

		this.loadVideoFile			= this.loadVideoFile.bind(this);
		this.closeVideoLoader		= this.closeVideoLoader.bind(this);
		this.receiveLoadedVideo		= this.receiveLoadedVideo.bind(this);

		this.loadFFMpeg				= this.loadFFMpeg.bind(this);
		this.saveAndTranscode		= this.saveAndTranscode.bind(this);

		this.setAVType				= this.setAVType.bind(this);

		this.publishBundle			= this.publishBundle.bind(this);
		this.publishTranscode		= this.publishTranscode.bind(this);
		this.publishOriginal		= this.publishOriginal.bind(this);
		this.publish				= this.publish.bind(this);
		this.closePubInProgressModal= this.closePubInProgressModal.bind(this);

		this.peekAtVideoTx          = this.peekAtVideoTx.bind(this);
		this.closeVideoPeek         = this.closeVideoPeek.bind(this);

		this.handleDescriptionChange= this.handleDescriptionChange.bind(this);

		this.flipTranscoderFlavor	= this.flipTranscoderFlavor.bind(this);

		this.surveyDevices			= this.surveyDevices.bind(this);
		this.showCameraOptions		= this.showCameraOptions.bind(this);
		this.videoDeviceChosen		= this.videoDeviceChosen.bind(this);
		this.closeVideoDeviceModal	= this.closeVideoDeviceModal.bind(this);
	}

	// using a callback ref - since the reference doesn't exist until AFTER video is stopped,
	// and processed into a blob, then URL

	//FIXME: rename to setVideoPlaybackReference()
	setRefV (element) {
		console.warn("setRefV(): refV: ", element)
		console.warn("setRefV(): recordedVideoURL: ", this.state.recordedVideoURL)

		this.setState({videoPlayerRef: element}, () => {
				console.warn("state.recordedVideoURL:", this.state.recordedVideoURL)
				console.warn("state.videoPlayerRef:", this.state.videoPlayerRef)

				if ( this.state.videoPlayerRef !== null ) {
					console.warn("We're ABOUT to set the src for the state.videoPlayerRef")
					this.state.videoPlayerRef.src = this.state.recordedVideoURL

					console.log("we did it! We set player.src to " + this.state.recordedVideoURL)
				} else {
					// eventually we get around to it
					//console.error("TROUBLE? we don't yet have a reference to the video player, yet we need it")
				}
			})
	};
	setRefV2 (element) {
		console.warn("setRefV2(): refV: ", element)
		console.warn("setRefV2(): transcodedVideoURL: ", this.state.transcodedVideoURL)

		this.setState({videoPlayerRef2: element}, () => {
				console.warn("state.transcodedVideoURL:", this.state.transcodedVideoURL)
				console.warn("state.videoPlayerRef2:", this.state.videoPlayerRef2)

				if ( this.state.videoPlayerRef2 !== null ) {
					this.state.videoPlayerRef2.src = this.state.transcodedVideoURL

					console.log("we did it! We set player2.src to " + this.state.transcodedVideoURL)
				}
			})
	};

	evaluateAudioOnlyMimeTypes() {

		console.warn("VidRecorder evaluateAudioOnlyMimeTypes(): evaluating supported AUDIO-only mimeTypes...")

		let supported = null
		audioOnlyMimeTypes.forEach( mimeType => {
			let recorderSupport = false
			let playbackSupport = false

			if ( MediaRecorder.isTypeSupported(mimeType) ) {
				console.warn("AUDIO-only mime type " + mimeType + " is SUPPORTED for RECORDING   <------<<< (audio)")
				//supported = mimeType
				recorderSupport = true
			} else {
				//console.warn("AUDIO-only mime type " + mimeType + " is NOT supported for RECORDING XXXXX (audio)")
			}


			if ( MediaSource.isTypeSupported(mimeType)) {
				console.warn("AUDIO-only mime type is SUPPORTED for PLAYBACK:     " + mimeType + "   <========== !!! (audio)\n")
				playbackSupport = true
			} else {
				//console.warn("AUDIO-only mime type is NOT supported for PLAYBACK: " + mimeType + "     XXXX (audio)\n")
			}

			if ( recorderSupport && playbackSupport ) {
				supported = mimeType
				console.error("Since BOTH record and playback is supported, setting to use this for audio-only: " + supported + "  [[[[[--------<<<<< (audio)")
			}
		});

		if ( supported === null ) {
			alert("Hmm. Your browser doesn't seem to support a single AUDIO-only mimeType for capture AND playback")
			alert("FIXME: perhaps we should try audio capture-only? NEEDS WORK.")
		}

		let fileSuffix = ''
		if ( supported.includes('mp4') ) {
			fileSuffix = '.mp4'
		} else if ( supported.includes('webm') ) {
			fileSuffix = '.webm'
		} else {
			alert("IMPLEMENT: we need support for more AUDIO-only file types. Supported audio mime: " + supported)
		}

		console.warn("Will save AUDIO-only files with file type: " + fileSuffix)
		this.setState({	supportedAudioMime: supported,
						supportedAudioSuffix: fileSuffix
					  });

		console.log("----")
		console.log("----")
	}

	// record label, id of every video capture device
	// Will be used later if user wishes to change devices (front/back on phone)
	surveyDevices() {
		if ("MediaRecorder" in window) {
			try {
				navigator.mediaDevices.enumerateDevices()
						.then(devices => {
							console.log("1 Here are the devices: ", devices)
							let vDevs = []
							for ( let d of devices ) {    // of give the ELEMENT. in gives the index
								if ( d.kind === 'videoinput' ) {
									//alert("video input device: " + d.label)
									vDevs.push( {label: d.label, dId: d.deviceId, gId: d.groupId} )
								}
							}
							if ( vDevs.length < 1 ) {
								alert("surveyDevices() found no video input devices.")
								return
							}
							this.setState({videoDevices: vDevs})
							//const filtered = devices.filter(device => device.kind === type);
							//callback(filtered);
						});
			} catch (err) {
				alert("2: Error handling media device or streams: " + err.message);
			}
		} else {
			alert("The MediaRecorder API is not supported in your browser.");
		}
	}

	// generate (and display in a modal) a table of available video capture devices (their labels)
	// label the 'current' device as such
	showCameraOptions() {

		const deviceList = this.state.videoDevices.map((device) =>
			<Table.Row className="hoverLink" key={device.dId}
					onClick={       () => this.videoDeviceChosen(device.dId)}
					onContextMenu={ () => this.videoDeviceChosen(device.dId)}>

				<Table.Cell style={{padding:'8px 8px', border:'.1px solid'}}>
					{this.state.chosenVideoDeviceId === device.dId ? <><b>{device.label} &nbsp;(current)</b></> :  <>{device.label}</>}
				</Table.Cell>

			</Table.Row>
		)

		this.setState({showVideoDeviceModal: true, videoDeviceList: deviceList})

	}

	// switch to a device chosen/clicked from table
	videoDeviceChosen(deviceId) {
		console.error("chose camera with id " + deviceId)

		this.setState({chosenVideoDeviceId: deviceId, showVideoDeviceModal: false}, this.getCameraPermission)
	}

	// close video capture device selection table
	closeVideoDeviceModal() {
		this.setState({showVideoDeviceModal: false})
	}

	async componentDidMount() {
		// take inventory of available video capture devices
		// This actually runs asynchronously
		this.surveyDevices()

		alert("vidRecorder: playbackNotRequired: " + this.props.playbackNotRequired)

		this.evaluateAudioOnlyMimeTypes()

		console.warn("VidRecorder componentDidMount(): evaluating supported a+v mimeTypes...")

		let supported = null
		for ( const mimeType of mimeTypes ) {
			let recorderSupport = false
			let playbackSupport = false

			if ( MediaRecorder.isTypeSupported(mimeType) ) {
				console.warn("RECORDING of mime type  " + mimeType + "  is SUPPPORTED    <------<<< (a+v)")
				recorderSupport = true
				//supported = mimeType
			} else {
				//console.warn("RECORDING of mime type  " + mimeType + "  is NOT supported XXXX (a+v)")
			}


			if ( MediaSource.isTypeSupported(mimeType)) {
				playbackSupport = true

				console.warn("PLAYBACK  of mime type  " + mimeType + "  is SUPPORTED: <========== !!! (a+v)\n")
			} else {
				//console.warn("PLAYBACK  of mime type  " + mimeType + "  is NOT supported    XXXX (a+v)\n")
			}

			// chose the final ACCEPTABLE mimeType in the list of mimeTypes
			// property/option to NOT require playback support
			if ( recorderSupport && (playbackSupport || this.props.playbackNotRequired)
			) {
				supported = mimeType
				console.error("Since BOTH record and playback is supported, setting to use this for video: " + supported + "  [[[[[--------<<<<< (a+v)")
			}
		}

		if ( supported === null ) {
			alert("Hmm. Your browser doesn't seem to support a single audio/video mimeType for capture AND playback")
			alert("FIXME: perhaps we should try capture-only? NEEDS WORK.")
		}

		let fileSuffix = ''
		if ( supported.includes('mp4') ) {
			fileSuffix = '.mp4'
		} else if ( supported.includes('webm') ) {
			fileSuffix = '.webm'
		} else {
			alert("IMPLEMENT: we need support for more media file types. Supported audio/video mime: " + supported)
		}

		const publishedVids = await getAllVideoInfos(await openDB())
		console.warn("publishedVids array: ", publishedVids)

		console.warn("Will save video files with file type: " + fileSuffix)
		alert("BTW: Will capture with mimeType: " + supported)
		this.setState({	supportedMime: supported,
						supportedSuffix: fileSuffix,

						publishedVids: publishedVids
					  },
					  this.getCameraPermission
					);

		const me = this
		this.timerId = setInterval(async function() {
			me.periodicTask();
		}, 500);
	}

	componentWillUnmount() {
		if (this.timerId !== null ) {
			clearInterval(this.timerId)
		}
	}

	periodicTask() {
		const oldColor = this.state.recordingMentionColor
		const newColor = oldColor === 'lightGreen' ? 'red' : 'lightGreen'
		const backgroundColor = newColor === 'lightGreen' ? '#ff0000' : '#ffffff00'

		// only update duration while recording
		const recordingDuration = this.state.recordingStatus === "recording" ?
					Math.floor( (Date.now() - this.state.videoStartStamp) / 1000 )
				:
					this.state.recordingDuration

		//console.log("Actual recording duration: " + recordingDuration + " ms")
		//const durationSeconds = Math.round( recordingDuration / 1000 )
		//console.log("Close-enough duration: " + durationSeconds  + " seconds")

		this.setState({recordingMentionColor: newColor, recordingMentionBgColor: backgroundColor, recordingDuration: recordingDuration})
	}

	doneAdjustingFrameRate() {

		this.props.adjustFrameRateModal(this.props.parent, <></>)  // shuts little modal

		// re-start camera
		this.getCameraPermission()
	}

	handleFpsChange(event, val) {

		console.log("state.fpsVal is " + this.state.fpsVal)
		console.log("event: ", event)
		console.log("event.target.defaultValue: ", event.target.defaultValue)
		//console.log("val: ", val)
		console.log("val.value: ", val.value)
		console.warn("typeof is " + typeof val.value)


		this.setState({fpsVal: val.value}, this.adjustFrameRate) //event.target.defaultValue})
	}

	adjustFrameRate(e) {
		// ***  DEVICE-DEPENDENT STYLING  ***
		// For mobile, "detect" landscape screen mode
		const landscape = this.props.isMobile ? window.innerWidth > window.innerHeight : false
		// For mobile, shrink the About, Security, and Glossary modals
		const modalClassName   = this.props.isMobile ?
										(landscape ?
												"modalMobileLandscape"
											:
												"modalMobilePortrait"
										)
									:
										""
		const modalContentClassName = this.props.isMobile ? "modalContentMobile" : ""
		const modalBottomClassName  = this.props.isMobile ? "modalBottomMobile"  : ""

		const fpsVal = this.state.fpsVal
		const mod =
				<Modal dimmer='blurring' size='large' centered className={modalClassName}  open={true}
								style={{backgroundColor: this.bshzPurple, borderRadius: '20px', height: "auto"}}>

					<Modal.Header style={{textAlign: 'center', backgroundColor: this.bshzPurple, borderRadius: '20px'}}>
						<span style={{color: this.bshzYellow}}> Adjust Video Capture Quality </span>
					</Modal.Header>

					<Modal.Content className={modalContentClassName} scrolling style={{backgroundColor: this.bshzLtPurp}}>

					<p>
						Select the <b>minimum</b> frame rate you'd like to use (default: 12 frames/second)
					</p>
					<div>
					<Form>
						<Form.Group >
							<Form.Field control={Radio} label='12 fps' value={12} checked={fpsVal === 12} onChange={this.handleFpsChange}/>
							<Form.Field control={Radio} label='20 fps' value={20} checked={fpsVal === 20} onChange={this.handleFpsChange}/>
							<Form.Field control={Radio} label='30 fps' value={30} checked={fpsVal === 30} onChange={this.handleFpsChange}/>
						</Form.Group>
					</Form>
					</div>
					<br></br>

					<Button positive onClick={this.doneAdjustingFrameRate} content='DONE'/>

					</Modal.Content>

				</Modal>
		
		if ( this.state.vStream && this.state.vStream !== null ) {
			console.warn("CLOSING STREAM")
			this.closeStreams()
		}

		// pop-up the adjuster modal
		this.props.adjustFrameRateModal(this.props.parent, mod)
	}

	closeStreams(closeModal = false) {
		console.warn("stream: ", this.state.stream)
		console.warn("vStream: ", this.state.vStream)
		console.warn("vStream.length: ", this.state.vStream?.length)


			console.warn("vStream.getVideoTracks(): ", this.state.vStream?.getVideoTracks())
			console.warn("vStream.getVideoTracks()[0].readyState = ", this.state.vStream?.getVideoTracks()[0].readyState + " <--- 1 ")
			this.state.vStream?.getVideoTracks()[0].stop()
			//vStream.setTrack(null)

			console.warn("vStream.getVideoTracks(): ", this.state.vStream?.getVideoTracks())
			console.warn("vStream.getVideoTracks()[0].readyState = ", this.state.vStream?.getVideoTracks()[0].readyState + " <--- 2 ")

			this.state.aStream?.getAudioTracks()[0].stop()

		//liveVideoFeed.current.srcObject = null
		console.log("BTW: live: ", this.liveVideoFeed)
		console.log("BTW: live?.current: ", this.liveVideoFeed?.current)
		console.log("BTW: live?.current?.srcObject: ", this.liveVideoFeed?.current)

		if ( this.liveVideoFeed && this.liveVideoFeed.current && this.liveVideoFeed.current.srcObject ) {
			console.log("setting liveVideoFeed.current.srcObject to null - because we can")
			this.liveVideoFeed.current.srcObject = null
		}

		this.setState({stream: null, vStream: null, aStream: null, showCameraSwirly: true})

		if ( closeModal ) {
			// callback close videoRecorderModal (parent)
			this.props.closeIt()
		}
	}

	// FIXME: rename getCapturePermission()
	async getCameraPermission() {

		this.setState({recordedVideoURL: null, recordedBlob: null, mimeType: '', videoFromLoader: false,
						showCameraSwirly: true, recordingDuration: 0,
						hasBeenPublished: false,

						transcodedVideoURL: null,
						transcodedVideoBlob: null,
					})

		//get video and audio permissions and then stream the result media stream to the videoSrc variable
		if ("MediaRecorder" in window) {
			let step = 0
			try {
				navigator.mediaDevices.enumerateDevices()
						.then(devices => {
							console.log("2 Here are the devices: ", devices)
						});

				step = 1

				console.log("Here are the contraints: ", navigator.mediaDevices.getSupportedConstraints())

				const audioConstraints = { audio: true };

				// create audio and video streams separately
				const audioStream = await navigator.mediaDevices.getUserMedia(
					audioConstraints
				);

				step = 2

				let combinedStream
				if ( this.state.captureVideo ) {
					this.state.vStream?.getVideoTracks()[0].stop()

					step = 3

					// note: width, height doesn't seem to matter MUCH for capture

					const videoConstraints = this.state.chosenVideoDeviceId === 0 ?
								{
									audio: false,
									video: {
										width: {
											ideal: 320,
											max: 640
										},
										height: {
											ideal: 240,
											max: 480
										},
										frameRate: {
											ideal: this.state.fpsVal,
											min: this.state.fpsVal,
											max: 30
										}
									}
								}
							:
								{
									audio: false,
									video: {
										width: {
											ideal: 320,
											max: 640
										},
										height: {
											ideal: 240,
											max: 480
										},
										frameRate: {
											ideal: this.state.fpsVal,
											min: this.state.fpsVal,
											max: 30
										},
										deviceId: { exact: this.state.chosenVideoDeviceId  }
									}
								}

					const videoStream = await navigator.mediaDevices.getUserMedia(
						videoConstraints
					);

					step = 4

					let frameRate = 0
					let realWidth = 0
					let realHeight = 0
					videoStream.getTracks().forEach(function(track) {
						console.warn("BTW: video settings: ", track.getSettings());
						frameRate = track.getSettings().frameRate
						realWidth = track.getSettings().width
						realHeight = track.getSettings().height
					})

					step = 5

					if ( realWidth !== 320 || realHeight !== 240) {
						console.error("By the way: the width,height is actually " + realWidth + ", " + realHeight)
						//alert("By the way: the width, height is actually " + realWidth + ", " + realHeight)
					}

					if ( this.state.avType === 3 ) {
						//combine both audio and video streams
						combinedStream = new MediaStream([
							...videoStream.getVideoTracks(),
							...audioStream.getAudioTracks(),
						]);
					} else {
						combinedStream = videoStream
					}

					step = 6

					this.setState({permission: true, vStream: videoStream, aStream: audioStream, frameRate: frameRate})

					// set videostream to live feed player
					this.liveVideoFeed.current.srcObject = videoStream;

					step = 7
				} else {
					this.state.vStream?.getVideoTracks()[0].stop()     // error sometimes when code changed

					step = 8

					audioStream.getTracks().forEach(function(track) {
						console.warn("BTW: audio settings: ", track.getSettings());
					})

					step = 9

					combinedStream = audioStream //.getAudioTracks()

					step = 10

					this.setState({permission: true, vStream: null, aStream: audioStream})
				}

				this.setState({stream: combinedStream})

				// Stop showing swirly, and display the "start recording button"
				this.setState({showCameraSwirly: false})

			} catch (err) {
				console.error("ERROR while trying to get device permissions: ", err.message)
				alert("Error handling media device or streams (step " + step + "): " + err.message);
			}
		} else {
			alert("The MediaRecorder API is not supported in your browser.");
		}
	};

	async startRecording() {

		this.setState({recordingStatus: "recording"})
		const supportedMime = this.state.captureVideo ?
						this.state.supportedMime
					:
						this.state.supportedAudioMime

		if ( supportedMime === null ) {
			console.warn("Neither mimeType is supported: ",  mimeTypes)
			alert("We can't seem to find a suitable audio/video mimeType. Did you DENY a camera or microphone permission request?")
			this.setState({recordingStatus: "inactive"})
		} else {
			console.warn("startRecording will use supported mimeType of " + supportedMime)

			// We now use either audio-only, or a+v supported mimeType - depending on capture mode
			const media = new MediaRecorder(this.state.stream, { supportedMime });

			const mRecorder = this.mediaRecorder
			mRecorder.current = media

			let localVideoChunks = [];

			this.mediaRecorder.current.ondataavailable = (event) => {
				if (typeof event.data === "undefined") return;
				if (event.data.size === 0) return;

				localVideoChunks.push(event.data);
			};

			const startStamp = Date.now() //Math.floor(Date.now() / 1000)

			mRecorder.current.start();

			this.setState({videoChunks: localVideoChunks, videoStartStamp: startStamp})
		}
	};

	receiveLoadedVideo(videoUrl, videoBlob, mimeType='video/mp4') {
		console.log("receiveLoadedVideo:  videoURL: ", videoUrl)

		this.setState({	recordedVideoURL: videoUrl, recordedBlob: videoBlob, mimeType: mimeType,
						videoFromLoader: true,
						transcodedVideoURL: null, transcodedVideoBlob: null,
					}, this.closeVideoLoader)
	}

	stopRecording() {

		this.setState({permission: false, recordingStatus: "inactive"})
		const supportedMime = this.state.captureVideo ?
						this.state.supportedMime
					:
						this.state.supportedAudioMime

		this.mediaRecorder.current.onstop = async () => {

			console.warn("stopRecording: will use supportedMime of " + supportedMime)

			// embed the mime type in the blob ?????  <----- here? or later, in publishOriginal() ? In publishOriginal()  - when it calls parent
			const videoBlob = new Blob(this.state.videoChunks, { type: supportedMime });
			const videoUrl = URL.createObjectURL(videoBlob);

			let arrayView = new Uint8Array( await videoBlob.arrayBuffer() );
			const {mType, headerString} = deduceMimeType( arrayView )
			console.warn("vidRecorder:  DEDUCED recorded mimeType: ", mType)

	alert("DEDUCED mimeType: " + mType +
		  "\n\nUSING mimeType " + supportedMime )
	alert("headerString: " + headerString)

			console.warn("chunks had length of " + this.state.videoChunks.length)
			console.warn("blob has size of " + videoBlob.size)
			console.warn("url: " + videoUrl)

			console.log("Here's the recorded blob: ", videoBlob)
			console.log("Here's the recorded blob url: ", videoUrl)

			//refVideo?.current?.src=videoUrl

			this.setState({	recordedVideoURL: videoUrl, recordedBlob: videoBlob, mimeType: supportedMime,
							transcodedVideoURL: null, transcodedVideoBlob: null,
							videoFromLoader: false })

			//REVERTED to original. Duplicate in close
			this.state.vStream?.getVideoTracks()[0].stop()
		};

		this.mediaRecorder.current.stop();
	};

	// activate/display the videoLoader modal
	// which gives the user the opportunity to load a recording from file
	loadVideoFile() {

		// clear-away state before getting a video
		this.setState({	recordedVideoURL: null,
						recordedBlob: null,
						mimeType: '',

						transcodedVideoURL: null,
						transcodedVideoBlob: null,

						videoFromLoader: false, // for NOW. once loaded, flip
						//showCameraSwirly: true,
						recordingDuration: 0,

						permission: false,
						recordingStatus: "inactive",

						showVideoLoader: true
					 })
	}
	closeVideoLoader() {
		console.log("vidRecorder: about to close video loader")
		this.setState({showVideoLoader: false, showCameraSwirly: false})
	}

	// switch between single- and multi-threaded version of ffmpeg (BEFORE dowloading)
	flipTranscoderFlavor() {
		this.setState({multiThreadedTranscoder: !this.state.multiThreadedTranscoder})
	}

	// dynamically download ffmpeg tool from public folder
	async loadFFMpeg() {
		// let this setState() ripple so that messageRef definitely has a value
		this.setState({showFFMpegLoader: true}, async function () {

			//console.warn("VidRecorder loadFFMpeg: ff: ", this.ff)
			//console.warn("VidRecorder loadFFMpeg: ffmpegR: ", this.ffmpegR)

			//const ffmpeg = this.ffmpegR.current;
			//console.warn("loadFFMpeg: ffmpeg: ", ffmpeg)

			const baseURL = ''//'https://unpkg.com/@ffmpeg/core@0.12.6/dist/umd'
			//const baseURL = 'https://unpkg.com/@ffmpeg/core-mt@0.12.6/dist/umd'  // multi-threaded versions

			const messageRef = this.messageRef
			this.ff.on('log', ({ message }) => {
				//this.messageRef.current.innerHTML = message;
				console.log("FF Mesg: ", message);

				if ( message !== null && message.includes('time=') ) {
					const idx = message.indexOf("time=")
					if ( message.length >= idx+16 ) {
						// scrape the timestamp from the log
						const sub = message.substring(idx+5, idx+16)
						console.error("FFMPEG TIME: ", sub)
						if ( messageRef !== null && messageRef.current !== null ) {
							messageRef.current.innerHTML = sub
						}
					} else {
						console.error("   WHOOPS: check our substring logic\n" + message)
					}
				}
			});

			if ( this.state.multiThreadedTranscoder ) {
				// toBlobURL is used to bypass CORS issue, urls with the same
				// domain can be used directly.
				await this.ff.load({ //ffmpeg.load({
				/**
					coreURL: await toBlobURL(`${baseURL}/ffmpeg-core.js`, 'text/javascript'),
					wasmURL: await toBlobURL(`${baseURL}/ffmpeg-core.wasm`, 'application/wasm'),
					//workerURL: await toBlobURL(`${baseURL}/ffmpeg-core.worker.js`, 'text/javascript'),
				/*/
					//NOTE: these were taken from the multi-threaded base dir (see above)
					coreURL: `${baseURL}/ffmpeg-core-mt.js`,
					wasmURL: `${baseURL}/ffmpeg-core-mt.wasm`,
					workerURL: `${baseURL}/ffmpeg-core.worker.js`,
				/**/
				});
			} else {
				// toBlobURL is used to bypass CORS issue, urls with the same
				// domain can be used directly.
				await this.ff.load({ //ffmpeg.load({
					/**/
						coreURL: `${baseURL}/ffmpeg-core.js`,
						wasmURL: `${baseURL}/ffmpeg-core.wasm`,
						//workerURL: await toBlobURL(`${baseURL}/ffmpeg-core.worker.js`, 'text/javascript'),
					/*/
						//NOTE: these were taken from the multi-threaded base dir (see above)
						coreURL: `${baseURL}/ffmpeg-core-mt.js`,
						wasmURL: `${baseURL}/ffmpeg-core-mt.wasm`,
						workerURL: `${baseURL}/ffmpeg-core.worker.js`,
					/**/
					});
			}

			console.log("ffmpeg loaded")

			// pass ffmpeg up to parent (so we don't have to keep loading it)
			this.props.receiveFFMpegRef(this.ff)

			this.setState({ffmpegLoaded: true, showFFMpegLoader: false})
		})
	}

	// transcode file to mp4 format
	// NOTE: because of imperfections, we do this in multiple steps:
	//       - strip audio, transcode to video-only mp4
	//       - strop video, transcode to audio-only mp4
	//       - combine two mp4s into single a+v mp4
	// FUTURE: transcode from mp4 to webm. Others too?
	async saveAndTranscode() {
		this.setState({showTranscoderToast: true})
		console.warn("videoUrl: ", this.state.recordedVideoURL)

		// SEE THIS: https://ffmpegwasm.netlify.app/docs/api/util/#fetchfile
		await this.ff.writeFile('input.webm', await fetchFile(this.state.recordedVideoURL))
		console.error("WROTE video FILE input.webm to ffmpeg memory")

		await this.ff.exec(['-i', 'input.webm', '-an', 'video.mp4']);
alert('done stripping audio')

		await this.ff.exec(['-i', 'input.webm', '-vn', 'audio.mp4']);
alert('done stripping video. now try combining...')

		await this.ff.exec(['-i', 'video.mp4', '-i', 'audio.mp4', '-c', 'copy', 'output.mp4']);
		console.error("TRANSCODED input FILE - to output.mp4")

		const data = await this.ff.readFile('output.mp4');

		const transcodedVideoBlob = new Blob([data.buffer], { type: 'video/mp4'})

		const transcodedVideoURL = URL.createObjectURL( transcodedVideoBlob );

		console.error("TRANSCODED URL: ", transcodedVideoURL)
		this.setState({	transcodedVideoURL: transcodedVideoURL,
						transcodedVideoBlob: transcodedVideoBlob,
						showTranscoderToast: false
					})
	}

	// allow switching capture mode between audio-only, video-only, and a+v
	setAVType(event, val) {
		console.error("setAVType: event:", event)
		console.error("setAVType: value:", val)
		this.setState({captureVideo: val.value===2 || val.value===3, avType: val.value}, this.getCameraPermission)
	}

	// save recording description to IDB. Close publishing-success modal
	async closePubInProgressModal() {
		if ( this.state.pubTxId.length > 0 ) {
			console.log("saving txId " + this.state.pubTxId + " with description: " + this.state.videoDescription)

			// save the video txId, and a description, to IDB
			await saveVideoInfo(await openDB(), this.state.pubTxId, this.state.videoDescription)

			alert("We've saved your recording identifier, and description, to your browser database")
		}

		this.setState({publishInProgress: false, showPubResult: false})
	}

	// publish JUST the transcoded/mp4 recording
	async publishTranscode(event) {
		await this.publish(event,
			this.state.transcodedVideoBlob, "video/mp4") // OPTIONAL second blob (if not null)
	}
	// publish original recording AND transcoded/mp4 recording
	async publishBundle(event) {
		const supportedMime =
						this.state.videoFromLoader ?
								this.state.mimeType
							:
								this.state.captureVideo ?
											this.state.supportedMime
										:
											this.state.supportedAudioMime
		await this.publish(event,
			this.state.recordedBlob, supportedMime,      // HERE is where we EMBED the mime type
			this.state.transcodedVideoBlob, "video/mp4") // OPTIONAL second blob (if not null)
	}

	// publish original (non-mp4) recording
	async publishOriginal(event) {
		const supportedMime =
						this.state.videoFromLoader ?
								this.state.mimeType
							:
								this.state.captureVideo ?
											this.state.supportedMime
										:
											this.state.supportedAudioMime
		await this.publish(event, this.state.recordedBlob, supportedMime)
	}

	// build and broadcast a recording tx
	async publish(event, blobA, mimeTypeA, blobB = null, mimeTypeB = null) {
		console.warn("vidRecorder  publish()")

if ( blobB === null ) {
	alert("Will publish single recording, with mimeType of " + mimeTypeA)
} else {
	if ( mimeTypeB !== "video/mp4" ) {
		alert("CODE ERROR? mimeTypeB is '" + mimeTypeB + "', but expected 'video/mp4'")
	} else {
		alert("Will publish BUNDLE of recordings, with mimeTypes of " + mimeTypeA + ", and " + mimeTypeB)
	}
}

		this.setState({ publishInProgress: true, pubTxId: '', showPubResult: false, videoDescription: '' },
				async () => {
					const result = await this.props.postVidBlob(event,
																blobA, mimeTypeA,      // HERE is where we EMBED the mime type
																blobB, "video/mp4") // OPTIONAL second blob (if not null)
					//const result = {success: true, txId: 'cce08603ae64806a624e2673388cf3e40a4aff571fc5a14a4aab3cb92059e7fc'}
					//const result = {success: false, txId: ''}

					console.warn("a/v publish result: " + result.success + "  txId: " + result.txId)

					if ( result.success ) {
						this.setState({hasBeenPublished: true,  showPubResult: true, pubTxId: result.txId})

					} else {
						this.setState({hasBeenPublished: false, showPubResult: true, pubTxId: ''})
					}

				}
		)

	}

	// open modal allowing viewing a published recording (tx)
	peekAtVideoTx() {
		this.setState({peekAtVideo: true})
	}
	closeVideoPeek() {
		this.setState({peekAtVideo: false})
	}

	// allow user to set a description to a recording that was just-now published
	handleDescriptionChange(event, data) {
		const v = data.value

		console.log("hD(): v: " + v)
		console.log("hD(): e.t.v: " + event.target.value)

		// regex check for valid 'domain' name pattern
		//const re = /^[ a-zA-Z0-9-.,]+$/;
		//const re = /[\t\r\n]|(--[^\r\n]*)|(\/\*[\w\W]*?(?=\*)\*\/)/gi

		//if ( v !== '' && (!re.test(v) || v.length > 60 ) ) {//this.maxDomainLength
		if ( v.length > 60 ) {

			console.warn("handleDescriptionChange(): Ignoring value")
			return
		}

		this.setState({videoDescription: event.target.value})

	}

	// original: <video className="recorded" src={this.state.recordedVideo} controls></video>
	render() {
		// ***  DEVICE-DEPENDENT STYLING  ***
		// For mobile, "detect" landscape screen mode
		const landscape = this.props.isMobile ? window.innerWidth > window.innerHeight : false
		// For mobile, shrink the About, Security, and Glossary modals
		const modalClassName   = this.props.isMobile ?
										(landscape ?
												"modalMobileLandscape"
											:
												"modalMobilePortrait"
										)
									:
										""
		const modalContentClassName = this.props.isMobile ? "modalContentMobile" : ""
		const modalBottomClassName  = this.props.isMobile ? "modalBottomMobile"  : ""


		const fileSuffix = this.state.captureVideo ? this.state.supportedSuffix : this.state.supportedAudioSuffix
		const downloadName = this.state.captureVideo ?
					"Give this recording a better name" + this.state.supportedSuffix
				:
					"Give this AUDIO-only file a better name" + this.state.supportedAudioSuffix

		const suitableFormat = this.state.mimeType.includes('mp4') || this.state.mimeType.includes('MP4')

		//style={{textAlign: 'center'}}
		const maybeCameraSwirly = this.state.showCameraSwirly ?
						<div >
							<br></br>
							<br></br>
							&nbsp; &nbsp; &nbsp; <Icon size='huge'  loading name='spinner' />
						</div>
					:
						<></>

		const disableSuccessOkButton = this.state.pubTxId.length > 0 && this.state.videoDescription.length < 3

		// icon size:    large, huge, massive
		//      name: spinner, circle notch, sync, cog, asterisk
		const maybePublishingSwirly =
					<Modal dimmer='blurring' size='small' centered className={modalClassName}  open={this.state.publishInProgress}
							style={{backgroundColor: this.bshzPurple, borderRadius: '20px', height: "auto"}}>
						<Modal.Content className={modalContentClassName} scrolling style={{backgroundColor: this.bshzLtPurp}}>

							<div style={{textAlign: 'center'}}>
								{!this.state.showPubResult ?
									<>
										<br></br>
										Please wait while we publish this recording to the blockchain...
										<br></br>
										<br></br>
										<Icon size='huge'  loading name='circle notch' color='purple'/>
										<br></br>
										<br></br>
									</>
									:
									<>
										<br></br>
										Publishing attempt complete:&nbsp;
										{this.state.pubTxId.length > 0 ?
											<>
												<b style={{color:'blue'}}> SUCCESS ! </b>
												<br></br>
												<br></br>
												Your recording was saved in this transaction:
												<br></br>
												<span style={{color:'blue'}}>{this.state.pubTxId}</span>
												<br></br>
												<br></br>
												Please enter a description to help you find and reference it later.
												<br></br>

												<Input value={this.state.videoDescription}
														style={{width:'400px'}}
														placeholder='Enter a short description of your recording'
														onChange={this.handleDescriptionChange} autoFocus>
													<input style={{borderRadius: '50px'}} />
												</Input>
											</>
												:
											<>
												<b style={{color:'red'}}> FAILED </b>
											</>
										}
										<br></br>
										<br></br>
										<Button disabled={disableSuccessOkButton}
												positive={this.state.pubTxId.length > 0}
												negative={this.state.pubTxId.length === 0}
												onClick={this.closePubInProgressModal}>
											OK
										</Button>
									</>
								}
							</div>

						</Modal.Content>
					</Modal>


		const recordingDurationMention =
						<>
							<b>Duration: </b> 
							<span style={{color:'blue'}}>{this.state.recordingDuration} seconds</span>
						</>

		const vidBorderColor = this.state.recordingStatus === "recording" ? 'lightGreen' : 'yellow'
		//const vidBorderColor = this.state.recordingStatus === "recording" ? this.state.recordingMentionColor : 'yellow'
		const cameraStatusMention = this.state.recordingStatus === "recording" ?
									<h2>
										<span style={{color:this.state.recordingMentionColor, backgroundColor: this.state.recordingMentionBgColor}}>
											NOW RECORDING
										</span>
									</h2>
								:
									<h4>
										Use this to make recordings which you can then publish to the blockchain.
									</h4>

		const recordStartButtonGrey = this.state.recordingStatus === "recording" || this.state.showCameraSwirly
		const recordStopButtonGrey = this.state.recordingStatus !== "recording"
		const frameRateButtonGrey = this.state.recordingStatus === "recording" || this.state.showCameraSwirly


		const approxSats = this.state.recordedBlob === null ? 0 : Math.round(  this.state.recordedBlob.size * TARGET_RATE ) + 22
		const approxMilliPennies = this.props.bsvPriceUSD * approxSats / 1000
		const approxCurrency = calcFromMilliPennies( approxMilliPennies, true )
		const approxFee = this.state.recordedBlob === null ?
							<></>
						:
							<>
								<br></br>
								It would cost roughly {approxSats} sats ({approxCurrency}) to publish this recording. The miner fee rate is {Math.round(100000.0 * TARGET_RATE)/100} sats/kb.
							</>


		// VERY ROUGH approximation for CURRENT capture settings:
		const approxBytesPerMinAt12fps = 12516310		// 12 fps  320x240
		const approxBytesPerMinAt20fps = 17092796		// 20 fps  320x240
		const approxBytesPerMinAt30fps = 19966886		// 30 fps  320x240

		const fps = this.state.frameRate

		const bytePerMin =  fps === 12 ? approxBytesPerMinAt12fps
						: ( fps === 20 ? approxBytesPerMinAt20fps
									   : approxBytesPerMinAt30fps
						  )

		const approxSatsPerMin = bytePerMin * TARGET_RATE
		const approxMilliPenniesPer10Min = this.props.bsvPriceUSD * approxSatsPerMin / 100
		const approxCurrencyPer10Min = calcFromMilliPennies( approxMilliPenniesPer10Min )

		const maybeVideoLoader = this.state.showVideoLoader ?
					<>
						<VideoLoader 	closeLoader={this.closeVideoLoader}
										returnVideo={this.receiveLoadedVideo}
										/>
					</>
				:
					<></>

		const doneButtonLabel = <>
									DONE<br></br>(unpublished, unsaved recordings will be lost)
								</>

		const captureVideo = this.state.captureVideo

		const showDummyVidBox = captureVideo ?
						<>
						</>
					:
						<div style={{width:'400px', height:'300px', border:'1px solid black', margin:'0', padding:'0', textAlign:'center'}}>
							<br></br>
							<br></br>
							<br></br>
							Only capturing AUDIO
							<br></br>
							<br></br>
							<br></br>
							<br></br>
							<Icon name='microphone' size='massive' color='black'/>
						</div>
		const displayVideoBlockOrNone = captureVideo ? "block" : "none"

		const maybeFrameRateButton = this.state.captureVideo ?
					<>
						<Button style={{color:'purple'}} disabled={frameRateButtonGrey} onClick={this.adjustFrameRate} type="button">
							Adjust FrameRate
						</Button>
					</>
				:
					<></>
		const maybeChangeCameraButton = this.state.videoDevices.length > 1 ?
					<>
						&nbsp;
						<Button style={{color:'yellow', backgroundColor:'blue'}} onClick={this.showCameraOptions} type="button">
							Change Camera
						</Button>
					</>
				:
					null

		const costMention = this.state.captureVideo ?
					<>
						At {this.state.frameRate} fps (320x240) it will cost <b>{approxCurrencyPer10Min}</b> to publish 10 minutes of video.
						<br></br>
						Your mileage may vary.
					</>
				:
					<>
						The cost to publish audio-only recordings is a fraction of that for video.
						<br></br>
						&nbsp;
					</>


		const maybePeekAtVideoTx = this.state.peekAtVideo ?
						<>
							<VideoViewerModal  closeIt={this.closeVideoPeek}/>
						</>
					:
						<></>

		// icon size:    large, huge, massive
		//      name: spinner, circle notch, sync, cog, asterisk
		const maybeShowFFMpegLoader = this.state.showFFMpegLoader ?
					<Modal dimmer='blurring' size='small' centered className={modalClassName}  open={true}
							style={{backgroundColor: this.bshzPurple, borderRadius: '20px', height: "auto"}}>
						<Modal.Content className={modalContentClassName} scrolling style={{backgroundColor: this.bshzLtPurp}}>

							<div style={{textAlign: 'center'}}>

										<br></br>
										Please wait while we load the FFMpeg tool...
										<br></br>
										<br></br>
										<Icon size='huge'  loading name='circle notch' color='purple'/>
										<br></br>
										<br></br>
							</div>
						</Modal.Content>
					</Modal>
				:
					null

		// icon sizes: mini tiny small large big huge massive
		const ffmpegExplainer = <Icon  size='large' style={{color: bshzColors.purple}} name='info circle' />
		const popUpFFMpegExplainerText = <>
										Unfortunately, different people have different browsers, and
										not all browsers support the same audio/video format. This means
										some people can't play your recording.
										<br></br><br></br>
										You can use the transcoder tool to convert your recording to
										the <b>MP4</b> format - so that <b>more</b> people will be able
										to play your recording.
									</>
		//NOTE: we avoid fuzzy popup with the popper hack
		const popupFFMpegExplainer = <Popup style={{backgroundColor: bshzColors.ltYellow}}
											trigger={ffmpegExplainer} wide="very"
											content={popUpFFMpegExplainerText}
											popper={<div style={{ filter: 'none' }}></div>}
											position="top center"
											hideOnScroll/>
		const multiThreadedTranscoder = this.state.multiThreadedTranscoder
		const transcoderFlavorCheckbox =
				<>
					<Form>
						<Form.Field>
							Transcoder Type:
						</Form.Field>
						<Form.Field>
							<Checkbox
							radio
							label='Single-Threaded'
							checked={!multiThreadedTranscoder}
							onChange={this.flipTranscoderFlavor}
							/>
						</Form.Field>
						<Form.Field>
							<Checkbox
							radio
							label='Multi-Threaded (recommended)'
							checked={multiThreadedTranscoder}
							onChange={this.flipTranscoderFlavor}
							/>
						</Form.Field>
					</Form>
				</>
		// icon sizes: mini tiny small large big huge massive
		const transcodeExplainer = <Icon  size='large' style={{color: bshzColors.purple}} name='question circle outline' />
		const popUpTranscodeExplainerText = <>
										Use the transcoder tool to convert your recording to
										the <b>MP4</b> format - so that <b>more</b> people will be able
										to play your recording.
										<br></br><br></br>
										It's an annoying extra step, but for right now it's the only
										option. For example, on a laptop, it might take 6 minutes to transcode
										a 1-minute video. On a cell phone, however, it would take <b>much</b> longer.
										<br></br><br></br>
										If <b style={{color:'blue'}}>you</b> see a better way, the ShizzleVerse may value your ideas.
									</>
		//NOTE: we avoid fuzzy popup with the popper hack
		const popupTranscodeExplainer = <Popup style={{backgroundColor: bshzColors.ltYellow}}
											trigger={transcodeExplainer} wide="very"
											content={popUpTranscodeExplainerText}
											popper={<div style={{ filter: 'none' }}></div>}
											position="top center"
											hideOnScroll/>
		const ffmpegButton = suitableFormat ?
					<></>
				:
					!this.state.ffmpegLoaded ?
								<>
									<Button positive content='Load transcoder' style={{paddingLeft:'10px', paddingRight:'10px'}}
											onClick={this.loadFFMpeg}>
									</Button>&nbsp;{popupFFMpegExplainer}
									&nbsp; {transcoderFlavorCheckbox}
								</>
							:
								this.state.transcodedVideoURL === null ?
											<>
												<Button positive style={{paddingLeft:'8px', paddingRight:'8px'}} content='.'
														disabled>
												</Button>
												&nbsp;
												<Button positive style={{paddingLeft:'10px', paddingRight:'10px'}}
														content='Transcode recording'
														onClick={this.saveAndTranscode}>
												</Button>&nbsp;{popupTranscodeExplainer}
											</>
										:
											<>
												<Button positive style={{paddingLeft:'8px', paddingRight:'8px'}} content='.'
														disabled>
												</Button>
												&nbsp;
												<Button positive style={{paddingLeft:'8px', paddingRight:'8px'}} content='.'
														disabled>
												</Button>
												&nbsp;
											</>

		const transcoderMessageDiv = <p ref={this.messageRef}></p>
		// icon size:    large, huge, massive
		//      name: spinner, circle notch, sync, cog, asterisk
		const maybeShowTranscoderToast = this.state.showTranscoderToast ?
					<Modal dimmer='blurring' size='small' centered className={modalClassName}  open={true}
							style={{backgroundColor: this.bshzPurple, borderRadius: '20px', height: "auto"}}>
						<Modal.Content className={modalContentClassName} scrolling style={{backgroundColor: this.bshzLtPurp}}>

							<div style={{textAlign: 'center'}}>

										<br></br>
										Please wait while we convert your recording...
										<br></br>
										<br></br>
										<Icon size='huge'  loading name='circle notch' color='purple'/>
										<br></br>
										<br></br>
										Transcoded through time: <b style={{color:'blue'}}>{transcoderMessageDiv}</b>
										<br></br>
							</div>
						</Modal.Content>
					</Modal>
				:
					null

		const simplePublishLabel = this.state.transcodedVideoURL === null ? 'Publish recording' : 'Publish original'
		// if not an MP4, tarnish the simplest publish button
		const simplePublishButton = suitableFormat ?
					<Button positive content='Publish recording'
							disabled={this.state.hasBeenPublished}
							onClick={this.publishOriginal} />
				:
					<Button style={{backgroundColor:'white', color:'red', paddingLeft:'10px', paddingRight:'10px'}} content={simplePublishLabel}
							disabled={this.state.hasBeenPublished}
							onClick={this.publishOriginal} />
		// icon sizes: mini tiny small large big huge massive
		const bundleExplainer = <Icon  size='large' style={{color: bshzColors.purple}} name='question' />
		const popUpBundleExplainerText = <>
										Publishing the original recording will mean some people
										won't be able to play your recording.
										<br></br><br></br>
										Publishing the transcoded video allows more people
										to enjoy your recording.
										<br></br><br></br>
										If <b style={{color:'red'}}>you</b> can't play the <b style={{color:'green'}}>transcoded</b> video, that means
										things are <b>worse</b> than we thought. By <b style={{color:'blue'}}>bundling both</b> recording
										formats into the same transaction, we cover our bases, and allow a browser to select from
										the two formats - increasing the chance that more people can play your recording.
										<br></br><br></br>
										Note that bundling multiple formats into one transaction
										is <b style={{color:'red'}}>more expensive</b> - since the transaction holds more data.
									</>
		//NOTE: we avoid fuzzy popup with the popper hack
		const popupBundleExplainer = <Popup style={{backgroundColor: bshzColors.ltYellow}}
											trigger={bundleExplainer} wide="very"
											content={popUpBundleExplainerText}
											popper={<div style={{ filter: 'none' }}></div>}
											position="bottom center"
											hideOnScroll/>
		const publishTranscodedButton = this.state.transcodedVideoURL ?
				<>
					<Button style={{backgroundColor:'orange', paddingLeft:'10px', paddingRight:'10px'}} content='Publish transcoded'
							disabled={this.state.hasBeenPublished}
							onClick={this.publishTranscode}
							>
					</Button>
					&nbsp;
					<Button positive content='Publish BOTH'
							style={{paddingLeft:'10px', paddingRight:'10px'}}
							disabled={this.state.hasBeenPublished}
							onClick={this.publishBundle}
							>
					</Button>&nbsp;{popupBundleExplainer}
					<br></br>
					<Table collapsing style={{border:'4px solid blue', backgroundColor:'white'}}>
						<TableBody>
							<TableRow>
								<Table.Cell collapsing style={{border:'0px', margin: '0px', padding:'0px'}}>
									<video ref={this.setRefV2} controls width='400' height='300' style={{ margin: '0px', padding:'0px'}} />
								</Table.Cell>
								<Table.Cell collapsing style={{border:'0px', margin: '0px', padding:'0px'}}>
									&nbsp; Transcoded &nbsp;<br></br>&nbsp; MP4 &nbsp;
								</Table.Cell>
							</TableRow>
						</TableBody>
					</Table>
				</>
			:
				null

		const maybeFFMpegOption = suitableFormat ?
					<div style={{color:'blue'}}>
						<b>We believe the format of this recording (MP4) is accepted by many browsers and devices.</b>
					</div>
				:
					this.state.transcodedVideoURL === null ?
								<>
									<div style={{color:'blue'}}>
										<b>The format of this recording is <b style={{color:'red'}}>not</b> universally
										accepted by all browsers and devices.</b> You may want to <b style={{color:'green'}}>transcode</b> the
										recording into a more-suitable format.
									</div>
								</>
							:
								<>
									<div style={{color:'blue'}}>
										The format of the <b>original</b> recording is <b style={{color:'red'}}>not</b> universally
										accepted by all browsers and devices. You may want to publish
										the <b style={{color:'green'}}>transcoded</b> recording - which
										is in a more-suitable format.
									</div>
								</>
		const maybeMicrophoneSlash = this.state.avType === 2 ?    // just video
					<>
						&nbsp; &nbsp;
						<Icon name='video camera' size='big' color='black'/> &nbsp;
						<Icon name='microphone slash' size='big' color='red'/>
					</>
				:
					this.state.avType === 3 ?
							<>
								&nbsp; &nbsp;
								<Icon name='video camera' size='big' color='black'/> &nbsp;
								<Icon name='microphone' size='big' color='black'/>
							</>
						:
							<>
								&nbsp; &nbsp; &nbsp; &nbsp; <Icon name='microphone' size='big' color='black'/>
							</>

		const maybeVideoDeviceModal = this.state.showVideoDeviceModal ?
					<Modal dimmer='blurring' size='small' centered className={modalClassName}  open={true}
							style={{backgroundColor: this.bshzPurple, borderRadius: '20px', height: "auto"}}>
						<Modal.Content className={modalContentClassName} scrolling style={{backgroundColor: this.bshzLtPurp}}>

							<Table unstackable celled style={{borderSpacing:"0px", border:'none', backgroundColor:"#00000000"}} >
								<Table.Header>
									<Table.Row key={1}>
										<Table.HeaderCell style={{backgroundColor:'blue', color:'white', padding:'8px 8px'}}>
											Camera Name
										</Table.HeaderCell>
									</Table.Row>
								</Table.Header>
								<Table.Body>
									{this.state.videoDeviceList}
								</Table.Body>
							</Table>
							<div style={{textAlign: 'center'}}>
								<Button positive onClick={this.closeVideoDeviceModal} content='BACK'/>
							</div>

						</Modal.Content>
					</Modal>
				:
					null

		return (
			<>
			<div>
				<div className="video-player">
					{!this.state.recordedVideoURL ?
							(
								<>
									{cameraStatusMention}

									<span style={{color:'blue'}}>
										{costMention}
									</span>
									<br></br>

									<div style={{border: '8px solid black',borderColor:vidBorderColor,display: 'inline-block'}} >

										<Table collapsing  style={{color:'#ffffff'}} >
											<TableBody >
												<TableRow >
													<Table.Cell >
														{maybeCameraSwirly}
														<video 	width='400' height='300'
																ref={this.liveVideoFeed}
																style={{display: displayVideoBlockOrNone}}
																autoPlay className="live-player">
														</video>
														{showDummyVidBox}
													</Table.Cell>
													<Table.Cell >
														<Form>
															<Form.Field>
																A/V Capture:
															</Form.Field>
															<Form.Field>
																<Checkbox
																radio
																label='Just Audio'
																value={1}
																checked={this.state.avType === 1}
																onChange={this.setAVType}
																/>
															</Form.Field>
															<Form.Field>
																<Checkbox
																radio
																label='Just Video'
																value={2}
																checked={this.state.avType === 2}
																onChange={this.setAVType}
																/>
															</Form.Field>
															<Form.Field>
																<Checkbox
																radio
																label='Audio & Video'
																value={3}
																checked={this.state.avType === 3}
																onChange={this.setAVType}
																/>
															</Form.Field>
															<Form.Field>
																<br></br>
																{maybeMicrophoneSlash}
															</Form.Field>
														</Form>
													</Table.Cell>
												</TableRow>
											</TableBody>
										</Table>

									</div>

									<div className="video-controls">
											<Button positive disabled={recordStartButtonGrey} onClick={this.startRecording} type="button">
												Start Recording
											</Button>

											<Button negative disabled={recordStopButtonGrey} onClick={this.stopRecording} type="button">
												Stop Recording
											</Button>

											{maybeFrameRateButton}
											{maybeChangeCameraButton}
									</div>
									<br></br>
									<Button disabled={this.state.recordingStatus==='recording'}
											style={{backgroundColor:'blue', color:"white"}}
											onClick={this.loadVideoFile}>
										LOAD recorded FILE
									</Button>
									<Button disabled={this.state.recordingStatus==='recording'}
											style={{backgroundColor: "purple", color: "yellow"}}
											content='Peek at an A/V Recording Tx' onClick={this.peekAtVideoTx}/>
									{this.state.recordingStatus === "recording" ? recordingDurationMention: <>&nbsp;</>}
									<br></br>
									There are {this.state.publishedVids?.length} published recordings you can reference
									when you <b>"Include an A/V recording"</b> in a post.
								</>
							)
						:
							(
								<div className="recorded-player">
									<h4>Use this to PUBLISH recordings which you can THEN use in your posts.</h4>

									Playing blob url {this.state.recordedVideoURL}
									<br></br>
									Blob size: {this.state.recordedBlob.size} bytes
									<br></br>

									<video className="recorded" width='400' height='300' ref={this.setRefV} controls ></video>

									<br></br>
									{this.state.recordingStatus !== "recording" ? recordingDurationMention: <>&nbsp;</>}
									<br></br>
									<Button style={{backgroundColor: this.bshzPurple, color: "white"}}>
										<a download={downloadName} href={this.state.recordedVideoURL} style={{color: "white"}}>
											SAVE recording to FILE
										</a>
									</Button> &nbsp; (if you haven't already done so)
									<br></br>
									<br></br>
									{simplePublishButton}&nbsp;
									{ffmpegButton}
									{publishTranscodedButton}

									{maybeFFMpegOption}

									<br></br>
									{ !this.state.permission ?
											<Button negative onClick={this.getCameraPermission}>
												Discard recording. Start over.
											</Button>
										:
											null
									}
									{approxFee}
								</div>
							) }
				</div>

				<Divider/>
				<div style={{textAlign: 'center'}}>
					<Button disabled={this.state.recordingStatus==="recording"} negative onClick={() => {this.closeStreams(true)}} content={doneButtonLabel}/>
				</div>

			</div>
			{maybeVideoLoader}
			{maybePeekAtVideoTx}
			{maybePublishingSwirly}
			{maybeShowFFMpegLoader}
			{maybeShowTranscoderToast}
			{maybeVideoDeviceModal}
			</>
		);
	};
}

export default VidRecorder;