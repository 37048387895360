import React from 'react';

import './index.css';

import { Button, Modal } from 'semantic-ui-react';

import { deduceMimeType } from './commonFuncs';


// Load a local video FILE
// reader portion adapted from walletImporter?
class VideoLoader extends React.Component {
  constructor(props) {
    super(props);

    this.videoReaderFunc  = this.videoReaderFunc.bind(this);
    this.setRefV          = this.setRefV.bind(this);

    this.returnVideoToParent = this.returnVideoToParent.bind(this);

    //FIXME: the purples are currently duplicated in index.css
    this.bshzPurple             = '#8313e2';
    this.bshzYellow             = "#fff300";
    this.bshzLtPurp             = '#e7d0fb';
    this.bshzLightYellow        = "#fffccc";

    this.state = {
                  invalidVideoFile: false,

                  videoPlayerRef: null,
                  videoURL: null,
                  videoBlob: null,

                  mimeType: ''        // deduced by scanning the header of a file
                };
  }

  componentWillUnmount() {
    document.getElementById('inputFileHolder')?.removeEventListener('change', this.videoReaderFunc)
  }

  componentDidMount() {
    this.setState({enableInspectButton: false, numItemsToImport: ' ', invalidVideoFile: false});

    document.getElementById('inputFileHolder').addEventListener('change', this.videoReaderFunc);
  }

  // called when video file loaded
  videoReaderFunc(e) {
    const parent = e.currentTarget
    const fr = new FileReader();

    fr.addEventListener('progress', (event) => {
      if (event.loaded && event.total) {
        const percent = (event.loaded / event.total) * 100;
        console.log(`LOADER:  Progress: ${Math.round(percent)}`);
      }
    });

    const me = this

    //FIXME: consider NOT using the element's innerHTML
    fr.onload=function(event){

        const result = event.target.result;
        console.warn("LOADER:  BTW: result: ", result)
        console.warn("LOADER:  BTW: fr.result: ", fr.result)
        console.warn("LOADER:  BTW: event.target: ", event.target)

        // PLACE the contents we've read into a 'nook' which the solicitPassword() will use
        document.getElementById('contentPlaceHolder').textContent = fr.result;
        const rawVideoFile = document.getElementById('contentPlaceHolder').innerHTML;
        console.log("LOADER:  loaded content of length " + rawVideoFile.length);
        console.log("LOADER:  actual content: ", rawVideoFile);

        me.setState({videoURL: null, videoBlob: null, mimeType: ''}, function() {
            console.warn("We've CLEARED the videoURL, because we're about to TRY to load a new video.")

            try {
              console.log("LOADER:  loaded video file of length: ", rawVideoFile.length)

              if ( rawVideoFile.length < 50 ) {
                console.warn("Since content was very little, here it is: ", rawVideoFile)
              }

              let arrayView = new Uint8Array(fr.result);
              // mimeType
              const {mType} = deduceMimeType(arrayView)

              // some mimeTypes:
              //    "video/webm; codecs=vorbis,vp8"
              //    'video/mp4;codecs=avc1'         SAFARI
              //    'video/webm; codecs=h264'       Chrome, brave, android
              const videoBlob = new Blob([arrayView], { type: mType})   // URL also plays fine for:
                                                                              //   'video/mp4;codecs=avc1'
                                                                              //   'video/webm'
                                                                              //   'video/ogg'
              console.log("Here's our new videoBlob: ", videoBlob)

              const videoUrl = URL.createObjectURL(videoBlob);
              console.log("Here's our video url: ", videoUrl)

              me.setState({ videoURL: videoUrl, videoBlob: videoBlob, mimeType: mType})

            } catch (error) {
              console.warn("LOADER: error while trying to load video: ", error)
              console.warn("LOADER:  FAILED to load video file of length: ", rawVideoFile.length)
            }
          }

        )
    } // fileReader.onLoad()

    //fr.readAsText(parent.files[0]);
    //fr.readAsDataURL(parent.files[0]);      // results in base64 data
    fr.readAsArrayBuffer(parent.files[0]);  // looks like we could build an URL from this
    //fr.readAsBinaryString(parent.files[0]);   // displays as ascii
  }

  // NOTE: this is in vidRecorder, and maybe elsewhere (videoViewerModal?)
  setRefV(element) {
    console.warn("setRefV(): refV: ", element)
    console.warn("setRefV(): state.videoURL: ", this.state.videoURL)

    this.setState({ videoPlayerRef: element }, () => {
      console.warn("state.videoURL:", this.state.videoURL)
      console.warn("state.videoPlayerRef:", this.state.videoPlayerRef)

      if (this.state.videoPlayerRef !== null) {
        this.state.videoPlayerRef.src = this.state.videoURL
        this.state.videoPlayerRef.type = this.state.mimeType

        console.log("we did it! We set player.src to " + this.state.videoURL
                  + "\nand .type (mimeType) to " + this.state.mimeType)
      }
    })
  };

  returnVideoToParent() {
    console.log("LOADER: about to return video to parent.")
    this.props.returnVideo(this.state.videoURL, this.state.videoBlob, this.state.mimeType)
  }

  render() {

    //FIXME: Copied from onramp.js.   can we share this somehow?
    // ***  DEVICE-DEPENDENT STYLING  ***
    // For mobile, "detect" landscape screen mode
    const landscape = this.props.isMobile ? window.innerWidth > window.innerHeight : false
    // For mobile, shrink the About, Security, and Glossary modals
    const modalClassName   = this.props.isMobile ?
                                    (landscape ?
                                            "modalMobileLandscape"
                                        :
                                            "modalMobilePortrait"
                                    )
                                :
                                    ""
    const modalContentClassName = this.props.isMobile ? "modalContentMobile" : ""
    const modalBottomClassName  = this.props.isMobile ? "modalBottomMobile"  : ""

    const invalidWarning = this.state.invalidVideoFile ?
                            <div style={{color: 'red'}}>Invalid video file</div>
                            : null;

    return  <>
             <Modal  dimmer='blurring' size='large' centered className={modalClassName} open={true}
                      style={{backgroundColor: this.bshzPurple, borderRadius: '20px', height: "auto"}}>

                <Modal.Header style={{textAlign: 'center', backgroundColor: this.bshzPurple, borderRadius: '20px'}}>
                  <span style={{color: this.bshzYellow}}> Load an Audio/Video File </span>
                </Modal.Header>

                <Modal.Content className={modalContentClassName} scrolling style={{backgroundColor: this.bshzLtPurp}}>
                  <p>
                    Use this to load, review, and use a recorded audio/video <b>file</b> you've already got on your device.
                  </p>

                  <input type="file" name="inputFileHolder" id="inputFileHolder" />
                  {invalidWarning}

                  {!this.state.videoURL ?
                          <></>
                      :
                          <>
                            <video  className="recorded" width='400' height='300'
                                    ref={this.setRefV} controls autoPlay>
                            </video>
                            <br></br>
                            <Button positive onClick={this.returnVideoToParent}>
                              Use this recording
                            </Button>
                          </>
                  }

                  <div style={{textAlign: 'center'}}>
                    <Button negative onClick={this.props.closeLoader} content='Never Mind'/>
                  </div>
                </Modal.Content>

						  </Modal>
              <pre id="contentPlaceHolder" style={{display:'none'}}></pre>
            </>
  }
} // VideoLoader

export default VideoLoader;
