import React from 'react';

import { Button, Checkbox, Container, Divider, Dropdown, Form, Grid,
    Input, Menu, Message, Modal, Popup, Radio, TextArea } from 'semantic-ui-react'

import {
    openDB,
    findADialog,
    findAllTheDialogs,
} from './buildShizzle.js';

class DialogModal extends React.Component {
    constructor(props) {
      super(props);

      this.state = {
        dialogsArray: []
      };

      this.handleCancel  = this.handleCancel.bind(this);
      this.buildDialogListFromArray = this.buildDialogListFromArray.bind(this);
      this.dialogClicked            = this.dialogClicked.bind(this);
    }

    async componentDidMount() {
        console.warn("dialogModal componentDidMount() - searching for dialogs for "
                    + this.props.domain + ":" + this.props.ownerCount)

        const db = await openDB();
        const dialogsArray = await findAllTheDialogs(db, this.props.domain, this.props.ownerCount)

        console.log("dialogModal componentDidMount(): dialogs: ", dialogsArray)

        this.setState({dialogsArray: dialogsArray})
    }

    dialogClicked(e, idx) {
        if (e.type === 'click') {
            console.log('dialogClicked: Left click');
        } else if (e.type === 'contextmenu') {
            console.log('dialogClicked: Right click');
        }
        const otherGuyThatWasClicked = this.state.dialogsArray[idx].name.toLowerCase()
        console.warn("dialogModal dialogClicked(): A dialog list was CLICKED!")
        console.warn("dialogModal dialogClicked(): idx: " + idx)
        console.warn("other domain: " + otherGuyThatWasClicked)

        this.props.dialogClickHandler(this.props.parent,
                                      this.state.dialogsArray[idx].startingTxId,
                                      this.state.dialogsArray[idx].dialogId)
        this.handleCancel()
    }

    //FIXME: extra/share this?
    buildDialogListFromArray(arr) {
        if ( arr === null ) {
            return null
        }

        let listArray=[]
        for ( let i = 0; i < arr.length; i++ ) {
            //FIXME: add info re: who OWNS the dialog
            listArray[i] = {
                            idx:           i,
                            name:          arr[i].name,
                            ownerCount:    arr[i].ownershipCount,
                            isOwner:       arr[i].isOwner,
                            startingTxId:  arr[i].startingTxId,
                            otherGuy:      arr[i].otherGuy,
                            dialogId:      arr[i].dialogId
            }
            console.log("    DlgM buildDialogListFromArray(): adding dialog with id #" + listArray[i].dialogId)
        }
        const keysList = listArray.map((key) =>
                        <li className="hoverLink" key={key.idx} onClick={       (event) => this.dialogClicked(event, key.idx)}
                                                                onContextMenu={ (event) => this.dialogClicked(event, key.idx)}>
                                <span style={{color: 'green'}}> Dialog with '{key.otherGuy}'</span>
                            &nbsp; (owner #{key.ownerCount}) - staring at txid {key.startingTxId.substring(0,14)}...</li>
                );

        return keysList
    }

    handleCancel() {
        this.props.closeModal(this.props.parent)
    }

    render() {
        const ourDomain = this.props.domain

        const dialogsArray = this.state.dialogsArray
        console.log("dialogModal render(): dialogsArray: ", dialogsArray)
        const arrList = this.buildDialogListFromArray(dialogsArray)

        return <>
                <Modal open={true} size='small' dimmer='blurring'>
                    <Modal.Header className="modaledge">Your Dialogs on domain <strong style={{color: 'blue'}}>'{ourDomain}'</strong></Modal.Header>
                    <Modal.Content>
                        Below is a list of all Dialogs in which you're engaged, for the domain {ourDomain}.
                        <br></br>
                        SOON: a button to 'refresh' our dialogs - checking for new messages
                        <Divider />
                        {arrList}
                        <Divider />

                        <Modal.Actions className="modaledge">
                            <Button field="cancelButton" onClick={this.handleCancel} negative>CANCEL</Button>
                        </Modal.Actions>

                    </Modal.Content>
                </Modal>
               </>
    }
}

export default DialogModal;
