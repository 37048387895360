"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var getOption_1 = require("./getOption");
function getSkinColor(options, random) {
    var skinColor = [];
    if (getOption_1.getOption('skin', 'tanned', options)) {
        skinColor.push('Tanned');
    }
    if (getOption_1.getOption('skin', 'yellow', options)) {
        skinColor.push('Yellow');
    }
    if (getOption_1.getOption('skin', 'pale', options)) {
        skinColor.push('Pale');
    }
    if (getOption_1.getOption('skin', 'light', options)) {
        skinColor.push('Light');
    }
    if (getOption_1.getOption('skin', 'brown', options)) {
        skinColor.push('Brown');
    }
    if (getOption_1.getOption('skin', 'darkBrown', options)) {
        skinColor.push('DarkBrown');
    }
    if (getOption_1.getOption('skin', 'black', options)) {
        skinColor.push('Black');
    }
    return random.pickone(skinColor);
}
exports.getSkinColor = getSkinColor;
