// qrScanModal.js

import React from 'react';

import { Button, Checkbox, Divider, Form, Icon, Input, List, ListItem, Modal, Radio, Table } from 'semantic-ui-react'

import { Html5Qrcode} from "html5-qrcode";

class QrScanModal extends React.PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            scannedAddress: '',

            cameraId: 0,
            cameraLabel: '',
            deviceList: null,

            showCameraList: false,
            cameraListItems: null,

            html5scanner: null,

            cameraStarted: false,
        }

        //FIXME: the purples are currently duplicated in index.css
        this.bshzPurple             = '#8313e2';
        this.bshzYellow             = "#fff300";
        this.bshzLtPurp             = '#e7d0fb';
        this.bshzLightYellow        = "#fffccc";

        //FIXME: rename to startCapture
        this.startUpCamera      = this.startUpCamera.bind(this);
        this.stopCamera    = this.stopCamera.bind(this);
        this.closeUp       = this.closeUp.bind(this);

        this.acceptAddress = this.acceptAddress.bind(this);

        this.chooseCamera  = this.chooseCamera.bind(this);
        this.cameraChosen  = this.cameraChosen.bind(this);
    }

    async componentDidMount() {

        const devices = await Html5Qrcode.getCameras()
        console.error("devices: ", devices)
        if (devices && devices.length) {
            const multipleCameras = devices.length > 1
            const cameraId = devices[0].id;
            const cameraLabel = devices[0].label

            const deviceList = multipleCameras ? devices : devices  // null
            // use this to start scanning.
            this.setState(  {
                                cameraId: cameraId,
                                cameraLabel: cameraLabel,
                                multipleCameras: multipleCameras,
                                deviceList: deviceList
                            },
                            this.startUpCamera)
        }
    }

    closeCameraList() {
        this.setState({showCameraList: false}, this.startUpCamera)
    }
    cameraChosen(id) {
        console.warn("Camera chosen id: ", id)

        let label = '???'
        for ( let device of this.state.deviceList ) {
            if ( device.id === id ) {
                label = device.label
                break
            }
        }
        if ( label === '???' ) {
            alert("PROBLEM: can't find camera")
        }

        this.setState({showCameraList: false, cameraId: id, cameraLabel: label}, this.startUpCamera)
    }
    async chooseCamera() {
        // XXXXX
        await this.stopCamera()
        console.warn("chooseCamera(): here are the devices: ", this.state.deviceList)
        //array.map(function(currentValue, index, arr), thisValue)
        const theListItems = this.state.deviceList.map( device => 
            <ListItem   key={device.id}
                        style={{color:'blue'}}
                        onClick={() => this.cameraChosen(device.id)}>
                {device.label}
            </ListItem>
        )

        this.setState({cameraListItems: theListItems, showCameraList: true})
    }

    async closeUp() {
        await this.stopCamera()
        this.props.closeQRScanner()
    }
    async stopCamera() {
        if ( this.state.html5scanner !== null ) {
            try {
                //NOTE: this await seems NOT to wait for camera to REALLY stop   XXXXX
                //  review this: https://developer.chrome.com/blog/play-request-was-interrupted
                await this.state.html5scanner.stop()
            } catch ( error ) {
                console.error("ERROR: while trying to stop camera: ", error)
                alert("ERROR while stopping camera. Please report this.")
            }
            this.setState({html5scanner: null, cameraStarted: false})
        }
    }

    startUpCamera() {
        //NOTE: clearing scannedAddress results in creation of the 'reader' element ( within render() )
        //      THEN we can create the Html5Qrcode
        this.setState({scannedAddress: ''}, () => {
            const html5Qrcode = new Html5Qrcode("reader");  // element id
            this.setState({html5scanner: html5Qrcode}, async () => {

                //NOTE: this await seems NOT to wait for camera to REALLY start   XXXXX
                //  review this: https://developer.chrome.com/blog/play-request-was-interrupted
                await html5Qrcode.start(
                        this.state.cameraId, 
                        {
                            fps: 5,    // Optional, frame per seconds for qr code scanning
                            qrbox: { width: 250, height: 250 }  // Optional, if you want bounded box UI
                        },
                        async (decodedText, decodedResult) => {
                            // do something when code is read
                            console.warn("Got qr scan result: ", decodedText)
                            console.warn("  BTW: ", decodedResult)



                            //FIXME: check if it's a valid address.
                            //       If not, can we KEEP SCANNING?
                            //       Or, need we stop, then start again?

                            // not sure if this await really waits for camera to stop
                            await html5Qrcode.stop()
                            console.warn("qrScanModal: stopped camera")

                            this.setState({scannedAddress: decodedText, html5scanner: null})

                        },
                        (errorMessage) => {
                            //IMPORTANT NOTE: THIS is how/where we avoid most race conditions
                            //                with rapid start, stop, start of camera

                            //console.log("qrScanModal Camera STARTED")

                            // parse error, ignore it, and take note that the camera has started
                            this.setState({cameraStarted: true})
                        })
                        .catch((err) => {
                        // Start failed, handle it.
                            console.error("FAILURE starting camera?", err)
                            alert("CAUGHT ERROR in camera start()")

                            //FIXME: do we need to STOP the camera? prob not
                        }
                ) // .start()

                //console.error("Camera STARTED")
                //NOTE: we don't signal start until we get an error, in handler above
                //      This avoids problem in stopCamera() - where it had never fully started
                //setTimeout( () => {this.setState({cameraStarted: true})}, 3000)
                //this.setState({cameraStarted: true})

            }) // setState(html5Scanner)
        })
    }

    acceptAddress() {
        this.stopCamera()
        console.log("Passing address (" + this.state.scannedAddress + ") back to parent")
        this.props.reportScannedAddress(this.state.scannedAddress)
    }

    render() {

        // copied from walletFunder:
        // ***  DEVICE-DEPENDENT STYLING  ***
        // For mobile, "detect" landscape screen mode
        const landscape = this.props.isMobile ? window.innerWidth > window.innerHeight : false
        // For mobile, shrink the About, Security, and Glossary modals
        const modalClassName   = this.props.isMobile ?
                                        (landscape ?
                                                "modalMobileLandscape"
                                            :
                                                "modalMobilePortrait"
                                        )
                                    :
                                        ""
        const modalContentClassName = this.props.isMobile ? "modalContentMobile" : ""
        const modalBottomClassName  = this.props.isMobile ? "modalBottomMobile"  : ""

        //backgroundColor:this.bshzPurple, color:'yellow',
        const messageToDisplay = this.state.scannedAddress === '' ?
                    <>
                        Point this at the QR Code for the address you'd like to scan

                        <div id="reader" width="200px"></div>
                        Camera: <b style={{color:'blue'}}>{this.state.cameraLabel}</b>
                        <br></br>
                    </>
                :
                    <>
                        Scanned Address: <b style={{color:'blue'}}>{this.state.scannedAddress}</b>
                        <br></br>
                        <br></br>
                        <Button positive onClick={this.acceptAddress} style={{ borderRadius: '15px'}}>
                            Use this address
                        </Button>
                        &nbsp;
                        <Button negative onClick={this.startUpCamera}>
                            Scan again
                        </Button>
                    </>
        const maybeShowCameraList = this.state.showCameraList ?
                    <>
                        <Modal size='large' centered className={modalClassName}  open={true} style={{backgroundColor: this.bshzPurple, borderRadius: '20px'}}>
                            <Modal.Header style={{textAlign: 'center', backgroundColor: this.bshzPurple, borderRadius: '20px'}}>
                                <span style={{color: this.bshzYellow}}> Available Cameras </span>
                            </Modal.Header> 

                            <Modal.Content className={modalContentClassName} scrolling style={{backgroundColor: this.bshzLtPurp}}>

                                Choose which camera to use
                                <br></br>
                                <List bulleted>
                                    {this.state.cameraListItems}
                                </List>

                            </Modal.Content>

                            <Modal.Actions className={modalBottomClassName} style={{backgroundColor: this.bshzPurple, borderRadius: '0px 0px 20px 20px'}}>
                                <div style={{textAlign: 'center'}}>
                                    <Button negative onClick={this.closeUp} content='Cancel'/>
                                </div>
                            </Modal.Actions>
                        </Modal>
                    </>
                :
                    null

        // by using .cameraStarted, we avoid a race when quickly starting, stopping, starting camera
        const maybeShowSwitchCameraButtton = this.state.cameraStarted ? //scannedAddress === '' ?
                    <>
                        <Button positive onClick={this.chooseCamera} content='Switch Camera'/>
                    </>
                :
                    null
        return(
            <>
                <Modal size='large' centered className={modalClassName}  open={true} style={{backgroundColor: this.bshzPurple, borderRadius: '20px'}}>
                    <Modal.Header style={{textAlign: 'center', backgroundColor: this.bshzPurple, borderRadius: '20px'}}>
                        <span style={{color: this.bshzYellow}}> Address QR Scanner </span>
                    </Modal.Header> 

                    <Modal.Content className={modalContentClassName} scrolling style={{backgroundColor: this.bshzLtPurp}}>

                        {messageToDisplay}

                    </Modal.Content>

                    <Modal.Actions className={modalBottomClassName} style={{backgroundColor: this.bshzPurple, borderRadius: '0px 0px 20px 20px'}}>
                        <div style={{textAlign: 'center'}}>
                            {maybeShowSwitchCameraButtton}
                            <Button negative disabled={!this.state.cameraStarted} onClick={this.closeUp} content='Cancel'/>
                        </div>
                    </Modal.Actions>
                </Modal>
                {maybeShowCameraList}
            </>
        )
    }
}

export default QrScanModal;
