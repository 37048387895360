//
// Adapted from code at: https://medium.com/@neetigyachahar784/handling-emojis-from-user-input-in-react-step-by-step-fully-explained-7c8dde6e62d9
//

import React from 'react';

import emojiTree from 'emoji-tree';


/**
 * Wraps emojis in a span, and specifies them as an "HTML Entity" - via their code point
 * For content from a quill editor, though, will try to distinguish between user-pasted emojis
 * (not wrapped in three quill spans), and emojis injected by the quill editor.
 *
 * HACK ALERT: this relies on detecting a few quill-specific span attributes
 *
 * @param {*} text to be scanned
 * @param {*} removeThreeSurroundingSpans option to strip-out three 'quill-emoji'-added spans which wrap emojis
 * @param {*} wrapAsHtmlEntity option to wrap all emojis as HTML Entities
 * @returns text, containing emojis that have been converted to HTML entities, wrapped in <span> </span>
 *
 * If neither option is selected, this function alters nothing
 */
const scanForEmojis = (text, removeThreeSurroundingSpans = false, wrapAsHtmlEntity = false) => {
    let textChunk = '';
    let formattedText = [];
    let runningString = ''

    const brokenString = [...text];

    console.log("text BEFORE scanning: ", text)
    console.log(" \n")

    let removeThreeClosingSpans = false
    let numClosingSpansIgnored = 0
    let wrappedEmoji = false   // some of the emojis may have been PASTED by the user, and so have no wrapping
    brokenString.forEach((char, index) => {
        if (emojiTree(char)[0].type === 'emoji') {
            console.warn(" \n")
            console.error(" FOUND an EMOJI: " + char + "  length", char.length + "   charPoint: ", char.codePointAt(0) + " at index " + index)

            if ( removeThreeSurroundingSpans && !removeThreeClosingSpans ) {
                console.warn("WE MIGHT remove three <span>s that wrap this emoji")

                if ( textChunk.includes('<span class="ql-emojiblot" data-name=') &&
                        textChunk.includes('<span contenteditable="false"><span class=') ) {

                    wrappedEmoji = true
                    console.log("    textChunk: ", textChunk)
                    let numFound = 0
                    let idx = -1
                    for ( let i = textChunk.length-1; i>=0; i-- ) {
                        //console.log("      i " + i)
                        if ( textChunk[i] === '<' ) {
                            console.log("        found one here: " + i)
                            numFound++
                        }
                        if ( numFound === 3 ) {
                            idx = i
                            break
                        }
                    }
                    if ( idx !== -1 ) {
                        console.warn("should only use textChunk from 0 to " + idx)
                        console.warn("     chunk is:", textChunk)
                        textChunk = textChunk.substring(0, idx)
                        console.warn("Now, chunk is:", textChunk)
                    } else {
                        alert("DIDN'T find 3 surrounding <span>s?!?!?!?")
                    }
                } else {
                    wrappedEmoji = false
                    console.error("Even though we're using quill, we WON'T remove any wrapping <spans> here, "
                                + "because we believe this emoji was PASTED")
                }
            }

            console.warn(" \n")
            if (textChunk !== '') {
console.log("appending this chunk to runningString: ", textChunk + "\n \n")
                formattedText.push(<span key={index - 1}>{textChunk}</span>);
                //runningString += ("<span key='" + (index-1) + "'>" + textChunk + "</span>")
                runningString += textChunk
                textChunk = '';
            }
            if ( wrappedEmoji ) {
                removeThreeClosingSpans = true
                numClosingSpansIgnored = 0
            }

            if ( wrapAsHtmlEntity ) {
                runningString += ("<span>&#" + char.codePointAt(0) + "</span>")
            } else {
                runningString += char
            }
        } else {
            if ( removeThreeClosingSpans ) {
                console.log(" We should start counting '>' chars...  IGNORING char " + char)

                if ( char === '>'  ) {
                    numClosingSpansIgnored++
                    console.error("YAY! We've found a '>' char.    #" + numClosingSpansIgnored + " of 3")

                    if ( numClosingSpansIgnored === 3 ) {
                        console.error("We've found all THREE!! Let's clean-up...")
                        removeThreeClosingSpans = false
                        numClosingSpansIgnored = 0
                        wrappedEmoji = false
                    }
                }
            } else {
                //console.warn("just adding single char: " + char)
                textChunk += char;
            }
        }

        // final chunk?
        if (brokenString.length - 1 === index && textChunk !== '') {

            console.log(" \n")
            console.error(" REVIEW THIS CODE: maybe we're adding a FINAL chunk (and MIGHT need to remove final 3 span-closers): ", textChunk)

            if ( removeThreeClosingSpans ) {
                let numFound = 0
                let idx = -1
                for ( let i = 0; i < textChunk.length; i++ ) {
                    //console.log("      i " + i)
                    if ( textChunk[i] === '>' ) {
                        console.log("        found one here: " + i)
                        numFound++
                    }
                    if ( numFound === 3 ) {
                        idx = i
                        break
                    }
                }
                if ( idx !== -1 ) {
                    console.warn("should REMOVE textChunk from 0 to " + idx)
                    console.warn("     chunk is:", textChunk)
                    textChunk = textChunk.substring(idx, textChunk.length)
                    console.warn("Now, chunk is:", textChunk)
                } else {
                    alert("DIDN'T find  ** 3 **  surrounding <span>s ?!?!?!?")
                }
            } else {
                console.warn("NO, we didn't need to close anything. we're just-about done.")
            }

            formattedText.push(<span key={index}>{textChunk}</span>);
            //runningString += ("<span key=" + index + ">" + textChunk + "</span>")
            runningString += textChunk

            textChunk = '';
        }
    });

    console.warn(" \n")
    console.log(" final running string: ", runningString)
    console.warn(" \n")
    return (
        runningString
        /*
        [
            ...formattedText
        ]
        */
    )
};
export {
    scanForEmojis
}
