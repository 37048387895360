"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
function getAvatarStyle(options) {
    switch (options.style) {
        case 'circle':
            return 'Circle';
        default:
            return 'Transparent';
    }
}
exports.getAvatarStyle = getAvatarStyle;
