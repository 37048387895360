// avatarBuilder.js

import React, {Component} from 'react';

import { Button, Divider, Modal, Radio, Table } from 'semantic-ui-react'
import { Avatar, Options } from 'react-avataaars';

class AvatarBuilder extends Component {

  constructor(props) {
    super(props)

    this.handleFeatureTypeChange        = this.handleFeatureTypeChange.bind(this);

    this.handlePrevFeatureValue         = this.handlePrevFeatureValue.bind(this);
    this.handleNextFeatureValue         = this.handleNextFeatureValue.bind(this);
    this.handleFeatureIteration         = this.handleFeatureIteration.bind(this);

    this.handlePrevHashValue            = this.handlePrevHashValue.bind(this);
    this.handleNextHashValue            = this.handleNextHashValue.bind(this);

    this.submitAvatar                   = this.submitAvatar.bind(this);
    this.clearAvatar                    = this.clearAvatar.bind(this);

    this.features = Object.freeze({
      STYLE: 0,
      EYES: 1,
      EYEBROW: 2,
      MOUTH: 3,
      CLOTHES: 4,
      CLOTHES_COLOR: 5,
      HAIR_COLOR: 6,
      TOP: 7,
      SKIN: 8,

      //ACCESSORIES: 9,   // not used - doesn't seem to work
    })

    this.featuresLabel = [
      'style',
      'eyes',
      'eye brows',
      'mouth',
      'clothes',
      'clothes color',
      'hair color',
      'head',
      'skin'
    ]

    this.featuresAndValues = [
      ['style',         'default', 'circle'],
      ['eyes',          'default', 'close', 'cry', 'dizzy', 'roll', 'happy', 'hearts', 'side', 'squint', 'surprised', 'wink', 'winkWacky'],
      ['eyebrow',       'default', 'angry', 'flat', 'raised', 'sad', 'unibrow', 'up'],
      ['mouth',         'default', 'concerned', 'disbelief', 'eating', 'grimace', 'sad', 'scream', 'serious', 'smile', 'tongue', 'twinkle', 'vomit'],
      ['clothes',       'default', 'blazer', 'shirt', 'hoodie', 'overall', 'sweater'],
      ['clothesColor',  'black', 'blue', 'gray', 'heather', 'pastel', 'pink', 'red', 'white'],
      ['hairColor',     'auburn', 'black', 'blonde', 'brown', 'pastel', 'platinum', 'red', 'gray'],
      ['top',           'default', 'shortHair', 'longHair', 'hat', 'eyepatch', 'hijab', 'turban'],   // 2, 3, 5 can be tweaked
      ['skin',          'light', 'tanned', 'yellow', 'pale', 'brown', 'darkBrown', 'black'],

      //['accessories',   'default', 'kurt', 'prescription01', 'prescription02', 'round', 'sunglasses', 'wayfarers']  // not used
      //facialHair  not used (doesn't work)
    ]


    this.state = {
      featureType:       this.features.TOP,
                        //style   brow    clothes      hairClr     skin
      theFeatureVals:   [ 1,   9,  4,  11,  6,   2,      1,    2,    1 ],
                        //    eyes   mouth     clthColor      top        accessories (doesn't work)

      hashVal:  20000,
    }
  }

  handlePrevFeatureValue() {
    this.handleFeatureIteration(-1)
  }

  handleNextFeatureValue() {
    this.handleFeatureIteration(1)
  }

  handleFeatureIteration(nextOrPrev) {
    const features = this.features
    const featureType = this.state.featureType
    const featureValuesLen = this.featuresAndValues[ featureType ].length   // number of options for a given feature

    const currentFeatureCurrentVal = this.state.theFeatureVals[featureType]
    const currentFeatureNextVal = ((currentFeatureCurrentVal + nextOrPrev + featureValuesLen - 2) % (featureValuesLen - 1)) + 1

    // alter value of particular feature
    let theFeatureVals = this.state.theFeatureVals
    theFeatureVals[ featureType ] = currentFeatureNextVal

    this.setState({theFeatureVals: theFeatureVals,
                 }, () => {
              
                  console.log("==================")
                  console.log("style:       ", this.featuresAndValues[ features.STYLE]         [this.state.theFeatureVals[features.STYLE]   ])
                  console.log("eyes:        ", this.featuresAndValues[ features.EYES]          [this.state.theFeatureVals[features.EYES]    ])
                  console.log("eyebrow:     ", this.featuresAndValues[ features.EYEBROW]       [this.state.theFeatureVals[features.EYEBROW] ])
                  console.log("mouth:       ", this.featuresAndValues[ features.MOUTH]         [this.state.theFeatureVals[features.MOUTH]   ])
              
                  console.log("clothes:     ", this.featuresAndValues[ features.CLOTHES]       [this.state.theFeatureVals[features.CLOTHES] ])
                  console.log("clothesColor:", this.featuresAndValues[ features.CLOTHES_COLOR] [this.state.theFeatureVals[features.CLOTHES_COLOR] ])
              
                  console.log("hairColor:   ", this.featuresAndValues[ features.HAIR_COLOR]    [this.state.theFeatureVals[features.HAIR_COLOR]])
                  console.log("top:         ", this.featuresAndValues[ features.TOP]           [this.state.theFeatureVals[features.TOP]])
                  console.log("skin:        ", this.featuresAndValues[ features.SKIN]          [this.state.theFeatureVals[features.SKIN]])
              
                  //console.log("accessories: ", this.featuresAndValues[ features.ACCESSORIES]   [this.state.theFeatureVals[features.ACCESSORIES]])
                  console.log("hash (not used):", Number(this.state.hashVal).toString())
              
                 })
  }

  handlePrevHashValue() {
    this.setState({hashVal: this.state.hashVal - 179})
  }

  handleNextHashValue() {
    this.setState({hashVal: this.state.hashVal + 179})
  }

  handleFeatureTypeChange(e, v) {
    console.warn("handleFeatureTypeChange(): value = ", v.value)

    this.setState({featureType: v.value})
  }

  handleFeatureValChange(e, v) {
    console.warn("handleFeatureValChange(): value = ", v.value)
  }

  clearAvatar() {
    // BLANK avatar (means no avatar)
    this.props.useThisAvatar({ blankAvatar: true })
  }

  submitAvatar() {
    const features = this.features
    this.props.useThisAvatar({
      blankAvatar: false,

      hash:        this.state.hashVal,

      style:       this.featuresAndValues[ features.STYLE]         [this.state.theFeatureVals[features.STYLE]   ],
      eyes:        this.featuresAndValues[ features.EYES]          [this.state.theFeatureVals[features.EYES]    ],
      eyebrow:     this.featuresAndValues[ features.EYEBROW]       [this.state.theFeatureVals[features.EYEBROW] ],
      mouth:       this.featuresAndValues[ features.MOUTH]         [this.state.theFeatureVals[features.MOUTH]   ],

      clothes:     this.featuresAndValues[ features.CLOTHES]       [this.state.theFeatureVals[features.CLOTHES] ],
      clothesColor:this.featuresAndValues[ features.CLOTHES_COLOR] [this.state.theFeatureVals[features.CLOTHES_COLOR] ],

      hairColor:   this.featuresAndValues[ features.HAIR_COLOR]    [this.state.theFeatureVals[features.HAIR_COLOR]],
      top:         this.featuresAndValues[ features.TOP]           [this.state.theFeatureVals[features.TOP]],
      skin:        this.featuresAndValues[ features.SKIN]          [this.state.theFeatureVals[features.SKIN]]
    })
  }

  render() {
    const features = this.features
    const featureType = this.state.featureType
    const currentFeatureCurrentVal = this.state.theFeatureVals[featureType]
    const currentFeatureCurrentValStr = this.featuresAndValues[ featureType ][ currentFeatureCurrentVal ]

    const ava = <>
                  <Avatar size='175px'
                    //hash="55512345678" // long fro w/band, short alfalfa,                blue winter hat
                    //hash="5551234567" // long up w/flowers, short tuft,                  blue winter hat
                    //hash="551234567" // long square back,   short tuft,                  santa hat
                    //hash="5124567" // long default,         short balding,               santa hat w/ears
                    //hash="514567" // long just fro,         short tuft,                  blue winter hat
                    //hash="51457" // long wavey highlights,  short bald,                  red canadian winter hat
                    //hash="58457" // long chopped bottom,    classic short,               santa hat
                    //hash="57457" // long parted,            short bowl w/ slight part,   santa hat w/ears    <-----
                    //hash="52457" // long wavey,             short spikey, orthodox hat
                    //hash="49457" // long lucy,              short shaved,long on top,    red canadian winter hat
                    //hash="79457" // long bun,               short freakish wild,         orthodox hat
                    //hash="89457" // long default,           short perfect bowl,          santa hat w/ ears
                    //hash="99457" // long rasta,             short tufty w/ back,         red canadian winter hat      FULL beard
                    //hash="99457999" // long lucy,              short: shaved,long on top,   red canadian winter hat      magnum moustache (no pastel, no gray)
                    //hash="994579999" //long straight.longOnTop,  short: balding,   santa hat
                                     // blonde only
                    //hash="994579998" //long bun,  short: freakish wild,   orthodox hat
                    hash={this.state.hashVal}

                    options={ {
                              style:       this.featuresAndValues[ features.STYLE]         [this.state.theFeatureVals[features.STYLE]   ],
                              eyes:        this.featuresAndValues[ features.EYES]          [this.state.theFeatureVals[features.EYES]    ],
                              eyebrow:     this.featuresAndValues[ features.EYEBROW]       [this.state.theFeatureVals[features.EYEBROW] ],
                              mouth:       this.featuresAndValues[ features.MOUTH]         [this.state.theFeatureVals[features.MOUTH]   ],

                              clothes:     this.featuresAndValues[ features.CLOTHES]       [this.state.theFeatureVals[features.CLOTHES] ],
                              clothesColor:this.featuresAndValues[ features.CLOTHES_COLOR] [this.state.theFeatureVals[features.CLOTHES_COLOR] ],

                              hairColor:   this.featuresAndValues[ features.HAIR_COLOR]    [this.state.theFeatureVals[features.HAIR_COLOR]],
                              top:         this.featuresAndValues[ features.TOP]           [this.state.theFeatureVals[features.TOP]],
                              skin:        this.featuresAndValues[ features.SKIN]          [this.state.theFeatureVals[features.SKIN]],

                              //accessories: this.featuresAndValues[ features.ACCESSORIES]   [this.state.theFeatureVals[features.ACCESSORIES]],

                              topChance:   Number(this.state.hashVal).toString()
                            } }
                  />
                </>

    const prevValueButton = <>
                        <Button positive onClick={this.handlePrevFeatureValue}  >
                            Previous
                        </Button>
                       </>
    const nextValueButton = <>
                        <Button positive onClick={this.handleNextFeatureValue}  >
                            Next
                        </Button>
                       </>
    const topVal = this.state.theFeatureVals[features.TOP]
    const topIsLongOrShortOrPatchOrHat = featureType === features.TOP &&
                                  (topVal === 2 || topVal === 3 || topVal === 4 || topVal === 5)
    const tweakName = topVal === 2 ? 'short hair' :
                          topVal === 3 ? 'long hair' :
                              topVal === 4 ? 'hat' : 'patch'
    const maybeTopTweak = this.state.featureType === features.TOP && topIsLongOrShortOrPatchOrHat ?
                    <>
                      <Divider/>
                      <b>{tweakName} tweak:</b> {this.state.hashVal}
                      <br></br>
                      <Button onClick={this.handlePrevHashValue} style={{padding: '8px'}}>
                            Previous
                      </Button> &nbsp;
                      <Button onClick={this.handleNextHashValue} style={{padding: '8px'}}>
                            Next
                      </Button>
                    </>
                  :
                    <></>

    // required to define ALL props used here
    const whatToRender = (typeof this.props.justRender !== 'undefined') ?
                <>
                  <Avatar size={this.props.size}
                    //hash="55512345678" // long fro w/band, short alfalfa,                blue winter hat
                    //hash="5551234567" // long up w/flowers, short tuft,                  blue winter hat
                    //hash="551234567" // long square back,   short tuft,                  santa hat
                    //hash="5124567" // long default,         short balding,               santa hat w/ears
                    //hash="514567" // long just fro,         short tuft,                  blue winter hat
                    //hash="51457" // long wavey highlights,  short bald,                  red canadian winter hat
                    //hash="58457" // long chopped bottom,    classic short,               santa hat
                    //hash="57457" // long parted,            short bowl w/ slight part,   santa hat w/ears    <-----
                    //hash="52457" // long wavey,             short spikey, orthodox hat
                    //hash="49457" // long lucy,              short shaved,long on top,    red canadian winter hat
                    //hash="79457" // long bun,               short freakish wild,         orthodox hat
                    //hash="89457" // long default,           short perfect bowl,          santa hat w/ ears
                    //hash="99457" // long rasta,             short tufty w/ back,         red canadian winter hat      FULL beard
                    //hash="99457999" // long lucy,              short: shaved,long on top,   red canadian winter hat      magnum moustache (no pastel, no gray)
                    //hash="994579999" //long straight.longOnTop,  short: balding,   santa hat
                                     // blonde only
                    //hash="994579998" //long bun,  short: freakish wild,   orthodox hat
                    hash={this.props.hashVal}

                    options={ {
                              style:       this.props.style,
                              eyes:        this.props.eyes,
                              eyebrow:     this.props.eyebrow,
                              mouth:       this.props.mouth,

                              clothes:     this.props.clothes,
                              clothesColor:this.props.clothesColor,

                              hairColor:   this.props.hairColor,
                              top:         this.props.top,
                              skin:        this.props.skin,

                              //accessories: this.featuresAndValues[ features.ACCESSORIES]   [this.state.theFeatureVals[features.ACCESSORIES]],

                              topChance:   Number(this.props.hashVal).toString()
                            } }
                  />
                </>
              :

      <>
        <Table style={{borderSpacing:"0px", border:'none', margin:'0px 5px', width:"100%", backgroundColor:"#00000000"}} >
          <Table.Body><Table.Row>
              <Table.Cell style={{border:'none', padding:'0px'}} collapsing>
                {ava}
              </Table.Cell>
              <Table.Cell style={{border:'none', padding:'0px'}} collapsing>
                &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;<b>{this.featuresLabel[ this.state.featureType ]}: </b> {currentFeatureCurrentValStr}
                <br></br>
                <br></br>
                {prevValueButton} &nbsp; &nbsp; {nextValueButton}

                {maybeTopTweak}

              </Table.Cell>
          </Table.Row></Table.Body>
        </Table>
        <br></br>
        <Radio
          label='head'
          name='radioGroup'
          value={features.TOP}
          checked={this.state.featureType === features.TOP}
          onChange={this.handleFeatureTypeChange}
        /> &nbsp; &nbsp; &nbsp; 
        <Radio
          label='hair color'
          name='radioGroup'
          value={features.HAIR_COLOR}
          checked={this.state.featureType === features.HAIR_COLOR}
          onChange={this.handleFeatureTypeChange}
        /> &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;
        <Radio
          label='eyes'
          name='radioGroup'
          value={features.EYES}
          checked={this.state.featureType === features.EYES}
          onChange={this.handleFeatureTypeChange}
        />

        <br></br>

        <Radio
          label='skin'
          name='radioGroup'
          value={features.SKIN}
          checked={this.state.featureType === features.SKIN}
          onChange={this.handleFeatureTypeChange}
        /> &nbsp; &nbsp; &nbsp; &nbsp;
        <Radio
          label='eye brows'
          name='radioGroup'
          value={features.EYEBROW}
          checked={this.state.featureType === features.EYEBROW}
          onChange={this.handleFeatureTypeChange}
        /> &nbsp; &nbsp; &nbsp; &nbsp;
        <Radio
          label='mouth'
          name='radioGroup'
          value={features.MOUTH}
          checked={this.state.featureType === features.MOUTH}
          onChange={this.handleFeatureTypeChange}
        />

        <br></br>

        <Radio
          label='clothes'
          name='radioGroup'
          value={features.CLOTHES}
          checked={this.state.featureType === features.CLOTHES}
          onChange={this.handleFeatureTypeChange}
        /> &nbsp;&nbsp;&nbsp;
        <Radio
          label='clothes color'
          name='radioGroup'
          value={features.CLOTHES_COLOR}
          checked={this.state.featureType === features.CLOTHES_COLOR}
          onChange={this.handleFeatureTypeChange}
        /> &nbsp; &nbsp; 
        <Radio
          label='style'
          name='radioGroup'
          value={features.STYLE}
          checked={this.state.featureType === features.STYLE}
          onChange={this.handleFeatureTypeChange}
        />

        <br></br>
        <br></br>

        <Button disabled={false}  style={{backgroundColor:'blue'}} onClick={this.submitAvatar}>
          <span style={{color: 'white'}}>
            Set this as my avatar
          </span>
        </Button>

        <Button disabled={false}  style={{backgroundColor:'red'}} onClick={this.clearAvatar}>
          <span style={{color: 'yellow'}}>
            I don't want an avatar
          </span>
        </Button>
      </>

    return (<>{whatToRender}</>)
  }
}

export default AvatarBuilder;
