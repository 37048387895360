/* */
const {
	bsv,
	//int2Asm					//FIXME: not xported?
} = require('scryptlib');

const {
    //generatePrivKey,
    //privKeyToPubKey, privKeyToPubKey2,
    //sign,
    sign2,
    verify,
    simpleHash
} = require("rabinsig");

const {
    USE_BITAILS_FULLY,

    DEFAULT_FEE_PER_KB,
    MIN_RATE,
    TARGET_RATE,
    MAX_RATE,

    FINAL_POST_FEE_PER_KB,
    TIP_TRANSIENT_FEE_PER_KB,
    BOUNTY_CLAIM_FEE_PER_KB,

    API_NETWORK
} = require('./harnessConstants');

const {
	int2Asm,
	oneByteLE,
	twoByteLE,
	fourByteLE,
	fiveByteLE,
	eightByteLE,
    hexByteToAscii,
    hexStringToAscii,
	swapEndian,

    printError,
} = require( './commonFuncs' );

const {
    initProviders,
    sendSerializeTx,
    fetchUtxos,
    sendLockingTx,
} = require( './providers' );

/////////////
/* *
import {
	bsv,
	buildContractClass,
	getPreimage,
	toHex,
	num2bin,
	Bytes,
	signTx,
	PubKey,
	Sig,
	//int2Asm					//FIXME: not xported?
} from 'scryptlib'

import {
    //generatePrivKey,
    //privKeyToPubKey, privKeyToPubKey2,
    //sign,
    sign2,
    verify,
    simpleHash
} from "rabinsig";

import  {
    DEFAULT_FEE_PER_KB,
    FINAL_POST_FEE_PER_KB,
    TIP_TRANSIENT_FEE_PER_KB,
    BOUNTY_CLAIM_FEE_PER_KB,

    API_NETWORK
} from './harnessConstants'

import {
	int2Asm,
	oneByteLE,
	twoByteLE,
	fourByteLE,
	fiveByteLE,
	eightByteLE,
    hexByteToAscii,
    hexStringToAscii,
	swapEndian
} from './commonFuncs' ;
/** */

//const { showError } = require('./helper.js');

const BN = bsv.crypto.BN;
const FLAGS = bsv.Script.Interpreter.SCRIPT_VERIFY_MINIMALDATA | bsv.Script.Interpreter.SCRIPT_ENABLE_SIGHASH_FORKID | bsv.Script.Interpreter.SCRIPT_ENABLE_MAGNETIC_OPCODES | bsv.Script.Interpreter.SCRIPT_ENABLE_MONOLITH_OPCODES;
const SIGHASH_TYPE_ALL = bsv.crypto.Signature.SIGHASH_ALL | bsv.crypto.Signature.SIGHASH_FORKID;
const SIGHASH_ALLANY = SIGHASH_TYPE_ALL | bsv.crypto.Signature.SIGHASH_ANYONECANPAY;
const SIGHASH_SINGLEANY = bsv.crypto.Signature.SIGHASH_SINGLE | bsv.crypto.Signature.SIGHASH_ANYONECANPAY |  bsv.crypto.Signature.SIGHASH_FORKID;

const MIN_FEE = 2;  // on July 17, 2023 change from 346 sats, to 2 sats'
                    // this is a backstop used by setFeePerKB()
const INPUT_IDX = 0;

/**
 * For the CONTINUE contract - builds the tx, with the state at the end
 *
 * @param {*} lockingScriptX
 * @param {*} contentNameX - a base-64 BUFFER
 * @param {*} contentX
 * @param {*} txDescriptorX
 * @param {*} txToPublishHexX
 * @param {*} payCodeHexX
 * @param {*} ownerPkhX
 * @param {*} rabinPkhOfPotentialBuyer
 * @param {*} priceInSatsX
 * @param {*} expCounterX
 * @param {*} renewalBlockDeadlineIntX
 * @param {*} publisherRabinPkhX
 * @param {*} buildersStateStringX
 * @param {*} genBlockHexX
 * @param {*} newBlockIntX
 * @param {*} smallestOutputX
 * @param {*} newModeX
 * @param {*} appendageX
 * @param {*} counterX
 */
//export /* */
function buildContinueScriptPubKey(lockingScriptX,
            ownerP2PkhX,                 // instaBuy owner receiving p2pkh
            rabinPkhOfPotentialBuyer,
            potentialSaleMinBlockIntX,
            potentialSaleFlagIntX,
            priceInSatsX,                // instaBuy price

            quarterlyCountHex,
            expCounterX,
            renewalBlockDeadlineIntX,
            publisherRabinPkhX,
            buildersStateStringX,
            genBlockHexX, newBlockIntX,
            smallestOutputX,
            appendageX, counterX,
            newModeX) {

    // mode 'P'?
    const notYetClaimed     = newModeX === '50'
    // mode 'K'?
    const applyingKeys      = (newModeX === '4B') || (newModeX === '4b')
    // mode 'p'?
    const fullyClaimed      = newModeX === '70'

console.warn("newModX: " + newModeX)
console.warn("fully claimed? " + fullyClaimed)
console.warn("applying keys? " + applyingKeys)

    let potentialSaleMinBlock = ''
    let potentialSaleFlag = ''
    let priceInSatsLE = ''

    if ( fullyClaimed ) {
        console.log("FULLY CLAIMED...")
        potentialSaleMinBlock = fourByteLE(potentialSaleMinBlockIntX)
        potentialSaleFlag = oneByteLE(potentialSaleFlagIntX)
        priceInSatsLE     = fiveByteLE( priceInSatsX );   // NOTE: this is a 5-byte price (and assuming actual price converts to just 4 bytes)
    }
    if ( fullyClaimed && rabinPkhOfPotentialBuyer.length !== 40 ) {
        throw new Error("472300: invalid rabinPKH of potential buyer - must be 20 hex bytes (40 chars)")
    }

    let expCounterHex = ''
    let renewalBlockDeadlineHex = ''
    if ( applyingKeys || fullyClaimed ) {
        console.log("buildContinueScriptPubKey(): applying keys (or fullyClaimed). renewal is " + renewalBlockDeadlineIntX)
         //FIXME: crazy. just use int2Asm(), or oneByteLE()
        expCounterHex     = ("0" + expCounterX.toString(16)).slice(-2);
        renewalBlockDeadlineHex = fourByteLE(renewalBlockDeadlineIntX)
        //quarterly was passed in as hex
    }

    const newBlockHex       = fourByteLE(newBlockIntX)

    console.log('- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -')
    console.log('Setting CONTINUE ScriptPubKey to (without commas): locking script + OP_RETURN')
    if ( fullyClaimed ) {
        console.log("FULLY CLAIMED, so, including 5 sale fields:")
        console.log(
            //+ '  //ownerPKH:  (future?)' + ownerPkhX + ' ],\n'
              '  rabinPkhOfPotentialBuyer: ' + rabinPkhOfPotentialBuyer + ',   <-- NEW\n'
            + '  potentialSaleMinBlock:    ' + potentialSaleMinBlock + ' (' + potentialSaleMinBlockIntX + ' decimal),  <-- NEW\n'
            + '  potentialSaleFlag: ' + potentialSaleFlag + ',   <-- NEW\n'
            + '  ownerP2PKH:        ' + ownerP2PkhX + ',\n'
            + '  price:             ' + priceInSatsLE + '\n')
    }
    if ( applyingKeys || fullyClaimed ) {
        console.log("applying keys, or fully claimed, so, these 4 fields:")
        console.log(
              '  quarterlyCountHex: ' + quarterlyCountHex + ',\n'
            + '  ownershipCounter:  ' + expCounterHex + ',\n'
            + '  renewal:           ' + renewalBlockDeadlineHex + ',\n'
            + '  publisherRabinPKH: ' + publisherRabinPkhX + ',')
    }
    console.log(
              '  Builders String0:        ' + buildersStateStringX.substring(  0, 82) + ',\n'
            + '  Builders String1:        ' + buildersStateStringX.substring( 82,164) + ',\n'
            + '  Builders String2:        ' + buildersStateStringX.substring(164,246) + ',\n'
            + '  Builders String3:        ' + buildersStateStringX.substring(246,328) + ',\n'
            + '  Builders String4:        ' + buildersStateStringX.substring(328,410) + ',\n'
            + '  Builders String5:        ' + buildersStateStringX.substring(410,492) + ',\n'
            + '  Builders String6:        ' + buildersStateStringX.substring(492,574) + ',\n'
            + '  Builders String7:        ' + buildersStateStringX.substring(574,656) + ',\n'
            + '  Builders String8:        ' + buildersStateStringX.substring(656,738) + ',\n'
            + '  Builders String9:        ' + buildersStateStringX.substring(738,820) + ',\n'
            + '  Builders String10:       ' + buildersStateStringX.substring(820,902) + ',\n'
            + '  Builders String11:       ' + buildersStateStringX.substring(902,984) + ',\n'
            + '  genesis:           ' + genBlockHexX + ',\n'
            + '  newBlock:          ' + newBlockHex + ',\n'
            + '  smallest output:   ' + twoByteLE(smallestOutputX) + ',\n'
            + '  path/name:         ' + appendageX + ',        counter: ' + counterX + ',\n'
            + '  newMode:           ' + newModeX + ',\n' )
    console.log('- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -')

//FIXME: better name: pSpecificState?
    const nonPreClaimedState = fullyClaimed ?
            rabinPkhOfPotentialBuyer
            + potentialSaleMinBlock
            + potentialSaleFlag
            + ownerP2PkhX
            + priceInSatsLE
                :
            ''

    const kSpecificState = (applyingKeys || fullyClaimed) ?
            quarterlyCountHex
            + expCounterHex + renewalBlockDeadlineHex
            + publisherRabinPkhX
        :
            ''

console.warn("buildContinueScriptPubKey(): nonPreClaimedState is ", nonPreClaimedState)
console.warn("buildContinueScriptPubKey(): kSpecificState is     ", kSpecificState)
console.warn("buildContinueScriptPubKey(): builderStateString is ", buildersStateStringX)

    return lockingScriptX + ' OP_RETURN '
            + nonPreClaimedState
            + kSpecificState
            + buildersStateStringX
            + genBlockHexX + newBlockHex
            + twoByteLE(smallestOutputX)
            + appendageX + counterX
            + newModeX
} // buildContinueScriptPubKey

//export /* */
function buildAskBidScriptPubKey(insertOpFalseBeforeFinalOpReturn,
        lockingScriptX,
        balksInfoArrayX,
        bidsInfoArrayX,

        mainLineOwnerRabinPubKeyX,
        authCodeX,
        authCodePaddingX,

        beforeBlockX,

        sumOfAllFundsX,
        bestBidNum,
        worstBidNum,

        askPriceX,
        ownerRabinPkhX,
        mainLineOwnerRabinPKH,
        salesDeadlineBlockIntX,
        newBlockIntX, //FIXME: consider passing newBlockHex
        operationNumX,
        smallestOutputX,

        quarterlyCountIntX,
        expirationsCountIntX,

        namePathX,
        newSubModeX) {

    const namePathLen = namePathX.length / 2; //NOTE: converting from ascii
    if ( namePathX.length % 2 !== 0 ) {
        console.log("invalid namePath: ", namePathX);
        console.log("(invalid hex has odd # of digits)");
        throw new Error("17305: invalid value"); //exit();
    }
    const namePathLenLE = oneByteLE( namePathLen )

    const balksInfoArrayLenLE = twoByteLE( balksInfoArrayX.length )
    const bidsInfoArrayLenLE = oneByteLE( bidsInfoArrayX.length )
    const mlrpklen = Math.floor((mainLineOwnerRabinPubKeyX.length + 1) / 2)
    console.log("mainline len is " + mlrpklen)
    const mainLineOwnerRabPubKeyLenLE = oneByteLE( mlrpklen )
    const aclen = Math.floor((authCodeX.length + 1) / 2)
    console.log("authcode len is " + aclen)
    const authCodeLenLE = oneByteLE( aclen )
    const authCodePaddingLenLE = twoByteLE( authCodePaddingX.length/2 )  // NOTE: we divide length in half

    const beforeBlockLE = fourByteLE( beforeBlockX )

    const sumOfAllFundsLE = eightByteLE( sumOfAllFundsX ) //FIXME: ensure it's 8-byte
    const bestBidNumLE = oneByteLE( bestBidNum )
    const worstBidNumLE = oneByteLE( worstBidNum )

    const askPriceLE = fiveByteLE( askPriceX );   // NOTE: this is a 5-byte price (and ASSUMING actual price converts to just 4 bytes)
    const salesDeadlineBlockHex = fourByteLE(salesDeadlineBlockIntX)
    const newBlockHex = fourByteLE(newBlockIntX)
    const operationNumLE = twoByteLE( operationNumX )
    const smallestOutputLE = twoByteLE( smallestOutputX )

    const quarterlyCountLE = twoByteLE( quarterlyCountIntX )
    const expirationsCountLE = oneByteLE( expirationsCountIntX )

    //FIXME: if we add OP_FALSE, mention it here, and remove locking script
    console.log('- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -')
    console.log('Setting AskBid ScriptPubKey to: locking script + OP_RETURN +\n'
            + '  ----------------- Balks\n')
    for ( var j = 0; j < balksInfoArrayX.length; j++ ) {
        console.log('  Balk #' + j + ' - refund PKH:       ' + balksInfoArrayX[j].refundPKH + '\n'
                  + '         - earliestRefundBlock#: ' + balksInfoArrayX[j].earliestRefundBlock + ' (LE: ' + fourByteLE(balksInfoArrayX[j].earliestRefundBlock) + ')\n'
                  + '         - Funds:                ' + balksInfoArrayX[j].funds + ' (LE: ' + fiveByteLE( balksInfoArrayX[j].funds ) + ')\n' )
    }
    console.log('  ----------------- Bids\n')
    for ( var i = 0; i < bidsInfoArrayX.length; i++ ) {
        console.log('  Bid #' + i + ' - price:  ' + bidsInfoArrayX[i].price + ' (LE: ' + fiveByteLE( bidsInfoArrayX[i].price ) + ')\n'
                + '         - Rabin PKH:            ' + bidsInfoArrayX[i].rabinPKH + '\n'
                + '         - refund PKH:           ' + bidsInfoArrayX[i].refundPKH + '\n'
                + '         - earliestRefundBlock#: ' + bidsInfoArrayX[i].earliestRefundBlock + ' (LE: ' + fourByteLE(bidsInfoArrayX[i].earliestRefundBlock) + ')\n'
                + '         - reserveFunds:         ' + bidsInfoArrayX[i].reserveFunds + ' (LE: ' + fiveByteLE(bidsInfoArrayX[i].reserveFunds) + ')\n');
    }
    console.log(''
            + '  -----------------\n'
            + '  [mainLineOwnerRabPubKey]:  ' + mainLineOwnerRabinPubKeyX + '\n'
            + '  [authCode]:                ' + authCodeX + '\n'
            + '  [authCodePadding]:         ' + authCodePaddingX + '\n'
            + '  numBalks:                  ' + balksInfoArrayLenLE + '\n'
            + '  numBids:                   ' + bidsInfoArrayLenLE + '\n'
            + '  mainLineOwnerRabPubKeyLen: ' + mainLineOwnerRabPubKeyLenLE + '\n'
            + '  authCodeLen:               ' + authCodeLenLE + '\n'
            + '  authCodePaddingLen:        ' + authCodePaddingLenLE + '\n'
            + '  before block #:            ' + beforeBlockX + ' (LE: ' + beforeBlockLE + ')\n'
            + '  sumOfBidFunds:             ' + sumOfAllFundsX + ' (LE: ' + sumOfAllFundsLE + ')\n'
            + '  bestBidNum:                ' + bestBidNumLE + '\n'
            + '  worstBidNum:               ' + worstBidNumLE + '\n'
            + '  askPrice:                  ' + askPriceX + ' (LE: ' + askPriceLE + ')\n'
            + '  ownerRabinPKH:             ' + ownerRabinPkhX + '\n'
            + '  mainLineOwnerRabinPKH:     ' + mainLineOwnerRabinPKH + '\n'
            + '  deadline:                  ' + salesDeadlineBlockIntX + ' (LE: ' + salesDeadlineBlockHex + ')\n'
            + '  newBlock:                  ' + newBlockIntX + ' (LE: ' + newBlockHex + ')\n'
            + '  operationNum:              ' + operationNumLE + '\n'
            + '  smallest output:           ' + smallestOutputX + ' (LE: ' + smallestOutputLE + ')\n'

            + '  quarterly count:           ' + quarterlyCountIntX + ' (LE: ' + quarterlyCountLE + ')\n'
            + '  expirations count:         ' + expirationsCountIntX + ' (LE: ' + expirationsCountLE + ')\n'

            + '  name/path:                 \'' + namePathX + '\'\n'
            + '  namePathLen:               ' + namePathLen  + '\n'
            + '  subMode:                   ' + newSubModeX + '\n'
            + '  primaryMode:               4E  (\'N\')\n' ); //NOTE: always 'N'egotiateSale
    console.log('- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -')

    //FIXME: temporarily abandoned idea of 0-satoshi final output
    var scriptPubKey
    //if ( insertOpFalseBeforeFinalOpReturn ) {
    //    scriptPubKey = 'OP_FALSE OP_RETURN '
    //} else {
        scriptPubKey = lockingScriptX + ' OP_RETURN ';
    //}
    for ( var k = 0; k < balksInfoArrayX.length; ++k ) {
        scriptPubKey += '' + balksInfoArrayX[k].refundPKH
                    + fourByteLE( balksInfoArrayX[k].earliestRefundBlock )
                    + fiveByteLE( balksInfoArrayX[k].funds );
    }
    for ( var m = 0; m < bidsInfoArrayX.length; ++m ) {
        scriptPubKey += '' + fiveByteLE( bidsInfoArrayX[m].price )
                + bidsInfoArrayX[m].rabinPKH
                + bidsInfoArrayX[m].refundPKH
                + fourByteLE( bidsInfoArrayX[m].earliestRefundBlock )
                + fiveByteLE( bidsInfoArrayX[m].reserveFunds );
    }
    scriptPubKey += ''
            + mainLineOwnerRabinPubKeyX
            + authCodeX
            + authCodePaddingX;
    scriptPubKey += ''
            + balksInfoArrayLenLE
            + bidsInfoArrayLenLE;
    scriptPubKey += ''
            + mainLineOwnerRabPubKeyLenLE
            + authCodeLenLE
            + authCodePaddingLenLE;
    scriptPubKey += ''
            + beforeBlockLE;
    scriptPubKey += ''
            + sumOfAllFundsLE
            + bestBidNumLE
            + worstBidNumLE;
    scriptPubKey += ''
            + askPriceLE
            + ownerRabinPkhX
            + mainLineOwnerRabinPKH
            + salesDeadlineBlockHex
            + newBlockHex
            + operationNumLE
            + smallestOutputLE

            + quarterlyCountLE
            + expirationsCountLE

            + namePathX
            + namePathLenLE
            + newSubModeX  // N, O, W, G, C, c, e, E
            + '4E';     // always N (Negotiations)

    return scriptPubKey;
}

//export /* */
//FIXME: TAKE NOTE that this is the FINAL FORM (mode E) (announcement, not speaker/pulpit)
//       MAYBE we should also have one for 'e'
function buildEbfraScriptPubKey(
        namePathX,
        builderPubKeyX,
        builderSigHexX,
        messagePaddingX) {

    const newSubModeX = '45' // 'E'

    const pubKeyLen = Math.floor((builderPubKeyX.length + 1) / 2)
    console.log("builder pubkey len is " + pubKeyLen)
    const pubKeyLenLE = oneByteLE( pubKeyLen )
    console.log("HEX form of BUILDER signature is ", builderSigHexX)
    const sigLen = Math.floor( ( builderSigHexX.length + 1 ) / 2)
    console.log("length of HEX form of sig is " + sigLen)
    const sigLenLE = oneByteLE( sigLen )
    const messagePaddingLenLE = twoByteLE( messagePaddingX.length/2 )  // NOTE: we divide length in half

    console.log('- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -')
    console.log('Setting EBFRA ScriptPubKey to: OP_FALSE OP_RETURN +\n')
    console.log(''
            + '  -----------------\n'
            + '  [builderPubKey]: ' + builderPubKeyX + '\n'
            + '  [builderSigHex]: ' + builderSigHexX + '\n'
            + '  pubKeyLen:       ' + pubKeyLenLE + '\n'
            + '  sigLen:          ' + sigLenLE + '\n'
            + '  paddingLen:      ' + messagePaddingLenLE + '\n'
            + '  namePath:        ' + namePathX
            + '  nameLen:         ' + oneByteLE( namePathX.length / 2 )
            + '  mode (E):        ' + newSubModeX + '\n'); //NOTE: always 'E'bfra announcement
    console.log('- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -')

    let scriptPubKey = 'OP_FALSE OP_RETURN '
            + builderPubKeyX
            + builderSigHexX
            + pubKeyLenLE
            + sigLenLE
            + messagePaddingLenLE
            + namePathX
            + oneByteLE( namePathX.length / 2 )
            + newSubModeX  // E

    return scriptPubKey;
} //buildEbfraScriptPubKey()

//export /* */
function buildUpdateScriptPubKey(lockingScript,
            voteTallyIntX,
            periodicCountHex,
            renewalBlockDeadlineIntX,
            publisherRabinPkhX, pkhStringX,
            genBlockX, newBlockIntX,
            smallestOutputX,
            quarterlyCountHex,
            ownershipCountHex,
            appendageX, counterX, newModeX) {

    const renewalBlockDeadlineHex = fourByteLE(renewalBlockDeadlineIntX)
    const newBlockHex = fourByteLE(newBlockIntX)
    const voteTallyHex = oneByteLE(voteTallyIntX)
    // NOTE: This is a SIMILAR state layout. This is for 'U'pdate outputs
    console.log('- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -')
    console.log(' Setting updateScriptPubKey to (without commas): locking script + OP_RETURN\n'
            + '  voteTallyHex:      ' + voteTallyHex + ',\n'
            + '  periodicCountHex:  ' + periodicCountHex + ',\n'
            + '  renewal:           ' + renewalBlockDeadlineHex + ',\n'
            + '  publisherRabinPKH: ' + publisherRabinPkhX + ',\n'
            + '  PKH String:        ' + pkhStringX + ',\n'
            + '  genesis:           ' + genBlockX + ',\n'
            + '  currentBlock:      ' + newBlockHex + ',\n'
            + '  smallest output:   ' + twoByteLE(smallestOutputX) + ',\n'
            + '  quarterlyCountHex: ' + quarterlyCountHex + ',\n'
            + '  ownerCountHex:     ' + ownershipCountHex + ',\n'
            + '  [name/path]:       ' + appendageX + ',\n'
            + '  counter:           ' + counterX + ',\n'
            + '  mode:              ' + newModeX )
    return lockingScript    + ' OP_RETURN '
                            + voteTallyHex
                            + periodicCountHex
                            + renewalBlockDeadlineHex + publisherRabinPkhX
                            + pkhStringX
                            + genBlockX + newBlockHex
                            + twoByteLE(smallestOutputX)
                            + quarterlyCountHex
                            + ownershipCountHex
                            + appendageX + counterX
                            + newModeX
}

/**
 * NOTE: we pass in some contract input params (like content) for purposes
 * of SIGNING it, and returning the signature alongside the state.
 */
//export /* */
function buildTransientScriptPubKey(lockingScriptX,
        contentNameX, contentXHex,
        txDescriptorX, txToPublishHexX,
        previousTxidX,
        guestPostPriceIntX,
        maxContentLenIntX, publisherRabinPkhX,
        newBlockIntX, renewalBlockDeadlineIntX,
        smallestOutputX,
        maxDownCounterX,
        downCounterX,

        consecutivePostsHostedInt,

        txAddressHex,
        appendageToPublishX, counterX,
        transModeHexChar) {

    console.log("bTSPK() gpp: " + guestPostPriceIntX)
    const contentNameXHex   = contentNameX.toString("hex")    //Buffer.from(contentNameX).toString("hex");
    //const contentXHex       = Buffer.from(contentX).toString("hex");
    const txDescriptorXHex  = txDescriptorX.toString("hex")    //Buffer.from(txDescriptorX).toString("hex");
    const contentNameLenLE  = oneByteLE( contentNameX.length )
    const contentLenLE      = fourByteLE( contentXHex.length / 2 );
    const txDescriptorLenLE = oneByteLE( txDescriptorX.length );
    const txIdLenLE         = oneByteLE( txToPublishHexX.length / 2 )

    const guestPostPriceLE  = fourByteLE( guestPostPriceIntX );
    const maxContentLenLE   = fourByteLE( maxContentLenIntX );
    const newBlockHex       = fourByteLE(newBlockIntX)
    const renewalBlockDeadlineHex = fourByteLE(renewalBlockDeadlineIntX)

    const consecutivePostsHostedHex = fourByteLE( consecutivePostsHostedInt ).substring(0, 6)

    // NOTE: This is a DIFFERENT state layout. This is for 'T'ransient outputs
    console.log('- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -')
    console.log(' Setting transientScriptPubKey to (without commas): locking script + OP_RETURN +\n'
        //    + '   [ contentName:  ' + contentNameXHex + ',\n'
        //    + '     content:      ' + contentXHex + ',\n'
        //    + '     txDescriptor: ' + txDescriptorXHex + ',\n'
        //    + '     txToPublish:  ' + txToPublishHexX + '],\n'
        //    + '  contentNameLen:    ' + contentNameLenLE + ',\n'
        //    + '  contentLen:        ' + contentLenLE + ',\n'
        //    + '  txDescLen:         ' + txDescriptorLenLE + ',\n'
        //    + '  txIdLen:           ' + txIdLenLE + ',\n'
            + '  outpointSnippet:   ' + previousTxidX.substring(0,8) + ',\n'
            + '  guestPostPrice:    ' + guestPostPriceLE + ',\n'
            + '  maxContentLen:     ' + maxContentLenLE + ',\n'
            + '  publisherRabinPKH: ' + publisherRabinPkhX + ',\n'
            + '  currentBlock:      ' + newBlockHex + ',\n'
            + '  renewalDeadline:   ' + renewalBlockDeadlineHex + ',\n'
            + '  smallest output:   ' + twoByteLE(smallestOutputX) + ',\n'
            + '  maxDownCounterHex: ' + maxDownCounterX + ',\n'
            + '  downCounter:       ' + downCounterX + ',\n'

            + '  consecPostsHosted: ' + consecutivePostsHostedHex + ',\n'

            + '  txAddress:         ' + txAddressHex + ',\n'
            + '  txAddrLen:         ' + oneByteLE(txAddressHex.length/2) + ',\n'
            + '  [name/path]:       ' + appendageToPublishX + ',\n'
            + '  counter:           ' + counterX + ',\n'
            + '  mode:              ' + transModeHexChar)
    console.log('- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -')

    const state =
              previousTxidX.substring(0,8)
            + guestPostPriceLE
            + maxContentLenLE
            + publisherRabinPkhX
            + newBlockHex
            + renewalBlockDeadlineHex
            + twoByteLE(smallestOutputX)
            + maxDownCounterX
            + downCounterX

            + consecutivePostsHostedHex

            + txAddressHex
            + oneByteLE(txAddressHex.length/2)
            + appendageToPublishX
            + counterX
            + transModeHexChar

    // message-to-sign contains input-only parameters PLUS output state parameters
    const messageToSignPartA = contentNameXHex   + contentXHex
            + txDescriptorXHex  + txToPublishHexX
            + contentNameLenLE  + contentLenLE
            + txDescriptorLenLE + txIdLenLE
            + state

    const pubkey = lockingScriptX + ' OP_RETURN ' + state
    return {
        messagetoSignPartA : messageToSignPartA,
        state:  state,
        pubkey: pubkey
    }
}


//export /* */
function buildDialogScriptPubKey(lockingScript,
            prevTxIdRvsEndian,

            // NEW in Jan 2023
            guestPostPriceIntX,
            maxContentLenIntX,

            guestRabinPkhX,     // other/guest
            ownerRabinPkhX,     // owner/host
            maxBlockHex,

            // NEW in Jan 2023
            visitorAddressHex,  // other/guest
            visitorNameHex,
            ownerAddressHex,     // owner/host
            ownerNameHex,

            postNumX) {

    const guestPostPriceLE    = fourByteLE(guestPostPriceIntX)
    const maxContentLenLE     = fourByteLE(maxContentLenIntX)
    const visitorAddressLenLE = oneByteLE(visitorAddressHex.length/2)
    const visitorNameLenLE    = oneByteLE(visitorNameHex.length/2)
    const ownerAddressLenLE   = oneByteLE(ownerAddressHex.length/2)
    const ownerNameLenLE      = oneByteLE(ownerNameHex.length/2)
    const postNumHex          = fourByteLE(postNumX)

    console.log('- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -')
    console.log(' Setting dialogScriptPubKey to (without commas): locking script + OP_RETURN\n'
            + '  thisTxId-reversed:   ' + prevTxIdRvsEndian + ',\n'
                // NEW in Jan 2023
            + '  guestPostPriceLE:    ' + guestPostPriceLE + ', (NEW in Jan 2023)\n'
            + '  maxContentLenLE:     ' + maxContentLenLE + ',  (NEW in Jan 2023)\n'

            + '  guestRabinPKH:       ' + guestRabinPkhX + ',\n'
            + '  ownerRabinPKH:       ' + ownerRabinPkhX + ',\n'
            + '  maxBlock:            ' + maxBlockHex + ',\n'
                // NEW in Jan 2023
            + '  visitorAddressHex:   ' + visitorAddressHex + ',\n'
            + '  visitorAddressLenLE: ' + visitorAddressLenLE + ',\n'
            + '  visitorNamedHex:     ' + visitorNameHex + ',\n'
            + '  visitorNameLenLE:    ' + visitorNameLenLE + ',\n'
            + '  ownerAddressHex:     ' + ownerAddressHex + ',\n'
            + '  ownerAddressLenLE:   ' + ownerAddressLenLE + ',\n'
            + '  ownerNamedHex:       ' + ownerNameHex + ',\n'
            + '  ownerNameLenLE:      ' + ownerNameLenLE + ',\n'

            + '  postNumHex:          ' + postNumHex + ',\n'
            + '  mode:                44')

    const state =             prevTxIdRvsEndian

                            // NEW in Jan 2023
                            + guestPostPriceLE     // 4 bytes
                            + maxContentLenLE      // 4 bytes

                            + guestRabinPkhX
                            + ownerRabinPkhX
                            + maxBlockHex

                            // NEW in Jan 2023
                            + visitorAddressHex
                            + visitorAddressLenLE
                            + visitorNameHex
                            + visitorNameLenLE

                            + ownerAddressHex
                            + ownerAddressLenLE
                            + ownerNameHex
                            + ownerNameLenLE

                            + postNumHex
                            + '44' //newModeX

    const script =   lockingScript
                            +' OP_RETURN '
                            + state

    const scriptAndState = {
            state: state,
            script: script
          }

    return scriptAndState
}

//export /* */
function buildBitGroupScriptPubKey(lockingScript,
        userProfiles, //numUsers,
        opNum, nextBlock, startedAtPost,
        groupName, bounty,
        maxPostLen, satsPerPost,
        maxBlock,
        periodicCountHex,
        quarterlyCountHex,
        ownerCountHex,
        branchName) {

    // NOTE: This is a SIMILAR state layout. This is for BitGroup outputs
    console.log('- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -')
    console.log(' Setting bitGroupScriptPubKey to (without commas): locking script + OP_RETURN +\n'
        + '  --------------------------- UserProfiles:\n')
    for ( var j = 0; j < userProfiles.length; ++j ) {
        console.log('  User #' + j + ' - rabinPKH:   ' + userProfiles[j].userRabinPKH + ' +\n'
                  + '            P2PKH:               ' + userProfiles[j].userP2PKH + ' +\n'
                  + '            userRecentPostBlock: ' + userProfiles[j].userRecentPostBlock + ' +\n'
                  + '            rank:                ' + userProfiles[j].userStatus + ' +\n'
                  + '            paidBounty:          ' + userProfiles[j].userPaidBounty + ' +\n'
        )
    }
    console.log('  ---------------------------\n'
        + '  numUsers:       ' + userProfiles.length + ' +\n'
        + '  op/post num:    ' + opNum + ' +\n'
        + '  nextBlock:      ' + nextBlock + ' (' + fourByteLE(nextBlock) + ')+\n'
        + '  startedAtPost:  ' + startedAtPost + ' +\n'
        + '  groupName:      ' + groupName + ' +\n'
        + '  bounty:         ' + bounty + ',\n'
        + '  maxPostLen:     ' + maxPostLen + ' +\n'
        + '  satsPerPost:    ' + satsPerPost + ' +\n'
        + '  maxBlock:       ' + maxBlock + ' (' + fourByteLE(maxBlock) + ')+\n'
        + '  periodicCountHex:  ' + periodicCountHex + ' +\n'
        + '  quarterlyCountHex: ' + quarterlyCountHex + ' +\n'
        + '  ownerCountHex:  ' + ownerCountHex + ' +\n'
        + '  branchName:     ' + branchName + ' +\n'
        + '  mode:           47 (\'G\')')

    var scriptPubKey = lockingScript    + ' OP_RETURN '
    for ( var n = 0; n < userProfiles.length; ++n ) {
        scriptPubKey += userProfiles[n].userRabinPKH
                      + userProfiles[n].userP2PKH
                      + userProfiles[n].userRecentPostBlock
                      + userProfiles[n].userStatus
                      + userProfiles[n].userPaidBounty
    }
    scriptPubKey += (twoByteLE( userProfiles.length )
                  + fourByteLE( opNum )
                  + fourByteLE( nextBlock ) )
    const snippet = fourByteLE( startedAtPost )
                  + groupName
                  + oneByteLE( groupName.length/2 )
                  + fourByteLE( bounty )
                  + fourByteLE( maxPostLen )
                  + fourByteLE( satsPerPost )
                  + fourByteLE( maxBlock )
                  + periodicCountHex
                  + quarterlyCountHex
                  + ownerCountHex
                  + branchName
                  + oneByteLE( branchName.length/2 )
                  + '47'  // 'G'
    return {
            scriptPubKey: scriptPubKey + snippet,
            messagePart: snippet
    }
}

//export /* */
function buildAdministerGroupScriptPubKey(lockingScript,
        userProfiles, //numUsers,
        bitGroupCodeHash,
        opNum, nextBlock, startedAtPost,
        groupName, bounty,
        maxPostLen, satsPerPost,
        maxBlock,
        periodicCountHex,
        quarterlyCountHex,
        ownerCountHex,
        branchName) {

    // NOTE: This is a SIMILAR state layout. This is for BitGroup outputs
    console.log('- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -')
    console.log(' Setting administerGroupScriptPubKey to (without commas): locking script + OP_RETURN +\n'
        + '  --------------------------- UserProfiles:\n')
    for ( var j = 0; j < userProfiles.length; ++j ) {
        console.log('  User #' + j + ' - rabinPKH:        ' + userProfiles[j].userRabinPKH + ' +\n'
                  + '            P2PKH:           ' + userProfiles[j].userP2PKH + ' +\n'
                  + '            recentPostBlock: ' + userProfiles[j].userRecentPostBlock + ' +\n'
                  + '            rank:            ' + userProfiles[j].userStatus + ' +\n'
                  + '            paidBounty:      ' + userProfiles[j].userPaidBounty + ' +\n'
        )
    }
    console.log('  ---------------------------\n'
        + '  numUsers:       ' + userProfiles.length + ' +\n'
        + '  bitGroupHash:   ' + bitGroupCodeHash + ' +\n'
        + '  op/post num:    ' + opNum + ' +\n'
        + '  nextBlock:      ' + nextBlock + ' +\n'
        + '  startedAtPost:  ' + startedAtPost + ' +\n'
        + '  groupName:      ' + groupName + ' +\n'
        + '  bounty:         ' + bounty + ',\n'
        + '  maxPostLen:     ' + maxPostLen + ' +\n'
        + '  satsPerPost:    ' + satsPerPost + ' +\n'
        + '  maxBlock:       ' + maxBlock + ' +\n'
        + '  periodicCountHex:  ' + periodicCountHex + ' +\n'
        + '  quarterlyCountHex: ' + quarterlyCountHex + ' +\n'
        + '  ownerCountHex:  ' + ownerCountHex + ' +\n'
        + '  branchName:     ' + branchName + ' +\n'
        + '  mode:           67 (\'g\')')

    var scriptPubKey = lockingScript    + ' OP_RETURN '
    for ( var o = 0; o < userProfiles.length; ++o ) {
        scriptPubKey += userProfiles[o].userRabinPKH
                      + userProfiles[o].userP2PKH
                      + userProfiles[o].userRecentPostBlock
                      + userProfiles[o].userStatus
                      + userProfiles[o].userPaidBounty
    }
    scriptPubKey += (twoByteLE( userProfiles.length )  // numUsers
                  + bitGroupCodeHash                   // important deviation from BitGroup state
                  + fourByteLE( opNum )
                  + fourByteLE( nextBlock ) )
    const snippet = fourByteLE( startedAtPost )
                  + groupName
                  + oneByteLE( groupName.length/2 )
                  + fourByteLE( bounty )
                  + fourByteLE( maxPostLen )
                  + fourByteLE( satsPerPost )
                  + fourByteLE( maxBlock )
                  + periodicCountHex
                  + quarterlyCountHex
                  + ownerCountHex
                  + branchName
                  + oneByteLE( branchName.length/2 )
                  + '67'
    return {
            scriptPubKey: scriptPubKey + snippet,
            messagePart: snippet
    }
}

//export /* */
async function executeTransientHostingContract( altKeyX,
                                                lockingTxidsX,              // now an array
                                                prevOutIdxsX,               // now an array   MOVED <----
                                                scriptPubKeysX,             // now an array
                                                oldAmountsX,                // now an array
                                                newScriptPubKeyX,
                                                outputAmounts,
                                                newBlockIntX,
                                                extFundingChangePkhX,
                                                contentNameX,
                                                contentX,
                                                txDescriptorHexX,
                                                txToPublishHexX,
                                                publisherPkhX,
                                                    hostPublisherPkhX,      // unique for the host
                                                updateSigX, sigPaddingX, pubPubKeyX,
                                                spinoffScriptStrX,
                                                finalPostX,

                                                hostGuestFlagX,             // (if 1) this indicates there's a host input/tx (guest-posting onto/with it)
                                                    addDialogOutput,
                                                    injectedDialogScriptX,
                                                guestAndTypicalOutputBytesX,// typical case
                                                hostOutputBytesX,
                                                hostNewBlockIntX,
                                                guestSpawnState) {          // BRAND NEW PARAM: just the STATE of the guest's sub-transient
                                                //prevOutIdxsX) {             // MOVED UP       ^

    console.log(' eTHC() === GETTING SIGHASHPREIMAGE.......=========')

    const payScriptsQ = []
    const payoutsAmount = 0

    const feeType = addDialogOutput ? 6
                            : finalPostX
    let feePerKB = getFeePerKB(feeType)

    let blockNumIntForNLockTime = newBlockIntX
    if ( hostGuestFlagX > 0 ) {
        // If host/guest tx, nLockTime must be the larger of the two newBlockInts
        // (from host, and guest)
        blockNumIntForNLockTime = Math.max(newBlockIntX, hostNewBlockIntX);
        console.warn("eTHC(): using max of both newBlockInts: " + blockNumIntForNLockTime)

		if ( blockNumIntForNLockTime !== newBlockIntX ) {
			console.warn("FYI: INCREASED minBlock (newBlockInt) from newBlockInt (" + newBlockIntX
						+ ") to accomodate the HOST <-----")
		}
    }

    // for now, hard-coded nSequence (Little-Endian)
    // Contracts (sCrypt) specify in Big-Endian: 0xf0ffffff
    //const nSeq = 0xfffffff0
    const nSeq = 0xfffffffe
    //const nSeq = 0xffffffff

console.error("BTW: nseq is ", nSeq)


    const funding = await prepFundingParams(altKeyX, 'executeTransientHostingContract', null, true)

    let attemptsCount = 0
    let lowerBoundFailedRateAttempt = 100000
    let upperBoundFailedRateAttempt = 0
    let doneTrying = false
    let raw
    let theChange
    let theChangeOutIndex = -1 //NEW: reported to sendSerializeTx() so it might HELP manage UTXOs

    do {
        attemptsCount++


        const preData = await getGeneralizedSighashPreimage(funding,    // better than just altKeyX - it includes utxos
                                lockingTxidsX,                          // now an array  <-----
                                prevOutIdxsX,                           // now an array  <---- MOVED
                                scriptPubKeysX,                         // now an array  <-----
                                oldAmountsX,                            // now an array  <-----
                                newScriptPubKeyX,
                                outputAmounts,
                                payScriptsQ,
                                payoutsAmount,
                                blockNumIntForNLockTime,         // largest of the host and guest newBlockInts
                                feePerKB,
                                nSeq);

        // at some point change of 0 sats will mean NO new UTXO
        theChangeOutIndex = preData.change > 0 ? preData.outputIdxForChange : -1

        console.log(' === Got preimage data: ', preData)
        const preimages = preData.preimages
        const preimagesLength = preimages.length
        console.log(' === preimages has length of ', preimagesLength)
        console.log('   preimages[0] has length of ', preimages[0].length)
        //FIXME: and [1]?

        const newBlockHexX = fourByteLE( newBlockIntX );

        const changeASM = int2Asm(preData.change)
        let satsOfHost  = outputAmounts[0]
        let satsOfGuest = outputAmounts[ hostGuestFlagX ]
        let finalArgs   = []
        let scriptSigs  = []

        let normalOrGuestOutputVal = 0   // typical (ouput 0) or guest (output 1)

        // if hostGuest flag is set, it's a special transaction that spends from TWO different assets
        // We need to satisfy TWO input locking scripts at the same time
        // The transient contract is called twice - once as host, and once as guest
        // Additionally, the guest-post can also Spawn (if it's not already a T5 or T1)
        if ( hostGuestFlagX > 0 ) {
            if ( spinoffScriptStrX.length > 2 ) {
                console.log("BTW: spawning while guest-posting. Final parameter (guest spawn state) should have been passed in: " + guestSpawnState)
            }

            const output0HostVal  = addDialogOutput ? 4 : 1    // hosting:   1,  or   4 (for frozen guest)
            normalOrGuestOutputVal = addDialogOutput ? 3 : 2    // guest:     2,  or   3 (for host response)

            const hostNewBlockHexX = fourByteLE( hostNewBlockIntX );
            // We have two inputs which are contracts (transients)
            // We'll need to generate a HOST scriptSig too - here, now
            // Note we use a different rabinPKH for the host: hostPublisherPkhX
            finalArgs[0] = hostNewBlockHexX + ' '
                    + extFundingChangePkhX + ' ' + changeASM + ' '
                    + '' + ' '         + '' + ' '                          // contentName, and content
                    + '' + ' '     + '' + ' '   // txDescriptor, and txToPublish
                    + hostPublisherPkhX + ' '        + int2Asm(0) + ' '          //pkh and sig
                    + '' + ' '          + int2Asm(0) + ' '        // padding and pubKey
                    + '' + ' '          // required BLANK spinoff script
                    + int2Asm( finalPostX ) + ' '   //finalPost (signifying state of the GUEST, NOT the host)
                    + int2Asm( 0 ) + ' '            //claimBounty
                    + injectedDialogScriptX + ' '
                        + int2Asm( output0HostVal ) + ' '        //hosting:   1,  or   4 (for frozen guest)
                        + eightByteLE( satsOfGuest ) + ' '          // sats of the guest
                        + guestAndTypicalOutputBytesX + ' '         // output of the guest
                        + guestSpawnState  + ' '      // just the STATE of the spawned (sub-transient) of the guest

                        + '' + ' '      // outpoint 0
                        + '' + ' '      // outpoint 1
                        + ''            // outpoint 2

            scriptSigs[ 0 ] = preimages[ 0 ] + ' ' + finalArgs[ 0 ]

            console.log('- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -')
            console.log('TRANSIENT scriptSigs[0] (for HOSTING) is preimage +\n'
                    + '  newBlockHex       ' + hostNewBlockHexX + ' +\n'
                    + '  changePKH         ' + extFundingChangePkhX + ' +\n'
                    + '  change (sats)     ' + changeASM + ' (' + preData.change + ' decimal) +\n'
                    + '  contentNameHex    BLANK +\n'
                    + '  contentHex        BLANK +\n'
                    + '  txDescrHex        BLANK +\n'
                    + '  txToPublish       BLANK +\n'
                    + '  HOST publisherRabinPKH ' + hostPublisherPkhX,  ' (unchanged) +\n'
                    + '  signature         0 (blank) +\n'
                    + '  padding           \'\' (blank) +\n'
                    + '  userPubKey        0 (blank) +\n'
                    + '  spinoffScriptStrX BLANK +\n'
                    + '  finalPost         ' + int2Asm(finalPostX) +'\n'
                    + '  claimBounty       0 +\n'
                    + '  dialogScriptStr of len ' + injectedDialogScriptX.length + '  <----- new param\n'
                    + '  hostGuestMode (host) ' + output0HostVal + '+\n'
                    + '  guestOutputSatsLE ' + eightByteLE( satsOfGuest ) + ' +\n'
                    + '  guestOutputState of ' + guestAndTypicalOutputBytesX + ' +\n'
                    + '  guestSpawnState of  ' + guestSpawnState + '\n'
                    ) //, ' + ', functionIndex)

            //console.log("\n\nTRANSIENT scriptSigs[ 0 ] (for host) is preimage + " + finalArgs[0])
        }

        //const functionIndex = int2Asm( 1 )  // contract public function 1 is transientPublish()

        // if not guestMode, set these to blank
        let outpoint0 = ''
        let outpoint1 = ''
        let outpoint2 = ''
        if ( hostGuestFlagX === 2 || hostGuestFlagX === 3 ) {
            outpoint0 = preData.outpoint0
            outpoint1 = preData.outpoint1
            outpoint2 = preData.outpoint2
        }

        //////// NOTE: this must match one of the contract's public functions. In our case:
        ////////  transientPublish(preimage, newBlock, newContractOutputAmount, changePKH, changeSats, content, txDesc, txId, publisherPKH, claimExpired, publisherSig4Update, sigPadding, publisherPubKey)
        finalArgs[ hostGuestFlagX ] = newBlockHexX + ' '
                        + extFundingChangePkhX + ' ' + changeASM + ' '
                        + contentNameX + ' '         + contentX + ' '
                        + txDescriptorHexX + ' '     + txToPublishHexX + ' '
                        + publisherPkhX + ' '        + updateSigX + ' '
                        + sigPaddingX + ' '          + pubPubKeyX + ' '
                        + spinoffScriptStrX + ' '
                        + int2Asm(finalPostX) + ' '
                        + int2Asm( 0 ) + ' '            //claimBounty
                        + injectedDialogScriptX + ' '
                            + int2Asm( hostGuestFlagX * normalOrGuestOutputVal ) + ' '           // 0 (typical), or 2 (guest) or 3 (host response)
                            + eightByteLE( satsOfHost ) + ' '               // includes hosting fee
                            + hostOutputBytesX + ' '                             // other
                            + '' + ' ' // guestSpawnOutputState (blank. not used for guest)
                            + outpoint0 + ' '
                            + outpoint1 + ' '
                            + outpoint2
                        //+ ' ' + functionIndex
        scriptSigs[ hostGuestFlagX ] = preimages[ hostGuestFlagX ] + ' ' + finalArgs[ hostGuestFlagX ]
        console.log("TRANSIENT scriptSigs[" + hostGuestFlagX + "] is preimage + " + finalArgs[hostGuestFlagX])


        const hashToShow = spinoffScriptStrX.length > 0 ? simpleHash(spinoffScriptStrX) : 'null'
        console.log('- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -')
        console.log('TRANSIENT scriptSigs[] (for guest/typical) is preimage +\n'
                + '  newBlockHex       ' + newBlockHexX + ' +\n'
                + '  changePKH         ' + extFundingChangePkhX + ' +\n'
                + '  change (sats)     ' + changeASM + ' (' + preData.change + ' decimal) +\n'
                + '  contentNameHex    ' + contentNameX + ' +\n'
                + '  contentHex        ' + contentX + ' +\n'
                + '  txDescrHex        ' + txDescriptorHexX + ' +\n'
                + '  txToPublish       ' + txToPublishHexX + ' +\n'
                + '  publisherRabinPKH ' + publisherPkhX,  ' +\n'
                + '  signature         ' + updateSigX + ' +\n'
                + '  padding           ' + sigPaddingX + ' +\n'
                + '  userPubKey        ' + pubPubKeyX + ' +\n'
                + '  spinoffScriptStrX of length ' + (spinoffScriptStrX.length / 2) + ', hash of ' + hashToShow + ' +\n'
                + '  finalPost         ' + int2Asm(finalPostX) + ' +\n'
                + '  claimBounty       ' + int2Asm( 0 ) + ' +\n'
                + '  dialogScriptStr of len ' + injectedDialogScriptX.length + '  <----- new param\n'
                + '  hostGuestMode     ' + int2Asm( hostGuestFlagX * normalOrGuestOutputVal ) + ' \n'
                + '  otherOutputSatsLE ' + eightByteLE( satsOfHost ) + ' +\n'
                + '  hostOutputState of ' + hostOutputBytesX + ' +\n'
                + '  guestSpawnOutputState (blank????) +\n'
                + '  in0Prevout         ' + preData.outpoint0 + ' +\n'
                + '  in1Prevout         ' + preData.outpoint1 + ' +\n'
                + '  in2Prevout         ' + preData.outpoint2 + '\n'
                ) //, ' + ', functionIndex)
        console.log('- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -')

        console.log('  BTW: EXT_FUNDING_CHANGE_PKH X is ', extFundingChangePkhX, '  <=========<<<<<<')
        console.log('  BTW:  unlocking with scriptSig of preimage + ... + ... + change of ', preData.change)
        console.log(' === UNLOCKING FUNDED SCRIPT.......========= - BUILDING from txid ', lockingTxidsX[0])

        const { rawTx, feeWePaid } = await unlockGeneralizedTx(funding,
                                scriptSigs,            //FIXME: now an array
                                lockingTxidsX,            //FIXME: now an array
                                scriptPubKeysX,            //FIXME: now an array
                                oldAmountsX,            //FIXME: now an array
                                prevOutIdxsX,            //FIXME: now an array. and MOVED
                                newScriptPubKeyX,
                                outputAmounts,
                                payScriptsQ,
                                payoutsAmount,
                                blockNumIntForNLockTime,
                                feePerKB,
                                nSeq)

        raw = rawTx

        const { doneAdjustingFee, newFeePerKB, newLowerBound, newUpperBound }
                        = evaluateFee(  attemptsCount,
                                        feePerKB,
                                        feeWePaid,
                                        rawTx.length / 2,
                                        lowerBoundFailedRateAttempt,
                                        upperBoundFailedRateAttempt)
        doneTrying = doneAdjustingFee
        feePerKB   = newFeePerKB
        lowerBoundFailedRateAttempt = newLowerBound
        upperBoundFailedRateAttempt = newUpperBound

        theChange = preData.change
    } while ( !doneTrying )

    //alert("Done building the TRANSIENT tx - after " + attemptsCount + " tries at a reasonable fee")

    console.warn("Done building the TRANSIENT tx - after " + attemptsCount + " tries at a reasonable fee")

    let txid
    console.log("btw: buildBogusTx: " + window.buildBogusTx);
    if ( window.buildBogusTx ) {
        const hashTxSerial = bsv.crypto.Hash.sha256sha256( Buffer.from(raw, 'hex') ).toString('hex');
        console.log('executeTransientHostingContract(): not-yet-flipped HASH of txSerial: ', hashTxSerial);
        txid = '11111111111111111111111111111111' + hashTxSerial.substring(32,64)
        console.log('Won\'t broadcast. Forming a BOGUS txid: ' + txid)
    } else {
        txid = await sendSerializeTx(raw, [funding.bestUtxo], theChangeOutIndex, theChange, funding.changePKH);

        console.log('    executed (and broadcast) TRANSIENT HOSTING Contract:  txid: ', txid)
    }

    return {
		txid: txid,
		rawTx: raw,
        change: theChange
    }
} // executeTransientHostingContract

// This is for claiming the bounty/balance of a Transient contract, and ending its line
//export /* */
async function executeClaimTransientBalance(altKeyX,
        lockingTxidX,
        scriptPubKeyX, oldAmountX,
        newBlockIntX,   // can't be executed before this time.  FIXME: rename locktime? lockBlock?
        extFundingChangePkhX,
        prevOutIdxX) {

    console.log(' === GETTING SIGHASHPREIMAGE.......=========')

    // For Transients, the contract amount (output value in satoshis) doesn't change.
    // It was set when spawned from Update. EXCEPT if it's the final post of the line
    // (downCounter is 0, finalPost set to 1) - in which case we remove the contract
    // code altogether, and allow an OP_FALSE OP_RETURN with output value of 0.
    //
    // In THIS case, however, there will not even be an OP_RETURN. The contract balance
    // will be aggregated in with the change, directed to the change PKH.
    var newScriptPubKeyX = []
    var newAmountsX = []
    var payScriptsQ = []
    const payoutsAmount = 0
    const finalPost = 1

    const funding = await prepFundingParams(altKeyX, 'executeClaimTransientBalance')
    const feePerKB = getFeePerKB(finalPost)
    const preData = await getMultiSighashPreimageFundedLockTime( funding,
                            lockingTxidX,
                            scriptPubKeyX, oldAmountX,
                            newScriptPubKeyX, newAmountsX,
                            payScriptsQ, payoutsAmount,
                            newBlockIntX, prevOutIdxX,
                            feePerKB)

    const theChange = preData.change
    // at some point change of 0 sats will mean NO new UTXO
    const theChangeOutIndex = preData.change > 0 ? preData.outputIdxForChange : -1

    console.log(' === Got preimage data: ', preData)
    const preimage = preData.preimage
    const preimageLength = preimage.length
    console.log(' === preimage has length of ', preimageLength)

    const newBlockHexX = fourByteLE(newBlockIntX)

    const changeASM = int2Asm(preData.change)
    const contentNameX = ""
    const contentX = ""
    const txDescriptorHexX = ""
    const txToPublishHexX = ""
    const publisherPkhX = extFundingChangePkhX  //""  use extFundingChangePKH because it's the correct len. It's not actually used
    const updateSigX = int2Asm(0)
    const sigPaddingX = ""
    const pubPubKeyX = int2Asm(1)
    const spinoffScriptStrX = "11"
    const finalPostX = int2Asm(1)
    const claimBounty = int2Asm(1)
    //const functionIndex = int2Asm( 1 )  // contract public function 4 is transientPublish()
    ////////
    //////// NOTE: this must match one of the contract's public functions. In our case:
    ////////  transientPublish(preimage, newBlock, newContractOutputAmount, changePKH, changeSats, content, txDesc, txId, publisherPKH, claimExpired, publisherSig4Update, sigPadding, publisherPubKey)
    const finalArgs = newBlockHexX + ' '
                    + extFundingChangePkhX + ' ' + changeASM + ' '
                    + contentNameX + ' '         + contentX + ' '
                    + txDescriptorHexX + ' '     + txToPublishHexX + ' '
                    + publisherPkhX + ' '        + updateSigX + ' '
                    + sigPaddingX + ' '          + pubPubKeyX + ' '
                    + spinoffScriptStrX + ' '
                    + finalPostX + ' '
                    + claimBounty     //+ ' ' + functionIndex
    console.log("TRANSIENT BOUNTY CLAIM ScriptSig is preimage + " + finalArgs)
    const scriptSig = preimage + ' ' + finalArgs
    console.log('- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -')
    console.log('CLAIM TRANSIENT BOUNTY scriptSig is preimage +\n'
            + '  newBlockHex       ' + newBlockHexX + ' +\n'
            + '  changePKH         ' + extFundingChangePkhX + ' +\n'
            + '  change (sats)     ' + changeASM + ' (' + preData.change + ' decimal) +\n\n'

            + '  contentNameHex    ' + contentNameX + ' +\n'
            + '  contentHex        ' + contentX + ' +\n'
            + '  txDescrHex        ' + txDescriptorHexX + ' +\n'
            + '  txToPublish       ' + txToPublishHexX + ' +\n'
            + '  publisherRabinPKH ' + publisherPkhX,  ' +\n'
            + '  signature         ' + updateSigX + ' +\n'
            + '  padding           ' + sigPaddingX + ' +\n'
            + '  userPubKey        ' + pubPubKeyX + ' +\n'
            + '  spinoffScriptStrX of length ' + (spinoffScriptStrX.length / 2) + ' +\n'
            + '  finalPost         ' + finalPostX + ' +\n'
            + '  claimBounty       ' + claimBounty) //, ' + ', functionIndex)
    console.log('- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -')

    console.log('  BTW: EXT_FUNDING_CHANGE_PKH X is ', extFundingChangePkhX, '  <=========<<<<<<')
    console.log('  BTW:  unlocking with scriptSig of preimage + ... + ... + change of ', preData.change)
    console.log(' === UNLOCKING FUNDED SCRIPT.......========= - BUILDING from txid ', lockingTxidX)

    const { rawTx, feeWePaid } = await unlockFundedMultiScriptTxLockTime(funding,
                            scriptSig, lockingTxidX,
                            scriptPubKeyX, oldAmountX,
                            newScriptPubKeyX, newAmountsX,
                            payScriptsQ, payoutsAmount,
                            newBlockIntX, prevOutIdxX,
                            feePerKB)

    let txid
    console.log("btw: buildBogusTx: " + window.buildBogusTx);
    if ( window.buildBogusTx ) {
        const hashTxSerial = bsv.crypto.Hash.sha256sha256( Buffer.from(rawTx, 'hex') ).toString('hex');
        console.log('executeClaimTransientBalance(): not-yet-flipped HASH of txSerial: ', hashTxSerial);
        txid = '11111111111111111111111111111111' + hashTxSerial.substring(32,64)
        console.log('Won\'t broadcast. Forming a BOGUS txid: ' + txid)
    } else {
        txid = await sendSerializeTx(rawTx, [funding.bestUtxo], theChangeOutIndex, theChange, funding.changePKH);
        console.log('    executed (and broadcast) CLAIMED BOUNTY of TRANSIENT: ', txid)
    }

    return {
        txid: txid,
        rawTx: rawTx,
        change: preData.change
    }
}  // executeClaimTransientBalance()

// This is for tipping a Transient contract
//export /* */
async function executeIncreaseTransientBalance(altKeyX,
        lockingTxidX,
        scriptPubKeyX, oldAmountX,
        tipAmountInt,
        oldBlockInt,
        extFundingChangePkhX,
        prevOutIdxX) {

    console.log(' === GETTING SIGHASHPREIMAGE.......=========')

    // For Transients, the contract amount (output value in satoshis) doesn't change.
    // It was set when spawned from Update. EXCEPT if it's the final post of the line
    // (downCounter is 0, finalPost set to 1) - in which case we remove the contract
    // code altogether, and allow an OP_FALSE OP_RETURN with output value of 0.
    //
    // In THIS case, however, there will not even be an OP_RETURN. The contract balance
    // will be aggregated in with the change, directed to the change PKH.
    var newScriptPubKeyX = [ scriptPubKeyX ]
    var newAmountsX = [ oldAmountX + tipAmountInt ]
    var payScriptsQ = []
    const payoutsAmount = 0

    const funding = await prepFundingParams(altKeyX, 'executeIncreaseTransientBalance')
    const feePerKB = getTipTransientFeePerKB()
    const preData = await getMultiSighashPreimageFundedLockTime( funding,
                            lockingTxidX,
                            scriptPubKeyX, oldAmountX,
                            newScriptPubKeyX, newAmountsX,
                            payScriptsQ, payoutsAmount,
                            oldBlockInt, prevOutIdxX,
                            feePerKB)

    const theChange = preData.change
    // at some point change of 0 sats will mean NO new UTXO
    const theChangeOutIndex = preData.change > 0 ? preData.outputIdxForChange : -1

    console.log(' === Got preimage data: ', preData)
    const preimage = preData.preimage
    const preimageLength = preimage.length
    console.log(' === preimage has length of ', preimageLength)

    const newBlockHexX = fourByteLE(oldBlockInt)

    const changeASM = int2Asm(preData.change)
    const contentNameX = ""
    const contentX = ""
    const txDescriptorHexX = ""
    const txToPublishHexX = ""
    const publisherPkhX = extFundingChangePkhX  //""  use extFundingChangePKH because it's the correct len. It's not actually used
    const updateSigX = int2Asm(0)
    const sigPaddingX = ""
    const pubPubKeyX = int2Asm(1)
    const spinoffScriptStrX = "11"
    const finalPostX = int2Asm(1)
    const claimBounty = int2Asm( tipAmountInt )
    //const functionIndex = int2Asm( 1 )  // contract public function 4 is transientPublish()
    ////////
    //////// NOTE: this must match one of the contract's public functions. In our case:
    ////////  transientPublish(preimage, newBlock, newContractOutputAmount, changePKH, changeSats, content, txDesc, txId, publisherPKH, claimExpired, publisherSig4Update, sigPadding, publisherPubKey)
    const finalArgs = newBlockHexX + ' '
                    + extFundingChangePkhX + ' ' + changeASM + ' '
                    + contentNameX + ' '         + contentX + ' '
                    + txDescriptorHexX + ' '     + txToPublishHexX + ' '
                    + publisherPkhX + ' '        + updateSigX + ' '
                    + sigPaddingX + ' '          + pubPubKeyX + ' '
                    + spinoffScriptStrX + ' '
                    + finalPostX + ' '
                    + claimBounty     //+ ' ' + functionIndex
    console.log("TRANSIENT TIP ScriptSig is preimage + " + finalArgs)
    const scriptSig = preimage + ' ' + finalArgs
    console.log('- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -')
    console.log('TRANSIENT TIP scriptSig is preimage +\n'
            + '  newBlockHex       ' + newBlockHexX + ' +\n'
            + '  changePKH         ' + extFundingChangePkhX + ' +\n'
            + '  change (sats)     ' + changeASM + ' (' + preData.change + ' decimal) +\n\n'

            + '  contentNameHex    ' + contentNameX + ' +\n'
            + '  contentHex        ' + contentX + ' +\n'
            + '  txDescrHex        ' + txDescriptorHexX + ' +\n'
            + '  txToPublish       ' + txToPublishHexX + ' +\n'
            + '  publisherRabinPKH ' + publisherPkhX,  ' +\n'
            + '  signature         ' + updateSigX + ' +\n'
            + '  padding           ' + sigPaddingX + ' +\n'
            + '  userPubKey        ' + pubPubKeyX + ' +\n'
            + '  spinoffScriptStrX of length ' + (spinoffScriptStrX.length / 2) + ' +\n'
            + '  finalPost         ' + finalPostX + ' +\n'
            + '  claimBounty       ' + claimBounty) //, ' + ', functionIndex)
    console.log('- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -')

    console.log('  BTW: EXT_FUNDING_CHANGE_PKH X is ', extFundingChangePkhX, '  <=========<<<<<<')
    console.log('  BTW:  unlocking with scriptSig of preimage + ... + ... + change of ', preData.change)
    console.log(' === UNLOCKING FUNDED SCRIPT.......========= - BUILDING from txid ', lockingTxidX)

    const { rawTx, feeWePaid } = await unlockFundedMultiScriptTxLockTime(funding,
                            scriptSig, lockingTxidX,
                            scriptPubKeyX, oldAmountX,
                            newScriptPubKeyX, newAmountsX,
                            payScriptsQ, payoutsAmount,
                            oldBlockInt, prevOutIdxX,
                            feePerKB)
    let txid
    console.log("btw: buildBogusTx: " + window.buildBogusTx);
    if ( window.buildBogusTx ) {
        const hashTxSerial = bsv.crypto.Hash.sha256sha256( Buffer.from(rawTx, 'hex') ).toString('hex');
        console.log('executeIncreaseTransientBalance(): not-yet-flipped HASH of txSerial: ', hashTxSerial);
        txid = '11111111111111111111111111111111' + hashTxSerial.substring(32,64)
        console.log('Won\'t broadcast. Forming a BOGUS txid: ' + txid)
    } else {
        txid = await sendSerializeTx(rawTx, [funding.bestUtxo], theChangeOutIndex, theChange, funding.changePKH);
        console.log('    executed (and broadcast) TIP of TRANSIENT: ', txid)
    }

    return {
        txid: txid,
        rawTx: rawTx,
        change: preData.change
    }
}  // executeIncreaseTransientBalance()

//export /* */
async function executeTheAskBidContract(altKeyX,
						lockingTxidX,
						scriptPubKeyX, oldAmountX,
						newScriptPubKeyX, newAmountsX,
						newBlockIntX, extFundingChangePkhX, operationNumX,
						operationX,
						priceInSatsX,
						regardingEntryX,
						bidRefundPkhX,
						userRabinPkhX,
						sigResultX,     // This has signature + padding
						userPubKeyX, //BigIntX,
						refundAmount,
						refundScriptQ,
						whichParentOutputToUseX) {

//FIXME: rename to userPubKeyLE
	//const userPubKeyX = int2Asm(userPubKeyBigIntX)   //FIXME: rename to userPubKeyLE

	console.log('[executeTheAskBidContract] UNPACKING sigResult...')
	const thePadding = sigResultX.paddingBytes
	const theSigInt = sigResultX.signature //int2Asm(sigResultX.signature)
	//FIXME: look at what .sigHex is. Part of sign2()
	const theSigHex = int2Asm(theSigInt) //sigResultX.sigHex  // (could also int2Asm(theSigInt) )

	console.log(' === GETTING SIGHASHPREIMAGE.......=========')

	var payScriptsQ = refundScriptQ
	var payoutsAmount = refundAmount

	console.log(' === This is a normal ask/bid situation (single contract output)')
	console.log(' AND we\'re specifying to spend parent output #' + whichParentOutputToUseX + ' as input <-----')
	console.log('calling getMultiSighashPreimageFundedLockTime with:\n'
		+ "  altKeyx \n")
	console.log("  lockingTxid: " + lockingTxidX + "\n")
	console.log("  scriptPubKey - length " + scriptPubKeyX.length + "(chars)\n")
	console.log("  oldAmount: " + oldAmountX + "\n")
	console.log("  newScriptPubKey - length " + newScriptPubKeyX.length + "(chars)\n")
	console.log("  newAmounts: ", newAmountsX, "\n")
	console.log("  newBlockIntX: " + newBlockIntX + "\n (plus " + whichParentOutputToUseX + ")")

	const funding = await prepFundingParams(altKeyX, 'executeTheAskBidContract')
	const feePerKB = getFeePerKB(0)
	const preData = await getMultiSighashPreimageFundedLockTime(funding, lockingTxidX,
							scriptPubKeyX, oldAmountX,
							newScriptPubKeyX, newAmountsX, //NOTE: newAmountsX needs to be an array
							payScriptsQ, payoutsAmount,
							newBlockIntX, whichParentOutputToUseX,
							feePerKB)

    const theChange = preData.change
    // at some point change of 0 sats will mean NO new UTXO
    const theChangeOutIndex = preData.change > 0 ? preData.outputIdxForChange : -1

	console.log(' === Got preimage data: ', preData)
	const preimage = preData.preimage
	const preimageLength = preimage.length
	console.log(' === preimage has length of ', preimageLength)

	const newBlockHexX = fourByteLE(newBlockIntX)

	const changeASM = int2Asm(preData.change)

	const operationNumLE = int2Asm( operationNumX )
	const operationASM = int2Asm( operationX )

	const priceInSatsLE = int2Asm(priceInSatsX); //fiveByteLE( priceInSatsX );   // NOTE: this is a 5-byte price (and assuming actual price converts to just 4 bytes)
	const regardingEntryLE = int2Asm( regardingEntryX )

	//const userPubKey = int2Asm( userPubKeyX )


	const finalArgs = newBlockHexX + ' '
					+ extFundingChangePkhX + ' ' + changeASM + ' '     + operationNumLE + ' '
					+ operationASM + ' '         + priceInSatsLE + ' ' + regardingEntryLE + ' '
					+ bidRefundPkhX + ' '        + userRabinPkhX + ' ' + theSigHex + ' '
					+ thePadding + ' '           + userPubKeyX
					//+ ' ' + functionIndex
	console.log("ScriptSig is preimage + " + finalArgs)
	const scriptSig = preimage + ' ' + finalArgs

	console.log('- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -')
	console.log('ScriptSig is preimage +\n'
			+ '    newBlockHexX     ' + newBlockIntX + ' (LE: ' + newBlockHexX + ')\n'
			+ '    changePKH        ' + extFundingChangePkhX + '\n'
			+ '    changeASM        ' + preData.change + ' (ASM: ' + changeASM + ') (use int for debugger)\n'
			+ '    operationNumLE   ' + operationNumX + ' (ASM: ' + operationNumLE + ') (use int for debugger)\n'
			+ '    operationASM     ' + operationASM + '  (a hex #) Can be confusing. For debugger, convert to decimal\n'
			+ '    priceInSatsLE    ' + priceInSatsX + ' (ASM: ' + priceInSatsLE + ') (use int for debugger)\n'
			+ '    regardingEntryLE ' + regardingEntryLE + ' (ASM) (use int for debugger)\n'
			+ '    bidRefundPkhX    ' + bidRefundPkhX + '\n'
			+ '    userRabinPkhX    ' + userRabinPkhX + '\n'
			+ '    theSig  (int)    ' + theSigInt + '  (For debugger)\n'
			+ '      --->  (ASM)    ' + theSigHex + '  <---\n'
			+ '    thePadding       ' + thePadding + '\n'
			+ '    userPubKeyX (ASM) ' + userPubKeyX + ' <--- BUT, for debugger, use bigInt (in "" without trailing "n")') // + '\n'
//			+ '            (bigInt) ' + userPubKeyBigIntX)
	console.log('- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -   - - - - - - - -')

	console.log(' === UNLOCKING FUNDED SCRIPT.......========= - BUILDING from txid ', lockingTxidX)

	console.log(' === This is an Ask/Bid contract (single contract output)')
	console.log(' AND we\'re specifying to spend parent output #' + whichParentOutputToUseX + ' as input <-----')
	const { rawTx, feeWePaid } = await unlockFundedMultiScriptTxLockTime(funding, scriptSig,
						lockingTxidX, scriptPubKeyX,
						oldAmountX, newScriptPubKeyX,
						newAmountsX, payScriptsQ,
						payoutsAmount, newBlockIntX,
						whichParentOutputToUseX,
						feePerKB)

    let txid
    if ( window && window.buildBogusTx ) {
        console.log("btw: buildBogusTx: " + window.buildBogusTx);
        const hashTxSerial = bsv.crypto.Hash.sha256sha256( Buffer.from(rawTx, 'hex') ).toString('hex');
        console.log('executeTheAskBidContract(): not-yet-flipped HASH of txSerial: ', hashTxSerial);
        txid = '11111111111111111111111111111111' + hashTxSerial.substring(32,64)
        console.log('Won\'t broadcast. Forming a BOGUS txid: ' + txid)
    } else {
	    txid = await sendSerializeTx(rawTx, [funding.bestUtxo], theChangeOutIndex, theChange, funding.changePKH);
	    console.log('    executed (and broadcast) ASKBID Contract:  txid: ', txid)
    }

	return {
		//FIXME: return raw tx too? So we won't have to consult with WoC (sometimes)
		//FIXME: would need to modify unlockFundedScriptTxLockTime() to return raw tx too
		txid: txid,
		rawTx: rawTx,
		change: preData.change
	}
}  // executeTheAskBidContract

/*
async function executeAskBidContract(altKeyX, lockingTxidX,
    scriptPubKeyX, oldAmountX,
    newScriptPubKeyX, newAmountX,
    newBlockIntX, extFundingChangePkhX, operationNumX,
    operationX,
    priceInSatsX,
    regardingEntryX,
    bidRefundPkhX,
    userRabinPkhX,
    sigResultX,     // This has signature + padding
    userPubKeyX,
    refundAmount,
    refundScriptQ) {

    console.log('[executeAskBidContract] UNPACKING sigResult...')
    var thePadding = sigResultX.paddingBytes
    var theSigInt = sigResultX.signature //int2Asm(sigResultX.signature)
    //FIXME: look at what .sigHex is. Part of sign2()
    var theSigHex = int2Asm(theSigInt) //sigResultX.sigHex

    console.log(' === GETTING SIGHASHPREIMAGE.......=========')

    var payScriptsQ = refundScriptQ
    var payoutsAmount = refundAmount

    console.log(' === This is a normal ask/bid situation (single contract output)')
    console.log('calling getSighashPreimageFundedLockTime2 with:\n'
        + "  altKeyx \n")
    console.log("  lockingTxid: " + lockingTxidX + "\n")
    console.log("  scriptPubKey - length " + scriptPubKeyX.length + "(chars)\n")
    console.log("  oldAmount: " + oldAmountX + "\n")
    console.log("  newScriptPubKey - length " + newScriptPubKeyX.length + "(chars)\n")
    console.log("  newAmount: " + newAmountX + "\n")
    console.log("  newBlockIntX: " + newBlockIntX + "\n")
    var preData = await getSighashPreimageFundedLockTime2(altKeyX,
                            lockingTxidX,
                            scriptPubKeyX, oldAmountX,
                            newScriptPubKeyX, newAmountX,
                            payScriptsQ, payoutsAmount,
                            newBlockIntX,
                            0)  // use parent tx output #0 for askBid contracts

    console.log(' === Got preimage data: ', preData)
    const preimage = preData.preimage
    const preimageLength = preimage.length
    console.log(' === preimage has length of ', preimageLength)

    const newBlockHexX = fourByteLE(newBlockIntX)

    const changeASM = int2Asm(preData.change)

    const operationNumLE = int2Asm( operationNumX )
    const operationASM = int2Asm( operationX )

    const priceInSatsLE = fiveByteLE(priceInSatsX); // NOTE: this is a 5-byte price (and assuming actual price converts to just 4 bytes)
    const regardingEntryLE = int2Asm( regardingEntryX )

    const finalArgs = newBlockHexX + ' '
                    + extFundingChangePkhX + ' ' + changeASM + ' '     + operationNumLE + ' '
                    + operationASM + ' '         + priceInSatsLE + ' ' + regardingEntryLE + ' '
                    + bidRefundPkhX + ' '        + userRabinPkhX + ' ' + theSigHex + ' '
                    + thePadding + ' '           + userPubKeyX
                    //+ ' ' + functionIndex
    console.log("ScriptSig is preimage + " + finalArgs)
    const scriptSig = preimage + ' ' + finalArgs

    console.log('- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -')
    console.log('ScriptSig is preimage +' + '\n'
            + '    newBlockHexX     ' + newBlockIntX + ' (LE: ' + newBlockHexX + ')\n'
            + '    changePKH        ' + extFundingChangePkhX + '\n'
            + '    changeASM        ' + preData.change + ' (LE: ' + changeASM + ')\n'
            + '    operationNumLE   ' + operationNumX + ' (LE: ' + operationNumLE + ')\n'
            + '    operationASM     ' + operationASM + '\n'
            + '    priceInSatsLE    ' + priceInSatsX + ' (LE: ' + priceInSatsLE + ')\n'
            + '    regardingEntryLE ' + regardingEntryLE + '\n'
            + '    bidRefundPkhX    ' + bidRefundPkhX + '\n'
            + '    userRabinPkhX    ' + userRabinPkhX + '\n'
            + '    theSig  (int)    ' + theSigInt + '\n'
            + '      --->  (HEX)    ' + theSigHex + '  <---\n'
            + '    thePadding       ' + thePadding + '\n'
            + '    userPubKeyX (LE) ' + userPubKeyX)
    console.log('- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -   - - - - - - - -')

    console.log(' === UNLOCKING FUNDED SCRIPT.......========= - BUILDING from txid ', lockingTxidX)

    console.log(' === This is an Ask/Bid contract (single contract output)')
    const res = await unlockFundedScriptTxLockTime2(altKeyX,
                        scriptSig, lockingTxidX,
                        scriptPubKeyX, oldAmountX,
                        newScriptPubKeyX, newAmountX,
                        payScriptsQ, payoutsAmount,
                        newBlockIntX,
                        0)  // use parent tx output #0 for askBid contracts


    console.log('    executed CONTINUE/PUBLISH Contract:  txid: ', res.txid)

    return {
		txid: res.txid,
		rawTx: res.rawTx,
        change: preData.change
    }
}
*/

//export /* */
async function executeBuilderPrepContract(altKeyX, lockingTxidX,
                scriptPubKeyX, oldAmountX,
                newScriptPubKeysX, newAmounts,
                newPayoutPKHX, payScriptsQ, payoutsAmount,
                newBlockIntX, extFundingChangePkhX,
                outIdxOfContractWeWant,
                optionalEbfraScriptHexX,
                optionalAppendScriptHexX) {

    // kludgey
    const txFeeType = optionalAppendScriptHexX.length > 0 ? 4 : 0
    const feePerKB = getFeePerKB( txFeeType )
    console.warn(" Using feePerKB of " + feePerKB)

    const funding = await prepFundingParams(altKeyX, 'executeBuilderPrepContract')

    console.log('executeBuilderPrepContract(): === GETTING SIGHASHPREIMAGE.......=========')
    const preData = await getMultiSighashPreimageFundedLockTime(funding, lockingTxidX,
                                scriptPubKeyX, oldAmountX,
                                newScriptPubKeysX, newAmounts,
                                payScriptsQ, payoutsAmount,
                                newBlockIntX, outIdxOfContractWeWant,
                                feePerKB)

    const theChange = preData.change
    // at some point change of 0 sats will mean NO new UTXO
    const theChangeOutIndex = preData.change > 0 ? preData.outputIdxForChange : -1

	console.log('executeBuilderPrepContract(): === Got preimage data: ', preData)
	const preimage = preData.preimage
	const preimageLength = preimage.length
	console.log('executeBuilderPrepContract(): === preimage has length of ', preimageLength)

	const changeASM = int2Asm(preData.change)

	////////
	//////// NOTE: this must match one of the contract's public functions. In our case: addBuilder()
	const scriptSig = preimage + ' '                   + newPayoutPKHX + ' '
					+ extFundingChangePkhX + ' '       + changeASM + ' '
                    + optionalEbfraScriptHexX + ' '
					+ optionalAppendScriptHexX

    const ebraScriptToDisplayHash = optionalEbfraScriptHexX

    // a blank script crashes simpleHash(), so, for display purposes, hash '00' instead of blank
    const appendScriptToHash = optionalAppendScriptHexX.length > 0 ? optionalAppendScriptHexX : '00'
    console.log('executeBuilderPrepContract():  BTW: scriptSig is preimage +\n',
                '  newPayoutPKH:            ', newPayoutPKHX, ' +\n',
                '  changePKH:               ', extFundingChangePkhX, ' +\n',
                '  change in satoshis       ', changeASM, ' ( ' + preData.change + ' decimal)+\n',
                '  {optionalEbfraScript}  (length ' + optionalEbfraScriptHexX.length/2 + ', hash of '
                                            + simpleHash(ebraScriptToDisplayHash) + ') +\n',
                '  {optionalContinueScript}  (length ' + optionalAppendScriptHexX.length/2 + ', hash of '
                                            + simpleHash(appendScriptToHash) + ') +\n')

	console.log('executeBuilderPrepContract():  BTW: EXT_FUNDING_CHANGE_PKH X is ', extFundingChangePkhX, '  <=========<<<<<<')
	console.log('executeBuilderPrepContract():  BTW:  unlocking with scriptSig of preimage + ... + ... + change of ', preData.change)
	console.log('executeBuilderPrepContract(): === UNLOCKING FUNDED SCRIPT.......========= - BUILDING from txid ', lockingTxidX)
    const { rawTx, feeWePaid } = await unlockFundedMultiScriptTxLockTime(funding,
                            scriptSig, lockingTxidX,
                            scriptPubKeyX, oldAmountX,
                            newScriptPubKeysX, newAmounts,
                            payScriptsQ, payoutsAmount,
                            newBlockIntX, outIdxOfContractWeWant,
                            feePerKB)

    let txid
    console.log("btw: buildBogusTx: " + window.buildBogusTx);
    if ( window.buildBogusTx ) {
        const hashTxSerial = bsv.crypto.Hash.sha256sha256( Buffer.from(rawTx, 'hex') ).toString('hex');
        console.log('executeBuilderPrepContract(): not-yet-flipped HASH of txSerial: ', hashTxSerial);
        txid = '11111111111111111111111111111111' + hashTxSerial.substring(32,64)
        console.log('Won\'t broadcast. Forming a BOGUS txid: ' + txid)
    } else {
	    txid = await sendSerializeTx(rawTx, [funding.bestUtxo], theChangeOutIndex, theChange, funding.changePKH);
	    console.log('executeBuilderPrepContract():   built and broadcast. executeMultiContract:  txid: ', txid)
    }

	return {
		txid: txid,
		rawTx: rawTx,
		change: preData.change
	}
}  // executeBuilderPrepContract()

// This is used for contract's append()  (building a path)
//  COPIED FROM newMultiPub.js                                                        array of size 'numOutputs'
//export /* */
async function executeTheAppendContract(altKeyX, lockingTxidX,
                scriptPubKeyX, oldAmountX,
                newScriptPubKeysX, newAmounts, newSmallestAmountX,
                newPayoutPKHX, payScriptsQ, payoutsAmount,
                newBlockIntX, extFundingChangePkhX,
                outIdxOfContractWeWant,
                optionalContinueScriptHexX,
                optionalEbfraScriptHexX,
                optionalPublisherPkhX,
            optionalAdditionalBalance,              // new param - typically must be zero. Can be positive when claiming to K
                numOutputsX,
                optionalSilentBuild = false,
                optionalUTXOs = null) {

    //NOTE: newAmounts[0] must have ALREADY has optionalAdditionalBalance added to it (if non-zero)

    let funding
    if ( optionalSilentBuild ) {
        funding = await prepFundingParams(altKeyX, 'executeTheAppendContract', optionalUTXOs)
        console.warn("executeTheAppendContract(): before calling gMSPFLT() A: funding: ", funding)
    } else {
        funding = await prepFundingParams(altKeyX, 'executeTheAppendContract')
        console.warn("executeTheAppendContract(): before calling gMSPFLT() B: funding: ", funding)
    }

    const feePerKB = getFeePerKB(0)
    console.log('executeTheAppendContract(): === GETTING SIGHASHPREIMAGE.......=========')  //                                               array of 3
    const preData = await getMultiSighashPreimageFundedLockTime(funding, lockingTxidX,
                                scriptPubKeyX, oldAmountX,
                                newScriptPubKeysX, newAmounts,
                                payScriptsQ, payoutsAmount,
                                newBlockIntX, outIdxOfContractWeWant,
                                feePerKB)
	console.log('executeTheAppendContract(): === Got preimage data: ', preData)

    const theChange = preData.change
    // at some point change of 0 sats will mean NO new UTXO
    const theChangeOutIndex = preData.change > 0 ? preData.outputIdxForChange : -1

	const preimage = preData.preimage
	const preimageLength = preimage.length
	console.log('executeTheAppendContract(): === preimage has length of ', preimageLength)
	const newSmallestAmountASM = int2Asm(newSmallestAmountX)
	const newBlockHexX = fourByteLE(newBlockIntX)

	const changeASM = int2Asm(preData.change)
	const numOutputsASM = int2Asm( numOutputsX )
	//const functionIndex = int2Asm( 1 )  // contract public function 1 is append()

	var maybePublisherPKH = '00'
	if ( optionalPublisherPkhX === '' ) {
		console.log('\nexecuteTheAppendContract():  BTW: the OPTIONAL publisher PKH is blank. Will pass in 00 <=====\n')
	} else {
		console.log('\nexecuteTheAppendContract():  BTW: the OPTIONAL publisher PKH has a value: \'' + optionalPublisherPkhX + '\'<=====\n')
		maybePublisherPKH = optionalPublisherPkhX
	}

	////////
	//////// NOTE: this must match one of the contract's public functions. In our case:
	////////  append(preimage, newPKH, newBlock, newContractOutputAmount, changePKH, changeSats, numOutputs)
	//                                         new PKH        new block                  amount            + changePKH           + changeSats      + maybeContinueScript        + maybePubPKH               +numOutputs  //+ funcIndex
	const scriptSig = preimage + ' '                   + newPayoutPKHX + ' '
					+ newBlockHexX + ' '               + newSmallestAmountASM + ' '
					+ extFundingChangePkhX + ' '       + changeASM + ' '
					+ optionalContinueScriptHexX + ' '
                    + optionalEbfraScriptHexX + ' '  // only present if current builder vote is NO
                    + maybePublisherPKH + ' '
                    + int2Asm( optionalAdditionalBalance ) + ' '
					+ numOutputsASM + ' '
                    + preData.outpoint0 + ' '
                    + preData.outpoint1
                    //+ ' ' + functionIndex

    // a blank script crashes simpleHash(), so, for display purposes, use '00' instead of blank
    const ebraScriptToDisplayHash = optionalEbfraScriptHexX.length === 0 ? '00' : optionalEbfraScriptHexX
//FIXME: do the same for other scripts - anywhere we use simpleHash()

    console.log('executeTheAppendContract():  BTW: scriptSig is preimage +\n',
                '  newPayoutPKH:            ', newPayoutPKHX, ' +\n',
                '  newBlockHex:             ', newBlockHexX, ' +\n',
                '  newSmallestAmount:       ', newSmallestAmountASM, '(LE Hex) +\n',
                '  changePKH:               ', extFundingChangePkhX, ' +\n',
                '  change in satoshis       ', changeASM, ' ( ' + preData.change + ' decimal)+\n',
                '  {optionalContinueScript}  (length ' + optionalContinueScriptHexX.length/2 + ', hash of '
                                            + simpleHash(optionalContinueScriptHexX) + ') +\n',
                '  {optionalEbfraScript}  (length ' + optionalEbfraScriptHexX.length/2 + ', hash of '
                                            + simpleHash(ebraScriptToDisplayHash) + ') +\n',
                '  optionalPublisherPKH:    ', maybePublisherPKH, ' +\n',
                '  optionalAdditionalBalance:', optionalAdditionalBalance, ' + \n',
                '  numberOfOutputs:         ', numOutputsASM, '+\n',
                '  outpoint0:               ', preData.outpoint0, '+\n',
                '  outpoint1:               ', preData.outpoint1)  //, ' + ', functionIndex)

	console.log('executeTheAppendContract():  BTW: EXT_FUNDING_CHANGE_PKH X is ', extFundingChangePkhX, '  <=========<<<<<<')
	console.log('executeTheAppendContract():  BTW:  unlocking with scriptSig of preimage + ... + ... + change of ', preData.change)
	console.log('executeTheAppendContract(): === UNLOCKING FUNDED SCRIPT.......========= - BUILDING from txid ', lockingTxidX)
    const { rawTx, feeWePaid } = await unlockFundedMultiScriptTxLockTime( funding,
                            scriptSig, lockingTxidX,
                            scriptPubKeyX, oldAmountX,
                            newScriptPubKeysX, newAmounts,
                            payScriptsQ, payoutsAmount,
                            newBlockIntX, outIdxOfContractWeWant,
                            feePerKB)

    if ( optionalSilentBuild ) {
        const hashTxSerial = bsv.crypto.Hash.sha256sha256( Buffer.from(rawTx, 'hex') ).toString('hex');
        console.log('executeTheAppendContract(): not-yet-flipped HASH of txSerial: ', hashTxSerial);
        const finalHash = swapEndian( hashTxSerial )
        console.log('executeTheAppendContract(): flipped (final) HASH of txSerial: ', finalHash);

        return {
            txid: finalHash,
            rawTx: rawTx,
            change: preData.change
        }
    }

    let txid
    console.log("btw: buildBogusTx: " + window.buildBogusTx);
    if ( window.buildBogusTx ) {
        const hashTxSerial = bsv.crypto.Hash.sha256sha256( Buffer.from(rawTx, 'hex') ).toString('hex');
        console.log('executeTheAppendContract(): not-yet-flipped HASH of txSerial: ', hashTxSerial);
        txid = '11111111111111111111111111111111' + hashTxSerial.substring(32,64)
        console.log('Won\'t broadcast. Forming a BOGUS txid: ' + txid)
    } else {
	    txid = await sendSerializeTx(rawTx, [funding.bestUtxo], theChangeOutIndex, theChange, funding.changePKH);
	    console.log('executeTheAppendContract():   built and broadcast. executeMultiContract:  txid: ', txid)
    }

	return {
		txid: txid,
		rawTx: rawTx,
		change: preData.change
	}
} // executeTheAppendContract()

// Grab the UTXOs just ONCE before calling multiple low-level functions that need them
async function prepFundingParams(altKeyX, calledFrom, optionalUTXOs = null, forceWoC = false) {
    // NOTE: executeTheAppendContract() may send an optionalUTXOs - since it
    //       may build up a series of chained transactions (and use prevTx funding)

    //FIXME: this stuff doesn't usually change. Make available from somewhere else?
    const privateKey = new bsv.PrivateKey( altKeyX );
    const specifiedPubKey = bsv.PublicKey.fromPrivateKey( privateKey )
    const specifiedBsvChangePKH = bsv.crypto.Hash.sha256ripemd160(specifiedPubKey.toBuffer('hex')).toString('hex')

    let utxos
    let arrayToCheck
    let best = null
    if ( optionalUTXOs && optionalUTXOs !== null ) {
        // We believe utxos CANNOT be null - it should have thrown if so

        //  FIXME: maybe not needed: .slice()
        utxos = optionalUTXOs.slice()



        arrayToCheck = utxos
    } else {
        utxos = await fetchUtxos( privateKey.toAddress(), true, false, "prepFundingParams")
        console.warn("prepFundingParams(): utxos returned: ", utxos)

        // We believe that utxos CANNOT be null - it would have thrown if so
        // We believe (when getting TWO batches) that utxos[0] CANNOT be null - it would have thrown if so
        arrayToCheck = utxos
    }

    console.warn("prepFundingParams(): arrayToCheck: ", arrayToCheck)

//FIXME: consider having fetchUtxos() call getBestUtxoChoice( ?
    const utxoIdxForFunds = getBestUtxoChoice(arrayToCheck)
    if ( utxoIdxForFunds === -1 ) {
        throw new Error("44821: FAILED to find the BEST UTXO")
    }
    console.log("prepFundingParams(): best UTXO (index " + utxoIdxForFunds + "): ", arrayToCheck[utxoIdxForFunds])
    best = arrayToCheck[utxoIdxForFunds]

    console.log("prepFundingParams(): utxos: ", utxos)

    return {
        key:        altKeyX,
        privateKey: privateKey,
        utxos:      utxos,
        bestUtxo:   best,
        calledFrom: calledFrom,

        changePKH: specifiedBsvChangePKH,
    }
}

// This is for contract's continue()   (publish/renew - continues indefinitely)
// This has an option to include an ADDITIONAL (UPDATE) output
//FIXME: soon, remove claimExpired? No.

/**
 * For the CONTINUE contract.
 * WARNING: un-obvious feature:
 *          If sigResultX.signature is null, use sigResultX.sigHexLE field instead
 *          The former is a BigInt, the latter is a LE hex.
 *
 * @param {*} altKeyX
 * @param {*} lockingTxidX
 * @param {*} scriptPubKeyX
 * @param {*} oldAmountX
 * @param {*} newScriptPubKeyX
 * @param {*} newAmountX
 * @param {*} payScriptsQ
 * @param {*} payoutsAmount
 * @param {*} newBlockIntX
 * @param {*} extFundingChangePkhX
 * @param {*} contentX
 * @param {*} txDescriptorX
 * @param {*} txIdX
 * @param {*} pcodeX
 * @param {*} ownerPkhX
 * @param {*} priceInSatsX
 * @param {*} publisherPkhX
 * @param {*} claimExpiredX
 * @param {*} purchaseThisX
 * @param {*} sigResultX
 * @param {*} pubPubKeyX
 * @param {*} optionalUpdateScriptHexCodeX
 */
//export /* */
async function executePubUpdateContract(altKeyX,
        lockingTxidX,
        scriptPubKeyX, oldAmountX,
        newScriptPubKeyX, outputAmounts, smallestAmount,
        payScriptsQ, payoutsAmount,
        newBlockIntX,
        extFundingChangePkhX,
        contentNameX, contentX, txDescriptorX,
        txIdX, pcodeX,
        ownerP2PkhX,
        rabinPkhOfPotentialBuyerX,
        //potentialSaleMinBlockIntX,  NOT passed in
        //potentialSaleFlagIntX,        NOT passed in
        priceInSatsX,
        publisherPkhX, claimExpiredX,
        //purchaseThisX,
        sigResultX, pubPubKeyX,
        optionalUpdateScriptHexCodeX,
        optionalAskBidScriptHexCodeX,
    optionalAdditionalBalance    ) {     // new param: normally must be zero. If newMode is K, can be positive (added to output amount)
                                         // NOTE: that this addtional amount should have ALREADY been applied to the outputAmounts[0] (the 6th params)
    //             for contract function 'continue()'

    console.log('[executePubUpdateContract] UNPACKING sigResult...', sigResultX)
    const thePadding = sigResultX.paddingBytes
    console.log('[executePubUpdateContract]: padding: ' + thePadding)
    console.log('[executePubUpdateContract]: sig: ' + sigResultX.signature)
    console.warn('[executePubUpdateContract]: sigHexLE: ' + sigResultX.sigHexLE)

    //console.warn('[executePubUpdateContract]: outputAmounts[]: ', outputAmounts)

    //WARNING: un-obvious feature:
    //         if .signature is null, use .sigHexLE field instead
    let theSig
    if ( sigResultX.signature !== null ) {
        theSig = int2Asm(sigResultX.signature)
        console.warn("   using signature, and calculating sigHexLE (normal case)")
    } else {
        theSig = sigResultX.sigHexLE
        console.warn("   using ALTERNATIVE: sigHexLE")
    }
    console.log('[executePubUpdateContract]: theSig: ' + theSig)

    console.log(' === GETTING SIGHASHPREIMAGE.......=========')
    const outIdxOfContractWeWantX = 0

    let feePerKB = getFeePerKB(0)

    console.log(' === So this may be important. We want newScriptPubKeysX to be array. it has len ', newScriptPubKeyX.length, " <<------")

    const funding = await prepFundingParams(altKeyX, 'executePubUpdateContract')

    let attemptsCount = 0
    let lowerBoundFailedRateAttempt = 100000
    let upperBoundFailedRateAttempt = 0
    let doneTrying = false
    let raw
    let theChange
    let theChangeOutIndex = -1 //NEW: reported to sendSerializeTx() so it might HELP manage UTXOs
    do {
        attemptsCount++

        const preData = await getMultiSighashPreimageFundedLockTime(funding,
                                lockingTxidX,
                                scriptPubKeyX, oldAmountX,
                                newScriptPubKeyX, outputAmounts,
                                payScriptsQ, payoutsAmount,
                                newBlockIntX, outIdxOfContractWeWantX,
                                feePerKB)
        // at some point change of 0 sats will mean NO new UTXO
        theChangeOutIndex = preData.change > 0 ? preData.outputIdxForChange : -1

        console.log(' === Got preimage data: ', preData)
        const preimage = preData.preimage
        const preimageLength = preimage.length
        console.log(' === preimage has length of ', preimageLength)

        // By using int2Asm() we corectly handle satoshis less than 256 - like zero, for finalPost
        const smallestAmountASM = int2Asm(smallestAmount)  //twoByteLE(newAmountX)

        const newBlockHexX = fourByteLE(newBlockIntX)
        const priceInSatsLE = fiveByteLE( priceInSatsX );   // NOTE: this is a 5-byte price (and assuming actual price converts to just 4 bytes)
        const changeASM = int2Asm(preData.change)

    //    const functionIndex = int2Asm( 2 )  // contract public function 2 is continue()  (publish)
        ////////
        //////// NOTE: this must match one of the contract's public functions.
        const scriptSig = preimage + ' '             + newBlockHexX + ' '
                        + smallestAmountASM + ' '
                        + extFundingChangePkhX + ' ' + changeASM + ' '
                        + contentNameX + ' '         + contentX + ' '
                        + txDescriptorX + ' '        + txIdX + ' '
                        + pcodeX + ' '
                        + priceInSatsLE + ' '
                        + ownerP2PkhX + ' '
                        + publisherPkhX + ' '
                        + claimExpiredX + ' '
                        + theSig + ' '
                        + thePadding + ' '
                        + pubPubKeyX + ' '
                        + rabinPkhOfPotentialBuyerX + ' '
                        + optionalUpdateScriptHexCodeX + ' '
                        + optionalAskBidScriptHexCodeX + ' '
                        + int2Asm( optionalAdditionalBalance )                // new int param to add funds to claimed asset - only mode K. Otherwise must be zero
                        //+ ' ' + functionIndex

        console.log('- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -')
        console.log('CONTINUE ScriptSig is preimage +',
                '\n  newBlock:         ', newBlockHexX,
                '\n  smallestAmount:   ', smallestAmountASM,
                '\n  changePKH:        ', extFundingChangePkhX,
                '\n  changeAmount:     ', changeASM + ' LE  (', preData.change, ' decimal)',
                '\n     [ contentName: ', contentNameX,
                '\n       content:     ', contentX,
                '\n       txDescr:     ', txDescriptorX,
                '\n       txId:        ', txIdX,
                '\n       pcode:       ', pcodeX, ' ]',
                '\n  price:            ', priceInSatsLE,
                '\n  ownerP2PKH:       ', ownerP2PkhX,
                '\n  publisherRabinPKH:', publisherPkhX,
                '\n  specialOp (formerly claimExpired):', claimExpiredX,
            //    '\n  //purchaseThis:     ', purchaseThisX,
                '\n  signature:       (', sigResultX.signature, ' - int form for debugger. hex we build with: \'theSig\')',
                '\n       BTW: (theSig is ' + theSig + ')',
                '\n  padding:          ', thePadding, '  (no FF pre-pend)',
                '\n  publisher PubKeyLE: ', pubPubKeyX, ' (but use int form for debugger)',
                '\n  rabinPkhOfPotentialBuyer:', rabinPkhOfPotentialBuyerX, '  <-- new',
                '\n  {optionalUpdateScriptStr of length ', optionalUpdateScriptHexCodeX.length/2, /*', hash of ' + simpleHash(optionalUpdateScriptHexCodeX) + */'}',
                '\n  {optionalAskBidScriptStr of length ', optionalAskBidScriptHexCodeX.length/2, /*', hash of ' + simpleHash(optionalAskBidScriptHexCodeX) + */'}',
                '\n  optionalAdditionalBalance: ', optionalAdditionalBalance, "\n"
                )
                                        // +   func ', functionIndex)
        console.log('- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -')

        console.log(' === UNLOCKING FUNDED SCRIPT.......========= - BUILDING from txid ', lockingTxidX)

        const { rawTx, feeWePaid } = await unlockFundedMultiScriptTxLockTime(funding,
                            scriptSig, lockingTxidX,
                            scriptPubKeyX, oldAmountX,
                            newScriptPubKeyX, outputAmounts,
                            payScriptsQ, payoutsAmount,
                            newBlockIntX, outIdxOfContractWeWantX,
                            feePerKB)

        raw = rawTx

        const { doneAdjustingFee, newFeePerKB, newLowerBound, newUpperBound }
                        = evaluateFee(  attemptsCount,
                                        feePerKB,
                                        feeWePaid,
                                        rawTx.length / 2,
                                        lowerBoundFailedRateAttempt,
                                        upperBoundFailedRateAttempt)
        doneTrying = doneAdjustingFee
        feePerKB   = newFeePerKB
        lowerBoundFailedRateAttempt = newLowerBound
        upperBoundFailedRateAttempt = newUpperBound

        theChange = preData.change
    } while ( !doneTrying )

    //alert("Done building the QUARTERLY tx - after " + attemptsCount + " tries at a reasonable fee")

    console.warn("Done building the QUARTERLY tx - after " + attemptsCount + " tries at a reasonable fee")

    let txid
    console.log("btw: buildBogusTx: " + window.buildBogusTx);
    if ( window.buildBogusTx ) {
        const hashTxSerial = bsv.crypto.Hash.sha256sha256( Buffer.from(raw, 'hex') ).toString('hex');
        console.log('executePubUpdateContract(): not-yet-flipped HASH of txSerial: ', hashTxSerial);
        txid = '11111111111111111111111111111111' + hashTxSerial.substring(32,64)
        console.log('Won\'t broadcast. Forming a BOGUS txid: ' + txid)
    } else {
        console.log("ABOUT TO send serialized raw tx of len " + raw.length)
        console.log("change index: " + theChangeOutIndex)
        console.log("change sats: " + theChange)
        txid = await sendSerializeTx(raw, [funding.bestUtxo], theChangeOutIndex, theChange, funding.changePKH);
        console.log('    executed (and broadcast) CONTINUE/PUBLISH Contract:  txid: ', txid)
    }

    return {
		txid: txid,
		rawTx: raw,
        change: theChange
    }
} // executePubUpdateContract()

//export /* */
async function executeEbfraContract(altKeyX,
        lockingTxidX,
        scriptPubKeyX, oldAmountX,
        newScriptPubKeyX, outputAmounts,
        payScriptsQ,
        payoutsAmount,

        extFundingChangePkhX,

    newBlockIntX,
        operationTypeInt,
        sigResultX, pubPubKeyX,
        prevOutIdxX) {

    console.log('[executeEbfraContract] UNPACKING sigResult...', sigResultX)
    const thePadding = sigResultX.paddingBytes
    console.log('[executeEbfraContract]: padding: ' + thePadding)
    console.log('[executeEbfraContract]: sig: ' + sigResultX.signature)
    console.warn('[executeEbfraContract]: sigHexLE: ' + sigResultX.sigHexLE)

    //WARNING: un-obvious feature:
    //         if .signature is null, use .sigHexLE field instead
    let theSig
    if ( sigResultX.signature !== null ) {
        theSig = int2Asm(sigResultX.signature)
        console.warn("   using signature, and calculating sigHexLE (normal case)")
    } else {
        theSig = sigResultX.sigHexLE
        console.warn("   using ALTERNATIVE: sigHexLE")
    }
    console.log('[executeEbfraContract]: theSig: ' + theSig)

    console.log(' === GETTING SIGHASHPREIMAGE.......=========')
    const outIdxOfContractWeWantX = prevOutIdxX

//FIXME: will have to adjust this for bounty-claiming
    let feePerKB
    if ( operationTypeInt === 1 ) {
        feePerKB = getFeePerKB(2) // finalPost  EBFRA YES
    } else if ( operationTypeInt === 2 ){
        feePerKB = getFeePerKB(3) // finalPost  EBFRA NO
    } else {
        console.error("WHAT? operation (" + operationTypeInt + ") should be just 1 or 2. (well, or 3 at some point")
        throw new Error("57668: invalid operation type for EBFRA: " + operationTypeInt)
    }

    const funding = await prepFundingParams(altKeyX, 'executeEbfraContract')

    console.log(' === So this may be important. We want newScriptPubKeysX to be array. it has len ', newScriptPubKeyX.length, " <<------")
    const preData = await getMultiSighashPreimageFundedLockTime(funding,
                            lockingTxidX,
                            scriptPubKeyX, oldAmountX,
                            newScriptPubKeyX, outputAmounts,
                            payScriptsQ, payoutsAmount,
                            newBlockIntX, outIdxOfContractWeWantX,
                            feePerKB)

    const theChange = preData.change
    // at some point change of 0 sats will mean NO new UTXO
    const theChangeOutIndex = preData.change > 0 ? preData.outputIdxForChange : -1

    console.log(' === Got preimage data: ', preData)
    const preimage = preData.preimage
    const preimageLength = preimage.length
    console.log(' === preimage has length of ', preimageLength)

    // By using int2Asm() we corectly handle satoshis less than 256 - like zero, for finalPost
    const changeASM = int2Asm(preData.change)

    const opTypeASM = int2Asm(operationTypeInt)
    const newBlockHexX = fourByteLE( newBlockIntX )
//    const functionIndex = int2Asm( 2 )
    ////////
    //////// NOTE: this must match one of the contract's public functions.
    const scriptSig = preimage + ' '             + newBlockHexX + ' '
                    + extFundingChangePkhX + ' ' + changeASM + ' '

                    + opTypeASM + ' '
                    + theSig + ' '
                    + thePadding + ' '
                    + pubPubKeyX
                    //+ ' ' + functionIndex

    console.log('- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - ')
    console.log('EBFRA Announce ScriptSig is preimage +',
            '\n  newBlock:         ', newBlockHexX,
            '\n  changePKH:        ', extFundingChangePkhX,
            '\n  changeAmount:     ', changeASM + ' LE  (', preData.change, ' decimal)',
            '\n  opType:           ', opTypeASM,
            '\n  signature:       (', sigResultX.signature, ' - int form for debugger. hex we build with: \'theSig\')',
            '\n       BTW: (theSig is ' + theSig + ')',
            '\n  padding:          ', thePadding, '  (no FF pre-pend)',
            '\n  publisher PubKeyLE: ', pubPubKeyX, ' (but use int form for debugger)')
                                    // +   func ', functionIndex)
    console.log('- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - ')

    console.log(' === UNLOCKING FUNDED SCRIPT.......========= - BUILDING from txid ', lockingTxidX)

    const { rawTx, feeWePaid } = await unlockFundedMultiScriptTxLockTime(funding,
                        scriptSig, lockingTxidX,
                        scriptPubKeyX, oldAmountX,
                        newScriptPubKeyX, outputAmounts,
                        payScriptsQ, payoutsAmount,
                        newBlockIntX, outIdxOfContractWeWantX,
                        feePerKB)

    let txid
    console.log("btw: buildBogusTx: " + window.buildBogusTx);
    if ( window.buildBogusTx ) {
        const hashTxSerial = bsv.crypto.Hash.sha256sha256( Buffer.from(rawTx, 'hex') ).toString('hex');
        console.log('executePubUpdateContract(): not-yet-flipped HASH of txSerial: ', hashTxSerial);
        txid = '11111111111111111111111111111111' + hashTxSerial.substring(32,64)
        console.log('Won\'t broadcast. Forming a BOGUS txid: ' + txid)
    } else {
        console.log("ABOUT TO send serialized raw tx of len " + rawTx.length)
        txid = await sendSerializeTx(rawTx, [funding.bestUtxo], theChangeOutIndex, theChange, funding.changePKH);
        console.log('    executed (and broadcast) CONTINUE/PUBLISH Contract:  txid: ', txid)
    }

    return {
		txid: txid,
		rawTx: rawTx,
        change: preData.change
    }
} // executeEbfraContract()

//  copied from newUpdate.js
//
// This is for contract's update() - to update content multiple times per year (but ultimately stop at end of year)
//
// This has an option to include an ADDITIONAL (TRANSIENT) output
//export /* */
async function executePubTransContract(altKeyX,
        lockingTxidX,
        scriptPubKeyX, oldAmountX,
        newScriptPubKeyX, outputAmounts, smallestAmount, //newAmountX,
        payScriptsQ, payoutsAmount,
        newBlockIntX, extFundingChangePkhX,
        contentNameX, contentHexX,
        txDescriptorHexX, txToPublishHexX,
        publisherPkhX, updateSigX, sigPaddingX,
        pubPubKeyX,
        transientScriptHexString,
        guestPostPriceHexX,
        bitGroupParamsX,
        transModeHexCharX,
        finalPostX,
        whichParentOutputToUseX) {
    // for contract function 'update()'

    console.log(' === GETTING SIGHASHPREIMAGE.......=========')

    let feePerKB = getFeePerKB(finalPostX)

    const funding = await prepFundingParams(altKeyX, 'executePubTransContract')

    let attemptsCount = 0
    let lowerBoundFailedRateAttempt = 100000
    let upperBoundFailedRateAttempt = 0
    let doneTrying = false
    let raw
    let theChange
    let theChangeOutIndex = -1 //NEW: reported to sendSerializeTx() so it might HELP manage UTXOs
    do {
        attemptsCount++



        const newScriptPubKeysX = newScriptPubKeyX
        console.log(' === So this may be important. We want newScriptPubKeyX to be array of len 2. it has len ', newScriptPubKeyX.length, " <<------")
        const preData = await getMultiSighashPreimageFundedLockTime(funding,
                lockingTxidX,
                scriptPubKeyX, oldAmountX,
                newScriptPubKeyX, outputAmounts,
                payScriptsQ, payoutsAmount,
                newBlockIntX, whichParentOutputToUseX,
                feePerKB)
        // at some point change of 0 sats will mean NO new UTXO
        theChangeOutIndex = preData.change > 0 ? preData.outputIdxForChange : -1

        console.log(' === Got preimage data: ', preData)
        const preimage = preData.preimage
        const preimageLength = preimage.length
        console.log(' === preimage has length of ', preimageLength)

        const smallestAmountASM = int2Asm(smallestAmount)

        const newBlockHexX = fourByteLE(newBlockIntX)
        const changeASM = int2Asm(preData.change)

        const claimBountyX = int2Asm(0)

        //const functionIndex = int2Asm( 3 )  // contract public function 2 is publish()
        ////////
        //////// NOTE: this must match one of the contract's public functions. In our case:
        ////////  publish(preimage, newBlock, newContractOutputAmount, changePKH, changeSats, content, publisherPKH, publisherSig4Update, sigPadding, publisherPubKey)
        //                                     new block                     amount            + changePKH          + changeSats         + content          pub rabin PKH                sig                sigpadding         pub rabin pubkey      optionalTransient           // NO longer use func index
        const finalArgs = newBlockHexX + ' '         + smallestAmountASM + ' '
                        + extFundingChangePkhX + ' ' + changeASM + ' '
                        + contentNameX + ' '         + contentHexX + ' '
                        + txDescriptorHexX + ' '     + txToPublishHexX + ' '
                        + publisherPkhX + ' '        + updateSigX + ' '
                        + sigPaddingX + ' '          + pubPubKeyX + ' '
                        + transientScriptHexString + ' '
                        + guestPostPriceHexX + ' '
                        + bitGroupParamsX[0] + ' ' + bitGroupParamsX[1] + ' '
                        + bitGroupParamsX[2] + ' ' + bitGroupParamsX[3] + ' '
                        + transModeHexCharX + ' '
                        + int2Asm(finalPostX) + ' '
                        + claimBountyX // + ' ' +functionIndex
        console.log("UPDATE ScriptSig is preimage + " + finalArgs)
        const scriptSig = preimage + ' ' + finalArgs
        console.log('- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -')
        console.log('UPDATE scriptSig is preimage +\n'
                + '  newBlockHex       ' + newBlockHexX + ' +\n'
                + '  smallestAmount    ' + smallestAmountASM +' +\n'
                + '  changePKH         ' + extFundingChangePkhX + ' +\n'
                + '  change (sats)     ' + changeASM + ' (' + preData.change + ' decimal) +\n'
                + '  contentNameHex    ' + contentNameX + ' +\n'
                + '  contentHex        ' + contentHexX + ' +\n'
                + '  txDescrHex        ' + txDescriptorHexX +' +\n'
                + '  txToPublish       ' + txToPublishHexX + ' +\n'
                + '  publisherRabinPKH ' + publisherPkhX + ' +\n'
                + '  signature         ' + updateSigX + ' +\n'
                + '  padding           ' + sigPaddingX + ' +\n'
                + '  pubKey            ' + pubPubKeyX + ' +\n'
                + '  {transientScriptStr of length ' + (transientScriptHexString.length/2) + ', hash of ' + simpleHash(transientScriptHexString) + '} +  <---<<\n'
                + '  guestPostPriceHex ' + guestPostPriceHexX + ' +\n'
                + '  bitGroupParam[0]  ' + bitGroupParamsX[0] + ' +\n'
                + '  bitGroupParam[1]  ' + bitGroupParamsX[1] + ' +\n'
                + '  bitGroupParam[2]  ' + bitGroupParamsX[2] + ' +\n'
                + '  bitGroupParam[3]  ' + bitGroupParamsX[3] + ' +\n'
                + '  transMode         ' + transModeHexCharX + ' +\n'
                + '  finalPost         ' + int2Asm(finalPostX) + ' +\n'
                + '  claimBounty       ' + claimBountyX) //, ' + ', functionIndex)
        console.log('- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -')

        console.log('  BTW: EXT_FUNDING_CHANGE_PKH X is ', extFundingChangePkhX, '  <=========<<<<<<')
        console.log('  BTW:  unlocking with scriptSig of preimage + ... + ... + change of ', preData.change)
        console.log(' === UNLOCKING FUNDED SCRIPT.......========= - BUILDING from txid ', lockingTxidX)


        const { rawTx, feeWePaid } = await unlockFundedMultiScriptTxLockTime(funding,
                    scriptSig, lockingTxidX,
                    scriptPubKeyX, oldAmountX,
                    newScriptPubKeysX, outputAmounts,
                    payScriptsQ, payoutsAmount,    //payScriptsQ is an array
                    newBlockIntX, whichParentOutputToUseX,
                    feePerKB)
        raw = rawTx

        const { doneAdjustingFee, newFeePerKB, newLowerBound, newUpperBound }
                        = evaluateFee(  attemptsCount,
                                        feePerKB,
                                        feeWePaid,
                                        rawTx.length / 2,
                                        lowerBoundFailedRateAttempt,
                                        upperBoundFailedRateAttempt)
        doneTrying = doneAdjustingFee
        feePerKB   = newFeePerKB
        lowerBoundFailedRateAttempt = newLowerBound
        upperBoundFailedRateAttempt = newUpperBound

        theChange = preData.change
    } while ( !doneTrying )

    console.warn("Done building the UPDATE tx - after " + attemptsCount + " tries at a reasonable fee. Will now broadcast...")

    let txid
    console.log("btw: buildBogusTx: " + window.buildBogusTx);
    if ( window.buildBogusTx ) {
        const hashTxSerial = bsv.crypto.Hash.sha256sha256( Buffer.from(raw, 'hex') ).toString('hex');
        console.log('executePubTransContract(): not-yet-flipped HASH of txSerial: ', hashTxSerial);
        txid = '11111111111111111111111111111111' + hashTxSerial.substring(32,64)
        console.log('Won\'t broadcast. Forming a BOGUS txid: ' + txid)
    } else {
        txid = await sendSerializeTx(raw, [funding.bestUtxo], theChangeOutIndex, theChange, funding.changePKH);
        console.log('    executed (and broadcast) PUBLISH Contract:  txid: ', txid)
    }

    return {
		txid: txid,
		rawTx: raw,
        change: theChange
    }
}

//NOTE: feePerKB, and satsPerByte seem duplicative
//      HOWEVER, WARNING: the contract transactions are built with 'interesting' (or, maybe mis-applied)
//      function that doesn't seem to set the fee according to the target fee rate
function evaluateFee(attemptsCount, feePerKB, feeWePaid, rawLength, lowerBoundFailedRateAttempt, upperBoundFailedRateAttempt) {

    let doneAdjustingFee = false

    console.warn("ATTEMPT " + attemptsCount + ": We paid a fee of " + feeWePaid + " sats")
    console.warn("  tx is " + rawLength + " bytes")
    const satsPerByte = feeWePaid / rawLength
    console.warn("  That's " + satsPerByte + " sats/Byte  when using 'feePerKB' of " + feePerKB)

    const attemptedFeeRate = feePerKB
    let tooMuch = false
    let tooLittle = false
    let factor = 1
    if ( satsPerByte < MIN_RATE ) {
        console.warn("    We paid too little")
        tooLittle = true
        // paid too little

        if ( feeWePaid === 0 ) {
            //FIXME: This should not happen if we don't pass-in 0
            alert("While searching for an appropriate fee, we arrived at 0 sats. This may screw-up our iterations. REPORT THIS, please.")
        }

        console.warn("evaluateFee(): TOO LITTLE:  is feePerKB of " + feePerKB + " > lowerFailed of " + lowerBoundFailedRateAttempt + " ?")
        if ( feePerKB > lowerBoundFailedRateAttempt ) {
            console.warn("YES. it's greater. RAISING lower bound to that feePerKB  <------")
            // keep track, trying to zero-in on a good rate
            lowerBoundFailedRateAttempt = feePerKB
        }

        factor = TARGET_RATE / satsPerByte
        feePerKB = Math.round(feePerKB * factor)
        console.warn("Multiplying by increasing factor of " + factor + ", we have a NEW feePerKB of " + feePerKB)
        // BUT, don't go higher than we've already tried
        if (feePerKB > upperBoundFailedRateAttempt && upperBoundFailedRateAttempt !== 0) {
            feePerKB = Math.round( feePerKB * 0.91)
            console.warn("  PROBLEM: that would be higher than we've already tried - " + upperBoundFailedRateAttempt
                        + ", so, reducing to feePerKB of " + feePerKB)
            upperBoundFailedRateAttempt = feePerKB
        }

        if ( attemptedFeeRate === feePerKB ) {
            console.warn("We tried tweaking the fee rate, but it didn't really change. We're done. We'll pay a rate of "
                        + satsPerByte + " sats/Byte, or " + feeWePaid + " sats. TARGET rate: " + TARGET_RATE + " s/B")

            doneAdjustingFee = true
        }
    } else if ( satsPerByte > MAX_RATE ) {
        tooMuch = true
        console.warn("    We paid too much")
        // paid too much

        console.warn("evaluateFee(): TOO MUCH:  is feePerKB of " + feePerKB + " < upperFailed of " + upperBoundFailedRateAttempt + " ?")
        if ( feePerKB < upperBoundFailedRateAttempt ) {
            console.warn("YES. it's less. LOWERING upper bound to that feePerKB")
            // keep track, trying to zero-in on a good rate
            upperBoundFailedRateAttempt = feePerKB
        }

        factor = TARGET_RATE / satsPerByte
        feePerKB = Math.round(feePerKB * factor)
        console.warn("Multiplying by reducing factor of " + factor + ", we have a NEW feePerKB of " + feePerKB)
        // BUT, don't go lower than we've already tried
        if (feePerKB < lowerBoundFailedRateAttempt && lowerBoundFailedRateAttempt !== 100000 ) {
            feePerKB = Math.round( feePerKB * 1.1)
            console.warn("  PROBLEM: that would be lower than we've already tried - " + lowerBoundFailedRateAttempt
                        + ", so, raising to feePerKB of " + feePerKB)
            lowerBoundFailedRateAttempt = feePerKB
        }

        if ( feeWePaid === 1) {
            console.warn("WAIT. We tried tweaking the fee rate, but we were already at a fee of 1 sat, and were thinking THAT was too much. We're done. We'll pay a rate of "
                        + satsPerByte + " sats/Byte, or " + feeWePaid + " sats. TARGET rate: " + TARGET_RATE + " s/B")
            doneAdjustingFee = true
        } else if ( attemptedFeeRate === feePerKB ) {
            console.warn("WAIT. We tried tweaking the fee rate, but it didn't change. We're done. We'll pay a rate of "
                        + satsPerByte + " sats/Byte, or " + feeWePaid + " sats. TARGET rate: " + TARGET_RATE + " s/B")
            doneAdjustingFee = true
        }
    }

    return {
        doneAdjustingFee: (doneAdjustingFee || (satsPerByte >= MIN_RATE && satsPerByte <= MAX_RATE)),
        newFeePerKB:   feePerKB,
        newLowerBound: lowerBoundFailedRateAttempt,
        newUpperBound: upperBoundFailedRateAttempt,
        tooLittle:     tooLittle,
        tooMuch:       tooMuch,
        factor:        factor
    }
}

//
// This is for the Dialog contract - to send a message to the other participant
//
//export /* */
async function executeDialogContract(altKeyX, lockingTxidX,
        scriptPubKeyX, oldAmountX,
        newScriptPubKeyX, outputAmounts,
        payScriptsQ, payoutsAmount,
        newBlockIntX, extFundingChangePkhX,
        contentX,
        updateSigX, sigPaddingX,
        pubPubKeyX,
        finalPostX,
        whichParentOutputToUseX) {

    console.log(' === GETTING DIALOG SIGHASHPREIMAGE.......=========')

    const feePerKB = getFeePerKB(5) // FIXME: and if finalPostX?

    const funding = await prepFundingParams(altKeyX, 'executeDialogContract')

    const newScriptPubKeysX = newScriptPubKeyX
    const preData = await getMultiSighashPreimageFundedLockTime(funding,
            lockingTxidX,
            scriptPubKeyX, oldAmountX,
            newScriptPubKeyX, outputAmounts,
            payScriptsQ, payoutsAmount,
            newBlockIntX, whichParentOutputToUseX,
            feePerKB)

    const theChange = preData.change
    // at some point change of 0 sats will mean NO new UTXO
    const theChangeOutIndex = preData.change > 0 ? preData.outputIdxForChange : -1

    console.log(' === Got Dialog preimage data: ', preData)
    const preimage = preData.preimage
    const preimageLength = preimage.length
    console.log(' === dialog preimage has length of ', preimageLength)

    const newBlockHexX = fourByteLE(newBlockIntX)
    const changeASM = int2Asm(preData.change)

    const claimBountyX = int2Asm(0)

    const finalArgs = newBlockHexX + ' '
                    + extFundingChangePkhX + ' ' + changeASM + ' '
                    + contentX + ' '
                    + updateSigX + ' '
                    + sigPaddingX + ' '
                    + pubPubKeyX + ' '
                    + int2Asm(finalPostX) + ' '
                    + claimBountyX // + ' ' +functionIndex
    console.log("DIALOG ScriptSig is preimage + " + finalArgs)
    const scriptSig = preimage + ' ' + finalArgs
    console.log('- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -')
    console.log('DIALOG scriptSig is preimage +\n'
            + '  newBlockHex       ' + newBlockHexX + ' +\n'
            + '  changePKH         ' + extFundingChangePkhX + ' +\n'
            + '  change (sats)     ' + changeASM + ' (' + preData.change + ' decimal) +\n'
            + '  contentHex        ' + contentX + ' +\n'
            + '  signature         ' + updateSigX + ' +\n'
            + '  padding           ' + sigPaddingX + ' +\n'
            + '  pubKey            ' + pubPubKeyX + ' +\n'
            + '  finalPost         ' + int2Asm(finalPostX) + ' +\n'
            + '  claimBounty       ' + claimBountyX) //, ' + ', functionIndex)
    console.log('- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -')

    console.log('  BTW: EXT_FUNDING_CHANGE_PKH X is ', extFundingChangePkhX, '  <=========<<<<<<')
    console.log('  BTW:  unlocking with scriptSig of preimage + ... + ... + change of ', preData.change)
    console.log(' === UNLOCKING FUNDED SCRIPT.......========= - BUILDING from txid ', lockingTxidX)


    const { rawTx, feeWePaid } = await unlockFundedMultiScriptTxLockTime(funding,
                scriptSig, lockingTxidX,
                scriptPubKeyX, oldAmountX,
                newScriptPubKeysX, outputAmounts,
                payScriptsQ, payoutsAmount,    //payScriptsQ is an array
                newBlockIntX, whichParentOutputToUseX,
                feePerKB)

    let txid
    console.log("btw: buildBogusTx: " + window.buildBogusTx);
    if ( window.buildBogusTx ) {
        const hashTxSerial = bsv.crypto.Hash.sha256sha256( Buffer.from(rawTx, 'hex') ).toString('hex');
        console.log('executeDialogContract(): not-yet-flipped HASH of txSerial: ', hashTxSerial);
        txid = '11111111111111111111111111111111' + hashTxSerial.substring(32,64)
        console.log('Won\'t broadcast. Forming a BOGUS txid: ' + txid)
    } else {
        //FIXME: do other contract functions re-try? OR, do they NOT broadcast, and keep the logic higher
        txid = await sendSerializeTx(rawTx, [funding.bestUtxo], theChangeOutIndex, theChange, funding.changePKH);
        console.log('    executed (and broadcast) DIALOG Contract:  txid: ', txid)
    }

    return {
		txid: txid,
		rawTx: rawTx,
        change: preData.change
    }
}


//export /* */
async function executeBitGroupContract(altKeyX,
        lockingTxidX,
        scriptPubKeyX, oldAmountX,
        newScriptPubKeyX, outputAmounts, //smallestAmount, //newAmountX,
        payScriptsQ, payoutsAmount,

        cmdHex, userPost,
        newBlockIntX, extFundingChangePkhX,
        userNum, updateSigX, sigPaddingX,
        userRabinPubKeyX, otherUserNum, newUserRabinPKH, newUserP2PKH,
        newUserStatus, newUserName,
        tipSatoshis, tipeeUserNum,
        optionalGroupSubThreadName,
        finalPostX,
        claimBountyHex,
        optionalAdminGroupScriptX,

        whichParentOutputToUseX) {
    // for BitGroup contract function 'userCommand()'

    console.log(' === GETTING SIGHASHPREIMAGE.......=========')

    var feePerKB = getFeePerKB(finalPostX)
    if ( claimBountyHex !== "OP_0" ) {
        console.log("Setting special fee per kb because we're claiming the bounty")
        feePerKB = getBountyClaimFeePerKB()
    }

    const funding = await prepFundingParams(altKeyX, 'executeBitGroupContract')
    const newScriptPubKeysX = newScriptPubKeyX //FIXME: why bother. look at other funcs too.
    const preData = await getMultiSighashPreimageFundedLockTime(funding,
            lockingTxidX,
            scriptPubKeyX, oldAmountX,
            newScriptPubKeyX, outputAmounts,
            payScriptsQ, payoutsAmount,
            newBlockIntX, whichParentOutputToUseX,
            feePerKB)

    const theChange = preData.change
    // at some point change of 0 sats will mean NO new UTXO
    const theChangeOutIndex = preData.change > 0 ? preData.outputIdxForChange : -1

    console.log(' === Got preimage data: ', preData)
    const preimage = preData.preimage
    const preimageLength = preimage.length
    console.log(' === preimage has length of ', preimageLength)

    //const cmdHex          = int2Asm(cmd)
    const userNumHex      = int2Asm(userNum)
    const otherUserNumHex = int2Asm(otherUserNum)
    const newUserStatusHex= int2Asm(newUserStatus)
    const tipSatoshisHex  = int2Asm(tipSatoshis)
    const tipeeUserNumHex = int2Asm(tipeeUserNum)

    //const smallestAmountASM = int2Asm(smallestAmount)
    const newBlockHexX = fourByteLE(newBlockIntX)
    const changeASM = int2Asm(preData.change)
    //const claimBountyX = int2Asm(0)  // we'll have an explicit function for claiming bounty

    //const functionIndex = int2Asm( 3 )  // contract public function 2 is publish()
    const finalArgs = cmdHex + ' '               + userPost + ' '
                    + newBlockHexX + ' '
                    + extFundingChangePkhX + ' ' + changeASM + ' '
                    + userNumHex + ' '           + updateSigX + ' '
                    + sigPaddingX + ' '          + userRabinPubKeyX + ' '
                    + otherUserNumHex + ' '
                    + newUserRabinPKH + ' '      + newUserP2PKH + ' '
                    + newUserStatusHex + ' '     + newUserName + ' '
                    + tipSatoshisHex + ' '       + tipeeUserNumHex + ' '
                    + optionalGroupSubThreadName + ' '
                    + int2Asm(finalPostX) + ' '
                    + claimBountyHex + ' '
                    + optionalAdminGroupScriptX
                    // + ' ' +functionIndex
    console.log("BITGROUP ScriptSig is preimage + " + finalArgs)
    const scriptSig = preimage + ' ' + finalArgs
    console.log('- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -')
    console.log('BITGROUP scriptSig is preimage,\n'
            + '  command              ' + cmdHex + ',\n'
            + '  userPost             ' + userPost + ',\n'
            + '  newBlockHex          ' + newBlockHexX + ',\n'
            + '  changePKH            ' + extFundingChangePkhX + ',\n'
            + '  change (sats)        ' + changeASM + ', (' + preData.change + ' decimal) +\n'
            + '  userNum              ' + userNumHex + ',\n'
            + '  signature            ' + updateSigX + ',\n'
            + '  padding              ' + sigPaddingX + ',\n'
            + '  userRabinPubKey      ' + userRabinPubKeyX + ',\n'
            + '  otherUserNum         ' + otherUserNumHex + ',\n'
            + '  newUserRabinPKH      ' + newUserRabinPKH + ',\n'
            + '  newUserP2PKH         ' + newUserP2PKH + ',\n'
            + '  newUserStatus        ' + newUserStatusHex + ',\n'
            + '  newUserName          ' + newUserName + ',\n'
            + '  tipSatoshis          ' + tipSatoshisHex + ',\n'
            + '  tipeeUserNum         ' + tipeeUserNumHex + ',\n'
            + '  {groupSubthreadName} ' + optionalGroupSubThreadName + ',  <---<<\n'
            + '  finalPost            ' + int2Asm(finalPostX) + ',\n'
            + '  claimBounty          ' + claimBountyHex + ',\n'
            + '  optional admin script of len ' + optionalAdminGroupScriptX.length/2 + '\n'
            )
            //, ' + ', functionIndex)
    console.log('- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -')

    console.log('  BTW: EXT_FUNDING_CHANGE_PKH X is ', extFundingChangePkhX, '  <=========<<<<<<')
    console.log('  BTW:  unlocking with scriptSig of preimage + ... + ... + change of ', preData.change)
    console.log(' === UNLOCKING BITGROUP SCRIPT.......========= - BUILDING from txid ', lockingTxidX)


    const { rawTx, feeWePaid } = await unlockFundedMultiScriptTxLockTime(funding,
                scriptSig, lockingTxidX,
                scriptPubKeyX, oldAmountX,
                newScriptPubKeysX, outputAmounts,
                payScriptsQ, payoutsAmount,    //payScriptsQ is an array
                newBlockIntX, whichParentOutputToUseX,
                feePerKB)

    let txid
    console.log("btw: buildBogusTx: " + window.buildBogusTx);
    if ( window.buildBogusTx ) {
        const hashTxSerial = bsv.crypto.Hash.sha256sha256( Buffer.from(rawTx, 'hex') ).toString('hex');
        console.log('executeBitGroupContract(): not-yet-flipped HASH of txSerial: ', hashTxSerial);
        txid = '11111111111111111111111111111111' + hashTxSerial.substring(32,64)
        console.log('Won\'t broadcast. Forming a BOGUS txid: ' + txid)
    } else {
        txid = await sendSerializeTx(rawTx, [funding.bestUtxo], theChangeOutIndex, theChange, funding.changePKH);
        console.log('    executed (and broadcast) BITGROUP Contract:  txid: ', txid)
    }

    return {
		txid: txid,
		rawTx: rawTx,
        change: preData.change
    }
}

/**
 * Adds multiple user profiles, and transitions back to BitGroup
 */
//export /* */
async function executeAdminGroupContract(altKeyX,
        lockingTxidX,
        scriptPubKeyX, oldAmountX,
        newScriptPubKeyX, outputAmounts,
        payScriptsQ, payoutsAmount,

        newBlockIntX,             // FIXME: we still use an nLockTime <------
        extFundingChangePkhX,

        updateSigX,
        sigPaddingX,
        userRabinPubKeyX,

        numProfilesToAddX,
        profilesX,
        userNamesBlob,
        bitGroupScriptX,

        whichParentOutputToUseX) {
    // for AdministerGroup contract function 'loadUserProfiles()'

    console.log(' === GETTING SIGHASHPREIMAGE.......=========')

    var feePerKB = getFeePerKB(0)

    console.log("\n\nWAS GOING to use feePerKB of " + feePerKB)
    feePerKB *= 2
    console.log("Will DOUBLE it to " + feePerKB + "  < ----<<\n\n")

    const funding = await prepFundingParams(altKeyX, 'executeAdminGroupContract')
    const newScriptPubKeysX = newScriptPubKeyX //FIXME: why bother. look at other funcs too.
    const preData = await getMultiSighashPreimageFundedLockTime(funding,
            lockingTxidX,
            scriptPubKeyX, oldAmountX,
            newScriptPubKeyX, outputAmounts,
            payScriptsQ, payoutsAmount,
            newBlockIntX, whichParentOutputToUseX,
            feePerKB)

    const theChange = preData.change
    // at some point change of 0 sats will mean NO new UTXO
    const theChangeOutIndex = preData.change > 0 ? preData.outputIdxForChange : -1

    console.log(' === Got preimage data: ', preData)
    const preimage = preData.preimage
    const preimageLength = preimage.length
    console.log(' === preimage has length of ', preimageLength)

    const numProfilesToAddAsm = int2Asm(numProfilesToAddX)
    var newProfilesBytes = ""
    for ( var i = 0; i < numProfilesToAddX; i++) {
        newProfilesBytes += profilesX[i].userRabinPKH
        newProfilesBytes += profilesX[i].userP2PKH
        newProfilesBytes += profilesX[i].userRecentPostBlock
        newProfilesBytes += profilesX[i].userStatus
        newProfilesBytes += profilesX[i].userPaidBounty
    }

    //const newBlockHexX = fourByteLE(newBlockIntX)
    const changeASM = int2Asm(preData.change)

    //const functionIndex = int2Asm( 3 )  // contract public function 2 is publish()
    const finalArgs =
                    //+ newBlockHexX + ' '
                    extFundingChangePkhX + ' '
                    + changeASM + ' '

                    + updateSigX + ' '
                    + sigPaddingX + ' '
                    + userRabinPubKeyX + ' '

                    + numProfilesToAddAsm + ' '
                    + newProfilesBytes + ' '
                    + userNamesBlob + ' '
                    + bitGroupScriptX
                    // + ' ' +functionIndex
    console.log("ADMINISTERGROUP ScriptSig is preimage + " + finalArgs)
    const scriptSig = preimage + ' ' + finalArgs
    console.log('- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -')
    console.log('ADMINISTERGROUP scriptSig is preimage,\n'
            + '  changePKH            ' + extFundingChangePkhX + ',\n'
            + '  change (sats)        ' + changeASM + ', (' + preData.change + ' decimal) +\n'

            + '  signature            ' + updateSigX + ',\n'
            + '  padding              ' + sigPaddingX + ',\n'
            + '  userRabinPubKey      ' + userRabinPubKeyX + ',\n'

            + '  numProfilesToAdd     ' + numProfilesToAddAsm + ',\n'
            + '  profileBytes         ' + newProfilesBytes + ',\n'
            + '  userNamesBlob        ' + userNamesBlob + ',\n'
            + '  {BitGroupScript of length ' + bitGroupScriptX.length/2 + '}') //, ' + ', functionIndex)
    console.log('- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -')

    console.log('  BTW: EXT_FUNDING_CHANGE_PKH X is ', extFundingChangePkhX, '  <=========<<<<<<')
    console.log('  BTW:  unlocking with scriptSig of preimage + ... + ... + change of ', preData.change)
    console.log(' === UNLOCKING ADMINISTERGROUP SCRIPT.......========= - BUILDING from txid ', lockingTxidX)

    const { rawTx, feeWePaid } = await unlockFundedMultiScriptTxLockTime(funding,
                scriptSig, lockingTxidX,
                scriptPubKeyX, oldAmountX,
                newScriptPubKeysX, outputAmounts,
                payScriptsQ, payoutsAmount,    //payScriptsQ is an array
                newBlockIntX, whichParentOutputToUseX,
                feePerKB)

    let txid
    console.log("btw: buildBogusTx: " + window.buildBogusTx);
    if ( window.buildBogusTx ) {
        const hashTxSerial = bsv.crypto.Hash.sha256sha256( Buffer.from(rawTx, 'hex') ).toString('hex');
        console.log('executeAdminGroupContract(): not-yet-flipped HASH of txSerial: ', hashTxSerial);
        txid = '11111111111111111111111111111111' + hashTxSerial.substring(32,64)
        console.log('Won\'t broadcast. Forming a BOGUS txid: ' + txid)
    } else {
        txid = await sendSerializeTx(rawTx, [funding.bestUtxo], theChangeOutIndex, theChange, funding.changePKH);
        console.log('    executed (and broadcast) ADMINISTERGROUP Contract:  txid: ', txid)
    }

    return {
        txid: txid,
        rawTx: rawTx,
        change: preData.change
    }
}

// This is for claiming the bounty/balance of an Update contract, and ending its line
//export /* */
async function executeClaimUpdateBalance(altKeyX,
        lockingTxidX,
        scriptPubKeyX, oldAmountX,
        newBlockIntX,
        extFundingChangePkhX,
        whichParentOutputToUseX) {

    console.log(' === GETTING SIGHASHPREIMAGE.......=========')

    // For Updates, the contract amount (output value in satoshis) doesn't change.
    // It was set when spawned from Continue. EXCEPT if it's the final post of the line
    // (finalPost was set to 1) - in which case we remove the contract code altogether, and
    // allow an OP_FALSE OP_RETURN with output value of 0.
    //
    // In THIS case, however, there will not even be an OP_RETURN. The contract balance
    // will be aggregated in with the change, directed to the change PKH.
    var newScriptPubKeysX = []
    var newAmountX = 0
    var newAmounts = []
    var payScriptsQ = []
    const payoutsAmount = 0
//    const finalPost = 1

    const funding = await prepFundingParams(altKeyX, 'executeClaimUpdateBalance')
    //const feePerKB = getFeePerKB(finalPost)
    const feePerKB = getBountyClaimFeePerKB()
    const preData = await getMultiSighashPreimageFundedLockTime(funding,
            lockingTxidX,
            scriptPubKeyX, oldAmountX,
            newScriptPubKeysX, newAmounts,
            payScriptsQ, payoutsAmount,
            newBlockIntX, whichParentOutputToUseX,
            feePerKB)

    const theChange = preData.change
    // at some point change of 0 sats will mean NO new UTXO
    const theChangeOutIndex = preData.change > 0 ? preData.outputIdxForChange : -1

    console.log(' === Got preimage data: ', preData)
    const preimage = preData.preimage
    const preimageLength = preimage.length
    console.log(' === preimage has length of ', preimageLength)

    const newAmountASM = int2Asm(newAmountX)  //twoByteLE(newAmountX)
    const newBlockHexX = fourByteLE(newBlockIntX)
    const changeASM = int2Asm(preData.change)
    const contentNameX = ""
    const contentX = ""
    const txDescriptorHexX = ""
    const txToPublishHexX = ""
    const publisherPkhX = extFundingChangePkhX  //""  use extFundingChangePKH because it's the correct len. It's not actually used
    const updateSigX = int2Asm(0)
    const sigPaddingX = ""
    const pubPubKeyX = int2Asm(1) //FIXME 20-long?
    const transientScriptHexString = "11"
    const transModeHexCharX = '00'
    const finalPostX = int2Asm(1)
    const claimBounty = int2Asm(1)

    //const functionIndex = int2Asm( 3 )  // contract public function 2 is publish()
    ////////
    //////// NOTE: this must match one of the contract's public functions. In our case:
    ////////  publish(preimage, newBlock, newContractOutputAmount, changePKH, changeSats, content, publisherPKH, publisherSig4Update, sigPadding, publisherPubKey)
    //                                     new block                     amount            + changePKH          + changeSats         + content          pub rabin PKH                sig                sigpadding         pub rabin pubkey      optionalTransient           // NO longer use func index

    const finalArgs = newBlockHexX + ' '         + newAmountASM + ' '
                    + extFundingChangePkhX + ' ' + changeASM + ' '
                    + contentNameX + ' '         + contentX + ' '
                    + txDescriptorHexX + ' '     + txToPublishHexX + ' '
                    + publisherPkhX + ' '        + updateSigX + ' '
                    + sigPaddingX + ' '          + pubPubKeyX + ' '
                    + transientScriptHexString + ' '
                    + transModeHexCharX + ' '
                    + finalPostX + ' '
                    + claimBounty      // + ' ' +functionIndex
    console.log("UPDATE BOUNTY CLAIM ScriptSig is preimage + " + finalArgs)
    const scriptSig = preimage + ' ' + finalArgs
    console.log('- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -')
    console.log('CLAIM UPDATE BOUNTY scriptSig is preimage +\n'
            + '  newBlockHex       ' + newBlockHexX + ' +\n'
            + '  amount            ' + newAmountASM +' +\n'
            + '  changePKH         ' + extFundingChangePkhX + ' +\n'
            + '  change (sats)     ' + changeASM + ' (' + preData.change + ' decimal) +\n\n'

            + '  contentNameHex    ' + contentNameX + ' +\n'
            + '  contentHex        ' + contentX + ' +\n'
            + '  txDescrHex        ' + txDescriptorHexX +' +\n'
            + '  txToPublish       ' + txToPublishHexX + ' +\n'
            + '  publisherRabinPKH ' + publisherPkhX + ' +\n'
            + '  signature         ' + updateSigX + ' +\n'
            + '  padding           ' + sigPaddingX + ' +\n'
            + '  pubKey            ' + pubPubKeyX + ' +\n'
            + '  {transientScriptStr of length ' + (transientScriptHexString.length/2) + '} +  <---<<\n'
            + '  transMode         ' + transModeHexCharX + ' +\n'
            + '  finalPost         ' + finalPostX + ' +\n'
            + '  claimBounty       ' + claimBounty) //, ' + ', functionIndex)
    console.log('- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -')

    console.log('  BTW: EXT_FUNDING_CHANGE_PKH X is ', extFundingChangePkhX, '  <=========<<<<<<')
    console.log('  BTW:  unlocking with scriptSig of preimage + ... + ... + change of ', preData.change)
    console.log(' === UNLOCKING FUNDED SCRIPT.......========= - BUILDING from txid ', lockingTxidX)

    const { rawTx, feeWePaid } = await unlockFundedMultiScriptTxLockTime(funding,
                scriptSig, lockingTxidX,
                scriptPubKeyX, oldAmountX,
                newScriptPubKeysX, newAmounts,
                payScriptsQ, payoutsAmount,    //payScriptsQ is an array
                newBlockIntX, whichParentOutputToUseX,
                feePerKB)

    let txid
    console.log("btw: buildBogusTx: " + window.buildBogusTx);
    if ( window.buildBogusTx ) {
        const hashTxSerial = bsv.crypto.Hash.sha256sha256( Buffer.from(rawTx, 'hex') ).toString('hex');
        console.log('executeClaimUpdateBalance(): not-yet-flipped HASH of txSerial: ', hashTxSerial);
        txid = '11111111111111111111111111111111' + hashTxSerial.substring(32,64)
        console.log('Won\'t broadcast. Forming a BOGUS txid: ' + txid)
    } else {
        txid = await sendSerializeTx(rawTx, [funding.bestUtxo], theChangeOutIndex, theChange, funding.changePKH);
        console.log('    executed (and broadcast) BOUNTY CLAIM of UPDATE. txid:', txid)
    }

    return {
		txid: txid,
		rawTx: rawTx,
        change: preData.change
    }
}

//export /* */
function getSigParts(theContentBuffer, theRabinPrivKeyP, theRabinPrivKeyQ, theRabinPubKeyBigInt) {
        // NOTE: we use custom sign2() because we "store" and use rabin private keys as ascii
        let theSig = sign2(theContentBuffer.toString("hex"), theRabinPrivKeyP, theRabinPrivKeyQ, theRabinPubKeyBigInt)  //bcc: was a change to rabin.js
        console.log("\n[getSigParts]:  Signature = " + theSig.signature);
        console.log("[getSigParts]:  Sig hex = ", theSig.sigHex);                     //bcc: was a change to rabin.js
        console.log("[getSigParts]:  Padding Bytes = " + theSig.paddingByteCount);

        var theSigPadding = ''
        for ( var us = 0; us < theSig.paddingByteCount; us++ ) {
            theSigPadding = theSigPadding + '00'
        }

        console.log("[getSigParts]:  Padding = '" + theSigPadding + "'");

        let verificationResults = verify(theContentBuffer.toString("hex"), theSigPadding.length/2, theSig.signature, theRabinPubKeyBigInt);
        console.log("\n[getSigParts]:  Signature Verified = " + verificationResults);

        return {
            signature:    theSig.signature,
            sigHex:       theSig.sigHex,
            paddingBytes: theSigPadding
        }
}

//export /* */
function appendUserProfile( profilesArray, newRabinPKH, newP2PKH, blockNumOfMostRecentPost, status ) {
    const numUsers = profilesArray.length;

    // scan for duplicate user?

    profilesArray[ numUsers ] = {
        userRabinPKH: newRabinPKH,
        userP2PKH: newP2PKH,
        userRecentPostBlock: blockNumOfMostRecentPost,
        userStatus: oneByteLE(status),
        userPaidBounty: '00'
    }

    return profilesArray
}

//export /* */
function removeUserProfile( profilesArray, indexToRemove ) {

    const numUsers = profilesArray.length;
    var newProfilesArray = [];
    var j = 0;
    for( var i = 0; i < numUsers; i++ ) {
        if ( i === indexToRemove ) {
            // we skip this profile/entry
        } else {
            newProfilesArray[j] = profilesArray[i];
            j++
        }
    }

    return newProfilesArray;
}


// publishes data (mostly video right now) txs, using 'dataFeeRateDivider' to control fee rate
//export /* */
async function makeDataTx(privateKey, data, bitcomFlag, dataFeeRateDivider = 20) {

    let outputScript = "OP_FALSE OP_RETURN "
    if ( bitcomFlag ) {
        // This is the convention. It's not strictly necessary. It's playing nice with others.
        outputScript += "31394878696756345179427633744870515663554551797131707a5a56646f417574 "
    }
    outputScript += data

    //console.log("complete script asm: ", outputScript)

    console.log("adding output...")
    const newScriptPubKey = bsv.Script.fromASM(outputScript); //"OP_FALSE OP_RETURN 0102030405060708");

    console.log("newScriptPubKey: ", newScriptPubKey)
    //console.log("  okay?")

    console.log("SENDING:...")

    // NOTE: new dataFeeRateDivider parameter
    const newTx = await sendLockingTx(newScriptPubKey, privateKey, 0, dataFeeRateDivider);

    return newTx

} // makeDataTx()

async function getMultiSighashPreimageFundedLockTime(funding, lockingTxid,
        scriptPubKeyStr, inputAmount,
        newScriptPubKeyStrs, outputAmounts,
        payScriptsQ, payoutsAmount,
        blockNum, outIdxOfContractWeWant,
        feePerKB) {
//console.error("gMShPiFLT(): sPKStr is '" + scriptPubKeyStr + "'")
    const scriptPubKey = bsv.Script.fromASM(scriptPubKeyStr);

// Reduce logging, and unnecessary compute
/*
    const pubKey = bsv.PublicKey.fromPrivateKey(privateKey).toBuffer();
    var pubKeyHash = bsv.crypto.Hash.sha256ripemd160(pubKey);
    let PKH = pubKeyHash.toString('hex');
    console.log('    getMultiSighashPreimageFundedLockTime(): priv key PKH: ', PKH);
    let q = utxos;
    console.log('    getMultiSighashPreimageFundedLockTime(): key\'s utxos: ', q);
*/

    console.log('    getMultiSighashPreimageFundedLockTime(): calling buildFundedMultiScriptUnlockTxLockTime()... (with fresh utxos)');
    const unlockingTx = buildFundedMultiScriptUnlockTxLockTime(funding,
                            lockingTxid,
                            scriptPubKey, inputAmount,
                            newScriptPubKeyStrs, outputAmounts,
                            payScriptsQ, payoutsAmount,
                            blockNum, outIdxOfContractWeWant);

    setFeePerKB(unlockingTx, feePerKB)

console.warn("unlocking tx: ", unlockingTx)
console.warn("   0 prevTxId (LE): " + Buffer.from( unlockingTx.inputs[0].prevTxId ).toString('hex'))
console.warn("   0 prevTxId (BE): " + swapEndian( Buffer.from( unlockingTx.inputs[0].prevTxId ).toString('hex') ) )
console.warn("   0 outputIndex: " + unlockingTx.inputs[0].outputIndex )
console.warn("   0 outputIndex: " + fourByteLE(Number(unlockingTx.inputs[0].outputIndex)) )

if ( unlockingTx.inputs.length > 1 ) {
    console.warn("   1 prevTxId (LE): " + Buffer.from( unlockingTx.inputs[1].prevTxId ).toString('hex'))
    console.warn("   1 prevTxId (BE): " + swapEndian( Buffer.from( unlockingTx.inputs[1].prevTxId ).toString('hex') ) )
    console.warn("   1 outputIndex: " + unlockingTx.inputs[1].outputIndex )
    console.warn("   1 outputIndex: " + fourByteLE(Number(unlockingTx.inputs[1].outputIndex)) )
}

const outpointsToHash = swapEndian( Buffer.from( unlockingTx.inputs[0].prevTxId ).toString('hex') ) + fourByteLE( Number(unlockingTx.inputs[0].outputIndex) )
                    +   unlockingTx.inputs.length > 1 ?
                            swapEndian( Buffer.from( unlockingTx.inputs[1].prevTxId ).toString('hex') ) + fourByteLE( Number(unlockingTx.inputs[1].outputIndex) )
                                :
                            ''

console.log("   outpointsToHash = " + outpointsToHash)
var hashPrevouts = bsv.crypto.Hash.sha256sha256( Buffer.from(outpointsToHash,'hex') ).toString('hex');
console.warn('    HASH of previous outpoints (aka hashPrevouts): ', hashPrevouts);

//////////////////////////////////////////alert("contractSupport 3334: show unlocking tx. we want to know the input utxos...")

    const inOutpoint0 = swapEndian( Buffer.from( unlockingTx.inputs[0].prevTxId ).toString('hex') ) + fourByteLE( Number(unlockingTx.inputs[0].outputIndex) )
    const inOutpoint1 = unlockingTx.inputs.length > 1 ?
                                swapEndian( Buffer.from( unlockingTx.inputs[1].prevTxId ).toString('hex') ) + fourByteLE( Number(unlockingTx.inputs[1].outputIndex) )
                            :
                                ""

    console.log('    getMultiSighashPreimageFundedLockTime(): built. Now actually getting sighash preimage...');
    const preimage = bsv.Transaction.sighash.sighashPreimage(unlockingTx, SIGHASH_ALLANY /*SIGHASH_ALLANY try on tuesday * SIGHASH_TYPE_ALL*/, INPUT_IDX, scriptPubKey, new bsv.crypto.BN(inputAmount), FLAGS);
    //NOTE: we're assuming the unlocking tx doesn't change between now and the call to unlockFunded...()
    let fee1 = unlockingTx._inputAmount - unlockingTx._outputAmount;
    // This is crucial. Change is the final output. There could be 0, 1, 2, or 3 payouts
    //     NOTE: we add N-1 - where N is the number of contracts being output
    let changeOutputIndex = payScriptsQ.length + newScriptPubKeyStrs.length;
    console.log('    getMultiSighashPreimageFundedLockTime: CHANGE is on output ', changeOutputIndex);
    console.log('    getMultiSighashPreimageFundedLockTime: ', changeOutputIndex, ' = pSQ.len ', payScriptsQ.length, ' + ', newScriptPubKeyStrs.length, '  (cOI = PayScriptsQ.len + newScriptPubKeyStrs.len)');
    let satsChange = unlockingTx.outputs[changeOutputIndex]._satoshis;
    console.log('    getMultiSighashPreimageFundedLockTime: CHANGE is ', satsChange);
    console.log('    getMultiSighashPreimageFundedLockTime: FEE  is ', fee1);

    const outputIdxForChange = unlockingTx.outputs.length - 1
    return { preimage: preimage.toString('hex'),
        change: satsChange,
        outputIdxForChange: outputIdxForChange,

        fee: fee1,
        outpoint0: inOutpoint0,  // input outpoint
        outpoint1: inOutpoint1   // input outpoint
    };
};

async function getGeneralizedSighashPreimage( funding,
                                        lockingTxids,               // now an array
                                        outIdxsOfContractsWeWant,   // now an array <---- moved
                                        scriptPubKeyStr,            // now an array
                                        inputAmounts,               // now an array
                                        newScriptPubKeyStrs,
                                        outputAmounts,
                                        payScriptsQ,
                                        payoutsAmount,
                                        blockNum,
                                        feePerKB,
                                        nSeq = 0xfeffffff) {
    const numInputContracts = lockingTxids.length
    if( numInputContracts !== scriptPubKeyStr.length ||
        numInputContracts !== inputAmounts.length ||
        numInputContracts !== outIdxsOfContractsWeWant.length ) {
            throw new Error("17400:  (array) lengths of args 2-5 (base-1) must match");
    }

console.error("getGeneralizedSigHashPreimage: nSeq = ", nSeq)
    let scriptPubKeys = [];
    scriptPubKeys[0] = bsv.Script.fromASM(scriptPubKeyStr[0]);
    if ( numInputContracts === 2 ) {
        scriptPubKeys[1] = bsv.Script.fromASM(scriptPubKeyStr[1]);
    }

    //const pubKey = bsv.PublicKey.fromPrivateKey(privateKey).toBuffer();
    //var pubKeyHash = bsv.crypto.Hash.sha256ripemd160(pubKey);
    //let PKH = pubKeyHash.toString('hex');
    //console.log('    getGeneralizeSighashPreimage(): priv key PKH: ', PKH);

    console.log('    getGeneralizeSighashPreimage(): best utxo: ', funding.bestUtxo);
    console.log('    getGeneralizeSighashPreimage(): calling buildGeneralizedUnlockTx()... (with fresh utxo)');
    const unlockingTx = buildGeneralizedUnlockTx(   funding,
                                                    lockingTxids,               // now an array
                                                    outIdxsOfContractsWeWant,   // now an array
                                                    scriptPubKeys,              // now an array
                                                    inputAmounts,               // now an array
                                                    newScriptPubKeyStrs,
                                                    outputAmounts,
                                                    payScriptsQ,
                                                    payoutsAmount,
                                                    blockNum,
                                                    nSeq);

    console.log('    getGeneralizeSighashPreimage(): BACK from buildGeneralizeUnlockTx(). Will now set fee..')
    setFeePerKB(unlockingTx, feePerKB)
//xxxxx
    //NOTE: if this is a guest/hosted tx, the 0th preImage could be SIGHASH_SINGLEANY (instead of SIGHASH_ALLANY)
    //      The host would like to sign (build input params) only for its output - and not be
    //      concerned with how the guest outputs look
    //const sighashTypeForInput0 = hostGuestFlag ? SIGHASH_SINGLEANY : SIGHASH_ALLANY;
    console.log('    getGeneralizeSighashPreimage(): built. Now actually getting sighash preimage...');
    let preimages = [];
    preimages[0] = bsv.Transaction.sighash.sighashPreimage( unlockingTx,
                                                            SIGHASH_ALLANY, ///*SIGHASH_ALLANY try on tuesday */SIGHASH_TYPE_ALL,
                                                            /*INPUT_IDX*/ 0,
                                                            scriptPubKeys[0],
                                                            new bsv.crypto.BN(inputAmounts[0]),
                                                            FLAGS).toString('hex');
    if ( numInputContracts === 2 ) {
        console.log("    getting 2nd preimage")
        preimages[1] = bsv.Transaction.sighash.sighashPreimage( unlockingTx,
                                                                SIGHASH_ALLANY, ///*SIGHASH_ALLANY try on tuesday */SIGHASH_TYPE_ALL,
                                                                /*INPUT_IDX*/ 1,
                                                                scriptPubKeys[1],
                                                                new bsv.crypto.BN(inputAmounts[1]),
                                                                FLAGS).toString('hex');
    }

    //NOTE: we're assuming the unlocking tx doesn't change between now and the call to unlockFunded...()
    let fee1 = unlockingTx._inputAmount - unlockingTx._outputAmount;

    // This is crucial. Change is the final output. There could be 0, 1, 2, or 3 payouts
    //     NOTE: we add N-1 - where N is the number of contracts being output
    let changeOutputIndex = payScriptsQ.length + newScriptPubKeyStrs.length;
    console.log('    getGeneralizeSighashPreimage: CHANGE is on output ', changeOutputIndex);

    console.log('    getGeneralizeSighashPreimage: ', changeOutputIndex, ' = pSQ.len ', payScriptsQ.length,
                ' + ', newScriptPubKeyStrs.length, '  (cOI = PayScriptsQ.len + newScriptPubKeyStrs.len)');
    let satsChange = unlockingTx.outputs[changeOutputIndex]._satoshis;
    console.log('    getGeneralizeSighashPreimage: CHANGE is ', satsChange);
    console.log('    getGeneralizeSighashPreimage: FEE  is ', fee1);

    const inOutpoint0 = swapEndian( Buffer.from( unlockingTx.inputs[0].prevTxId ).toString('hex') ) + fourByteLE( Number(unlockingTx.inputs[0].outputIndex) )
    const inOutpoint1 = unlockingTx.inputs.length > 1 ?
                                swapEndian( Buffer.from( unlockingTx.inputs[1].prevTxId ).toString('hex') ) + fourByteLE( Number(unlockingTx.inputs[1].outputIndex) )
                            :
                                ""
    const inOutpoint2 = unlockingTx.inputs.length > 2 ?
                                swapEndian( Buffer.from( unlockingTx.inputs[2].prevTxId ).toString('hex') ) + fourByteLE( Number(unlockingTx.inputs[2].outputIndex) )
                            :
                                ""

    const outputIdxForChange = unlockingTx.outputs.length - 1
    return { preimages: preimages,
             change: satsChange,
             outputIdxForChange: outputIdxForChange,       // the CHANGE output index (base-0)
             fee: fee1,
             outpoint0: inOutpoint0,  // input outpoint
             outpoint1: inOutpoint1,  // input outpoint
             outpoint2: inOutpoint2,  // input outpoint
    };
};

// send tx to fund and unlock previously locked funds - BUT also specify the nLockTime
async function unlockFundedMultiScriptTxLockTime(funding,
        scriptSigStr, lockingTxid,
        scriptPubKeyStr, inputAmount,
        newScriptPubKeyStrs, outputAmounts,
        payScriptsQ, payoutsAmount,
        blockNum, outIdxOfContractWeWant,
        feePerKB) {

//console.error("unlockFundedMultiScriptTxLockTime(): funding: ", funding)

    const scriptSig = bsv.Script.fromASM(scriptSigStr);
    const scriptPubKey = bsv.Script.fromASM(scriptPubKeyStr);

    console.log('    unlockFundedMultiScriptTxLockTime(): calling build...');
    const unlockingTx = buildFundedMultiScriptUnlockTxLockTime(funding,
                            lockingTxid,
                            scriptPubKey, inputAmount,
                            newScriptPubKeyStrs, outputAmounts,
                            payScriptsQ, payoutsAmount,
                            blockNum, outIdxOfContractWeWant);

    setFeePerKB(unlockingTx, feePerKB)


    console.log('    unlockFundedMultiScriptTxLockTime(): got Tx. setting script (contract\'s)...');
    // set the scriptSig ON THE FIRST INPUT
    // FUNDED TXs have external funds at input 1, and contract at input 0
    unlockingTx.inputs[INPUT_IDX].setScript(scriptSig);


    // ONLY sign second input if there IS a 2nd input (funding)
    if ( unlockingTx.inputs.length > 1 ) {
        const txSig1 = new bsv.Transaction.Signature({
            publicKey: bsv.PublicKey.fromPrivateKey( funding.privateKey ),
            prevTxId: unlockingTx.inputs[1].prevTxId,
            outputIndex: unlockingTx.inputs[1].outputIndex,
            inputIndex: 1,
            signature: bsv.Transaction.Sighash.sign(unlockingTx, funding.privateKey, SIGHASH_TYPE_ALL, 1, unlockingTx.inputs[1].output.script, unlockingTx.inputs[1].output.satoshisBN),
            sigtype: SIGHASH_TYPE_ALL
        });
        unlockingTx.inputs[1].setScript(bsv.Script.buildPublicKeyHashIn(txSig1.publicKey, txSig1.signature.toDER(), txSig1.sigtype));
    } else {
        console.warn("There was no funding input to sign <---")
    }

    console.log('    unlockFundedMultiScriptTxLockTime(): here\'s our SIGNED unlocking tx:', unlockingTx);
    console.log('    unlockFundedMultiScriptTxLockTime(): ------------------------------------------');
    console.log('    unlockFundedMultiScriptTxLockTime(): returning unlockingAndFunding tx...');

    // when serializing, disable check for dusty outputs. The miners now accept 135 sats,
    // but the bsv library thinks that's dusty
    const txSerial = unlockingTx.serialize({disableDustOutputs: true, disableIsFullySigned: true});

    console.log('    unlockFundedMultiScriptTxLockTime(): serialized tx: ', txSerial);
//    var hashTx = bsv.crypto.Hash.sha256sha256(unlockingTx.toBuffer());
//    console.log('    unlockFundedMultiScriptTxLockTime(): HASH of serialized tx: ', hashTx);

    const feeWePaid = unlockingTx._inputAmount - unlockingTx._outputAmount
    console.log("we're done serializing... txSerial has length " + txSerial.length
            + ", and we paid a fee of " + feeWePaid + " sats")

    return {rawTx: txSerial, feeWePaid: feeWePaid}

};  // unlockFundedMultiScriptTxLockTime()

async function unlockGeneralizedTx(   funding,
                                scriptsSigStrs,             // now an array
                                lockingTxids,               // now an array
                                scriptPubKeysStrs,          // now an array
                                inputAmounts,               // now an array
                                outIdxsOfContractsWeWant,   // now an array <---- moved
                                newScriptPubKeyStrs,
                                outputAmounts,
                                payScriptsQ,
                                payoutsAmount,
                                blockNum,
                                feePerKB,
                                nSeq = 0xfeffffff) {

console.error("unlockGeneralizedTx: nSeq = ", nSeq)

    const numInputContracts = scriptsSigStrs.length
    if( numInputContracts !== lockingTxids.length ||
        numInputContracts !== scriptPubKeysStrs.length ||
        numInputContracts !== inputAmounts.length ||
        numInputContracts !== outIdxsOfContractsWeWant.length ) {
            throw new Error("17401:  (array) lengths of args 2-6 (base-1) must match");
    }
    let scriptSigs = [];
    let scriptPubKeys = [];
    scriptSigs[0] = bsv.Script.fromASM(scriptsSigStrs[0]);
    scriptPubKeys[0] = bsv.Script.fromASM(scriptPubKeysStrs[0]);
    if ( numInputContracts === 2 ) {
        scriptSigs[1] = bsv.Script.fromASM(scriptsSigStrs[1]);
        scriptPubKeys[1] = bsv.Script.fromASM(scriptPubKeysStrs[1]);
    }

    console.log('    unlockGeneralizedTx(): calling build...');
    const unlockingTx = buildGeneralizedUnlockTx(   funding,
                                                    lockingTxids,
                                                    outIdxsOfContractsWeWant,
                                                    scriptPubKeys,
                                                    inputAmounts,
                                                    newScriptPubKeyStrs,
                                                    outputAmounts,
                                                    payScriptsQ,
                                                    payoutsAmount,
                                                    blockNum,
                                                    nSeq);

    setFeePerKB(unlockingTx, feePerKB)

    console.log('    unlockGeneralizedTx(): got Tx. setting script (contract\'s)...');
    // set the scriptSig ON THE FIRST INPUT (and maybe 2nd too)
    // FUNDED TXs have external funds at input 1 (or maybe input 2), and contract at input 0 (and maybe input 1)
    unlockingTx.inputs[0 /*INPUT_IDX*/].setScript(scriptSigs[0]);
    if ( numInputContracts === 2 ) {
        unlockingTx.inputs[1 /*INPUT_IDX*/].setScript(scriptSigs[1]);
    }

    const fundingIdx = numInputContracts;
    const fundingSig = new bsv.Transaction.Signature({
        publicKey:   bsv.PublicKey.fromPrivateKey( funding.privateKey ),
        prevTxId:    unlockingTx.inputs[fundingIdx].prevTxId,
        outputIndex: unlockingTx.inputs[fundingIdx].outputIndex,
        inputIndex:  fundingIdx,
        signature:   bsv.Transaction.Sighash.sign(  unlockingTx,
                                                    funding.privateKey,
                                                    SIGHASH_TYPE_ALL,
                                                    fundingIdx,
                                                    unlockingTx.inputs[fundingIdx].output.script,
                                                    unlockingTx.inputs[fundingIdx].output.satoshisBN),
        sigtype:     SIGHASH_TYPE_ALL
    });
    unlockingTx.inputs[fundingIdx].setScript(bsv.Script.buildPublicKeyHashIn(fundingSig.publicKey,
                                                                             fundingSig.signature.toDER(),
                                                                             fundingSig.sigtype));
    console.log('    unlockGeneralizedTx(): here\'s our SIGNED unlocking tx:', unlockingTx);
    console.log('    unlockGeneralizedTx(): ------------------------------------------');
    console.log('    unlockGeneralizedTx(): returning unlockingAndFunding tx...');

    // when serializing, disable check for dusty outputs. The miners now accept 135 sats,
    // but the bsv library thinks that's dusty
    const txSerial = unlockingTx.serialize({disableDustOutputs: true, disableIsFullySigned: true});

    console.log('    unlockGeneralizedTx(): serialized tx: ', txSerial);
    const hashTx = bsv.crypto.Hash.sha256sha256(unlockingTx.toBuffer());
    console.log('    unlockGeneralizedTx(): not-flipped HASH of serialized tx: ', hashTx.toString('hex'));

    //NOTE: this also works:
    //var hashTxSerial = bsv.crypto.Hash.sha256sha256( Buffer.from(txSerial, 'hex') );
    //console.log('    unlockGeneralizedTx(): HASH of txSerial: ', hashTxSerial.toString('hex'));

    const feeWePaid = unlockingTx._inputAmount - unlockingTx._outputAmount
    console.log("we're done serializing... txSerial has length " + txSerial.length
            + ", and we paid a fee of " + feeWePaid + " sats")

    return {rawTx: txSerial, feeWePaid: feeWePaid}


};  // unlockGeneralizedTx()

/**
 * Choose largest utxo for funding
 * Avoid using contract utxo for funding.
 *   WAIT: I don't think we do that. Maybe that's a vestigial comment  <----
 */
function getBestUtxoChoice(q, contractTxid, contractOutIdx) {
    if ( q.length < 1 ) {
        console.error("getBestFunding(): No UTXOS")
        throw new Error("no utxos with which to build")
    }

    console.warn("# of utxos: ", q.length)
    //console.warn("contractOutIdx = ", contractOutIdx)
    //console.warn("   prevTxId (contractTxid) =  ", contractTxid)

    let utxoChoice = 0
    let utxoIdxForFunds = q[utxoChoice].outputIndex
    console.warn("NOTE: starting with utxo[0]s outputIndex of " + utxoIdxForFunds)
    let highestFundedChoice = -1
    let highestFunding = 0
    for ( let i = 0; i < q.length; i++ ) {
        if ( q[i].satoshis > highestFunding ) {
            if ( q[i]?.FROZEN === true ) {
                continue
            }
            highestFundedChoice = i
            highestFunding = q[i].satoshis
            console.warn("  new highest funded choice: " + i)
            console.warn("    funding: " + highestFunding)
        }
    }
    utxoChoice = highestFundedChoice

    utxoIdxForFunds = q[utxoChoice].outputIndex
    console.warn("   USING utxo choice " + utxoChoice + ", its outIndex is " + q[utxoChoice].outputIndex)
    console.warn("   It's tx is " + q[utxoChoice].txId)
    return utxoChoice
}

/**
 * Sends a prescribed amount of satoshis to a different address.
 * Returns change, if any, to SAME address.
 *
 * @param {*} privKeyStr
 * @param {*} utxo
 * @param {*} satsSum
 * @param {*} satsOverage
 */
//                                                       funding   destAmt
async function buildBalanceReducingTx(privKeyStr, utxos, satsSum, satsOverage, offloadPKH, initialFee) {

    console.log("UTXOs we'll use for funding: ", utxos)

    // COPIED FROM buildConsolidatingTx(), below

    const privateKey = new bsv.PrivateKey.fromWIF(privKeyStr)
    // derive PKH from Private Key
    const fundingPubKey = bsv.PublicKey.fromPrivateKey(privateKey).toBuffer();
    const fundingPKH = bsv.crypto.Hash.sha256ripemd160(fundingPubKey);
    console.log('    buildBalanceReducingTx(): funding PKHash: ', fundingPKH);
    const fundingPKHstr = fundingPKH.toString('hex');
    console.log('    buildBalanceReducingTx(): funding PKH hex string: ', fundingPKHstr);
    const fundingScriptPubKey = bsv.Script.fromASM("OP_DUP OP_HASH160 " + fundingPKHstr + " OP_EQUALVERIFY OP_CHECKSIG");
    console.log('        buildBalanceReducingTx(): 2- funding SCRIPT pub key: ', fundingScriptPubKey);

    console.log('    buildBalanceReducingTx(): Offload PKH hex string: ', offloadPKH);

    console.log("  ")
    let feePerKB;

    let attemptsCount = 0
    let lowerBoundFailedRateAttempt = 100000000
    let upperBoundFailedRateAttempt = 0
    let doneTrying = false
    let raw
    let txid = ''
    let amountToRemoveForMiners = initialFee;
    let prevFee = 100000000
    let oneFinalIteration = false
    do {
        console.log("  ")
        attemptsCount++
        console.warn("   LOOP attempt: " + attemptsCount + " <-----")
        console.log("  ")

        const tx = new bsv.Transaction().from(utxos);

        //NOTE: we attempted to build no-fee tx (if 1-in, 1-out) on 8/25/22
        //      Testnet: 63abb77c24d9729762844d135463d8ada0dc8e693d3285bc8001e36450f24a09
        //      Miners didn't like it

        const minerFee = Math.max(Math.round(amountToRemoveForMiners), 1)  // avoid 0-sat fee. Minimum 1 sat

        console.log("new output = satsSum - amountToRemoveForMiners: " + satsSum + ", decimal fee: " + amountToRemoveForMiners)
        console.log("new output = satsSum - amountToRemoveForMiners: " + satsSum + ",   whole fee:" + minerFee)

        console.warn("  minerFee: ", minerFee)
        console.warn("  overage (desired SEND amount): ", satsOverage)
        console.warn("  output 0: offload amount (overage): ", satsOverage)

        // send overage to OFFLOAD address
        const offloadScriptPubKey = bsv.Script.fromASM("OP_DUP OP_HASH160 " + offloadPKH + " OP_EQUALVERIFY OP_CHECKSIG");
        tx.addOutput(new bsv.Transaction.Output({
            script: offloadScriptPubKey,
            satoshis: satsOverage,
        }));

        const satsChange = satsSum - satsOverage - minerFee

        // Only include a change output if needed
        if ( satsChange > 0 ) {
            console.warn("  satsSum " + satsSum + ",  satsOverage " + satsOverage + ",  minerFee " + minerFee)
            console.warn("  output 1: change amount (satsSum  -  overage  -  fee): ", satsChange)

            // send change back to SAME address
            const newScriptPubKey = bsv.Script.fromASM("OP_DUP OP_HASH160 " + fundingPKHstr + " OP_EQUALVERIFY OP_CHECKSIG");
            tx.addOutput(new bsv.Transaction.Output({
                script: newScriptPubKey,
                satoshis: satsChange,
            }));
        } else {
            console.log("buildBalanceReducingTx(): a change output is unnecessary")
        }

        // We don't set the fee per kilobyte when constructing the tx. we set the fee
        //console.log("SETTING feePerKB: " + feePerKB)

        console.warn("buildBalanceReducingTx(): tx so far (after adding output, setting fee): ", tx)

        //FIXME: check on unlockGeneralizedTx()   circa line 3515.
        for ( let i = 0; i < tx.inputs.length; i++ ) {
            console.log("Here's an input: " + i + " - ", tx.inputs[i])

            const txSigI = new bsv.Transaction.Signature({
                publicKey: bsv.PublicKey.fromPrivateKey(privateKey),
                prevTxId: tx.inputs[i].prevTxId,
                outputIndex: tx.inputs[i].outputIndex,
                inputIndex: i,  // was 1
                signature: bsv.Transaction.Sighash.sign(tx, privateKey, SIGHASH_TYPE_ALL, i, tx.inputs[i].output.script, tx.inputs[i].output.satoshisBN),
                sigtype: SIGHASH_TYPE_ALL
            });
            tx.inputs[i].setScript(bsv.Script.buildPublicKeyHashIn(txSigI.publicKey, txSigI.signature.toDER(), txSigI.sigtype));
        }

        //const feeWePaid = tx._inputAmount - tx._outputAmount     //hmm. sometimes NaN
        //const feeWePaid = tx.getFee()     //hmm. maybe this is the target rate? <---
        const feeWePaid = minerFee
        console.log("BTW: feeWePaid: " + feeWePaid + " sats")
        console.log("---> BTW: minerFee, by definition: ", minerFee)


        console.warn("buildBalanceReducingTx(): tx after signing: ", tx)
        const rawTx = await tx.serialize({disableDustOutputs: true, disableIsFullySigned: true});

        raw = rawTx

        feePerKB = feeWePaid / ( rawTx.length / 2 )
        console.warn(" directly-CALCULATED fee per KB: ", feePerKB)

        const { doneAdjustingFee, newFeePerKB, newLowerBound, newUpperBound, tooMuch, tooLittle, factor }
                        = evaluateFee(  attemptsCount,
                                        feePerKB,
                                        feeWePaid,
                                        rawTx.length / 2,
                                        lowerBoundFailedRateAttempt,
                                        upperBoundFailedRateAttempt)

console.error("the feeWePaid: " + feeWePaid + "\n")
        if ( oneFinalIteration ) {
            console.error("THAT was our final iteration. amountToRemoveForMiners: " + amountToRemoveForMiners)
            break;
        } else if ( tooMuch && ( feeWePaid === (prevFee + 1) || feeWePaid === prevFee ) ) {
            console.error("Whoa. Too MUCH (" + feeWePaid + " sats), but roughly 1 more than the PREVIOUS attempt. ONE MORE TIME, but will pay " + (amountToRemoveForMiners - 1))
            amountToRemoveForMiners = amountToRemoveForMiners - 1
            oneFinalIteration = true
        } else if ( tooLittle && ( feeWePaid === (prevFee - 1) || feeWePaid === prevFee ) ) {
            console.error("Whoa. Too little (" + feeWePaid + " sats), but roughly 1 less than the PREVIOUS attempt. ONE MORE TIME, but will pay " + (amountToRemoveForMiners + 1) + ", or " + minerFee)
            amountToRemoveForMiners = amountToRemoveForMiners + 1
            oneFinalIteration = true
        } else if ( tooMuch ) {
            amountToRemoveForMiners = amountToRemoveForMiners * factor
        } else if ( tooLittle ) {
            amountToRemoveForMiners = amountToRemoveForMiners * factor
        }
        console.log("We SET modified FEE, of reduction tx, to " + amountToRemoveForMiners)

        // change of 0 sats doesn't necessarily mean we have the 'correct' fee just yet
        doneTrying = doneAdjustingFee //|| (satsChange===0)

        lowerBoundFailedRateAttempt = newLowerBound
        upperBoundFailedRateAttempt = newUpperBound

        if ( tooMuch && attemptsCount > 8 ) {
            console.warn("We're going to pay a larger-than-expected fee because we're already up to " + attemptsCount + " tries.")
            doneTrying = true
        } else if ( attemptsCount > 12 ) {
            console.error(" too many times")
            alert("ERROR: We're having trouble building this transaction with a proper fee")
            raw = null
            break;
        }

        prevFee = feeWePaid

    } while ( !doneTrying )

    console.warn("attempts: " + attemptsCount)
    console.warn("final feePerKB: " + feePerKB)

    console.warn("serialized reduction tx: ", raw)

    //NOTE: we assume a policy of NOT allowing sending to ourselves
    //      Else, output 0 could also be a potential funding option (utxo) to be tracked
    const changeOutIndex = 1              // overage on output 0. Change on 1
    const newChange = satsSum - prevFee - satsOverage
    const changeScript = utxos[0].script // copy script from INPUT/funding
    // take 20 bytes (40 chars) in the 'middle' of the locking script
    // This is the 'pkh' of the p2pkh
    // example: 76a914 55e43a2edb9de30e47f138961915bdef66fcfe0d 88ac
    const changePKH = changeScript.slice(6, 46)

    if ( raw !== null ) {
        txid = await sendSerializeTx(raw, utxos, changeOutIndex, newChange, changePKH);
        console.log("Broadcast reduction tx with txId " + txid)
    }

    return {
        raw: raw,
        txid: txid
    }
}  // buildBalanceReducingTx()

// Called from at least 4 places in walletFunder
async function buildConsolidatingTx(privKeyStr, utxos, satSum, optionalDestinationPKHStr = null,
                                    sweepingIntoWallet = false) {

    const privateKey = new bsv.PrivateKey.fromWIF(privKeyStr)
    // derive PKH from Private Key
    const fundingPubKey = bsv.PublicKey.fromPrivateKey(privateKey).toBuffer();
    const fundingPKH = bsv.crypto.Hash.sha256ripemd160(fundingPubKey);
    console.log('    buildConsolidatingTx(): funding PKHash: ', fundingPKH);
    const fundingPKHstr = fundingPKH.toString('hex');
    console.log('    buildConsolidatingTx(): funding PKH hex string: ', fundingPKHstr);

    const fundingScriptPubKey = bsv.Script.fromASM("OP_DUP OP_HASH160 " + fundingPKHstr + " OP_EQUALVERIFY OP_CHECKSIG");
    console.log('        buildConsolidatingTx(): 2- funding SCRIPT pub key: ', fundingScriptPubKey);

    let destinationPKHStr
    if ( optionalDestinationPKHStr === null ) {
        console.warn("buildConsolidatingTx(): NO destination was specified, so, will direct (output) funds BACK to fundingPKH")
        destinationPKHStr = fundingPKHstr
    } else {
        destinationPKHStr = optionalDestinationPKHStr
        console.warn("buildConsolidatingTx(): Destination (PKH) was specified, so, will direct (output) funds there: " + destinationPKHStr)
    }

    console.log("  ")
    let feePerKB = 1 // dummy value - to be updated soon

    let attemptsCount = 0
    let lowerBoundFailedRateAttempt = 100000000    //FIXME: re-visit if these are appropriate-set
    let upperBoundFailedRateAttempt = 0
    let doneTrying = false
    let raw
    let txid = ''
    let amountToRemoveForMiners = 2  // This is just a starting-point in our search. Had been 546, but rates have dropped
    const newScriptPubKey = bsv.Script.fromASM("OP_DUP OP_HASH160 " + destinationPKHStr + " OP_EQUALVERIFY OP_CHECKSIG");

    let oneSatTooLittle = false
    let prevFee = 100000000
    let oneFinalIteration = false

    let theTx
    do {
        console.log("  ")
        attemptsCount++
        console.warn("   LOOP attempt: " + attemptsCount + " <-----")
        console.log("  ")

console.warn("buildConsolidatingTx(): utxos to put in tx: ", utxos)
        const tx = new bsv.Transaction().from(utxos);
console.warn("buildConsolidatingTx(): tx at the start (just inputs): ", tx)

console.log("BTW: tx.inputAmount: ", tx.inputAmount)
        console.log("new output = satSum - amountToRemoveForMiners:    " + satSum + " - " + amountToRemoveForMiners)
        console.log("new output = satSum - amountToRemoveForMiners:   " + satSum + " - " + Math.round(amountToRemoveForMiners))

        const theFee = Math.max(Math.round(amountToRemoveForMiners), 1)  // avoid 0-sat fee. Minimum 1 sat

        // send funds either:
        //     - back to SAME address (consolidate), OR
        //     - to the specified address (PKH) (EMPTY wallet)

        tx.addOutput(new bsv.Transaction.Output({
            script: newScriptPubKey,
            satoshis: satSum - theFee,
        }));
        //NOTE: there is NO 'change' output. ALL funds go to a SINGLE output

        console.warn("buildConsolidatingTx(): tx so far (after adding output, setting fee): ", tx)

        //FIXME: check on unlockGeneralizedTx()   circa line 3515.
        for ( let i = 0; i < tx.inputs.length; i++ ) {
            console.log("Here's an input: " + i + " - ", tx.inputs[i])

            const txSigI = new bsv.Transaction.Signature({
                publicKey: bsv.PublicKey.fromPrivateKey(privateKey),
                prevTxId: tx.inputs[i].prevTxId,
                outputIndex: tx.inputs[i].outputIndex,
                inputIndex: i,  // was 1
                signature: bsv.Transaction.Sighash.sign(tx, privateKey, SIGHASH_TYPE_ALL, i, tx.inputs[i].output.script, tx.inputs[i].output.satoshisBN),
                sigtype: SIGHASH_TYPE_ALL
            });
            tx.inputs[i].setScript(bsv.Script.buildPublicKeyHashIn(txSigI.publicKey, txSigI.signature.toDER(), txSigI.sigtype));
        }

        // NOTE: redundant with theFee. Double-check they match?
        const feeWePaid = tx.getFee()
        console.log("BTW: feeWePaid: " + feeWePaid + " sats")
console.error("BTW: here's the tx obj: ", tx)


        console.warn("buildConsolidatingTx(): tx after signing: ", tx)

        //NOTE: We serialize it so that we can measure its size/
        //      That helps us evaluate the fee
        const rawTx = await tx.serialize({disableDustOutputs: true, disableIsFullySigned: true});

        raw = rawTx

        feePerKB = feeWePaid / ( rawTx.length / 2 )
        console.warn(" our directly-CALCULATED fee per KB: ", feePerKB, ".  Will use this as 'feePerKB' as we evaluateFee()")

        const { doneAdjustingFee, newFeePerKB, newLowerBound, newUpperBound, tooMuch, tooLittle, factor }
                        = evaluateFee(  attemptsCount,
                                        feePerKB,
                                        feeWePaid,
                                        rawTx.length / 2,
                                        lowerBoundFailedRateAttempt,
                                        upperBoundFailedRateAttempt)

        doneTrying = doneAdjustingFee
        lowerBoundFailedRateAttempt = newLowerBound
        upperBoundFailedRateAttempt = newUpperBound
        console.log("lowerBoundFailedRate: " + newLowerBound)
        console.log("upperBoundFailedRate: " + newUpperBound + "   <===")

        console.log("the feeWePaid: " + feeWePaid + "\n")

        if ( oneFinalIteration ) {
            console.error("THAT was our final iteration. amountToRemoveForMiners: " + amountToRemoveForMiners)
            break;
        } else if ( tooMuch && ( feeWePaid === (prevFee + 1) || feeWePaid === prevFee ) ) {
            console.error("Whoa. Too MUCH (" + feeWePaid + " sats), but roughly 1 more than the PREVIOUS attempt. ONE MORE TIME, but will pay " + (amountToRemoveForMiners - 1))
            amountToRemoveForMiners = amountToRemoveForMiners - 1
            oneFinalIteration = true
        } else if ( tooLittle && ( feeWePaid === (prevFee - 1) || feeWePaid === prevFee ) ) {
            console.error("Whoa. Too little (" + feeWePaid + " sats), but roughly 1 less than the PREVIOUS attempt. ONE MORE TIME, but will pay " + (amountToRemoveForMiners + 1))
            amountToRemoveForMiners = amountToRemoveForMiners + 1
            oneFinalIteration = true
        } else if ( tooMuch ) {
            amountToRemoveForMiners = amountToRemoveForMiners * factor

            if ( feeWePaid === 2 ) {
                console.error(" 2-sat fee is too much   <-----")
                if ( oneSatTooLittle ) {
                    console.error("We've set our targets too narrow for simple offloads. We're FINE paying 2 sats. We're done.")
                    amountToRemoveForMiners = 2
                    doneTrying = true
                }
            }
        } else if ( tooLittle ) {
            amountToRemoveForMiners = amountToRemoveForMiners * factor

            if ( feeWePaid === 1 ) {
                console.error(" 1-sat fee is too little   <-----")
                oneSatTooLittle = true
            }
        }
        console.error(" SET modified FEE to " + amountToRemoveForMiners + " <=====")

        if ( attemptsCount > 8 ) {
            console.error(" too many times")
            raw = null
            break;
        }

        prevFee = feeWePaid

        theTx = tx
    } while ( !doneTrying )

    console.warn("tried times: " + attemptsCount)
    console.warn("final feePerKB: " + feePerKB)

    console.warn("serialized tx: ", raw)

    const changeOutIndex = 0
    const newChange = satSum - prevFee
    const changeScript = utxos[0].script
    // take 20 bytes (40 chars) in the 'middle' of the locking script
    // This is the 'pkh' of the p2pkh
    // example: 76a914 55e43a2edb9de30e47f138961915bdef66fcfe0d 88ac
    const changePKH = changeScript.slice(6, 46)

    console.error("buildConsolidatingTx(): utxos: ", utxos)
    console.error("buildConsolidatingTx(): changeOutIndex: ", changeOutIndex)
    console.error("buildConsolidatingTx(): newChange: ", newChange)
    console.error("buildConsolidatingTx(): changePKH: ", changePKH)

    if ( sweepingIntoWallet ) {
        console.warn("We're about to actually 'sweep' funds INTO your wallet...")
    }

    if ( raw !== null ) {
        // if we're sweeping INTO wallet, the spent utxos won't be found in our wallet,
        // so, don't bother passing them in
        const spentUTXOs = sweepingIntoWallet ? [] : utxos
        txid = await sendSerializeTx(raw, spentUTXOs, changeOutIndex, newChange, changePKH, 'test', sweepingIntoWallet);
        console.log("Broadcast txid " + txid)
    }

    return {
        raw: raw,
        txid: txid
    }
}  // buildConsolidatingTx()

const buildFundedMultiScriptUnlockTxLockTime = (funding,
            prevTxId,
            scriptPubKey, inputAmount,
            newScriptPubKeyStrs, outputAmounts,
            payScriptsQ, payoutsAmount,
            blockNum,
            outIdxOfContractWeWant     // the out index (from the previous tx) of the contract we're spending
            ) => {
    const bestUtxo = funding.bestUtxo
    const privateKey = funding.privateKey;

    console.log('        buildFundedMultiScriptUnlockTxLockTime(): 0- got payScriptsQ of length ', payScriptsQ.length);
    console.log('        buildFundedMultiScriptUnlockTxLockTime(): 1- setting input from external funding UTXOs...');

    // derive PKH from Private Key
    const fundingPubKey = bsv.PublicKey.fromPrivateKey(privateKey).toBuffer();
    const fundingPKH = bsv.crypto.Hash.sha256ripemd160(fundingPubKey);
    console.log('    buildFundedMSUTLT(): funding PKHash: ', fundingPKH);
    const fundingPKHstr = fundingPKH.toString('hex');
    console.log('    buildFundedMSUTLT(): funding PKH hex string: ', fundingPKHstr);

    const fundingScriptPubKey = bsv.Script.fromASM("OP_DUP OP_HASH160 " + fundingPKHstr + " OP_EQUALVERIFY OP_CHECKSIG");
    console.log('        buildFundedMultiScriptUnlockTxLockTime(): 2- funding SCRIPT pub key: ', fundingScriptPubKey);

    const tx = new bsv.Transaction(); //.from(utxos);
    console.log('        buildFundedMultiScriptUnlockTxLockTime(): 2- adding contract input(0)...');
    tx.addInput(new bsv.Transaction.Input({
        prevTxId,
        outputIndex: outIdxOfContractWeWant,
        script: new bsv.Script(),
    }), scriptPubKey, inputAmount);

    console.log(" UTXO:", bestUtxo)
    if ( bestUtxo === null ) {
        throw new Error("CODE ERROR: Not given any UTXOS")
    } else {
        let fundingAmount = bestUtxo.satoshis;
        console.log('        buildFundedMultiScriptUnlockTxLockTime(): 2- funding amount: ', fundingAmount);

        console.log('        buildFundedMultiScriptUnlockTxLockTime(): 2- adding P2PKH input(1):    best TxId:',
                        bestUtxo.txId,
                        ', oI: ', bestUtxo.outputIndex,
                        '  fundingAmount: ', fundingAmount);
        tx.addInput(new bsv.Transaction.Input({
            prevTxId: bestUtxo.txId,
            outputIndex: bestUtxo.outputIndex,
            script: new bsv.Script(),
        }), fundingScriptPubKey, fundingAmount);
    }

    const numOuts = newScriptPubKeyStrs.length;
    console.log('        buildFundedMultiScriptUnlockTxLockTime(): 3- adding ', numOuts, ' contract outputs...');
    for (var outIdx = 0; outIdx < numOuts; outIdx++) {
        console.log("          (adding outIdx " + outIdx + ") - script of len " + newScriptPubKeyStrs[outIdx].length)
        console.log("              amount on it will be " + outputAmounts[outIdx])
        console.log("              buildFundedMultiScriptUnlockTxLockTime(): asm script has len " + newScriptPubKeyStrs[outIdx].length)
        //console.log("              buildFundedMultiScriptUnlockTxLockTime(): asm script: ", newScriptPubKeyStrs[outIdx])
        const newScriptPubKey = bsv.Script.fromASM(newScriptPubKeyStrs[outIdx]);
        console.log("              buildFundedMultiScriptUnlockTxLockTime(): compilation has len " + newScriptPubKey.size)
        tx.addOutput(new bsv.Transaction.Output({
            script: newScriptPubKey,
            satoshis: outputAmounts[outIdx],
        }));
    }

    console.warn("   payoutsAmount is an array? " + (payoutsAmount.constructor === Array) )
    console.log('        buildFundedMultiScriptUnlockTxLockTime(): 3- adding ', payScriptsQ.length, ' payout outputs...');
    for (var j = 0; j < payScriptsQ.length; j++) {
        console.log('            buildFundedMultiSUTLT(): 3b-  in loop...');
        console.log('            buildFundedMultiSUTLT(): 3b-  element ', j, ' - P2KH script: ', payScriptsQ[j]);
        const payoutScriptPubKey = bsv.Script.fromASM(payScriptsQ[j]);

        let pay
        // if it's not an array, use the same amount for ALL scripts
        if ( payoutsAmount.constructor !== Array ) {
            pay = payoutsAmount
        } else {
            // If it is an array, it's because we've shoe-horned
            // in an extra payment - for instaBuy payment to owner
            pay = payoutsAmount[j]
            console.log(" (using payountsAmount[" + j + "])")
        }
        console.log('            buildFundedMultiSUTLT(): adding output of amount ', pay);
        tx.addOutput(new bsv.Transaction.Output({
            script: payoutScriptPubKey,
            satoshis: pay,
        }));
    }

    console.log('        buildFundedMultiScriptUnlockTxLockTime(): 4- setting nLockTime to ', blockNum);
    tx.lockUntilBlockHeight(blockNum);

    console.log('        buildFundedMultiScriptUnlockTxLockTime(): 5- adding change output...');
    tx.change(privateKey.toAddress());

    console.log('        buildFundedMultiScriptUnlockTxLockTime(): 7- returning tx...');
    // We'll sign it later
    return tx;
};

const buildGeneralizedUnlockTx = (  funding,
                                    prevTxIds,              // now an array
                                    outIdxsOfContracts,     // now an array <---- moved
                                    scriptPubKeys,          // now an array
                                    inputAmounts,           // now an array
                                    newScriptPubKeyStrs,
                                    outputAmounts,
                                    payScriptsQ,
                                    payoutsAmount,
                                    blockNum,
                                    nSeq = 0xfeffffff
                                    ) => {
    const bestUtxo = funding.bestUtxo
    const privateKey = funding.privateKey

    const numInputContracts = prevTxIds.length
    if( numInputContracts !== outIdxsOfContracts.length ||
        numInputContracts !== scriptPubKeys.length ||
        numInputContracts !== inputAmounts.length ) {
            throw new Error("17311:  (array) lengths of args 3-6 (base-1) must match");
    }

    console.error("buildGeneralizedUnlockTx: nSeq = ", nSeq)

    console.log('        buildGeneralizedUnlockTx(): Note that there are ' + numInputContracts + ' contract inputs');
    console.log('        buildGeneralizedUnlockTx(): 0- got payScriptsQ of length ', payScriptsQ.length);
    console.log('        buildGeneralizedUnlockTx(): 1- setting input from external funding UTXOs...');

    if ( bestUtxo === null ) {
        throw new Error("CODE ERROR: Was not given any UTXOS")
    }

    console.log('        buildGeneralizedUnlockTx(): NEW: best utxo: ', bestUtxo);

    // derive PKH from Private Key
    const fundingPubKey = bsv.PublicKey.fromPrivateKey(privateKey).toBuffer();
    const fundingPKH = bsv.crypto.Hash.sha256ripemd160(fundingPubKey);
    //console.log('    buildGeneralizedUnlockTx(): funding PKHash: ', fundingPKH);
    const fundingPKHstr = fundingPKH.toString('hex');
    console.log('        buildGeneralizedUnlockTx(): funding PKH hex string: ', fundingPKHstr);

    const fundingScriptPubKey = bsv.Script.fromASM("OP_DUP OP_HASH160 " + fundingPKHstr + " OP_EQUALVERIFY OP_CHECKSIG");
    console.log('        buildGeneralizedUnlockTx(): 2- funding SCRIPT pub key: ', fundingScriptPubKey);

    // updated to use smarter utxo-choosing logic
    const fundingAmount = bestUtxo.satoshis;

    console.log('        buildGeneralizedUnlockTx(): 2- funding amount: ', fundingAmount);
    const tx = new bsv.Transaction(); //.from(utxos);

    console.log('        buildGeneralizedUnlockTx(): 2- adding contract input(0)...');
    tx.addInput(new bsv.Transaction.Input({
                                            prevTxId:    prevTxIds[0],
                                            outputIndex: outIdxsOfContracts[0],
                                            script:      new bsv.Script(),
                                            //sequenceNumber: nSeq,
                                        }), scriptPubKeys[0], inputAmounts[0]);

    if ( numInputContracts === 2 ) {
        console.log('        buildGeneralizedUnlockTx(): 2- adding ANOTHER contract input(1)...   <====== special tx (multiple input contracts)');
        tx.addInput(new bsv.Transaction.Input({
                                                prevTxId:    prevTxIds[1],
                                                outputIndex: outIdxsOfContracts[1],
                                                script:      new bsv.Script(),
                                                //sequenceNumber: nSeq,
                                            }), scriptPubKeys[1], inputAmounts[1]);
    }

    console.log('        buildGeneralizedUnlockTx(): 2- adding P2PKH (funding) input:    best TxId:', bestUtxo.txId, ', oI: ', bestUtxo.outputIndex, '  fundingAmount: ', fundingAmount);
    tx.addInput(new bsv.Transaction.Input({
        prevTxId: bestUtxo.txId,
        outputIndex: bestUtxo.outputIndex,
        script: new bsv.Script(),
        //sequenceNumber: nSeq,
    }), fundingScriptPubKey, fundingAmount);

    const numOuts = newScriptPubKeyStrs.length;
    console.log('        buildGeneralizedUnlockTx(): 3- adding ', numOuts, ' contract outputs...');
    console.log('        NOTE: outputAmounts[]: ', outputAmounts)
    for (var outIdx = 0; outIdx < numOuts; outIdx++) {
        console.log("          (adding outIdx " + outIdx + ") - script of len " + newScriptPubKeyStrs[outIdx].length)
//console.warn("  BTW:  script --->" + newScriptPubKeyStrs[outIdx])
        const newScriptPubKey = bsv.Script.fromASM(newScriptPubKeyStrs[outIdx]);
        tx.addOutput(new bsv.Transaction.Output({
            script: newScriptPubKey,
            satoshis: outputAmounts[outIdx],
        }));
    }

    console.log('        buildGeneralizedUnlockTx(): 3- adding ', payScriptsQ.length, ' payout outputs...');
    for (var j = 0; j < payScriptsQ.length; j++) {
        console.log('            buildGeneralizedUnlockTx(): 3b-  in loop...');
        console.log('            buildGeneralizedUnlockTx(): 3b-  element ', j, ' - P2KH script: ', payScriptsQ[j]);
        const payoutScriptPubKey = bsv.Script.fromASM(payScriptsQ[j]);
        tx.addOutput(new bsv.Transaction.Output({
            script: payoutScriptPubKey,
            satoshis: payoutsAmount,
        }));
    }
    console.log('        buildGeneralizedUnlockTx(): 4- setting nLockTime to ', blockNum);
    tx.lockUntilBlockHeight(blockNum);

    console.log('        buildGeneralizedUnlockTx(): 5- adding change output...');
    console.log("          change address: " + privateKey.toAddress())
    tx.change(privateKey.toAddress());

    console.log('        buildGeneralizedUnlockTx(): 6- returning tx...');
    // We'll sign it later
    return tx;
};

function getFeePerKB(txType) {
    var feePerKB = DEFAULT_FEE_PER_KB
    if ( txType === 1 ) {
        feePerKB = FINAL_POST_FEE_PER_KB
        console.log("    Using feePerKB of " + feePerKB + " because finalPost")
    } else if ( txType === 2 ) {
//FIXME: define these in harnessConstants.js
        //feePerKB = 1700 // instead of 9000, for ebfra YES finalPost in the era of .25 sat/B
        feePerKB = 1721   // instead of 9000, for ebfra YES finalPost in the era of .25 sat/B
        console.log("    Using NEW feePerKB of " + feePerKB + " because finalPost")
    } else if ( txType === 3 ) {
        feePerKB = 3650 // instead of 9000, for ebfra NO finalPost in the era of .25 sat/B
        console.log("    Using NEW feePerKB of " + feePerKB + " because finalPost")
    } else if ( txType === 4 ) {
        feePerKB = 610 // instead of 524, for builderPrep transition to APPEND - in the era of .25 sat/B
        console.log("    Using NEW feePerKB of " + feePerKB + " because transitioning to append")
    } else if ( txType === 5 ) {
        feePerKB = 550 // instead of 524, for Dialog - in the era of .25 sat/B
        console.log("    Using NEW feePerKB of " + feePerKB + " because this is a DIALOG")
    } else if ( txType === 6 ) {
        feePerKB = 555 // instead of 524, for Transient SPAWNGING a Dialog - in the era of .25 sat/B
        console.log("    Using NEW feePerKB of " + feePerKB + " because this is a TRANSIENT spawning a DIALOG")

    } else {
        console.log("    Using default feePerKB of " + feePerKB + " because NOT a finalPost")
    }

    return feePerKB
}

function getTipTransientFeePerKB() {
    const feePerKB = TIP_TRANSIENT_FEE_PER_KB
    console.log("    Using feePerKB of " + feePerKB + " because tipping a Transient")

    return feePerKB
}

function getBountyClaimFeePerKB() {
    const feePerKB = BOUNTY_CLAIM_FEE_PER_KB
    console.log("    Using feePerKB of " + feePerKB + " because claiming bounty")

    return feePerKB
}

function setFeePerKB(tx, feePerKB) {
    console.log("\n  >>>  calling tx.feePerKb(" + feePerKB + ") <---\n")
    tx.feePerKb(feePerKB)
    if (tx.getFee() < MIN_FEE) {
        tx.fee(MIN_FEE);
    }
}

// node utility needs this, but abhors function export
/* */
//export {
module.exports = {

        makeDataTx,

        appendUserProfile,
        removeUserProfile,

        buildAskBidScriptPubKey,
        buildEbfraScriptPubKey,
        buildContinueScriptPubKey,
        buildUpdateScriptPubKey,
        buildDialogScriptPubKey,
        buildTransientScriptPubKey,
        buildBitGroupScriptPubKey,
        buildAdministerGroupScriptPubKey,
        //executeTransientPubContract,  // transient()
        executeTransientHostingContract,
        executePubUpdateContract,     // continue()
        executeEbfraContract,
        executePubTransContract,      // update()
        executeDialogContract,
        executeBitGroupContract,
        executeAdminGroupContract,
        executeClaimTransientBalance, // claim a dangling contract bounty
        executeClaimUpdateBalance,    // claim a dangling contract bounty
        executeIncreaseTransientBalance,
        executeTheAskBidContract,
        executeBuilderPrepContract,
        executeTheAppendContract,
        getSigParts,

        getBestUtxoChoice,
        buildBalanceReducingTx,
        buildConsolidatingTx,
}
/* */