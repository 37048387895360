import React from 'react';

import './index.css';

import { Button, Checkbox, Container, Divider, Dropdown, Form, Grid,
    Input, Loader, Menu, Message, Modal, Popup, Radio, TextArea } from 'semantic-ui-react'

import {
    encryptData,
    saveEncryptedP2PKHKey,

    decryptData,                   // for new-fangled wallet import

    saveEncryptedWallet,
    saveEncryptedKeys,
    getEncryptedKeysFromPKH,
    getEncryptedKeysFromAddress,  //officialP2pkhTab  FIXME: generalize these two
    getEncryptedKeyFromAddress,   //p2pkhTab

    registerDomainOfMine,
    registerBuiltDomain,

    openDB,
} from './buildShizzle.js';



import {
	bsv,
} from 'scryptlib' ;


class WalletImporter extends React.Component {
  constructor(props) {
    super(props);

    this.solicitPassword = this.solicitPassword.bind(this);
    //this.inspectContents = this.inspectContents.bind(this);

    this.importFundingKey= this.importFundingKey.bind(this);
    this.importPayoutKeys= this.importPayoutKeys.bind(this);
    this.importRabinKeys = this.importRabinKeys.bind(this);

    this.importDomainsClaimed = this.importDomainsClaimed.bind(this);
    this.importDomainsBuilt   = this.importDomainsBuilt.bind(this);


    this.allowPaste      = this.allowPaste.bind(this);
    this.handlePaste     = this.handlePaste.bind(this);

    this.handleBsvKey    = this.handleBsvKey.bind(this);
    this.handlePwdChange = this.handlePwdChange.bind(this);

    // for new-fangled wallet import, we need to decrypt with a password
    this.useEnteredPasswordToDecrypt = this.useEnteredPasswordToDecrypt.bind(this);

    this.walletReaderFunc  = this.walletReaderFunc.bind(this);

    //FIXME: the purples are currently duplicated in index.css
    this.bshzPurple             = '#8313e2';
    this.bshzYellow             = "#fff300";
    this.bshzLtPurp             = '#e7d0fb';
    this.bshzLightYellow        = "#fffccc";

    this.state = {enableInspectButton: false,
                  numItemsToImport: ' ',
                  invalidImportFile: false,

                  enablePaste: false,
                  enableInspectButton2: false,
                  numItemsToImport2: ' ',
                  invalidImportFile2: false,

                  importClicked: false,

                  showOkModal: false,
                  keysSkipped: 0,

                  totalRecordsCount: 0, // count of ALL records that were imported (not skipped)

                  getPwdForPriv: false,  //NOTE: might not be used. Maybe vestige from rabin-handling code
                  priv:          null,

                  pwd:           '',
                  showPasswordInputField: false,

                  walletGibberish: '',
                  walletVersion: '',
                  walletNetwork: '',
                  walletBlob: '',

                  disablePasteButton: false,

                  showImportSwirly: false
                };
  }

  allowPaste() {
    console.log("Allow paste")

    //FIXME: consider disabling other option (imorting file)

    this.setState({enablePaste: true, disablePasteButton: true})
  }
  handlePaste(event, data) {
    if ( data.value.length > 300000 ) {
      alert("ERROR: Your backup file is too long. If you believe your backup is valid, please report this problem.")
      return
    }

    const value = data.value.substr(0, 300000)
    console.log("Change to backup text box...")
    //console.log("text: ", value)

    //NOTE: adapted from componentDidMount() option, below

    const jsonString = value;
    console.log("placed (paste) content of length " + jsonString.length);

    try {
      const contents = JSON.parse(jsonString)

      console.warn("These are the RAW contents: ", jsonString)
      console.warn("These are the JSON-parsed contents: ", contents)

      this.setState({enableInspectButton2: true, numItemsToImport2: (' ' + contents.length)});
      this.setState({invalidImportFile2: false, pastedContents: jsonString});
    } catch (error) {
      console.log("invalid wallet text/gibberish: ", error);
      this.setState({invalidImportFile2: true});
    }

  }

  componentWillUnmount() {
    //alert("REMOVING listener...")
    document.getElementById('inputFileHolder')?.removeEventListener('change', this.walletReaderFunc)
  }

  componentDidMount() {
    this.setState({enableInspectButton: false, numItemsToImport: ' ', invalidImportFile: false});

    //alert("ADDING listener...")
    document.getElementById('inputFileHolder').addEventListener('change', this.walletReaderFunc);


    //FIXME: it's when we enable/disable the paste button that this 'inputFileHolder' comes into and out of existence
  }

  walletReaderFunc(e) {
    const parent = e.currentTarget
    const myWalletImporter = this
    const fr = new FileReader();

    fr.onload=function(){
        // PLACE the contents we've read into a 'nook' which the solicitPassword() will use
        document.getElementById('contentPlaceHolder').textContent = fr.result;
        const jsonString = document.getElementById('contentPlaceHolder').innerHTML;
        console.log("placed content of length " + jsonString.length);
        try {
          const contents = JSON.parse(jsonString)
          console.warn(' ')
          console.log(" here's the JSON file we've loaded: ", jsonString)
          console.warn(" ")
          console.log("number of elements: ", contents.length);
          myWalletImporter.setState({enableInspectButton: true, numItemsToImport: (' ' + contents.length)});
          myWalletImporter.setState({invalidImportFile: false});
        } catch (error) {
          console.warn(' ')
          console.log(" here's the file: ", jsonString)
          console.warn(" ")
          console.log("invalid encrypted-key import file: ", error);
          myWalletImporter.setState({invalidImportFile: true});
        }
    }

    fr.readAsText(parent.files[0]);
  }

  handlePwdChange(event) {
    //console.log("handlePwdChange(): pwd now set to ", event.target.value);

    this.setState({pwd: event.target.value});
  }
  // NOTE: COPIED from keyUtil.js  handleBsvKeySaverSave(), and walletGenerator's handleBsvKeySave()
  async handleBsvKey() {

        const addressToSave = bsv.PrivateKey.fromWIF( this.state.priv ).toAddress().toString()
        console.log("handleBsvKeySave(): address is ", addressToSave);

    //console.log("pwd is ", this.state.pwd);

        const bundled = {p: this.state.priv, address: addressToSave}
        const bundledPlainText = JSON.stringify(bundled);
    //console.log("bundled plain text is", bundledPlainText);

        let cyphText
        try {
          cyphText = await encryptData(bundledPlainText, this.state.pwd);
        } catch (error) {
          alert("Please make sure you're using https://")
          throw error
        }

        console.log("cypher text is ", cyphText);
        //console.warn("That cypher/blob was generated with pwd " + this.state.pwd)

        try {
            const db = await openDB();
            //NOTE: saves as an 'officialWallet' (testnet)
            await saveEncryptedP2PKHKey(db, addressToSave, cyphText, true, 't', 1);

            console.warn("we've saved the encrypted p2pkh key")
            alert("Imported / created-from-scratch")

            this.props.userNowHasWallet();
        } catch (error) {
//FIXME: did this ever work? we have no such props - yet
            this.props.showOkModal("Wallet Save FAILED",
            <>
                <p>
                    Something went wrong. Your wallet was NOT saved. Do NOT use the wallet's address (shown earlier).
                </p>
                <p>
                    Please report this.
                </p>
            </>)
            alert("Something went wrong. Your wallet was NOT saved. Do NOT use that address: ", error)
            //FIXME: now what? Maybe offer ideas, and close THIS modal
        }

        // close the pop-up
        //           this.props.parent.setState({openBsvKeySaverModal: false, pwd: ''});
  }


  // user pasted gibberish, or retrieved from a file
  async solicitPassword( jsonContentString ) {
    //const jsonContentString = document.getElementById('contentPlaceHolder').innerHTML;

    console.log("here is the content string: ", jsonContentString)
    const contents = JSON.parse(jsonContentString)

//FIXME: can the json FAIL to parse


    const walletVersion = contents.bshzWalletVersion
    const walletNetwork = contents.network
    const walletBlob    = contents.blob
    const walletCreated = contents.created

    console.log("Imported wallet version: ", walletVersion)
    console.log("Imported wallet network: ", walletNetwork)
    console.log("Imported wallet blob: ", walletBlob)

    this.setState({
                    importClicked: true,      // disables the IMPORT button

                    showPasswordInputField: true,

                    walletGibberish: jsonContentString,

                    walletVersion: walletVersion,
                    walletNetwork: walletNetwork,
                    walletCreated: walletCreated,
                    walletBlob:    walletBlob,
                  })
  }

  //NOTE: copied from keyImporter's inspectContents()
  //NOTE: used for both publisher rabins, and builder rabins (called twice)
  async importRabinKeys(rabinKeyRecords, variant=null) {

    //NOTE: we don't use variant
    const recordsType = variant === "null" ? "Publisher" : "Builder"

    console.warn("importRabinKeys() " + recordsType + " records: ", rabinKeyRecords)

    const jsonContentString = rabinKeyRecords;
    console.log("here is the RABIN record content string: ", jsonContentString)
    const contents = JSON.parse(jsonContentString)
    console.log("number of elements: ", contents.length);
    let eachHasPkh = true;
    let eachHasBlob = true;
    let eachHasAlloc = true;

    //FIXME: in order for this to work,
    //       we need to solicit the WALLET pwd - not the FILE pwd
    const checkEachKey = false

    let i = 0;
    contents.forEach(element => {
      console.log("element.pkh: ", element.pkh);
      console.log("element.blob: ", element.blob);
      console.log("element.alloc: ", element.alloc);
      if ( !element.pkh ) {
        console.log("There is no pkh for " + i);
        eachHasPkh = false;
      }
      if ( !element.blob ) {
        console.log("There is no blob for " + i);
        eachHasBlob = false;
      }
      if ( element.alloc === undefined && typeof element.alloc == 'undefined' ) {
        console.log("There is no alloc for " + i);
        eachHasAlloc = false;
      }
      i++;
    });
    console.log("There are " + i + " elements")

    let successfulImports = 0
    if ( eachHasPkh && eachHasBlob && eachHasAlloc ) {
      console.log("suitable. will import " + i + " entries (rabin)");
      var db = await openDB();

      for ( var j = 0; j < i; j++ ) {
        const pkh = contents[j].pkh;

        // does this pkh entry already have a record? Don't overwrite
        let x = await getEncryptedKeysFromPKH(db, pkh, variant);
        console.log("record for given pkh (", pkh, "): ", x);

        if ( x && x !== null ) {
          console.log("There's already an entry for pkh " + pkh + ". SKIPPING");
        } else {
          console.log("IMPORTING record for pkh " + pkh);
          saveEncryptedKeys(db, pkh, contents[j].blob, variant, contents[j].alloc);
          successfulImports++;

          if ( checkEachKey ) {
            try {
              //FIXME: this requires the WALLET pwd - not the FILE pwd
              const plainBundle = await decryptData(contents[j].blob, this.state.pwd);

              //console.log("importRabinKeys(): decrypted json string:", plainBundle);

              const bundledObject = JSON.parse(plainBundle);
              //console.log("decrypted bundle: ", bundledObject)
            } catch (error) {
              console.error("importRabinKeys(): Bad decrypt. Probably provided wrong password.");
              alert("trouble while importing a Publisher key")
              break;
            }
          }

        }
      };

      console.log("Imported " + successfulImports + " of "+ i
                + " " + recordsType
                + " rabin keys");
    } else {
      console.log("Since not all entries had all the required fields, we won't import the contents of this file.")
      alert("WARNING: There was something wrong with the " + recordsType + " records in this file, so, we didn't import ANY of them.")
    }

    return successfulImports
  }

  // This might be outdated - from when the wallet was JUST an 'official' funding key
  //async inspectContents(event) {
  //  event.preventDefault()

  //  await this.importFundingKey(this.state.walletBlob)
  //}

  async importFundingKey(officialRecord) {

    console.warn("importFundingKey() officialFundsRecord: ", officialRecord)

    const contents = JSON.parse( officialRecord )

    //NOTE: methinks there's only ONE 'official' record anyway
    let eachHasAddress = true;
    let eachHasBlob = true;
    let eachHasAlloc = true;

    let successfulFundingImports = 0;
    let keysSkipped = 0;
    let hasPriv = false;
    let priv = null

    let i = 0;
    contents.forEach(element => {
      // outdated logic - probably copied from rabin-handling code
      if ( element.privk ) {
        alert("import has private key");
        hasPriv = true
        priv = element.privk
      }

      console.log("element.address: ", element.address);
      console.log("element.blob: ", element.blob);
      if ( !element.address ) {
        console.log("There is no address for " + i);
        eachHasAddress = false;
      }
      if ( !element.blob ) {
        console.log("There is no blob for " + i);
        eachHasBlob = false;
      }
      /*
      console.log(" element.alloc: " + element.alloc)
      if ( element.alloc === undefined && typeof element.alloc == 'undefined' ) {
        console.log("There is no alloc for " + i);
        eachHasAlloc = false;
      }
      */
      i++;
    });
    console.log("There are " + i + " elements")

    if ( hasPriv ) {  //NOTE: might not be used. Vestige from rabin-handling code?
      //await handleBsvKey(priv)
      this.setState({getPwdForPriv: true, priv: priv})
    } else if ( eachHasAddress && eachHasBlob && eachHasAlloc) {
      console.log("suitable. will import " + i + " entries (funding)");
      var db = await openDB();

      for ( var j = 0; j < i; j++ ) {
        const address = contents[j].address;

        // does this address entry already have a record? Don't overwrite
        let x = await getEncryptedKeysFromAddress(db, address);
        console.log("EXISTING (if any) record for given address (", address, "): ", x);

        if ( x && x !== null ) {
          console.log("There's already an entry for address " + address + ". SKIPPING");
          keysSkipped++;
        } else {
          console.log("IMPORTING record for address " + address);
          saveEncryptedWallet(db, address, contents[j].blob);
          successfulFundingImports++;
        }
      };

      console.log("Imported " + successfulFundingImports + " of " + i + " wallet FUNDING keys");

      // This part was moved to useEnteredPasswordToDecrypt(), where SUCCESS is if we loaded at least 1 funding key
      /*
      this.setState({ showOkModal: true,
                    keysSkipped: keysSkipped
                  })

      this.props.userNowHasWallet();
      */
      this.setState({ keysSkipped: keysSkipped })

    } else {
      console.warn("Since not all entries had all the required fields, we won't import the contents of this file.")
      alert("WARNING: this file has some missing, or unrecognized fields. Decryption declined.")
    }

    return successfulFundingImports

  } // importFundingKey

  //FIXME: this was copied from importFundingKey(). We can probably extract shared logic

  async importPayoutKeys(records) {

    //NOTE: slight difference with importFUndinKey() - parsing string,
    //      and also two similar DB function calls
    //const contents = JSON.parse( payoutRecords )

    let eachHasAddress = true;
    let eachHasBlob = true;
    let eachHasAlloc = true;

    let successfulKeyImports = 0;
    let keysSkipped = 0;

    //FIXME: in order for this to work,
    //       we need to solicit the WALLET pwd - not the FILE pwd
    const checkEachKey = false

    let i = 0;
    records.forEach(element => {

      console.log("element.address: ", element.address);
      console.log("element.blob: ", element.blob);
      console.log("element.alloc: ", element.alloc);
      if ( !element.address ) {
        console.log("There is no address for " + i);
        eachHasAddress = false;
      }
      if ( !element.blob ) {
        console.log("There is no blob for " + i);
        eachHasBlob = false;
      }
      if ( element.alloc === undefined && typeof element.alloc == 'undefined' ) {
        console.log("There is no alloc for " + i);
        eachHasAlloc = false;
      }
      i++;
    });
    console.log("There are " + i + " payout elements")

    if ( eachHasAddress && eachHasBlob && eachHasAlloc ) {
      console.log("suitable. will import " + i + " payout entries");
      var db = await openDB();

      for ( var j = 0; j < i; j++ ) {
        const address = records[j].address;

        // does this address entry already have a record? Don't overwrite
        //NOTE: super-similar name - getEncryptedKeysFromAddress()
        let x = await getEncryptedKeyFromAddress(db, address);
        console.log("EXISTING (if any) record for given address (", address, "): ", x);

        if ( x && x !== null ) {
          console.log("There's already an entry for address " + address + ". SKIPPING");
          keysSkipped++;
        } else {
          console.log("IMPORTING record for address " + address);
          //await saveEncryptedWallet(db, address, contents[j].blob);  //official
          await saveEncryptedP2PKHKey(db, address, records[j].blob, false, 't', records[j].alloc);
          successfulKeyImports++;


          if ( checkEachKey ) {
            try {
              //FIXME: this requires the WALLET pwd - not the FILE pwd
              const plainBundle = await decryptData(records[j].blob, this.state.pwd);

              //console.log("importPayoutKeys(): decrypted json string:", plainBundle);

              const bundledObject = JSON.parse(plainBundle);
              //console.log("decrypted bundle: ", bundledObject)
            } catch (error) {
              console.error("importPayoutKeys(): Bad decrypt. Probably provided wrong password.");
              alert("trouble while importing a Payout key")
              break;
            }
          }
        }
      };

      console.log("Imported " + successfulKeyImports + " of " + i + " payout keys");

    } else {
      console.warn("Since not all Payout entries had all the required fields, we won't import the contents of this file.")
      alert("WARNING: these Payout records have some missing, or unrecognized fields. Decryption declined.")
    }

    return successfulKeyImports

  } // importPayoutKeys

  // adapted from importPayoutKeys()
  async importDomainsClaimed(records) {

    let eachHasName = true;
    let eachHasOwnerCount = true;

    let successfulKeyImports = 0


    let i = 0;
    records.forEach(element => {

      console.log("element.name: ", element.name);
      console.log("element.ownerCount: ", element.ownerCount);
      if ( !element.name ) {
        console.log("There is no name for " + i);
        eachHasName = false;
      }
      if ( !element.ownerCount ) {
        console.log("There is no ownerCount for " + i);
        eachHasOwnerCount = false;
      }
      i++;
    });
    console.log("There are " + i + " domain elements")

    if ( eachHasName && eachHasOwnerCount ) {
      console.log("suitable. will import " + i + " domain entries");
      var db = await openDB();

      for ( var j = 0; j < i; j++ ) {
        const name = records[j].name;

          console.log("IMPORTING record for domain " + name);

          // NEW field. Fill it in, if missing (for now)
          const approxBlockHeightWhenRegistered = records[j].approxBlockHeight //? records[j].approxBlockHeight : 1517450

          //saveEncryptedWallet(db, address, contents[j].blob);  //official
          registerDomainOfMine(db, name, records[j].ownerCount, approxBlockHeightWhenRegistered, records[j].outdated);
          successfulKeyImports++
      };

      console.log("Imported " + successfulKeyImports + " of " + i + " domains");

    } else {
      console.warn("Since not all domain entries had all the required fields, we won't import the contents of this file.")
      alert("WARNING: these domain records have some missing, or unrecognized fields. IMPORT declined.")
    }

    // actually, records: name, ownerCount, approxBlockHeight, flag
    return successfulKeyImports
  } // importDomainsClaimed

  // adapted from importPayoutKeys()
  async importDomainsBuilt(records) {

    let eachHasName = true;
    let eachHasPayout = true;
    let eachHasRabin = true
    let eachHasTxId = true

    let successfulKeyImports = 0

    let i = 0;
    records.forEach(element => {

      console.log("element.name: ", element.name);
      console.log("element.payout: ", element.payout);
      console.log("element.rabin: ", element.rabin);
      console.log("element.txid: ", element.txid);
      if ( !element.name ) {
        console.log("There is no name for " + i);
        eachHasName = false;
      }
      if ( !element.payout ) {
        console.log("There is no payout for " + i);
        eachHasPayout = false;
      }
      if ( !element.rabin ) {
        console.log("There is no rabin for " + i);
        eachHasRabin = false;
      }
      if ( !element.txid ) {
        console.log("There is no txid for " + i);
        eachHasTxId = false;
      }

      i++;
    });
    console.log("There are " + i + " domainsBuilt elements")

    if ( eachHasName && eachHasPayout && eachHasRabin && eachHasTxId ) {
      console.log("suitable. will import " + i + " domainsBuilt entries");
      var db = await openDB();

      for ( var j = 0; j < i; j++ ) {
        const name = records[j].name;

          console.log("IMPORTING domainsBuilt record for domain " + name);
          //saveEncryptedWallet(db, address, contents[j].blob);  //official
          registerBuiltDomain(db, name, records[j].payout, records[j].rabin, records[j].txid);
          successfulKeyImports++
      };

      console.log("Imported " + successfulKeyImports + " of " + i + " domainsBuilt");

    } else {
      console.warn("Since not all domain entries had all the required fields, we won't import the contents of this file.")
      alert("WARNING: these domain records have some missing, or unrecognized fields. IMPORT declined.")
    }

    // actually, records: name, payoutP2PKH, rabin, txid
    return successfulKeyImports
  }

  //NOTE: this was taken from WalletVerifier, and adapted for use here
  async useEnteredPasswordToDecrypt(event) {
    event.preventDefault();

    // show the fleeting modal
    this.setState({showImportSwirly: true})

    //const walletRecord = await this.props.getOfficialWallet();

    console.warn("useEnteredPasswordToDecrypt(): attempt to decrypt wallet blob. record: ", this.state.walletBlob)

    let tampered = false

    //console.warn("P: ", this.state.pwd)
    try {
        const plainBundle = await decryptData(this.state.walletBlob, this.state.pwd);

        //console.warn("useEnteredPasswordToDecrypt(): plainBundle", plainBundle)
        //alert("useEnteredPasswordToDecrypt(): we were able to decrypt wallet blob!")

        console.log("here is the decrypted string: ", plainBundle)
        const contents = JSON.parse(plainBundle)
        console.warn("Here's the json-parsed structure: ", contents)

        // From WalletExporter

        //const bundleToEncrypt = {
        //  bundleTime:        Date(),
        //  officialFunds:     [],
        //  publisherKeys:     [],
        //  builderPayoutKeys: [],
        //  domainsBuilt:      [],
        //  domainsClaimed:    [],
        //}

        const bundleTime        = contents.bundleTime
        const officialFunds     = contents.officialFunds
        const publisherKeys     = contents.publisherKeys
        const builderPayoutKeys = contents.builderPayoutKeys
        //const builderRabins     = contents.builderRabins
        const domainsBuilt      = contents.domainsBuilt
        const domainsClaimed    = contents.domainsClaimed
        //console.warn("IMPORTED officialFunds: ", officialFunds)
        //console.warn("IMPORTED publisherKeys: ", publisherKeys)
        //console.warn("IMPORTED builderPayoutKeys: ", builderPayoutKeys)
        //console.warn("IMPORTED builderRabins: ", builderRabins)
        console.warn("IMPORTED domainsBuilt: ", domainsBuilt)
        console.warn("IMPORTED domainsClaimed: ", domainsClaimed)

        console.warn("envelope 'create' timestamp: ", this.state.walletCreated)
        console.warn("decrypted 'bundleTime': ", bundleTime)
    /*
        if ( bundleTime !== this.state.walletCreated ) {
          tampered = true
          console.error("Wallet tampering/corruption detected? Decrypted 'bundleTime' doesn't match the envelope 'created' timestamp")
          throw new Error("mismatch in timestamps")
        }
    */

        const payoutCount = await this.importPayoutKeys( builderPayoutKeys )
        const rabinCount = await this.importRabinKeys( publisherKeys )
        //await this.importRabinKeys( builderRabins, "builder" )

        const domainsBuiltCount = await this.importDomainsBuilt( domainsBuilt )       // name, payout, rabin, txid
        const domainsClaimedCount = await this.importDomainsClaimed( domainsClaimed )    // name, ownerCount (number)

        const fundingKeysCount = await this.importFundingKey( officialFunds )

        console.warn("funds records: " + fundingKeysCount)
        console.warn("payout records: " + payoutCount)
        console.warn("rabin records: " + rabinCount)
        console.warn("claimed records: " + domainsClaimedCount)
        console.warn("built domains: " + domainsBuiltCount)
        const totalElementCount = fundingKeysCount +
                                  payoutCount +
                                  rabinCount +
                                  domainsClaimedCount +
                                  domainsBuiltCount
        console.warn("total records: " + totalElementCount)

        this.setState({totalRecordsCount: totalElementCount})

        // logic moved from importFundingKey() (called above)
        if ( fundingKeysCount > 0 ) {
          this.setState({ showOkModal: true })

          this.props.userNowHasWallet();
        } else {
          alert("We imported ZERO funding keys, so we consider the import a FAILURE.\nPlease check your wallet backup.")
        }

    } catch (error) {
      if ( tampered ) {
        console.warn("File tampered-with?", error)
        this.props.showOkModal("File tampering or corruption",
                              <>
                                We don't like this file. The envelope timestamp doesn't match the decrypted contents.
                                <br></br>
                                Where have you been storing this file?
                              </>)
      } else {
        console.warn("Wrong password? ", error)
        this.props.showOkModal("Wrong Password", <>You've entered the <span style={{color: "red"}}>wrong password</span> for
                                                    your wallet, OR, you've selected the <span style={{color: "red"}}>wrong
                                                    file</span>, OR, <span style={{color: "red"}}>you're not using
                                                    https://</span>. Please check, and try again.</>)

        // set-up for user to try again
        this.setState({ enablePaste: true, pwd: '', showPasswordInputField: false, importClicked: false,
                        disablePasteButton:true,     //needed?
                        enableInspectButton2: false  //needed?
                      })
      }
      this.setState({pwd: '', showImportSwirly: false});
    }
  }


  render() {

    //FIXME: Copied from onramp.js.   can we share this somehow?
    // ***  DEVICE-DEPENDENT STYLING  ***
    // For mobile, "detect" landscape screen mode
    const landscape = this.props.isMobile ? window.innerWidth > window.innerHeight : false
    // For mobile, shrink the About, Security, and Glossary modals
    const modalClassName   = this.props.isMobile ?
                                    (landscape ?
                                            "modalMobileLandscape"
                                        :
                                            "modalMobilePortrait"
                                    )
                                :
                                    ""
    const modalContentClassName = this.props.isMobile ? "modalContentMobile" : ""
    const modalBottomClassName  = this.props.isMobile ? "modalBottomMobile"  : ""

    //FIXME: future: indicate how many keys are read

    //    early attempts
    //    <Button field="import" onClick={this.handleImport} positive>Import Password-Encrypted Keys</Button>
    //    <input type="file" accept=".txt" onChange={this.handleFileImport} />

    //FIXME: style the input button: https://www.w3docs.com/tools/code-editor/6961
    //       style, AND hide: https://stackoverflow.com/questions/36540105/how-to-style-a-choose-file-button-using-css-only

    const keyWord = this.state.numItemsToImport === ' 1' ? 'key' : 'keys';

    const invalidWarning = this.state.invalidImportFile ?
                            <div style={{color: 'red'}}>Invalid import file</div>
                            : null;

    const importButtonLabel2 = this.state.invalidImportFile2 ?
                            "IMPORT wallet from this gibberish"
                          :
                            "IMPORT wallet from this gibberish"
//                            "IMPORT" + this.state.numItemsToImport2 + " " + keyWord + " from this gibberish";

    const textBox = this.state.enablePaste ?
                    <>
                      <TextArea maxLength="300000"
                                style={{width: "100%", minHeight: 150}}
                                placeholder="Paste your backed-up wallet gibberish/text here"
                                onChange={this.handlePaste}/>
                      <Button field="inspect" disabled={!this.state.enableInspectButton2 || this.state.invalidImportFile2 || this.state.importClicked}
                          onClick={() => this.solicitPassword(this.state.pastedContents)} positive>
                          {importButtonLabel2}
                      </Button>
                    </>
                  :
                    <></>

    const skippedKeysMention = this.state.keysSkipped > 0 ?
                            <>
                              <br></br><br></br>
                              {this.state.keysSkipped} of the key(s) were skipped - because they were duplicates.
                            </>
                        :
                            <></>

    //NOTE: might not be used - vestige from rabin-handling code
    const disableSave = this.state.pwd.length < 8
    const encryptPriv = this.state.getPwdForPriv ?   //NOTE: might not be used
            <>
                <div>
                    We've IMPORTED a Bitcoin private key for you. We'll use it once you've encrypted your wallet with a password, saved it to your browser, and verified you can retrieve (and decrypt) it.
                </div>
                <br></br>

                <Form>
                    Please enter a strong password (at least 8 chars):<br></br>
                    <Input style={{width:'300px'}} onChange={this.handlePwdChange}
                            placeholder="Enter a password to protect your wallet">
                      <input style={{borderRadius: '50px'}} />
                    </Input> <br></br>
                    <Button disabled={disableSave} field="saveButton" onClick={this.handleBsvKey} positive>ENCRYPT my key. I've written down my password.</Button>
                </Form>
            </>
          :
            null


        const disableCheckButton = this.state.pwd.length < 8;
        const verifierTextB =
            <>
                <p>
                    We can't import this wallet file until you've entered the <b style={{color:"blue"}}>file</b> password.
                </p>

                <Form>
                    Please enter the <b style={{color: "blue"}}>file</b> password (at least 8 chars) you used to save/export/encrypt this wallet gibberish:<br></br>
                    <Input value={this.state.pwd} style={{width:'300px'}} onChange={this.handlePwdChange} autoFocus
                            placeholder="Enter your wallet FILE password">
                      <input style={{borderRadius: '50px'}} />
                    </Input> <br></br>
                    <Button disabled={disableCheckButton} field="checkButton" onClick={this.useEnteredPasswordToDecrypt} positive>
                        DECRYPT wallet file with this password
                    </Button>
                </Form>
            </>

        const verifierText = this.state.showPasswordInputField ? verifierTextB : <></>;

        //FIXME: check if this file is invalid
        const firstMethod = this.state.disablePasteButton ?
                    <></>
                :
                    <>
                      <p>
                        <b>1 - Use a file you've saved</b>
                        <br></br>
                        Please select the file which you've used to backup your wallet. It's been encrypted with a password you provided.
                        <br></br>
                        <input type="file" name="inputFileHolder" id="inputFileHolder" />
                        <Button field="inspect" disabled={!this.state.enableInspectButton || this.state.invalidImportFile}
                                onClick={() => this.solicitPassword(document.getElementById('contentPlaceHolder').innerHTML)} positive>
                            Use this Wallet File
                        </Button>
                      </p>
                    </>
        const secondMethod = this.state.enableInspectButton ?
                    <></>
                :
                    <>
                      <p>
                        <b>2 - Use the raw (gibberish) text you've saved</b> (perhaps from an email)
                        <br></br><br></br>
                        <Button disabled={this.state.disablePasteButton} onClick={this.allowPaste} positive autoFocus>
                            Use the raw text I've saved
                        </Button>

                        {textBox}
                      </p>
                    </>
    // MODIFIED this to now solicitPassword( contents )
    //onClick={() => this.inspectContents(document.getElementById('contentPlaceHolder').innerHTML)} positive>
    return <>
              <Modal size='small' centered className={modalClassName}  open={true} style={{backgroundColor: this.bshzPurple, borderRadius: '20px'}}>
                <Modal.Header style={{textAlign: 'center', backgroundColor: this.bshzPurple, borderRadius: '20px'}}>
                <span style={{color: this.bshzYellow}}> Import/Restore Your Wallet </span>
                </Modal.Header>
                <Modal.Content className={modalContentClassName} scrolling style={{backgroundColor: this.bshzLtPurp}}>
                  There are two ways to import/restore an encrypted wallet.
                  <p></p>

                  {firstMethod}
                  <p></p>
                  {invalidWarning}

                  <br></br>
                  {secondMethod}
                  {verifierText}

                  {encryptPriv}

                  <p></p>
                  <Divider />
                  <Button field="cancelButton" onClick={this.props.closeWalletImportModal} negative>CANCEL</Button>

                  <pre id="contentPlaceHolder" style={{display:'none'}}></pre>
                </Modal.Content>
              </Modal>

              <Modal size='small' centered className={modalClassName}  open={this.state.showImportSwirly} style={{backgroundColor: this.bshzPurple, borderRadius: '20px'}}>
                <Modal.Header style={{textAlign: 'center', backgroundColor: this.bshzPurple, borderRadius: '20px'}}>
                    <span style={{color: this.bshzYellow}}> Importing Your Wallet File</span>
                </Modal.Header>
                <Modal.Content className={modalContentClassName} scrolling style={{backgroundColor: this.bshzLtPurp, borderRadius: '0px 0px 20px 20px'}}>

                    Please wait while we decrypt, and import your wallet file...
                    <br></br>

                    <Loader  inline style={{color: "black"}}>
                        Processing...
                    </Loader>
                    <br></br>
                </Modal.Content>
              </Modal>

              <Modal size='small' centered className={modalClassName}  open={this.state.showOkModal} style={{backgroundColor: this.bshzPurple, borderRadius: '20px'}}>
                <Modal.Header style={{textAlign: 'center', backgroundColor: this.bshzPurple, borderRadius: '20px'}}>
                    <span style={{color: this.bshzYellow}}> Wallet Import/Restore Complete </span>
                </Modal.Header>
                <Modal.Content className={modalContentClassName} scrolling style={{backgroundColor: this.bshzLtPurp}}>

                    We're done restoring your wallet keys and records.
                    <br></br>
                    We imported a total of <b style={{color:'blue', fontSize:'1.2rem'}}>{this.state.totalRecordsCount}</b> keys and records.

                    {skippedKeysMention}

                </Modal.Content>
                <Modal.Actions className={modalBottomClassName} style={{backgroundColor: this.bshzPurple, borderRadius: '0px 0px 20px 20px'}}>
                    <div style={{textAlign: 'center'}}>
                        <Button positive onClick={this.props.closeWalletImportModal} content='OK' autoFocus/>
                    </div>
                </Modal.Actions>
              </Modal>
          </>
  }
} // WalletImporter

export default WalletImporter;