import React from 'react';

import { Button, Checkbox, Divider, Form, Input, Loader, Modal } from 'semantic-ui-react'

import './index.css';
import {
	bsv,
} from 'scryptlib' ;

import  {
            encryptData,
            openDB,

            saveSetting,
            readSetting,

            //p2pkh
            saveEncryptedP2PKHKey,

            // rabins
            validateRabinPrivateKeys,
            generateRabinPrivateKeyPlus,
            saveEncryptedKeys,
            getEncryptedKeysFromPKH,
        } from './buildShizzle.js';
import { NETWORK } from './harnessConstants';

class WalletGenerator extends React.PureComponent {
    constructor(props) {
        super(props);

        this.bitShizzle          = <><b>BitShizzle</b><small>&trade;</small></>;

        //FIXME: the purples are currently duplicated in index.css
        this.bshzPurple             = '#8313e2';
        this.bshzYellow             = "#fff300";
        this.bshzLtPurp             = '#e7d0fb';
        this.bshzLightYellow        = "#fffccc";

        //NOTE: also used in walletExporter
        this.maxPasswordLength = 36;

        this.state = {
            understandsBoxChecked: false,
            generatorStage: 0,  // 0: have user Agree/Understand about wallets
                                // 1: generate a keypair. enter a password
            generatedPrivKeyWIF: null,   //Funds key
            pwd:                 '',
            newWalletSaved:      false,

            rabPool: '',                 // Publisher AND Builder key pool
            //builderRabinPool: '',      // Builder key pool
            p2Pool: '',                  // Builder payouts pool

            showSwirly: false,           // loader to show while generating lots of keys
            swirlyText: ''
        }

        this.setUnderstandsBox      = this.setUnderstandsBox.bind(this);
        this.showSwirlyThenBuildWallet = this.showSwirlyThenBuildWallet.bind(this);
        this.showSwirlyThenSaveWallet  = this.showSwirlyThenSaveWallet.bind(this);
        this.buildTheWallet         = this.buildTheWallet.bind(this);
        this.handlePwdChange        = this.handlePwdChange.bind(this);
        this.handleBsvKeySave       = this.handleBsvKeySave.bind(this);

        this.getWalletAgreementSetting       = this.getWalletAgreementSetting.bind(this);
        this.saveAgreementSettingThenRestoreWallet = this.saveAgreementSettingThenRestoreWallet.bind(this);
    }

    async componentDidMount() {
        const walletAgreementSetting = await this.getWalletAgreementSetting()
        console.log("WalletGenerator: the current 'walletAgreement' setting: ", walletAgreementSetting)

        // Has the user previously checked the walletAgreement?
        this.setState({understandsBoxChecked: walletAgreementSetting === null ? false : walletAgreementSetting.value})
    }

    async getWalletAgreementSetting() {

        //FIXME: check the date. compare to here-hardcoded latest revision to Terms

        const settingRecord = await readSetting(await openDB(), "walletAgreement")
        console.log("walletAgreement record: ", settingRecord)

        return settingRecord
    }

    async setUnderstandsBox() {
        const oldSetting = this.state.understandsBoxChecked
        console.warn("understands checked OLD: ", oldSetting)

        if ( oldSetting ) {
            console.warn("walletGenerator: setUnderstandsBox(): taking the opportunity to CLEAR/RESET the walletAgreement NOW")

            await saveSetting(await openDB(), "walletAgreement", false)
        }

        this.setState({understandsBoxChecked: !oldSetting})
    }

    showSwirlyThenBuildWallet() {
        const self = this
        this.setState({ showSwirly: true, swirlyText: 'Generating your wallet keys...'},
                async function () {
                    console.warn("will now save agreement")

                    // first, save the 'walletAgreement' setting
                    await saveSetting(await openDB(), "walletAgreement", self.state.understandsBoxChecked)

                    console.warn("will now generate keys...")

                    self.buildTheWallet()
                }
        )
    }

    async saveAgreementSettingThenRestoreWallet() {
        await saveSetting(await openDB(), "walletAgreement", this.state.understandsBoxChecked)

        this.props.restoreWallet()
    }

    showSwirlyThenSaveWallet() {
        this.setState({showSwirly: true, swirlyText: 'Encrypting your wallet, and saving it to your browser...'},
                        this.handleBsvKeySave
                     )
    }

    buildTheWallet() {
        const NUM_RABINS_TO_GEN = 60
        const NUM_P2PKHS_TO_GEN = 50

        // generate new FUNDING/wallet keypair
        console.log("generating " + NETWORK + " FUNDING privKey")


        //const fundingPrivKey = bsv.PrivateKey.fromRandom('testnet')
//console.log("FUNDING FROM: ", fundingPrivKey)
//alert("FUNDING FROM: " + fundingPrivKey.toString())
//console.log("FUNDING FROM: ", fundingPrivKey)

        //  moM78...
        const fundingPrivKey = bsv.PrivateKey.fromString('cUmHdLpf1bPFGrQRuLfC5AmiMYWFpG9nC5Xp4gwNUNj4mcA3Syhf')

        // SEGREGATE THESE for a while?

        //  muv... (high balance)
        //const fundingPrivKey = bsv.PrivateKey.fromString('cSpSHuWZKnWTAB5a462jf9RxdsjqSwZE7Hczic7ECryV1MZ9eRsG')
        //  n3p4...
        //const fundingPrivKey = bsv.PrivateKey.fromString('cTGRstyFhWsSoXXR2wVKLbvNRAwbBZ8Y5pn7rTKgDfkV29wzXcKp')
        //  mx1kK4...
        //const fundingPrivKey = bsv.PrivateKey.fromString('cQwMjaDdfBcwN9frRx4Mh8GnS4hkiASSoANxdts3KZcfgiGYeSRG')
        //  msHtTyGVo8rjaSxnaVkwmD5Co151URrGan
        //const fundingPrivKey = bsv.PrivateKey.fromString('cVQMJ1RDAe8jVnvXUQMMaLLnqyVEA4acwmVx4HKicQ5PrGRa5fSs')

        //  n1Ta2B...  40¢    SEGREGATE as of NOV 2 <-------
        //const fundingPrivKey = bsv.PrivateKey.fromString('cVAp9XG6sRqBSkWr54hQYGaDiJ9hLbiNNfLT7xUvaL6NvaAQZjPz')

        //  mr5ad  -- segregate since Nov 10    (has 21¢)
        //const fundingPrivKey = bsv.PrivateKey.fromString('cRhc4uDpEWFAQrHNPsMUCMEo1mKP5Aar7wc6pfbS5Wx7arUXBnGZ')



        //  muE4dz...  $5     SEGREGATED on FEB 9, 2023 <-----
        //const fundingPrivKey = bsv.PrivateKey.fromString('cNL4Hw8YYFw58S2hPrMRYEWUFkExvnSUsjCFGxUPsQAFtyinTXVM')

        //  mwpGSv...         SEGREGATE on FEB 9, 2023
        //const fundingPrivKey = bsv.PrivateKey.fromString('cPX5kbfXoVTr1SUvRmFDhCdPR171FUda27TaZJ4A7bhdQ32hPYoY')


        console.log("  address: " + fundingPrivKey.toAddress())
        //console.log("Generated new private key (WIF):", newPrivKey.toWIF())

        let p2Pool = []
        // These will be used for declaring any builder payouts - when building
        // (and eventually to collect the builder payout crumbs)
        for (let i = 0; i < NUM_P2PKHS_TO_GEN; i++) {
            console.log("generating " + NETWORK + " PAYOUT privKey " + i)
            const newPrivKey = bsv.PrivateKey.fromRandom( NETWORK )
            console.log("  address: " + newPrivKey.toAddress())

            p2Pool.push({p: newPrivKey.toWIF(), address: newPrivKey.toAddress().toString()})
        }

        let rabPool = []
        // These will be used for claiming, and publishing
        // claim, pub quarterly, pub update/periodical, pub transients
        for (let i = 0; i < NUM_RABINS_TO_GEN; i++) {
            console.log("generating rabin (publisher) keys " + i)
            const rabinPlus = generateRabinPrivateKeyPlus();

            //console.log("  generated: p = ", rabinPlus.p)
            //console.log("  generated: q = ", rabinPlus.q)
            console.log("  rabinPKH: ", rabinPlus.rabinPKH);

            //console.log("pubkeyBI: ", rabinPlus.rabinPubkeyBI)
            //console.log("pubkeyHex: ", rabinPlus.rabinPubkeyLE)
            rabPool.push({ p: rabinPlus.p, q: rabinPlus.q, pkh: rabinPlus.rabinPKH })
        }

        this.setState({ showSwirly: false,
                        generatorStage: 1,
                        generatedPrivKeyWIF: fundingPrivKey.toWIF(),
                        p2Pool: p2Pool,
                        rabPool: rabPool,
                        //builderRabinPool: builderRabinPool
                      })
    }

    handlePwdChange(event, data) {

        const v = data.value

        //NOTE: this logic also appears in WalletExporter
        // regex check for password pattern
        // gleaned from this: https://unix.stackexchange.com/questions/391866/regex-for-password-restricting-special-characters
        const re = /^[a-zA-Z0-9#@$?!%^&*()+{}=]+$/;

        if ( v !== '' && (!re.test(v) || v[0] === '-' || v.length > this.maxPasswordLength
                                || v[0] === '0' || v[0] === '1' || v[0] === '2'
                                || v[0] === '3' || v[0] === '4' || v[0] === '5'
                                || v[0] === '6' || v[0] === '7' || v[0] === '8'
                                || v[0] === '9' ) ) {
            console.log("handleNameChange(): Ignoring value - doesn't match regex")
            return
        }

        //console.log("handlePwdChange(): pwd now set to ", event.target.value);
    
        this.setState({pwd: v}); //event.target.value});
    }

    // NOTE: taken from keyUtil.js  handleBsvKeySaverSave()
    // ALSO, copied to WalletImporter's handleBsvKey() (maybe temporarily)
    //FIXME: rename to handleWalletKeySave()
    async handleBsvKeySave(event) {
        //event.preventDefault();

        // Encrypt, and save the SINGLE, 'official' p2pkh key

        const addressToSave = bsv.PrivateKey.fromWIF( this.state.generatedPrivKeyWIF ).toAddress().toString()
        console.log("handleBsvKeySave(): address is ", addressToSave);
    
    //console.log("pwd is ", this.state.pwd);

        //NOTE: we're encrypting the address within the record. Necessary?
        const bundled = {p: this.state.generatedPrivKeyWIF, address: addressToSave}
        const bundledJSON = JSON.stringify(bundled);
        let cyphText
        try {
            cyphText = await encryptData(bundledJSON, this.state.pwd);
        } catch (error) {
            alert("Please make sure you're using https://")
            throw error
        }
        console.log("official funding cypher text is ", cyphText);
        //console.warn("That cypher/blob was generated with pwd " + this.state.pwd)

        try {
            var db = await openDB();

            const recordNetworkCharacter = NETWORK === "testnet" ?
                                            't' : 'm'

            //NOTE: saves as an 'officialWallet'
            await saveEncryptedP2PKHKey(db, addressToSave, cyphText, true, recordNetworkCharacter);

            // Builder payout pool
            const retrievedPayoutPool = this.state.p2Pool
            console.warn("retrieved payouts pool: ", retrievedPayoutPool)
            for ( let i = 0; i < retrievedPayoutPool.length; i++) {
                const thisPayoutRecord = retrievedPayoutPool[i]
                //console.warn("save wallet: payout record: ", thisPayoutRecord)

                //const addressToSave = bsv.PrivateKey.fromWIF( this.state.generatedPrivKeyWIF ).toAddress().toString()
                const addressToSave = thisPayoutRecord.address
                console.warn("  save to wallet: payout address: ", addressToSave)


                const bundle = {p: thisPayoutRecord.p, address: addressToSave}

                //const bundleJSON = JSON.stringify(bundle);
                const bundleJSON = JSON.stringify(thisPayoutRecord);
                let cypherText
                try {
                    cypherText = await encryptData(bundleJSON, this.state.pwd);
                } catch (error) {
                    alert("Please make sure you're using https://")
                    throw error
                }
                console.log("payout record cypher text is ", cypherText);


                // saves to p2pkhTab
                await saveEncryptedP2PKHKey(db, addressToSave, cypherText, false, recordNetworkCharacter);
            }

            // Publisher key pool
            const retrievedRabPool = this.state.rabPool
            console.warn("retrieved rabPool: ", retrievedRabPool)
            for ( let i = 0; i < retrievedRabPool.length; i++) {
                const thisRec = retrievedRabPool[i]
                //console.log("save wallet:  rabin pool key " + i + ": ", thisRec, "   <---")
                //console.log("              rabin pool key p: ", thisRec.p)
                //console.log("              rabin pool key q: ", thisRec.q)
                console.log("              rabin pool PKH: ", thisRec.pkh)

                // this is necessary - to serialize a BigInt
                const record = {p: thisRec.p.toString(), q: thisRec.q.toString()} //, pkh: thisKey.pkh}
                const recordJSON = JSON.stringify(record);
                //const recordJSON = JSON.stringify(thisRec);
                console.log("stringified.")
                const rabinPKH = thisRec.pkh

                //FIXME: remove pkh from the plainText we're about to encrypt into a 'blob'?

                let cyphTxt
                try {
                  cyphTxt = await encryptData(recordJSON, this.state.pwd);
                } catch (error) {
                  alert("Please make sure you're using https://")
                  throw error
                }
                console.log("cypher text is ", cyphTxt);

                // save to PUBLISHER rabin table (null variant), unallocated (0)
                await saveEncryptedKeys(db, rabinPKH, cyphTxt, null, 0);
            }

            console.warn("we've saved your encrypted keys")
            this.setState({newWalletSaved: true, showSwirly: false})
            this.props.showOkModal("Wallet Created",
                                <>
                                    <p>
                                        Great. We've saved your encrypted wallet to your browser. <b style={{color:'blue'}}>Now, write down your password.</b>
                                    </p>
                                    <p>
                                        Next, we'll verify that you've written it down correctly.
                                    </p>
                                </>)
            this.props.userNowHasWallet();
        } catch (error) {
            console.error("Error while saving wallet: ", error)
            this.props.showOkModal("Wallet Save FAILED",
            <>
                <p>
                    Something went wrong. Your wallet was NOT saved. Do <b>NOT</b> use the wallet's address (shown earlier).
                </p>
                <p>
                    Please report this.
                </p>
            </>)
            alert("Something went wrong. Your wallet was NOT saved. Do NOT use that address: ", error)
            //FIXME: now what? Maybe offer ideas, and close THIS modal
        }

        // close the pop-up
        //           this.props.parent.setState({openBsvKeySaverModal: false, pwd: ''});
    }

    render() {
        //console.log("walletGenerator()")

        //COPIED FROM ONRAMP

        // ***  DEVICE-DEPENDENT STYLING  ***
        // For mobile, "detect" landscape screen mode
        const landscape = this.props.isMobile ? window.innerWidth > window.innerHeight : false
        // For mobile, shrink the About, Security, and Glossary modals
        const modalClassName   = this.props.isMobile ?
                                        (landscape ?
                                                "modalMobileLandscape"
                                            :
                                                "modalMobilePortrait"
                                        )
                                    :
                                        ""
        const modalContentClassName = this.props.isMobile ? "modalContentMobile" : ""
        //const modalBottomClassName  = this.props.isMobile ? "modalBottomMobile"  : ""


        const warningColor = this.state.understandsBoxChecked ? "blue" : "red"
        const generatorTextA =
                <>
                    <p>
                        We're about to generate a browser wallet address for you to keep the Bitcoin you'll use to fund everything you might do at {this.bitShizzle}.
                    </p>
                    <p> <span style={{color: "red"}}><b>WARNING: </b></span>
                        We'll be storing your encrypted wallet <b>in this browser</b>. Unless you copy it to another device or browser,
                        you'll only be able to access it from <b>this</b> particular browser on <b>this</b> device only. And unless you copy it to another device,
                        if this device is ever lost or damaged, you'll lose access to your Bitcoin, and any assets you've claimed - forever.
                    </p>
                    <p>
                        To protect your browser wallet, we'll ask you to create a wallet password. We'll use the password to encrypt your browser wallet.
                    </p>
                    <p>
                        <span style={{color: "red"}}><b>WARNING: </b></span>Do not put more than a small amount of Bitcoin in this wallet
                        (worth no more than $3 USD). It is experimental, and could lose your funds and keys <b>forever</b>.
                    </p>
                    <p>
                        <span style={{color: "red"}}><b>WARNING: </b></span>If you use your device to visit sketchy sites, you risk the
                        security of your browser wallet.
                    </p>
                    <Divider />
                    <span style={{color: warningColor}}>By checking this box, you agree that you'll backup your browser wallet, and that you understand the wallet
                        should NEVER hold more than a small amount of Bitcoin (worth less than $3 USD). You also agree to allow BitShizzle to save
                        your password-encrypted wallet to your browser.
                    </span> &nbsp; &nbsp;

                    <Checkbox label='I agree' checked={this.state.understandsBoxChecked} onClick={this.setUnderstandsBox}/>
                    <br></br><br></br>

                    <div style={{textAlign: 'center'}}>
                        <Button  color='green' onClick={this.showSwirlyThenBuildWallet} disabled={!this.state.understandsBoxChecked}>
                            <span style={{color: 'white'}}>
                                Build My Browser Wallet
                            </span>
                        </Button>
                        <Button  color='grey' onClick={this.saveAgreementSettingThenRestoreWallet} disabled={!this.state.understandsBoxChecked}>
                            <span style={{color: 'white'}}>
                                Restore My Backed-Up Wallet
                            </span>
                        </Button>
                    </div>
                </>
        const disableSave = this.state.pwd.length < 8  || this.state.newWalletSaved;
        //NOTE: we have identical password rules description in walletExporter
        const generatorTextB =
                <>
                    <p>
                        We've generated a Bitcoin address for you. We'll show it to you once you've encrypted your wallet with a password, saved it to your browser, and verified you can retrieve (and decrypt) it.
                    </p>

                    <Form>
                        Please enter a <b style={{color:'blue'}}>strong password</b> (at least 8 chars):<br></br>
                        <Input value={this.state.pwd} style={{width:'300px'}}
                                placeholder="Enter a password to protect your wallet"
                                onChange={this.handlePwdChange} autoFocus>
                            <input style={{borderRadius: '50px'}} />
                        </Input>
                        <br></br>
                        Your password can be up to {this.maxPasswordLength} characters long, may
                        have <span style={{color:"blue"}}>numbers, upper and lower case letters</span>, and any of these special
                        characters: <span style={{color:"blue", fontSize:"1.1rem"}}>#@$?!%^&amp;*()+=&#123;&#125;</span>
                        <br></br>
                        <br></br>
                        <Button disabled={disableSave} field="saveButton" onClick={this.showSwirlyThenSaveWallet} positive>
                            ENCRYPT my wallet. I've written down my password.
                        </Button>
                    </Form>
                </>
        const generatorText = this.state.generatorStage === 0 ?
                                    generatorTextA
                                        :
                                    generatorTextB;

        return(
                <>
                    {generatorText}

            <Modal dimmer='blurring' size='mini' centered className={modalClassName}  open={this.state.showSwirly}
                            style={{backgroundColor: this.bshzPurple, borderRadius: '20px', height: "auto"}}>
                <Modal.Content className={modalContentClassName} scrolling style={{backgroundColor: this.bshzLtPurp}}>

                  <Loader inline style={{color: "black"}}>
                    {this.state.swirlyText}
                  </Loader>

                </Modal.Content>
            </Modal>
                </>
        )
    }
}

export default WalletGenerator;
