import React from 'react';

import { Button, Checkbox, Container, Divider, Form, Header, Icon, Input, Message, Modal, Radio, Segment } from 'semantic-ui-react'

import Toast from './toast';

import { encryptData,
        readSetting, saveSetting, openDB } from './buildShizzle.js';

class GifterModal extends React.PureComponent {
    constructor(props) {
        super(props);

        this.handleModalOk             = this.handleModalOk.bind(this);
        this.encryptKey                = this.encryptKey.bind(this);
        this.copyEncKeyToClipboard     = this.copyEncKeyToClipboard.bind(this);

        this.BitSchnitzelLLC     = <>BitSchnitzel LLC</>;
        this.shizzleVerse        = <><b>ShizzleVerse</b><small>&trade;</small></>;
        this.bitShizzle          = <><b>BitShizzle</b><small>&trade;</small></>;
        this.bitShizzleItalic    = <><b>Bit<i>Shizzle</i></b><small>&trade;</small></>;
        this.shizzleVerseNotBold = <>ShizzleVerse<small>&trade;</small></>;
        this.bitShizzleNotBold   = <>BitShizzle<small>&trade;</small></>;

        //FIXME: the purples are currently duplicated in index.css
        this.bshzPurple             = '#8313e2';
        this.bshzYellow             = "#fff300";
        this.bshzLtPurp             = '#e7d0fb';
        this.bshzLightYellow        = "#fffccc";

        this.state = {
            pin: '',
            cyphText: '',

            showQuickToast:         false,
            toastText:              '',
            toastDuration:          800,
            suppressLoader:         false,
        }
    }

    async componentDidMount() {

        //console.log("gifterModal generated four random bytes to encrypted key with: ", fourRandos)
        // example: Uint8Array(4) [219, 201, 182, 64]

        // generate a random 12-digit PIN we'll use to encrypt the keys
        // Uses 5 random bytes

        let decimalRando
        let fiveRandos
        do {
            fiveRandos = window.crypto.getRandomValues(new Uint8Array(5))

            decimalRando = fiveRandos[4]
                         + fiveRandos[3] * 256
                         + fiveRandos[2] * 256 * 256
                         + fiveRandos[1] * 256 * 256 * 256
                         + fiveRandos[0] * 256 * 256 * 256 * 256

            //console.error(" pinHex  is: ",  decimalRando.toString(16))
            //console.error(" pin is: ",  decimalRando.toString())

            // allow starting with a single 0, but no more than that
        } while ( decimalRando.toString().length < 11 )

        let decimalString = decimalRando.toString()
        if ( decimalString.length < 12 ) {
            // manually add the leading 0
            decimalString = "0" + decimalString
            //console.log("zero-padded to: " + decimalString)
        } else if ( decimalString.length > 12 ) {
            //console.log("decimalString WAS " + decimalString)
            decimalString = decimalString.substring(1, 13)
            //console.log("truncated down to: " + decimalString)
        }

        // we'll display the PIN as decimal, but encrypt with the hex form
        // NOTE that we're re-calculating the hex, since we may have truncated the decimal
        this.setState({ pin: decimalString,
                        pinHex: Number(decimalString).toString(16)})

        //console.warn(" PIN LEN: " + decimalString.length )
        //console.error("five randos: ", fiveRandos)
        //console.error(" pin is: ",  decimalString)
    }

    componentWillUnmount() {
        console.log("gifterModal about to unmount. ")

        // fix Warning: Can't perform a React state update on an unmounted component
        // see: https://stackoverflow.com/questions/53949393/cant-perform-a-react-state-update-on-an-unmounted-component
        this.setState = (state,callback)=>{
            return;
        };
    }

    async handleModalOk() {
        this.props.closeMe()
    }

    async encryptKey() {
        console.warn("PKH used to claim: ", this.props.pkhUsed)
        //console.warn("random bytes: ", this.state.fiveRandos)

        const pin = this.state.pin
        const pinHex = this.state.pinHex
        //console.warn("Pin used:    ", pin)
        //console.warn("PinHex used: ", pinHex)

        const fromWhom = this.props.fromWhich ? this.props.fromWhich : null

        const bundleToEncrypt = {
                                    pkhUsed:      this.props.pkhUsed,
                                    pUsedToClaim: this.props.pUsed,
                                    qUsedToClaim: this.props.qUsed,
                                    domain:       this.props.domain,
                                    sentBy:       fromWhom
                                }
        const bundleJsonToEncrypt = JSON.stringify(bundleToEncrypt);

        //console.warn("Here's what we're about to encrypt: ", bundleJsonToEncrypt)

        let cyphText
        try {
            // This could be sitting in an email inbox for some time
            // Use 900k rounds/iterations when encrypting
            cyphText = await encryptData(bundleJsonToEncrypt, pinHex, null, 900000);
        } catch (error) {
            alert("GifterModal: Key encryption failed. Please make sure you're using https://")
            throw error
        }

        console.warn("Here's the pin-encrypted cypher text: ", cyphText)

        this.setState({cyphText: cyphText})
    }

    // show a 'toast' for a specified period
    quickToast(toastText, duration=2000, withoutLoader = false) {
        console.warn("quickToast()")
        this.setState({showQuickToast: true, toastText: toastText, toastDuration: duration, suppressLoader: withoutLoader})
        // once expired, quickToastDone() will be called
    }
    // called-back by toast, once it's expired
    quickToastDone(self) {
        self.setState({showQuickToast: false, suppressLoader: false})

        // now that it's expired, do NOTHING
    }
    async copyEncKeyToClipboard() {
        try {
            await navigator.clipboard.writeText(this.state.cyphText);

            this.quickToast("Encrypted key copied to clipboard", 1100, true)
        } catch (error ) {
            alert("SORRY. We couldn't copy your friend's encrypted key.\nPlease copy it MANUALLY - then paste it into an email to your friend.")
        }
    }

    render() {
        console.warn("gifterModal  render()")
        console.warn("gifterModal render(): pkhUsed: ", this.props.pkhUsed)

        // ***  DEVICE-DEPENDENT STYLING  ***
        // For mobile, "detect" landscape screen mode
        const landscape = this.props.isMobile ? window.innerWidth > window.innerHeight : false
        // For mobile, shrink the About, Security, and Glossary modals
        const modalClassName   = this.props.isMobile ?
                                        (landscape ?
                                                "modalMobileLandscape"
                                            :
                                                "modalMobilePortrait"
                                        )
                                    :
                                        ""
        const modalContentClassName = this.props.isMobile ? "modalContentMobile" : ""
        const modalBottomClassName  = this.props.isMobile ? "modalBottomMobile"  : ""

        const oneTimeEncryptButton = this.state.cyphText === '' ?
                                        <>
                                            <Button onClick={this.encryptKey} positive>
                                                    Encrypt the Key with this PIN
                                            </Button>
                                        </>
                                    :
                                        <></>

        const instructions = this.state.cyphText === '' ?
                                        <>
                                            <div>
                                                Here's how we'll send domain <b style={{color:"blue", fontSize:"1.3rem"}}>{this.props.domain.toUpperCase()}</b> to your friend:<br></br>
                                                - We've generated a secret PIN for your friend <b style={{color:"red"}}>({this.state.pin})</b><br></br>
                                                - You need to write down the PIN <b style={{color:"red"}}>right now</b><br></br>
                                                <br></br>
                                                Now, we'll encrypt the KEY that you used to claim the domain.<br></br>
                                                - Then, you'll <b>EMAIL</b> the encrypted KEY (gibberish) to your friend<br></br>
                                                - Then, you'll <b>TEXT</b> the PIN to your friend (do NOT put it in the email)<br></br>
                                            </div>
                                        </>
                                    :
                                        <>
                                            <div>
                                                You're now ready to send domain <b style={{color:"blue", fontSize:"1.3rem"}}>{this.props.domain.toUpperCase()}</b> to your friend!
                                                <br></br>

                                                <Message>
                                                    <Message.Header style={{color:this.bshzPurple}}>Now do these two things</Message.Header>
                                                    <Message.List>
                                                        <Message.Item>
                                                            <b style={{color:"blue"}}>Email</b> the blob (gibberish below) to your
                                                            friend, asking them to visit <wbr></wbr>
                                                            <span style={{color:"blue", whiteSpace:"nowrap"}}>https://bitshizzle.com/?gift</span>
                                                        </Message.Item>
                                                        <Message.Item><b style={{color:"blue"}}>Text</b> them the PIN, <span style={{color:"red"}}>separately</span>: <b style={{color:"blue"}}>{this.state.pin}</b></Message.Item>
                                                    </Message.List>
                                                </Message>

                                                <Message>
                                                    <Message.Header style={{color:this.bshzPurple}}>
                                                        Encrypted Key for your friend &nbsp; <Icon name='clone outline' onClick={this.copyEncKeyToClipboard}/>
                                                    </Message.Header>

                                                    <p style={{wordWrap:"break-word", maxWidth:"45em"}}>
                                                        {this.state.cyphText}
                                                    </p>
                                                </Message>

                                            </div>
                                        </>

        // don't present a DONE button until we're on the final step
        const doneButton = this.state.cyphText === '' ?
                    <></>
                :
                    <>
                        <div style={{textAlign: 'center'}}>
                         <Button positive onClick={this.handleModalOk} content='DONE emailing + texting'/>
                        </div>
                    </>

        const maybeQuickToast = this.state.showQuickToast ?
                <>
                <Toast  durationMillis={this.state.toastDuration}
                    displayText={this.state.toastText}
                    noLoader={this.state.suppressLoader}
                    done={this.quickToastDone}
                    parent={this}/>
                </>
            :
                <></>

        return(
                <>
                    <Modal size='small' centered className={modalClassName}  open={true} style={{backgroundColor: this.bshzPurple, borderRadius: '20px'}}>
                        <Modal.Header style={{textAlign: 'center', backgroundColor: this.bshzPurple, borderRadius: '20px'}}>
                            <span style={{color: this.bshzYellow}}> Gift-a-Shizzle </span>
                        </Modal.Header>
                        <Modal.Content className={modalContentClassName} scrolling style={{backgroundColor: this.bshzLtPurp}}>

                            {instructions}

                            {oneTimeEncryptButton}

                        </Modal.Content>
                        <Modal.Actions className={modalBottomClassName} style={{backgroundColor: this.bshzPurple, borderRadius: '0px 0px 20px 20px'}}>
                            {doneButton}
                        </Modal.Actions>
                    </Modal>

                    {maybeQuickToast}
                </>
        )
    }
}

export default GifterModal;
