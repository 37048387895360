import React from 'react';

import { Button, Container, Divider, Icon, Input,
         Modal, Popup, Segment, Sidebar, Sticky, Transition } from 'semantic-ui-react'

import ShizzleMasthead from './shizzleMasthead';
import Footer      from './footer';
import Toast       from './toast';

import './index.css';

import QuoteSlider     from './quoteSlider';
import GiftRedeemerModal from './giftRedeemerModal';

import { execAsync, validateShizzleTree } from './commonFuncs';
import { openDB, findAllDomainsOfMine, getOfficialWalletJson,
         queryFetchDecodeTx, readSetting, saveSetting } from './buildShizzle';
import { getDecodedTx } from './shizzleIDB';
import { NETWORK, TARGET_RATE, GENESIS_APPEND } from './harnessConstants'

import { bshzColors } from './bshzColors';

// device-specific custom sidebar
// see this: https://stackoverflow.com/questions/52345724/why-custom-css-class-does-not-work-on-react-semantic-ui-elements
import './componentStyles.css'

function execAsync2( f, msecs ) {
    setTimeout(f, msecs)
}

class ShizzleHome extends React.PureComponent {
    constructor(props) {
        super(props);

        this.fontFamily          = "verdana"

        this.BitSchnitzelLLC     = <>BitSchnitzel LLC</>;
        this.shizzleVerse        = <><b>ShizzleVerse</b><small>&trade;</small></>;
        this.bitShizzle          = <><b>BitShizzle</b><small>&trade;</small></>;
        this.bitShizzleItalic    = <><b>Bit<i>Shizzle</i></b><small>&trade;</small></>;
        this.shizzleVerseNotBold = <>ShizzleVerse<small>&trade;</small></>;
        this.bitShizzleNotBold   = <>BitShizzle<small>&trade;</small></>;

        this.segmentA_blurb_tweets = <>Tweet from your own Bitcoin account</>;
        this.segmentB_blurb_website = <>Build your own Bitcoin-powered Website</>
        this.segmentC_blurb_communicate = <>Communicate privately over Bitcoin</>
        this.segmentD_blurb_spam = <>Charge spammers for your time</>
        this.segmentE_blurb_earn = <>Earn Bitcoin from your followers</>

        //WARNING: set this to a value that divides evenly into 1000, but doesn't exceed:
        //             25, 50, 100, 200, 1000
        //         Trade-off: lower number mean more processing, but better
        //                    magical scolling effect behind masthead
        this.intervalMillis = 250  // how often to tick the clock
        //NOTE: assumes millisCount (every intervalMillis) hits these exactly
        //NOTE: 33250 is exactly how long the transitions in quoteSlider take:
        //          6x(longInterval + shortInterval) + finalInterval + shortInterval + 750 delay
        this.animationPeriod = 33000
        this.mastheadJiggleOffsetMillis = 20500 //16000
        this.signupJiggleOffsetMillis   = 30000

//FIXME: repeated in shizzleWhy.js
        this.browserExplanation =
        <>
            <p>
                The {this.bitShizzleNotBold} Bitcoin browser allows you to claim, explore, use, and build on Bitcoin identities and websites in the {this.shizzleVerseNotBold}.
            </p>
        </>

        this.state =  { showEmailSignup: false,
                        userEmail: '',
                        alreadySent: false,         // prevents user from submitting multiple emails

                        showGenericOkModal: false,
                        showServerErrorModal: false,

                        mostRecentTopic: 0,
                        invertSignUp: false,

                        colorTweets:        bshzColors.ltPurple,
                        colorCommunicate:   bshzColors.ltPurple,
                        colorSpam:          bshzColors.ltPurple,
                        colorWebsite:       bshzColors.ltPurple,
                        colorEarn:          bshzColors.ltPurple,

                        label1: <h2 style={{textAlign: "center", fontFamily: this.fontFamily, color: bshzColors.purple}} >
                                    {this.segmentA_blurb_tweets}
                                </h2>,       //A
                        label2: <h2 style={{textAlign: "center", fontFamily: this.fontFamily, color: bshzColors.purple}} >
                                    {this.segmentC_blurb_communicate}
                                </h2>,       //C
                        label3: <h2 style={{textAlign: "center", fontFamily: this.fontFamily, color: bshzColors.purple}} >
                                    {this.segmentD_blurb_spam}
                                </h2>,       //D
                        label4: <h2 style={{textAlign: "center", fontFamily: this.fontFamily, color: bshzColors.purple}} >
                                    {this.segmentB_blurb_website}
                                </h2>,       //B
                        label5: <h2 style={{textAlign: "center", fontFamily: this.fontFamily, color: bshzColors.purple}} >
                                    {this.segmentE_blurb_earn}
                                </h2>,       //E

                        scrollY: 0,
                        millisCount: 0,
                        jiggleMasthead: true,
                        fadeInMain: false,
                        jiggleSignup: true,
                        jiggleFooter: true,
                        qualityTxs:  false,

                        tempParam: this.props.tempParam,
                        authToken: this.props.authToken,   // for handcash

                        isLoaded: false,
                        footerVisible: false,

                        jiggleArrow: true,
                        arrowAnimation: 'shake',

                        closeGiftRedeemer: false,
                        showGiftRedeemer: false,  // set by click of button

                        hasWallet:     false,
                        showGoButtons: false,

                        showDbInitializationToast: false,
                      }

        this.reanimate              = this.reanimate.bind(this);
        this.handleSegA_tweets      = this.handleSegA_tweets.bind(this);
        this.handleSegB_website     = this.handleSegB_website.bind(this);
        this.handleSegC_communicate = this.handleSegC_communicate.bind(this);
        this.handleSegD_spam        = this.handleSegD_spam.bind(this);
        this.handleSegE_earn        = this.handleSegE_earn.bind(this);


        this.showSignUp             = this.showSignUp.bind(this);
        this.getStarted             = this.getStarted.bind(this);
        this.citizenOptions         = this.citizenOptions.bind(this);
        this.closeSignUp            = this.closeSignUp.bind(this);

        this.handleEmail            = this.handleEmail.bind(this);
        this.validateEmail          = this.validateEmail.bind(this);
        this.signUp                 = this.signUp.bind(this);
        this.handleMouseEnter       = this.handleMouseEnter.bind(this);
        this.handleMouseLeave       = this.handleMouseLeave.bind(this);
        this.handleGenericModalOk   = this.handleGenericModalOk.bind(this);

        this.signInOutCallback      = this.signInOutCallback.bind(this);

        this.handleServerErrorModalOk = this.handleServerErrorModalOk.bind(this);

        this.showRedeemerBecauseOfClick = this.showRedeemerBecauseOfClick.bind(this);
        this.closeGiftRedeemer      = this.closeGiftRedeemer.bind(this);

        this.handleWindowVisibilityChange = this.handleWindowVisibilityChange.bind(this);
        this.periodicRun            = this.periodicRun.bind(this);

        this.openDrawer                 = this.openDrawer.bind(this);
        this.closeDrawer                = this.closeDrawer.bind(this);
        this.logoutThenCloseDrawer      = this.logoutThenCloseDrawer.bind(this);

        // If we got here because we just filled-out a survey, let's clean the address bar (set to homepage)
        try {
            window.history.pushState("", "", "/")
        } catch (error) {
            console.error("FAILED to window.history.pushState(). Perhaps we're surfing from a CACHED copy of the site.")
        }

        console.log("BTW: tempParam: ", this.props.tempParam)
    }

    async signInOutCallback() {
        if ( this.props.citizenLevel === 1 ) {
            console.error("home: we should SHOW the verifier")
            this.setState({showVerifier: true})
        } else if ( this.props.citizenLevel > 1 ) {
            console.error("home: We should FORGET official key record, and disappear the verifier.")

            this.setState({showVerifier: false})
            this.bundle = null   //FIXME: ok?
            await this.props.changeCitizenLevel(1)
        }
    }

    componentWillUnmount() {
        console.error("ShizzleHome about to unmount. "); //Will UNregister...")

        document.removeEventListener("visibilitychange", this.handleWindowVisibilityChange)

    //    this.props.unregisterClient(this.props.parent, this, "shizzleNav")

        // Avoid timer-related (and other?) functions being called after unmount
        // fix Warning: Can't perform a React state update on an unmounted component
        // see: https://stackoverflow.com/questions/53949393/cant-perform-a-react-state-update-on-an-unmounted-component
        this.setState = (state,callback)=>{
            return;
        };
    }

    handleWindowVisibilityChange(event) {
        if (document.visibilityState === "visible") {
            console.warn("TAB VISIBLE")
            // be responsive for scrolling effect - while in the foreground
            this.intervalMillis = 250
        } else {
            console.warn("TAB HIDDEN")
            // slow-down rate (hopefully eat less CPU) while in the background
            this.intervalMillis = 750
        }
    }

    async componentDidMount() {
        // If we got here because we just filled-out a survey, let's scroll back up to the top
        window.scrollTo(0, 0)

        document.addEventListener("visibilitychange", this.handleWindowVisibilityChange);

        const me = this
        //NOTE: this could be cancelled (and re-instated)
        const timerId = setInterval(async function() {
                            // pass in the interval used
                            //NOTE: appears to get updated as intervalMillis is changed
                            //      WITHOUT us having canceled, and re-instated the timer
                            await me.periodicRun(me, me.intervalMillis);
                        }, me.intervalMillis);

        const db = await openDB()

        // check for a wallet
        const officialWalletJson = await getOfficialWalletJson(db)

        // check for any registered domains
        const myDomains = await findAllDomainsOfMine(db)
        console.log("MY DOMAINS: ", myDomains)
        console.log("len: ", myDomains.length)
        let showGoButtons = false

        let hasWallet = false
//FIXME: why > 2?
        if ( officialWalletJson.length > 2 ) {
            hasWallet = true
        }

        // If user has a domain AND a wallet
        // show the 'not a newb' button
        // FIXME: is the WALLET check necessary? <-----
        //        If user has already 'registered' a domain, they probably HAVE a wallet, right?
        if ( hasWallet && myDomains.length > 0 ) {
            showGoButtons = true
        }

        this.setState({ effectTimerId: timerId,
                        isLoaded: true,
                        hasWallet:     hasWallet,
                        showGoButtons: showGoButtons,
                        myDomains: myDomains
                     })

        if ( typeof window !== 'undefined' ) {
            console.error("width: ", window.innerWidth)
            console.error("height: ", window.innerHeight)
        }

    }

    async periodicRun(me, intervMillis) {

        // Tick the clock count
        let millisCount = this.state.millisCount + intervMillis

        // Fired only ONCE - almost immediately at the start
        let fadeInMain = this.state.fadeInMain

        const moduloMillisCount = millisCount % this.animationPeriod
        // Once each period, re-start the quoteSlider
        if ( moduloMillisCount < intervMillis ) {
            //console.warn("RE-STARTING SLIDER ANIMATION.  state.millisCount: "  + this.state.millisCount)
            //console.warn("RE-STARTING SLIDER ANIMATION.  millisCount: "        + millisCount)
            //console.warn("RE-STARTING SLIDER ANIMATION.  moduloMillisCount: "  + moduloMillisCount)
            //console.warn("RE-STARTING SLIDER ANIMATION.  this.intervalMillis: " + this.intervalMillis)
            //console.warn("RE-STARTING SLIDER ANIMATION.  intMillis: " + intMillis)

            // visibility API could have us changing this.intervalMillis at random times
            // When starting over, let's do so with a known/fixed clock
            millisCount = 0

            this.setState({restartQuoteSlider: true})
        } else {
            this.setState({restartQuoteSlider: false})
            // Only fadeInMain once - just after start (to start the fade-in animation)
            // Once fadeInMain is true, keep that value (never toggle) - else it would re-start the fade-in animation
            fadeInMain = fadeInMain || (millisCount == intervMillis)
        }

        // jiggle the masthead and signup button at specific times within the animation period
        // They're synchronized with quotes within the quoteSlider
        const toggleMastheadJiggle = moduloMillisCount == this.mastheadJiggleOffsetMillis
        const toggleSignupJiggle   = !this.state.alreadySent && moduloMillisCount == this.signupJiggleOffsetMillis

        const jiggleMasthead = toggleMastheadJiggle ? !this.state.jiggleMasthead : this.state.jiggleMasthead
        const jiggleSignup   = toggleSignupJiggle   ? !this.state.jiggleSignup : this.state.jiggleSignup

        const qualityTxs     = (moduloMillisCount > 13000) && (moduloMillisCount < 24000)


        //console.warn("millisCount: " + millisCount)
        //console.warn("    jiggleMasthead (before): ", this.state.jiggleMasthead)
        //console.warn("    toggleMastheadJiggle: ", toggleMastheadJiggle)
        //console.warn("    jiggleMasthead (after any toggle): ", jiggleMasthead)

        me.setState({   scrollY:        window.scrollY,
                        fadeInMain:     fadeInMain,
                        jiggleMasthead: jiggleMasthead,
                        jiggleSignup:   jiggleSignup,
                        millisCount:    millisCount,
                        qualityTxs:     qualityTxs,
                    })
    }

    handleSegA_tweets() {
        // pop up a generic modal - with specific text

        const titleText   = <>Your Own Twitter-like Bitcoin Account</>
        const contentText = <>
                            Claim a name that's your's to keep:<br></br>
                            <div style={{color: 'blue'}}><h1>{'\u20BF'}@&#123;<i>your-name</i>&#125;<small>&trade;</small></h1></div>

                            Use a separate name for family and friends:
                            <div style={{color: 'blue'}}><h1>{'\u20BF'}@&#123;<i>your-nickname</i>&#125;<small>&trade;</small></h1></div>

                            <p>Changed your nick-name? <b>Sell</b> it, and get a new one.</p>


                            <p>Customize how your tweets look. Add some color, or change the font. They're <b>your</b> words, shouldn't you decide how they look?</p>

                            <p>They're all registered on the Bitcoin blockchain, so, there's no monopoly in charge. <b>You</b> own them.</p>

                            <p>Getting your own Bitcoin Identity is the first step to putting <b>yourself</b> in charge.</p>

                            </>
        const buttonText  = <>Got it</>

        // Use a generic modal that has a simple OK-like button
        this.setState({
                        showGenericOkModal: true,
                        genericModalTitle: titleText,
                        genericModalContent: contentText,
                        genericModalButton: buttonText
                    })
    }
    handleSegB_website() {
        // pop up a generic modal - with specific text

        const titleText   = <>Your Own Bitcoin Website</>
        const contentText = <>&nbsp;<p>Build your own website (Blog, Photo Gallery, or NewsLetter) - without paying for a web server.</p>
                            <p>Claim a domain name that's yours to keep, for a site that's always running.</p>
                            <p>Tired of your Bitcoin domain name? Sell it, and get another.</p>&nbsp;</>
        const buttonText  = <>Got it</>

        // Use a generic modal that has a simple OK-like button
        this.setState({
                        showGenericOkModal: true,
                        genericModalTitle: titleText,
                        genericModalContent: contentText,
                        genericModalButton: buttonText
                    })
    }
    handleSegC_communicate() {
        // pop up a generic modal - with specific text

        const titleText   = <>Private Commmunications</>
        const contentText = <>  &nbsp;
                                <p>Most email providers scan your mail to "better server you", but it actually makes them rich - helping them <b>sell your eyeballs</b> to creepy advertisers. </p>
                                <p>With {this.bitShizzleNotBold}, any message you send can be completely private. Only you and the other party(s) can read it. Google and Facebook won't have any idea what you're up to.</p>
                                &nbsp;
                            </>
        const buttonText  = <>Got it</>

        // Use a generic modal that has a simple OK-like button
        this.setState({
                        showGenericOkModal: true,
                        genericModalTitle: titleText,
                        genericModalContent: contentText,
                        genericModalButton: buttonText
                    })
    }
    handleSegD_spam() {
        // pop up a generic modal - with specific text

        const titleText   = <>Set a price on your inbox</>
        const contentText = <>&nbsp;<p>Spammers can no-longer reach you without paying you - and <b>you</b> set the price, in Bitcoin.</p>
                                <p>Having a busy week? Raise your price. Bored out of your mind? Lower it. </p><p><b>You</b> decide how much your time is worth.</p>&nbsp;</>
        const buttonText  = <>Got it</>

        // Use a generic modal that has a simple OK-like button
        this.setState({
                        showGenericOkModal: true,
                        genericModalTitle: titleText,
                        genericModalContent: contentText,
                        genericModalButton: buttonText
                    })
    }
    handleSegE_earn() {
        // pop up a generic modal - with specific text

        const titleText   = <>Earn tips from your followers</>
        const contentText = <>&nbsp;<p>Your readers/followers will appreciate content that isn't laced with advertisements.</p>
                                <p>{this.bitShizzleNotBold} let's them show you their support by tipping you in Bitcoin.</p>&nbsp;</>
        const buttonText  = <>Got it</>

        // Use a generic modal that has a simple OK-like button
        this.setState({
                        showGenericOkModal: true,
                        genericModalTitle: titleText,
                        genericModalContent: contentText,
                        genericModalButton: buttonText
                    })
    }

    showSignUp(e) {
        this.setState({showEmailSignup: true})
    }

    getStarted(e) {
        // get started on-ramping user
        this.props.handleJumpToOnRamp()
    }

    // Called by the "I'm no newb" button
    // Jumps right to the Surfer - assumes enough funds
    async citizenOptions(e) {

        const db = await openDB()

        // determine if this is a brand NEW user
        // IOW: do we need to query for the genesis tx?
        // IOW: try our DB first. If nothing there, query our tx-provider
        const genesis = await getDecodedTx(db, GENESIS_APPEND)
        if ( genesis === null ) {

            this.setState({showDbInitializationToast: true})

            console.warn("NOTE: brand-new user. We need to prime the pump (query the genesis tx)")
            await queryFetchDecodeTx(GENESIS_APPEND, db, true)

            this.setState({showDbInitializationToast: false})
        }

        // make sure we don't have an OLD genesis (in IDB), while
        // runing a NEW browser which uses a NEWER genesis/root
        // THIS CHECK IS only for during testing
        // Once fully-deployed, we should never update the genesis/root
        validateShizzleTree()

        if ( this.state.myDomains.length > 0 ) {
            // The user claims he knows what he's doing
            // Bump-up his level to 4 - has enough funds, and an asset
            this.props.changeCitizenLevel(4)

            // COPIED from onRamp, for reference:

            // 0: has no wallet
            // 1: has a wallet
            // 2: password entered/verified
            // 3: good wallet (enough money)
            // 4: user owns an asset

            let domain     = this.state.myDomains[0].name
            let ownerCount = this.state.myDomains[0].ownerCount

            // get the 'default' identity, if there is one
            const defID = await readSetting(db, "defaultIdentity")
            if ( defID !== null && defID.value !== '' ) {
                domain = defID.value

                console.warn("ShizzleHome: retrieved default identity:  ", domain)

                // get the 'default' identity owner count, if there is one
                // NOTE: this is a NEW setting
                const defIDOC = await readSetting(db, "defaultIdentityOwnerCount")
                if ( defIDOC !== null ) {
                    ownerCount = defIDOC.value

                    console.warn("Retrieved default identity owner count:  ", ownerCount)
                } else {
                    ownerCount = 1
                    alert("HOME: WARNING: Retrieved NO default identity owner count, so, will use 1 ?!?!?!?")
                }
            } else {
                alert("current default identity is perhaps blank. will use this instead: " + domain + ":" + ownerCount)
            }

            this.props.handleJumpToSurfer(domain, ownerCount)
        }
    }

    closeSignUp() {
        this.setState({showEmailSignup: false, userEmail: ''})
    }

    // alternatively, can be shown because of query string: /?gift
    showRedeemerBecauseOfClick() {
        this.setState({showGiftRedeemer: true, closeGiftRedeemer: false})
    }
    closeGiftRedeemer(restart = false) {
        this.setState({closeGiftRedeemer: true, showGiftRedeemer: false})

        // If we're here because user just peformed a
        // FULL RESET in the GiftRedeemer, then start
        // the GiftRedeemer over again
        if ( restart ) {
            const parent = this
            execAsync2( async function() {
                parent.setState({closeGiftRedeemer: false})
            }, 1750)
        }
    }

    handleEmail(e, v) {
        console.log("handleEmail: ", v.value)
        this.setState({userEmail: v.value})
    }

    validateEmail(email) {
        const res = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return res.test(String(email).toLowerCase());
    }

    async signUp(e) {
        e.preventDefault()

        console.log(" email: ", this.state.userEmail)
        console.log("validate email: ", this.validateEmail(this.state.userEmail))

        const sent = await this.props.sendEmail(this.state.userEmail, '')

        const titleText   = sent ? <>Check your email</> : <>The sign-up failed</>
        const contentText = sent ? <>We've sent an email to confirm your interest.</> : <>Please come back later to sign-up.</>
        const buttonText  = sent ? <>Great!</> : <>Sure</>

        console.log("before setting: already sent, and sendCheckEmail: ", this.state.alreadySent)
//FIXME: change alreadySent to userHasSignedUp

        // Use a generic modal that has a simple OK-like button
        this.setState({ alreadySent: sent,
                        showGenericOkModal: true,
                        genericModalTitle: titleText,
                        genericModalContent: contentText,
                        genericModalButton: buttonText
                    })
        this.closeSignUp() //FIXME: use setState() above?
    }

    handleGenericModalOk() {
        this.setState({showGenericOkModal: false})
    }

    handleServerErrorModalOk() {
        this.setState({showServerErrorModal: false})
    }

    // user hovering over a segment
    handleMouseEnter(which) {
        //console.log("Mouse entered " + which)
 
        //console.log("ENTER: mostRecentTopic: ", this.state.mostRecentTopic + "   before setting NEW topic (which): ", which)
        if ( typeof this.state.mostRecentTopic !== 'undefined' && this.state.mostRecentTopic !== which ) {
            //console.log("   ENTER: new (which) has len ", which.length)

            //alert("ENTER: FOUND a problem. will correct. old " + this.state.mostRecentTopic + " != new ", which)

            // the semantic ui <Popup>s on <Icon>s cause trouble: if we do a two-finger scroll away while
            // Popup is displayed, we miss the handleMouseLeave() callback.
            // We now keep track of mostRecentTopic - to clear it if/when this happens, and we later enter or leave
            this.handleMouseLeave(this.state.mostRecentTopic)
        }

        const segA_hoverText = <>Enjoy having your very own Twitter-like Bitcoin account - except that it's built with Bitcoin, and
                                you <b>actually</b> own it. The {this.bitShizzle} browser helps you claim and manage your very own Bitcoin Identity.</>
        const segB_hoverText = <>Use {this.bitShizzle} to build your very own Bitcoin Website - without paying for a server.</>
        const segC_hoverText = <><p>Use {this.bitShizzle} to send and receive messages that not even Google and Facebook can read.</p>
                                    <p>You can do it using your <b>own</b> Bitcoin identity.</p>
                                </>
        const segD_hoverText = <>Spammers are out of luck with {this.bitShizzle}. They'll go broke trying to get your attention.</>
        const segE_hoverText = <>Followers who aren't forced to read advertisments are more willing to tip for good comments and content.</>

        const anIcon = <Icon style={{cursor: "default"}} link circular size='small' color='green' name='help' />

        const infoIconTweet = <Icon link circular size='small' color='green' name='info' onClick={this.handleSegA_tweets}/>
        const infoIconComm = <Icon link circular size='small' color='green' name='info' onClick={this.handleSegC_communicate}/>
        const infoIconSpam = <Icon link circular size='small' color='green' name='info' onClick={this.handleSegD_spam}/>
        const infoIconWebsite = <Icon link circular size='small' color='green' name='info' onClick={this.handleSegB_website}/>
        const infoIconEarn    = <Icon link circular size='small' color='green' name='info' onClick={this.handleSegE_earn}/>

        switch (which) {
            case 1:
                this.setState({ mostRecentTopic: 1, colorTweets: "white",
                                label1:  <h2 style={{textAlign: "center", fontFamily: this.fontFamily, color: bshzColors.purple}} >

                                            {this.segmentA_blurb_tweets} &nbsp;<nobr>
                                            <Popup style={{backgroundColor: bshzColors.ltYellow}} position="bottom left" trigger={anIcon} wide="very" content={segA_hoverText} hideOnScroll/>
                                            {infoIconTweet}</nobr>

                                         </h2>
                              })
                break;
            case 2:
                this.setState({mostRecentTopic: 2, colorCommunicate: "white",
                                label2: <h2 style={{textAlign: "center", fontFamily: this.fontFamily, color: bshzColors.purple}} >
                                            {this.segmentC_blurb_communicate} &nbsp;<nobr>
                                            <Popup style={{backgroundColor: bshzColors.ltYellow}} position="bottom center" trigger={anIcon} wide="very" content={segC_hoverText} hideOnScroll/>
                                            {infoIconComm}</nobr>
                                        </h2>
                              })
                break;
            case 3:
                this.setState({mostRecentTopic: 3, colorSpam: "white",
                                label3: <h2 style={{textAlign: "center", fontFamily: this.fontFamily, color: bshzColors.purple}} >
                                            {this.segmentD_blurb_spam} &nbsp;<nobr>
                                            <Popup style={{backgroundColor: bshzColors.ltYellow}} position="top center" trigger={anIcon} wide="very" content={segD_hoverText} hideOnScroll/>
                                            {infoIconSpam}</nobr>
                                        </h2>
                              })
                break;
            case 4:
                this.setState({mostRecentTopic: 4, colorWebsite: "white",
                                label4:  <h2 style={{textAlign: "center", fontFamily: this.fontFamily, color: bshzColors.purple}} >
                                            {this.segmentB_blurb_website} &nbsp;<nobr>
                                            <Popup style={{backgroundColor: bshzColors.ltYellow}} position="top left" trigger={anIcon} wide="very" content={segB_hoverText} hideOnScroll/>
                                            {infoIconWebsite}</nobr>
                                        </h2>
                              })
                break;
            case 5:
                this.setState({mostRecentTopic: 5, colorEarn: "white",
                                label5:  <h2 style={{textAlign: "center", fontFamily: this.fontFamily, color: bshzColors.purple}} >
                                            {this.segmentE_blurb_earn} &nbsp;<nobr>
                                            <Popup style={{backgroundColor: bshzColors.ltYellow}} position="bottom center" trigger={anIcon} wide="very" content={segE_hoverText} hideOnScroll/>
                                            {infoIconEarn}</nobr>
                                        </h2>
                              })
                break;

            // sign-up button
            case 9:
                this.setState({invertSignUp: true})
                break;

            default:
                break;
        }
    }

    handleMouseLeave(which) {
        //console.log("Mouse left " + which)

       // console.log("LEAVE: mostRecentTopic: ", this.state.mostRecentTopic + "   before CLEARING topic (which): ", which)
        if ( typeof this.state.mostRecentTopic !== 'undefined' && this.state.mostRecentTopic !== which ) {
            //console.log("   LEAVE: new (which) has len ", which.length)

            // the semantic ui <Popup>s on <Icon>s cause trouble: if we do a two-finger scroll away while
            // Popup is displayed, we miss the handleMouseLeave() callback.
            // We now keep track of mostRecentTopic - to clear it if/when this happens, and we later enter or leave
            //alert("LEAVE: FOUND a problem. will correct. old " + this.state.mostRecentTopic + " != new ", which)
            this.handleMouseLeave(this.state.mostRecentTopic)
        }

        switch (which) {
            case 1:
                this.setState({colorTweets: bshzColors.ltPurple,
                                label1: <h2 style={{textAlign: "center", fontFamily: this.fontFamily, color: bshzColors.purple}} >
                                            {this.segmentA_blurb_tweets}
                                        </h2>
                              })
                break;
            case 2:
                this.setState({colorCommunicate: bshzColors.ltPurple,
                                label2: <h2 style={{textAlign: "center", fontFamily: this.fontFamily, color: bshzColors.purple}} >
                                            {this.segmentC_blurb_communicate}
                                        </h2>
                              })
                break;
            case 3:
                this.setState({colorSpam: bshzColors.ltPurple,
                                label3: <h2 style={{textAlign: "center", fontFamily: this.fontFamily, color: bshzColors.purple}} >
                                            {this.segmentD_blurb_spam}
                                        </h2>
                              })
                break;
            case 4:
                this.setState({colorWebsite: bshzColors.ltPurple,
                                label4: <h2 style={{textAlign: "center", fontFamily: this.fontFamily, color: bshzColors.purple}} >
                                            {this.segmentB_blurb_website}
                                        </h2>
                              })
                break;
            case 5:
                this.setState({colorEarn: bshzColors.ltPurple,
                                label5: <h2 style={{textAlign: "center", fontFamily: this.fontFamily, color: bshzColors.purple}} >
                                            {this.segmentE_blurb_earn}
                                        </h2>
                              })
                break;
            // sign-up button
            case 9:
                this.setState({invertSignUp: false})
                break;

            default:
                break;
        }
    }

    reanimate() {
        // loop to re-start animation every 3500 seconds
        // alternating the animation type
        const parent = this
        execAsync( async function() {
            await new Promise(resolve => setTimeout(resolve, 3000))
            //console.warn("async RE-ANIMATE() 3   flip state")
            const animType = parent.state.jiggleArrow ? "jiggle" : "tada"
            parent.setState({ jiggleArrow: ! parent.state.jiggleArrow,
                              arrowAnimation: animType
                            })
        })

    }

    // Called by footer when one of its modals show, or hide
    // FIXME: Use this knowledge to disable (or not) buttons on this page
    footerFocus(trueOrFalse) {
        //alert("Footer in focus? " + trueOrFalse)
        console.warn("consider disabling page buttons on home")

        // We don't yet use this info - like onRamp does
        //this.setState({footerModalInFocus: trueOrFalse})
    }

    openDrawer() {
        console.warn("opening drawer...")
        this.setState({showDrawer: !this.state.showDrawer})
    }
    closeDrawer() {
        // also clear selection positions
        this.setState({showDrawer: false})
    }
    logoutThenCloseDrawer() {
        if ( this.props.handleSignInOutClick ) {
            this.props.handleSignInOutClick(this.props.parent)
        }
        this.closeDrawer()
    }

    render() {
console.log("home render()")

        // ***  DEVICE-DEPENDENT STYLING  ***
        // For mobile, "detect" landscape screen mode
        const landscape = this.props.isMobile ? window.innerWidth > window.innerHeight : false

        const alreadyOnRamped = false
        const onRampColor     = alreadyOnRamped ? "grey" : bshzColors.yellow;
        const onRampBackColor = alreadyOnRamped ? bshzColors.ltPurple : bshzColors.green;
        const citizenColor    = bshzColors.yellow;
        const citizenBackColor= "blue";

        const alreadySent  = this.state.alreadySent
        const userEmail    = this.state.userEmail
        const emailValid   = this.validateEmail(userEmail)
        const partialEmail = (userEmail.trim().length > 0 && !emailValid)
        const partialOrBlankEmail = partialEmail || userEmail.length === 0;
        const emailMention = partialEmail ?
                        <span style={{color: '#FF5858'}}>(not a valid email format)</span>
                            :
                        null

        const signupBackColor = alreadySent ? bshzColors.ltPurple : bshzColors.purple;
        const signupColor     = alreadySent ? "grey" : bshzColors.yellow;
        const signedUpNotice = alreadySent ? <span style={{color: "blue"}}> &nbsp; &nbsp; &nbsp; (We've sent you a confirmation email)</span> : <></>
        const signupButton =
                            <Button animated='fade' onClick={this.showSignUp}
                                    disabled={alreadySent}
                                    style={{backgroundColor: signupBackColor, color: signupColor, borderRadius: "30px"}} >
                                    <Button.Content visible>
                                        &nbsp; Sign up for news and updates &nbsp;
                                    </Button.Content>
                                    <Button.Content hidden>
                                        <Icon name='check square' size='large' style={{marginTop:"-3px"}}/> &nbsp;Stay Informed
                                    </Button.Content>
                            </Button>
        // icon size:   mini  tiny  small  large  big  huge  massive
        const redeemButton = <>
                                <br></br>
                                <div style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
                                    <Button animated='fade' onClick={this.showRedeemerBecauseOfClick}
                                            style={{backgroundColor: bshzColors.yellow, color: "blue", borderRadius: "30px"}}>
                                        <Button.Content visible>
                                        Redeem a GIFT from a friend
                                        </Button.Content>
                                        <Button.Content hidden>
                                            <Icon name='gift' size='big'  style={{marginTop:"-7px"}}/>
                                        </Button.Content>
                                    </Button>
                                </div>
                            </>
        let notYetReadyButtons = <></>
        let showGoButtons = this.state.showGoButtons
        const greenMarginLeft = !showGoButtons ? "-68px" : 0
        const greenMarginTop  = !showGoButtons ? "-10px" : 0
        const greenMarginBot  = !showGoButtons ? "9px" : 0
        const maybeGreenArrow = !showGoButtons ?
                        <>
                            <Transition transitionOnMount
                                        visible={this.state.jiggleArrow}
                                        animation={this.state.arrowAnimation} duration={800}
                                        onComplete={this.reanimate} >
                                <Icon size='huge' color='green' name='arrow right'/>
                            </Transition>
                        </>
                            :
                        <></>
        if ( this.props.tempParam?.length > 0 || this.state.hasWallet || showGoButtons ) {
            // icon size:   mini  tiny  small  large  big  huge  massive
            // flash glow bounce shake  tada  jiggle
            notYetReadyButtons =
                <div style={{textAlign: "center"}}>
                    <br></br>
                    <div style={{marginTop:greenMarginTop, marginBottom:greenMarginBot, marginLeft:greenMarginLeft}}>
                    {maybeGreenArrow}
                    <Button animated='fade' onClick={this.getStarted}
                                    disabled={alreadyOnRamped}
                                    style={{backgroundColor: onRampBackColor, color: onRampColor, borderRadius: "30px"}} >
                                    <Button.Content visible>
                                        &nbsp; &nbsp; &nbsp; &nbsp; Let's &nbsp;get &nbsp;started! &nbsp; &nbsp; &nbsp; &nbsp;
                                    </Button.Content>
                                    <Button.Content hidden>
                                        <Icon name='bolt' size='large'  style={{marginTop:"-3px"}}/>
                                    </Button.Content>
                    </Button>
                    </div>
                </div>
        }

        const blueButton =  <span>
                                <Button animated='fade' onClick={this.citizenOptions}
                                        disabled={this.props.citizenLevel < 2}
                                        style={{backgroundColor: citizenBackColor, color: citizenColor, borderRadius: "30px"}} >
                                    <Button.Content visible>
                                        &nbsp; &nbsp; &nbsp; &nbsp; I'm not a newbie &nbsp; &nbsp; &nbsp; &nbsp;
                                    </Button.Content>
                                    <Button.Content hidden>
                                        <Icon name='rocket' size='large'  style={{marginTop:"-3px"}}/>
                                    </Button.Content>
                                </Button>
                            </span>
        const proButtonHoverText =
                <>
                    Please login to enable this button.<br></br>
                    (click the <Icon name='toggle off'/> symbol on the top-left, in the title bar)
                </>
        const proButtonHover =
                <>
                    <Popup style={{backgroundColor: bshzColors.ltYellow}} trigger={blueButton} wide="very" content={proButtonHoverText} hideOnScroll/>
                </>
        const buttonOrHover =
                                this.props.citizenLevel < 2 ?
                                <>
                                    {proButtonHover}
                                </>
                            :
                                <>
                                    {blueButton}
                                </>
        const proButtonClause =
                <div>
                    <div style={{textAlign: "center"}}>
                    <br></br>
                    <div style={{marginTop:"-10px", marginBottom:"9px", marginLeft:"-71px"}}>
                    <Transition transitionOnMount
                                visible={this.state.jiggleArrow}
                                animation={this.state.arrowAnimation} duration={800}
                                onComplete={this.reanimate} >
                        <Icon size='huge' color='blue' name='arrow right'/>
                    </Transition>
                    {buttonOrHover}
                    </div>
                    </div>
                </div>

        const proButtonOption = showGoButtons ?
                                    proButtonClause
                                :
                                    <></>

        //  <Icon name='gift' size='large' style={{color:"purple"}}/>
        //  Gift Redeemer!
        //citizenLevel={this.props.citizenLevel}
        //checkCitizenLevel={this.props.checkCitizenLevel}
        const giftRedeemerOption = (this.props.giftParam?.length > 0 || this.state.showGiftRedeemer) && !this.state.closeGiftRedeemer ?
                                <>
                                    <GiftRedeemerModal  isMobile={this.props.isMobile}
                                                        closeMe={this.closeGiftRedeemer}

                                                        getOfficialWalletRecord={this.props.getOfficialWalletRecord}
                                                        returnBundle={this.props.returnBundle}
                                                        handleJumpToSurfer={this.props.handleJumpToSurfer}

                                                        changeCitizenLevel={this.props.changeCitizenLevel}
                                                        blockHeight={this.props.blockHeight}
                                                        bsvPriceUSD={this.props.bsvPriceUSD}
                                                        />
                                </>
                            :
                                <></>

        const buttons =
                <div style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
                        <Transition visible={this.state.fadeInMain} animation='scale' duration={1000}>
                            <div>
                                <Transition visible={this.state.jiggleSignup} animation='shake' duration={800}>
                                    <div style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
                                        {signupButton}
                                    </div>
                                </Transition>
                                {signedUpNotice}
                                {redeemButton}
                                {notYetReadyButtons}
                                {proButtonOption}
                            </div>
                        </Transition>

                </div>

        const genericModalTitle   = this.state.genericModalTitle
        const genericModalContent = this.state.genericModalContent
        const genericModalButton  = this.state.genericModalButton

        //NOTE: Segment onClick() handlers added to mitigate flaws in semantic ui onMouseX() detection on mobile
        //      Big improvement on mobile
        const segmentA_tweets =
                <div>
                    <Segment circular raised size={"massive"} onClick={() => this.handleMouseEnter(1)}
                            style={{margin: 0, padding: 15, height: "133px", width: "700px", backgroundColor: this.state.colorTweets}}
                            onMouseEnter={() => this.handleMouseEnter(1)}
                            onMouseLeave={() => this.handleMouseLeave(1)}>
                        {this.state.label1}
                    </Segment>
                </div>
        const segmentB_website =
                <div>
                    <Segment circular raised size={"massive"} onClick={() => this.handleMouseEnter(4)}
                            style={{margin: 0, padding: 15, height: "133px", width: "700px", backgroundColor: this.state.colorWebsite}}
                            onMouseEnter={() => this.handleMouseEnter(4)}
                            onMouseLeave={() => this.handleMouseLeave(4)}>
                        {this.state.label4}
                    </Segment>
                </div>

        const segmentC_communicate =
                <div>
                    <Segment circular raised size={"massive"} onClick={() => this.handleMouseEnter(2)}
                            style={{margin: 0, padding: 15, height: "133px", width: "700px", backgroundColor: this.state.colorCommunicate}}
                            onMouseEnter={() => this.handleMouseEnter(2)}
                            onMouseLeave={() => this.handleMouseLeave(2)}>
                        {this.state.label2}
                    </Segment>
                </div>

        const segmentD_spam =
                <div>
                    <Segment circular raised size={"massive"} onClick={() => this.handleMouseEnter(3)}
                            style={{margin: 0, padding: 15, height: "133px", width: "700px", backgroundColor: this.state.colorSpam}}
                            onMouseEnter={() => this.handleMouseEnter(3)}
                            onMouseLeave={() => this.handleMouseLeave(3)}>
                        {this.state.label3}
                    </Segment>
                </div>

        const segmentE_earn =
                <div>
                    <Segment circular raised size={"massive"} onClick={() => this.handleMouseEnter(5)}
                            style={{margin: 0, padding: 15, height: "133px", width: "700px", backgroundColor: this.state.colorEarn}}
                            onMouseEnter={() => this.handleMouseEnter(5)}
                            onMouseLeave={() => this.handleMouseLeave(5)}>
                        {this.state.label5}
                    </Segment>
                </div>

        const s1Text=<>Tell spam to get off <b>your</b> lawn.</>
        //const s2Text=<>Who's reading <b>your</b> email?</>
        const s2Text=<>Keep others out of <b>your</b> inbox.</>
        //const s3Text=<>Who signed you up for creepy ads?</>
        const s3Text=<>Say "No thanks" to creepy ads.</>
        //const s4Text=<>Who owns <b>your</b> identity?</>
        const s5Text=<>Take ownership of <b>your</b> identity.</>
        //const s5Text=<>What color will you paint <b>your</b> tweets today?</>
        const s4Text=<>Pick a <b><span style={{backgroundColor: "green", color: "yellow"}}>color</span></b> to paint <b>your</b> tweets.</>
        const s6Text=<>It's time to put on your digital big-kid pants.</>
        const finalText=<>{this.bitShizzleItalic} can help</>

        const me = this;
        const observer = new IntersectionObserver(function(entries) {
            // isIntersecting is true when element and viewport are overlapping
            // isIntersecting is false when element and viewport don't overlap
            if( typeof me != undefined && !me.state.footerVisible && entries[0].isIntersecting === true) {
                //console.log('Element has just become visible in screen');
                me.setState({footerVisible: true, jiggleFooter: !me.state.jiggleFooter})
            } else if ( typeof me != undefined && me.state.footerVisible && entries[0].isIntersecting === false ) {
                //console.log("Element NO LONGER visible")
                me.setState({footerVisible: false})
            }
        }, { threshold: [1] });

        if ( this.state.isLoaded ) {
            // when the footer links come into view, animate them, and the dedication box
            observer.observe( document.getElementById("flinks") );
        }

        const passToFooter =
            <>
                <br></br>

                <Transition visible={this.state.jiggleFooter} animation='pulse' duration={500}>
                <div style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
                <table style={{width: "50%", padding: 0, margin: 0}}>
                    <tbody>
                    <tr>
                        <td style={{width: "10%"}}>
                        </td>
                        <td style={{width: "80%"}}>
                            <Transition visible={this.state.fadeInMain} animation='scale' duration={800}>
                                <div style={{border: "1px solid purple", backgroundColor: "#fff30030", paddingRight: '20px'}}>
                                    <ul>
                                    <nobr>{this.bitShizzle} is dedicated to promoting:</nobr>
                                        <li style={{marginLeft: "+1.3em", marginTop: "+0.3em"}}>
                                            True ownership of Bitcoin identities, and websites</li>
                                        <li style={{marginLeft: "+1.3em"}}>
                                            Private Communications</li>
                                        <li style={{marginLeft: "+1.3em"}}>
                                            Permissionless innovation</li>
                                    </ul>
                                </div>
                            </Transition>
                        </td>
                        <td style={{width: "10%"}}>
                        </td>
                    </tr>
                    </tbody>
                </table>
                </div>
                </Transition>

            </>

        const signInOutCallback = this.props.handleSignInOutClick === null ?
                            null
                        :
                            () => this.props.handleSignInOutClick(this.props.parent)

        const maybeInitialToast = this.state.showDbInitializationToast ?
                            <>
                                <Toast displayText={"Initializing Database..."}/>
                            </>
                        :
                            <></>

        //const sidebarWidth = this.props.isMobile ? (landscape ? '275px' : '80px') : '500px'
        const sidebarClassname = this.props.isMobile ? (landscape ? 'my_phone_landscape_sidebar' : 'my_phone_sidebar') : 'my_sidebar'

        const walletIcon = <Icon link size='large' color="purple" name='money bill alternate' onClick={this.props.handleWalletClick}/>
        const logOutIcon = <Icon link size='large' color="purple" name='toggle on' onClick={this.logoutThenCloseDrawer}/>
        const surfIcon   = <Icon link size='large' color="purple" name='rocket' onClick={this.citizenOptions}/>

        const maybeSurf = showGoButtons ?
                        <>
                            <br></br>
                            {surfIcon} &nbsp;
                            <span onClick={this.citizenOptions}>Explore</span>
                            <br></br>
                        </>
                    :
                        <></>
        return(
            <>
        <div id="container">
            <div id="header">
                <Transition visible={this.state.fadeInMain} animation='slide right' duration={800}>
                    <div>
                    <ShizzleMasthead blank={false} scrollY={this.state.scrollY}
                                    effectVisible={this.state.jiggleMasthead}
                                    qualityTxs={this.state.qualityTxs}

                                    passwordVerified={this.props.citizenLevel > 1}
                                    logInOutCallback={signInOutCallback}

                                    sidebarCallback={this.openDrawer}
                                    />
                    </div>
                </Transition>
            </div>
            <div id="body">
                <div style={{paddingTop: "180px", backgroundImage: 'url("paleGreySmoke.jpg")'}} className="cont">
                <p></p>

                <table style={{width: "100%", padding: 0, margin: 0, marginTop: "-103px", backgroundColor: "#fffccca0"}}>
                    <tbody>
                    <tr>
                        <td style={{width: "0%"}}>
                        </td>
                        <td style={{width: "100%", textAlign: "left"}}>
                            <QuoteSlider    s1Text={s1Text}
                                        s2Text={s2Text}
                                        s3Text={s3Text}
                                        s4Text={s4Text}
                                        s5Text={s5Text}
                                        s6Text={s6Text}
                                        s7Text={finalText}
                                        singleShot={true}
                                        restart={this.state.restartQuoteSlider}
                            />
                        </td>
                        <td style={{width: "0%"}}>
                        </td>
                    </tr>
                    </tbody>
                </table>

                <Sidebar.Pushable style={{ transform: "none" }}>
                    <Sticky context={this.stickyRef} offset={92} bottomOffset={0}>
                    <Sidebar className={sidebarClassname} style={{padding: 8, position: 'fixed', top: "92px", bottom: "0px", overflowY: "auto"}}
                            animation='overlay'
                            direction='right'
                            icon='labeled'
                            onHide={() => this.closeDrawer()}

                            visible={this.state.showDrawer}
                    >
                        <div style={{padding: 0, fontSize:'1.2rem'}}>
                        <Segment style={{overflow: 'auto', maxHeight: '100%', backgroundColor:bshzColors.ltPurple, padding: 8 }}>
                            <Icon name='window close outline' onClick={this.closeDrawer} size='large' color='black'/>
                            <br></br>
                            <br></br>
                            {walletIcon} &nbsp;
                            <span onClick={this.props.handleWalletClick}>Your Wallet</span>
                            <br></br>

                            {maybeSurf}

                            <Divider style={{padding: 0}}/>

                            {logOutIcon} &nbsp;
                            <span onClick={this.logoutThenCloseDrawer}>Logout</span>
                        </Segment>
                        </div>
                    </Sidebar>
                    </Sticky>
                    <Sidebar.Pusher  >
                <Transition visible={this.state.fadeInMain} animation='slide left' duration={800}>
                <div style={{padding: "10px", paddingTop: "20px", fontSize: "16px"}}>
                     &nbsp;&nbsp;       Use your Bitcoin browser to:
                </div>
                </Transition>



                <table style={{width: "100%", padding: 0, margin: 0}}>
                    <tbody>
                    <tr>
                        <td style={{width: "100%"}}>

                            <Container text >
                                <Transition visible={this.state.fadeInMain} animation='scale' duration={1200}>
                                            {segmentA_tweets}
                                </Transition>
                                                    &nbsp;
                                <Transition visible={this.state.fadeInMain} animation='scale' duration={1200}>
                                            {segmentE_earn}
                                </Transition>
                                                    &nbsp;
                                <Transition visible={this.state.fadeInMain} animation='scale' duration={1200}>
                                            {segmentC_communicate}
                                </Transition>
                                                    &nbsp;
                                <Transition visible={this.state.fadeInMain} animation='scale' duration={1200}>
                                            {segmentD_spam}
                                </Transition>
                                                    &nbsp;
                                <Transition visible={this.state.fadeInMain} animation='scale' duration={1200}>
                                            {segmentB_website}
                                </Transition>
                            </Container>

                        </td>
                    </tr>
                    </tbody>
                </table>

                &nbsp;
                <p></p>
                <p></p>
                <br></br>
                &nbsp;

                {buttons}
                </Sidebar.Pusher>
                </Sidebar.Pushable>
            </div>

            &nbsp;
            <p></p>
            <p></p>
            <br></br>

            {giftRedeemerOption}

            <Footer enforceTerms={false}
                    topOfFooter={passToFooter}
                    isMobile={this.props.isMobile}
                    jiggleFooter={this.state.jiggleFooter}
                    sendEmail={this.props.sendEmail}
                    footerFocus={this.footerFocus}
                    />

            {maybeInitialToast}

                <Modal size='small' open={this.state.showGenericOkModal && !this.state.showServerErrorModal} dimmer='blurring' style={{backgroundColor: bshzColors.purple, borderRadius: '20px'}}>
                <Modal.Header style={{textAlign: 'center', backgroundColor: bshzColors.purple, borderRadius: '20px'}}>
                    <span style={{color: bshzColors.yellow}}>  {genericModalTitle}  </span>
                </Modal.Header>
                <Modal.Content style={{textAlign: 'center', backgroundColor: bshzColors.ltPurple, borderRadius: '0px 0px 20px 20px'}}>
                    <span style={{fontSize: "16px"}}>{genericModalContent} </span>

                    <p></p>
                    <p></p>

                    <Button  color='green' onClick={this.handleGenericModalOk}>
                        <span style={{color: 'white'}}>
                        {genericModalButton}
                        </span>
                    </Button>

                </Modal.Content>
                </Modal>

                <Modal size='small' open={this.state.showEmailSignup && !alreadySent}  dimmer='blurring' style={{backgroundColor: bshzColors.purple, borderRadius: '20px'}}>
                <Modal.Header style={{textAlign: 'center', backgroundColor: bshzColors.purple, borderRadius: '20px'}}>
                    <span style={{color: bshzColors.yellow}}>Stay Informed</span>
                </Modal.Header>
                <Modal.Content style={{textAlign: 'center', backgroundColor: bshzColors.ltPurple, borderRadius: '0px 0px 20px 20px'}}>
                    <p>{this.bitShizzle} is under construction. We can keep you up-to-date on its progress, and let you know when it's ready for action</p>
                    <p>We will never sell nor share your data without your explicit consent, and you can un-subscribe at any time.</p>
                    <br></br>

                    <Input type='email' loading={false} field='userEmail' placeholder='Enter your email'
                                    value={this.state.userEmail} style={{width: "275px"}}
                                    onChange={ this.handleEmail }>
                            <input style={{borderRadius: '50px'}} />
                    </Input>
                    <br></br>
                    {emailMention}
                    <br></br>
                    <br></br>

                    <div align="center">
                        <Button positive disabled={alreadySent || partialOrBlankEmail} onClick={this.signUp}>
                            Sign Up
                        </Button>

                        <Button  color='red' onClick={this.closeSignUp}>
                            <span style={{color: 'white'}}>
                            CANCEL
                            </span>
                        </Button>
                    </div>

                </Modal.Content>
                </Modal>


                <Modal size='small' open={this.state.showServerErrorModal} dimmer='blurring'>
                <Modal.Header style={{textAlign: 'center', backgroundColor: bshzColors.purple}}>
                    <span style={{color: bshzColors.yellow}}>  SERVER ERROR  </span>
                </Modal.Header>
                <Modal.Content style={{textAlign: 'center', backgroundColor: bshzColors.ltPurple}}>
                    <p>We're sorry. We had trouble communicating with the {this.bitShizzle} Server.</p>
                    <p> Please try again later.</p>
                    <p></p>
                    <p></p>

                    <Button  color='red' onClick={this.handleServerErrorModalOk}>
                        <span style={{color: 'white'}}>
                          Okay
                        </span>
                    </Button>

                </Modal.Content>
                </Modal>

            </div>
        </div>
            </>
        )
    }
}

export default ShizzleHome;