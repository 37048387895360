/* */
const {
	bsv,
} = require('scryptlib');

import { NETWORK, GENESIS_APPEND } from './harnessConstants'
import { openDB, readSetting, saveSetting, queryFetchTx, decomposeTx, parseTheOutputs, parseTheInputs } from './buildShizzle';

const BN = bsv.crypto.BN;

function deduceMimeType(arrayView) {
    //console.warn("Here's the file as a Uint8Array: ", arrayView)
    const header = arrayView.slice(0, 512) //384)//256)
    console.warn("here's the first few (512) bytes (HEADER slice): ", header)
    const td = new TextDecoder("utf-8");
    const headerString = td.decode(header);
    console.warn("here's the header string (ascii): ", headerString)

    // from: https://stackoverflow.com/questions/18299806/how-to-check-file-mime-type-with-javascript-before-upload
    // and answer by Wilter Monteiro
    // Array to be iterated [<string signature>, <MIME type>]
    const mimeTypes = [
        // Images
        //['PNG', 'image/png'],
        // Audio
        ['ID3', 'audio/mpeg'],// MP3

        // Video
        ['ftypmp4', 'video/mp4'],// MP4
        ['ftypisom', 'video/mp4'],// MP4
        ['ftypiso5', 'video/mp4'],   // I added this. Chrome saw it when loading a SAFARI mp4 avc1
        ['.Eß£', 'video/webm'],//webm   mkv?  ???
        ['matroska', 'video/webm'], // codecs=h264? NOTE!! mac safari puts matroska within .webm
        ['webm', 'video/webm'], //codecs=h264? webm
        ['AVI LIST', 'video/x-msvideo'],   //avi is a FILE TYPE /container
        ['ftypqt',   'video/quicktime'],   // quickTime, BUT, mov files not all mp4
        ['ftypM4V',  'video/x-m4v'],       // BUT, maybe this is video/mp4

        //FIXME: what about video/mpeg?

    ];

    //FIXME: how to recognize h264 vs avc (or anything else?)
    const videoCodecs = [
      ['VP8',  'vp8'],
      ['VP9',  'vp9'],
      ['M4V',  'm4v'],
      ['m4v',  'm4v'],
      ['AVC',  'avc1'],
    ];

    const audioCodecs = [
      // Audio
      ['OPUS', 'opus'],
      ['opus', 'opus'],
      ['ID3', 'audio/mpeg'],// MP3
      ['vorbis', 'vorbis'],  // made this up
      ['VORBIS', 'vorbis'],  // made this up
      ['ftypm4a', 'm4a']    // haven't seen this. just added it
    ];

    // Matching an audio or video type pattern
    // see: https://mimesniff.spec.whatwg.org
    const hasFORM     = headerString.indexOf('FORM') > -1
    const hasAIFF     = headerString.indexOf('AIFF') > -1
    const hasID3      = headerString.indexOf('ID3')  > -1
    const hasOgg      = headerString.indexOf('Ogg')  > -1
    const hasOggS     = headerString.indexOf('OggS') > -1
    const hasMThd     = headerString.indexOf('MThd') > -1
    const hasRIFF     = headerString.indexOf('RIFF') > -1
    const hasAVI      = headerString.indexOf('AVI')  > -1
    const hasWAVE     = headerString.indexOf('WAVE') > -1
    const hasftyp     = headerString.indexOf('ftyp') > -1
    const hasmp4      = headerString.indexOf('mp4')  > -1
    const haswebm     = headerString.indexOf('webm') > -1
    const hasMatroska = headerString.indexOf('matroska') > -1

    // Perhaps this is string '.Eß£'
    const startsWithWEBM  = header[0] === 0x1a &&
                    header[1] === 0x45 &&
                    header[2] === 0xdf &&
                    header[3] === 0xa3

    const hasH264   = headerString.indexOf('h264')   > -1 ||
                      headerString.indexOf('H264')   > -1
    const hasH263   = headerString.indexOf('h263')   > -1 ||
                      headerString.indexOf('H263')   > -1
    const hasVP8    = headerString.indexOf('VP8')    > -1
    const hasOpus   = headerString.indexOf('opus')   > -1 ||
                      headerString.indexOf('OPUS')   > -1
    const hasVorbis = headerString.indexOf('vorbis') > -1 ||
                      headerString.indexOf('VORBIS') > -1
    const hasM4V    = headerString.indexOf('M4V')    > -1 ||
                      headerString.indexOf('m4v')    > -1
    const hasM4A    = headerString.indexOf('m4a')    > -1 ||
                      headerString.indexOf('M4A')    > -1
    const hasisom   = headerString.indexOf('isom')   > -1
    const hasiso5   = headerString.indexOf('iso5')   > -1

    let done = false
    if ( startsWithWEBM ) {
      if ( haswebm ) {
        alert("header starts with webm signature bytes, AND has 'webm'. We know it's a webm file")
      } else if ( hasMatroska ) {
        alert("header starts with webm signature bytes, AND has 'matroska'.  Sounds like a webm :)")
      } else {
        alert("header starts with webm signature bytes, BUT has no 'webm', nor 'matroska' :(")
      }
    } else if ( hasFORM && hasAIFF ) {
      alert("We believe this is 'audio/aiff'")
      done = true
    } else if ( hasID3 ) {
      alert("We believe this is 'audio/mpeg'")
      done = true
    } else if ( hasOgg || hasOggS ) {
      alert("perhaps this is 'application/ogg'")
    } else if ( hasMThd ) {
      alert("We believe this is 'audio/midi'")
      done = true
    } else if ( hasRIFF && hasAVI ) {
      alert("This is 'video/avi'")
      done = true  //? what about specifying audio?
    } else if ( hasRIFF && hasWAVE ) {
      alert("this is 'audio/wave'")
      done = true
    } else if ( hasftyp ) {
      if ( hasmp4 ) {
        alert("has 'ftyp' AND 'mp4'. We know it's an mp4 file")
      } else if ( hasM4A) {
        alert("has 'ftyp' AND 'm4a'")
      } else if ( hasM4V) {
        alert("has 'ftyp' AND 'm4v'")
      } else {
        alert("has 'ftyp', BUT no 'mp4'  :(")
      }
    }

    let theType = 'video/mp4'
    let found = false
    // Iterate over the required types.
    for(let i = 0;i < mimeTypes.length;i++){
        // If a type matches we return the MIME type
        if(headerString.indexOf(mimeTypes[i][0]) > -1){
          console.error("FOUND mimeType (based on header): ", mimeTypes[i][1]);
          theType = mimeTypes[i][1]
          found = true
          //if ( theType === 'video/x-matroska' ) {
          //  theType = 'video/webm; codecs=vorbis,vp8'
          //  alert("We're TEMP double-overriding the mimeType from matroska. Setting to " + theType);
          //} else {
            //alert("We think we found the mimeType. Setting to " + theType);
            //alert("FIXME: Or, should we just always scan at time of PLAY?") // <--- PONDER
          //}
          break
        }
    }
    if ( !found ) {
      console.error("WARNING: we couldn't deduce the mime type from scanning the file header")
      alert("WARNING: we couldn't deduce the mime type from scanning the file's header.\n\n"
          + "Will default to " + theType + ". BUT WE COULD BE WRONG, and that could be a PROBLEM later.")
      alert("PAY ATTENTION: right here/now we should really determine the mime type")
    }
    if ( theType === 'video/mp4' ) {
        alert("since it's an mp4, will employ extra logic to determine codecs...")

        for ( let i = 0; i < arrayView.length; ) {
            console.log("i is now " + i)
            console.log("now slice " + i + ": ", arrayView.slice(i))

            const u32bytes = arrayView.slice(i, i+4)

            const uintLen0 = u32bytes[0];
            const uintLen1 = u32bytes[1];
            const uintLen2 = u32bytes[2];
            const uintLen3 = u32bytes[3];
            const chunkLen = uintLen0 * 256 * 256 * 256 +
                             uintLen1 * 256 * 256 +
                             uintLen2 * 256 +
                             uintLen3

            console.warn("  this mp4 chunk has len " + chunkLen)

            const td = new TextDecoder("utf-8");
            const chunkLabel = td.decode(arrayView.slice(i+4, i+8));
            const chunkString = td.decode(arrayView.slice(i+4, i+chunkLen));
            console.log("    chunkLabel: ", chunkLabel)
            console.log("    chunk: ", chunkString)

            if ( chunkLabel === 'moov' ) {
                const mdhdIdx = chunkString.indexOf('mdhd')
                if ( mdhdIdx > -1 ) {
                    console.error("YES! Found the mdhd box at chunk's index " + mdhdIdx)
                    const mdhdString = td.decode(arrayView.slice(i+4+mdhdIdx, i+4+chunkLen))
                    console.warn("mdhdString: ", mdhdString)
                }

                const vmhdIdx = chunkString.indexOf('vmhd')
                if ( vmhdIdx > -1 ) {
                    console.error("YES! Found the vmhd box at chunk's index " + vmhdIdx)
                    const vmhdString = td.decode(arrayView.slice(i+4+vmhdIdx, i+4+chunkLen))
                    console.warn("vmhdString: ", vmhdString)
                }
            }

            i += (chunkLen)
            console.log("    advancing i to " + i + "\n\n")
        }
    } // mp4 chunk probe

    let theVideoCodec = ''
    let foundVideo = false
    // Iterate over the video codecs.
    for(let i = 0; i < videoCodecs.length; i++){
        // If a type matches we return the MIME type
        if(headerString.indexOf(videoCodecs[i][0]) > -1){
          console.error("FOUND video codec: ", videoCodecs[i][1] + "  ! ! !");
          theVideoCodec = videoCodecs[i][1]
          foundVideo = true
          break
        }
    }
    if ( !foundVideo ) {
      console.error("WARNING: we couldn't deduce the VIDEO codec from scanning the file header")
      alert("WARNING: we couldn't deduce the VIDEO codec from scanning the file's header.\n\n"
          + "Will default to NOTHING (maybe it's an audio file?). BUT WE COULD BE WRONG, and that could be a PROBLEM later.")
    }


    let theAudioCodec = ''
    let foundAudio = false
    // Iterate over the audio codecs.
    for(let i = 0;i < audioCodecs.length;i++){
        // If a type matches we return the MIME type
        if(headerString.indexOf(audioCodecs[i][0]) > -1){
          console.error("FOUND audio codec: ", audioCodecs[i][1] + "  ! ! !");
          theAudioCodec = audioCodecs[i][1]
          foundAudio = true
          break
        }
    }
    if ( !foundAudio ) {
      console.error("WARNING: we couldn't deduce the AUDIO codec from scanning the file header")
      alert("WARNING: we couldn't deduce the AUDIO codec from scanning the file's header.\n\n"
          + "Will default to " + theAudioCodec + ". BUT WE COULD BE WRONG, and that could be a PROBLEM later.")
    }

    let finalType = theType
    if ( foundAudio || foundVideo ) {
        finalType += "; codecs="

        if ( foundVideo ) {
            finalType += theVideoCodec
            if ( foundAudio ) {
                finalType += "," + theAudioCodec
            }
        } else {
            if ( haswebm || hasMatroska ) {
                finalType += ('h264,' + theAudioCodec)
            } else {
                finalType += theAudioCodec
            }
        }
    }

    if ( !foundAudio || !foundVideo ) {
        console.error("WARNING: either audio, and/or video codec wasn't deduced.\n"
                    + "BTW: hasIso5: " + hasiso5 + "    hasIsom: " + hasisom
                    + "     hasH264: " + hasH264 + "    hasH263: " + hasH263
        )
    }

    return {mType: finalType, headerString: headerString}

} // deduceMimeType()

function convertAddressToPKH(address, network=NETWORK) {

    //FIXME: look at this:
    //bsv.Script.buildPublicKeyHashOut(address).toHex()

    let aPKH // array of integers
    try {
      aPKH = bsv.encoding.Base58Check.decode(address)
      console.log("P2PKH buffer derived from address: ", aPKH)
    } catch (error) {
      return null
    }

    const fullP2PKH = aPKH.toString("hex")
    //console.log("FULL p2pkh derived from address: ", fullP2PKH)

    // It seems the full p2pkh has a checksum, or format byte
    const derivedP2PKH = fullP2PKH.substring(2, 42)
    //console.log("snipped P2KH derived from address: ", derivedP2PKH)

    // double-check the P2PKH maps back to the original address
    const secondAddress = bsv.Address.fromPublicKeyHash(aPKH.slice(1,41), network)
    //console.log("Address re-derived from P2PKH: ", secondAddress)

    if ( secondAddress.toString() !== address ) {
      console.warn("re-derived address to string: ", secondAddress.toString())
      console.error("\nERROR: failed to generate identical address from derived P2PKH")
      return -1 // signal that it's for the wrong network
    }

    return derivedP2PKH
}

/**
 * Verify that a previously-saved txId (setting 'genesisTx') matches the
 * constant GENESIS_APPEND (within the code of this browser)
 *
 * @returns true/fase
 */
async function validateShizzleTree() {
    const db = await openDB()

    // get the genesis/root most-recently used, if there is one
    const genesisTx = await readSetting(db, "genesisTx")
    if ( genesisTx !== null ) {
        console.warn("validateShizzleTree(): retrieved genesisTx:  ", genesisTx.value)
        if ( genesisTx.value !== GENESIS_APPEND ) {
            console.error("ERROR: your IDB database was built on an OLD (test) shizzleTree, "
                        + "which has since been deprecated. You must empty your wallet, and perform a FULL RESET.")
            alert("WARNING: the old (test) shizzleVerse has been deprecated. Empty your wallet, and perform a FULL RESET.")
            return false
        }
    } else {
        console.warn("Saving the GENESIS Tx to IDB: ", GENESIS_APPEND)
        await saveSetting(db, 'genesisTx', GENESIS_APPEND)
    }

    return true
}

/**
 * decimal int to little-endian signed magnitude
 */
//export /* */
function int2Asm(str) {
    if (!/^-?(0x)?\d+$/.test(str)) {
        throw new Error(`17300: invalid str '${str}' to convert to int`);
    }
    const number = new BN(str, 10);
    if (number.eqn(-1)) {
        return 'OP_1NEGATE';
    }
    if (number.gten(0) && number.lten(16)) {
        return 'OP_' + str;
    }
    const m = number.toSM({ endian: 'little' });
    return m.toString('hex');
}

// Little-endian
//export /* */
function fiveByteLE(dec) {
    var hex
    if ( dec < 256) {
        hex = Number(dec).toString(16)
    } else {
        hex = int2Asm( dec )
        if ( hex.length > 10 ) {
            console.log("instead of " + hex + ", using result of "
                    + hex.substring(0,10))
            hex = hex.substring(0,10)
        }
    }

    if ( hex.length === 1 ) {
        // 0x0 to 0xf
        hex = '0' + hex + '00000000'
    } else if ( hex.length === 2 ) {
        hex = hex + '00000000'
    } else if ( hex.length === 4 ) {
        hex = hex + '000000'
    } else if ( hex.length === 6 ) {
        hex = hex + '0000'
    } else if ( hex.length === 8 ) {
        hex = hex + '00'
    } else if ( hex.length !== 10 ) {
        console.log('[fiveByteLE]: What? hex was ', hex, ' - length ', hex.length)
        throw new Error("17301: invalid value"); //exit()
    }
    return hex
}

// Little-endian
//export /* */
function eightByteLE(dec) {
    var hex
    if ( dec < 256) {
        hex = dec.toString(16)
    } else {
        hex = int2Asm( dec )
        //0x7fffffffffffffff
        //if ( Number(dec) > 9223372036854775807 ) {
            //remove final 00
            // ah. int2Asm() avoiding negative number?
            if ( hex.length > 16 ) {
                console.log("instead of " + hex + ", using result of "
                        + hex.substring(0,16))
                hex = hex.substring(0,16)
            }
        //}
    }

    if ( hex.length === 1 ) {
        // 0x0 to 0xf
        hex = '0' + hex + '00000000000000'
    } else if ( hex.length === 2 ) {
        hex = hex + '00000000000000'
    } else if ( hex.length === 4 ) {
        hex = hex + '000000000000'
    } else if ( hex.length === 6 ) {
        hex = hex + '0000000000'
    } else if ( hex.length === 8 ) {
        hex = hex + '00000000'
    } else if ( hex.length === 10 ) {
        hex = hex + '000000'
    } else if ( hex.length === 12 ) {
        hex = hex + '0000'
    } else if ( hex.length === 14 ) {
        hex = hex + '00'
    } else if ( hex.length !== 16 ) {
        console.log('[eightByteLE]: What? hex was ', hex, ' - length ', hex.length)
        throw new Error("17302: invalid value"); //exit()
    }
    return hex
}

// Little-endian
//export /* */
function oneByteLE(dec) {
    var hex = Number(dec).toString(16)

    if ( hex.length === 1 ) {
        // 0x0 to 0xf
        hex = '0' + hex
    }
    return hex
}

// Little-endian
//export /* */
function twoByteLE(dec) {
    var hex
    if ( dec < 256) {
        hex = Number(dec).toString(16)
    } else {
        hex = int2Asm( dec )
        if ( Number(dec) > 32767 ) {
            //remove final 00
            // ah. int2Asm() avoiding negative number?
            if ( hex.length > 4 ) {
                console.log("instead of " + hex + ", using result of "
                        + hex.substring(0,4))
                hex = hex.substring(0,4)
            }
        }
    }

    if ( hex.length === 1 ) {
        // 0x0 to 0xf
        hex = '0' + hex + '00'
    } else if ( hex.length === 2 ) {
        hex = hex + '00'
    } else if ( hex.length !== 4 ) {
        console.log('[twoByteLE]: What? hex was ', hex, ' - length ', hex.length)
        console.log('(and dec was ' + dec)
        throw new Error("17303: Error in twoByteLE()")
        //exit()
    }
    return hex
}

// Little-endian
//FIXME: rename fourByteULE()
//export /* */
function fourByteLE(dec) {
    var hex
    if ( dec < 256) {
        hex = Number(dec).toString(16)
    } else {
        //console.log("int2asm() of " + dec)
        hex = int2Asm( dec )
        if ( Number(dec) > 2147483647 ) {
            //remove final 00
            // ah. int2Asm() avoiding negative number?
            if ( hex.length > 8 ) {
                console.log("instead of " + hex + ", using result of "
                        + hex.substring(0,8))
                hex = hex.substring(0,8)
            }
        }
        console.log("int2asm() of " + dec + "(decimal) is " + hex + "(LE)")
    }

    if ( hex.length === 1 ) {
        // 0x0 to 0xf
        hex = '0' + hex + '000000'
    } else if ( hex.length === 2 ) {
        hex = hex + '000000'
    } else if ( hex.length === 3 ) {
        hex = '0' + hex + '0000'
    } else if ( hex.length === 4 ) {
        hex = hex + '0000'
    } else if ( hex.length === 5 ) {
        hex = '0' + hex + '00'
    } else if ( hex.length === 6 ) {
        hex = hex + '00'
    } else if ( hex.length === 7 ) {
        hex = '0' + hex
    } else if ( hex.length !== 8 ) {
        console.log('[fourByteLE]: What? hex was ', hex, ' - length ', hex.length)
        throw new Error("17304: invalid value"); //exit()
    }
    return hex
}

//export /* */
function hexByteToAscii(hexByte) {
	return String.fromCharCode(parseInt(hexByte.substr(0, 2), 16));
}

//export /* */
function hexStringToAscii( someHexString ) {
	var asciiString = ''
	for ( var i = 0; i < someHexString.length; i += 2 ) {
		asciiString += hexByteToAscii( someHexString[i] + someHexString[i+1] )
	}

	return asciiString
}

//FIXME: use reverseEndian() - from helper?
//export /* */
function swapEndian(original) {
	const strLen = original.length
	if ( strLen % 2 !== 0 ) {
		console.log("ERROR: swapEndian: string has an odd number of characters: " + original)
		throw new Error('10012');
	}

	const byteLen = strLen / 2;
	//console.log('swapEndian: there are ' + byteLen + ' bytes to swap')
	var final = ''
	for ( var i = 0; i < byteLen; i++ ) {
		let b = 2 * i;
		final += ( original[strLen - b - 2] + original[strLen - b - 1] )
	}

	//console.log('swapped to ' + final)
	return final
}

//export /* */
function execAsync( f, milliSeconds = 0 ) {
    setTimeout(f, milliSeconds)
}

//export /* */
function printError(error) {
    console.log('Failed:\nError .response.status ' + error.response?.status + '   .response.data "' + error.response?.data + '"');
    console.log("Error .request: ", error.request)
    console.log("Error .message: ", error.message)
    console.log("Error .context: ", error.context)
}

// from:
// https://stackoverflow.com/questions/1125084/how-to-make-the-window-full-screen-with-javascript-stretching-all-over-the-scre/7525760#7525760
function requestFullScreen(elemRef) {
    const elem = elemRef.current

    // Supports most browsers and their versions.
    var requestMethod = elem.requestFullScreen || elem.webkitRequestFullScreen || elem.mozRequestFullScreen || elem.msRequestFullScreen;

    if (requestMethod) { // Native full screen.
        // make the WINDOW full screen
        requestMethod.call(elem);

    } else {
        console.error("requestFullScreen() not supported by your browser")
        //alert("requestFullScreen() not supported by your browser")

    }
}

/**
 * Fetches a tx, which is NOT a part of the shizzle tree, and identify
 * and deliver the requested chunks for media playback
 *
 * @param {*} txId     published tx which holds video/audio data
 * @param {*} inOut    'in', or 'out' - whether data is in an input, or an output
 * @param {*} inOutNum base-0 index to the input or output
 * @param {*} chunkNum base-0 index to the data-containing chunk in the input-or-output
 *
 * @returns an object with multiple fields
 */
async function fetchPublishedVideo(txId, inOut = 'out', inOutNum = 0, chunkNum = 1) {
    // taken from videoLibrary
    // adapted from contentPresenter's theChildMessageHandler()
    // see also: surfer.js's componentDidMount(), and .tempVideoWork(). ?WHAT?

    //const txId = videoTxId    //fields[0]
    //const inOut = 'out' //fields[1]
    //const inOutNum = 0  //fields[2]
    //const chunkNum = 1  //fields[3]

    if ( inOut !== 'in' && inOut !== 'out' ) {
      console.warn("fPV: CONTENT ERROR: INVALID in/out type specified: " + inOut + ". Should be 'in', or 'out'.")
      return {success: false, failurePoint: 1}; //continue
    }

    //FIXME: allow ranges of chunks, outputs? All chunks? All outputs?
    console.log("fPV:   Will extract chunk #" + chunkNum + " of " + inOut + "[" + inOutNum + "] of " + txId)

    const rawTx = await queryFetchTx(txId, await openDB())
    if ( rawTx === null || typeof rawTx === 'number' ) {
      console.warn("fPV: theChildMessageHandler() Ignoring tx (txid " + txId + "). It's not there.")

      return {success: false, failurePoint: 2}; //continue
    }

    // We decompose AFTER we've checked that the tx is still relevant
    const tx = decomposeTx(rawTx)

    console.log("fPV:   BTW: inOutNum is " + inOutNum)
    console.log("fPV:   BTW: inOutNum+1 is " + (inOutNum+1))

    if ( inOut === 'out' && (inOutNum + 1) > tx.outputs.length ) {
      console.warn("fPV: CONTENT ERROR: Invalid OUT index: " + inOutNum + ". Exceeds number of outputs: " + tx.outputs.length)
      return {success: false, failurePoint: 3}; //continue
    }
    if ( inOut === 'in' && (inOutNum + 1) > tx.inputs.length ) {
      console.warn("fPV: CONTENT ERROR: Invalid IN index: " + inOutNum + ". Exceeds number of inputs: " + tx.inputs.length)
      return {success: false, failurePoint: 4}; //continue
    }
    if ( inOutNum < 0 ) {
      console.warn("fPV: CONTENT ERROR: Invalid in/out index: " + inOutNum)
      return {success: false, failurePoint: 5}; //continue
    }

    console.log(" -.-.-.-.-.-.-")
    console.log("fPV: theChildMessageHandler(): decomposition of tx: " + txId + ": ", tx)
    console.log("fPV: theChildMessageHandler(): got " + tx.inputs.length + " inputs, and "
                + tx.outputs.length + " outputs for bshz tx request")

    let inOuts
    if ( inOut === 'out' ) {
      inOuts = parseTheOutputs( tx.outputs, true )
    } else {
      inOuts = parseTheInputs( tx.inputs, true )
    }

    console.log("fPV: Done parsing inputs, or outputs of tx " + txId)
    console.log("fPV: will deliver chunk # " + chunkNum + " from in/out #" + inOutNum)

    //FIXME: allow delivery of more than 1 chunk or output?

    const chunks = inOuts[inOutNum]
    console.log("fPV: BTW: there are " + chunks.length + " chunks in this in/out")

    const theData = chunks[chunkNum]

    console.log("fPV: BTW: chunk # " + (chunkNum-1) + " from in/out #" + inOutNum + " has a length of " + chunks[chunkNum-1].length)
    console.log("fPV: chunk # " + chunkNum + " from in/out #" + inOutNum + " has a length of " + theData?.length + "  <---")
    console.log("fPV: BTW: chunk # " + (chunkNum+1) + " from in/out #" + inOutNum + " has a length of " + chunks[chunkNum+1]?.length)


    const videoType = hexStringToAscii(chunks[Number(chunkNum) + 1])
    console.log("fPV: This is of videoType: ", videoType)
    //FIXME: rename to mediaClass
    const mediaType = videoType.startsWith('audio') ? 'audio' : 'video'

    // TypeError: Failed to construct 'Blob': The provided value cannot be converted to a sequence.

    const videoBuff64 = Buffer.from(theData, "hex").toString('base64')
    const videoBuff = Buffer.from(theData, "hex")

    // NOTE the [] around the videoBuff !!
    const videoBlob = new Blob([videoBuff], { type: videoType });
    const videoUrl = URL.createObjectURL(videoBlob);

    // FAILED attempt to solve/workaround safari problems playing webm opus
    // (which MediaSource.isTypeSupported() claims is 'supported' for playback)
    // Thought the problem was blob-specific. Probably not
    //const videoBase64 = {base64: videoBuff.toString('base64'), mType: videoType}

    console.warn("fPV: chunks had length of " + chunks.length)

    console.warn("fPV: blob has size of " + videoBlob.size)
    console.warn("fPV: url: " + videoUrl)

    console.log("fPV: Here's the recorded blob: ", videoBlob)
    console.log("fPV: Here's the recorded blob url: ", videoUrl)

    return {
        success:    true,

        videoType:  videoType,      // ex: video/webm; codecs=opus,vp8
        mediaType:  mediaType,      // 'audio' or 'video'
        //videoBuff:  videoBuff,
        videoBuff64: videoBuff64,   // ready for embedding in <video>
        mediaURL:   videoUrl,       // ready for play, but can't be passed to an iframe
    }
}

// add/persist a single utxo to be frozen
async function persistUtxoFrozen( utxo ) {
    const db = await openDB()
    const frozenUtxosJsonStr = await readSetting(db, 'frozenCoins')

    console.log("frozenCoins BEFORE freezing one: ", frozenUtxosJsonStr)

    let frozenCoins
    if ( frozenUtxosJsonStr === null ) {
        console.warn("frozenCoins was null")
        frozenCoins = []
    } else {
        frozenCoins = JSON.parse( frozenUtxosJsonStr.value )
    }
    frozenCoins.push( utxo )

    const newFrozenUtxosJsonStr = JSON.stringify( frozenCoins )
    await saveSetting(db, 'frozenCoins', newFrozenUtxosJsonStr)
}

// UN-freeze (thaw) a single utxo (and persist that removal)
async function persistUtxoUnFrozen( utxo ) {
    const db = await openDB()
    const frozenUtxosJsonStr = await readSetting(db, 'frozenCoins')

    console.log("frozenCoins BEFORE thawing one: ", frozenUtxosJsonStr)

    const frozenCoins = JSON.parse( frozenUtxosJsonStr.value )

    frozenCoins.forEach( (coin, coinIndex) => {
        if ( coin.txId === utxo.txId  && coin.outputIndex === utxo.outputIndex ) {
            frozenCoins.splice( coinIndex, 1 )
        }
    })

    const newFrozenUtxosJsonStr = JSON.stringify( frozenCoins )

    console.log("frozenCoins AFTER thawing one: ", newFrozenUtxosJsonStr)

    await saveSetting(db, 'frozenCoins', newFrozenUtxosJsonStr)
}

async function getFrozenUtxos() {
    const frozenUtxosJson = await readSetting(await openDB(), 'frozenCoins')

    console.log("frozenCoinsJSON: ", frozenUtxosJson)

    if ( frozenUtxosJson === null ) {
      return []
    }

    const frozenCoins = JSON.parse( frozenUtxosJson.value )
    console.warn("json-parsed frozen coins: ", frozenCoins)

    return frozenCoins
}


// node utility needs this, but abhors function export
/* */
//module.exports = {
export {
    deduceMimeType,
    convertAddressToPKH,
    int2Asm,
    oneByteLE,
        twoByteLE,
        fourByteLE,
        fiveByteLE,
        eightByteLE,
    hexByteToAscii,
    hexStringToAscii,
    swapEndian,
    execAsync,
    printError,
    requestFullScreen,

    validateShizzleTree,
    fetchPublishedVideo,
    persistUtxoFrozen,
    persistUtxoUnFrozen,
    getFrozenUtxos
}
/* */