"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var getOption_1 = require("./getOption");
function getAccessoriesType(options, random) {
    var accessoriesType = [];
    if (getOption_1.getOption('accessories', 'kurt', options)) {
        accessoriesType.push('Kurt');
    }
    if (getOption_1.getOption('accessories', 'prescription01', options)) {
        accessoriesType.push('Prescription01');
    }
    if (getOption_1.getOption('accessories', 'prescription02', options)) {
        accessoriesType.push('Prescription02');
    }
    if (getOption_1.getOption('accessories', 'round', options)) {
        accessoriesType.push('Round');
    }
    if (getOption_1.getOption('accessories', 'sunglasses', options)) {
        accessoriesType.push('Sunglasses');
    }
    if (getOption_1.getOption('accessories', 'wayfarers', options)) {
        accessoriesType.push('Wayfarers');
    }
    var pickedAccessoriesType = random.pickone(accessoriesType);
    if (false === random.bool(undefined !== options.accessoriesChance ? options.accessoriesChance : 10)) {
        return 'Blank';
    }
    return pickedAccessoriesType;
}
exports.getAccessoriesType = getAccessoriesType;
