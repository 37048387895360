import React from 'react';

import { Button, Checkbox, Divider, Form, Icon,
    Message, Modal, Popup, Table, TextArea } from 'semantic-ui-react'

import {
    openDB,

    readSetting,
    saveSetting,

    getGuestPostRef,

    decryptData,

    findNextTxFromProvider,

    queryFetchDecodeTx,

    findADialogByStartingTxId,
    findADialogById,

    mySleep,
    simpleDecode,
} from './buildShizzle.js';

import {
    hexStringToAscii,
} from './commonFuncs';

import {
    getBitcoinDomainName,
} from './commonReact';

import './index.css';

import DialogManager    from './dialogManager';

class GuestPostManager extends React.PureComponent {
    constructor(props) {
        super(props);

        this.fontFamily          = "verdana"

        //FIXME: the purples are currently duplicated in index.css
        this.bshzPurple             = '#8313e2';
        this.bshzYellow             = "#fff300";
        this.bshzLtPurp             = '#e7d0fb';
        this.bshzLightYellow        = "#fffccc";

        this.shizzleVerse        = <><b>ShizzleVerse</b><small>&trade;</small></>;

        this.domainsWithDialogs = new Set()

        this.state =  {
                        otherDomainsList: null,

                        zoomIn: false,
                        showSpinner: false,
                        zoomedInContent: null,

                        otherDomainsFullSet: null,
                        relevantGPsCount: 0,

                        showSingleGP: false,
                        singleGPContent: null,
                        singleGPByUs: false,
                        singleGPDialogTxId: null,   // if we click on a zoomed Dialog, this is the start of it


                        showSwirly: false,
                        swirlyText: '',

                        showDialogManager: false,
                        theDialogToJumpTo: null,
        }



        this.closeGuestPostManager         = this.closeGuestPostManager.bind(this);

        this.buildOtherDomainsList         = this.buildOtherDomainsList.bind(this);
        this.otherDomainChosen             = this.otherDomainChosen.bind(this);

        this.buildZoomIn                   = this.buildZoomIn.bind(this);
        this.closeZoom                     = this.closeZoom.bind(this);

        this.zoomInPost                    = this.zoomInPost.bind(this);
        this.closeSinglePost               = this.closeSinglePost.bind(this);

        this.jumpToDialog                  = this.jumpToDialog.bind(this);
        this.closeDialogManager            = this.closeDialogManager.bind(this);
    }

    closeGuestPostManager() {
        this.props.closeManager()
    }

    async componentDidMount() {

        console.warn("guestPostManager componentDidMount()")
        console.warn("guestPostManager cDR(): dialogs of interest: ", this.props.dialogsOfInterest)

        if ( this.props.parent && this.props.registerClient ){
            this.props.registerClient(this.props.parent, this, "guestPostManager")
        }

        const db = await openDB()
        const guestPosts = await getGuestPostRef(db, this.props.ourIdentity, true)
        console.warn("Here are the " + guestPosts.length + " guestPosts we've found: ", guestPosts)
        const otherDomains = new Set
        const otherDomainsFullSet = new Set
        for (let i = 0; i < guestPosts.length; i++ ) {
            const gp = guestPosts[i]
            //console.log("  gp " + i + " regards domain " + gp.otherLimb)
            if ( !otherDomains.has(gp.otherLimb) ) {
                otherDomains.add( gp.otherLimb )
                otherDomainsFullSet.add(   {
                                            name: gp.otherLimb,
                                            numGPs: 1
                                        }
                                    )
            } else {
                for (const item of otherDomainsFullSet) {
                    //console.log("We already have a set: ", item);
                    if ( item.name === gp.otherLimb ) {
                        //console.warn("Yay. This one needs incrementing!")
                        item.numGPs++
                        //console.warn("NOW: this one: ", item)
                        break;
                    }
                }
                //console.warn("NOW, full: ", otherDomainsFull)
            }
        }
        console.log("at the END, otherDomainsFullSet: ", otherDomainsFullSet)

        console.log("Here's the set of " + otherDomains.size + " other domains involved in guestPosts with " + this.props.ourIdentity + ":", otherDomains)


        console.log("Our IDENTITY: " + this.props.ourIdentity)
        console.log("Current list of domains with dialogs: ", this.domainsWithDialogs)

        for ( let i = 0; i < this.props.dialogsOfInterest.length; i++ ) {
            console.log("reviewing a DIALOGUE of interest: " + this.props.dialogsOfInterest[i] + " <-----")

            const dialogRec = await findADialogById(db, this.props.dialogsOfInterest[i])
            console.log("    record of interest: ", dialogRec)

            console.log("  gpm: Am dialog owner? " + dialogRec.isOwner)
            console.log("  gpm:   dialog owner name: " + dialogRec.name)
            console.log("  gpm:   dialog other guy: " + dialogRec.otherGuy)

            if ( dialogRec.name === this.props.ourIdentity ) {
                this.domainsWithDialogs.add(dialogRec.otherGuy)
                console.log("ADDING domain " + dialogRec.otherGuy)
            } else {
                this.domainsWithDialogs.add(dialogRec.name)
                console.log("ADDING domain " + dialogRec.name)
            }
        }

        console.log("Total list of domains with which we dialog: ", this.domainsWithDialogs)


        const otherDomainsList = this.buildOtherDomainsList(otherDomainsFullSet)

        this.setState({
                        relevantGPsCount: guestPosts.length,
                        guestPosts:       guestPosts,
                        otherDomainsList:   otherDomainsList,   //FIXME: rename to otherDomainsTable
                        otherDomainsFullSet: otherDomainsFullSet,
                      })

        //IMPLEMENT: a manager that lists count for each domain, and allows:
        //             - listing/scrolling through each - mentioning if a validation has been made etc
        //             - jumping to a selected GP
        //             - checking each for possible dialog-creation (if not already flagged)
        //             - maybe mentioning which currently spawn a dialog
        //             - maybe jumping to a dialog spawned by one
        //             - MAYBE grouping consecutive GPs? hmm
        //             - initiating a validation check on a GP
        console.warn("IMPLEMENT ME: consider ownerCount - to see ONLY the GuestPosts of which " + this.props.ourIdentity + " is a party")


    } // componentDidMount()

    componentWillUnmount() {
        console.log("DIALOGUE MANAGER about to unmount. Will UNregister...")

        if ( this.props.parent && this.props.unregisterClient ) {
            this.props.unregisterClient(this.props.parent, this, "dialogManager")
        }


        // fix Warning: Can't perform a React state update on an unmounted component
        // see: https://stackoverflow.com/questions/53949393/cant-perform-a-react-state-update-on-an-unmounted-component
        this.setState = (state,callback)=>{
            return;
        };
    }

    zoomInPost(event, content, byUs, txId) {
        console.log("zoomInPost(): Great. now display this (by us? " + byUs + "). content: " + content)
        this.setState({
                        showSingleGP: true,
                        singleGPContent: content,
                        singleGPByUs: byUs,
                        singleGPDialogTxId: txId,   // allows us to query for dialog using its startingTxId
                      })
    }
    closeSinglePost() {
        this.setState({
            showSingleGP: false,
            singleGPContent: null,
            singleGPByUs: false,
            singleGPDialogTxId: null,
          })
    }

    closeZoom() {
        this.setState({
            zoomIn: false,
            zoomedInContent: null,
        })
    }
    async buildZoomIn() {

        const db = await openDB()
        const txs = []
        const contents = []
        for ( const item of this.state.guestPosts ) {
            if ( item.otherLimb === this.state.chosenDomain ) {
                console.log("buildZoomIn(): guestPost with " + this.state.chosenDomain + ": " + item.txId)
                const tx = await queryFetchDecodeTx(item.txId, db)

                const spawnsDialog = tx.outputStates[2]?.contract === 'dialog' || tx.outputStates[3]?.contract === 'dialog'

                let content
                if ( tx.input0Params.content.length > 0 ) {
                    //FIXME: maybe we should look closer at the content - in case it's not a 'post'
                    //FIXME: at some point, will need to distinguish between content WE wrote, and that of the other party
                    content = tx.input0Params.content

                } else if ( tx.input1Params.content.length > 0 ) {
                    //FIXME: maybe we should look closer at the content - in case it's not a 'post'
                    content = tx.input1Params.content

                } else {
                    console.warn("hmm. content missing for tx " + item.txId + ". WON'T add it to the list")
                    continue
                }

                const byUs = hexStringToAscii( tx.outputStates[0].namePath ) === this.state.chosenDomain
                contents.push({ postContent: hexStringToAscii( content ),
                                byUs: byUs,
                                // we need a unique key (not just null), but this guest-post doesn't spawn a dialog,
                                // so, we can alter the txId (since we won't actually use it here)
                                txId: spawnsDialog ? item.txId : "x" + item.txId,
                                spawnsDialog: spawnsDialog,
                             })
                txs.push(tx)
            }
        }

        console.warn("Here are all of the txs/guest-posts with chosen domain " + this.state.chosenDomain + ":", txs)
        console.warn("Here are all of the POSTS chosen domain " + this.state.chosenDomain + ":", contents)


        // a scrollable, chooseable table of posts
        const zoomedGuestPostRows = contents.map((rec) =>
                <Table.Row className="hoverLink" key={rec.txId}
                        onClick={       (event) => this.zoomInPost(event, rec.postContent, rec.byUs, rec.txId)}
                        onContextMenu={ (event) => this.zoomInPost(event, rec.postContent, rec.byUs, rec.txId)}>

                    <Table.Cell style={{padding:'8px 8px', border:'.1px solid'}}>
                        {rec.byUs ? 'yes' : 'no'}
                    </Table.Cell>

                    <Table.Cell style={{padding:'8px 8px', border:'.1px solid'}}>
                        <span style={{color:"blue"}}>{rec.postContent.substring(0, 24)}...</span>&nbsp;
                        {rec.spawnsDialog ? " [spawns dialogue]" : ""}
                    </Table.Cell>

                </Table.Row>
            )

        const zoomedInTable = 
                    <Table unstackable celled style={{borderSpacing:"0px", border:'none', backgroundColor:"#00000000"}} >
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell style={{backgroundColor:'blue', color:'white', padding:'8px 8px'}}>By Us?</Table.HeaderCell>
                                <Table.HeaderCell style={{backgroundColor:'blue', color:'white', padding:'8px 8px'}}>Snippet</Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            {zoomedGuestPostRows}
                        </Table.Body>
                    </Table>

        this.setState({ showSpinner: false,
                        zoomedInContent: zoomedInTable,
                    })
    }

    async otherDomainChosen(e, domain) {

        if (e?.type === 'click') {
            console.log('otherDomainChosen: Left click');
        } else if (e?.type === 'contextmenu') {
            console.log('otherDomainChosen: Right click');
            return
        }

        console.warn("IMPLEMENT: expand view to all guest posts related to domain " + domain)
        console.log("otherDomainChosen(): otherDomainsFullSet: ", this.state.otherDomainsFullSet)

        for ( const item of this.state.guestPosts ) {
            if ( item.otherLimb === domain ) {
                console.log("oDC(): guestPost with " + domain + ": " + item.txId)
            }
        }

        // This happens in two steps
        this.setState({ chosenDomain: domain,
                        zoomIn: true,
                        swirlyText: "Loading guest posts...",
                        showSpinner: true,
                      }, this.buildZoomIn )
    }

    //FIXME: rename to buildOtherDomainsTable
    buildOtherDomainsList(otherDomainsFullSet) {

        console.log("buildGuestPostList(): otherDomainsFullSet: ", otherDomainsFullSet)
        console.warn("domainsWithDialogs: ", this.domainsWithDialogs)
        const records = Array.from( otherDomainsFullSet )
        const gpList = records.map((rec) =>
                <Table.Row className="hoverLink" key={rec.name}
                        onClick={       (event) => this.otherDomainChosen(event, rec.name)}
                        onContextMenu={ (event) => this.otherDomainChosen(event, rec.name)}>

                    <Table.Cell style={{padding:'8px 8px', border:'.1px solid'}}>
                        {rec.name}
                    </Table.Cell>

                    <Table.Cell style={{padding:'8px 8px', border:'.1px solid'}}>
                        <span style={{color:"blue"}}>{rec.numGPs}</span>&nbsp;
                    </Table.Cell>

                    <Table.Cell style={{padding:'8px 8px', border:'.1px solid'}}>
                        <span style={{color:"blue"}}>{this.domainsWithDialogs.has(rec.name)? "yes" : "no"}</span>&nbsp;
                    </Table.Cell>

                </Table.Row>
            )
        console.log("gpList: ", gpList)
        return gpList
    }

    async jumpToDialog() {
        const txIdOfDialogToJumpTo = this.state.singleGPDialogTxId
        console.log("guestPostManager: will jump to dialog starting at " + txIdOfDialogToJumpTo)

        if ( txIdOfDialogToJumpTo && txIdOfDialogToJumpTo !== null ) {
            const dialogIdRecords = await findADialogByStartingTxId(await openDB(), txIdOfDialogToJumpTo)

            console.warn("Got records of dialog: ", dialogIdRecords)
            console.log("our identity: ", this.props.ourIdentity)

            let dialogIdToJumpTo = dialogIdRecords[0].name === this.props.ourIdentity ?
                                        dialogIdRecords[0].dialogId
                                    :
                                        dialogIdRecords[1].dialogId
            console.log("record 0.name: ", dialogIdRecords[0].name)

            console.log("the dialogId to jump to: ", dialogIdToJumpTo)

            this.setState({ theDialogToJumpTo:  dialogIdToJumpTo,
                            showDialogManager:   true,
                        })
        } else {
            alert("Whoa. we don't have a dialog starting txId to jump to")
            throw new Error("CODE Error in GPManager")
        }
    }
    closeDialogManager() {
        this.setState({showDialogManager: false, theDialogToJumpTo: null})
    }

    render() {

        // ***  DEVICE-DEPENDENT STYLING  ***
        // For mobile, "detect" landscape screen mode
        const landscape = this.props.isMobile ? window.innerWidth > window.innerHeight : false
        // For mobile, shrink the About, Security, and Glossary modals
        const modalClassName   = this.props.isMobile ?
                                        (landscape ?
                                                "modalMobileLandscape"
                                            :
                                                "modalMobilePortrait"
                                        )
                                    :
                                        ""
        const modalContentClassName = this.props.isMobile ? "modalContentMobile" : ""
        const modalBottomClassName  = this.props.isMobile ? "modalBottomMobile"  : ""


        const otherDomainsList = this.state.otherDomainsList


        //FIXME: ponder if the GuestPostManager should registerClient:
        //          registerClient={this.registerBRunnerClient}
        //          unregisterClient={this.unregisterBRunnerClient}
        //       Probably - so it can get periodic heartbeats/prompts to query subcription UTXOs


        const pluralOrSingleGPs = this.state.relevantGPsCount === 1 ? "Guest Post" : "Guest Posts"
        const dlgIcon = <Icon link size='large' color="purple" name='envelope outline'/>
        const mainModal =
                <>
                    <Modal dimmer='blurring' size='small' centered className={modalClassName}  open={true}
                                    style={{backgroundColor: this.bshzPurple, borderRadius: '20px', height: "auto"}}>

                        <Modal.Header style={{textAlign: 'center', backgroundColor: this.bshzPurple, borderRadius: '20px'}}>
                            <span style={{color: this.bshzYellow}}> Domains Guest-Posting with your {getBitcoinDomainName( this.props.ourIdentity)} </span>
                        </Modal.Header>

                        <Modal.Content className={modalContentClassName} scrolling style={{backgroundColor: this.bshzLtPurp}}>

                        <div style={{textAlign: 'center', color:'blue', fontSize: "1.3rem"}}>
                            We've found there are {this.state.otherDomainsFullSet?.size} domains involved in {this.state.relevantGPsCount} {pluralOrSingleGPs} with you.

                            <br></br>

                        </div>

                            <Table unstackable celled style={{borderSpacing:"0px", border:'none', backgroundColor:"#00000000"}} >
                                <Table.Header>
                                    <Table.Row>
                                        <Table.HeaderCell style={{backgroundColor:'blue', color:'white', padding:'8px 8px'}}>domain</Table.HeaderCell>
                                        <Table.HeaderCell style={{backgroundColor:'blue', color:'white', padding:'8px 8px'}}># of guest posts</Table.HeaderCell>
                                        <Table.HeaderCell style={{backgroundColor:'blue', color:'white', padding:'8px 8px'}}>has dialogue(s)?</Table.HeaderCell>
                                    </Table.Row>
                                </Table.Header>
                                <Table.Body>
                                    {otherDomainsList}
                                </Table.Body>
                            </Table>

                        <br></br>
                        <p>
                        Click on a domain above to examine the Guest-Posts associated with it.
                        </p>
                        <p>
                        If you have any Dialogues with that domain, you could also go BACK, and click on
                        the Dialogue icon {dlgIcon} in the title banner - to review them.
                        </p>
                        <div style={{textAlign: 'center'}}>
                                <Button positive onClick={this.closeGuestPostManager} content='BACK'/>
                        </div>

                        </Modal.Content>

                    </Modal>
                </>

        const maybeSpinner = this.state.showSpinner ?
                            <>
                                <div style={{textAlign: 'center'}}>
                                    <Icon size='large'  loading name='spinner' />
                                </div>

                                <div style={{textAlign: 'center'}}>
                                    {this.state.swirlyText}
                                </div>
                            </>
                                :
                            <></>
        const zoomForFullGP = this.state.showSpinner ?
                                        <></>
                                    :
                                        <>
                                            <br></br>
                                            Click a Guest-Post snippet to see it's full text.
                                        </>
        const maybeZoomInGuestPostModal =
                this.state.zoomIn ?
                    <>
                        <Modal dimmer='blurring' size='tiny' centered className={modalClassName}  open={true}
                                        style={{backgroundColor: this.bshzPurple, borderRadius: '20px', height: "auto"}}>
                            <Modal.Header style={{textAlign: 'center', backgroundColor: this.bshzPurple, borderRadius: '20px'}}>
                                <span style={{color: this.bshzYellow}}> Your Guest-Posts with {getBitcoinDomainName( this.state.chosenDomain)} </span>
                            </Modal.Header>

                            <Modal.Content className={modalContentClassName} scrolling style={{backgroundColor: this.bshzLtPurp}}>

                                {maybeSpinner}
                                {this.state.zoomedInContent}

                                {zoomForFullGP}
                                <br></br>
                                <br></br>
                                <div style={{textAlign: 'center'}}>
                                        <Button positive onClick={this.closeZoom} content='BACK'/>
                                </div>
                            </Modal.Content>
                        </Modal>
                    </>
                :
                    <></>

        const singleGPTitle = this.state.singleGPByUs ?
                                        "Your Guest-Post to "
                                    :
                                        "A Guest-Post by "
        const dialogJumpButton = this.state.singleGPDialogTxId === null || this.state.singleGPDialogTxId.startsWith("x") ?
                                        <></>
                                    :
                                        <>
                                            <Button positive onClick={this.jumpToDialog} content='Jump to the spawned Dialogue'/>
                                        </>
        const maybeSingleGP =
                this.state.showSingleGP ?
                    <>
                        <Modal dimmer='blurring' size='tiny' centered className={modalClassName}  open={true}
                                        style={{backgroundColor: this.bshzPurple, borderRadius: '20px', height: "auto"}}>
                            <Modal.Header style={{textAlign: 'center', backgroundColor: this.bshzPurple, borderRadius: '20px'}}>
                                <span style={{color: this.bshzYellow}}> {singleGPTitle}{getBitcoinDomainName( this.state.chosenDomain)} </span>
                            </Modal.Header>

                            <Modal.Content className={modalContentClassName} scrolling style={{backgroundColor: this.bshzLtPurp}}>


                                {this.state.singleGPContent}

                                <br></br>
                                <br></br>
                                <div style={{textAlign: 'center'}}>
                                        <Button positive onClick={this.closeSinglePost} content='Okay'/>
                                        {dialogJumpButton}
                                </div>
                            </Modal.Content>
                        </Modal>
                    </>
                :
                    <></>

const maybeDialogManager = this.state.showDialogManager ?
                    <>
                        <DialogManager
                                jumpToSingleDialog={this.state.theDialogToJumpTo}

                                ourIdentity={this.props.ourIdentity}
                                dialogsOfInterest={this.props.dialogsOfInterest}

                                getDialogsYetToHearBackFrom={this.props.getDialogsYetToHearBackFrom}
                                getDialogsToRespondTo={ this.props.getDialogsToRespondTo }
                                getActiveDialogs={ this.props.getActiveDialogs }
                                getClosedDialogs={ this.props.getClosedDialogs }

                                closeManager={this.closeDialogManager}

                                bundle={this.props.bundle}

                                dialogCallbackToParent={this.props.dialogCallbackToParent}
                        />
                    </>
                :
                    <></>
        return(
            <>
            {mainModal}
            {maybeZoomInGuestPostModal}
            {maybeSingleGP}
            {maybeDialogManager}

            <Modal dimmer='blurring' size='tiny' centered className={modalClassName}  open={this.state.showSwirly}
                            style={{backgroundColor: this.bshzPurple, borderRadius: '20px', height: "auto"}}>
                <Modal.Content className={modalContentClassName} scrolling style={{backgroundColor: this.bshzLtPurp}}>

                  <div style={{textAlign: 'center'}}>
                    <Icon size='large'  loading name='spinner' />
                  </div>

                  <div style={{textAlign: 'center'}}>
                    {this.state.swirlyText}
                  </div>

                </Modal.Content>
            </Modal>

            </>
        )
    }
}

export default GuestPostManager;
