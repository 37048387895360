import React from 'react';

import { Button, Checkbox, Container, Divider, Dropdown, Form, Grid,
    Input, Menu, Message, Modal, Popup, Radio, TextArea } from 'semantic-ui-react'

import {
    testGetBulkUnspent,

    saveEncryptedKeys,
    getEncryptedKeysFromPKH,
    getAllEncryptedP2PkhKeys,
    getRabinsJson,   //KeyExporter actually uses this

    saveEncryptedP2PKHKey,
    getEncryptedP2PKHKeyFromAddress,
    getEncryptedKeyFromAddress,
    getP2PKHsJson,

    buildShizzleLockingTx,
    encryptData,
    decryptData,

    getCurrentBlockInfo,
    findLatestContinue,
    findLatestAuction,

    openDB,
    getTxidFromAddr,
    recursivelyDeleteDescendantSpends,
    queryTxs,
    queryLabels,
    checkDanglingPaths,
    queryFetchTx,
    decomposeTx,
    queryFetchDecodeTx,
    findContent,

    parseTheOutputs,

    findNextTx,
    findPrevTx,
    buildOnBuilderPrep,
    buildOnAppend,
    buildOnClaimOrPublish,
      applyBuilderFeeReduction,
      announceBuilderFeeReductionAuth,
      buildOnAskBid,
    buildOnAnUpdate,
    buildOnATransient,
    buildOnDialog,
    buildOnABitGroup,
    buildOnTheAdminGroup,
    validateRabinPrivateKeys,
    generateRabinPrivateKeyPlus
} from './buildShizzle.js';

const { fetchUtxos } = require('./providers.js')
const { bsv } = require('scryptlib');

// Use the PKH generated by RabinUtility - help to fill-in the
// rabinPKH field for the given contract Builder:
//     ClaimBuilder, UpdateBuilder, TransientBuilder, AdminGroupBuilder, BitGroupBuilder
// NOT FOR: AppendBuilder
const handleGeneratedKeys = (parent, p, q, pkh) => {
    console.log("static XBuilder handleGeneratedKeys: will use p of " + p)
    console.log("static XBuilder handleGeneratedKeys: will use q of " + q)
    console.log("static XBuilder handleGeneratedKeys: will use pkh of " + pkh)

    // ALSO set by handleFieldChange()
    parent.setState({rabinPKH: pkh})
}


function mySleep(ms, message) {
  if ( message.length !== 0 ) {
    console.log("Sleeping " + ms + " ms " + message)
  }
  return new Promise(resolve => setTimeout(resolve, ms))
}

class BsvKeyRetriever extends React.Component {
  constructor(props) {
    super(props);
    this.handleBsvKeyGetterCancel    = this.handleBsvKeyGetterCancel.bind(this);
    this.handleBsvKeyGetterGet       = this.handleBsvKeyGetterGet.bind(this);
    this.handleBsvRetrieverPwdChange = this.handleBsvRetrieverPwdChange.bind(this);

    this.state = {openBsvRetrieverModal: true, bsvRetrieverPwd: ''};
  }

  handleBsvKeyGetterCancel(event) {
    event.preventDefault();

    console.log("handleBsvKeyGetterCancel(): calling parent's closeRetriever()")
    this.props.closeBsvRetriever()
  }

  async handleBsvKeyGetterGet(event) {
    event.preventDefault();

    console.log("handleBsvKeyGetterGet():");

    //console.error("handleBsvKeyGetterGet(): will look-up keys for password " + this.state.bsvRetrieverPwd);

    var db = await openDB();
    let results = []

    // We pass a param if we want to limit the search to a single key/address
    if ( this.props.addressOfKeyToGet !== null && this.props.addressOfKeyToGet !== '' ) {

      // we're looking for a particular key - probably to DECRYPT a transaction message
      console.warn("handleBsvKeyGetterGet(): Just getting ONE KEY/address: " + this.props.addressOfKeyToGet)
      const result = await getEncryptedKeyFromAddress(db, this.props.addressOfKeyToGet)

      console.log("handleBsvKeyGetterGet(): got SINGLE json object? ", result);
      results[0] = result

    } else {

      // we're looking for ANY key - probably to FUND a transaction
      console.log("handleBsvKeyGetterGet(): We may get multiple results...")

      // now, search the 'Official' wallet (new-school)
      results = await getAllEncryptedP2PkhKeys(db, true)
    }

    console.log("handleBsvKeyGetterGet(): got json results object: ", results);

    const pwd = this.state.bsvRetrieverPwd;

    let foundDecodableEntry = false
    let foundParseableEntry = false
    let decodableParseableResults = []
    let decodableParseableCount = 0
    for ( let i in results ) {
      const obj = results[i]

      try {
        console.log("handleBsvKeyGetterGet(): looking at a single object  result [" + i + "]: ", obj);

        const plainBundle = await decryptData(obj.blob, pwd);
        //console.log("handleBsvKeyGetterGet(): decrypted json string:", plainBundle);

        foundDecodableEntry = true
        console.warn("handleBsvKeyGetterGet(): found decodable entry #" + i)

        const bundledObject = JSON.parse(plainBundle);
        foundParseableEntry = true
        //console.warn("parsed decoded bundle: ", bundledObject, "\n")

        decodableParseableResults[ decodableParseableCount ] = bundledObject.p
        decodableParseableCount++
      } catch (error) {
        console.log("handleBsvKeyGetterGet(): Bad decrypt or parse. Maybe provided wrong password for entry #" + i);
        continue
      }
    }
//    console.warn("\n\nhandleBsvKeyGetterGet(): We found " + decodableParseableCount + " usable KEYs: ", decodableParseableResults)
    console.warn("\n\nhandleBsvKeyGetterGet(): We found " + decodableParseableCount + " usable KEYs")

    // This could take a while. Currently:
    //   FundsGetter's handleRetrievedBsvKeys(),
    //   ShizzleNav's handleRetrievedDecryptionKey(),
    //   or KSM's handleRetrievedRabinKeys()
    await this.props.resultsHandler(this.props.parent, decodableParseableResults)

    console.log("BsvKeyRetriever handleBsvKeyGetterGet(): done returning state/results to parent (FundsGetter, or ShizzleNav)")

    this.props.closeBsvRetriever();
  }

  handleBsvRetrieverPwdChange(event) {
    event.preventDefault();

    //console.log("handleBsvRetrieverPwdChange(): pwd now set to ", event.target.value);

    this.setState({bsvRetrieverPwd: event.target.value});
  }

  render() {  // BsvKeyRetriever

    console.warn("BTW: bsvKeyRetriever extra verbage: " + this.props.extraVerbage)

    const disableGet = this.state.bsvRetrieverPwd.length < 8
    let goalMention
    if ( this.props.addressOfKeyToGet && this.props.addressOfKeyToGet.length > 20 ) {
      goalMention = <> {this.props.extraVerbage}
                      We'll need the password to decrypt the key that's associated with address
                        &nbsp;<span style={{color: 'blue'}}>{this.props.addressOfKeyToGet}</span>.
                    </>
    } else {
      goalMention = <>
                      We'll need a password to decrypt ANY key that can help us fund this
                      transaction we're building.
                    </>
    }

    return  <>
              <Modal open={true} size='small' dimmer='blurring'>
                <Modal.Header>GET, Decrypt, and USE BSV Private Key</Modal.Header>
                <Modal.Content>
                  {goalMention}
                  <p></p>
                  Any key saved to this browser (on this machine) has been encrypted through the use of a password.
                  <br></br>
                  <Form>
                    Please enter the password you used to encrypt the private key associated with this address (min 8 chars):
                    <Input style={{width:'350px'}} onChange={this.handleBsvRetrieverPwdChange}/> <br></br>
                    <Button field="getButton" onClick={this.handleBsvKeyGetterGet} disabled={disableGet} positive>Decrypt Key(s) with this password</Button>
                    <Button field="cancelButton" onClick={this.handleBsvKeyGetterCancel} negative>CANCEL</Button>
                  </Form>
                </Modal.Content>
              </Modal>
            </>;
  } // BsvKeyRetriever render()
}


class RabinKeyRetriever extends React.Component {
  constructor(props) {
    super(props);
    this.handleRabinGetterCancel  = this.handleRabinGetterCancel.bind(this);
    this.handleRabinGetterGet     = this.handleRabinGetterGet.bind(this);
    this.handleRetrieverPwdChange = this.handleRetrieverPwdChange.bind(this);

    this.state = {openRetrieverModal: true, retrieverPwd: ''};
  }

  handleRabinGetterCancel(event) {
    event.preventDefault();

    console.log("handleRabinGetterCancel(): calling parent's closeTriever()")
    this.props.closeRetriever()
  }

  async handleRabinGetterGet(event) {
    event.preventDefault();

    console.log("handleRabinGetterGet():");

    console.log("will look-up keys for PKH " + this.props.pkh);

    var db = await openDB();
    const obj = await getEncryptedKeysFromPKH(db, this.props.pkh);

    console.log("handleRabinGetterGet(): got json object: ", obj);

    const pwd = this.state.retrieverPwd;
    try {
      const plainBundle = await decryptData(obj.blob, pwd);

      //console.log("handleRabinGetterGet(): decrypted json string:", plainBundle);

      const bundledObject = JSON.parse(plainBundle);

      // return results back to parent
      this.props.resultsHandler({
        p: bundledObject.p,
        q: bundledObject.q
      });
      this.props.closeRetriever();
    } catch ( error ) {
      console.log("handleRabinGetterGet(): Bad decrypt. Probably provided wrong password.");

      //FIXME: let user know - bad password

    }
  }

  handleRetrieverPwdChange(event) {
    event.preventDefault();

    //console.log("handleRetrieverPwdChange(): pwd now set to ", event.target.value);

    this.setState({retrieverPwd: event.target.value});
  }

  render() {

    const disableGet = this.state.retrieverPwd.length < 8;

    return  <>
              <Modal open={true} size='small' dimmer='blurring'>
                <Modal.Header>GET, Decrypt, and USE Rabin Private Keys</Modal.Header>
                <Modal.Content>
                  Any keys saved to this browser (on this machine) have been encrypted through the use of a password.
                  <p></p>
                  We'll need the password to decrypt the Rabin keys that are associated with Rabin PKH
                  &nbsp;<span style={{color: 'blue'}}>{this.props.pkh}</span>.
                  <br></br>
                  <Form>
                    Please enter the password you used to encrypt the private keys associated with this PKH (min 8 chars):
                    <Input style={{width:'350px'}} onChange={this.handleRetrieverPwdChange}/> <br></br>
                    <Button field="getButton" onClick={this.handleRabinGetterGet} disabled={disableGet} positive>Decrypt Keys with this password</Button>
                    <Button field="cancelButton" onClick={this.handleRabinGetterCancel} negative>CANCEL</Button>
                  </Form>
                </Modal.Content>
              </Modal>
            </>;
  } // RabinKeyRetriever render()
}

/**
 * When done, the FundsGetter modal will set OUR state for these three variables:
 *     bsvPrivKey, bsvKeyIsSufficient, chosenAddress
 */
class FundsGetter extends React.Component {
  constructor(props) {
    super(props);

    this.handleGetBSVPrivKey        = this.handleGetBSVPrivKey.bind(this)
    this.handleSaveBSVPrivKey       = this.handleSaveBSVPrivKey.bind(this)
    this.handleBitcoinPrivKeyChange = this.handleBitcoinPrivKeyChange.bind(this)

    this.closeUsDown                = this.closeUsDown.bind(this)
    this.clearFundingAndCancel      = this.clearFundingAndCancel.bind(this)

    this.closeBsvRetrieverModal     = this.closeBsvRetrieverModal.bind(this)
    this.handleRetrievedBsvKeys     = this.handleRetrievedBsvKeys.bind(this)

    this.listClicked                = this.listClicked.bind(this)

    this.state = {
                  p2pkhPrefetchSuccessful: false,
                  usableBsvKeys: null,
                  bsvPrivKey: ''
                }
  }

  closeUsDown() {
    console.log("FundsGetter closing down fundsGetter...")
    this.props.closeModal( this.props.parent )
  }

  clearFundingAndCancel() {
    console.log("FundsGetter clearFunding()")

    this.props.parent.setState({bsvPrivKey: '', bsvKeyIsSufficient: false, chosenAddress: ''})
    this.setState({usableBsvKeys: null})
    this.closeUsDown()
  }

  closeBsvRetrieverModal() {
    console.log("FundsGetter closeBsvRetrieverModal(): ...")

    this.setState({openBsvKeyRetrieverModal: false});
  }
  handleGetBSVPrivKey() {
    console.log("FundsGetter handleGetBSVPrivKey(): ...")

    this.setState({openBsvKeyRetrieverModal: true});
  }

  analyzeUTXOs(utxos) {
    let largestUtxoFunds = -1
    let bestIdx = -1
    let satsSum = 0
    for ( let i = 0; i < utxos.length; i++ ) {
      const theseFunds = utxos[i].satoshis
      if ( theseFunds > largestUtxoFunds ) {
        largestUtxoFunds = theseFunds
        bestIdx = i
      }
      satsSum += theseFunds
    }

    if ( bestIdx === -1 ) {
      return { good: false }
    }

    const bestUTXO = utxos[bestIdx]
    console.warn("      analyzeUTXOs(): Total funding, in " + utxos.length + " UTXOS: " + satsSum + " sats")
    console.warn("      analyzeUTXOs(): Having looked through " + utxos.length
              + " UTXOs for that address, we found that the largest funding was for "
              + largestUtxoFunds + " sats (outpoint "
              + bestUTXO.txId + " : " + bestUTXO.outputIndex + ")")

    //FIXME: rule-of-thumb to judge what might be 'viable' for funding most transactions
    if ( largestUtxoFunds > 50000 || (satsSum/utxos.length > 500 && satsSum > 55000) ) {
      console.log("analyzeUTXOs(): building entry with .numUTXOs of " + utxos.length)
      return {
        good: true,
        bestIdx: bestIdx,
        bestUTXO: bestUTXO,                 // not really needed
        largestUtxoFunds: largestUtxoFunds, // not really needed
        satsSum: satsSum
      }
    } else {
      return { good: false }
    }
  }

  // process the multiple privKeys we retrieve
  // check their UTXOs. If they have enough funds, use them
  async handleRetrievedBsvKeys(us, results) {
    //console.log("FundsGetter handleRetrievedBsvKeys(): results: ", results)
    console.log("FundsGetter handleRetrievedBsvKeys(): there are " + results.length + " results/keys to inspect")

    // NOW is when we have the time to get the totalFunds for each address

    let keysToActuallyUse = []
    let goodCount = 0
    for ( let i in results ) {
      //console.log("FG hRBK(): Should look at key " + i + " of " + results.length + ": " + results[i])

      const privKeyX = new bsv.PrivateKey.fromWIF(results[i]) //.keyX)
      //console.log("privKey - derived from WIF: ", privKeyX.toString())
      const pubKeyX = bsv.PublicKey.fromPrivateKey( privKeyX )
      console.log("    FG hRBK(): pubKey - derived from privKey: ", pubKeyX.toString())

      const publicAddress = pubKeyX.toAddress().toString()
      console.log("    FG hRBK(): its public address: " + publicAddress)

      try {
        const utxos = await fetchUtxos(publicAddress, true, true, "handleRetrieveBsvKeys") //privateKey.toAddress());
        console.log("    FG hRBK(): Here are its utxos ", utxos)

        const utxoAnalysis = this.analyzeUTXOs(utxos)
        if ( utxoAnalysis.good ) {
          console.warn("    FG hRBK(): A keeper")
          keysToActuallyUse[ goodCount ] = {
              privKey: results[i],
              address: publicAddress,
              largestUtxoFunds: utxoAnalysis.largestUtxoFunds,
              satsSum: utxoAnalysis.satsSum,
              numUTXOs: utxos.length
          }
          goodCount++
        }

        // if there are a lot of results, we might flood the tx provider
        // with too many requests (too quickly)
        await mySleep(200, "    sleep after a UTXO fetch...")

      } catch (error) {
        console.error("Error. (Are we checking too many UTXOs too quickly? Should we pace ourselves slower?)")
        console.error("Error while fetching UTXOs (" + i + " of " + results.length + "): ", error)
        console.error("Skipping UTXOs for address " + publicAddress)
      }
    }

    console.warn("FG hRBK(): We found " + goodCount + " GOOD keys/addresses - from " +results.length + " we retrieved.") //Setting state ", keysToActuallyUse)

    if ( goodCount === 0 ) {
      alert("WARNING: we couldn't find any suitable funding.")
    } else if ( goodCount === 1 ) {
      console.warn("FG hRBK(): Selecting the only key/address - automatically.")
      //console.warn("FG HRK(): Setting parent privKey: " + )
      us.props.parent.setState({ bsvPrivKey: keysToActuallyUse[0].privKey,
                                chosenAddress: keysToActuallyUse[0].address,
                                bsvKeyIsSufficient: true
                              })

      us.setState({usableBsvKeys: keysToActuallyUse})
    } else {
      us.setState({usableBsvKeys: keysToActuallyUse})
    }
  }

  handleSaveBSVPrivKey() {
    console.log("FundsGetter handleSaveBSVPrivKey(): ...")

    // open BsvKeySave
    this.setState({openBsvKeySaverModal: true});
  }

  async handleBitcoinPrivKeyChange ( event, data ) {
      const field = data.field;
      const privKey = data.value;
      console.log("FundsGettter handleBitcoinPrivKeyChange(): field: " + field)
      //console.error("KSM() handleBitcoinPrivKeyChange(): value: " + privKey)

    /*
      // regex check for hex pattern
      const re = /^[a-fA-F0-9]+$/;
      //console.log("input: " + value)
      if ( value !== '' && !re.test(value)) {
          console.log("Ignoring value - doesn't match regex")
          return
      }
    */

      if ( field === 'bsvPrivKey' ) {
        // user has (started to?) specified the p key
        // IF we also have a q key, validate them together

        console.log("handleBitcoinPrivKeyChange: BTW: new privKey.length: " + privKey.length)
        //console.log("handleBitcoinPrivKeyChange: BTW: OLD/CURRENT bsvPrivKey: " + this.props.parent.state.bsvPrivKey)

        if ( privKey.length > 45 ) {
          console.log("This entered privKey is long enough. Will save it, and validate it...")

          let publicAddress
          try {
            const privKeyX = new bsv.PrivateKey.fromWIF(privKey)
            console.log("privKey - derived from WIF: ", privKeyX.toString())
            const pubKeyX = bsv.PublicKey.fromPrivateKey( privKeyX )
            console.log("pubKey - derived from privKey: ", pubKeyX.toString())
            const keyXP2PKH = bsv.crypto.Hash.sha256ripemd160(pubKeyX.toBuffer('hex')).toString('hex')
            console.log("Derived p2kh: ", keyXP2PKH, '\n');
            if ( keyXP2PKH.length !== 40 ) {
              throw new Error("58554: Length of derived P2PKH is " + keyXP2PKH.length +
                              " But expected 40!")
            }

            publicAddress = pubKeyX.toAddress().toString()
            console.log("Your public address: " + publicAddress)
          } catch (error) {
            this.props.parent.setState({bsvPrivKey: privKey, bsvKeyIsSufficient: false, chosenAddress: ''})
            this.setState({usableBsvKeys: null})
            console.warn("That private key doesn't look proper: ", error)
            //alert("That proper key doesn't seem proper. Please try a different key.")
            //FIXME: use a modal?
            return
          }

          const utxos = await fetchUtxos(publicAddress, true, true, "handleBitcoinPrivKeyChange")
          console.log("handleBitcoinPrivKeyChange(): Here are the utxos ", utxos)

          const utxoAnalysis = this.analyzeUTXOs(utxos)

          if ( utxoAnalysis.good ) {
            const keysToActuallyUse = []
            keysToActuallyUse[0] = {
              privKey: privKey,
              address: publicAddress,
              largestUtxoFunds: utxoAnalysis.largestUtxoFunds,
              satsSum: utxoAnalysis.satsSum,
              numUTXOs: utxos.length
            }
            console.log("FG HBPKC(): informing parent (KSM) of key info...")
            // Okay, we probably have enough funds. Save the key
            //FIXME: two setState()s in a row :(
            this.props.parent.setState({bsvPrivKey: privKey,
                                        bsvKeyIsSufficient: true,
                                        chosenAddress: publicAddress})
            this.setState({usableBsvKeys: keysToActuallyUse})
          } else {
            this.props.parent.setState({bsvPrivKey: privKey, bsvKeyIsSufficient: false, chosenAddress: ''})
            this.setState({usableBsvKeys: null})
            //FIXME: use a modal
            alert("It's not clear that the funds at this address (" + publicAddress + ") are enough. Please provide a different key.")
            return
          }

        } else {
          console.warn("keys isn't specified, or long enough. WON'T validate it yet")
          // bad or incomplete keys
          this.props.parent.setState({bsvPrivKey: privKey, bsvKeyIsSufficient: false, chosenAddress: ''})
          this.setState({usableBsvKeys: null})
        }
      } else {
        throw new Error("CODING ERROR 27445: invalid field: " + field)
      }
  }

  listClicked(event, idx) {
    console.warn("listClicked(): The list was CLICKED!")
    console.warn("listClicked(): event target: " + event.target)
    console.warn("listClicked(): event: ", event)
    console.warn("listClicked(): idx: " + idx)

    const bsvKeys = this.state.usableBsvKeys
    const theKey = bsvKeys[ idx ].privKey
    const theAddress = bsvKeys[ idx ].address

    console.log("listClicked(): Setting bsvPrivKey")
    this.props.parent.setState({bsvPrivKey: theKey, bsvKeyIsSufficient: true, chosenAddress: theAddress})
  }

  render() {  // FundsGetter
    const disableSave = this.props.parent.state.bsvPrivKey === '' || !this.props.parent.state.bsvKeyIsSufficient
    const bsvPrivKey = this.props.parent.state.bsvPrivKey;
    const chosenAddress = this.props.parent.state.chosenAddress;

    let disableGet = bsvPrivKey.length > 0
    //console.error("bsvPrivKey: " + bsvPrivKey)
    let labelForGet = "Get SAVED BSV Priv key";

    const bsvPrivKeySaver = this.state.openBsvKeySaverModal ?
                            <BsvKeySaver parent={this} p={bsvPrivKey} address={chosenAddress}/>
                                                : null;

    const bsvPrivKeyGetter = this.state.openBsvKeyRetrieverModal ?
                                  <BsvKeyRetriever parent={this}
                                                   addressOfKeyToGet=''
                                                   resultsHandler={this.handleRetrievedBsvKeys}
                                                   closeBsvRetriever={this.closeBsvRetrieverModal}/>
                                : null;

    const usableBsvKeys = this.state.usableBsvKeys
    //console.warn("FundsGetter render() usableBsvKeys: ", usableBsvKeys)
    const numUsable = usableBsvKeys ?
                        usableBsvKeys.length
                      :
                        'N/A'
    let keysList = ''
    if ( usableBsvKeys && usableBsvKeys.length > 0 ) {
      console.warn("FG render(): building a list of addresses... - length " + usableBsvKeys.length)
      let listArray=[]
      for ( let i = 0; i < usableBsvKeys.length; i++ ) {
        listArray[i] = {
                        address:  usableBsvKeys[i].address,
                        idx:      i,
                        funds:    usableBsvKeys[i].satsSum,
                        numUtxos: usableBsvKeys[i].numUTXOs
        }
        console.log("    FG render(): adding address " + usableBsvKeys[i].address)
      }
      keysList = listArray.map((key) =>
                      <li className="hoverLink" key={key.idx} onClick={ (event) => this.listClicked(event, key.idx)}> {key.funds}
                          &nbsp;sats at address <span style={{color: 'green'}}>{key.address}</span> &nbsp; (in {key.numUtxos} utxos)</li>
                );
      //console.warn("FG render(): Here's the list of clickable addresses: ", keysList)
    } else {
      console.warn("FG render(): currently, usableBsvKeys is now empty")
    }

    const addressMention = (usableBsvKeys && (usableBsvKeys.length > 0 )) ?
                            <> - with {usableBsvKeys[0].satsSum} sats in {usableBsvKeys[0].numUTXOs} utxos</>
                          :
                            <> </>


    const singleViableAddress = (usableBsvKeys && (usableBsvKeys.length === 1 ))
    const viableAddressesMention = !this.props.parent.state.bsvKeyIsSufficient ?
                                        usableBsvKeys ?
                                              singleViableAddress ?
                                                  <>
                                                    Single viable funding address: {this.state.usableBsvKeys[0].address} &nbsp; <span style={{color: 'blue'}}> Click Okay or Cancel.</span>
                                                  </>
                                                :
                                                  <>
                                                    There are {numUsable} viable funding addresses. &nbsp;<span style={{color: 'blue'}}>Please choose/click one.</span>
                                                    <br></br>
                                                    <ul>{keysList}</ul>
                                                  </>
                                            :
                                              <>
                                                Viable funding addresses: none so far
                                              </>
                                    :
                                        <>
                                          Retrieved address: {this.props.parent.state.chosenAddress} {addressMention}
                                        </>
    return (
      <Modal size='small' open={true}>
      <Modal.Header>Your transaction will need funding (BSV satoshis)</Modal.Header>
      <Modal.Content>
        You'll need to specify the private key to an UNSPENT Transaction Output (UTXO).
        You could either type one in, cut&amp;paste it, or retrieve a key you're already saved.
        <p></p>

        <Form >
              <Input fluid label='Private Key:' placeholder='Enter a Bitcoin Private Key'
                      value={bsvPrivKey} field='bsvPrivKey' onChange={ (event, data) => this.handleBitcoinPrivKeyChange(event, data) }/>

              <p></p>
              <div>Note that this Private key is NOT sent anywhere.</div>
              <div><strong>IF</strong> you've saved and encrypted the key <strong>for this P2PKH</strong>, you may be able to retrieve and decrypt it.</div>
              <p></p>

              <Button content={labelForGet}  disabled={disableGet}  positive onClick={this.handleGetBSVPrivKey}/>
              <Button content='SAVE this BSV private key' disabled={disableSave}  positive onClick={this.handleSaveBSVPrivKey}/>
              <p></p>
              <div><strong>WARNING:</strong> the browser could reclaim space at any point. <span style={{color:'red'}}>Be sure to save your keys outside of this tool.</span></div>
        </Form>

        <Divider />

        {viableAddressesMention}
        <p></p>

        <Button type='submit' content='Okay' positive onClick={this.closeUsDown} disabled={!this.props.parent.state.bsvKeyIsSufficient}/>
        <Button type='submit' content='CANCEL' negative onClick={this.clearFundingAndCancel}/>

        <Divider />
        {bsvPrivKeySaver}
        {bsvPrivKeyGetter}
      </Modal.Content>
    </Modal>
    )
  }
} // FundsGetter

class KeySolicitingModal extends React.Component {
  constructor(props) {
    super(props);
    this.clearFundingAddress = this.clearFundingAddress.bind(this);
    this.handleUseSavedKeys  = this.handleUseSavedKeys.bind(this);
    this.handleSaveTheseKeys = this.handleSaveTheseKeys.bind(this);
    this.closeRabinRetrieverModal = this.closeRabinRetrieverModal.bind(this);
    this.handleRetrievedRabinKeys = this.handleRetrievedRabinKeys.bind(this);
    this.handleGetFunds      = this.handleGetFunds.bind(this);

    this.handleRabinPrivKeyChange = this.handleRabinPrivKeyChange.bind(this);
    this.ksmCancel           = this.ksmCancel.bind(this);
    this.givePrivKeyToParent = this.givePrivKeyToParent.bind(this)

    this.state = {
                  keysAreValid: false,
                  pkhPrefetchSuccessful: false,

                  bsvPrivKey: '',
                  bsvKeyIsSufficient: false,
                  chosenAddress: '',

                  openSaverModal: false,
                  openRabinKeyRetrieverModal: false,
                  openFundingModal: false
                }
  }

  closeFundsGetter(us) {
    // close fundsGetter
    us.setState({openFundingModal: false});
  }

  clearFundingAddress(event) {
    event.preventDefault();

    console.log("KSM clearFundingAddress()")
    this.setState({bsvPrivKey: '', bsvKeyIsSufficient: false})
  }

  handleGetFunds(event) {

    // avoid reloading page
    event.preventDefault();

    // open fundsGetter
    this.setState({openFundingModal: true});
  }

  handleSaveTheseKeys(event) {
    // avoid reloading page
    event.preventDefault();

    // open RabinSaver
    this.setState({openSaverModal: true});
  }

  handleUseSavedKeys(event) {
    // avoid reloading page
    event.preventDefault();

    console.log("handleUseSavedKeys(): opening retriever modal");
    // open KeyRetriever
    this.setState({openRabinKeyRetrieverModal: true});
  }

  closeRabinRetrieverModal() {
    console.log("closeRabinRetrieverModal(): closing the retriever modal");
    this.setState({openRabinKeyRetrieverModal: false});
  }

  handleRetrievedRabinKeys(results) {
    //console.log("handleRetrievedRabinKeys(): p:", results.p);
    //console.log("handleRetrievedRabinKeys(): q:", results.q)

    let valid
    if ( validateRabinPrivateKeys( results.p, results.q, this.props.ownerRabinPKH) ) {
      // good keys
      console.log("HRK(): private keys match the 'owner' PKH")
      valid = true
    } else {
      // bad or incomplete keys
      console.log("HRK(): Whoops. The private keys don't match up with a participant")
      valid = false
    }

    this.setState({keysAreValid: valid})
    this.props.parent.setState({ rabinPrivKeyP: results.p, rabinPrivKeyQ: results.q })
  }

  async componentDidMount() {
    const pkh = this.props.ownerRabinPKH;
    console.log("KSM: componentDidMount() owner rabin to find: " + pkh)

    if ( pkh !== null ) {
      const result = await getEncryptedKeysFromPKH(await openDB(), pkh);
      this.setState({pkhPrefetchSuccessful: result !== null})

      console.log("KSM: componentDidMount() result querying for encrypted keys/BLOB of PKH " + pkh + ": ", result);
    } else {
      console.warn("KSM: componentDidMount():  pkh is null. Should we clear pkhPrefetchSuccessful ?")
      //console.warn( "KSM: componentDidMount():  value is " + this.state.pkhPrefetchSuccessful)
    }
  }

  //FIXME: do we even need 'object'?
  ksmCancel(event, object) {
    this.setState({keysAreValid: false, bsvKeyIsSufficient: false, bsvPrivKey: ''})
    console.log("ksmCancel(). triggering props.onCancel()...")
    this.props.onCancel(event, object)
  }

  handleRabinPrivKeyChange = ( event, data ) => {

    const field = data.field;
    const value = data.value;
    console.log("KSM() handleRabinPrivKeyChange(): field: " + field)
    //console.error("KSM() handleRabinPrivKeyChange(): value: " + value)

    // regex check for hex pattern
    const re = /^[a-fA-F0-9]+$/;
    //console.log("input: " + value)
    if ( value !== '' && !re.test(value)) {
        console.log("Ignoring rabin value - doesn't match regex")
        return
    }

    //console.log("handleRabinPrivKeyChange: BTW: parent rabinPrivKeyP: " + this.props.parent.state.rabinPrivKeyP)
    //console.log("handleRabinPrivKeyChange: BTW: parent rabinPrivKeyQ: " + this.props.parent.state.rabinPrivKeyQ)

    let validateThem = false
    let pToCheck = this.props.parent.state.rabinPrivKeyP
    let qToCheck = this.props.parent.state.rabinPrivKeyQ
    if ( field === 'rabinPrivKeyP' ) {
      // user has (started to?) specified the p key
      // IF we also have a q key, validate them together

      if ( value.length > 64 && this.props.parent.state.rabinPrivKeyQ.length > 64 ) {
        console.log("This entered P, and the already-specified Q are long enough. Will save this P, and now validate them both...")
        validateThem = true
      } else {
        console.warn("Both keys aren't specified, or long enough. WON'T validate them yet")
      }

      // regardless, save the change to the key
      //console.log("KSM handleRabinPrivKeyChange(): regardless, saving P: " + value)
      this.props.parent.setState({rabinPrivKeyP: value})
      pToCheck = value

    } else if ( field === 'rabinPrivKeyQ' ) {
        // user has (started to?) specified the q key
        // IF we also have a p key, validate them together

        if ( value.length > 64 && this.props.parent.state.rabinPrivKeyP.length > 64 ) {
          console.log("This entered Q, and the already-specified P are long enough. Will save this Q, and now validate them both...")
          validateThem = true
        } else {
          console.warn("Both keys aren't specified, or long enough. WON'T validate them yet")
        }

        // regardless, save the change to the key
        //console.log("KSM handleRabinPrivKeyChange(): regardless, saving Q: " + value)
        this.props.parent.setState({rabinPrivKeyQ: value})
        qToCheck = value

    } else {
      throw new Error("CODING ERROR 27444: invalid key field: " + field)
    }

    // validate the P and Q generate the proper PKH
    const pkh = this.props.ownerRabinPKH;
    console.log("Checking if they add up to " + pkh)

    if ( validateThem && validateRabinPrivateKeys( pToCheck, qToCheck, pkh) ) {
      // good keys
      this.setState({keysAreValid: true})
    } else {
      // bad or incomplete keys
      console.warn("Whoops. The keys don't add up - yet")
      this.setState({keysAreValid: false})
    }

    //console.log("\nKSM(): calling parent onInputChange()...")
    this.props.onInputChange(event, data)
  }

  givePrivKeyToParent() {
    //console.warn("KSM passing privKey " + this.state.bsvPrivKey + " to builder...")
    this.props.parent.setState({bsvPrivKey: this.state.bsvPrivKey})
  }

  render() { // KSM
    let addressToUse = ''
    if ( this.state.bsvPrivKey.length > 45 ) {
      try {
//FIXME: it would be good to have this calculation outside of render(), so we could setState it
        addressToUse = bsv.PublicKey.fromPrivateKey( new bsv.PrivateKey.fromWIF( this.state.bsvPrivKey ) ).toAddress().toString()
      } catch (error) {
      }
    }

    const bsvKeyIsSufficient = this.state.bsvKeyIsSufficient

    const isSaveRabinButtonDisabled = !this.state.keysAreValid
    const isFundingButtonDisabled = isSaveRabinButtonDisabled || addressToUse.length > 5
    const isBuildButtonDisabled = !bsvKeyIsSufficient

    const bsvAddressMention = bsvKeyIsSufficient ?
                <>
                  Funding address: {addressToUse}
                </>
              :
                <>
                  Please provide a funding address
                </>

    if ( typeof this.props.parent === 'undefined' ) {
        console.warn("skipping keyutils render, for now")
        return null
    }

    // WARNING: relying on any parent to have these state variables
    const p = this.props.parent.state.rabinPrivKeyP;
    const q = this.props.parent.state.rabinPrivKeyQ;

    let disableGet = p.length > 0 || q.length > 0;
    let labelForGet = "Get SAVED Rabin keys";
    if ( p.length === 0 && q.length === 0 ) {
      if ( !this.state.pkhPrefetchSuccessful ) {
        labelForGet = "No SAVED keys found";
        disableGet = true;
      }
    }

    const rabinSave = this.state.openSaverModal ?
                            <RabinSaver parent={this} p={p} q={q} pkh={this.props.ownerRabinPKH}/>
                                                : null;
    //console.log("openRabinKeyRetrieverModal is ", this.state.openRabinKeyRetrieverModal);
    const keyGetter = this.state.openRabinKeyRetrieverModal ?
                                  <RabinKeyRetriever pkh={this.props.ownerRabinPKH}
                                                resultsHandler={this.handleRetrievedRabinKeys}
                                                closeRetriever={this.closeRabinRetrieverModal}/>
                                                       : null;

    const fundsGetter = this.state.openFundingModal ?
                                  <FundsGetter parent={this} closeModal={this.closeFundsGetter}/>
                                          :
                                  null
    return (
      <>
        <Modal size='small' open={this.props.openSecondaryModal}>
          <Modal.Header>Private Rabin Keys are needed for signing your transaction</Modal.Header>
          <Modal.Content>
            To finish building this transaction you'll need to do TWO things:
            <p>
             &nbsp; &nbsp; 1 - Authorize the transaction (using Rabin Keys) <br></br>
             &nbsp; &nbsp; 2 - Fund the transaction (using Bitcoin P2PKH Keys)
            </p>
            <Divider />
            First, you'll authorize/sign it using the private Rabin keys
            corresponding to the <span style={{color:'blue'}}>registered Rabin PKH: {this.props.ownerRabinPKH}</span>
            <p></p>
            <div>Note that there are two parts to the private key - known as p, and q.</div>

            <Form onSubmit={this.props.onSubmit}>
              <Input fluid label='Rabin Private Key p:' placeholder='Enter the first part of the Rabin Private Key'
                      value={p} field='rabinPrivKeyP' onChange={ (event, data) => this.handleRabinPrivKeyChange(event, data) }/>

              <Input fluid label='Rabin Private Key q:' placeholder="Enter the second part of the Rabin Private Key"
                      value={q} field='rabinPrivKeyQ' onChange={ (event, data) => this.handleRabinPrivKeyChange(event, data) }/>
              <p></p>
              <div>These Private keys are NOT sent anywhere.</div>
              <div><strong>IF</strong> you've saved and encrypted the keys <strong>for this PKH</strong>, you may be able to retrieve and decrypt them.</div>
              <p></p>
              <Button content={labelForGet}  disabled={disableGet} type='submit' positive onClick={this.handleUseSavedKeys}/>
              <Button content='SAVE these Rabin keys' disabled={isSaveRabinButtonDisabled} type='submit' positive onClick={this.handleSaveTheseKeys}/>
              <p></p>
              <div><strong>WARNING:</strong> the browser could reclaim space at any point. <span style={{color:'red'}}>Be sure to save your keys outside of this tool.</span></div>
              <p></p>

              <Divider />
              Next, you'll provide funding for this transaction, by specifying a BSV Private Key.
              <Button content='Provide Bitcoin Funds' disabled={isFundingButtonDisabled}  positive onClick={this.handleGetFunds}/>
                  &nbsp; {bsvAddressMention}<br></br>
              <Button content='Clear Funding' disabled={!isFundingButtonDisabled || this.state.bsvPrivKey === ''}  negative onClick={this.clearFundingAddress}/>
              <Divider />
              <Button content='SIGN with these Private Keys' disabled={isBuildButtonDisabled} type='submit' positive onClick={this.givePrivKeyToParent}/>
              <Button field="cancelButton" onClick={ (event) => this.ksmCancel(event, this) } negative>CANCEL</Button>
            </Form>

          </Modal.Content>
        </Modal>

        {rabinSave}
        {keyGetter}
        {fundsGetter}

        <Modal size='small' open={this.props.openBadKeysModal}>
          <Modal.Header><span style={{color: 'red'}}>WRONG Rabin private keys for signing</span></Modal.Header>
          <Modal.Content>
            It looks like you've provided the wrong Rabin Private Keys. They don't match the required PKH.
            <p></p>
            <div>Please try again.</div>
            <p></p>
            <Button type='submit' content='Okay' positive onClick={this.props.closeThird}/>

          </Modal.Content>
        </Modal>
      </>
    );
  } // KeySolicitingModal render()
}

class BsvKeySaver extends React.Component {
  constructor(props) {
    super(props);
    this.handleBsvKeySaverSave   = this.handleBsvKeySaverSave.bind(this);
    this.handleBsvKeySaverCancel = this.handleBsvKeySaverCancel.bind(this);
    this.handlePwdChange        = this.handlePwdChange.bind(this);

    this.state = {openBsvKeySaverModal: true, pwd: ''};
  }

  async handleBsvKeySaverSave(event) {
    event.preventDefault();

    console.log("handleBsvKeySaverSave(): address is ", this.props.address);

    //console.log("pwd is ", this.state.pwd);

    const bundled = {p: this.props.p, address: this.props.address}
    const bundledPlainText = JSON.stringify(bundled);
    //console.log("bundled plain text is", bundledPlainText);

    const cyphText = await encryptData(bundledPlainText, this.state.pwd);
    console.log("cypher text is ", cyphText);
    //console.warn("That cypher/blob was generated with pwd " + this.state.pwd)

    var db = await openDB();
    await saveEncryptedP2PKHKey(db, this.props.address, cyphText);

    // close the pop-up
    this.props.parent.setState({openBsvKeySaverModal: false, pwd: ''});
  }

  handleBsvKeySaverCancel(event) {
    console.log("handleBsvKeySaverCancel()");

    event.preventDefault();

    // close the pop-up
    this.props.parent.setState({openBsvKeySaverModal: false, pwd: ''});
  }

  handlePwdChange(event) {
    //console.log("handlePwdChange(): pwd now set to ", event.target.value);

    this.setState({pwd: event.target.value});
  }

  render() {
    const p = this.props.p   //bsvPrivKey
    //console.log("BsvKeySaver render(): here's p: ", p)
    const pkh = this.props.pkh;
    const address = this.props.address;

    // ensure the password is long enough
    const disableSave = this.state.pwd.length < 8;

    return  <>
              <Modal size='small' open={this.state.openBsvKeySaverModal} dimmer='blurring'>
                <Modal.Header>SAVE BSV Private Key</Modal.Header>
                <Modal.Content>
                  There are a hundred ways in which this key could be lost. Be sure to back it up INDEPENDENT of this feature.
                  <p></p>
                  <div>Note that you should not share this Private key in any way.</div>
                  <div style={{overflowWrap: 'break-word'}}>
                    <Message style={{color:'blue'}}>BSV Private Key: <span style={{color:'green'}}>{p}</span></Message>
                  </div>
                  <p></p>
                  From it we can derive the Public Key Hash (PKH).
                  <Message style={{color:'blue'}}>PKH: <span style={{color:'green'}}>{pkh}</span></Message>
                  <br></br>
                  We can also derive the Address.
                  <Message style={{color:'blue'}}>Address: <span style={{color:'green'}}>{address}</span></Message>
                  <br></br>
                  We'll use a password to encrypt the key, and store it on THIS BROWSER, on THIS MACHINE only.
                  &nbsp;<span style={{color:'red'}}>IF it's ALREADY saved, saving it again, now, will OVERRIDE the previous password.</span>
                  <br></br>
                  <Form>
                     Please enter a strong password (min 8 chars): <Input style={{width:'350px'}} onChange={this.handlePwdChange}/> <br></br>
                    <Button disabled={disableSave} field="saveButton" onClick={this.handleBsvKeySaverSave} positive>SAVE this Key. I've written down my password.</Button>
                    <Button field="cancelButton" onClick={this.handleBsvKeySaverCancel} negative>CANCEL</Button>
                  </Form>

                  <p></p>
                  <div><strong>WARNING:</strong> this browser could reclaim space at any point.
                    <span style={{color:'red'}}> Be sure to save your keys outside of this tool BEFORE using them.
                    </span> You can EXPORT these keys to a file from the main page.</div>
                  <p></p>
                </Modal.Content>
              </Modal>
            </>;
  }
} // BsvKeySaver

class RabinSaver extends React.Component {
  constructor(props) {
    super(props);
    this.handleRabinSaverSave   = this.handleRabinSaverSave.bind(this);
    this.handleRabinSaverCancel = this.handleRabinSaverCancel.bind(this);
    this.handlePwdChange        = this.handlePwdChange.bind(this);

    this.state = {openRabinSaverModal: true, pwd: ''};
  }

  async handleRabinSaverSave(event) {
    event.preventDefault();

    //console.log("handleRabinSaverSave(): p is ", this.props.p);
    //console.log("handleRabinSaverSave(): q is ", this.props.q);
    console.log("handleRabinSaverSave(): pkh is ", this.props.pkh);

    //console.log("pwd is ", this.state.pwd);

    //FIXME: may not need pkh. A successful JSON.parse() (without exception) might suffice
    const bundled = {p: this.props.p.toString(), q: this.props.q.toString(), pkh: this.props.pkh}
    const bundledPlainText = JSON.stringify(bundled);
    //console.log("bundled plain text is", bundledPlainText);

    let cyphText
    try {
      cyphText = await encryptData(bundledPlainText, this.state.pwd);
    } catch (error) {
      alert("Please make sure you're using https://")
      throw error
    }
    console.log("cypher text is ", cyphText);

    var db = await openDB();
    await saveEncryptedKeys(db, this.props.pkh, cyphText);

    // close the pop-up
    this.props.parent.setState({openSaverModal: false, pwd: ''});
  }

  handleRabinSaverCancel(event) {
    console.log("handleRabinSaverCancel()");

    event.preventDefault();

    // close the pop-up
    this.props.parent.setState({openSaverModal: false, pwd: ''});
  }

  handlePwdChange(event) {
    //console.log("handlePwdChange(): pwd now set to ", event.target.value);

    this.setState({pwd: event.target.value});
  }

  render() {
    const p = this.props.p.toString();
    const q = this.props.q.toString();
    const pkh = this.props.pkh;

    // ensure the password is long enough
    const disableSave = this.state.pwd.length < 8;

    return  <>
              <Modal size='small' open={this.state.openRabinSaverModal} dimmer='blurring'>
                <Modal.Header>SAVE Rabin Private Keys</Modal.Header>
                <Modal.Content>
                  There are a hundred ways in which these keys could be lost. Be sure to back them up INDEPENDENT of this feature.
                  <p></p>
                  <div>Note that there are two parts to the private key - known as p, and q.</div>
                  <div style={{overflowWrap: 'break-word'}}>
                    <Message style={{color:'blue'}}>Rabin Private Key p: <span style={{color:'green'}}>{p}</span></Message>
                    <Message style={{color:'blue'}}>Rabin Private Key q: <span style={{color:'green'}}>{q}</span></Message>
                  </div>
                  <p></p>
                  Together they derive the Rabin Public Key Hash.
                  <Message style={{color:'blue'}}>Rabin PKH: <span style={{color:'green'}}>{pkh}</span></Message>
                  <br></br>
                  We'll use a password to encrypt the keys, and store them on THIS BROWSER, on THIS MACHINE only.
                  &nbsp;<span style={{color:'red'}}>IF they're ALREADY saved, saving them again, now, will OVERRIDE the previous password.</span>
                  <br></br>
                  <Form>
                     Please enter a strong password (min 8 chars): <Input style={{width:'350px'}} onChange={this.handlePwdChange}/> <br></br>
                    <Button disabled={disableSave} field="saveButton" onClick={this.handleRabinSaverSave} positive>SAVE these Keys. I've written down my password.</Button>
                    <Button field="cancelButton" onClick={this.handleRabinSaverCancel} negative>CANCEL</Button>
                  </Form>

                  <p></p>
                  <div><strong>WARNING:</strong> this browser could reclaim space at any point.
                    <span style={{color:'red'}}> Be sure to save your keys outside of this tool BEFORE using them.
                    </span> You can EXPORT these keys to a file from the main page.</div>
                  <p></p>
                </Modal.Content>
              </Modal>
            </>;
  }
} // RabinSaver

class RabinUtility extends React.Component {
  constructor(props) {
    super(props);

    this.state = {openRabinUtilityModal: false,
                  openSaverModal: false,
                  p: null, q: null, pkh: null};
    this.handleManageRabins         = this.handleManageRabins.bind(this);
    this.handleRabinUtilityCancel   = this.handleRabinUtilityCancel.bind(this);
    this.handleRabinUtilityGenerate = this.handleRabinUtilityGenerate.bind(this);
    this.handleRabinUtilityUse      = this.handleRabinUtilityUse.bind(this);
    this.handleRabinUtilitySave     = this.handleRabinUtilitySave.bind(this);
  }

  handleManageRabins(event) {
    console.log("handleManageRabins()")
    event.preventDefault();
    this.setState({openRabinUtilityModal: true})
  }

  // Closes the 'Manage Keys' pop-up
  handleRabinUtilityCancel(event) {
    console.log("RabinUtility handleRabinUtilityCancel()")
    event.preventDefault();

    // set p to null, so if/when user returns, we don't immediately enable the 'Use These' button
    this.setState({openRabinUtilityModal: false, p: null, q: null, pkh: null})
    //FIXME: hmm. should this be in handleManageRabins() instead?
  }

  handleRabinUtilityUse(event) {
    console.log("handleRabinUtilityUse()")
    event.preventDefault();

    if ( this.props.parent && this.props.parent !== null ) {
      // send results to parent
      handleGeneratedKeys(this.props.parent, this.state.p, this.state.q, this.state.pkh)
    } else {
      // for AppendBuilder
      this.props.useGeneratedKeys(this.state.p, this.state.q, this.state.pkh)
    }

    // set p to null, so if/when user returns, we don't immediately enable the 'Use These' button
    this.setState({openRabinUtilityModal: false, p: null, q: null, pkh: null})
    //FIXME: hmm. should this be in handleManageRabins() instead?
  }

  handleRabinUtilitySave(event) {
    console.log("handleRabinUtilitySave()")
    event.preventDefault();

    // open RabinSaver
    this.setState({openSaverModal: true});
  }

  handleRabinUtilityGenerate(event) {
    console.log("handleRabinUtilityGenerate()")
    const rabinPlus = generateRabinPrivateKeyPlus();

    console.log("generated: p = ", rabinPlus.p)
    console.log("generated: q = ", rabinPlus.q)

    //console.log("pubkeyBI: ", rabinPlus.rabinPubkeyBI)
    //console.log("pubkeyHex: ", rabinPlus.rabinPubkeyLE)

    console.log("rabinPKH: ", rabinPlus.rabinPKH);

    event.preventDefault();
    this.setState({ p: rabinPlus.p.toString(),
                    q: rabinPlus.q.toString(),
                    //pubkey: rabinPlus.rabinPubkeyBI.toString(),
                    pkh: rabinPlus.rabinPKH})
  }

  render() {
    // <div id='pk'>Rabin Public Key: {this.state.p}</div>
    // style={{ 'white-space': 'unset' }}
    const info = <>
                   <div style={{overflowWrap: 'break-word'}}>
                   <Message style={{color:'blue'}}>Rabin Private Key p: <span style={{color:'green'}}>{this.state.p}</span></Message>
                   <Message style={{color:'blue'}}>Rabin Private Key q: <span style={{color:'green'}}>{this.state.q}</span></Message>
                   </div>

                   <br></br>
                   Multiplied together, then hashed, they form a <strong>Public Key Hash</strong> (PKH) which you can use to declare ownership (publishing rights) over a 'limb'/asset:
                   <Message style={{color:'blue'}}>Rabin PKH: <span style={{color:'green'}}>{this.state.pkh}</span></Message>
                   <br></br>
                   You can't do anything without the private keys that correspond to the PKH of an asset. Protect them. Anyone possessing them could steal control of the asset away from you - permanently.
                   <br></br>
                 </>;
    const disableUse = this.state.p === null;

    const rabinSave = this.state.openSaverModal ? <RabinSaver parent={this} p={this.state.p} q={this.state.q} pkh={this.state.pkh}/> : null;

    return <>
            <Button disabled={this.props.disabled} content='Manage Keys' positive onClick={this.handleManageRabins}/>

            <Modal size='small' open={this.state.openRabinUtilityModal}>
              <Modal.Header>Manage Rabin Private Keys</Modal.Header>
              <Modal.Content>
                To build various transactions, you'll need to sign using private Rabin Keys. These keys should be generated randomly.
                <p></p>
                <div>Note that there are two parts to the private key - known as p, and q.</div>
                {info}
                <Form>
                <Button field="genButton" onClick={this.handleRabinUtilityGenerate} positive>Generate</Button>
                <Button field="useButton" disabled={disableUse} onClick={this.handleRabinUtilityUse} positive>Use this PKH. I've copied its p and q.</Button>
                <Button field="saveButton" disabled={disableUse} onClick={this.handleRabinUtilitySave} positive>Encrypt and SAVE these Keys</Button>
                <Button field="cancelButton" onClick={this.handleRabinUtilityCancel} negative>CANCEL</Button>
                </Form>
              </Modal.Content>
            </Modal>

            {rabinSave}
          </>;
  }
} // RabinUtility

export { KeySolicitingModal,
         BsvKeyRetriever,
         FundsGetter,
         RabinUtility
       };
