import React from 'react';

import { Button, Checkbox, Container, Divider, Dropdown, Form, Grid,
    Input, Menu, Message, Modal, Popup, Radio, TextArea } from 'semantic-ui-react'

// Loads chosen file into EITHER local placeholder,
// OR caller-specified (this.props.pplaceholder) location
//
// parent placeholder could be an invisible element like:
//   <pre id="parentContentPlaceHolder" style={{display:'none'}}></pre>
//FIXME: consider optional PREVIEW in modal?
class FileChooserReader extends React.Component {
  constructor(props) {
    super(props);

    this.handleContent = this.handleContent.bind(this);
    this.buf2hex       = this.buf2hex.bind(this);

    this.changeEventListener = this.changeEventListener.bind(this);

    this.state = {enableInspectButton: false,
                  invalidImportFile: false,
                  fileLen: null,
                  chosenFileName: ''};
  }

  // https://stackoverflow.com/questions/40031688/javascript-arraybuffer-to-hex
  buf2hex(buffer) { // buffer is an ArrayBuffer
    return [...new Uint8Array(buffer)]
        .map(x => x.toString(16).padStart(2, '0'))
        .join('');
  }

  componentWillUnmount() {
    document.getElementById('inputFileHolder').removeEventListener('change', this.changeEventListener);
  }

  // Loads file into a placeholder
  componentDidMount() {
    this.setState({enableInspectButton: false, invalidImportFile: false});

    //const parent = this;
    document.getElementById('inputFileHolder').addEventListener('change', this.changeEventListener);
  }

  changeEventListener(e) {
        const fileThing = e.currentTarget
        const myFileChooserReader = this

        const { files } = e.target;
        console.error("file holder event listener:  target files: ", e.target.files)
        console.error("file holder event listener:  files: ", files)
        console.error("file holder event listener:  #files: ", files.length)
        console.error("file holder event listener:  [0].name: ", files[0].name)
        console.error("file holder event listener:  [0].size: ", files[0].size)
        myFileChooserReader.setState({chosenFileName: files[0].name})
        // https://www.javascripttutorial.net/web-apis/javascript-filereader/
        // https://www.javascripttutorial.net/web-apis/javascript-filereader/
        let fr = new FileReader();
        fr.onload=function() {

          // Copy file contents to EITHER parent-specified pplaceholder
          //  (and setState() in this modal)
          //   OR
          // an invisible location in this modal
          let contentString
          if ( myFileChooserReader.props.pplaceholder ) {
            const hexString = myFileChooserReader.buf2hex(fr.result)
            console.error("We've read the content: ", fr.result)
            console.error("  array buffer has length: ", fr.result.byteLength)
            console.error("  as hex string: " + hexString)

            // copy the file to the parent element - presumably not shown
            myFileChooserReader.props.pplaceholder.textContent = hexString //fr.result;

            //contentString = parent.props.pplaceholder.innerHTML
            contentString = hexString
          } else {
            // copy the file to the parent element - presumably not shown
            document.getElementById('contentPlaceHolder').textContent = fr.result;
            contentString = document.getElementById('contentPlaceHolder').innerHTML;
          }
          console.log("You've chosen a file of length " + contentString.length/2);

          // If there is no placeholder, we might get no data
          if ( contentString.length/2 !== files[0].size ) {
            console.error("files[0].size: " + files[0].size)
            console.error("contentString.length: " + contentString.length/2)
            throw new Error("lengths don't match")
          }

          // DUPLICATE: we also setState() the contentString
          //FIXME: if too large (passed in as property, or use callback to determine?), set/keep invalid
          myFileChooserReader.setState({invalidImportFile: false, enableInspectButton: true, contentString: contentString, fileLen: contentString.length/2});

          //FIXME: add an optional callback property?
          //       It could even alter what's displayed
          //         the description
          //         the load/read button
          //         the INVALID text
        }

        // see also:
        //        readAsDataURL()
        //        readAsBinaryString()
        //        readAsText(this.files[0]);
        // On completion, onload function, above, gets called
        fr.readAsArrayBuffer(fileThing.files[0])
  };


  // user clicked OK (after file was actually read)
  // return file name, and content (as hex string)
  async handleContent() {

    if ( this.props.callback ) {
      // originally we stashed the text data in html
      // Is that better?
      const contentString = this.props.pplaceholder ?
                                  this.props.pplaceholder.innerHTML
                                :
                                  document.getElementById('contentPlaceHolder').innerHTML;

      //console.warn("FileChooserReader handleContents. now closing modal.   content: ", contentString)
      //await this.props.callback(this.state.chosenFileName, contentString)
      await this.props.callback(this.state.chosenFileName, this.state.contentString)
    }

    this.props.closeChooserModal();
  }

  render() {

    //FIXME: future: indicate how many keys are read

    //    early attempts
    //    <Button field="import" onClick={this.handleImport} positive>Import Password-Encrypted Keys</Button>
    //    <input type="file" accept=".txt" onChange={this.handleFileImport} />

    //FIXME: style the input button: https://www.w3docs.com/tools/code-editor/6961
    //       style, AND hide: https://stackoverflow.com/questions/36540105/how-to-style-a-choose-file-button-using-css-only

    const readButtonLabel = "USE/READ this file";
    const invalidWarning = this.state.invalidImportFile ?
                            <div style={{color: 'red'}}>Invalid file</div>
                            : null;
    const description = this.state.fileLen === null ?
                          "Choose a file to read"
                        :
                          "Chosen file has length " + this.state.fileLen
    return <>
              <Modal open={true} size='small' dimmer='blurring'>
                <Modal.Header>READ File</Modal.Header>
                <Modal.Content>
                  {description}
                  <p></p>
                  <input type="file" name="inputFileHolder" id="inputFileHolder" />
                  <Button field="inspect" disabled={!this.state.enableInspectButton} onClick={this.handleContent} positive> {readButtonLabel} </Button>
                  <Button field="cancelButton" onClick={this.props.closeChooserModal} negative> CANCEL </Button>
                  <p></p>
                  {invalidWarning}
                  <pre id="contentPlaceHolder" style={{display:'none'}}></pre>
                </Modal.Content>
              </Modal>
          </>
  }
} // KeyImporter

export default FileChooserReader;