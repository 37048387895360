"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var getOption_1 = require("./getOption");
function getClotheType(options, random) {
    var clotheType = [];
    if (getOption_1.getOption('clothes', 'blazer', options)) {
        clotheType.push('BlazerShirt', 'BlazerSweater');
    }
    if (getOption_1.getOption('clothes', 'sweater', options)) {
        clotheType.push('CollarSweater');
    }
    if (getOption_1.getOption('clothes', 'shirt', options)) {
        clotheType.push('GraphicShirt', 'ShirtCrewNeck', 'ShirtScoopNeck', 'ShirtVNeck');
    }
    if (getOption_1.getOption('clothes', 'hoodie', options)) {
        clotheType.push('Hoodie');
    }
    if (getOption_1.getOption('clothes', 'overall', options)) {
        clotheType.push('Overall');
    }
    return random.pickone(clotheType);
}
exports.getClotheType = getClotheType;
