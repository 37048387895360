import React from 'react';

import { Button, Checkbox, Container, Divider, Dropdown, Form,
        Header, Icon, Input, Message, Modal, Radio, Segment } from 'semantic-ui-react'

class CoolSites extends React.PureComponent {
    constructor(props) {
        super(props);

        this.BitSchnitzelLLC     = <>BitSchnitzel LLC</>;
        this.shizzleVerse        = <><b>ShizzleVerse</b><small>&trade;</small></>;
        this.bitShizzle          = <><b>BitShizzle</b><small>&trade;</small></>;
        this.bitShizzleItalic    = <><b>Bit<i>Shizzle</i></b><small>&trade;</small></>;
        this.shizzleVerseNotBold = <>ShizzleVerse<small>&trade;</small></>;
        this.bitShizzleNotBold   = <>BitShizzle<small>&trade;</small></>;

        //FIXME: the purples are currently duplicated in index.css
        this.bshzPurple             = '#8313e2';
        this.bshzYellow             = "#fff300";
        this.bshzLtPurp             = '#e7d0fb';
        this.bshzLightYellow        = "#fffccc";

        this.handleDDTypeChange     = this.handleDDTypeChange.bind(this);
        this.handleSiteChange       = this.handleSiteChange.bind(this);

        this.takeUpdatedFavorites   = this.takeUpdatedFavorites.bind(this);
        this.takeUpdatedRecents     = this.takeUpdatedRecents.bind(this);

        this.state = {
            coolSite: '',
            ddType: 3,

            arrayOfFaves: [],
            arrayOfRecents: [],
        }

        // Suggested, Recent, Favorites

        let i = 0
        this.siteOptions =
            [
                [
                    {
                        key: i,
                        value: i++,
                        text: "Announcements",
                    },
                    {
                        key: i,
                        value: i++,
                        text: "Bitcoin",
                    },
                    {
                        key: i,
                        value: i++,
                        text: "BitShizzle",
                    },
                    {
                        key: i,
                        value: i++,
                        text: "Feedback",
                    },
                    {
                        key: i,
                        value: i++,
                        text: "FreeThink",
                    },
                    {
                        key: i,
                        value: i++,
                        text: "Hashtag",
                    },
                    {
                        key: i,
                        value: i++,
                        text: "Ideas",
                    },
                    {
                        key: i,
                        value: i++,
                        text: "News",
                    },
                    {
                        key: i,
                        value: i++,
                        text: "Politics",
                    },
                    {
                        key: i,
                        value: i++,
                        text: "Predictions",
                    },
                ],
                [ ],    // Recents
                [ ],    // favorites
            ]
    }

    async componentDidMount() {
        this.props.registerCallbacks(   this.props.parent,
                                        this,                      // reference to coolSites
                                        this.takeUpdatedFavorites,
                                        this.takeUpdatedRecents)
    }

    componentWillUnmount() {
        console.log("coolSites about to unmount. ")

        // fix Warning: Can't perform a React state update on an unmounted component
        // see: https://stackoverflow.com/questions/53949393/cant-perform-a-react-state-update-on-an-unmounted-component
        this.setState = (state,callback)=>{
            return;
        };
    }

    handleDDTypeChange(e, {value}) {
        console.warn("handleDDTypeChange(): value: ", value)
        this.setState({ddType: value, coolSite: ''})

        if ( value === 3 ) {
            this.props.categorySetCallback(true)
        } else {
            this.props.categorySetCallback(false)
        }
    }

    handleSiteChange(e, v) {

        if ( e.target.className === 'search' ) {
            console.warn("IGNORING search change")
            return
        }

        console.log("handleSiteChange():  domain option: ", v.value)
        console.log("handleSiteChange():  domain ddType: ", this.state.ddType)

        console.log("Setting state.coolSite to '" + this.siteOptions[this.state.ddType][v.value].text + "'")
        this.setState({coolSite: this.siteOptions[this.state.ddType][v.value].text})

    }

    takeUpdatedFavorites(me, arrayOfFaves) {
        console.warn("takeUpdatedFavorites(): new faves: ", arrayOfFaves)

        // note: as long as we're getting a new array OBJECT,
        // we'll re-render
        me.setState({arrayOfFaves: arrayOfFaves})
    }

    takeUpdatedRecents(me, arrayOfRecents) {
        console.warn("takeUpdatedRecents(): new recents: ", arrayOfRecents)

        let currentSite = null
        const arrayLen = arrayOfRecents.length
        console.log("length ; " + arrayLen)

        // the CURRENT site is actually the final element in the array of 'recents'
        if ( arrayOfRecents !== null && arrayLen > 0 ) {
            currentSite = arrayOfRecents[ arrayLen - 1 ]
            //alert("current site: " + currentSite)
        }

        // note: as long as we're getting a new array OBJECT here,
        // we'll re-render
        me.setState({arrayOfRecents: arrayOfRecents,
                     currentSite: currentSite
                    })
    }

    render() {
        console.warn("coolSites  render()")

        // NOTE the length-1 (we leave off the CURRENT site)
        this.siteOptions[1] = []
        for ( let j = 0; j < this.state.arrayOfRecents?.length -1; j++ ) {
            this.siteOptions[1][j] = {
                                        key: j,
                                        value: j,
                                        text: this.state.arrayOfRecents[j]
                                     }
            //console.log("Adding recent option: " + this.state.arrayOfRecents[j])
        }

        // This can be updated dynamically
        this.siteOptions[2] = []
        for ( let j = 0; j < this.state.arrayOfFaves?.length; j++ ) {
            this.siteOptions[2][j] = {
                                        key: j,
                                        value: j,
                                        text: this.state.arrayOfFaves[j]
                                     }
            //console.log("Adding FAVORITE option [" + j + "]: " + this.state.arrayOfFaves[j])
        }

        let ddType = this.state.ddType

        const dropdownType = ddType ===  0 ?
                    <>Suggested Sites</>
                :
                    (
                        ddType === 1 ?
                            <>Recent Sites</>
                    :
                            (ddType === 2 ?
                                <>Favorite Sites</>
                            :
                                <></>
                            )
                    )
        const dropDownColor = ddType === 0 ?
                        "green"
                    :
                        (
                            ddType === 1 ?
                                "purple"
                            :
                                (ddType === 2 ?
                                    "#ff9916" //"orange"
                                :
                                    "#000000"
                                )
                        )
        const dropDownHeading = ddType === 3 ?
                        <></>
                    :
                        <>
                            <b style={{color:dropDownColor, fontSize:"1.3rem",padding: "0px 10px 0px 10px"}}>{dropdownType}</b>
                            <br></br>
                        </>
        const dropDownBgColor = ddType === 0 ?
                        "lightGreen"
                    :
                        (
                            ddType === 1 ?
                                "pink"
                            :
                                (ddType === 2 ?
                                    "#FFe89c"  // light-orange
                                :
                                    "#d0d0d0"
                                )
                        )

        const dropDownPlaceholder = this.state.coolSite === '' ?
                                "choose"
                            :
                                this.state.coolSite
        const theDropdown =
                ddType === 3 ?
                <></>
            :
                <>
                    <Dropdown upward placeholder={dropDownPlaceholder}
                        search={true}
                            selection

                        value={this.state.coolSite}
                            options={this.siteOptions[ddType]}
                            onChange={this.handleSiteChange}
                            style={{backgroundColor:dropDownBgColor}}
                    />
                </>

        const goButton = this.state.ddType === 3 ?
                        <></>
                    :
                        <>
                            <Button disabled={this.state.coolSite === '' || this.state.coolSite === this.state.currentSite}
                                    style={{backgroundColor:dropDownColor, color:"white"}}
                                    onClick={() => this.props.goToSite( this.state.coolSite )}>
                                GO
                            </Button>
                        </>
        const upperPart = this.state.ddType === 3 ?
                        <></>
                    :
                        <>
                            <div style={{padding: "0px 10px 0px 10px"}}>
                                {theDropdown}
                                {goButton}
                            </div>
                        </>
        //<div style={{padding: "0px 10px 0px 10px"}}>
        return(
                <>
                    {dropDownHeading}

                    {upperPart}

                    <div style={{padding: "0px 10px 0px 10px"}}>
                    <Form style={{padding: '5px 0px 5px 0px'}}>
                        <Form.Group inline >

                        <Form.Field
                            control={Radio}
                            label='Elsewhere'
                            value={3}
                            checked={ddType === 3}
                            onChange={this.handleDDTypeChange}
                        />

                        <Form.Field
                            control={Radio}
                            label='Favorites'
                            value={2}
                            checked={ddType === 2}
                            onChange={this.handleDDTypeChange}
                        />
                        <Form.Field
                            control={Radio}
                            label='Recent'
                            value={1}
                            checked={ddType === 1}
                            onChange={this.handleDDTypeChange}
                        />
                        <Form.Field
                            control={Radio}
                            label='Suggested'
                            value={0}
                            checked={ddType === 0}
                            onChange={this.handleDDTypeChange}
                        />
                        </Form.Group>
                    </Form>
                    </div>
                </>
        )
    }
}

export default CoolSites;
