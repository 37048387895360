import React from 'react';

import './index.css';

import { Button, Icon, Modal, Table, TableBody, TableRow } from 'semantic-ui-react';


// Load a local photo FILE
// reader portion adapted from walletImporter?
class PhotoLoader extends React.Component {
  constructor(props) {
    super(props);

    this.photoReaderFunc  = this.photoReaderFunc.bind(this);
    this.setImageRef          = this.setImageRef.bind(this);

    this.returnPhotoToParent   = this.returnPhotoToParent.bind(this);
    this.removePhotoFromParent = this.removePhotoFromParent.bind(this);

    this.setExisting           = this.setExisting.bind(this);

    this.useCamera             = this.useCamera.bind(this);
    this.usePicture            = this.usePicture.bind(this);

    this.getCameraPermission   = this.getCameraPermission.bind(this);
    this.setupPictureThings    = this.setupPictureThings.bind(this);

    this.snapPhoto             = this.snapPhoto.bind(this);
    this.receiveSnapshotBlob   = this.receiveSnapshotBlob.bind(this);

    //FIXME: the purples are currently duplicated in index.css
    this.bshzPurple             = '#8313e2';
    this.bshzYellow             = "#fff300";
    this.bshzLtPurp             = '#e7d0fb';
    this.bshzLightYellow        = "#fffccc";

    this.liveVideoFeed = React.createRef()
    this.canvas = null
    this.canvasContext = null

    this.state = {
                  invalidPhotoFile: false,

                  imageRef: null,

                  photoURL: null,
                  photoBlob: null,
                  photoLoaded: false,

                  existingFileName: null,
                  existingFileDescription: <>&nbsp;</>,

                  mode: 'cam',  // or 'pic'
                  videoStream: null,

                  canvasContext: null,
                };
  }

  componentWillUnmount() {
    if ( this.state.mode === 'pic' ) {

      document.getElementById('inputFileHolder')?.removeEventListener('change', this.photoReaderFunc)
    } else {

      this.state.videoStream?.getVideoTracks()[0].stop()
      this.setState({videoStream: null})
    }
  }

  async getCameraPermission() {
    const videoStream = await navigator.mediaDevices.getUserMedia({ video: true, audio: false })
    this.setState({videoStream: videoStream})

    //FIXME: detect when stream starts - to help avoid potential race conditions starting/stopping cam?
    this.liveVideoFeed.current.srcObject = videoStream

    // Get the canvas and obtain a context for
    // drawing in it
    const canvas = document.getElementById("photoCanvas");
    const ctx = canvas.getContext('2d');
    this.setState({canvasContext: ctx})
    this.canvas = canvas
    this.canvasContext = ctx

    if ( this.state.photoBlob !== null ) {
      console.warn("There's an EXISTING photoBlob. FIXME: fill canvas with it")
      const baseImage = new Image()
      baseImage.src = this.state.photoURL

      const ctx = this.canvasContext
      console.warn("BEFORE image: ctx: ", ctx)

      // see: https://stackoverflow.com/questions/6011378/how-to-add-image-to-canvas
      baseImage.onload = function() {
          console.warn("INSIDE image: ctx: ", ctx)
          ctx.drawImage(baseImage, 0,0, ctx.canvas.width, ctx.canvas.height)
      }
    } else {
      this.canvasContext.fillStyle = "#AAA";
      this.canvasContext.fillRect(0, 0, canvas.width, canvas.height);
    }
  }

  componentDidMount() {
    this.setState({ invalidPhotoFile: false});

    if (  this.props.existingPhotoUrl !== null ||
          this.props.existingPhotoBlob !== null) {
      console.warn("photoLoader: MAYBE we were passed an EXISTING filename: " + this.props.existingPhotoName)
      console.warn("photoLoader: MAYBE we were passed an EXISTING photo URL: " + this.props.existingPhotoUrl)
      console.warn("photoLoader: MAYBE we were passed an EXISTING photo Blob: ", this.props.existingPhotoBlob)

      this.setExisting()

      // use default mode - UNLESS user has previously loaded a picture file
      const modeToUse = this.props.existingPhotoName !== null ? 'pic' : this.state.mode
      const funcToUse = modeToUse === 'pic' ? this.setupPictureThings : this.getCameraPermission

      this.setState({ mode: modeToUse,
                      photoBlob: this.props.existingPhotoBlob,
                      photoURL: this.props.existingPhotoUrl,
                      photoLoaded: true
                    }, funcToUse
      )
    } else if ( this.state.mode === 'cam' ) {
      this.getCameraPermission()
    } else {
      this.setupPictureThings()
    }
  }

  async receiveSnapshotBlob(snapshotBlob) {
    console.warn("Got snapshot blob: ", snapshotBlob)

    // similar to part of photoReaderFunc()
    const arrayView = new Uint8Array(await snapshotBlob.arrayBuffer());

    const photoUrl = URL.createObjectURL(snapshotBlob);
    console.log("Here's our photo url: ", photoUrl)

    this.setState({ photoURL: photoUrl, photoBlob: arrayView})
  }

  snapPhoto() {
    //const canvas = this.state.canvasContext
    const canvasCtx = this.canvasContext

    console.warn("canvas context: ", this.canvasContext)
    console.warn("canvas.canvas: ", this.canvasContext.canvas)
    console.warn("canvas context.width: ", this.canvasContext.canvas.width)

    // Draws current image from the video element into the canvas
    //canvas.drawImage(this.state.videoStream.getVideoTracks()[0], 0,0, canvas.width, canvas.height);
    canvasCtx.drawImage(this.liveVideoFeed.current, 0,0, this.canvasContext.canvas.width, this.canvasContext.canvas.height);

    // see: https://developer.mozilla.org/en-US/docs/Web/API/HTMLCanvasElement/toDataURL
    //const data = this.canvas.toDataURL("image/png");

    // for blob, see: https://developer.mozilla.org/en-US/docs/Web/API/HTMLCanvasElement/toBlob
    this.canvas.toBlob(this.receiveSnapshotBlob, "image/png" /*, qual*/);
  }
  useCamera() {
    console.warn("useCamera:")

    document.getElementById('inputFileHolder')?.removeEventListener('change', this.photoReaderFunc)

    this.setState({mode: 'cam'}, this.getCameraPermission)
  }
  setupPictureThings() {
    this.setState({canvasContext: null})
    document.getElementById('inputFileHolder').addEventListener('change', this.photoReaderFunc)
  }
  usePicture() {
    console.warn("usePicture:")

    //this.liveVideoFeed.current.srcObject.stop()

    this.state.videoStream?.getVideoTracks()[0].stop() //
    this.setState({videoStream: null})

    this.setState({mode: 'pic'},
      this.setupPictureThings
    )
  }

  setExisting() {
    const existingDescription =
    <>
      You've set a photo to use, but, you may elect to remove, then change it.
      <br></br>
    </>
    this.setState({ existingFileDescription: existingDescription })
  }

  // called when photo file loaded
  photoReaderFunc(e) {
    const parent = e.currentTarget
    const myWalletImporter = this
    const fr = new FileReader();

    fr.addEventListener('progress', (event) => {
      if (event.loaded && event.total) {
        const percent = (event.loaded / event.total) * 100;
        console.log(`LOADER:  Progress: ${Math.round(percent)}`);
      }
    });

    const me = this

    fr.onload=function(event){

        const result = event.target.result;
        console.warn("LOADER:  BTW: result: ", result)
        console.warn("LOADER:  BTW: fr.result: ", fr.result)
        console.warn("LOADER: length of fr.result: ", fr.result.byteLength)
        console.warn("LOADER:  BTW: event.target: ", event.target)

        // PLACE the contents we've read into a 'nook' which we might not actually use
    //    document.getElementById('contentPlaceHolder').textContent = fr.result;
    //    const rawPhotoFile = document.getElementById('contentPlaceHolder').innerHTML;

        me.setState({photoURL: null, photoBlob: null}, function() {
            console.warn("We've CLEARED the photoURL, because we're about to TRY to load a new photo.")

            try {

              let arrayView = new Uint8Array(fr.result);

              // Is this the best mimeType to use?
              const photoBlob = new Blob([arrayView], { type: 'image/png'})   // URL also plays fine for:
                                                                              //   'video/mp4;codecs=avc1'
                                                                              //   'video/webm'
                                                                              //   'video/ogg'
              console.log("Here's our new photoBlob: ", photoBlob)

              const photoUrl = URL.createObjectURL(photoBlob);
              console.log("Here's our photo url: ", photoUrl)

              me.setState({ photoURL: photoUrl, photoBlob: arrayView})

            } catch (error) {
              console.warn("LOADER: error while trying to load photo: ", error)
              console.warn("LOADER:  FAILED to load photo file of length: ", fr.result.byteLength)
            }
          }
        )
    }

    //fr.readAsText(parent.files[0]);
    //fr.readAsDataURL(parent.files[0]);      // results in base64 data
    fr.readAsArrayBuffer(parent.files[0]);  // looks like we could build an URL from this
    //fr.readAsBinaryString(parent.files[0]);   // displays as ascii
  }

  setImageRef(element) {
    console.warn("setImageRef(): refV: ", element)
    console.warn("setImageRef(): state.photoURL: ", this.state.photoURL)

    this.setState({ imageRef: element }, () => {
      console.warn("state.photoURL:", this.state.photoURL)
      console.warn("state.imageRef:", this.state.imageRef)

      if (this.state.imageRef !== null) {
        this.state.imageRef.src = this.state.photoURL

        console.log("we did it! We set player.src to " + this.state.photoURL)
      }
    })
  };

  returnPhotoToParent() {
    console.log("LOADER: about to return photo to parent.")

    const fileName = document.getElementById('inputFileHolder')?.value
    console.log("will use file by name of: ", fileName)

    this.props.returnPhoto(this.state.photoURL, this.state.photoBlob, fileName)

    this.setExisting()
    this.setState({ photoLoaded: true})
  }

  removePhotoFromParent() {
    console.log("LOADER: about to REMOVE photo from parent.")

    if ( this.state.mode === 'pic' ) {
      document.getElementById('inputFileHolder').value = null
    } else {
      console.warn("will now blank the canvas")
      this.canvasContext.fillStyle = "#AAA";
      this.canvasContext.fillRect(0, 0, this.canvas.width, this.canvas.height);
    }

    this.props.removePhoto()
    this.setState({ photoLoaded: false, photoURL: null, photoBlob: null, existingFileDescription: <>&nbsp;</>})
  }

  render() {

    //FIXME: Copied from onramp.js.   can we share this somehow?
    // ***  DEVICE-DEPENDENT STYLING  ***
    // For mobile, "detect" landscape screen mode
    const landscape = this.props.isMobile ? window.innerWidth > window.innerHeight : false
    // For mobile, shrink the About, Security, and Glossary modals
    const modalClassName   = this.props.isMobile ?
                                    (landscape ?
                                            "modalMobileLandscape"
                                        :
                                            "modalMobilePortrait"
                                    )
                                :
                                    ""
    const modalContentClassName = this.props.isMobile ? "modalContentMobile" : ""
    const modalBottomClassName  = this.props.isMobile ? "modalBottomMobile"  : ""

    const invalidWarning = this.state.invalidPhotoFile ?
                            <div style={{color: 'red'}}>Invalid photo file</div>
                            : null;

    const disableTheUseButton = this.state.photoLoaded || this.state.photoURL === null
    const disableTheRemoveButton = !this.state.photoLoaded
    const disableTheSnapButton = this.state.photoLoaded || this.state.photoURL === null
    const maybeRemovalButton =
                          <>
                              <Button negative disabled={disableTheRemoveButton}
                                      onClick={this.removePhotoFromParent}>
                                REMOVE photo
                              </Button>
                          </>

    const doneIsPositive = this.state.photoLoaded && this.state.photoURL !== null

    const maybeExistingFile = this.state.existingFileDescription

    const maybeHideInputButton = this.state.photoLoaded || this.state.photoURL !== null ? "none" : 'block'
    //console.log("\n\nHIDE INPUT BUTTON: ", maybeHideInputButton)

    const doneButtonLabel = doneIsPositive ? "DONE (use this photo)" : "DONE (no photo)"

    const cameraColor  = this.state.mode === 'cam' ? 'blue' : '#a8a8a8'
    const photoColor   = this.state.mode === 'pic' ? 'blue' : '#a8a8a8'

    const picBorder = this.state.photoLoaded ? '4px solid yellow' : ''
    const photoBox = <>
                      <br></br>
                      <input type="file" name="inputFileHolder" id="inputFileHolder" style={{display: maybeHideInputButton}}/>
                      {invalidWarning}
                      {!this.state.photoURL ?
                              <>
                                &nbsp;
                                <img  width='320' height='240'
                                        ref={this.setImageRef} >
                                </img>
                                <br></br>
                              </>
                          :
                              <>
                                <img  width='320' height='240' style={{border:picBorder}}
                                        ref={this.setImageRef} >
                                </img>
                                <br></br>

                              </>
                      }
                      <br></br>
                    </>
    const camBox = <>
                    <Table collapsing style={{border:'4px solid blue', backgroundColor:'white'}}>
                      <TableBody>
                        <TableRow>
                          <Table.Cell collapsing style={{border:'0px', margin: '0px', padding:'0px'}}>
                            <video 	width='320' height='240'
                                ref={this.liveVideoFeed}
                                style={{display: 'block'}}
                                autoPlay className="live-player">
                            </video>
                          </Table.Cell>
                          <Table.Cell collapsing style={{border:'0px', margin: '0px', padding:'0px'}}>
                            <canvas  id="photoCanvas" width="320" height="240" style={{border:picBorder}}></canvas>
                          </Table.Cell>
                        </TableRow>
                      </TableBody>
                    </Table>

                    <Button positive disabled={this.state.photoLoaded} onClick={this.snapPhoto}>
                          Snap a photo
                    </Button>

                  </>
    const cameraOrPhotoBox = (this.state.mode === 'pic') ? photoBox : camBox

    // note: we no longer use this to stash photo:
    // <pre id="contentPlaceHolder" style={{display:'none'}}></pre>

    //FIXME: consider TRYIHG to change the label of the <input>:
    //       see:  https://stackoverflow.com/questions/1944267/how-to-change-the-button-text-of-input-type-file
    //

    // BTW: inputFileHolder.value: {document.getElementById('inputFileHolder')?.value}
    // NOTE how we force re-load of <img> when photoURL blanks, or is set (from blank)

    //WARNING: very subtle clause below:    {!this.state.photoURL ? ....
    return  <>
             <Modal  dimmer='blurring' size='large' centered className={modalClassName} open={true}
                      style={{backgroundColor: this.bshzPurple, borderRadius: '20px', height: "auto"}}>

                <Modal.Header style={{textAlign: 'center', backgroundColor: this.bshzPurple, borderRadius: '20px'}}>
                  <span style={{color: this.bshzYellow}}> Load a Photo </span>
                </Modal.Header>

                <Modal.Content className={modalContentClassName} scrolling style={{backgroundColor: this.bshzLtPurp}}>
                  <p>
                    Use this to include a photo with your post.
                  </p>
                  <Icon name='picture' size='large' onClick={this.usePicture} style={{color:photoColor}}/> &nbsp;
                  <Icon name='camera' size='large' onClick={this.useCamera} style={{color:cameraColor}}/>

                  <br></br>

                  {maybeExistingFile}
                  {cameraOrPhotoBox}
                  <Button positive disabled={disableTheUseButton} onClick={this.returnPhotoToParent}>
                          Use this photo
                  </Button>
                  {maybeRemovalButton}

                  <br></br>
                  <div style={{textAlign: 'center'}}>
                    <Button positive={doneIsPositive} negative={!doneIsPositive} onClick={this.props.closeLoader} content={doneButtonLabel}/>
                  </div>
                </Modal.Content>

						  </Modal>
            </>
  }
} // PhotoLoader

export default PhotoLoader;
