"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var getOption_1 = require("./getOption");
function getFacialHairType(options, random) {
    var facialHairType = [];
    if (getOption_1.getOption('facialHair', 'medium', options)) {
        facialHairType.push('BeardMedium');
    }
    if (getOption_1.getOption('facialHair', 'light', options)) {
        facialHairType.push('BeardLight');
    }
    if (getOption_1.getOption('facialHair', 'majestic', options)) {
        facialHairType.push('BeardMajestic');
    }
    if (getOption_1.getOption('facialHair', 'fancy', options)) {
        facialHairType.push('MoustacheFancy');
    }
    if (getOption_1.getOption('facialHair', 'magnum', options)) {
        facialHairType.push('MoustacheMagnum');
    }
    var pickedFacialHairType = random.pickone(facialHairType);
    if (false === random.bool(undefined !== options.facialHairChance ? options.facialHairChance : 10)) {
        return 'Blank';
    }
    return pickedFacialHairType;
}
exports.getFacialHairType = getFacialHairType;
