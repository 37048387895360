//NOTE: always match with contract param
const REFUND_DELAY1 = 200;
const REFUND_DELAY2 = 6;
const REFUND_DELAY_FOR_NO_RESPONSE = 144 * 14;
const REFUND_DELAY_FOR_VSLOW_RESPONSE = 144 * 6;
const REFUND_DELAY_FOR_SLOW_RESPONSE = 144 * 3;


//export /* */
function appendBalk( balkArray, refundPKH, earliestRefund, funds ) {
    const numBalks = balkArray.length;
    balkArray[numBalks] = {
        refundPKH: refundPKH,
        earliestRefundBlock: earliestRefund,
        funds: funds
    }

    return balkArray;
}

//export /* */
function removeBalk( balkArray, index ) {
    const numBalks = balkArray.length;
    var newBalkArray = [];
    var j = 0
    for( var i = 0; i < numBalks; i++ ) {
        if ( i === index ) {
            // we skip this balk/entry
        } else {
            newBalkArray[j] = balkArray[i];
            j++
        }
    }

    return newBalkArray;
}

// adds DELAY1 to the currentBlock - to calculate the earliest refund
//FIXME: if there are already MAX bids, must REPLACE the worst bid
//FIXME: BUT, if bid is too low (lower than worstBid), this should fail
//FIXME: this, and modBid() should ensure we don't match an existing bid
//export /* */
function appendBid( bidArray, price, rabinPKH, refundPKH, currentBlock, MAX_BIDS ) {
    const earliestToWithdraw = currentBlock + REFUND_DELAY1
    const numBids = bidArray.length;
    var replacementIndex = -1
    var replacedEntry

    // scan for duplicate bid/price
    for( var i = 0; i < numBids; i++ ) {
        if ( bidArray[i].price === price ) {
            console.log('ERROR: bid of ' + price + ' already exists. Please choose a different price')
            throw new Error("17306: invalid bid. already exists"); //exit()
        }
    }

    // append or eject/replace?
    if ( numBids < MAX_BIDS ) {
        bidArray[numBids] = {
            price: price,
            rabinPKH: rabinPKH,
            refundPKH: refundPKH,
            earliestRefundBlock: earliestToWithdraw,
            reserveFunds: 0
        }
    } else {
        // identify the worst bid
        let worstIndex = getWorstBidIndex( bidArray )
        let worstPrice = bidArray[worstIndex].price
        // ensure this bid is better
        if ( price < worstPrice ) {
            console.log('ERROR: There are already MAX (' + MAX_BIDS
                    + ') bids. You must bid higher than the current lowest bid: ' + worstPrice)
            throw new Error("17307: invalid bid. Too low"); //exit()
        }

        replacedEntry = bidArray[worstIndex]
        console.log('\nWe already have ' + MAX_BIDS + ' bids. We\'ll be refunding bid at index '
                + worstIndex + ' to make room for this one.')
        // replace the worst bid
        bidArray = replaceBid(bidArray, worstIndex, price, rabinPKH, refundPKH, earliestToWithdraw, 0)
    }

    return {
        bidEntries: bidArray,
        replacedEntry: replacedEntry
    };
}

//export /* */
function removeBid( bidArray, index ) {
    const numBids = bidArray.length;
    var newBidArray = [];
    var j = 0;
    for( var i = 0; i < numBids; i++ ) {
        if ( i === index ) {
            // we skip this bid/entry
        } else {
            newBidArray[j] = bidArray[i];
            j++
        }
    }

    return newBidArray;
}

//export /* */
function replaceBid( bidArray, index, price, rabinPKH, refundPKH, earliest, reserves ) {

    //FIXME: can we just alter the array that's been passed in?

    const numBids = bidArray.length;
    var newBidArray = [];
    for( var i = 0; i < numBids; i++ ) {
        if ( i !== index ) {
            newBidArray[i] = bidArray[i];
        } else {
            newBidArray[i] = {
                price: price,
                rabinPKH: rabinPKH,
                refundPKH: refundPKH,
                earliestRefundBlock: earliest,
                reserveFunds: reserves
            }
        }
    }

    return newBidArray;
}

//export /* */
function modifyBid( bidArray, index, newPrice, currentBlock ) {

    //FIXME: can we just alter the array that's been passed in?

    const numBids = bidArray.length;
    var newBidArray = [];
    for( var i = 0; i < numBids; i++ ) {
        if ( bidArray[i].price === newPrice ) {
            console.log('ERROR: bid of ' + newPrice + ' already exists. Please choose a different price')
            throw new Error("17308: invalid bid. Already exists."); //exit()
        }
        if ( i !== index ) {
            newBidArray[i] = bidArray[i];
        } else {
            const oldEarliest = bidArray[i].earliestRefundBlock
            const oldReserves = bidArray[i].reserveFunds
            const oldPrice = bidArray[i].price

            const totalFunds = oldReserves + oldPrice
            const priceIncrease = newPrice - oldPrice

            var newEarliest = oldEarliest
            var newReserves = oldReserves
            if ( priceIncrease > 0 ) {
                //newEarliest += REFUND_DELAY1
                newEarliest = currentBlock + REFUND_DELAY1
                console.log("Note: bumping earliestRefundBlock by " + REFUND_DELAY1 + " blocks")
                if ( priceIncrease > oldReserves ) {
                    newReserves = 0
                    if ( oldReserves !== 0 ) {
                        console.log("Note: depleted reserves when increasing bid " + i
                                + " from " + oldPrice + " to " + newPrice)
                    }
                } else {
                    newReserves -= priceIncrease
                    console.log("Note: reduced reserves when increasing bid " + i
                            + " from " + oldPrice + " to " + newPrice)
                }
            } else if ( priceIncrease < 0 ) {
                console.log("Note: bumping earliestRefundBlock by only " + REFUND_DELAY2 + " blocks")
                newEarliest += REFUND_DELAY2
                console.log("priceIncrease is " + priceIncrease)
                //newEarliest = currentBlock + REFUND_DELAY2
                newReserves = newReserves - priceIncrease
                console.log("Note: INCREASED reserves when  reducing bid " + i
                            + " from " + oldPrice + " to " + newPrice)
            } else {
                console.log("We should not allow modifying to the same price")
                throw new Error("17309: invalid price. already exists."); //exit(false)
            }
            newBidArray[i] = {
                price: newPrice,
                rabinPKH: bidArray[i].rabinPKH,
                refundPKH: bidArray[i].refundPKH,
                earliestRefundBlock: newEarliest,
                reserveFunds: newReserves
            }
        }
    }

    return newBidArray;
}

//export /* */
function copyBid2Balk( bidArray, balkArray, fromEntry ) {
    const refundPKH           = bidArray[fromEntry].refundPKH
    const earliestRefund      = bidArray[fromEntry].earliestRefundBlock
    const funds               = bidArray[fromEntry].price
                            + bidArray[fromEntry].reserveFunds

    return appendBalk( balkArray, refundPKH, earliestRefund, funds )
}

//export /* */
function findMyBidIndex( bidArray, price ) {

	const numBids = bidArray.length;
    for( var i = 0; i < numBids; i++ ) {
        if ( bidArray[i].price === price ) {
			console.log('    Found your bid at index ' + i)
			return i
        }
    }

	console.log('ERROR: Could NOT find your bid (price ' + price
			+ ") in any of the " + numBids + " bids.")
    throw new Error("17310: can't find bid"); //exit()
}

//export /* */
function getBestBidIndex( bidArray ) {

	const numBids = bidArray.length;
	var bestBidIdx = 0
	var bestBidValue = 0
    for( var i = 0; i < numBids; i++ ) {
		const bidPrice = bidArray[i].price
        if ( bidPrice >= bestBidValue ) {
			bestBidIdx = i;
			bestBidValue = bidPrice
        }
    }

    return bestBidIdx;
}

//export /* */
function getWorstBidIndex( bidArray ) {

	const numBids = bidArray.length;
	var worstBidIdx = 0
	var worstBidValue = 1100000000000 // larger than 0xFFFFFFFFFF
    for( var i = 0; i < numBids; i++ ) {
		const bidPrice = bidArray[i].price
        if ( bidPrice <= worstBidValue ) {
			worstBidIdx = i;
			worstBidValue = bidPrice
        }
    }

    return worstBidIdx;
}

// node utility needs this, but abhors function export
/* */
//export {
module.exports = {
        appendBid,
        removeBid,
        modifyBid,
        replaceBid,
        appendBalk,
        removeBalk,
        copyBid2Balk,
        findMyBidIndex,
        getBestBidIndex,
        getWorstBidIndex,
}
/* */