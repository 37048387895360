"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var getOption_1 = require("./getOption");
function getClotheColor(options, random) {
    var clotheColor = [];
    if (getOption_1.getOption('clothesColor', 'black', options)) {
        clotheColor.push('black');
    }
    if (getOption_1.getOption('clothesColor', 'blue', options)) {
        clotheColor.push('Blue01', 'Blue02', 'Blue03');
    }
    if (getOption_1.getOption('clothesColor', 'gray', options)) {
        clotheColor.push('Gray01', 'Gray02');
    }
    if (getOption_1.getOption('clothesColor', 'heather', options)) {
        clotheColor.push('Heather');
    }
    if (getOption_1.getOption('clothesColor', 'pastel', options)) {
        clotheColor.push('PastelBlue', 'PastelGreen', 'PastelOrange', 'PastelRed', 'PastelYellow');
    }
    if (getOption_1.getOption('clothesColor', 'pink', options)) {
        clotheColor.push('Pink');
    }
    if (getOption_1.getOption('clothesColor', 'red', options)) {
        clotheColor.push('Red');
    }
    if (getOption_1.getOption('clothesColor', 'white', options)) {
        clotheColor.push('White');
    }
    return random.pickone(clotheColor);
}
exports.getClotheColor = getClotheColor;
