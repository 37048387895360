import React from 'react';
import ReactQuill, {Quill} from 'react-quill';
import 'react-quill/dist/quill.snow.css';

// for quill-embedded emoji picker
import * as Emoji from "quill-emoji";
import "quill-emoji/dist/quill-emoji.css";

// we use these for plain text 'editor' (<TextArea>)
import { scanForEmojis } from './emojiScanner';
import EmojiPicker from 'emoji-picker-react';

import './myQuill.css' // used for background color when not editable

import { Checkbox, Form, Icon, Modal, Popup, Radio, Segment,
    Sidebar, Table, TableBody, TableRow, TextArea } from 'semantic-ui-react'

import VideoLibrary from './videoLibrary';      // (audio/video analog to photo loader)
import PhotoLoader from './photoLoader';

// device-specific custom sidebar
// see this: https://stackoverflow.com/questions/52345724/why-custom-css-class-does-not-work-on-react-semantic-ui-elements
import './componentStyles.css'


// for quill-embedded emoji support
Quill.register("modules/emoji", Emoji);

class PostPopup extends React.PureComponent {
    constructor(props) {
        super(props);

        this.quillRef = React.createRef()  // we use this to apply focus, and alter the placeholder when appropriate
        this.textRef  = React.createRef()  // we use this to apply focus

        this.state = {
            show: true,
            richText: "",        // rich text
            plainText: "",       // plain text
            txtAreaStartPos: 0,
            txtAreaEndPos: 0,

            // This should just be initialized to false.
            // We allow the user to HERE select it.
            inviteToDialog:             false, //this.props.inviteToDialog,

            suitableForDialogInvite:    this.props.suitableForDialogInvite,
            dialogInvitee:              this.props.dialogInvitee,

            photoName: null,
            photoBlob: null,
            photoToAdd: null,
            addPhotoToPost: false,

            useRichText: false,
            encryptPost: this.props.initialEncrypt,

            videoToAdd: null,
            videoDescription: null,
            addVideoToPost: false,
            videoURL: null,

            emojiPicker: false,
        };
/*
        Quill.register(
            {
                "formats/emoji": quillEmoji.EmojiBlot,
                "modules/emoji-toolbar": quillEmoji.ToolbarEmoji,
                "modules/emoji-textarea": quillEmoji.TextAreaEmoji,
                "modules/emoji-shortname": quillEmoji.ShortNameEmoji,
            },
            true,
        );
*/

        //FIXME: the purples are currently duplicated in index.css
        this.bshzPurple             = '#8313e2';
        this.bshzYellow             = "#fff300";
        this.bshzLtPurp             = '#e7d0fb';
        this.bshzLightYellow        = "#fffccc";

        this.handleChange                   = this.handleChange.bind(this);
        this.handleSubmit                   = this.handleSubmit.bind(this);
        this.handleClose                    = this.handleClose.bind(this);

        this.toggleUseRichText              = this.toggleUseRichText.bind(this);
        this.delayedFocus                   = this.delayedFocus.bind(this);

        this.maybeToggleShowPhotoModal      = this.maybeToggleShowPhotoModal.bind(this);
        this.toggleShowPhotoModal           = this.toggleShowPhotoModal.bind(this);
        this.removePhoto                    = this.removePhoto.bind(this);
        this.addPhoto                       = this.addPhoto.bind(this);
        this.toggleEncryptPost              = this.toggleEncryptPost.bind(this);

        this.maybeToggleShowVideoLibrary    = this.maybeToggleShowVideoLibrary.bind(this);
        this.toggleShowVideoLibrary         = this.toggleShowVideoLibrary.bind(this);
        this.removeVideo                    = this.removeVideo.bind(this);
        this.addVideo                       = this.addVideo.bind(this);
        this.getPlaceholderText             = this.getPlaceholderText.bind(this);

        this.handleContentChange            = this.handleContentChange.bind(this);
        this.handleTextAreaClick            = this.handleTextAreaClick.bind(this);
        this.handleTextAreaKeyUp            = this.handleTextAreaKeyUp.bind(this);
        this.toggleDialogInvite             = this.toggleDialogInvite.bind(this);

        this.toggleEmojiPicker              = this.toggleEmojiPicker.bind(this);
        this.closeEmojiPicker               = this.closeEmojiPicker.bind(this);
        this.emojiPicked                    = this.emojiPicked.bind(this);

        // A minimal toolbar for while <TextArea> is selected
        this.modulesNearEmpty = {
            toolbar: [
                [{ header: [ false] }, {font: ['sans serif'] }], [{ size: ['normal'] }],
            ],
        }
        this.modules = {
            toolbar: [
              [{ header: [1, 2, false] }, {font: [] }],
              [{ size: [] }],
              ["bold", "italic", "underline", "strike", "blockquote"],
              [
                {
                  color: ["black", "white", "red", "blue", "yellow", "green", "purple"],
                },
                {
                  background: ["white", "black", "red", "blue", "yellow", "green", "purple"],
                },
              ],
              [
                { list: "ordered" },
                { list: "bullet" },
                { indent: "-1" },
                { indent: "+1" }
              ],
              //["emoji"], // we don't need it on the toolbar
              ["link", "image", /*"video"*/],
              ["clean"]
            ],
            "emoji-toolbar": false,
            "emoji-textarea": true,   // THIS may be a better location
            "emoji-shortname": true,
        }
        this.formats = [
              "header",
              "font",
              "size",
              "bold",
              "italic",
              "underline",
              "strike",
              "blockquote",
              "color",
              "background",
              "list",
              "bullet",
              "indent",
              "link",
              "image",
              //"video"
              "emoji"
            ];
    }

    // Rich text
    // We track this so that the read-only editor can have a copy of the text
    // We have trouble updating the placeholder text of the read-only editor, though
    handleChange(value) {
        this.setState({ richText: value });

        /* this all works, but isn't necessary
        const myElement = this.quillRef.current
        let selection = myElement.getEditorSelection()
        if ( !selection ) {

            this.quillRef.current.focus()

            selection = myElement.getEditorSelection()
            console.warn("Did we get the selection? ", selection)
            alert("We maybe GOT the quill selection AFTER we NEEDED to focus")
            return
        }
        console.log("Quill editor selection: ", selection, " <------")
        const startPosition = selection.index;
        const endPosition = selection.index + selection.length;

        // Check if you've selected text
        if(startPosition === endPosition) {
            console.log("handleChange: The position of the cursor is (" + startPosition + "/" + value.length + ")");
        } else {
            console.log("handleChange: Selected text from ("+ startPosition +" to "+ endPosition + " of " + value.length + ")");
        }

        // adjust selection positions - in case emojis are to be inserted
        this.setState({richTextStartPos: startPosition, richTextEndPos: endPosition});
        */

    }
/*
    // all of this, below, works, but, none of it is necessary
    handleRichTextOnChangeSelection(e) {
        console.log("RICH TEXT change. e: ",e)


        const selection = this.quillRef.current.getEditorSelection()
        console.error("rich text change selection: ", selection)
        if ( selection ) {
            const startPosition = selection.index;
            const endPosition = selection.index + selection.length;

            // Check if you've selected text
            if(startPosition === endPosition){
                console.log("hRTocs: The position of the cursor is (" + startPosition + "/" + selection.length + ")");
            }else{
                console.log("hRTocs: Selected text from ("+ startPosition +" to "+ endPosition + " of " + selection.length + ")");
            }

            // adjust selection positions - in case emojis are to be inserted
            this.setState({richTextStartPos: startPosition, richTextEndPos: endPosition});
        }
    }
*/

    componentDidMount() {
        if ( this.state.useRichText ) {
            this.quillRef.current.focus();
        } else {
            this.textRef.current.focus();
        }
    }

    handleSubmit() {
        // We now only scan the quill-generated content.
        // Quill wraps emojis, and we might want to strip them out.
        // We no-longer wrap emojis as htmlEntities (final param/option: false)
        const textToPost = this.state.useRichText ?
                        scanForEmojis( this.quillRef.current.getEditorContents(), true, false )
                    :
                        this.state.plainText

        console.log("scanned/treated text has length " + textToPost.length + ": ", textToPost)

        // pass back the post params:
        //     - post text (formatted or not)
        //     - dialog invite
        //     - photo
        //     - A/V recording
        //     - encryption

        //NOTE: WARNING: if we're using RichText, the photoName, blob is ignored (not returned to parent)
        //      But, so what?

        this.props.processPost({
            inviteToDialog:     this.state.inviteToDialog,

            photoName:          this.state.useRichText ? null : this.state.photoName,
            photoBlob:          this.state.useRichText ? null : this.state.photoBlob,
            addPhotoToPost:     this.state.addPhotoToPost,
            photoToAdd:         this.state.photoToAdd,

            encryptPost:        this.state.encryptPost,

            videoToAdd:         this.state.videoToAdd,
            addVideoToPost:     this.state.addVideoToPost,
            videoURL:           this.state.videoURL,

            postText:           textToPost,
        })

    }
    handleClose() {
        this.props.close()
    }

    //HACK alert: it seems to take time for a reference to work - after enabling
    //            (definitely for quill. Not sure about textArea - just took the same approach for it)
    delayedFocus(it) {
        setTimeout(() => it.current.focus(), 10)
    }

    toggleUseRichText() {
        console.log("Toggling useRichText. Was " + this.state.useRichText)
        if ( !this.state.useRichText ) {

            this.closeEmojiPicker()
            this.setState({useRichText: true}, this.delayedFocus( this.quillRef ) )

            // scroll down a bit
            document.getElementById('myQuill')?.scrollIntoView({
                behavior: 'smooth'
            })
        } else {
            if ( this.state.encryptPost && this.state.addPhotoToPost ) {
                // At some point the user was in 'plain text' mode, and attached a photo.
                // Later, while the user was in 'rich text' mode, he selected 'encrypt' (since it's allowed in that mode).
                // Now that the user has moved to 'plain text' mode again, we can't (currently) allow BOTH options,
                // so we favor attaching a photo, and turn-off encryption.
                alert("SORRY: we don't currently handle encrypted posts that include a photo UNLESS you're using the RichText editor.\n"
                    + "We'll need to de-select encryption.")
                this.setState({encryptPost: false})
            }

            this.setState({useRichText: false}, this.delayedFocus( this.textRef ) )

            // scroll up a bit
            document.getElementById('myTextArea')?.scrollIntoView({
                behavior: 'smooth'
            })
        }
    }

    maybeToggleShowPhotoModal() {
        if ( this.state.addPhotoToPost ) {

            // photoToAdd is the URL - for now
            this.setState({photoToAdd: null, photoBlob: null, photoName: null, addPhotoToPost: false})
        } else {
            this.toggleShowPhotoModal()
        }
    }

    toggleShowPhotoModal() {
        // disable the photoLoader while using RichText/quill editor
        if ( this.state.useRichText )
            return
        console.log("toggling state.showPhotoModal to " + !this.state.showPhotoModal)
        this.setState({showPhotoModal: !this.state.showPhotoModal})
    }
    removePhoto() {
        console.warn("removePhoto()")
        this.setState({photoToAdd: null, photoName: null, photoBlob: null, addPhotoToPost: false})
    }
    addPhoto(photoUrl, photoBlob, photoName) {
        console.warn("addPhoto()")
        let pName
        if ( photoName?.startsWith("C:\\fakepath\\") ) {
            pName = photoName.substr(12)
            console.log("substr: ", photoName.substr(12))
            console.log("substring: ", photoName.substring(12))
        } else {
            pName = photoName
        }
        this.setState({ photoToAdd: photoUrl,   // hold onto this. Pass it in the *next* time we instantiate the photoLoader
                        photoBlob:  photoBlob,
                        photoName:  pName,
                        addPhotoToPost: true})

        console.warn("NOTE: blob (array): ", photoBlob)
    }

    // Whether or not to encrypt the user's post (on the blockchain)
    toggleEncryptPost() {
        const newEncryptPostVal = !this.state.encryptPost
        console.log("toggling state.encryptPost to " + newEncryptPostVal)
        this.setState({ encryptPost: newEncryptPostVal })

        //console.log("here's the quillRef.current.cleanProps: ", this.quillRef?.current?.cleanProps)
        //console.log("here's the .ql-editor: ", document.querySelector('.ql-editor'))

        // Set the RichTextEditor placeholder text
        // The TextArea placeholder text is set within render()
        const newPlaceholderText = this.getPlaceholderText(this.state.inviteToDialog, newEncryptPostVal)
        document.querySelector('.ql-editor').setAttribute("data-placeholder", newPlaceholderText);

        //NOTE: logic above is for snow theme (found empirically). Bubble theme might need this:
        //      document.querySelector('.ql-tooltip-editor input').setAttribute("data-link", "your own placeholder");
        //      see this: https://stackoverflow.com/questions/38936594/dynamically-change-quill-placeholder

        // re-apply focus
        if ( this.state.useRichText ) {
            this.delayedFocus( this.quillRef )
        } else {
            this.delayedFocus( this.textRef )
        }
    }

    maybeToggleShowVideoLibrary() {
        if ( this.state.addVideoToPost ) {

            // photoToAdd is the URL - for now
            this.setState({videoToAdd: null, videoDescription: null, addVideoToPost: false})
        } else {
            this.toggleShowVideoLibrary()
        }
    }
    toggleShowVideoLibrary() {
        console.log("toggling state.showVideoLibrary to " + !this.state.showVideoLibrary)
        this.setState({showVideoLibrary: !this.state.showVideoLibrary})
    }
    removeVideo() {
        console.warn("removeVideo()")
        this.setState({videoToAdd: null, videoDescription: null, addVideoToPost: false})
    }
    addVideo(videoTxId, videoDescription, videoURL) {
        console.warn("addVideo() - with description: ", videoDescription)

        this.setState({ videoToAdd:       videoTxId,   // hold onto this. Pass it in the *next* time we instantiate the videoLibrary
                        videoDescription: videoDescription,
                        videoURL:         videoURL,
                        addVideoToPost:   true})

        console.warn("NOTE: video URL ", videoURL)
        console.warn("NOTE: video txId ", videoTxId)
    }

    getPlaceholderText(inviteToDialog, encryptPost) {
        return inviteToDialog ?
                            'Type/paste an invitation to dialogue'
                        :
                            encryptPost ?
                                    'Type/paste a private note to yourself'
                                :
                                    'Type/paste content to publish'
    }

    toggleDialogInvite() {
        console.log("toggling state.inviteToDialog to " + !this.state.inviteToDialog)
        this.setState({ inviteToDialog: !this.state.inviteToDialog })
    }

    handleContentChange(event, data) {
        event.preventDefault()

        const myElement = document.getElementById('myTextArea');
        const startPosition = myElement.selectionStart;
        const endPosition = myElement.selectionEnd;

        // Check if you've selected text
        if(startPosition === endPosition){
            //console.log("hcc: The position of the cursor is (" + startPosition + "/" + myElement.value.length + ")");
        }else{
            console.log("hcc: Selected text from ("+ startPosition +" to "+ endPosition + " of " + myElement.value.length + ")");
        }

        const value = data.value;

        // reduce multiple tabs, returns, and spaces to single spaces
        // see:  https://stackoverflow.com/questions/22921242/remove-carriage-return-and-space-from-a-string
        const trimmedValue = value.replace(/\t/g, ' ')/*.replace(/[\n\r]+/g, ' ')*/.replace(/\s{2,10}/g, ' ');

        // also adjust selection positions - in case emojis are to be inserted
        this.setState({plainText: trimmedValue, txtAreaStartPos: startPosition, txtAreaEndPos: endPosition});
    }
    handleTextAreaClick() {
        const myElement = document.getElementById('myTextArea');
        const startPosition = myElement.selectionStart;
        const endPosition = myElement.selectionEnd;

        // Check if you've selected text
        if(startPosition === endPosition){
            console.log("htac: The position of the cursor is (" + startPosition + "/" + myElement.value.length + ")");
        }else{
            console.log("htac: Selected text from ("+ startPosition +" to "+ endPosition + " of " + myElement.value.length + ")");
        }

        // adjust selection positions - in case emojis are to be inserted
        this.setState({txtAreaStartPos: startPosition, txtAreaEndPos: endPosition});
    }
    handleTextAreaKeyUp() {
        const myElement = document.getElementById('myTextArea');
        const startPosition = myElement.selectionStart;
        const endPosition = myElement.selectionEnd;

        // Check if you've selected text
        if(startPosition === endPosition){
            console.log("htaku: The position of the cursor is (" + startPosition + "/" + myElement.value.length + ")");
        }else{
            console.log("htaku: Selected text from ("+ startPosition +" to "+ endPosition + " of " + myElement.value.length + ")");
        }

        // adjust selection positions - in case emojis are to be inserted
        this.setState({txtAreaStartPos: startPosition, txtAreaEndPos: endPosition});
    }
    closeEmojiPicker() {
        // also clear selection positions
        this.setState({emojiPicker: false, txtAreaStartPos: 0, txtAreaEndPos: 0})
    }
    toggleEmojiPicker() {
        if ( !this.state.emojiPicker ) {
            this.setState({emojiPicker: true})
        } else {
            this.setState({emojiPicker: false}, this.delayedFocus( this.state.useRichText ? this.quillRef : this.textRef ) )
            if ( !this.state.useRichText ) {
                const myElement = document.getElementById('myTextArea');
                myElement.focus()
                myElement.setSelectionRange(this.state.txtAreaStartPos, this.state.txtAreaEndPos)
            }
        }
    }

    emojiPicked(emojiData, event) {
        console.log("emoji picked: ", emojiData.emoji)
        console.log("that emoji has length ", emojiData.emoji.length)
        const emojiLen = emojiData.emoji.length

        if ( !this.state.useRichText ) {
            const startPos = this.state.txtAreaStartPos
            const endPos   = this.state.txtAreaEndPos

            console.log("Should place at location(s) ", startPos, " to ", endPos)
            // insert emoji into the selection area
            const newContentLHS = this.state.plainText.slice(0, startPos)
            const newContentRHS = this.state.plainText.slice(endPos)
            const newContent = newContentLHS + emojiData.emoji + newContentRHS
            console.log("new textArea content: ", newContent)
            console.log("setting txtArea pos to " + (startPos+emojiLen) )

            this.setState({plainText: newContent, txtAreaStartPos: startPos+emojiLen, txtAreaEndPos: startPos+emojiLen}, this.delayedFocus(  this.textRef ))
        } else {
            alert("This should not happen. Please report error 55911.")
        }
    }

    render() {
        // ***  DEVICE-DEPENDENT STYLING  ***
        // For mobile, "detect" landscape screen mode
        const landscape = this.props.isMobile ? window.innerWidth > window.innerHeight : false
        // For mobile, shrink the About, Security, and Glossary modals
        const modalClassName   = this.props.isMobile ?
                                        (landscape ?
                                                "modalMobileLandscape"
                                            :
                                                "modalMobilePortrait"
                                        )
                                    :
                                        ""
        const modalContentClassName = this.props.isMobile ? "modalContentMobile" : ""
        const modalBottomClassName  = this.props.isMobile ? "modalBottomMobile"  : ""

        //NOTE that this is only for the textArea.
        //     Quill sets the editor color (if editable or not) based on myQuill.css
        const selfBoxColor = this.state.useRichText ?
                                    'grey'
                                :
                                    this.state.suitableForDialogInvite && this.state.inviteToDialog ?
                                                this.bshzLtPurp
                                            :
                                                (this.state.encryptPost ?
                                                        'pink'
                                                    :
                                                        this.bshzLightYellow )

        const placeholderTxt = this.getPlaceholderText(this.state.inviteToDialog, this.state.encryptPost)

        const dialogHiLite = <span style={{color:'blue'}}>Dialogue</span>

        // icon sizes: mini tiny small large big huge massive
        const inviteExplainer = <Icon  size='large' style={{color: this.bshzPurple, cursor: "pointer"}} name='question circle outline' />
        const inviteExplainerText = <>  A {dialogHiLite} is a running conversation between two ShizzleVersians. It's a follow-on option anytime you receive
                                        a 'comment' from a fellow ShizzleVersian. Once started, it can be public, or private (encrypted).
                                        <br></br>
                                        Since this other party has 'recently' commented/posted on your domain/identity, it's currently possible to
                                        start a one-on-on {dialogHiLite} with them. In general this opportunity to start a {dialogHiLite} is <b>fleeting</b>,
                                        and should be acted on quickly. Note that the other party is under no obligation to respond to any {dialogHiLite} you initiate.
                                    </>
        //FIXED/HACK: popper prop (below) 'fixes' blurring/dimming of modal's video Popup
        //            see this: https://github.com/Semantic-Org/Semantic-UI-React/issues/1545
        const popupInviteExplainer = <Popup style={{backgroundColor: this.bshzLightYellow}}
                                            trigger={inviteExplainer}
                                            wide="very"
                                            content={inviteExplainerText}
                                            popper={<div style={{ filter: 'none' }}></div>}
                                            hideOnScroll/>

        // copied from claimer.js
        const bitcoinSymbol = '\u20BF'
        //WARNING: on chrome, bitcoin symbol seems to only work with fontSizes: 1.2rem, 1.8rem, 16px
        //         though, commonFuncs' getBitcoinDomainName() has success with other sizes
        const inviteeDomainSmaller = <>
                            <b style={{color:this.bshzPurple, fontSize:"1.0rem", verticalAlign:"7%"}}><span style={{verticalAlign:"-5%"}}>{bitcoinSymbol}</span>@</b>
                            <b style={{color:"blue", fontSize:"1.0rem", verticalAlign:"-3%"}}>{this.state.dialogInvitee?.toLowerCase()}</b>
                        </>
        //FIXME: use table to put TextArea, and checkbox side-by-side
        const inviteLabel = <>Start dialogue with <b>{inviteeDomainSmaller}</b></>
        // Don't allow encrypting AND inviting to dialog - it's rude/confusing
        const disableDialogInvite = this.state.encryptPost
        const dialogCheckbox = this.state.suitableForDialogInvite ?
                                disableDialogInvite ?
                                        <>
                                            <Checkbox   checked={false}
                                                        disabled/>&nbsp; {inviteLabel}&nbsp;&nbsp;{popupInviteExplainer}
                                        </>
                                    :
                                        <>
                                            <Checkbox   checked={this.state.inviteToDialog}
                                                        onClick={this.toggleDialogInvite}/>&nbsp; {inviteLabel}&nbsp;&nbsp;{popupInviteExplainer}
                                        </>
                            :
                                <></>

        // icon sizes: mini tiny small large big huge massive
        const videoExplainer = <Icon  size='large' style={{color: this.bshzPurple}} name='video camera' onClick={this.toggleShowVideoLibrary}/>

        const maybeVideoIcon = this.state.addVideoToPost ?
                        <span>
                            {videoExplainer}&nbsp;{this.state.videoToAdd.substring(0,8)}...
                        </span>
                    :
                        <span></span>

        const optionalElipsis = this.state.videoDescription?.length > 23 ?
                              <>
                                <b style={{color:'blue'}}>...</b>
                              </>
                            :
                              <></>
        const popUpVideoExplainerText = <><p>Click the camera icon to review, modify, or clear the recording you'll include with your posts.</p>

                                            Recording: <span style={{color:'blue'}}>{this.state.videoToAdd?.substring(0,10)}... &nbsp;
                                            [{this.state.videoDescription?.substring(0,23)}{optionalElipsis}]</span>
                                            <br></br>
                                            <video width="240" height="160" src={this.state.videoURL} autoPlay muted/>
                                            <br></br>
                                        </>
        //FIXED/HACK: popper prop (below) 'fixes' blurring/dimming of modal's video Popup
        //            see this: https://github.com/Semantic-Org/Semantic-UI-React/issues/1545
        const popupVideoExplainer =     <Popup  style={{backgroundColor: this.bshzLightYellow}}
                                                popper={<div style={{ filter: 'none' }}></div>}
                                                trigger={maybeVideoIcon} wide="very"
                                                content={popUpVideoExplainerText}
                                                position='top left' hideOnScroll
                                                size='tiny'/>

        const addVideoCheckbox = !this.state.useRichText && (this.state.encryptPost || this.state.addPhotoToPost) ||
                                  (this.state.useRichText && this.state.encryptPost) ?
                    <>
                        <Checkbox label="Include an A/V recording"
                            checked={false}
                            disabled
                            />
                    </>
                :
                    <>
                        <Checkbox label="Include an A/V recording"
                            checked={this.state.addVideoToPost}
                            onClick={this.maybeToggleShowVideoLibrary}
                            />
                        <span>
                        &nbsp; &nbsp; &nbsp; &nbsp;{popupVideoExplainer}
                        </span>
                    </>
        const maybeVideoLibrary = this.state.showVideoLibrary ?
                    <>
                        <VideoLibrary
                            closeLibrary={this.toggleShowVideoLibrary}
                            returnVideo={this.addVideo}
                            removeVideo={this.removeVideo}

                            existingVideoToAdd={this.state.videoToAdd}
                            />
                    </>
                :
                    <></>

        // Don't allow encrypting AND inviting to dialog - it's rude/confusing
        const disableSelfEncrypt = (!this.props.allowEncryption) || this.state.inviteToDialog ||
                                    (!this.state.useRichText && (this.state.addPhotoToPost || this.state.addVideoToPost ) ) ||
                                    (this.state.useRichText && this.state.addVideoToPost)

        const encryptionCheckbox = disableSelfEncrypt ?
                    <>
                        <Checkbox label={this.props.privacyMention}
                            checked={this.state.encryptPost}
                            disabled
                            />
                    </>
                :
                    <>
                        <Checkbox label={this.props.privacyMention}
                            checked={this.state.encryptPost}
                            onClick={this.toggleEncryptPost}
                            disabled={disableSelfEncrypt}
                            />
                    </>
        const intendedAudience = this.state.encryptPost ? this.props.introMentionEncrypted : this.props.introMentionNotEncrypted
        const intendedAudience2 = this.state.encryptPost ? this.props.infoMentionEncrypted : this.props.infoMentionNotEncrypted

        const richTextChoiceDescriptionColor  = this.state.useRichText ? "purple" : "#a8a8a8"
        const textAreaChoiceDescriptionColor  = this.state.useRichText ? "#a8a8a8" : "purple"

        const disablePost = this.state.useRichText ?
                                    this.quillRef.current.getEditorContents().length < 2
                                :
                                    this.state.plainText.length < 1

        //<Icon   name='window close' onClick={this.handleClose} size='large' color='red' />
        const textAreaArrowIcon = this.state.useRichText ?
                        <>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</>
                    :
                        <>
                            <Icon   name='arrow circle right'
                                    onClick={this.handleSubmit}
                                    disabled={disablePost}
                                    size='big' color='purple' />
                        </>
        const richTextArrowIcon = this.state.useRichText ?
                        <>
                            <Icon   name='arrow circle right'
                                    onClick={this.handleSubmit}
                                    disabled={disablePost}
                                    size='big' color='purple' />
                        </>
                    :
                        <>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</>

        const richTextBorderWidth = this.state.useRichText ? '5px' : '3px'
        const textAreaBorderWidth = this.state.useRichText ? '3px' : '5px'

        const richTextBorderStyle = this.state.useRichText ? 'dashed' : 'groove'
        const textAreaBorderStyle = this.state.useRichText ? 'groove' : 'dashed'

        const richTextBorderColor = this.state.useRichText ?
                                        this.state.encryptPost ? 'red' : 'purple'
                                    :
                                        '#e0e0e0'
        const textAreaBorderColor = this.state.useRichText ?
                                        '#e0e0e0'
                                    :
                                        this.state.encryptPost ? 'red' : 'purple'

        // quill editor A is shown when plain text is chosen (it's minimal, and read-only)
        // quill editor B is shown when Rich Text is chosen (it's active, with a full toolbar)
        const showAorB = this.state.useRichText ? 'none' : 'block'
        const showBorA = this.state.useRichText ? 'block' : 'none'

        const explainerColor = this.state.useRichText ? 'grey' : this.bshzPurple
        // icon sizes: mini tiny small large big huge massive
        const photoExplainer = <Icon  size='large' style={{color: explainerColor}} name='picture' onClick={this.toggleShowPhotoModal}/>

        const maybePhotoIcon = this.state.addPhotoToPost ?
                        <>
                            {photoExplainer}&nbsp;{this.state.photoName}
                        </>
                    :
                        <></>

        const photoCheckBox =
                <span>
                    {maybePhotoIcon}
                </span>

        const popUpPhotoExplainerText = <>Click the icon to review, modify, or clear the photo you'll include with your posts
                                            <br></br>
                                            <img src={this.state.photoToAdd} style={{maxWidth:'40%', maxHeight:'40%'}}/>
                                        </>
        //FIXED/HACK: popper prop (below) 'fixes' blurring/dimming of modal's video Popup
        //            see this: https://github.com/Semantic-Org/Semantic-UI-React/issues/1545
        const popupPhotoExplainer =     <Popup  style={{backgroundColor: this.bshzLightYellow}}
                                                trigger={photoCheckBox} wide="very"
                                                disabled={this.state.useRichText}
                                                popper={<div style={{ filter: 'none' }}></div>}
                                                content={popUpPhotoExplainerText} hideOnScroll/>

        const addPhotoCheckbox = this.state.encryptPost || this.state.addVideoToPost || this.state.useRichText ?
                    <>
                        <Checkbox label="photo, gif"
                            checked={this.state.addPhotoToPost}
                            disabled
                            />
                        <span>
                        &nbsp; &nbsp; &nbsp; &nbsp;{popupPhotoExplainer}
                        </span>
                    </>
                :
                    <>
                        <Checkbox label="photo, gif"
                            checked={this.state.addPhotoToPost}
                            onClick={this.maybeToggleShowPhotoModal}
                            disabled={this.state.useRichText}
                            />
                        <span>
                        &nbsp; &nbsp; &nbsp; &nbsp;{popupPhotoExplainer}
                        </span>
                    </>

        const maybePhotoLoader = this.state.showPhotoModal ?
                    <>
                        <PhotoLoader
                            closeLoader={this.toggleShowPhotoModal}
                            returnPhoto={this.addPhoto}
                            removePhoto={this.removePhoto}

                            existingPhotoName={this.state.photoName}
                            existingPhotoBlob={this.state.photoBlob}
                            existingPhotoUrl={this.state.photoToAdd}
                            />
                    </>
                :
                    <></>

        const dimmablePlainBackgroundColor = this.state.useRichText ? '#e0e0e0' : 'white'
        const dimmableRichBackgroundColor  = this.state.useRichText ? 'white' : '#e0e0e0'

        const emojiToggler = this.state.useRichText ?
                            <span  style={{fontSize:'2.0rem'}}>🫥</span>
                        :
                            <span style={{fontSize:'2.0rem'}} onClick={this.toggleEmojiPicker}>
                                 {this.state.emojiPicker ? <></> : <>😀</>}
                             </span>

        const sidebarWidth = 'wide'
        const sidebarClassname = this.props.isMobile ? (landscape ? 'my_phone_landscape_sidebar' : 'my_phone_sidebar') : 'my_sidebar'

        const textAreaWidth = this.props.isMobile ? (landscape ? "450px" : "300px") : "550px"

        // <EmojiPicker   disableAutoFocus={true} autoFocusSearch={false} ...

        // This isn't necessary. What it was tracking can just be queried.
        // <ReactQuill    onChangeSelection={this.handleRichTextOnChangeSelection}
        //

        // FIXED: cut-off of video link pop-up:
        //   see: https://github.com/quilljs/quill/issues/360
        //   We add a data-text-editor property in a <div> that contains <ReactQuill>,
        //   and a bounds property that references it in the <ReactQuill>
        return(
            <>
                <Modal dimmer='blurring' size='fullscreen' centered className={modalClassName}  open={this.state.show}
                        style={{backgroundColor: this.bshzPurple, borderRadius: '20px', height: "auto"}}>

                    <Modal.Header style={{backgroundColor: this.bshzPurple, borderRadius: '20px'}}>
                        <Icon name='window close outline' onClick={this.handleClose} size='large' color='yellow'/>
                        &nbsp; &nbsp; &nbsp;
                        <span style={{color: this.bshzYellow}}> {this.props.headerTitle} </span>
                    </Modal.Header>


                    <Sidebar.Pushable>
                        <Sidebar className={sidebarClassname} style={{padding: 0}}
                            animation='overlay'
                            direction='right'
                            icon='labeled'
                            onHide={() => this.closeEmojiPicker()}

                            visible={this.state.emojiPicker}
                            width={sidebarWidth}
                        >
                            <EmojiPicker onEmojiClick={this.emojiPicked}
                                            skinTonesDisabled={true} height="100%" width="100%"
                                            previewConfig={{showPreview: false}}
                            />
                        </Sidebar>

                        <Sidebar.Pusher style={{backgroundColor:this.props.bgColor}} >

                            <Modal.Content className={modalContentClassName} scrolling style={{backgroundColor: this.props.bgColor}}>
                                <Segment style={{backgroundColor: this.props.bgColor}}>

                                    <span style={{color:'blue',fontSize:'1.2rem'}}>{this.props.introText} <b style={{color:'blue'}}>{intendedAudience}</b></span>

                                    <Table  style={{border: 'none'}}>
                                        <TableBody>
                                            <TableRow style={{backgroundColor: dimmablePlainBackgroundColor}}>
                                                <Table.Cell >
                                                    <Form size='large' style={{width: textAreaWidth}}>
                                                            <TextArea   placeholder={placeholderTxt} ref={this.textRef}
                                                                        id='myTextArea'
                                                                        style={{backgroundColor:selfBoxColor, padding:'8px 8px', borderWidth:textAreaBorderWidth, borderStyle: textAreaBorderStyle, borderColor: textAreaBorderColor}}
                                                                        field='content'
                                                                        rows="4"
                                                                        value={this.state.plainText}
                                                                        onChange={this.handleContentChange}
                                                                        onClick={this.handleTextAreaClick}
                                                                        onKeyUp={this.handleTextAreaKeyUp}
                                                                        disabled={this.state.useRichText}
                                                                        />
                                                    </Form>
                                                </Table.Cell>
                                                <Table.Cell style={{width:"15%"}}>

                                                    <Table  style={{border: 'none'}}>
                                                        <TableBody>
                                                            <TableRow style={{backgroundColor: dimmablePlainBackgroundColor}}>
                                                                <Table.Cell>
                                                                    {emojiToggler}
                                                                </Table.Cell>
                                                            </TableRow>
                                                            <TableRow style={{backgroundColor: dimmablePlainBackgroundColor}}>
                                                                <Table.Cell>
                                                                    {addPhotoCheckbox}
                                                                </Table.Cell>
                                                            </TableRow>
                                                        </TableBody>
                                                    </Table>


                                                </Table.Cell>
                                                <Table.Cell style={{width:"25%"}}>
                                                    {textAreaArrowIcon}
                                                    {maybePhotoLoader}
                                                </Table.Cell>
                                            </TableRow>

                                            <TableRow style={{backgroundColor:this.props.bgColor, border:'none'}}>
                                                <Table.Cell style={{padding: 0}}>

                                                    <Table  style={{border: 'none'}} collapsing>
                                                        <TableBody>
                                                            <TableRow style={{backgroundColor: this.props.bgColor}}>
                                                                <Table.Cell style={{padding: '5px', width: "45px"}}>

                                                                </Table.Cell>
                                                                <Table.Cell style={{padding: '8px 0px'}}>
                                                                    {dialogCheckbox}
                                                                    <br></br>
                                                                    {addVideoCheckbox}
                                                                    <br></br>
                                                                    {maybeVideoLibrary}
                                                                    <br></br>
                                                                    {encryptionCheckbox}
                                                                </Table.Cell>
                                                                <Table.Cell style={{padding: '8px 0px'}}>
                                                                    <nobr style={{color: textAreaChoiceDescriptionColor, verticalAlign:'top'}}>plain text &nbsp; </nobr>
                                                                    <Radio  toggle
                                                                        checked={this.state.useRichText}
                                                                        onChange={this.toggleUseRichText} >
                                                                    </Radio> <nobr style={{color: richTextChoiceDescriptionColor, verticalAlign:'top'}}> &nbsp; rich text</nobr>

                                                                </Table.Cell>
                                                            </TableRow>
                                                        </TableBody>
                                                    </Table>

                                                </Table.Cell>
                                                <Table.Cell>
                                                </Table.Cell>
                                                <Table.Cell>
                                                </Table.Cell>
                                            </TableRow>

                                            <TableRow style={{backgroundColor: dimmableRichBackgroundColor}}>
                                                <Table.Cell style={{width:"60%"}}>
                                                    <div id='myQuill' data-text-editor="name" style={{padding:'8px 8px', display: showBorA, borderWidth: richTextBorderWidth, borderStyle: richTextBorderStyle, borderColor: richTextBorderColor}}>
                                                    <ReactQuill theme="snow" ref={this.quillRef}
                                                        modules={this.modules}
                                                        formats={this.formats}
                                                        onChange={this.handleChange}
                                                        placeholder={placeholderTxt}
                                                        readOnly={!this.state.useRichText}
                                                        style={{backgroundColor:'#f0f0f0'}}

                                                        bounds={`[data-text-editor="name"]`}
                                                    />
                                                    </div>

                                                    <div style={{padding:'8px 8px', display: showAorB, borderWidth: richTextBorderWidth, borderStyle: richTextBorderStyle, borderColor: richTextBorderColor}}>
                                                    <ReactQuill theme="snow"
                                                        value={this.state.richText}
                                                        modules={this.modulesNearEmpty}
                                                        formats={this.formats}
                                                        placeholder={placeholderTxt}
                                                        readOnly={true}
                                                        style={{backgroundColor:'#d0d0d0'}}
                                                    />
                                                    </div>

                                                </Table.Cell>
                                                <Table.Cell>
                                                </Table.Cell>
                                                <Table.Cell >
                                                    {richTextArrowIcon}
                                                </Table.Cell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                    {this.props.infoText} <b style={{color:'blue'}}>{intendedAudience2}</b>

                                </Segment>
                            </Modal.Content>
                        </Sidebar.Pusher>
                    </Sidebar.Pushable>
                </Modal>
            </>
        )
    }
}

export default PostPopup;
