import React from 'react';

import { Button, Checkbox, Container, Divider, Dropdown, Form, Grid,
    Input, Menu, Message, Modal, Popup, Radio, TextArea } from 'semantic-ui-react'

//import { showError } from './helper.js'

import { FundsGetter } from './keyUtils.js';

import FileChooserReader from './fileChooserReader';

import {
    testGetBulkUnspent,

    saveEncryptedKeys,
    getEncryptedKeysFromPKH,

    buildShizzleLockingTx,
    encryptData,
    decryptData,
    getRabinsJson,

    getCurrentBlockInfo,
    findLatestContinue,
    findLatestAuction,

    openDB,
    getTxidFromAddr,
    recursivelyDeleteDescendantSpends,
    queryTxs,
    queryLabels,
    checkDanglingPaths,
    queryFetchTx,
    decomposeTx,
    queryFetchDecodeTx,
    findContent,

    parseTheOutputs,

    findNextTx,
    findPrevTx,
    buildOnBuilderPrep,
    buildOnAppend,
    buildOnClaimOrPublish,
      applyBuilderFeeReduction,
      announceBuilderFeeReductionAuth,
      buildOnAskBid,
    buildOnAnUpdate,
    buildOnATransient,
    buildOnDialog,
    buildOnABitGroup,
    buildOnTheAdminGroup,
    validateRabinPrivateKeys,
    generateRabinPrivateKeyPlus,

    findAllDomainsOfMine,
} from './buildShizzle.js';

import {
  convertAddressToPKH,
  hexByteToAscii,
  hexStringToAscii,
  execAsync,
  oneByteLE,

  printError,
} from './commonFuncs';

import { MAX_NUM_BIDS, MAX_PKHS, NETWORK, PAYLOAD_ESCAPE_FLAG } from './harnessConstants'

//NOTE: this bypasses buildShizzle.js - straight to shizzleBuilder.js
import {
  libGetOptionsForAskBidSubMode,
} from './shizzleBuilder.js';

import { KeySolicitingModal, RabinUtility } from './keyUtils.js';

import {
    reviewRenewalStatus,
    adjustRenewalStatus
} from './commonReact';

import * as eccryptoJS from 'eccrypto-js';

// for converting private key from WIF, to generate shared key
const { bsv } = require('scryptlib');

function getShizzleNav() {
  return window.ourShizzleNav
}



function containsNonZeroChars(str) {
  for( let i = 0; i < str.length; i++ ) {
    if ( str[i] !== '0' ) {
      return true
    }
  }
  return false
}


function closeOurThird(object) {
  console.log("global: closeOurThird() closing warning of invalid keys...");
  object.setState({openThirdModal: false});
}



// Now that keys have been obtained, we can build and publish.
// This is shared by multiple builder classes.
const handleRabinKeyModalSubmit = (event, object, builderName) => {
  console.log("static Builder (" + builderName + ") handleRabinKeyModalSubmit():");

  //FIXME: document that builder/parent must have this function, and buildAndPublish()
  if ( !object.preCheckRabinPrivateKeys() ) {
    console.log("Opening yet another modal - to warn about the priv keys used (don't match the target PKH)");

    // open a tertiary modal - to inform user the keys don't match the PKH
    object.setState({openThirdModal: true});
    return;
  }

  // close the key-soliciting modal
  object.setState({openSecondaryModal: false});

  // Don't post the form, and trigger a page reload
  event.preventDefault();

  if ( object.state && object.state !== null ) {
    //console.warn("static handleRabinKeyModalSubmit(): btw, here's the builder's bsvPrivKey: " + object.state.bsvPrivKey)
    console.log("static Builder: handleRabinKeyModalSubmit(): calling buildAndPublish()");
    object.buildAndPublish();
  }
}


const handleRabinKeyModalCancel = (event, object) => {
  console.log("static handleRabinKeyModalCancel():");

  // close modal AND clear any keys
  //FIXME: rename 'openSecondaryModal'
  object.setState({openSecondaryModal: false, rabinPrivKeyP: '', rabinPrivKeyQ: ''});

  // Don't post the form, and trigger a page reload
  event.preventDefault();
}


/**
 * Ensure the status is for the current limb/asset. If not, get the proper status.
 * @param {*} renewalStatus
 * @param {*} dtx
 * @returns
 */
function confirmStatusIsProper(renewalStatus, dtx) {
  // Could this actually happen?
  if ( renewalStatus.limbName !== dtx.limbName ) {
    // something is wrong. We're looking at a stale renewal status
    console.warn("User is looking to build on asset '" + dtx.limbName + "', but we've only got status on "
              + renewalStatus.limbName + ". Expediting status refresh.", dtx)

    alert("ERROR: you're looking to build on asset '" + dtx.limbName + "', but currently, we've only got status on "
        + renewalStatus.limbName + ".\nPlease try again.")

    execAsync( async function() {
      // get a fresh renewal status
      await getShizzleNav().expeditePeriodicCheck()
    })

    return false
  }

  console.log("confirmStatusIsProper(): all good")
  return true
}


/**
 * Handle changes to fields of modal - shared by multiple builder classes:
 *    ClaimBuilder, UpdateBuilder
 *    (soon to be more)
 * @param {*} event
 * @param {*} data
 * @param {*} object
 */
const handleFieldChange = (event, data, object, extraObject) => {
  //console.log("event: ", event);
  //console.log("data: ", data);
  //console.log("Object: ", object);
  const field = data.field;
  const value = data.value;
  const checked = data.checked;
//  console.log("static::handleFieldChange(): field: " + field + ",  checked: " + checked + ",  value: " + value); //FIXME: don't commit changes to this line

  switch(field) {
    case 'contentIsAFile':  // Transients can use this. Periodicals (and quarterlies?) will use contentSpecChoice locally ('0', '1', or '2')
      object.setState({contentIsAFile: checked})
      console.log("contentIsAFile: " + checked)
      break
    case 'contentIsHex':
      object.setState({contentIsHex: checked})
      console.log("contentIsHex: " + checked)
      break
    case 'ipByte0':
      const re4 = /^[0-9]+$/;
      console.log("ipByte0: " + value)
      console.log("parseInt(value):" + parseInt(value))
      if ( (value === '' || parseInt(value) < 256) && (value === '' || re4.test(value)) ) {
          object.setState({ipByte0: value})
          //FIXME: blank should be interpreted as 0
          //FIXME: don't allow building if byte is blank
      }
      break
    case 'ipByte1':
      const re5 = /^[0-9]+$/;
      console.log("ipByte1: " + value)
      console.log("parseInt(value):" + parseInt(value))
      if ( (value === '' || parseInt(value) < 256) && (value === '' || re5.test(value)) ) {
          object.setState({ipByte1: value})
          //FIXME: blank should be interpreted as 0
          //FIXME: don't allow building if byte is blank
      }
      break
    case 'ipByte2':
      const re6 = /^[0-9]+$/;
      console.log("ipByte2: " + value)
      console.log("parseInt(value):" + parseInt(value))
      if ( (value === '' || parseInt(value) < 256) && (value === '' || re6.test(value)) ) {
          object.setState({ipByte2: value})
          //FIXME: blank should be interpreted as 0
          //FIXME: don't allow building if byte is blank
      }
      break
    case 'ipByte3':
      const re7 = /^[0-9]+$/;
      console.log("ipByte3: " + value)
      console.log("parseInt(value):" + parseInt(value))
      if ( (value === '' || parseInt(value) < 256) && (value === '' || re7.test(value)) ) {
          object.setState({ipByte3: value})
          //FIXME: blank should be interpreted as 0
          //FIXME: don't allow building if byte is blank
      }
      break
    case 'encryptMsg':
      object.setState({encryptMsg: checked})
      console.log("encryptMsg: " + checked)
      break;
    case 'launchDialog':
//FIXME: should we clear the hostTx too?
      object.setState({launchDialog: checked,
                       rabinPKH: '',
                       hostTxConfirmed: false,
                       //hostTx: '',
                       guestPostPrice: '?'})
      console.log("launchDialog: " + checked)
      break;
    case 'notTheOwner':
      object.setState({notTheOwner: checked})
      console.log("notTheOwner: " + checked)
      break;
    case 'alterInstaBuy':
      object.setState({alterInstaBuy: checked})
      console.log("alterInstaBuy: " + checked)
      break;
    case 'newInstaPrice':
      object.setState({newInstaPrice: value})
      console.log("newInstaPrice: " + value)
      break;
    case 'newOwnerP2Pkh':
      object.setState({newOwnerP2Pkh: value})
      console.log("newOwnerP2Pkh: " + value)
      break;
    case 'bfrPadding':
      if ( containsNonZeroChars(value) ) {
        console.warn("IGNORING non-zero char")
        return
      }
      object.setState({bfrPadding: value})
      console.log("bfrPadding: " + value)
      console.warn("bfrPadding length in BYTES: " + value.length/2)
      break
    case 'bfrSignature':
      object.setState({bfrSignature: value})
      console.log("bfrSignature: " + value)
      console.warn("bfrSignature length: " + value.length/2)
      break
    case 'builderPubKey':
      object.setState({builderPubKey: value})
      console.log("builderPubKey: " + value)
      console.warn("builderPubKey length: " + value.length/2)
      break
    case 'applyBuilderFeeReduction':
      object.setState({applyBuilderFeeReduction: checked})
      console.log("applyBuilderFeeReduction: " + checked)
      break;
    case 'sellerRabinPubKey':
      object.setState({sellerRabinPubKey: value})
      console.log("sellerRabinPubKey: " + value)
      console.warn("sellerRabinPubKey length: " + value.length/2)
      break;
    case 'authcode':
      object.setState({authcode: value})
      console.log("authcode: " + value)
      console.warn("authcode length: " + value.length/2)
      break;
    case 'authcodePadding':
      object.setState({authcodePadding: value})
      console.log("authcodePadding: " + value)
      break;
    case 'transferOwnership':
      object.setState({transferOwnership: checked})
      console.log("transferOwnership: " + checked)
      break;
    case 'clearPotentialSale':
      //NOTE: this handling may be going overboard
      if ( checked ) {
        // if CHECKING this option, UNCHECK the other option
        object.setState({clearPotentialSale: checked, specifyPotentialSale: !checked})
        console.log("clearPotentialSale: " + checked + "  specifyPotentialSale: " + !checked)
      } else {
        // if UNCHECKING this option, don't do anything to the other option
        object.setState({clearPotentialSale: checked})
        console.log("clearPotentialSale: " + checked + "  specifyPotentialSale: unchanged: " + object.state.specifyPotentialSale)
      }
      //object.setState({clearPotentialSale: checked, potentialBuyerRabinPKH: ''})
      break;
    case 'specifyPotentialSale':
      //NOTE: this handling may be going overboard
      if ( checked ) {
        // if CHECKING this option, UNCHECK the other option
        object.setState({specifyPotentialSale: checked, clearPotentialSale: !checked})
        console.log("specifyPotentialSale: " + checked + "  clearPotentialSale: " + !checked)
      } else {
        // if UNCHECKING this option, don't do anything to the other option
        object.setState({specifyPotentialSale: checked})
        console.log("specifyPotentialSale: " + checked + "  clearPotentialSale: unchanged: " + object.state.clearPotentialSale)
      }
      break;
    case 'potentialBuyerRabinPKH':
      object.setState({potentialBuyerRabinPKH: value})
      console.log("potentialBuyerRabinPKH: " + value)
      break
//    case 'refundP2PKH':
//FIXME: instead, convert an address to a P2PKH
//      object.setState({refundP2PKH: value})
//      console.log("refundP2PKH: " + value)
//      break;
    case 'bidSats':
      object.setState({bidSats: value})
      console.log("bidSats: " + value)
      break;
    case 'askingPrice':
      object.setState({askingPrice: value})
      console.log("askingPrice: " + value)
      break;
/*
    case 'buildBogus':
      //object.setState({buildBogus: checked});      // used for all builders
      window.buildBogusTx = checked;    //NOTE: used in ContractSupport <---- //FIXME: kludgey
      console.log("buildBogusTx: " + window.buildBogusTx);
      object.setState({buildBogus: checked})
      break;
    case 'devTestMode':
      window.devTestMode = checked;
      object.setState({devTestMode : checked});
      console.log("devTestMode: " + window.devTestMode);

      // kludgey way to make ShizzleNav responsive to blocksBeyondMin
      extraObject.setState({blocksBeyondMin: 0})
      break;
    case 'blocksBeyondMin':
      //NOTE: this is kept in ShizzleNav
      //FIXME: enforce max too
      let val = value
      if ( val < 0 ) val = 0;
      object.setState({blocksBeyondMin: val});
      console.log("blocksBeyondMin: " + val);
      break;
*/
    case "allowGuestPosts":
      object.setState({allowGuestPosts: checked});  // specific to UpdateBuilder
      break;
    case "content":
      // reduce multiple tabs, returns, and spaces to single spaces
      // see:  https://stackoverflow.com/questions/22921242/remove-carriage-return-and-space-from-a-string
      const trimmedValue = value.replace(/\t/g, ' ').replace(/[\n\r]+/g, ' ').replace(/\s{2,10}/g, ' ');
      //console.log("REDUCING ", value, "   TO   ", trimmedValue)

      object.setState({content: trimmedValue});
      break;
    case "contentLabel": // enforce alphanumeric | dash | slash

      // regex check for alphanumeric, dash, slash  - max 64 characters
      const re3 = /^([/-]|[a-zA-Z0-9])+$/;

      if ( (value === '' || re3.test(value)) && value.length <= 64) {
        object.setState({contentLabel: value});
      }
      break;
    case "refTxId":
      //console.log("CHECKING FOR HEX PATTERN on refTxId")

      // regex check for hex pattern
      const re = /^[a-fA-F0-9]+$/;
      //console.log("input: " + value)
      if ( value === '' || re.test(value)) {
          object.setState({refTxId: value})
      }
      break;
    case "refTxLabel":
      object.setState({refTxLabel: value});
      break;
    case "paycode":
      //FIXME: enforce hexadecimal?
      object.setState({paycode: value});
      break;
    case "rabinPKH":
      //FIXME: enforce dec/hex?
      object.setState({rabinPKH: value});
      break;
    case "spawnUpdate":                           // specific to ClaimBuilder
      object.setState({spawnUpdate: checked});
      break;
    case "spawnSale":                             // specific to ClaimBuilder
      object.setState({spawnSale: checked});
      break;
    case "hostTx":
      //console.log("CHECKING FOR HEX PATTERN on hostTx")

      // regex check for hex pattern
      const re2 = /^[a-fA-F0-9]+$/;
      //console.log("input: " + value)
      if ( value === '' || re2.test(value)) {
//FIXME: not sure how much logic should sit here in this function
//FIXME: maybe call an object.extraStuff() ?
        // specific to TransientBuilder
        object.setState({ hostTx: value,
                          guestPostPrice: '?',
                          hostTxConfirmed: false,
                          userConfirmedHostTx: false})
      }
      break;
    case "rabinPrivKeyP":
      //FIXME: enforce dec/hex?
      object.setState({rabinPrivKeyP: value});
      break;
    case "rabinPrivKeyQ":
      //FIXME: enforce dec/hex?
      object.setState({rabinPrivKeyQ: value});
      break;
    case "spawnTransient":                        // specific to UpdateBuilder, and TransientBuilder
      object.setState({spawnTransient: checked});
      break;
    case "spawnBitGroup":                         // specific to UpdateBuilder
      object.setState({spawnBitGroup: checked});
      break;
    case "postText":                              // specific to BitGroup
      object.setState({postText: value});
      break;
    case "newUserP2PKH":                          // specific to BitGroup
      //FIXME: enforce hexadecimal?
      object.setState({newUserP2PKH: value});
      break;
    case "newUserRabinPKH":                       // specific to BitGroup
    //FIXME: enforce hexadecimal?
      object.setState({newUserRabinPKH: value});
      break;
    case "newUserName":                           // specific to BitGroup
      object.setState({newUserName: value});
      break;
    case "subGroupName":                          // specific to BitGroup
      object.setState({subGroupName: value});
      break;
    case "switchToAdmin":                         // specific to BitGroup
      object.setState({subGroupName: value});
      break;
    case "tipAmount":
      //FIXME: enforce decimal?
      object.setState({tipAmount: value});
      break;
    default:
      console.error("CODE ERROR: unrecognized field type: " + field
                + ", with value " + value + ", checked " + checked);
      throw new Error('12000: unrecognized field type: ' + field);
  }
}

// propapates to grandparent's onCancel
// This is shared by multiple builder classes.
const onCancel = (event, object, builderName) => {
  console.log("static (" + builderName + ") onCancel():");
  event.preventDefault();
  object.props.onCancel();
}


class SubGroupOptions extends React.Component {
  constructor(props) {
    super(props);

    this.state = {spawnSub: false, subType: '1'};

    this.checkboxChanged = this.checkboxChanged.bind(this);
    this.subTypeChanged  = this.subTypeChanged.bind(this);
  }

  checkboxChanged(e, d) {
    //console.log("checkboxChanged(): e: ", e);
    console.log("checkboxChanged(): d.checked: ", d.checked);

    // Note that we need to set state locally, and at parent
    this.setState({spawnSub: d.checked});
    this.props.parent.setState({spawnSub: d.checked});

    //FIXME: if not spawning, consider clearing subGroupName (subType too?)
    //       (not crucial)
  }

  subTypeChanged(e) {
    console.log('subTypeChanged: ' + e.target.value);

    // Note that we need to set state locally, and at parent
    this.setState({subType: e.target.value});
    this.props.parent.setState({subType: e.target.value});

    //FIXME: if subType is unnamed, consider clearing subGroupName
    //       (not crucial)
  }

  render() {
    const dtx = JSON.parse( this.props.dtx );
    const outputChosen = this.props.outputChosen;
    const startedAtPost = dtx.outputStates[ outputChosen ].startedAtPost;
    //console.log("SubGroupOptions: startedAtPost (of chosen base-0 output " + outputChosen
    //          + ") is " + startedAtPost)
    if ( startedAtPost !== 0 ) {
      console.log("That output is a subGroup, so, it can't spawn a subgroup");
      return (
        <>
          <Divider />
          <div>Note that since we're already building on a subgroup line, we can't spawn a subgroup (max one level deep).</div>
        </>
      );
    }


    const spawnDisabled = this.state.spawnSub === null || this.state.spawnSub === false;
    const spawnColor = spawnDisabled ? 'lightgrey' : 'black';
    const subType = this.state.subType;
    const namedSubDisabled = this.state.subType === null || this.state.subType === '1';
    const namedSubColor = namedSubDisabled ? 'lightgrey' : 'black';

    return (
      <>
        <Divider />
        <div>You may elect to: &nbsp; &nbsp; &nbsp; <Checkbox label='spawn a SUB group' onChange={this.checkboxChanged}/></div>
        <div style={{color: spawnColor}}>SubGroup type:
        <Form.Group >
          <Form.Field disabled={spawnDisabled} label='named'
                      value='0' checked={subType==='0'}
                      onChange={this.subTypeChanged} type='radio' control='input'/>
          <Form.Field disabled={spawnDisabled} label='un-named'
                      value='1' checked={subType==='1'}
                      onChange={this.subTypeChanged} type='radio' control='input'/>
        </Form.Group>
        </div>
        <div style={{color: namedSubColor}}> Name of sub group: <Input disabled={namedSubDisabled}
                                                                       placeholder='enter a sub group name'
                                                                       field='subGroupName'
                                                                       onChange={ (event, data) => handleFieldChange(event, data, this.props.parent) }/>
        </div>
      </>
    );
  } // SubGroupOptions render()
}

class AdminOptions extends React.Component {
  constructor(props) {
    super(props);

    this.opChanged                 = this.opChanged.bind(this);
    this.opUserSelected            = this.opUserSelected.bind(this);
    this.newUserLevelSelected      = this.newUserLevelSelected.bind(this);
    this.adminGroupCheckboxChanged = this.adminGroupCheckboxChanged.bind(this);

    this.state = {opVal: '0',
                  switchToAdmin: false}
  }

  opChanged(e) {
    console.log('opChanged: ' + e.target.value);
    this.setState({opVal: e.target.value});


    //FIXME: rename opSelectedUser
    //FIXME: pass better values than -1-4

    if ( this.props.parent ) {
      const val = e.target.value;
      console.log("opChanged(): reporting state to parent");
      this.props.parent.setState({adminOption: val});

      if ( val === '1' || val === '0' ) {
        //FIXME: is this where we should do this?
        console.log("NOTE: since we've chosen 'ADD', or 'NONE of these', let's clear the parent's opSelectedUser")
        this.props.parent.setState({opSelectedUser: null});
      }
    } else {
      console.log("opChanged(): WARNING: no parent to which we can report state change");
    }
  }

  // User on which to operate (remove, promote, demote)
  //FIXME: consider using handleFieldChange(). might the parent ever not be set?
  opUserSelected(e, d) {
    //console.log("opUserSelected(): e: ", e);
    console.log("opUserSelected(): d.value: ", d.value);

    if ( this.props.parent ) {
      console.log("opUserSelected(): reporting state to parent");
      this.props.parent.setState({opSelectedUser: d.value});
    } else {
      console.log("opUserSelected(): WARNING: no parent to which we can report state change");
    }
  }

  newUserLevelSelected(e, d) {
    console.log("newUserLevelSelected(): d.value: ", d.value);

    if ( this.props.parent ) {
      console.log("newUserLevelSelected(): reporting state to parent");
      this.props.parent.setState({newUserLevel: d.value});
    } else {
      console.log("newUserLevelSelected(): WARNING: no parent to which we can report state change");
    }
  }

  adminGroupCheckboxChanged(e, d) {
    console.log("adminGroupCheckboxChanged(): d.checked: ", d.checked);

    // Note that we need to set state locally, and at parent
    this.setState({switchToAdmin: d.checked});
    this.props.parent.setState({switchToAdmin: d.checked});
  }

  render() {
    const profiles = JSON.parse(this.props.profiles);
    //console.log("There are " + profiles.length + " to consider operating on");

    const opVal = this.state.opVal;
    //console.log("--->>>>>> BTW opVal is " + opVal);
    const op = [ '--- render? hmm ---', 'add', 'remove', 'promote' ,'demote' ];

    let otherElements = null;
    let pickLevel     = null;

    if ( !this.props || this.props.weAreUser === null ) {
      return  <Divider />;
    }
    const ourLevel = parseInt( profiles[ this.props.weAreUser ].userStatus, 10 );
    let extraNote = null;
    if ( this.state.switchToAdmin ) {
      extraNote = <>Note that until you complete the second step (reverting to a BitGroup contract), users will be unable to post.</>
    }
    let adminGroupOption = null;
    if ( ourLevel === 5 ) { //FIXME: magic number
      adminGroupOption =  <div>
                            <p> </p>
                            <div> As the owner, you may also perform a two-step show-stopping bulk-add operation by:</div>
                            <Checkbox label='switching to an AdminGroup contract' onChange={this.adminGroupCheckboxChanged}/>
                            <p>{extraNote}</p>
                          </div>;
    }

    const statusNames = [ 'probie', 'normie', 'hero', 'mod', 'uber-mod', 'owner' ];

    if ( opVal === '1' ) { // ADD a user

      // Adding a user, so, we'll want to get:  P2PKH, rabinPKH, level (less than us)
      let p2pkhInput    = <>
                            <div>You must supply the new user's P2PKH: &nbsp;
                              <Input placeholder='Enter the P2PKH' field='newUserP2PKH'
                                        onChange={ (event, data) => handleFieldChange(event, data, this.props.parent) }/>
                            </div>
                          </>;
      let rabinPkhInput = <>
                            <div>You must supply the new user's Rabin PKH: &nbsp;
                              <Input placeholder='Enter the Rabin PKH' field='newUserRabinPKH'
                                        onChange={ (event, data) => handleFieldChange(event, data, this.props.parent) }/>
                            </div>
                          </>;
      let userNameInput = <>
                            <div>You must supply the new user's name (max length 32 characters): &nbsp;
                              <Input placeholder='Enter the User Name' field='newUserName'
                                        onChange={ (event, data) => handleFieldChange(event, data, this.props.parent) }/>
                            </div>
                          </>;
      let userLevels = [  { key: 0, value: 0, text: statusNames[0] },
                          { key: 1, value: 1, text: statusNames[1] },
                          { key: 2, value: 2, text: statusNames[2] },
                          { key: 3, value: 3, text: statusNames[3] },
                          { key: 4, value: 4, text: statusNames[4] } ];
      //Note that the new user must have a level lower than OUR level
      if ( ourLevel < 5 ) { //FIXME: magic number
        // remove key 4 (uber-mod)
        userLevels.pop()
      }
      if ( ourLevel < 4 ) { //FIXME: magic number
        // remove key 3 (mod)
        userLevels.pop()
      }

      pickLevel = <div>You must supply the new user's level: &nbsp;
                    <Dropdown onChange={this.newUserLevelSelected}
                              placeholder="Choose the new user's level"
                              selection
                              options={userLevels} />
                  </div>;
      otherElements = <>
                        {userNameInput}
                        {p2pkhInput}
                        {rabinPkhInput}
                        {pickLevel}
                      </>;
    } else if ( opVal === '0' ) {
      const opts = [{ key: 0, value: 0, text: 'N/A' }];
      otherElements = <div style={{color: 'lightgrey'}}>
                        User to <Dropdown disabled placeholder='N/A' selection options={opts} />
                      </div>;
    } else { // Remove, promote, demote a user

      const question = 'choose a user to ' + op[opVal];
      let userListToOperateOn = [];
      let k = 0;
      for ( let i = 0; i < profiles.length; i++ ) {
        if ( i === this.props.weAreUser ) {
          //console.log(" --> operate: skipping user " + i);
          continue;
        }
        const userStatus = parseInt( profiles[i].userStatus, 10);
        if ( ourLevel <= userStatus ) {
          console.log(" --> operate: skipping user " + i + " - level too high: " + userStatus);
          continue;
        }
        const operationText = 'User #' + i + " (current status: " + statusNames[ userStatus ] + ")";

        userListToOperateOn[k] =  { key: i, value: i, text: operationText }
        k++;
      }

      let opOnUserText = <>User to {op[opVal]}:</>;
      let userPickerDisabled = opVal < 1;   // don't choose a user until we know the operation
      const pickColor = userPickerDisabled ? 'lightgrey' : 'black';

      otherElements = <div style={{color: pickColor}}>{opOnUserText} <Dropdown disabled={userPickerDisabled} onChange={this.opUserSelected}
                        placeholder={question}
                        selection
                        options={userListToOperateOn} />
                      </div>;
    }

    //console.log("OUR user level: " + userLevel )
    if ( ourLevel < 3 ) {
      return <Divider />;
    } else {
      return(
        <>
          <p></p>
          <div>You may elect to Add/Remove/Promote/Demote a user (who has a lower status than you).</div>
          <Form.Group>
              <Form.Field label='Add'              type='radio' value='1' control='input' checked={opVal==='1'} onChange={this.opChanged}/> &nbsp;
              <Form.Field label='Remove'           type='radio' value='2' control='input' checked={opVal==='2'} onChange={this.opChanged}/> &nbsp;
              <Form.Field label='Promote'          type='radio' value='3' control='input' checked={opVal==='3'} onChange={this.opChanged}/> &nbsp;
              <Form.Field label='Demote'           type='radio' value='4' control='input' checked={opVal==='4'} onChange={this.opChanged}/> &nbsp;
              <Form.Field label='None of these'    type='radio' value='0' control='input' checked={opVal==='0'} onChange={this.opChanged}/>
          </Form.Group>
          {otherElements}
          {adminGroupOption}
          <Divider />
        </>
      );
    }
  } // AdminOptions render()
}






class BitGroupBuilder extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      spawnBitGroup: false,
      //spawnUpdate: true,
      //spawnSale: false,
      openSecondaryModal: false,
      //content: '',
      //contentLabel: '',
      //refTxId: '',
      //refTxLabel: '',
      //paycode: '',
      rabinPKH: '',
      rabinPrivKeyP: '',
      rabinPrivKeyQ: '',
      selectedUser: null,  // who WE are
      opSelectedUser: null,  // who we may remove, promote, or demote
      userToTip: null,
      tipAmount: 0,
      postText: '',
      adminOption: '0',    // 'None of these' (not adding, removing, promoting, demoting)
      newUserP2PKH: '',
      newUserRabinPKH: '',
      newUserLevel: null,
      newUserName: '',
      spawnSub: false,
      subGroupName: '',
      subType: '1',        // un-named
      switchToAdmin: false
    };
    this.handlePublishSubmit      = this.handlePublishSubmit.bind(this);
    this.buildAndPublish          = this.buildAndPublish.bind(this);
    this.userSelected             = this.userSelected.bind(this);
    this.userToTipSelected        = this.userToTipSelected.bind(this);

    this.preCheckRabinPrivateKeys = this.preCheckRabinPrivateKeys.bind(this);
    this.reUseRabin               = this.reUseRabin.bind(this);
  }

  preCheckRabinPrivateKeys() {
    const dtx = JSON.parse( this.props.dtx );
    const outputChosen = this.props.outputChosen;
    const profiles = dtx.outputStates[outputChosen].userProfiles;

    console.log("BitGroupBuilder preCheckRabinPrivateKeys(): checking on user #" + this.state.selectedUser);
    const prevRabinPKH = profiles[ this.state.selectedUser ].userRabinPKH;
    console.log("BitGroupBuilder preCheckRabinPrivateKeys(): checking against previous PKH " + prevRabinPKH);

    return validateRabinPrivateKeys(this.state.rabinPrivKeyP, this.state.rabinPrivKeyQ, prevRabinPKH)
  }

  async buildAndPublish() {
    console.log("BitGroupBuilder::buildAndPublish() It's time to build a BitGroup tx");
    //console.warn("buildAndPublish(): privKey: " + this.state.bsvPrivKey);
    console.log("BitGroupBuilder::buildAndPublish(): postText: "       + this.state.postText);
    console.log("BitGroupBuilder::buildAndPublish(): (new) rabinPKH: " + this.state.rabinPKH);
    console.log("BitGroupBuilder::buildAndPublish(): user index (us): "+ this.state.selectedUser);
    console.log("BitGroupBuilder::buildAndPublish(): user to tip: "    + this.state.userToTip);
    console.log("BitGroupBuilder::buildAndPublish(): tipAmount: "      + this.state.tipAmount);

    console.log("BitGroupBuilder::buildAndPublish(): adminOption: "    + this.state.adminOption);
    console.log("BitGroupBuilder::buildAndPublish(): opSelectedUser (to remove/promote/demote): "
                                                                       + this.state.opSelectedUser);

    console.log("BitGroupBuilder::buildAndPublish(): newUserP2PKH: "   + this.state.newUserP2PKH);
    console.log("BitGroupBuilder::buildAndPublish(): newUserRabinPKH: "+ this.state.newUserRabinPKH);
    console.log("BitGroupBuilder::buildAndPublish(): newUserLevel: "   + this.state.newUserLevel);
    console.log("BitGroupBuilder::buildAndPublish(): newUserName: "    + this.state.newUserName);


    console.log("BitGroupBuilder::buildAndPublish(): spawnSub: "       + this.state.spawnSub);
    console.log("BitGroupBuilder::buildAndPublish(): subGroupName: "   + this.state.subGroupName);
    console.log("BitGroupBuilder::buildAndPublish(): subType: "        + this.state.subType);

    console.log("BitGroupBuilder::buildAndPublish(): switchToAdmin: "  + this.state.switchToAdmin);

    // rabinPKH  <-- rename to newRabinPKH

    const dtx = JSON.parse(this.props.dtx);
    console.log("BitGroupBuilder::buildAndPublish(): dtx = ", dtx);

    const outIndex = this.props.outputChosen;
    console.log("buildAndPublish(): outIndex = " + outIndex);

    const chosenMode = hexByteToAscii( dtx.outputStates[ outIndex ].mode );
    console.log("buildAndPublish(): chosen mode is " + chosenMode);

    console.log("buildAndPublish(): props.txOnWhichToBuild = " + this.props.txOnWhichToBuild);

  //  const prevRabinPKH = dtx.outputStates[ outIndex ].ownerRabinPKH;
  //  const paramPKH = this.state.rabinPKH;
  //  const reUsePKH = prevRabinPKH === paramPKH;
  //  const spawnSub = this.state.spawnSub;

    console.log("BitGroupBuilder::buildAndPublish(): calling buildOnBitGroup().");

    try {
      const txid = await buildOnABitGroup(dtx.outputStates[outIndex], this.props.txOnWhichToBuild, outIndex,
                              this.state.selectedUser,              //param1WhichUser
                              4,                                    //p2   claimBountyOption  1) finalPost  2) bounty-claim as Owner  4) NONE of the above
                              false,                                //p3   ffwd to claim as owner
                              this.state.switchToAdmin,             //p4   administer to group (adminGroup)
                              this.state.adminOption !== '0',       //p5   perform admin: add/remove/promote/demote
                              parseInt(this.state.adminOption, 10), //p6   '1' add  '2' remove  '3' promote  '4' demote
                              this.state.opSelectedUser,            //p7   other user num  - who to operate on
                              this.state.newUserRabinPKH,           //p8   new user Rabin PKH
                              this.state.newUserP2PKH,              //p9
                              this.state.newUserLevel,              //p10
                              this.state.newUserName,               //p10b  new user name
                              false,                                //p11quit
                              this.state.postText,                  //p12  content to post
                              false,                                //p13  finalPost
                              this.state.spawnSub,                  //p14  spawn a subGroup?
                              this.state.userToTip !== null,        //p15  tip someone?
                              this.state.userToTip,                 //p16  tipee
                              this.state.tipAmount,                 //p17  amount
                              this.state.subGroupName.length > 0,   //p18  NAME the sub-group?
                              this.state.subGroupName,              //p19  NAME of the sub group

                              this.state.rabinPrivKeyP,
                              this.state.rabinPrivKeyQ,
                              this.state.bsvPrivKey
                        );

      // now pass back THIS tx - to make it the txToConsider
      console.log("BitGroupBuilder::buildAndPublish(): we've built a tx ", txid);

      this.props.handleBuildEvent(txid);
    }  catch (error) {
      console.log('Failed in BitGroupBuilder::buildAndPublish()');
      //console.log('Failed on network: ' + NETWORK)
      console.error("stack: " + error.stack);
      printError(error);    // needed?
    }
  }

  async handlePublishSubmit() {  // BitGroupBuilder
    console.log("BitGroupBuilder::handlePublishSubmit(): will trigger opening of key-soliciting modal");

    // Opens secondary modal to solicit keys for signing
    // Results of that (a click of 'Build' or 'CANCEL') emerge at handleRabinKeyModalSubmit()
    //   or a cancel leads to handleRabinKeyModalCancel()
    this.setState({openSecondaryModal: true});
    // May trigger buildAndPublish() after signing
  }

  // set who WE are
  userSelected(e, d) {
    //console.log("userSelected(): e: ", e);
    console.log("userSelected(): d.value: ", d.value);
    this.setState({selectedUser: d.value});
  }

  userToTipSelected(e, d) {
    //console.log("userToTipSelected(): e: ", e);
    console.log("userToTipSelected(): d.value: ", d.value);
    this.setState({userToTip: d.value});
  }

  reUseRabin(event) {
    event.preventDefault();
    const dtx = JSON.parse( this.props.dtx );
    const outputChosen = this.props.outputChosen;
    const profiles = dtx.outputStates[outputChosen].userProfiles

    let currentUserRabinPKH
    if ( this.state.selectedUser !== null ) {
      currentUserRabinPKH = profiles[this.state.selectedUser].userRabinPKH;  // OUR rabin
    }

    console.log("BitGroupBuilder reUseRabin():  will re-use PKH " + currentUserRabinPKH)
    this.setState({rabinPKH: currentUserRabinPKH});
  }

  render() {  // BitGroupBuilder
    const dtx = JSON.parse( this.props.dtx );

    const outputChosen = this.props.outputChosen;
    const profiles = dtx.outputStates[outputChosen].userProfiles
    //console.log("output chosen is " + outputChosen);
    const chosenMode = hexByteToAscii( dtx.outputStates[ outputChosen ].mode );
    const buttonLabel = 'BUILD contract on output of mode ' + chosenMode;
    const limb = hexStringToAscii( dtx.outputStates[ outputChosen ].namePath);

    const statusNames = [ 'probie', 'normie', 'hero', 'mod', 'uber-mod', 'owner' ];
    let userList = [];
    for ( let i = 0; i < profiles.length; i++ ) {
      const userStatus = parseInt( profiles[i].userStatus, 10);
      const userName = 'User #' + i + ", status: " + statusNames[ userStatus ];
      const key =  i;
      userList[i] =  { key: key, value: i, text: userName }
    }

    let userToTipList = [];
    userToTipList[0] =  { key: 0, value: -1, text: ' ---' }
    let k = 1;
    for ( let j = 1; j < profiles.length + 1; j++ ) {
      if ( j === this.state.selectedUser + 1) {
        //console.log(" --> tip: skipping user " + (j-1));  // don't allow tipping yourself
        continue;
      }
      const userStatus = parseInt( profiles[j-1].userStatus, 10);
      const tippingText = 'User #' + (j-1) + ", status: " + statusNames[ userStatus ];
      const key =  j;
      userToTipList[k] =  { key: key, value: j-1, text: tippingText }
      k++;
    }
    const tippingDisabled = this.state.selectedUser === null;
    const tipColor = tippingDisabled ? 'lightgrey' : 'black';
    const amountDisabled = this.state.userToTip === null || this.state.userToTip < 0;
    const amountColor = amountDisabled ? 'lightgrey' : 'black';

    let blurb = <div> Publishing on a 'G' (BitGroup) output is for one of two purposes: posting,
                    or moderating/administering to it. The biggest difference is that OTHER,
                    subscribed users, can post to the line as well. The owner can admit users, and assign
                    them as probies, users, uber/hero users, moderators, or uber moderators.
                    BitGroup contracts exist on a parallel, but finite-length line of transactions. They can also
                    spawn a subGroup (on a parallel, finite-length line). Users can tip other users.

                    As with most transactions, at each step/tx a Rabin PKH is declared (or
                    re-used) for future authentication, and a user uses his secret Rabin
                    Keys to sign against his previously-declared PKH.
                </div>;
    let pickUser =  <Dropdown onChange={this.userSelected}
                      placeholder='Which User are you?'
                      selection
                      options={userList}
                    />
    let tipUser = <Dropdown onChange={this.userToTipSelected}
                    disabled={tippingDisabled}
                    placeholder='Tip a user?'
                    selection
                    options={userToTipList}
                  />
    let currentUserRabinPKH
    if ( this.state.selectedUser !== null ) {
      currentUserRabinPKH = profiles[this.state.selectedUser].userRabinPKH;  // OUR rabin
    }

    let userPkhClause = this.state.selectedUser === null ? <div>Your current PKH: ???</div> : <div> For reference, your current Rabin PKH: {currentUserRabinPKH}</div>
    let rabinPKHLabel = 'Rabin PKH to Declare:';
    let rabinPKHPlaceholder = 'Enter a Rabin PKH for the NEXT publication.';
    let action = <div> Post or administer on a namePath/limb '{limb}' BitGroup tx</div>

    //FIXME: if ADDing a user, require THOSE fields are good (p2pkh, rabin, level, name)
    //FIXME: if removing, promoting, demoting, require THAT field is good (other user)
    const allNewUserFieldsSpecified = this.state.newUserRabinPKH.length === 40
                                    && this.state.newUserP2PKH.length === 40
                                    && this.state.newUserLevel !== null
                                    && this.state.newUserName.length < 33
                                    && this.state.newUserName.length > 0
    const disabledForOpAddReasons = this.state.adminOption === '1' ? !allNewUserFieldsSpecified : false;
    const disableForOpRemovePromoteDemoteReasons = ( this.state.adminOption === '2'
                                                  || this.state.adminOption === '3'
                                                  || this.state.adminOption === '4' )
                                                      && this.state.opSelectedUser === null;
    //console.log("render(): BTW: state.opSelectedUser is " + this.state.opSelectedUser);
    const bitGrpBuildBtnDisabled = this.state.selectedUser === null || this.state.rabinPKH.length !== 40
                            || disabledForOpAddReasons || disableForOpRemovePromoteDemoteReasons;

    const disableReUseButton = !currentUserRabinPKH || currentUserRabinPKH === null || this.state.rabinPKH === currentUserRabinPKH;
    const reUseButton = <Button disabled={disableReUseButton} content='Re-Use Current PKH' positive onClick={this.reUseRabin}/>;

    //NOTICE that the KeySolicitingModal has a 'key' property
    return (
      <>
        <Modal.Content image scrolling>
        <Container>
        <Modal.Description>

        {action}
        <Divider />
        {blurb}
        <Divider />
        <Form onSubmit={this.handlePublishSubmit}>

          You are: {pickUser}
          <AdminOptions profiles={JSON.stringify(profiles)} weAreUser={this.state.selectedUser}
                        parent={this}/>

          <div>You may post a message to the Group:</div>
          <TextArea placeholder='Enter a message'
                        field='postText' onChange={ (event, data) => handleFieldChange(event, data, this) }/>

          <SubGroupOptions parent={this} dtx={this.props.dtx} outputChosen={this.props.outputChosen}/>

          <Divider />
          <div style={{color: tipColor}}>
              <div>You may tip a user</div>
              Tip User: {tipUser}
          </div>
          <div style={{color: amountColor}}>
            Amount to tip: <Input disabled={amountDisabled} placeholder='amount to tip'
                                field='tipAmount'
                                onChange={ (event, data) => handleFieldChange(event, data, this) }/> (satoshis)
          </div>

          <Divider />
            <div>Rabin PKHs help authenticate transaction publishing rights on a Limb.</div>
            <div>BitGroup transactions are different, in that they allow multiple users to declare their own Rabin PKHs.</div>
            {userPkhClause}
            <div>You may declare a new Rabin PKH to authenticate your NEXT transaction, or you may decide to re-use your current Rabin PKH.</div>
            <Input field='rabinPKH' label={rabinPKHLabel} placeholder={rabinPKHPlaceholder}
                          value={this.state.rabinPKH} style={{width: "350px"}}
                          onChange={ (event, data) => handleFieldChange(event, data, this) }/>
            &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
            &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
            {reUseButton}&nbsp;
            <RabinUtility disabled={this.state.rabinPKH.length > 0}
                parent={this}
            />
        </Form>

        </Modal.Description>
        </Container>
        </Modal.Content>

        <Modal.Actions className="modaledge">
          <Button disabled={bitGrpBuildBtnDisabled} content={buttonLabel} positive onClick={this.handlePublishSubmit}/>
          <Button onClick={ (event) => onCancel(event, this, 'bitgroupBuilder') } content='CANCEL' negative/>
        </Modal.Actions>

        <KeySolicitingModal onInputChange={ (event, data) => handleFieldChange(event, data, this) }
                            openSecondaryModal={this.state.openSecondaryModal}
                            openBadKeysModal={this.state.openThirdModal}
                            parent={this}
                            closeThird={ () => closeOurThird(this) }
                          ownerRabinPKH={currentUserRabinPKH}
                          key={currentUserRabinPKH}
                            //checkFilled={ () => bothPrivKeysAreFilled(this) }
                            onSubmit={ (event) => handleRabinKeyModalSubmit(event, this, 'bitgroupBuilder') }
                            onCancel={ (event) => handleRabinKeyModalCancel(event, this)} />
      </>
    );
  }  // BitGroupBuilder render()
}

class AdminGroupBuilder extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      openSecondaryModal: false,
      rabinPKH: '',
      rabinPrivKeyP: '',
      rabinPrivKeyQ: '',
      addedUsers: '',
      newUserNames: '',

      newUserP2PKH: '',
      newUserRabinPKH: '',
      newUserLevel: null
    };

    this.handlePublishSubmit      = this.handlePublishSubmit.bind(this);
    this.buildAndPublish          = this.buildAndPublish.bind(this);
    this.addThisUser              = this.addThisUser.bind(this);
    this.newUserLevelSelected     = this.newUserLevelSelected.bind(this)
    this.preCheckRabinPrivateKeys = this.preCheckRabinPrivateKeys.bind(this);
    this.reUseRabin               = this.reUseRabin.bind(this);
  }

  preCheckRabinPrivateKeys() {
    const dtx = JSON.parse( this.props.dtx );
    const outputChosen = this.props.outputChosen;
    const profiles = dtx.outputStates[outputChosen].userProfiles;

    const prevRabinPKH = profiles[ 0 ].userRabinPKH;  //WARNING: assumes owner is always #0
    console.log("AdminGroupBuilder preCheckRabinPrivateKeys(): checking against previous PKH " + prevRabinPKH);

    return validateRabinPrivateKeys(this.state.rabinPrivKeyP, this.state.rabinPrivKeyQ, prevRabinPKH)
  }

  async buildAndPublish() {  //AdminGroupBuilder
    console.log("AdminGroupBuilder::buildAndPublish() It's time to build an AdminGroup tx");
    //console.warn("buildAndPublish(): privKey: " + this.state.bsvPrivKey);

    console.log("bunch of users to add: " + this.state.addedUsers);
    // we may decide to allow adding 0 users
    let addedUsersArray = [];
    if ( this.state.addedUsers.length !== 0 ) {
      addedUsersArray = JSON.parse( this.state.addedUsers );
    }
    console.log("  num to add: " + addedUsersArray.length);

    // we may decide to allow adding 0 users
    let newNamesArray = [];
    if ( this.state.newUserNames.length !== 0 ) {
      newNamesArray = JSON.parse( this.state.newUserNames );
    }


    if ( newNamesArray.length !== addedUsersArray.length ) {
      console.error("New names array size doesn't match size of new profiles array");
      throw new Error("16301: inconsistent names/profiles array size");
    }

    // rabinPKH  <-- rename to newRabinPKH

    const dtx = JSON.parse(this.props.dtx);
    console.log("AdminGroupBuilder::buildAndPublish(): dtx = ", dtx);

    const outIndex = this.props.outputChosen;
    console.log("buildAndPublish(): outIndex = " + outIndex);

    const chosenMode = hexByteToAscii( dtx.outputStates[ outIndex ].mode );
    console.log("buildAndPublish(): chosen mode is " + chosenMode);

    console.log("buildAndPublish(): props.txOnWhichToBuild = " + this.props.txOnWhichToBuild);

    console.log("AdminGroupBuilder::buildAndPublish(): calling buildOnAdminGroup().");

    try {
      const txid = await buildOnTheAdminGroup(dtx.outputStates[outIndex], this.props.txOnWhichToBuild, outIndex,
                              addedUsersArray.length,              //param1numProfilesToAdd
                              addedUsersArray,
                              newNamesArray,

                              this.state.rabinPrivKeyP,
                              this.state.rabinPrivKeyQ,
                              this.state.bsvPrivKey
                        );

      // now pass back THIS tx - to make it the txToConsider
      console.log("AdminGroupBuilder::buildAndPublish(): we've built a tx ", txid);

      this.props.handleBuildEvent(txid);
    }  catch (error) {
      console.log('Failed in AdminGroupBuilder::buildAndPublish()');
      //console.log('Failed on network: ' + NETWORK)
      console.error("stack: " + error.stack);
      printError(error);    // needed?
    }
  }

  async handlePublishSubmit() {  // AdminGroupBuilder
    console.log("AdminGroupBuilder::handlePublishSubmit(): will trigger opening of key-soliciting modal");

    // Opens secondary modal to solicit keys for signing
    // Results of that (a click of 'Build' or 'CANCEL') emerge at handleRabinKeyModalSubmit()
    //   or a cancel leads to handleRabinKeyModalCancel()
    this.setState({openSecondaryModal: true});
    // May trigger buildAndPublish() after signing
  }

  // Add the specified user to state. Clear settings for the next potential user entry.
  addThisUser() {
    // CLEAR the inputs displayed
    document.getElementById('inP2PKH').value = '';
    document.getElementById('inRabin').value = '';
    document.getElementById('inUserName').value = '';

    console.log("adminGroup addThisUser(): shall add user with P2PKH "     + this.state.newUserP2PKH);
    console.log("adminGroup addThisUser(): shall add user with Rabin PKH " + this.state.newUserRabinPKH);
    console.log("adminGroup addThisUser(): shall add user with level "     + this.state.newUserLevel);
    console.log("adminGroup addThisUser(): shall add user with name "      + this.state.newUserName);

    let addedUsersList = [];
    let addedUsersJSON = this.state.addedUsers;
    if ( addedUsersJSON.length > 0 ) {
      addedUsersList = JSON.parse( addedUsersJSON );
      console.log("addThisUser(): We've already got " + addedUsersList.length + " users ready to add.");
    } else {
      console.log("addThisUser(): THERE ARE AS YET NO USERS. let's add the first...");
    }

    let userNames = [];
    let userNamesJSON = this.state.newUserNames;
    if ( userNamesJSON && userNamesJSON.length > 0 ) {
      userNames = JSON.parse( userNamesJSON );
      console.log("addThisUser(): we've already got '" + userNames.length + " names ready to add.");
    } else {
      console.log("addThisUser(): THERE ARE AS YET NO user names. let's add the first...");
    }

  //FIXME: use the 'current' block for userRecentPostBlock
    let newUser = { userP2PKH: this.state.newUserP2PKH,
                    userPaidBounty: "00",
                    userRabinPKH: this.state.newUserRabinPKH,
                    userRecentPostBlock: "00000000",
                    userStatus: this.state.newUserLevel};
    addedUsersList.push(newUser);

    userNames.push(this.state.newUserName);

    console.log("addThisUser(): New list of added users:", addedUsersList);
    console.log("addThisUser(): New list of user names:", userNames);
    // CLEAR the input values (so the 'Add this user' button gets disabled )
    this.setState( {addedUsers: JSON.stringify(addedUsersList),
                    newUserNames: JSON.stringify(userNames),
                    newUserP2PKH: '',
                    newUserRabinPKH: '',
                    newUserName: ''});
  }

  newUserLevelSelected(e, d) {
    console.log("adminGroup newUserLevelSelected(): d.value: ", d.value);

    this.setState({newUserLevel: d.value});
  }

  reUseRabin(event) {
    event.preventDefault();
    const dtx = JSON.parse( this.props.dtx );
    const outputChosen = this.props.outputChosen;
    const profiles = dtx.outputStates[outputChosen].userProfiles

    //let currentUserRabinPKH
    //if ( this.state.selectedUser !== null ) {
    let currentUserRabinPKH = profiles[0].userRabinPKH;  // OWNER rabin
    //}

    console.log("AdminGroupBuilder reUseRabin():  will re-use PKH " + currentUserRabinPKH)
    this.setState({rabinPKH: currentUserRabinPKH});
  }

  render() {  // AdminGroupBuilder
    const dtx = JSON.parse( this.props.dtx );

    const outputChosen = this.props.outputChosen;
    const profiles = dtx.outputStates[outputChosen].userProfiles
    //console.log("output chosen is " + outputChosen);
    const chosenMode = hexByteToAscii( dtx.outputStates[ outputChosen ].mode );
    const buttonLabel = 'BUILD contract on output of mode ' + chosenMode;
    const limb = hexStringToAscii( dtx.outputStates[ outputChosen ].namePath);

    let blurb = <div> Occasionally the owner of a BitGroup may wish to add multiple user profiles. Rather
                    than adding each one separately, he may elect to first switch the BitGroup contract
                    to an AdminGroup contract (output mode 'g'). This can only be done by the asset owner.
                    Publishing on an AdminGroup output is a one-shot affair. It addition to adding
                    multiple profiles, it simultaneously reverts to a BitGroup contract. This too can ONLY
                    be done by the owner, so, until that happens NO user can post to a group that is
                    currently blocked by an AdminGroup output. The owner can add users of all types:
                    probies, normies, uber/hero users, moderators, and uber moderators. AdminGroup
                    operations can also occur on a subgroup.
                </div>;
    //FIXME: double-check this is the OWNER (level 5)
    let currentUserRabinPKH = profiles[0].userRabinPKH;  // OUR rabin (the owner)

    let userPkhClause = <div> For reference, the current OWNER Rabin PKH: {currentUserRabinPKH}</div>
    let rabinPKHLabel = 'Rabin PKH to Declare:';
    let rabinPKHPlaceholder = 'Enter a Rabin PKH for the NEXT publication.';
    let action = <div> The OWNER may add zero or more users to a namePath/limb '{limb}' BitGroup tx in a single tx.</div>

    const adminGrpBuildBtnDisabled = this.state.rabinPKH.length !== 40;
    let userLevels = [  { key: 0, value: '00', text: 'probie'   },
                        { key: 1, value: '01', text: 'normie'   },
                        { key: 2, value: '02', text: 'hero'     },
                        { key: 3, value: '03', text: 'mod'      },
                        { key: 4, value: '04', text: 'uber-mod' } ];
    let dropDownList = [];
    let addedUsersList = [];
    let addedUsersJSON = this.state.addedUsers;
    if ( addedUsersJSON.length > 0 ) {
      addedUsersList = JSON.parse( addedUsersJSON );
      console.log("render(): We've got " + addedUsersList.length + " users ready to add.");
      for ( var i = 0; i < addedUsersList.length; i++ ) {
        const p2pkh = addedUsersList[i].userP2PKH;
        const p2pkhLen = p2pkh.length;
        const newP2PKH = p2pkh.substring(0, 8)
                            + "..."
                         + p2pkh.substring(p2pkhLen-8, p2pkhLen);
        const rabin = addedUsersList[i].userRabinPKH
        const rabinLen = rabin.length;
        const newRab   = rabin.substring(0,8)
                         + "..."
                         + rabin.substring(rabinLen-8, rabinLen)

        const newLevel = addedUsersList[i].userStatus;
        const intLevel = parseInt(newLevel, 10);
        //console.log("here's one user we're ready to add (#" + (i+1) + "):");
        //console.log("  p2pkh: " + newP2PKH);
        //console.log("  userRabin: " + newRab);
        //console.log("  userLevel: " + newLevel);
        const levelDescription = userLevels[intLevel].text;

        dropDownList.push( {
              key: i,
              text: (<div>{i+1}: &nbsp;&nbsp; p2pkh: {newP2PKH} &nbsp;&nbsp;
                          rabinPKH: {newRab} &nbsp;&nbsp;
                          level: {newLevel} ({levelDescription})</div>),
              value: i }
            );
      }
    }

    const countOfNewUsers = <>So far you've specified {addedUsersList.length} users to be added:</>;
    //  onChange={this.handleBuildChange}
    //  onMouseDown={this.handleBuildClick}
    let alreadyEnteredUsers =
          <div style={{height:"200px"}}>
            <Menu compact>
              <Dropdown selection fluid open text='Users to add'
                        options={dropDownList}
                        style={{background:"lightgrey",fontWeight:"bold",width:"610px"}}/>
            </Menu>
          </div>;

    let nameInput    = <>
                          <div>You must supply a new user's name: &nbsp;
                            <Input placeholder='Enter the User name' id='inUserName' field='newUserName'
                                      onChange={ (event, data) => handleFieldChange(event, data, this) }/>
                          </div>
                        </>;
    let p2pkhInput    = <>
                          <div>You must supply a new user's P2PKH: &nbsp;
                            <Input placeholder='Enter the P2PKH' id='inP2PKH' field='newUserP2PKH'
                                      onChange={ (event, data) => handleFieldChange(event, data, this) }/>
                          </div>
                        </>;
    let rabinPkhInput = <>
                          <div>You must supply a new user's Rabin PKH: &nbsp;
                            <Input placeholder='Enter the Rabin PKH' id='inRabin' field='newUserRabinPKH'
                                      onChange={ (event, data) => handleFieldChange(event, data, this) }/>
                          </div>
                        </>;

    let pickLevel = <div>You must supply a new user's level: &nbsp;
                      <Dropdown onChange={this.newUserLevelSelected} id='inLevel'
                                placeholder="Choose the new user's level"
                                selection
                                options={userLevels} />
                    </div>;
    const isDisabled = this.state.newUserP2PKH.length !== 40
                      || this.state.newUserRabinPKH.length !== 40
                      || this.state.newUserLevel === null
                      || this.state.newUserName.length < 1;
    const addUserButton = <>
                  <Button disabled={isDisabled} type='submit' content='Add this user' positive onClick={this.addThisUser}/>
                          </>;
    let otherElements = <>
                      {countOfNewUsers}
                      {alreadyEnteredUsers}
                      <Divider />
                      Enter the important parameters for a new user to be added:
                      {nameInput}
                      {p2pkhInput}
                      {rabinPkhInput}
                      {pickLevel}
                      {addUserButton}
                    </>;

    const disableReUseButton = this.state.rabinPKH === currentUserRabinPKH;
    const reUseButton = <Button disabled={disableReUseButton} content='Re-Use Current PKH' positive onClick={this.reUseRabin}/>;

    return (
      <>
        <Modal.Content image scrolling>
        <Container>
        <Modal.Description>

        {action}
        <Divider />
        {blurb}
        <Divider />
        <Form onSubmit={this.handlePublishSubmit}>

          {otherElements}
          <Divider />
          <div>Rabin PKHs help authenticate transaction publishing rights on a Limb.</div>
          {userPkhClause}
          <div>You may declare a new Rabin PKH to authenticate your NEXT transaction, or you may decide to re-use your current Rabin PKH.</div>
          <Input field='rabinPKH' label={rabinPKHLabel} placeholder={rabinPKHPlaceholder}
                        value={this.state.rabinPKH} style={{width: "355px"}}
                        onChange={ (event, data) => handleFieldChange(event, data, this) }/>
          &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
          &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;
          {reUseButton}&nbsp;
          <RabinUtility disabled={this.state.rabinPKH.length > 0}
              parent={this}
          />
        </Form>

        </Modal.Description>
        </Container>
        </Modal.Content>

        <Modal.Actions className="modaledge">
          <Button disabled={adminGrpBuildBtnDisabled} content={buttonLabel} positive onClick={this.handlePublishSubmit}/>
          <Button onClick={ (event) => onCancel(event, this, 'admingroupBuilder') } content='CANCEL' negative/>
        </Modal.Actions>

        <KeySolicitingModal onInputChange={ (event, data) => handleFieldChange(event, data, this) }
                            openSecondaryModal={this.state.openSecondaryModal}
                            openBadKeysModal={this.state.openThirdModal}
                            parent={this}
                            closeThird={ () => closeOurThird(this) }
                            ownerRabinPKH={currentUserRabinPKH}
                            //checkFilled={ () => bothPrivKeysAreFilled(this) }
                            onSubmit={ (event) => handleRabinKeyModalSubmit(event, this, 'admingroupBuilder') }
                            onCancel={ (event) => handleRabinKeyModalCancel(event, this)} />
      </>
    );
  } // AdminGroupBuilder render()
}

class DialogBuilder extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      openSecondaryModal: false,
      content: '',
      rabinPrivKeyP: '',
      rabinPrivKeyQ: '',
      foundOwnerRabin: false,
      foundGuestRabin: false,
      pval: '0',
      chosenRabinPKH: null,
      encryptMsg: false
    };

    this.handlePublishSubmit      = this.handlePublishSubmit.bind(this);
    this.buildAndPublish          = this.buildAndPublish.bind(this);
    this.preCheckRabinPrivateKeys = this.preCheckRabinPrivateKeys.bind(this);
    this.participantChanged       = this.participantChanged.bind(this);
  }

  // we pre-check against BOTH owner and guest
  preCheckRabinPrivateKeys() {
    const dtx = JSON.parse( this.props.dtx );
    const outputChosen = this.props.outputChosen;
    const ownerRabinPKH = dtx.outputStates[outputChosen].ownerRabinPKH;
    const guestRabinPKH = dtx.outputStates[outputChosen].guestRabinPKH;

    const validForOwner = validateRabinPrivateKeys(this.state.rabinPrivKeyP, this.state.rabinPrivKeyQ, ownerRabinPKH)
    console.log("DialogBuilder preCheckRabinPrivateKeys(): checked against owner rabin PKH: " + validForOwner);

    const validForGuest = validateRabinPrivateKeys(this.state.rabinPrivKeyP, this.state.rabinPrivKeyQ, guestRabinPKH)
    console.log("DialogBuilder preCheckRabinPrivateKeys(): checked against guest rabin PKH: " + validForGuest);

    return validForOwner || validForGuest
  }

  async buildAndPublish() {  // DialogBuilder

    console.log("DialogBuilder::buildAndPublish(): content: " + this.state.content);
    //console.warn("buildAndPublish(): privKey: " + this.state.bsvPrivKey);
    console.log("buildAndPublish(): chosenRabinPKH: "      + this.state.chosenRabinPKH);

    //console.log("buildAndPublish(): rabinPrivKeyP: " + this.state.rabinPrivKeyP);
    //console.log("buildAndPublish(): rabinPrivKeyQ: " + this.state.rabinPrivKeyQ);

    const dtx = JSON.parse(this.props.dtx);
    console.log("DialogBuilder::buildAndPublish(): dtx = ", dtx);

    const outIndex = this.props.outputChosen;
    console.log("buildAndPublish(): outIndex = " + outIndex);

    const chosenMode = hexByteToAscii( dtx.outputStates[ outIndex ].mode );
    console.log("buildAndPublish(): chosen mode is " + chosenMode);

    console.log("buildAndPublish(): props.txOnWhichToBuild = " + this.props.txOnWhichToBuild);

    console.warn("buildAndPublish(): encryptMsg? " + this.state.encryptMsg)

    let contentToSend = this.state.content
    if ( this.state.encryptMsg ) {
      console.error("buildAndPublish(): we should ENCRYPT")
      //console.warn("OUR priv key: ", this.state.bsvPrivKey)
      console.warn(" dtx: ", dtx)
      console.warn("  dialogMostRecentOwnerPubKey:    " + dtx.dialogMostRecentOwnerPubKey)
      console.warn("  dialogMostRecentVisitorPubKey: " + dtx.dialogMostRecentVisitorPubKey)

      let pubKeyToUse
      if ( this.state.newSender === 'guest' ) {
        pubKeyToUse = dtx.dialogMostRecentOwnerPubKey
        console.warn("Guest is sending, so, will use OWNER pubkey: " + pubKeyToUse)
      } else if ( this.state.newSender === 'owner' ) {
        pubKeyToUse = dtx.dialogMostRecentVisitorPubKey
        console.warn("Owner is sending, so, will use GUEST pubkey: " + pubKeyToUse)
      } else {
        console.warn("Hmm. Not sure which participant you are. We can't build a tx yet.")
        throw new Error("73113: CODING ERROR: not sure which participant is posting")
      }

      console.warn("step 1. pubKeyToUse: " + pubKeyToUse)

      const pubToUseBuffer = Buffer.from( pubKeyToUse, 'hex')
      //console.warn("pubToUseBuffer: ", pubToUseBuffer)

      const privKeyHex = new bsv.PrivateKey.fromWIF(this.state.bsvPrivKey)
      //console.log("privKeyHex: ", privKeyHex.toHex())
      const privToUseBuffer = Buffer.from( privKeyHex.toHex(), 'hex')
      //console.warn("privToUseBuffer: ", privToUseBuffer)


      const sharedKey = eccryptoJS.derive(
        privToUseBuffer,
        pubToUseBuffer
      );
      //console.warn("shared Key: ", sharedKey.toString('hex'))

      console.warn("Will encrypt this hex message: " + this.state.content)

      const aesKey = sharedKey
      const iv = eccryptoJS.randomBytes(16)
      const msgBytes = eccryptoJS.utf8ToBuffer(this.state.content);
      const ciphertext = await eccryptoJS.aesCbcEncrypt(iv, aesKey, msgBytes);

      const decrypted = await eccryptoJS.aesCbcDecrypt(iv, aesKey, ciphertext);
      console.log("decrypted ascii message: " + decrypted)
      if ( Buffer.from(decrypted).toString('hex') !== Buffer.from(this.state.content).toString('hex') ) {
        console.error("Decrypted doesn't match the original")
        console.error("Decrypted length: " + decrypted)
        console.error("original length: " + this.state.content.length)
        console.error("decrypted hex: " + Buffer.from(decrypted).toString('hex'))
        console.error("original hex:  " + Buffer.from(this.state.content).toString('hex'))
        alert("Read the logs. Trouble encrypting+decrypting. Won't send the transaction")
        return
      }

/* *
      const hexCipherOnly = Buffer.from(ciphertext).toString('hex')
      console.log("BTW: cipher text is this many bytes: " + (hexCipherOnly.length/2))
      console.log("BTW: hex cipher: " + hexCipherOnly + '\n')

      const hexIvOnly = Buffer.from(iv).toString('hex')
      console.log("BTW: iv is this many bytes: " + (hexIvOnly.length/2))
      console.log("IV: " + hexIvOnly + '\n')
/* */

      // prepend PAYLOAD_ESCAPE_FLAG ('*$^&'), and flagged type (encryption: 'EC'),
      // 16 bytes of initialization vector, then the ciphertext
      const buf1 = Buffer.from( PAYLOAD_ESCAPE_FLAG +'EC' )
      const buf2 = Buffer.from( iv )
      const buf3 = Buffer.from( ciphertext )
      const bufArray = [buf1, buf2, buf3]
      const completeBuff = Buffer.concat(bufArray)
      const buffCompleteHexString = Buffer.from(completeBuff).toString('hex')
      console.log("completeBuffHex: ", buffCompleteHexString)
      console.log("len completeBuffHex: ", buffCompleteHexString.length/2)

/* *
      const buff1HexString = Buffer.from(buf1).toString('hex')
      const buff2HexString = Buffer.from(buf2).toString('hex')
      const buff3HexString = Buffer.from(buf3).toString('hex')
      console.log("buf1Hex (flag+type EC): ", buff1HexString)
      console.log("buf2Hex (iv): ", buff2HexString)
      console.log("buf3Hex: (ciphertext)", buff3HexString)
      console.log("len buf1Hex: ", buff1HexString.length/2)
      console.log("len buf2Hex: ", buff2HexString.length/2)
      console.log("len buf3Hex: ", buff3HexString.length/2)
/* */

      contentToSend = buffCompleteHexString
      //alert("encryption went alright? cipherAsciiToSend: " + contentToSend)
    } else {  // .encryptMsg
      contentToSend = Buffer.from(this.state.content).toString('hex')
    }

    console.log("DialogBuilder::buildAndPublish(): calling buildOnDialog().");

    console.log("buildAndPublish(): FIXME: query the actual block #. Use it to guide/set some parameters here.");

    try {
      const txid = await buildOnDialog(dtx.outputStates[outIndex], this.props.txOnWhichToBuild, outIndex,
                            false,                  // p1 - claimBounty instead of publishing? - only if newBlockInt is greater than the deadlineInt
                            true,                   // p2 - publish content?
                            contentToSend,     // p3 - the content (maybe encrypted)
                    this.state.pval, //        this.whichParticipant,  // owner or guest
                    dtx.outputStates[outIndex].maxBlock - 2, //FIXME: using a mostly-arbitrary block height
                    false,//        this.state.finalPost,   // p9 - final post?

                            this.state.rabinPrivKeyP,
                            this.state.rabinPrivKeyQ,
                            this.state.bsvPrivKey);

      // now pass back THIS tx - to make it the txToConsider
      console.log("DialogBuilder::buildAndPublish(): we've built a tx ", txid);

      this.props.handleBuildEvent(txid);
    }  catch (error) {
      console.log('Failed in DialogBuilder::buildAndPublish()');
      //console.log('Failed on network: ' + NETWORK)
      console.error("stack: " + error.stack);
      printError(error);    // needed?
    }
  } // buildAndPublish()

  async handlePublishSubmit() {  // DialogBuilder
    console.log("DialogBuilder::handlePublishSubmit():  will trigger opening of key-soliciting modal");

    // Opens secondary modal to solicit keys for signing
    // Results of that (a click of 'Build' or 'CANCEL') emerge at handleRabinKeyModalSubmit()
    //   or a cancel leads to handleRabinKeyModalCancel()
    this.setState({openSecondaryModal: true});
    // May trigger buildAndPublish() after signing
  }

  participantChanged = (e) => {

    // '1': owner     '2': guest    '0': not yet chosen/decided
    const val = e.target.value

    const dtx = JSON.parse( this.props.dtx );
    const outputChosen = this.props.outputChosen;

    let chosenRabinPKH = null
    let sender = 'not yet chosen'
    if ( val === '1' ) {
      console.warn("DialogBuilder: You identified that you are the owner.")
      chosenRabinPKH = dtx.outputStates[ outputChosen ].ownerRabinPKH;
      sender = 'owner'
    } else if ( val === '2' ) {
      console.warn("DialogBuilder: You identified that you are the guest.")
      chosenRabinPKH = dtx.outputStates[ outputChosen ].guestRabinPKH;
      sender = 'guest'
    } else {
      this.setState({pval: val});
      return
    }

    this.setState({pval: val, chosenRabinPKH: chosenRabinPKH, newSender: sender});
  }

  async componentDidMount() {
    const dtx = JSON.parse( this.props.dtx );
    const outputChosen = this.props.outputChosen;
    const ownerRabinPKH = dtx.outputStates[ outputChosen ].ownerRabinPKH;
    const guestRabinPKH = dtx.outputStates[ outputChosen ].guestRabinPKH;
    const ownerOwnerCount   = dtx.outputStates[ outputChosen ].ownerOwnerCount
    const visitorOwnerCount = dtx.outputStates[ outputChosen ].visitorOwnerCount

    let chosen
    let pval = 0

    console.error("DialogBuilder: IMPLEMENT REMOVAL of old owner/visitor choosing logic")
    console.error("DialogBuilder: will check domain registrations instead...")
    console.error("DialogBuilder: FIXME: maybe have ContractBuilder get the domainsArray, and pass to each builder? TBD")
    const db = await openDB();
    const domainsArray = await findAllDomainsOfMine(db)

    console.error("DialogBuilder componentDidMount(): domains: ", domainsArray)

    // Check all of the domains we 'own' - get their name and ownerCount
    // Do any of them match the ownerName or visitorName of the Dialog
    // on which we're building?
    let weAreTheOwner = false
    let weAreTheVisitor = false
    let sender = ''
    for ( let i = 0; i < domainsArray.length; i++ ) {
      const thisDomainName = domainsArray[i].name
      const thisOwnerCount = domainsArray[i].ownerCount
      console.log(" - domain #" + i)

      if ( thisDomainName === dtx.outputStates[0].ownerName &&
           thisOwnerCount === ownerOwnerCount
          ) {
        console.error("    We may have a name match with OWNER of this dialog")
        weAreTheOwner = true
        pval = 1
        chosen = ownerRabinPKH
        sender = 'owner'
      } else if ( thisDomainName === dtx.outputStates[0].visitorName &&
                  thisOwnerCount === visitorOwnerCount
          ) {
        console.error("    We may have a name match with VISITOR of this dialog")
        weAreTheVisitor = true
        pval = 2
        chosen = guestRabinPKH
        sender = 'guest'
      } else {
        console.error("DBuilder cDM(): entry " + i + " didn't match, BTW")
      }
    }

    console.error("Was this code (below) ever useful? What are we really doing in contractBuilder's componentDidMount()? <======")
    if ( weAreTheOwner && weAreTheVisitor ) {
      console.error("BTW: dtx: ", this.props.dtx)
      alert("The componentDidMount() for contractBuilder is UNHAPPY, but it's not clear it's doing something useful. In fact, it might be doing something INCORRECT.")
      //throw new Error("11766: both owner and visitor?")
    }

    this.setState({ foundOwnerRabin: weAreTheOwner,
                    foundGuestRabin: weAreTheVisitor,
                    chosenRabinPKH:  chosen,
                    pval:            pval,  //participant value: 1: owner   2: guest
                    newSender:       sender
                  })

  } // componentDidMount()

  render() {  // DialogBuilder
    const dtx = JSON.parse( this.props.dtx );
    const outputChosen = this.props.outputChosen;

    const chosenMode = hexByteToAscii( dtx.outputStates[ outputChosen ].mode );
    const buttonLabel = 'BUILD contract on output of mode ' + chosenMode;

    let blurb = <div> Only two participants can publish/converse on a 'D' (Dialog) output.
                    It's a POTENTIALLY private, certainly closed, conversation.

                    Throughout the conversation, the same two Rabin PKHs can be used
                    for message/tx authentication, and the two participants use
                    their secret Rabin Keys to sign the messages against their PKH.
                    The Rabin PKHs may not be updated during the Dialog.
                </div>;

    let action = <div> Publish on a Dialog (namePath/limb '?') tx</div>  // {limb}

    const foundOwnerRabin = this.state.foundOwnerRabin
    const foundGuestRabin = this.state.foundGuestRabin
    let foundBoth = false
    let userMustChoose = false

    if ( !foundOwnerRabin && !foundGuestRabin ) {
      // neither Rabin found. User must choose one, and provide keys
      console.warn("DialogBuilder: You have owner, nor visitor domains registered. You must tell us WHICH participant you are.")
      userMustChoose = true
    } else if ( foundOwnerRabin && !foundGuestRabin ) {
      // found owner keys
      console.warn("DialogBuilder: We've found you have the owner domain registered. You are the owner.")
    } else if ( !foundOwnerRabin && foundGuestRabin ) {
      // found guest keys
      console.warn("DialogBuilder: We've found you have the visitor domain registered. You are the guest.")
    } else {
      // found BOTH. User must choose one
      console.warn("You have keys to BOTH the owner, and the visitor domains registered. You must choose which to use.")
      userMustChoose = true
      foundBoth = true
    }

    const DialogBuildBtnDisabled = this.state.chosenRabinPKH === null;
      //this.state.rabinPKH.length !== 40

    const foundBothMention = foundBoth ?
                                    <>
                                      <p>We found you have saved Rabin keys for BOTH owner and guest. You must choose which to use.</p>
                                    </>
                                :
                                    null
    let participantChooser = userMustChoose ?
                              <>
                                  <div>
                                    <p></p>
                                    <b> Which participant are you? </b>
                                    {foundBothMention}
                                    <div>Choose which participant you are:</div>
                                    <Form.Group>
                                      <Form.Field checked={this.state.pval==='1'} label='Owner' value='1' control='input' type='radio' onChange={this.participantChanged}/> &nbsp; &nbsp;
                                      <div> &nbsp; &nbsp;&#x27F5;&nbsp; Owner - of domain: {dtx.outputStates[ outputChosen ].ownerName} </div>
                                    </Form.Group>
                                    <Form.Group>
                                      <Form.Field checked={this.state.pval==='2'} label='Visitor' value='2' control='input' type='radio' onChange={this.participantChanged}/> &nbsp; &nbsp;
                                      <div>&nbsp;&#x27F5;&nbsp; Visitor - of domain: {dtx.outputStates[ outputChosen ].visitorName}</div>
                                    </Form.Group>
                                  </div>
                              </>
                          :
                              foundOwnerRabin ?
                                    <>
                                      We only found the OWNER domain registered by you.
                                    </>
                                :
                                    <>
                                      We only found the GUEST domain registered by you.
                                    </>

    const rabinPresentation = parseInt(this.state.pval) !== 0 ?
                                <> We know who you are - and you'll need to have keys for Rabin PKH
                                   of <span style={{color: 'blue'}}> {this.state.chosenRabinPKH} </span>
                                </>
                            :
                                <> <span style={{color: 'red'}}> We don't yet know who you are </span>
                                </>
    const yColor = this.state.encryptMsg ? 'blue' : 'grey'
    const nColor = this.state.encryptMsg ? 'grey' : 'blue'

    // Using a form expands the TextArea
    return (   // DialogBuilder
      <>
        <Modal.Content image scrolling>
        <Container>
        <Modal.Description>

        {action}
        <Divider />
        {blurb}
        <Divider />
        <Form>
          <TextArea placeholder='Enter your message to the other participant'
                        field='content' onChange={ (event, data) => handleFieldChange(event, data, this) }/>

          <div></div>
          <Divider />
          Would you like to ENCRYPT this message?
          <br></br>
          (Only you and the receiver can decrypt it)
          <br></br>
          <span style={{color: nColor}}>Don't Encrypt Message</span> &nbsp; <Radio toggle checked={this.state.encryptMsg}
                                      field='encryptMsg' onChange={ (event, data) => handleFieldChange(event, data, this) }/> &nbsp; <span style={{color: yColor}}>Encrypt Message</span>
          <Divider />

          { participantChooser }
          <br></br>
          {rabinPresentation}

        </Form>

        </Modal.Description>
        </Container>
        </Modal.Content>

        <Modal.Actions className="modaledge">
          <Button disabled={DialogBuildBtnDisabled} content={buttonLabel} positive onClick={this.handlePublishSubmit}/>
          <Button onClick={ (event) => onCancel(event, this, 'dialogBuilder') } content='CANCEL' negative/>
        </Modal.Actions>

        <KeySolicitingModal onInputChange={ (event, data) => handleFieldChange(event, data, this) }
                            openSecondaryModal={this.state.openSecondaryModal}
                            openBadKeysModal={this.state.openThirdModal}
                            parent={this}
                            closeThird={ () => closeOurThird(this) }
                            ownerRabinPKH={this.state.chosenRabinPKH}
                            key={this.state.chosenRabinPKH}
                            //checkFilled={ () => bothPrivKeysAreFilled(this) }
                            onSubmit={ (event) => handleRabinKeyModalSubmit(event, this, 'dialogBuilder') }
                            onCancel={ (event) => handleRabinKeyModalCancel(event, this)} />
      </>
    );
  } //DialogBuilder render()
}

class UpdateBuilder extends React.Component {
  constructor(props) {
    super(props);

    this.state = {spawnTransient: true,
      spawnBitGroup: false,
      tval: 'undefined',
      spawnVal: '1',             //NOTE: this matches spawnTransient: true, above (it must)
      openSecondaryModal: false,
      content: '',
      contentLabel: '',
      refTxId: '',
      refTxLabel: '',
      rabinPKH: '',
      rabinPrivKeyP: '',
      rabinPrivKeyQ: '',
      allowGuestPosts: false,
      gpPrice: '103',

      contentSpecChoice: '0',      // default to just typing/pasting content
      showContentFileReaderModal: false,
      fileChosenToUpload: '',
      contentChosenToUpload: '',
      ipByte0: '',
      ipByte1: '',
      ipByte2: '',
      ipByte3: ''
    };

    this.handlePublishSubmit      = this.handlePublishSubmit.bind(this);
    this.buildAndPublish          = this.buildAndPublish.bind(this);
    this.tChanged                 = this.tChanged.bind(this);
    this.spawnOpChanged           = this.spawnOpChanged.bind(this);
    this.preCheckRabinPrivateKeys = this.preCheckRabinPrivateKeys.bind(this);
    this.reUseRabin               = this.reUseRabin.bind(this);
    this.handleGPPriceChange      = this.handleGPPriceChange.bind(this);
    this.showContentFileReaderModal = this.showContentFileReaderModal.bind(this);
    this.closeContentFileReaderModal= this.closeContentFileReaderModal.bind(this);
    this.handleChosenFile         = this.handleChosenFile.bind(this);
    this.setContentSpecType       = this.setContentSpecType.bind(this);
  }

  preCheckRabinPrivateKeys() {
    const dtx = JSON.parse( this.props.dtx );
    const outputChosen = this.props.outputChosen;
    const prevRabinPKH = dtx.outputStates[outputChosen].ownerRabinPKH;

    console.log("UpdateBuilder preCheckRabinPrivateKeys(): checking against previous PKH " + prevRabinPKH);

    return validateRabinPrivateKeys(this.state.rabinPrivKeyP, this.state.rabinPrivKeyQ, prevRabinPKH)
  }

  async buildAndPublish() {  // UpdateBuilder

    //FIXME: we'd like a policy where if the PKH is left blank, we'll re-use the previous/current

    const contentIsAFile       = this.state.contentSpecChoice === '1'
    const contentIsJumpMapping = this.state.contentSpecChoice === '2'
    //FIXME: better name than 'jumpMapping'?

    console.log("UpdateBuilder::buildAndPublish(): content: " + this.state.content);
    //console.warn("buildAndPublish(): privKey: " + this.state.bsvPrivKey);
    console.log("buildAndPublish(): contentIsHex: "  + this.state.contentIsHex)
    console.log("buildAndPublish(): contentLabel: "  + this.state.contentLabel);
    console.log("buildAndPublish(): refTxId: "       + this.state.refTxId);
    console.log("buildAndPublish(): refTxLabel: "    + this.state.refTxLabel);
    console.log("buildAndPublish(): rabinPKH: "      + this.state.rabinPKH);
    console.log("buildAndPublish(): spawnTransient: "+ this.state.spawnTransient);
    console.log("tval:                             " + this.state.tval);   // which transient: 1-8
    console.log("buildAndPublish(): spawnBitGroup: " + this.state.spawnBitGroup);

    //console.log("buildAndPublish(): rabinPrivKeyP: " + this.state.rabinPrivKeyP);
    //console.log("buildAndPublish(): rabinPrivKeyQ: " + this.state.rabinPrivKeyQ);

    console.log("buildAndPublish(): allowGuestPosts: " + this.state.allowGuestPosts);
    console.log("buildAndPublish(): gpPrice:       " + this.state.gpPrice);

    console.log("buildAndPublish(): contentIsJumpMapping: "+ contentIsJumpMapping)
    console.log("buildAndPublish(): contentIsAFile:       "+ contentIsAFile)
    console.log("buildAndPublish(): contentChosenToUpload: " + (this.state.contentChosenToUpload.length / 2) + " bytes")

    //FIXME: we could encrypt and store rabin private keys - keyed by the PKH.
    //       before soliciting p15, we could check if we've got the keys already

    const dtx = JSON.parse(this.props.dtx);
    console.log("UpdateBuilder::buildAndPublish(): dtx = ", dtx);

    const outIndex = this.props.outputChosen;
    console.log("buildAndPublish(): outIndex = " + outIndex);

    const chosenMode = hexByteToAscii( dtx.outputStates[ outIndex ].mode );
    console.log("buildAndPublish(): chosen mode is " + chosenMode);

    console.log("buildAndPublish(): props.txOnWhichToBuild = " + this.props.txOnWhichToBuild);

    const prevRabinPKH = dtx.outputStates[ outIndex ].ownerRabinPKH;
    const paramPKH = this.state.rabinPKH;
    const reUsePKH = prevRabinPKH === paramPKH;

    const spawnSomething = this.state.spawnTransient || this.state.spawnBitGroup;

    console.log("UpdateBuilder::buildAndPublish(): calling buildOnAnUpdate().");


    //FIXME: we should KNOW what the current (or, next) block is, and use it.
    //       OR we should have a special mode for dev - where we specify/override the actual block (for testing)
    //       Based on that, we should present differently - showing it's expired; to renew or not, etc.
    //       There are also policies about spawning bitGroup, and claiming bounty.
    //       Maybe there are even rules about spawning Transient?

    console.log("buildAndPublish(): FIXME: query the actual block #. Use it to guide/set some parameters here.");

//FIXME: if user claims content is a hex string, we'll want to verify that string is truly in 'hex' format
//       I think we could simply try/catch Buffer.from(content, 'hex')
//       BUT, if it fails, maybe we should alert(), then return
    const contentHexString = contentIsAFile ?
                            this.state.contentChosenToUpload
                          :
                            contentIsJumpMapping ?
                                Buffer.from(PAYLOAD_ESCAPE_FLAG + 'i4').toString('hex') + oneByteLE(this.state.ipByte0) + oneByteLE(this.state.ipByte1) + oneByteLE(this.state.ipByte2) + oneByteLE(this.state.ipByte3)
                              :
                                this.state.contentIsHex ?
                                    this.state.content
                                  :
                                    Buffer.from(this.state.content).toString('hex')
    try {
      const txid = await buildOnAnUpdate(dtx.outputStates[outIndex], this.props.txOnWhichToBuild, outIndex,
                            false,                  // p1 - claimBounty instead of publishing? - only if newBlockInt is greater than the deadlineInt
                            true,                   // p2 - publish content?
                            contentHexString,       // p3 - the content
                            this.state.contentLabel,// p4 - base64 contentName
                            this.state.refTxId,     // p5 - txId to publish
                            this.state.refTxLabel,  // p6 - txDescription
                            reUsePKH,               // p7 - re-use publisher PKH?
                            paramPKH,               // p8 - newPKH
                            this.state.finalPost,   // p9 - final post?

                            spawnSomething,            // p10 - spawn something?
                            this.state.spawnTransient, // p11 - spawn transient?
                            this.state.tval,           // p11Opt - if spawn trans: which transient?
                            this.state.allowGuestPosts,      // param11bAllowGuestPosts (for transients)
                            Number(this.state.gpPrice),// param11cGuestPostPrice (sats, for transients)

                            'Friends', //this.state.groupName,  // p12 - if spawn BitGroup: group name
                            1000, //this.state.userBounty,     // p13 - if spawn BitGroup: user bounty
                            255, //this.state.maxPostLen,     // p14 - if spawn BitGroup: max post len
                            0,  //this.state.satsPerPost,    // p15 - if spawn BitGroup: sats per post
                            this.state.rabinPrivKeyP,
                            this.state.rabinPrivKeyQ,
                            this.state.bsvPrivKey);
      // now pass back THIS tx - to make it the txToConsider
      console.log("UpdateBuilder::buildAndPublish(): we've built a tx ", txid);

      this.props.handleBuildEvent(txid);
    }  catch (error) {
      console.log('Failed in UpdateBuilder::buildAndPublish()');
      //console.log('Failed on network: ' + NETWORK)
      console.error("stack: " + error.stack);
      printError(error);    // needed?
    }
  } // buildAndPublish()

  async handlePublishSubmit() {  // UpdateBuilder
    console.log("UpdateBuilder::handlePublishSubmit():  will trigger opening of key-soliciting modal");

    // Opens secondary modal to solicit keys for signing
    // Results of that (a click of 'Build' or 'CANCEL') emerge at handleRabinKeyModalSubmit()
    //   or a cancel leads to handleRabinKeyModalCancel()
    this.setState({openSecondaryModal: true});
    // May trigger buildAndPublish() after signing
  }

  // when choosing spawnT, spawnBG, or spawnNeither
  spawnOpChanged(e) {
    console.log('spawnOpChanged: ' + e.target.value);

    // used to effect changes in the display
    this.setState({spawnVal: e.target.value});

    let spawnT = false;
    let spawnBG = false;
    if ( e.target.value === '0' ) {         // neither
      //console.log("we'll spawn nothing");
    } else if ( e.target.value === '1' ) {  // transient
      //console.log("we'll spawn a transient");
      spawnT = true;
    } else if ( e.target.value === '2' ) {  // bitGroup
      //console.log("we'll spawn a bitgroup");
      spawnBG = true;
    } else {
      return;
    }

    // used by buildAndPublish()
    this.setState({ spawnTransient: spawnT, spawnBitGroup: spawnBG });
  }

  // when choosing a Transient type to spawn (1-8)
  tChanged= (e) => {
    this.setState({tval: e.target.value});
  }

  reUseRabin(event) {
    event.preventDefault();
    const dtx = JSON.parse( this.props.dtx );
    const outputChosen = this.props.outputChosen;
    const prevRabinPKH = dtx.outputStates[outputChosen].ownerRabinPKH;

    console.log("UpdateBuilder reUseRabin():  will re-use PKH " + prevRabinPKH)
    this.setState({rabinPKH: prevRabinPKH});
  }

  handleGPPriceChange(event) {
    const re = /^[0-9\b]+$/;
    console.log("input: " + event.target.value)
    if (event.target.value === '' || re.test(event.target.value)) {
      if ( event.target.value.length < 11 && Number(event.target.value) < 4294967295) {
        this.setState({gpPrice: event.target.value})
      }
    }
  }

  closeContentFileReaderModal() {
    console.log('UpdateBuilder: Closing Content FileReader modal...')

    this.setState({showContentFileReaderModal: false})
  }

  showContentFileReaderModal() {
    this.setState({showContentFileReaderModal: true})
  }

  // called by fileChooserReader when user clicks OK/Use
  handleChosenFile(chosenFileName, content) {
    console.warn("UpdateBuilder: handleChosenFile() - content file has been specified: " + chosenFileName + ", with content of length " + content.length)
    this.setState({fileChosenToUpload: chosenFileName, contentChosenToUpload: content})
  }

  setContentSpecType(e) {
    console.log('setContentSpecType: ' + e.target.value);

    // used to effect changes in how the content is specified, OR what it is
    //   - 0: type/paste content
    //   - 1: upload a file
    //   - 2: specifies a 4-byte ipv4 address (a Shizzle Jump)
    this.setState({contentSpecChoice: e.target.value});
  }

  render() {  // UpdateBuilder
    const dtx = JSON.parse( this.props.dtx );
    const outputChosen = this.props.outputChosen;
    const ownerRabinPKH = dtx.outputStates[ outputChosen ].ownerRabinPKH;
    //console.log("output chosen is " + outputChosen);
    const chosenMode = hexByteToAscii( dtx.outputStates[ outputChosen ].mode );
    const buttonLabel = 'BUILD contract on output of mode ' + chosenMode;
    const limb = hexStringToAscii( dtx.outputStates[ outputChosen ].namePath);

    let blurb = <div> There are many more opportunities to publish on a 'U' (Update) output.
                    What's different is that they exist on a parallel, but finite-length line of transactions.
                    An Update can spawn a Transient, or a BitGroup.

                    As with Continue transactions, at each step/tx a Rabin PKH is declared (or
                    re-used) for future authentication, and the owner uses his secret Rabin
                    Key to sign against the previously-declared PKH.
                </div>;
    let rabinPKHLabel = 'Rabin PKH to Declare:';
    let rabinPKHPlaceholder = 'Enter a Rabin PKH for the NEXT publication.';
    let action = <div> Publish on a namePath/limb '{limb}' Update tx</div>

    const spawnVal = this.state.spawnVal;
    const tval = this.state.tval;

    const disableForPoorJumpMapping = this.state.contentSpecChoice === '2' &&
                (this.state.ipByte0 === '' || this.state.ipByte1 === '' || this.state.ipByte2 === '' || this.state.ipByte3 === '' )
    const disableForSpawnTransReason = spawnVal === '1' && tval === 'undefined';
    const UpdateBuildBtnDisabled = this.state.rabinPKH.length !== 40
                          || disableForSpawnTransReason || disableForPoorJumpMapping;

    const spawnTOff = spawnVal === '0' || spawnVal === '2';
    const tColor = spawnTOff ? 'lightgrey' : 'black';
    const bgSpawnOption = spawnVal === '0' || spawnVal === '1' ? <div style={{color: 'lightgrey'}}> No BitGroup will be spawn </div>
                                                               : <div> A BitGroup will be spawn </div>;

    const disableReUseButton = this.state.rabinPKH === ownerRabinPKH;
    const reUseButton = <Button disabled={disableReUseButton} content='Re-Use Current PKH' positive onClick={this.reUseRabin}/>;
    const gpOff = spawnTOff || !this.state.allowGuestPosts;
    const gpColor = gpOff ? 'lightgrey' : 'black';
    const guestPostPriceSetter = <>
                                  <div style={{color: gpColor}}> &nbsp; &nbsp; How many satoshis should guests pay you for each post on your Transients? &nbsp;
                                    <Input type="text" value={this.state.gpPrice} disabled={gpOff}
                                        onChange={this.handleGPPriceChange} />
                                  </div>
                                </>

    const contentPlaceHolder = document.getElementById('contentPlace')
    const fileReaderModal = this.state.showContentFileReaderModal ?
                                <FileChooserReader  closeChooserModal={this.closeContentFileReaderModal}
                                                    pplaceholder={ contentPlaceHolder }
                                                    callback={this.handleChosenFile}/>
                              :
                                null
//FIXME: if specifying ip4, present 3rd king of input. also, if transient, there is (by policy) no shizzleJump
    const contentIsAFile       = this.state.contentSpecChoice === '1'
    const contentIsJumpMapping = this.state.contentSpecChoice === '2'
    const whichContent = contentIsAFile ?
                              <>
                                <Button disabled={false} content='Choose File to Upload' positive onClick={this.showContentFileReaderModal}/>
                                <br></br>
                                Chosen File: {this.state.fileChosenToUpload}
                                <br></br>
                                File Length: {this.state.contentChosenToUpload.length / 2}
                                <br></br>
                                {fileReaderModal}
                              </>
                            :
                              contentIsJumpMapping ?
                                <>
                                  Specify an IPv4 'ShizzleJump'. You may specify an IP address to map to your domain.<br></br>
                                  Anyone directing a browser to <span style={{color: 'blue'}}>https://bitshizzle.com/jump/{limb}</span> will be
                                  re-directed to a <b>standard</b> (non-shizzle) browser window with the IP address you specify (decimal):<br></br>
                                   &nbsp; &nbsp; &nbsp; &nbsp;
                                  <Input placeholder='XXX'
                                    style={{width: "60px"}}
                                    value={this.state.ipByte0}
                                    field='ipByte0' onChange={ (event, data) => handleFieldChange(event, data, this) }/>
                                  .
                                  <Input placeholder='XXX'
                                    style={{width: "60px"}}
                                    value={this.state.ipByte1}
                                    field='ipByte1' onChange={ (event, data) => handleFieldChange(event, data, this) }/>
                                  .
                                  <Input placeholder='XXX'
                                    style={{width: "60px"}}
                                    value={this.state.ipByte2}
                                    field='ipByte2' onChange={ (event, data) => handleFieldChange(event, data, this) }/>
                                  .
                                  <Input placeholder='XXX'
                                    style={{width: "60px"}}
                                    value={this.state.ipByte3}
                                    field='ipByte3' onChange={ (event, data) => handleFieldChange(event, data, this) }/>
                                  <p>
                                  This is a <b style={{color: 'red'}}>very crude</b> form of DNS, which can allow others to find your traditional
                                  (non-ShizzleVerse) web site without the use of traditional DNS. Only do this for a site which YOU contro. Don't
                                  do this if you're not very clear on what this means. Going this route means that your site shouldn't fully rely
                                  on traditional DNS either. There would be no support for sub-domains. Also, when linking internally, your site
                                  will probably need to only use relative links.
                                  </p>
                                  To later clear this mapping, set/map it to 0.0.0.0.
                                </>
                              :
                                <>
                                  <TextArea placeholder='Type/paste content to publish'
                                            field='content'
                                            onChange={ (event, data) => handleFieldChange(event, data, this) }/>
                                  <br></br>
                                  Content above is ascii/text <Radio  toggle label='Content above is HEX' field='contentIsHex'
                                              onChange={ (event, data) => handleFieldChange(event, data, this) }/>
                                </>
    //const contentSpec = <>
    //                      Type/paste content, or <Radio toggle label='Upload a file'
    //                                                    field='contentIsAFile'
    //                                                    onChange={ (event, data) => handleFieldChange(event, data, this) }/>
    //                      <br></br>
    //                      {whichContent}
    //                    </>
    const contentTypeChoice =
            <>
              <div>You may elect to type/paste content, upload a file, or do something very rare/technical.</div>
              <Form.Group>
                  <Form.Field label='Type/paste content' type='radio' value='0' control='input' checked={this.state.contentSpecChoice === '0'} onChange={this.setContentSpecType}/> &nbsp;
                  <Form.Field label='Upload a file'      type='radio' value='1' control='input' checked={this.state.contentSpecChoice === '1'} onChange={this.setContentSpecType}/> &nbsp;
                  <Form.Field label='Rare/technical'     type='radio' value='2' control='input' checked={this.state.contentSpecChoice === '2'} onChange={this.setContentSpecType}/>
              </Form.Group>
              {whichContent}
            </>

    return (   // UpdateBuilder
      <>
        <Modal.Content image scrolling>
        <Container>
        <Modal.Description>

        {action}
        <Divider />
        {blurb}
        <Divider />
        <Form>
          {contentTypeChoice}
          <br></br>
          <br></br>
          You may LABEL your content ( alphanumeric | slash | dash ) - maximum of 64 characters (currently: {this.state.contentLabel.length} chars)<br></br>
          (Multiple consecutive slashes will be interpreted as a single slash)
          <Input fluid label='Content label/name:' placeholder='Enter a name for this content'
                        value={this.state.contentLabel}
                        field='contentLabel' onChange={ (event, data) => handleFieldChange(event, data, this) }/>
          <div></div>
          <Input fluid label='Reference TxId:' placeholder="Enter a Tx Id (hexadecimal) you'd like to reference"
                        value={this.state.refTxId}
                        field='refTxId' onChange={ (event, data) => handleFieldChange(event, data, this) }/>
          <div></div>
          <Input fluid label='Reference Tx label/name:' placeholder="Enter a name for this Tx you're referencing"
                        field='refTxLabel' onChange={ (event, data) => handleFieldChange(event, data, this) }/>
          <div></div>

          <div>You may elect to spawn a Transient, a BitGroup, or neither.</div>
          <Form.Group>
              <Form.Field label='Spawn Transient'  type='radio' value='1' control='input' checked={spawnVal==='1'} onChange={this.spawnOpChanged}/> &nbsp;
              <Form.Field label='Spawn BitGroup'   type='radio' value='2' control='input' checked={spawnVal==='2'} onChange={this.spawnOpChanged}/> &nbsp;
              <Form.Field label='Spawn Nothing'    type='radio' value='0' control='input' checked={spawnVal==='0'} onChange={this.spawnOpChanged}/>
          </Form.Group>

          <p></p>
          <b style={{color: tColor}}> Transient Type </b>
          <div style={{color: tColor}}>Choose the type of Transient to spawn:</div>
          <Form.Group>
            <Form.Field checked={tval==='4'} disabled={spawnTOff} label='T4 (Vanilla)' value='4' control='input' type='radio' onChange={this.tChanged}/> &nbsp; &nbsp;
            <div style={{color: tColor}}> &nbsp; &nbsp;&#x27F5;&nbsp; These are simpler/cheaper</div>
          </Form.Group>
          <Form.Group>
            <Form.Field checked={tval==='9'} disabled={spawnTOff} label='T9 (Labeling)' value='9' control='input' type='radio' onChange={this.tChanged}/> &nbsp; &nbsp;
            <div style={{color: tColor}}>&nbsp;&#x27F5;&nbsp; These allow labeling content and referenced transactions</div>
          </Form.Group>

          <div></div>
          <p></p>
          <b style={{color: tColor}}> Guest-Posting </b>
          <div style={{color: tColor}}>Will you allow guest-posts on your Transients? </div>

          <span style={{color: tColor}}>No Guest-Posts &nbsp; <Radio toggle checked={this.state.allowGuestPosts} disabled={spawnTOff}
                                      field='allowGuestPosts' onChange={ (event, data) => handleFieldChange(event, data, this) }/> &nbsp; Allow Guest-Posts</span>
          {guestPostPriceSetter}
          <span style={{color: tColor}}>This guest-post decision applies to <b>ALL</b> Transient transactions derived from this one.</span>
          <div></div>
          {bgSpawnOption}
          <Divider />
          <div>Rabin PKHs help authenticate transaction publishing rights on a Limb.</div>
          <div>Almost every transaction must declare a Rabin PKH (to unlock the user's NEXT transaction). You may declare a new Rabin PKH to authenticate your NEXT transaction, or you may decide to re-use your current PKH.</div>
          <Input field='rabinPKH' label={rabinPKHLabel} placeholder={rabinPKHPlaceholder}
                        value={this.state.rabinPKH} style={{width: "350px"}}
                        onChange={ (event, data) => handleFieldChange(event, data, this) }/>
          &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
          &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;
          {reUseButton}&nbsp;
          <RabinUtility disabled={this.state.rabinPKH.length > 0}
              parent={this}
          />
        </Form>
        <pre id="contentPlace" style={{display:'none'}}></pre>

        </Modal.Description>
        </Container>
        </Modal.Content>

        <Modal.Actions className="modaledge">
          <Button disabled={UpdateBuildBtnDisabled} content={buttonLabel} positive onClick={this.handlePublishSubmit}/>
          <Button onClick={ (event) => onCancel(event, this, 'updateBuilder') } content='CANCEL' negative/>
        </Modal.Actions>

        <KeySolicitingModal onInputChange={ (event, data) => handleFieldChange(event, data, this) }
                            openSecondaryModal={this.state.openSecondaryModal}
                            openBadKeysModal={this.state.openThirdModal}
                            parent={this}
                            closeThird={ () => closeOurThird(this) }
                            ownerRabinPKH={ownerRabinPKH}
                            //checkFilled={ () => bothPrivKeysAreFilled(this) }
                            onSubmit={ (event) => handleRabinKeyModalSubmit(event, this, 'updateBuilder') }
                            onCancel={ (event) => handleRabinKeyModalCancel(event, this)} />
      </>
    );
  } //UpdateBuilder render()
}

class TransientBuilder extends React.Component {
  constructor(props) {
    super(props);

    this.state = {spawnTransient: true,
      openSecondaryModal: false,
      content: '',
      contentLabel: '',
      refTxId: '',
      refTxLabel: '',
      rabinPKH: '',
      rabinPrivKeyP: '',
      rabinPrivKeyQ: '',

      hostTxLimb: '',             //WARNING: associated with guestPostPrice, and hostTxConfirmed in confirmHostTx()

      // These four are bungled together
      hostTxConfirmed: false,
      hostTx: '',                  //WARNING: cleared when launchDialog is set
      guestPostPrice: '?',         //WARNING: reset (to '?') when launchDialog is set
      userConfirmedHostTx: false,

      //WARNING: when this is set, hostTx, and guestPostPrice are reset
      launchDialog: false,

      showContentFileReaderModal: false,
      fileChosenToUpload: '',
      contentChosenToUpload: '',
    };

    this.handlePublishSubmit      = this.handlePublishSubmit.bind(this);
    this.buildAndPublish          = this.buildAndPublish.bind(this);
    this.preCheckRabinPrivateKeys = this.preCheckRabinPrivateKeys.bind(this);
    this.reUseRabin               = this.reUseRabin.bind(this);
    this.confirmValidHostTx       = this.fetchAndConfirmValidUnspentHostTx.bind(this);
    this.confirmHostTx            = this.confirmHostTx.bind(this);
    this.showContentFileReaderModal = this.showContentFileReaderModal.bind(this);
    this.closeContentFileReaderModal= this.closeContentFileReaderModal.bind(this);
    this.handleChosenFile         = this.handleChosenFile.bind(this);
  }

  preCheckRabinPrivateKeys() {
    const dtx = JSON.parse( this.props.dtx );
    const outputChosen = this.props.outputChosen;
    const prevRabinPKH = dtx.outputStates[outputChosen].rabinPKH;  //FIXME: note: different field name than update and continue. maybe fine.

    console.log("TransientBuilder preCheckRabinPrivateKeys(): checking against previous PKH " + prevRabinPKH);

    return validateRabinPrivateKeys(this.state.rabinPrivKeyP, this.state.rabinPrivKeyQ, prevRabinPKH)
  }

  // Used by two different functions:
  //   buildAndPublish()
  //   confirmHostTx()
  /**
   * NOTE: IF outputAlreadyChosen is null, this is the first round/pass,
   *         and it's okay to have affirmative alerts
   *       IF outputAlreadyChosen is NOT null, this is the second round,
   *         and we should refrain from informative alerts, AND
   *         we should require the 2nd-round-chosen output to match the outputAlreadyChosen,
   *         AND there should be no reason for the user to disambiguate
   */
  async fetchAndConfirmValidUnspentHostTx(hostTxId, guestMode, outputAlreadyChosen) {
    console.log("    query and decode host tx: " + hostTxId);
    // query and decode the tx
    var db = await openDB();
    const hostTxX = await queryFetchDecodeTx( hostTxId, db, true ); //this.state.dbConnection );
    if ( hostTxX === null || typeof hostTxX === 'number' ) {
      // alert here? or deeper, in contractSupport's getRawInfo() (where we can more-easily see a reason/code)?
      alert("ERROR: failed to retrieve the host transaction " + hostTxId + ": " + hostTxX)

      return false
    }

    let round
    if ( outputAlreadyChosen === null ) {
      round = 1
      console.warn("fetchAndConfirmValidUnspentHostTx: first round. If necessary, will settle on a host output to use.")
    } else {
      round = 2
      console.warn("fetchAndConfirmValidUnspentHostTx: second round. If necessary, check that first-round choice matches what we find this time.")
    }

    let intialHostTargetOutput = 0

    // verify host mode transient TYPE matches the guest-post (Vanilla, or Labeling)
    const aHostMode = hostTxX.outputStates[0].mode;
    const aHostModeInt = parseInt(aHostMode) - 30
    const guestModeInt = parseInt(guestMode) - 30
    // Type 1 is Labeled
    // Type 0 is Vanilla (unlabeled)
    const hostTransientType = aHostModeInt > 4 ? 1 : 0
    const guestTransientType = guestModeInt > 4 ? 1 : 0
    console.log("TransientBuilder::fetchAndConfirm...(): transient levels:  aHostModeInt: " + aHostModeInt + ",  guestModeInt: " + guestModeInt)
    console.log("TransientBuilder::fetchAndConfirm...(): transient types:  hostTransientType: " + hostTransientType + ",  guestTransientType: " + guestTransientType)
    if ( hostTransientType !== guestTransientType ) {
      console.warn("a host mode is " + aHostMode + ", guest mode is " + guestMode);

      const hostOut1 = hostTxX.outputStates[1]
      let allGood = false
      // If user wants to guest-post on an 'Update' tx, check if it has a Transient spawn
      if ( aHostMode === '55' && hostOut1 && hostOut1.mode ) {
        console.warn("Maybe there's a chance we can guest-post there. Output 1 exists, and has mode " + hostOut1.mode)

        const aHostOut1ModeInt = parseInt(hostOut1.mode) - 30
        const hostOut1TransientType = aHostOut1ModeInt > 4 ? 1 : 0
        if ( hostOut1TransientType === guestTransientType ) {
          console.warn("It'll work!!! Will guest-post on output 1")
          if ( round === 1 ) {
            alert("It'll work. Will guest-post on output 1 (output 0 is an Update/Periodical)")
          }
          allGood = true
          intialHostTargetOutput = 1
        } else {
          console.warn("No good. Can't guest-post there. It's the wrong type.")
          alert("Nope. We can't guest-post there. It's the wrong type.")
        }
      }

      if ( !allGood ) {
        alert("We're sorry. You're attempting to guest-post on a transient of a different TYPE (Vanilla vs Labeling). That's not permitted. Removing Host TxId.")
        return false
      }
    }


    const dtx = JSON.parse(this.props.dtx)
    const outputChosen = this.props.outputChosen;
    const guestLimb = dtx.outputStates[outputChosen].namePath;

    // This may change if the host/target Tx was ITSELF a guest/hosted tx
    let hostOutput = intialHostTargetOutput

    // IF host tx is ITSELF a hosted tx, be clear on the target/host output
    // It could be output 0 (the host), or output 1 (the guest)
    if ( hostTxX.input0Params.hostGuestMode === 1 || hostTxX.input0Params.hostGuestMode === 4 ) {

      // The host Tx was ITSELF, indeed, a hosted tx
      // We need to find which of its outputs to use as a host/input

      const host0TxState = hostTxX.outputStates[0];
      const host1TxState = hostTxX.outputStates[1];
      const host0Limb = host0TxState.namePath
      const host1Limb = host1TxState.namePath

      console.warn("The host tx was itself a guest post. It's not yet clear WHICH output of it to use.")
      //hostTxState = hostTxX.outputStates[1];

      let otherMatchesToo = false
      let mentionMatch = false

      // Here we might ask the user WHICH output should host the tx
      //     - If NEITHER output [0], nor output [1] are of the same limb
      //     - If one IS, then use the other
      //     - Don't bother having a spawned Transient (third output) host the tx
      if ( guestLimb === host0Limb ) {
        console.warn("NOTE: host output[0] matches guest limb, so, will consider posting on host output[1]")
        hostOutput = 1

        otherMatchesToo = guestLimb === host1Limb
        mentionMatch = true
      } else if ( guestLimb === host1Limb ) {
        console.warn("NOTE: host output[1] matches guest limb, so, will consider posting on host output[0]")
        hostOutput = 0

        otherMatchesToo = guestLimb === host0Limb
        mentionMatch = true
      } else {

        // neither limb matches
        // Either output is viable as a host

        const out0AlreadySpent = !hostTxX.foundBuildable || hostTxX.buildable[ 0 ] !== 0
        const out1AlreadySpent = !hostTxX.foundBuildable || hostTxX.buildable[ 1 ] !== 1

        if ( out0AlreadySpent && out1AlreadySpent ) {
          alert("We're sorry. Both HOST outputs (which we're willing to consider) have been spent. Removing Host TxId.")
          return false
        }

        // Does output #1 (a guest-post) forbid guest-posting?
        if ( !host1TxState.guestPostsAllowed ) {
          hostOutput = 0
          if ( out0AlreadySpent ) {
            alert("We're sorry. Output 1 doesn't allow guest-posts, and output 0 has been spent. Removing Host TxId.")
            return false
          }
          console.warn("NOTE: targeting host output 0 (unspent) because output 1 doesn't allow guest-posts")
        } else if ( out0AlreadySpent ) {
          hostOutput = 1
          console.warn("NOTE: targeting host output 1 because output 0 was already spent.")
        } else if ( out1AlreadySpent ) {
          hostOutput = 0
          console.warn("NOTE: targeting host output 0 because output 1 was already spent.")
        } else {
          if ( round === 2 ) {
            throw new Error("58223: there should be no reason, on this second round, for the user to "
                          + "choose an output. It should've already been determined in round 1.")
          }

          throw new Error("Must disambiguate: WHICH host output to use (both 0 and 1 would be fine, and they're unspent)")
        }

        if ( round === 1 ) {
          alert("NOTE: Targeting host output " + hostOutput + " because either  a) output " + (1-hostOutput)
            + " was already spent, OR possibly  b) if other output doesn't allow guest-posting")
        }
      }

      // Is the other output viable as a host?
      if ( otherMatchesToo ) {
        alert("Whoops. One can't guest-post onto one's own limb. Removing Host TxId.")
        return false
      } else if ( mentionMatch && round === 1 ) {
        alert("NOTE: Targeting host output " + hostOutput + " because the other output's limb matches the guest-post limb")
      }
    } // disambiguate when target is host/guest (and don't bother considering a spawn)

    console.log("Targeting host Tx's output #" + hostOutput + " - to guest-post on")
    const hostTxState = hostTxX.outputStates[ hostOutput ];
    const hostLimb = hostTxState.namePath

    if ( hostLimb === guestLimb ) {
      alert("Whoops. You can't guest-post onto your own limb. Removing Host TxId.")
      return false
    }

    // check SPEND status
    if ( !hostTxX.foundBuildable || hostTxX.buildable[ hostOutput ] !== hostOutput ) {
      console.warn("TransientBuilder: hostTx output " + hostOutput + " was already SPENT")
      console.warn("BTW: here's the decoded host tx: ", hostTxX)
      alert("We're sorry. That output was already SPENT. One can only guest-post on an output that wasn't. Removing Host TxId.")
      return false
    }

    console.log("NOTE: decoded target hostTxState as:", hostTxState)

    if ( round === 2 && hostOutput !== outputAlreadyChosen ) {
      console.error("Something's changed. On this second pass, we've chosen "
                  + "output " + hostOutput + ", but on the first pass, we had "
                  + "confirmed the user had wanted output " + outputAlreadyChosen)
      alert("We're sorry. Something appears to have changed. Maybe an output has "
          + " since been spent? Please try again.")
      return false
    }

    // set the target host output
    this.setState({targetHostOutput: hostOutput, targetHostLimb: hexStringToAscii(hostLimb)})

    return {
            hostTxState: hostTxState,
            hostOutput: hostOutput
          }

  } // fetchAndConfirmValidUnspentHostTx


  // 'Confirm Host Tx' button was clicked. Confirm it's a valid,
  // and suitable, host txid
  async confirmHostTx() {
    if ( this.state.hostTx.length !== 64 ) {
      alert("That's not a valid TxId")
      return
    }

    const dtx = JSON.parse(this.props.dtx);
    const outIndex = this.props.outputChosen;
    const guestMode = dtx.outputStates[ outIndex ].mode
    const hostTxConfirmation = await this.fetchAndConfirmValidUnspentHostTx(this.state.hostTx, guestMode, null)

    console.log("TransientBuilder::confirmHostTx(): results: " + hostTxConfirmation)

    if ( !hostTxConfirmation ) {
      this.setState({hostTxConfirmed: false, hostTx: ''})

      return
    }
    const hostTxState = hostTxConfirmation.hostTxState
    const hostOutput  = hostTxConfirmation.hostOutput
    console.warn("confirmHostTx(): got state of hostTx[" + hostOutput + "]: ", hostTxState)

    console.warn("note the gpp: ", hostTxState)

    if ( hostTxState.guestPostsAllowed ) {
      alert("Note that the '" + hexStringToAscii(hostTxState.namePath)
          + "' Host Tx's price to guest-post there is " + hostTxState.guestPostPrice
          + " satoshis - for YOU to pay in THIS Tx.")

      // since confirmed, setState, so we *might* enable the BUILD button
      this.setState({guestPostPrice: hostTxState.guestPostPrice,
                    hostTxConfirmed: true, hostTxLimb: hexStringToAscii(hostTxState.namePath)})
    } else {
      alert("Sorry, guest-posts aren't allowed there. Removing Host TxId.")

      // clear the hostTxId field
      this.setState({hostTx: ''})
    }
  }

  async buildAndPublish() {  // TransientBuilder

    console.log("TransientBuilder::buildAndPublish(): content: " + this.state.content);
    //console.warn("TransientBuilder::buildAndPublish(): privKey: " + this.state.bsvPrivKey);
    console.log("buildAndPublish(): contentIsHex: "  + this.state.contentIsHex)
    console.log("buildAndPublish(): contentLabel: "  + this.state.contentLabel);
    console.log("buildAndPublish(): refTxId: "       + this.state.refTxId);
    console.log("buildAndPublish(): refTxLabel: "    + this.state.refTxLabel);
    console.log("buildAndPublish(): hostTx:   "      + this.state.hostTx);
    console.log("buildAndPublish(): rabinPKH: "      + this.state.rabinPKH);
    console.log("buildAndPublish(): spawnTransient: "+ this.state.spawnTransient);
    console.log("buildAndPublish(): spawnBitGroup: " + this.state.spawnBitGroup);

    console.log("buildAndPublish(): contentIsAFile: "+ this.state.contentIsAFile)
    console.log("buildAndPublish(): contentChosenToUpload: " + (this.state.contentChosenToUpload.length / 2) + " bytes")

    //console.log("buildAndPublish(): rabinPrivKeyP: " + this.state.rabinPrivKeyP);
    //console.log("buildAndPublish(): rabinPrivKeyQ: " + this.state.rabinPrivKeyQ);

    const dtx = JSON.parse(this.props.dtx);
    console.log("TransientBuilder::buildAndPublish(): dtx = ", dtx);

    const outIndex = this.props.outputChosen;
    console.log("buildAndPublish(): outIndex = " + outIndex);

    const chosenMode = dtx.outputStates[ outIndex ].mode
    const chosenModeAscii = hexByteToAscii( chosenMode );
    console.log("buildAndPublish(): chosen mode is ascii '" + chosenModeAscii + "'");

    console.log("buildAndPublish(): props.txOnWhichToBuild = " + this.props.txOnWhichToBuild);

    const prevRabinPKH = dtx.outputStates[ outIndex ].ownerRabinPKH;
    let paramPKH
    let reUsePKH
    const launchDialog = this.state.launchDialog
    if ( launchDialog ) {
      // You can't change the Rabin when starting (and during) a Dialog
      paramPKH = prevRabinPKH;
      reUsePKH = true;
    } else {
      paramPKH = this.state.rabinPKH;
      reUsePKH = prevRabinPKH === paramPKH;
    }

    console.log("TransientBuilder::buildAndPublish(): calling buildOnATransient().");

    //FIXME: we should KNOW what the current (or, next) block is, and use it.
    //       OR we should have a special mode for dev - where we specify/override the actual block (for testing)
    //       Based on that, we should present differently - showing it's expired; to renew or not, etc.
    //       There are also policies about spawning bitGroup, and claiming bounty.
    //       Maybe there are even rules about spawning Transient?

    let hostTxId = '';
    let hostTxState = null;
    let hostTxOutput = 0
    if ( this.state.hostTx !== '' || launchDialog ) {
      if ( !launchDialog && this.state.hostTx.length !== 64 ) {
        //FIXME: pop-up something?
        console.log("hostTx len is " + this.state.hostTx.length );
        throw new Error("16302: invalid host txid length");
      }

      let useOutput
      if ( !launchDialog ) {
        hostTxId = this.state.hostTx;
        useOutput = this.state.targetHostOutput
        console.error("b&p() NOT launching dialog, so, calling fetchAndConfirm...() with useOutput " + useOutput)
      } else {
        hostTxId = this.props.txOnWhichToBuild
        useOutput = 1
        console.error("b&p() YES launching dialog, so, calling fetchAndConfirm...() with useOutput " + useOutput)
      }

      // check again. Something may have changed - the target host output may have been spent
      const hostTxConfirmation = await this.fetchAndConfirmValidUnspentHostTx(hostTxId, chosenMode, useOutput)

      //console.log("NOTE: decoded hostTxState as:", hostTxState)
      if ( hostTxConfirmation === false ) {
        return
      }

      hostTxState = hostTxConfirmation.hostTxState
      // This is a new parameter
      hostTxOutput  = hostTxConfirmation.hostOutput
      console.warn("NOTE: using host Tx's ouput #" + hostTxOutput + " (as the host)")
      console.log("NOTE: and that host output has state: " + hostTxState)
      console.log("NOTE: using hostTxid of " + hostTxId)
    } // guest-posting somewhere

//FIXME: if user claims content is a hex string, we'll want to verify that string is truly in 'hex' format
//       I think we could simply try/catch Buffer.from(content, 'hex')
//       BUT, if it fails, maybe we should alert(), then return
    const contentHexString = this.state.contentIsAFile ?
                            this.state.contentChosenToUpload
                          :
                            this.state.contentIsHex ?
                                this.state.content
                              :
                                Buffer.from(this.state.content).toString('hex')

    try {
      const results = await buildOnATransient(dtx.outputStates[outIndex], this.props.txOnWhichToBuild, outIndex,
                            false,                  // p1 - claimBounty instead of publishing? - only if newBlockInt is greater than the deadlineInt
                            false,                  // p2 - tip owner?
                            0,                      // p3 - tip amt
                            true,                   // p4 - publish Y/N
                            contentHexString,       // p5 - the content is now in HEX string format
                            this.state.contentLabel,// p6 - base64 contentName
                            this.state.refTxId.length > 0, // p7 - publish tx? true/false
                            this.state.refTxId,     // p8 - tx to publish
                            this.state.refTxLabel,  // p9 - txDescription
                            reUsePKH,               // p10 - re-use publisher PKH?
                            paramPKH,               // p11 - newPKH
                            this.state.finalPost,   // p12 - final post?

                                                  // FIXME: ask user

                            this.state.spawnTransient, // p13 - spawn transient?

                            hostTxId !== '',                 // p14 - guest post?
                              this.state.launchDialog,

                            hostTxId,                        // p15 - host (prev) txid
                            hostTxState,                              // p16 - decoded host (prev) tx state
                              hostTxOutput,         // a NEW parameter

                            this.state.rabinPrivKeyP,
                            this.state.rabinPrivKeyQ,
                            this.state.bsvPrivKey);

      const txid = results.txid
                        //txid,
                        //rawTx,
                        //change

      // now pass back THIS tx - to make it the txToConsider
      console.log("TransientBuilder::buildAndPublish(): we've built a tx ", txid);

      this.props.handleBuildEvent(txid);
    }  catch (error) {
      console.log('Failed in TransientBuilder::buildAndPublish()');
      //console.log('Failed on network: ' + NETWORK)
      console.error("stack: " + error.stack);
      printError(error);    // needed?
    }
  } // buildAndPublish()

  async handlePublishSubmit() {  // TransientBuilder
    console.log("TransientBuilder::handlePublishSubmit():  will trigger opening of key-soliciting modal");

    // Opens secondary modal to solicit keys for signing
    // Results of that (a click of 'Build' or 'CANCEL') emerge at handleRabinKeyModalSubmit()
    //   or a cancel leads to handleRabinKeyModalCancel()
    this.setState({openSecondaryModal: true});
    // May trigger buildAndPublish() after signing
  }

  reUseRabin(event) {
    event.preventDefault();
    const dtx = JSON.parse( this.props.dtx );
    const outputChosen = this.props.outputChosen;
    const prevRabinPKH = dtx.outputStates[outputChosen].rabinPKH;

    console.log("TransientBuilder reUseRabin():  will re-use PKH " + prevRabinPKH)
    this.setState({rabinPKH: prevRabinPKH});
  }

  closeContentFileReaderModal() {
    console.log('Closing Content FileReader modal...')

    this.setState({showContentFileReaderModal: false})
  }

  showContentFileReaderModal() {
    this.setState({showContentFileReaderModal: true})
  }

  // called by fileChooserReader when user clicks OK/Use
  handleChosenFile(chosenFileName, content) {
    console.warn("handleChosenFile() - content file has been specified: " + chosenFileName + ", with content of length " + content.length)
    this.setState({fileChosenToUpload: chosenFileName, contentChosenToUpload: content})
  }

  render() {  // TransientBuilder
    const dtx = JSON.parse( this.props.dtx );
    const outputChosen = this.props.outputChosen;
    const ownerRabinPKH = dtx.outputStates[ outputChosen ].rabinPKH;  //FIXME: transient has different rabinPKH field name than continue and update
    //console.log("output chosen is " + outputChosen);
    const chosenMode = hexByteToAscii( dtx.outputStates[ outputChosen ].mode );
    const buttonLabel = 'BUILD contract on output of mode ' + chosenMode;
    const limb = hexStringToAscii( dtx.outputStates[ outputChosen ].namePath);

    let offerDialogMention = null
    const hostGuestMode = dtx.input0Params.hostGuestMode

    const buildingOnHost = (hostGuestMode === 1 || hostGuestMode === 4) && outputChosen == 0    // NOTE that we use == 0
    console.warn("decoded hostGuestMode is " + hostGuestMode)
    console.log("  outputChosen is " + outputChosen)
    console.log("  buildingOnHost is " + buildingOnHost)

    const transientType = hexByteToAscii( dtx.outputStates[ outputChosen ].mode );
    let beefyTransient; // meaning T5-T8
    switch (transientType) {
      case '0':
      case '1':
      case '2':
      case '3':
      case '4':
        beefyTransient = false;
        break;
      case '5':
      case '6':
      case '7':
      case '8':
      case '9':
        beefyTransient = true;
        break;
      default:
        console.error("Invalid transient type: " + transientType);
        throw new Error('12401');
    }
    let contentLabel = null;
    let refTxLabel = null;
    let spawnSection = null;
    let guestPostSection = null;
    if ( beefyTransient ) {
      contentLabel =  <>
                        <br></br>
                        You may LABEL your content ( alphanumeric | slash | dash ) - maximum of 64 characters (currently: {this.state.contentLabel.length} chars)<br></br>
                        (Multiple consecutive slashes will be interpreted as a single slash)
                        <Input fluid label='Content label/name:' placeholder='Enter a name for this content'
                              value={this.state.contentLabel}
                              field='contentLabel' onChange={ (event, data) => handleFieldChange(event, data, this) }/>
                        <div></div>
                      </>;
      refTxLabel =    <>
                        <Input fluid label='Reference Tx label/name:' placeholder="Enter a name for this Tx you're referencing"
                                field='refTxLabel' onChange={ (event, data) => handleFieldChange(event, data, this) }/>
                        <div></div>
                      </>;
    }

    // T0 and T5 are the lowest-level transients - they can't spawn anything.
    const sChecked = this.state.spawnTransient
    if ( transientType !== '5' && transientType !== '0' ) {
      spawnSection =  <> Spawning a sub-transient gives you more opportunities to post/publish.
                        <div></div>
                        <Radio toggle label='Spawn a sub-transient' checked={sChecked}
                                      field='spawnTransient' onChange={ (event, data) => handleFieldChange(event, data, this) }/>
                      </>;
    }

    const disableConfirmHostTxButton = this.state.guestPostPrice !== '?' || this.state.hostTx.length !== 64 || this.state.launchDialog
    const targetOutputMention = this.state.guestPostPrice === '?' ?
          disableConfirmHostTxButton ?
              <>
              </>
            :
              <div>
                (You have not yet <span style={{color: 'red'}}>confirmed</span> the Host Tx)
              </div>
        :
          <div>
            Targeting Host Tx's <span style={{color: 'blue'}}>output #{this.state.targetHostOutput} (base-0) of limb <b>'{this.state.targetHostLimb}'</b></span>
          </div>
    const priceMention = this.state.guestPostPrice === '?' ?
          <div>
            Price to guest-post: {this.state.guestPostPrice}
          </div>
        :
          <div>
            <span style={{color: 'red'}}>Price <b>you'll pay</b> to guest-post on that Host Tx output:</span> <b>{this.state.guestPostPrice}</b> satoshis
          </div>

    if ( buildingOnHost ) {
      console.warn("hostGuestMode is 1 or 4, and outputChosen is '0'. You've chosen to build on a HOST output.")
      console.warn("launchDialog = " + this.state.launchDialog)
      const priceTextColor = this.state.launchDialog ?
                          'red'
                        :
                          'lightgrey'
      const priceSatsColor = this.state.launchDialog ?
                          'black'
                        :
                          'lightgrey'
      offerDialogMention = <>
                              <Divider />
                              <b>Start Special DIALOG with Guest-Post</b>
                              <div>
                              Because your transaction was 'guest-posted' on, you have the option of responding directly to the Guest-Post
                              (guest-posting on the guest-poster) and including an <b>additional</b> DIALOG contract output.
                              A DIALOG contract would enable you to interact individually with the guest-poster (without others), though,
                              he is not obligated to do so.
                              <p></p>
                              <p>It costs to do this - just as much as guest-posting (plus a slightly larger miner fee). It IS
                                guest-posting - but with an additional contract output.</p>
                              <Radio toggle label='Respond to Guest-Post with a DIALOG contract output' checked={this.state.launchDialog}
                                            field='launchDialog' onChange={ (event, data) => handleFieldChange(event, data, this) }/>
                              <div style={{color: priceTextColor}}>Price to respond to the guest-post: <span style={{color: priceSatsColor}}>{dtx.outputStates[1].guestPostPrice} satoshis</span></div>
                              <span style={{color: priceSatsColor}}>(Note that the Rabin Keys must be kept unchanged in a Dialog)</span>
                              </div>
                          </>;
    }

    const guestPostSectionColor = this.state.launchDialog ?
                                    'lightgrey'
                                  :
                                    'black'
    //FIXME: if ! finalPost, and not claiming, nor tipping
    //FIXME: specify asset/limb name, instead of a tx
    //NOTE: it shouldn't have maxDownCounter, nor 0
    //NOTE: if specified, we shouldn't be claiming, nor tipping
    guestPostSection = //this.state.launchDialog ?
                      //null
                  //:
                      <>
                        <Divider />
                        <div style={{color: guestPostSectionColor}}>
                          <b>Guest Posting</b>
                          <div>If you'd like, you can 'attach' your next Tx to that of another limb/domain.
                            This is one way of commenting on, or drawing attention to, another post or domain.
                            The caveat is that the host may charge a 'guest-posting' fee.</div>
                          <Input disabled={this.state.launchDialog} fluid label='Guest-Post on a Host TxId:' placeholder="Enter an unspent Transaction Id (hexadecimal) of another (host) asset"
                                  value={this.state.hostTx}
                                  field='hostTx' onChange={ (event, data) => handleFieldChange(event, data, this) }/>
                          <Button disabled={disableConfirmHostTxButton} content='Confirm Host Tx' positive onClick={this.confirmHostTx}/>
                          {targetOutputMention}
                          {priceMention}
                        </div>
                        <Divider />
                      </>;

    let blurb = <div> There are even more opportunities to publish on a 'T' (Transient) output.
                    What's different is that they exist on a parallel, but finite-length line of transactions,
                    and there may be up to 255 on that line. Some Transients can spawn a lower-level transient.
                    There are eight kinds of Transients.
                    Transients 5 through 8 allow the owner to label content. Transients 1 through 4 do not.
                    A T-4 can spawn a T-3, which can spawn a T-2, which can spawn a T-1.
                    A T-8 can spawn a T-7, which can spawn a T-6, which can spawn a T-5.

                    As with Continue and Update transactions, at each step/tx a Rabin PKH is declared (or
                    re-used) for future authentication, and the owner uses his secret Rabin
                    Key to sign against the previously-declared PKH.
                </div>;
    let rabinPKHLabel = 'Rabin PKH to Declare:';
    let rabinPKHPlaceholder = 'Enter a Rabin PKH for authenticating the NEXT publication.';
    let action = <div> Publish on a namePath/limb '{limb}' Transient tx</div>;

    const transBuildBtnDisabled = (this.state.rabinPKH.length !== 40 && !this.state.launchDialog )
                          || ( this.state.hostTx.length == 64 && !this.state.hostTxConfirmed && !this.state.launchDialog)
                          || (this.state.hostTx.length !== 64 && this.state.hostTx.length !== 0 && !this.state.launchDialog)

    const disableReUseButton = this.state.rabinPKH === ownerRabinPKH;
    const reUseButton = <Button disabled={disableReUseButton} content='Re-Use Current PKH' positive onClick={this.reUseRabin}/>;

    const rabinSection = this.state.launchDialog ?
        <>
          <Divider />
          <span style={{color: 'blue'}}>Note that when starting a Dialog, the Rabin Keys cannot be changed.</span>
          <br></br>&nbsp;
        </>
      :
        <>
          <Divider />
          <div>Rabin PKHs help authenticate transaction publishing rights on a Limb.</div>
          <div>Almost every transaction must declare a Rabin PKH (to unlock the user's NEXT transaction).
            You may declare a new Rabin PKH to authenticate your NEXT transaction, or you may decide
            to re-use your current Rabin PKH.</div>
          <Input field='rabinPKH' label={rabinPKHLabel} placeholder={rabinPKHPlaceholder}
                        value={this.state.rabinPKH} style={{width: "390px"}}
                        onChange={ (event, data) => handleFieldChange(event, data, this) }/>
          &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
          &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
          {reUseButton}&nbsp;
          <RabinUtility disabled={this.state.rabinPKH.length > 0}
              parent={this}
          />
        </>

    const contentPlaceHolder = document.getElementById('contentPlace')
    const fileReaderModal = this.state.showContentFileReaderModal ?
                                <FileChooserReader  closeChooserModal={this.closeContentFileReaderModal}
                                                    pplaceholder={ contentPlaceHolder }
                                                    callback={this.handleChosenFile}/>
                              :
                                null
    const whichContent = this.state.contentIsAFile ?
                              <>
                                <Button disabled={false} content='Choose File to Upload' positive onClick={this.showContentFileReaderModal}/>
                                <br></br>
                                Chosen File: {this.state.fileChosenToUpload}
                                <br></br>
                                File Length: {this.state.contentChosenToUpload.length / 2}
                                <br></br>
                                {fileReaderModal}
                              </>
                            :
                              <>
                                <TextArea placeholder='Type/paste content to publish'
                                          field='content'
                                          onChange={ (event, data) => handleFieldChange(event, data, this) }/>
                                <br></br>
                                Content above is ascii/text <Radio  toggle label='Content above is HEX' field='contentIsHex'
                                            onChange={ (event, data) => handleFieldChange(event, data, this) }/>
                              </>
    const contentSpec = <>
                          Type/paste content, or <Radio toggle label='Upload a file'
                                                        field='contentIsAFile'
                                                        onChange={ (event, data) => handleFieldChange(event, data, this) }/>
                          <br></br>
                          {whichContent}
                        </>
    return (
      <>
        <Modal.Content image scrolling>
        <Container>
        <Modal.Description>

        {action}
        <Divider />
        {blurb}
        <Divider />
        <Form>
          {contentSpec}
          <br></br>

          {contentLabel}

          <Input fluid label='Reference TxId:' placeholder="Enter a Tx Id (hexadecimal) you'd like to reference"
                        value={this.state.refTxId}
                        field='refTxId' onChange={ (event, data) => handleFieldChange(event, data, this) }/>
          <div></div>

          {refTxLabel}
          {offerDialogMention}
          {guestPostSection}
          {spawnSection}
          {rabinSection}
        </Form>

        <pre id="contentPlace" style={{display:'none'}}></pre>

        </Modal.Description>
        </Container>
        </Modal.Content>

        <Modal.Actions className="modaledge">
          <Button disabled={transBuildBtnDisabled} content={buttonLabel} positive onClick={this.handlePublishSubmit}/>
          <Button onClick={ (event) => onCancel(event, this, 'transientBuilder') } content='CANCEL' negative/>
        </Modal.Actions>

        <KeySolicitingModal onInputChange={ (event, data) => handleFieldChange(event, data, this) }
                            openSecondaryModal={this.state.openSecondaryModal}
                            openBadKeysModal={this.state.openThirdModal}
                            parent={this}
                            closeThird={ () => closeOurThird(this) }
                            ownerRabinPKH={ownerRabinPKH}
                            //checkFilled={ () => bothPrivKeysAreFilled(this) }
                            onSubmit={ (event) => handleRabinKeyModalSubmit(event, this, 'transientBuilder') }
                            onCancel={ (event) => handleRabinKeyModalCancel(event, this)} />
      </>
    );
  } // TransientBuilder render()
}

class AskBidBuilder extends React.Component {
  constructor(props) {
    super(props);

    const dtx = JSON.parse( this.props.dtx );
    const outputChosen = this.props.outputChosen;
    const auctionState = dtx.outputStates[outputChosen]

    this.state =  { askingPrice: auctionState.askingPrice,
                    signatureNeeded: true,
                    refundP2PKH: '',
                    refundAddress: '',
                    bidSats: 0,
                    openSecondaryModal: false,
                    rabinPKH: '',      // used for signing tx we build  ??? perhaps SELLER rabin?
                    rabinPrivKeyP: '',
                    rabinPrivKeyQ: '',
                    userTypeVal: null,
                    selectedOperation: null,
                    selectedBid: null,
                    selectedBalk: null,
                    relevantRabinPKH: null     // WHICH rabin to sign against
                  };
    this.handleAuctionSubmit      = this.handleAuctionSubmit.bind(this);
    this.preCheckRabinPrivateKeys = this.preCheckRabinPrivateKeys.bind(this);
    this.buildAndPublish          = this.buildAndPublish.bind(this)
    this.reUseRabin               = this.reUseRabin.bind(this);
    this.userTypeChanged          = this.userTypeChanged.bind(this);
    this.opSelected               = this.opSelected.bind(this);
    this.bidSelected              = this.bidSelected.bind(this);
    this.balkSelected             = this.balkSelected.bind(this);
    this.updateRelevantPKH        = this.updateRelevantPKH.bind(this);
    this.handleRefundAddressChange= this.handleRefundAddressChange.bind(this);
  }

  updateRelevantPKH(userTypeVal, selectedOperation, auctionState) {
    // 1: owner   2: buyer/bidder
    console.log("askBidBuilder::updateRelevantPKH(): userTypeVal: " + userTypeVal)

    // owner:  C, T, A
    // bidder: D, G, T, R, r
    console.log("  selectedOperation: " + selectedOperation)

    let relevantRabinPKH = null;
    if ( userTypeVal === '1' ) {
      relevantRabinPKH = auctionState.sellerRabinPKH
      console.warn("Using (owner's) rabin PKH of " + relevantRabinPKH)
    } else if ( userTypeVal === '2' ) {
      if ( selectedOperation === 'G' || selectedOperation === 'D' ) {
        const topBid = auctionState.bestBidNum
        relevantRabinPKH = auctionState.bidEntries[ topBid ].rabinPKH
        console.warn("Using (top bidder's) rabin PKH of " + relevantRabinPKH)
      } else {
        console.error("IMPLEMENT ME: for operations T, R, or r, we need to know WHICH bidder you are.")
      }
    } else {
      console.warn("No relevantRabinPKH yet assigned. Owner or bidder?")
    }

    return relevantRabinPKH
  }

  // get relevant PKH based on userTypeVal, and operation selected
  opSelected(e, d) {
    const dtx = JSON.parse( this.props.dtx );
    const outputChosen = this.props.outputChosen;
    const auctionState = dtx.outputStates[outputChosen]
    let sigNeeded = true
    let relevantRabinPKH = null
    if ( d.value === 'B' || d.value === 'r' || d.value === 'T'
      || (d.value === 'R' && (auctionState.subMode === '65' || auctionState.subMode === '63') ) ) {
      // When placing a new bid, or refunding a balk, it's not necessary to sign the tx
      // nor, if refunding, and subMode is c or e
      sigNeeded = false
    } else if ( d.value === 'D' || d.value === 'G' ) {
      // when GRANTing, or DENYing/BALKing, it's clear which BID we're discussing: the TOP bid

      //const dtx = JSON.parse( this.props.dtx );
      //const outputChosen = this.props.outputChosen;
      //const auctionState = dtx.outputStates[ outputChosen ]
      const topBidnum = auctionState.bestBidNum

      console.warn("opSelected: top bid #" + topBidnum + ".  calling bidSelected()... <===")
      this.bidSelected(0, {value: topBidnum})

      const userTypeVal = this.state.userTypeVal
      console.warn("opSelected(): BTW: userTypeVal is " + userTypeVal)

      // get relevant PKH based on userTypeVal, and operation selected
      relevantRabinPKH = this.updateRelevantPKH(userTypeVal, d.value, auctionState)
    } else if ( d.value === 'b' ) {
      console.warn("opSelected(): a bid-specific operation: MODIFY. We don't yet know which is the relevant PKH <---")
    } else if ( d.value === 'E' || d.value === 'A' || d.value === 'P' || d.value === 'C' ) {
      relevantRabinPKH = auctionState.sellerRabinPKH
      console.warn("opSelected: setting relevantRabinPKH to owners - to ASK, request PERMISSION, or EXECUTE sale: " + relevantRabinPKH)
    } else {
      console.error("opSelected: IMPLEMENT ME: operation " + d.value + " not handled")
      throw new Error("29034: code error: unhandle operation")
    }
    console.log("opSelected(): d.value: ", d.value);

    this.setState({ selectedOperation: d.value,
                    relevantRabinPKH: relevantRabinPKH,
                    signatureNeeded: sigNeeded})
  }

  userTypeChanged(e) {
    console.log('userTypeChanged: ' + e.target.value);

    const dtx = JSON.parse( this.props.dtx );
    const outputChosen = this.props.outputChosen;
    const auctionState = dtx.outputStates[outputChosen]

    const selectedOperation = this.state.selectedOperation
    console.warn("userTypeChanged(): BTW: selectedOperation is " + selectedOperation)

    // get relevant PKH based on userTypeVal, and operation selected
    const relevantRabinPKH = this.updateRelevantPKH(e.target.value, selectedOperation, auctionState)

    this.setState({ userTypeVal: e.target.value,
                    relevantRabinPKH: relevantRabinPKH });
  }

  // validate that the private keys match the RELEVANT Rabin PKH
  preCheckRabinPrivateKeys() {
    const dtx = JSON.parse( this.props.dtx );
    const outputChosen = this.props.outputChosen;
    const auctionState = dtx.outputStates[outputChosen]
    let prevRabinPKH = auctionState.sellerRabinPKH;

    if ( this.state.userTypeVal === '1' ) {
      console.log("askBidBuilder preCheckRabinPrivateKeys(): currently only handling OWNER's 'previous' rabin PKH")

      const subMode = hexByteToAscii( auctionState.subMode )
      if (  (this.state.selectedOperation === 'A' && (subMode === 'N' || subMode === 'O'))
            ||
            (this.state.selectedOperation === 'P' && (subMode === 'O'))
            ||
            (this.state.selectedOperation === 'T' && (subMode === 'W'))
            ||
            (this.state.selectedOperation === 'E' && (subMode === 'G'))
            ||
            (this.state.selectedOperation === 'C' && (subMode !== 'c' && subMode !== 'C'))
          ) {
        console.log("askBidBuild prepared to handle owner operation of A, in mode N or O")
      } else {
        console.error("IMPLEMENT ME: logic to compare correct old rabin PKH for auction - with subMode other than N")
        throw new Error("17800: implement rabin PKH OWNER comparison for auction")
      }
    } else {
      //FIXME: which subModes are ok for B?
      if ( this.state.selectedOperation === 'B' ) {
        console.warn("NEW BID. so, no need to pre-validate")
      } else if ( this.state.selectedOperation === 'b' ) {
        console.error("IMPLEMENT ME: validating keys for BID MODIFICATION")
        //FIXME: set the relevantPKH properly
      } else if ( this.state.selectedOperation === 'R' ) {
        console.error("validating keys for BID REFUND")
        //FIXME: set the relevantPKH properly
      } else if ( this.state.selectedOperation === 'r' ) {
        console.error("validating keys for BALK REFUND (not needed, right?)")
        //FIXME: set the relevantPKH properly? Or, not needed? !sigNeeded?
      } else if ( this.state.selectedOperation === 'D' ) {
        console.warn("validating keys for deny/BALK")

        prevRabinPKH = this.state.relevantRabinPKH

      } else if ( this.state.selectedOperation === 'G' ) {
        console.warn("validating keys for GRANT")

        prevRabinPKH = this.state.relevantRabinPKH

      } else if ( this.state.selectedOperation === 'T' ) {
        console.warn("validating keys for TIMESTAMP (not needed)")
      } else {
        console.log(" selectedOperation: " + this.state.selectedOperation)
        console.error("IMPLEMENT ME: logic to compare correct old rabin PKH for auction - with userType other than 1 (owner)")
        throw new Error("17801: implement rabin PKH BIDDER/BALKER comparison for auction")
      }
    }
    console.warn("askBidBuilder preCheckRabinPrivateKeys(): checking against relevant PKH " + prevRabinPKH);

    return validateRabinPrivateKeys(this.state.rabinPrivKeyP, this.state.rabinPrivKeyQ, prevRabinPKH)
  }

  async handleAuctionSubmit() {  // AskBidBuilder
    console.log("AskBidBuilder::handleAuctionSubmit():  MAY trigger opening of key-soliciting modal");

    if ( this.state.signatureNeeded ) {
      // Opens secondary modal to solicit keys for signing
      // Results of that (a click of 'Build' or 'CANCEL') emerge at handleRabinKeyModalSubmit()
      //   or a cancel leads to handleRabinKeyModalCancel()
      this.setState({openSecondaryModal: true});
      // Will trigger buildAndPublish() after proper signing
    } else {
      console.log("AskBidBuilder handleAuctionSubmit(): calling buildAndPublish()")
      await this.buildAndPublish()
    }
  }

  async buildAndPublish() {
    const dtx = JSON.parse( this.props.dtx );
    const outputChosen = this.props.outputChosen;
    const auctionState = dtx.outputStates[ outputChosen ]
    const bids = auctionState.bidEntries
    const subMode = hexByteToAscii( auctionState.subMode )

    //FIXME: validate field values

    //FIXME: we'd like a policy where if the PKH is left blank, we'll re-use the previous/current

    console.log("AskBidBuilder::buildAndPublish()");
    console.log("AskBidBuilder(): seller rabinPKH:  " + this.state.rabinPKH);
    console.log("AskBidBuilder(): askingPrice:      " + this.state.askingPrice);
    console.log("AskBidBuilder(): build on subMode: " + subMode);
    console.log("AskBidBuilder(): number of bids:   " + auctionState.numBids);
    console.log("AskBidBuilder(): number of balks:  " + auctionState.numBalks);
    console.log("AskBidBuilder(): limb name:        " + hexStringToAscii( auctionState.namePath ) );
    console.log("AskBidBuilder(): opsCounter:       " + auctionState.opsCounter);
    console.log("AskBidBuilder(): beforeBlock:      " + auctionState.beforeBlock);


    //FIXME: based on current state/subMode, and OPERATION, decide what
    //       the NEW subMode should be, and/or the operation


    console.error("FIXME: finish implementing buildAndPublish() for askBidBuilder - for ALL subModes")


    try {
      console.warn("buildAndPublish(): selectedOperation: " + this.state.selectedOperation)
      //console.warn("buildAndPublish(): privKey: " + this.state.bsvPrivKey);

      let txid
      let newSubMode = auctionState.subMode
      let regardingEntry
      const commandHex = this.state.selectedOperation.charCodeAt(0).toString(16)
      let newBid = 0

      switch( this.state.selectedOperation ) {
        //FIXME: verify userTypeVal? why bother? UI controls it

        case 'A':  // set the Asking price

          // transition state
          // --> 'O'pen
          newSubMode = '4F'
          regardingEntry = auctionState.bestBidNum

          auctionState.askingPrice = this.state.askingPrice

          //FIXME: when/where should we alter/advance auctionState.blockNumInt?
          //       MAYBE just advance by bbm (if set), else, use current block
          console.warn("FIXME: AskBidBuilder buildAndPublish(): IMPLEMENT ME: alter/advance auctionState.blockNumInt - based on whatever policy (actual, blocksBeyondMin, etc)")

          console.log("buildAndPublish(): calling buildOnAskBid().");
          console.log("    building on tx: " + this.props.txOnWhichToBuild)

          txid = await buildOnAskBid( auctionState, this.props.txOnWhichToBuild, outputChosen,

                                      commandHex,
                                      newSubMode,
                                      regardingEntry,
                                      '0102030405060708090a0102030405060708090a',  //bidRefundP2PKH.  For 'A'sk, value not important. Length is (It's the owner)
                                      newBid,  // not used here

                                      this.state.rabinPKH,      //must match these, below
                                      this.state.rabinPrivKeyP,
                                      this.state.rabinPrivKeyQ,
                                      this.state.bsvPrivKey)
          break;

        case 'E':
          newSubMode = '65'  // 'e'
          regardingEntry = auctionState.bestBidNum

          console.log("buildAndPublish(): calling buildOnAskBid().");
          console.log("    building on tx: " + this.props.txOnWhichToBuild)

          txid = await buildOnAskBid( auctionState, this.props.txOnWhichToBuild, outputChosen,

                                      commandHex,
                                      newSubMode,
                                      regardingEntry,
                                      '0102030405060708090a0102030405060708090a',  //bidRefundP2PKH.  For 'A'sk, and 'P'ermission, value not important. Length is (It's the owner)
                                      newBid,  // not used here

                                      this.state.rabinPKH,      //must match these, below
                                      this.state.rabinPrivKeyP,
                                      this.state.rabinPrivKeyQ,
                                      this.state.bsvPrivKey)
          break;

        case 'P':  // request Permission
          newSubMode = '57' 	// ascii hex value 'W'aiting for permission
          regardingEntry = auctionState.bestBidNum

          console.log("buildAndPublish(): calling buildOnAskBid().");
          console.log("    building on tx: " + this.props.txOnWhichToBuild)
          console.warn("    regarding entry " + regardingEntry)

          txid = await buildOnAskBid( auctionState, this.props.txOnWhichToBuild, outputChosen,

                                      commandHex,
                                      newSubMode,
                                      regardingEntry,
                                      '0102030405060708090a0102030405060708090a',  //bidRefundP2PKH.  For 'A'sk, and 'P'ermission, value not important. Length is (It's the owner)
                                      newBid,  // not used here

                                      this.state.rabinPKH,      //must match these, below
                                      this.state.rabinPrivKeyP,
                                      this.state.rabinPrivKeyQ,
                                      this.state.bsvPrivKey)
          break;

        case 'C':  // CANCEL the entire auction
          if ( auctionState.numBids === 0 && auctionState.numBalks === 0 ) {
            newSubMode = '43'  // 'C' Closed
          } else {
            newSubMode = '63'  // 'c' closing
          }
          regardingEntry = 0   // ok?

          console.log("buildAndPublish(): calling buildOnAskBid().");
          console.log("    building on tx: " + this.props.txOnWhichToBuild)

          txid = await buildOnAskBid( auctionState, this.props.txOnWhichToBuild, outputChosen,

                                      commandHex,
                                      newSubMode,
                                      regardingEntry,
                                      '0102030405060708090a0102030405060708090a',  //bidRefundP2PKH.  For 'A'sk, and 'P'ermission, value not important. Length is (It's the owner)
                                      newBid,  // not used here

                                      this.state.rabinPKH,      //must match these, below
                                      this.state.rabinPrivKeyP,
                                      this.state.rabinPrivKeyQ,
                                      this.state.bsvPrivKey)
          break;

        case 'B':  // make a BID/offer
          regardingEntry = auctionState.numBids === 0 ?
                              0
                                :
                              ( auctionState.numBids === MAX_NUM_BIDS ?
                                  auctionState.worstBidNum
                                    :
                                  auctionState.numBids
                              )
          console.warn("regarded entry is " + regardingEntry)
          newBid = this.state.bidSats

          newSubMode = auctionState.subMode  // what if something timed-out? note. old state must have been 'O', right?

          console.warn("numBids WAS " + auctionState.numBids)

          console.warn("sumOfAllFunds WAS " + auctionState.sumOfAllFunds)

          console.log("buildAndPublish(): calling buildOnAskBid().");
          console.log("    building on tx: " + this.props.txOnWhichToBuild)

          txid = await buildOnAskBid( auctionState, this.props.txOnWhichToBuild, outputChosen,

                                      commandHex,
                                      newSubMode,
                                      regardingEntry,
                                      this.state.refundP2PKH,  // for 'A'sk, value not important. Length is (It's the owner)
                                      parseInt(newBid),

                                      this.state.rabinPKH,
                                      this.state.rabinPrivKeyP,
                                      this.state.rabinPrivKeyQ,
                                      this.state.bsvPrivKey)
          break;

        case 'b':  // modify BID
          console.warn("buildAndPublish(): regarding entry " + this.state.selectedBid)
          regardingEntry = this.state.selectedBid
          newSubMode = '4F'
          newBid = this.state.bidSats
          console.warn("sumOfAllFunds WAS " + auctionState.sumOfAllFunds + ".   THIS WILL NOW CHANGE")

          console.log("buildAndPublish(): calling buildOnAskBid().");
          console.log("    building on tx: " + this.props.txOnWhichToBuild)

          console.log("  NEW BID is " + newBid + ", " + parseInt(newBid))

          console.warn(" the UNCHANGED refundP2PKH is " + auctionState.bidEntries[ regardingEntry ].refundPKH)
          txid = await buildOnAskBid( auctionState, this.props.txOnWhichToBuild, outputChosen,

                                      commandHex,
                                      newSubMode,
                                      regardingEntry,
                                      auctionState.bidEntries[ regardingEntry ].refundPKH,
                                      parseInt(newBid),

                                      this.state.rabinPKH,
                                      this.state.rabinPrivKeyP,
                                      this.state.rabinPrivKeyQ,
                                      this.state.bsvPrivKey)

          break;

        case 'D':  // DENY permission to execute (BALK)
          console.warn("buildAndPublish(): regarding entry " + this.state.selectedBid)
          regardingEntry = this.state.selectedBid     // will/must be BEST bid  MAKE IT SO?
          newSubMode = '4F'
          newBid = this.state.bidSats

          console.log("buildAndPublish(): calling buildOnAskBid().");
          console.log("    building on tx: " + this.props.txOnWhichToBuild)

          txid = await buildOnAskBid( auctionState, this.props.txOnWhichToBuild, outputChosen,

                                      commandHex,
                                      newSubMode,
                                      regardingEntry,
                                      '0102030405060708090a0102030405060708090a',  //bidRefundP2PKH.  For 'A'sk, and 'P'ermission, value not important. Length is (It's the owner)
                                      newBid,  // used here ??

                                      this.state.rabinPKH,      //must match these, below
                                      this.state.rabinPrivKeyP,
                                      this.state.rabinPrivKeyQ,
                                      this.state.bsvPrivKey)
          break;

        case 'G':  // GRANT permission to execute
          console.warn("buildAndPublish(): regarding entry " + this.state.selectedBid)
          regardingEntry = this.state.selectedBid     // will/must be BEST bid  MAKE IT SO?
          newSubMode = '47'   // change mode to 'G'ranted
          newBid = this.state.bidSats

          console.log("buildAndPublish(): calling buildOnAskBid().");
          console.log("    building on tx: " + this.props.txOnWhichToBuild)

          txid = await buildOnAskBid( auctionState, this.props.txOnWhichToBuild, outputChosen,

                                      commandHex,
                                      newSubMode,
                                      regardingEntry,
                                      '0102030405060708090a0102030405060708090a',  //bidRefundP2PKH.  For 'A'sk, and 'P'ermission, value not important. Length is (It's the owner)
                                      newBid,  // used here ??

                                      this.state.rabinPKH,      //must match these, below
                                      this.state.rabinPrivKeyP,
                                      this.state.rabinPrivKeyQ,
                                      this.state.bsvPrivKey)
          break;

        case 'T':  // Timestamp

          newSubMode = '4F';

          // requires NO SIGNATURE

          // Don't allow timestamp if there are no bids?
          regardingEntry = 0  // hmm. seems it MUST be topBid
          if ( auctionState.numBids > 0 ) { // would there really be no bids?
            regardingEntry = auctionState.bestBidNum
          } else {
            throw new Error("26100: CODING ERROR: must use bestBid when timestamping")
          }
          //NOTE: probably enforces topBid somewhere obscure in the contracts

          newBid = 0  // not used

          txid = await buildOnAskBid( auctionState, this.props.txOnWhichToBuild, outputChosen,

                                    commandHex,
                                    newSubMode,
                                    regardingEntry,
                                    '0102030405060708090a0102030405060708090a',  //bidRefundP2PKH.  For 'A'sk, and 'P'ermission, value not important. Length is (It's the owner)
                                    newBid,  // used here ??

                                    this.state.rabinPKH,      // these aren't used
                                    this.state.rabinPrivKeyP,
                                    this.state.rabinPrivKeyQ,
                                    this.state.bsvPrivKey)
          break;

        case 'R':  // refund Bid
          console.warn("buildAndPublish(): regarding entry " + this.state.selectedBid)
          regardingEntry = this.state.selectedBid
          newSubMode = '4F'

          console.warn("sumOfAllFunds WAS " + auctionState.sumOfAllFunds + ".   THIS WILL NOW CHANGE")

          console.log("buildAndPublish(): calling buildOnAskBid().");
          console.log("    building on tx: " + this.props.txOnWhichToBuild)

          console.warn(" the refund P2PKH we're about to use is " + auctionState.bidEntries[ regardingEntry ].refundPKH)
          txid = await buildOnAskBid( auctionState, this.props.txOnWhichToBuild, outputChosen,

                                      commandHex,
                                      newSubMode,
                                      regardingEntry,
                                      auctionState.bidEntries[ regardingEntry ].refundPKH,
                                      parseInt(newBid), //FIXME: put dummy. document not used for refund

                                      this.state.rabinPKH,
                                      this.state.rabinPrivKeyP,
                                      this.state.rabinPrivKeyQ,
                                      this.state.bsvPrivKey)
          break;

        case 'r':  // refund BALK
          console.warn("buildAndPublish(): regarding entry " + this.state.selectedBalk)
          regardingEntry = this.state.selectedBalk
          newSubMode = auctionState.subMode   //newSubMode = '4F'

          console.warn("sumOfAllFunds WAS " + auctionState.sumOfAllFunds + ".   THIS WILL NOW CHANGE")

          console.log("buildAndPublish(): calling buildOnAskBid().");
          console.log("    building on tx: " + this.props.txOnWhichToBuild)

          console.warn(" the refund P2PKH we're about to use is " + auctionState.balkEntries[ regardingEntry ].refundPKH)
          txid = await buildOnAskBid( auctionState, this.props.txOnWhichToBuild, outputChosen,

                                      commandHex,
                                      newSubMode,
                                      regardingEntry,
                                      auctionState.balkEntries[ regardingEntry ].refundPKH,
                                      0, //parseInt(newBid), //FIXME: put dummy. document not used for refund

                                      this.state.rabinPKH,
                                      this.state.rabinPrivKeyP,
                                      this.state.rabinPrivKeyQ,
                                      this.state.bsvPrivKey)
          break;


        //FIXME: include other operations, besides Ask, Bid, ModBid, request Permission, Deny/BALK


        default:
          throw new Error("22202: attempt to build with invalid operation: " + this.state.selectedOperation)
      }

      console.log("AskBidBuilder::buildAndPublish(): we've built a tx ", txid);

      this.props.handleBuildEvent(txid);
    } catch (error) {
      console.log('Failed in AskBidBuilder::buildAndPublish()');
      //console.log('Failed on network: ' + NETWORK)
      console.error("stack: " + error.stack);
      printError(error);    // needed?
    }
  } // AskBidBuilder buildAndPublish()

  //FIXME: when state is 'N', re-using PKH means using the Owner/seller PKH
  //       OTHERWISE, we'll need to know WHICH USER/BIDDER the user is.
  //
  //       ONE way to do that is to scan all bids, and compare to all PKHs in memory
  //       ANOTHER way is to keep a special variable for auctions - of a given asset/limb
  //         BUT, will need to consider: what if user is using a fresh browser (no saved state)
  //         It might still be fine, but, be sure to consider it

  reUseRabin(event) {
    event.preventDefault();
    const dtx = JSON.parse( this.props.dtx );
    const outputChosen = this.props.outputChosen;
    const auctionState = dtx.outputStates[ outputChosen ]
    const bids = auctionState.bidEntries
    const subMode = hexByteToAscii( auctionState.subMode )

    console.warn("AskBidBuilder reUseRabin() operation: " + this.state.selectedOperation)

    let prevRabinPKH = ''
    if ( this.state.userTypeVal === '1' ) {             // any submode
      prevRabinPKH = auctionState.sellerRabinPKH
    } else {
      // if command is mod-bids, get old rabin from entry
      if ( this.state.selectedBid !== null
              && (this.state.selectedOperation === 'b'  // modify bid
                    ||
                    this.state.selectedOperation === 'D'  // deny/balk
                    ||
                    this.state.selectedOperation === 'T'  // timestamp
                    ||
                    this.state.selectedOperation === 'G') ) {  // grant permission
        prevRabinPKH = auctionState.bidEntries[ this.state.selectedBid ].rabinPKH
        console.warn("AskBidBuilder: setting rabinPKH (from previous) to " + prevRabinPKH)
      } else {
        console.error("AskBidBuilder: IMPLEMENT ME: since not OWNER, and not modifying bid, (nor refunding,? irrelevant?) determine WHICH previous rabin PKH to use <---")
        throw new Error("32100: implement: which rabinPKH to re-use for operation " + this.state.selectedOperation)
      }
    }

    console.log("AskBidBuilder reUseRabin():  will re-use PKH " + prevRabinPKH)
    this.setState({rabinPKH: prevRabinPKH});
  }

  // set which BID we'd like to modify (or refund)
  bidSelected(e, d) {
    console.log("bidSelected(): d.value: ", d.value);

    const dtx = JSON.parse( this.props.dtx );
    const outputChosen = this.props.outputChosen;
    const auctionState = dtx.outputStates[ outputChosen ]

    // set the rabinPKH
    const rabinPKH = auctionState.bidEntries[ d.value ].rabinPKH
    console.warn("askBidBuilder  bidSelected(): for Bid #" + d.value + ", rabin is " + rabinPKH)
    this.setState({selectedBid: d.value, relevantRabinPKH: rabinPKH});
    //NOTE: had erroneously set 'rabinPKH'
    //      Also see this.updateRelevantPKH(userTypeVal, 'b', auctionState)
    //      call in opSelected()
  }

  // set which BALK we'd like to refund
  balkSelected(e, d) {
    console.log("balkSelected(): d.value: ", d.value);

    //const dtx = JSON.parse( this.props.dtx );
    //const outputChosen = this.props.outputChosen;

    console.warn("askBidBuilder  balkSelected(): for Bid #" + d.value + ". NO rabin is needed")
    this.setState({selectedBalk: d.value});
  }

  handleRefundAddressChange(event) {
    console.log("handleRefundAddressChange(): REFUND address now set to ", event.target.value);

    const p2pkh = convertAddressToPKH(event.target.value)
    if ( p2pkh === -1 ) {
      //FIXME: WARN user that this address is for the WRONG network
      this.setState({refundAddress: event.target.value, refundP2PKH: ''})
    } else if ( p2pkh !== null ) {
      console.warn("handleRefundAddressChange: setting refundP2PKH to " + p2pkh)
      console.warn("handleRefundAddressChange: setting refundAddress to " + event.target.value)
      this.setState({refundAddress: event.target.value, refundP2PKH: p2pkh})
    } else {
      this.setState({refundAddress: event.target.value, refundP2PKH: ''})
    }
  }

  render() {  // AskBidBuilder
    // 1: owner   2: buyer/bidder
    const userTypeVal = this.state.userTypeVal;
    console.log("askBidBuilder::render(): NEW userTypeVal: " + userTypeVal)

    // owner:  C, T, A
    // bidder: D, G, T, R, r
    const selectedOperation = this.state.selectedOperation;
    console.log("SELECTED OPERATION: " + selectedOperation)

    const dtx = JSON.parse( this.props.dtx );
    const outputChosen = this.props.outputChosen;
    const auctionState = dtx.outputStates[ outputChosen ]

    // If we're a BIDDER, the relevant Rabin is different than if we're the OWNER
    // (relevant Rabin PKH to sign with)
    // userTypeVal   1: owner          2: a bidder
    // selectedOperation  C, A,   T,   D, G, R, r
    //
    // NOTE: this was set based on the userTypeVal, and selectedOperation
    //       in userTypeChanged(), and opSelected()
    let relevantRabinPKH = this.state.relevantRabinPKH;

    console.log("output chosen is " + outputChosen);
    const chosenMode = hexByteToAscii( auctionState.mode );
    const subMode = hexByteToAscii( auctionState.subMode );
    const buildButtonLabel = 'BUILD contract on output of mode ' + chosenMode

    console.log("askBidBuilder::render() call libGetOptionsForAskBidSubMode() with: " + subMode)
    const commands = libGetOptionsForAskBidSubMode(subMode)
    console.log("  got back: ", commands)

    let commandList = [];
    let extra = null
    let bidRefundExtra = null
    let balkRefundExtra = null
    let cmdIdx = 0
    let cmdReasonToDisableBuild = false
    let numBids = auctionState.numBids
    let numBalks = auctionState.numBalks

    let whichBid = null
    let whichBalk = null
    //FIXME: 2 corresponds to newAskBid.scrypt's MIN_BID_DUST_MULTIPLER
    //       It may be increased for production
    const minBid = numBids < MAX_NUM_BIDS ? 2 * auctionState.smallestAmount
                              : auctionState.bidEntries[ auctionState.worstBidNum ].price + 1
    if ( userTypeVal !== null ) {

      let bidList = []
      for ( let i = 0; i < auctionState.numBids; i++ ) {
        const bidPrice = auctionState.bidEntries[i].price
        const rabin = ' - RabinPKH: ' + auctionState.bidEntries[i].rabinPKH
        const bidSummary = "Bid " + i + ': ' + bidPrice + " sats"
        bidList[i] =  { key: i, value: i, text: bidSummary + rabin}
      }

      let balkList = []
      for ( let i = 0; i < numBalks; i++ ) {
        const balkPrice = auctionState.balkEntries[i].funds

        // NO RABIN needed for BALK refund

        const refundP2PKH = ' - refund P2PKH: ' + auctionState.balkEntries[i].refundPKH
        const balkSummary = "Balk " + i + ': ' + balkPrice + " sats"
        balkList[i] =  { key: i, value: i, text: balkSummary + refundP2PKH}
      }

      for ( let i = 0; i < commands.length; i++ ) {
        const key =  i;
        let commandDescription = null
        if ( userTypeVal === '1' ) {

          switch(commands[i]) {
            case 'A':
              commandDescription = "Ask (owner sets the asking price)"
              if ( selectedOperation === commands[i] ) {
                extra =
                        <div>
                          You (as owner) will set an asking price. &nbsp;
                          <Input  label="Asking Price (sats):" field='askingPrice' type="number" min="1" max="1099511627775"
                                  value={this.state.askingPrice}
                                  onChange={ (event, data) => handleFieldChange(event, data, this) } />
                          <p>This is a non-binding signal to potential bidders</p>
                        </div>
                cmdReasonToDisableBuild = this.state.askingPrice < minBid
              }
              break;
            case 'C':
              commandDescription = "Cancel (owner cancels the entire auction)"
              if ( selectedOperation === commands[i] ) {
                extra =
                        <div>
                          You (as owner) will CANCEL the entire auction.
                        </div>
              }
              break;
            case 'P':
              commandDescription = "Owner Requests permission to execute the sale"
              if ( selectedOperation === commands[i] ) {
                extra =
                        <div>
                          You (as owner) will request to take/execute the top offer.
                        </div>
              }
              break;
            case 'E':
              commandDescription = "Owner executes sale - receiving funds, transfering ownership"
              break;
            case 'T':
              // bidder or owner, handled below
              break;
            default:
              console.log("invalid command for owner: " + commands[i])
              break;
          }

        } else if ( userTypeVal === '2' ) {

          switch(commands[i]) {
            case 'B':
              commandDescription = "Bid (offer a price for the asset)"
              if ( selectedOperation === commands[i] ) {
                const refundP2PKHLabel = 'In case you need a BID refund: '
                const refundP2PKHPlaceholder = 'Enter your refund address (to receive a refund)'
                extra =
                        <div>
                          You could BID on the asset (min {minBid} sats).<br></br>
                          <Input  label="Your bid (satoshis to send):" field='bidSats' type="number" min="1" max="1099511627775"
                                  value={this.state.bidSats}
                                  onChange={ (event, data) => handleFieldChange(event, data, this) } />

                          <Input  label={refundP2PKHLabel} field='maybeRefundAddress' placeholder={refundP2PKHPlaceholder}
                                  value={this.state.refundAddress} style={{width: "515px"}}
                                  onChange={ this.handleRefundAddressChange }/>
                          <br></br>Sample refund Address (Don't use this. It's not yours): msHtTyGVo8rjaSxnaVkwmD5Co151URrGan
                          <div>(your refundP2KH is {this.state.refundP2PKH})</div>
                        </div>
                cmdReasonToDisableBuild = this.state.bidSats < minBid || this.state.refundP2PKH.length !== 40

              }
              break;
            case 'b':
              commandDescription = "Modify Bid (change your offer for the asset)"
              if ( selectedOperation === commands[i] && numBids > 0 ) {
                let bidModInput = null
                if ( this.state.selectedBid !== null ) {
                  console.warn("state.selectedBid is " + this.state.selectedBid)
                  const currBidAmt = auctionState.bidEntries[ this.state.selectedBid ].price
                  bidModInput =
                      <>
                      YOUR current bid amount: {currBidAmt} &nbsp;
                      <Input  label="NEW bid amount (satoshis):" field='bidSats' type="number" min="1" max="1099511627775"
                              value={this.state.bidSats}
                              onChange={ (event, data) => handleFieldChange(event, data, this) } />
                      <br></br>
                      NOTE that this new amount must NOT match any existing bid
                      </>
                }
                extra =
                        <div>
                          You could MODIFY your bid on the asset.
                          <Dropdown onChange={this.bidSelected}
                              placeholder='Which BID is yours?'
                              selection
                              options={bidList}
                            />
                          <div>
                          {bidModInput}
                          </div>
                        </div>
              }
              break;
            case 'R':
              commandDescription = "Refund Bid (take back your offer - receiving funds)"
              if ( selectedOperation === commands[i] && numBids > 0 ) {
                if ( this.state.selectedBid !== null ) {
                  console.warn("state.selectedBid is " + this.state.selectedBid)
                  const currBidAmt =    auctionState.bidEntries[ this.state.selectedBid ].price
                  const reserves =      auctionState.bidEntries[ this.state.selectedBid ].reserveFunds
                  const totalRefund =   currBidAmt + reserves
                  const refundP2PKH =   auctionState.bidEntries[ this.state.selectedBid ].refundPKH
                  const earliestBlock = auctionState.bidEntries[ this.state.selectedBid ].earliestRefundBlock
                  let reservesText = <></>
                  if ( reserves > 0 ) {
                     reservesText = <> + {reserves} (reserve funds) = {totalRefund}</>
                  }
                  bidRefundExtra =
                      <>
                      YOUR bid refund amount to receive: {currBidAmt} {reservesText} satoshis
                      <br></br>
                      Refund P2PKH to receive refund: {refundP2PKH}
                      <br></br>
                      Earliest block when you can receive refund: {earliestBlock}
                      </>

                  // As long as user identified which bid, we should be good to build
                  // (will validate keys soon)
                  cmdReasonToDisableBuild = false

                }

                extra =
                        <div>
                          You could get a REFUND on your bid for the asset. &nbsp;
                          <Dropdown onChange={this.bidSelected}
                              placeholder='Which BID is yours?'
                              selection
                              options={bidList}
                            />
                        </div>
              }
              break;
            case 'r':
              commandDescription = "Refund Balk (take back your rescinded offer - receiving funds)"
              if ( selectedOperation === commands[i] && numBalks > 0) {
                if ( this.state.selectedBalk !== null ) {
                  console.warn("state.selectedBalk is " + this.state.selectedBalk)
                  const balkAmt =    auctionState.balkEntries[ this.state.selectedBalk ].funds
                  const refundP2PKH =   auctionState.balkEntries[ this.state.selectedBalk ].refundPKH
                  const earliestBlock = auctionState.balkEntries[ this.state.selectedBalk ].earliestRefundBlock

                  balkRefundExtra =
                      <>
                      YOUR balk refund amount to receive: {balkAmt} satoshis
                      <br></br>
                      Refund P2PKH to receive refund: {refundP2PKH}
                      <br></br>
                      Earliest block when you can receive refund: {earliestBlock}
                      </>

                  // As long as user identified which balk, we should be good to build
                  cmdReasonToDisableBuild = false

                }
                extra =
                        <div>
                          You could get a REFUND on your BALK - rescinded bid/offer for the asset. &nbsp;
                          <Dropdown onChange={this.balkSelected}
                              placeholder='Which BALK is yours?'
                              selection
                              options={balkList}
                            />
                        </div>
              }
              break;
            case 'D':
              commandDescription = "Top Bidder DENIES permission to execute sale (balks)"
              break;
            case 'G':
              commandDescription = "Top bidder GRANTS permission to execute sale"
              break;
            case 'T':
              // bidder or owner, handled below
              break;
            default:
              console.log("invalid command for bidder: " + commands[i])
              break;
          }

        } else {
          throw new Error("30000: invalid user type: " + userTypeVal)
        }

        // both owner and bidder could timestamp
        if ( commands[i] === 'T' ) {
          // Timestamp when waiting for permisson, or when granted.
          //    - when waiting, break-out if bidder never responds (and we've waited long enough)
          //    - when granted, to keep owner honest (document he's not yet executed)
          commandDescription = "User takes a timestamp - to avoid trickery, or to keep the auction moving"

          if ( selectedOperation === commands[i] ) {
              extra = <>
                        You can take a timestamp to document that time has elapsed, and a bidder (or the owner) has not yet acted. &nbsp;
                      </>
          }
        } // command T

        if ( commandDescription !== null ) {
          // add command to list of options/commands to display
          commandList[cmdIdx] =  { key: key, value: commands[i], text: commands[i] + ': ' + commandDescription}
          cmdIdx++
        }
      } // loop through all available commands for the current auction subMode
    }
    console.log("  commandList: ", commandList)
    const pickCommand = <> Choose operation: <Dropdown  placeholder='command/operation'
                                              onChange={this.opSelected}
                                              selection
                                              options={commandList}
                                  />
                        </>

    let subModeDescription = null
    switch(subMode) {
      case 'N':
        subModeDescription = "(Not yet open) Ready for OWNER to set a price"
        //FIXME: minimum price is actually related to smallestAmount, I think


        //FIXME: there's only 1 rabinPKH - the owner's

        break;
      case 'O':
        subModeDescription = "(Open for bids) Anyone can make a bid (or alter their bid, or get refund). Owner can request permission to execute highest bid."
        //extra = 'There are ' + numBids + " bids."
        //FIXME: mention the best bid
        //FIXME: mention can retrieve refund
        break;
      case 'W':
        subModeDescription = "(Waiting for permission to execute sale) Can be granted (or denied) by highest bidder only. Owner could cancel."
        //FIXME: metion price (best bid)
        //FIXME: can refunds be processed?
        //FIXME: mention deadline
        break;
      case 'G':
        subModeDescription = "(Permission granted to execute) Owner can now execute the sale. Others must wait."
        //FIXME: can refunds be processed?
        //FIXME: mention deadline
        break;
      case 'e':
        subModeDescription = "(Sale executed, but refunds pending) Any BIDDER (except the BUYER) can retrieve their BID/refund"
        //FIXME: allow to specify user number, or PKH?
        //FIXME: mention any wait
        break;
      case 'E':
        subModeDescription = "(Sale fully Executed)"
        break;
      case 'c':
        subModeDescription = "(Sale cancelled, but refunds pending) Any BIDDER can retrieve their BID/refund"
        //FIXME: allow to specify user number, or PKH?
        //FIXME: mention any wait
        break;
      case 'C':
        subModeDescription = "(Sale fully Cancelled)"
        break;
      default:
        console.error("Invalid auction subMode: " + subMode)
        throw new Error("16802: invalid auction subMode: " + subMode)
    }

    if ( userTypeVal === '2' ) {
      console.warn("There are " + numBids + " bids we could look through, if we had to, to help identify user.")
    }

    // disable 're-use PKH' if we're starting a NEW bid - there's no old PKH to re-use
    const disableReUseButton = (
                    this.state.selectedOperation === 'R'        // refund BID
                      ||
                      this.state.selectedOperation === 'r'        // refund BALK
                      ||
                    ((this.state.selectedOperation === 'b') && this.state.selectedBid === null )   // mod bid, but not sure which
                      ||
                    (userTypeVal === '2' && selectedOperation === 'B')       // new bid
            ); //FIXME: or, maybe if it has ANY value?
    const rabinPKHLabel = disableReUseButton ? 'Rabin PKH:' : 'Next Rabin PKH:';
    const rabinPKHPlaceholder = disableReUseButton ? 'Rabin PKH to use' : 'Your next Rabin PKH to use';
    let reUseButton = <Button disabled={disableReUseButton} content='Re-Use Current PKH' positive onClick={this.reUseRabin}/>;

    let disableUtility = false
    if ( this.state.rabinPKH && this.state.rabinPKH.length > 0 ) {
      disableUtility = true
    }

    let bestBidPrice = null
    let worstBidPrice = null
    if ( numBids > 0 ) {
      bestBidPrice = auctionState.bidEntries[ auctionState.bestBidNum ].price
      worstBidPrice = auctionState.bidEntries[ auctionState.worstBidNum ].price
    }
    const bidStatus = numBids > 0 ? (
                          numBids === MAX_NUM_BIDS ? (
                              <> &nbsp; &nbsp; We already have the max number of bids ({numBids}). &nbsp; &nbsp;<span style={{color: 'blue'}}>Best: {bestBidPrice} sats</span> &nbsp; &nbsp;Worst: {worstBidPrice} sats</>
                          ) : (
                              <> &nbsp; &nbsp; {numBids} bid(s) &nbsp; &nbsp;<span style={{color: 'blue'}}>Best: {bestBidPrice} sats</span> &nbsp; &nbsp;Worst: {worstBidPrice} sats</>
                          )
                      ) : (
                          <> There are no bids </>
                      )

    console.warn("askBidBuilder: selected operation is " + this.state.selectedOperation)
    console.warn("askBidBuilder: relevantRabinPKH is " + relevantRabinPKH)

    // When adding a NEW bid, there's no existing rabinPKH to re-use
    let rabinSettingExtraText
    if ( this.state.selectedOperation === 'B' ) {
      rabinSettingExtraText = <>You MUST declare a Rabin PKH to authenticate your NEXT transaction.</>
    } else {
      rabinSettingExtraText = <>You may declare a new Rabin PKH to authenticate your NEXT transaction, OR you may decide to re-use your current Rabin PKH.</>
    }

    // Don't ask for the NEXT RabinPKH if user is getting a refund
    const optionalRabinPkhInput = ( this.state.selectedOperation === 'r'
                                || this.state.selectedOperation === 'R'
                                || this.state.selectedOperation === 'T' ) ?
        null :
        <>
          <Divider />
          <div>Rabin PKHs help authenticate transaction publishing rights on a Limb.</div>
          <div>Auction transactions are different, in that they allow multiple users to declare their own Rabin PKHs - for controlling their own bids/offers.</div>
          <div>Almost every transaction must declare a Rabin PKH (to unlock the user's NEXT transaction). {rabinSettingExtraText}</div>
          <Input field='rabinPKH' label={rabinPKHLabel} placeholder={rabinPKHPlaceholder}
                        value={this.state.rabinPKH} style={{width: "360px"}}
                        onChange={ (event, data) => handleFieldChange(event, data, this) }/>
          &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
          &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
          {reUseButton}&nbsp;
          <RabinUtility disabled={disableUtility} parent={this} />
        </>

    // don't allow building unless all required fields are filled
    const auctionBuildBtnDisabled = cmdReasonToDisableBuild ||
                          ( selectedOperation === null ) ||
                          ( this.state.rabinPKH.length !== 40 && (selectedOperation !== 'r' && selectedOperation !== 'T' ) )  // refunding balk, or timestamping, requires no rabin

    //NOTICE that the KeySolicitingModal has a 'key' property
    return (
      <>
        <Modal.Content image scrolling>
        <Container>
        <Modal.Description>

        <p><b>'AUCTION' Contract Builder</b></p>

        <p>When RENEWING an asset, an owner can simultaneously spawn an AUCTION contract.
           Such a contract enables the owner to solicit bids, and eventually transfer
           the keys of ownership, while simultaneously claiming the funds of the top bid.</p>
        <Divider />
        <div>Such an auction is a fairly sophisticated and <span style={{color: 'red'}}>EXPERIMENTAL</span> process. It has not been suitably tested.</div>
        <div>As an owner of an asset, <span style={{color: 'red'}}>do not build/use this contract to sell your asset</span> unless you're prepared to permanently lose
             ownership of your asset without reimbursement. Testing has not been exhaustive.</div>
        <p></p>
        <div>Similarly, <span style={{color: 'red'}}>do not BID on assets</span> unless you're prepared to lose your bid without receiving ownership of an asset. Testing
          has not been exhaustive. Also, note that every step in the auction process requires posting/building a transaction that costs more than most other BitShizzle
          transactions, and to 'win' an auction, you may be required to post many such transactions - with no guarantee you'll 'win'. And if you don't win the auction,
          you'll need to construct a transaction to receive a refund for your bid - potentially after a significant delay.
        </div>

        <Divider />
        Current Asking Price: {auctionState.askingPrice} satoshis
        <div>{bidStatus}</div>
        Current state: {subMode}
        <div>
          {subModeDescription}
        </div>
        <Divider />
        <div>You are:</div>
          <Form.Group>
              <Form.Field label='the owner/seller'  type='radio' value='1' control='input' checked={userTypeVal==='1'} onChange={this.userTypeChanged}/>
              <Form.Field label='a buyer/bidder'    type='radio' value='2' control='input' checked={userTypeVal==='2'} onChange={this.userTypeChanged}/>
          </Form.Group>

        <div> {pickCommand} </div>
        {extra}
        {bidRefundExtra}
        {balkRefundExtra}

        {optionalRabinPkhInput}

        </Modal.Description>
        </Container>
        </Modal.Content>

        <Modal.Actions className="modaledge">
          <Button disabled={auctionBuildBtnDisabled} content={buildButtonLabel} positive onClick={this.handleAuctionSubmit}/>
          <Button onClick={ (event) => onCancel(event, this, 'askBidBuilder') } content='CANCEL' negative/>
        </Modal.Actions>

        <KeySolicitingModal onInputChange={ (event, data) => handleFieldChange(event, data, this) }
                            openSecondaryModal={this.state.openSecondaryModal}
                            openBadKeysModal={this.state.openThirdModal}
                            parent={this}
                            closeThird={ () => closeOurThird(this) }
                          ownerRabinPKH={relevantRabinPKH}
                          key={relevantRabinPKH}
                            //checkFilled={ () => bothPrivKeysAreFilled(this) }
                            onSubmit={ (event) => handleRabinKeyModalSubmit(event, this, 'askBidBuilder') }
                            onCancel={ (event) => handleRabinKeyModalCancel(event, this)} />

      </>
    );
  } // AskBidBuilder render()
}


/**
 * For building on Continue's P-, K-, or p-mode outputs
 * (also known as Quarterly)
 */
class ClaimBuilder extends React.Component {

      state = {   notTheOwner: false,
                  alterInstaBuy: false,
                  newInstaPrice: '',
                  newOwnerP2Pkh: '',
                  spawnUpdate: true,
                  spawnSale: false,
                  openSecondaryModal: false,
                  content: '',
                  contentLabel: '',
                  refTxId: '',
                  refTxLabel: '',
                  paycode: '',
                  rabinPKH: '',
                  rabinPrivKeyP: '',
                  rabinPrivKeyQ: '',
                  auctionExecuted: false,
                  recentlyExpedited: false,
                  specifyPotentialSale: false,
                  clearPotentialSale: false,
                  transferOwnership: false,
                  potentialBuyerRabinPKH: '',
                  authcode: '',
                  authcodePadding: '',
                  sellerRabinPubKey: '',
                  applyBuilderFeeReduction: false,
                  bfrSignature: '',
                  bfrPadding: '',
                  builderPubKey: '',

                  showContentFileReaderModal: false,
                  fileChosenToUpload: '',
                  contentChosenToUpload: '',

                  openFundingModal: false,
                  bsvPrivKey: '',
                  bsvKeyIsSufficient: false,
                  chosenAddress: ''
              };

  constructor(props) {
    super(props);

    console.log("CLAIMBUILDER CTOR: recentlyExpedited: " + this.state.recentlyExpedited)

    this.handlePublishSubmit      = this.handlePublishSubmit.bind(this);
    this.buildAndPublish          = this.buildAndPublish.bind(this);
    this.preCheckRabinPrivateKeys = this.preCheckRabinPrivateKeys.bind(this);
    this.reUseRabin               = this.reUseRabin.bind(this);
    this.maybeAdjust              = this.maybeAdjust.bind(this);
    this.submitFeeReductionTx     = this.submitFeeReductionTx.bind(this);

    this.showContentFileReaderModal  = this.showContentFileReaderModal.bind(this);
    this.closeContentFileReaderModal = this.closeContentFileReaderModal.bind(this);
    this.handleChosenFile            = this.handleChosenFile.bind(this);

    this.openFundsGetter             = this.openFundsGetter.bind(this);
    this.closeFundsGetter            = this.closeFundsGetter.bind(this);
  }

  preCheckRabinPrivateKeys() {
    const dtx = JSON.parse( this.props.dtx );
    const outputChosen = this.props.outputChosen;
    const prevRabinPKH = dtx.outputStates[outputChosen].ownerRabinPKH;

    console.log("ClaimBuilder preCheckRabinPrivateKeys(): checking against previous PKH " + prevRabinPKH);

    return validateRabinPrivateKeys(this.state.rabinPrivKeyP, this.state.rabinPrivKeyQ, prevRabinPKH)
  }

  async submitFeeReductionTx() {
    console.warn("ClaimBuilder::submitFeeReductionTx(): We're going to build a SPECIAL tx - applying a Builder Fee Reduction Authorization")
    //console.warn("submiteFeeReductionTx similar to buildAndPublish(): privKey: " + this.state.bsvPrivKey);
    console.log("submitFeeReductionTx(): bfrSignature:  " + this.state.bfrSignature);
    console.log("submitFeeReductionTx(): bfrPadding:    " + this.state.bfrPadding);
    console.log("submitFeeReductionTx(): builderPubKey: " + this.state.builderPubKey);

    //NOTE: we could here determine if the pubKey matches any builder
    //      OR, we could accept a return code to see if/why it fails

    const dtx = JSON.parse(this.props.dtx);
    console.log("submitFeeReductionTx(): dtx = ", dtx);

    const outIndex = this.props.outputChosen;
    console.log("submitFeeReductionTx(): outIndex = " + outIndex);

    const theState = dtx.outputStates[ outIndex ];

    try {
      console.log("submitFeeReductionTx(): calling applyBuilderFeeReduction().");

      const txid = await applyBuilderFeeReduction(theState, this.props.txOnWhichToBuild, outIndex,
                        this.state.bfrSignature,
                        this.state.bfrPadding,
                        this.state.builderPubKey,
                        this.state.bsvPrivKey )
      // now pass back THIS tx - to make it the txToConsider
      console.log("ClaimBuilder::submitFeeReductionTx(): we've built a tx ", txid);

      this.props.handleBuildEvent(txid);

    }  catch (error) {
      console.log('Failed in ClaimBuilder::submitFeeReductionTx()');
      //console.log('Failed on network: ' + NETWORK)
      console.error("stack: " + error.stack);
      printError(error);    // needed?
    }

  }

  async buildAndPublish() {  // ClaimBuilder

    //FIXME: validate field values

    //FIXME: MAYBE we'd like a policy where if the PKH is left blank, we'll re-use the previous/current

    console.log("ClaimBuilder::buildAndPublish(): content: "      + this.state.content);
    //console.warn("buildAndPublish(): privKey: " + this.state.bsvPrivKey);
    console.log("buildAndPublish(): contentIsHex: "  + this.state.contentIsHex)
    console.log("buildAndPublish(): contentIsAFile: "+ this.state.contentIsAFile)
    console.log("buildAndPublish(): contentChosenToUpload: " + (this.state.contentChosenToUpload.length / 2) + " bytes")
    console.log("buildAndPublish(): contentLabel: " + this.state.contentLabel);
    console.log("buildAndPublish(): refTxId: "      + this.state.refTxId);
    console.log("buildAndPublish(): refTxLabel: "   + this.state.refTxLabel);
    console.log("buildAndPublish(): paycode: "      + this.state.paycode);

    console.warn("buildAndPublish(): alterInstaBuy: " + this.state.alterInstaBuy)
    console.warn("buildAndPublish(): newInstaPrice: " + this.state.newInstaPrice)
    console.warn("buildAndPublish(): newOwnerP2Pkh: " + this.state.newOwnerP2Pkh)
    console.warn("                     notTheOwner: " + this.state.notTheOwner)

    console.warn("buildAndPublish(): rabinPKH of potential buyer: " + this.state.potentialBuyerRabinPKH);
    console.log("buildAndPublish(): NEXT owner rabinPKH: "     + this.state.rabinPKH);
    console.log("                   (BUT, if specifying, or clearing a potential sale, we'll hardcode this in a sec)");
    console.log("buildAndPublish(): spawnUpdate: "  + this.state.spawnUpdate);
    console.log("buildAndPublish(): spawnSale: "    + this.state.spawnSale);
    console.warn("buildAndPublish(): authcode LENGTH: " + this.state.authcode.length/2)
    console.warn("buildAndPublish(): authcode padding LENGTH: " + this.state.authcodePadding.length/2)
    console.warn("buildAndPublish(): sellerRabinPubKey: " + this.state.sellerRabinPubKey)
    console.warn("buildAndPublish(): sellerRabinPubKey LENGTH: " + this.state.sellerRabinPubKey.length/2)

    //console.log("buildAndPublish(): rabinPrivKeyP: " + this.state.rabinPrivKeyP);
    //console.log("buildAndPublish(): rabinPrivKeyQ: " + this.state.rabinPrivKeyQ);

    const dtx = JSON.parse(this.props.dtx);
    console.log("buildAndPublish(): dtx = ", dtx);

    const outIndex = this.props.outputChosen;
    console.log("buildAndPublish(): outIndex = " + outIndex);

    const chosenMode = hexByteToAscii( dtx.outputStates[ outIndex ].mode );
    console.log("buildAndPublish(): building on output mode " + chosenMode);

    const qc = dtx.outputStates[ outIndex ].quarterlyCount
    console.log("buildAndPublish(): qc is " + qc)

    console.log("buildAndPublish(): props.txOnWhichToBuild = " + this.props.txOnWhichToBuild);

    //NOTE: this could be changed if we're specifying or clearing a potential buyer
    let paramPKH = this.state.rabinPKH;
    let theBlockHeight
    let rabinPkhOfPotentialBuyer

    // mode P can't be renewed, nor expire, so, the renewalStatus wouldn't apply
    let renew       = false
    let expired     = false
    let normalZone  = false
    let instaBuy    = false
    let newOwnerP2Pkh
    if ( qc < 5 ) {
      newOwnerP2Pkh = '0000000000000000000000000000000000000000'
    } else {
      newOwnerP2Pkh = this.state.newOwnerP2Pkh === '' ?
            '0000000000000000000000000000000000000000'
          :
            this.state.newOwnerP2Pkh
    }
    if ( chosenMode !== 'P' ) {   // P can't have a 'renewal' status

      theBlockHeight = dtx.outputStates[ outIndex ].potentialSaleMinBlock   //blockNumInt
      console.warn("ClaimBuilder buildAndPublish():  for NOW, using (prev) blockheight (potentialSaleMinBlock) of " + theBlockHeight + " (TBD)")

      const specifyOrClearPotentialSale = this.state.specifyPotentialSale || this.state.clearPotentialSale

      rabinPkhOfPotentialBuyer = (this.state.potentialBuyerRabinPKH === ''
                                  || this.state.clearPotentialSale ) ?
                '0000000000000000000000000000000000000000'
              :
                this.state.potentialBuyerRabinPKH
      console.warn("buildAndPublish(): rabin of potential buyer: " + rabinPkhOfPotentialBuyer)

      // are we TRANSFERING ownership?
      if ( this.state.authcode.length > 0 ) {
          console.warn("buildAndPublish(): paramPKH defaulted to value " + paramPKH)
          // PREVIOUSLY-specified potential buyer
          paramPKH = dtx.outputStates[ outIndex ].rabinOfPotentialBuyer
          console.warn("buildAndPublish(): setting param1,5,6/paramPKH to " + paramPKH)

          // If we had recently toggled-over to specifyPotentialSale,
          // none of these can have values
          this.setState({ content: '', contentLabel: '',
                          refTxId: '', refTxLabel: '',
                          spawnUpdate: false, spawnSale: false,
                          paycode: ''
                        })

          renew = false
          expired = false
          normalZone = false
          theBlockHeight++
      }
      else if ( this.state.notTheOwner ) {
          console.warn("buildAndPublish(): insta-BUYING this asset")

          // None of these can have values
          this.setState({ content: '', contentLabel: '',
                          refTxId: '', refTxLabel: '',
                          spawnUpdate: false, spawnSale: false,
                          paycode: ''
                        })

          const prevBlockHeight = dtx.outputStates[ outIndex ].blockNumInt
          const actualBlockHeight = this.props.blockHeight
          const bbm = parseInt(this.props.bbm)

          console.warn("b&p(): prevBlockHeight: " + prevBlockHeight)
          console.warn("b&p(): actualBlockHeight: " + actualBlockHeight)
          console.warn("b&p(): bbm: " + bbm)

          // if we're instaBuying, there is no minimum block advancement
          // We don't need to 'adjust' the renewal status.
          // But if we've got bbm set, that means we're looking to simply
          // adjust the blockHeight from previous
          if ( window.devTestMode ) {
            theBlockHeight = prevBlockHeight + bbm
          } else {
            theBlockHeight = actualBlockHeight
          }

          console.log("setting blockHeight to be " + theBlockHeight)
          const theStat = reviewRenewalStatus(dtx, theBlockHeight)
          console.warn("here's the resultant status: ", theStat)
          //alert("Look at that status. compare to the next, this.props.renewalStatus...")
          //console.warn("props status: ", this.props.renewalStatus)
          //alert("done for now.")


          //copied these from logic below (other scenario)
          //renew = renewalStatus.couldBeRenewed
          expired = theStat.expired //renewalStatus.expired
          //normalZone = renewalStatus.canPost && !renew && !expired
          //NOTE: probably not important: normalZone, and renew
          //      instaBuy is higher priority than those

          instaBuy = !expired
          if ( !instaBuy ) {
            alert("CODE/UI ERROR: if you're trying to instaBuy, and this is " +
                "expired, the code should have recognized this, and allowed " +
                "you to re-claim this asset instead (prioritized EXPIRATION over instaBuy).")
            throw new Error("44333: I think we're trying to instaBuy, but this is expired. The UI should recognize this, and prioritize EXPIRATION.")
          }

          console.warn("let's set the NEW ownerP2Pkh to all zeroes <----")
          newOwnerP2Pkh = '0000000000000000000000000000000000000000'
      }

      // User radio button option (specifyPotentialSale) allows us to either:
      //     - specifying, or clear, potential sales
      //     - perform a normal Continue post
      else if ( !specifyOrClearPotentialSale ) {
        const res = this.maybeAdjust(dtx, outIndex, chosenMode, parseInt(this.props.bbm), this.props.blockHeight, this.props.renewalStatus)
        theBlockHeight = res.blockHeight
        const renewalStatus = res.renewalStatus

        if ( !confirmStatusIsProper(renewalStatus, dtx) ) {
          alert("Please try again. We weren't ready yet.")

          // We want to build a tx, but the renewalStatus is for a DIFFERENT asset
          return
        }

        console.warn("buildAndPublish(): renewalStatus: ", renewalStatus)

        renew = renewalStatus.couldBeRenewed
        expired = renewalStatus.expired
        normalZone = renewalStatus.canPost && !renew && !expired

      } else {
        // If we had recently toggled-over to specifyPotentialSale,
        // none of these can have values
        this.setState({ content: '', contentLabel: '',
                        refTxId: '', refTxLabel: '',
                        spawnUpdate: false, spawnSale: false,
                        paycode: ''
                      })

        // hold constant the current rabinPKH
        // required when specifying or clearing a potential buyer
        this.setState({rabinPKH: dtx.outputStates[ outIndex ].ownerRabinPKH });

        paramPKH = this.state.rabinPKH

        console.warn("buildAndPublish(): NOT adjusting status (just adding SOMETHING) - since this is a potentialSale tx  <-------<<<<")
        if ( rabinPkhOfPotentialBuyer === '0000000000000000000000000000000000000000' ) {
          theBlockHeight = theBlockHeight + 24*144 + 1
          console.warn("buildAndPublish(): adding 24 days to blockHeight - since potentialSaleMinBlock")
        } else {
          theBlockHeight++
        }
        console.warn("ClaimBuilder buildAndPublish():  NOW, using blockheight of " + theBlockHeight)
      }
      console.warn("buildAndPublish():  renew: " + renew + "   expired: " + expired + "   normalZone: " + normalZone)
    } else {
      console.warn("ClaimBuilder  buildAndPublish(): claiming from 'P'")
      theBlockHeight = dtx.outputStates[ outIndex ].blockNumInt
      console.warn("ClaimBuilder buildAndPublish():  for NOW, using (prev) blockheight (blockNumInt) of " + theBlockHeight)

      rabinPkhOfPotentialBuyer = '0000000000000000000000000000000000000000'
      console.warn("buildAndPublish(): rabin of potential buyer: " + rabinPkhOfPotentialBuyer)
    }

    // If building on mode 'P' or 'K', there is no price, nor ownerP2PKH
    // from which to copy
    let newInstaPriceParam = 0
    let newOwnerP2PkhParam = '0000000000000000000000000000000000000000'
    // be ready to re-use the old instaBuy params
    if ( dtx.outputStates[outIndex].priceInSats ) {
      newInstaPriceParam = Buffer.from( dtx.outputStates[outIndex].priceInSats, "hex").readUIntLE(0,5)
      newOwnerP2PkhParam = dtx.outputStates[outIndex].ownerP2PKH
    }

    // if user wants to alter instaBuy params, do so
    if ( this.state.alterInstaBuy ) {
      console.warn("using NEW values for instaBuy settings")
      newInstaPriceParam = this.state.newInstaPrice
      newOwnerP2PkhParam = newOwnerP2Pkh
    }
    console.warn("Passing values for instaBuy settings: " + newInstaPriceParam
              +  " sats to " + newOwnerP2PkhParam)

    // we now pass content as a hex string
    const contentHexString = this.state.contentIsAFile ?
                                this.state.contentChosenToUpload
                              :
                                this.state.contentIsHex ?
                                    this.state.content
                                  :
                                    Buffer.from(this.state.content).toString('hex')
    //console.warn("pkey: ", this.state.bsvPrivKey)
    try {
      console.log("buildAndPublish(): calling buildOnClaimOrPublish().");

      const publishContent = this.state.content.length > 0 || this.state.contentLabel.length > 0
      const publishTxid    = this.state.refTxId.length > 0 || this.state.refTxLabel.length > 0

      // empower new option/param in components that call this
      const optionalAdditionalBalance = 0

      const txid = await buildOnClaimOrPublish(dtx.outputStates[outIndex], this.props.txOnWhichToBuild, outIndex,
                            theBlockHeight,
                            this.state.paycode,     // p0
                            paramPKH,               // p1
                            normalZone,             // p2  post within the 'normal' zone (before zone of renewal),
                                                    //     Should be based on block, and dev setting(s)
                            rabinPkhOfPotentialBuyer,
                            this.state.authcode, this.state.authcodePadding,
                            this.state.sellerRabinPubKey, // LE Hex

                            newInstaPriceParam, //this.state.newInstaPrice,// p3: price in sats
                            newOwnerP2PkhParam, //newOwnerP2Pkh, //this.state.newOwnerP2Pkh,//     instaBuy receiving P2PKH. If buying, set NEW value to zeroes
                            instaBuy,

                            renew,                  // p4: if p2 was false, this is if we'd like to renew(T/F). sure.
                            paramPKH,               // p5: if 'p', and p2 was false, and we're re-claiming, this is the Rabin PKH to use
                                                    //     if 'p', and not renewing (p4 was false),
                            paramPKH,               // p6: keep the same PKH - but an opportunity to change it <---
                            publishContent,         // p7: publish content?
                            contentHexString,       // p8: content
                            this.state.contentLabel,// p9: content label
                            publishTxid,            // p10: publish a txid?
                            this.state.refTxId,     // p11: txId
                            this.state.refTxLabel,  // p12: label for tx
                            this.state.spawnUpdate, // p13: spawn update
                            this.state.spawnSale,   // p14: spawn sale/negotiations

                            optionalAdditionalBalance,

                            this.state.rabinPrivKeyP,
                            this.state.rabinPrivKeyQ,
                            this.state.bsvPrivKey);
      // now pass back THIS tx - to make it the txToConsider
      console.log("ClaimBuilder::buildAndPublish(): we've built a tx ", txid);

      this.props.handleBuildEvent(txid);
    }  catch (error) {
      console.log('Failed in ClaimBuilder::buildAndPublish()');
      //console.log('Failed on network: ' + NETWORK)
      console.error("stack: " + error.stack);
      printError(error);    // needed?
    }
  } // ClaimBuilder buildAndPublish()

  // Two modes (K, and p) require a signature (using a modal)
  // One mode (P) doesn't - so it skips the modal, and goes straight to buildAndPublish()
  async handlePublishSubmit() {
    console.log("ClaimBuilder::handlePublishSubmit():  MAY trigger opening of key-soliciting modal");

    if ( this.state.applyBuilderFeeReduction ) {
      console.warn("handlePublishSubmit(): Proceeding without keys - to submitFeeReductionTx()")
      return await this.submitFeeReductionTx();
    }
    const dtx = JSON.parse(this.props.dtx);
    console.log("handlePublishSubmit(): dtx = ", dtx);

    const outIndex = this.props.outputChosen;
    console.log("handlePublishSubmit(): outIndex = " + outIndex);

    const chosenMode = hexByteToAscii( dtx.outputStates[ outIndex ].mode );
    console.log("handlePublishSubmit(): chosen mode is " + chosenMode);

    if ( chosenMode === 'P' || this.state.notTheOwner ) {
      // If claiming, no keys are necessary (so far)

      // OR, if insta-buying, no keys are necessary

      console.warn("ClaimBuilder: claiming. Proceeding without keys.")
      return await this.buildAndPublish();
    }

    // renewalStatus.expired should be affected/adjusted by devMode/bbm settings
    const renewalStatus = this.maybeAdjust(dtx,
                            outIndex,
                            chosenMode,
                            parseInt(this.props.bbm),
                            this.props.blockHeight,
                            this.props.renewalStatus).renewalStatus

    // even if K or p, if it's expired, no signature necessary
    if ( renewalStatus.expired || chosenMode === 'P' ) {  // no sig necessary
      console.log("ClaimBuilder handlePublishSubmit(): renewalStatus: ", renewalStatus)
      await this.buildAndPublish();
    } else if ( this.state.authcode.length > 0 ) {
      console.warn("ClaimBuilder handlePublishSubmit(): no sigs needed - that's what the authcode is for")
      await this.buildAndPublish();
    } else if ( chosenMode === 'K' || chosenMode === 'p' ) { // we'll need a signature
      // Opens secondary modal to solicit keys for signing
      // Results of that (a click of 'Build' or 'CANCEL') emerge at handleRabinKeyModalSubmit()
      //   or a cancel leads to handleRabinKeyModalCancel()
      this.setState({openSecondaryModal: true});
      // May trigger buildAndPublish() after signing
    }
  }

  /**
   * Maybe adjust blockHeight and renewalStatus, based on 'blocksBeyondMinimum' setting.
   * In other words, IF we want to build a tx while advancing the blockNum (minBlock) as
   * little a possible, use the 'bbm' setting, and calculate what the renewalStatus would
   * be at that height.
   */
  //NOTE: dtx should be LEADING continue decoded tx
  maybeAdjust(dtx, outIndex, chosenMode, propsBbm, propsHeight, propsStatus) {
    let renewalStatus
    let theBlockHeight

         // special mode has us using 'bbm' setting
    if ( window.devTestMode ) {
      console.warn("maybeAdjust(): using minimal block height (driven by previous tx's 'minBlock', instead of 'current' block height)")

      //  if in devTestMode, we should calculate a DIFFERENT
      //  renewal status - using a BOGUS blockHeight

      // Calculate a renewalStatus based on this bogus/test blockHeight
      //NOTE: dtx IS leadingContinueDecodedTx

      const adjustment = adjustRenewalStatus(dtx,
                                          dtx.outputStates[ outIndex ].blockNumInt,
                                          chosenMode,
                                          propsBbm)
      renewalStatus = adjustment.status
      theBlockHeight = adjustment.height
    } else {
      theBlockHeight = propsHeight
      renewalStatus = propsStatus
      console.warn("maybeAdjust(): using latest true blockHeight: " + theBlockHeight)
    }

    if ( !renewalStatus || renewalStatus === null ) {
      throw new Error("bad renewal status: " + renewalStatus)
    }

    return {
      blockHeight: theBlockHeight,
      renewalStatus: renewalStatus
    }
  } //maybeAdjust()

  reUseRabin(event) {
    event.preventDefault();
    const dtx = JSON.parse( this.props.dtx );
    const outputChosen = this.props.outputChosen;
    const prevRabinPKH = dtx.outputStates[outputChosen].ownerRabinPKH;

    console.log("ClaimBuilder reUseRabin():  will re-use PKH " + prevRabinPKH)
    this.setState({rabinPKH: prevRabinPKH});
  }

  closeContentFileReaderModal() {
    console.log('ClaimBuilder: Closing Content FileReader modal...')

    this.setState({showContentFileReaderModal: false})
  }

  showContentFileReaderModal(event) {
    event.preventDefault();
    this.setState({showContentFileReaderModal: true})
  }

  // called by fileChooserReader when user clicks OK/Use
  handleChosenFile(chosenFileName, content) {
    console.warn("ClaimBuilder: handleChosenFile() - content file has been specified: " + chosenFileName + ", with content of length " + content.length)
    this.setState({fileChosenToUpload: chosenFileName, contentChosenToUpload: content})
  }

  openFundsGetter(event) {
    console.log("openFundsGetter()")
    this.setState({openFundingModal: true})

    event.preventDefault()
  }

  closeFundsGetter() {
    console.log("closeFundsGetter()")
    this.setState({openFundingModal: false})
  }

  render() {   // ClaimBuilder
    const dtx = JSON.parse( this.props.dtx );
    const outputChosen = this.props.outputChosen;
    const ownerRabinPKH = dtx.outputStates[ outputChosen ].ownerRabinPKH;
    const qc = dtx.outputStates[ outputChosen ].quarterlyCount
    const instaPriceHex = dtx.outputStates[ outputChosen ].priceInSats
    const ownerP2PKH    = dtx.outputStates[ outputChosen ].ownerP2PKH
    //console.log("output chosen is " + outputChosen);
    const chosenMode = hexByteToAscii( dtx.outputStates[ outputChosen ].mode );
    const buttonLabel = 'BUILD contract on output of mode ' + chosenMode;
    const limb = hexStringToAscii( dtx.outputStates[ outputChosen ].namePath);
    //console.log("chosen mode is " + chosenMode);
    const isP = chosenMode === 'P';
    const isLittleP = chosenMode === 'p';
    const isK = chosenMode === 'K';
    let expired = false

    const shizzleNav = getShizzleNav()
    let renewalStatus = this.props.renewalStatus

    console.warn("ClaimBuilder::render(): which renewal status? from props: ", this.props.renewalStatus)

    if ( !this.state.recentlyExpedited ) {
      console.log("CLAIMBUILDER: NOT recentlyExpedited. Doing so, now.")
      const par = this
      execAsync( async function() {
        //NOTE: by setting PRIOR, MAYBE we avoid an infinite loop
        par.setState({recentlyExpedited: true})

        // get a fresh renewal status. It tries to use the LATEST Continue
        await shizzleNav.expeditePeriodicCheck()
      })

    }

    if ( window.devTestMode ) {

      console.warn("ClaimBuilder::render(): using minimal block height (driven by previous tx's 'minBlock', instead of 'current' block height)")

      renewalStatus = adjustRenewalStatus(dtx,
                                          dtx.outputStates[ outputChosen ].blockNumInt,
                                          chosenMode,
                                          parseInt(this.props.bbm),
                                          true).status

      console.warn("ClaimBuilder render(): modified renewalStatus: ", renewalStatus)
    }
    let dispositionDescription = null
    if ( !renewalStatus || renewalStatus === null ) {

      if ( chosenMode === 'P' ) {
        console.warn("ClaimBuilder: We have no renewalStatus, BUT, this is an unclaimed asset, so, WON'T expedite periodic check.")
      } else {
        console.warn("ClaimBuilder(): We have no renewalStatus, so, we can't yet proceed.")

        return <>
                <Modal.Content image scrolling>
                  <Container>
                  <Modal.Description>
                    We don't have the latest info on this asset. Please try again.
                  </Modal.Description>
                  </Container>
                </Modal.Content>

                <Modal.Actions>
                  <Button onClick={ (event) => onCancel(event, this, 'claimBuilder') } content='CANCEL' negative/>
                </Modal.Actions>
              </>
      }

    } else {
      if ( renewalStatus.couldBeRenewed ) {
        dispositionDescription = <> Building a transaction now would <span style={{color: 'red'}}>RENEW</span> this asset.</>
      } else if ( renewalStatus.expired ) {
        expired = true
        dispositionDescription = <span style={{color: 'red'}}> This asset is EXPIRED. Building a transaction now would re-claim it.</span>
      }
    }


    const blankP2PKH = ownerP2PKH === '0000000000000000000000000000000000000000'
                    && this.state.newOwnerP2Pkh.length === 0 //FIXME: !== 40 ?
    const blankPrice = isP || isK || instaPriceHex === '0000000000'

    console.warn("InstaPrice is " + instaPriceHex)
    const decimalPrice = blankPrice ? 0 : Buffer.from(instaPriceHex, 'hex').readUIntLE(0,5)
    console.warn("decimal instaPrice is " + decimalPrice)
    const instaPrice = blankPrice ?
                            <> Not for sale </>
                          :
                            <>{decimalPrice} satoshis </>
    const priceDisabled = blankP2PKH;
    const instaP2PKH = blankP2PKH ?
            <> NOT SET </>
          :
            <> {ownerP2PKH} </>
    // 9dee81b2c0a1ecf85de3b8ea1c389e2a9fc2e215
    const newP2PkhLabel = 'NEW P2PKH: '

    const smallestPriceHex = dtx.outputStates[ outputChosen ].smallestAmount
    const smallestPriceDecimal = Buffer.from( smallestPriceHex, "hex").readUInt16LE(0)

    const newP2PkhPlaceholder = 'Enter a P2PKH to receive funds from sale'
    let alterInstaBuyOption = this.state.alterInstaBuy ?
            <div>
              You'd like to <b>alter</b> your settings.
              <p></p>
              Your (current/old) Receiving P2PKH: {instaP2PKH} <br></br>
              Enter it again, or it will be cleared.<br></br>
              <Input field='newOwnerP2Pkh' label={newP2PkhLabel} placeholder={newP2PkhPlaceholder}
                                  value={this.state.newOwnerP2Pkh} style={{width: "530px"}}
                                  onChange={ (event, data) => handleFieldChange(event, data, this) }/>
              <p></p>
              Current/Old Price: {instaPrice} &nbsp; &nbsp; NEW price: &nbsp;
              <Input disabled={priceDisabled} placeholder='new price in satoshis'
                                field='newInstaPrice'
                                onChange={ (event, data) => handleFieldChange(event, data, this) }/>
                                &nbsp;({parseInt(this.state.newInstaPrice)} satoshis)
              <p>(If you specify a non-zero price, you must specify a non-blank P2PKH)<br></br>
              (Any non-zero price must be at least {smallestPriceDecimal} satoshis)</p>
            </div>
        :
            <div>
              You'd like to keep your InstaBuy settings <b>unchanged</b>.
              <p></p>
              Your Receiving P2PKH: {instaP2PKH} <p></p>
              Current Price: {instaPrice} <p></p>
            </div>

    let instaBuyOption = null
    if ( qc >= 5 && !expired ) {
      instaBuyOption =
        <>
          <Divider> </Divider>
            <div>
               Since you've owned this asset for a little while (have made
               five mainline posts), you can set (or modify) your <b>InstaBuy</b>
               &nbsp;preferences. InstaBuy is a feature for setting a price (and a
               receiving wallet address) for your domain/asset. If you set a
               price+address, anyone in the world can INSTANTLY purchase
               your asset/domain - provided they use the BitShizzle protocol
               to send the specified amount of bitcoin to your specified
               address. NEVER enable InstaBuy unless you are prepared to
               give up ownership in your asset (sell it - potentially very
               quickly). There is no way to undo an InstaBuy sale.
               <p></p>
               Leave InstaBuy alone &nbsp;<Radio toggle label='Set/Modify InstaBuy' checked={this.state.alterInstaBuy}
                        field='alterInstaBuy' onChange={ (event, data) => handleFieldChange(event, data, this) }/>
               {alterInstaBuyOption}
            </div>
          <Divider></Divider>
        </>
    } else if ( !expired ) {
      instaBuyOption =
          <div>
            (There's no 'instaBuy' option, since you haven't yet made five
            mainline posts.)
          </div>
    } else { // EXPIRED
      instaBuyOption =
          <div style={{color: 'red'}}>
            <Divider />
            Posting now will re-claim (swipe) this EXPIRED asset from the previous
            owner - for a fee, and will require a temporary bounty/balance/deposit.
            <Divider />
          </div>
    }

    let rabinPKHLabel;
    let rabinPKHPlaceholder;
    let blurb;
    let action;
    let allowedToBeSwiped = false     // <--- why was this true?
    let allowedToPresentPotentialBuyer = false
    let showFundsGetterButton = false
    if ( isP ) {
      allowedToBeSwiped = false  // unclaimed
      blurb = <div> Anyone can CLAIM and publish on a 'P' publishing output (#0) for a fee.
                    Doing so actually claims the entire namePath/Limb. When claiming,
                    one also declares the Rabin PKH that will be used to authenticate future
                    publications on the limb. From that point forward, only that person
                    can use his secret Rabin Key to authenticate future publications
                    on that namePath/limb.
              </div>;
      rabinPKHLabel = 'Rabin PKH to Declare:';
      rabinPKHPlaceholder = 'Enter your Rabin PKH for claiming this asset.';
      action = <div> Publish and/or Claim on a namePath/limb '{limb}' </div>
      showFundsGetterButton = true
    } else if ( renewalStatus.expired ) {
      allowedToBeSwiped = true  // re-claiming
      blurb = <div> You can RECLAIM publishing output (#0) for a fee.
                    Doing so actually claims the entire namePath/Limb. When re-claiming,
                    one also declares the Rabin PKH that will be used to authenticate future
                    publications on the limb. From that point forward, only that person
                    can use his secret Rabin Key to authenticate future publications
                    on that namePath/limb.
              </div>;
      rabinPKHLabel = 'Rabin PKH to Declare:';
      rabinPKHPlaceholder = 'Enter your Rabin PKH for reclaiming this asset.';
      action = <div> RECLAIM namePath/limb '{limb}' </div>
      showFundsGetterButton = true
    } else if ( isK ) { // 'K'
      allowedToBeSwiped = true
      blurb = <div> While building on an 'X' output, a builder can
                    pre-claim the ensuing pathName/limb (take ownership), producing a 'K' output,
                    while also declaring the Rabin PKH used to authenticate future
                    publication on the limb. From that point forward, only that builder
                    can use his secret Rabin Key to authenticate future publication
                    on that namePath/limb.
              </div>;
      rabinPKHLabel = 'New Rabin PKH:';
      rabinPKHPlaceholder = 'New Rabin PKH to use.';
      action = <div>Publish on pre-claimed namePath/limb '{limb}' </div>
    } else { // 'p'
      allowedToBeSwiped = true
      blurb = <div> Publishing on a 'p' output is almost identical to publishing on a 'P'.
                    What differs is that there is ALREADY a Rabin PKH attributed, and so
                    publishing requires authentication. It's already claimed. Only the
                    builder/owner can use his secret Rabin Key to authenticate publication
                    on the namePath/limb.
              </div>;
      rabinPKHLabel = 'Next Rabin PKH:';
      rabinPKHPlaceholder = 'Your next Rabin PKH to use';
      action = <div>Continue publishing on a namePath/limb '{limb}' </div>
      allowedToPresentPotentialBuyer = true
    }

    let reclaimText = null
    if ( allowedToBeSwiped && renewalStatus.expired ) {
      reclaimText = <span style={{color: 'red'}}>
                        <b>NOTE:</b> You're about to re-claim ('swipe') this asset. ANYONE could, because it's EXPIRED. Good luck.
                    </span>
    }

    const instaPriceNaN = parseInt(this.state.newInstaPrice) != parseInt(this.state.newInstaPrice)
    const instaBuyMisSpecified = this.state.alterInstaBuy
            &&
              (
                (this.state.newOwnerP2Pkh.length !== 40
                  && this.state.newOwnerP2Pkh.length !== 0 )
              ||
                ( instaPriceNaN )
              ||
                ( parseInt(this.state.newInstaPrice) < smallestPriceDecimal )
              )

    const newOwnerP2PkhSpecified = this.state.newOwnerP2Pkh !== '0000000000000000000000000000000000000000'
                        && this.state.newOwnerP2Pkh.length === 40
    const instaBuyNotFullySpecified = this.state.alterInstaBuy &&
                    this.state.newInstaPrice > 0 && !newOwnerP2PkhSpecified

    // Special case: if owner is specifying a potential buyer,
    // don't require the NEXT rabin to be filled-in. We'll fill it in
    // when building tx.
    const claimBuilderBuildBtnDisabled = (this.state.applyBuilderFeeReduction
                                          && (this.state.bfrSignature.length < 1 ||
                                              this.state.builderPubKey.length < 1)
                                         )
                                      ||
                                        (!this.state.applyBuilderFeeReduction
                                        && this.state.rabinPKH.length !== 40
                                        && !this.state.specifyPotentialSale
                                        && !this.state.clearPotentialSale
                                        && !this.state.transferOwnership)
                                      ||
                                        instaBuyNotFullySpecified
                                      ||
                                        instaBuyMisSpecified
                                      ||
                                        (showFundsGetterButton && !this.state.bsvKeyIsSufficient);

    const spawnUpChecked = this.state.spawnUpdate;

    // This is the rabin of a potential buyer specified on the CONTINUE line (main line)
    const rabinPkhOfPotentialBuyer = dtx.outputStates[ outputChosen ].rabinOfPotentialBuyer
    const rabinOfPotentialBuyerSpecified = rabinPkhOfPotentialBuyer
                                        && rabinPkhOfPotentialBuyer !== null
                                        && rabinPkhOfPotentialBuyer.length === 40
                                        && rabinPkhOfPotentialBuyer !== '0000000000000000000000000000000000000000'
    console.warn("ClaimBuilder::render(): rabinPkhOfPotentialBuyer: " + rabinPkhOfPotentialBuyer)


    // Only display reUseButton if there IS a rabinPKH. If mode is 'P', there won't be one.
    const disableReUseButton = this.state.rabinPKH === ownerRabinPKH;
    const reUseButton = ownerRabinPKH && ownerRabinPKH !== null && !renewalStatus.expired
            ?
              <Button disabled={disableReUseButton} content='Re-Use Current PKH' positive onClick={this.reUseRabin}/>
            : null;
    const optionalSalesSpawn = renewalStatus && renewalStatus !== null && renewalStatus.couldBeRenewed ? <>
                                  <div></div>
                                  <Radio  toggle label='Spawn a sales-negotiation contract' field='spawnSale'
                                          onChange={ (event, data) => handleFieldChange(event, data, this) }/>
                              </> : null


    const contentPlaceHolder = document.getElementById('contentPlace2')
    const fileReaderModal = this.state.showContentFileReaderModal ?
                                <FileChooserReader  closeChooserModal={this.closeContentFileReaderModal}
                                                    pplaceholder={ contentPlaceHolder }
                                                    callback={this.handleChosenFile}/>
                              :
                                null
    const whichContent = this.state.contentIsAFile ?
                              <>
                                <Button disabled={false} content='Choose File to Upload' positive onClick={this.showContentFileReaderModal}/>
                                <br></br>
                                Chosen File: {this.state.fileChosenToUpload}
                                <br></br>
                                File Length: {this.state.contentChosenToUpload.length / 2}
                                <br></br>
                                {fileReaderModal}
                              </>
                            :
                              <>
                                <TextArea placeholder='Type/paste content to publish'
                                          field='content'
                                          onChange={ (event, data) => handleFieldChange(event, data, this) }/>
                                <br></br>
                                Content above is ascii/text <Radio  toggle label='Content above is HEX' field='contentIsHex'
                                            onChange={ (event, data) => handleFieldChange(event, data, this) }/>
                              </>
    const contentSpec = <>
                          Type/paste content, or <Radio toggle label='Upload a file'
                                                        field='contentIsAFile'
                                                        onChange={ (event, data) => handleFieldChange(event, data, this) }/>
                          <br></br>
                          {whichContent}
                        </>

    const normalPost = <>
                        <br></br>
                        <b>Normal Post</b><br></br>
                        <Form onSubmit={this.handlePublishSubmit}>
                          {contentSpec}
                          <br></br>
                          <br></br>
                          You may LABEL your content ( alphanumeric | slash | dash ) - maximum of 64 characters (currently: {this.state.contentLabel.length} chars)<br></br>
                          (Multiple consecutive slashes will be interpreted as a single slash)
                          <Input fluid label='Content label/name:' placeholder='Enter a name for this content'
                                        value={this.state.contentLabel}
                                        field='contentLabel' onChange={ (event, data) => handleFieldChange(event, data, this) }/>
                          <div></div>
                          <Input fluid label='Reference TxId:' placeholder="Enter a Tx Id (hexadecimal) you'd like to reference"
                                        value={this.state.refTxId}
                                        field='refTxId' onChange={ (event, data) => handleFieldChange(event, data, this) }/>
                          <div></div>
                          <Input fluid label='Reference Tx label/name:' placeholder="Enter a name for this Tx you're referencing"
                                        field='refTxLabel' onChange={ (event, data) => handleFieldChange(event, data, this) }/>
                          <div></div>
                          <div>Sample paycode (don't use this one): 50b4d8ca6d9c3e475286d9cd695240fba62a2c1f6b2ae381e394cd8f0a7a8a08beb4d8ca6d9c3e475286d9cd695240fba62a2c1f6b2ae381e394cd8f0a7a8a0811223344556677889900112233445566</div>
                          <Input fluid label='PayCode:' placeholder='Enter a paycode with which to receive/connect privately'
                                        field='paycode' onChange={ (event, data) => handleFieldChange(event, data, this) }/>
                          <br></br>



                          <Radio toggle label='Spawn an update' checked={spawnUpChecked}
                                        field='spawnUpdate' onChange={ (event, data) => handleFieldChange(event, data, this) }/>
                          {optionalSalesSpawn}
                        </Form>
                        <pre id="contentPlace2" style={{display:'none'}}></pre>
                      </>

    const conditionsOkForSpecifyingPotentialSale = allowedToPresentPotentialBuyer
                                                && renewalStatus && renewalStatus !== null && !renewalStatus.expired

    // There's only a valid auction if it MATCHES the leadingContinue's limbName, ownerCount, and quarterlyCount
    // Is this overboard checking?
    const thereIsAnAuction = shizzleNav.state.leadingAuctionDTx && shizzleNav.state.leadingAuctionDTx !== null
                              && shizzleNav.state.leadingAuctionDTx.limbName === dtx.limbName
                              && shizzleNav.state.leadingAuctionDTx.outputStates[ 0 ].ownerCount === dtx.outputStates[ 0 ].ownerCount
                              && shizzleNav.state.leadingAuctionDTx.outputStates[ 0 ].quarterlyCount === dtx.outputStates[ 0 ].quarterlyCount
    const auctionStateOutput = thereIsAnAuction ? shizzleNav.state.leadingAuctionDTx.outputStates[0] : null
    const auctionBestBidNum = thereIsAnAuction ? auctionStateOutput.bestBidNum : null

    // if auction was executed, bestBidNum is no longer valid
    const auctionSubMode = thereIsAnAuction ? auctionStateOutput.subMode : null
    const auctionWasExecuted = auctionSubMode === '65' || auctionSubMode === '45'
    const auctionBestBidEntry = thereIsAnAuction ? auctionStateOutput.bidEntries[ auctionBestBidNum ] : null
    const auctionBidsExist = thereIsAnAuction && auctionStateOutput.numBids > 0
    const auctionBestBidSats = auctionBidsExist ?
                                  auctionWasExecuted ? null : auctionBestBidEntry.price
                              :
                                  null
    const auctionBestBidRabin = auctionBidsExist ?
                                  auctionWasExecuted ? null : auctionBestBidEntry.rabinPKH
                              :
                                  null

    console.log("ClaimBuilder render(): leading auctionState: ", shizzleNav.state.leadingAuctionDTx)
    const specBuyerBlurb = shizzleNav.state.auctionGranted ?
                  <>
                  <span style={{color: 'red'}}> In the auction, the owner has already been GRANTED permission (from the TOP BIDDER) to EXECUTE the sale.
                  If you are the owner, you SHOULD HAVE already specified the potential buyer Rabin PKH, HERE, locking-in your choice for a period
                  of time (max 24 days).</span><br></br>
                  Once you do that, you may decide to EXECUTE the sale in the auction (claiming his funds), you'll simultaneously
                  generate an AUTHCODE that he can use HERE to assume ownership of this asset. NOTE: executing the sale in the auction, without specifying
                  the buyer here, would constitute theft in any country. Your actions on Bitcoin are traceable. Please specify the top bidder if you
                  intend to EXECUTE the sale in the auction (claim the funds of the top bidder).
                  </>
              :
                  shizzleNav.state.auctionWaiting ?
                          <>
                          <span style={{color: 'red'}}> In the auction, the owner is WAITING for permission (from the TOP BIDDER) to EXECUTE the sale.
                          If you are the owner, and you want the top bidder to GRANT you permission to EXECUTE (sell this
                          asset, and claim his bid/funds) in the auction, you'll need to make a good-faith gesture. You'll need to specify his/her
                          Rabin PKH, HERE, as a potential buyer - locking-in your choice for a period of time (24 days).</span><br></br>
                          IF the top BIDDER then GRANTS permission, and you then EXECUTE the sale in the auction (claiming his funds), you'll simultaneously
                          generate an AUTHCODE that he can use HERE to assume ownership of this asset. If the bidder instead BALKS, he'll be penalized
                          - unable to withdraw/refund his bid for a period of time.
                          </>
                      :
                          'notGrantedNorWaiting'
    const specifyOrNormal = this.state.specifyPotentialSale ?
                    <div> <br></br><b>Lock-in Potential Buyer</b> <br></br>
                          {specBuyerBlurb}
                          <br></br>
                          <br></br>
                            Auction best bid: entry #{auctionBestBidNum}, for {auctionBestBidSats} satoshis<br></br>
                            Best bid Rabin PKH: {auctionBestBidRabin} <br></br>
                          <br></br>
                          <Input field='potentialBuyerRabinPKH' label='Rabin PKH of Potential BUYER' placeholder='Enter PKH of top bidder in auction'
                                  value={this.state.potentialBuyerRabinPKH} style={{width: "575px"}}
                                  onChange={ (event, data) => handleFieldChange(event, data, this) }/>
                    </div>
                :
                    <>
                      {normalPost}
                    </>
    const clearOrNormal = this.state.clearPotentialSale ?
                    <>
                      <br></br><b>Clear the Potential Buyer</b><br></br>
                      By CLEARing the Potential BUYER - after waiting a period of time, in vain,
                      for the previous top-bidder to GRANT you permission to EXECUTE - you make
                      it clear that you'd like for the auction to continue anew. It clears the
                      way for another top-bidding potential buyer to be locked-in. It's a
                      necessary gesture to encourage participants to make new BIDs/offers on
                      this asset.
                    </>
                :
                    <>
                      {normalPost}
                    </>

    const notSpecified = dtx.outputStates[ outputChosen ].rabinOfPotentialBuyer === '0000000000000000000000000000000000000000'
    const waitingOrGranted = shizzleNav.state.auctionWaiting || shizzleNav.state.auctionGranted
    const waitingOrGrantedButNotSpecified = shizzleNav.state.auctionWaiting || (shizzleNav.state.auctionGranted && notSpecified)
    const waitingAndConditionsOkForSpecifyOrClear = waitingOrGrantedButNotSpecified && conditionsOkForSpecifyingPotentialSale
    const optionalSpecifyOrClear =    waitingOrGranted ?
                                              conditionsOkForSpecifyingPotentialSale ?
                                                    !rabinOfPotentialBuyerSpecified ?
                                                            shizzleNav.state.auctionWaiting ?
                                                            <>
                                                            <div>
                                                                <b style={{color: 'red'}}>Since the auction is waiting for permission for owner to execute,</b><br></br>
                                                                the owner here has two options:<br></br>
                                                                Normal Post &nbsp;<Radio toggle label='Lock-in Potential Buyer' checked={this.state.specifyPotentialSale}
                                                                    field='specifyPotentialSale' onChange={ (event, data) => handleFieldChange(event, data, this) }/>
                                                            </div>
                                                            {specifyOrNormal}
                                                            </>
                                                            :
                                                            <>
                                                            <div>
                                                                <b style={{color: 'red'}}>Since the auction has the owner GRANTED for permission to execute,</b><br></br>
                                                                the owner here has two options:<br></br>
                                                                Normal Post &nbsp;<Radio toggle label='Lock-in Potential Buyer' checked={this.state.specifyPotentialSale}
                                                                    field='specifyPotentialSale' onChange={ (event, data) => handleFieldChange(event, data, this) }/>
                                                            </div>
                                                            {specifyOrNormal}
                                                            </>
                                                        :
                                                            <>
                                                            <div>
                                                                  <b style={{color: 'red'}}>Since the owner is still WAITING for permission to execute (in the Auction),
                                                                  AND he's already made a good-faith gesture, by specifying the potential buyer,</b><br></br>
                                                                  the owner here has two Options
                                                                  (OTHER THAN to continue waiting):<br></br>
                                                                  Normal Post &nbsp;<Radio toggle label='Clear the Potential Buyer' checked={this.state.clearPotentialSale}
                                                                      field='clearPotentialSale' onChange={ (event, data) => handleFieldChange(event, data, this) }/>
                                                            </div>
                                                            {clearOrNormal}
                                                            </>
                                                :
                                                  <div> In the auction, the owner is WAITING for permission to execute,
                                                        but IT SEEMS as though the time is NOT RIGHT (too much time elapsed?)
                                                  </div>
                                            :
                                              <div> In the Auction, the owner is NOT WAITING for permission to execute </div>

    const optionalPotentialBuyerSpecified = rabinOfPotentialBuyerSpecified ?
                                                        <>
                                                            If you're the top bidder in the auction, note that the current
                                                            owner has COMMITTED to transfering ownership IF you GRANT him
                                                            permission to EXECUTE (in the auction). If he later EXECUTES
                                                            (in the auction), you can then use the resulting AUTHCODE to
                                                            CLAIM ownership, here.
                                                        </>
                                                    :
                                                        <>
                                                            Note that the current owner has NOT (yet) committed to transfering
                                                            ownership of this asset (hasn't yet specified a Potential Buyer on
                                                            this Main Line).
                                                            <br></br>
                                                            IOW: IF there's an auction, it's still ongoing.
                                                        </>

    const optionalMentionIfAuctionGranted = shizzleNav.state.auctionGranted ?
                                                      <div>
                                                      <br></br>
                                                      Auction owner was GRANTED permission to execute
                                                      </div>
                                                    :
                                                      shizzleNav.state.auctionWaiting ?
                                                          <div>
                                                          <br></br>
                                                          Auction owner NOT YET GRANTED permission to execute
                                                          </div>
                                                        :
                                                          <div>
                                                          <br></br>
                                                          Auction owner hasn't yet requested permission to Execute
                                                          </div>

    const transferOrNormal = this.state.transferOwnership ?
                    <> <br></br>
                      <b>Transfer Ownership</b><br></br>
                        <Form onSubmit={this.handlePublishSubmit}>
                          <div> The top bidder can now claim ownership of this asset!
                                To do this he needs to enter three parameters generated in the AUCTION line.
                                Once he does this, control will pass to the Rabin keys the current owner has
                                recently specified (locked-in for a period of time).</div>
                                <b>Rabin of owner-to-be:</b> {dtx.outputStates[ outputChosen ].rabinOfPotentialBuyer}
                          <div> <span style={{color: 'red'}}>This MUST be done soon</span>,
                                or else the lock-in period will expire, and the current owner can then invalidate/ignore
                                the results of the auction.</div>
                          <div> <br></br>
                          <div> The following parameters have been published on the AUCTION line:<br></br>
                              <span style={{color: 'blue'}}>Authcode:</span> {auctionStateOutput.authCode}
                              <br></br>
                              <span style={{color: 'blue'}}>Padding (can be blank):</span> {auctionStateOutput.authCodePadding}
                              <br></br>
                              <span style={{color: 'blue'}}>Seller Rabin PubKey:</span> {auctionStateOutput.mainLineRabPubKey}
                          </div> <br></br>
                          <Input fluid label='Authcode:' placeholder='Enter the authcode generated by the SELLER/previousOwner'
                                        value={this.state.authcode}
                                        field='authcode' onChange={ (event, data) => handleFieldChange(event, data, this) }/>

                          </div><div>
                          <Input fluid label='Authcode Padding:' placeholder="Enter the authcode padding generated by the SELLER/previousOwner"
                                        value={this.state.authcodePadding}
                                        field='authcodePadding' onChange={ (event, data) => handleFieldChange(event, data, this) }/>
                          </div><div>
                          <Input fluid label='Seller Rabin PubKey:' placeholder="Enter the SELLER's Rabin PubKey (LE Hex)"
                                        value={this.state.sellerRabinPubKey}
                                        field='sellerRabinPubKey' onChange={ (event, data) => handleFieldChange(event, data, this) }/>
                          </div>
                        </Form>
                    </>
                :
                    <>
                      {normalPost}
                    </>

    const optionalTransferOwnership =       shizzleNav.state.auctionExecuted ?
                <>
                  <div> Auction has been EXECUTED </div>
                  <div>
                    <b style={{color: 'red'}}>Since the auction has been EXECUTED on the auction line,</b><br></br>
                    the owner here has two options:<br></br>
                    Normal Post &nbsp;<Radio toggle label='Transfer Ownership' checked={this.state.transferOwnership}
                        field='transferOwnership' onChange={ (event, data) => handleFieldChange(event, data, this) }/>
                  </div>
                  {transferOrNormal}
                </>
            :
                <div> Auction has NOT yet been EXECUTED </div>

    // owner's request to execute auction was denied?
    const permissionDenied = (typeof rabinOfPotentialBuyerSpecified !== 'undefined') &&
                        rabinOfPotentialBuyerSpecified && !(shizzleNav.state.auctionWaiting || shizzleNav.state.auctionGranted || shizzleNav.state.auctionExecuted)
    console.warn("ClaimBuilder render(): permissionDenied: " + permissionDenied)

    const optionIfDenied = <>
                            <div>
                              <b style={{color: 'red'}}>Since the Top Bidder has DENIED permission for the owner to execute,</b><br></br>
                              the owner here has two options:<br></br>
                              Normal Post &nbsp;<Radio toggle label='Clear the Potential Buyer' checked={this.state.clearPotentialSale}
                                  field='clearPotentialSale' onChange={ (event, data) => handleFieldChange(event, data, this) }/>
                            </div>
                          </>
    const auctionStateSummary = this.state.thereIsAnAuction ?
                            <>
                                !Specified  !permissionDenied  !executed <br></br>
                            </>
                          :
                            <>
                            </>
    const alternateMainFeatures = waitingAndConditionsOkForSpecifyOrClear ?
                <>
                    waitingAndConditionsOkForSpecifyOrClear<br></br>
                    {optionalSpecifyOrClear}
                </>
            :
                (shizzleNav.state.auctionExecuted ?
                    <>
                        !waitingForPemission  auctionExecuted<br></br>
                        {optionalTransferOwnership}
                    </>
                :
                    permissionDenied ?
                        <>
                          !waitingForPemission  !auctionExecuted  permissionDenied<br></br>
                          {optionIfDenied}
                          {clearOrNormal}
                        </>
                      :
                          rabinOfPotentialBuyerSpecified ?
                                  <>
                                  SPECIFIED, Permission granted, !executed
                                  <br></br>
                                  If you are the owner, you can now <b style={{color: 'blue'}}>EXECUTE the sale, in the AUCTION</b> -
                                  not doing ANYTHING here.
                                  <br></br>
                                  Doing so (EXECUTING the auction) means you'll get the funds offered by the top Bidder,
                                  but you'll also perrmanently relinquish control of this asset/domain to him.
                                  <br></br><br></br>
                                  Alternatively, you could abandon the auction (post normally), or clear the specified potential buyer.
                                  <br></br>
                                  Normal Post &nbsp;<Radio toggle label='Clear the Potential Buyer' checked={this.state.clearPotentialSale}
                                                                      field='clearPotentialSale' onChange={ (event, data) => handleFieldChange(event, data, this) }/>
                                  <br></br>
                                  NOTE: normally (when not testing) these two options are only available after waiting a period of time.
                                  <br></br>
                                  {clearOrNormal}
                                  </>
                              :
                                  <>
                                    {auctionStateSummary}
                                    {normalPost}
                                  </>)

    const rabinReUseOption = this.state.specifyPotentialSale || this.state.clearPotentialSale
                              || this.state.transferOwnership ?
                  this.state.transferOwnership ?
                      <div >
                        Because you're applying an AuthCode, there's no need to specify the
                        next authorized Rabin PKH. It's built into the AuthCode. The authcode
                        is a signature authorizing the transfer of ownership to a NEW owner.
                        That new owner has alredy been specified - via a new authorized Rabin
                        PKH: {auctionBestBidRabin} (controlled by the winner of the auction).
                        Applying the authcode transfers control from the previous owner, to
                        the new owner.
                      </div>
                  :
                      <div>
                      Because you're specifying or clearing a potential sale, you'll need to
                      keep the authorized Rabin PKH unchanged (at {ownerRabinPKH}). Once enough
                      time has elapsed that you're able to post a 'normal' transaction, you can
                      update the authorized rabin PKH if you wish.
                      </div>
            :
                  <>
                    <div>Rabin PKHs help authenticate transaction publishing rights on a Limb.</div>
                    <div>Every CONTINUE transaction must declare a Rabin PKH (to unlock the NEXT transaction).
                        You may declare a new Rabin PKH to authenticate your NEXT transaction, or you may
                        decide to re-use your current Rabin PKH.</div>
                    <Input field='rabinPKH' label={rabinPKHLabel} placeholder={rabinPKHPlaceholder}
                                  value={this.state.rabinPKH} style={{width: "530px"}}
                                  onChange={ (event, data) => handleFieldChange(event, data, this) }/>
                    &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                    &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                    {reUseButton}&nbsp;
                    <RabinUtility disabled={this.state.rabinPKH.length > 0}
                        parent={this}
                    />
                  </>

    const instaBuyerRabinOption =
                  <>
                    <div>Rabin PKHs help authenticate transaction publishing rights on a BitShizzle Limb/Asset.</div>
                    <div>Every CONTINUE transaction must declare a Rabin PKH (to unlock the NEXT transaction).
                        You must declare a Rabin PKH to take control of this asset, and later, authenticate
                        your NEXT transaction. BE SURE that you have secure (and redundant) access to the Rabin
                        Private Keys associated with this PKH. If you don't, then you'll immediately lose control
                        of this asset.
                    </div>
                    <Input field='rabinPKH' label='NEW Rabin PKH' placeholder='Enter a NEW Rabin PKH to take control'
                                  value={this.state.rabinPKH} style={{width: "530px"}}
                                  onChange={ (event, data) => handleFieldChange(event, data, this) }/>

                    <RabinUtility disabled={this.state.rabinPKH.length > 0}
                        parent={this}
                    />
                  </>
    //NOTE: now we ALWAYS allow registering an Emergency Builder Fee Redution Authorizationi (EBFRA)
    const optionalRegisterBuilderVote = //isLittleP ?
              <>
                <div>
                    <b style={{color: 'red'}}>Since an owner might want to keep fees low,</b> one <i>COULD</i>
                    &nbsp;apply a builder authorization to reduce fees (IF one exists).<br></br>
                    Normal Options &nbsp;<Radio toggle label='Apply Emergency Builder Fee Reduction Authorization' checked={this.state.applyBuilderFeeReduction}
                        field='applyBuilderFeeReduction' onChange={ (event, data) => handleFieldChange(event, data, this) }/>
                    <p></p>IMPLEMENT ME: detect and report if there's been a builder-broadcast fee-reduction authorization before
                    presenting this option.
                </div>
              </>

    const auctionMentions = this.state.thereIsAnAuction ?
                      <>
                          {optionalPotentialBuyerSpecified}
                          {optionalMentionIfAuctionGranted}
                          <br></br>
                      </>
                    :
                      <>
                      </>
    const normalOptions =
                  <>
                    {instaBuyOption}
                    {auctionMentions}
                    {alternateMainFeatures}
                    <br></br>

                    {reclaimText}

                    <Divider />
                    <b>Next Authorized Rabin PKH</b>
                    {rabinReUseOption}
                  </>

    let surferOption = this.state.notTheOwner ?
                  <>
                    <div>
                      You can <b>BUY</b> this asset, if you choose.
                    </div>
                    <div>
                      You'll only need to specify your new RabinPKH, and
                      SEND the proper funds ({decimalPrice} satoshis) to the seller. You can do
                      all of that, right now, below.
                    </div>

                    <Divider />

                    <b>NEW Authorized Rabin PKH</b>
                    {instaBuyerRabinOption}
                  </>
              :
                  <>
                    <div>You can BUILD on this transaction</div>
                    {normalOptions}
                  </>

    let ownerOrSurferOption = ( qc >= 5 && !blankPrice && !expired ) ?
                <>
                  <div>
                    <b>Asset for Sale</b><p></p>
                    Are you the owner of this asset? If so, you may continue.
                    If not, you may want to know that it is for sale
                    <p></p>
                    I am the owner &nbsp;<Radio toggle label='I am NOT the owner' checked={this.state.notTheOwner}
                                field='notTheOwner' onChange={ (event, data) => handleFieldChange(event, data, this) }/>
                  </div>
                  {surferOption}
                </>
            :
                <>
                {normalOptions}
                </>

    const normalOrApplyVote = this.state.applyBuilderFeeReduction ?
              <>
                <b> Apply Builder Fee Reduction </b>
                We will apply a Builder-signed Fee Reduction Authorization. Doing so leaves all state
                unchanged, EXCEPT flips a single builder's fee-reduction 'vote' to 'YES' - resulting
                in builder fees that are approximately 30% cheaper.<p></p>
                <Input field='bfrSignature' label='BFR Signature' placeholder='Enter the BFR signature string (long decimal #)'
                                  value={this.state.bfrSignature} style={{width: "500px"}}
                                  onChange={ (event, data) => handleFieldChange(event, data, this) }/>
                <Input field='bfrPadding' type="number" label='BFR Padding' placeholder='Enter the zero-padding string (could be blank)'
                                  value={this.state.bfrPadding} style={{width: "500px"}}
                                  onChange={ (event, data) => handleFieldChange(event, data, this) }/>
                <Input field='builderPubKey' label='Builder PubKey' placeholder='Enter the Builder Public Key'
                                  value={this.state.builderPubKey} style={{width: "500px"}}
                                  onChange={ (event, data) => handleFieldChange(event, data, this) }/>
              </>
          :
              <>
                {ownerOrSurferOption}
              </>

    // NOTE: FundsGetter will set OUR state for these three variables:
    //       bsvPrivKey, bsvKeyIsSufficient, chosenAddress
    const fundsGetter = this.state.openFundingModal ?
                                  <FundsGetter parent={this} closeModal={this.closeFundsGetter}/>
                                          :
                                  null
    const fundingAddressMention = this.state.bsvKeyIsSufficient ?
                                        <>
                                          Funded by address {this.state.chosenAddress}
                                        </>
                                    :
                                        <>
                                          Please fund this transaction
                                        </>

    const fundingBtnLabel = "FUND this transaction"
    const fundingBtnDisabled = this.state.bsvKeyIsSufficient
    const maybeFundsGetterButton = showFundsGetterButton ?
                                              <>
                                                <Divider />
                                                <Button disabled={fundingBtnDisabled} content={fundingBtnLabel} positive onClick={this.openFundsGetter}/>
                                                    &nbsp; {fundingAddressMention}
                                              </>
                                          :
                                              <></>

    return (
      <>
        <Modal.Content image scrolling>
        <Container>
        <Modal.Description>

          {action}
          {dispositionDescription}
          <Divider />
          {blurb}
          <Divider />

          {optionalRegisterBuilderVote}
          <p></p>

          {normalOrApplyVote}

          {maybeFundsGetterButton}
          <><p>&nbsp;</p></>
          {fundsGetter}

        </Modal.Description>
        </Container>
        </Modal.Content>

        <Modal.Actions className="modaledge">
          <Button disabled={claimBuilderBuildBtnDisabled} content={buttonLabel} positive onClick={this.handlePublishSubmit}/>
          <Button onClick={ (event) => onCancel(event, this, 'claimBuilder') } content='CANCEL' negative/>
        </Modal.Actions>

        <KeySolicitingModal onInputChange={ (event, data) => handleFieldChange(event, data, this) }
                            openSecondaryModal={this.state.openSecondaryModal}
                            openBadKeysModal={this.state.openThirdModal}
                            parent={this}
                            closeThird={ () => closeOurThird(this) }
                            ownerRabinPKH={ownerRabinPKH}
                            //checkFilled={ () => bothPrivKeysAreFilled(this) }
                            onSubmit={ (event) => handleRabinKeyModalSubmit(event, this, 'claimBuilder') }
                            onCancel={ (event) => handleRabinKeyModalCancel(event, this) } />
      </>);
  } //ClaimBuilder render()
}



class BuilderPrepBuilder extends React.Component {
  constructor(props) {
    super(props);

    this.state = {payoutPKH: '',
                  payoutAddress: '',
                  builderRabin: '',
                  rabinPkhFieldEnabled: false,

                  openFundingModal: false,
                  bsvPrivKey: '',
                  bsvKeyIsSufficient: false,
                  chosenAddress: ''
                 };
    this.handlePayoutAddressChange = this.handlePayoutAddressChange.bind(this);
    this.handleBuilderRabinChange  = this.handleBuilderRabinChange.bind(this); // for a BUILDER to broadcast an emergency fee reduction authorization
    this.handleFormSubmit          = this.handleFormSubmit.bind(this);
    this.handleGeneratedBuilderRabin = this.handleGeneratedBuilderRabin.bind(this);

    this.openFundsGetter           = this.openFundsGetter.bind(this);
    this.closeFundsGetter          = this.closeFundsGetter.bind(this);
  }

  openFundsGetter(event) {
    console.log("openFundsGetter()")
    this.setState({openFundingModal: true})

    event.preventDefault()
  }

  closeFundsGetter() {
    console.log("closeFundsGetter()")
    this.setState({openFundingModal: false})
  }

  handleBuilderRabinChange(event) {
    //console.log("handleBuilderRabinChange(): builder rabin pkh now set to ", event.target.value);

    if ( event.target.value.length === 40 ) {
      // If already in use, clear it out, and pop-up mention which
      // builder already using it
      const dtx = JSON.parse(this.props.dtx);
      const outIndex = this.props.outputChosen;
      const builderCount = dtx.outputStates[outIndex].builderCount
      console.warn("SO FAR, there are " + builderCount + " builders")
      for (let i = 0;  i < builderCount; i++ ) {
        console.warn("builder " + i + " rabin: " + dtx.outputStates[outIndex].builderRabins[i])
        if ( event.target.value === dtx.outputStates[outIndex].builderRabins[i] ) {
          alert("Rabin REJECTED. It's already been applied to builder " + i + ". Please use a different Rabin.")
          this.setState({builderRabin: ''});
          return
        }
      }
    }

    this.setState({builderRabin: event.target.value});
  }

  handlePayoutAddressChange(event) {
    console.log("handlePayoutAddressChange(): address now set to ", event.target.value);

    const pkh = convertAddressToPKH(event.target.value)
    if ( pkh === -1 ) {
      //FIXME: WARN user that this address is for the WRONG network
      this.setState({payoutAddress: event.target.value, payoutPKH: ''})
    } else if ( pkh !== null ) {
      this.setState({payoutAddress: event.target.value, payoutPKH: pkh})
    } else {
      this.setState({payoutAddress: event.target.value, payoutPKH: ''})
    }
  }

  handleGeneratedBuilderRabin(p, q, pkh) {
    //console.log("BuilderPrepBuilder handleGeneratedBuilderRabin: will use p of " + p)
    //console.log("BuilderPrepBuilder handleGeneratedBuilderRabin: will use q of " + q)
    console.log("BuilderPrepBuilder handleGeneratedBuilderRabin: will use pkh of " + pkh)

    // ALSO set by handleBuilderRabinChange()
    this.setState({builderRabin: pkh})
  }

  async handleFormSubmit(event) {

    // we use onPayoutPKHChange to set payoutPKH, and this to use it

    const dtx = JSON.parse(this.props.dtx);
    console.log("BuilderPrepBuilder::handleFormSubmit(): dtx = ", dtx);
    //console.warn("handleFormSubmit() - similar to buildAndPublish(): privKey: " + this.state.bsvPrivKey);

    console.warn("BuilderPrepBuilder::handleFormSubmit(): payoutAddress was ", this.state.payoutAddress);
    console.log("BuilderPrepBuilder::handleFormSubmit(): payoutPKH was ", this.state.payoutPKH);
    console.log("BuilderPrepBuilder::handleFormSubmit(): builderRabin (pkh) was ", this.state.builderRabin);

    const outIndex = this.props.outputChosen;
    console.log("BuilderPrepBuilder::handleFormSubmit(): outIndex = " + outIndex);

    // Don't post the form, and trigger a page reload
    event.preventDefault();

    console.log("BuilderPrepBuilder::handleFormSubmit(): building on prev tx (onWhichToBuild): " + this.props.txOnWhichToBuild);

    let theBlockHeight
    if ( window.devTestMode ) {
      console.warn("BuilderPrepBuilder::handleFormSubmit(): using minimal block height (driven by previous tx's 'minBlock', instead of 'current' block height)")
      theBlockHeight = 0
    } else {
      theBlockHeight = this.props.blockHeight
    }

    const initialBuilderVoteNO = "00"
    try {
      // build the builder prep tx (then we'll pass it back to the ContractBuilder)
      // This ALSO adds multiple txo entries (which ever are appropriate)
      // NOTE: pass blockHeight of 0 if devTestMode
      const tx = await buildOnBuilderPrep(dtx.outputStates[outIndex], this.props.txOnWhichToBuild, outIndex,
                                    this.state.payoutPKH + this.state.builderRabin + initialBuilderVoteNO,   // builder state: payoutPKH + rabin + vote
                                    this.state.bsvPrivKey);

      // we now pass back THIS tx - to make it the txToConsider/render
      console.log("BuilderPrepBuilder::handleFormSubmit(): we've built tx ", tx);

      this.props.handleBuildEvent(tx);
    } catch (error) {
      console.log('Failed in BuilderPrepBuilder::handleFormSubmit()');
      //console.log('Failed on network: ' + NETWORK)
      console.error("stack: " + error.stack);
      printError(error);    // needed?
    }
  }

  render() {
    const dtx = JSON.parse(this.props.dtx);
    console.log("BuilderPrepBuilder::render(): dtx is ", dtx);
    const outIndex = this.props.outputChosen;

    //console.log("BuilderPrepBuilder::render(): out Index is ", outIndex)
    const chosenMode = hexByteToAscii( dtx.outputStates[ outIndex ].mode );
    const namePathHex = dtx.outputStates[outIndex].namePath;
    const namePath = hexStringToAscii( namePathHex );
    //console.log("BuilderPrepBuilder::render(): namePath is ", namePath);

    const buildButtonLabel = this.state.rabinPkhFieldEnabled ? 'BUILD & Pre-CLAIM \'' + namePath + '\' on output ' + outIndex + ' (mode ' + chosenMode + ')'
                                                           : 'BUILD/Expand on \''   + namePath + '\' on output ' + outIndex + ' (mode ' + chosenMode + ')';

    const prepBuildBtnDisabled = this.state.payoutPKH.length !== 40 ||
                          this.state.builderRabin.length !== 40 ||
                          !this.state.bsvKeyIsSufficient
                          //(this.state.rabinPkhFieldEnabled );
if ( prepBuildBtnDisabled ) {
  console.error("payoutPKH len: ", this.state.payoutPKH.length)
  console.error("builder rabin PKH len: ", this.state.builderRabin.length)
  console.error("rabinPkhFieldEnabled: " + this.state.rabinPkhFieldEnabled)
}

    // NOTE: FundsGetter will set OUR state for these three variables:
    //       bsvPrivKey, bsvKeyIsSufficient, chosenAddress
    const fundsGetter = this.state.openFundingModal ?
                                  <FundsGetter parent={this} closeModal={this.closeFundsGetter}/>
                                          :
                                  null
    const fundingAddressMention = this.state.bsvKeyIsSufficient ?
                                        <>
                                          Funded by address {this.state.chosenAddress}
                                        </>
                                    :
                                        <>
                                          Please fund this transaction
                                        </>

    const fundingBtnLabel = "FUND this transaction"
    const fundingBtnDisabled = this.state.bsvKeyIsSufficient
    return (
      <>
        <Modal.Content image scrolling>
        <Container>
        <Modal.Description>

        <p><b>'BUILDER PREP' Contract Builder</b></p>

        <p>Only BitShizzle can build on an UNSPENT output of mode 'b'.</p>
        <Divider />
        <div>Please specify your BSV Address (alphanumeric string of 33-ish characters) to receive these builder fees.
          (example Payout Address: muE4dzmzchQWgVTHb1Ndvwhb7P8uGTBEwP   Don't use this one! It's not yours.)
        </div>
        <div>You MUST have (and keep secure) the private key for this. If not, you'll miss-out on receiving builder fees,
            and any fees sent your way will be lost forever.</div>

        <form>
          <label>
            Payout Address: &nbsp;
            <input type="text" name="name" value={this.state.payoutAddress}
                  style={{width: "475px", backgroundColor: 'lightyellow'}}
                  autoComplete="off" placeholder="Enter YOUR BSV address to receive builder fees"
                  onChange={this.handlePayoutAddressChange}/>
          </label>
          <p></p>
          <div>Please specify your Builder Rabin PKH (40-character hexadecimal) to allow you
              to authorize builder fee discounts (if you ever so choose). Also, NEVER assign a
              Builder Rabin more than once (don't re-use it).</div>
          <div>You MUST have (and keep secure) the private keys for this. If not, you'll miss-out on one of the
              benefits of being a builder.</div>
          <label>
            Builder Rabin PKH: &nbsp;
            <input type="text" name="name" value={this.state.builderRabin}
                  style={{width: "475px", backgroundColor: 'lightyellow'}}
                  autoComplete="off" placeholder="Enter YOUR Rabin PKH to exercise control as a Builder"
                  onChange={this.handleBuilderRabinChange}/>
          </label>
          &nbsp; &nbsp;
              <RabinUtility disabled={this.state.builderRabin.length > 0}
                            useGeneratedKeys={this.handleGeneratedBuilderRabin} />

          <Divider />
          <Button disabled={fundingBtnDisabled} content={fundingBtnLabel} positive onClick={this.openFundsGetter}/>
            &nbsp; {fundingAddressMention}
          <Divider />

        </form>

        {fundsGetter}

        </Modal.Description>
        </Container>
        </Modal.Content>

        <Modal.Actions className="modaledge">
          <Button disabled={prepBuildBtnDisabled} content={buildButtonLabel} positive onClick={this.handleFormSubmit}/>
          <Button onClick={ (event) => onCancel(event, this, 'BuilderPrepBuilder') } content='CANCEL' negative/>
        </Modal.Actions>


      </>
    );
  } //BuilderPrepBuilder render()
}

class AppendBuilder extends React.Component {
  constructor(props) {
    super(props);

    this.state = {payoutPKH: '',
                  payoutAddress: '',
                  builderRabin: '',
                  rabinPkhFieldEnabled: false,
                  preClaim: false,
                  preClaimRabinPKH: '',
                  builderInitialVote: false,

                  openFundingModal: false,
                  bsvPrivKey: '',
                  bsvKeyIsSufficient: false,
                  chosenAddress: ''
                 };
    this.handlePayoutAddressChange = this.handlePayoutAddressChange.bind(this);
    this.handleBuilderRabinChange  = this.handleBuilderRabinChange.bind(this); // for a BUILDER to broadcast emergency fee halvings
    this.handlePreClaimChange      = this.handlePreClaimChange.bind(this);
    this.handlePreVoteChange       = this.handlePreVoteChange.bind(this);
    this.handleFormSubmit          = this.handleFormSubmit.bind(this);
    this.handleRabinPkhChange      = this.handleRabinPkhChange.bind(this);  // for an OWNER to authorize the next publication
    this.handleGeneratedKeys       = this.handleGeneratedKeys.bind(this);
    this.handleGeneratedBuilderRabin = this.handleGeneratedBuilderRabin.bind(this);

    this.openFundsGetter           = this.openFundsGetter.bind(this);
    this.closeFundsGetter          = this.closeFundsGetter.bind(this);
  }

  openFundsGetter(event) {
    console.log("openFundsGetter()")
    this.setState({openFundingModal: true})

    event.preventDefault()
  }

  closeFundsGetter() {
    console.log("closeFundsGetter()")
    this.setState({openFundingModal: false})
  }

  handleBuilderRabinChange(event) {
    //console.log("handleBuilderRabinChange(): builder rabin pkh now set to ", event.target.value);

    if ( event.target.value.length === 40 ) {
      // If already in use, clear it out, and pop-up mention which
      // builder already using it
      const dtx = JSON.parse(this.props.dtx);
      const outIndex = this.props.outputChosen;
      for (let i = 0;  i < MAX_PKHS; i++ ) {
        console.warn("builder " + i + " rabin: " + dtx.outputStates[outIndex].builderRabins[i])
        if ( event.target.value === dtx.outputStates[outIndex].builderRabins[i] ) {
          alert("Rabin REJECTED. It's already been applied to builder " + i + ". Please use a different Rabin.")
          this.setState({builderRabin: ''});
          return
        }
      }
    }

    this.setState({builderRabin: event.target.value});
  }

  handleRabinPkhChange(event) {
    console.log("handleRabinPkhChange(): pre-claim RABIN pkh now set to ", event.target.value);

    // ALSO set by handleGeneratedKeys()
    this.setState({preClaimRabinPKH: event.target.value});
  }

  handlePayoutAddressChange(event) {
    console.log("handlePayoutAddressChange(): address now set to ", event.target.value);

    const pkh = convertAddressToPKH(event.target.value)
    if ( pkh === -1 ) {
      //FIXME: WARN user that this address is for the WRONG network
      this.setState({payoutAddress: event.target.value, payoutPKH: ''})
    } else if ( pkh !== null ) {
      this.setState({payoutAddress: event.target.value, payoutPKH: pkh})
    } else {
      this.setState({payoutAddress: event.target.value, payoutPKH: ''})
    }
  }

  handlePreClaimChange(event, data) {
    console.log("handlePreClaimChange: (pre-claim) setting:", data.checked);
    this.setState({rabinPkhFieldEnabled: data.checked});
  }

  handlePreVoteChange(event, data) {
    console.log("handlePreVoteChange: (pre-vote) setting:", data.checked);
    this.setState({builderInitialVote: data.checked});
  }

  handleGeneratedBuilderRabin(p, q, pkh) {
    //console.log("AppendBuilder handleGeneratedBuilderRabin: will use p of " + p)
    //console.log("AppendBuilder handleGeneratedBuilderRabin: will use q of " + q)
    console.log("AppendBuilder handleGeneratedBuilderRabin: will use pkh of " + pkh)

    // ALSO set by handleBuilderRabinChange()
    this.setState({builderRabin: pkh})
  }

  handleGeneratedKeys(p, q, pkh) {
    console.log("AppendBuilder handleGeneratedKeys: will use p of " + p)
    console.log("AppendBuilder handleGeneratedKeys: will use q of " + q)
    console.log("AppendBuilder handleGeneratedKeys: will use pkh of " + pkh)

    // ALSO set by handleRabinPkhChange()
    this.setState({preClaimRabinPKH: pkh})
  }

  //FIXME: at some point evaluate if we really need a form
  async handleFormSubmit(event) {

    // we use onPayoutPKHChange to set payoutPKH, and this to use it

    const dtx = JSON.parse(this.props.dtx);
    console.log("AppendBuilder::handleFormSubmit(): dtx = ", dtx);
    //console.warn("handleFormSubmit similar to buildAndPublish(): privKey: " + this.state.bsvPrivKey);

    console.warn("AppendBuilder::handleFormSubmit(): payoutAddress was ", this.state.payoutAddress);
    console.log("AppendBuilder::handleFormSubmit(): payoutPKH was ", this.state.payoutPKH);
    console.log("AppendBuilder::handleFormSubmit(): builderRabin (pkh) was ", this.state.builderRabin);

    const outIndex = this.props.outputChosen;
    console.log("AppendBuilder::handleFormSubmit(): outIndex = " + outIndex);

    const preClaimOrNo = this.state.rabinPkhFieldEnabled ? 'y' : 'n';   // was .preClaim
    const preClaimRabinPKH = this.state.preClaimRabinPKH;
    console.log("AppendBuilder::handleFormSubmit(): preClaimOrNo: " + preClaimOrNo);
    console.log("AppendBuilder::handleFormSubmit(): preClaimRabinPKH: " + preClaimRabinPKH);

    // Don't post the form, and trigger a page reload
    event.preventDefault();

    console.log("AppendBuilder::handleFormSubmit(): building on prev tx (onWhichToBuild): " + this.props.txOnWhichToBuild);

    let theBlockHeight
    if ( window.devTestMode ) {
      console.warn("AppendBuilder::handleFormSubmit(): using minimal block height (driven by previous tx's 'minBlock', instead of 'current' block height)")
      theBlockHeight = 0
    } else {
      theBlockHeight = this.props.blockHeight
    }

    const initialBuilderVote = this.state.builderInitialVote ? "FF" : "00"

    // empower those that call this function, to set this value
    const optionalAdditionalBalance = 0

    if ( preClaimOrNo === 'n' && optionalAdditionalBalance > 0 ) {
      throw new Error("CODE ERROR: cannot add additional balance if not claiming asset")
    }

    try {
      // build the append tx (then we'll pass it back to the ContractBuilder)
      // This ALSO adds multiple txo entries (which ever are appropriate)
      // NOTE: pass blockHeight of 0 if devTestMode
      const tx = await buildOnAppend(dtx.outputStates[outIndex], this.props.txOnWhichToBuild, outIndex,
                                    this.state.payoutPKH + this.state.builderRabin + initialBuilderVote,  // builder state: payoutPKH + rabin + vote
                                    preClaimOrNo, preClaimRabinPKH, theBlockHeight,
                                    this.state.bsvPrivKey,
                                    optionalAdditionalBalance
                                    ); //this.props.blockHeight);
        // NOTE: we now include builderRabin (and vote) in the builderState param

      // we now pass back THIS tx - to make it the txToConsider/render
      console.log("AppendBuilder::handleFormSubmit(): we've built tx ", tx);

      this.props.handleBuildEvent(tx);
    } catch (error) {
      console.log('Failed in AppendBuilder::handleFormSubmit()');
      //console.log('Failed on network: ' + NETWORK)
      console.error("stack: " + error.stack);
      printError(error);    // needed?
    }
  }

  render() {
    const dtx = JSON.parse(this.props.dtx);
    console.log("AppendBuilder::render(): dtx is ", dtx);
    const outIndex = this.props.outputChosen;

    //console.log("AppendBuilder::render(): out Index is ", outIndex)
    const chosenMode = hexByteToAscii( dtx.outputStates[ outIndex ].mode );
    const namePathHex = dtx.outputStates[outIndex].namePath;
    const namePath = hexStringToAscii( namePathHex );
    //console.log("AppendBuilder::render(): namePath is ", namePath);
    let noVoteDisplay = 'Reduce fees now'
    let yesVoteDisplay
    if ( this.state.builderInitialVote ) {
      yesVoteDisplay = <span style={{color: 'blue'}}>  &nbsp; Reduce Builder fees now</span>
      noVoteDisplay  = <span style={{color: 'lightGrey'}}> Decide later &nbsp;</span>
    } else {
      yesVoteDisplay = <span style={{color: 'lightGrey'}}>  &nbsp; Reduce Builder fees now</span>
      noVoteDisplay  = <span style={{color: 'blue'}}> Decide later &nbsp;</span>
    }
    const preclaimCheckbox = "Pre-claim path '" + namePath + "' (take ownership)";
    const pkhColor = this.state.rabinPkhFieldEnabled ? 'rgb(25, 25, 25)' : 'lightgrey';
    const mustColor = this.state.rabinPkhFieldEnabled ? 'red' : 'lightgrey';
    const buildButtonLabel = this.state.rabinPkhFieldEnabled ? 'BUILD & Pre-CLAIM \'' + namePath + '\' on output ' + outIndex + ' (mode ' + chosenMode + ')'
                                                           : 'BUILD/Expand on \''   + namePath + '\' on output ' + outIndex + ' (mode ' + chosenMode + ')';
    // /* color: rgba(0,0,0,.87); */
    const rabinPkhSolicitationClause =  <> <p></p>
                                          <div style={{color: pkhColor}}>
                                            <div> A Rabin PKH is required when claiming a path/limb - for authenticating future transactions.
                                                  You <span style={{color: mustColor}}><b>MUST</b></span> have the private keys corresponding to this PKH (it must be <strong>your</strong> PKH).
                                                  If you don't, you'll immediately lose control of this asset. READ THIS AGAIN, and don't build/proceed until you're sure you understand.
                                            </div>
                                            <br></br>
                                            <div> Example Rabin PKH: 748fc2b2cbad49e520f1d2cdfc91265318fdff3c (do NOT use this PKH)</div>
                                          </div>
                                          <Input disabled={!this.state.rabinPkhFieldEnabled} label='Rabin PKH:'
                                                placeholder='Enter YOUR Rabin PKH to authorize your next transaction'
                                                value={this.state.preClaimRabinPKH} style={{width: "475px"}}
                                                onChange={this.handleRabinPkhChange}/>
                                          &nbsp; &nbsp;
                                          <RabinUtility disabled={!this.state.rabinPkhFieldEnabled || this.state.preClaimRabinPKH.length > 0}
                                              useGeneratedKeys={this.handleGeneratedKeys}
                                          />
                                        </>;

    const appendBuildBtnDisabled = this.state.payoutPKH.length !== 40 ||
                          this.state.builderRabin.length !== 40 ||
                          (this.state.rabinPkhFieldEnabled && this.state.preClaimRabinPKH.length !== 40) ||
                          !this.state.bsvKeyIsSufficient;

    // NOTE: FundsGetter will set OUR state for these three variables:
    //       bsvPrivKey, bsvKeyIsSufficient, chosenAddress
    const fundsGetter = this.state.openFundingModal ?
                                  <FundsGetter parent={this} closeModal={this.closeFundsGetter}/>
                                          :
                                  null

    const fundingAddressMention = this.state.bsvKeyIsSufficient ?
                                        <>
                                          Funded by address {this.state.chosenAddress}
                                        </>
                                    :
                                        <>
                                          Please fund this transaction
                                        </>

    const fundingBtnLabel = "FUND this transaction"
    const fundingBtnDisabled = this.state.bsvKeyIsSufficient
    return (
      <>
        <Modal.Content image scrolling>
        <Container>
        <Modal.Description>

        <p><b>'APPEND' Contract Builder</b></p>

        <p>ANYONE can build on an UNSPENT output of mode 'X'. As a Builder of an Expand/Append contract,
           you get to specify a 'Payout Address'. Anyone building on
           your transaction/node, or certain transactions and assets derived from it, may occasionally need to pay you a small fee.</p>
        <Divider />
        <div>Please specify your BSV Address (alphanumeric string of 33-ish characters) to receive these builder fees.
          (example Payout Address: mqDWe6Df4Yqyt8FZM3ycMNYb7rzcZeGvWJ   Don't use this one! It's not yours.)
        </div>
        <div>You MUST have (and keep secure) the private key for this. If not, you'll miss-out on receiving builder fees,
            and any fees sent your way will be lost forever.</div>

        <form>
          <label>
            Payout Address: &nbsp;
            <input type="text" name="name" value={this.state.payoutAddress}
                  style={{width: "475px", backgroundColor: 'lightyellow'}}
                  autoComplete="off" placeholder="Enter YOUR BSV address to receive builder fees"
                  onChange={this.handlePayoutAddressChange}/>
          </label>
          <p></p>
          <div>Please specify your Builder Rabin PKH (40-character hexadecimal) to allow you
              to authorize builder fee discounts (if you ever so choose). Also, NEVER assign a
              Builder Rabin more than once (don't re-use it).</div>
          <div>You MUST have (and keep secure) the private keys for this. If not, you'll miss-out on one of the
              benefits of being a builder.</div>
          <label>
            Builder Rabin PKH: &nbsp;
            <input type="text" name="name" value={this.state.builderRabin}
                  style={{width: "475px", backgroundColor: 'lightyellow'}}
                  autoComplete="off" placeholder="Enter YOUR Rabin PKH to exercise control as a Builder"
                  onChange={this.handleBuilderRabinChange}/>
          </label>
          &nbsp; &nbsp;
              <RabinUtility disabled={this.state.builderRabin.length > 0}
                            useGeneratedKeys={this.handleGeneratedBuilderRabin} />

          <div>As a Builder, you may decide if you'd like to enable LOWER Builder fees for most of your
                descendants (asset owners derived from, below, this transaction you're about to build), or wait to
                decide later. IF/when Builder fees are high, you may want to reduce them - to hopefully stimulate
                activity (and thus, the <b>number</b> of fees you may collect).
                There is a schedule that reduces Builder fees,  slowly, over time, but it may or may not coincide
                with changes in the price of BSV. You, and other Builders have the responsibility/power/opportunity
                to adjust (reduce) them when you wish. They can NEVER be increased.
                <p>FUTURE: inform on the current state of Builder fees, votes, schedule.</p>
          Builder Fee-reduction decision: &nbsp; &nbsp;{noVoteDisplay} <Radio toggle  onChange={this.handlePreVoteChange}/>
          {yesVoteDisplay}
          </div>

          <Divider/>
          <p>IF your goal is to publish on this namePath/Limb you're about to build ('{namePath}'), you should consider
            'claiming' it right now. Later (immediately thereafter), you can actually publish on it. Claiming a Limb costs more than just building/extending a Limb.
            Any asset you claim also carries with it a requirement to 'renew' ownership once a year - for a small fee. <span style={{color: 'red'}}> If you don't
            renew your claimed asset (limb '{namePath}'), roughly every year, <b>ANYONE</b> could wrest away control of your asset - for a small fee.</span>
          </p>
          <p>IF your goal is at a namePath/limb further along (longer domain/asset name), there's no need to claim this path.</p>
          <Radio toggle label={preclaimCheckbox} onChange={this.handlePreClaimChange}/>
          {rabinPkhSolicitationClause}

          <Divider />
          <Button disabled={fundingBtnDisabled} content={fundingBtnLabel} positive onClick={this.openFundsGetter}/>
            &nbsp; {fundingAddressMention}
          <Divider />
        </form>

        {fundsGetter}

        </Modal.Description>
        </Container>
        </Modal.Content>

        <Modal.Actions className="modaledge">
          <Button disabled={appendBuildBtnDisabled} content={buildButtonLabel} positive onClick={this.handleFormSubmit}/>
          <Button onClick={ (event) => onCancel(event, this, 'appendBuilder') } content='CANCEL' negative/>
        </Modal.Actions>

      </>
    );
  } //AppendBuilder render()
}

class EbfraBuilder extends React.Component {
  constructor(props) {
    super(props);

    this.state = { theVote: false,
                   openSecondaryModal: false,    // Key-soliciting modal
                   rabinPrivKeyP: '',
                   rabinPrivKeyQ: '' };

    this.preCheckRabinPrivateKeys = this.preCheckRabinPrivateKeys.bind(this)
    this.handleBuilderEbfraVote   = this.handleBuilderEbfraVote.bind(this)
    this.handleEVoteSubmit        = this.handleEVoteSubmit.bind(this)
    this.buildAndPublish          = this.buildAndPublish.bind(this)
  }


  preCheckRabinPrivateKeys() {
    const dtx = JSON.parse( this.props.dtx );
    const outputChosen = this.props.outputChosen;
    const prevRabinPKH = dtx.outputStates[outputChosen].builderRabinPKH;

    console.log("EbfraBuilder preCheckRabinPrivateKeys(): checking against previous PKH " + prevRabinPKH);

    return validateRabinPrivateKeys(this.state.rabinPrivKeyP, this.state.rabinPrivKeyQ, prevRabinPKH)
  }

  handleBuilderEbfraVote(event, data) {
    console.log("handleBuilderEbfraVote() vote to reduce fees: ", data.checked);
    this.setState({theVote: data.checked});
  }

  handleEVoteSubmit(event) {
    // Don't post the form, and trigger a page reload
    event.preventDefault();

    // open the key-soliciting modal
    this.setState({openSecondaryModal: true});
  }

  // called by KeySolicitingModal
  async buildAndPublish() { //publishEVote( event ) {
    const dtx = JSON.parse( this.props.dtx );
    const outIndex = this.props.outputChosen;

    //const blockHeightInt = this.props.blockHeight
    let blockHeightInt
    if ( window.devTestMode ) {
      blockHeightInt = dtx.outputStates[ 0 ].blockNumInt
      console.warn("EbfraBuilder buildAndPublish(): DEV MODE, so, using the "
                + "blockNumInt of the 0th output - as the current blockNum "
                + "for the EBFRA: " + blockHeightInt)
    } else {
      blockHeightInt = this.props.blockHeight
      console.warn("EbfraBuilder buildAndPublish(): using current blockHeight: " + blockHeightInt)
    }

    console.warn("buildAndPublish(): using blockHeight of " + blockHeightInt)
    //console.warn("buildAndPublish(): privKey: " + this.state.bsvPrivKey);
    console.log("  calling announceBuilderFeeReductionAuth(). dtx: ", dtx)

    try {
      const txid = await announceBuilderFeeReductionAuth(
            dtx.outputStates[outIndex],
              this.props.txOnWhichToBuild,
              outIndex,
          blockHeightInt,
            this.state.theVote,  // true/false
            this.state.rabinPrivKeyP,
            this.state.rabinPrivKeyQ,
            this.state.bsvPrivKey)

      // now pass back THIS tx - to make it the txToConsider
      console.log("EbfraBuilder::buildAndPublish(): we've built a tx ", txid);

      this.props.handleBuildEvent(txid);
    } catch (error) {
      console.log('Failed in EbfraBuilder::buildAndPublish()');
      //console.log('Failed on network: ' + NETWORK)
      console.error("stack: " + error.stack);
      printError(error);    // needed?
    }
  } // EbfraBuilder buildAndPublish()

  render() {
    const dtx = JSON.parse(this.props.dtx);
    console.log("EbfraBuilder::render(): dtx is ", dtx);
    const outIndex = this.props.outputChosen;

    const builderRabinPKH = dtx.outputStates[outIndex].builderRabinPKH
    const deadlineInt = dtx.outputStates[outIndex].deadlineInt

    const buildButtonLabel = 'Publish the ' + (this.state.theVote ? "'EBFRA'" : "'NO' vote")
    const ebfraBuildBtnDisabled = false

    return (
      <>
        <Modal.Content image scrolling>
        <Container>
        <Modal.Description>

        <p><b>'EBFRA (Speaker/Announcement)' Contract Builder</b></p>

        <p>Only the Builder of this transaction (who controls the keys to Rabin PKH '{builderRabinPKH}') can build
           (an EBFRA Announcement) on this UNSPENT output of mode 'e' (EBFRA Speaker). As a Builder of an EBFRA Announcement contract,
           you get to specify if you wish to authorize (and make public) an 'Emergency' Builder Fee Reduction Authorization.
        </p>
        <Divider />
        <div>There is nothing to specify - other than Yea/Nay.
        Choosing 'Yea' results in the publishing of an authorization code which most descendant assets can use to reduce their Builder fees.
        <p>Choosing 'Nay' results in the broadcasting of the <b>permanent</b> decision to not publish an EBFRA through this public mechanism.</p>
        </div>
        <div>'Voting' here requires you to have the private keys corresponding to the RabinPKH of record: </div>

        <form onSubmit={this.handleEVoteSubmit}>
          <p>Vote here:</p>
          No <Radio toggle label='Yes' onChange={this.handleBuilderEbfraVote}/>
          <p><b>Current decision:</b> {this.state.theVote ? 'YES, reduce fees' : 'NO, do NOT reduce fees'} for most descendant branches</p>
        </form>
        <Divider />
        Lastly, note that the Builder has until block #{deadlineInt} to vote yes or no. After that point ANYONE in the world can
        spend this output, and claim the satoshis it holds.

        </Modal.Description>
        </Container>
        </Modal.Content>

        <Modal.Actions className="modaledge">
          <Button disabled={ebfraBuildBtnDisabled} content={buildButtonLabel} positive onClick={this.handleEVoteSubmit}/>
          <Button onClick={ (event) => onCancel(event, this, 'ebfraBuilder') } content='CANCEL' negative/>
        </Modal.Actions>

        <KeySolicitingModal onInputChange={ (event, data) => handleFieldChange(event, data, this) }
                            openSecondaryModal={this.state.openSecondaryModal}
                            openBadKeysModal={this.state.openThirdModal}
                            parent={this}
                            closeThird={ () => closeOurThird(this) }
                            ownerRabinPKH={builderRabinPKH}
                            //checkFilled={ () => bothPrivKeysAreFilled(this) }
                            onSubmit={ (event) => handleRabinKeyModalSubmit(event, this, 'ebfraBuilder') }
                            onCancel={ (event) => handleRabinKeyModalCancel(event, this) } />
      </>
    )
  } //EbfraBuilder render()
}


class ContractBuilder extends React.Component {
    constructor(props) {
      super(props);

      this.handleContractBuilt  = this.handleContractBuilt.bind(this);
    }

    //FIXME: instead this should/will pass up/back the newly-generated raw tx
    handleContractBuilt(txId) {
      console.warn("handleContractBuilt(). It looks like we've built, broadcast, and recorded tx ", txId);
  
      this.props.handleNewTx(txId);  //<--- pass this back up, after building and broadcasting
    }

    render() {
      //FIXME: remove this whole clause
      if ( this.props.decodedTx ) {
        const decodedTxObj = JSON.parse( this.props.decodedTx );
        console.log("ContractBuilder::render(): decodedTxObj: ", decodedTxObj);
      }

      // NOTE that each builder needs to know the current block height.
      //      For dev/testing, it can sometimes be nice to use 'old'/low
      //      numbers for the blockHeight, but eventually it's probably
      //      best to just use the current block height

      let contractBuilder = null;
      switch(this.props.chosenMode) {
        case "58":  // X - expand on a namePath/Limb
          console.log("CBuilder: append");
          contractBuilder = <AppendBuilder handleBuildEvent={this.handleContractBuilt}
                                  dtx={this.props.decodedTx}
                                  outputChosen={this.props.outputChosen}
                                  txOnWhichToBuild={this.props.txOnWhichToBuild}
                                  blockHeight={this.props.blockHeight}
                                  onCancel={this.props.onCancel}/>;
          break;
        case "50":             // P - to be claimed, and published on
        case "4b": case "4B":  // K - already claimed. Ready to be published on
        case "70":             //'p' - already published on. already owned. continue
          console.log("CBuilder: claim/continue");
          contractBuilder = <ClaimBuilder handleBuildEvent={this.handleContractBuilt}
                                  dtx={this.props.decodedTx}
                                  outputChosen={this.props.outputChosen}
                                  txOnWhichToBuild={this.props.txOnWhichToBuild}
                                  renewalStatus={this.props.renewalStatus}
                                  blockHeight={this.props.blockHeight}
                                  onCancel={this.props.onCancel}
                                  bbm={this.props.bbm}/>;
          break;
        case "55":
          console.log("CBuilder: update");
          contractBuilder = <UpdateBuilder handleBuildEvent={this.handleContractBuilt}
                                  dtx={this.props.decodedTx}
                                  outputChosen={this.props.outputChosen}
                                  txOnWhichToBuild={this.props.txOnWhichToBuild}
                                  blockHeight={this.props.blockHeight}
                                  onCancel={this.props.onCancel}/> ;
          break;
        case "30":
        case "31": case "32": case "33": case "34":
        case "35": case "36": case "37": case "38":
        case "39":
          console.log("CBuilder: transient");
          contractBuilder = <TransientBuilder handleBuildEvent={this.handleContractBuilt}
                                              dtx={this.props.decodedTx}
                                              outputChosen={this.props.outputChosen}
                                              txOnWhichToBuild={this.props.txOnWhichToBuild}
                                              blockHeight={this.props.blockHeight}
                                              onCancel={this.props.onCancel}/>;
          break;
        case "47":
          console.log("CBuilder: bitgroup");
          contractBuilder = <BitGroupBuilder handleBuildEvent={this.handleContractBuilt}
                                             dtx={this.props.decodedTx}
                                             outputChosen={this.props.outputChosen}
                                             txOnWhichToBuild={this.props.txOnWhichToBuild}
                                             blockHeight={this.props.blockHeight}
                                             onCancel={this.props.onCancel}/>;
          break;
        case "67":
          console.log("CBuilder: admingroup");
          contractBuilder = <AdminGroupBuilder handleBuildEvent={this.handleContractBuilt}
                                               dtx={this.props.decodedTx}
                                               outputChosen={this.props.outputChosen}
                                               txOnWhichToBuild={this.props.txOnWhichToBuild}
                                               blockHeight={this.props.blockHeight}
                                               onCancel={this.props.onCancel}/>;
          break;
        case "4e": case "4E":
          console.log("CBuilder: askbid");
          contractBuilder = <AskBidBuilder handleBuildEvent={this.handleContractBuilt}
                                           dtx={this.props.decodedTx}
                                           outputChosen={this.props.outputChosen}
                                           txOnWhichToBuild={this.props.txOnWhichToBuild}
                                           blockHeight={this.props.blockHeight}
                                           onCancel={this.props.onCancel}/>;
          break;
        case "65":
          console.log("CBuilder: EBFRA (announcement)");
          contractBuilder = <EbfraBuilder handleBuildEvent={this.handleContractBuilt}
                                           dtx={this.props.decodedTx}
                                           outputChosen={this.props.outputChosen}
                                           txOnWhichToBuild={this.props.txOnWhichToBuild}
                                           blockHeight={this.props.blockHeight}
                                           onCancel={this.props.onCancel}/>;
          break;
        case "62":
          console.log("CBuilder: builderPrep");
          contractBuilder = <BuilderPrepBuilder handleBuildEvent={this.handleContractBuilt}
                                           dtx={this.props.decodedTx}
                                           outputChosen={this.props.outputChosen}
                                           txOnWhichToBuild={this.props.txOnWhichToBuild}
                                           blockHeight={this.props.blockHeight}
                                           onCancel={this.props.onCancel}/>;
          break;
        case "44":
          console.log("CBuilder: dialog");
          contractBuilder = <DialogBuilder handleBuildEvent={this.handleContractBuilt}
                                              dtx={this.props.decodedTx}
                                              outputChosen={this.props.outputChosen}
                                              txOnWhichToBuild={this.props.txOnWhichToBuild}
                                              //blockHeight={this.props.blockHeight}
                                              onCancel={this.props.onCancel}/>;
          break;

        default:
          console.error("CBuilder: hmm. default. Unrecognized mode: " + this.props.chosenMode);
          break;
      }

      return (<>
                {contractBuilder}
              </>);
    } //ContractBuilder render()
}
export default ContractBuilder;