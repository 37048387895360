import React from 'react';

import { Button, Checkbox, Container, Divider, Dropdown, Form, Grid,
    Input, Menu, Message, Modal, Popup, Radio, TextArea } from 'semantic-ui-react'

import {
    saveEncryptedKeys,
    getEncryptedKeysFromPKH,
    openDB,

    encryptData,
    decryptData,
    getRabinsJson,
} from './buildShizzle.js';


class KeyExporter extends React.Component {
  constructor(props) {
    super(props);

    this.handleExport = this.handleExport.bind(this);

    this.state = {theRecords: '', recordsToShow: null, numberOfElements: 0};
  }

  async handleExport() {
    console.log("handleExport()")

    const options = {
      types: [
        {
          description: 'Text Files',
          accept: {
            'text/plain': ['.txt'],
          },
        },
      ],
    };

    let records = '';
    try {
      // we've pre-fetched the records - in componentDidMount()
      records = this.state.theRecords;

      const fileHandle = await window.showSaveFilePicker(options);

      // FileSystemWritableFileStream.
      const writable = await fileHandle.createWritable();

      //console.log("Will write these records: " + records)

      // Write to the stream.
      await writable.write(records);

      // Close and write to disk.
      await writable.close();

      this.props.closeKeyExportModal();
    } catch (error) {
      console.error(error);
      //alert('We had trouble trying to write the Rabin Key file. Perhaps your browser doesn\'t support this: ' + error);

      // Have the user manually copy and paste to a file
      this.setState({recordsToShow: records});
    }
  }

  async componentDidMount() {
    var db = await openDB();
    const records = await getRabinsJson(db);
    const contents = JSON.parse(records)
    console.log("componentDidMount() number of elements: ", contents.length);
    this.setState({recordsToShow: null, theRecords: records, numberOfElements: contents.length});
  }

  render() {
    //FIXME: future: indicate how many keys are being saved
    const supported = this.state.recordsToShow === null;
    const recordsToShow = supported ? null
                                    : <Message style={{overflowWrap: 'break-word'}}><Container>{this.state.recordsToShow}</Container></Message>;
    const buttonsToShow = !supported ?
                          <>
                            Your browser doesn't seem to support writing to a file. If you
                            wish to proceed, you can copy and paste the following gibberish
                            to a file. If your browser ever deletes your encrypted Rabin
                            Private Keys, you can import that file to restore them.
                            <p></p>
                            <Button field="copied" onClick={this.props.closeKeyExportModal} positive>Got it</Button>
                          </>
                        :
                          <>
                            These keys are encrypted with a password you provided. Exporting them helps
                            protect you if your machine breaks, or the browser clears your data.
                            You could re-import them using the exported file.
                            <p></p>
                            Unfortunately, not all browsers support writing to a file. If yours doesn't,
                            you should be able to copy and paste the data to a text file.
                            <p></p>
                            <Button field="export" onClick={this.handleExport} positive>Export {this.state.numberOfElements} Password-Encrypted Key(s)</Button>
                            <Button field="cancelButton" onClick={this.props.closeKeyExportModal} negative>CANCEL</Button>
                          </>

    return <>
              <Modal open={true} size='small' dimmer='blurring'>
                <Modal.Header>EXPORT Encrypted Rabin Private Keys</Modal.Header>
                <Modal.Content scrolling>
                  {buttonsToShow}
                  {recordsToShow}
                </Modal.Content>
              </Modal>
          </>
  }
} // KeyExporter

export default KeyExporter;