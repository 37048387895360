import React from 'react';

import { Button, Checkbox, Container, Divider, Dropdown, Form, Grid,
    Input, Menu, Message, Modal, Popup, Radio, TextArea } from 'semantic-ui-react'  

import KeyImporter from './keyImporter';
import KeyExporter from './keyExporter';
import FileChooserReader from './fileChooserReader';

import {
  hexByteToAscii,
  hexStringToAscii,
  execAsync,

  printError,
} from './commonFuncs.js';

import {
    testGetBulkUnspent,

    saveEncryptedKeys,
    getEncryptedKeysFromPKH,

    buildShizzleLockingTx,
    encryptData,
    decryptData,
    getRabinsJson,

    getCurrentBlockInfo,
    findLatestContinue,
    findLatestAuction,

    openDB,
    getTxidFromAddr,
    recursivelyDeleteDescendantSpends,
    queryTxs,
    queryLabels,
    checkDanglingPaths,
    queryFetchTx,
    decomposeTx,
    queryFetchDecodeTx,
    findContent,

    parseTheOutputs,

    findNextTx,
    findPrevTx,

    validateRabinPrivateKeys,
    generateRabinPrivateKeyPlus
} from './buildShizzle.js';

//import { showError } from './helper.js'

//import ShizzleTester from './shizzleTester.js';
import GenericModal  from './genericModal.js';

const axios = require("axios");

function testThing() {
  axios.get('https://reqres.in/api/users')
    .then(response => {
      const users = response.data.data;
      console.log(`GET users`, users);
    })
    .catch(error => console.error(error));
}

function getShizzleNav() {
  return window.ourShizzleNav
}

// Process followable outputs - create labels for a table
//TODO: if this is selected, it's like buildShizzleTx's presentAppendOptionsBasedOnState()'s call to:
//          findNextTx(), then update the entry (txToConsider) in TxStatsDisplay
//      FIXME: bread crumbs?
//FIXME: pass in the classname, and collapse these two functions into one
function fable(i, mode, path, build, confirmed, bogus) {
  const asciiMode = hexStringToAscii(mode);
  const pathString = (path && path !== '') ? hexStringToAscii(path) : '-- spent/built --';
  const claimed       = (asciiMode === 'P' || asciiMode === 'K') ? " - CLAIMED" : "";
  const append        = (asciiMode === 'X' || asciiMode === 'P' || asciiMode === 'K') ? "Append" : ""
  const cntinue       = asciiMode === 'p' ? 'Continue' : "";
  const spawnedUpdate = asciiMode === 'U' ? 'Update' : "";
  const spawnedAskBid = asciiMode === 'N' ? 'AskBid' : "";
  const bitGroup      = asciiMode === 'G' ? 'BitGroup' : "";
  const outputAdminister = asciiMode === 'g' ? 'AdminGroup' : "";
  const transient     = (asciiMode === '0' || asciiMode === '1' || asciiMode === '2' || asciiMode === '3' || asciiMode === '4' ||
                         asciiMode === '5' || asciiMode === '6' || asciiMode === '7' || asciiMode === '8' || asciiMode === '9')
                            ? 'Transient-' + asciiMode : "";
  const allModeProperties = append + cntinue + bitGroup + spawnedUpdate + transient + spawnedAskBid + outputAdminister + claimed;

  let backColor;
  if ( bogus ) {
    backColor = {background: 'pink'}
  } else if ( confirmed ) {
    backColor = {background: 'lightgreen'};
  } else {
    backColor = {background: 'lightyellow'};
  }
  if ( build )
    return { key: i, text: '-- SPENT -->', value: i, disabled: true };
  else
    return { key: i,
             text: (<div style={backColor}>{i}: &nbsp;
                         Limb: <span style={{color: 'blue'}}><strong>
                               {pathString}</strong></span> &nbsp;
                         Mode: {asciiMode} &nbsp; {allModeProperties}</div>),
             value: i };
    //<div className="out-buildlinks-style" key={i}> {label} </div>
}

// process buildable outputs - create labels for a table
function bable(i, mode, path, build) {
  const asciiMode = hexStringToAscii(mode);
  const isEBFRA = asciiMode === 'e' || asciiMode === 'E';
  const isDialog= asciiMode === 'D'
  const pathString = (isEBFRA || isDialog) ? 'N/A' : hexStringToAscii(path);
  const claimable     = asciiMode === 'P' ? " - CLAIMable" : "";
  const claimed       = asciiMode === 'K' ? " - CLAIMED" : "";
  const append        = (asciiMode === 'X' || asciiMode === 'P' || asciiMode === 'K') ? "Append" : ""
  const cntinue       = asciiMode === 'p' ? 'Continue' : "";
  const spawnedUpdate = asciiMode === 'U' ? 'Update' : "";
  const spawnedAskBid = asciiMode === 'N' ? 'AskBid' : "";
  const bitGroup      = asciiMode === 'G' ? 'BitGroup' : "";
  const outputAdminister = asciiMode === 'g' ? 'AdminGroup' : "";
  const transient     = (asciiMode === '0' || asciiMode === '1' || asciiMode === '2' || asciiMode === '3' || asciiMode === '4' ||
                         asciiMode === '5' || asciiMode === '6' || asciiMode === '7' || asciiMode === '8' || asciiMode === '9')
                            ? 'Transient-' + asciiMode : "";
  const allModeProperties = append + cntinue + bitGroup + spawnedUpdate + transient + spawnedAskBid + outputAdminister + claimable + claimed;

  //TODO: if this is selected, it's like buildShizzleTx's presentAppendOptionsBasedOnState()'s call to:
  //          buildOnAppend() (for just 'X', I think), or claimAndPublish ( for 'P' or 'K' )
  //FIXME: also need to handle
  if ( build )
    return { key: i,
             text: (<div>{i}: &nbsp;
                         Limb: <span style={{color: 'blue'}}><strong>
                               {pathString}</strong></span> &nbsp;
                         Mode: {asciiMode} &nbsp; {allModeProperties}</div>),
             value: i };
  else
    return { key: i, text: '<-- UNspent --', value: i, disabled: true };
      //<div className="out-followlinks-style" key={i}> {label}</div>
}

function getMenuOptionsForTxOut(decodedTx, build) {
  const numOutputs = decodedTx.outputStates.length
  var follows = "";
  var builds = "";
  var outs = [];
  //FIXME: build an array for builds and follows? just re-creating what already exists?
  for (var i = 0; i < numOutputs; i++ ) {
      const outputState = decodedTx.outputStates[i];
      const confirmed   = decodedTx.confirmedOutputs[i];
      const bogus       = decodedTx.bogusOutputs[i];
      const mode = outputState.mode;
      const path = outputState.namePath;

      //console.log("BUILD: " + build + "    output " + i + " buildable: ", decodedTx.buildable)
      //console.log("BUILD: " + build + "    output " + i + " followable: ", decodedTx.followable)
      //FIXME: should we really compare to 'undefined'?
      if ( decodedTx.buildable && decodedTx.buildable !== 'undefined' && typeof decodedTx.buildable[i] !== 'undefined' && decodedTx.buildable[i] !== null ) {
        //console.log("output " + i + " buildable: " + decodedTx.buildable + "  buildable[i]: " + decodedTx.buildable[i] + ", mode " + mode);
        builds += i + " ";
        outs.push( bable(i, mode, path, build) );
      } else if ( decodedTx.followable && decodedTx.followable !== 'undefined' && typeof decodedTx.followable[i] !== 'undefined' && decodedTx.followable[i] !== null ) {
        //console.log("output " + i + " followable: " + decodedTx.followable + " followable[i]: " + decodedTx.followable[i] + ", mode " + mode + " bogus: " + bogus);
        follows += i + " ";
        outs.push( fable(i, mode, path, build, confirmed, bogus) );
      } else {
        // here is where we treat unspendable outputs differently
        console.log("This is neither BUILDABLE, NOR FOLLOWABLE. It should be a FINAL post.")
        console.log("output " + i + " buildable: " + decodedTx.buildable[i] + ", mode " + mode);
        console.log("output " + i + " followable: " + decodedTx.followable[i] + ", mode " + mode + " bogus: " + bogus);
        if ( build ) {
          outs.push(  { key: i, text: '(unspendable)', value: i, disabled: true }  )
        } else {
          outs.push(  { key: i, text: '-- spent/built FINAL --', value: i, disabled: true }  )
        }
      }
  }
  //console.log("builds: ", builds);
  //console.log("follows: ", follows);
  //console.log("outs: ", outs);
  return ( outs );
}


/**
 * Handle changes to fields of modal - shared by multiple builder classes:
 *    ClaimBuilder, UpdateBuilder
 *    (soon to be more)
 * @param {*} event
 * @param {*} data
 * @param {*} object
 */
const handleFieldChange = (event, data, object, extraObject) => {
  //console.log("event: ", event);
  //console.log("data: ", data);
  //console.log("Object: ", object);
  const field = data.field;
  const value = data.value;
  const checked = data.checked;
//  console.log("static::handleFieldChange(): field: " + field + ",  checked: " + checked + ",  value: " + value); //FIXME: don't commit changes to this line

  switch(field) {
    case 'buildBogus':
      //object.setState({buildBogus: checked});      // used for all builders
      window.buildBogusTx = checked;    //NOTE: used in ContractSupport <---- //FIXME: kludgey
      console.log("buildBogusTx: " + window.buildBogusTx);
      object.setState({buildBogus: checked})
      break;
    case 'devTestMode':
      window.devTestMode = checked;
      object.setState({devTestMode : checked});
      console.log("devTestMode: " + window.devTestMode);

      // kludgey way to make ShizzleNav responsive to blocksBeyondMin
      extraObject.setState({blocksBeyondMin: 0})
      break;
    case 'blocksBeyondMin':
      //NOTE: this is kept in ShizzleNav
      //FIXME: enforce max too
      let val = value
      if ( val < 0 ) val = 0;
      object.setState({blocksBeyondMin: val});
      console.log("blocksBeyondMin: " + val);
      break;

    default:
      console.log("FIXME: unrecognized field type: " + field
                + ", with value " + value + ", checked " + checked);
      throw new Error('12000');
  }
}


class ContractOutputsTable extends React.Component {
  constructor(props) {
    super(props);

    this.handleFollowClick = this.handleFollowClick.bind(this);
    this.handleBuildClick = this.handleBuildClick.bind(this);
  }

  handleFollowClick(event, data) {

    //WARNING: kludgy: we're relying on the format of the options content.
    //         We do this because we haven't found a good way to reliably
    //         use onChange() to set a value, and onClick() or MouseDown()
    //         to use it. Instead, we dig deep into event.target to find/infer
    //         the value of the selection.
    const node = event.target.childNodes[0];
    if ( node && node !== null ) {
      var choice = node.data;
      console.log("Follow Option was clicked. event.target.childNodes[0].data: ", choice);
      if ( isNaN(choice) ) {
        //WARNING: this part is even kludgier. The user clicking on <span>s
        //         and <div>s within the menu options content confuse our
        //         choice calculation, above, and force us to take an even
        //         more-complicated approach here.
        choice = event.target.parentNode.parentNode.firstChild.data;
        console.log("whoops. let's use event target parentNode parentNode firstChild data: ", choice)
        if ( !choice ) {
          console.log("NEVERMIND. it was the menu title.")
          return;
        }
      }

      this.props.onFollowClicked(choice);
    }
  }

  handleBuildClick(event, data) {
//    event.preventDefault()

    //WARNING: kludgy: we're relying on the format of the options content.
    //         We do this because we haven't found a good way to reliably
    //         use onChange() to set a value, and onClick() or MouseDown()
    //         to use it. Instead, we dig deep into event.target to find/infer
    //         the value of the selection.
    const node = event.target.childNodes[0];
    if ( node && node !== null ) {
      var choice = node.data;
      console.log("Build Option was clicked " + choice);
      if ( isNaN(choice) ) {
        //WARNING: this part is even kludgier. The user clicking on <span>s
        //         and <div>s within the menu options content confuse our
        //         choice calculation, above, and force us to take an even
        //         more-complicated approach here.
        choice = event.target.parentNode.parentNode.firstChild.data;
        console.log("whoops. let's use event target parentNode parentNode firstChild data: ", choice)
        if ( !choice ) {
          console.log("NEVERMIND. it was the menu title.")
          return;
        }
      }

      // Since we think the  ** ShizzleNav ** should open the modal, let's pass relevant info here, now.
      //   It has the searchEntry, and the dbConnection, so, that's probably a good place to build
      // This eventually reaches the ShizzleNav's handleBuildOnOutput()
      this.props.onBuildOutputSelected(choice);
    }
  }

  render() {  // ContractOutputsTable
    let decodedTx
    if ( this.props !== 'undefined' && this.props !== null && this.props.pdecodedTx && this.props.pdecodedTx !== 'undefined' ) {

      decodedTx = JSON.parse( this.props.pdecodedTx );

      const mode = decodedTx.outputStates[0].mode;
      const isEBFRA = mode === '45' || mode === '65'
      const isDialog = mode === '44'
      const contractType = decodedTx.input0Params.unlockedContract;
      let contractTypeDescription;
      let className;
      //WARNING: we're relying on a string set in shizzleBuilder's getUsefulInputParams() function
      if ( contractType === 'append' ) {
        className = "specific-contract";
        contractTypeDescription = "'Extend'/'Append' transactions (txs) branch out to extend their "
            + "many 'X'-mode limbs. Anyone can extend an Append tx that has available (unspent/un-built) "
            + "'X'-mode limbs - for a fee. Most 'Append' transactions also have a 'P'ublish output that "
            + "can be claimed for publishing - for a fee (if not already spent/claimed).";
      } else if ( contractType === 'continue' ) {
        className = "specific-contract2";
        contractTypeDescription = "'Publish'/'Continue' transactions (txs) are constructed by the owner "
            + "of a given 'Limb'. Only the owner of a limb can control it via private Rabin Key. "
            + "IF you have the key to the latest declared Rabin PKH of a limb, you are the owner, and "
            + "can build on it.";
      } else if ( contractType === 'update' ) {
        className = "specific-contract3";
        contractTypeDescription = "'Update' transactions (txs), spawned from Continue txs, are part of a "
            + "limited-duration line of txs constructed by the owner of a given 'Limb'. Only the owner "
            + "of a limb can control it via a private Rabin Key. IF you have the key to the latest "
            + "declared Rabin PKH of an Update line/branch of a limb, you are the owner, and can build "
            + "on it.";
      } else if ( contractType === 'transient' ) {
        className = "specific-contract6";
        contractTypeDescription = "'Transient' transactions (txs), spawned from Update txs, are part of "
            + "a limited-duration line of txs constructed by the owner of a given 'Limb'. Only the owner "
            + "of a limb can control it via a private Rabin Key. IF you have the key to the latest "
            + "declared Rabin PKH of a Transient line/branch of a limb, you are the owner, and can build "
            + "on it.";
      } else if ( contractType === 'bitgroup' ) {
        className = "specific-contract7";
        contractTypeDescription = "'BitGroup' transactions (txs), spawned from Update txs, are part of a "
            + "limited-duration line of txs constructed by the owner of a given 'Limb'. A BitGroup "
            + "transaction can be built by any member of the BitGroup. Each BitGroup transaction holds "
            + "membership information - an array of Rabin PKHs. IF you have the Private key "
            + "corresponding to one of the declared Rabin PKHs of a BitGroup line/branch of a limb, you "
            + "are a member, and can build on it.";
      } else if ( contractType === 'adminGroup' ) {
        className = "specific-contract8";
        contractTypeDescription = "'AdministerGroup' transactions (txs), are a brief interlude in the "
            + "limited-duration line of BitGroup transactions. An AdministerGroup transaction takes the "
            + "place of a single BitGroup transaction, and allows the owner of the line to add multiple "
            + "members in a single step - by adding to the array of Rabin PKHs, (and associated "
            + "information). An AdministerGroup tx can only be followed by a BitGroup tx. Until the admin "
            + "creates a follow-up BitGroup tx, no other member of the BitGroup can post to the line. "
            + "IF you have the Private Key corresponding to the ownership Rabin PKH of a BitGroup "
            + "line/branch of a limb, you can create an AdministerGroup, and can build on it (add group members).";
      //} else if ( mode === "4e" || mode === '4E' ) { //'N' (Negotiate / sale)
      } else if ( contractType === 'askbid' ) {
        className = "specific-contract4";
        contractTypeDescription = "'Negotiate'/'Sale' transactions (txs) are initiated by the owner "
            + "of a given 'Limb', and form a separate limited-duration line of transactions. Anyone "
            + "can build on it by adhering to the special rules of an auction (of sorts). "
            + "Anyone can place a bid, modify it, or balk, and get a refund. If the owner accepts "
            + "the best bid there is a procedure whereby he can transfer ownership to the highest bidder.";
      } else if ( mode === '65' ) {
        //NOTE: This should normally never get displayed. An EBFRA speaker is an add-on to an append contract.
        //      It should never be the zeroeth output,
        className = "specific-contract9";
        contractTypeDescription = "'EBFRA Speaker' transactions (txs) are setup by a Builder "
            + "of a given fan-out point, and are monitored closely (by descendants) for a special announcement, "
            + "by the builder - regarding a (permanent) reduction in Builder fees. The announcement either provides "
            + "an EBFRA (authorization code), or NOT. An EBFRA can be used by most descendants to reduce fees. "
            + "An EBFRA announcement at a given build-point is a single-shot affair. Once it takes place ('voting' "
            + "either way), the are no further announcements, and the EBFRA line is ended.";
      } else if ( mode === '45' ) {
        className = "specific-contract9b";
        contractTypeDescription = "'EBFRA Announcement' transactions (txs) are initiated by a Builder "
            + "of a given fan-out point, and can empower almost any of its descendant branches to reduce Builder Fees. "
            + "Each builder is able to 'vote' just once to either reduce fees (permanently), or NOT "
            + "reduce fees (permanently) - at its leisure, as it sees fit, given the state of the Bitcoin/BitShizzle "
            + "ecosystem at any given time (price of BSV, miner fees, builder fees). The result of the transaction is a code, "
            + "embedded in the transaction, which can be applied by most descendant asset owner, to their CONTINUE/MAINLINE dangle/tx, to enact a fee reduction.";
      } else if ( mode === '62' ) {
        className = "specific-contract9c";
        contractTypeDescription = "'Builder Prep' transactions (txs) are initiated by BitShizzle "
            + "to setup the initial conditions of the Shizzle Tree.";
      } else if ( mode === '44' ) {
        className = "specific-contract9d";
        contractTypeDescription = "'Dialog' transactions (txs) are initiated by a Transient Owner "
            + "to setup a dialog with another Transient owner (guest-poster).";
      } else {
        className = "specific-contract5";
        console.warn("ContractOutputsTable::render(): contractType is ", contractType)
        console.error("FIXME: add ContractOutputsTable() logic for " + mode);
        contractTypeDescription = "UNRECOGNIZED contract mode: " + hexByteToAscii(mode);
      }

      // Options for two drop-down menus for following, and building on outputs
      const buildOptions  = getMenuOptionsForTxOut(decodedTx, true);
      const followOptions = getMenuOptionsForTxOut(decodedTx, false);
      // Above is where we consider UNSPENDABLE outputs.

      const outputState = decodedTx.outputStates[0]
      const blockNumInt = isEBFRA ? this.props.blockHeight
                              :
                          isDialog ? Buffer.from(decodedTx.input0Params.dialogBlockNum, "hex").readUInt32LE(0)
                              :
                          Buffer.from(outputState.blockNum, "hex").readUInt32LE(0)
      const outputAge = parseInt(this.props.blockHeight) - parseInt(blockNumInt)
      const unspentColumnText = 'Build On Unspent Outputs (' + outputAge + ' blocks old)'

      // Note that the modal has no onClose (for when user clicks away). we close it ourselves, using a button
      //  Used to use: closeOnDimmerClick there too. That's probably what empowered/allowed-triggering the onClose()
      return (<React.Fragment>
                <Grid.Column width={8} className={className}>
                  <div> {contractTypeDescription} </div>
                  <div>
                    <Menu compact>
                      <Dropdown selection open text={unspentColumnText}
                                options={buildOptions}
                                onMouseDown={this.handleBuildClick}
                                style={{background:"lightgrey",fontWeight:"bold",width:"285px"}}/>
                      <Dropdown selection open text='Follow Spent Outputs'
                                options={followOptions}
                                onMouseDown={this.handleFollowClick}
                                style={{background:"lightgrey",width:"285px"}}/>
                    </Menu>
                  </div>
                </Grid.Column>
              </React.Fragment>
              );
    }
    return ( null );
  } // ContractOutputsTable render()
}


class MenuCard extends React.Component {
  constructor(props) {
    super(props)
    this.state = {  displayMenu: false,
                    showKeyExportModal: false,
                    showKeyImportModal: false,
                    showFileUploadModal: false
                  };

    this.handleOption     = this.handleOption.bind(this);
    this.closeKeyExporter = this.closeKeyExporter.bind(this);
    this.closeKeyImporter = this.closeKeyImporter.bind(this);
    this.closeFileUploader= this.closeFileUploader.bind(this);
    this.handleChosenFile = this.handleChosenFile.bind(this);
  }

  async handleOption(event) {
    console.log("Option button: jump, list, etc pressed. passing it along..." + event.target.value);
    let db
    if ( event.target.value.trim() === 'list' ) {
      console.log("Option button: will list txids...");
      db = await openDB();
      await queryTxs(db);
    } else if ( event.target.value.trim() === 'listLabels' ) {
      console.log("Option button: will list labels of content...");
      db = await openDB();
      await queryLabels(db);
    } else if ( event.target.value.trim() === 'dangles' ) {
      console.log("Option button: will list and re-query dangling paths...");
      db = await openDB();
      await checkDanglingPaths(db);
    } else if ( event.target.value.trim() === 'bulk' ) {
      await testGetBulkUnspent()
    } else if ( event.target.value.trim() === 'export' ) {
      this.setState({showKeyExportModal: true})
    } else if ( event.target.value.trim() === 'import' ) {
      this.setState({showKeyImportModal: true})
    } else if ( event.target.value.trim() === 'fupload' ) {
      this.setState({showFileUploadModal: true})
    } else if ( event.target.value.trim() === 'axios' ) {
      //this.setState({showFileUploadModal: true})
      console.warn("TEST THING...")
      testThing()
    } else {
      console.log("Option button: jump, or build pressed. passing it along..."+event.target.value);
      this.props.onOptionChosen(event.target.value);
    }
  }

  closeKeyExporter() {
    this.setState({showKeyExportModal: false})
  }

  closeKeyImporter() {
    this.setState({showKeyImportModal: false})
  }

  closeFileUploader() {
    console.log('Closing File Uploader modal...')

    this.setState({showFileUploadModal: false})
  }

  handleChosenFile(fileName, content) {
    console.warn("HERE is where we could DO something with " + content.length + " bytes of chosen local file " + fileName)
  }

  render() {  // MenuCard
    const parentPlaceH = document.getElementById('parentContentPlaceHolder')
    const bytesInPlaceholder = parentPlaceH === null ? 0 : parentPlaceH.innerHTML.length

    const keyExportModal = this.state.showKeyExportModal ?
                                <KeyExporter closeKeyExportModal={this.closeKeyExporter}/>
                                : null;
    const keyImportModal = this.state.showKeyImportModal ?
                                <KeyImporter closeKeyImportModal={this.closeKeyImporter}/>
                                : null;
    const FileUploadModal = this.state.showFileUploadModal ?
                                <FileChooserReader  closeChooserModal={this.closeFileUploader}
                                                    pplaceholder={ parentPlaceH }
                                                    callback={this.handleChosenFile}/>
                                : null;

    // send bbm value changes to the parent (ShizzleNav)
    //FIXME: limit this, somehow, to actual blockHeight minus minimal minus 1
    const extraBlockHeightOption =
              this.state.devTestMode ?
                                      <> &nbsp; &nbsp; &nbsp; blocksBeyondMinimal:
                                        <Input field="blocksBeyondMin" type="number" min="0" max="27000"
                                                value={this.props.parentSNav.state.blocksBeyondMin}
                                                onChange={ (event, data) => handleFieldChange(event, data, this.props.parentSNav) }
                                        />
                                      </> : null

    //<li> <ShizzleTester /> (for testing)</li>
    return (
      <div>
              <ul>
                <li><button  value="jump"       onClick={this.handleOption}> Jump to an Existing Transaction </button></li>
                <li><button  value="visit"       onClick={this.handleOption}> Visit a registered domain </button></li>
                <br></br>
                <li> <button value="axios"      onClick={this.handleOption}> Try vanilla axios request</button> (for testing)</li>
                <li><button  value="list"       onClick={this.handleOption}> List unconfirmed spends, and bogus Txs </button> (for testing)</li>
                <li><button  value="listLabels" onClick={this.handleOption}> List labeled content entries </button> (for testing)</li>
                <li><button  value="build"      onClick={this.handleOption}> Build a Locking Transaction </button> (for testing)</li>
                <li><button  value="dangles"    onClick={this.handleOption}> Check on Dangling Paths </button> (for testing)</li>
                <li> <GenericModal title='WARNING'
                                  mainText='This is the main text'
                                  openButton='Test Generic Button'
                                  /> test generic modal </li>
                <li><button  value="bulk"       onClick={this.handleOption}> Test Get Bulk Unspents </button> (for testing)</li>
                <br></br>
                <li><button  value="export"     onClick={this.handleOption}> Export encrypted Rabin keys </button></li>
                <li><button  value="import"     onClick={this.handleOption}> Import encrypted Rabin keys </button></li>
                <li><button  value="fupload"    onClick={this.handleOption}> Upload file </button></li>
              </ul>
              <pre id="parentContentPlaceHolder" style={{display:'none'}}></pre>
              There are {bytesInPlaceholder} bytes in parent placeholder
              <div></div>
              <div> For content-testing purposes, you could optionally build bogus transactions, which are entered into the
                    browser database, but aren't broadcast. </div>
              <Radio toggle label='Build bogus transactions' checked={window.buildBogusTx}
                                      field='buildBogus' onChange={ (event, data) => handleFieldChange(event, data, this) }/>
              <div> For dev/testing purposes, you may decide to advance minBlock as little as possible </div>
              <Radio toggle label='Build with minimal blockHeight advancement (not fully implemented)' checked={this.state.devTestMode}
                                      field='devTestMode' onChange={ (event, data) => handleFieldChange(event, data, this, this.props.parentSNav) }/>
              {extraBlockHeightOption}
              {keyExportModal}
              {keyImportModal}
              {FileUploadModal}
      </div>
    );
  } // MenuCard render()
}


// This is where we present some decoded content of a transaction,
// and links to its outputs.
//FIXME: rename   TxSummary
class TxStatsDisplay extends React.Component {
  constructor(props) {
    super(props);

    this.testPopUp              = this.testPopUp.bind(this)
  }

  testPopUp() {
    console.error("OPENING A POP-UP")
    const output = `Text commentary preface
    <iframe width="360" height="270" src="https://www.youtube.com/embed/UnhfClpogf8"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer;
              autoplay;
              encrypted-media;
              gyroscope;
              picture-in-picture"
          allowfullscreen></iframe>
    Text commentary later
    `
    var w = window.open('', '', 'width=375,height=305,resizeable,scrollbars').document.write(output);
    //var w = window.open('', '', 'width=400,height=400,resizeable,scrollbars');
    //w.document.write('Content goes here');
    //w.document.close();
  }

  render() {  // TxStatsDisplay
    let txState;
    let namePath = null;
    let dialogGuestName = null;  // for dialogs
    let dialogSenderMention = null;
    let guestNamePath = null
    let contract = null;
    let shizzleAddress = null;
    let guestShizzleAddress = null;
    let blockNum = null;
    let guestBlockNum = null;
    let deadline = null;
    let guestDeadline = null
    const entryMade = this.props.txToDisplay !== ''; // if we've been here already
    let wocLink = null;
    let loading = false;
    let commentOnBogusTx = null;
    let swipeText = null;
    let extraContractOuts = null
    let extraContractOutsDescription = null
    let auctionExtra = ''
    let ebfraAnnouncement = ''

    if ( this.props.txStateToPresent ) {
      const wocUrl = "https://classic-test.whatsonchain.com/tx/" + this.props.txToDisplay;
      // avoid tab-napping
      // see: https://stackoverflow.com/questions/17711146/how-to-open-link-in-new-tab-on-html#17711167
      wocLink = <a href={wocUrl} target="_blank" rel="noopener noreferrer" >WoC Link</a>;
      if ( this.props.txToDisplay.startsWith('11111111111111111111111111111111') ) {
        commentOnBogusTx = <><br></br>Note: this is a 'BOGUS' tx (hasn't been broadcast)</>
      }

      txState = JSON.parse( this.props.txStateToPresent );
      const isAnEBFRA = txState.outputStates[0].mode === '45' || txState.outputStates[0].mode === '65'
      const isDialog = txState.outputStates[0].mode === '44'

      dialogSenderMention = isDialog ?
                              <div>Sent by {txState.outputStates[0].sentBy}</div>
                          :
                              null
      namePath = isDialog ?
                    <>OwnerName:  <b>{txState.outputStates[0].ownerName}</b></>
                  :
                    <>Limb:          <b> { txState.limbName } </b> </>
      dialogGuestName = isDialog ?
                    <>VisitorName:  <b>{txState.outputStates[0].visitorName}</b></>
                  :
                    null
      contract = "Contract type: " + txState.input0Params.unlockedContract;
      shizzleAddress = txState.outputStates[0].address

      if ( txState.input0Params.hostGuestMode === 1 || txState.input0Params.hostGuestMode === 4 ) {
        console.warn("This is hosting a post")
        guestNamePath = <>GUEST limb: <b> { hexStringToAscii( txState.outputStates[1].namePath ) } </b> </>
        guestShizzleAddress = "GUEST address: " + txState.outputStates[1].address

        const guestBlockNumInt = Buffer.from(txState.outputStates[1].blockNum, "hex").readUInt32LE(0)
        guestBlockNum = "GUEST Min BlockNum: " + guestBlockNumInt;

        console.log("here's the GUEST downCounter: ", txState.outputStates[1].downCounter)
        const guestDeadlineInt = Buffer.from(txState.outputStates[1].downCounter, "hex").readUInt8(0);
        guestDeadline = "Posts until GUEST Transient deadline (from this point): " + guestDeadlineInt
      }

      //FIXME: define ebfraSpeaker in harnessConstants.js
      if ( !shizzleAddress || shizzleAddress.endsWith('/ebfraSpeaker') || shizzleAddress.endsWith('/ebfraAnnounce')) {
        console.log("last-minute calculation of shizzleAddress (it was " + shizzleAddress + ")")
        console.log(" STATE: ", this.props.txStateToPresent)


        if ( txState.outputStates[0].mode == '45' ) {
          namePath = "Limb:          " + txState.limbName; //"Limb: N/A  (EBFRA)"
          if ( txState.outputStates[0].builderPubKeyLen === 0 ) {
            ebfraAnnouncement = <>
                                  This is a Builder announcement. The builder decided to NOT reduce fees.
                                </>
          } else {
            ebfraAnnouncement = <>This is an Emergency Builder Fee-Reduction Authorization (EBFRA).
                              It contains an authcode that may be used
                              by descendants - to lower their builder fees.
                              <p></p>
                                <p> <b>Builder Signature:</b> {txState.outputStates[0].builderSigHexLE}           </p>
                                <p> <b>Message Padding Length:</b> {txState.outputStates[0].builderSigPaddingLen} </p>
                                <p> <b>Builder Pub Key:</b> {txState.outputStates[0].builderRabinPubKeyHexLE}     </p>
                              </>
          }
        } else if ( txState.outputStates[0].mode == '65' ) {
//FIXME: remove?
          console.error("Not sure why we found an ebfraSpeaker at output 0")
          alert("We're probably testing. so what?")
        } else {
          shizzleAddress = "MAYBE STALE CACHE OF blank ADDRESS?"
        }
      }

      const blockNumLE = txState.outputStates[0].blockNum;
      const blockNumInt = (isAnEBFRA || isDialog) ? 'NA' : Buffer.from(blockNumLE, "hex").readUInt32LE(0)
//FIXME: blockNum from INPUT
      blockNum = "Min BlockNum: " + blockNumInt;

      const mode = hexByteToAscii( txState.outputStates[0].mode )
      let deadlineInt = null
      switch ( mode ) {
        case 'p':
        case 'K':
          deadlineInt = Buffer.from(txState.outputStates[0].renewalDeadline, "hex").readUInt32LE(0);
          deadline = "Blocks until Publish deadline (from this point): " + (deadlineInt - blockNumInt)
          break;
        case 'U':
          deadlineInt = Buffer.from(txState.outputStates[0].deadline, "hex").readUInt32LE(0);
          deadline = "Blocks until Update deadline (from this point): " + (deadlineInt - blockNumInt)
          break;
        case '0':
        case '1': case '5':
        case '2': case '6':
        case '3': case '7':
        case '4': case '8':
        case '9':
          console.log("here's the downCounter: ", txState.outputStates[0].downCounter)
          deadlineInt = Buffer.from(txState.outputStates[0].downCounter, "hex").readUInt8(0);
          deadline = "Posts until Transient deadline (from this point): " + deadlineInt
          break;
        case 'G':  // BitGroup
        case 'g':  // AdminGroup
          deadlineInt = txState.outputStates[0].maxBlock
          deadline = "Blocks until BitGroup deadline (from this point): " + (deadlineInt - blockNumInt)
          break;
        case 'P':
        case 'X':
          // These have no 'deadline'
          break;
        case 'N':  //'N' askBid (negotiate price - auction)
          const auctionState = txState.outputStates[0]
          deadlineInt = auctionState.deadlineBlock
          let blocksUntil = deadlineInt - blockNumInt
          const numBids = auctionState.numBids
          if ( blocksUntil > 0 ) {
            auctionExtra = ' There are ' + numBids + ' bids. '
            if ( auctionState.numBids > 0 ) {
              console.log("best bid: ", auctionState.bidEntries[auctionState.bestBidNum])
              auctionExtra += ('best: ' + auctionState.bidEntries[auctionState.bestBidNum].price)
            }
          }

          deadline = "Blocks until auction ends: " + (deadlineInt - blockNumInt)
          break;
        case 'e':
          console.warn("txStatsDisplay:: render(): EBFRA Speaker state: ", txState.outputStates[0])
          break;
        case 'E':
          console.warn("txStatsDisplay:: render(): EBFRA Announcement state: ", txState.outputStates[0])
          break;
        case 'b':
          console.warn("txStatsDisplay:: render(): Builder Prep state: ", txState.outputStates[0])
          break;
        case 'D':
          console.warn("txStatsDisplay:: render(): Dialog state: ", txState.outputStates[0])
          deadline = "Block when Dialog bounty can be claimed: " + txState.outputStates[0].maxBlock
          break;
        default:
          console.error("TxStatsDisplay render(): implement mode " + mode)
          deadline = "deadline? IMPLEMENT mode " + mode
          break;
      }

      const numContractOuts = txState.outputStates.length
      if ( numContractOuts > 1 ) {
        console.log("There is more than 1 contract output: " + numContractOuts)
        const finalOutputMode = hexByteToAscii(txState.outputStates[numContractOuts - 1].mode)
        //FIXME: is this particular bit needed?
        extraContractOuts = "Final output (index " + (numContractOuts - 1) + ") mode: "
                            + finalOutputMode
        if ( finalOutputMode === 'N' ) {
          extraContractOuts += " (sale/auction)"
          const subMode = hexByteToAscii(txState.outputStates[numContractOuts - 1].subMode)
          let subModeDescription = null
          switch(subMode) {
            case 'N':
              subModeDescription = "(Not yet open)"
              break;
            case 'O':
              subModeDescription = "(Open for bids)"
              break;
            case 'W':
              subModeDescription = "(Waiting for permission to execute sale)"
              break;
            case 'G':
              subModeDescription = "(permission Granted to execute)"
              break;
            case 'e':
              subModeDescription = "(sale executed, but refunds pending)"
              break;
            case 'E':
              subModeDescription = "(sale fully Executed)"
              break;
            case 'c':
              subModeDescription = "(sale cancelled, but refunds pending)"
              break;
            case 'C':
              subModeDescription = "(sale fully Cancelled)"
              break;
            default:
              console.error("Invalid auction subMode: " + subMode)
              throw new Error("16802: invalid auction subMode: " + subMode)
              break;
          }

          extraContractOutsDescription = <> &nbsp; sales subMode: {subMode} {subModeDescription} </>

          //FIXME: handle/display each possible subMode:
          //    N: not yet open
          //    O: open
          //    W: waiting for permission to sweep funds from buyer
          //    G: permission Granted (to execute sale - take money)
          //    e: sale executed, but refunds pending
          //    E: sale fully executed (refunds processed)
          //    c: canceled, refunds pending
          //    C: fully closed/canceled (refunds processed)
        }
      }


      //console.log("TxStatsDisplay::render(): state: ", txState);
    } else {
      loading = entryMade;
    }

    //FIXME: screwy use of ShizzleNav's state blocksBeyondMin
    return (
      <React.Fragment>
        <Grid.Column width={6} className="tx-stats-display">
          <div className="txid-display">
            {this.props.txToDisplay} &nbsp; {wocLink}{commentOnBogusTx}
          </div>
          {entryMade && <div className="ui divider"></div>}
          {loading && <div className="ui active inline loader">Retrieving transaction...</div>}
          {namePath} &nbsp; &nbsp; {guestNamePath}{dialogGuestName}
          <div>
          <div> Host Address: {shizzleAddress} </div>
          </div>
          <div>
          {guestShizzleAddress}
          </div>
          <div>
              { contract }
              { dialogSenderMention }
          </div> <div>
              { extraContractOuts }
          </div><div>
              { extraContractOutsDescription }
          </div>
          { blockNum }
          <div>{guestBlockNum}</div>
          <div> { deadline } </div>
          <div> { guestDeadline } </div>
          <div> devMode setting:
            <span style={{color: 'red'}}>
              { window.devTestMode ? " blocksBeyondMin: " + getShizzleNav().state.blocksBeyondMin : " NONE"}
            </span>
          </div>
          &nbsp;
          <div> Current block height: {this.props.blockHeight}</div>
          <div> {this.props.swipeText} </div>
          <Divider />
          <div> { auctionExtra } </div>
          <div> {ebfraAnnouncement} </div>

          <div> {entryMade && !loading && <div className="ui divider"></div>}
          </div>
        </Grid.Column>
        <ContractOutputsTable pdecodedTx={this.props.txStateToPresent}
                               onFollowClicked={this.props.onTxToFollow}
                               blockHeight={this.props.blockHeight}
                               onBuildOutputSelected={this.props.onBuildOutSelected}
                               />
      </React.Fragment>
    );
  } // TxStatsDisplay render()
}

class TxSearchBar extends React.Component {
  constructor(props) {
    super(props);
    this.handleSearchBarChange = this.handleSearchBarChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
  }

  //FIXME: WARNING: we allow entering an arbitrary txid which
  //       MAY or MAY NOT be part of the shizzle tree.
  //       We then allow it to be entered into the IDB
  handleSearchBarChange(event) {
    //console.log("CHECKING FOR HEX PATTERN on search bar value")

    // check for hex pattern
    const re = /^[a-fA-F0-9]+$/;
    //console.log("input: " + event.target.value)
    if ( event.target.value === '' || re.test(event.target.value)) {
      this.props.onSearchChange(event.target.value);
    }
  }

  handleSubmit(event) {
    console.log("handleSubmit...")
    this.props.onSearchSubmitted(event);
    event.preventDefault();
  }

  handleDelete(event) {
    console.log("handleDelete...")
    this.props.onDeleteSubmitted(event);
    event.preventDefault();
  }

  // <input type="text" value={this.props.value} className="tx-input-field" onChange={this.handleChange} />

  render() {
    const popupLen = this.props.value.length
    const popupContent = 'A Transaction Id needs to be a 64-character hexadecimal number (currently '
            + popupLen + ')'
    const popupDisabled = popupLen === 64;
    return (
      <div>
        <form >
          <label name='searchLabel' className="tx-to-find">
            Examine Transaction:
            <Input placeholder='Enter a tx id (64-character, hexadecimal)'
                  value={this.props.value}
                  className="tx-input-field" onChange={this.handleSearchBarChange} />
          </label>
          <Popup content={popupContent}
              disabled={popupDisabled}
              trigger={
                <Button type="submit" content="Fetch & Decode" onClick={this.handleSubmit} />
              }
          />
          <div> </div>
          <div>For testing:</div>
          <Popup content={popupContent}
              disabled={popupDisabled}
              trigger={
                <Button type="submit" content="Delete spends" onClick={this.handleDelete} />
              }
          />
        </form>
      </div>
    );
  } //TxSearchBar render()
}

class TxBuildPane extends React.Component {
  constructor(props) {
    super(props);
    this.state = { type: this.props.type, alreadyBuilt: false };
    this.buildLockingTx = this.buildLockingTx.bind(this);
  }

  async componentDidMount() {
    console.log("TxBuildPane: OPENING DB  <-------");

    //FIXME: can/should this be const?
    var db = await openDB();
    console.log("TxBuildPane: SETTING DB to state <-------");

    this.setState({dbConnection: db});
    console.log("TxBuildPane: DB is SET to state <-------");
  }

  //FIXME: allow to build multiple locking txs
  async buildLockingTx() {
    if ( !this.state.alreadyBuilt ) {
      let txid
      try {
        txid = await buildShizzleLockingTx(this.state.dbConnection, this.state.type );
      } catch (error) {
        console.log("TxBuildPane:buildLockingTx(): Failed building a locking tx of type "
            + this.state.type);
        console.error("ERROR: ", error);

        printError(error)

        return;
      }

      console.log("TxBuildPane:buildLockingTx(): BUILT. txid: " + txid);
      this.setState({alreadyBuilt: true});
      this.setState({txid: txid});

    } else {
      console.log("TxBuildPane:buildLockingTx(): IGNORING. Already built.");
    }
  }

  render() {
    return (
      <div>
        <div> Selected Contract type: {this.state.type} </div>
        <button onClick={this.buildLockingTx} >
          Build {this.state.type} contract
        </button>
        <div> Built tx: {this.state.txid} </div>
      </div>
    );
  } // TxBuildPane render()
}

class LockingScriptOptions extends React.Component {
  constructor(props) {
    super(props)
    this.state = { displayMenu: false, lockingScriptSelected: "Append", };
    this.handleLockingScriptSelection = this.handleLockingScriptSelection.bind(this);
    this.formSubmit = this.formSubmit.bind(this);
  }

  handleLockingScriptSelection(event) {
    //console.log("RADIO BUTTON pressed: " + event.target.value);
    this.setState({ lockingScriptSelected: event.target.value });
  }

  formSubmit(event) {
    console.log("FORM SUBMITTED: " + this.state.lockingScriptSelected);
    this.props.onBuildTypeChosen(this.state.lockingScriptSelected);

    // avoid reloading page
    event.preventDefault();
  }

  render() {
    return (
      <form onSubmit={this.formSubmit} className="build-locking-form">
        <div className="radio">
          <label>
            <input type="radio" value="BuilderPrep" name="contractType"
              onChange={this.handleLockingScriptSelection}
              checked={this.state.lockingScriptSelected === "BuilderPrep"}
            /> BuilderPrep
          </label>
        </div>
        <div className="radio">
          <label>
            <input type="radio" value="Append" name="contractType"
              onChange={this.handleLockingScriptSelection}
              checked={this.state.lockingScriptSelected === "Append"}
            /> Append
          </label>
        </div>
        <div className="radio">
          <label>
            <input type="radio" value="Ebfra" name="contractType"
              onChange={this.handleLockingScriptSelection}
              checked={this.state.lockingScriptSelected === "Ebfra"}
            /> EBFRA Speaker
          </label>
        </div>
        <div className="radio">
          <label>
            <input type="radio" value="Continue" name="contractType"
              onChange={this.handleLockingScriptSelection}
              checked={this.state.lockingScriptSelected === "Continue"}
            /> Continue
          </label>
        </div>
        <div className="radio">
          <label>
            <input type="radio" value="Update" name="contractType"
              onChange={this.handleLockingScriptSelection}
              checked={this.state.lockingScriptSelected === "Update"}
            /> Update
          </label>
        </div>
        <div className="radio">
          <label>
            <input type="radio" value="Transient" name="contractType"
              onChange={this.handleLockingScriptSelection}
              checked={this.state.lockingScriptSelected === "Transient"}
            /> Transient
          </label>
        </div>
        <div className="radio">
          <label>
            <input type="radio" value="Dialog" name="contractType"
              onChange={this.handleLockingScriptSelection}
              checked={this.state.lockingScriptSelected === "Dialog"}
            /> Dialog
          </label>
        </div>
        <div className="radio">
          <label>
            <input type="radio" value="Sales" name="contractType"
              onChange={this.handleLockingScriptSelection}
              checked={this.state.lockingScriptSelected === "Sales"}
            /> Sales
          </label>
        </div>
        <div className="radio">
          <label>
            <input type="radio" value="BitGroup" name="contractType"
              onChange={this.handleLockingScriptSelection}
              checked={this.state.lockingScriptSelected === "BitGroup"}
            /> BitGroup
          </label>
        </div>
        <div className="radio">
          <label>
            <input type="radio" value="Administer" name="contractType"
              onChange={this.handleLockingScriptSelection}
              checked={this.state.lockingScriptSelected === "Administer"}
            /> Administer
          </label>
        </div>
        <button className="btn btn-default" type="submit">
          Submit
        </button>
      </form>
    );
  } // LockingScriptOptions render()
}

export { //ContractOutputsTable,  used only by TxStatsDisplay
        MenuCard,
        TxStatsDisplay,
        TxSearchBar,
        TxBuildPane,
        LockingScriptOptions };