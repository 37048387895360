import React from 'react';

import { Button, Checkbox, Container, Divider, Dropdown, Form, Grid,
    Input, Menu, Message, Modal, Popup, Radio, TextArea } from 'semantic-ui-react'

import {
    saveEncryptedKeys,
    getEncryptedKeysFromPKH,
    openDB,
} from './buildShizzle.js';

class KeyImporter extends React.Component {
  constructor(props) {
    super(props);

    this.inspectContents = this.inspectContents.bind(this);
    this.fileReaderFunc = this.fileReaderFunc.bind(this)

    this.state = {enableInspectButton: false, numItemsToImport: ' ', invalidImportFile: false};
  }

  componentWillUnmount() {
    //alert("REMOVING listener...")
    document.getElementById('inputFileHolder').removeEventListener('change', this.fileReaderFunc);
  }

  componentDidMount() {
    this.setState({enableInspectButton: false, numItemsToImport: ' ', invalidImportFile: false});

    // if function wasn't of this class
    //this.fileReaderFunc = fileReaderFunc.bind(this)

    //alert("ADDING listener...")
    document.getElementById('inputFileHolder').addEventListener('change', this.fileReaderFunc);
  }

  fileReaderFunc(e) {
    const parent = e.currentTarget
    const myKeyImporter = this
    const fr = new FileReader();

    fr.onload=function(){
        document.getElementById('contentPlaceHolder').textContent = fr.result;
        const jsonString = document.getElementById('contentPlaceHolder').innerHTML;
        console.log("placed content of length " + jsonString.length);
        try {
          const contents = JSON.parse(jsonString)
          console.log("number of elements: ", contents.length);
          myKeyImporter.setState({enableInspectButton: true, numItemsToImport: (' ' + contents.length)});
          myKeyImporter.setState({invalidImportFile: false});
        } catch (error) {
          console.log("invalid encrypted-key import file: ", error);
          myKeyImporter.setState({invalidImportFile: true});
        }
    }

    console.warn("KeyImporter: cDM(): BTW: parent.files[]: ", parent.files)

    fr.readAsText(parent.files[0]);
  }

  async inspectContents() {
    const jsonContentString = document.getElementById('contentPlaceHolder').innerHTML;
    console.log("here is the content string: ", jsonContentString)
    const contents = JSON.parse(jsonContentString)
    console.log("number of elements: ", contents.length);
    let eachHasPkh = true;
    let eachHashBlob = true;

    let i = 0;
    contents.forEach(element => {
      console.log("element.pkh: ", element.pkh);
      console.log("element.blob: ", element.blob);
      if ( !element.pkh ) {
        console.log("There is no pkh for " + i);
        eachHasPkh = false;
      }
      if ( !element.blob ) {
        console.log("There is no blob for " + i);
        eachHashBlob = false;
      }
      i++;
    });
    console.log("There are " + i + " elements")

    if ( eachHasPkh && eachHashBlob ) {
      console.log("suitable. will import " + i + " entries");
      var db = await openDB();

      let successfulImports = 0;
      for ( var j = 0; j < i; j++ ) {
        const pkh = contents[j].pkh;

        // does this pkh entry already have a record? Don't overwrite
        let x = await getEncryptedKeysFromPKH(db, pkh);
        console.log("record for given pkh (", pkh, "): ", x);

        if ( x && x !== null ) {
          console.log("There's already an entry for pkh " + pkh + ". SKIPPING");
        } else {
          console.log("IMPORTING record for pkh " + pkh);
          saveEncryptedKeys(db, pkh, contents[j].blob);
          successfulImports++;
        }
      };

      console.log("Imported " + successfulImports + " of " + i + " rabin keys");
    } else {
      console.log("Since not all entries had all the required fields, we won't import the contents of this file.")
    }

    this.props.closeKeyImportModal();
  }

  render() {

    //FIXME: future: indicate how many keys are read

    //    early attempts
    //    <Button field="import" onClick={this.handleImport} positive>Import Password-Encrypted Keys</Button>
    //    <input type="file" accept=".txt" onChange={this.handleFileImport} />

    //FIXME: style the input button: https://www.w3docs.com/tools/code-editor/6961
    //       style, AND hide: https://stackoverflow.com/questions/36540105/how-to-style-a-choose-file-button-using-css-only

    const keyWord = this.state.numItemsToImport === ' 1' ? 'key' : 'keys';
    const importButtonLabel = this.state.invalidImportFile ?
                                  "IMPORT keys from this file"
                                :
                                  "IMPORT" + this.state.numItemsToImport + " " + keyWord + " from this file";
    const invalidWarning = this.state.invalidImportFile ?
                            <div style={{color: 'red'}}>Invalid import file</div>
                            : null;

    return <>
              <Modal open={true} size='small' dimmer='blurring'>
                <Modal.Header>IMPORT Encrypted Rabin Private Keys</Modal.Header>
                <Modal.Content>
                  These keys should have been encrypted with a password you provided.
                  <p></p>
                  <input type="file" name="inputFileHolder" id="inputFileHolder" />
                  <Button field="inspect" disabled={!this.state.enableInspectButton || this.state.invalidImportFile} onClick={this.inspectContents} positive>{importButtonLabel}</Button>
                  <Button field="cancelButton" onClick={this.props.closeKeyImportModal} negative>CANCEL</Button>
                  <p></p>
                  {invalidWarning}
                  <pre id="contentPlaceHolder" style={{display:'none'}}></pre>
                </Modal.Content>
              </Modal>
          </>
  }
} // KeyImporter

export default KeyImporter;