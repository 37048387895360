import React from 'react';

import { Button, Divider, Icon, List, Modal, Table } from 'semantic-ui-react'

import Toast from './toast';
import {
      bsv,
} from 'scryptlib' ;

import {
    saveEncryptedKeys,
    //getEncryptedKeysFromPKH,
    getP2PKHsJson,
    openDB,

    readSetting,
    saveSetting,

    saveEncryptedP2PKHKey,
    generateRabinPrivateKeyPlus,

    encryptData,
    decryptData,
    getRabinsJson,
    getOfficialWalletJson,
    findAllDomainsOfMine,

    getAvailableRabinsCount,
    getAvailableP2PKHsCount,

    findAllMyBuiltDomains,
} from './buildShizzle.js';

import { NETWORK } from './harnessConstants';
import { buildConsolidatingTx } from './contractSupport.js';

const { fetchUtxos } = require('./providers.js')

class AssetManager extends React.Component {
  constructor(props) {
    super(props);

    this.bshzPurple             = '#8313e2';
    this.bshzYellow             = "#fff300";
    this.bshzLtPurp             = '#e7d0fb';
    this.bshzLightYellow        = "#fffccc";

    this.state =  {
        showSwirly: false,
        showBackupAdviceModal: false,

        showSinglePayoutModal: false,
        singlePayoutTitle: null,
        singlePayoutInfo: null,

        chosenBuilderAsset: '',
        payoutPKH: null,
        payoutAddr: null,
        payoutUtxos: null,
        totalPayoutSats: 0,

        showSweeperToast: false,

        payoutAssetList: null,
        knownTotalSats: 0,      // un-swept, across all assets. FIXME: rename to totalUnsweptPayoutSats?
        knownTotalCoins: 0,
        knownTotalSweptSats: 0, // swept, across all assets. FIXME: rename to totalSweptPayoutSats?
    };

    this.addMoreRabins     = this.addMoreRabins.bind(this);
    this.addMoreRabinsB    = this.addMoreRabinsB.bind(this);
    this.addMorePayoutKeys = this.addMorePayoutKeys.bind(this);
    this.closeAdviceModal  = this.closeAdviceModal.bind(this);

    this.buildPayoutAssetList = this.buildPayoutAssetList.bind(this);
    this.payoutChosen         = this.payoutChosen.bind(this);

    this.sweepPayouts           = this.sweepPayouts.bind(this);
    this.sweeperToastDone       = this.sweeperToastDone.bind(this);

    this.closeSinglePayoutModal = this.closeSinglePayoutModal.bind(this);

    this.getPersistedPayoutScanData = this.getPersistedPayoutScanData.bind(this);
    this.updatePersistedPayoutInfo  = this.updatePersistedPayoutInfo.bind(this);
    this.getPersistedPayoutScanObject = this.getPersistedPayoutScanObject.bind(this);
    this.persistPayoutScanData      = this.persistPayoutScanData.bind(this);

    this.satsToCentsJSX             = this.satsToCentsJSX.bind(this);

    this.penniesPerSat = this.props.bsvPriceUSD / 1000000
  }

  satsToCentsJSX(sats) {
    return <>(approx <span style={{color:'blue'}}>{ Math.round( sats * this.penniesPerSat *10)/10 }¢</span> (USD))</>
  }

  async addMoreRabins() {
    this.setState({showSwirly: true, swirlyText: 'Adding more encrypted publisher keys to your wallet, then saving them to your browser...'},
                  this.addMoreRabinsB
    )
  }

  //NOTE: essentially copied from WalletGenerator
  async addMoreRabinsB() {
    const db = await openDB();

    const NUM_RABINS_TO_GEN = 50

    let rabPool = []
    // These will be used for claiming, and publishing
    // claim, pub quarterly, pub update/periodical, pub transients
    for (let i = 0; i < NUM_RABINS_TO_GEN; i++) {
        console.log("generating rabin (publisher) keys " + i)
        const rabinPlus = generateRabinPrivateKeyPlus();

        //console.log("  generated: p = ", rabinPlus.p)
        //console.log("  generated: q = ", rabinPlus.q)
        console.log("  rabinPKH: ", rabinPlus.rabinPKH);

        //console.log("pubkeyBI: ", rabinPlus.rabinPubkeyBI)
        //console.log("pubkeyHex: ", rabinPlus.rabinPubkeyLE)
        rabPool.push({ p: rabinPlus.p, q: rabinPlus.q, pkh: rabinPlus.rabinPKH })
    }

    let progress = 0
    let added = 0
    try {
      //var db = await openDB();
      progress = 1

      // Publisher key pool
      const retrievedRabPool = rabPool
      console.warn("retrieved rabPool: ", retrievedRabPool)
      for ( let i = 0; i < retrievedRabPool.length; i++) {
          const thisRec = retrievedRabPool[i]
          //console.log("save wallet:  rabin pool key " + i + ": ", thisRec, "   <---")
          //console.log("              rabin pool key p: ", thisRec.p)
          //console.log("              rabin pool key q: ", thisRec.q)
          console.log("              rabin pool PKH: ", thisRec.pkh)

          // this is necessary - to serialize a BigInt
          const record = {p: thisRec.p.toString(), q: thisRec.q.toString()} //, pkh: thisKey.pkh}
          const recordJSON = JSON.stringify(record);
          //const recordJSON = JSON.stringify(thisRec);
          console.log("stringified")
          const rabinPKH = thisRec.pkh

          //FIXME: remove pkh from the plainText we're about to encrypt into a 'blob'?

          let cyphText
          try {
            cyphText = await encryptData(recordJSON, this.props.bundle.pwd);
          } catch (error) {
            this.setState({showSwirly: false})
            alert("Please make sure you're using https://")
            throw error
          }
          console.log("cypher text is ", cyphText);

          progress++

          // save to PUBLISHER rabin table (default variant), unallocated (0)
          await saveEncryptedKeys(db, rabinPKH, cyphText, null, 0);
          progress++
          added++
      }

      console.warn("we've saved your NEW encrypted keys")
      this.setState({showAddedSuccess: true})
    } catch (error) {
      alert("We had trouble adding more rabins. Progress = " + progress
            + "  while aiming for 1+2*" + NUM_RABINS_TO_GEN)
    }

    const freeRabinsCount = await getAvailableRabinsCount(db)

    this.setState({showSwirly: false,
                   freeRabins: freeRabinsCount,
                   numPubKeys: this.state.numPubKeys + added,
                   showBackupAdviceModal: true,
                  })
  }

  //NOTE: essentially copied from WalletGenerator
  async addMorePayoutKeys() {
    this.setState({showSwirly: true,
                   swirlyText: 'Adding more encrypted payout keys to your wallet, then saving them to your browser...'})

    const NUM_P2PKHS_TO_GEN = 40

    let p2Pool = []
    // These will be used for declaring any builder payouts - when building
    // (and eventually to collect the builder payout crumbs)
    for (let i = 0; i < NUM_P2PKHS_TO_GEN; i++) {
        console.log("generating " + NETWORK + " PAYOUT privKey " + i)
        const newPrivKey = bsv.PrivateKey.fromRandom( NETWORK )
        console.log("  address: " + newPrivKey.toAddress())

        p2Pool.push({p: newPrivKey.toWIF(), address: newPrivKey.toAddress().toString()})
    }

    let progress = 0
    let added = 0
    const db = await openDB();
    try {
      progress++

      const recordNetworkCharacter = NETWORK === "testnet" ?
                                      't' : 'm'

      // Builder payout pool
      const retrievedPayoutPool = p2Pool
      console.warn("retrieved payouts pool: ", retrievedPayoutPool)
      for ( let i = 0; i < retrievedPayoutPool.length; i++) {
          const thisPayoutRecord = retrievedPayoutPool[i]
          //console.warn("save wallet: payout record: ", thisPayoutRecord)

          //const addressToSave = bsv.PrivateKey.fromWIF( this.state.generatedPrivKeyWIF ).toAddress().toString()
          const addressToSave = thisPayoutRecord.address
          console.warn("  save wallet: address: ", addressToSave)


          const bundleJSON = JSON.stringify(thisPayoutRecord);
          let cyphText
          try {
            cyphText = await encryptData(bundleJSON, this.props.bundle.pwd);
          } catch (error) {
            this.setState({showSwirly: false})
            alert("Please make sure you're using https://")
            throw error
          }
          console.log("payout record cypher text is ", cyphText);


          // saves to p2pkhTab
          await saveEncryptedP2PKHKey(db, addressToSave, cyphText, false, recordNetworkCharacter);
          progress++
          added++
      }

      console.warn("we've saved your NEW encrypted keys")
      this.setState({showAddedSuccess: true})
    } catch (error) {
      alert("We had trouble adding more payout keys. Progress = " + progress
          + "  while aiming for 1 + " + NUM_P2PKHS_TO_GEN)
    }

    const freeP2PKHsCount = await getAvailableP2PKHsCount(db)
    this.setState({showSwirly: false,
                   freeP2PKHs: freeP2PKHsCount,
                   numPayouts: this.state.numPayouts + added,
                   showBackupAdviceModal: true,
                  })
  }

  async componentDidMount() {
    const db = await openDB();

    const freeRabinsCount = await getAvailableRabinsCount(db)
    const freeP2PKHsCount = await getAvailableP2PKHsCount(db)

    //TODO: over time we will expand what the Official Wallet is
    //      Right now it is a single record - per NETWORK (main, testnet)
    //const records = await getRabinsJson(db);
    const fundsRecord = await getOfficialWalletJson(db);
    const contents = JSON.parse(fundsRecord)
    console.log("assetManager: number of OFFICIAL p2pkh elements: ", contents.length);


    const payoutsRecords = await getP2PKHsJson(db)
    const payoutsContents = JSON.parse(payoutsRecords)
    console.log("assetManager: number of Builder Payout elements: ", payoutsContents.length)
    //console.log(" payoutsContents: ", payoutsContents)


    const rabinRecords = await getRabinsJson(db);
    const rabinContents = JSON.parse(rabinRecords)
    console.log("assetManager: number of Rabin Publisher (and builder) elements: ", rabinContents.length);

    //const builderRabinRecords = await getRabinsJson(db, "builder");
    //const builderRabinContents = JSON.parse(builderRabinRecords)
    //console.log("assetManager: number of Rabin BUILDER elements: ", builderRabinContents.length);

    const claimedRecords = await findAllDomainsOfMine(db);
    //const claimedContents = JSON.parse(claimedRecords)
    console.log("assetManager: number of claimed domain elements: ", claimedRecords.length)
    console.log("domains: ", claimedRecords)

    const builtDomainsRecords = await findAllMyBuiltDomains(db);
    //const builtDomainsContents = JSON.parse(builtDomainsRecords)
    console.log("assetManager: number of built domain elements: ", builtDomainsRecords.length)

    console.error("assetManager: sift through [address+network]. EXCLUDE other network?")


    // moved here from render()
    const {payoutAssetList, knownTotalSats, knownTotalSweptSats, knownTotalCoins} = await this.buildPayoutAssetList(builtDomainsRecords)

    this.setState({ recordsToShow: null,
                    theRecords: fundsRecord,                numberOfElements: contents.length,
                    payoutRecords: payoutsContents,         numPayouts: payoutsContents.length,
                    publisherRecords: rabinRecords,         numPubKeys: rabinContents.length,
                    //builderRabRecords: builderRabinRecords, numBuilderKeys: builderRabinContents.length,

                    domainsClaimedRecs: claimedRecords,     numClaimed: claimedRecords.length,
                    domainsBuiltRecs: builtDomainsRecords,  numBuilt: builtDomainsRecords.length,

                    freeRabins: freeRabinsCount,
                    freeP2PKHs: freeP2PKHsCount,

                    payoutAssetList: payoutAssetList,
                    knownTotalSats: knownTotalSats,
                    knownTotalCoins: knownTotalCoins,
                    knownTotalSweptSats: knownTotalSweptSats,
                  });

    //NEW: prep these:  (FIXME: re-evaluate/balance)
    //       0- network  ("test", or "main" - only one)             (FIXME: implemented?)
    //       1- Publisher (and Builder) RabinPool JSON (rabinTab)   (10x6  = 60 rabin keys)
    //       2- BUILDER payout (p2pkh) JSON                         (10x5  = 50 p2pkh keys)
    //       3- assets built JSON                                   (10x10 = 100 nodes - key count based on this)
    //       4- assets claimed JSON (with owner#)                   (10    = 10 names - key count based on this)
  }

  closeAdviceModal() {
    this.setState({showBackupAdviceModal: false})
  }

  async closeSinglePayoutModal() {
    // re-generate the list, since, we've recently scanned an asset, AND may have swept coins
    const {payoutAssetList, knownTotalSats, knownTotalSweptSats, knownTotalCoins} = await this.buildPayoutAssetList(this.state.domainsBuiltRecs)

    this.setState({ showSinglePayoutModal: false,
                    chosenBuilderAsset: '',
                    payoutPKH: null,
                    payoutAddr: null,
                    payoutUtxos: null,
                    totalPayoutSats: 0,
                    singlePayoutInfo: null,
                    singlePayoutTitle: null,

                    payoutAssetList: payoutAssetList,
                    knownTotalSats: knownTotalSats,
                    knownTotalCoins: knownTotalCoins,
                    knownTotalSweptSats: knownTotalSweptSats,
                  }, )
  }

  /**
   * Build a UTXO table of all payouts for a given (selected/chosen) builder asset
   * @param {*} e
   * @param {*} name
   * @param {*} payoutPKH
   */
  //FIXME: rename to builderAssetChosen()?
  async payoutChosen(e, name, payoutPKH) {
    console.warn("You chose to check on asset " + name + " - with builder payout pkh: " + payoutPKH)

    const addr = bsv.Address.fromPublicKeyHash(Buffer.from(payoutPKH, 'hex'), "testnet")
    console.log("address: " + addr)

    try {
      // NOT your typical call. This involves a DIFFERENT address
      // These UTXOs are for a different address than the wallet address
      const payoutUtxos = await fetchUtxos( addr, true, true, 'payoutChosen' );

      console.log("payoutChosen() Here are the " + payoutUtxos.length + " builder-payout utxos for '" + name + "': ")
      let info = ''
      let totalSats = 0
      for ( let i = 0; i < payoutUtxos.length; i++ ) {
        info += (payoutUtxos[i].satoshis + " sats in tx " + payoutUtxos[i].txId + " :" + payoutUtxos[i].outputIndex + '<br></br>')
        console.warn("    " + payoutUtxos[i].satoshis + " sats in tx " + payoutUtxos[i].txId + " :" + payoutUtxos[i].outputIndex)
        totalSats += payoutUtxos[i].satoshis
      }

      await this.updatePersistedPayoutInfo(name, this.props.blockHeight, payoutUtxos.length, totalSats)

      const payoutList = payoutUtxos.map((rec) =>
              <Table.Row>
                  <Table.Cell style={{padding:'8px 8px', border:'.1px solid', backgroundColor:'white'}}>
                      {rec.satoshis} sats in tx {rec.txId.substring(0,20)}... :{rec.outputIndex}
                  </Table.Cell>
              </Table.Row>
            )

      const payoutMention = payoutUtxos.length > 0 ?
                                  <>
                                     These total to <b>{totalSats}</b> satoshis {this.satsToCentsJSX(totalSats)}.
                                     <br></br><br></br>
                                     You can now 'sweep' these payouts into your Browser Wallet - so you can use the funds. The keys
                                     to these builder-payout coins are in your Browser Wallet - so, be sure to back it up (copied to
                                     another device), and keep your passwords safe.
                                     <br></br>
                                     <Button positive onClick={this.sweepPayouts}>
                                      Sweep these Payouts
                                     </Button>
                                  </>
                                :
                                  <></>
      const payoutTable = payoutUtxos.length > 0 ?
                          <>
                            <Table unstackable celled style={{borderSpacing:"0px", border:'none', backgroundColor:"#00000000", padding:"3px 5px 6px 5px"}} >
                              <Table.Body>
                                  {payoutList}
                              </Table.Body>
                            </Table>
                          </>
                        :
                          null
      const payoutSummary = <>
                            There are {payoutUtxos.length} payouts to your builder asset <b style={{color:'blue'}}>{name.toUpperCase()}</b>:
                            {payoutTable}
                            {payoutMention}
                            <br></br>
                            <br></br>
                            <div><b>Background</b></div>
                            <p>
                            Payouts are occasionally made to 'builders' of the ShizzleTree - by ShizzleVersians (like you) when
                            they post to the ShizzleVerse. When you claim an asset (domain/identity) in the ShizzleVerse you
                            sometimes need to build the 'nodes' that lead to that asset.
                            </p>
                            <p>
                            So, for example, if you claim the asset 'SUNDAY', but at the time the ShizzleTree only extends
                            to 'SUN', you will also have to <b>build</b> the assets/nodes 'SUND', and 'SUNDA' in order to
                            reach your destination of 'SUNDAY'. (This is done automatically, behind-the-scenes when you claim an asset.)
                            Even though you
                            didn't claim 'SUND', and 'SUNDA', you're still considered their 'Builder'. Later, anyone claiming an
                            asset which derives from any of these nodes (like 'SUNDAE' would derive from your 'SUND', and
                            'SUNDA' nodes) may occasionally pay you a required (fairly meager) fee when they construct certain
                            transactions.
                            <br></br><br></br>
                            If such payouts accumulate, you may want to put them to use - by <b>sweeping</b> them
                            to the active area of your wallet.
                            </p>
                          </>

      this.setState({
                      singlePayoutTitle: <>Builder-Payouts for {name.toUpperCase()}</>,
                      singlePayoutInfo: <>{payoutSummary}</>,
                      showSinglePayoutModal: true,
                      payoutPKH: payoutPKH,
                      payoutAddr: addr.toString(),
                      payoutUtxos: payoutUtxos,
                      totalPayoutSats: totalSats,
                      chosenBuilderAsset: name,
                    })
    } catch (error) {
      console.error("payout query failed: ", error)
    }
  }

  async buildPayoutAssetList(domainsBuiltRecs) {
    //console.warn("domainsBuiltRecs: ", domainsBuiltRecs)

    if ( !domainsBuiltRecs ) {
      return {payoutAssetList: null, knownTotalSats: null, knownTotalSweptSats: null, knownTotalCoins: null}
    }

    // get persisted info about if/when we've scanned each builder asset
    const payoutScanData = await this.getPersistedPayoutScanData()
    console.warn("buildPayoutAssetList(): persisted payoutScanData: ", payoutScanData)

    // build a map of payout scan data - which we can use below,
    // when building the payoutList (rows for each asset)

    let totalTotalSats = 0
    let totalSweptSats = 0
    let totalCoins = 0
    const scanMap = new Map()
    for ( const elem of payoutScanData ){

      if ( typeof scanMap.get(elem.name) === 'undefined' ) {
        scanMap.set(elem.name, elem)
        totalTotalSats += (elem.totalSats)
        totalSweptSats += ((typeof elem.totalSwept === 'undefined' || elem.totalSwept === null) ? 0 : elem.totalSwept)
        totalCoins += elem.numUtxos
      } else {
        alert("ERROR: asset " + elem.name + " has multiple entries in persisted 'payoutScanData' setting.\n\nPlease report this.")
      }
    }

    const payoutList = domainsBuiltRecs.map((rec) =>
        <Table.Row className="hoverLink" key={rec.name}
                onClick={       (event) => this.payoutChosen(event, rec.name, rec.payout)}
                onContextMenu={ (event) => this.payoutChosen(event, rec.name, rec.payout)}>
            <Table.Cell style={{padding:'8px 8px', border:'.1px solid'}}>{rec.name}</Table.Cell>
            <Table.Cell style={{padding:'8px 8px', border:'.1px solid'}}>
                { scanMap.get(rec.name)?.name === rec.name ?
                      scanMap.get(rec.name).numUtxos > 0 ?
                          <>
                            <b style={{color:'blue'}}>{scanMap.get(rec.name).numUtxos}: {scanMap.get(rec.name).totalSats}</b>
                          </>
                        :
                          <>
                            none
                          </>
                    :
                      <div style={{color:'red'}}>
                        ?
                      </div>
                }
            </Table.Cell>
            <Table.Cell style={{padding:'8px 8px', border:'.1px solid'}}>
                { scanMap.get(rec.name)?.name === rec.name ?
                      <>
                        {scanMap.get(rec.name).totalSwept}
                      </>
                    :
                      null
                }
            </Table.Cell>
            <Table.Cell style={{padding:'8px 8px', border:'.1px solid'}}>
                { scanMap.get(rec.name)?.name === rec.name ?
                      <>
                        {this.props.blockHeight - scanMap.get(rec.name).lastScanHeight}
                      </>
                    :
                      <div style={{color:'red'}}>
                        (not yet)
                      </div>
                }
            </Table.Cell>
        </Table.Row>
    )

    console.log("total totalSats: " + totalTotalSats)
    console.log("total totalSwept sats: " + totalSweptSats)

    return {payoutAssetList: payoutList, knownTotalSats: totalTotalSats, knownTotalSweptSats: totalSweptSats, knownTotalCoins: totalCoins}
  } // buildPayoutAssetList

  sweeperToastDone() {

    // Close, then re-draw, the payoutChosen/showSinglePayoutModal modal (now with zero payouts/funds).
    // And close the sweeper toast.
    //
    // payoutChosen() eventually resets the state.showSinglePayoutModal to true.
    // payoutChosen() is also where state.chosenBuilderAsset, and state.payoutPKH were originally set (by user selection)
    this.setState({showSinglePayoutModal: false, showSweeperToast: false}, async () => {
          await this.payoutChosen(null, this.state.chosenBuilderAsset, this.state.payoutPKH)
    })
  }

  async sweepPayouts() {

    console.warn("looking for payout record for payout address of ", this.state.payoutAddr)

    let found = false
    let foundRec = null
    for ( const rec of this.state.payoutRecords ) {
      console.log("payout rec: ", rec)
      if ( rec.address === this.state.payoutAddr ) {
        console.warn("FOUND: ", rec)
        foundRec = rec
        found = true
        break
      }
    }

    if ( found ) {
      try {
        // get the keys to the user's selected builder's payouts
        const plainBundle = await decryptData(foundRec.blob, 'aaaaaaaa');

        // .p,   .address
        const bundledObject = JSON.parse(plainBundle);

        //console.warn("BO: ", bundledObject)
        // double-check this record's encrypted address is what we're looking for
        // (we have no reason to believe it shouldn't)
        if ( bundledObject.address !== this.state.payoutAddr ) {
          throw new Error("wacky p2pkh field")
        }

        //console.warn("Got priv key for this FUNDING/sweep address: ", bundledObject.p)
        console.error("will consolidate using these utxos: ", this.state.payoutUtxos)
        console.error("totalSats: ", this.state.totalPayoutSats)

        //console.error("theRecords: ", this.state.theRecords)
        //const contents = JSON.parse(this.state.theRecords)
        //console.error("contents: ", contents)

        // derive walet P2PKH
        const wallPrivKey = new bsv.PrivateKey.fromWIF(this.props.bundle.p)
        //console.log("privKey: ", privKey)
        const wallPubKey = bsv.PublicKey.fromPrivateKey( wallPrivKey )
        console.log("wallPubKey: ", wallPubKey.toString('hex'))
        const wallPubKeyHash = bsv.crypto.Hash.sha256ripemd160( wallPubKey.toBuffer('hex') ).toString('hex')
        console.log("wpkH", wallPubKeyHash)

        // alternative derivation of P2PKH
        const walletAddress = this.props.bundle.address
        const secondPKH = bsv.encoding.Base58Check.decode(walletAddress)
        console.warn("P2PKH buffer derived from address: ", secondPKH)
        console.warn("  NOW, as a hex string: ", secondPKH.toString("hex"))
        console.warn("  NOW, reduced:         ", secondPKH.toString("hex").substring(2, 42))
        const walletPKH = secondPKH.toString("hex").substring(2, 42)

        if ( wallPubKeyHash !== walletPKH ) {
          alert("We're having trouble deriving the correct P2PKH destination for your builder payouts. CODE ERROR? Please report this.")
          return
        }

        console.warn("We're about to sweep payouts to this PKH: " + walletPKH)
        //NOTE: this alters (adds to) the wallet utxos
        //      We won't really notice this until the next scanUTXOs()
        //      (which will happen the next time we access the wallet - which is 99% of what we want)
        const {raw, txid} = await buildConsolidatingTx(bundledObject.p, this.state.payoutUtxos, this.state.totalPayoutSats, walletPKH, true)

        if ( raw !== null ) {
          console.warn("created and broadcast payout-sweeping (to wallet) tx " + txid)
          const oldObj = await this.getPersistedPayoutScanObject(this.state.chosenBuilderAsset)

          // also track/update the total number of sats we've swept for this asset
          const alreadySwept = oldObj === null ? 0 : oldObj.totalSwept
          const newTotalSwept = alreadySwept + this.state.totalPayoutSats

          console.log("updating 'totalSwept' for " + this.state.chosenBuilderAsset + ": from " + alreadySwept + " to " + newTotalSwept)

          await this.updatePersistedPayoutInfo(this.state.chosenBuilderAsset, this.props.blockHeight, 0, 0, newTotalSwept)

        } else {
          alert("ERROR while sweeping payouts?")
          return
        }

        // show a toast for a few seconds - so the tx propagates
        // the Toast itself will call sweeperToastDone() when it finishes
        this.setState({showSweeperToast: true})
      } catch (error) {
        console.warn("sweepPayouts(): Wrong password? ", error)
        alert("Consolidation tx failed. Wrong password?")
        //this.props.showOkModal("Wrong Password", <>You've entered the <span style={{color: "red"}}>wrong password</span> for
        //                                            your browser wallet, OR, <span style={{color: "red"}}>you're not using
        //                                            https://</span>. Please check, and try again.</>)
        //this.setState({pwd: ''});
      }
    }
  } // sweepPayouts()

  // persists info about a recently-scanned builder asset
  async updatePersistedPayoutInfo(name, blockHeight, numUtxos, totalSats, numSwept = 0) {
    const dataArray = await this.getPersistedPayoutScanData()

    let found = false
    dataArray.forEach ( (elem, idx ) => {
      console.warn("updatePersistedPayoutInfo()  elem " + idx + ":", elem)
      if ( elem.name === name ) {
        if (found) {
          alert("ERROR: assetManager updatePersistedPayoutInfo(): found a duplicate " + name + " entry.")
        } else {
          console.log("found asset " + name + " at index " + idx + ". Will splice in an UPDATED object")
          found = true
          // check for NEW field
          const alreadySwept = elem.totalSwept === null ? 0 : elem.totalSwept

          // replace with, and persist, this item/name
          const newObj2 = {name: name, lastScanHeight: blockHeight, numUtxos: numUtxos, totalSats: totalSats, totalSwept: alreadySwept + numSwept}
          console.log("assetManager  updatePersistedPayoutInfo(): adding alreadySwept (" + alreadySwept
                    + ") to numSwept (" + numSwept +") for asset " + elem.name)
          console.warn("new object to substitute in: ", newObj2)
          dataArray.splice(idx, 1, newObj2)
        }

        // keep going - to check for any unexpected duplicates
        //break;
      }
    })

    if ( !found ) {
      // push, persist this NEW item/name
      const newObj = { name: name, lastScanHeight: blockHeight, numUtxos: numUtxos, totalSats: totalSats, totalSwept: 0 }

      console.warn("asset " + name + " not found. Will PUSH in new object:", newObj)
      dataArray.push( newObj )
    }

    await this.persistPayoutScanData( dataArray )
  }

  async getPersistedPayoutScanObject(name) {
    const payoutScanArray = await this.getPersistedPayoutScanData()

    console.log("getPersistedPayoutScanObject(): payoutScanArray = ", payoutScanArray)

    for ( const elem of payoutScanArray ) {
      if ( elem.name === name ) {
        console.warn("FOUND payoutScan object for builder " + name + ":", elem)
        return elem
      }
    }

    return null
  }

  async getPersistedPayoutScanData() {
    const payoutScanData = await readSetting(await openDB(), 'payoutScanData')

    console.log("===>  payoutScanJson: ", payoutScanData)

    if ( payoutScanData === null ) {
      return []
    }

    const payoutScanObjArray = JSON.parse( payoutScanData.value )
    //console.warn("json-parsed scan array: ", payoutScanObjArray)

    return payoutScanObjArray
  }

  // only called by updatePersistedPayoutInfo()
  async persistPayoutScanData(payoutScanObjArray) {

    //for ( const elem of payoutScanObjArray ) {
    //  console.warn("persisting elem: ", elem)
    //}
    console.warn("persisting " + payoutScanObjArray.length + " asset payout elements...")

    const payoutScanJsonStr = JSON.stringify( payoutScanObjArray )
    await saveSetting(await openDB(), 'payoutScanData', payoutScanJsonStr)

  }

  render() {

    //COPIED FROM ONRAMP

    // ***  DEVICE-DEPENDENT STYLING  ***
    // For mobile, "detect" landscape screen mode
    const landscape = this.props.isMobile ? window.innerWidth > window.innerHeight : false
    // For mobile, shrink the About, Security, and Glossary modals
    const modalClassName   = this.props.isMobile ?
                                    (landscape ?
                                            "modalMobileLandscape"
                                        :
                                            "modalMobilePortrait"
                                    )
                                :
                                    ""
    const modalContentClassName = this.props.isMobile ? "modalContentMobile" : ""
    const modalBottomClassName  = this.props.isMobile ? "modalBottomMobile"  : ""

    //console.warn("claimed: ", this.state.domainsClaimedRecs)

    //let domainsClaimedList = ''
    let claimedList = []
    if ( this.state.domainsClaimedRecs ) {
      //console.warn("   assetManager:  " + domainName + "this.props.blockHeight: " + this.props.blockHeight)
      for ( let i = 0; i < this.state.domainsClaimedRecs.length; i++ ) {
        //domainsClaimedList += (this.state.domainsClaimedRecs[i].name + ", ")

        const domainName = this.state.domainsClaimedRecs[i].name
        const ownerCount = this.state.domainsClaimedRecs[i].ownerCount

        const registeredAtApproxBlockHeight = this.state.domainsClaimedRecs[i].approxBlockHeight
        const approxBlocksUntilExpiration   = registeredAtApproxBlockHeight + 365 * 144 - this.props.blockHeight
        const approxHoursUntilExpiration    = approxBlocksUntilExpiration / 6
        const approxDaysUntilExpiration     = approxBlocksUntilExpiration / 144
        const approxWeeksUntilExpiration    = approxDaysUntilExpiration / 7
        const approxMonthsUntilExpiration   = approxDaysUntilExpiration / 30

//        console.warn("   assetManager:  " + domainName + " registeredAtApproxBlockHeight: " + registeredAtApproxBlockHeight)
//        console.log("   assetManager:     HOURS until exp: " + approxHoursUntilExpiration)
//        console.log("   assetManager:     DAYS until exp: " + approxDaysUntilExpiration)
//        console.log("   assetManager:     WEEKS until exp: " + approxWeeksUntilExpiration)
//        console.log("   assetManager:     MONTHS until exp: " + approxMonthsUntilExpiration)

        //FIXME: ALSO calculate time until CAN BE RENEWED, and display that

        let expirationMention
        if ( approxMonthsUntilExpiration > 3.0 ) {
          expirationMention = "   - expires in roughly " + Math.round(approxMonthsUntilExpiration) + " months"
        } else if ( approxWeeksUntilExpiration > 3.0 ) {
          expirationMention = "   - expires in roughly " + Math.round(approxWeeksUntilExpiration) + " weeks"
        } else if ( approxDaysUntilExpiration > 3.0 ) {
          expirationMention = "   - expires in roughly " + Math.round(approxDaysUntilExpiration) + " days"
        } else if ( approxHoursUntilExpiration > 6.0 ) {
          expirationMention = "   - expires in roughly " + Math.round(approxHoursUntilExpiration) + " hours"
        } else if ( approxBlocksUntilExpiration > 3.0 ) {
          expirationMention = "   - expiration is IMMINENT - in roughly " + Math.round(approxBlocksUntilExpiration) + " blocks"
        } else if ( this.state.domainsClaimedRecs[i].outdated ) {
          expirationMention = "   - is OUTDATED"
        } else if ( approxBlocksUntilExpiration > -2.0 ) {
          expirationMention = "   - may have already EXPIRED"
        } else {
          expirationMention = "   - has EXPIRED, and can be re-claimed by ANYONE"
        }

        claimedList.push( domainName.toUpperCase() + " (" + ownerCount + ")" + expirationMention)
      }
    }

    const disableRabinGen  = this.state.freeRabins > 79
    const disablePayoutGen = this.state.freeP2PKHs > 69
    const plentyRabinsMention  = disableRabinGen  ? <> (That's plenty.)</> : <></>
    const plentyPayoutsMention = disablePayoutGen ? <> (That's plenty.)</> : <></>

    // NOTE: these numbers should be at least the same (or larger than) the
    //       check in claimer's componentDidMount()
    const rabinsLow  = this.state.freeRabins < 18
    const payoutsLow = this.state.freeP2PKHs < 18
    const lowRabinsMention  = rabinsLow  ? <span style={{color: "red"}}> (You're low.)</span> : <></>
    const lowPayoutsMention = payoutsLow ? <span style={{color: "red"}}> (You're low.)</span> : <></>

    const incomeMention = this.state.domainsBuiltRecs?.length > 0 ?
              <>
                Some domains that extend from any of these nodes may occasionally pay you a
                small fee (to some of your Builder Payout keys). <b style={{color:'blue'}}>Click on a
                builder asset below</b> to check if any recent payouts have been made to you.
              </>
            :
              <></>


    /*
      // originally had this:
                  <Loader inline style={{color: "black"}}>
                    {this.state.swirlyText}
                  </Loader>

      // instead of this (further below):
                <Icon size='large'  loading name='spinner' />
      // icon size: mini tiny small large big huge massive
    */


    const maybeBuilderPayoutTable = this.state.domainsBuiltRecs?.length > 0 ?
                <>
                  <h3 style={{color:"blue"}}>Builder Payout Nodes</h3>
                  Along the way to claiming your domains, you have built (and may therefore benefit from) the following {this.state.domainsBuiltRecs?.length} ShizzleTree limbs/nodes.&nbsp;
                  {incomeMention}

                  <Table unstackable celled style={{borderSpacing:"0px", border:'none', backgroundColor:"#00000000"}} >
                      <Table.Header>
                          <Table.Row>
                              <Table.HeaderCell style={{backgroundColor:'blue', color:'white', padding:'8px 8px'}}>Builder Asset</Table.HeaderCell>
                              <Table.HeaderCell style={{backgroundColor:'blue', color:'white', padding:'8px 8px'}}># of Coins,<br></br>Total Sats</Table.HeaderCell>
                              <Table.HeaderCell style={{backgroundColor:'blue', color:'white', padding:'8px 8px'}}>Total Sats<br></br>Swept</Table.HeaderCell>
                              <Table.HeaderCell style={{backgroundColor:'blue', color:'white', padding:'8px 8px'}}>Last Scan<br></br>(blocks ago)</Table.HeaderCell>
                          </Table.Row>
                      </Table.Header>
                      <Table.Body>
                          {this.state.payoutAssetList}
                      </Table.Body>
                      <Table.Footer>
                        <Table.Row>
                          <Table.HeaderCell style={{backgroundColor:'blue', color:'white', padding:'8px 8px', textAlign:'right'}}>
                            Totals: &nbsp; &nbsp;
                          </Table.HeaderCell>
                          <Table.HeaderCell style={{backgroundColor:"#00000000", padding:'8px 8px', border:'.1px solid'}}>
                            <b style={{color:'blue'}}>{this.state.knownTotalCoins}: {this.state.knownTotalSats}</b>
                          </Table.HeaderCell>
                          <Table.HeaderCell style={{backgroundColor:"#00000000", padding:'8px 8px', border:'.1px solid'}}>
                            <b style={{color:'blue'}}>{this.state.knownTotalSweptSats}</b>
                          </Table.HeaderCell>
                          <Table.HeaderCell style={{backgroundColor:"#00000000", padding:'8px 8px', border:'.1px solid'}}>
                          </Table.HeaderCell>
                        </Table.Row>
                      </Table.Footer>
                  </Table>

                  Known total outstanding (un-swept) payout sats: <span style={{color:'blue'}}>{this.state.knownTotalSats}</span>
                  &nbsp;{this.satsToCentsJSX(this.state.knownTotalSats)}
                  <br></br>
                  Total already-swept payout sats: <span style={{color:'blue'}}>{this.state.knownTotalSweptSats}</span>
                  &nbsp;{this.satsToCentsJSX(this.state.knownTotalSweptSats)}
                  <br></br><br></br>
                </>
              :
                <></>

    // It's possible that it doesn't take as long for a simple funds-consolidating tx to
    // propagate, as for a more complicated (ShizzleVerse) smart contract (which takes 4000-ish ms)
    // Hmmm. TBD.
    const displayText = <>We've built and broadcast a payout-sweeping tx, directing funds to your wallet.
                          <br></br>
                          <br></br>
                          Please wait while this tx propagates the network...
                        </>
    const maybeSweeperToast = this.state.showSweeperToast ?
                <>
                  <Toast  durationMillis={4100}
                      displayText={displayText}
                      done={this.sweeperToastDone}
                      parent={this}/>
                </>
            :
                null
    return <>

            <Modal size='large' centered className={modalClassName} open={true} style={{backgroundColor: this.bshzPurple, borderRadius: '20px'}}>
              <Modal.Header style={{textAlign: 'center', backgroundColor: this.bshzPurple, borderRadius: '20px'}}>
                <span style={{color: this.bshzYellow}}> Assets and Keys Manager</span>
              </Modal.Header>
              <Modal.Content className={modalContentClassName} scrolling style={{backgroundColor: this.bshzLtPurp}}>


                  <h3 style={{color:"blue"}}>Keys</h3>

                  You have <b style={{color:"blue"}}>{this.state.freeP2PKHs} available</b> Builder Payout keys. {plentyPayoutsMention}{lowPayoutsMention} <span style={{color:"grey"}}>({this.state.numPayouts} total)</span>
                  <br></br>
                  <Button onClick={this.addMorePayoutKeys} disabled={disablePayoutGen} positive>
                        Generate More Payout Keys
                  </Button>

                  <br></br>
                  <br></br>
                  You have <b style={{color:"blue"}}>{this.state.freeRabins} available</b> Publisher keys. {plentyRabinsMention}{lowRabinsMention} <span style={{color:"grey"}}>({this.state.numPubKeys} total)</span>
                  <br></br>
                  <Button onClick={this.addMoreRabins} disabled={disableRabinGen} positive>
                        Generate More Publisher Keys
                  </Button>

                  <br></br>
                  <Divider/>

                  <h3 style={{color:"blue"}}>Domains (Identities)</h3>
                  You have claimed the following {this.state.domainsClaimedRecs?.length} domains:
                  <List selection bulleted items={claimedList}/>

                  <Divider/>
                  {maybeBuilderPayoutTable}

                </Modal.Content>

                <Modal.Actions className={modalBottomClassName} style={{backgroundColor: this.bshzPurple, borderRadius: '0px 0px 20px 20px'}}>
                  <div style={{textAlign: 'center'}}>
                      <Button negative onClick={this.props.closeAssetsModal} content='Back'/>
                  </div>
              </Modal.Actions>
              </Modal>

              <Modal dimmer='blurring' size='mini' centered className={modalClassName}  open={this.state.showSwirly}
                            style={{backgroundColor: this.bshzPurple, borderRadius: '20px', height: "auto"}}>
                <Modal.Content className={modalContentClassName} scrolling style={{backgroundColor: this.bshzLtPurp}}>

                  <div style={{textAlign: 'center'}}>
                    <Icon size='large'  loading name='spinner' />
                  </div>

                  {this.state.swirlyText}


                </Modal.Content>
              </Modal>

              <Modal size='small' centered className={modalClassName} open={this.state.showBackupAdviceModal} style={{backgroundColor: this.bshzPurple, borderRadius: '20px'}}>
                <Modal.Header style={{textAlign: 'center', backgroundColor: this.bshzPurple, borderRadius: '20px'}}>
                  <span style={{color: this.bshzYellow}}> Backup Your Wallet Now </span>
                </Modal.Header>
                <Modal.Content className={modalContentClassName} scrolling style={{backgroundColor: this.bshzLtPurp}}>

                  <h1><span style={{color:"red"}}>NOW</span> is the time to backup your wallet.
                  </h1>
                  <h3>
                      <p>
                      If you've already done this, that backup is now OUTDATED. It doesn't have the new keys we've
                      created for you just now.
                      </p>
                      <p style={{color:"red"}}>
                          Anything you build or claim with these newly-created keys is in danger of being lost forever
                          UNLESS you backup your wallet AGAIN.
                      </p>
                  </h3>
                  When you click the <Button negative size='mini' content='Back'/> button, you'll then see
                  a <Button color="blue" size='mini' style={{color:"yellow"}} content='Backup Wallet'/> button
                  which you can click.

                </Modal.Content>

                <Modal.Actions className={modalBottomClassName} style={{backgroundColor: this.bshzPurple, borderRadius: '0px 0px 20px 20px'}}>
                  <div style={{textAlign: 'center'}}>
                      <Button positive onClick={this.closeAdviceModal} content="Understood. I'll backup my wallet now."/>
                  </div>
              </Modal.Actions>
              </Modal>


              <Modal size='small' centered className={modalClassName} open={this.state.showSinglePayoutModal} style={{backgroundColor: this.bshzPurple, borderRadius: '20px'}}>
                <Modal.Header style={{textAlign: 'center', backgroundColor: this.bshzPurple, borderRadius: '20px'}}>
                  <span style={{color: this.bshzYellow}}>{this.state.singlePayoutTitle}</span>
                </Modal.Header>
                <Modal.Content className={modalContentClassName} scrolling style={{backgroundColor: this.bshzLtPurp}}>

                  {this.state.singlePayoutInfo}

                </Modal.Content>

                <Modal.Actions className={modalBottomClassName} style={{backgroundColor: this.bshzPurple, borderRadius: '0px 0px 20px 20px'}}>
                  <div style={{textAlign: 'center'}}>
                      <Button negative onClick={this.closeSinglePayoutModal} content="Back"/>
                  </div>
              </Modal.Actions>
              </Modal>

              {maybeSweeperToast}
          </>
  }
} // AssetManager

export default AssetManager;