const bshzColors = {
    ltPurple:   '#e7d0fb',
    purple:     '#8313e2',
    yellow:     '#fff300',
    ltYellow:   '#fffccc',

    green:      "#01a936",  // from shizzleHome  onRampBackColor

    ltBluePurple: '#c8c8FF', // from dialogManager (dialog modal)
}

export {
    bshzColors
}