/* Use these for when building/running console utitlity */
//const {
//	bsv,
//	buildContractClass
//} = require('scryptlib');

const {
	bsv,
} = require('scryptlib');

const {
	privateKey
} = require('./privateKey');

const {
	BUILDERPREP_LOCKING_SCRIPT,
    APPEND_LOCKING_SCRIPT,
	EBFRA_LOCKING_SCRIPT,
    CONTINUE_LOCKING_SCRIPT,
    UPDATE_LOCKING_SCRIPT,

	TRANS_VANILLA_LOCKING_SCRIPT,
	TRANS_LABELED_LOCKING_SCRIPT,

    BITGROUP_LOCKING_SCRIPT,
    ADMINISTERGROUP_LOCKING_SCRIPT,
    ASKBID_LOCKING_SCRIPT,
	DIALOG_LOCKING_SCRIPT,

	appendScriptHexString,
	ebfraScriptHexString,
    continueScriptHexString,
    updateScriptHexString,

	tVanillaScriptHexString,
	tLabeledScriptHexString,

	dialogScriptHexString,

    askBidScriptHexString,
    bitGroupScriptHexString,
    administerGroupScriptHexString
} = require('./contractsCache');

const {
	appendUserProfile,
	removeUserProfile,

	buildAskBidScriptPubKey,
	buildEbfraScriptPubKey,
	buildUpdateScriptPubKey,
	buildTransientScriptPubKey,
	buildDialogScriptPubKey,

	buildBitGroupScriptPubKey,
	buildAdministerGroupScriptPubKey,
	//executeTransientPubContract,		// no longer used ( we now use ...HostingContract() )
	executeTransientHostingContract,
	executeTheAskBidContract,
	executeBuilderPrepContract,
	executeTheAppendContract,

	buildContinueScriptPubKey,
	getSigParts,
	executePubUpdateContract,
	executeEbfraContract,
	executePubTransContract,
	executeDialogContract,
	executeBitGroupContract,
	executeAdminGroupContract,
	executeClaimTransientBalance,
	executeClaimUpdateBalance,
	executeIncreaseTransientBalance,
} = require( "./contractSupport");

const {
	appendBid,
	removeBid,
	removeBalk,
	modifyBid,
	copyBid2Balk,

	findMyBidIndex,
	getBestBidIndex,
	getWorstBidIndex,
} = require( "./auctionUtils")

const {
	getRawInfo,
	sendLockingTx,
	getUnspentScript,
	getSpentScript
} = require( "./providers" );

const { generatePrivKey,
	privKeyToPubKey, privKeyToPubKey2,
	sign, sign2,
	verify,
	simpleHash
} = require("rabinsig");

const {
	FOURTH_OF_YEAR_IN_BLOCKS,
    THIRD_OF_YEAR_IN_BLOCKS,
	BLOCKS_IN_ONE_YEAR,

	MIN_BLOCKS_PER_UPDATE,

	CLAIM_PAYOUT_MULTIPLIER,

	TRANSIENT_PAYOUT_MULTIPLIER,

	BITGROUP_PAYOUT_MULTIPLIER,

	NAMED_GROUP_SUBTHREAD_FEE_MULTIPLIER,
	MAX_POSTS_FOR_UNNAMED_GROUP_SUBTHREAD,

	BOUNTY_MULTIPLIER_TRANSIENT,
	BOUNTY_MULTIPLIER_UPDATE,
	BOUNTY_MULTIPLIER_CONTINUE,
	BOUNTY_MULTIPLIER_BITGROUP,

	BLOCKNUM_SIZE,

	MAX_NUM_BIDS,

	PKH_LEN,
	MAX_PKHS,

	CONTINUE_SPECIAL_OP_CLAIM_EXPIRED_ASSET,
    CONTINUE_SPECIAL_OP_APPLY_AUTHCODE,
    CONTINUE_SPECIAL_OP_INSTA_BUY,
	CONTINUE_SPECIAL_OP_BUILDER_VOTES_BASE,

	NETWORK
} = require( './harnessConstants' );

const {
	int2Asm,
	oneByteLE,
	twoByteLE,
	fourByteLE,
	fiveByteLE,
	eightByteLE,
    hexByteToAscii,
    hexStringToAscii,
	swapEndian,

	printError,
} = require( './commonFuncs' );

///////////////

/** Use these for Web App - for when NOT building/running console utility *

import {
	bsv,
	buildContractClass
} from 'scryptlib';

import {
	//DataLen,
	//loadDesc,
	//createLockingTx,
	//sendTx,
	//reverseEndian,
	//showError,       // needed?
	//compileContract
	//tx
} from './helper'

import { privateKey } from './privateKey';

import {
	BUILDERPREP_LOCKING_SCRIPT,
    APPEND_LOCKING_SCRIPT,
	EBFRA_LOCKING_SCRIPT,
    CONTINUE_LOCKING_SCRIPT,
    UPDATE_LOCKING_SCRIPT,

	TRANS_VANILLA_LOCKING_SCRIPT,
	TRANS_LABELED_LOCKING_SCRIPT,

    BITGROUP_LOCKING_SCRIPT,
    ADMINISTERGROUP_LOCKING_SCRIPT,
    ASKBID_LOCKING_SCRIPT,
	DIALOG_LOCKING_SCRIPT,

	ebfraScriptHexString,
    continueScriptHexString,
    updateScriptHexString,

	tVanillaScriptHexString,
	tLabeledScriptHexString,

	dialogScriptHexString,

    askBidScriptHexString,
    bitGroupScriptHexString,
    administerGroupScriptHexString
} from './contractsCache'

import {
	getRawInfo,
	appendUserProfile,
	removeUserProfile,
	appendBid,
	removeBid,
	removeBalk,
	modifyBid,
	copyBid2Balk,
	buildAskBidScriptPubKey,
	buildUpdateScriptPubKey,
	buildTransientScriptPubKey,
	buildBitGroupScriptPubKey,
	buildAdministerGroupScriptPubKey,
	//executeTransientPubContract,		// no longer used ( we now use ...HostingContract() )
	executeTransientHostingContract,
	executeTheAskBidContract,
	executeBuilderPrepContract,
	executeTheAppendContract,

	buildContinueScriptPubKey,
	getSigParts,
	executePubUpdateContract,
	executePubTransContract,
	executeBitGroupContract,
	executeAdminGroupContract,
	executeClaimTransientBalance,
	executeClaimUpdateBalance,
	executeIncreaseTransientBalance,
	findMyBidIndex,
	getBestBidIndex,
	getWorstBidIndex,
	sendLockingTx,
	getUnspentScript,
	getSpentScript
} from "./contractSupport"

import { generatePrivKey,
	privKeyToPubKey, privKeyToPubKey2,
	privKeyToPubKeyHex, privKeyToPubKeyHashHex,
	sign, sign2,
	verify,
	simpleHash
} from "rabinsig"

import {
	FOURTH_OF_YEAR_IN_BLOCKS,
	THIRD_OF_YEAR_IN_BLOCKS,
	BLOCKS_IN_ONE_YEAR,

	MIN_BLOCKS_PER_UPDATE,

	CLAIM_PAYOUT_MULTIPLIER,

	TRANSIENT_PAYOUT_MULTIPLIER,

	BITGROUP_PAYOUT_MULTIPLIER,

	NAMED_GROUP_SUBTHREAD_FEE_MULTIPLIER,
	MAX_POSTS_FOR_UNNAMED_GROUP_SUBTHREAD,

	BOUNTY_MULTIPLIER_TRANSIENT,
	BOUNTY_MULTIPLIER_UPDATE,
	BOUNTY_MULTIPLIER_CONTINUE,
	BOUNTY_MULTIPLIER_BITGROUP,

	BLOCKNUM_SIZE,

	MAX_NUM_BIDS,

	PKH_LEN,
	MAX_PKHS,

	CONTINUE_SPECIAL_OP_CLAIM_EXPIRED_ASSET,
    CONTINUE_SPECIAL_OP_APPLY_AUTHCODE,
    CONTINUE_SPECIAL_OP_INSTA_BUY,
	CONTINUE_SPECIAL_OP_BUILDER_VOTES_BASE,

	NETWORK
} from './harnessConstants'

import {
	int2Asm,
	oneByteLE,
	twoByteLE,
	fourByteLE,
	fiveByteLE,
	eightByteLE,
    hexByteToAscii,
    hexStringToAscii,
	swapEndian
} from './commonFuncs' ;
/**/
///////////////////////////


// Each iteration - up to 26 outputs - build from just one of them
//                      a    b    c    d    e    f    g    h    i    j    k    l    m    n    o    p    q    r    s    t    u    v    w    x    y    z
const iter0NewChars = ['61','62','63','64','65','66','67','68','69','6a','6b','6c','6d','6e','6f','70','71','72','73','74','75','76','77','78','79','7a']
const iter1NewChars = ['61','62','63','64','65','66','67','68','69','6a','6b','6c','6d','6e','6f','70','71','72','73','74','75','76','77','78','79','7a',
					'30','31','32','33','34','35','36','37','38','39','5f']
const fullNewChars = [ iter0NewChars, iter1NewChars ]

const CONTRACT_SELL       = "sale negotiation"
const CONTRACT_ANNOUNCE   = "EBFRA Announcement"
const CONTRACT_SPEAKER    = "EBFRA Speaker"
const CONTRACT_PREP       = "builderPrep"
const CONTRACT_GROW       = "expand"
const CONTRACT_CONTINUE   = "continue"
const CONTRACT_UPDATE     = "update"
const CONTRACT_TRANSIENT  = "transient"
const CONTRACT_BITGROUP   = "bitGroup"
const CONTRACT_ADMINGROUP = "adminGroup"
const CONTRACT_DIALOG     = "dialog"

//FIXME: in the future: 26, then 37+
//const APPEND_FAN_OUT = 4
//increased to 12 on 9/19/2022
const APPEND_FAN_OUT = 20

const MAX_LEN_CONTENT_NAME  = 64
const MAX_LEN_TX_DESCRIPTOR = 32

//FIXME: be clear on what we mean by level
//FIXME: in future, what's this value?
const MIN_LEVEL_TO_CLAIM = 1   // IMPORTANT this needs to be set correctly, or else we won't have
							   // txoTab entries for early txs

const PRICE_LEN = 5
//const MAX_NUM_BIDS = 5  moved to harnessConstants.js

//WARNING: must match buildShizzle's value
const SMALLEST_OUTPUT = 1

const SLEEP_INTERVAL_AFTER_TX_MS = 15000

const PERMISSION_GRANT_DEADLINE_BLOCKS = 400  // How many blocks the topBidder has to grant permission to the seller once it's been requested
const EXECUTION_TIME_LIMIT = 144 * 4  // The seller has 4 days (576 blocks) to execute sale once he's received permission


//FIXME: use ALL of these values
const REFUND_DELAY_FOR_SLOW_RESPONSE  = 144 * 3		// 201-255 blocks before deadline (of request+400)
const REFUND_DELAY_FOR_VSLOW_RESPONSE = 144 * 6		// 0/1-100 blocks before deadline (of request+400)
const REFUND_DELAY_FOR_NO_RESPONSE    = 144 * 14	// missed deadline (of request + 400)


//FIXME: best place to set this?
const extFundingPubKey = bsv.PublicKey.fromPrivateKey( privateKey ) //extFundingPrvKey)
const EXT_FUNDING_CHANGE_PKH = bsv.crypto.Hash.sha256ripemd160(extFundingPubKey.toBuffer('hex')).toString('hex')


const transientPayoutMultiples = [ TRANSIENT_PAYOUT_MULTIPLIER,
								   TRANSIENT_PAYOUT_MULTIPLIER ];

const transientScriptHexStrings = [ tVanillaScriptHexString,
									tLabeledScriptHexString ];

const TRANSIENT_LOCKING_SCRIPTS = [ TRANS_VANILLA_LOCKING_SCRIPT,
									TRANS_LABELED_LOCKING_SCRIPT ];

//export /* */
function generateBuilderPrepLockingScript(theData, asAScript) {
    return generateLockingScript('builderPrep.scrypt', "builderPrep", BUILDERPREP_LOCKING_SCRIPT, theData, asAScript)
}

//export /* */
function generateAppendLockingScript(theData, asAScript) {
    return generateLockingScript('newMulti1Pub.scrypt', "append", APPEND_LOCKING_SCRIPT, theData, asAScript)
}

//export /* */
function generateEBFRALockingScript(theData, asAScript) {
    return generateLockingScript('ebfraAnnouncer.scrypt', "ebfra", EBFRA_LOCKING_SCRIPT, theData, asAScript)
}

//export /* */
function generateContinueLockingScript(theData, asAScript) {
    return generateLockingScript('newContinue.scrypt', "continue", CONTINUE_LOCKING_SCRIPT, theData, asAScript)
}

//export /* */
function generateUpdateLockingScript(theData, asAScript) {
    return generateLockingScript('newUpdate.scrypt', "update", UPDATE_LOCKING_SCRIPT, theData, asAScript)
}

//export /* */
function generateTransientLockingScript(tNumber, theData, asAScript) {
	let typeIndex
	let transType
	switch (tNumber) {
		case 9:
		case 8:
		case 7:
		case 6:
		case 5:
			console.warn("NOTE: building a LABELED transient")
			transType = 'Labeled'
			typeIndex = 1
			break;
		case 4:
		case 3:
		case 2:
		case 1:
		case 0:
			console.warn("NOTE: building a VANILLA transient")
			transType = 'Vanilla'
			typeIndex = 0
			break;
		default:
			throw new Error("49990: request to build invalid transient type: " + tNumber)

	}
    var transContractName = "transient" + transType + ".scrypt"
	// Note that we're indexing by TYPE, not T-number
    const transLockingScript = TRANSIENT_LOCKING_SCRIPTS[ typeIndex ]
    return generateLockingScript(transContractName, "transient"+tNumber, transLockingScript, theData, asAScript)
}

//export /* */
function generateBitGroupLockingScript(theData, asAScript) {
    return generateLockingScript('bitGroup.scrypt', "bitGroup", BITGROUP_LOCKING_SCRIPT, theData, asAScript)
}

//export /* */
function generateDialogLockingScript(theData, asAScript) {
    return generateLockingScript('dialog.scrypt', "dialog", DIALOG_LOCKING_SCRIPT, theData, asAScript)
}

//export /* */
function generateAdministerGroupLockingScript(theData, asAScript) {
    return generateLockingScript('administerGroup.scrypt', "administerGroup", ADMINISTERGROUP_LOCKING_SCRIPT, theData, asAScript)
}

//export /* */
function generateAskBidLockingScript(theData, asAScript) {
    return generateLockingScript('newAskBid.scrypt', "askBid", ASKBID_LOCKING_SCRIPT, theData, asAScript)
}

//export /* */
function generateLockingScript(contractName, descriptiveName, preRecordedLockingScript, theData, asAScript) {
    var lockingScript
    if ( preRecordedLockingScript === '' ) {
        console.error("Must generate pure locking script for " + contractName)
		throw new Error("29999: Locking script for " + contractName + " not currently cached.")
/** *
        //const theContract = buildContractClass(compileContract(contractName)) //'newAskBid.scrypt'))
        console.log("\n\n    DONE generating locking script.\n\n")
        const contractInstance = new theContract()

        lockingScript = contractInstance.lockingScript
        console.log('\n\n\nTHIS is the PURE ' + descriptiveName + ' locking script (no data): '
                + lockingScript.toASM() + '\n\n')

        if ( theData.length !== 0 ) {
            console.log("--> appending data, of length " + theData.length + " bytes, to the script")
            contractInstance.setDataPart(theData)
        } else {
            console.log("NO DATA TO APPEND TO LOCKING SCRIPT <---\n")
        }

        if ( asAScript ) {
            lockingScript = contractInstance.lockingScript
        } else {
            lockingScript = contractInstance.lockingScript.toASM()
        }
/**/
        //console.log("\n\nHere's the script now: " + lockingScript)

    } else {
        console.log("We've already got the " + descriptiveName + " locking script cached - no need to compile contract")
        var lockingAsm = preRecordedLockingScript
        if ( theData.length !== 0 ) {
            //console.log("\n  adding data to the end of locking script: " + theData + "\n")
            lockingAsm += (' OP_RETURN ' + theData)
        }

        if ( asAScript ) {
            lockingScript = bsv.Script.fromASM( lockingAsm )
        } else {
            lockingScript = lockingAsm
        }
    }

    return lockingScript
}

//export /* */
async function buildAndSendBuilderPrepLockingTxWithThisInitialState(theData, amount) {

    var lockingScript = generateBuilderPrepLockingScript( theData, true )

    // lock fund to the script.
    // Check: setting to zero, AND having a change output,
    //        results in a call to estimateFee()
    const lockingTxid = await sendLockingTx(lockingScript, privateKey, amount) //used to be 546

    console.log('BuilderPrep funding txid:      ', lockingTxid)

    return lockingTxid
}

//export /* */
async function buildAndSendAppendLockingTxWithThisInitialState(theData, amount) {

    var lockingScript = generateAppendLockingScript( theData, true )

    // lock fund to the script.
    // Check: setting to zero, AND having a change output,
    //        results in a call to estimateFee()
    const lockingTxid = await sendLockingTx(lockingScript, privateKey, amount) //used to be 546

    console.log('APPEND funding txid:      ', lockingTxid)

    return lockingTxid
}

//export /* */
async function buildAndSendEbfraLockingTxWithThisInitialState(theData, amount) {

    var lockingScript = generateEBFRALockingScript( theData, true )

    // lock fund to the script.
    // Check: setting to zero, AND having a change output,
    //        results in a call to estimateFee()
    const lockingTxid = await sendLockingTx(lockingScript, privateKey, amount) //used to be 546

    console.log('EBFRA funding txid:      ', lockingTxid)

    return lockingTxid
}

//export /* */
async function buildAndSendContinueLockingTxWithThisInitialState(theData, amount) {
	var lockingScript = generateContinueLockingScript( theData, true )

	// lock fund to the script.
	// Check: setting to zero, AND having a change output,
	//        results in a call to estimateFee()
	const lockingTxid = await sendLockingTx(lockingScript, privateKey, amount) //used to be 546

	console.log('CONTINUE funding txid:      ', lockingTxid)

	return lockingTxid
}

//export /* */
async function buildAndSendUpdateLockingTxWithThisInitialState(theData, amount) {
	var lockingScript = generateUpdateLockingScript( theData, true )

	// lock fund to the script.
	// Check: setting to zero, AND having a change output,
	//        results in a call to estimateFee()
	const lockingTxid = await sendLockingTx(lockingScript, privateKey, amount) //used to be 546

	console.log('UPDATE funding txid:      ', lockingTxid)

	return lockingTxid
}

//export /* */
async function buildAndSendTransientLockingTxWithThisInitialState(tNumber, theData, amount) {
	var lockingScript = generateTransientLockingScript( tNumber, theData, true )

	// lock fund to the script.
	// Check: setting to zero, AND having a change output,
	//        results in a call to estimateFee()
	const lockingTxid = await sendLockingTx(lockingScript, privateKey, amount) //used to be 546

	console.log('TRANSIENT-' + tNumber + ' funding txid:      ', lockingTxid)

	return lockingTxid
}

//export /* */
async function buildAndSendBitGroupLockingTxWithThisInitialState(theData, amount) {

	var lockingScript = generateBitGroupLockingScript( theData, true )

	// lock funds to the script.
	//console.log("BitGroup locking script: " + lockingScript)
	const lockingTxid = await sendLockingTx(lockingScript, privateKey, amount)

	console.log('BITGROUP funding txid:      ', lockingTxid)

	return lockingTxid
}

function generateP2PKHList() {
	let p2pkhList = ''
	for (let i = 0; i < MAX_PKHS; i++) {
		const moduloIndex = i % MAX_PKHS
		// alternate builder PKHs to add
		if ( moduloIndex === 0 ) {
			p2pkhList += 'dcfe9b987fd242cce57f970994afa9bfe4ee69e0'    // p2pkh
		} else if ( moduloIndex === 1 ) {
			p2pkhList += 'da807b170ac9bb4ae641a9e4da45c235b95f9cba'    // p2pkh
		} else if ( moduloIndex === 2 ) {
			p2pkhList += '2108a90eb9508bcfc1b1ec42fbc87ff7b5ce92ae'    // p2pkh
		} else if ( moduloIndex === 3 ) {
			p2pkhList += '8128e7d54a00a3011180df0f8fae57829a1a1f65'    // p2pkh
		} else if ( moduloIndex === 4 ) {
			p2pkhList += '55e43a2edb9de30e47f138961915bdef66fcfe0d'    // p2pkh
		} else {
			p2pkhList += '7b7c9971a63ff61bc3e5bb91951027609c4a7e63'    // p2pkh
		}
	}
	console.warn("Generated P2PKH list of length " + MAX_PKHS + ": ", p2pkhList)
	return p2pkhList
}

function generateBuilderStateList( numBuilders ) {
	let builderStateList = ''
	for (let i = 0; i < numBuilders; i++) {
		const moduloIndex = i%6
		// alternate builder PKHs to add
		if ( moduloIndex === 0 ) {
			builderStateList += 'dcfe9b987fd242cce57f970994afa9bfe4ee69e0'    // p2pkh
			builderStateList += '918c6b3b6e6faa6499e1b422e93be00650fb41e4'    // rabin PKH
			builderStateList += '00'                                          // fee-reduction vote
		} else if ( moduloIndex === 1 ) {
			builderStateList += 'da807b170ac9bb4ae641a9e4da45c235b95f9cba'    // p2pkh
			builderStateList += '31b95a2f4c75eab82b747d009cd90a4cd6fb3553'    // rabin PKH
			builderStateList += '00'                                          // fee-reduction vote
		} else if ( moduloIndex === 2 ) {
			builderStateList += '2108a90eb9508bcfc1b1ec42fbc87ff7b5ce92ae'    // p2pkh
			builderStateList += '5278d88d615f56f8c3b349fde299be59af242783'    // rabin PKH
			builderStateList += '00'                                          // fee-reduction vote
		} else if ( moduloIndex === 3 ) {

			builderStateList += '8128e7d54a00a3011180df0f8fae57829a1a1f65'    // p2pkh
			builderStateList += 'f057ed57e61a2edc9a9e07a7ba4bcbbf1371f8fc'    // rabin PKH
			builderStateList += '00'                                          // fee-reduction vote
		} else if ( moduloIndex === 4 ) {
			builderStateList += '55e43a2edb9de30e47f138961915bdef66fcfe0d'    // p2pkh
			builderStateList += '9d8f126884558fd0ab657d1a2df538f4dd8fa0a3'    // rabin PKH
			builderStateList += '00'                                          // fee-reduction vote
		} else {
			builderStateList += '7b7c9971a63ff61bc3e5bb91951027609c4a7e63'    // p2pkh
			builderStateList += '2bea772058241f2a980b22d32a31a317e0f5baa7'    // rabin PKH
			builderStateList += '00'                                          // fee-reduction vote
		}
	}
	console.warn("Generated Builder state list of length " + numBuilders + ": ", builderStateList)
	return builderStateList
}

//export /* */
function buildHardcodedBuilderPrepStateData() {

    console.log("buildHardcodedBuilderPrepStateData(): using smallest output of " + twoByteLE(SMALLEST_OUTPUT))

    const randomGenesisBlock = 1100000 + Math.floor((Math.random() * 256 * 256));
    const randomCurrentBlock = randomGenesisBlock + Math.floor((Math.random() * 256 * 64));
    console.log("buildHardcodedBuilderPrepStateData(): using randomGenesisBlock, and randomCurrentBlock of " + randomGenesisBlock + ", and " + randomCurrentBlock)

    //const builderStateList = generateBuilderStateList( 1 )
    const theData   = //builderStateList
					//+ '01'  // builderCount
					'00'  // NO BUILDERS at the start
                    + fourByteLE( randomGenesisBlock )	//GENESIS_BLOCK_HEX		// genesis block
                    + fourByteLE( randomCurrentBlock )	//GENESIS_BLOCK_HEX		// current blockNum
                    + twoByteLE(SMALLEST_OUTPUT)
                            // blank appendage (variable size)
                    + '00'	// 'counter' (appendage length)
                    + '62'	// 'b'uilderPrep mode

    console.log("\n\nHERE's the initial data for BuilderPrep: " + theData)
    return theData
}

//export /* */
function buildHardcodedAppendStateData() {

    console.log("buildHardcodedAppendStateData(): using smallest output of " + twoByteLE(SMALLEST_OUTPUT))

    const randomGenesisBlock = 1100000 + Math.floor((Math.random() * 256 * 256));
    const randomCurrentBlock = randomGenesisBlock + Math.floor((Math.random() * 256 * 64));
    console.log("buildHardcodedAppendStateData(): using randomGenesisBlock, and randomCurrentBlock of " + randomGenesisBlock + ", and " + randomCurrentBlock)

    const builderStateList = generateBuilderStateList( MAX_PKHS )
    const theData   = builderStateList
                    + fourByteLE( randomGenesisBlock )	//GENESIS_BLOCK_HEX		// genesis block
                    + fourByteLE( randomCurrentBlock )	//GENESIS_BLOCK_HEX		// current blockNum
                    + twoByteLE(SMALLEST_OUTPUT)
                            // blank appendage (variable size)
                    + '00'	// 'counter' (appendage length)
                    + '58'	// 'X'pansion modes

    console.log("\n\nHERE's the initial data for APPEND: " + theData)
    return theData
}

//export /* */
function buildHardcodedEbfraStateData() {

const randomGenesisBlock = 1100000 + Math.floor((Math.random() * 256 * 256));
const randomCurrentBlock = randomGenesisBlock + Math.floor((Math.random() * 256 * 64));

	console.log("buildHardcodedEbfraSpeakerStateData(): setting terminalBlock/deadline, and rabinPKH.")
	console.log("Using random blockHeight of ", randomCurrentBlock)

	const theData	= fourByteLE( randomCurrentBlock )  // random deadline/expiration
					+ 'b2ac1a5dd557c5211e8c04f8b9d25b7f659fc159'  // the Builder RabinPKH
					+ '65' // mode 'e'

    console.log("\n\nHERE's the initial data for an EBFRA: " + theData)
    return theData
}

//export /* */
function buildHardcodedContinueStateData() {
	console.log("buildHardcodedContinueStateData(): using smallest output of " + twoByteLE(SMALLEST_OUTPUT))

	const namePathHex = "6c6d63"     // lmc
	const namePathLen = namePathHex.length / 2
	//const p2pkh = '9dee81b2c0a1ecf85de3b8ea1c389e2a9fc2e215'
	const p2pkh = '0000000000000000000000000000000000000000'
	let qc = ''

	//const unclaimed = getYesNoFromUser("Shall the mode be UNclaimed ('P') ?")
	const unclaimed = true   // '50'  P  else K/p
	const preClaim  = false
	let mode //= '50'  // P
	//const mode = '4B'  // K
	//const mode = '70'  // p

	const builderStateList = generateBuilderStateList( MAX_PKHS )
	let preData = ''

	const randomGenesisBlock = 1100000 + Math.floor((Math.random() * 256 * 256));
	const randomCurrentBlock = randomGenesisBlock + Math.floor((Math.random() * 256 * 64));
	console.log("buildHardcodedContinueStateData(): using randomGenesisBlock, and randomCurrentBlock of " + randomGenesisBlock + ", and " + randomCurrentBlock)

	if ( unclaimed ) {
		mode = '50' // 'P' unclaimed
	} else {
		if ( preClaim ) {
			mode = '4b'		// 'K'  claimed
			qc = '0000'
		} else {
			console.log("Ok. This will be an ALREADY-claimed locking tx ('p').")
			mode = '70'		// 'p'  already claimed
			qc = '0100'
			preData = '0000000000000000000000000000000000000000'    // rabinPkhOfPotentialBuyer
			          + fourByteLE( randomCurrentBlock )            // NEW: potentialSaleMinBlockHex
			          + '00'            // potentialSaleFlag
			          + '0000000000'    // priceInSats
					  + p2pkh           // 20-byte (160-bit) p2pkh of owner (else zeroes)
		}
		preData = preData
		        + qc        // quarterly count (should be 0000 for K. should be larger for p, but whatever)
		        + '01'          // owner count
		        + '82591200'    // renewal deadline
		        + '748fc2b2cbad49e520f1d2cdfc91265318fdff3c'    // Publisher's Rabin PKH
	}

	const theData   = builderStateList
	                + fourByteLE( randomGenesisBlock )     // genesis block
	                + fourByteLE( randomCurrentBlock )     // current block
	                + twoByteLE(SMALLEST_OUTPUT)
	                + namePathHex                          // appendage (variable size)
	                + oneByteLE(namePathLen)               // 'counter' (appendage length)
	                + mode	// mode 'K'eys applied OR 'P' unclaimed

	console.log("\n\nHERE's the initial data for CONTINUE:  predata '" + preData
			+	"'  remainder of data " + theData)
	return preData + theData
}

//export /* */
function buildHardcodedUpdateStateData() {
	console.log("buildHardcodedUpdateStateData(): using smallest output of " + twoByteLE(SMALLEST_OUTPUT))

	const randomQC = fourRandomBytes().substring(0, 4)
	const randomOC = fourRandomBytes().substring(0, 2)
	console.log("buildHardcodedUpdateStateData(): using random QuarterCount and OwnerCount of " + randomQC + ", and " + randomOC)

	const p2pkhList = generateP2PKHList()

	const theData   = '00'          // initial voteTally
	                + '0000'        // initial periodicCount
	                + '15131400'    // renewal block deadline
	                + '748fc2b2cbad49e520f1d2cdfc91265318fdff3c'    // publisherPKH
	                + p2pkhList     // preserved PKH list - now MAX_PKH of them, always
	                + '308c1100'    // genesis block
	                + 'f5c41300'    // current block
	                + twoByteLE(SMALLEST_OUTPUT)    // smallest output
	                + randomQC      // quarterly count
	                + randomOC      // owner count
	                + '6701'        // appendage + counter
	                + '55'          // mode 'U'pdate

	console.log("\n\nHERE's the initial data for UPDATE: " + theData)
	return theData
}

function fourRandomBytes() {
	const outpointSnippetADec = Math.floor((Math.random() * 256 * 256));
	const outpointSnippetBDec = Math.floor((Math.random() * 256 * 256));
	const outpointSnippetA = twoByteLE(outpointSnippetADec)
	const outpointSnippetB = twoByteLE(outpointSnippetBDec)

	console.log("fourRandomBytes(): returning " + outpointSnippetA + outpointSnippetB)
	return outpointSnippetA + outpointSnippetB
}

//export /* */
function buildHardcodedTransientStateData(whichT) {
	console.log("buildHardcodedTransientStateData(): using smallest output of " + SMALLEST_OUTPUT
			+ " (LE hex: " + twoByteLE(SMALLEST_OUTPUT) + ")")

	// To avoid a scriptHash collision (something that should never happen in 'production', use a random outpointSnippet)

	const randomOutpointSnippet = fourRandomBytes();
	console.log("buildHardcodedTransientStateData(): using random outpointSnippet of " + randomOutpointSnippet)

	const modeHexChar = '3' + whichT
	console.log("buildHardcodedTransientStateData(): using mode " + modeHexChar)
	const theData   = randomOutpointSnippet
	                + '64000000'    // (LE) price to guestPost - in sats
	                + '00000200'    // max content length (128k)
	                + '748fc2b2cbad49e520f1d2cdfc91265318fdff3c'    // Publisher PKH
	                + 'f5c41300'    // currentBlock               (1295605)
	                //+ '15131400'    // maxBlock / renewalDeadline (1315605)
                    + '60e31600'     // maxBlock / renewalDeadline (1500000)  - but CHEATING (potentially FAR in the future)
	                + twoByteLE(SMALLEST_OUTPUT)        // smallest output
	                + '0c'          // frozen maxDownCounter
	                + '0a'          // initial downCounter      set this low, so we can test edge case
	                + '000000'      // NEW: consecutivePostsHosted
	                + '0100' + '0200' + '01'            // fixed address (currently 2 bytes periodicCount, 2-byte quarterly count, 1-byte ownerCount)
	                + '05'          // fixed address len
	                + '72727703'        // app + counter
	                + modeHexChar   // (ascii '1') mode T.  Used to be '54'.

	console.log("\n\nHERE's the initial (hardcoded, arbitrary) data for TRANSIENT: " + theData)
	return theData
}

//export /* */
function buildHardcodedBitGroupStateData() {
	console.log("buildHardcodedBitGroupStateData(): building arbitrary hardcoded data...")

	const randomQC = fourRandomBytes().substring(0, 4)
	const randomOC = fourRandomBytes().substring(0, 2)
	console.log("buildHardcodedBitGroupStateData(): using random QuarterCount and OwnerCount of " + randomQC + ", and " + randomOC)

	const theData   = //''              // postText
					  '748fc2b2cbad49e520f1d2cdfc91265318fdff3c'  // user: owner rabinPKH
					+ 'c085725573f714747a4c617a2935d5b9021f1d97'  // user: owner P2PKH
					+ 'f5c41300'      // user: recentPostBlock
					+ '05'            // user: owner
					+ '01'            // user: bounty paid
					+ '0100'          // number of users
					//+ '00000000'	  // post length
					+ '00000000'      // operations/post number
					+ 'f5c41300'      // currentBlock               (1295605)
					+ '00000000'      // started at post
					+ '467269656e6473'// group name
					+ '07'            // group name length
					+ '22020000'      // bounty per user
					+ '00010000'      // max post size of 256
					+ '1e000000'      // price per post of 30 sats
					+ '15131400'      // maxBlock / renewalDeadline (1315605)
				//	+ 'f5c41300'      // started at block
					+ '0100'          // periodic count
					+ randomQC        // quarterly count
					+ randomOC        // owner count
					+ '6201'          // branchName + branchNameLen
					+ '47'            // (ascii 'G') mode 0x47

	console.log("\n\nHERE's the initial (hardcoded, arbitrary) data for BITGROUP: " + theData)
	return theData
}

//export /* */
function buildHardcodedAdministerGroupStateData() {

	if ( bitGroupScriptHexString.length === 0 ) {
		console.error("\nERROR: Length of BitGroup hex string is zero.\n")
		console.log("Because we're probably trying to generate a locking script, we'll TEMPORARILY "
				+	"define a BOGUS BitGroup hash. It will need to be replaced immediately.")
	//	getYesNoFromUser("Do you understand that we're setting a BOGUS BitGroup hash, "
	//					+"and that you'll need to replace it (and everything generated with it) almost immediately?")
		console.error("You need to first choose 'automate contract hash calculations'.")
		throw new Error('10013');
	}

	const randomQC = fourRandomBytes().substring(0, 4)
	const randomOC = fourRandomBytes().substring(0, 2)
	console.log("buildHardcodedAdministerGroupStateData(): using random QuarterCount and OwnerCount of " + randomQC + ", and " + randomOC)

	const hashOfBitGroupScriptHexString = simpleHash(bitGroupScriptHexString)

	console.log("buildHardcodedAdministerGroupStateData(): building arbitrary hardcoded data...")
	const theData   = '748fc2b2cbad49e520f1d2cdfc91265318fdff3c'  // user: owner rabinPKH
					+ 'c085725573f714747a4c617a2935d5b9021f1d97'  // user: owner P2PKH
					+ 'f5c41300'                    // user: recentPostBlock
					+ '05'                          // user: owner
					+ '01'                          // user: bounty paid
					+ '0100'                        // number of users
					+ hashOfBitGroupScriptHexString // hash of BitGroup contract code
					+ '00000000'                    // operations/post number
					+ 'f5c41300'                    // currentBlock               (1295605)
					+ '00000000'                    // started at post
					+ '467269656e6473'              // group name
					+ '07'                          // group name length
					+ '22020000'                    // bounty per user
					+ '00010000'                    // max post size of 256
					+ '1e000000'                    // price per post of 30 sats
					+ '15131400'                    // maxBlock / renewalDeadline (1315605)
				//	+ 'f5c41300'                    // started at block
					+ '0100'                        // periodic count
					+ randomQC						//'0200'                        // quarterly count
					+ randomOC						//'01'                          // owner count
					+ '6201'                        // branchName + branchNameLen
					+ '67'                          // (ascii 'g') mode 0x67

	console.log("\n\nHERE's the initial (hardcoded, arbitrary) data for ADMINGROUP: " + theData)
	return theData
}

//export /* */
function buildHardcodedDialogStateData() {

    console.log("buildHardcodedDialogStateData():")

	const prevTxId  = '00112233445566778899AABBCCDDEEFF00112233445566778899AABBCCDDEEFF'
	const rabinPkhA = '918c6b3b6e6faa6499e1b422e93be00650fb41e4'    // rabin PKH A
	const rabinPkhB = '31b95a2f4c75eab82b747d009cd90a4cd6fb3553'    // rabin PKH B

	const randomMaxBlock = 1000000 + Math.floor((Math.random() * 256 * 256));

    const theData   = prevTxId
                    + rabinPkhA
                    + rabinPkhB
                    + fourByteLE(randomMaxBlock)
                    + '00000000'	// 'counter' (numPosts)
                    + '44'	// 'D'ialog mode

    console.log("\n\nHERE's the initial data for DIALOG: " + theData)
    return theData
}

//export /* */
function buildHardcodedAskBidStateData() {
	const randomGenesisBlock = 1100000 + Math.floor((Math.random() * 256 * 256));
	const randomCurrentBlock = randomGenesisBlock + Math.floor((Math.random() * 256 * 64));
	console.log("buildHardcodedAskBidStateData(): using randomCurrentBlock of " + randomCurrentBlock)
	const renewalDeadline = randomCurrentBlock + 50000

	console.log("buildHardcodedAskBidStateData(): using smallest output of " + SMALLEST_OUTPUT
	+ " (LE hex: " + twoByteLE(SMALLEST_OUTPUT) + ")")

	const theData   = '0000'        // zero balks at start
					+ '00'          // zero bids at start

					+ '00'          // blank mainLine Rabin PubKey at start  <===  Only gets revealed when 'sold'
					+ '00'          // blank authCode at start
					+ '0000'          // blank authCode padding at start

					+ '00000000'    // default beforeBlock while not relevant state

					+ '0000000000000000'    //FIXME: initial balance of BID/BALK funds: 0
					+ '00'          // initial 'bestBidNum':  0.  <---
					+ '00'          // initial 'worstBidNum':  0.     <---

					+ '0000000000'  // For Sale: 0 (not 50000) sats initial asking price   <===== optional at start
					+ '748fc2b2cbad49e520f1d2cdfc91265318fdff3c'    // Owner's (local) Rabin PKH  <---  can be updated each time owner builds a tx

					+ '748fc2b2cbad49e520f1d2cdfc91265318fdff3c'  // mainLineOwnerRabinPKH  <===  THIS WOULD NORMALLY BE COPIED (hardcoded-for-duration) FROM MAINLINE AT START
					+ fourByteLE(renewalDeadline)		//'82591200'    // renewal deadline   <=== Set (harcoded-for-duration) at start
					+ fourByteLE(randomCurrentBlock)	//'328c1100'    // current block	<=== initialized at start
					+ '0000'        // initial ops counter - set to - at start
					+ twoByteLE(SMALLEST_OUTPUT)        // smallest output (satoshis)

					+ '0100'        // QuarterlyCount 1
					+ '01'          // owner 1

					+ '64656363'      // name/path: aecc
					+ '04'          // namePath length
					+ '4E'          // 'N'ot yet open - so owner can decide WHEN to start negotiations.
									//     Alternative: subMode 'O'pen.
					+ '4E'          // Mode 'N'egotiations
	return theData
}

//export /* */
async function buildAndSendAdministerGroupLockingTxWithThisInitialState(theData, amount) {

	var lockingScript = generateAdministerGroupLockingScript( theData, true )

	// lock funds to the script.
	//console.log("AdministerGroup locking script: " + lockingScript)
	const lockingTxid = await sendLockingTx(lockingScript, privateKey, amount)

	console.log('ADMINISTERGROUP funding txid:      ', lockingTxid)

	return lockingTxid
}

//export /* */
async function buildAndSendDialogLockingTxWithThisInitialState(theData, amount) {

	var lockingScript = generateDialogLockingScript( theData, true )

	// lock funds to the script.
	//console.log("Dialog locking script: " + lockingScript)
	const lockingTxid = await sendLockingTx(lockingScript, privateKey, amount)

	console.log('DIALOG funding txid:      ', lockingTxid)

	return lockingTxid
}

//export /* */
async function buildAndSendAskBidLockingTxWithThisInitialState(theData, amount) {

	var lockingScript = generateAskBidLockingScript( theData, true )

	// lock fund to the script.
	// Check: setting to zero, ANS having a change output,
	//        results in a call to estimateFee()
	const lockingTxid = await sendLockingTx(lockingScript, privateKey, amount) //used to be 546
	//lockingTx.outputs[0].setScript(askBid.lockingScript)
//	lockingTx.sign(privateKey)
//	let lockingTxid = await sendTx(lockingTx)
		//'913c8ede01e58a34bfcfeebbe2e7c1c45003272cbd4ce89970fbac4dfeafb788' 
	console.log('ASKBID funding txid:      ', lockingTxid)

	return lockingTxid
}

//export /* */
async function libBuildOnBuilderPrepOutput(theState, previousTxid, whichOutput,
										builderState,                 // for web app, set to payoutP2PKH + builderRabin + builderVote
										theDB,           // to be passed to addRawTx
										dbAddRawTx,
										specifiedPrivateKey) {

	const privKey = new bsv.PrivateKey.fromWIF(specifiedPrivateKey)
	const specifiedPubKey = bsv.PublicKey.fromPrivateKey( privKey )
	const specifiedBsvChangePKH = bsv.crypto.Hash.sha256ripemd160(specifiedPubKey.toBuffer('hex')).toString('hex')

	console.log("\nWe're going to build off of output " + whichOutput + " of BUILDER PREP tx " + previousTxid)
	console.log("\nHere's the state of the BuilderPrep output you're about to build upon: ", theState)

	const theGenesisBlockLE = theState.genesisBlock

	console.log("GenesisHex embedded in current state: " + theGenesisBlockLE)

	// This is the PREVIOUS scriptPubKey: locking script plus previous state
	const oldScriptPubKey = generateBuilderPrepLockingScript( theState.stateHex, false )

	// main line output (either BuilderPrep, or Append), plus EBFRA output
	var newScriptPubKeys = new Array( 2 )

	const newBlockInt = Buffer.from(theState.blockNum, "hex").readUInt32LE(0)
	//const newBlockInt = prevBlockInt

	console.warn("NOTE: using the blockHeight - from previous transaction: " + newBlockInt)   //  append (and soon continue) NO LONGER requires +1 (from previous)

	const newBlockHex = fourByteLE(newBlockInt)
	console.log('  new block hex: ', newBlockHex)

	console.log("libBuildOnBuilderPrepOutput(): building up the PKHs list. Starting with " + theState.builderPKHs.length + " existing entries...")

	var theBuilderState = builderState;

	// only allow MAX_PKHS PKHs
	//FIXME: kludgy
	var pkhQueue = []
	//console.log('\nlibBuildOnBuilderPrepOutput(): the state payout PKHs has length ' + theState.builderPKHs.length)
	var buildersStateString = ''
	for ( var i = 0; i < theState.builderPKHs.length; i++ ) {
		pkhQueue.push( theState.builderPKHs[i] )
		pkhQueue.push( theState.builderRabins[i] )
		pkhQueue.push( theState.builderVotes[i] )
	}

	if ( pkhQueue.length !== theState.builderCount * 3 ) {
		throw new Error("22839: CODE ERROR: wrong number of fields in builder state? " + pkhQueue.length)
	}

	// NOTE: We DON'T shaft any builders in this PREP phase

	for ( var j = 0; j < pkhQueue.length; j++ ) {
		buildersStateString += pkhQueue[j]
	}
	buildersStateString += theBuilderState
//FIXME: check on this
	console.error('libBuildOnBuilderPrepOutput(): NEW buildersStateString: \'' + buildersStateString + '\'')
	console.log("  (a concatenated list of BUILDER P2PKHs, PKHs, and votes)")

	const newBuilderCountInt = theState.builderCount + 1
	const newBuilderCountHex = oneByteLE( newBuilderCountInt )
	console.log('libBuildOnBuilderPrepOutput(): added a builder. We now have ' + newBuilderCountHex)

	// We always spawn an EBFRA.
	// We typically maintain the 0th output as a BuilderPrep,
	// but finally transition to Append

	// early-on (first MAX_PKH iterations) we build 'b'uilderPrep outputs, so,
	// we don't need the append script code to be included in the ScriptSig
	var optionalAppendScriptHex = ''

	// get amount from previous tx (theState)
	//FIXME: note: readUInt() only goes up to 6 bytes - not the 8 we actually need
	const oldAmount = Buffer.from(theState.contractSatoshis, "hex").readUIntLE(0,6)

	const newBuilderRabinPKH = theBuilderState.substring(40, 80)
	const newBuilderVote     = theBuilderState.substring(80, 82)
	if ( newBuilderVote !== '00' ) {
		throw new Error("88355: Invalid builder vote. We start with 00 in this prep phase.")
	}

	console.log("BTW: new Builder Rabin PKH: " + newBuilderRabinPKH)

	var outputAmounts = []
	outputAmounts[0] = oldAmount  // smallestAmount     builderPrep or append
	outputAmounts[1] = oldAmount  // smallestAmount     ebfra
	var expandModeChar
	const smallestAmount = theState.smallestAmount //Buffer.from(theState.smallestAmount, "hex").readUInt16LE(0)

	if ( newBuilderCountInt === MAX_PKHS ) {
		console.log("libBuildOnBuilderPrepOutput(): transitioning to an APPEND output")

		const pureAppendLockingScript = generateAppendLockingScript('', false)

		optionalAppendScriptHex = appendScriptHexString   // For transaction which transitions to Append output, we include the output script in the script sig

		console.log('\nlibBuildOnBuilderPrepOutput():    (we\'re inserting output #0 - a \'X\'append-mode output)\n')
		expandModeChar = '58'  // 'X'

		// when appendage is long enough, we allow publishing - first/zeroth contract output (from mode of 0x50 - 'P', or 'K')
		// Here, we're still presenting path-eXpanding options, but we allow the path-builder to pre-claim a path, by declaring his 'K'ey (PKH)
		//                                                                                                    (P)ublish                                      appendage and counter do NOT change
		// NOTE: we're using the CONTINUE locking script - injecting code
		const appendage = ''
		console.log("libBuildOnBuilderPrepOutput():      using blank appendage")
//FIXME: use buildAppendScriptPubKey()    <---------
		newScriptPubKeys[0] = pureAppendLockingScript
							+ ' OP_RETURN '
							+ buildersStateString
							+ theGenesisBlockLE
							+ newBlockHex
							+ smallestAmount
							+ appendage
							+ '00' //oneByteLE(0)  // appendage has length zero
							+ expandModeChar
		console.log('Setting newScriptPubKeys[0] to (without commas): locking script + OP_RETURN +\n'
					+ '    Builders State String:    ' + buildersStateString + ',\n'
					+ '    Genesis Block:            ' + theGenesisBlockLE + ',\n'
					+ '    newBlock:                 ' + newBlockHex + ',\n'
					+ '    smallestAmount (dust):    ' + smallestAmount + ',\n'
					+ '    branchName:               ' + appendage + ',\n'
					+ '    branchNameLength (level): 00,\n'
					+ '    mode:                     ' + expandModeChar)

	} else {
		const pureBuilderPrepLockingScript = generateBuilderPrepLockingScript('', false)
		console.log("libBuildOnBuilderPrepOutput(): setting up output 0 as yet another BuilderPrep output")

		const newModeHexChar = '62' //b
		console.log("libBuildOnBuilderPrepOutput():     note: newModeHexChar (Xpando) is hardcoded to " + newModeHexChar)

		console.log("libBuildOnBuilderPrepOutput(): building the appendages (and 'newScriptPubKeys' for each output\n")

		const tempAppendage = theState.namePath

	//FIXME: isn't there a routine we can call to build this?
		const newScriptPubKey	= pureBuilderPrepLockingScript
									+ ' OP_RETURN ' + buildersStateString + newBuilderCountHex
									+ theGenesisBlockLE + newBlockHex
									+ smallestAmount
									+ tempAppendage + '00'
									+ newModeHexChar
		console.log('libBuildOnBuilderPrepOutput(): Setting newScriptPubKeys[out 0'
						+ '] to (without commas): locking script + OP_RETURN +\n'
						+ '        ' + buildersStateString + ','
						+ theGenesisBlockLE + ',' + newBlockHex + ',' + smallestAmount + ','
						+ tempAppendage + ',00,' + newModeHexChar)
		newScriptPubKeys[0] = newScriptPubKey
	}

	console.log("libBuildOnBuilderPrepOutput(): setting up newScriptPubKeys as an array of 2 outputs")



	// The new builder's vote is NO, spawn an EBFRA output - so that
	// it can later maybe vote YES, and publish an EBFRA
	if ( newBuilderVote !== '00' ) {
		throw new Error("44444: initial builder votes should be 00")
	}
	console.warn("libBuildOnBuilderPrepOutput(): Including an EBFRA output")
	const genesisBlockInt = Buffer.from(theGenesisBlockLE, "hex").readUInt32LE(0)
	console.log('  genesis block int: ', genesisBlockInt)

	const pureEbfraLockingScript = generateEBFRALockingScript('', false)
	const sizeOfArray = newScriptPubKeys.length

	console.log("NOTE: size of newScriptPubKeys[]: " + sizeOfArray)

//FIXME: why not a buildEbfraSpeakerScriptPubKey()
	const newEbfraScriptPubKey = pureEbfraLockingScript + ' OP_RETURN '
//FIXME: define or derive 13 years too
								+ fourByteLE(genesisBlockInt + 13 * BLOCKS_IN_ONE_YEAR)
								+ newBuilderRabinPKH
								+ theState.namePath
								+ oneByteLE( theState.namePath.length / 2 )
								+ '65'
	newScriptPubKeys[ 1 ] = newEbfraScriptPubKey

	const payoutAmount = 0  //NOTE: we've removed any payouts during append/build phase (the 'tree-branching' portion of the graph)
	const payScriptQueue = []


	const outputIndexToTake = whichOutput
	console.log("libBuildOnBuilderPrepOutput(): USER passed-in outputIndexToTake of " + outputIndexToTake)

	const results = await executeBuilderPrepContract(specifiedPrivateKey,
								previousTxid,
								oldScriptPubKey,	oldAmount,
								newScriptPubKeys,	outputAmounts, //newSmallestAmount,
								theBuilderState,
								payScriptQueue,		payoutAmount,
								newBlockInt,		specifiedBsvChangePKH, //EXT_FUNDING_CHANGE_PKH,
								outputIndexToTake,
								ebfraScriptHexString,
								optionalAppendScriptHex)
	console.log("libBuildOnBuilderPrepOutput(): results from Builder Prep: ", { txid: results.txid, change: results.change})

	if ( results.txid.startsWith('11111111111111111111111111111111') ) {
		console.log("We've got a bogus tx, we'll save its raw bytes")
		// insert results.rawTx into table for this purpose
		await dbAddRawTx(theDB, results.txid, results.rawTx)
	}

	return results.txid
}

/**
 *
 * @param {*} theState               // State of the output on which we'll build
 * @param {*} previousTxid           // TxId of tx on which we'll build
 * @param {*} whichOutput            // base-0 output index on which we'll build
 * @param {*} solicitPKH             // function for console app, null for web app
 * @param {*} solicitResponse        // function for console app, null for web app
 * @param {*} builderState           // null for console app, builderState (payoutPKH + rabin + vote) for web app
 * @param {*} paramPreClaim          // null for console app, Y/N for web app
 * @param {*} paramResponseRabinPKH  // null for console app, pre-claim Rabin PKH for web app
 * @param {*} theDB                  // db connection
 * @param {*} dbAddRawTx             // db function to add the raw tx
 * @param {*} currentBlockHeight     // the current blockchain height
 */
//export /* */
async function libBuildOnAppendOutput(theState, previousTxid, whichOutput,
										solicitPKH,      // for console app, set to function
										solicitResponse, // for console app, set to function
										builderState,                 // for web app, set to payoutP2PKH + builderRabin + builderVote
										paramPreClaim,            // for web app, set to Y or N
										paramResponseRabinPKH,    // for web app, set to values
										theDB,           // to be passed to addRawTx
										dbAddRawTx,
										currentBlockHeight = 0,
										specifiedPrivateKey,

										optionalAdditionalBalance,

										optionalSilentBuild = false,
										optionalUTXOs = null) {

	const privKey = new bsv.PrivateKey.fromWIF(specifiedPrivateKey)
	const specifiedPubKey = bsv.PublicKey.fromPrivateKey( privKey )
	const specifiedBsvChangePKH = bsv.crypto.Hash.sha256ripemd160(specifiedPubKey.toBuffer('hex')).toString('hex')

	console.log("\nWe're going to build off of (extend) output " + whichOutput + " of APPEND tx " + previousTxid)
	console.log("\nHere's the state of the Append output you're about to build upon: ", theState)

	const theGenesisBlockLE = theState.genesisBlock

	console.log("GenesisHex embedded in current state: " + theGenesisBlockLE)

	// This is the PREVIOUS scriptPubKey: locking script plus previous state
	const oldScriptPubKey = generateAppendLockingScript( theState.stateHex, false )

	//iter0NewChars

	const pureAppendLockingScript = generateAppendLockingScript('', false)

	//FIXME: in the future: 26, 37 outputs (+Publish)
	const numExtendOutputs = APPEND_FAN_OUT  //FIXME: rename numExpansionOutputs or numAppendages or numAppendOutputs

	var newScriptPubKeys
	const level = theState.namePath.length / 2
	console.log("libBuildOnAppendOutput(): set 'level' to " + level)


	let newBlockInt
	const prevBlockInt = Buffer.from(theState.blockNum, "hex").readUInt32LE(0)
	if ( currentBlockHeight === 0 ) {
		// If we've been passed a blockHeight of 0, we're being asked to use the minimal-allowed blockHeight
		newBlockInt = prevBlockInt
		console.warn("NOTE: using the next blockHeight - from previous transaction: " + newBlockInt)   //  append (and soon continue) NO LONGER requires +1 (from previous)
	} else {
		newBlockInt = currentBlockHeight
		console.warn("NOTE: using the CURRENT blockHeight (NOT incrementing): " + newBlockInt)         //NOTE: if we use a FUTURE blockHeight, we can't easily 'fetch' a pending tx
		                                                                                               //      (WoC api doesn't allow)
	}
	const newBlockHex = fourByteLE(newBlockInt)
	console.log('  new block int: ', newBlockInt)
	console.log('  new block hex: ', newBlockHex)

	console.log("libBuildOnAppendOutput(): building up the PKHs list. Starting with " + theState.builderPKHs.length + " existing entries...")

	console.log("\nIn the future, other BUILDERS may build on (extend) this transaction which you're constructing. "
			+	"If others then CLAIM and PUBLISH from those branches, they'll need to pay you a nomimal fee. You need "
			+	"to specify a PublicKeyHash (PKH) where they could then send that fee. We call this a PAYOUT PKH.\n")
	var theBuilderState
	if ( solicitPKH != null ) {
		theBuilderState = solicitPKH("Enter the Payout PKH for you to receive BUILDER fees (example: c085725573f714747a4c617a2935d5b9021f1d97): ")
	} else {
		theBuilderState = builderState;
	}

	// only allow MAX_PKHS PKHs
	//FIXME: kludgy
	var pkhQueue = []
	//console.log('\nlibBuildOnAppendOutput(): the state payout PKHs has length ' + theState.builderPKHs.length)
	var buildersStateString = ''
	for ( var i = 0; i < theState.builderPKHs.length; i++ ) {
		pkhQueue.push( theState.builderPKHs[i] )
		pkhQueue.push( theState.builderRabins[i] )
		pkhQueue.push( theState.builderVotes[i] )
	}

	if ( pkhQueue.length !== MAX_PKHS * 3 ) {
		throw new Error("22839: CODE ERROR: wrong number of P2PKHs in state? " + pkhQueue.length)
	}
	const shaftedP2PKH = pkhQueue.shift()
	console.log('     SHAFTING "oldest" P2PKH from queue: ', shaftedP2PKH)
	const shaftedPKH = pkhQueue.shift()
	console.log('     SHAFTING "oldest" rabinPKH from queue: ', shaftedPKH)
	const shaftedVote = pkhQueue.shift()
	console.log('     SHAFTING "oldest" vote from queue: ', shaftedVote)

	for ( var j = 0; j < pkhQueue.length; j++ ) {
		buildersStateString += pkhQueue[j]
	}
	buildersStateString += theBuilderState
	console.log('\n\nlibBuildOnAppendOutput(): NEW buildersStateString: \'' + buildersStateString + '\'')
	console.log("  (a concatenated list of BUILDER P2PKHs, PKHs, and votes for this BRANCH)")


	const newCounter = oneByteLE(level + 1)
	console.log('libBuildOnAppendOutput(): the state payout P2PKHs will NOW have length: ' + newCounter)
	var renewalBlockDeadlineInt = 0


	// Maybe allow publishing from an output #0
	var extraOutput = 0

	const rabinOfPotentialSale = ''
	const potentialSaleMinBlock = ''
	const potentialSaleCounter = ''
	const optionalPrice = ''
	var quarterlyCountHex = ''
	var optionalExpCounter = ''
	var renewalBlockDeadline = ''
	var optionalPubPKH = ''

	var optionalContinueScriptHex = '99'  // early-on (first few iterations) we don't build a 'P'ublish or 'K'eysApplied output, so, we don't need the large continue script code to be included in the ScriptSig
	const preClaimOnLastIter = false

	// append a new char for each output in this iteration (except a 'P'ublish output, or change)
	var newCharsArray

	// get amount from previous tx (theState)
	// In practice, the contract output amount could drop to whatever the miners are willing to accept as non-dust
	//FIXME: note: readUInt() only goes up to 6 bytes - not the 8 we actually need
	const oldAmount = Buffer.from(theState.contractSatoshis, "hex").readUIntLE(0,6)

	const newBuilderRabinPKH = theBuilderState.substring(40, 80)
	const newBuilderVote     = theBuilderState.substring(80, 82)
	let extraScriptPubKey = 0
	if ( newBuilderVote === '00' ) {
		// Since the builder has not yet decided whether to reduce fees, we'll need
		// to build an extra (EBFRA) output - for a potential future EBFRA announcement
		extraScriptPubKey = 1
	}
	console.log("BTW: new Builder Rabin PKH: " + newBuilderRabinPKH)

	var outputAmounts = []
	var preClaim = 'n'
	var publishingModeChar
	const smallestAmount = theState.smallestAmount //Buffer.from(theState.smallestAmount, "hex").readUInt16LE(0)
	//FIXME: be clear on what we mean by level
	if ( level >= MIN_LEVEL_TO_CLAIM ) {//FIXME: in future, should only allow building from level 3?
		do {
			console.log("\n\nThe BRANCH/namePath for the transaction you're about to BUILD will be 0x"
					+ theState.namePath + " ('" + hexStringToAscii( theState.namePath ) + "'). "
					+ "'As of right now, no one has ever BUILT that BRANCH. It does not yet exist. You're about to create it.")
			console.log("\nIf you wish, you could also PRE-CLAIM this BRANCH for yourself. It costs money (BSV satoshis) EVERY YEAR, but would allow you to "
					+ "be the exclusive publisher at this BRANCH.")
			console.log("\nYou are NOT required to PRE-CLAIM this BRANCH. You're probably building this transaction for one of three reasons:")
			console.log("  - to expand the Bitcoin Shizzle Tree, and MAYBE collect nominal fees for your efforts, from other users")
			console.log("  - to reach a specific BRANCH that's a little further/longer (and this one is on the way)")
			console.log("  - to reach THIS namePath, and claim it for exclusive publishing rights")
			console.log("You could think of it as a Bitcoin tweetable account (also website domain, or a group chat). Most people wouldn't typically want to claim more than one.")

			console.log("If you intend to publish from this BRANCH you're about to build, you'll need to OWN it. "
					+	"If so, to avoid the slight chance that someone might CLAIM it immediately after you BUILD it, you may wish "
					+	"to PRE-CLAIM it. If you're building this BRANCH on the way to a longer branch, then there's no need "
					+	"to PRE-CLAIM this branch. For every BRANCH you build/grow, you also grow 37 buds - potential future branches.")
			console.log("\nAlternatively, you could find a different BRANCH that someone else has already BUILT, but not claimed.")
			if ( solicitResponse != null ) {
				preClaim = solicitResponse("\nWould you like to PRE-CLAIM this BRANCH ('" + hexStringToAscii( theState.namePath ) + "')? (Y/N) ")
			} else {
				preClaim = paramPreClaim;
				if ( solicitResponse === null && preClaim !== 'Y' && preClaim !== 'y' && preClaim !== 'N' && preClaim !== 'n') {
					throw new Error('10016');
				}
			}
		} while ( preClaim !== 'Y' && preClaim !== 'y' && preClaim !== 'N' && preClaim !== 'n' );

		var preClaimPublisherPKH = ''
		var sureThing
		if ( preClaim === 'Y' || preClaim === 'y' ) {
			// if laying claim to an asset, must pay a bond/bounty to mitigate mass-squatting
			const smallestAmtDecimal = Buffer.from(theState.smallestAmount, "hex").readUInt16LE(0)
			outputAmounts[0] = (smallestAmtDecimal * BOUNTY_MULTIPLIER_CONTINUE) + optionalAdditionalBalance
			console.log("\nNOTE: because you're pre-claiming, setting output 'bond/bounty' to the required "
						+ outputAmounts[0] + " satoshis (which is " + smallestAmtDecimal + " x " + BOUNTY_MULTIPLIER_CONTINUE + ")")

			do {
				console.log("\n\nTo secure your claim, and authorize future postings, you'll need to have a Rabin Private Key, and declare "
						+ "the PKH associated with it. The Rabin Private Key (there are TWO components to it) MUST be kept PRIVATE. The "
						+ "PKH is like a public ID for your key. Do NOT enter a PKH unless you have EXCLUSIVE access to the Rabin PRIVATE Key. "
						+ "If you don't have access to the key, you won't be able to post to your Shizzle. If your access is not EXCLUSIIVE, "
						+ "anyone who has it could post to your Shizzle, and easily lock you out - forever.")
				console.log("\nEXAMPLE Rabin PKH: 748fc2b2cbad49e520f1d2cdfc91265318fdff3c, or 0be3f9349938aed00f0b03ca9930e0f92e80c909")

				if ( solicitResponse != null ) {
					preClaimPublisherPKH = solicitResponse("\nPlease enter the Rabin PKH you'd like to declare for this ('"
												+ theState.namePath + "') namePath: ")
				} else {
					preClaimPublisherPKH = paramResponseRabinPKH;
				}
				if ( preClaimPublisherPKH.length === 40 ) {
					if ( solicitResponse != null ) {
						sureThing = solicitResponse("Are you sure this is the Rabin PKH you'd like to declare? (Y/N) ")
					} else {
						sureThing = 'y';
					}
				} else {
					console.log("\nThat's not a valid length for a PKH.")
					if ( solicitResponse === null ) {
						throw new Error('10015');
					}
				}
			} while ( sureThing !== 'y' && sureThing !== 'Y' );

			console.log("\n\nOnce you've PRE-CLAIMED this namePath, you'll be able to PUBLISH from your namePath.")
		} else {
			outputAmounts[0] = oldAmount  // smallestAmount
		}

		const pureContinueLockingScript = generateContinueLockingScript('', false)

		newCharsArray = fullNewChars[1]
		console.log("\nlibBuildOnAppendOutput(): setting up newCharsArray as an array of " + newCharsArray.length + " characters")

		optionalContinueScriptHex = continueScriptHexString   // For transaction which include a 'P'ublish/continue (or 'K') output, we include the output script in the script sig
		console.log('\nlibBuildOnAppendOutput(): Level is ' + level + ', so, starting with a newScriptPubKeys[] of size ', (numExtendOutputs + 1))
		newScriptPubKeys = new Array( numExtendOutputs + 1 + extraScriptPubKey )
		console.log('\nlibBuildOnAppendOutput():    (we\'re inserting output #0 - a \'P\'ublish-mode (or a pre\'K\'laim?) output)\n')
		publishingModeChar = '50'  // 'P'   //FIXME: rename modeCharPublish


		if ( preClaimPublisherPKH !== '' ) { //FIXME: test this //( i == numIterations-1 )) {
			console.log('\n >>> PRE-CLAIMING PATH!! <<<\n')
			//claimBday = newBlockInt
			//console.log("We're setting claim birthday to ", claimBday)
			renewalBlockDeadlineInt = newBlockInt + 365*144
			renewalBlockDeadline = fourByteLE(renewalBlockDeadlineInt)  //newBlockHex   FIXME: rename renewalBlockDeadlineHex
			//claimBday = newBlockInt
			console.log('NOTE: renewal block deadline is NOW + 365*144 = ', renewalBlockDeadlineInt)
			console.log('      or, hexLE ', renewalBlockDeadline)

			//rabinOfPotentialSale = '0000000000000000000000000000000000000000'
			//potentialSaleMinBlock = newBlockHex
			//potentialSaleCounter = '00'
			//optionalPrice = '0000000000'

			quarterlyCountHex = '0000'
			optionalExpCounter = '01'
			optionalPubPKH = preClaimPublisherPKH
			publishingModeChar = '4b'  // 'K'
		} else {
			console.log("\nlibBuildOnAppendOutput(): NOT pre-claiming the Publish output (#0), so, mode is 'P'. (FUTURE: IMPLEMENT ME - mode 'K')")
		}
		// when appendage is long enough, we allow publishing - first/zeroth contract output (from mode of 0x50 - 'P', or 'K')
		// Here, we're still presenting path-eXpanding options, but we allow the path-builder to pre-claim a path, by declaring his 'K'ey (PKH)
		//                                                                                                    (P)ublish                                      appendage and counter do NOT change
		// NOTE: we're using the CONTINUE locking script - injecting code
		const appendage = theState.namePath
		console.log("libBuildOnAppendOutput():      NOTE: for 'P'ublish output, using appendage of " + appendage)
//FIXME: use buildContinueScriptPubKey()    <---------
		newScriptPubKeys[0] = pureContinueLockingScript
							+ ' OP_RETURN '

							// These 4 actually stay blank when pre-claiming
							+ rabinOfPotentialSale
							+ potentialSaleMinBlock
							+ potentialSaleCounter
							+ optionalPrice

							+ quarterlyCountHex
							+ optionalExpCounter
							+ renewalBlockDeadline
							+ optionalPubPKH
							+ buildersStateString
							+ theGenesisBlockLE
							+ newBlockHex
							+ smallestAmount
							+ appendage
							+ oneByteLE(level)
							+ publishingModeChar // this 'counter' is one less than the Xpando outputs that follow //'0' + (i)    // only works for i < 9
		console.log('Setting newScriptPubKeys[0] to (without commas): locking script + OP_RETURN +\n'
					+ '    rabinPkhOfPotentialBuyer: 0000000000000000000000000000000000000000,\n'
					+ '    potentialSaleMinBlock:    ' + newBlockHex + ',\n'
					+ '    potentialSaleFlag:        00,\n'
					+ '    optionalPrice:            ' + optionalPrice
					+ '    quarterlyCountHex:        ' + quarterlyCountHex + ',\n'
					+ '    ownerCountHex:            ' + optionalExpCounter + ',\n'
					+ '    renewalBlockDeadline:     ' + renewalBlockDeadline + ',\n'
					+ '    PublisherPKH:             ' + optionalPubPKH + ',\n'
					+ '    Builders State String:    ' + buildersStateString + ',\n'
					+ '    Genesis Block:            ' + theGenesisBlockLE + ',\n'
					+ '    newBlock:                 ' + newBlockHex + ',\n'
					+ '    smallestAmount (dust):    ' + smallestAmount + ',\n'
					+ '    branchName:               ' + appendage + ',\n'
					+ '    branchNameLength (level): ' + oneByteLE(level) + ',\n'
					+ '    publishing mode:          ' + publishingModeChar)//'0' + (i) )

		// There is a zeroeth output for claiming/publishing
		extraOutput = 1;

	} else {
		console.log("libBuildOnAppendOutput(): setting up newScriptPubKeys as an array of " + numExtendOutputs + " outputs")
		newScriptPubKeys = new Array( numExtendOutputs + extraScriptPubKey )

		newCharsArray = fullNewChars[0]
		console.log("libBuildOnAppendOutput(): setting up newCharsArray as an array of " + newCharsArray.length + " characters")
	}

	const newModeHexChar = '58'
	console.log("libBuildOnAppendOutput(): IMPLEMENT ME.    note: newModeHexChar (Xpando) is hardcoded to " + newModeHexChar)
	console.log("\nFIXME: offer chance to decrease the 'smallestAmount'\n")

	console.log("libBuildOnAppendOutput(): building the appendages (and 'newScriptPubKeys' for each output\n")
	for( var oi = 0; oi < numExtendOutputs; oi++ ) {

		const newChar = newCharsArray[oi]
		const tempAppendage = theState.namePath + newChar    // The appendage grows each iteration/tx
		console.log('libBuildOnAppendOutput(): Setting level ', level, ' output ', (oi+extraOutput), ' appendage to: ', tempAppendage)

//FIXME: isn't there a routine we can call to build this?
		const newScriptPubKey	= pureAppendLockingScript
								+ ' OP_RETURN ' + buildersStateString
								+ theGenesisBlockLE + newBlockHex
								+ smallestAmount
								+ tempAppendage + newCounter
								+ newModeHexChar
		console.log('libBuildOnAppendOutput(): Setting newScriptPubKeys[out ' + (oi+extraOutput)
					+ '] to (without commas): locking script + OP_RETURN +\n'
					+ '        ' + buildersStateString + ','
					+ theGenesisBlockLE + ',' + newBlockHex + ',' + smallestAmount + ','
					+ tempAppendage + ',' + newCounter + ',' + newModeHexChar)
		newScriptPubKeys[oi + extraOutput] = newScriptPubKey
		outputAmounts[oi + extraOutput] = oldAmount

	}


	// IF the new builder's vote is YES, there's no need to add an EBFRA output
	// IF the new builder's vote is NO, spawn an EBFRA output - so that
	// it can later maybe vote YES, and publish an EBFRA
	let optionalEbfraScriptHex = '' //hack to avoid call to simpleHash('')
	if ( newBuilderVote === '00' ) {
		console.warn("libBuildOnAppendOutput(): Including an EBFRA output")
		const genesisBlockInt = Buffer.from(theGenesisBlockLE, "hex").readUInt32LE(0)
		console.log('  genesis block int: ', genesisBlockInt)

		optionalEbfraScriptHex = ebfraScriptHexString
		const pureEbfraLockingScript = generateEBFRALockingScript('', false)
		const sizeOfArray = newScriptPubKeys.length

		console.log("NOTE: size of newScriptPubKeys[]: " + sizeOfArray)

//FIXME: why not a buildEbfraSpeakerScriptPubKey()
		const newEbfraScriptPubKey = pureEbfraLockingScript + ' OP_RETURN '
//FIXME: define or derive 13 years too
									+ fourByteLE(genesisBlockInt + 13 * BLOCKS_IN_ONE_YEAR)
									+ newBuilderRabinPKH
									+ theState.namePath
									+ oneByteLE( theState.namePath.length / 2 )
									+ '65'
		newScriptPubKeys[ sizeOfArray - 1 ] = newEbfraScriptPubKey
		outputAmounts[sizeOfArray - 1] = oldAmount
	} else {
		console.warn("libBuildOnAppendOutput(): NOT including an EBFRA output - since " +
					" the new builder's vote is YES, and it's being applied right now.")
	}

	const newSmallestAmount = oldAmount
	const payoutAmount = 0  //NOTE: we've removed any payouts during append/build phase (the 'tree-branching' portion of the graph)
	const payScriptQueue = []


	const outputIndexToTake = whichOutput
	console.log("libBuildOnAppendOutput(): USER passed-in outputIndexToTake of " + outputIndexToTake)
	const results = await executeTheAppendContract(specifiedPrivateKey,
								previousTxid,
								oldScriptPubKey,	oldAmount,
								newScriptPubKeys,	outputAmounts, newSmallestAmount,
								theBuilderState,
								payScriptQueue,		payoutAmount,
								newBlockInt,		specifiedBsvChangePKH,//EXT_FUNDING_CHANGE_PKH,
								outputIndexToTake,
								optionalContinueScriptHex,
								optionalEbfraScriptHex,
								optionalPubPKH,

								optionalAdditionalBalance,

								numExtendOutputs,
								optionalSilentBuild,
								optionalUTXOs)
	console.log("libBuildOnAppendOutput(): results from APPEND: ", { txid: results.txid, change: results.change})

	// If this we're only building, we don't yet want to touch the DB
	if ( optionalSilentBuild ) {
		console.warn("libBuildOnAppendOutput(): silent build. will return results: ", results)
		return results
	}

	if ( results.txid.startsWith('11111111111111111111111111111111') ) {
		console.log("We've got a bogus tx, we'll save its raw bytes")
		// insert results.rawTx into table for this purpose
		await dbAddRawTx(theDB, results.txid, results.rawTx)
	}

	return results.txid
}

/**
 *
 * @param {*} payScriptQueue
 * @param {*} newOwnerCountInt
 * @param {*} newQuarterlyCount
 */
function getContinuePayoutP2PKH(payScriptQueue, newOwnerCountInt, newQuarterlyCount) {
	const index = (newOwnerCountInt + newQuarterlyCount) % MAX_PKHS
	// efffectively round-robin the builder P2PKHs
	console.warn("getContinuePayoutP2PKH(): choosing p2pkh index using OC " +
				newOwnerCountInt + ", QC " + newQuarterlyCount + ", MAX_PKHS " +
				MAX_PKHS + ":  " + index)
	const chosenP2PKHScript = payScriptQueue[index]
	console.warn("getContinuePayoutP2PKH(): chose P2PKH " + chosenP2PKHScript)
	return [ chosenP2PKHScript ]
}

/**
 *
 * @param {*} payScriptQueue
 * @param {*} newOwnerCountInt
 * @param {*} newQuarterlyCount
 * @param {*} newPeriodicalCount
 * @returns
 */
 function getUpdatePayoutP2PKH(payScriptQueue, newOwnerCountInt, newQuarterlyCount, newPeriodicalCount) {
	const index = (newOwnerCountInt + newQuarterlyCount + newPeriodicalCount) % MAX_PKHS
	// efffectively round-robin the builder P2PKHs
	console.warn("getUpdatePayoutP2PKH(): choosing p2pkh index using OC " +
				newOwnerCountInt + ", QC " + newQuarterlyCount +
				", periodicalCount " + newPeriodicalCount + ", MAX_PKHS " +
				MAX_PKHS + ":  " + index)
	const chosenP2PKHScript = payScriptQueue[index]
	console.warn("getUpdatePayoutP2PKH(): chose P2PKH " + chosenP2PKHScript)
	return [ chosenP2PKHScript ]
}

//export /* */
async function libClaimAndPublish(theState, previousTxid, whichOutput,
							   blockHeight,
							   solicitPaycode,
							   solicitPKH,
							   solicitYN,
							   solicitResponse,
							   solicitContent,
							   solicitTx,
							   solicitKeys,
							   param0paycode,
							   paramPKH, param2NormalZone,
									param2bRabinPkhOfPotentialBuyer,
									p2cAuthcode, p2dAuthcodePadding,
									p2dOldOwnerRabinPubKeyLE,
										param3, newOwnerP2Pkh, instaBuy,

										param4Renew,

										param5,
										param6, param7, param8HexContent, param9, param10,
										param11, param12, param13, param14,

										optionalAdditionalBalance,

							   paramPrivKeyP,   // THESE two carry the keys to unlock/authorize spend of prev tx/output
							   paramPrivKeyQ,   //
							   theDB,
							   dbAddRawTx,
							   specifiedPrivateKey) {

	const privKey = new bsv.PrivateKey.fromWIF(specifiedPrivateKey)
	const specifiedPubKey = bsv.PublicKey.fromPrivateKey( privKey )
	const specifiedBsvChangePKH = bsv.crypto.Hash.sha256ripemd160(specifiedPubKey.toBuffer('hex')).toString('hex')

	console.log("You wish to BUILD on the continuous (main) publishing line.\n")

	// This is the PREVIOUS scriptPubKey: locking script plus previous state
	const oldScriptPubKey = generateContinueLockingScript( theState.stateHex, false )

	const pureContinueLockingScript = generateContinueLockingScript('', false)

	const genesisBlockInt = Buffer.from(theState.genesisBlock, "hex").readUInt32LE(0)
	console.log('  genesis block int: ', genesisBlockInt)

	var payScriptQueue = []   // to build payout scripts. (This lags the payStateQueue.)
	// Payout to EVERY builder PKH
	console.log('\nlibClaimAndPublish(): the state PKHs has length ' + theState.builderPKHs.length)
	console.log("Will payout to ONE of " + theState.builderPKHs.length + " builders at a time.")
	var buildersStateString = ''
	let justP2pkhsString = ''      // for spawned updates: omits builder rabins, and votes
	let voteTally = 0
	for ( var i = 0; i < theState.builderPKHs.length; i++ ) {
		console.warn("Adding these strings to buildersStateString: " + theState.builderPKHs[i])
		console.log("   and rabin: " + theState.builderRabins[i])
		console.log("   and vote: " + theState.builderVotes[i])
		buildersStateString += theState.builderPKHs[i]
		buildersStateString += theState.builderRabins[i]
		buildersStateString += theState.builderVotes[i]

		justP2pkhsString += theState.builderPKHs[i] // take only the builder payout p2pkhs (first 20 bytes (40 chars))
		const vote = theState.builderVotes[i]
		if ( vote !== '00' ) {
			voteTally++
			console.warn("    adding vote for builder #" + i + ". Tally now " + voteTally + " <-----<<")
		}

		// Only one of these payouts will be used for a given tx (deterministically)
		const payScript = 'OP_DUP OP_HASH160 ' + theState.builderPKHs[i] + ' OP_EQUALVERIFY OP_CHECKSIG'
		payScriptQueue.push( payScript )
		console.log("    Script to builder " + i + ": " + payScript)
	}
	console.log('libClaimAndPublish():  buildersStateString: \'' + buildersStateString + '\'\n')

	// default to a very large price: 16777218 ?
	//FIXME: rename newPriceInSats
	var priceInSats = 0  // FIXME: maybe debugger doesn't like 5 bytes of b0  // we can't set this just yet
	var claimExpired = 'OP_0'  // else OP_1

	var publisherRabinPKH
	var deadlineInt
	var renewal = false;
	console.warn("newBlockInt is initialized to " + blockHeight)
	const newBlockInt = blockHeight   // used EITHER for minBlock, or potentialSaleMinBlock
	// depending on the tx type, one of these two variables will change
	let minBlock              = newBlockInt
	let potentialSaleMinBlock = newBlockInt

	let potentialSaleFlag = 0
	let potentialSaleBool = false
//alert("shizzleBuilder A")
	const oldBlockNumInt = theState.blockNumInt  //Buffer.from(theState.blockNumInt, "hex").readUInt32LE(0)
	const blocksSinceLastPost = newBlockInt - oldBlockNumInt

	console.log("libClaimAndPublish(): BTW: PREVIOUS blockNumInt was " + oldBlockNumInt)
	let rabinPkhOfPotentialBuyer = param2bRabinPkhOfPotentialBuyer
	if ( theState.mode === '50' ) { // 'P', so, claiming right now
		console.log("\nlibClaimAndPublish(): NOTE: since you're claiming the branch now, "
				+	"you'll need to specify a Publisher's RabinPKH - to specify what authorizes future posts.")
		console.log("EXAMPLE Rabin PKH: 748fc2b2cbad49e520f1d2cdfc91265318fdff3c")
		publisherRabinPKH = paramPKH;

		//FIXME: in the future we'll use 365.25    (52596 instead of 52560)
		deadlineInt = newBlockInt + 144 * 365
		console.log("Setting renewal deadline to 1 year from new block - new block being " + newBlockInt)
		theState.ownerCountHex = '01'  // changed to 01. Updated the Continue contract too
		theState.quarterlyCount = 0    // to be incremented further below
		//theState.ownerCount = 1; unused
		//theState.ownerRabinPKH = publisherRabinPKH      Set further below
		if ( param2bRabinPkhOfPotentialBuyer !== '0000000000000000000000000000000000000000' ) {
			throw new Error("26830: rabin of potential buyer needs to be zeroes when building on 'P'")
		}


		// NEW mechanism: P->K, but we don't set new mode (continueModeHexChar)
		// until further below, just before calling buildContinueScriptPubKey()

		// Can't spawn while claiming
		if ( param13 ) {
			throw new Error("46830: can't spawn Update when claiming (P -> K)")
		}


	} else {
		// K or p

		// This checks if EXPIRED, or RENEWING - whether 'p', or 'K'

		//WARNING: FIXME: horrible name for variable
		//         This tells us if we'll need to sign against the previous rabin
		var newRabinPkhNeeded = true

		// check if this next post MUST be within the zone of renewal

		//NOTE: this is what the deadline WAS
		const oldRenewalBlockDeadlineInt = Buffer.from(theState.renewalDeadline, "hex").readUInt32LE(0)
		const fourMonthsBeforeDeadline = oldRenewalBlockDeadlineInt - THIRD_OF_YEAR_IN_BLOCKS

		if ( newBlockInt > oldRenewalBlockDeadlineInt ) {
			console.warn("It looks like this asset has EXPIRED. You'll need to re-claim it (if someone hasn't already)")

			// Copied from below (expiration scenario)
		//	deadlineInt = newBlockInt + 52560
		//	const oldOwnerCounter = Buffer.from(theState.ownerCountHex, "hex").readUInt8(0)
		//	theState.ownerCountHex = oneByteLE(oldOwnerCounter + 1)
			console.log("LEAVING ownerCountHex at " + theState.ownerCountHex + " (and not yet setting deadlineInt). They'll be modified VERY soon")
			theState.quarterlyCount = 0   // it'll be incremented soon
			claimExpired = 'OP_1'     // <---  this will trigger us to increment ownerCount, and advance deadlineInt soon, further down
			newRabinPkhNeeded = false

			console.log("libClaimAndPublish(): NOTE: since you're RE-claiming the branch now, "
					+	"you'll need to specify a NEW rabinPKH - to specify what authorizes future posts.")
			publisherRabinPKH = param5;

			if ( param2NormalZone || param4Renew ) {
				console.error("You need to re-claim, but you've set param2NormalZone (" + param2NormalZone
							+ ") or param4Renew (" + param2NormalZone + ") to true")
				throw new Error("10001: Must re-claim");
			}

		} else if ( newBlockInt > fourMonthsBeforeDeadline ) {
			console.log("\nIt seems it's time to renew - whether you like it or not.")
			console.log("Incidentally, it is only during a RENEWAL that you can spawn a SALES-NEGOTIATION output.")

			// block must be more than 6 months after previous claim/renewal
			console.log("Since it has already been claimed, this tx must be more than "
					+	"SOME FRACTION of a year since the previous tx. Will set it accordingly.")

			console.log("NOTE: Advancing the deadline/renewal block by 1 year.")
			deadlineInt = oldRenewalBlockDeadlineInt + 52560

			if ( !param4Renew && !instaBuy ) {
				console.error("You did not request a renewal, nor instaBuy, but you must perform one of those. Check the block height, and expiration deadline.")
				throw new Error("10701: must renew or instaBuy")
			}
			if ( !instaBuy ) {
				console.warn("This will be a renewal")
				renewal = true
			} else {
				console.warn("Even though we *could* renew, we won't. This is an instaBuy <---")
			}
		} else {
			console.log("Not expired. Not renewing.")
			console.log("newBlockInt is " + newBlockInt)
			console.log("fourMonthsBeforeDeadline is " + fourMonthsBeforeDeadline)
			// neither EXPIRED, nor RENEWing
			// keep deadline unchanged (for now)
			deadlineInt = oldRenewalBlockDeadlineInt
		}

		console.log("NOTE: new minBlock is: " + newBlockInt)
		console.log("NOTE: deadline is: " + deadlineInt)

		if ( claimExpired === 'OP_1') {
			console.warn("libClaimAndPublish(): skipping checks on mode. The claim has expired. We're re-claiming it")
		} else if ( p2cAuthcode.length > 0 ) {

			console.warn("libClaimAndPublish(): NOTE: we're setting the authcode, padding, and prevOwnerPubKey, to TRANSFER OWNERSHIP")
	//FIXME: decide if calling party must supply correct values for the constrained parameters
	//       or should we just set them here/now

			// set new renewal deadline to 1 year from right now
			deadlineInt = newBlockInt + 144 * 365
			//FIXME: use some harnessConstants symbol


			const oldOwnerCounter = Buffer.from(theState.ownerCountHex, "hex").readUInt8(0)
			theState.ownerCountHex = oneByteLE(oldOwnerCounter + 1)
			console.log("setting ownerCountHex to " + theState.ownerCountHex)
			theState.quarterlyCount = 0   // it'll be incremented soon
			//FIXME: rename claimExpired to specialOperation
			claimExpired = 'OP_2'
			newRabinPkhNeeded = false

			publisherRabinPKH = param5
			if ( publisherRabinPKH !== theState.rabinOfPotentialBuyer) { //theState.rabinOfPotentialBuyer ) {
				console.warn("publisherRabinPKH: " + publisherRabinPKH)
				console.warn("param2bRabinPkhOfPotentialBuyer: " + param2bRabinPkhOfPotentialBuyer)
				console.warn("rabinPkhOfPotentialBuyer: " + rabinPkhOfPotentialBuyer)
				console.warn("theState.rabinOfPotentialBuyer: " + theState.rabinOfPotentialBuyer)
				throw new Error("CODE ERROR: since transfering ownership, new owner rabin PKH must match that of PREVIOUSLY-specified potential buyer")
			}
			if ( rabinPkhOfPotentialBuyer !== '0000000000000000000000000000000000000000' ) {
				throw new Error("CODE ERROR: since transfering ownership, new value for potential buyer must be all zeroes")
			}

		} else if ( instaBuy ) {
			console.warn("libClaimAndPublish(): we're going to instaBuy")

			claimExpired = 'OP_3'
			publisherRabinPKH = paramPKH
//set newQuarterlyCount here?
			//FIXME: add a payment output script - to the ownerP2Pkh,
			//       for the priceInSats
			//       let's have 'instaBuy' trigger that, further below

		} else if ( theState.mode === '4b' || theState.mode === '4B' ) { //'K'
			console.log("\n\nlibClaimAndPublish(): We're building on something which we've PRE-CLAIMED (or just bought).")
			console.log("So, unlike 'P', we've already got a publisherPKH, a renewalDeadline, and an expCounter")
			console.log("And unlike 'p', we have no price, and no content of any kind: pcode, tx, nor generic content.")
			console.log("I THINK we can post right-away with initial content (before the zone of renewal)")

			publisherRabinPKH = paramPKH;
			// But when would it have been set to anything other than blank?
			if ( rabinPkhOfPotentialBuyer !== '0000000000000000000000000000000000000000' ) {
				throw new Error("12839: rabin PKH of potential buyer must be 20 bytes of zeroes when buliding on mode 'K'")
			}
		} else { // 'p'   (normal post, OR potentialSale)

//FIXME: is this even a good parameter to have?
			let normalPost = param2NormalZone;
//FIXME: get from harnessConstants.js
			if ( blocksSinceLastPost < FOURTH_OF_YEAR_IN_BLOCKS ) {
				if ( normalPost ) {
//FIXME: why bother passing-in 'normalPost'?
					throw new Error("10909: despite declaring it a normal post, this is not. Enough time has not passed since your last post.")
				}

				// increment potentialSaleFlag
				// maintain QC, OC
				// (further below)

				console.warn("This must be a potentialSale post")

				const oneIntervalSinceLastPost = theState.blockNumInt + FOURTH_OF_YEAR_IN_BLOCKS;
				const weHadJustRenewed = theState.blockNumInt + BLOCKS_IN_ONE_YEAR < theState.renewalDeadlineInt
				// check that there's enough time to do a complete potentialSale - 24 days
				// Also check that this is the first quarter (after a renewal)
				if ( !weHadJustRenewed || (newBlockInt >= oneIntervalSinceLastPost - 24*144) ) {
					throw new Error("10910: a 'potentialSale' tx must be made in the quarter immediately following a renewal, AND must be at least 24 days before the next NORMAL (quarterly) post could be made.")
				}

				// check on constraints:

				//	no content, label, txRef, txRef label, pcode, nor spawning
				if ( param7 || param8HexContent.length > 0 || param9.length > 0 ) { //publishing true , or content length > 0, or its label length > 0
					console.warn(" param7: " + param7)
					console.warn(" param8: " + param8HexContent + "  len " + param8HexContent.length)
					console.warn(" param9: " + param9 + "  len " + param9.length)
					throw new Error("12840: a 'potentialSale' tx cannot publish content, nor a content label")
				}

				if ( param10 || param11.length > 0  || param12.length > 0 ) {// publishing a txid is true, or its length > 0, or its label's length > 0
					throw new Error("12841: a 'potentialSale' tx cannot publish a reference txid, nor label a txid")
				}

				if ( param13 || param14 ) { // spawn update, or spawn sales
					throw new Error("12842: a 'potentialSale' tx cannot spawn an update output, nor a sale/auction output")
				}
				if ( param0paycode.length > 0 ) {
					throw new Error("12843: a 'potentialSale' tx cannot publish a payment code")
				}

				// keep publisherPKH unchanged
				if ( !param6 ) {
					throw new Error("12844: a 'potentialSale' must keep the ownerRabinPKH unchanged")
				}


				// keep QC unchanged (further below), and don't payout to a Builder
				potentialSaleBool = true              // <----- maybe this can help with the 3 things below


				// keep minBlock (blockNumInt) unchanged
				minBlock = theState.blockNumInt
				console.warn("NOTE: minBlock REVERTING to previous value, for potentialSale: " + minBlock)
				console.warn("     AND: potentialSaleMinBlock is: " + potentialSaleMinBlock)

				// set potentialSaleMinBlock
				// Well, it was already initialized to this value
				//potentialSaleMinBlock = newBlockInt

				// advance potentialSaleFlag counter
				potentialSaleFlag = theState.potentialSaleFlag + 1

				// Enforce timing constraints
				if ( rabinPkhOfPotentialBuyer !== '0000000000000000000000000000000000000000' ) {
					// If we're SETTING a rabinPkhOfPotentialBuyer,
					// PREVIOUS pkh must have been zeroes
					if ( theState.rabinOfPotentialBuyer !== '0000000000000000000000000000000000000000' ) {
						throw new Error("43780: can't set rabin of potential buyer until clearing previous")
					}
					// And the min block must have advanced at least one block
					if ( newBlockInt <= theState.potentialSaleMinBlockDecimal ) {
						throw new Error("43781: potentialSaleMinBlock must be at least one block greater than when potential sale rabin was cleared (previous tx)")
					}
				} else {    // If we're CLEARING the rabin of potential buyer,
				            // previous rabin must have persisted for at least 24 days
				            // (the potential sale minBlock must increase by at least 24 days)
					if( newBlockInt <= theState.potentialSaleMinBlockDecimal + 24*144 ) {
						throw new Error("43782: when clearing rabin PKH of potential buyer, potentialSaleMinBlock must be set to at least 24 days after previous value (when potential sale rabin was set)")
					}
				}

			} else if ( !renewal ) {
				//NEW: this is for NOT expired, and NOT renewing. This is for normal posts
				//     RENEW and EXPIRED were handled further up

				console.log("\nWe could be posting within the 'normal' zone, in the 'renewal' zone, or test an EXPIRATION.")
				console.log("The first post within the final four months of the lease results in a lease RENEWAL.")
				console.log("Incidentally, it is only when you RENEW that you can spawn a SALES-NEGOTIATION output.")

				if ( normalPost ) {
					console.log("posting normally (not a renewal, nor expiration)")
					deadlineInt = oldRenewalBlockDeadlineInt

					console.log("\n\nWe could set a price (since we're not renewing)")
					priceInSats = param3;
					console.log("\nwe haven't done any checks on this price: " + priceInSats)

				} else {
					console.error("You did not request a normalPost, but you must. Check the block height, and expiration deadline.")
					throw new Error("10702: must specify this as a 'normalPost'")
				}  // error
			} else {
				if ( normalPost ) {
					console.error("You requested a normalPost, but you need to renew. Check the block height, and expiration deadline.")
					throw new Error("10703: must be a renewal")
				}

				console.warn("This is a renewal")
				priceInSats = param3;
				console.warn("even though it's a renewal, price setting is " + priceInSats)
			}

			if ( newRabinPkhNeeded ) {
				publisherRabinPKH = theState.ownerRabinPKH
				console.log("\nlibClaimAndPublish(): NOTE: the Rabin PKH set/declared in the PREVIOUS tx was: "
							+ theState.ownerRabinPKH)
				console.log("libClaimAndPublish(): so, when you sign, you'll need to sign with the private keys "
							+ "associated with that PKH\n")

				//console.log("\nThe existing rabinPKH is " + publisherRabinPKH)
				let keepSameRabinPKH = param6;

				//alert("shizzleBuilder: code says newRabinPkhNeeded. keepSameRabinPKH = " + keepSameRabinPKH)
				if ( !keepSameRabinPKH ) {
					publisherRabinPKH = param6;
					console.warn("supposedly we're CHANGING the publisherRabinPKH: " + publisherRabinPKH)
				} else {
					console.warn("supposedly we're NOT changing the publisherRabinPKH: " + publisherRabinPKH)
				}
			} else {
				console.warn("shizzleBuilder() newRabinPkhNeeded is FALSE")
			}
		} // 'p'
	} // 'K' or 'p'

	const minBlockHex = fourByteLE(minBlock)
	console.log('  minBlock int: ', minBlock)
	console.log('  minBlock hex: ', minBlockHex)

	var continueContentHex = ""
	var continueContentName = ""
	let publishSomeContent = param7;
	if ( publishSomeContent ) {
		if ( claimExpired === 'OP_2') {
			throw new Error("38919: can't publish content while transfering ownership")
		}

		//FIXME: NOTE: must match newContinue.scrypt's call. Similar to Update, but a slower period (2 years, instead of 18 months)
		const maxContentLen = calcMaxContentLenFromBlockAndGenesis(minBlock, genesisBlockInt, 52560 * 2, 278046, 393216)
		console.log("\n\nNOTE: max content length is " + maxContentLen + " (" + (maxContentLen/1024) + " KB)  <---\n\n")

		continueContentHex = param8HexContent;
		if ( continueContentHex.length > 0 ) {

			//FIXME: or, put error check here, instead

			let tempName = param9;
			let result = verifyBase64( tempName )
			continueContentName = result.base64

			if ( !result.valid ) {
				console.log("invalid content name: " + continueContentName);
				throw new Error("10014: Invalid content name");
			}
		}
	}

	var txToPublishHex = ""
	let publishATxId = param10;
	if ( publishATxId ) {
		txToPublishHex = param11;
	}

	var txDescriptor = ''
	if ( txToPublishHex.length > 0 ) {
		if ( claimExpired === 'OP_2') {
			throw new Error("38920: can't publish a reference tx while transfering ownership")
		}

		let res
		let tempDescr = param12;

		res = verifyBase64( tempDescr )
		txDescriptor = res.base64

		if ( !res.valid ) {
			console.log("invalid tx label: " + tempDescr);
			throw new Error("10015: Invalid tx label");
		}
	}

	// sample paycode (80-bytes):
	// '50b4d8ca6d9c3e475286d9cd695240fba62a2c1f6b2ae381e394cd8f0a7a8a08beb4d8ca6d9c3e475286d9cd695240fba62a2c1f6b2ae381e394cd8f0a7a8a0811223344556677889900112233445566';
	let payCodeHex = param0paycode;
	if ( claimExpired === 'OP_2' && payCodeHex.length > 0 ) {
		throw new Error("38921: can't publish a paycode while transfering ownership")
	}


	console.warn("libClaimAndPublish(): BTW: state is: ", theState)

	const smallestAmount = Buffer.from(theState.smallestAmount, "hex").readUInt16LE(0)
	console.log("libClaimAndPublish(): theState.quarterlyCount was " + theState.quarterlyCount)
	let newQuarterlyCount = theState.quarterlyCount + (potentialSaleBool ? 0 : 1)

	// IF OP_1 (expired), OP_2 (auction), or OP_3 (instaBuy)
	//     newQuarterlyCount is 1
	//FIXME: why wasn't this logic already here? investigate. maybe it's nearby?
	//FIXME: same as if applying authcode
	console.warn("BTW: instaBuy is " + instaBuy)
	console.warn("BTW: claimExpired is " + claimExpired)
	if ( claimExpired === 'OP_1' || claimExpired === 'OP_2' || instaBuy ) {
		newQuarterlyCount = 1;
		console.warn("SETTING newQuarterlyCount to 1")

		// set new renewal deadline to 1 year from right now
		deadlineInt = newBlockInt + 144 * 365
		//FIXME: use some harnessConstants symbol

		const oldOwnerCounter = Buffer.from(theState.ownerCountHex, "hex").readUInt8(0)
		theState.ownerCountHex = oneByteLE(oldOwnerCounter + 1)
		console.log("setting ownerCountHex to " + theState.ownerCountHex)
	}

	console.log("libClaimAndPublish(): newQuarterlyCount is " + newQuarterlyCount)
	const newQuarterlyCountHex = twoByteLE( newQuarterlyCount )

	//FIXME: ask user if the smallestAmount has gone down
	console.log("\n\nFIXME: ask if the smallestAmount has gone down (mention current level). If it has, "
			+   "use the smaller value.\n\n")

	console.log('- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -')
	console.log(' SIMPLE CONTINUE script pub key (newPublishScriptPubKey):')

	// what should the NEW mode be?
	// Added new case: P -> K
	const continueModeHexChar = ((claimExpired === 'OP_2') || (claimExpired === 'OP_1') || instaBuy || theState.mode === '50' ) ?   // transfering ownership?   Typically, no
												'4B' // new 'K'eys applied.     nowadays we P -> K first (no longer P -> p)
											:
												'70' // 'p'ublish
	console.log(" claimExpired is " + claimExpired + ", and continueModeHexChar is " + continueModeHexChar)
	console.log("continueModeHexChar: (" + hexByteToAscii(continueModeHexChar) + ")")

	if ( continueModeHexChar === '4B' || continueModeHexChar === '4b' ) {
		if ( optionalAdditionalBalance < 0 ) {
			throw new Error("optionalAdditionalBalance must be non-negative if setting mode to 'K' ('4b')")
		}
	} else {
		if ( optionalAdditionalBalance !== 0 ) {
			throw new Error("optionalAdditionalBalance must be zero if NOT setting mode to 'K' ('4b')")
		}
	}

	console.warn("passing in price (should be decimal) of " + priceInSats)

	const newPublishScriptPubKey = buildContinueScriptPubKey(pureContinueLockingScript,
			newOwnerP2Pkh,          // instaBuy receiving P2PKH
			rabinPkhOfPotentialBuyer,
			potentialSaleMinBlock,
			potentialSaleFlag,  // set this partly based on previous value - AND rename to ...Counter
			priceInSats,            // NEW instaBuy price

			newQuarterlyCountHex,
			theState.ownerCountHex,
			deadlineInt,
			publisherRabinPKH,
			buildersStateString,
			theState.genesisBlock,
			minBlock,   			// may be different from new var 'potentialSaleMinBlock'
			smallestAmount,
			theState.namePath,
			oneByteLE( theState.namePath.length / 2 ),
			continueModeHexChar)


	var continueScriptPubKeys  // This is an array of scriptPubKeys
	var optionalUpdateScriptHexString
	let spawnUpdate = param13;

	var spawnSalesLine = false
	if ( renewal ) {
		console.log("\nBECAUSE you are renewing, you could also spawn a sales-negotiation output. "
				+	"Doing so, HOWEVER, requires that the insta-buy price be set at zero (not "
				+	"for sale via insta-buy).")
		spawnSalesLine = param14;
	} else {
		console.log("Because you are NOT renewing, you CANNOT spawn a sales-negotiation output")
		if ( param4Renew ) {
			throw new Error("76300: you requested a renewal, but it's not the right time")
		}
	}

	const updateIndex = 1
	var salesIndex = 1
	var outputAmounts = [] // The value (satoshis) for each output
	if ( spawnUpdate || spawnSalesLine ) {
		if ( claimExpired === 'OP_2' ) {
			throw new Error("38922: can't spawn anything while transfering ownership")
		}

		if ( spawnUpdate && spawnSalesLine ) {
			continueScriptPubKeys = new Array( 3 )
			salesIndex = 2
		} else {
			continueScriptPubKeys = new Array( 2 )
		}
	} else {
		continueScriptPubKeys = new Array( 1 )
	}
	continueScriptPubKeys[0] = newPublishScriptPubKey

	//if QC >= 5, this multiplier drops to 1
	//NOTE: we're BUILDING on QC - the new QC is thisQC+1
	let outputBalanceMultipler = newQuarterlyCount >= 6 ?
									1 : BOUNTY_MULTIPLIER_CONTINUE;
	console.warn("balance multiplier is ", outputBalanceMultipler)

	outputAmounts[0] = (smallestAmount * outputBalanceMultipler) + optionalAdditionalBalance

	if ( spawnUpdate ) {
		console.log("\nWill include an UPDATE at output #" + updateIndex + " (base-0)")
		const pureUpdateLockingScript = generateUpdateLockingScript('', false)

		console.log('\n\nADDITIONAL UPDATE script pub key (realUpdateScriptPubKey)')
		const updateModeHexChar = '55'    // 'U'

		//FIXME: after publisherRabinPKH  future: nextAuctionRabinPKH, and nextUpdateRabinPKH (will need new contract input params)
		const realUpdateScriptPubKey = buildUpdateScriptPubKey(pureUpdateLockingScript,
				voteTally,
				'0000',                  // initial periodicCount
				deadlineInt,
				publisherRabinPKH,
				justP2pkhsString,
				theState.genesisBlock,
				minBlock,
				smallestAmount,
				newQuarterlyCountHex,
				theState.ownerCountHex,          // renamed from ownerCounter
				theState.namePath,
				oneByteLE( theState.namePath.length / 2 ),
				updateModeHexChar)

		continueScriptPubKeys[updateIndex] = realUpdateScriptPubKey
		outputAmounts[updateIndex] = smallestAmount * BOUNTY_MULTIPLIER_UPDATE
		optionalUpdateScriptHexString = updateScriptHexString
		if ( updateScriptHexString.length === 0 ) {
			console.error("\nERROR: EMPTY update script hex string cache. Please define this in the contracts cache.\n")
			throw new Error("10002"); //exit(-1)
		}
	} else {
		optionalUpdateScriptHexString = ''
	}

	var optionalAskBidScriptHexString
	if ( spawnSalesLine ) {
		priceInSats = 0
		console.log("\nWill include a SALES-NEGOTIATION contract at output #" + salesIndex + " (base-0)")
		const pureAskBidLockingScript = generateAskBidLockingScript('', false)

		console.log('\n\nADDITIONAL SALES script pub key (realAskBidScriptPubKey)')
		const askBidModeHexChar = '4e'    // 'N' (sale NOT yet open)
		const realAskBidScriptPubKey = buildAskBidScriptPubKey(false,
				pureAskBidLockingScript,
				[],		// balks
				[],		// bids
				'',		// mainlineRabinPubKey
				'',		// authcode
				'',		// authcode padding
				0,		// before block Int
				0,		// sum of all funds Int
				0,		// best bid Int
				0,		// worst bid Int
				0,		// asking price Int
				publisherRabinPKH,			//FIXME: future: nextAuctionRabinPKH, and nextUpdateRabinPKH (will need new contract input params)
				publisherRabinPKH,        // mainlineOwnerRabinPKH (hardcoded for duration of auction)
				deadlineInt, //FIXME: is this less than deadline?
				minBlock,
				0,  //opNum
				smallestAmount,

				newQuarterlyCount,
				Buffer.from(theState.ownerCountHex, "hex").readUInt8(0),

				theState.namePath,
				askBidModeHexChar)

		// sales index is 1 or 2
		continueScriptPubKeys[salesIndex] = realAskBidScriptPubKey
		outputAmounts[salesIndex] = smallestAmount
		optionalAskBidScriptHexString = askBidScriptHexString
	} else {
		optionalAskBidScriptHexString = ''
	}

	// In practice, the contract output amount could drop to whatever the miners are willing to accest as non-dust

	//FIXME: note: readUInt() only goes up to 6 bytes - not the 8 we actually need
	const oldAmount = Buffer.from(theState.contractSatoshis, "hex").readUIntLE(0,6)

	console.warn("calculating payout amount using a voteTally of " + voteTally)
	var payoutAmount = calcPayoutFromBlockAndGenesis(minBlock, genesisBlockInt, voteTally)
	console.log("\nStandard payout amount (per builder) is " + payoutAmount)


	var sigRes = {
		signature: '0',
		sigHexLE: null,
		paddingBytes: '00'
	}
	var publisherPK = 'OP_0'

	var payoutMultiplier = CLAIM_PAYOUT_MULTIPLIER; // The most common in the code below
	if ( theState.mode === '50' ) {  // 'P'
		console.log("\nYou're the first to ever CLAIM this branch. You'll need to pay for this "
				+	"privilege, but you won't need to sign for it.")
		console.log("1: PAYOUT MULTIPLIER is " + payoutMultiplier)
	} else if (claimExpired === 'OP_0' ) { // if it hasn't expired, probably need to authorize the post
		if ( theState.mode === '4b' || theState.mode === '4B' ) {  // 'K'
			console.log("\nYou're the first to ever POST on this branch. You'll need to pay for this "
					+	"privilege, but you won't need to sign for it.")
			console.log("2: PAYOUT MULTIPLIER is " + payoutMultiplier)
		} else {
			// Not claiming, pre-claiming, nor re-claiming. Just 'continuing'. No payout multiplier.
			payoutMultiplier = 1
			console.log("3: PAYOUT MULTIPLIER is " + payoutMultiplier)
		}
		console.log("\nYou now need to authorize what you're about to publish (and set). "
				+	"You'll need to use the keys associated with the PKH set in the PREVIOUS/parent tx/post: ",
					theState.ownerRabinPKH);
		let keyInfo = calculateKeyInfo( paramPrivKeyP, paramPrivKeyQ );

//FIXME: in the future we may require priceInSats to be 0 when spawning AskBid (and we can only spawn AskBid at renewal time)

		const spinoffUpdateFlag = optionalUpdateScriptHexString.length > 2 ? '01' : '00'
		const spinoffSalesFlag = optionalAskBidScriptHexString.length > 2 ? '01' : '00'
		const completeContent	= oneByteLE(continueContentName.length)
								+ continueContentName.toString("hex")
								+ fourByteLE( continueContentHex.length / 2 )
								+ continueContentHex
								+ oneByteLE(txDescriptor.length)
								+ txDescriptor.toString("hex")
								+ oneByteLE(txToPublishHex.length / 2)
								+ txToPublishHex
								+ payCodeHex
								+ rabinPkhOfPotentialBuyer
								+ fourByteLE( potentialSaleMinBlock )
								+ oneByteLE( potentialSaleFlag )
								+ fiveByteLE( priceInSats )  // all zeros
								+ newOwnerP2Pkh
								+ newQuarterlyCountHex
								+ minBlockHex
								+ theState.namePath
								+ oneByteLE(theState.namePath.length / 2)
								+ spinoffUpdateFlag
								+ spinoffSalesFlag
								+ publisherRabinPKH			//FIXME: future: will need TWO more contract input params:
															//  nextUpdateRabinPKH and nextAuctionRabinPKH

		if ( !validateRabinPrivKeys(keyInfo.rabinPrivKeyP, keyInfo.rabinPrivKeyQ, theState.ownerRabinPKH) ) {
			throw new Error("18001: Invalid private keys for given Rabin PKH")
		}
		console.log("libClaimAndPublish(): will sign this message: '" + completeContent + "'")
		sigRes = getSigParts(Buffer.from(completeContent, "hex"), keyInfo.rabinPrivKeyP, keyInfo.rabinPrivKeyQ, keyInfo.rabinPubKeyBigInt)
		console.log("For SIMPLE Cont/Publish, will set this rabin pkh: ", publisherRabinPKH)
		console.log("For SIMPLE Cont/Publish, signature is: ", sigRes.signature)
		publisherPK = keyInfo.rabinPubKeyLE

	} else if ( claimExpired === 'OP_2' ) { // transfering ownership - a big deal
		console.log("\nYou're CLAIMING this branch, having BOUGHT it. You'll need to pay for this "
				+	"privilege (fee to the BUILDERS) SOON (eventually, on the next post (when we've changed "
				+	"to have the new mode set to 'K' when buying)), but you won't need to sign "
				+	"for it - the authcode does that for you (the previous owner already signed for you).")
		payoutMultiplier = 1
		console.log("4: PAYOUT MULTIPLIER is " + payoutMultiplier)

		console.warn("\nYou now need to authorize what you're about to do. "
					+	"BUT, you'll do it using a signature already provided:  the AUTHCODE",
					p2cAuthcode);


		// use ALTERNATIVE parameter - provide signature as a LE Hex
		// Triggered by setting .signature to null
		sigRes.signature = null		//WARNING: unobvious feature of executePubUpdateContract():
									//         If this is null, use .sigHexLE (LE field/param of signature)
		sigRes.sigHexLE = p2cAuthcode
		sigRes.paddingBytes = p2dAuthcodePadding

		publisherPK = p2dOldOwnerRabinPubKeyLE // revealed at auction execution

	} else if ( claimExpired === 'OP_3' ) {
		// new owner, so, buck-up for a few quarters
		console.log("You're insta-BUYing. payout multiplier is " + BOUNTY_MULTIPLIER_CONTINUE)
		payoutMultiplier = 1
	} else {  // CLAIM EXPIRED
		console.log("\nYou're RE-CLAIMing this branch. You'll need to pay for this "
				+	"privilege, but you won't need to sign for it.")
		console.log("5: PAYOUT MULTIPLIER is " + payoutMultiplier)
	}

	console.log("\nActual payout amount is " + payoutAmount + " x " + payoutMultiplier + " (per builder)")
	payoutAmount = payoutMultiplier * payoutAmount
	console.log("                     = " + payoutAmount + " satoshis per builder )")

	// NOTE: potentialSaleMinBlock typically matches minBlock.
	// IF, however, we specify a potentialSale, THIS will advance (while minBlock won't change).
	// so, now it's always used for nLockTime (potentialSaleMinBlock)

	// Choose a SINGLE payout p2pkh
	const newOwnerCountInt = Buffer.from(theState.ownerCountHex, "hex").readUInt8(0)
	let chosenP2PKHScript = []
	// if we're specifying, or clearing, a potentialSale, don't payout to a Builder
	if ( !potentialSaleBool ) {
		chosenP2PKHScript = getContinuePayoutP2PKH(payScriptQueue, newOwnerCountInt, newQuarterlyCount)
	}

	if ( instaBuy ) {
		console.error("SPECIAL CODE: for instaBuy - will alter payoutAmount, " +
						" adding amount " + theState.priceInSats)
		const decimalPrice = Buffer.from(theState.priceInSats, 'hex').readUIntLE(0,5)
		console.warn("decimal price is " + decimalPrice)
		const firstAmount = payoutAmount
		//NOTE: hacky: normally not an array
		//      The contractSupport code looks for special case
		//      of an array
		payoutAmount = [ firstAmount, decimalPrice]
		console.warn(" RE-WROTE payoutAmount as array: ", payoutAmount)

		console.warn(" Initially, chosenP2PKHScript: ", chosenP2PKHScript)

		console.error("Paying to SELLER's p2pkh: " + theState.ownerP2PKH)
		const payToSeller = 'OP_DUP OP_HASH160 ' + theState.ownerP2PKH + ' OP_EQUALVERIFY OP_CHECKSIG'
		chosenP2PKHScript.push( payToSeller )
		console.warn(" NOW, chosenP2PKHScript: ", chosenP2PKHScript)
	}

	const results = await executePubUpdateContract(specifiedPrivateKey,
							previousTxid, oldScriptPubKey, oldAmount,
							continueScriptPubKeys, outputAmounts, smallestAmount,
							chosenP2PKHScript,
							payoutAmount,
								//NOTE: THIS variable is typically equal to minBlock, except
								// it ALWAYS advances (even when potentialSale)
								// That's why we use it here - to set the nLockTime
							potentialSaleMinBlock,
							specifiedBsvChangePKH, //EXT_FUNDING_CHANGE_PKH,
							continueContentName.toString("hex"),
							continueContentHex,
							txDescriptor.toString("hex"),
							txToPublishHex,
							payCodeHex,
							newOwnerP2Pkh,         // instaBuy receiving p2pkh
							rabinPkhOfPotentialBuyer,
							priceInSats,           // NEW instaBuy price
							publisherRabinPKH,
							claimExpired,   //FIXME: rename claimExpired? other operations? for instaBuy?
							sigRes, publisherPK,
							optionalUpdateScriptHexString,
							optionalAskBidScriptHexString,
						optionalAdditionalBalance    ) // NOTE: this amount should have ALREADY been added to 6th param: outputAmounts[0]

	console.log("libClaimAndPublish(): results from CONTINUE: ", { txid: results.txid, change: results.change})

	if ( results.txid.startsWith('11111111111111111111111111111111') ) {
		console.log("We've got a bogus tx, we'll save its raw bytes")
		// insert results.rawTx into table for this purpose
		await dbAddRawTx(theDB, results.txid, results.rawTx)
	}

	return results.txid
} //libClaimAndPublish()

//export /* */
async function libApplyBuilderFeeReduction(theState, previousTxid, whichOutput,

									p2cAuthcode,               // sig     (needs endian reversal)
									p2dAuthcodePadding,        // padding
									p2dOldOwnerRabinPubKeyLE,  // pubkey

							   theDB,
							   dbAddRawTx,
							   specifiedPrivateKey) {

	const privKey = new bsv.PrivateKey.fromWIF(specifiedPrivateKey)
	const specifiedPubKey = bsv.PublicKey.fromPrivateKey( privKey )
	const specifiedBsvChangePKH = bsv.crypto.Hash.sha256ripemd160(specifiedPubKey.toBuffer('hex')).toString('hex')

	console.warn('libApplyBuilderFeeReduction(): builder pubkey is ' + p2dOldOwnerRabinPubKeyLE + " <-------\n\n")
	const builderRabinPubKeyLEBuffer = Buffer.from(p2dOldOwnerRabinPubKeyLE, 'hex');
	const builderRabinPKH = bsv.crypto.Hash.sha256ripemd160(builderRabinPubKeyLEBuffer).toString('hex')
	console.warn('libApplyBuilderFeeReduction(): builder rabinPKH is ' + builderRabinPKH + " <-------\n\n")


	const blockHeight = theState.blockNumInt
	console.log("You wish to BUILD a fee-reducing tx on the Continuous (main) publishing line.\n")

	// This is the PREVIOUS scriptPubKey: locking script plus previous state
	const oldScriptPubKey = generateContinueLockingScript( theState.stateHex, false )

	const pureContinueLockingScript = generateContinueLockingScript('', false)

	const genesisBlockInt = Buffer.from(theState.genesisBlock, "hex").readUInt32LE(0)
	console.log('  genesis block int: ', genesisBlockInt)

	var payScriptQueue = []   // to build payout scripts. (This lags the payStateQueue.)
	// Payout to EVERY builder PKH
	console.log('libApplyBuilderFeeReduction(): the state PKHs has length ' + theState.builderPKHs.length)
	console.log("Will payout to ONE of " + theState.builderPKHs.length + " builders at a time.")
	var buildersStateString = ''
	let voteTally = 0
	let foundTheBuilder = false
	let specialOp = CONTINUE_SPECIAL_OP_BUILDER_VOTES_BASE
	// Find for which builder this EBFRA applies, and change his vote from NO ('00'), to YES ('FF')
	for ( var i = 0; i < theState.builderPKHs.length; i++ ) {

		const oldVote = theState.builderVotes[i]
		if ( oldVote !== '00' ) {
			voteTally++
			console.warn("    adding vote for builder #" + i + ". Tally now " + voteTally + " <-----<<")
		}

		if ( foundTheBuilder ) {
			console.log("We've already found the builder, and applied the EBFRA. Won't look to do so for builder #" + i + " (base-0)")
		} else if ( oldVote !== '00' ) {
			//throw new Error("35608: a builder can't vote more than once")
			console.log("The EBFRA can't apply to builder " + i + " (base-0), since he's already voted (not yet having looked at his rabin PKH anyway)")
		} else if ( theState.builderRabins[i] === builderRabinPKH ) { // Check if the EBFRA applies to this builder
			console.warn("\n    builder # " + i + " is the one!")
			console.warn("    his vote WAS " + oldVote + ", BUT, will modify it to YES/0xff")
			theState.builderVotes[i] = 'FF' // change his vote
			voteTally++                     // add his vote to the total (not that we do anything with it here)
			foundTheBuilder = true

			//NOTE: we signal for which builder, by adding 4 to the builder # (base-0) - and setting specialOp to this
			specialOp += i
		}

		console.warn("Adding these strings to buildersStateString: " + theState.builderPKHs[i])
		console.log("   and rabin: " + theState.builderRabins[i])
		console.log("   and vote: " + theState.builderVotes[i])

		buildersStateString += theState.builderPKHs[i]
		buildersStateString += theState.builderRabins[i]
		buildersStateString += theState.builderVotes[i]

		// Only one of these payouts will be used for a given tx (deterministically)
		const payScript = 'OP_DUP OP_HASH160 ' + theState.builderPKHs[i] + ' OP_EQUALVERIFY OP_CHECKSIG'
		payScriptQueue.push( payScript )
		console.log("    Script to builder " + i + ": " + payScript)
	} // each builder

	console.log('libApplyBuilderFeeReduction():  there are now ' + voteTally + ' builder fee reduction votes. buildersStateString: \'' + buildersStateString + '\'\n')
	if ( !foundTheBuilder ) {
		throw new Error("35607: failed to find a builder for which this applies")
	}

	console.warn("newBlockInt is initialized to " + blockHeight)
	const newBlockInt = blockHeight   // used EITHER for minBlock, or potentialSaleMinBlock
	// depending on the tx type, one of these two variables will change
	let minBlock              = newBlockInt

	const oldBlockNumInt = theState.blockNumInt

	console.log("libApplyBuilderFeeReduction(): BTW: PREVIOUS blockNumInt was " + oldBlockNumInt)


	const minBlockHex = fourByteLE(minBlock)
	console.log('  minBlock int: ', minBlock)
	console.log('  minBlock hex: ', minBlockHex)


	console.warn("libApplyBuilderFeeReduction(): BTW: state is: ", theState)

	const smallestAmount = Buffer.from(theState.smallestAmount, "hex").readUInt16LE(0)
	console.log("libApplyBuilderFeeReduction(): theState.quarterlyCount was " + theState.quarterlyCount)

//FIXME: ask user if the smallestAmount has gone down
	console.log("\n\nFIXME: ask if the smallestAmount has gone down (mention current level). If it has, "
			+   "use the smaller value.\n\n")

	console.log('- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -')
	console.log(' EBFRA CONTINUE script pub key (newPublishScriptPubKey):')

	// keep mode UNCHANGED
	const continueModeHexChar =  theState.mode; // could now be P, K, or p     '70' // 'p'ublish

	const newPublishScriptPubKey = buildContinueScriptPubKey(pureContinueLockingScript,
			theState.ownerP2PKH,
			theState.rabinOfPotentialBuyer,
			theState.blockNumInt, //potentialSaleMinBlock,
			theState.potentialSaleFlag,
			theState.priceInSats,
			theState.quarterlyCountHex,
			theState.ownerCountHex,
			theState.renewalDeadlineInt,
			theState.ownerRabinPKH,
			buildersStateString,
			theState.genesisBlock,
			theState.blockNumInt,   			// may be different from new var 'potentialSaleMinBlock'
			smallestAmount,
			theState.namePath,
			oneByteLE( theState.namePath.length / 2 ),
			continueModeHexChar)


	var continueScriptPubKeys  // This is an array of scriptPubKeys


	var outputAmounts = [] // The value (satoshis) for each output
	continueScriptPubKeys = new Array( 1 )
	continueScriptPubKeys[0] = newPublishScriptPubKey

	//if QC > 5, this multiplier drops to 1
	// WAIT. if old mode was P, and we're applying an EBFRA, keep multiplier at 1. We don't want to
	// penalize someone who applies an EBFRA before even claiming.
	let outputBalanceMultipler = continueModeHexChar === '50' || theState.quarterlyCount > 5 ?
									1 : BOUNTY_MULTIPLIER_CONTINUE;

	outputAmounts[0] = smallestAmount * outputBalanceMultipler


	// In practice, the contract output amount could drop to whatever the miners are willing to accest as non-dust

	//FIXME: note: readUInt() only goes up to 6 bytes - not the 8 we actually need
	const oldAmount = Buffer.from(theState.contractSatoshis, "hex").readUIntLE(0,6)
	// don't payout while applying EBFRA
	var payoutAmount = 0;
	console.log("\nStandard payout amount (per builder) is " + payoutAmount)


	let sigRes = {
		signature: '0',
		sigHexLE: null,
		paddingBytes: '00'
	}

	let publisherPK


	console.log("\nSpecial Operation: applying an Emergency Builder Fee Reduction Authorization. " +
				"It's like an authcode (signature+padding+pubkey), but from a builder).")
	console.log("There is no PAYOUT MULTIPLIER")

	// use ALTERNATIVE parameter - provide signature as a LE Hex
	// Triggered by setting .signature to null
	sigRes.signature = null;         //WARNING: unobvious feature of executePubUpdateContract():
								     //         If this is null, use .sigHexLE (LE field/param of signature)

	//NOTE: we had to REVERSE the calculated sigHexLE (calculated in buildShizzleTx.js's  signABuilderVote() )
						// (signature with reversed hex)
	sigRes.sigHexLE = p2cAuthcode; // ALREADY-swapped   //swapEndian(p2cAuthcode);
								//FIXME: rename
	sigRes.paddingBytes = p2dAuthcodePadding;  //FIXME: rename

	publisherPK = p2dOldOwnerRabinPubKeyLE; // revealed elsewhere by a builder

	console.warn("  FOR THE RECORD: using already-reverse sigHexLE of " + sigRes.sigHexLE)

	// NOTE: potentialSaleMinBlock typically matches minBlock.
	// IF, however, we specify a potentialSale, THIS will advance (while minBlock won't change).
	// so, now it's always used for nLockTime (potentialSaleMinBlock)

	// DON'T choose a SINGLE payout p2pkh. There is NO builder payout when applying an EBFRA
	const chosenP2PKHScript = [];

	let rabinOfPotentialBuyer = '0000000000000000000000000000000000000000'
	let priceInSats = 0
	let ownerRabinPKH = '0000000000000000000000000000000000000000'
	if ( continueModeHexChar === '50' ) { // 'P'
		console.warn("libApplyBuilderFeeReduction(): before executing, mode P")
	} else if ( continueModeHexChar === '4B' || continueModeHexChar === '4b' ) {
		console.warn("libApplyBuilderFeeReduction(): before executing, mode K")
		ownerRabinPKH = theState.ownerRabinPKH
	} else { // must be 'p'. should have full state
		console.warn("libApplyBuilderFeeReduction(): before executing, mode p")
		rabinOfPotentialBuyer = theState.rabinOfPotentialBuyer
		priceInSats = theState.priceInSats
		ownerRabinPKH = theState.ownerRabinPKH
	}

	let ownerP2Pkh
	if ( typeof theState.ownerP2PKH == 'undefined' ) {
		console.error("newOwnerP2Pkh is undefined, so, setting to blank")
		ownerP2Pkh = '0000000000000000000000000000000000000000'
	} else {
		ownerP2Pkh = theState.ownerP2PKH
	}

	const results = await executePubUpdateContract(specifiedPrivateKey,
							previousTxid, oldScriptPubKey, oldAmount,
							continueScriptPubKeys, outputAmounts, smallestAmount,
							chosenP2PKHScript,
							payoutAmount,
								//NOTE: THIS variable is typically equal to minBlock, except
								// it always advances (even when potentialSale) - except maybe now, when applying an EBFRA
								// That's why we use it here - to set the nLockTime
							theState.blockNumInt, //potentialSaleMinBlock,
							specifiedBsvChangePKH, //EXT_FUNDING_CHANGE_PKH,
							'', //continueContentName.toString("hex"), BASE-64 encoding!!
							'', //Buffer.from(continueContent).toString("hex"),
							'', //txDescriptor.toString("hex"),    BASE-64 encoding!!
							'', //txToPublishHex,
							'', //payCodeHex,
							ownerP2Pkh,
							rabinOfPotentialBuyer,
							priceInSats,
							ownerRabinPKH,
							int2Asm(specialOp),      //claimExpired,
							sigRes, publisherPK,
							'', //optionalUpdateScriptHexString,
							'')  //optionalAskBidScriptHexString)

	console.log("libApplyBuilderFeeReduction(): results from CONTINUE: ", { txid: results.txid, change: results.change})

	if ( results.txid.startsWith('11111111111111111111111111111111') ) {
		console.log("We've got a bogus tx, we'll save its raw bytes")
		// insert results.rawTx into table for this purpose
		await dbAddRawTx(theDB, results.txid, results.rawTx)
	}

	return results.txid
} // new func to applyBuilderFeeReduction()

//export /* */
async function libAnnounceBuilderFeeReductionAuth(theState, previousTxid, whichOutput,
								blockHeightInt,
									theVote,
									privKeyP,
									privKeyQ,
							   theDB,
							   dbAddRawTx,
							   specifiedPrivateKey) {

	const privKey = new bsv.PrivateKey.fromWIF(specifiedPrivateKey)
	const specifiedPubKey = bsv.PublicKey.fromPrivateKey( privKey )
	const specifiedBsvChangePKH = bsv.crypto.Hash.sha256ripemd160(specifiedPubKey.toBuffer('hex')).toString('hex')

	//FIXME: 3 for claiming bounty
	const operationType = theVote ? 1 : 2

	//FIXME: compare this to getSigParts() - in ContractSupport
	let sigStuff = signABuilderVote(privKeyP, privKeyQ, theVote)

	console.warn("libAnnounceBuilderFeeReductionAuth(): back from signABuilderVote(). "
				+ "Here's what we got:", sigStuff)

	const deadlineBlockHeight = theState.deadlineInt
	console.log("You wish to BUILD an EBFRA Announcement tx on an EBFRA Speaker output.\n")
	console.log("Let's first RECREATE the old script - an EBFRA Speaker output.\n")

	// This is the PREVIOUS scriptPubKey: locking script plus previous state
	const oldScriptPubKey = generateEBFRALockingScript( theState.stateHex, false )

	console.warn("deadlineInt is initialized to " + deadlineBlockHeight)

	const deadlineHex = fourByteLE(deadlineBlockHeight)
	console.log('  deadline int: ', deadlineBlockHeight)
	console.log('  deadline hex: ', deadlineHex)

	console.warn("libAnnounceBuilderFeeReductionAuth(): BTW: state is: ", theState)

	console.log('- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -')
	console.log(' EBFRA Announcement script pub key (newPublishScriptPubKey):')

	let newPublishScriptPubKey
	if ( theVote ) {
		newPublishScriptPubKey = buildEbfraScriptPubKey(
				theState.namePath,
				sigStuff.pubkeyLE,                   //builderRabinPubKeyLE,
				int2Asm(sigStuff.sigInfo.signature), //builderSig,
				sigStuff.sigInfo.paddingBytes)       //messagePaddingLen,
	} else {
		newPublishScriptPubKey = buildEbfraScriptPubKey(
			theState.namePath,
			'',  //builderRabinPubKeyLE,
			'',  //builderSig,
			'')  //messagePaddingLen,
	}

	let outputAmounts = [] // The value (satoshis) for each output
	outputAmounts[0] = 0

//FIXME: const?   = [ newPublishScriptPubKey ]
	let ebfraScriptPubKeys = new Array( 1 ) // This is an array of scriptPubKeys
	ebfraScriptPubKeys[0] = newPublishScriptPubKey

	//FIXME: note: readUInt() only goes up to 6 bytes - not the 8 we actually need
	const oldAmount = Buffer.from(theState.contractSatoshis, "hex").readUIntLE(0,6)

	let sigRes = {
		signature: sigStuff.sigInfo.signature, //'0',
		sigHexLE: null,
		paddingBytes: sigStuff.sigInfo.paddingBytes //'00'
	}

	console.log("\nSpecial Operation: Announcing an Emergency Builder Fee Reduction Authorization. " +
				"It's like an authcode (signature+padding+pubkey), but from a builder).")

	const builderRabinPubKeyLE = sigStuff.pubkeyLE;  //builderRabinPubKeyLE;

	console.warn("  FOR THE RECORD: using already-reverse sigHexLE of " + sigRes.sigHexLE)

	// DON'T choose a SINGLE payout p2pkh. There is NO builder payout when applying an EBFRA
	const chosenP2PKHScript = [];

	const payoutAmount = 0

	const results = await executeEbfraContract(specifiedPrivateKey,
							previousTxid,
							oldScriptPubKey, oldAmount,
							ebfraScriptPubKeys, outputAmounts,

							chosenP2PKHScript,
							payoutAmount,

							specifiedBsvChangePKH, //EXT_FUNDING_CHANGE_PKH,

						blockHeightInt,  // for nLockTime
							operationType,
							sigRes, builderRabinPubKeyLE,
//FIXME: rename outputIndexSpentFrom ?  or, spendingFromOutputIdx,
//       or indexOfOutputFromWhichWereSpending
							whichOutput)

	console.log("libAnnounceBuilderFeeReductionAuth(): results from EBFRA Announce: ", { txid: results.txid, change: results.change})

	if ( results.txid.startsWith('11111111111111111111111111111111') ) {
		console.log("We've got a bogus tx, we'll save its raw bytes")
		// insert results.rawTx into table for this purpose
		await dbAddRawTx(theDB, results.txid, results.rawTx)
	}

	return results.txid
} // libAnnounceBuilderFeeReductionAuth()

//export /* */
function libGetOptionsForAskBidSubMode(subModeChar) {

	// review presentAskBidOptionsBasedOnState()

	switch(subModeChar) {
		case 'N':
			// askBidHandleFirstAsk
			// getPriceThenFinishAnAskNowThatWeHavePrevState() <---

			// ask/modAsk  		 (owner)  askBidAsk()
			// cancel      		 (owner)  askBidCancel()

			return ['A', 'C'];
		case 'O':
			// presentOptionsForAskBidSubModeOpen()

			// ask/modAsk  		 (owner)  askBidAsk()
			// cancel      		 (owner)  askBidCancel()
			// bid         		 (bidder) askBidAddBid()
			// modBid	   		 (bidder) askBidModBid()
			// refundBid   		 (bidder) askBidRefundBid()
			// refundBalk  		 (bidder) askBidRefundBalk()
			// requestPermission (owner)  askBidRequestPerm()

			return ['A', 'C', 'B', 'b', 'R', 'r', 'P'];
		case 'W':
			// presentOptionsForSubModeWaiting()

			// cancel      		(owner)  		askBidCancel()
			// deny				(top bidder)	askBidDenyPermission()
			// grant			(top bidder)	askBidGrant()
			// timestamp		(bidder. any?)	askBidTimeStamp()
			// refundBid		(bidder)		askBidRefundBid()
			// refundBalk		(bidder)		askBidRefundBalk()?
			// ask				(owner - only if waiting has timed-out)
			//									askBidAsk()

			//FIXME: check on time-out?

			return ['C', 'D', 'G', 'T', 'R', 'r', 'A']; // but ask only if...
		case 'G':
			// presentOptionsForSubModeGranted()

			// ask/modAsk  		 (once expired) (owner)        askBidAsk()
			// cancel      		                (owner)        askBidCancel()
			// bid         		 (once expired) (bidder)       askBidAddBid()
			// modBid	   		 (once expired) (bidder)       askBidModBid()
			// refundBid   		 (not top bid)  (bidder)       askBidRefundBid()
			// refundBalk  		                (bidder)       askBidRefundBalk()
			// requestPermission (once expired) (owner)        askBidRequestPerm()
			// execute sale                     (owner)        askBidExecuteSale()
			// timeStamp		 (once expired) (bidder. any?) askBidTimeStamp()

			//FIXME: check on time-out?

			return ['A', 'C', 'B', 'b', 'R', 'r', 'P', 'E', 'T']
		case 'c':
		case 'e':
			// presentOptionsForSubModeClosing()

			// refundBid		(bidder)		askBidRefundBid()
			// refundBalk		(bidder)		askBidRefundBalk()

			return ['R', 'r'];
		case 'C':
			// allow nothing
			//FIXME: there actually shouldn't be any spendable 'contract' UTXO
			//       It should have OP_FALSE  OP_RETURN ...... C N
			return [];
		case 'E':
			// allow nothing
			// show the results of the sale - and explain what the winner should do
			return [];
		default:
			throw new Error("24404: invalid subMode: " + subModeChar)
	}
}

//export /* */
async function libBuildOnAskBid(theState, previousTxid, whichOutputWasSpent,

								commandHexByte,
								newSubMode,
								regardingEntry,
								refundP2PKH,
								newBid,				// only relevant/used for Add and Mod bid functions

								newRabinPKH,
								paramPrivKeyP,
								paramPrivKeyQ,
								theDB,
								dbAddRawTx,
								specifiedPrivateKey) {

	const privKey = new bsv.PrivateKey.fromWIF(specifiedPrivateKey)
	const specifiedPubKey = bsv.PublicKey.fromPrivateKey( privKey )
	const specifiedBsvChangePKH = bsv.crypto.Hash.sha256ripemd160(specifiedPubKey.toBuffer('hex')).toString('hex')

	const oldAmount = parseInt(Buffer.from(theState.contractSatoshis, "hex").readUIntLE(0,6))
	//FIXME: note: readUInt() only goes up to 6 bytes - not the 8 we actually need
	var newAmounts = []

	let bidsArray = theState.bidEntries
	let balksArray = theState.balkEntries

	//FIXME: dangerous?
	const oldBid = bidsArray[regardingEntry]

	let refundScript
	let refundScriptsArray = []
	let refundAmount = 0

	let sigNeeded = true
	let specialSigNeeded = false
	let specialMessage	// for authcode generation (when EXECUTING sale)
	let sigInfo
	let rabinPubKeyLE

	let priceParam
	let insertOpFalseBeforeFinalOpReturn = false

	switch ( hexByteToAscii(commandHexByte) ) {
		case 'A':
			console.log("Auction command: ASK")
			newAmounts[0] = oldAmount

			priceParam = theState.askingPrice
			break;
		case 'E':
			sigNeeded = false
			specialSigNeeded = true
			console.log("Auction command: EXECUTE")
			// and we better've set regardingEntry to best entry, earlier
			priceParam = bidsArray[regardingEntry].price

			theState.beforeBlock = 0

			if ( theState.bidEntries.length === 1  && theState.balkEntries.length === 0 ) {
				console.log("NOTE: This is the ONLY bid/balk. Setting subMode to 'E'. <----")
				newSubMode = '45'
			}

			const theBid = theState.bidEntries[regardingEntry]
			const topPrice = theBid.price
			console.log("That bid is for " + topPrice)

			//FIXME: revisit which fields are needed
			//       MAYBE should have the namePath, and maybe OC, and QC?
			specialMessage = theState.namePath
						+ theState.quarterlyCountHex
						//+ commandHexByte
						//+ fourByteLE(theState.deadlineBlock)
						//+ fourByteLE(theState.blockNumInt)
						//+ fiveByteLE(topPrice)
						+ theBid.rabinPKH
			console.log("EXEC: special message:\n"
						+ "\tnamePath:          " + theState.namePath + "\n"
						+ "\tquarterlyCountHex: " + theState.quarterlyCountHex + "\n"
						//+ "\toperation:       " + commandHexByte + "\n"
						//+ "\tdeadlineBlock:   " + fourByteLE(theState.deadlineBlock) + "\n"
						//+ "\tblockNum:        " + fourByteLE(theState.blockNumInt) + "\n"
						//+ "\tprice:           " + fiveByteLE(topPrice) + "\n"
						+ "\tuserRabinPKH:      " + theBid.rabinPKH + "\n\n")

			// Remove winning/top bid from array
			theState.bidEntries = removeBid( theState.bidEntries, regardingEntry)

			// NOTE: this is what we're supposed to keep here, now that it's executed
			theState.bestBidNum = 0 //255  255 screws-up index calculations within the contract
			theState.worstBidNum = 0 //255

			const priceAmount = parseInt(topPrice)
			const reserveAmount = parseInt(theBid.reserveFunds)
			const totalDebit = priceAmount + reserveAmount
			newAmounts[0] = oldAmount - priceAmount - reserveAmount
			console.log("\nreserveAmount is " + reserveAmount)
			if ( reserveAmount > 0 ) {
				refundScript = 'OP_DUP OP_HASH160 ' + theBid.refundPKH + ' OP_EQUALVERIFY OP_CHECKSIG'
				refundAmount = reserveAmount
				refundScriptsArray = [ refundScript ]
				console.log("EXECUTE refundScript is " + refundScript)
				if ( refundAmount < theState.smallestAmount ) {
					console.log("\t\tWe have an issue. The refund amount is small: " + refundAmount)
					console.log("\n\nWe're going to increase it to " + theState.smallestAmount
							+ " and have the difference deducted from the change amount")
					refundAmount = theState.smallestAmount
				}
			}
			theState.sumOfAllFunds -=  totalDebit;
			console.log("debit is " + totalDebit)
			console.log("sumOfAllFunds is " + theState.sumOfAllFunds)
			break;
		case 'P':
			console.log("Auction command: request PERMISSION")
			newAmounts[0] = oldAmount
			theState.beforeBlock = PERMISSION_GRANT_DEADLINE_BLOCKS + theState.blockNumInt
			//regardingEntry = theState.bestBidNum
			//FIXME: document how to set regardingEntry
			priceParam = bidsArray[regardingEntry].price
			break;
		case 'C':
			console.log("Auction command: CANCEL")
			newAmounts[0] = oldAmount
			priceParam = 0
			theState.beforeBlock = 0   // where in the contract is this enforced?
			break;
		case 'B':
			sigNeeded = false
			console.log("Auction command: BID")
			priceParam = newBid

			console.warn("numBids WAS " + theState.numBids)
			theState.numBids += 1
			console.warn("numBids is NOW " + theState.numBids)

			console.warn("sumOfAllFunds WAS " + theState.sumOfAllFunds)
			theState.sumOfAllFunds = parseInt(theState.sumOfAllFunds) + parseInt(newBid)
			console.warn("sumOfAllFunds is NOW " + theState.sumOfAllFunds + "  (but COULD change if we jettison an existing bid)")

			// Add our bid to the array. It might eject an existing, lower, bid - if there are already MAX_NUM_BIDS bids
			const appendResult = appendBid( bidsArray, newBid, newRabinPKH, refundP2PKH, theState.blockNumInt, MAX_NUM_BIDS)
			if ( typeof (appendResult.replacedEntry) != 'undefined' ) {
				console.log("\nThere was a lower bid that needed to be jettisoned. Let's build a refund script.")

				refundScript = 'OP_DUP OP_HASH160 ' + appendResult.replacedEntry.refundPKH + ' OP_EQUALVERIFY OP_CHECKSIG'
				refundAmount = appendResult.replacedEntry.price + appendResult.replacedEntry.reserveFunds
				refundScriptsArray = [ refundScript ]
				theState.sumOfAllFunds -=  refundAmount;
				console.log("ADDBID:   refunding " + refundAmount + " sats  <=====")
				console.log("                     (" + appendResult.replacedEntry.price + " + " + appendResult.replacedEntry.reserveFunds + ")")
				console.log("  Refund script: " + refundScript + "     <=======\n")
			} else {
				console.log("No entry was replaced/ejected when we added this bid.\n")
			}
			console.log("    Result of Bid-append: " + JSON.stringify(appendResult, null, 2))
			theState.bidEntries = appendResult.bidEntries

			// re-calculate best and worst
			theState.bestBidNum = getBestBidIndex( theState.bidEntries )
			theState.worstBidNum = getWorstBidIndex( theState.bidEntries )
			const myBidIndex = findMyBidIndex( theState.bidEntries, newBid )

			console.log("AskBid NOTE: BALKS array now: ", theState.balkEntries)
			console.log("AskBid NOTE: BID array now: ", theState.bidEntries)

			console.warn("oldAmount WAS " + oldAmount)
			newAmounts[0] = oldAmount + newBid - refundAmount
			console.warn("newAmount IS " + newAmounts[0])

			break;
		case 'b':
			console.warn("libBuildOnAskBid():  building a Bid-modifying tx...")
			priceParam = newBid

			console.warn("sumOfAllFunds WAS " + theState.sumOfAllFunds + " but may now change")

			var extraFundsNeeded = 0
			console.warn(' --> Bid ' + regardingEntry + ' is currently for ' + oldBid.price
					+ ' sats (with ' + oldBid.reserveFunds + ' in reserve)')

			const priceChange = newBid - oldBid.price
			if ( priceChange > 0 ) {
				if ( priceChange > oldBid.reserveFunds ) {
					extraFundsNeeded = priceChange - oldBid.reserveFunds
					console.log("In order to do this, you'll need to supply an extra "
							+ extraFundsNeeded + " satoshis.")
				} else {
					console.log("Doing this will reduce your reserve funds from "
							+ oldBid.reserveFunds + " to " + (oldBid.reserveFunds - priceChange)
							+ " satoshis.")

					//FIXME: change reserve funds?
				}
			} else {
				console.log('Reducing bid to ' + newBid + ' will add '
						+ (-priceChange) + ' sats to your reserveFunds - setting it at '
						+ (oldBid.reserveFunds - priceChange) + ' satoshis')

					//FIXME: change reserve funds?
			}

			theState.sumOfAllFunds += extraFundsNeeded
			console.warn("sumOfAllFunds is NOW " + theState.sumOfAllFunds)

			// This should modify the reserves, if necessary
			var newBidArray = modifyBid( bidsArray, regardingEntry, newBid, theState.blockNumInt )
			console.log("\nAskBid NOTE: the NEW BID array will be as follows: ", newBidArray)

			// re-calculate best and worst
			theState.bestBidNum = getBestBidIndex( newBidArray )
			theState.worstBidNum = getWorstBidIndex( newBidArray )
			console.log("\nThe BEST bid will be at index " + theState.bestBidNum)
			console.log("The WORST bid will be at index " + theState.worstBidNum)

			console.log("Note that your Rabin PKH is " + newBidArray[regardingEntry].rabinPKH)
			console.log("To execute this transaction - modifying the bid, you'll need to sign with "
					+ "the private key(s) associated with this PKH, AND supply a new (preferred), "
					+ "or identical (discouraged) Rabin PKH.\n")
			console.log("WARNING: If you do not have and safely preserve the private key(s) to the Rabin PKH "
					+ "which you provide, you will lose your BID - irrevocably. NO ONE is storing this for you.\n")

			theState.bidEntries = newBidArray
			theState.subMode = '4F'

			newAmounts[0] = oldAmount + extraFundsNeeded
			break;
		case 'D':
			console.warn("libBuildOnAskBid():  building a BALK tx...")
			priceParam = bidsArray[regardingEntry].price  //had better be the BEST bid's price


			// calculate TIME/BLOCK penalty
			// How long did topBidder wait to respond to Request for Permission to execute sale?
			console.warn("libBuildOnAskBid(): calculating BALK penalty. beforeBlock is " + theState.beforeBlock)
			console.warn("libBuildOnAskBid(): calculating BALK penalty. blockNumInt is " + theState.blockNumInt)
			const blocksBeforeDeadline = theState.beforeBlock - theState.blockNumInt;
			console.warn("libBuildOnAskBid(): blocksBeforeDeadline is " + blocksBeforeDeadline)

			//FIXME: decide how long to wait before DENYing the request
			//       (I)mmediately, (D)elayed, or (L)ast-second (I/D/L)?

			// 255 < blocksBeforeDeadline    			256
			theState.blockNumInt = theState.beforeBlock - 256
			// no penalty
			const blocksPenalty = 0
/*
			// 200 < blocks before deadline <= 255      220
			theState.blockNumInt = theState.beforeBlock - 220
			const blocksPenalty = REFUND_DELAY_FOR_SLOW_RESPONSE

			// blocks before deadline <= 200			1
			theState.blockNumInt = theState.beforeBlock - 1
			const blocksPenalty = REFUND_DELAY_FOR_VSLOW_RESPONSE
/* */

			console.log("\nWe will probably apply a penalty of " + blocksPenalty + " blocks.")
			console.log("If the existing 'earliestRefundBlock' is later than the current block plus the penalty, "
					+ "then we won't bother applying the penalty.\n")

			// Apply a penalty for the best bid - which is about to be moved to the balk list
			theState.bidEntries = penalizeBid( theState.bidEntries, regardingEntry, theState.blockNumInt, blocksPenalty )
			console.log("    The NEW (penalized) 'earliestRefundBlock' is " + theState.bidEntries[regardingEntry].earliestRefundBlock + "\n")
			const balkedBid = theState.bidEntries[regardingEntry]

			theState.balkEntries = copyBid2Balk( theState.bidEntries, theState.balkEntries, regardingEntry)
			theState.bidEntries = removeBid( theState.bidEntries, regardingEntry)

			console.log("ASKBID DENY NOTE: BALKS array now: ", theState.balkEntries)
			console.log("    number of balks: ", twoByteLE( theState.balkEntries.length ) );

			console.log("ASKBID DENY NOTE: BID array now: ", theState.bidEntries)
			console.log("    number of bids: ", oneByteLE( theState.bidEntries.length ) );

			// re-calculate best and worst
			theState.bestBidNum = getBestBidIndex( theState.bidEntries )
			theState.worstBidNum = getWorstBidIndex( theState.bidEntries )


			theState.subMode = '4F'  // 'O'pen (since we're DENYing)
			theState.beforeBlock = 0 // No longer relevant

			newAmounts[0] = oldAmount  // nothing's refunded yet
			break;
		case 'G':
			console.warn("libBuildOnAskBid():  building a GRANT tx...")
			priceParam = bidsArray[regardingEntry].price  //had better be the BEST bid's price
			theState.beforeBlock = theState.blockNumInt + EXECUTION_TIME_LIMIT
			newAmounts[0] = oldAmount
			break;
		case 'T':
			sigNeeded = false
			console.warn("libBuildOnAskBid():  building a TIMESTAMP tx...")
			priceParam = 0

//FIXME: we could double-check the new subMode here? hmmm.

			console.log("Current block is " + theState.blockNumInt)
			console.log("Must wait until " + theState.beforeBlock)
			console.log("\nSince you're looking to TIMESTAMP, we'll assume you want the new blockNum to be AFTER the "
					+ "current subMode ('W' or 'G') has elapsed. We'll make sure it's at least one block after timeout.\n")
			if ( theState.blockNumInt >= theState.beforeBlock + 1 ) {
				console.log("NOTE: enough time had already passed.")
				theState.blockNumInt += 1
			} else {
				theState.blockNumInt = theState.beforeBlock + 1  //FIXME: should also work with +1. and +0. test
				console.log("Advancing blockNumInt to " + theState.blockNumInt)
			}

			// We're either waiting or granted. Which one
			if ( theState.subMode === '57' ) {  // ascii hex for 'W'aiting
				console.log("\n\nWe're going to create a TIMESTAMP tx, transitioning "
						+ "us from WAIT mode to OPEN (or Canceled/Closed if very late).")
				console.log("We'll also move the best bid to the balk list.")

				// assess a penalty for the best bid - which is about to be moved to the balk list
				const blocksPenalty = REFUND_DELAY_FOR_NO_RESPONSE
				theState.bidEntries = penalizeBid( theState.bidEntries, regardingEntry, theState.blockNumInt, blocksPenalty )

				// Move bestBid to balk list
				theState.balkEntries = copyBid2Balk( theState.bidEntries, theState.balkEntries, regardingEntry)
				theState.bidEntries = removeBid( theState.bidEntries, regardingEntry)

				// re-calculate best and worst
				theState.bestBidNum = getBestBidIndex( theState.bidEntries )
				theState.worstBidNum = getWorstBidIndex( theState.bidEntries )

				console.log("Since the best bidder never gave permission for the owner/seller to execute that bid, "
						+ "the bid must be moved to the BALK list, and probably get penalized (earliest withdrawal block).")
				console.log("\nThe NEW best bid will be at index " + theState.bestBidNum)
				console.log("The NEW worst bid will be at index " + theState.worstBidNum)
			} else if ( theState.subMode === '47') {  // ascii hex for 'G'ranted
				console.log("\n\nWe're going to create a TIMESTAMP tx, transitioning "
						+ "us from GRANTED mode to OPEN (or Canceled/Closed if very late)")

				// NOTE: there is no 'penalty' for the owner/seller

				//REVIEW: not executed (timed-out), we don't move anything to balk list, right?
			} else {
				console.log("\n\nWe're in the wrong subMode to issue a TIMESTAMP tx. SubMode: " + theState.subMode)
				throw new Error("31415: wrong subMode for TIMESTAMP tx: " + theState.subMod)
			}

			console.log("ASKBID TIMESTAMP NOTE: BALKS array now: ", theState.balkEntries)
			console.log("    number of balks: ", twoByteLE( theState.balkEntries.length ) );

			console.log("ASKBID TIMESTAMP NOTE: BID array now: ", theState.bidEntries)
			console.log("    number of bids: ", oneByteLE( theState.bidEntries.length ) );

			theState.beforeBlock = 0
			console.log("\nBeforeBlock is set to " + theState.beforeBlock)

			console.log("For the purposes of this test, we'll set the current block to the block after that. Hmm. Will we? <---")
			//theState.blockNumInt = theState.beforeBlock + 1

			rabinPubKeyLE = 'OP_0'
			// Adding a fresh bid requires no signature
			sigInfo = {
				signature: '0',
				paddingBytes: '00'    // NOTE: maybe OP_0 bothers the debugger?
			}

			//FIXME check if W or G has expired. if so, change mode to 'O'
			//    if sale expired, change to c or e (C, E possible if no bids, right?)

			//FIXME: needed?
			newRabinPKH = "0102030405060708090a0b0c0d0e0f1011121314"

			newAmounts[0] = oldAmount
			break;
		case 'R':
			console.warn("libBuildOnAskBid():  building a Bid-refunding tx...")

			// If not c, nor e, we'll need a signature to authorize a refund.
			// We check here because we'll soon be changing the subMode.
			sigNeeded = theState.subMode !== '63' && theState.subMode !== '65'

			priceParam = oldBid.price + oldBid.reserveFunds
			console.log("\nTo refund that bid, the current block height must be at least equal to "
					+ "the 'earliestRefundBlock' of the bid.")
			const earliestBlock = oldBid.earliestRefundBlock
			console.log("The earliest refund block for bid #" + regardingEntry + " is " + earliestBlock)
			console.log("The 'current' block is " + theState.blockNumInt)

			let blocksRemaining = earliestBlock - theState.blockNumInt
			console.log("There are still " + blocksRemaining + " blocks remaining, before these BID funds "
					+"can be refunded (compared to the previous tx's 'blockNum').")
			if ( blocksRemaining > 0 ) {


		//FIXME: maybe pass-in the devTest mod, bbm setting
		//       Also, what's the current actual (real) block height?


				console.log("We're going to assume you're alright with advancing the blocknum to a "
						+ "time when we CAN refund them - block #" + earliestBlock)
			} else {
				blocksRemaining = 1 // let's keep advancing
				console.log("Enough time has elapsed. These BID funds are ready to be released.")
			}


			console.log("The bid was ", oldBid)
			console.log("\nPrice/bid was ", priceParam )
			console.log("Reserves were ", oldBid.reserveFunds)
			refundAmount = parseInt(oldBid.price) + parseInt(oldBid.reserveFunds)
			console.log("\nLet's refund all of the bid's ", refundAmount, " satoshis to PKH "
					+ oldBid.refundPKH + "...\n")

			theState.sumOfAllFunds -= refundAmount
			theState.blockNumInt += blocksRemaining
			theState.bidEntries = removeBid(bidsArray, regardingEntry)

			console.log("\nHere's the NEW bid array: ", theState.bidEntries, "\n")



			console.warn("sumOfAllFunds will NOW be " + theState.sumOfAllFunds)

			newAmounts[0] = oldAmount - refundAmount

			if ( theState.subMode === '63' || theState.subMode === '65' ) { // ascii hex for 'c' or 'e'
				console.log("\nSetting best/worst to 0x00 - since we're wrapping-up things")
				// NOTE: this is what we're supposed to keep here, now that it's executed
				theState.bestBidNum = 0  //255  255 screws-up index calculations within the contract
				theState.worstBidNum = 0 //255
			} else {
				// re-calculate best and worst
				theState.bestBidNum = getBestBidIndex( theState.bidEntries )
				theState.worstBidNum = getWorstBidIndex( theState.bidEntries )
			}

			if ( theState.sumOfAllFunds === 0 ) {
		//FIXME: if one of these final txs, then OP_FALSE  OP_RETURN, with value 0  <=======
		//FIXME: this also appears in refundBalk
				if ( theState.subMode === '63' ) {
					console.log("FINAL REFUND (BID). Moving to subMode 'C'LOSED  <----")
					newSubMode = '43'
//FIXME: revisit soon
					insertOpFalseBeforeFinalOpReturn = true
					//newAmount = 0
				} else if ( theState.subMode === '65' ) {
					console.log("FINAL REFUND (BID). Moving to subMode 'E'XECUTED  <----")
					newSubMode = '45'
//FIXME: revisit soon
					insertOpFalseBeforeFinalOpReturn = true
					//newAmount = 0
				}
			}

			console.log("\nThe BEST bid will be at index " + theState.bestBidNum)
			console.log("The WORST bid will be at index " + theState.worstBidNum)

			//FIXME check if W or G has expired. if so, explicitly change mode to 'O'

			console.log("Setting a refund script of " + refundAmount + " satoshis to " + oldBid.refundPKH)
			refundScript = 'OP_DUP OP_HASH160 ' + oldBid.refundPKH + ' OP_EQUALVERIFY OP_CHECKSIG'
			refundScriptsArray = [ refundScript ]

			newAmounts[0] = oldAmount - refundAmount

			break;
		case 'r':
			sigNeeded = false
			console.warn("libBuildOnAskBid():  building a Balk-refunding tx...")
			const oldBalk = balksArray[ regardingEntry ]
			priceParam = oldBalk.funds
			refundAmount = parseInt(priceParam)

			//FIXME: check if NO balks?

			console.log("\n\nREFUND a BALK. Current balks: ", theState.balkEntries)
			const theBalk = theState.balkEntries[ regardingEntry ]
			console.log("\nTo refund a balk, the current block height must be at least equal to "
						+ "the 'earliestRefundBlock' of the balk.")
			const earliestRefundBlock = theBalk.earliestRefundBlock
			console.log("The earliest refund block for balk #" + regardingEntry + " is " + earliestRefundBlock)
			console.log("The current block is " + theState.blockNumInt)

			let balkBlocksRemaining = earliestRefundBlock - theState.blockNumInt
			console.log("There are still " + balkBlocksRemaining + " blocks remaining, before these BALK funds "
						+ "can be refunded (compared to the previous tx's 'blockNum').")
			if ( balkBlocksRemaining > 0 ) {
				console.log("We're going to assume you're alright with advancing the blocknum to a "
					+ "time when we CAN refund them - block #" + earliestRefundBlock)
			} else {
				balkBlocksRemaining = 1 // let's keep advancing
				console.log("Enough time has elapsed. These BALK funds are ready to be released.")
			}

			console.log("\nLet's refund all of the balk's " + refundAmount + " satoshis to PKH "
				+ theBalk.refundPKH + "...\n")

			theState.sumOfAllFunds -= refundAmount
			theState.blockNumInt += balkBlocksRemaining

			theState.balkEntries = removeBalk(theState.balkEntries, regardingEntry)

			console.log("\nHere's the NEW balk array: ", theState.balkEntries, "\n")

			newAmounts[0] = oldAmount - refundAmount

			if ( theState.sumOfAllFunds === 0 ) {
		//FIXME: if one of these final txs, then OP_FALSE  OP_RETURN, with value 0  <=======
		// This also appears in refundBid
				if ( theState.subMode === '63' ) {
					console.log("FINAL REFUND (BALK). Moving to subMode 'C'LOSED  <----")
					newSubMode = '43'
					insertOpFalseBeforeFinalOpReturn = true
					//newAmount = 0
				} else if ( theState.subMode === '65' ) {
					console.log("FINAL REFUND (BALK). Moving to subMode 'E'XECUTED  <----")
					newSubMode = '45'
					insertOpFalseBeforeFinalOpReturn = true
					//newAmount = 0
				}
			}

	//FIXME check if W or G has expired. if so, change mode to 'O'

			const balkRefundScript = 'OP_DUP OP_HASH160 ' + theBalk.refundPKH + ' OP_EQUALVERIFY OP_CHECKSIG'
			refundScriptsArray = [ balkRefundScript ]


			//FIXME: needed?
			newRabinPKH = "0102030405060708090a0b0c0d0e0f1011121314"

			break;

		default:
			throw new Error("25555: invalid command (or not yet implemented) for Auction contract: " + commandHexByte)
	}

	//FIXME: maybe pass in bmm
	//FIXME: here, set blockNumInt

	// This is the previous scriptPubKey: locking script plus previous state
	const oldScriptPubKey = generateAskBidLockingScript( theState.stateHex, false )

	theState.opsCounter += 1
	theState.subMode = newSubMode

	let ownerRabinPubKeyBigInt
	if ( sigNeeded || specialSigNeeded ) {
		//FIXME: rename params from OWNER

		//NOTE: below taken from buildShizzleTx.js
		ownerRabinPubKeyBigInt = privKeyToPubKey2(paramPrivKeyP, paramPrivKeyQ)
		rabinPubKeyLE = int2Asm(ownerRabinPubKeyBigInt)       // used for executing contract - when updating content
	}

	if ( sigNeeded ) {
		//NOTE: below taken from buildShizzleTx.js

		//FIXME: rename params from OWNER
		const ownerRabinPubKeyLEBuffer = Buffer.from(rabinPubKeyLE, 'hex');

		const calculatedPKH = bsv.crypto.Hash.sha256ripemd160(ownerRabinPubKeyLEBuffer).toString('hex')
		if ( newRabinPKH !== calculatedPKH ) {
			throw new Error("26666: invalid keys for auction PKH of " + newRabinPKH)
		}

		const completeMessage	= theState.namePath
								+ commandHexByte
								+ twoByteLE(theState.opsCounter)
								+ fourByteLE(theState.blockNumInt) //FIXME: could just use blockNum
								+ fiveByteLE(priceParam)    // Sometime the asking price. Sometimes the bid
								+ oneByteLE(regardingEntry)
								+ refundP2PKH
								+ newRabinPKH
		sigInfo = getSigParts( Buffer.from(completeMessage, "hex"), paramPrivKeyP, paramPrivKeyQ, ownerRabinPubKeyBigInt)
		console.log("BTW: complete msg to sign = ", completeMessage)
	} else if ( specialSigNeeded ) {
		// THIS CONTAINS THE FABLED AUTHCODE - the signature
		sigInfo = getSigParts( Buffer.from(specialMessage, "hex"), paramPrivKeyP, paramPrivKeyQ, ownerRabinPubKeyBigInt)
		console.log("BTW: complete special msg to sign = ", specialMessage)
		console.log("BTW: sigInfo = ", sigInfo)

		theState.authCode = int2Asm(sigInfo.signature)
		theState.authCodePadding = sigInfo.paddingBytes
		theState.mainLineRabPubKey = rabinPubKeyLE
		console.log("\nBTW: just filled-out authCode, its padding, and the mainlineRabPubKey <=====\n")
	} else {
		rabinPubKeyLE = 'OP_0'
		// Some scenarios require no signature
		sigInfo = {
			signature: '0',
			paddingBytes: '00'    // NOTE: maybe OP_0 bothers the debugger?
		}

		console.warn("No signature needed")
	}

	console.log("BTW: sigInfo = ", sigInfo)

	const pureAskBidLockingScript = generateAskBidLockingScript('', false)
	const newAskBidScriptPubKey = new Array( 1 )
	newAskBidScriptPubKey[0] = buildAskBidScriptPubKey(	false,
														pureAskBidLockingScript,
														theState.balkEntries,
														theState.bidEntries,
														theState.mainLineRabPubKey,
														theState.authCode,
														theState.authCodePadding,

														theState.beforeBlock,
														theState.sumOfAllFunds,
														theState.bestBidNum,
														theState.worstBidNum,
														theState.askingPrice,
														theState.sellerRabinPKH,
														theState.mainLineRabinPKH,
														theState.deadlineBlock,
														theState.blockNumInt,
														theState.opsCounter,        // <---- we incremented this
														theState.smallestAmount,    // <--- unchanged, so far

														theState.quarterlyCount,
														theState.ownerCount,

														theState.namePath,
														theState.subMode)

	const results = await executeTheAskBidContract(specifiedPrivateKey,
							previousTxid,
							oldScriptPubKey, oldAmount,
							newAskBidScriptPubKey, newAmounts,
							theState.blockNumInt, specifiedBsvChangePKH, //EXT_FUNDING_CHANGE_PKH,
							theState.opsCounter, //OP_1 worked
							parseInt(commandHexByte, 16),
							priceParam,     // can be bid or ask <-----
							regardingEntry,  //regEntry 0?
							refundP2PKH,
							newRabinPKH,     //userRabinPKH, or owner's
							sigInfo,
							rabinPubKeyLE,
							refundAmount,
							refundScriptsArray,     // set to 0 ('OP_0' ?) for Bid, or Timestamp. everything else needs a real value
							whichOutputWasSpent )


	console.log("libBuildOnAskBid(): results from AskBid build: ", { txid: results.txid, change: results.change})

	if ( results.txid.startsWith('11111111111111111111111111111111') ) {
		console.log("We've got a bogus tx, we'll save its raw bytes")
		// insert results.rawTx into table for this purpose
		await dbAddRawTx(theDB, results.txid, results.rawTx)
	}

	return results.txid
}

function penalizeBid( bidArray, index, blockNum, penalty ) {

	const oldEarliestRefundBlock = bidArray[index].earliestRefundBlock
	const newEarliestRefundBlock = Math.max( blockNum + penalty, oldEarliestRefundBlock )
	bidArray[index].earliestRefundBlock = newEarliestRefundBlock

	return bidArray
}

/**
 * Derives two forms of Rabin Public Key - from the private key(s)
 *
 * @param {*} paramPrivKeyP
 * @param {*} paramPrivKeyQ
 */
function calculateKeyInfo(paramPrivKeyP, paramPrivKeyQ) {
	//console.log("BTW: p is " + paramPrivKeyP);
	//console.log("BTW: q is " + paramPrivKeyQ);
	const theRabinPubKeyBigInt = privKeyToPubKey2(paramPrivKeyP, paramPrivKeyQ);  // we use this to sign2()
	console.log("BTW: theRabinPubKeyBigInt is " + theRabinPubKeyBigInt);

	const theRabinPubKeyLE = int2Asm(theRabinPubKeyBigInt);
	console.log("BTW: theRabinPubKeyLE is " + theRabinPubKeyLE);

	return {
		rabinPrivKeyP: paramPrivKeyP,
		rabinPrivKeyQ: paramPrivKeyQ,
		rabinPubKeyBigInt: theRabinPubKeyBigInt,
		rabinPubKeyLE: theRabinPubKeyLE           // was this the only one we were after?
	};
}

//export /* */
async function libBuildOnUpdate(theState, previousTxid, whichOutput,
								solicitYN,			// getYesNoFromUser
								solicitResponse,	// getContentFromUser
								solicitTx,			// getTxIdFromUser
								solicitPKH,			// getPKHFromUser
								solicitOption,		// getOptionNumber
								solicitAnswer,		// readline.question
								solicitKeys,		// solicitKeysForSigning
								param1YN, param2YN, param3ContentHex, param4, param5Tx,
								param6, param7YN, param8PKH, param9YN, param10YN,
								param11,
								param11Opt,
								param11bAllowGuestPosts,
								param11cGuestPostPrice,
								param12_groupName,
								param13_userBounty,
								param14_maxPostLen,
								param15_satsPerPost,
								paramPrivKeyP,
								paramPrivKeyQ,
								theDB,
								dbAddRawTx,
								specifiedPrivateKey
	) {

	const privKey = new bsv.PrivateKey.fromWIF(specifiedPrivateKey)
	const specifiedPubKey = bsv.PublicKey.fromPrivateKey( privKey )
	const specifiedBsvChangePKH = bsv.crypto.Hash.sha256ripemd160(specifiedPubKey.toBuffer('hex')).toString('hex')

	console.log("libBuildOnUpdate: allow guest posts: " + param11bAllowGuestPosts)

	console.log("\n\nbuildOnUpdate(): We're going to build (post/tweet) off of UPDATE output " + whichOutput + " of tx " + previousTxid)
	console.log("\nbuildOnUpdate(): BTW, here's theState we have right now: ", theState)

	// This is the PREVIOUS scriptPubKey: locking script plus previous state
	const oldScriptPubKey = generateUpdateLockingScript( theState.stateHex, false )

	const pureUpdateLockingScript = generateUpdateLockingScript('', false)

	var newBlockInt = Buffer.from(theState.blockNum, "hex").readUInt32LE(0)

	const genesisBlockInt = Buffer.from(theState.genesisBlock, "hex").readUInt32LE(0)
	const blocksSinceGenesis = newBlockInt - genesisBlockInt;
	const yearsSinceGenesis = Math.floor(blocksSinceGenesis / BLOCKS_IN_ONE_YEAR)
	console.log(' ')
	console.log('newBlockInt: ' + newBlockInt)
	console.log('genesisBlockInt: ' + genesisBlockInt)
	console.log("blocksSinceGenesis: " + blocksSinceGenesis)
	console.log('blocks in one year: ' + BLOCKS_IN_ONE_YEAR)
	console.log("YEARS SINCE GENESIS: " + yearsSinceGenesis)
	console.log(' ')
	const minBlocksPerUpdate = MIN_BLOCKS_PER_UPDATE[ Math.min(yearsSinceGenesis, 15) ] // don't exceed the 16 elements in array
	console.log('MIN_BLOCKS_PER_UPDATE is thus: ' + minBlocksPerUpdate)

	console.log("\n\nWe're hardcoding the next block to be " + minBlocksPerUpdate
			+ " blocks since the previous tx (without even asking you), UNLESS you're building on a just-spawned Update. < ------<<<<\n")

	// New contract feature to allow posting immediately (not advancing the blockHeight) if periodicCount was 0
	newBlockInt += (theState.periodicCount === 0 ? 0 : minBlocksPerUpdate);

	const newBlockHex = fourByteLE(newBlockInt)
	console.log('  new block int is now: ', newBlockInt)
	console.log('  new block hex is now: ', newBlockHex)

	const deadlineInt = Buffer.from(theState.deadline, "hex").readUInt32LE(0)

	//FIXME: note: readUInt() only goes up to 6 bytes - not the 8 we actually need
	const oldAmount = Buffer.from(theState.contractSatoshis, "hex").readUIntLE(0,6)

	console.log("\nNOTE: the line of Update transactions can only be published up until the renewal deadline. "
				+ "At any time before then you could declare a given post the 'finalPost', re-claim the 'bounty'/"
				+ "balance that the contract contains, and formally end the line. AFTER the deadline, however, when "
				+ "it's no-longer permitted to post, ANYONE can claim the bounty/balance - redirecting the funds "
				+ "wherever they choose, and ending the line.")
	console.log("\nFor testing purposes, we now offer you the opportunity to fast-forward to just past the renewal "
				+ "deadline.")
	let claimBounty;
	if ( solicitYN !== null ) {
		claimBounty = solicitYN("\nInstead of Publishing, would you like to CLAIM the Update contract BOUNTY/BALANCE of "
										+ theState.contractSatoshis + " (by fast-forwarding to the renewal deadline)?")
	} else {
		claimBounty = param1YN;
	}
	if ( claimBounty ) {
		console.log("You can claim the bounty only if newBlockInt is greater than the deadlineInt")
		console.log("Deadline is " + theState.deadline + "   decimal: " + deadlineInt)
		if ( newBlockInt < deadlineInt ) {
			console.log("newBlockInt is only " + newBlockInt + ", so, will increase it to 1 more than deadline")
			newBlockInt = deadlineInt + 1
		}

		const results = await executeClaimUpdateBalance(specifiedPrivateKey,
									previousTxid,
									oldScriptPubKey, oldAmount,
									newBlockInt, specifiedBsvChangePKH, //EXT_FUNDING_CHANGE_PKH,
									whichOutput)

		if ( results.txid.startsWith('11111111111111111111111111111111') ) {
			console.log("We've got a bogus tx, we'll save its raw bytes")
			// insert results.rawTx into table for this purpose
			await dbAddRawTx(theDB, results.txid, results.rawTx)
		}

		return results.txid
	}

	console.log("\nbuildOnUpdate(): NOTE: the Rabin PKH set/declared in the PREVIOUS tx was: "
				+ theState.ownerRabinPKH)
	console.log("buildOnUpdate(): so, when you sign, you'll need to sign with the private keys "
				+ "associated with that PKH\n")

	//FIXME: NOTE: must match newUpdate.scrypt's call
	const maxContentLen = calcMaxContentLenFromBlockAndGenesis(newBlockInt, genesisBlockInt, 78840, 278046, 393216)
	console.log("\n\nNOTE: max content length is " + maxContentLen + " (" + (maxContentLen/1024) + " KB)  <---\n\n")





	var payScriptQueue = []   // to build payout scripts. (This lags the payStateQueue.)
	// Payout to EVERY builder PKH
	console.log('\nbuildOnUpdate(): the state PKHs has length ' + theState.builderPKHs.length)
	//console.log("Will payout to " + theState.builderPKHs.length + " builders.")
	console.log("Will payout to SINGLE builder - just ONE of the following scripts:")
	var pkhsString = ''
	for ( var i = 0; i < theState.builderPKHs.length; i++ ) {
		pkhsString += theState.builderPKHs[i]
		const payScript = 'OP_DUP OP_HASH160 ' + theState.builderPKHs[i] + ' OP_EQUALVERIFY OP_CHECKSIG'
		payScriptQueue.push( payScript )
		console.log("    Script to builder " + i + ": " + payScript)
	}
	console.log('buildOnUpdate():  pkhsString: \'' + pkhsString + '\'\n')

	const updateModeHexChar = '55' // 'U'pdate

	var updateContentHex = ""
	var updateContentName = ""
	let publishSomeContent;
	if ( solicitYN !== null ) {
		publishSomeContent = solicitYN("Would you like to publish some content?");
	} else {
		publishSomeContent = param2YN;
	}
	if ( publishSomeContent ) {
		if ( solicitResponse !== null ) {
			updateContentHex = solicitResponse("Please enter the content you'd like to publish", maxContentLen)
		} else {
			updateContentHex = param3ContentHex;
		}
		if ( updateContentHex.length > 0 ) {
			do {
				let tempName
				if ( solicitResponse !== null ) {
					tempName = solicitResponse("Please enter a Base-64 NAME to represent this content", MAX_LEN_CONTENT_NAME)
				} else {
					tempName = param4;
				}
				var result = verifyBase64( tempName )
				updateContentName = result.base64

				// avoid infinite loop
				if ( solicitResponse === null && !result.valid ) {
					console.error("invalid base-64 name");
					throw new Error('13000');
				}
			} while ( !result.valid );
		}
	}

	var txToPublishHex = ""
	let publishATxId;
	if ( solicitYN !== null ) {
		publishATxId = solicitYN("Would you like to publish a Transaction ID within the UPDATE?");
	} else {
		publishATxId = param5Tx.length > 0;
		console.log("Publishing a tx id? " + publishATxId);
	}
	if ( publishATxId ) {
		if ( solicitTx !== null ) {
			txToPublishHex = solicitTx()
		} else {
			txToPublishHex = param5Tx;
		}
	}

	var txDescriptor = ''
	if ( txToPublishHex.length > 0 ) {
		do {
			let tempDescr;
			if ( solicitResponse !== null ) {
				tempDescr = solicitResponse("Please enter a SHORT (no more than " + MAX_LEN_TX_DESCRIPTOR
								+ " chars) description for the txId you're publishing", MAX_LEN_TX_DESCRIPTOR)
			} else {
				tempDescr = param6;
			}
			var res = verifyBase64( tempDescr )
			txDescriptor = res.base64

			if ( solicitResponse === null && !res.valid ) {
				console.error("invalid tx description")
				throw new Error('13200');
			}
		} while ( !res.valid )
	}

	var publisherRabinPKH = theState.ownerRabinPKH

	let keepSameRabinPKH
	if ( solicitYN !== null ) {
		keepSameRabinPKH = solicitYN("Would you like to keep the same Publisher PKH for "
										+	"this next UPDATE posting, or set/declare a new one?");
	} else {
		keepSameRabinPKH = param7YN;
	}
	if ( !keepSameRabinPKH ) {
		if ( solicitPKH !== null ) {
			publisherRabinPKH = solicitPKH("1 - Enter the rabin PKH (hex) to use: ")
		} else {
			publisherRabinPKH = param8PKH;
			console.log("re-using Rabin PKH");
		}
	}

	//FIXME: warn that setting an invalid PKH can be bad. And do this elsewhere too.

	const counterInt = theState.namePath.length / 2
	const counterHex = oneByteLE(counterInt)
	const smallestAmount = Buffer.from(theState.smallestAmount, "hex").readUInt16LE(0)

	// In practice, the contract output amount could drop to whatever the miners are willing to accept as non-dust

	var smallestValue = smallestAmount
	var finalPostB
	if ( deadlineInt <= newBlockInt + MIN_BLOCKS_PER_UPDATE ) {
		console.log("\nNOTE: This will be the final post of this Update line (we're approaching the renewalDeadline).")
		finalPostB = true
	} else {
		//FIXME: warn if we're getting near the deadline
		if ( solicitYN !== null ) {
			finalPostB = solicitYN("Will this be the final post of this line Update line (even though there's still time to post more)?");
		} else {
			finalPostB = param9YN;
		}
	}

	var updateLockingScript = pureUpdateLockingScript
	var outputAmounts = []
	var finalPost = 0
	if ( finalPostB ) {
		finalPost = 1
		// construct tx with OP_FALSE OP_RETURN
		smallestValue = 0 //FIXME: don't overwrite this as 0, but alter contract to allow below-floor value? hmm
		updateLockingScript = 'OP_FALSE'
		console.log("\nINSERTING an OP_FALSE just before Update's OP_RETURN (AND dropping locking script) < ------- <<<<\n")
	}

	console.log('- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -')
	console.log(' SIMPLE UPDATE script pub key (newUpdateScriptPubKey):')
	const newUpdateScriptPubKey = buildUpdateScriptPubKey(updateLockingScript,
			theState.builderVoteTally,
			twoByteLE( theState.periodicCount + 1 ),
			deadlineInt, publisherRabinPKH, pkhsString,
			theState.genesisBlock, newBlockInt,
			smallestValue,
			theState.quarterlyCountHex,
			theState.ownerCountHex,
			theState.namePath,
			counterHex,
			updateModeHexChar)

	const parentOutputIndexToSpend = whichOutput  // output from previous Tx

	var payoutAmount = calcPayoutFromBlockAndGenesis(newBlockInt, genesisBlockInt, theState.builderVoteTally)

	var updateScriptPubKeys
	var injectedScriptHexString
	var spawnTransient = false
	var spawnBitGroup = false
	var bitGroupParams = [ "", "00000000", "00010000", "1e000000" ];
	let spawnSomething;
	if ( solicitYN !== null ) {
		spawnSomething = solicitYN("\nWould you like to spawn something (Transient or BitGroup)?");
	} else {
		spawnSomething = param10YN;
	}
	if ( spawnSomething ) {
		let spawnTrans;
		if ( solicitYN !== null ) {
			spawnTrans = solicitYN("\nWould you like to SPAWN a TRANSIENT output?")
		} else {
			spawnTrans = param11;
		}
		if ( spawnTrans ) {
			spawnTransient = true
		} else {
			spawnBitGroup = true
			console.log("\nOK. We'll spawn a BitGroup output.\n")
		}
	}

	var whichTtoSpawn
	var transModeHexChar = '00'
	let maxPerGuestPost = 4294967295; // actually, price for 'not-allowed': 0xffffffff
	let satsPerGuestPost = maxPerGuestPost;
	if ( spawnTransient ) {
		let allowGuestPosts = false;
		console.log("\nWill include a TRANSIENT at output #1 (base-0)")

		//FIXME: option to explain the types?
		console.log("\nFIXME: give option to explain the types <==== <====\n")
		if ( solicitOption !== null ) {
			whichTtoSpawn = solicitOption("Which Transient # type would you care to spawn (1-8)? ", 8, 1)
		} else {
			whichTtoSpawn = param11Opt;
		}

		if ( solicitYN !== null ) {
			allowGuestPosts = solicitYN("\nWill you allow guest-posts?");
		} else {
			allowGuestPosts = param11bAllowGuestPosts;
			console.log("allow guest posts: " + allowGuestPosts);
			console.log("typeof " + typeof(allowGuestPosts) );
		}
		if ( allowGuestPosts ) {
			console.log("Allow guest posts")
			if ( solicitAnswer !== null ) {
				satsPerGuestPost = solicitAnswer("\nEnter a price (in satoshis) a stranger will need to pay for the privelege of guest-posting on your limb: ");
			} else {
				satsPerGuestPost = param11cGuestPostPrice
			}
			if ( satsPerGuestPost > maxPerGuestPost || satsPerGuestPost < 0 ) {
				console.error("Invalid price for guest-posts")
				throw new Error('13353: invalid guest-post price');
			}
		}
		console.log("Setting satsPerGuestPost to " + satsPerGuestPost)

		const pureSpawnedTransientLockingScript = generateTransientLockingScript(parseInt(whichTtoSpawn), '', false)

		// This must match newUpdate.scrypt's initialDownCounter's
	//  const initialDownCounters = ['0c', '0c', '0d', '0e', '10', '13', '18', '20', '2d', '42', '64', '9b', 'f4', 'ff'];
		//const initialDownCounters = ['07', '08', '09', '0a', '0b', '0d', '10', '14', '19', '1f', '26', '2e', '37', '41'];
		const initialDownCounters = ['06', '07', '08', '09', '0a', '0c', '0f', '13', '18', '1e', '25', '2d', '36', '40'];

		const firstDownCounter = initialDownCounters[ Math.min(yearsSinceGenesis, 13) ]; // max 13 years of growth
		console.log(' ')
		console.log("maxDownCounter (hex): " + firstDownCounter)
		console.log(' ')

		transModeHexChar = '3' + whichTtoSpawn  // ascii '0' through '9' Transient
		console.log("buildOnUpdate(): using transModeHexChar of " + transModeHexChar)

		// when adding optionalTransient, transient content must initially be blank
		const tspk = buildTransientScriptPubKey( pureSpawnedTransientLockingScript,
				'', '', '', '',			// initially, no content, nor transactionID+descriptor
				swapEndian(previousTxid),
				satsPerGuestPost,	// (decimal) price for guest-posting
				maxContentLen,		// maximum content length calculated via a schedule
				publisherRabinPKH,	// Hash of PubKey to authorize next posting
				newBlockInt,		// current block
				deadlineInt,		// deadline - when this line dies-out. AKA maxBlock
				smallestAmount,
				firstDownCounter,
				firstDownCounter,

				// NEW: 3 bytes of consecutive posts hosted:   UPDATE spawning TRANSIENT
				0,

				twoByteLE( theState.periodicCount + 1 ) + theState.quarterlyCountHex + theState.ownerCountHex,
				theState.namePath,
				counterHex,
				transModeHexChar)	// ascii '1' mode.  Used to be 'T'
		const transientScriptPubKey = tspk.pubkey

		const spawnTypeIndex = parseInt(whichTtoSpawn) > 4 ? 1 : 0

		console.log("buildOnUpdate(): selecting transientScriptHexStrings[ " + spawnTypeIndex + "]")
		//Heres where we now need to index by TYPE: 0 for vanilla, 1 for labeled
		injectedScriptHexString = transientScriptHexStrings[ spawnTypeIndex ]
		if ( injectedScriptHexString.length === 0 ) {
			console.error("\nERROR: EMPTY Transient (of type/index " + spawnTypeIndex + ") script hex string cache. Please define this in the contracts cache.\n")
			throw new Error("13350");
		}
		const multiple = transientPayoutMultiples[ spawnTypeIndex ]
		console.log("Because we're spawning T" + whichTtoSpawn + ", the payout multiple is " + multiple)

		// We charge EXTRA (quintuple), in payouts to the builders, for the ability to use a 'transient' line of (max 254) content updates/outputs
		// Later transient publishings are 'free' - meaning, no fees to BUILDERS. Miner fees ALWAYS apply.
		payoutAmount = multiple * payoutAmount
		console.log('\nNOTE: charging ' + multiple + 'x on BUILDER payouts, because we\'re including a TRANSIENT output. '
				+ 	'All later (descended from this) Transient content updates (max 254) are FREE (no payouts to BUILDERS).');

		updateScriptPubKeys = new Array( 2 )
		updateScriptPubKeys[0] = newUpdateScriptPubKey
		updateScriptPubKeys[1] = transientScriptPubKey
		outputAmounts[1] = smallestAmount * BOUNTY_MULTIPLIER_TRANSIENT
	} else if ( spawnBitGroup ) {
		injectedScriptHexString = bitGroupScriptHexString
		if ( bitGroupScriptHexString.length === 0 ) {
			console.error("\nERROR: EMPTY BitGroup script hex string cache. Please define this in the contracts cache.\n")
			throw new Error("13351");
		}
		// solicit initial parameters for BitGroup
		let groupName;
		let userBounty;
		let maxPostLen;
		let satsPerPost;
		if ( solicitAnswer !== null ) {
			groupName = solicitAnswer("\nEnter the name of the BitGroup to be spawn (max 64 chars): ");
			userBounty = solicitAnswer("\nEnter a bounty (in satoshis) each user will need to pay (once) before posting: ");
			maxPostLen = solicitAnswer("\nEnter a maximum length for posts: ");
			satsPerPost = solicitAnswer("\nEnter a price (in satoshis) each user will need to pay for each post (min 0): ");
		} else {
			groupName = param12_groupName;
			userBounty = param13_userBounty;
			maxPostLen = param14_maxPostLen;
			satsPerPost = param15_satsPerPost;
		}
		const pureBitGroupLockingScript = generateBitGroupLockingScript('', false)

		bitGroupParams[0] = Buffer.from(groupName).toString("hex")
		bitGroupParams[1] = fourByteLE(userBounty)
		bitGroupParams[2] = fourByteLE(maxPostLen)
		bitGroupParams[3] = fourByteLE(satsPerPost)

		// create owner profile
		var userProfiles = []
		userProfiles = appendUserProfile(userProfiles, publisherRabinPKH, EXT_FUNDING_CHANGE_PKH, fourByteLE(newBlockInt), 5)
		userProfiles[0].userPaidBounty = '01'

		// spawn bitgroup from Update
		console.log('- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -')
		console.log(' Spawned BITGROUP script pub key (spawnedBitGroupScriptPubKey):')
		const sbgspk = buildBitGroupScriptPubKey(pureBitGroupLockingScript,
				userProfiles,
				0,  // post 0
				newBlockInt,
				0,  // startedAtPost
				Buffer.from(groupName).toString("hex"),   // solicited NAME
				userBounty,  // solicited num
				maxPostLen,  // solicited num
				satsPerPost, // solicited num
				deadlineInt,          // maxBlock or 1 year from now?
				twoByteLE( theState.periodicCount + 1 ),
				theState.quarterlyCountHex,
				theState.ownerCountHex,
				theState.namePath)
		const spawnedBitGroupScriptPubKey = sbgspk.scriptPubKey
		//msgSnippet = sbgspk.messagePart
		transModeHexChar = '47' // ascii 'G'



		// We charge EXTRA (quintuple), in payouts to the builders, for the ability to spawn a BitGroup
		//FIXME: define a bitgroupPayoutMultiple = 5 (not 2)
		payoutAmount = BITGROUP_PAYOUT_MULTIPLIER * payoutAmount
		console.log('\nNOTE: charging 2x on BUILDER payouts, because we\'re including a BITGROUP output.');

		updateScriptPubKeys = new Array( 2 )
		updateScriptPubKeys[0] = newUpdateScriptPubKey
		updateScriptPubKeys[1] = spawnedBitGroupScriptPubKey
		outputAmounts[1] = smallestAmount * BOUNTY_MULTIPLIER_BITGROUP

		//FIXME: a method of defining multiple initial users could be useful

	} else {
		updateScriptPubKeys = new Array( 1 )
		updateScriptPubKeys[0] = newUpdateScriptPubKey
		injectedScriptHexString = '11'
	}
	if ( finalPostB ) {
		outputAmounts[0] = 0
	} else {
		outputAmounts[0] = smallestAmount * BOUNTY_MULTIPLIER_UPDATE
	}

	// payout can't be smaller than the current 'dust+1' amount
	payoutAmount = Math.max(smallestAmount, payoutAmount)
	console.log("\nPayout to ONE of the " + theState.builderPKHs.length + " builders is "
				+ payoutAmount + " satoshis (NOT EACH anymore).")


	console.log("\nNow you need to authorize what you're about to post. "
			+	"You'll need to use the keys associated with the PKH set in the PREVIOUS/parent tx/post.")
	let keyInfo;
	if ( solicitKeys !== null ) {
		keyInfo = solicitKeys(theState.ownerRabinPKH)
	} else {
		keyInfo = calculateKeyInfo(paramPrivKeyP, paramPrivKeyQ);
	}

	const completeContent	= oneByteLE(updateContentName.length)
							+ updateContentName.toString("hex")
							+ fourByteLE(updateContentHex.length / 2)
							+ updateContentHex
							+ oneByteLE(txDescriptor.length)
							+ txDescriptor.toString("hex")
							+ oneByteLE(txToPublishHex.length / 2)
							+ txToPublishHex
							+ newBlockHex
							+ twoByteLE( theState.periodicCount + 1 )
							+ theState.quarterlyCountHex
							+ theState.ownerCountHex
							+ oneByteLE(theState.namePath.length / 2) // limb/branch length
							+ theState.namePath
							+ transModeHexChar
							+ oneByteLE( finalPost )
							+ fourByteLE( satsPerGuestPost )
							+ publisherRabinPKH

	if ( !validateRabinPrivKeys(keyInfo.rabinPrivKeyP, keyInfo.rabinPrivKeyQ, theState.ownerRabinPKH) ) {
		throw new Error("18002: Invalid private keys for given Rabin PKH")
	}
	const update1SigResult = getSigParts(Buffer.from(completeContent, "hex"), keyInfo.rabinPrivKeyP, keyInfo.rabinPrivKeyQ, keyInfo.rabinPubKeyBigInt)
	console.log("For UPDATE, we signed this message: " + completeContent)
	console.log("For SIMPLE UPDATE, will set this rabin pkh: ", publisherRabinPKH)
	console.log("For SIMPLE UPDATE, signature is: ", update1SigResult.signature)
	const update1Padding = update1SigResult.paddingBytes
	var update1Sig = int2Asm(update1SigResult.signature)
	const publisherPK = keyInfo.rabinPubKeyLE //publisherRabinPubKeyLE

	// Choose a SINGLE payout p2pkh
	const newOwnerCountInt = Buffer.from(theState.ownerCountHex, "hex").readUInt8(0)
	const newQuarterlyCountInt = Buffer.from(theState.quarterlyCountHex, "hex").readUInt16LE(0)
	const newPeriodicCountInt = theState.periodicCount + 1
	const chosenP2PKHScript = getUpdatePayoutP2PKH(payScriptQueue,
											newOwnerCountInt,
											newQuarterlyCountInt,
											newPeriodicCountInt)

	// update()
	const results = await executePubTransContract(specifiedPrivateKey, previousTxid,
								oldScriptPubKey, oldAmount,
								updateScriptPubKeys, outputAmounts, smallestAmount,
								chosenP2PKHScript, //payScriptQueue,
								payoutAmount,
								newBlockInt, specifiedBsvChangePKH, //EXT_FUNDING_CHANGE_PKH,
								updateContentName.toString("hex"),
								updateContentHex,
								txDescriptor.toString("hex"),
								txToPublishHex,
								publisherRabinPKH, update1Sig,
								update1Padding, publisherPK,
								injectedScriptHexString,
								fourByteLE( satsPerGuestPost ),
								bitGroupParams, // useful only if injecting bitGroup
								transModeHexChar,
								finalPost,
								parentOutputIndexToSpend)

	console.log("buildOnUpdate(): results from UPDATE: ", { txid: results.txid, change: results.change})

	if ( results.txid.startsWith('11111111111111111111111111111111') ) {
		console.log("We've got a bogus tx, we'll save its raw bytes")
		// insert results.rawTx into table for this purpose
		await dbAddRawTx(theDB, results.txid, results.rawTx)
	}

	return results.txid
} // libBuildOnUpdate

// libBuildOnTransient
//export /* */
async function libBuildOnTransient(theState, previousTxid, whichOutput,
									solicitYN,		// getYesNoFromUser
									solicitAnswer,	// readline.question
									solicitContent,	// getContentFromUser
									solicitTx,		// getTxIdFromUser
									solicitPKH,		// getPKHFromUser
									solicitKeys,	// solicitKeysForSigning

									param1ClaimBountyYN,
									param2TipOwnerYN,
									param3TipAmount,
									param4PublishContentYN,
									param5ContentHex,
									param6ContentName,
									param7PublishTxYN,
									param8Tx,
									param9TxDescr,
									param10SameRabinYN,
									param11NewPKH,
									param12FinalPostYN,
									param13SpawnSubTransYN,

									param14GuestPost,	// actually, these are used before param13
										param14bAddDialogOutput, // should only apply if responding to guest-post
									param15HostTxid,
									param16HostTxState,
										param16bHostTxOutputIdx,

									paramPrivKeyP,
									paramPrivKeyQ,

									theDB,
									dbAddRawTx,
									funcQueryFetchDecode,
									specifiedPrivateKey
	) {

	const privKey = new bsv.PrivateKey.fromWIF(specifiedPrivateKey)
	const specifiedPubKey = bsv.PublicKey.fromPrivateKey( privKey )
	const specifiedBsvChangePKH = bsv.crypto.Hash.sha256ripemd160(specifiedPubKey.toBuffer('hex')).toString('hex')

	console.log("\n\nbuildOnTransient(): We're going to build (post/tweet) off of TRANSIENT output " + whichOutput + " of tx " + previousTxid)
	console.log("\nbuildOnTransient(): BTW, here's theState we have right now: ", theState)

	const modeInt = parseInt( theState.mode, 16 )
	const tTypeBase1 = modeInt - 48
	console.log("T-type Mode (looking for 1-8): " + tTypeBase1 + "\n")

	// This is the PREVIOUS scriptPubKey: locking script plus previous state
	const oldScriptPubKey = generateTransientLockingScript( tTypeBase1, theState.stateHex, false )

	const pureTransientLockingScript = generateTransientLockingScript(tTypeBase1, '', false)

	// Use the SAME blockNum as the previous
	var newBlockInt = Buffer.from(theState.blockNum, "hex").readUInt32LE(0)

	const newBlockHex = fourByteLE(newBlockInt)
	console.log('  new block int: ', newBlockInt)
	console.log('  new block hex: ', newBlockHex)

	const maxBlockInt = Buffer.from(theState.maxBlock, "hex").readUInt32LE(0)

	//FIXME: note: readUInt() only goes up to 6 bytes - not the 8 we actually need
	const oldAmount = Buffer.from(theState.contractSatoshis, "hex").readUIntLE(0,6)

	console.log("\nNOTE: the line of Transient transactions can only be published up until the maxBlock deadline. "
				+ "At any time before then you could declare a given post the 'finalPost', re-claim the 'bounty'/"
				+ "balance that the contract contains, and formally end the line. AFTER the deadline, however, when "
				+ "it's no-longer permitted to post, ANYONE can claim the bounty/balance - redirecting the funds "
				+ "wherever they choose, and ending the line.")
	console.log("\nFor testing purposes, we now offer you the opportunity to fast-forward to just past the maxBlock "
				+ "deadline.")
	let claimBounty;
	if ( solicitYN !== null ) {
		claimBounty = solicitYN("\nInstead of Publishing, would you like to CLAIM the Transient contract BOUNTY/BALANCE of "
										+ theState.contractSatoshis + " (by fast-forwarding to the maxBlock deadline)?")
	} else {
		claimBounty = param1ClaimBountyYN;
	}
	if ( claimBounty ) {
		console.log("You can claim the bounty only if newBlockInt is greater than the maxBlockInt")
		console.log("MaxBlock is " + theState.maxBlock + "   decimal: " + maxBlockInt)
		if ( newBlockInt < maxBlockInt ) {
			console.log("newBlockInt is only " + newBlockInt + ", so, will increase it to 1 more than maxBlock")
			newBlockInt = maxBlockInt + 1
		}

		const results = await executeClaimTransientBalance(specifiedPrivateKey,
			previousTxid,
			oldScriptPubKey, oldAmount,
			newBlockInt, specifiedBsvChangePKH, //EXT_FUNDING_CHANGE_PKH,
			whichOutput)
		//FIXME: this function, within, needs updating, to use 4 new args

		if ( results.txid.startsWith('11111111111111111111111111111111') ) {
			console.log("We've got a bogus tx, we'll save its raw bytes")
			// insert results.rawTx into table for this purpose
			await dbAddRawTx(theDB, results.txid, results.rawTx)
		}

		return results.txid
	}

	const smallestAmount = Buffer.from(theState.smallestAmount, "hex").readUInt16LE(0)
	let tipTheOwner;
	if ( solicitYN !== null ) {
		tipTheOwner = solicitYN("\nInstead of Publishing (which requires a signature), would you like to TIP (increasing the "
										+ "BOUNTY/BALANCE from its current value of " + theState.contractSatoshis + " satoshis)?")
	} else {
		tipTheOwner = param2TipOwnerYN;
	}
	if ( tipTheOwner ) {
		var tip = 0
		// for unary operator '+', see this:
		// https://stackabuse.com/javascript-convert-string-to-number/
		while ( isNaN(tip) || !Number.isInteger(+tip) || parseInt(tip) < 100 || parseInt(tip) > 1099511627775 ) {
			console.log("\nFIXME: we need to specify the minimum tip.")
			if ( solicitAnswer !== null ) {
				tip = solicitAnswer("The minimum tip is " + (10 * smallestAmount) + " satoshis. How much would you like to tip? ");
			} else {
				tip = param3TipAmount;
			}
		}

		const	 tipAmount = parseInt( tip )

		const results = await executeIncreaseTransientBalance(specifiedPrivateKey,
			previousTxid,
			oldScriptPubKey, oldAmount,
			tipAmount,
			Buffer.from(theState.blockNum, "hex").readUInt32LE(0), // oldBlockInt
			specifiedBsvChangePKH, //EXT_FUNDING_CHANGE_PKH,
			whichOutput)
		//FIXME: this function, within, needs updating, to use 4 new args

		if ( results.txid.startsWith('11111111111111111111111111111111') ) {
			console.log("We've got a bogus tx, we'll save its raw bytes")
			// insert results.rawTx into table for this purpose
			await dbAddRawTx(theDB, results.txid, results.rawTx)
		}

		return results.txid
	}

	console.log("\nbuildOnTransient(): NOTE: the Rabin PKH set/declared in the PREVIOUS tx was: "
			+ theState.rabinPKH)
	console.log("buildOnTransient(): so, when you sign, you'll need to sign with the private keys "
				+ "associated with that PKH\n")

	const downCounterInt = Buffer.from(theState.downCounter, "hex").readUInt8(0) - 1
	var downCounterHex = oneByteLE(downCounterInt)

	const transModeHexChar = theState.mode
	console.log("   Using transModeHexChar of " + transModeHexChar)

	var transientContentHex = ""
	var transientContentName = ""
	let publishSomeContent;
	if ( solicitYN !== null ) {
		publishSomeContent = solicitYN("Would you like to publish some content?");
	} else {
		publishSomeContent = param4PublishContentYN;
	}
	if ( publishSomeContent ) {

		console.log("\n\nNOTE: INHERITED max content length is " + theState.maxContentLen + " (" + (theState.maxContentLen/1024) + " KB)  <---\n\n")

		if ( solicitContent !== null ) {
			transientContentHex = solicitContent("Please enter the content you'd like to publish", theState.maxContentLen)
		} else {
			transientContentHex = param5ContentHex;
		}
		if ( transientContentHex.length > 0 ) {
			if ( tTypeBase1 < 5 ) {
				console.log("Since tType is " + tTypeBase1 + " (less than 5), we won't be naming the content.")
			} else {
				do {
					let tempName;
					if ( solicitContent !== null ) {
						tempName = solicitContent("Please enter a Base-64 NAME to represent this content", MAX_LEN_CONTENT_NAME)
					} else {
						tempName = param6ContentName;
					}
					var result = verifyBase64( tempName )
					transientContentName = result.base64
					if ( !result.valid && solicitContent === null ) {
						throw new Error("13601: invalid base64 content name");
					}
				} while ( !result.valid );
			}
		}
	}

	var txToPublishHex = ""
	let publishATxId;
	if ( solicitYN !== null ) {
		publishATxId = solicitYN("Would you like to publish a Transaction ID within the TRANSIENT?");
	} else {
		publishATxId = param7PublishTxYN;
		console.log("publish txid (true/false): " + param7PublishTxYN)
	}
	if ( publishATxId ) {
		if ( solicitTx !== null ) {
			txToPublishHex = solicitTx()
		} else {
			txToPublishHex = param8Tx;
			console.log("tx to publish: " + txToPublishHex)
		}
	}

	var txDescriptor = ''
	if ( txToPublishHex.length > 0 ) {
		if ( tTypeBase1 < 5 ) {
			console.log("Since tType is " + tTypeBase1 + " (less than 5), we won't be labeling the tx.")
		} else {
			do {
				let tempDescr;
				if ( solicitContent !== null ) {
					tempDescr = solicitContent("Please enter a SHORT (no more than " + MAX_LEN_TX_DESCRIPTOR
										+ " chars) description for the txId you're publishing", MAX_LEN_TX_DESCRIPTOR)
				} else {
					tempDescr = param9TxDescr;
				}
				let result = verifyBase64( tempDescr )
				txDescriptor = result.base64
				if ( !result.valid && solicitContent === null ) {
					throw new Error("13602: invalid base64 tx description")
				}
			} while ( !result.valid )
		}
	}

	var publisherRabinPKH = theState.rabinPKH

	let keepSameRabinPKH;
	if ( solicitYN !== null ) {
		keepSameRabinPKH = solicitYN("Would you like to keep the same Publisher PKH for "
										+	"this next TRANSIENT posting, or set/declare a new one?");
	} else {
		keepSameRabinPKH = param10SameRabinYN;
	}
	if ( !keepSameRabinPKH ) {
		if ( solicitPKH !== null ) {
			publisherRabinPKH = solicitPKH("2 - Enter the rabin PKH (hex) to use: ")
		} else {
			publisherRabinPKH = param11NewPKH;
		}
	}

	//FIXME: warn that setting an invalid PKH can be bad. And do this elsewhere too.

	const counterInt = theState.namePath.length / 2
	const counterHex = oneByteLE(counterInt)

	// In practice, the contract output amount could drop to whatever the miners are willing to accest as non-dust

	var smallestValue = smallestAmount
	var finalPostB
	if ( downCounterInt === 0 ) {
		console.log("\nNOTE: This will be the final post of this Transient line - the downCounter is about to reach zero.")
		finalPostB = true
	} else {
		//FIXME: warn when we're getting near the maxBlock deadline
		if ( solicitYN !== null ) {
			finalPostB = solicitYN("\nWill this be the final post of this Transient line (even though you could post "
									+ downCounterInt + " more after this)?");
		} else {
			finalPostB = param12FinalPostYN;
		}
	}
	var transientLockingScript = pureTransientLockingScript
	var finalPost = 0
	if ( downCounterHex === '00' ) {
		finalPostB = true
		console.log("Forcing finalPost to 1 - since we're at our final downCounter (0) <-----")
	}
	if ( finalPostB ) {
		finalPost = 1
		downCounterHex = '00'
		// construct tx with OP_FALSE OP_RETURN
		smallestValue = 0 //FIXME: don't overwrite this as 0, but alter contract to allow below-floor value? hmm
		transientLockingScript = 'OP_FALSE'
		console.log("\nINSERTING an OP_FALSE just before Transient's OP_RETURN (AND dropping locking script) < ------- <<<<\n")
	}

	///////////
	///////////

	const outputIndexToTake = whichOutput  // output from previous Tx

	console.log("1 -------------------------------------");
	let guestPost = false;
	if ( !finalPostB && solicitYN !== null ) {
		guestPost = solicitYN("\nWould you like to GUEST POST at a different limb/shizzle?");
	} else {
		guestPost = param14GuestPost;
		console.log("guestPost? ", guestPost)
	}
	let addIOForHost = 0;
	let addDialogOutput = false
	let newHostStateBytes = '';
	let hostTxId = '';
	let hostTxState = null;
	let hostTxOutputIdx = 0;
	let previousTxids       = [];
	let outputIndexesToTake = [];
	let oldScriptPubKeys    = [];
	let oldAmounts          = [];
	var newTransientScriptPubKeys = []
	var outputAmounts             = new Array(); // The value (satoshis) for each output
	let hostNewBlockInt = 0;

/*
	if ( finalPostB ) {
		console.log("We won't even ask about guest-posting. This is the final post.");

		//FIXME: make sure we're not guestPosting, nor hosting (right?)
		console.log(" ");
		console.log("FIXME: there may be a few constraints we should enforce here.  <=====");
		console.log(" ");
		if ( guestPost ) {
			console.error("ERROR: this is the finalPost, but you had requested to guest-post. Something MIGHT need to change.");
			throw new Error("13803: potentially inconsistent parameters for a final post")
		}

	} else
*/

	if ( guestPost ) {
		// Now we need:
		//   - the tx (output #0) to build on (decoded)
		//   - the txid too

		//NOTE: the T-level TYPEs must match

		//FUTURE: maybe instead of txid, just the limbName
		//FUTURE: option to invert it all. Start with host post (or just the asset), and
		//        specify guest asset to post on it. Within the text, could specify the
		//        host shizzleAddress we're referring to:
		//            asset, owner#, QuarterlyCount, T4#, T3#, T2#, T1#
		//            asset, owner#, QuarterlyCount, BG#, SubGroup#


		if ( solicitTx !== null ) {
			console.log("We'll need the txid of the host tx.")
			hostTxId = solicitTx()

			//FIXME: try/catch
			hostTxState = await funcQueryFetchDecode(hostTxId, theDB)
			if ( hostTxState === null || typeof hostTxState === 'number' ) {
				throw new Error("14390: failed to retrieve host transaction " + hostTxId + ": " + hostTxState)
			}
		} else {
			hostTxId = param15HostTxid;
			console.log("guest txid: " + param15HostTxid)

			hostTxState = param16HostTxState;
			hostTxOutputIdx = param16bHostTxOutputIdx

			addDialogOutput = param14bAddDialogOutput
		}

		// verify they don't share the same Limb
		console.log("theState: ", theState);
		console.log("hostTxState: ", hostTxState);
		if ( theState.namePath === hostTxState.namePath ) {
			console.error("One can't guestPost onto a tx of the same limb");
			throw new Error("14389: can't guest-post onto Transients of the the same shizzle limb")
		}

		// verify guest-posting is allowed
		if ( !hostTxState.guestPostsAllowed ) {
			console.error("Guest-posting is not allowed on transients of that Quarterly, of that limb (declared by host)");
			console.error("hostTxState: ", hostTxState)
			throw new Error("14389: guest-posting onto Transients of that Quarterly, of that limb is prohibited (declared by host)")
		}

		//FIXME: implement check that host and guest are of the same Transient
		//       type: vanilla, or labeled

		// The host's Transient mode/level could be different from the guest's
		// BUT they must both be of the same type: either vanilla (T1-T4), or labeling (T5-T8)

		const hostTransModeHexChar = hostTxState.mode
		console.warn("   Using hostTransModeHexChar of " + hostTransModeHexChar)


		console.log("building guestPost transient script pubkey...")
		//NOTE: we currently require matching locking scripts (guest and host - same T-level)
		//NOTE: state doesn't change for host
		//NOTE: if finalPost for GUEST POST, its locking script becomes OP_FALSE OP_RETURN, followed by state,
		//      BUT, the HOST locking script remains UNCHANGED
		const hostSPK = buildTransientScriptPubKey(pureTransientLockingScript, //transientLockingScript,
			'', '',
			'', '',
			swapEndian(param15HostTxid),     // Note that the previous id (outpoint) is DIFFERENT for the host output state
			hostTxState.guestPostPrice,
			hostTxState.maxContentLen, //XXXX  Inherited value
			hostTxState.rabinPKH,
			Buffer.from(hostTxState.blockNum, "hex").readUInt32LE(0),
			Buffer.from(hostTxState.maxBlock, "hex").readUInt32LE(0),
			Buffer.from(hostTxState.smallestAmount, "hex").readUInt16LE(0),
			hostTxState.maxDownCounterHex,
			hostTxState.downCounter, //downCounterHex,  (doesn't change for host)

			// NEW: 3 bytes of consecutive posts hosted:     HOSTing a guest-post
			hostTxState.consecutivePostsHosted + 1,

			hostTxState.txAddress,
			hostTxState.namePath,
			oneByteLE( hostTxState.namePath.length / 2 ), //counterHex,
			hostTransModeHexChar)
		console.log("Done building NOT guestPost, but HOST, transient script pubkey")
		const newHostScriptPubKey = hostSPK.pubkey   // lockingScript + OP_RETURN + state
		newHostStateBytes = hostSPK.state           // just state
		addIOForHost = 1;
		hostNewBlockInt = Buffer.from(hostTxState.blockNum, "hex").readUInt32LE(0)

		//FIXME: at some point we'll get the hosting fee from state. Right now it's hardcoded at 200 sats.
		const oldHostAmount = Buffer.from(hostTxState.contractSatoshis, "hex").readUIntLE(0,6)

		previousTxids[0]       = hostTxId;
		outputIndexesToTake[0] = hostTxOutputIdx // sometimes we guest-post onto a guest-post
		oldScriptPubKeys[0]    = generateTransientLockingScript( tTypeBase1, hostTxState.stateHex, false )
		oldAmounts[0]          = oldHostAmount;

		newTransientScriptPubKeys[0] = newHostScriptPubKey;
		outputAmounts[0]             = oldHostAmount + hostTxState.guestPostPrice;  // GUEST_FEE - posted in host state
	} else {
		console.log("NOT Guest-Posting")
	}

	console.log('- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - ')
	console.log(' SIMPLE TRANSIENT script pub key (newTransientScriptPubKey):')
	const tspk = buildTransientScriptPubKey(transientLockingScript,
			transientContentName,
			transientContentHex,
			txDescriptor,
			txToPublishHex,
			swapEndian(previousTxid),
			theState.guestPostPrice,   // Inherited
			theState.maxContentLen,    // Inherited value
			publisherRabinPKH,
			newBlockInt,
			maxBlockInt,
			smallestValue,
			theState.maxDownCounterHex,
			downCounterHex,

			// NEW: 3 bytes of consecutive posts hosted:    normal post, or guest-posting
			0,

			theState.txAddress,
			theState.namePath,
			counterHex,
			transModeHexChar)
	const newTransientScriptPubKey = tspk.pubkey
	const newState = tspk.state
	const msgPartA = tspk.messagetoSignPartA


	previousTxids[ addIOForHost ]       = previousTxid;
	outputIndexesToTake[ addIOForHost ] = outputIndexToTake; //whichOutput  (for the guest)
	oldScriptPubKeys[ addIOForHost ]    = oldScriptPubKey;
	oldAmounts[ addIOForHost ]          = oldAmount;

	var transientScriptHexString
	var spawnSubTransient = false
	// Subtract one because we may spawn a SUB Transient (1 less than the current T)
	const whichTransToSpawn = tTypeBase1 - 1
	if ( tTypeBase1 !== 0  &&  tTypeBase1 !== 5 ) {
		if ( solicitYN !== null ) {
			spawnSubTransient = solicitYN("\nWould you like to also SPAWN a TRANSIENT (T" + whichTransToSpawn + ") output?");
		} else {
			spawnSubTransient = param13SpawnSubTransYN;
		}
	} else {
		console.log("BTW: can't spawn a lower-order Transient. This is the lowest-order Transient.")
	}

	console.log("buildOnTransient(): using transModeHexChar of " + transModeHexChar)
	let addOutputForSpawn = 0
	//NOTE: spinoffMode is set regardless of whether or not we spinoff a sub trans
	var subTransModeHexChar = '00'
	let subTransientState = ''  // used by HOST if GUEST-poster also spawns
	if ( spawnSubTransient ) {
		subTransModeHexChar = '3' + whichTransToSpawn  // ascii '1' through '8' Transient.  Used to be '54'	//'00'

		console.log("\nWill include a SUB-Transient at output #1 (base-0)")

		//FIXME: option to explain the types?
		console.log("\nFIXME: give option to explain the types <==== <====\n")

		const pureSubTransientLockingScript = generateTransientLockingScript(whichTransToSpawn, '', false)

		// when adding optionalTransient, transient content must initially be blank
		const stspk = buildTransientScriptPubKey( pureSubTransientLockingScript,
				'', '', '', '',			// initially, no content/name, nor transactionID+descriptor
				swapEndian(previousTxid),
				theState.guestPostPrice,
				theState.maxContentLen,	//XXXX  inherited value
				publisherRabinPKH,	// Hash of PubKey to authorize next posting
				newBlockInt,		// current block
				maxBlockInt,		// deadline - when this line dies-out. AKA maxBlock
				smallestAmount,
				theState.maxDownCounterHex,
				theState.maxDownCounterHex,

				// NEW: 3 bytes of consecutive posts hosted:   spawning a sub-transient
				0,

				downCounterHex + theState.txAddress,  // prepend new down-counter to state  //FIXME: rename to newDownCounter
				theState.namePath,
				counterHex,
				subTransModeHexChar)	// ascii '1' mode.  Used to be 'T'
		const subTransientScriptPubKey = stspk.pubkey
		subTransientState = stspk.state
		console.warn("buildOnTransient(): BTW: sub-transient STATE is " + subTransientState)

		// We no longer inject scripts for spawned subTransients. All transient contracts (of a type)
		// share the same contract (T1-T4, and T5-T8)
		// For now we use a dummy (non-empyt) string to signify if we WANT to spawn.
		// Soon/FIXME: use a bool parameter instead of non-empty string.
		transientScriptHexString = 'FF' //transientScriptHexStrings[ whichTransToSpawn-1 ]

		//newTransientScriptPubKeys = new Array( 2 )
		newTransientScriptPubKeys[addIOForHost] = newTransientScriptPubKey
		newTransientScriptPubKeys[1 + addIOForHost] = subTransientScriptPubKey
		outputAmounts[1 + addIOForHost] = smallestAmount * BOUNTY_MULTIPLIER_TRANSIENT

		addOutputForSpawn = 1
	} else {
		//newTransientScriptPubKeys = new Array( 1 )
		newTransientScriptPubKeys[addIOForHost] = newTransientScriptPubKey
		// If we wish to NOT spawn a sub-transient, keep this string/arg blank/empty
		transientScriptHexString = ''
	}

	// Add a special output for a 'dialog'?
	let injectedDialogScriptHexString = 'FF'
	if ( addDialogOutput ) {
		if ( publisherRabinPKH !== theState.rabinPKH || !keepSameRabinPKH ) {
			console.warn("rabinPKH: " + publisherRabinPKH)
			throw new Error("54773: The Rabin Keys cannot be changed when starting a Dialog")
		}
		const pureDialogLockingScript = generateDialogLockingScript('', false)

		const blockToUse = Math.max(newBlockInt, hostTxState.blockNumInt)
		console.error("BUILDING dialog output. using larger of two blockNums: " + theState.blockNumInt + ", " + hostTxState.blockNumInt)

const newHostName = hexStringToAscii( hostTxState.namePath )
console.error("new host/owner: ", newHostName)

const prevHostName = hexStringToAscii( theState.namePath )
console.error("PREVIOUS hostName (now guest-poster): ", prevHostName)


// The guest-poster (other) downCounter gets decremented

// The host/owner downCounter is preserved
//const hostOwnerDownCounterInt = Buffer.from(hostTxState.downCounter, "hex").readUInt8(0) - 1
//var hostOwnerDownCounterHex = oneByteLE(hostOwnerDownCounterInt)


console.error("we're going to use downCounter " + downCounterHex + " for dialog creator/guest-poster address. The other possibility was to use " + theState.downCounter)
console.error("For GP's dialog's host/owner address, will use downCounter " + hostTxState.downCounter)
		const maxBlock = blockToUse + FOURTH_OF_YEAR_IN_BLOCKS
		const spawnedDialogObject = buildDialogScriptPubKey( pureDialogLockingScript,
			swapEndian(previousTxid),

			// NEW in Jan 2023
			hostTxState.guestPostPrice,      // decimal int
			hostTxState.maxContentLen,       // decimal int

			theState.rabinPKH,               // OTHER/guest-post
			hostTxState.rabinPKH,            // OWNER/host rabin
			fourByteLE( maxBlock ),

			// NEW in JAN 2023

			// OTHER/guest-poster
			// NOTE: the guest-poster, creating the dialog, is the responding host (OTHER)
			downCounterHex + theState.txAddress,   // ownerAddressHexWithDownCounter
			theState.namePath,                     // ownerNamePath     (also hex)

			// OWNER/host
			hostTxState.downCounter + hostTxState.txAddress,    // visitorAddressHexWithDownCounter
			hostTxState.namePath,                               // visitorNamePath   (also hex)

			0)  // start with post count of 0

		// use the 'amount' of the responding-host (whoops. for now, of the host)
		const dialogAmount = outputAmounts[0]
		// correct amount, but test with above amount (because contract is wrong)
		//smallestAmount * BOUNTY_MULTIPLIER_TRANSIENT;// outputAmounts[1]

		console.warn("Building dialog with amount of " + dialogAmount + "   (" + fourByteLE(dialogAmount) + " LE)")
		console.warn("Putting dialog script in newTransientScriptPubKeys out idx (base-0): " + (addIOForHost + addOutputForSpawn + 1))

		newTransientScriptPubKeys[addIOForHost + addOutputForSpawn + 1] = spawnedDialogObject.script
						// This was for a phase 1 test - which worked
						//'OP_FALSE OP_RETURN ' + swapEndian( previousTxids[0]) + '44' // Add ascii 'D' (Dialog) to end of state
		outputAmounts[addIOForHost + addOutputForSpawn + 1] = dialogAmount
		injectedDialogScriptHexString = dialogScriptHexString;
	}

	if ( finalPostB ) {
		console.warn("FINAL POST. addIOForHost: " + addIOForHost)

		outputAmounts[addIOForHost] = 0
		console.warn("FINAL POST. set outputAmounts[" + addIOForHost + "] to 0")
		console.warn("  outputAmounts[] = ", outputAmounts)

	} else {
		outputAmounts[addIOForHost] = smallestAmount * BOUNTY_MULTIPLIER_TRANSIENT
		console.warn("Setting outputAmounts[" + addIOForHost + "] to " + outputAmounts[addIOForHost])
	}

	console.log("buildOnTransient(): using SUBtransModeHexChar of " + subTransModeHexChar)

	console.log("\nRight now you need to authorize what you're about to post. "
			+	"You'll need to use the keys associated with the PKH set in the PREVIOUS post.")
	let keyInfo;
	if ( solicitKeys !== null ) {
		keyInfo = solicitKeys(theState.rabinPKH)
	} else {
		keyInfo = calculateKeyInfo(paramPrivKeyP, paramPrivKeyQ);
	}

	const hostGuestModeForSigning = addIOForHost ?
							addDialogOutput ? '03' : '02'
						:
							'00'
	console.error("Signing with hostGuestMode of " + hostGuestModeForSigning)

	// We now get most of the 'message-to-sign' from the state itself
	const completeContent = msgPartA // input parameters + newState
						+	subTransModeHexChar      // spinoff mode
						+	oneByteLE( finalPost )
						+   hostGuestModeForSigning

	if ( !validateRabinPrivKeys(keyInfo.rabinPrivKeyP, keyInfo.rabinPrivKeyQ, theState.rabinPKH) ) {
		console.log("failed. rabinPKH: " + theState.rabinPKH + "    p: " + keyInfo.rabinPrivKeyP + "   q: " + keyInfo.rabinPrivKeyQ)
		throw new Error("18003: Invalid private keys for given Rabin PKH")
	}
	console.log("buildOnTransient(): signing this message: " + completeContent)
	var update1SigResult = getSigParts(Buffer.from(completeContent, "hex"), keyInfo.rabinPrivKeyP, keyInfo.rabinPrivKeyQ, keyInfo.rabinPubKeyBigInt)
	console.log("For SIMPLE TRANSIENT UPDATE, will set this rabin pkh: ", publisherRabinPKH)
	console.log("For SIMPLE TRANSIENT UPDATE, signature is: ", update1SigResult.signature)
	var update1Padding = update1SigResult.paddingBytes
	var update1Sig = int2Asm(update1SigResult.signature)
	console.log("  ASM form of that signature is: ", update1Sig)
	console.log("   ---> AND: keyInfo we used (when debugging, use PubKey BigInt instead of LE): ", keyInfo)
	const publisherPK = keyInfo.rabinPubKeyLE //publisherRabinPubKeyLE
	const hostRabinPKH = hostTxState === null ? null : hostTxState.rabinPKH

	const results = await executeTransientHostingContract(specifiedPrivateKey,
								previousTxids,
								outputIndexesToTake,
								oldScriptPubKeys,
								oldAmounts,
								newTransientScriptPubKeys,
								outputAmounts,
								newBlockInt,
								specifiedBsvChangePKH, //EXT_FUNDING_CHANGE_PKH,
								transientContentName.toString("hex"),
								transientContentHex, // Now as a hex string
								txDescriptor.toString("hex"),
								txToPublishHex,
								publisherRabinPKH,         // publisher rabin pkh for guest-poster
									hostRabinPKH,          // different publisher rabin pkh for host
								update1Sig, update1Padding, publisherPK,

								transientScriptHexString,
								finalPost,

								addIOForHost,		// This indicates there's a host input/tx (guest-posting onto/with it)
									addDialogOutput, // NEW, SPECIAL PARAMETER
									injectedDialogScriptHexString,
								newState,			// guest/typical state (w/o locking script)
								newHostStateBytes,  // host state (w/o locking script)
								hostNewBlockInt,    // if guest-posting on a hostTx: unchanged newBlockInt for host
								subTransientState) // only if guestPosting AND spawning  <--- BRAND NEW  <=======
								//outputIndexToTake)

	console.log("buildOnTransient(): results from TRANSIENT-" + hexByteToAscii(transModeHexChar)
			+ ": ", { txid: results.txid, change: results.change})

	if ( results.txid?.startsWith('11111111111111111111111111111111') ) {
		console.log("We've got a bogus tx, we'll save its raw bytes")
		// insert results.rawTx into table for this purpose
		await dbAddRawTx(theDB, results.txid, results.rawTx)
	}

	// return more than just the txid
	return results
			//txid,
			//rawTx,
			//change

} // libBuildOnTransient

//export /* */
async function libBuildOnDialog(theState, previousTxid, whichOutput,
								param1YN, param2YN, param3,
							whichParticipant,
							blockHeight,
								param9YN,           // finalPost?
								paramPrivKeyP,
								paramPrivKeyQ,
								theDB,
								dbAddRawTx,
								specifiedPrivateKey
	) {

	const privKey = new bsv.PrivateKey.fromWIF(specifiedPrivateKey)
	const specifiedPubKey = bsv.PublicKey.fromPrivateKey( privKey )
	const specifiedBsvChangePKH = bsv.crypto.Hash.sha256ripemd160(specifiedPubKey.toBuffer('hex')).toString('hex')

	console.log("\n\nlibBuildOnDialog(): We're going to build (post/tweet) off of DIALOG output " + whichOutput + " of tx " + previousTxid)
	console.log("\nlibBuildOnDialog(): BTW, here's theState we have right now: ", theState)

	// This is the PREVIOUS scriptPubKey: locking script plus previous state
	const oldScriptPubKey = generateDialogLockingScript( theState.stateHex, false )

	const pureDialogLockingScript = generateDialogLockingScript('', false)

	var newBlockInt = blockHeight

	console.log(' ')
	console.log('newBlockInt: ' + newBlockInt)
	console.log(' ')

	const newBlockHex = fourByteLE(newBlockInt)
	console.log('  new block int is now: ', newBlockInt)
	console.log('  new block hex is now: ', newBlockHex)

	const deadlineInt = theState.maxBlock

	//FIXME: note: readUInt() only goes up to 6 bytes - not the 8 we actually need
	const oldAmount = Buffer.from(theState.contractSatoshis, "hex").readUIntLE(0,6)

	let claimBounty = param1YN;
	if ( claimBounty ) {
		console.log("You can claim the bounty only if newBlockInt is greater than the deadlineInt")
		console.log("Deadline is " + theState.deadline + "   decimal: " + deadlineInt)
		if ( newBlockInt < deadlineInt ) {
			console.log("newBlockInt is only " + newBlockInt + ", so, will increase it to 1 more than deadline")
			newBlockInt = deadlineInt + 1
		}

//FIXME
		const results = '' /* await executeClaimDialogBalance(specifiedPrivateKey,
									previousTxid,
									oldScriptPubKey, oldAmount,
									newBlockInt, specifiedBsvChangePKH, //EXT_FUNDING_CHANGE_PKH,
									whichOutput)
							/**/

		if ( results.txid.startsWith('11111111111111111111111111111111') ) {
			console.log("We've got a bogus tx, we'll save its raw bytes")
			// insert results.rawTx into table for this purpose
			await dbAddRawTx(theDB, results.txid, results.rawTx)
		}

		return results.txid
	}

	console.log("\nbuildOnDialog(): NOTE: the Rabin PKH set/declared in the PREVIOUS tx was: "
				+ theState.ownerRabinPKH)
	console.log("buildOnDialog(): so, when you sign, you'll need to sign with the private keys "
				+ "associated with that PKH\n")

	//FIXME: NOTE: must match dialog.scrypt
	//const maxContentLen = 65535

	var messageToOther = ""
	let publishSomeContent = param2YN;

	if ( publishSomeContent ) {
		messageToOther = param3;
	}

	var finalPostB
	if ( deadlineInt <= newBlockInt ) {
		console.log("\nNOTE: This will be the final post of this DIALOG line (we're approaching the renewalDeadline).")
		finalPostB = true
	} else {
		//FIXME: warn if we're getting near the deadline
		finalPostB = param9YN;
	}

	console.warn("libBuildOnDialog(): finalPost: ", finalPostB)

	var dialogLockingScript = pureDialogLockingScript
	var outputAmounts = []
	var finalPost = 0
	let postNum = theState.postNum + 1   // normally advance the post count
	if ( finalPostB ) {

		//postNum = 4294967295 	// 0xffffffff

		finalPost = 1
		// construct tx with OP_FALSE OP_RETURN
		dialogLockingScript = 'OP_FALSE'
		console.log("\nINSERTING an OP_FALSE just before Dialog's OP_RETURN (AND dropping locking script) < ------- <<<<\n")
	}

	console.log('- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -')
	console.log(' SIMPLE DIALOG script pub key (newDialogScriptPubKey):')
	const dialogScriptAndState = buildDialogScriptPubKey(dialogLockingScript,
			swapEndian(previousTxid),

			// NEW in Jan 2023
			theState.postPrice,  	        // decimal int
			theState.maxContentLen,         // decimal int

			theState.ownerRabinPKH,
			theState.guestRabinPKH,
			theState.maxBlockHex,

			// NEW in JAN 2023
			theState.visitorAddress,          // visitorAddressHexWithDownCounter
			theState.visitorNameHex,          // visitorNamePath   (also hex)
			theState.ownerAddress,          // ownerAddressHexWithDownCounter
			theState.ownerNameHex,          // ownerNamePath     (also hex)

			postNum)

	const newDialogScriptPubKey = dialogScriptAndState.script
	const newDialogState = dialogScriptAndState.state  // ALSO return state - to be used for signing message, below

	const parentOutputIndexToSpend = whichOutput  // output from previous Tx

	var payoutAmount = []

	var dialogScriptPubKeys = new Array( 1 )
	dialogScriptPubKeys[0] = newDialogScriptPubKey

	if ( finalPostB ) {
		outputAmounts[0] = 0
	} else {
		// get amount from previous tx (theState)
		//FIXME: note: readUInt() only goes up to 6 bytes - not the 8 we actually need
		outputAmounts[0] = Buffer.from(theState.contractSatoshis, "hex").readUIntLE(0,6)
			//FIXME: add multiplier? smallestAmount * BOUNTY_MULTIPLIER_DIALOG
	}

	console.log("\nNow you need to authorize what you're about to post. "
			+	"You'll need to use the keys associated with the PKH set in the PREVIOUS/parent tx/post.")
	let keyInfo = calculateKeyInfo(paramPrivKeyP, paramPrivKeyQ);

	console.log("libBuildOnDialog(): messageToOther: " + messageToOther + " <-------")
	const completeContent = messageToOther + fourByteLE( messageToOther.length/2 ) // include LENGTH of message
						  + newDialogState
						  + oneByteLE( finalPostB ? '01' : '00' )                  // include finalPost in message-to-sign


	let rabinPKH
	if ( whichParticipant === '1' || whichParticipant === 1 ) {
		//owner
		rabinPKH = theState.ownerRabinPKH
	} else if ( whichParticipant === '2' || whichParticipant === 2 ) {
		// guest
		rabinPKH = theState.guestRabinPKH
	} else {
		throw new Error("37299: Invalid participant #: " + whichParticipant)
	}

	if ( !validateRabinPrivKeys(keyInfo.rabinPrivKeyP, keyInfo.rabinPrivKeyQ, rabinPKH) ) {
		throw new Error("18002: Invalid private keys for given Rabin PKH")
	}

console.warn("libBuildOnDialog(): completeContent: " + completeContent)
//console.warn("libBuildOnDialog(): completeContent hex: " + Buffer.from(completeContent, "hex").toString())

	const dialogSigResult = getSigParts(Buffer.from(completeContent, "hex"), keyInfo.rabinPrivKeyP, keyInfo.rabinPrivKeyQ, keyInfo.rabinPubKeyBigInt)
	console.log("For DIALOG, we signed this message: " + completeContent)
	console.log("For SIMPLE DIALOG, will set this rabin pkh: ", rabinPKH)
	console.log("For SIMPLE DIALOG, signature is: ", dialogSigResult.signature)
	const dialogPadding = dialogSigResult.paddingBytes
	var dialogSig = int2Asm(dialogSigResult.signature)
	const publisherPK = keyInfo.rabinPubKeyLE

	// NO PAYMENTS FOR DIALOG Txs
	const chosenP2PKHScript = []

	const results = await executeDialogContract(specifiedPrivateKey, previousTxid,
								oldScriptPubKey, oldAmount,
								dialogScriptPubKeys, outputAmounts, //smallestAmount,
								chosenP2PKHScript, payoutAmount,   //payScriptQueue,
								newBlockInt, specifiedBsvChangePKH,
								messageToOther,  // Now Dialog's message is already a hex string
								dialogSig, dialogPadding,
								publisherPK,
								finalPost,  // 0 or 1
								parentOutputIndexToSpend)

	console.log("libBuildOnDialog(): results from Dialog: ", { txid: results.txid, change: results.change})

	if ( results.txid.startsWith('11111111111111111111111111111111') ) {
		console.log("We've got a bogus tx, we'll save its raw bytes")
		// insert results.rawTx into table for this purpose
		await dbAddRawTx(theDB, results.txid, results.rawTx)
	}

	return results.txid
} // libBuildOnDialog

//export /* */
async function libBuildOnBitGroup(theState, previousTxid, whichOutput,
								//a bunch of function (pointers to)
								solicitAnswer, 			//readline.question()
								solicitOptionNumber,	//getOptionNumber()
								solicitYN,				//getYesNoFromUser()
								solicitContent,			//getContentFromUser()
								solicitKeys,			//solicitKeysForSigning()

								//lots of params
								param1WhichUser,
								param2claimBountyOption1_2_or_4,
								param3ffwdToClaimAsNonOwner,
								param4adminToGroup,
								param5PerformAdmin,
								param6optionalFunc,
								param7OtherUserNum,
								param8newUserRabinPKH,
								param9newUserP2PKH,
								param10newUserLevel,
								param10bNewUserName,
								param11Quit,
								param12contentToPost,
								param13FinalPost,
								param14spawnSubGroup,
								param15TipSomeone,
								param16Tipee,
								param17tipAmount,
								param18PayForNamedSubThread,
								param19subthreadName,

								paramPrivKeyP,
								paramPrivKeyQ,
								theDB,
								dbAddRawTx,
								specifiedPrivateKey
				) {

	const privKey = new bsv.PrivateKey.fromWIF(specifiedPrivateKey)
	const specifiedPubKey = bsv.PublicKey.fromPrivateKey( privKey )
	const specifiedBsvChangePKH = bsv.crypto.Hash.sha256ripemd160(specifiedPubKey.toBuffer('hex')).toString('hex')

	console.log("\n\nlibBuildOnBitGroup(): We're going to build (post/tweet) off of output " + whichOutput + " of tx " + previousTxid)
	console.log("\nlibBuildOnBitGroup(): BTW, here's theState we have right now: ", theState)

	// This is the PREVIOUS scriptPubKey: locking script plus previous state
	const oldScriptPubKey = generateBitGroupLockingScript( theState.stateHex, false )

	const pureBitGroupLockingScript = generateBitGroupLockingScript('', false)

	console.log(" ")
	console.log("theState is ", theState)
	console.log("blockNum is ", theState.blockNum)
	console.log(" ")
	var newBlockInt = Buffer.from( theState.blockNum, "hex" ).readUInt32LE(0) + 1;  //FIXME: remove +1. Actually, get actual nextBlock
	const newBlockHex = fourByteLE(newBlockInt)
	console.log('libBuildOnBitGroup(): new block int: ', newBlockInt)
	console.log('                   new block hex: ', newBlockHex)

//FIXME: rename?
	const deadlineInt = theState.maxBlock

	//FIXME: note: readUInt() only goes up to 6 bytes - not the 8 we actually need
	const oldAmount = Buffer.from(theState.contractSatoshis, "hex").readUIntLE(0,6)

	console.log("\nNOTE: the line of BitGroup transactions can only be published up until the maxBlock deadline. "
				+ "Before reaching that block, you could declare a given post the 'finalPost', re-claim the 'bounty'/"
				+ "balance that the contract contains, and formally end the line. Alternatively, within 2000 blocks AFTER the deadline, "
				+ "while it's no-longer permitted to post, the OWNER can CLAIM the bounty/balance - but is required to "
				+ "sign/authenticated himself. If the owner doesn't claim the bounty within those 2000 blocks after the "
				+ "deadline, however, ANYONE can CLAIM the bounty/balance - redirecting the funds wherever they choose, "
				+ "ending the line.")
	if ( deadlineInt < newBlockInt ) {
		console.log("NOTE: It's now too late to post (" + deadlineInt + " < " + newBlockInt + "). It's time to CLAIM the bounty.")
		console.log("Please implement true after-deadline 'bounty-claiming'.")
		//FIXME: very similar to fast-forward options, just below
		throw new Error("17000");
	}


	//Which user are you? (base-0)
	console.log("libBuildOnBitGroup NOTE: the userProfile array is currently: ", theState.userProfiles)
	var numUsers = theState.numUsers
	console.log("libBuildOnBitGroup(): there are " + numUsers + " users.")

	// for unary operator '+', see this:
	// https://stackabuse.com/javascript-convert-string-to-number/
	//FIXME: what's the lowest bid. largest?
	var userNum = -1
	while ( isNaN(userNum) || !Number.isInteger(+userNum) ||
			parseInt(userNum) < 0 || parseInt(userNum) >= numUsers ) {
		if ( solicitAnswer !== null ) {
			userNum = solicitAnswer("Which user are you? ");
		} else {
			userNum = param1WhichUser;
		}
	}

	var userIndex = parseInt( userNum )
	console.log("FIXME: use getOptionNumber instead. <---\n")

	// check the user's Rank
	const currentUserProfile = theState.userProfiles[userIndex]
	console.log("\nThis is your current user profile: ", currentUserProfile)
	const userRank = parseInt(currentUserProfile.userStatus, 10)
	console.log("USER RANK: ", userRank)

	console.log("\nFor testing purposes, we offer you the opportunity to fast-forward to just BEFORE, or just PAST the "
				+ "maxBlock deadline. This can allow you to test the 'finalPost', and the two different 'bounty-claim' features.")

	//FIXME: rename specialOption?
	var claimBountyOption = 4
	if ( userRank > 4 ) {
		if ( newBlockInt + 750 < deadlineInt) {
			console.log("\nIt's 'too early' to NORMALLY test finalPost, and 'bounty-claiming'.")
			console.log("But, would you like to 'fast-forward', and test:")
			console.log("  1) finalPost\n  2) bounty-claiming as OWNER\n  4) NONE of the above\n")
			if ( solicitOptionNumber !== null ) {
				claimBountyOption = solicitOptionNumber("Please choose (1, 2, or 4): ", 4, 1)
			} else {
				claimBountyOption = param2claimBountyOption1_2_or_4;
			}
			if ( claimBountyOption === 3 ) {
				console.log("3 is not an option")
				throw new Error("17001");
			}
		}
	}

	console.log("claimBountyOption: ", claimBountyOption);

	var switchToAdminGroup = false
	var optionalAdminGroupScript = ''
	if ( claimBountyOption === 4 ) {
		console.log("claimbounty?  none of the above")
		let ffwd;
		if ( solicitYN !== null ) {
			ffwd = solicitYN("Would you like to fast-forward, and claim the bounty as a non-owner? ");
		} else {
			ffwd = param3ffwdToClaimAsNonOwner;
		}
		if ( ffwd ) {
			claimBountyOption = 3

			// stranger-claimed bounty option
			console.log("Not being the 'OWNER', you can claim the bounty only if newBlockInt is 2000 blocks greater than the maxBlock (deadline)")
			console.log("Deadline is " + theState.maxBlock + "   decimal: " + deadlineInt)
			if ( newBlockInt < (deadlineInt + 2000) ) {
				console.log("newBlockInt is only " + newBlockInt + ", so, will increase it to 2001 more than deadline")
				newBlockInt = deadlineInt + 2001
			}

			// This is the one case where ANYONE can transact without authentication. It's a RACE.
			//console.log("\n\nFIXME: implement executeClaimBitGroupBalance() <---<\n\n")
			/*
			const results = await executeClaimBitGroupBalance(specifiedPrivateKey,
										previousTxid,
										oldScriptPubKey, oldAmount,
										newBlockInt, specifiedBsvChangePKH, //EXT_FUNDING_CHANGE_PKH,
										whichOutput)

			return results.txid
			*/

		}

		if ( userRank === 5 ) {
			console.log("Since you're the owner, you have the option of adding MULTIPLE "
					+	"users simultaneously - using the AdministerGroup contract, in "
					+	"TWO transactions:\n"
					+   "    1) jump to AdministerGroup contract, with current state\n"
					+	"    2) add many profiles, update numUsers, revert to BitGroup contract\n"
			)
			let adminToGroup;
			if ( solicitYN !== null ) {
				adminToGroup = solicitYN("Would you like to do this? ");
			} else {
				adminToGroup = param4adminToGroup;
			}
			if ( adminToGroup ) {
				switchToAdminGroup = true
				optionalAdminGroupScript = administerGroupScriptHexString
				console.log("\nGreat. NOTE that until you complete the next transaction, NO ONE "
						+	"can post to the line. There will NOT be a BitGroup contract output "
						+	"on which anyone can build. It will be an AdministerGroup contract "
						+	"- something on which only YOU, the owner, can build. When you do, you'll append "
						+	"a number of profiles, and simultaneously revert to the BitGroup contract.\n")
			}
		}
	}

	var payScriptQueue = []   // to build payout scripts
	var cmd = "50" // ascii 0x50: 'P'ost
	var newUserRabinPKH = ""   // former default "1122334455667788990011223344556677889900"
	var newUserP2PKH = ""      // former default "c085725573f714747a4c617a2935d5b9021f1d97"
	var newUserLevel = 0
	var newUserName = ''
	var otherUserNum = -1
	var tipAmount = 0
	var tipeeUserNum = 0
	var userPost = ""
	var userRabinPKH
	var outputAmounts = []
	var finalPost = 0
	var msgSnippet
	var parentOutputIndexToSpend = whichOutput  // output from previous Tx
	var bitGroupScriptPubKeys
	var spawnSubBitGroup = false
	var payoutAmount = 0        // We don't currently pay to BUILDERS for BitGroup txs
	var newActualGroupName = ""
	var newGroupNameForScriptSig = ""

	if ( claimBountyOption === 2 ) {
		// OWNER-claimed bounty option
		console.log("You can claim the bounty as OWNER only if newBlockInt is between the deadline, and 2000 blocks later.")
		console.log("Deadline is " + theState.maxBlock + "   decimal: " + deadlineInt)
		if ( (deadlineInt + 2000) < newBlockInt ) {
			console.log("It's actually TOO LATE to claim as an OWNER. Please choose a different option.")
			throw new Error("17002")
		}

		if ( newBlockInt <= deadlineInt ) {
			console.log("newBlockInt is only " + newBlockInt + ", so, will increase it to 1 more than the deadline")
			newBlockInt = deadlineInt + 1
		}
	}
	if ( claimBountyOption === 2 || claimBountyOption === 3 ) {

		otherUserNum             = 0
		userRabinPKH             = theState.userProfiles[userNum].userRabinPKH
		outputAmounts            = [] // only a CHANGE output
		bitGroupScriptPubKeys    = [] // only a CHANGE output
		spawnSubBitGroup         = false

		// special options to claim bounty/bond, or finalPost
		msgSnippet = buildBitGroupScriptPubKey(pureBitGroupLockingScript,
			theState.userProfiles,
			theState.postNum + 1,
			newBlockInt,
			theState.startedAtPost,
			theState.groupName,
			theState.userBounty,
			theState.maxPostLen,
			theState.satsPerPost,
			deadlineInt,  // maxBlock
			theState.periodicCountHex,
			theState.quarterlyCountHex,
			theState.ownerCountHex,
			theState.branchName).messagePart

		console.log("BTW: msgSnippet =", msgSnippet)

		console.log("We'll skip most everything else, but we still need to AUTHENICATE as the owner.")
	} else {
		const maxPostLen = theState.maxPostLen
		console.log("\n\nNOTE: max post length is " + maxPostLen + " (" + (maxPostLen/1024) + " KB)  <---\n\n")

		if ( userRank === 0 ) {
			console.log("Hello, probie!")
		} else if ( userRank === 1 ) {
			console.log("Hello, normie!")
		} else if ( userRank === 2 ) {
			console.log("Hello, hero!")
		} else if ( userRank === 3 ) {
			console.log("Hello, mod!")
		} else if ( userRank === 4 ) {
			console.log("Hello, uber mod!")
		} else if ( userRank === 5 ) {
			console.log("Hello, dear sir.")
		} else {
			console.log("HMM. userRank is ", userRank)
			throw new Error("17020");
		}
		if ( userRank < 3 ) {
			console.log("Because you're not a mod, we're setting 'otherUserNum' to your user number (you can't affect others, other than tipping them).")
			otherUserNum = userNum
		}

		// ask if would like to perfom an admin function
		if ( userRank > 3 ) {
			let performAdmin;
			if ( solicitYN !== null ) {
				performAdmin = solicitYN("Since you're a mod-or-better, would "
									+    "you like to perform an admin function (add/remove/promote/demote user)?");
			} else {
				performAdmin = param5PerformAdmin;
			}
			if ( performAdmin ) {
				console.log("\nAdministrative Functions:\n1) add user\n2) remove user\n3) promote user\n4) demote user\n5) NEVER MIND\n")
				console.log("\nFIXME: add function 'q'uit - which also refunds any bounty paid")
				var optionalFunction;
				if ( solicitOptionNumber !== null ) {
					optionalFunction = solicitOptionNumber("Which function would you like to perform? ", 4, 1)
				} else {
					optionalFunction = param6optionalFunc;
				}
				console.log("FIXME: decide if allowed to perform function on self?")

				if ( optionalFunction !== 1 ) {
					if ( solicitOptionNumber !== null ) {
						otherUserNum = solicitOptionNumber("On which user would you like to perform this function? ", numUsers-1, 0)
					} else {
						otherUserNum = param7OtherUserNum;
					}
					if ( otherUserNum === userNum ) {
						console.error("\nSorry, it's probably not legal to operate on yourself.")
						throw new Error("17003")
					}

					if ( theState.userProfiles[otherUserNum].userStatus >= userRank ) {
						console.error("Sorry. That user's rank/status is too high for you to administer.")
						throw new Error("17004")
					}
				}

				if ( optionalFunction === 1 ) {
					cmd = "41" // 'A'dd user

					console.log("\nFor this new user, we need 2 PKHs, and a status/rank.")
					var validNewRabin = false
					do {
						if ( solicitAnswer !== null ) {
							newUserRabinPKH = solicitAnswer("\nPlease enter the Rabin PKH for this new user: ")
						} else {
							newUserRabinPKH = param8newUserRabinPKH;
						}
						if ( newUserRabinPKH.length === 40 ) {
							if ( solicitYN !== null ) {
								validNewRabin = solicitYN("Are you sure this is the Rabin PKH you'd like to declare? (Y/N) ")
							} else {
								validNewRabin = true
							}
						} else {
							console.log("\nThat's not a valid length for a Rabin PKH.")
						}
						if ( !validNewRabin && solicitYN === null ) {
							throw new Error("17005");
						}
					} while ( !validNewRabin );

					var validNewP2PKH = false
					do {
						//FIXME: we prob have a solicitPKH
						if ( solicitAnswer !== null ) {
							newUserP2PKH = solicitAnswer("\nPlease enter the P2PKH for this new user: ")
						} else {
							newUserP2PKH = param9newUserP2PKH;
						}
						if ( newUserP2PKH.length === 40 ) {
							if ( solicitYN !== null ) {
								validNewP2PKH = solicitYN("Are you sure this is the P2PKH you'd like to declare? (Y/N) ")
							} else {
								validNewP2PKH = true;
							}
						} else {
							console.log("\nThat's not a valid length for a PKH.")
						}
						if ( !validNewP2PKH && solicitYN === null ) {
							throw new Error("17006");
						}
					} while ( !validNewP2PKH );

					console.log("These are the possible user status/ranks:")
					console.log("0) probationary\n1) normal\n2) hero\n3) moderator\n4) uber mod\n5) owner")
					if ( solicitOptionNumber !== null ) {
						newUserLevel = solicitOptionNumber("Which level should the new user have (max " + (userRank-1) + ")? ", (userRank-1), 0)
					} else {
						newUserLevel = param10newUserLevel;
					}
					numUsers ++

					if ( solicitContent !== null ) {
						newUserName = solicitContent("Please enter the new user's name (max len 32)");
					} else {
						newUserName = param10bNewUserName;
					}

					console.log("BTW: setting recentPostBlock to " + newBlockInt + " (" + fourByteLE( newBlockInt ) + ")")
					theState.userProfiles = appendUserProfile(theState.userProfiles,
																newUserRabinPKH,
																newUserP2PKH,
																fourByteLE(newBlockInt),  // userRecentPostBlock
																newUserLevel)
				} else if ( optionalFunction === 2 ) {
					cmd = "52" // 'R'emove user

					console.log("Will remove user " + otherUserNum)

					theState.userProfiles = removeUserProfile(theState.userProfiles, otherUserNum)
				} else if ( optionalFunction === 3 ) {
					cmd = "70" // 'p'romote user
					const currentRank = parseInt( theState.userProfiles[otherUserNum].userStatus )

					if ( currentRank === 4 ) {
						console.error("Sorry, that user is already at the highest rank for promotion: 4")
						throw new Error("17007");
					}
					console.log("Will promote user " + otherUserNum + " to level " + oneByteLE(currentRank+1))
					theState.userProfiles[ otherUserNum ].userStatus = oneByteLE(currentRank + 1)
				} else if ( optionalFunction === 4 ) {
					cmd = "64" // 'd'emote user
					const currentRank = parseInt( theState.userProfiles[otherUserNum].userStatus )

					if ( currentRank === 0 ) {
						console.error("Sorry, that user is already at the lowest rank: 0")
						throw new Error("17008");
					}
					console.log("Will demote user " + otherUserNum + " to level " + oneByteLE(currentRank-1))
					theState.userProfiles[ otherUserNum ].userStatus = oneByteLE(currentRank - 1)
				} else if ( optionalFunction === 5 ) {
					console.error("Sorry, there can only be one owner user");
					throw new Error("17009");
				} else {
					console.error("UNEXPECTED FUNCTION choice: " + optionalFunction)
					throw new Error("17010");
				}
			}
		} // mod function options

		var addToBalance = 0
		var subtractFromBalance = 0
		var quitGroup = false
		// give non-mod users the option to quit
		if ( claimBountyOption === 4 && userRank < 3 ) {
			console.log("\nNOTE: if you ever want to PERMANENTLY quit the group, we'll remove your user "
					+ "profile, and refund any Bounty you may have paid (IF you're a member in good standing).")
			if ( solicitYN !== null ) {
				quitGroup = solicitYN("Would you like to permanently QUIT/LEAVE the BitGroup? ")
			} else {
				quitGroup = param11Quit;
			}
			if ( quitGroup ) {
				var mostRecentPostBlock = Buffer.from(currentUserProfile.userRecentPostBlock, "hex").readUInt32LE(0)
				console.log("You'd like to Quit. most recent post block is " + mostRecentPostBlock)
				console.log("And newBlockInt is " + newBlockInt + ". Two days later is " + (newBlockInt + 288))
				if ( newBlockInt <= (mostRecentPostBlock + 288) ) {
					console.log("It's actually too early to quit. All users need to wait 2 days, without "
							+	"posting, before they can quit.")
					let ffwdToTestQuitting
					if ( solicitYN !== null ) {
						ffwdToTestQuitting = solicitYN("\nWould you like to fast-forward 2 days to test quitting? ");
					} else {
						ffwdToTestQuitting = false;
					}
					if ( ffwdToTestQuitting ) {
						newBlockInt = mostRecentPostBlock + 289
						console.log("Ok. We've fast-forwarded to 289 blocks after most-recent post.")
					} else {
						console.log("Ok, then, this transaction should fail.")
					}
				}

				if ( currentUserProfile.userPaidBounty !== 0 ) {
					if ( theState.startedAtPost === 0 ) {
						console.log("\nNOTE: refunding bounty (on quit) since we're in the main thread.\n")
						subtractFromBalance = theState.userBounty
					} else {
						console.log("\nNOTE: NOT refunding bounty (on quit) since we're in a SUB thread. <---\n")
					}
				}
				otherUserNum = userNum
				cmd = '51'
				console.log("Will remove user " + userNum)

				theState.userProfiles = removeUserProfile(theState.userProfiles, userNum)
			}
		}

		console.log("\nFIXME: setup way to reference a generic tx, or asset <=--<\n")

		/*
		var txToPublishHex = ""
		const publishATxId = getYesNoFromUser("Would you like to publish a Transaction ID within the UPDATE?");
		if ( publishATxId ) {
			txToPublishHex = getTxIdFromUser()
		}
		*/

		userRabinPKH = currentUserProfile.userRabinPKH
		console.log("\nYour current rabinPKH is " + userRabinPKH)
		/*
		const keepSameRabinPKH = getYesNoFromUser("Would you like to keep the same Publisher PKH for "
											+	"this next UPDATE posting, or set/declare a new one?");
		if ( !keepSameRabinPKH ) {
			publisherRabinPKH = getPKHFromUser("1 - Enter the rabin PKH (hex) to use: ")
		}
		*/

		//FIXME: warn that setting an invalid PKH can be bad. And do this elsewhere too.

		var bitGroupLockingScript = pureBitGroupLockingScript
		var finalPostB
		if ( !quitGroup ) {
			let payingBounty = false
			console.log("BTW: current profile.userPaidBounty: " + currentUserProfile.userPaidBounty)
			if ( currentUserProfile.userPaidBounty === 0 || currentUserProfile.userPaidBounty === "00") {
				if ( theState.startedAtPost !== 0 ) {
					console.log("\nNOTE: we're in a SUB-thread, and you haven't paid your bounty. You can't post here. <---\n")
					throw new Error("17011");
				}
				console.log("So, in order to do much, you need to pay your bounty")
				//console.log("\nFIXME: maybe this should go up higher/sooner in the function <--\n")
				if ( cmd === "50" ) {
					console.log("\nIn order to POST, you'll need to pay your bounty.")
					if ( otherUserNum !== userNum && otherUserNum !== -1 ) {
						console.error("Something is wrong. You can't 'P'ost unless you pay your bounty "
								+ "(AND set 'otherUserNum' to your userNum), " + userNum + ", but I see "
								+ "it's set to " + otherUserNum + ". Please report this.")
						throw new Error("17012");
					}

					console.log("\nNOTE: since you're NOW paying your bounty, we'll need to set the 'OTHER' "
							+ "userNum to your number, " + userNum + ".");
					otherUserNum = userNum

					addToBalance += theState.userBounty
					console.log("\nNOTE: adding " + addToBalance + " sats to balance - the bounty <-----\n")

					// modify userProfile
					theState.userProfiles[ userNum ].userPaidBounty = "01"
					payingBounty = true
				} else {
					console.log("Since you're not posting, we won't ask you to pay your Bounty right now.")
				}
			}

			// Track the block of the most-recent post of each non-mod user.
			// This helps us enforce a wait-period before a user can quit.
			// That allows time for moderators to adjudicate misbehavior
			// (and potentially confiscate bond/bounty of a misbehaving
			// user - before he can quit and re-claim his bond/bounty).
			if ( payingBounty || userRank < 3 ) {
				console.log("\nBTW: UPDATING recent post block to " + newBlockInt + " <---\n")
				theState.userProfiles[ userNum ].userRecentPostBlock = fourByteLE( newBlockInt )
			}

			// if not yet used, set to a reasonable value
			if ( otherUserNum === -1 ) {
				otherUserNum = 0
			}

			// add satsPerPost if 'P'osting. Admins/mods get a pass if they're also performing an admin function
			if ( userRank === 0 ) {
				console.log("NOTE: adding " + ( 2 * theState.satsPerPost ) + " satoshis to balance - (double postage fee)  <----<<<")
				addToBalance += ( 2 * theState.satsPerPost )
			} else if ( userRank === 1 ) {
				console.log("NOTE: adding " + theState.satsPerPost + " satoshis to balance (postage fee)    <-----<")
				addToBalance += theState.satsPerPost
			}

			//FIXME: see getOptionNumber() below

			//	const postToGroup = getYesNoFromUser("Would you like to post to the BitGroup?");
			//	if ( postToGroup ) {
			if ( solicitContent !== null ) {
				userPost = solicitContent("Please enter the text you'd like to post", maxPostLen)
			} else {
				userPost = param12contentToPost;
			}
			//	}


			// The finalPost can only be declared within the final
			// 750 blocks before the end of the line
			if ( userRank === 5 && deadlineInt <= newBlockInt + 750 ) {
				console.log("Note: there is a deadline for posting to the BitGroup. Once the deadline expires, you have a short window (2000 blocks) to "
						+ "claim the balance of satoshis. After that window, ANYONE can claim/empty the balance. BEFORE the deadline (within 750 blocks of "
						+ "it), you can declare a post to be the 'finalPost', and simultaneously empty/claim the balance. That ends the line of transactions.")
				console.log("If/when you declare the 'finalPost', you are not be able to also spawn a subThread group in that transaction.")
				if ( solicitYN !== null ) {
					finalPostB = solicitYN("Will this be the final post of this line BitGroup line (even though there may still be time to post more)?");
				} else {
					finalPostB = param13FinalPost;
				}
			}


			console.log("\nFIXME: if this is a UN-NAMED subThread, take note of the op/post num. We're limited to "
					+	"a maximum of " + MAX_POSTS_FOR_UNNAMED_GROUP_SUBTHREAD + " posts on such a thread.")
			// if theState.opNum+1 is 500, set finalPost


			if ( finalPostB || claimBountyOption === 1 ) {
				if ( newBlockInt + 750 < deadlineInt) {
					console.log("\nNOTE: since it's the finalPost (and we're fast-forwarding), increasing newBlockInt so it's 1 less than the deadline.")
					newBlockInt = deadlineInt - 1
				}

				finalPost = 1
				// construct tx with OP_FALSE OP_RETURN
				bitGroupLockingScript = 'OP_FALSE'
				console.log("\nINSERTING an OP_FALSE just before BitGroup's OP_RETURN (AND dropping locking script) < ------- <<<<\n")
				console.log("NOTE: because it's the final post, we won't ask you if you'd like to spawn a BitGroup sub-thread.")
			} else {
				// Don't bother asking to spawn a subThread if we're already on a subThread (not allowed)
				if ( theState.startedAtPost === 0 ) {
					if ( userRank > 0 ) {
						if ( solicitYN !== null ) {
							spawnSubBitGroup = solicitYN("\nWould you like to also SPAWN a BitGroup SubThread output?");
						} else {
							spawnSubBitGroup = param14spawnSubGroup;
						}
					} else {
						console.log("\nNOTE: We won't ask you to consider spawning a subThread, since you're still a probationary user. <---\n")
					}
				} else {
					console.log("NOTE: We won't consider spawning a subThread because we're already on a subThread.")
				}
			}

		} // !quitGroup

		if ( numUsers > 1 ) {
			let tipSomeone
			if ( solicitYN !== null ) {
				tipSomeone = solicitYN("Would you like to tip a user? ")
			} else {
				tipSomeone = param15TipSomeone
			}
			if ( tipSomeone ) {
				console.log("FIXME: be clear that he's tipping to userNum calculated by looking at the OLD list of users.")

				//if ( otherUserNum == -1 ) {
					if ( solicitOptionNumber !== null ) {
						tipeeUserNum = solicitOptionNumber("To which user would you like to tip? ", numUsers-1, 0)
					} else {
						tipeeUserNum = param16Tipee
					}
					if ( tipeeUserNum === userNum ) {
						console.error("\nSorry, it's probably not allowed to tip to yourself.")
						throw new Error("17013")
					}
				//} else {
				//	console.log("NOTE: In THIS transaction you can only tip to user #" + otherUserNum + ". "
				//			+ "That's the 'other' user you've already identified.\n")
				//}

				console.log("NOTE: the network won't let you tip a user less than " + SMALLEST_OUTPUT + " satoshis.")
				if ( solicitOptionNumber !== null ) {
					tipAmount = solicitOptionNumber("How much would you like to tip? ", 100000000, SMALLEST_OUTPUT)
				} else {
					tipAmount = param17tipAmount;
				}

				console.log("You will tip " + tipAmount + " satoshis to user " + tipeeUserNum + ".")
			}
		}

		var bgspk
		if ( switchToAdminGroup ) {
			const pureAdminGroupLockingScript = generateAdministerGroupLockingScript('', false)

			// OPTIONAL BitGroup hash inserted - to help authenticate when
			// restoring from admingroup back to bitgroup

			console.log('- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -')
			console.log(' SIMPLE BitGroup to ADMINISTERGROUP script pub key:')
			bgspk = buildAdministerGroupScriptPubKey(pureAdminGroupLockingScript,
							theState.userProfiles,
							simpleHash( bitGroupScriptHexString ), // allows us to return to BitGroup in the next tx
							theState.postNum + 1,
							newBlockInt,
							theState.startedAtPost,
							theState.groupName,
							theState.userBounty,
							theState.maxPostLen,
							theState.satsPerPost,
							deadlineInt,  // maxBlock
							theState.periodicCountHex,
							theState.quarterlyCountHex,
							theState.ownerCountHex,
							theState.branchName)
		} else {
			// normal case: build BitGroup on top of BitGroup
			console.log('- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -')
			console.log(' SIMPLE BITGROUP script pub key (newBitGroupScriptPubKey):')
			bgspk = buildBitGroupScriptPubKey(bitGroupLockingScript,
							theState.userProfiles,
							theState.postNum + 1,
							newBlockInt,
							theState.startedAtPost,
							theState.groupName,
							theState.userBounty,
							theState.maxPostLen,
							theState.satsPerPost,
							deadlineInt,  // maxBlock
							theState.periodicCountHex,
							theState.quarterlyCountHex,
							theState.ownerCountHex,
							theState.branchName)
		}
		//FIXME: maybe rename this?
		const newBitGroupScriptPubKey = bgspk.scriptPubKey
		msgSnippet = bgspk.messagePart

		if ( spawnSubBitGroup || tipAmount > 0 ) {
			if ( spawnSubBitGroup && tipAmount > 0 ) {
				console.log("-> (array of 3 outputs)")
				bitGroupScriptPubKeys = new Array( 3 )
			} else {
				console.log("-> (array of 2 outputs)")
				bitGroupScriptPubKeys = new Array( 2 )
			}
		} else {
			console.log("-> (array of 1 output)")
			bitGroupScriptPubKeys = new Array( 1 )
		}
		var tipIndex
		//var transModeHexChar = '47'
		if ( spawnSubBitGroup ) {
			var addToSubThreadBalance = 0

			console.log("\nWe will include a BitGroup subThread at output #1 (base-0)")

			const feeForNamedGroup = NAMED_GROUP_SUBTHREAD_FEE_MULTIPLIER * theState.satsPerPost
			console.log("There are two options:\n  1) a NAMED sub-thread/group (which costs " + feeForNamedGroup + " sats)\n"
					+ "  2) an un-named sub-thread/group limited to " + MAX_POSTS_FOR_UNNAMED_GROUP_SUBTHREAD
					+ " posts")

			let payForNamedSubThread
			if ( solicitYN !== null ) {
				payForNamedSubThread = solicitYN("Would you like to pay for a NAMED subThread/group? ")
			} else {
				payForNamedSubThread = param18PayForNamedSubThread
			}
			if ( payForNamedSubThread ) {
				addToSubThreadBalance = feeForNamedGroup
				if ( solicitAnswer !== null ) {
					newActualGroupName = solicitAnswer("\nEnter the name of the new sub-thread/group: ");
				} else {
					newActualGroupName = param19subthreadName;
				}
				newGroupNameForScriptSig = newActualGroupName
			} else {
				// wacky convention: for an unnamed subThread group, use a name of length 1
				// That parameter will be noticed (triggering a subthread), but its value
				// will be ignored
				newGroupNameForScriptSig = "a"
				newActualGroupName = ''
			}

			const pureSpawnedBitGroupLockingScript = generateBitGroupLockingScript('', false)

			// when adding optionalBitGroup, content must initially be blank
			// spawning SUB BitGroup
			const gspk = buildBitGroupScriptPubKey( pureSpawnedBitGroupLockingScript,
				theState.userProfiles,
				// Some of these four fields are different than mainline
				1, // start with a new post number of 1.      had been: theState.postNum + 1,
				newBlockInt,
				theState.postNum + 1, //new startedAtPost.      had been: 0 (theState.startedAtPost),
				Buffer.from( newActualGroupName ).toString("hex"),

				theState.userBounty,
				theState.maxPostLen,
				theState.satsPerPost,
				deadlineInt,  // maxBlock
				theState.periodicCountHex,
				theState.quarterlyCountHex,
				theState.ownerCountHex,
				theState.branchName)
			const spawnedBitGroupScriptPubKey = gspk.scriptPubKey

			//if ( bitGroupScriptHexString.length == 0 ) {
			//	console.error("\nERROR: EMPTY BitGroup script hex string cache. Please define this in the contracts cache.\n")
			//	exit(-1)
			//}

			//console.log("\nFIXME: use a payout multiple - price for spawing a NAMED BitGroup subThread. <---\n")
			//const multiple = bitGroupPayoutMultiple
			//console.log("Because we're spawning, the payout multiple is " + multiple)

			// We charge EXTRA (quintuple), in payouts to the builders, for the ability to use a 'transient' line of (max 254) content updates/outputs
			// Later transient publishings are 'free' - meaning, no fees to BUILDERS. Miner fees ALWAYS apply.
		//	payoutAmount = multiple * payoutAmount
		//	console.log('\nNOTE: charging ' + multiple + 'x on BUILDER payouts, because we\'re including a BitGroup output. '

			//bitGroupScriptPubKeys = new Array( 2 )
			bitGroupScriptPubKeys[0] = newBitGroupScriptPubKey
			bitGroupScriptPubKeys[1] = spawnedBitGroupScriptPubKey
			outputAmounts[1] = theState.userBounty + addToSubThreadBalance // this is a relatively small amount that we already have access to. We don't pass in 'smallestAmount'
			tipIndex = 2
		} else {
			//bitGroupScriptPubKeys = new Array( 1 )
			bitGroupScriptPubKeys[0] = newBitGroupScriptPubKey
			//bitGroupScriptHexString = '11'  //FIXME: probably don't need this
			tipIndex = 1
		}

		if ( finalPostB || claimBountyOption === 1 ) {
			outputAmounts[0] = 0
		} else {
			//console.log("Probably need to use previous balance PLUS anything special")
			outputAmounts[0] = oldAmount + addToBalance - subtractFromBalance // add any bounty due (or refund)
		}

		if ( tipAmount > 0 ) {
			const tipScript = 'OP_DUP OP_HASH160 ' + theState.userProfiles[ tipeeUserNum ].userP2PKH + ' OP_EQUALVERIFY OP_CHECKSIG'
			bitGroupScriptPubKeys[ tipIndex ] = tipScript
			outputAmounts[ tipIndex ] = tipAmount
			console.log("\nNOTE: Adding " + tipAmount + "-satoshi script output[" + tipIndex + "] of '" + tipScript + "' <----<<\n")
		}

		// payout can't be smaller than the current 'dust+1' amount
		//payoutAmount = 0 //Math.max(smallestAmount, payoutAmount)
		console.log("FIXME: consider PAYING TO BUILDERS for spawning a Group sub-thread\n")
		/*
		console.log("\nPayout to the " + theState.builderPKHs.length + " builders is "
					+ payoutAmount + " satoshis - each.")
		*/
	} // claimBountyOption != 2

	var claimBountyAsm = "OP_0"
	var claimBountyHex = "00"
	if ( claimBountyOption === 2 || claimBountyOption === 3 ) {
		claimBountyAsm = "OP_1"
		claimBountyHex = "01"
	}

	var update1Padding
	var update1Sig
	var userRabinPK
	if ( claimBountyOption !== 3 ) {
		console.log("\nNow you need to authorize what you're about to post. "
				+	"You'll need to use the keys associated with you when your 'user' was added to the contract.") //the PKH set in the PREVIOUS/parent tx/post.")
		let keyInfo
		if ( solicitKeys !== null ) {
			keyInfo = solicitKeys(userRabinPKH)
		} else {
			keyInfo = calculateKeyInfo(paramPrivKeyP, paramPrivKeyQ);
		}

		const completeContent	= fourByteLE(theState.postNum + 1)   //FIXME: this needs to be fourByteLE()  <---------
								+ cmd
								+ fourByteLE(userPost.length )
								+ Buffer.from(userPost).toString("hex")
								+ fourByteLE(newBlockInt)
								+ EXT_FUNDING_CHANGE_PKH
								+ twoByteLE( userNum )            //userIndex?
								+ twoByteLE( otherUserNum )
								+ newUserRabinPKH				// only relevant when adding user, but needs to be 20
								+ newUserP2PKH					// only relevant when adding user, but needs to be 20
								+ oneByteLE( newUserLevel )		// new user status. only relevant when adding user
								+ Buffer.from(newUserName).toString("hex")
								+ fiveByteLE( tipAmount )		// tip
								+ twoByteLE( tipeeUserNum )     // receiver of tip (base-0)
								+ Buffer.from( newActualGroupName ).toString("hex")
								+ oneByteLE(finalPost)			//FIXME: finalPost
								+ claimBountyHex				//FIXME: claimBounty
								+ msgSnippet			// startedAtPost, groupName through mode

		if ( !validateRabinPrivKeys(keyInfo.rabinPrivKeyP, keyInfo.rabinPrivKeyQ, userRabinPKH) ) {
			throw new Error("18000: Invalid private keys for given Rabin PKH")
		}
		const update1SigResult = getSigParts(Buffer.from(completeContent, "hex"), keyInfo.rabinPrivKeyP, keyInfo.rabinPrivKeyQ, keyInfo.rabinPubKeyBigInt)
		console.log("For BITGROUP, using this rabin pkh: ", userRabinPKH)
		console.log("For BITGROUP, signature is: ", update1SigResult.signature)

		update1Padding = update1SigResult.paddingBytes
		update1Sig = int2Asm(update1SigResult.signature)
		userRabinPK = keyInfo.rabinPubKeyLE //publisherRabinPubKeyLE
	} else {
		update1Padding = "00"
		update1Sig = "OP_0"
		userRabinPK = "OP_0"
	}

	console.log("BTW: finalPost is " + finalPost)
	//console.log("\nBTW: scriptPubKey is: " + newBitGroupScriptPubKey + "\n")
	const results = await executeBitGroupContract(specifiedPrivateKey, previousTxid,
								oldScriptPubKey, oldAmount,
								bitGroupScriptPubKeys, outputAmounts, //smallestAmount,
								payScriptQueue, payoutAmount,

								cmd,
								Buffer.from(userPost).toString("hex"),
								newBlockInt, specifiedBsvChangePKH, //EXT_FUNDING_CHANGE_PKH,
								userNum,
								update1Sig,
								update1Padding, userRabinPK,
								otherUserNum,
								newUserRabinPKH,
								newUserP2PKH,
								newUserLevel,
								Buffer.from(newUserName).toString("hex"),    //  0 < asciiLen < 33
								tipAmount,
								tipeeUserNum,
								Buffer.from( newGroupNameForScriptSig ).toString("hex"),
								finalPost,
								claimBountyAsm,

								optionalAdminGroupScript,

								parentOutputIndexToSpend)

	console.log("libBuildOnBitGroup(): results from BITGROUP: ", { txid: results.txid, change: results.change})

	if ( results.txid.startsWith('11111111111111111111111111111111') ) {
		console.log("We've got a bogus tx, we'll save its raw bytes")
		// insert results.rawTx into table for this purpose
		await dbAddRawTx(theDB, results.txid, results.rawTx)
	}

	return results.txid
} // libBuildOnBitGroup

//export /* */
async function libBuildOnAdminGroup(theState, previousTxid, whichOutput,
								solicitOptionNumber,		// getOptionNumber()
								solicitKeys,				// solicitKeysForSigning()

								param1numProfilesToAdd,
								param2addedUsersArray,
								param3newNamesArray,

								paramPrivKeyP,
								paramPrivKeyQ,

								theDB,
								dbAddRawTx,
								specifiedPrivateKey) {

	const privKey = new bsv.PrivateKey.fromWIF(specifiedPrivateKey)
	const specifiedPubKey = bsv.PublicKey.fromPrivateKey( privKey )
	const specifiedBsvChangePKH = bsv.crypto.Hash.sha256ripemd160(specifiedPubKey.toBuffer('hex')).toString('hex')

	console.log("\n\nlibBuildOnAdminGroup(): We're going to build off of output " + whichOutput + " of tx " + previousTxid)
	console.log("\nlibBuildOnAdminGroup(): BTW, here's theState we have right now: ", theState)


	// This is the PREVIOUS scriptPubKey: locking script plus previous state
	const oldScriptPubKey = generateAdministerGroupLockingScript( theState.stateHex, false )

	//const pureAdministerGroupLockingScript = generateAdministerGroupLockingScript('', false)
	const pureBitGroupLockingScript        = generateBitGroupLockingScript('', false)

	//var newBlockInt = theState.currentBlock
	const newBlockHex = theState.blockNum
	var newBlockInt = Buffer.from( newBlockHex, "hex" ).readUInt32LE(0);
	console.log('libBuildOnAdminGroup(): new block int: ', newBlockInt)
	console.log('                          new block hex: ', newBlockHex)

	//FIXME: note: readUInt() only goes up to 6 bytes - not the 8 we actually need
	const oldAmount = Buffer.from(theState.contractSatoshis, "hex").readUIntLE(0,6)

	//Which user are you? (base-0)
	console.log("libBuildOnAdminGroup NOTE: the userProfile array is currently: ", theState.userProfiles)
	var numUsers = theState.numUsers
	console.log("libBuildOnAdminGroup(): there are " + numUsers + " users.")

	var userIndex = 0;

	// check the user's Rank
	var currentUserProfile = theState.userProfiles[userIndex]
	console.log("\nThis is your current user profile: ", currentUserProfile)
	const userRank = parseInt( currentUserProfile.userStatus )

	var payScriptQueue = []   // to build payout scripts
	var userRabinPKH
	var outputAmounts = []
	var stateSnippet
	var parentOutputIndexToSpend = whichOutput  // output from previous Tx
	var adminGroupScriptPubKeys = []
	var payoutAmount = 0        // We don't currently pay to BUILDERS for BitGroup txs

	if ( userRank !== 5 ) {
		console.log("You're either the owner, or you can't do anything here right now. (rank: " + userRank + ")")
		throw new Error("17750")
	}

	var theNewProfiles = []
	console.log("This is the opportunity to ADD user profiles.")
	let numProfilesToAdd;
	if ( solicitOptionNumber !== null ) {
		numProfilesToAdd = solicitOptionNumber("How many user profiles would you like to add? ", 16, 0)
	} else {
		numProfilesToAdd = param1numProfilesToAdd;
	}

	const oldNumProfiles = theState.numUsers

	//FIXME: maybe pass this in, instead of re-creating it in executeAdminGroupContract()
	var newProfilesBytes = ""
	var newNamesBytes = ""

	for ( var i = 0; i < numProfilesToAdd; i++ ) {
		var newIndex = oldNumProfiles + i
		console.log("Adding supplied profile " + i + " - actually, as profile[" + newIndex + "]")

		if ( param2addedUsersArray === null ) {
			//FIXME: remove duplication of constants
			theNewProfiles[i] = {
				userRabinPKH: "748fc2b2cbad49e520f1d2cdfc91265318fdff3c",
				userP2PKH: "c085725573f714747a4c617a2935d5b9021f1d97",
				userRecentPostBlock: "f5c41300",
				userStatus: '02',
				userPaidBounty: '01'
			}
			theState.userProfiles[newIndex] = {
				userRabinPKH:        "748fc2b2cbad49e520f1d2cdfc91265318fdff3c",
				userP2PKH:           "c085725573f714747a4c617a2935d5b9021f1d97",
				userRecentPostBlock: "f5c41300",
				userStatus:          "02",
				userPaidBounty:      "01"
			}
		} else {
			theNewProfiles[i] = {
				userRabinPKH: 			param2addedUsersArray[i].userRabinPKH,
				userP2PKH: 				param2addedUsersArray[i].userP2PKH,
				userRecentPostBlock:	param2addedUsersArray[i].userRecentPostBlock,
				userStatus: 			param2addedUsersArray[i].userStatus,
				userPaidBounty: 		param2addedUsersArray[i].userPaidBounty
			}
			theState.userProfiles[newIndex] = {
				userRabinPKH:        	param2addedUsersArray[i].userRabinPKH,
				userP2PKH:           	param2addedUsersArray[i].userP2PKH,
				userRecentPostBlock: 	param2addedUsersArray[i].userRecentPostBlock,
				userStatus:          	param2addedUsersArray[i].userStatus,
				userPaidBounty:      	param2addedUsersArray[i].userPaidBounty
			}
		}

		newProfilesBytes += theNewProfiles[i].userRabinPKH
		newProfilesBytes += theNewProfiles[i].userP2PKH
		newProfilesBytes += theNewProfiles[i].userRecentPostBlock
		newProfilesBytes += theNewProfiles[i].userStatus
		newProfilesBytes += theNewProfiles[i].userPaidBounty

		let name;
		if ( param3newNamesArray === null ) {
			name = 'newUserAddedOnTopOf_' + oldNumProfiles + "_(" + i + ")";
			console.log("creating user with wacky name: " + name)
		} else {
			name = param3newNamesArray[i];
		}
		if ( name.length > 32 ) {
			console.error("User #" + i + "'s name is too long (max 32 chars)");
			throw new Error("17401");
		}

		newNamesBytes += Buffer.from( name ).toString("hex")
		// zero-pad
		const padLen = 32 - name.length;
		console.log("Padding name " + i + " with " + padLen + " zeroes (double for ascii hex).");
		for ( var j = padLen; j > 0; j-- ) {
			newNamesBytes += '00'
		}
	}

	userRabinPKH = currentUserProfile.userRabinPKH
	console.log("\nCurrent user (owner) rabinPKH is " + userRabinPKH)

	console.log('- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -')
	console.log(' SIMPLE ADMINISTERGROUP to BitGroup script pub key (newAdministerGroupScriptPubKey):')
	//NOTICE we're building a BitGroup output.
	// Restoring to BitGroup from AdminGroup
	const agspk = buildBitGroupScriptPubKey(pureBitGroupLockingScript,
			theState.userProfiles,
			theState.postNum,
			newBlockInt,
			theState.startedAtPost,
			theState.groupName,
			theState.userBounty,
			theState.maxPostLen,
			theState.satsPerPost,
			theState.maxBlock, //deadlineInt
			theState.periodicCountHex,
			theState.quarterlyCountHex,
			theState.ownerCountHex,
			theState.branchName)
	const newAdminGroupScriptPubKey = agspk.scriptPubKey
	stateSnippet = agspk.messagePart

	adminGroupScriptPubKeys[0] = newAdminGroupScriptPubKey
	outputAmounts[0] = oldAmount

	var update1Padding
	var update1Sig
	var userRabinPK

	console.log("\nNow you need to authorize what you're about to do. "
			+	"You'll need to use the owner rabin keys.") //the PKH set in the PREVIOUS/parent tx/post.")
	let keyInfo
	if ( solicitKeys !== null ) {
		keyInfo = solicitKeys(userRabinPKH)
	} else {
		keyInfo = calculateKeyInfo(paramPrivKeyP, paramPrivKeyQ);
	}

	const newNumUsers = theState.userProfiles.length
	const completeContent	= newProfilesBytes
							+ twoByteLE( newNumUsers )
							+ fourByteLE( theState.postNum )   //FIXME: consider incrementing opNum while administering
							+ theState.blockNum                // fourByteLE( theState.currentBlock )   FIXME: inconsistent
							+ stateSnippet			           // startedAtPost, groupName through mode '47'
							+ newNamesBytes                    // (zero-padded to 32 bytes each)

	const update1SigResult = getSigParts(Buffer.from(completeContent, "hex"), keyInfo.rabinPrivKeyP, keyInfo.rabinPrivKeyQ, keyInfo.rabinPubKeyBigInt)
	console.log("For ADMINGROUP, using this rabin pkh: ", userRabinPKH)
	console.log("For ADMINGROUP, signature is: ", update1SigResult.signature)

	update1Padding = update1SigResult.paddingBytes
	update1Sig = int2Asm(update1SigResult.signature)
	userRabinPK = keyInfo.rabinPubKeyLE //publisherRabinPubKeyLE

	const results = await executeAdminGroupContract(specifiedPrivateKey,
								previousTxid,
								oldScriptPubKey, oldAmount,
								adminGroupScriptPubKeys, outputAmounts, //smallestAmount,
								payScriptQueue, payoutAmount,

								newBlockInt,   //we ALMOST ignore block. we leave state mostly unchanged
								specifiedBsvChangePKH, //EXT_FUNDING_CHANGE_PKH,

								update1Sig,
								update1Padding,
								userRabinPK,

								numProfilesToAdd,
								theNewProfiles,
								newNamesBytes,
								bitGroupScriptHexString,

								parentOutputIndexToSpend)

	console.log("libBuildOnAdminGroup(): results from ADMINISTERGROUP: ", { txid: results.txid, change: results.change})

	if ( results.txid.startsWith('11111111111111111111111111111111') ) {
		console.log("We've got a bogus tx, we'll save its raw bytes")
		// insert results.rawTx into table for this purpose
		await dbAddRawTx(theDB, results.txid, results.rawTx)
	}

	return results.txid
} //libBuildOnAdminGroup()

/**
 * By validating the Rabin private keys against the target PKH, we can avoid runaway
 * signing operations in cases where we've got the wrong keys.
 *
 * @param {*} p
 * @param {*} q
 * @param {*} targetPKH
 */
//export /* */
function validateRabinPrivKeys(p, q, targetPKH) {
	const calculatedRabinPubKeyBigInt = privKeyToPubKey2(p, q)
	const calculatedRabinPubKeyLE = int2Asm(calculatedRabinPubKeyBigInt)
	const calculatedRabinPubKeyLEBuffer = Buffer.from(calculatedRabinPubKeyLE, 'hex');
	const calculatedRabinPKH = bsv.crypto.Hash.sha256ripemd160(calculatedRabinPubKeyLEBuffer).toString('hex')

	console.log("validateRabinPrivKeys(): calculated PKH is " + calculatedRabinPKH);
	console.log("validateRabinPrivKeys(): required PKH is " + targetPKH);

	return calculatedRabinPKH === targetPKH;
}

//export /* */
function verifyBase64(metaName) {

	var base64Name = Buffer.alloc(metaName.length)
	var allValid = true
	const metaBuff = Buffer.from(metaName)
	for ( var i = 0; i < metaBuff.length; i++ ) {
		if ( metaBuff[i] >= 65 && metaBuff[i] <= 90 ) {
			//console.log("char " + i + " is a CAPITAL letter.")
			base64Name[ i ] = metaBuff[i] - 65
		} else if ( metaBuff[i] >= 97 && metaBuff[i] <= 122 ) {
			//console.log("char " + i + " is a lower-case letter.")
			base64Name[ i ] = metaBuff[i] - 71
		} else if ( metaBuff[i] >= 48 && metaBuff[i] <= 57 ) {
			//console.log("char " + i + " is a digit.")
			base64Name[ i ] = metaBuff[i] + 4
		} else if ( metaBuff[i] === 47 ) {
			//console.log("char " + i + " is a forward slash.")
			base64Name[ i ] = 62
		} else if ( metaBuff[i] === 45 ) {
			//console.log("char " + i + " is a hyphen.")
			base64Name[ i ] = 63
		} else {
			console.log("verifyBase64(): char " + i + " is invalid (others may be as well). It needs to be an alphanumeric character (a-z,A-Z,0-9), or underscore, or hyphen.")
			allValid = false
			console.log("verifyBase64(): character " + i + " (and maybe others) is outside the range of Base-64 characters")
			break;
		}
	}

	return {
		valid: allValid,
		base64: base64Name
	}
}

//export /* */
function calcPayoutFromBlockAndGenesis(currentBlock, genesisBlock, reductionVotes) {
	console.log("calcPayoutFromBlockAndGenesis(): current block: " + currentBlock)
	console.log("calcPayoutFromBlockAndGenesis(): genesis block: " + genesisBlock)
	// Here we halve the payouts over time (blocks), but, we do so in steps.
	// Every DECAY_ITERATION_BLOCKS we take a STEP towards halving.
	// NOTE: When released, DIB might be 1-years' worth of blocks ~52k (or 6 months, with HS of 4)
	// NOTE FOR TESTING: 2 years is 105120, 1 year is 52560, 6 months is 26280
	// decaying payouts (halved every DIB * H_STEPS blocks)
	const DECAY_ITERATION_BLOCKS = 52560;
	//                                       (j)
	// InitP     InitP2        stepNum    sN/HSteps    div
	// 2160,     1527           0, 1         0          1
	// 1080,      763           2, 3         1          2
	//  540,      381           4, 5         2          4
	//  270,      190           6, 7         3          8
	//  135,       95           8, 9         4         16
	//   67,       47          10,11         5         32
	//   33,       23          12,13         6         64
	//   16,       11          14,15         7        128
	//    8,        5          16,17         8        256
	//    4,        2          18,19         9        512
	//    2,        1          20,21        10       1024
	//    1,        0          22,23        11       2048
	//    0         0          24,25        12       4096
	// The number steps to reach halving (ie 2160 -> 1527 -> 1080   is 2 steps to halving)
	//NOTE: when released, might be 4 (if we wanted to halve every 2 years, in steps of 6 months)
	const HALVING_STEPS = 2;
	//NOTE: when released, INITIAL_PAYOUT might be 2160 sats (with MAX_PKH = 10)
	const INITIAL_PAYOUT  = 2160;
	const INITIAL_PAYOUT2 = 1527;   // This is 1/(root 2)   or, 1/(HSth root of 2)
	//NOTE: if HALVING_STEPS were 3, we'd add an INITIAL_PAYOUT3
	const blocksSinceGenesis = currentBlock - genesisBlock;
	console.log("calcPayout(): Blocks since genesis: " + blocksSinceGenesis)
	let stepNum = Math.floor(blocksSinceGenesis / DECAY_ITERATION_BLOCKS);
	console.log("stepNum is " + stepNum + " (for blockHeight)  +  " + reductionVotes + " (votes) = " + (stepNum + reductionVotes))
	stepNum += reductionVotes;
	var payoutAmount = INITIAL_PAYOUT;
	if ( stepNum % HALVING_STEPS === 1 ) {
		payoutAmount = INITIAL_PAYOUT2;
	}  //NOTE: if HALVING_STEPS were 3, we'd add a case here for HALVING_STEPS == 2
	console.log("calcPayout(): initial payout is " + payoutAmount)
	var j = Math.floor(stepNum / HALVING_STEPS);
	console.log("initial value of j: " + j)
	//loop(12) {     //NOTE: enough loops to reach 0
	for ( var i = 0; i < 12; i++ ) {
		if ( j > 0 ) {                          // can we keep state which mentions what was the smallest out?
			payoutAmount = Math.floor(payoutAmount / 2);        // can we pass in the dust amount (and avoid/disIncntvz gaming it)?
			console.log("calcPayout(): j is " + j + ", payout is " + payoutAmount)
			j = j - 1;
		}
	}

	return payoutAmount;
}

function calcMaxContentLenFromBlockAndGenesis(currentBlock, genesisBlock, PERIOD_BLOCKS, INIT_MAX1, INIT_MAX2) {
	console.log("calcMaxContentLenFromBlockAndGenesis(): current block: " + currentBlock)
	console.log("calcMaxContentLenFromBlockAndGenesis(): genesis block: " + genesisBlock)
	// Here we double the maxContentLen over time (blocks), but, we do so in steps.
	// Every PERIOD_BLOCKS we take a STEP towards doubling (we multiple by 1.414).
	// NOTE FOR TESTING: 2 years is 105120, 1 year is 52560, 6 months is 26280

	//                                       (j)
	// Init1     Init 2        stepNum    sN/HSteps    mult
	// 271.5,    384            0, 1         0          1
	// ????,     ???            2, 3         1          2
	//  ???,     ???            4, 5         2          4
	// The number steps to reach doubling (ie 256 -> 362 -> 512  is 2 steps to doubling)
	//NOTE: when released, might be 2 (if we wanted to double every 4 years, in steps of 2 years)

	const blocksSinceGenesis = currentBlock - genesisBlock;
	console.log("calcMaxContentLenFromBlockAndGenesis(): Blocks since genesis: " + blocksSinceGenesis)
	const periods = Math.floor(blocksSinceGenesis / PERIOD_BLOCKS);
	console.log("calcMaxContentLenFromBlockAndGenesis(): periods " + periods)
	var max = INIT_MAX1;
	if ( periods % 2 === 1 ) {
		max = INIT_MAX2;
	}
	console.log("calcMaxContentLenFromBlockAndGenesis(): initial max is " + max)
	var j = Math.floor(periods / 2);
	console.log("calcMaxContentLenFromBlockAndGenesis(): initial value of j (number of doublings): " + j + "\n")

	for ( var i = 0; i < 13; i++ ) {
		if ( j > 0 ) {                          // can we keep state which mentions what was the smallest out?
			max = max * 2;        // can we pass in the dust amount (and avoid/disIncntvz gaming it)?
			console.log("calcMaxContentLenFromBlockAndGenesis():   iteration: j is " + j + ", max is " + max)
			j = j - 1;
		}
	}

	return max;
}

//export /* */
function decomposeRawTx( rawTx, silent=false ) {
	const rawLen = rawTx.length;
	console.log('raw tx has length ', rawLen, " CHARACTERS (half as many bytes)");
	const txVersion = rawTx.substring(0, 8);
	console.log('tx version: ', txVersion);
	const txNumInputsHex = rawTx.substring(8, 10);
	//console.log('tx number of inputs: ', txNumInputs);
	var numInputs = parseInt( txNumInputsHex, 16 );
	console.log('\nNumber of Tx INputs: ', numInputs);

	var cursor = 10
	var extraLen;
	var inScripts = []
	for (var i = 0; i < numInputs; i++) {
		extraLen = 0;
		var thisTxId = rawTx.substring(cursor, cursor + 64);
		console.log('  Input # ', i, '  txId: ', thisTxId);
		var thisOutpointIdx = rawTx.substring(cursor + 64, cursor + 72);
		console.log('    outpointId: ', thisOutpointIdx);
		// varInt length:  https://learnmeabitcoin.com/guide/varint
		var inScriptLenHex = rawTx.substring(cursor + 72, cursor + 74)
		var inScriptLen = parseInt( inScriptLenHex, 16 );
		console.log('    INput script len byte: 0x', inScriptLenHex, ' - ', inScriptLen)
		if ( inScriptLen <= 252 ) {
			console.log('      (single-byte length: ', inScriptLen, ')');
		} else if ( inScriptLen === 253 ) {
			const lenBytesHex1 = rawTx.substring( cursor + 74, cursor + 78 );
			// helpful: https://www.w3schools.com/nodejs/ref_buffer.asp
			const lenBytes1 = Buffer.from(lenBytesHex1, "hex").readUInt16LE(0)
			extraLen += 4;
			console.log('      (3-byte input varInt length bytes: fd ', lenBytesHex1, ')');
			inScriptLen = lenBytes1;
		} else if ( inScriptLen === 254 ) {
			const lenBytesHex1 = rawTx.substring( cursor + 74, cursor + 82 );
			// helpful: https://www.w3schools.com/nodejs/ref_buffer.asp
			const lenBytes1 = Buffer.from(lenBytesHex1, "hex").readUInt32LE(0)
			extraLen += 8;
			console.log('      (5-byte input varInt length bytes: fe ', lenBytesHex1, ')');
			inScriptLen = lenBytes1;
		} else {
			console.log('    Not sure of varInt length: ' + inScriptLen);
			console.log('    We already handle 254 (handling of 4/5-byte varInt lengths)');
			throw new Error('10005');
		}
		//FIXME: include handling of 4-byte length

		inScripts[i] = {
			txId: thisTxId,
			outputIdx: thisOutpointIdx,
			script: rawTx.substring(cursor + 74 + extraLen, cursor + 74 + extraLen + inScriptLen*2)
		}

		console.log('      INPUT script length: ', inScriptLen)
		var inSequence = rawTx.substring(cursor + 74 + inScriptLen*2 + extraLen, cursor + 74 + inScriptLen*2 + extraLen + 8);
		console.log('    input sequence: ', inSequence);
		cursor += 2*37 + extraLen + inScriptLen*2 + 8;  // 2*(txid + 4-byte idx + len) + scriptLen + sequence
		//console.log('  cursor is now ', cursor, '  <--');
	}

	console.log('')

	const txNumOutputsHex = rawTx.substring(cursor, cursor + 2);
	var numOutputs = parseInt( txNumOutputsHex, 16 );
	console.log('Number of Tx OUTputs: ', numOutputs);
	if ( numOutputs > 75 ) {
		throw new Error("CODE INCOMPLETE: the number of inputs requires decoding more than a single byte.")
		// if > 75, but less than 256, expect a prefix '4c'
		// if > 255, but less than 64k, expect a prefix '4d', then 2 bytes
	}

	//FIXME: if this is greater than 75, it will be preceeded by 0x4c
	//       if greater than 255, preceeded by 4d, then TWO bytes
	console.log("\nnote: numOutputs (raw): " + txNumOutputsHex + "\n")
	//console.log("more bytes, FYI: " + rawTx.substring(cursor, cursor + 40))

	cursor += 2; // skip over the numInputs byte (2 chars)

	const justOutputsLen = rawLen - cursor
	console.log("\nNOTE: there are " + justOutputsLen + " bytes remaining that are JUST outputs\n")
	const justOutputs = rawTx.substring(cursor, cursor + justOutputsLen)

	var outScripts = []

	for (var j = 0; j < numOutputs; j++) {
		extraLen = 0;
		var outValueHex = rawTx.substring(cursor, cursor + 16);
		const decimalValue = Buffer.from(outValueHex + "000000", "hex").readUIntLE(0, 6)
		console.log('  Output # ', j, '  value (LE): ', outValueHex, " - decimal " + decimalValue);

		var outScriptLenHex = rawTx.substring(cursor + 16, cursor + 18)
		var outScriptLen = parseInt( outScriptLenHex, 16 );
		if ( !silent ) console.log('    OUTput script len byte: 0x', outScriptLenHex, ' - ', outScriptLen)
		if ( outScriptLen <= 252 ) {
			console.log('      (single-byte length: ', outScriptLen, ')');
		} else if ( outScriptLen === 253 ) {
			const lenBytesHex2 = rawTx.substring( cursor + 18, cursor + 22 );
			// helpful: https://www.w3schools.com/nodejs/ref_buffer.asp
			const lenBytes2 = Buffer.from(lenBytesHex2, "hex").readUInt16LE(0)
			extraLen += 4;
			console.log('      (3-byte output varInt length bytes: fd ', lenBytesHex2, ')');
			outScriptLen = lenBytes2;
		} else if ( outScriptLen === 254 ) {
			const lenBytesHex4 = rawTx.substring( cursor + 18, cursor + 26 );
			// helpful: https://www.w3schools.com/nodejs/ref_buffer.asp
			const lenBytes4 = Buffer.from(lenBytesHex4, "hex").readUInt32LE(0)
			extraLen += 8;
			console.log('      (5-byte output varInt length bytes: fe ', lenBytesHex4, ')');
			outScriptLen = lenBytes4;
		} else {
			console.log('    not sure of length: ');
			throw new Error('10006');
		}

		console.log('      OUTPUT script length: ', outScriptLen)
		//FIXME: maybe convert outValue right here - to decimal
		outScripts[j] = {
				script: rawTx.substring(cursor + 2*9 + extraLen, cursor + 2*9 + extraLen + outScriptLen*2),
				value: outValueHex
		}
		//FIXME: make this last 65 bytes (if enough)
		if ( !silent) console.log('      Last 225 bytes of output script: ', outScripts[j].script.substring(outScriptLen*2-450, outScriptLen*2))
		cursor += 2*9 + extraLen + outScriptLen*2;  // 2*(8-byte value + lenByte) + scriptLen
		//console.log('  cursor is now ', cursor, '  <--');
	}
	console.log('\nThose were the outputs. Now the tx LockTime param...')
	var locktimeLE = rawTx.substring(cursor, cursor + 8);
	const locktime = Buffer.from(locktimeLE, "hex").readUInt32LE(0)
	console.log('\nTx LOCKTIME is ', locktime, '\n')

	return {
		inputs: inScripts,
		outputs: outScripts,
		justOutputs: justOutputs    // perhaps only referenced by utility script?
	}
}

/**
 * returns an ARRAY of chunks
 * @param {*} rawOutputs
 * @param {*} silent
 */
//export /* */
function parseOutputs(rawOutputs, silent) {
	let parsedOutputs = []
	for ( let i = 0; i < rawOutputs.length; i++ ) {
		let chunks = []
		const script = rawOutputs[i].script
		if ( !silent ) console.log("          output #" + i + " has length " + script.length/2)

		let cursor
		console.log("    output " + i + " starts with " + script.substring(0,12))
		//console.log("      output " + i + " has " + outputChunks[i].length + " chunks")
		if ( script.startsWith('006a') || script.startsWith('006A') ) {
			console.log("    OUTPUT " + i + " is an OP_FALSE OP_RETURN output")
			console.log("      first chunk may be length " + script.substring(4,6))
			cursor = 4
		} else if ( script.startsWith('6a') || script.startsWith('6A') ) {
			console.log("    OUTPUT " + i + " is an OP_RETURN output")
			console.log("      first chunk may be length " + script.substring(2,4))
			cursor = 2
		} else {
			console.log("    OUTPUT " + i + " doesn't start with OP_FALSE/OP_RETURN (probabky P2PKH). skipping (taking whole output as chunk).  <---")
			// It's probably a P2PKH
			chunks[0] = script.toString()
			continue
		}

		let chunkNum = 0
		let extraLen
		do {
			if ( !silent ) console.log("          -> Chunk " + chunkNum + " (base-0)")
			extraLen = 0
			var chunkLenHex = script.substring(cursor, cursor + 2)
			var chunkLen = parseInt( chunkLenHex, 16 );
			if ( !silent ) console.log('          OUTput chunk len byte: 0x', chunkLenHex, ' - ', chunkLen)
			if ( chunkLen <= 75 ) {    // <= 0x4b
				if ( !silent ) console.log('            ( single-byte pushdata chunk length: ', chunkLen, ' )');
			} else if ( chunkLen === 76 ) {   // 0x4c
				const lenBytesHex3 = script.substring( cursor + 2, cursor + 4 );
				// helpful: https://www.w3schools.com/nodejs/ref_buffer.asp
				const lenBytes3 = Buffer.from(lenBytesHex3, "hex").readUInt8(0)
				extraLen += 2;
				if ( !silent ) console.log('            ( 2-byte pushdata chunk length bytes: 4c ', lenBytesHex3, ' )');
				chunkLen = lenBytes3;
			} else if ( chunkLen === 77 ) {   // 0x4d
				const lenBytesHex4 = script.substring( cursor + 2, cursor + 6 );
				// helpful: https://www.w3schools.com/nodejs/ref_buffer.asp
				const lenBytes4 = Buffer.from(lenBytesHex4, "hex").readUInt16LE(0)
				extraLen += 4;
				if ( !silent ) console.log('            ( 3-byte pushdata chunk length bytes: 4d ', lenBytesHex4, ' )');
				chunkLen = lenBytes4;
			} else if ( chunkLen === 78 ) {   // 0x4e
				//OP_PUSHDATA4')
				const lenBytesHex8 = script.substring( cursor + 2, cursor + 10 );
				// helpful: https://www.w3schools.com/nodejs/ref_buffer.asp
				const lenBytes8 = Buffer.from(lenBytesHex8, "hex").readUInt32LE(0)
				extraLen += 8;
				if ( !silent ) console.log('            ( 5-byte pushdata chunk length bytes: 8d ', lenBytesHex8, ' )');
				chunkLen = lenBytes8;
			} else if ( chunkLen === 79 ) {   // 0x4f
				console.warn('            FIXME: this is an OP_1NEGATE (-1). Please implement.')
				throw new Error('26008');
			} else if ( chunkLen === 80 ) {   // 0x50
				console.warn('            FIXME: this is an OP_RESERVED. Typically invalid.')
				throw new Error('26009');
			} else if ( 81 <= chunkLen && chunkLen <= 96 ) {
				console.warn('            FIXME: this is an OP_1 through OP_16. Please implement.')
				chunks[ chunkNum ] = (chunkLen - 80)
				//chunkData = (chunkLen - 80)
				console.log("              setting chunkData to " + (chunkLen - 80))
				//chunkLen = 1
				//console.log("        or, set to " + script.substring(cursor + 0 + extraLen, cursor + 0 + chunkLen*2 + extraLen))
				cursor += 2  //FIXME: if we're continue ing, maybe this doesn't matter
				//if ( !silent ) console.log("        Cursor now at " + cursor + " of " + script.length + " (double-length)")
				chunkNum++
				continue
			} else {
				console.warn('        Not sure of pushdata chunk length: ' + chunkLen);
				console.log('        ');
				console.log('        setting this chunk to null. BLINDLY skipping to next output')
				//throw new Error('26010');
				chunks[ chunkNum ] = null  //FIXME: is this doing anything? NOR in parseInputs
				// chunkNum++
				// cursor += 2   // ????

				cursor = script.length // bail out of this output
				continue   // next output
			}
			//FIXME: include handling of 4- and 5-byte chunk length

			//if ( !silent ) console.log("          Input " + i + " chunk " + chunkNum + " has length: " + chunkLen)
			const chunkData = script.substring(cursor + 2 + extraLen, cursor + 2 + chunkLen*2 + extraLen);
			if ( !silent ) console.log("            Chunk data: " + chunkData)

			chunks[ chunkNum ] = chunkData

			cursor += 2 + extraLen + chunkLen*2;  // 2*(txid + 4-byte idx + len) + scriptLen + sequence
			//if ( !silent ) console.log("        Cursor now at " + cursor + " of " + script.length + " (double-length)")
			chunkNum++
		} while ( cursor < script.length )

		parsedOutputs[ i ] = chunks
	} // each rawOuputs[]

	return parsedOutputs
}

/**
 * returns an ARRAY of ARRAY of chunks
 * @param {*} rawInputs
 * @param {*} silent
 */
//export /* */
function parseInputs(rawInputs, silent) {
	let parsedInputs = []
	for ( var i = 0; i < rawInputs.length; i++ ) {
		let chunks = []
		if ( !silent ) console.log("\n    Input #" + i + " is from txId " + rawInputs[i].txId
						+ " output " + rawInputs[i].outputIdx)
		const script = rawInputs[i].script
		if ( !silent ) console.log("        Input #" + i + " has length " + script.length/2)
		//		+ ": " + script + "\n")

		var chunkNum = 0
		var cursor = 0
		var extraLen
		do {
			if ( !silent ) console.log("          -> Chunk " + chunkNum + " (base-0)")
			extraLen = 0
			var chunkLenHex = script.substring(cursor, cursor + 2)
			var chunkLen = parseInt( chunkLenHex, 16 );
			if ( !silent ) console.log('          INput chunk len byte: 0x', chunkLenHex, ' - ', chunkLen)
			if ( chunkLen <= 75 ) {    // <= 0x4b
				if ( !silent ) console.log('            ( single-byte pushdata chunk length: ', chunkLen, ' )');
			} else if ( chunkLen === 76 ) {   // 0x4c
				const lenBytesHex3 = script.substring( cursor + 2, cursor + 4 );
				// helpful: https://www.w3schools.com/nodejs/ref_buffer.asp
				const lenBytes3 = Buffer.from(lenBytesHex3, "hex").readUInt8(0)
				extraLen += 2;
				if ( !silent ) console.log('            ( 2-byte pushdata chunk length bytes: 4c ', lenBytesHex3, ' )');
				chunkLen = lenBytes3;
			} else if ( chunkLen === 77 ) {   // 0x4d
				const lenBytesHex4 = script.substring( cursor + 2, cursor + 6 );
				// helpful: https://www.w3schools.com/nodejs/ref_buffer.asp
				const lenBytes4 = Buffer.from(lenBytesHex4, "hex").readUInt16LE(0)
				extraLen += 4;
				if ( !silent ) console.log('            ( 3-byte pushdata chunk length bytes: 4d ', lenBytesHex4, ' )');
				chunkLen = lenBytes4;
			} else if ( chunkLen === 78 ) {   // 0x4e
				console.log('            this is an OP_PUSHDATA4')
				const lenBytesHex4 = script.substring( cursor + 2, cursor + 10 );
				// helpful: https://www.w3schools.com/nodejs/ref_buffer.asp
				const lenBytes4 = Buffer.from(lenBytesHex4, "hex").readUInt32LE(0)
				extraLen += 8;
				if ( !silent ) console.log('            ( 5-byte pushdata chunk length bytes: 4e ', lenBytesHex4, ' )');
				chunkLen = lenBytes4;
			} else if ( chunkLen === 78 ) {   // 0x4e
				console.log('            FIXME: this is an OP_PUSHDATA4. Please implement.')
				throw new Error('10007');
			} else if ( chunkLen === 79 ) {   // 0x4f
				console.log('            FIXME: this is an OP_1NEGATE (-1). Please implement.')
				throw new Error('10008');
			} else if ( chunkLen === 80 ) {   // 0x50
				console.log('            FIXME: this is an OP_RESERVED. Typically invalid.')
				throw new Error('10009');
			} else if ( 81 <= chunkLen && chunkLen <= 96 ) {
				console.log('            this is an OP_1 through OP_16. Implemented?')
				chunks[ chunkNum ] = (chunkLen - 80)
				//chunkData = (chunkLen - 80)
				console.log("              setting chunkData to " + (chunkLen - 80))
				//chunkLen = 1
				//console.log("        or, set to " + script.substring(cursor + 0 + extraLen, cursor + 0 + chunkLen*2 + extraLen))
				cursor += 2
				//if ( !silent ) console.log("        Cursor now at " + cursor + " of " + script.length + " (double-length)")
				chunkNum++
				continue
			} else {
				console.log('        Not sure of pushdata chunk length: ' + chunkLen);
				console.log('        If it\'s 78, add handling of 4/5-byte pushdata chunk lengths');
				throw new Error('10010');
			}
			//FIXME: include handling of 4- and 5-byte chunk length

			//if ( !silent ) console.log("          Input " + i + " chunk " + chunkNum + " has length: " + chunkLen)
			const chunkData = script.substring(cursor + 2 + extraLen, cursor + 2 + chunkLen*2 + extraLen);
			if ( !silent ) console.log("            Chunk data: " + chunkData)

			chunks[ chunkNum ] = chunkData

			cursor += 2 + extraLen + chunkLen*2;  // 2*(txid + 4-byte idx + len) + scriptLen + sequence
			//if ( !silent ) console.log("        Cursor now at " + cursor + " of " + script.length + " (double-length)")
			chunkNum++
		} while ( cursor < script.length )

		parsedInputs[ i ] = chunks
	}

	return parsedInputs
}

/**
 * Based on the mode of the 0th output (the contract type),
 * we know how to interpret/decode each input chunk.
 */
//export /* */
function getUsefulInputParams(input0Chunks, output0AsciiMode ) {
	const numChunks = input0Chunks.length;
	console.log("getUsefulInputParams(): considering output's mode is " + output0AsciiMode);
	console.log("getUsefulInputParams(): there are " + numChunks + " chunks in input 0");

	// IIRC: we do this since some blank chunks don't show-up.
	// We hold onto numChunks, since these operations will alter input0Chunks.length
	if ( input0Chunks[1] == null ) input0Chunks[1] = ''
	if ( input0Chunks[2] == null ) input0Chunks[2] = ''

	if ( input0Chunks[4] == null ) input0Chunks[4] = ''
	if ( input0Chunks[5] == null ) input0Chunks[5] = ''
	if ( input0Chunks[6] == null ) input0Chunks[6] = ''
	if ( input0Chunks[7] == null ) input0Chunks[7] = ''
	if ( input0Chunks[8] == null ) input0Chunks[8] = ''
	if ( input0Chunks[9] == null ) input0Chunks[9] = ''
	if ( input0Chunks[10] == null ) input0Chunks[10] = ''

	if ( input0Chunks[12] == null ) input0Chunks[12] = ''
	if ( input0Chunks[13] == null ) input0Chunks[13] = ''
	if ( input0Chunks[14] == null ) input0Chunks[14] = ''
	if ( input0Chunks[15] == null ) input0Chunks[15] = ''
	if ( input0Chunks[16] == null ) input0Chunks[16] = ''
	if ( input0Chunks[17] == null ) input0Chunks[17] = ''
	if ( input0Chunks[18] == null ) input0Chunks[18] = ''
	if ( input0Chunks[19] == null ) input0Chunks[19] = ''

	//FIXME: do we want these blank, null, or undefined?
	let unlockedContract = '';
	let txId          = '';
	let txDescription = '';
	let blockNum      = '00000000';     // Dialog. INITIALIZE, for now, so testing/locking txs can be decoded
	let pubKey        = '';             // Dialog. Helps identify WHO wrote the message
	let dialogSenderRabinPKH = '';      // Dialog. DERIVED. Helps identify WHO wrote the message
	let content       = '';
	let contentName   = '';
	let pcode         = '';
	let specialOp     = '';
	let userNum       = '';		// bit group
	let cmd           = '';		// bit group
	let userPost      = '';		// bit group
	let otherUserNum  = '';		// bit group
	let newUserName   = '';     // bit group
	let spawnedGroupName = '';  // bit group
	let numProfilesAdded = '';  // admingroup
	let addedProfiles = '';     // admingroup
	let addedNamesArray = [];     // admingroup
	let tipAmount     = '';
	let tipToUserNum  = '';
	let finalPost     = '';
	let claimingBounty= '';
	let hostGuestMode = '';
	let otherOutputSatsBytes = '';
	let otherOutputBytes     = '';
	let processBitGroup = false;
	let processEbfra    = false;
	let askBidCommand   = '';
	let askBidRgdEntry  = '';
	let spawnSub        = false
	let fundingPubKey   = '';   // Dialog's are setup from guest-posts
	                            // the PubKey used to unlock funds for the guest-post
	switch( output0AsciiMode ) {
		case 'Z': // a bogus mode - used to flag/indicate the funding input
			console.warn("INPUT PARAMETERS come from a P2PKH funding source")
			fundingPubKey = input0Chunks[1]
			break
		case 'X':
			console.log("INPUT PARAMETERS come from an Append tx - IGNORED for now");
			unlockedContract = 'append'
			break
		case 'K':
		case 'P':
			console.log("INPUT PARAMETERS come from an Append tx - but the output is Continue, so, let's look at them.");
			unlockedContract = 'append'
			specialOp     =                   input0Chunks[13];
			break;
		case 'p':
			unlockedContract = 'continue'
			console.log("INPUT PARAMETERS come from a Continue tx");
			pcode         =                   input0Chunks[9];
			txId          =                   input0Chunks[8];
			txDescription = convertFromBase64(input0Chunks[7]);
			content       =                   input0Chunks[6];
			contentName   = convertFromBase64(input0Chunks[5]);
			specialOp     =                   input0Chunks[13];
			break;
		case 'U':
			unlockedContract = 'update'
			console.log("INPUT PARAMETERS come from an Update tx");
			txId          =                   input0Chunks[8];
			txDescription = convertFromBase64(input0Chunks[7]);
			content       =                   input0Chunks[6];
			contentName   = convertFromBase64(input0Chunks[5]);

			finalPost     = input0Chunks[16];
			claimingBounty= input0Chunks[17];
			break;
		case '0':
		case '1':
		case '2':
		case '3':
		case '4':
		case '5':
		case '6':
		case '7':
		case '8':
		case '9':
			unlockedContract = 'transient'
			console.log("INPUT PARAMETERS come from a Transient tx");
			txId          =                   input0Chunks[7];
			txDescription = convertFromBase64(input0Chunks[6]);
			content       =                   input0Chunks[5];
			contentName   = convertFromBase64(input0Chunks[4]);
			spawnSub      = input0Chunks[12].length > 0;
			finalPost     = input0Chunks[13];
			claimingBounty= input0Chunks[14];

			// we now have a dialogScriptString argument at [15]
			hostGuestMode        = input0Chunks[16];
			otherOutputSatsBytes = input0Chunks[17];
			otherOutputBytes     = input0Chunks[18];

			console.warn("Since this is a Transient, we'll only look at chunks 4-7, and 12-18 (base-0). FIXME: look at [19] too?");

			break;
		case 'N':
			unlockedContract = 'askbid'
			console.error("IMPLEMENT ME: INPUT PARAMETERS come from an AskBid tx - IGNORED for now");
			askBidCommand 	= input0Chunks[5]
			askBidRgdEntry	= input0Chunks[7]
			console.log("AskBid command: " + askBidCommand)
			console.log("AskBid regarding entry: " + askBidRgdEntry)
			break;
		case 'G':
			console.log("INPUT PARAMETERS come from (unlock) a BitGroup OR an AdminGroup tx <---");
			if ( numChunks < 12 ) {
				unlockedContract   = 'adminGroup'
				console.log("  Since there are " + numChunks
							+ " input chunks they, must come from an AdminGroup tx");
				numProfilesAdded   = input0Chunks[6];
				addedProfiles      = input0Chunks[7];
				let addedNamesBlob = input0Chunks[8];

				// input chunk values of 0 don't show-up
				if ( !numProfilesAdded )        { numProfilesAdded        = 0 }

				// trim trailing 00s from user names - from a blob of 32 chars (zero-padded) for each
				for ( var i = 0; i < numProfilesAdded; i++ ) {
					for( var j = 0; j < 64; j+=2 ) {
						if ( addedNamesBlob.substr(i*32*2+j, 2) === '00' ) {
							//console.log("  string " + i + " char " + j + " is 00")
							break;
						} else {
							//console.log("  string " + i + " char " + j + " is "
							//		+ addedNamesBlob.substr(i*32*2 + j, 1))
						}
					}

					const rawNameHex = addedNamesBlob.substr(i*32*2, j);
					//console.log("  rawName: " + rawNameHex + " len " + rawNameHex.length);
					addedNamesArray[i] = hexStringToAscii(rawNameHex);
					console.log("Adding name: " + addedNamesArray[i] + " <----");
					console.log("IMPLEMENT ME: opportunity to take note of these new users' names - enter into DB")
				}
			} else {
				processBitGroup = true;
				unlockedContract   = 'bitgroup'
				console.log("  Since there are " + numChunks
							+ " input chunks they, must come from a BitGroup tx");
			}

			break;
		case 'g':
			processBitGroup = true;
			unlockedContract   = 'bitgroup'
			console.log("INPUT PARAMETERS come from a BitGroup tx");
			break;
//fiXME: specify the unlockedContract?  for e, E, amd g, G/else{} above
		case 'e':
		case 'E':
			unlockedContract   = 'ebfra'
			processEbfra = true;
			console.log("INPUT parameters come from an EBFRA tx")
			const ebfraCommand	= input0Chunks[4]
			const builderSig    = input0Chunks[5]
			const sigPadding    = input0Chunks[6]
			const builderPubKey = input0Chunks[7]
			console.log("  ebfra command: " + ebfraCommand)
			console.log("  ebfra builderSig: " + builderSig)
			console.log("  ebfra sigPadding: '" + sigPadding + "'")
			console.log("  ebfra builderPubKey (hex): " + builderPubKey)
			break;
		case 'b':
			unlockedContract   = 'builderPrep'
			console.log("INPUT parameters come from a BuilderPrep tx")
			break;
		case 'D':
			unlockedContract   = 'dialog'
			console.warn("INPUT parameters come from a Dialog tx")
			content       =                   input0Chunks[4];
			blockNum      =                   input0Chunks[1];
			pubKey        =                   input0Chunks[7];
			finalPost     =                   input0Chunks[8];

			const dialogPubKeyLEBuffer = Buffer.from(pubKey, 'hex');
			dialogSenderRabinPKH = bsv.crypto.Hash.sha256ripemd160(dialogPubKeyLEBuffer).toString('hex')
			console.log('getUsefulInputParams(): BTW sender rabinPKH is ' + dialogSenderRabinPKH)
			break;
		default:
			throw new Error("15506: Unrecognized mode: " + output0AsciiMode);
	}
	if ( processBitGroup ) {
		unlockedContract = 'bitgroup'

		userPost         = input0Chunks[2];
		cmd              = input0Chunks[1];
		userNum          = input0Chunks[6];
		otherUserNum     = input0Chunks[10];
		newUserName      = hexStringToAscii( input0Chunks[14] );
		spawnedGroupName = input0Chunks[17];       // added 1 since adding in[14]  (newUserName)

		const tipAmt     = input0Chunks[15];       // added 1 since adding in[14]  (newUserName)
		tipToUserNum     = input0Chunks[16];       // added 1 since adding in[14]  (newUserName)
		finalPost        = input0Chunks[18];       // added 1 since adding in[14]  (newUserName)
		claimingBounty   = input0Chunks[19];       // added 1 since adding in[14]  (newUserName)

		// input chunk values of 0 don't show-up
		if ( !userNum )        { userNum        = 0 }
		if ( !otherUserNum )   { otherUserNum   = 0 }
		if ( !tipToUserNum )   { tipToUserNum   = 0 }
		if ( !finalPost )      { finalPost      = 0 }
		if ( !claimingBounty ) { claimingBounty = 0 }

		//FIXME: if blank, tipAmount will be undefined
		const amtLen = tipAmt.length;
		tipAmount = Buffer.from(tipAmt, "hex").readUIntLE(0,amtLen/2); //FIXME: better way?
		console.log("tip amount of " + tipAmt + " (hex LE) is " + tipAmount + " decimal")
	}

	console.log('fundingPubKey:       ' + fundingPubKey)
	console.log('spawnSub:            ' + spawnSub)
	console.log('unlockedContract:    ' + unlockedContract)
	console.log('dialogBlockNum (dialog): ' + blockNum)
	console.log('dialogPubKey:            ' + pubKey)
	console.log('dialogSenderRabinPKH:    ' + dialogSenderRabinPKH + " (calculated from dialogPubKey ^)")
	console.log('contentName(ascii):  ' + contentName)
	console.log('content:             ' + content)
	console.log('    content (ascii): ' + hexStringToAscii(content))
	console.log('txDescription(ascii):' + txDescription)
	console.log('txId:                ' + txId)
	console.log('pcode:               ' + pcode)
	console.log('specialOp:           ' + specialOp)

	console.log('askBidCommand:       ' + askBidCommand)
	console.log('askBidRgdEntry:      ' + askBidRgdEntry)

	console.log('bgCmd:               ' + cmd)
	console.log('bgPost:              ' + userPost)
	console.log('bgUser:              ' + userNum)         //FIXME: int 0 doesn't show
	console.log('bgOtherUser:         ' + otherUserNum)    //FIXME: int 0 doesn't show
	console.log('bgNewUserName:       ' + newUserName)
	console.log('bgSpawnedGroup:      ' + spawnedGroupName)
	console.log('bgNumProfilesAdded:  ' + numProfilesAdded)
	console.log('bgAddedProfiles:     ' + addedProfiles)
	console.log('bgAddedNames:        ' + addedNamesArray)
	console.log('bgTipAmount:         ' + tipAmount)
	console.log('bgTipToUserNum:      ' + tipToUserNum)

	console.log('finalPost:           ' + finalPost)
	console.log('claimingBounty:      ' + claimingBounty)
	console.log('hostGuestMode:       ' + hostGuestMode)
	console.log('otherOutputSatsBytes:' + otherOutputSatsBytes)
	console.log('otherOutputBytes:    ' + otherOutputBytes)

	return {
		unlockedContract: unlockedContract,
		contentName:    contentName,
		content:        content,
		txDescription:  txDescription,
		txId:           txId,
		pcode:          pcode,
		specialOp:      specialOp,

		dialogBlockNum:     blockNum,
		dialogPubKey:       pubKey,
		dialogSenderRabinPKH: dialogSenderRabinPKH,
		bgCmd:              cmd,
		bgPost:             userPost,
		bgUser:             userNum,
		bgOtherUser:        otherUserNum,
		bgNewUserName:      newUserName,
		bgSpawnedGroup:     spawnedGroupName,
		bgNumProfilesAdded: numProfilesAdded,
		bgAddedProfiles:    addedProfiles,
		bgAddedNames:       addedNamesArray,
		bgTipAmount:        tipAmount,
		bgTipToUserNum:     tipToUserNum,
		finalPost:          finalPost,
		claimingBounty:     claimingBounty,

		hostGuestMode:      hostGuestMode,
		otherOutputSatsBytes: otherOutputSatsBytes,
		otherOutputBytes:   otherOutputBytes,

		spawnSub:           spawnSub,
		fundingPubKey:      fundingPubKey,

		askBidCommand:      askBidCommand,
		askBidRegardingEntry: askBidRgdEntry
	}
}

//export /* */
/**
 * NORMALLY only called by qFDTx(), but **sometimes** called by simpleDecode()
 *
 * @param {*} outScript
 * @param {*} satoshis
 * @param {*} silent
 * @param {*} input0Chunks
 * @returns
 */
function decodeScriptsToState(outScript, satoshis, silent, input0Chunks) {

	// branch-out based on mode (logic for different contracts)
	const out0Len = outScript.length
	const modeHex = outScript.substring(out0Len-2, out0Len);
	if (!silent) { console.log('mode is: ', modeHex); }

	if ( modeHex !== '4e' && modeHex !== '4E'	// N (ask/bid)
		&& modeHex !== '62'					// b (builderPrep)
		&& modeHex !== '58'					// X (append/multi1Pub)
		&& modeHex !== '50'					// P (pub output from multi1Pub)
		&& modeHex !== '70'					// p
		&& modeHex !== '4b' && modeHex !== '4B'	// K (pre-claim from multi1Pub)
		&& modeHex !== '30'
		&& modeHex !== '31'					// (ascii '1') T (Transient). Used to be '54'
		&& modeHex !== '32'
		&& modeHex !== '33'
		&& modeHex !== '34'
		&& modeHex !== '35'
		&& modeHex !== '36'
		&& modeHex !== '37'
		&& modeHex !== '38'
		&& modeHex !== '39'
		&& modeHex !== '55'					// U (update)
		&& modeHex !== '47'					// G (BitGroup)
		&& modeHex !== '67'                 // g (AdministerGroup)
		&& modeHex !== '65' && modeHex !== '45'   // e/E (EBFRA)
		&& modeHex !== '44' ) {      // D (Dialog)
		console.error('\nUnhandled mode: ' + modeHex);
		throw new Error('10092');
	}

	if ( modeHex === '62' ) {
		return decodeBuilderPrepScriptToState(outScript, satoshis, silent)
	} else if ( modeHex === '4e' || modeHex === '4E' ) {
		return decodeAskBidScriptToState(outScript, satoshis, silent)
	} else if ( modeHex === '58' || modeHex === '50' || modeHex === '4b' || modeHex === '4B') {
		// X, P, or K
		return decodeAppendScriptToState(outScript, satoshis, silent, input0Chunks)
	} else if ( modeHex === '65' ) {
		return decodeEbfraSpeakerScriptToState(outScript, satoshis, silent, input0Chunks)
	} else if ( modeHex === '45' ) {
		return decodeEbfraAnnouncementScriptToState(outScript, satoshis, silent, input0Chunks)
	} else if ( modeHex === '70' ) { // p
		return decodeContinueScriptsToState(outScript, satoshis, silent, input0Chunks)
	} else if ( modeHex === '30' || modeHex === '31' || modeHex === '32' || modeHex === '33' ||
		modeHex === '34' || modeHex === '35' || modeHex === '36' ||
		modeHex === '37' || modeHex === '38' || modeHex === '39' ) { // ascii '0' through '9' Transient
		return decodeTransientScriptsToState(outScript, satoshis, silent)
	} else if ( modeHex === '55' ) { // U
		return decodeUpdateScriptsToState(outScript, satoshis, silent)
	} else if ( modeHex === '47' ) { // G
		return decodeBitGroupScriptsToState(outScript, satoshis, silent, false)
	} else if ( modeHex === '67' ) { // g
		return decodeBitGroupScriptsToState(outScript, satoshis, silent, true)
	} else if ( modeHex === '44' ) { //D
		return decodeDialogScriptsToState(outScript, satoshis, false)
	}
}

function decodeAskBidScriptToState(outScript, satoshis, silent) {

	const out0Len = outScript.length
	const modeHex = outScript.substring(out0Len-2, out0Len);
	const subModeHex = outScript.substring(out0Len - 4, out0Len - 2);
	if ( !silent ) console.log('subMode: ', subModeHex);

	const namePathLenHex = outScript.substring(out0Len - 6 , out0Len - 4);
	if ( !silent ) console.log('namePathLen: ', namePathLenHex);
	const namePathLen = Buffer.from(namePathLenHex, "hex").readUInt8(0)
	if ( !silent ) console.log('actual namePathLen: ', namePathLen);
	//FIXME: here we might start to parse the tx, beginning at the end

	const namePath = outScript.substring(out0Len - 6 - 2*namePathLen, out0Len - 6)
	if ( !silent ) console.log('namePath: ', namePath)

	const ownerCountRIdx     = 6 + 2*namePathLen + 2;
	const quarterlyCountRIdx = ownerCountRIdx + 4;

	const amountRIdx = quarterlyCountRIdx + 4;  //6 + 2*namePathLen + 4;
	const opsCounterRIdx = amountRIdx + 4;
	const blockNumRIdx = opsCounterRIdx + 8;
	const deadlineBlockRIdx = blockNumRIdx + 8;
	const mainLineOwnerRabinPkhRIdx = deadlineBlockRIdx + 40;
	const sellerRabinPkhRIdx = mainLineOwnerRabinPkhRIdx + 40;
	const askingPriceRIdx = sellerRabinPkhRIdx + 10; //FIXME: finish extracting
	const worstBidNumRIdx = askingPriceRIdx + 2;
	const bestBidNumRIdx = worstBidNumRIdx + 2;
	const sumOfAllFundsRIdx = bestBidNumRIdx + 16; //FIXME: use this
	const beforeBlockRIdx = sumOfAllFundsRIdx + 8;
	const authCodePaddingLenRIdx = beforeBlockRIdx + 4;
	const authCodeLenRIdx = authCodePaddingLenRIdx + 2;
	const mainLineRabPubKeyLenRIdx = authCodeLenRIdx + 2;
	const numBidsRIdx = mainLineRabPubKeyLenRIdx + 2;
	const numBalksRIdx = numBidsRIdx + 4;

	const ownerCountHex =     outScript.substring(out0Len - ownerCountRIdx, out0Len - ownerCountRIdx + 2)
	const ownerCount    =     Buffer.from(ownerCountHex, "hex").readUInt8(0)
	const quarterlyCountHex = outScript.substring(out0Len - quarterlyCountRIdx, out0Len - quarterlyCountRIdx + 4)
	const quarterlyCount    = Buffer.from(quarterlyCountHex, "hex").readUInt16LE(0)

	const amountHex = outScript.substring(out0Len - amountRIdx, out0Len - amountRIdx + 4)
	const amount = Buffer.from(amountHex, "hex").readUInt16LE(0)
	if ( !silent ) console.log('amountHex: ', amountHex, ', or ', amount)

	const opsCounterHex = outScript.substring(out0Len - opsCounterRIdx, out0Len - opsCounterRIdx + 4)
	const opsCounter = Buffer.from(opsCounterHex, "hex").readUInt16LE(0)
	if ( !silent ) console.log('opsCounterHex: ', opsCounterHex, ', or ', opsCounter)

	const blockNumHex = outScript.substring(out0Len - blockNumRIdx, out0Len - blockNumRIdx + 8)
	const blockNum = Buffer.from(blockNumHex, "hex").readUInt32LE(0)
	if ( !silent ) console.log('blockNumHex: ', blockNumHex, ', or ', blockNum)

	const deadlineBlockHex = outScript.substring(out0Len - deadlineBlockRIdx, out0Len - deadlineBlockRIdx + 8)
	const deadlineBlock = Buffer.from(deadlineBlockHex, "hex").readUInt32LE(0)
	if ( !silent ) console.log('deadlineBlockHex: ', deadlineBlockHex, ', or ', deadlineBlock)

	const mainLineOwnerRabinPKH = outScript.substring(out0Len - mainLineOwnerRabinPkhRIdx, out0Len - mainLineOwnerRabinPkhRIdx + 40)
	if ( !silent ) console.log('mainLineOwnerRabinPKH: ', mainLineOwnerRabinPKH)

	const sellerRabinPKH = outScript.substring(out0Len - sellerRabinPkhRIdx, out0Len - sellerRabinPkhRIdx + 40)
	if ( !silent ) console.log('sellerRabinPKH: ', sellerRabinPKH)

	const askingPriceHex = outScript.substring(out0Len - askingPriceRIdx, out0Len - askingPriceRIdx + 10)
	const askingPrice = Buffer.from(askingPriceHex + "000000", "hex").readUInt16LE(0)
	if ( !silent ) console.log('askingPriceHex: ', askingPriceHex, ', or FIXME', askingPrice)

	const worstBidNumHex = outScript.substring(out0Len - worstBidNumRIdx, out0Len - worstBidNumRIdx + 2)
	const worstBidNum = Buffer.from(worstBidNumHex, "hex").readUInt8(0)
	if ( !silent ) console.log('worstBidNumHex: ', worstBidNumHex, ', or ', worstBidNum)

	const bestBidNumHex = outScript.substring(out0Len - bestBidNumRIdx, out0Len - bestBidNumRIdx + 2)
	const bestBidNum = Buffer.from(bestBidNumHex, "hex").readUInt8(0)
	if ( !silent ) console.log('bestBidNumHex: ', bestBidNumHex, ', or ', bestBidNum)

//FIXME: sumOfAllFunds - we only use/read 6 bytes
	const sumOfAllFundsHex = outScript.substring(out0Len - sumOfAllFundsRIdx, out0Len - sumOfAllFundsRIdx + 16)
	const sumOfAllFunds = Buffer.from(sumOfAllFundsHex + "000000", "hex").readUIntLE(0, 6)
	if ( !silent ) console.log('sumOfAllFundsHex: ', sumOfAllFundsHex, ', or FIXME', sumOfAllFunds)

	const beforeBlockHex = outScript.substring(out0Len - beforeBlockRIdx, out0Len - beforeBlockRIdx + 8)
	const beforeBlock = Buffer.from(beforeBlockHex, "hex").readUInt32LE(0)
	if ( !silent ) console.log('beforeBlockHex: ', beforeBlockHex, ', or ', beforeBlock)

	const authCodePaddingLenHex = outScript.substring(out0Len - authCodePaddingLenRIdx, out0Len - authCodePaddingLenRIdx + 4)
	const authCodePaddingLen = Buffer.from(authCodePaddingLenHex, "hex").readUInt16LE(0)
	if ( !silent ) console.log('authCodePaddingLenHex: ', authCodePaddingLenHex, ', or ', authCodePaddingLen)

	const authCodeLenHex = outScript.substring(out0Len - authCodeLenRIdx, out0Len - authCodeLenRIdx + 2)
	const authCodeLen = Buffer.from(authCodeLenHex, "hex").readUInt8(0)
	if ( !silent ) console.log('authCodeLenHex: ', authCodeLenHex, ', or ', authCodeLen)

	const mainLineRabPubKeyLenHex = outScript.substring(out0Len - mainLineRabPubKeyLenRIdx, out0Len - mainLineRabPubKeyLenRIdx + 2)
	const mainLineRabPubKeyLen = Buffer.from(mainLineRabPubKeyLenHex, "hex").readUInt8(0)
	if ( !silent ) console.log('mainLineRabPubKeyLenHex: ', mainLineRabPubKeyLenHex, ', or ', mainLineRabPubKeyLen)

	const numBidsHex = outScript.substring(out0Len - numBidsRIdx, out0Len - numBidsRIdx + 2)
	const numBids = Buffer.from(numBidsHex, "hex").readUInt8(0)
	if ( !silent ) console.log('numBidsHex: ', numBidsHex, ', or ', numBids)

	const numBalksHex = outScript.substring(out0Len - numBalksRIdx, out0Len - numBalksRIdx + 4)
	const numBalks = Buffer.from(numBalksHex, "hex").readUInt16LE(0)
	if ( !silent ) console.log('numBalksHex: ', numBalksHex, ', or ', numBalks)


	const authCodePaddingRIdx = numBalksRIdx + 2 * authCodePaddingLen;
	const authCodeRIdx = authCodePaddingRIdx + 2 * authCodeLen;
	const mainLineRabPubKeyRIdx = authCodeRIdx + 2 * mainLineRabPubKeyLen;

	const BID_ENTRY_LEN = (5 + 20 + 20 + 4 + 5) * 2;
	const BALK_ENTRY_LEN = (20 + 4 + 5) * 2;
	const bidEntriesRIdx = mainLineRabPubKeyRIdx + numBids * BID_ENTRY_LEN;
	const balkEntriesRIdx = bidEntriesRIdx +  numBalks * BALK_ENTRY_LEN;

	const authCodePadding = outScript.substring(out0Len - authCodePaddingRIdx, out0Len - authCodePaddingRIdx + authCodePaddingLen*2)
	if ( !silent ) console.log('authCodePadding: \'' + authCodePadding + '\'');

	const authCode = outScript.substring(out0Len - authCodeRIdx, out0Len - authCodeRIdx + authCodeLen*2)
	if ( !silent ) console.log('authCode: \'' + authCode + '\'');

	const mainLineRabPubKey = outScript.substring(out0Len - mainLineRabPubKeyRIdx, out0Len - mainLineRabPubKeyRIdx + mainLineRabPubKeyLen*2)
	if ( !silent ) console.log('mainLineRabPubKey: \'' + mainLineRabPubKey + '\'');

	var bids = []
	for (var i = 0; i < numBids; i++) {
		var bidEntryBytes = outScript.substring(out0Len - bidEntriesRIdx + i * BID_ENTRY_LEN,
										out0Len - bidEntriesRIdx + (i+1) * BID_ENTRY_LEN);
		var bidPriceHex        = bidEntryBytes.substring(0, 2 * PRICE_LEN);
		var bidRabinPKH        = bidEntryBytes.substring(2*PRICE_LEN, 2*(PRICE_LEN + PKH_LEN))
		var bidRefundPKH       = bidEntryBytes.substring(2*(PRICE_LEN + PKH_LEN), 2*(PRICE_LEN + 2*PKH_LEN))
		var bidEarliestRefundBlockHex = bidEntryBytes.substring(2*(PRICE_LEN + 2*PKH_LEN), 2*(PRICE_LEN + 2*PKH_LEN + BLOCKNUM_SIZE))
		var bidReserveFundsHex = bidEntryBytes.substring(2*(PRICE_LEN + 2*PKH_LEN + BLOCKNUM_SIZE), 2*(2*PRICE_LEN + 2*PKH_LEN + BLOCKNUM_SIZE))

		bids[i] = {
			price: Buffer.from(bidPriceHex, 'hex').readUIntLE(0, 5),
			rabinPKH: bidRabinPKH,
			refundPKH: bidRefundPKH,
			earliestRefundBlock: Buffer.from(bidEarliestRefundBlockHex, 'hex').readUInt32LE(0),
			reserveFunds: Buffer.from(bidReserveFundsHex, 'hex').readUIntLE(0, 5)
		}
		if ( !silent ) console.log('bids['+i+'] = ', bids[i])
	}

	var balks = []
	for (var j = 0; j < numBalks; j++) {
		var balkEntryBytes = outScript.substring(out0Len - balkEntriesRIdx + j * BALK_ENTRY_LEN,
													out0Len - balkEntriesRIdx + (j+1) * BALK_ENTRY_LEN);
		var balkRefundPKH              = balkEntryBytes.substring(0, 2 * PKH_LEN);
		var balkEarliestRefundBlockHex = balkEntryBytes.substring(2 * PKH_LEN, 2 * (PKH_LEN + BLOCKNUM_SIZE))
		var balkFundsHex               = balkEntryBytes.substring(2 * (PKH_LEN + BLOCKNUM_SIZE), 2 * (PKH_LEN + BLOCKNUM_SIZE + PRICE_LEN))

		balks[j] = {
			refundPKH: balkRefundPKH,
			earliestRefundBlock: Buffer.from(balkEarliestRefundBlockHex, 'hex').readUInt32LE(0),
			funds: Buffer.from(balkFundsHex, 'hex').readUIntLE(0,5)
		}
		if ( !silent ) console.log('balks['+j+'] = ', balks[j])
	}

	// Extract the complete 'state' hex string
	// This will be useful for building on this transaction
	const stateStartIdx = out0Len - balkEntriesRIdx
	const stateHex = outScript.substring(stateStartIdx, out0Len)

	// get Sha256 hash of output script
	const outScriptHash = bsv.crypto.Hash.sha256( Buffer.from(outScript, 'hex') ).toString('hex');
	if ( !silent ) console.log('    hash of output script: ' + outScriptHash);
	if ( !silent ) console.log('    (flip to get \'scriptHash\'' + swapEndian(outScriptHash) + ')')
	// TODO: see this for list of unspent UTXOs for this scriptHash:
	//       https://developers.whatsonchain.com/#get-script-unspent-transactions

	const address = 'bshz://' + hexStringToAscii(namePath)
						+ '/' + String(ownerCount).padStart(3, '0')
						+ '/' + String(quarterlyCount).padStart(5, '0')
						+ '/A/' + String(opsCounter).padStart(5, '0')

	return {
		balkEntries: balks,
		bidEntries: bids,
		mainLineRabPubKey: mainLineRabPubKey,
		authCode: authCode,
		authCodePadding: authCodePadding,
		numBalks: numBalks,
		numBids: numBids,
		mainLineRabPubKeyLen: mainLineRabPubKeyLen,
		authCodeLen: authCodeLen,
		authCodePaddingLen: authCodePaddingLen,
		beforeBlock: beforeBlock,
		sumOfAllFunds: sumOfAllFunds,
		bestBidNum: bestBidNum,
		worstBidNum: worstBidNum,
		askingPrice: askingPrice,
		sellerRabinPKH: sellerRabinPKH,
		mainLineRabinPKH: mainLineOwnerRabinPKH,//FIXME: be consistent - owner/seller
		deadlineBlock: deadlineBlock,
		blockNum: blockNumHex, //blockNum,
		blockNumInt: blockNum, //Buffer.from(blockNum, "hex").readUInt32LE(0),
		opsCounter: opsCounter,
		smallestAmount: amount,

		quarterlyCount: quarterlyCount,
		quarterlyCountHex: quarterlyCountHex,
		ownerCount: ownerCount,
		ownerCountHex: ownerCountHex,

		address: address,

		namePath: namePath,
		subMode: subModeHex,
		mode: modeHex,
		contract: CONTRACT_SELL,

		scriptHash: swapEndian( outScriptHash ),
		stateHex: stateHex,
		contractSatoshis: satoshis
	}
	//FIXME: fix conversion of 5-byte and 8-byte fields
	//FIXME: maybe perform mode-based parsing
	//FIXME: pack results
} // decodeAskBidScriptToState()

// decodeBuilderPrepScriptToState
function decodeBuilderPrepScriptToState(outScript, satoshis, silent, input0Chunks) {

	if (!silent) console.log("\nThis output is part of an BuilderPrep script/tx")
	const out0Len = outScript.length
	const modeHex = outScript.substring(out0Len-2, out0Len);
	if (!silent)console.log('mode: \'' + hexByteToAscii(modeHex) + '\'');

	// should be blank
	const namePathLenHex = outScript.substring(out0Len - 4 , out0Len - 2);
	if (!silent)console.log('namePathLen: ', namePathLenHex);
	const namePathLen = Buffer.from(namePathLenHex, "hex").readUInt8(0)
	if (!silent) console.log('actual namePathLen: ', namePathLen);

	const namePath = outScript.substring(out0Len - 4 - 2*namePathLen, out0Len - 4)
	if (!silent) console.log('namePath: "' + namePath + '"')

	const amountRIdx = 4 + 2*namePathLen + 4;
	const blockNumRIdx = amountRIdx + 8;
	const genesisBlockRIdx = blockNumRIdx + 8;


	// This helps us avoid dust outputs
	const amountHex = outScript.substring(out0Len - amountRIdx, out0Len - amountRIdx + 4)
	const amount = Buffer.from(amountHex, "hex").readUInt16LE(0)
	if (!silent) console.log('amount: ', amountHex, ', or ', amount)

	const blockNumHex = outScript.substring(out0Len - blockNumRIdx, out0Len - blockNumRIdx + 8)
	const blockNum = Buffer.from(blockNumHex, "hex").readUInt32LE(0)
	if (!silent) console.log('blockNum: ', blockNumHex, ', or ', blockNum)

	const genesisBlockHex = outScript.substring(out0Len - genesisBlockRIdx, out0Len - genesisBlockRIdx + 8)
	const genesisBlock = Buffer.from(genesisBlockHex, "hex").readUInt32LE(0)
	if (!silent) console.log('genesisBlock: ', genesisBlockHex, ', or ', genesisBlock)


	// The number of Builder PKHs depends on how long the namePath is
	// Each character was added by a BUILDER which also added his PKH
	// But, we limit it to MAX_PKHS
	//NOTE: the number of builer payout PKHs USED TO BE related to the length of the namePath
	// It's now simply MAX_PKHS

	const builderCountRIdx = genesisBlockRIdx + 2   // one-byte builder count
	const builderCountHex = outScript.substring(out0Len - builderCountRIdx , out0Len - genesisBlockRIdx);
	if (!silent)console.log('builderCountHex: ', builderCountHex);
	const builderCount = Buffer.from(builderCountHex, "hex").readUInt8(0)
	if (!silent) console.log('actual builderCount (decimal): ', builderCount);

	var numPKHs = builderCount //MAX_PKHS

	const pkhListRIdx    = builderCountRIdx + 2 * (2*PKH_LEN + 1) * numPKHs


	if (!silent) console.log("# of builders: " + numPKHs)  //FIXME: rename Builders
	var pkhs = []
	var rabins = []
	var votes = []
	let tally = []
	for (var i = 0; i < numPKHs; i++ ) {
		pkhs[i] = outScript.substring(   out0Len - pkhListRIdx + i * 2*(2*PKH_LEN + 1),
										 out0Len - pkhListRIdx + i * 2*(2*PKH_LEN + 1) + 2*PKH_LEN);
		rabins[i] = outScript.substring( out0Len - pkhListRIdx + i * 2*(2*PKH_LEN + 1) + 2*PKH_LEN,
										 out0Len - pkhListRIdx + i * 2*(2*PKH_LEN + 1) + 4*PKH_LEN);
		votes[i] = outScript.substring(  out0Len - pkhListRIdx + i * 2*(2*PKH_LEN + 1) + 4*PKH_LEN,
										 out0Len - pkhListRIdx + i * 2*(2*PKH_LEN + 1) + 4*PKH_LEN + 2);
		if ( votes[i] !== '00' ) {
			tally++
			console.warn("vote tally now: " + tally + " (and should be zero, anyway, at this stage)")
		}
	}

	const stateStartIdx = out0Len - pkhListRIdx
	console.warn("  Setting start of state at pkhList (builderList)")

	console.warn("  Decoding mode " + hexByteToAscii(modeHex))

	// Extract the complete 'state' hex string
	// This will be useful for building on this transaction
	const stateHex = outScript.substring(stateStartIdx, out0Len)

	// get Sha256 hash of output script
	const outScriptHash = bsv.crypto.Hash.sha256( Buffer.from(outScript, 'hex') ).toString('hex');
	if (!silent) console.log('    hash of output script: ' + outScriptHash);
	if (!silent) console.log('    (flip to get \'scriptHash\'' + swapEndian(outScriptHash) + ')')
	// TODO: see this for list of unspent UTXOs for this scriptHash:
	//       https://developers.whatsonchain.com/#get-script-unspent-transactions

	if ( namePathLen === 0 ) {
		if (!silent) console.log("\nThe namePath is currently blank. This must be part of the pre-root of the BuilderPrep tree.")
		return {
			contract: CONTRACT_PREP,
			mode: modeHex,
			namePath: namePath,
			smallestAmount: amountHex,
			blockNum: blockNumHex,
			blockNumInt: Buffer.from(blockNumHex, "hex").readUInt32LE(0),
			genesisBlock: genesisBlockHex,
			builderCount: builderCount,
			builderPKHs: pkhs,
			builderRabins: rabins,
			builderVotes: votes,

			scriptHash: swapEndian( outScriptHash ),
			stateHex: stateHex,
			contractSatoshis: satoshis
		}
	}

	if (!silent) {
		console.log("\nThere are " + namePathLen + " characters in the branch name: '" + hexStringToAscii(namePath) + "'  (should be blank)")
	}

} // decodeBuilderPrepScriptToState


function decodeAppendScriptToState(outScript, satoshis, silent, input0Chunks) {
	if (!silent) console.log("\nThis output is part of an APPEND script/tx")
	const out0Len = outScript.length
	const modeHex = outScript.substring(out0Len-2, out0Len);
	if (!silent)console.log('mode: \'' + hexByteToAscii(modeHex) + '\'');

	const namePathLenHex = outScript.substring(out0Len - 4 , out0Len - 2);
	if (!silent)console.log('namePathLen: ', namePathLenHex);
	const namePathLen = Buffer.from(namePathLenHex, "hex").readUInt8(0)
	if (!silent) console.log('actual namePathLen: ', namePathLen);

	const namePath = outScript.substring(out0Len - 4 - 2*namePathLen, out0Len - 4)
	if (!silent) console.log('namePath: "' + namePath + '"')

	const amountRIdx = 4 + 2*namePathLen + 4;
	const blockNumRIdx = amountRIdx + 8;
	const genesisBlockRIdx = blockNumRIdx + 8;


	// This helps us avoid dust outputs
	const amountHex = outScript.substring(out0Len - amountRIdx, out0Len - amountRIdx + 4)
	const amount = Buffer.from(amountHex, "hex").readUInt16LE(0)
	if (!silent) console.log('amount: ', amountHex, ', or ', amount)

	const blockNumHex = outScript.substring(out0Len - blockNumRIdx, out0Len - blockNumRIdx + 8)
	const blockNum = Buffer.from(blockNumHex, "hex").readUInt32LE(0)
	if (!silent) console.log('blockNum: ', blockNumHex, ', or ', blockNum)

	const genesisBlockHex = outScript.substring(out0Len - genesisBlockRIdx, out0Len - genesisBlockRIdx + 8)
	const genesisBlock = Buffer.from(genesisBlockHex, "hex").readUInt32LE(0)
	if (!silent) console.log('genesisBlock: ', genesisBlockHex, ', or ', genesisBlock)


	// The number of Builder PKHs depends on how long the namePath is
	// Each character was added by a BUILDER which also added his PKH
	// But, we limit it to MAX_PKHS
	//NOTE: the number of builer payout PKHs USED TO BE related to the length of the namePath
	// It's now simply MAX_PKHS
	var numPKHs = MAX_PKHS

	const pkhListRIdx    = genesisBlockRIdx + 2 * (2*PKH_LEN + 1) * numPKHs


	if (!silent) console.log("# of PKHs: " + numPKHs)  //FIXME: rename Builders
	var pkhs = []
	var rabins = []
	var votes = []
	let tally = []
	for (var i = 0; i < numPKHs; i++ ) {
		pkhs[i] = outScript.substring(   out0Len - pkhListRIdx + i * 2*(2*PKH_LEN + 1),
										 out0Len - pkhListRIdx + i * 2*(2*PKH_LEN + 1) + 2*PKH_LEN);
		rabins[i] = outScript.substring( out0Len - pkhListRIdx + i * 2*(2*PKH_LEN + 1) + 2*PKH_LEN,
										 out0Len - pkhListRIdx + i * 2*(2*PKH_LEN + 1) + 4*PKH_LEN);
		votes[i] = outScript.substring(  out0Len - pkhListRIdx + i * 2*(2*PKH_LEN + 1) + 4*PKH_LEN,
										 out0Len - pkhListRIdx + i * 2*(2*PKH_LEN + 1) + 4*PKH_LEN + 2);
		if ( votes[i] !== '00' ) {
			tally++
			console.warn("vote tally now: " + tally)
		}
	}

	var ownerRabinPkh = ''

	var deadlineHex = ''
	//FIXME: hmm. will this be a problem - to later give it a number value?
	var deadline = ''

	var expCounterHex = '00'
	var expCounterDecimal = 0

	var quarterlyCountHex = '0000'
	var quarterlyCount  = 0

	var stateStartIdx
	// pre-claimed namePaths have extra state/info
	if ( modeHex === '4b' || modeHex === '4B' ) { // 'K' pre-claimed
		const pubPkhRIdx     = 2*PKH_LEN + pkhListRIdx
		const deadlineRIdx   = 2*BLOCKNUM_SIZE + pubPkhRIdx
		const expCounterRIdx = 2 + deadlineRIdx
		const quarterlyCountRIdx = 4 + expCounterRIdx

		// publisher PKH
		// renewal deadline
		// exp counter
		// price in sats
		ownerRabinPkh = outScript.substring(out0Len - pubPkhRIdx, out0Len - pubPkhRIdx + 2*PKH_LEN)
		if ( !silent ) console.log('ownerRabinPkh: ', ownerRabinPkh)

		deadlineHex = outScript.substring(out0Len - deadlineRIdx, out0Len - deadlineRIdx + 2*BLOCKNUM_SIZE)
		deadline = Buffer.from(deadlineHex, "hex").readUInt32LE(0)
		if ( !silent ) console.log('deadline: ', deadlineHex, ', or ', deadline)

		expCounterHex = outScript.substring(out0Len - expCounterRIdx, out0Len - expCounterRIdx + 2)
		expCounterDecimal = Buffer.from(expCounterHex, "hex").readUInt8(0)
		if ( !silent ) console.log('ownerCountHex: ', expCounterHex, ', or decimal: ', expCounterDecimal)

		//NOTE: if this is K, it should be '0100'
		quarterlyCountHex = outScript.substring(out0Len - quarterlyCountRIdx , out0Len - quarterlyCountRIdx + 4);
		if ( !silent ) console.log('quarterlyCountHex: ' + quarterlyCountHex);
		quarterlyCount = Buffer.from(quarterlyCountHex, 'hex').readUInt16LE(0)
		if ( !silent ) console.log('quarterlyCount: ' + quarterlyCount);

		stateStartIdx = out0Len - quarterlyCountRIdx
		console.warn("  Setting start of state at quarterly count")
	} else {
		stateStartIdx = out0Len - pkhListRIdx
		console.warn("  Setting start of state at pkhList")
	}

	console.warn("  Decoding mode " + hexByteToAscii(modeHex))

	// Extract the complete 'state' hex string
	// This will be useful for building on this transaction
	const stateHex = outScript.substring(stateStartIdx, out0Len)

	// get Sha256 hash of output script
	const outScriptHash = bsv.crypto.Hash.sha256( Buffer.from(outScript, 'hex') ).toString('hex');
	if (!silent) console.log('    hash of output script: ' + outScriptHash);
	if (!silent) console.log('    (flip to get \'scriptHash\'' + swapEndian(outScriptHash) + ')')
	// TODO: see this for list of unspent UTXOs for this scriptHash:
	//       https://developers.whatsonchain.com/#get-script-unspent-transactions

	if ( namePathLen === 0 ) {
		if (!silent) console.log("\nThe namePath is currently blank. This must be the root of the APPEND tree.")
		return {
			contract: CONTRACT_GROW,
			mode: modeHex,
			//quarterlyCountHex: quarterlyCountHex,
			//quarterlyCount: quarterlyCount,
			namePath: namePath,
			smallestAmount: amountHex,
			blockNum: blockNumHex,
			blockNumInt: Buffer.from(blockNumHex, "hex").readUInt32LE(0),
			genesisBlock: genesisBlockHex,
			builderPKHs: pkhs,
			builderRabins: rabins,
			builderVotes: votes,

			scriptHash: swapEndian( outScriptHash ),
			stateHex: stateHex,
			contractSatoshis: satoshis
		}
	}

	if (!silent) {
		console.log("\nThere are " + namePathLen + " characters in the branch name: '" + hexStringToAscii(namePath) + "'")
	}

	if ( modeHex === '4b' || modeHex === '4B' ) { // 'K' pre-claimed has more info/state
//alert("decodeAppendScriptToState(): decoding mode K ! <------  whuh?")
		const input0Params = getUsefulInputParams(input0Chunks, hexByteToAscii( modeHex ) );
		const address = 'bshz://' + hexStringToAscii(namePath)
							+ '/' + String(expCounterDecimal).padStart(3, '0')
							+ '/' + String(quarterlyCount).padStart(5, '0')
						+ (input0Params.specialOp >= 4 ?   // 1 and 2 are for claiming
								('/vote' + String(tally).padStart(2))
									:
								''
							)

		//WARNING: why aren't we decoding this in decodeContinue...()? <-----
		return {
			contract: CONTRACT_GROW,
			mode: modeHex,
			namePath: namePath,

			smallestAmount: amountHex,
			blockNum: blockNumHex,
			blockNumInt: Buffer.from(blockNumHex, "hex").readUInt32LE(0),
			genesisBlock: genesisBlockHex,
			builderPKHs: pkhs,
			builderRabins: rabins,
			builderVotes: votes,

			ownerRabinPKH: ownerRabinPkh,
			renewalDeadline: deadlineHex,
			renewalDeadlineInt: Buffer.from(deadlineHex, "hex").readUInt32LE(0),

			quarterlyCountHex: quarterlyCountHex,
			quarterlyCount: quarterlyCount,

			ownerCountHex: expCounterHex,
			ownerCount: expCounterDecimal,

			address: address,

			scriptHash: swapEndian( outScriptHash ),
			stateHex: stateHex,
			contractSatoshis: satoshis
		}
	} else { // X, or P
//alert("decodeAppendScriptToState(): decoding mode X or P. and namePath of " + namePath + ", and blockNumHex of " + blockNumHex)
//alert("stateHex: " + stateHex)
		const input0Params = getUsefulInputParams(input0Chunks, hexByteToAscii( modeHex ) );
		const address = 'bshz://' + hexStringToAscii(namePath)
							+ '/' + String(expCounterDecimal).padStart(3, '0')
						+ (input0Params.specialOp >= 4 ?   // 1 and 2 are for claiming
								('/vote' + String(tally).padStart(2, '0'))
									:
								''
							)
		return {
			contract: CONTRACT_GROW,
			mode: modeHex,
			namePath: namePath,

			smallestAmount: amountHex,
			blockNum: blockNumHex,
			blockNumInt: Buffer.from(blockNumHex, "hex").readUInt32LE(0),
			genesisBlock: genesisBlockHex,
			builderPKHs: pkhs,
			builderRabins: rabins,
			builderVotes: votes,

			// Even though a 'Continue' output with mode 'X', or 'P' has no
			// ownerCount, nor quarterly count, logically, they're zero, and
			// an application needs access to these values
			quarterlyCountHex: quarterlyCountHex,
			quarterlyCount: quarterlyCount,

			ownerCountHex: expCounterHex,
			ownerCount: expCounterDecimal,

			address: address,

			scriptHash: swapEndian( outScriptHash ),
			stateHex: stateHex,
			contractSatoshis: satoshis
		}
	}
} // decodeAppendScriptToState

function decodeEbfraSpeakerScriptToState(outScript, satoshis, silent, input0Chunks) {
	if (!silent) console.log("\nThis output is part of an EBFRA Speaker script/tx")
	const out0Len = outScript.length
	const modeIndex = 2
	const modeHex = outScript.substring(out0Len - modeIndex, out0Len);
	if (!silent)console.log('modeHex: ' + modeHex + '   mode: \'' + hexByteToAscii(modeHex) + '\'');



	const namePathLenIndex = modeIndex + 2  // one byte (2 chars) for namePathLen
	const namePathLenHex = outScript.substring(out0Len - namePathLenIndex , out0Len - modeIndex);
	if (!silent)console.log('namePathLen: ', namePathLenHex);
	const namePathLen = Buffer.from(namePathLenHex, "hex").readUInt8(0)
	if (!silent) console.log('actual namePathLen: ', namePathLen);

	const namePathIndex = namePathLenIndex + namePathLen*2
	const namePath = outScript.substring(out0Len - namePathIndex, out0Len - namePathLenIndex)
	if (!silent) console.log('namePath: "' + namePath + '"')



	const builderRabinPKHIndex = namePathIndex + 2*20;
	const builderRabinPKH = outScript.substring(out0Len - builderRabinPKHIndex , out0Len - namePathIndex);
	if (!silent)console.log('builderRabinPKH: ', builderRabinPKH);

	const terminalBlockIndex = builderRabinPKHIndex + 2*4;
	const terminalBlockHex = outScript.substring(out0Len - terminalBlockIndex, out0Len - builderRabinPKHIndex);
	const terminalBlockInt = Buffer.from(terminalBlockHex, "hex").readUInt32LE(0)

	const stateStartIdx = out0Len - terminalBlockIndex
	console.warn("  Setting start of state at terminalBlock")

	console.warn("  EBFRA Speaker - mode " + hexByteToAscii(modeHex))

	// Extract the complete 'state' hex string
	// This will be useful for building on this transaction
	const stateHex = outScript.substring(stateStartIdx, out0Len)

	// get Sha256 hash of output script
	const outScriptHash = bsv.crypto.Hash.sha256( Buffer.from(outScript, 'hex') ).toString('hex');
	if (!silent) console.log('    hash of output script: ' + outScriptHash);
	if (!silent) console.log('    (flip to get \'scriptHash\'' + swapEndian(outScriptHash) + ')')
	// TODO: see this for list of unspent UTXOs for this scriptHash:
	//       https://developers.whatsonchain.com/#get-script-unspent-transactions

//FIXME: maybe we don't need this? hmm.
	const address = 'bshz://' + hexStringToAscii(namePath) + '/ebfraSpeaker'

	return {
		contract: CONTRACT_SPEAKER, // Prepare an EBFRA speaker (may later announce and EBFRA)
		mode: modeHex,

		namePath: namePath,
		builderRabinPKH: builderRabinPKH,
		deadlineInt: terminalBlockInt,

		address: address,

		scriptHash: swapEndian( outScriptHash ),
		stateHex: stateHex,
		contractSatoshis: satoshis
	}

//FIXME: maybe set an address for EBFRAs
	//const input0Params = getUsefulInputParams(input0Chunks, hexByteToAscii( modeHex ) );
	//const address = 'bshz://0/' + builderRabinPubKey

} // decodeEbfraSpeakerScriptToState


function decodeEbfraAnnouncementScriptToState(outScript, satoshis, silent, input0Chunks) {

	if (!silent) console.log("\nThis output is part of an EBFRA Announcement script/tx")
	const out0Len = outScript.length
	const modeIndex = 2
	const modeHex = outScript.substring(out0Len - modeIndex, out0Len);
	if (!silent)console.log('mode: \'' + hexByteToAscii(modeHex) + '\'');



	const namePathLenIndex = modeIndex + 2  // one byte (2 chars) for namePathLen
	const namePathLenHex = outScript.substring(out0Len - namePathLenIndex , out0Len - modeIndex);
	if (!silent)console.log('namePathLen: ', namePathLenHex);
	const namePathLen = Buffer.from(namePathLenHex, "hex").readUInt8(0)
	if (!silent) console.log('actual namePathLen: ', namePathLen);

	const namePathIndex = namePathLenIndex + namePathLen*2
	let namePath = outScript.substring(out0Len - namePathIndex, out0Len - namePathLenIndex)
	if (!silent) console.log('namePath: "' + namePath + '"')



	const sigPaddingLenIndex = namePathIndex + 4  // 2-byte (4-char) padding length
	const sigPaddingLenHex = outScript.substring(out0Len - sigPaddingLenIndex , out0Len - namePathIndex);
	if (!silent)console.log('sigPaddingLenHex: ', sigPaddingLenHex);
	const sigPaddingLen = Buffer.from(sigPaddingLenHex, "hex").readUInt16LE(0)
	if (!silent) console.log('integer sigPaddingLen: ', sigPaddingLen);

	const sigLenIndex = sigPaddingLenIndex + 2
	const builderSignatureLenHex = outScript.substring(out0Len - sigLenIndex , out0Len - sigPaddingLenIndex);
	if (!silent)console.log('builderSigLenHex: ', builderSignatureLenHex);
	const builderSigLen = Buffer.from(builderSignatureLenHex, "hex").readUInt8(0)
	if (!silent) console.log('integer builderSigLen: ', builderSigLen);

	const pubkeyLenIndex = sigLenIndex + 2
	const builderPubKeyLenHex = outScript.substring(out0Len - pubkeyLenIndex , out0Len - sigLenIndex);
	if (!silent)console.log('builderPubKeyLenHex: ', builderPubKeyLenHex);
	const builderPubKeyLen = Buffer.from(builderPubKeyLenHex, "hex").readUInt8(0)
	if (!silent) console.log('integer builderPubKeyLen: ', builderPubKeyLen);


	// if lengths are zero, these won't do anyting: sig and pubKey will be blank
	const signatureRIdx = pubkeyLenIndex + 2*builderSigLen
	const pubKeyRIdx    = signatureRIdx + 2*builderPubKeyLen

	const builderSigHex = outScript.substring(out0Len - signatureRIdx, out0Len - pubkeyLenIndex)
	if (!silent) console.log('builderSigHex (LE): ', builderSigHex)
//FIXME: print the bigint too

	const builderRabinPubKey = outScript.substring(out0Len - pubKeyRIdx, out0Len - signatureRIdx)
	if (!silent) console.log('builderRabinPubKey (LE): ', builderRabinPubKey)
//FIXME: print the bigint too


	const stateStartIdx = out0Len - pubKeyRIdx
	console.warn("  Setting start of state at Builder Rabin PubKey (whether blank, or not)")

	console.warn("  EBFRA, decoding mode " + hexByteToAscii(modeHex))

	// Extract the complete 'state' hex string
	// This will be useful for building on this transaction
	const stateHex = outScript.substring(stateStartIdx, out0Len)

	// get Sha256 hash of output script
	const outScriptHash = bsv.crypto.Hash.sha256( Buffer.from(outScript, 'hex') ).toString('hex');
	if (!silent) console.log('    hash of output script: ' + outScriptHash);
	if (!silent) console.log('    (flip to get \'scriptHash\'' + swapEndian(outScriptHash) + ')')
	// TODO: see this for list of unspent UTXOs for this scriptHash:
	//       https://developers.whatsonchain.com/#get-script-unspent-transactions

	if ( namePath === '' ) {
		alert("setting namePath to 0 (for this EBFRA Announcement), since it's currently blank")
		namePath = '30'
	}
	const address = 'bshz://' + hexStringToAscii(namePath) + '/ebfraAnnounce'

	return {
		contract: CONTRACT_ANNOUNCE, // announce an EBFRA
		mode: modeHex,

		namePath: namePath,

		builderSigPaddingLen: sigPaddingLen,
		builderSigLen: builderSigLen,
		builderPubKeyLen: builderPubKeyLen,
		builderSigHexLE: builderSigHex,
//FIXME:		//builderSig:
		builderRabinPubKeyHexLE: builderRabinPubKey,

		address: address,

		scriptHash: swapEndian( outScriptHash ),
		stateHex: stateHex,
		contractSatoshis: satoshis
	}

//FIXME: maybe set an address for EBFRAs
	//const input0Params = getUsefulInputParams(input0Chunks, hexByteToAscii( modeHex ) );
	//const address = 'bshz://0/' + builderRabinPubKey

} // decodeEbfraAnnouncementScriptToState

function decodeContinueScriptsToState(outScript, satoshis, silent, input0Chunks) {
	if (!silent) console.log("\nThis is part of a CONTINUE script/tx")

	const out0Len = outScript.length
	const modeHex = outScript.substring(out0Len-2, out0Len);
	if (!silent)console.log('\nmode: \'' + hexByteToAscii(modeHex) + '\'');

	const namePathLenHex = outScript.substring(out0Len - 4 , out0Len - 2);
	if (!silent)console.log('namePathLen: ', namePathLenHex);
	const namePathLen = Buffer.from(namePathLenHex, "hex").readUInt8(0)
	if (!silent) console.log('actual namePathLen: ', namePathLen);

	const namePath = outScript.substring(out0Len - 4 - 2*namePathLen, out0Len - 4)
	if (!silent) console.log('namePath: "' + namePath + '"')

	const amountRIdx = 4 + 2*namePathLen + 4;
	const blockNumRIdx = amountRIdx + 8;
	const genesisBlockRIdx = blockNumRIdx + 8;

	// The number of Builder PKHs depends on how long the namePath is
	// Each character was added by a BUILDER which also added his PKH
	// But, we limit it to MAX_PKHS
	//const numPKHs = Math.min(namePathLen + 1, MAX_PKHS)
	//NOTE: the number of builer payout PKHs USED TO BE related to the length of the namePath
	// It's now simply MAX_PKHS
	const numPKHs = MAX_PKHS

	const pkhListRIdx        = genesisBlockRIdx + 2 * numPKHs * (2 * PKH_LEN + 1)
	const pubPkhRIdx         = 2*PKH_LEN + pkhListRIdx
	const deadlineRIdx       = 2*BLOCKNUM_SIZE + pubPkhRIdx
	const expCounterRIdx     = 2 + deadlineRIdx
	const quarterlyCountRIdx = 4 + expCounterRIdx



	//NOTE: if mode is K, or P, these don't exist
	//      We typically decode K and P elsewhere ( in decodeAppendScriptToState() )?
	const priceRIdx          = 10 + quarterlyCountRIdx
	const p2pkhRIdx          = 40 + priceRIdx

	const potentialSaleFlagRIdx = 2 + p2pkhRIdx
	const potentialSaleMinBlockRIdx = 8 + potentialSaleFlagRIdx
	const rabinOfPotentialBuyerRIdx = 2*20 + potentialSaleMinBlockRIdx


	// This helps us avoid dust outputs
	const amountHex = outScript.substring(out0Len - amountRIdx, out0Len - amountRIdx + 4)
	const amount = Buffer.from(amountHex, "hex").readUInt16LE(0)
	if (!silent) console.log('amount: ', amountHex, ', or ', amount)

	const blockNumHex = outScript.substring(out0Len - blockNumRIdx, out0Len - blockNumRIdx + 8)
	const blockNum = Buffer.from(blockNumHex, "hex").readUInt32LE(0)
	if (!silent) console.log('blockNum: ', blockNumHex, ', or ', blockNum)

	const genesisBlockHex = outScript.substring(out0Len - genesisBlockRIdx, out0Len - genesisBlockRIdx + 8)
	const genesisBlock = Buffer.from(genesisBlockHex, "hex").readUInt32LE(0)
	if (!silent) console.log('genesisBlock: ', genesisBlockHex, ', or ', genesisBlock)

	if (!silent) console.log("# of PKHs: " + numPKHs)
	var pkhs = []
	var rabins = []
	var votes = []
	let tally = 0
	for (var i = 0; i < numPKHs; i++ ) {
		pkhs[i] = outScript.substring(   out0Len - pkhListRIdx + i * (2*2*PKH_LEN + 2),
										 out0Len - pkhListRIdx + i * (2*2*PKH_LEN + 2) + 2*PKH_LEN);      // first 20 bytes (40 chars)
		rabins[i] = outScript.substring( out0Len - pkhListRIdx + i * (2*2*PKH_LEN + 2) + 2*PKH_LEN,
										 out0Len - pkhListRIdx + i * (2*2*PKH_LEN + 2) + 4*PKH_LEN);      // next 20 bytes (40 chars)
		votes[i] = outScript.substring(  out0Len - pkhListRIdx + i * (2*2*PKH_LEN + 2) + 4*PKH_LEN,
										 out0Len - pkhListRIdx + i * (2*2*PKH_LEN + 2) + 4*PKH_LEN + 2);  // final 1 byte (2 chars)
		if (!silent) console.log("    -> " + pkhs[i])
		if ( votes[i] !== '00' ) {
			tally++
			console.warn("vote tally now: " + tally)
		}
	}


		// publisher PKH
		// renewal deadline
		// exp counter
		// quarterly count ( 2 bytes )
		// price in sats
	const ownerRabinPkh = outScript.substring(out0Len - pubPkhRIdx, out0Len - pubPkhRIdx + 2*PKH_LEN)
	if ( !silent ) console.log('ownerRabinPkh: ', ownerRabinPkh)

	const deadlineHex = outScript.substring(out0Len - deadlineRIdx, out0Len - deadlineRIdx + 2*BLOCKNUM_SIZE)
	const deadlineDecimal = Buffer.from(deadlineHex, "hex").readUInt32LE(0)
	if ( !silent ) console.log('deadline: ', deadlineHex, ', or ', deadlineDecimal)

	const expCounterHex = outScript.substring(out0Len - expCounterRIdx, out0Len - expCounterRIdx + 2)
	const expCounterDecimal = Buffer.from(expCounterHex, "hex").readUInt8(0)
	if ( !silent ) console.log('ownerCountHex: ', expCounterHex, ', or decimal ', expCounterDecimal)

	const quarterlyCountHex = outScript.substring(out0Len - quarterlyCountRIdx , out0Len - quarterlyCountRIdx + 4);
	if ( !silent ) console.log('quarterlyCountHex: ' + quarterlyCountHex);
	const quarterlyCount = Buffer.from(quarterlyCountHex, 'hex').readUInt16LE(0)
	if ( !silent ) console.log('quarterlyCount: ' + quarterlyCount);

	const priceInSatsHex = outScript.substring(out0Len - priceRIdx, out0Len - quarterlyCountRIdx) //priceRIdx + 2*PRICE_LEN)
	const priceInSatsDecimal = Buffer.from(priceInSatsHex, "hex").readUIntLE(0, 5) //FIXME: check this
	if ( !silent ) console.log('priceInSats: ', priceInSatsHex, ', or ', priceInSatsDecimal)

	const ownerP2PKH = outScript.substring(out0Len - p2pkhRIdx, out0Len - priceRIdx)
	if ( !silent ) console.warn('ownerP2PKH: ', ownerP2PKH)

	const potentialSaleFlagHex = outScript.substring(out0Len - potentialSaleFlagRIdx, out0Len - potentialSaleFlagRIdx + 2)
	const potentialSaleFlagDecimal = Buffer.from(potentialSaleFlagHex, "hex").readUInt8(0)

	const potentialSaleMinBlockHex = outScript.substring(out0Len - potentialSaleMinBlockRIdx, out0Len - potentialSaleMinBlockRIdx + 8)
	const potentialSaleMinBlockDecimal = Buffer.from(potentialSaleMinBlockHex, "hex").readUInt32LE(0)

	const rabinOfPotentialBuyer = outScript.substring(out0Len - rabinOfPotentialBuyerRIdx, out0Len - rabinOfPotentialBuyerRIdx + 2*20)

	if ( !silent ) console.warn('potentialSaleFlagHex: ' + potentialSaleFlagHex)
	if ( !silent ) console.warn('   potentialSaleFlagDecimal: ' + potentialSaleFlagDecimal)
	if ( !silent ) console.warn('potentialSaleMinBlock: ' + potentialSaleMinBlockDecimal)
	if ( !silent ) console.warn('rabinOfPotentialBuyer: ' + rabinOfPotentialBuyer)

	//NOTE: this is crucial for when generating a sighashPreimage
	let stateStartIdx

	switch ( hexByteToAscii(modeHex) ) {
		case 'P':
			console.warn("starting state at PKH list")
			stateStartIdx = out0Len - pkhListRIdx
			break;
		case 'K':
			console.warn("starting state at quarterly count")
			stateStartIdx = out0Len - quarterlyCountRIdx
			break;
		case 'p':
			console.warn("starting state at rabin PKH of potential buyer")
			stateStartIdx = out0Len - rabinOfPotentialBuyerRIdx
			break;
		default:
			throw new Error("19280: decoding invalid Continue mode");
	}

	if ( !silent) console.log("\n\n\ndecodeContinueScriptsToState(): HEADS-UP: stateStartIdx = " + stateStartIdx)
	if ( !silent) console.log("These are the 20 bytes which precede contentName: '" + outScript.substring(stateStartIdx-40, stateStartIdx) + "'\n\n\n")



	// Extract the complete 'state' hex string
	// This will be useful for building on this transaction
	// It's crucial for generating a sighashPreimage
	const stateHex = outScript.substring(stateStartIdx, out0Len)

	// get Sha256 hash of output script
	const outScriptHash = bsv.crypto.Hash.sha256( Buffer.from(outScript, 'hex') ).toString('hex');
	if (!silent) console.log('    hash of output script: ' + outScriptHash);
	if (!silent) console.log('    (flip to get \'scriptHash\'' + swapEndian(outScriptHash) + ')')
	// TODO: see this for list of unspent UTXOs for this scriptHash:
	//       https://developers.whatsonchain.com/#get-script-unspent-transactions

	if (!silent) {
		console.log("\nThere are " + namePathLen + " characters in the branch name: '" + hexStringToAscii(namePath) + "'")
	}
	console.warn("DECODE CONTINUE(): getting useful input params...")
	const input0Params = getUsefulInputParams(input0Chunks, hexByteToAscii( modeHex ) );
	console.warn("DECODE CONTINUE - got input0Params for namePath " + namePath + ": ", input0Params)
	console.warn("DECODE CONTINUE - got input0Params for the namePath " + namePath + " with .specialOp of ", input0Params.specialOp)

	//String(ownerCount).padStart(3, '0')
	const address = 'bshz://' + hexStringToAscii(namePath)
						+ '/' + String(expCounterDecimal).padStart(3, '0')
						+ '/' + String(quarterlyCount).padStart(5, '0')
						+ (potentialSaleFlagDecimal > 0 ?
								('/' + String(potentialSaleFlagDecimal).padStart(3, '0'))
									: '')
						+ (input0Params.specialOp >= 4 ?   // 1 and 2 are for claiming
								('/vote' + String(tally).padStart(2, '0'))
									:
								''
							)

	if ( !silent ) console.warn("generated shizzle address: " + address)

	return {
		contract: CONTRACT_CONTINUE,
		mode: modeHex,
		namePath: namePath,
		smallestAmount: amountHex,
		blockNum: blockNumHex,
		blockNumInt: Buffer.from(blockNumHex, "hex").readUInt32LE(0),
		genesisBlock: genesisBlockHex,
		builderPKHs: pkhs,
		builderRabins: rabins,
		builderVotes: votes,
		builderVoteTally: tally,

		ownerRabinPKH: ownerRabinPkh,
		renewalDeadline: deadlineHex,
		renewalDeadlineInt: Buffer.from(deadlineHex, "hex").readUInt32LE(0),
		ownerCountHex: expCounterHex,
		ownerCount: expCounterDecimal,

		quarterlyCount: quarterlyCount,
		quarterlyCountHex: quarterlyCountHex,

		address: address,

		ownerP2PKH: ownerP2PKH,
		priceInSats: priceInSatsHex,
		priceInSatsDecimal: priceInSatsDecimal,

		potentialSaleFlag: potentialSaleFlagDecimal,
		potentialSaleMinBlock: potentialSaleMinBlockDecimal,
		rabinOfPotentialBuyer: rabinOfPotentialBuyer,

		scriptHash: swapEndian( outScriptHash ),
		stateHex: stateHex,
		contractSatoshis: satoshis
	}
} // decodeContinueScriptsToState()

function decodeUpdateScriptsToState(outScript, satoshis, silent) {
	if ( !silent ) console.log("\nThis output is part of an UPDATE script/tx")

	const out0Len = outScript.length
	const modeHex = outScript.substring(out0Len-2, out0Len);
	if ( !silent ) console.log('mode: \'' + hexByteToAscii(modeHex) + '\'');

	const namePathLenHex = outScript.substring(out0Len - 4 , out0Len - 2);
	if ( !silent ) console.log('namePathLen: ', namePathLenHex);
	const namePathLen = Buffer.from(namePathLenHex, "hex").readUInt8(0)
	if ( !silent ) console.log('actual namePathLen: ', namePathLen);

	const namePath = outScript.substring(out0Len - 4 - 2*namePathLen, out0Len - 4)
	if ( !silent ) console.log('namePath: "' + namePath + '"')

	const ownerCountRIdx = 2 + 2*namePathLen + 4
	const ownerCountHex = outScript.substring(out0Len - ownerCountRIdx , out0Len - ownerCountRIdx + 2);
	if ( !silent ) console.log('ownerCountHex: ' + ownerCountHex);
	const ownerCount = Buffer.from(ownerCountHex, 'hex').readUInt8(0)
	if ( !silent ) console.log('ownerCount: ' + ownerCount);

	const quarterlyCountRIdx = 4 + ownerCountRIdx
	const quarterlyCountHex = outScript.substring(out0Len - quarterlyCountRIdx , out0Len - quarterlyCountRIdx + 4);
	if ( !silent ) console.log('quarterlyCountHex: ' + quarterlyCountHex);
	const quarterlyCount = Buffer.from(quarterlyCountHex, 'hex').readUInt16LE(0)
	if ( !silent ) console.log('quarterlyCount: ' + quarterlyCount);


	const amountRIdx         = 4 + quarterlyCountRIdx;
	const blockNumRIdx       = amountRIdx + 2*BLOCKNUM_SIZE;
	const genesisBlockRIdx   = blockNumRIdx + 2*BLOCKNUM_SIZE;

	//NOTE: the number of builer payout PKHs USED TO BE related to the length of the namePath
	//var numPKHs = Math.min(namePathLen + 1, MAX_PKHS)
	// It's now simply MAX_PKHS
	const numPKHs = MAX_PKHS
	const pkhListRIdx        = genesisBlockRIdx + 2 * PKH_LEN*numPKHs

	const pubPkhRIdx         = 2*PKH_LEN + pkhListRIdx
	const deadlineRIdx       = 2*BLOCKNUM_SIZE + pubPkhRIdx
	const periodicCountRIdx  = 4 + deadlineRIdx
	const voteTallyRIdx      = 2 + periodicCountRIdx

	const amountHex = outScript.substring(out0Len - amountRIdx, out0Len - amountRIdx + 4)
	const amount = Buffer.from(amountHex, "hex").readUInt16LE(0)
	if ( !silent ) console.log('amount: ', amountHex, ', or ', amount)

	const blockNumHex = outScript.substring(out0Len - blockNumRIdx, out0Len - blockNumRIdx + 8)
	const blockNum = Buffer.from(blockNumHex, "hex").readUInt32LE(0)
	if ( !silent ) console.log('blockNum: ', blockNumHex, ', or ', blockNum)

	const genesisBlockHex = outScript.substring(out0Len - genesisBlockRIdx, out0Len - genesisBlockRIdx + 8)
	const genesisBlock = Buffer.from(genesisBlockHex, "hex").readUInt32LE(0)
	if ( !silent ) console.log('genesisBlock: ', genesisBlockHex, ', or ', genesisBlock)

	if ( !silent ) console.log("# of PKHs: " + numPKHs)
	var pkhs = []
	for (var i = 0; i < numPKHs; i++ ) {
		pkhs[i] = outScript.substring( out0Len - pkhListRIdx +   i   * 2*PKH_LEN,
										out0Len - pkhListRIdx + (i+1) * 2*PKH_LEN);
	}

	const ownerRabinPkh = outScript.substring(out0Len - pubPkhRIdx, out0Len - pubPkhRIdx + 2*PKH_LEN)
	if ( !silent ) console.log('ownerRabinPkh: ', ownerRabinPkh)

	const deadlineHex = outScript.substring(out0Len - deadlineRIdx, out0Len - deadlineRIdx + 2*BLOCKNUM_SIZE)
	const deadlineDecimal = Buffer.from(deadlineHex, "hex").readUInt32LE(0)
	if ( !silent ) console.log('deadline: ', deadlineHex, ', or ', deadlineDecimal)

	const periodicCountHex = outScript.substring(out0Len - periodicCountRIdx, out0Len - periodicCountRIdx + 4)
	const periodicCount = Buffer.from(periodicCountHex, "hex").readUInt16LE(0)
	if ( !silent ) console.log('periodicCountHex: ', periodicCountHex, ', decimal: ', periodicCount)

	const voteTallyHex = outScript.substring(out0Len - voteTallyRIdx, out0Len - voteTallyRIdx + 2)
	const voteTally = Buffer.from(voteTallyHex, "hex").readUInt8(0)
	if ( !silent ) console.log('voteTallyHex: ', voteTallyHex, ', decimal: ', voteTally)

	// Extract the complete 'state' hex string
	// This will be useful for building on this transaction
	const stateStartIdx = out0Len - voteTallyRIdx  //periodicCountRIdx  //deadlineRIdx   //contentNameRIdx
	const stateHex = outScript.substring(stateStartIdx, out0Len)



	if ( !silent ) console.log("\n\n\ndecodeUpdateScriptsToState(): HEADS-UP: stateStartIdx = " + stateStartIdx)
	if ( !silent ) console.log("These are the 20 bytes which precede state: '" + outScript.substring(stateStartIdx-40, stateStartIdx) + "'\n\n\n")



	// get Sha256 hash of output script
	const outScriptHash = bsv.crypto.Hash.sha256( Buffer.from(outScript, 'hex') ).toString('hex');
	if ( !silent ) console.log('    hash of output script: ' + outScriptHash);
	if ( !silent ) console.log('    (flip to get \'scriptHash\'' + swapEndian(outScriptHash) + ')')
	// TODO: see this for list of unspent UTXOs for this scriptHash:
	//       https://developers.whatsonchain.com/#get-script-unspent-transactions

	if ( !silent ) console.log("\nThere are " + namePathLen + " characters in the branch name: '" + hexStringToAscii(namePath) + "'")


	const address = 'bshz://' + hexStringToAscii(namePath)
						+ '/' + String(ownerCount).padStart(3, '0')
						+ '/' + String(quarterlyCount).padStart(5, '0')
						+ '/U/' + String(periodicCount).padStart(5, '0')

	return {
		contract: CONTRACT_UPDATE,
		mode: modeHex,
		namePath: namePath,
		smallestAmount: amountHex,
		blockNum: blockNumHex,
		blockNumInt: Buffer.from(blockNumHex, "hex").readUInt32LE(0),
		genesisBlock: genesisBlockHex,
		builderPKHs: pkhs,
		builderVoteTally: voteTally,   //was recently .voteTally

		ownerRabinPKH: ownerRabinPkh,
		deadline: deadlineHex,

		periodicCountHex: periodicCountHex,
		periodicCount: periodicCount,

		quarterlyCount: quarterlyCount,
		quarterlyCountHex: quarterlyCountHex,
		ownerCount: ownerCount,
		ownerCountHex: ownerCountHex,

		address: address,

		scriptHash: swapEndian( outScriptHash ),
		stateHex: stateHex,
		contractSatoshis: satoshis
	}
} // decodeUpdateScriptsToState()

function decodeTransientScriptsToState(outScript, satoshis, silent) {
	if ( !silent ) console.log("\nThis is a TRANSIENT script/tx")

	const out0Len = outScript.length
	const modeHex = outScript.substring(out0Len-2, out0Len);
	if ( !silent ) console.log('mode: \'' + hexByteToAscii(modeHex) + '\'');

	const namePathLenHex = outScript.substring(out0Len - 4 , out0Len - 2);
	if ( !silent ) console.log('namePathLen: ', namePathLenHex);
	const namePathLen = Buffer.from(namePathLenHex, "hex").readUInt8(0)
	if ( !silent ) console.log('actual namePathLen: ', namePathLen);

	const namePath = outScript.substring(out0Len - 4 - 2*namePathLen, out0Len - 4)
	if ( !silent ) console.log('namePath: "' + namePath + '"')

	const fixedAddressLenRIdx = 2 + 2*namePathLen + 4
	const fixedAddressLenHex = outScript.substring(out0Len - fixedAddressLenRIdx , out0Len - fixedAddressLenRIdx + 2);
	if ( !silent ) console.log('fixedAddressLenHex: ' + fixedAddressLenHex);
	const fixedAddressLen = Buffer.from(fixedAddressLenHex, 'hex').readUInt8(0)
	if ( !silent ) console.log('fixedAddressLen: ' + fixedAddressLen);

	const fixedAddressRIdx    = 2*fixedAddressLen + fixedAddressLenRIdx
	const fixedAddressHex     = outScript.substring(out0Len - fixedAddressRIdx , out0Len - fixedAddressRIdx + fixedAddressLen*2);
	if ( !silent ) console.log("fixedAddressHex: " + fixedAddressHex + "   (may be a composite of Quarterly, daily/update, multiple transients)")


	// NEW: 3 bytes to count how many CONSECUTIVE times a guest-post was hosted
	//      Must reset to zero when NOT hosting
	const consecutivePostsHostedRIdx = 2*3 + fixedAddressRIdx
	const consecutivePostsHostedHex  = outScript.substring(out0Len - consecutivePostsHostedRIdx, out0Len - fixedAddressRIdx)
	const consecutivePostsHostedInt  = Buffer.from(consecutivePostsHostedHex, "hex").readUIntLE(0, 3)
	//FIXME: add a check, later, when we get the hostGuestMode.
	//       If NOT hosting (mode 0), this must be 0
	//       If hosting (mode1,4), this must be > 0

	const downCounterRIdx 	= 2 + consecutivePostsHostedRIdx
	const maxDownCounterRIdx= 2 + downCounterRIdx;
	const amountRIdx 		= 4 + maxDownCounterRIdx;
	const maxBlockRIdx 		= amountRIdx + 8;
	const blockNumRIdx 		= maxBlockRIdx + 8;
	const pkhRIdx 			= blockNumRIdx + 2 * 20
	const mxCntLenRIdx 		= 8 + pkhRIdx
	const guestPostPriceRIdx= 8 + mxCntLenRIdx
	const opointSnippetRIdx = 8 + guestPostPriceRIdx


	const downCounterHex = outScript.substring(out0Len - downCounterRIdx, out0Len - downCounterRIdx + 2)
	if ( !silent ) console.log('downCounter: ' + downCounterHex)

	const maxDownCounterHex = outScript.substring(out0Len - maxDownCounterRIdx, out0Len - maxDownCounterRIdx + 2)
	if ( !silent ) console.log('maxDownCounterHex: ' + maxDownCounterHex)

	const amountHex = outScript.substring(out0Len - amountRIdx, out0Len - amountRIdx + 4)
	const amount = Buffer.from(amountHex, "hex").readUInt16LE(0)
	if ( !silent ) console.log('amount: ', amountHex, ', or ', amount)

	const maxBlockHex = outScript.substring(out0Len - maxBlockRIdx, out0Len - maxBlockRIdx + 8)
	const maxBlock = Buffer.from(maxBlockHex, "hex").readUInt32LE(0)
	if ( !silent ) console.log('maxBlock: ', maxBlockHex, ', or ', maxBlock)

	const newBlockHex = outScript.substring(out0Len - blockNumRIdx, out0Len - blockNumRIdx + 8)
	const newBlock = Buffer.from(newBlockHex, "hex").readUInt32LE(0)
	if ( !silent ) console.log('newBlock: ', newBlockHex, ', or ', newBlock)

	const pkh = outScript.substring( out0Len - pkhRIdx, out0Len - pkhRIdx + 2*PKH_LEN);
	if ( !silent ) console.log("pkh: '" + pkh + "'")

	const maxContentLenHex = outScript.substring(out0Len - mxCntLenRIdx, out0Len - mxCntLenRIdx + 8)
	const maxContentLen = Buffer.from(maxContentLenHex, "hex").readUInt32LE(0)
	if ( !silent ) console.log('maxContentLen: ', maxContentLenHex, ', or ', maxContentLen)

	const guestPostPriceHex = outScript.substring(out0Len - guestPostPriceRIdx, out0Len - guestPostPriceRIdx + 8)
	const guestPostPrice = Buffer.from(guestPostPriceHex, "hex").readUInt32LE(0)
	if ( !silent ) console.log('guestPostPrice: ', guestPostPrice);
	const guestPostsAllowed = (guestPostPriceHex === 'ffffffff' || guestPostPriceHex === 'FFFFFFFF')
								? false : true;
	if ( !silent ) console.log('guestPostsAllowed: ' + guestPostsAllowed)

	const outpointSnippet = outScript.substring(out0Len - opointSnippetRIdx, out0Len - opointSnippetRIdx + 8)
	if ( !silent ) console.log('outpointSnippet: ', outpointSnippet)

	// Extract the complete 'state' hex string
	// This will be useful for building on this transaction
	const stateStartIdx = out0Len - opointSnippetRIdx //mxCntLenRIdx //contentNameRIdx
	const stateHex = outScript.substring(stateStartIdx, out0Len)



	if ( !silent ) console.log("\n\n\ndecodeTransientScriptsToState(): HEADS-UP: stateStartIdx = " + stateStartIdx)
	if ( !silent ) console.log("These are the 20 bytes which precede state: '" + outScript.substring(stateStartIdx-40, stateStartIdx) + "'\n\n\n")



	// get Sha256 hash of output script
	const outScriptHash = bsv.crypto.Hash.sha256( Buffer.from(outScript, 'hex') ).toString('hex');
	if ( !silent ) console.log('    hash of output script: ' + outScriptHash);
	if ( !silent ) console.log('    (flip to get \'scriptHash\'' + swapEndian(outScriptHash) + ')')
	// TODO: see this for list of unspent UTXOs for this scriptHash:
	//       https://developers.whatsonchain.com/#get-script-unspent-transactions

	if ( !silent ) console.log("\nThere are " + namePathLen + " characters in the branch name: '" + hexStringToAscii(namePath) + "'")

	const addressLen = fixedAddressHex.length
	// Extract Owner Count - 1 byte
	const ownerCountHex = fixedAddressHex.substring(addressLen-2, addressLen)
	const ownerCountDec = Buffer.from(ownerCountHex, "hex").readUInt8(0)

	// Extract Quarterly Count - 2 bytes
	const quarterlyCountHex = fixedAddressHex.substring(addressLen-6, addressLen-2)
	const quarterlyCountDec = Buffer.from(quarterlyCountHex, "hex").readUInt16LE(0)

	// Extract Periodic Count: 2 bytes
	const periodicCountHex = fixedAddressHex.substring(addressLen-10, addressLen-6)
	const periodicCountDec = Buffer.from(periodicCountHex, "hex").readUInt16LE(0)

	console.log("decodeTransientScriptsToState(): starting with txAddress of " + fixedAddressHex
				+ ", we've extracted ownerCountHex: " + ownerCountHex
				+ ", quarterlyCountHex: " + quarterlyCountHex
				+ ", periodicCountHex: " + periodicCountHex)
	console.log("decodeTransientScriptsToState(): we've extracted ownerCountDec: " + ownerCountDec
				+ ", quarterlyCountDec: " + quarterlyCountDec
				+ ", periodicCountDec: " + periodicCountDec)

	// Extract any counters
	const maxDownCounterDec = Buffer.from(maxDownCounterHex, "hex").readUInt8(0)
	console.log("maxDownCounterHex: " + maxDownCounterHex + "  maxDownCounterDec: " + maxDownCounterDec)
	let pos = 10    // current cursor position (having extracted 5 bytes (2 PC + 2 QC + 1 OC))
	let transCounters = []
	let transCountersString = ''
	let numTrans = 0
	let extractedSoFar = 5 * 2
	console.log("extractedSoFar: " + extractedSoFar + "   addressLen: " + addressLen)
	while ( extractedSoFar < addressLen ) {
		pos += 2
		const dnCounterHex = fixedAddressHex.substring(addressLen-pos, addressLen-pos + 2)
		console.log("    Extracted downcounter: " + dnCounterHex)
		const dnCounterDec = Buffer.from(dnCounterHex, "hex").readUInt8(0)
		console.log("    decimal: " + dnCounterDec)
		transCounters[numTrans] = String(maxDownCounterDec - dnCounterDec).padStart(3, '0')
		console.log("    upcounter: " + transCounters[numTrans])
		transCountersString += ("/" + transCounters[numTrans])
		numTrans++
		extractedSoFar += 2
		console.log("    NOW extractedSoFar: " + extractedSoFar + "   addressLen: " + addressLen)
	}
	console.log("decodeTransientScriptsToState(): extracted " + numTrans
				+ " transient counters: [", transCounters + "]")

	// NEW for decode stage: consecutivePostsHosted
	let consecPostHostedSuffix = consecutivePostsHostedInt === 0 ?
						''
					:
						':' + String(consecutivePostsHostedInt).padStart(8, '0') + "host"

	const downCounterDec = Buffer.from(downCounterHex, "hex").readUInt8(0)
	const upCounterDec   = maxDownCounterDec - downCounterDec
	const shizzleAddress = "bshz://" + hexStringToAscii(namePath)
						 + "/"  + String(ownerCountDec).padStart(3, '0')
						 + "/"  + String(quarterlyCountDec).padStart(5, '0')
						 + "/U/" + String(periodicCountDec).padStart(5, '0')
						 + "/T" + transCountersString
						 + "/"  + String(upCounterDec).padStart(3, '0')
						 + consecPostHostedSuffix
	console.log("decodeTransientScriptsToState(): final address: " + shizzleAddress)

	return {
		contract:          CONTRACT_TRANSIENT,
		mode:              modeHex,
		namePath:          namePath,
		downCounter:       downCounterHex,
		downCounterInt:    Buffer.from(downCounterHex, "hex").readUInt8(0),
		maxDownCounterHex: maxDownCounterHex,
		smallestAmount:    amountHex,
		maxBlock:          maxBlockHex,
		blockNum:          newBlockHex,
		blockNumInt:       Buffer.from(newBlockHex, "hex").readUInt32LE(0),

		rabinPKH:          pkh,
		maxContentLen:     maxContentLen,
		guestPostsAllowed: guestPostsAllowed,
		guestPostPrice:    guestPostPrice,
		outpointSnippet:   outpointSnippet,

		// This used to be only in the txAddress
		// Now we explicitly extract it
		periodicCountHex: periodicCountHex,
		periodicCount: periodicCountDec,

		quarterlyCount: quarterlyCountDec,
		quarterlyCountHex: quarterlyCountHex,
		ownerCount: ownerCountDec,
		ownerCountHex: ownerCountHex,

		consecutivePostsHosted: consecutivePostsHostedInt,

		//FIXME: eventually get rid of this. Who uses it?
		txAddress: fixedAddressHex,		// combine this with downCounter to "locate" tx
										// this currently includes ownershipCount and quarterlyCount
										// and higher Transients
		address: shizzleAddress,

		scriptHash: swapEndian( outScriptHash ),
		stateHex: stateHex,
		contractSatoshis: satoshis
	}
} // decodeTransientScriptsToState()

function decodeDialogScriptsToState(outScript, satoshis, silent) {

	const out0Len = outScript.length
	const modeRIdx = 2
	const modeHex = outScript.substring(out0Len - modeRIdx, out0Len);
	if ( !silent ) console.log('mode: \'' + hexByteToAscii(modeHex) + '\'');

	const postNumRIdx = modeRIdx + 8
	const postNumHex = outScript.substring(out0Len - postNumRIdx, out0Len - modeRIdx);
	const postNum = Buffer.from(postNumHex, "hex").readUInt32LE(0)
	if ( !silent ) console.log('postNum: ', postNum);


	// NEW in Jan 2023:
	//   owner state:
	//     limbLen(1), limb/namePath
	//     fixedAddressLen(1), fixedAddress:
	//           ownerCount(1), QuarterlyCount(2), PeriodicCount(2), downCounter(1), [ downCounter(1), ... ]
	//   visitor/other state:
	//     limbLen, limb/namePath
	//     fixedAddressLen(1), fixedAddress:
	//           ownerCount(1), QuarterlyCount(2), PeriodicCount(2), downCounter(1), [ downCounter(1), ... ]


	const namePathLenRIdx = postNumRIdx + 2       // ONE-byte length (2 chars)
	const namePathLenHex = outScript.substring(out0Len - namePathLenRIdx , out0Len - postNumRIdx);
	if ( !silent ) console.log('namePathLenHex: ', namePathLenHex);
	const namePathLen = Buffer.from(namePathLenHex, "hex").readUInt8(0)
	if ( !silent ) console.log('actual namePathLen (bytes): ', namePathLen);

	const namePathBytesRIdx = namePathLenRIdx + namePathLen * 2
	const namePath = outScript.substring(out0Len - namePathBytesRIdx, out0Len - namePathLenRIdx)
	if ( !silent ) console.log('namePath: "' + namePath + '"')

	const fixedAddressLenRIdx = namePathBytesRIdx + 2     // one BYTE of fixed-address length (2 chars)
	const fixedAddressLenHex = outScript.substring(out0Len - fixedAddressLenRIdx , out0Len - namePathBytesRIdx);
	if ( !silent ) console.log('fixedAddressLenHex: ' + fixedAddressLenHex);
	const fixedAddressLen = Buffer.from(fixedAddressLenHex, 'hex').readUInt8(0)
	if ( !silent ) console.log('fixedAddressLen: ' + fixedAddressLen);

	const fixedAddressRIdx    = fixedAddressLenRIdx + fixedAddressLen * 2
	const fixedAddressHex     = outScript.substring(out0Len - fixedAddressRIdx , out0Len - fixedAddressLenRIdx);
	if ( !silent ) console.log("fixedAddressHex: " + fixedAddressHex + "   (a composite of ownerCount, Quarterly,  periodic, maybe MULTIPLE transient downCounters)")



	const otherNamePathLenRIdx = fixedAddressRIdx + 2       // ONE-byte length (2 chars)
	const otherNamePathLenHex = outScript.substring(out0Len - otherNamePathLenRIdx , out0Len - fixedAddressRIdx);
	if ( !silent ) console.log('otherNamePathLenHex: ', otherNamePathLenHex);
	const otherNamePathLen = Buffer.from(otherNamePathLenHex, "hex").readUInt8(0)
	if ( !silent ) console.log('actual otherNamePathLen (bytes): ', otherNamePathLen);

	const otherNamePathBytesRIdx = otherNamePathLenRIdx + otherNamePathLen * 2
	const otherNamePath = outScript.substring(out0Len - otherNamePathBytesRIdx, out0Len - otherNamePathLenRIdx)
	if ( !silent ) console.log('otherNamePath: "' + otherNamePath + '"')

	const otherFixedAddressLenRIdx = otherNamePathBytesRIdx + 2     // one BYTE of fixed-address length (2 chars)
	const otherFixedAddressLenHex = outScript.substring(out0Len - otherFixedAddressLenRIdx , out0Len - otherNamePathBytesRIdx);
	if ( !silent ) console.log('otherFixedAddressLenHex: ' + otherFixedAddressLenHex);
	const otherFixedAddressLen = Buffer.from(otherFixedAddressLenHex, 'hex').readUInt8(0)
	if ( !silent ) console.log('otherFixedAddressLen: ' + otherFixedAddressLen);

	const otherFixedAddressRIdx    = otherFixedAddressLenRIdx + otherFixedAddressLen * 2
	const otherFixedAddressHex     = outScript.substring(out0Len - otherFixedAddressRIdx , out0Len - otherFixedAddressLenRIdx);
	if ( !silent ) console.log("otherFixedAddressHex: " + otherFixedAddressHex + "   (a composite of ownerCount, Quarterly,  periodic, maybe MULTIPLE transient downCounters)")




	const maxBlockRIdx = otherFixedAddressRIdx + 8
	const maxBlockHex = outScript.substring(out0Len - maxBlockRIdx, out0Len - otherFixedAddressRIdx);
	const maxBlock = Buffer.from(maxBlockHex, "hex").readUInt32LE(0)
	if ( !silent ) console.log('maxBlock: ', maxBlock);

	const rabinARIdx = maxBlockRIdx + 40  // guest
	const rabinPkhA = outScript.substring(out0Len - rabinARIdx, out0Len - maxBlockRIdx);
	if ( !silent ) console.log('rabinPkhA: ', rabinPkhA);

	const rabinBRIdx = rabinARIdx + 40    // owner
	const rabinPkhB = outScript.substring(out0Len - rabinBRIdx, out0Len - rabinARIdx);
	if ( !silent ) console.log('rabinPkhB: ', rabinPkhB);


	// NEW in Jan 2023
	//   maxContentLen(4)
	//   guestPostPrice(4)
	const mxCntLenRIdx 		= rabinBRIdx + 8
	const maxContentLenHex = outScript.substring(out0Len - mxCntLenRIdx, out0Len - mxCntLenRIdx + 8)
	const maxContentLen = Buffer.from(maxContentLenHex, "hex").readUInt32LE(0)
	if ( !silent ) console.log('maxContentLen: ', maxContentLenHex, ', or ', maxContentLen)

	const guestPostPriceRIdx= mxCntLenRIdx + 8
	const guestPostPriceHex = outScript.substring(out0Len - guestPostPriceRIdx, out0Len - guestPostPriceRIdx + 8)
	const guestPostPrice = Buffer.from(guestPostPriceHex, "hex").readUInt32LE(0)
	if ( !silent ) console.log('guestPostPrice: ', guestPostPrice);



	const prevTxIdRIdx = guestPostPriceRIdx + 64
	const prevTxIdRvs = outScript.substring(out0Len - prevTxIdRIdx, out0Len - guestPostPriceRIdx);
	if ( !silent ) console.log('prevTxIdRvs: ', prevTxIdRvs);
	if ( !silent ) console.log('prevTxId: ',    swapEndian(prevTxIdRvs));


	// Extract the complete 'state' hex string
	// This will be useful for building on this transaction
	const stateStartIdx = out0Len - prevTxIdRIdx
	const stateHex = outScript.substring(stateStartIdx, out0Len)

	// get Sha256 hash of output script
	const outScriptHash = bsv.crypto.Hash.sha256( Buffer.from(outScript, 'hex') ).toString('hex');
	if ( !silent ) console.log('    hash of output script: ' + outScriptHash);
	if ( !silent ) console.log('    (flip to get \'scriptHash\'' + swapEndian(outScriptHash) + ')')
	// TODO: see this for list of unspent UTXOs for this scriptHash:
	//       https://developers.whatsonchain.com/#get-script-unspent-transactions

//FIXME: revisit this
//       Maybe could blindly clump namePath, ownerCount, quarterlyCount together?
//       hmm. maybe. Decoder could unpack it
/*
	const address = 'bshz://' + hexStringToAscii(namePath)
						+ '/' + ownerCount
						+ '/' + quarterlyCount
						+ '/A/' + opsCounter
*/

//FIXME: visitor/other address too? Use this for the future call to createDialog(), below?

	// NEW in Jan 2023
	//   two dialogIds created on-the-spot (making this an async function)?
	//   hmm. Each would require name, ownerCount, otherName, otherOwnerCount, startingTxId

	return {
		contract:          CONTRACT_DIALOG,
		mode:              modeHex,

		postNum:           postNum,

		// NEW in Jan 2023
		ownerNameHex:      namePath,
		ownerName:         hexStringToAscii( namePath ),
		ownerAddress:      fixedAddressHex,
		visitorNameHex:    otherNamePath,                        //FIXME: visitor, or GUEST
		visitorName:       hexStringToAscii( otherNamePath ),
		visitorAddress:    otherFixedAddressHex,

		maxBlockHex:       maxBlockHex,
		maxBlock:          maxBlock,

		guestRabinPKH:     rabinPkhA,         // rename visitorRabinPKH? <---
		ownerRabinPKH:     rabinPkhB,

		// NEW in Jan 2023
		maxContentLen:     maxContentLen,
		postPrice:         guestPostPrice,

		prevTxId:          swapEndian( prevTxIdRvs ),

		//address: shizzleAddress,

		scriptHash: swapEndian( outScriptHash ),
		stateHex: stateHex,
		contractSatoshis: satoshis
	}
} //decodeDialogScriptsToState

function decodeBitGroupScriptsToState(outScript, satoshis, silent, admin) {

	//NOTE: we share this function for both BitGroup and AdministerGroup
	//      They differ only in that AdministerGroup has a hash inserted between
	//      postNum, and numUsers
	var contractType
	if ( !admin ) {
		contractType = CONTRACT_BITGROUP
		if ( !silent ) console.log("\nThis is a BITGROUP script/tx")
	} else {
		contractType = CONTRACT_ADMINGROUP
		if ( !silent ) console.log("\nThis is a ADMINISTERGROUP script/tx")
	}

	const out0Len = outScript.length
	const modeHex = outScript.substring(out0Len-2, out0Len);
	if ( !silent ) console.log('mode: \'' + hexByteToAscii(modeHex) + '\'');

	const branchLenHex = outScript.substring(out0Len - 4 , out0Len - 2);
	const branchLen = Buffer.from(branchLenHex, "hex").readUInt8(0)
	if ( !silent ) console.log('branchLenHex: ', branchLenHex, " (" + branchLen + " decimal)");

	const branchName = outScript.substring(out0Len - 4 - 2*branchLen, out0Len - 4)
	if ( !silent ) console.log('namePath: "' + branchName + '"')


	const ownerCountRIdx = 2 + 2*branchLen + 4
	const ownerCountHex = outScript.substring(out0Len - ownerCountRIdx , out0Len - ownerCountRIdx + 2);
	if ( !silent ) console.log('ownerCountHex: ' + ownerCountHex);
	const ownerCount = Buffer.from(ownerCountHex, 'hex').readUInt8(0)
	if ( !silent ) console.log('ownerCount: ' + ownerCount);


	const quarterlyCountRIdx = 4 + ownerCountRIdx
	const quarterlyCountHex = outScript.substring(out0Len - quarterlyCountRIdx , out0Len - quarterlyCountRIdx + 4);
	if ( !silent ) console.log('quarterlyCountHex: ' + quarterlyCountHex);
	const quarterlyCount = Buffer.from(quarterlyCountHex, 'hex').readUInt16LE(0)
	if ( !silent ) console.log('quarterlyCount: ' + quarterlyCount);


	const periodicCountRIdx = 4 + quarterlyCountRIdx
	const periodicCountHex = outScript.substring(out0Len - periodicCountRIdx , out0Len - periodicCountRIdx + 4);
	if ( !silent ) console.log('periodicCountHex: ' + periodicCountHex);
	const periodicCount = Buffer.from(periodicCountHex, 'hex').readUInt16LE(0)
	if ( !silent ) console.log('periodicCount: ' + periodicCount);


	const maxBlockRIdx			= periodicCountRIdx + 8
	const satsPerPostRIdx 		= maxBlockRIdx + 8
	const maxPostLenRIdx 		= satsPerPostRIdx + 8
	const userBountyRIdx 		= maxPostLenRIdx + 8
	const groupNameLenRIdx		= userBountyRIdx + 2

	const groupNameLenHex = outScript.substring(out0Len - groupNameLenRIdx , out0Len - groupNameLenRIdx + 2);
	const groupNameLen = Buffer.from(groupNameLenHex, "hex").readUInt8(0)

	const groupNameRIdx			= groupNameLenRIdx + 2*groupNameLen
	const startedAtPostRIdx		= groupNameRIdx + 8
	const currentBlockRIdx 		= startedAtPostRIdx + 8
	const postNumRIdx 			= currentBlockRIdx + 8

	var bitGroupHashRIdx = postNumRIdx
	if ( admin ) {
		bitGroupHashRIdx = postNumRIdx + 64;
	}

	const numUsersRIdx 		= bitGroupHashRIdx + 4
	const maxBlockHex = outScript.substring(out0Len - maxBlockRIdx, out0Len - maxBlockRIdx + 8)
	const maxBlock = Buffer.from(maxBlockHex, "hex").readUInt32LE(0)
	if ( !silent ) console.log('maxBlockHex: ', maxBlockHex, ', maxBlock:', maxBlock)

	const satsPerPostHex = outScript.substring(out0Len - satsPerPostRIdx, out0Len - satsPerPostRIdx + 8)
	const satsPerPost = Buffer.from(satsPerPostHex, "hex").readUInt32LE(0)
	if ( !silent ) console.log('satsPerPostHex: ', satsPerPostHex, ', satsPerPost:', satsPerPost)

	const maxPostLenHex = outScript.substring(out0Len - maxPostLenRIdx, out0Len - maxPostLenRIdx + 8)
	const maxPostLen = Buffer.from(maxPostLenHex, "hex").readUInt32LE(0)
	if ( !silent ) console.log('maxPostLenHex: ', maxPostLenHex, ', maxPostLen:', maxPostLen)

	const userBountyHex = outScript.substring(out0Len - userBountyRIdx, out0Len - userBountyRIdx + 8)
	const userBounty = Buffer.from(userBountyHex, "hex").readUInt32LE(0)
	if ( !silent ) console.log('userBountyHex: ', userBountyHex, ', userBounty:', userBounty)


	if ( !silent ) console.log('groupNameLenHex: ', groupNameLenHex, " (" + groupNameLen + " decimal)");
	const groupName = outScript.substring(out0Len - groupNameRIdx, out0Len - groupNameLenRIdx)
	if ( !silent ) console.log('groupName: "' + groupName + '"')

	const startedAtPostHex = outScript.substring(out0Len - startedAtPostRIdx, out0Len - startedAtPostRIdx + 8)
	const startedAtPost = Buffer.from(startedAtPostHex, "hex").readUInt32LE(0)
	if ( !silent ) console.log('startedAtPostHex: ', startedAtPostHex, ', startedAtPost:', startedAtPost)

	const currentBlockHex = outScript.substring(out0Len - currentBlockRIdx, out0Len - currentBlockRIdx + 8)
	const currentBlock = Buffer.from(currentBlockHex, "hex").readUInt32LE(0)
	if ( !silent ) console.log('blockNumHex: ', currentBlockHex, ', blockNum:', currentBlock)

	const postNumHex = outScript.substring(out0Len - postNumRIdx, out0Len - postNumRIdx + 8)
	const postNum = Buffer.from(postNumHex, "hex").readUInt32LE(0)
	if ( !silent ) console.log('postNumHex: ', postNumHex, ', postNum:', postNum)

	var bitGroupHash = ''
	if ( admin ) {
		bitGroupHash = outScript.substring(out0Len - bitGroupHashRIdx, out0Len - bitGroupHashRIdx + 64)
	}
	if ( !silent ) console.log('bitGroupHash: ', bitGroupHash)

	//const postLenHex = outScript.substring(out0Len - postLenRIdx, out0Len - postLenRIdx + 8)
	//const postLen = Buffer.from(postLenHex, "hex").readUInt32LE(0)
	//if ( !silent ) console.log('postLenHex: ', postLenHex, ', postLen:', postLen)

	const numUsersHex = outScript.substring(out0Len - numUsersRIdx, out0Len - numUsersRIdx + 4)
	const numUsers = Buffer.from(numUsersHex, "hex").readUInt16LE(0)
	if ( !silent ) console.log('numUsersHex: ', numUsersHex, ', numUsers:', numUsers)

	// rabin, p2pkh, recentPostBlock, status/rank, paidBounty
	const USER_ENTRY_LEN = (20 + 20 + 4 + 1 + 1) * 2;
	const userEntriesRIdx = numUsersRIdx + numUsers * USER_ENTRY_LEN

	var userProfiles = []
	for ( var i = 0; i < numUsers; i++ ) {
		const userProfileBytes = outScript.substring(out0Len - userEntriesRIdx + i * USER_ENTRY_LEN,
													out0Len - userEntriesRIdx + (i+1) * USER_ENTRY_LEN)
		const userRabinPKH        = userProfileBytes.substring(0, 2 * PKH_LEN)
		const userP2PKH           = userProfileBytes.substring(2*PKH_LEN,      4*PKH_LEN)
		const userRecentPostBlock = userProfileBytes.substring(4*PKH_LEN,      4*PKH_LEN + 8)
		const userStatus          = userProfileBytes.substring(4*PKH_LEN + 8,  4*PKH_LEN + 8 + 2)
		const userPaidBounty      = userProfileBytes.substring(4*PKH_LEN + 10, 4*PKH_LEN + 10 + 2)

		userProfiles[i] = {
			userRabinPKH:        userRabinPKH,
			userP2PKH:           userP2PKH,
			userRecentPostBlock: userRecentPostBlock,
			userStatus:          userStatus,
			userPaidBounty:      userPaidBounty
		}
		if ( !silent ) console.log('userProfiles['+i+'] = ', userProfiles[i])
	}

	//const userPostRIdx = userEntriesRIdx + postLen*2
	//const userPost = outScript.substring(out0Len - userPostRIdx, out0Len - userPostRIdx + 2*postLen)
	//if ( !silent ) console.log('userPost: ' + userPost )
	//if ( !silent ) console.log('userPost: ', input0Chunks[2] )

	//if ( !silent ) console.log('cmd:      ', input0Chunks[1] )

	// Extract the complete 'state' hex string
	// This will be useful for building on this transaction
	const stateStartIdx = out0Len - userEntriesRIdx //userPostRIdx
	const stateHex = outScript.substring(stateStartIdx, out0Len)

	if ( !silent ) console.log("\n\n\ndecodeBitGroupScriptsToState(): HEADS-UP: stateStartIdx = " + stateStartIdx)
	if ( !silent ) console.log("These are the 20 bytes which precede state/content: '" + outScript.substring(stateStartIdx-40, stateStartIdx) + "'\n\n\n")

	// get Sha256 hash of output script
	const outScriptHash = bsv.crypto.Hash.sha256( Buffer.from(outScript, 'hex') ).toString('hex');
	if ( !silent ) console.log('    hash of output script: ' + outScriptHash);
	if ( !silent ) console.log('    (flip to get \'scriptHash\'' + swapEndian(outScriptHash) + ')')
	// TODO: see this for list of unspent UTXOs for this scriptHash:
	//       https://developers.whatsonchain.com/#get-script-unspent-transactions

	if ( !silent ) console.log("\nThere are " + branchLen + " characters in the branch name: '" + hexStringToAscii(branchName) + "'")

	let address = 'bshz://' + hexStringToAscii(branchName)
						+ '/' + String(ownerCount).padStart(3, '0')
						+ '/' + String(quarterlyCount).padStart(5, '0')
						+ '/U/' + String(periodicCount).padStart(5, '0')
						+ '/G/'
	if ( startedAtPost !== 0 ) {
		address += (String(startedAtPost).padStart(10, '0') + '/')
		//FIXME: add the group name? prob not
		//FIXME: can't spawn subgroup on 0th post, right?
	}

	address += String(postNum).padStart(10, '0')

	if ( admin ) {
		address += "/A"
	}

	return {
		contract: contractType,
		mode: modeHex,
		branchName: branchName,
		namePath: branchName,

		ownerCount: ownerCount,
		ownerCountHex: ownerCountHex,
		quarterlyCount: quarterlyCount,
		quarterlyCountHex: quarterlyCountHex,
		periodicCount: periodicCount,
		periodicCountHex: periodicCountHex,

		address: address,

		maxBlock: maxBlock,
		satsPerPost: satsPerPost,
		maxPostLen: maxPostLen,
		userBounty: userBounty,
		groupName: groupName,
		startedAtPost: startedAtPost,
		blockNum: currentBlockHex,
		blockNumInt: Buffer.from(currentBlockHex, "hex").readUInt32LE(0),
		postNum: postNum,
		bitGroupHash: bitGroupHash,       //NOTE: only for AdministerGroup
		numUsers: numUsers,
		userProfiles: userProfiles,

		scriptHash: swapEndian( outScriptHash ),
		stateHex: stateHex,
		contractSatoshis: satoshis
	}
} // decodeBitGroupScriptsToState()

//export /* */
function convertFromBase64(base64) {

	const base64Buff = Buffer.from(base64, "hex")
	var ascii = Buffer.alloc(base64Buff.length)  //NOTE: buffer is HALF the length of base64
	//console.log("convertFromBase64(): setup base64Buff with len " + base64Buff.length)
	for ( var i = 0; i < base64Buff.length; i++ ) {
		if ( base64Buff[i] >= 0 && base64Buff[i] <= 25 ) {
			//console.log("char " + i + " is a CAPITAL letter.")
			ascii[ i ] = base64Buff[i] + 65
		} else if ( base64Buff[i] >= 26 && base64Buff[i] <= 51 ) {
			//console.log("char " + i + " is a lower-case letter.")
			ascii[ i ] = base64Buff[i] + 71
		} else if ( base64Buff[i] >= 52 && base64Buff[i] <= 61 ) {
			//console.log("char " + i + " is a digit.")
			ascii[ i ] = base64Buff[i] - 4
		} else if ( base64Buff[i] === 62 ) {
			//console.log("char " + i + " is a forward slash.")
			ascii[ i ] = 47
		} else if ( base64Buff[i] === 63 ) {
			//console.log("char " + i + " is a hyphen.")
			ascii[ i ] = 45
		} else {
			console.log("convertFromBase64(): character " + i
					+ " (and maybe others) is outside the range of Base-64 characters: " + base64Buff[i])
			throw new Error("13505: char " + i + " outside Base64 range. String: " + base64);
		}
	}

	return ascii.toString()
}

//NOTE: this is more shizzleDecoder than ...Builder

/**
 *  NOT USED <-----
 *
 * Rename to getUnspentOutputs()
 * @param {*} theState
 * @param {*} theTx
 * @param {*} outputIdx
 */
//export /* */
async function checkForExpectedUTXO(theState, theTx, outputIdx) {
	try {
		//console.log("Will check for an expected UTXO at output index " + outputIdx)
		//console.log('\n\nLet\s check for utxos - based on our output #0 scriptHash ' + theState.scriptHash)
		var utxoObject = await getUnspentScript(theState.scriptHash);


		//FIXME: what happens if we query for a spend from a FADED tx?
		//       does it return something different? if so, we'll want to take note, and maybe removeFadedTx()


		//var utxoObject = utxos;
		var numTxs = utxoObject.length;
		//console.log("The scriptHash has this many UTXOs: " + numTxs);
		let foundOutIndex = -1
		let numFound = 0
		var unspentOutputs = []
		for ( var i = 0; i < numTxs; i++ ) {
			if ( utxoObject[i].txId === theTx ) {
				//console.log('There\'s an UNSPENT output for our script (matches the scriptHash) in this tx, at index ' + utxoObject[i].outputIndex)
				foundOutIndex = utxoObject[i];  //utxoObject[i].outputIndex
				//break

				//FIXME: check if this output script matches the CURRENT contract.
				//       It could be an old UTXO, from an old contract, that can't
				//       be spent with the current contract.
				//compareScriptWithCurrentContract()

				// save this output
				unspentOutputs[ numFound ] = foundOutIndex
				numFound++
			}
		}
		//console.log("checkForExpectedUTXO(): BTW: numFound is " + numFound);

		//console.log("checkForExpectedUTXO(): returning (unspent) foundIndexes: ", unspentOutputs)
		return unspentOutputs;

	}  catch (error) {
		console.log('Failed in checkForExpectedUTXO')
		console.log('Failed on network: ' + NETWORK)
		console.error("stack: " + error.stack)
        printError(error) //needed?
    }
}

/**
 * Check if scriptHash appears in a mined transaction.
 *
 * Three scenarios of interest:
 * 		CONFIRMED spend		 	 1
 * 		UNCONFIRMED	spend	 	 0
 * 		NO spend (faded?)		-1
 *      missing parent (faded?)	-2		hmm. a 404? <----- ----- ----- <<<<<
 *
 * Other scenarios/errors:
 * 		multiple instances
 *
 * @param {*} scriptHash
 * @param {*} theTx
 * @returns
 */
//export /* */
async function getScriptHashMinedBlockHeight(scriptHash, theTx) {
	try {
		console.log("getScriptHashMinedBlockHeight() for scriptHash " + scriptHash + "  (appears in this txid? " + theTx + ")  (in or output?)")
		//console.log("Will check for an expected UTXO at output index " + outputIdx)
		//console.log('\n\nLet\s check for utxos - based on our output #0 scriptHash ' + theState.scriptHash)
		var history = await getSpentScript(scriptHash);
		console.log("getScriptHashMinedBlockHeight(): got back ", history);

	}  catch (error) {
		console.log('Failed in getScriptHashMinedBlockHeight for scriptHash ' + scriptHash)
		console.error('Failed on network: ' + NETWORK + ": ", error)
		//console.error("stack: " + error.stack)
		printError(error)

		//FIXME: is this valid for all browsers? And shouldn't we .toLowerCase()?
		if ( error.toString() === 'Error: Network Error' ) {
			return -3
		}
		throw( error )
	}

	if ( !history ) {
		console.error("getScriptHashMinedBlockHeight: got back undefined? Maybe this is a network error. returning -3")
		//FIXME: is this a valid assumption? Wouldn't the error CONTAIN that string 'Network Error'?
		return -3
	}

	//NOTE: a bitshizzle scriptHash should always be unique (except maybe when testing, funding a contract)
	if ( history.length > 2 ) {

		// Bitails interesting-response MITIGATION

		// AFTER a re-org they may return two nearly-identical entries (same txId, but likely with different times)
		const txsInWhichWeSeeThisScriptHash = new Set()
		let duplicateTxIdCount = 0
		let indexWhereWeFoundADuplicate = null
		for ( let z = 0; z < history.length; z++ ) {
			let entry = history[z]
			const entrysTxId = entry.txId
			if ( txsInWhichWeSeeThisScriptHash.has(entrysTxId) ) {
				console.error("ah hah. we've already seen this txid: " + entrysTxId)
				duplicateTxIdCount++
				indexWhereWeFoundADuplicate = z
			} else {
				txsInWhichWeSeeThisScriptHash.add(entrysTxId)
			}
		}

		if ( duplicateTxIdCount > 1 ) {
			alert("We've only coded a mitigation for ONE duplicate in the history. We found " + duplicateTxIdCount
				+ ", so we can't deal with this right now. Please report this scriptHash: " + scriptHash
			)
		} else if ( history.length - duplicateTxIdCount < 3 ) {
			alert("if we take " + duplicateTxIdCount + " duplicates into account, we should be fine. CODE A work-around")
			console.error("removing entry " + indexWhereWeFoundADuplicate + " from history array ", history)
			history.splice(indexWhereWeFoundADuplicate, 1)
			console.error("NEW, shorter, history array ", history)
			alert("CHECK RESULTS of splice")
		} else {
			console.error("There were " + duplicateTxIdCount + " history txId duplilcates. We still have a problem. Will throw an error.")
		}
	}

	if ( history.length > 2 ) {


		//FIXME: maybe we shouldn't print out the whole history

		console.error(  "ERROR: scripthash for an output of tx " + theTx + " is not unique (more than 2 occurences): " + scriptHash
				+ " Got back ", history);
		throw new Error("10017: scripthash for an output of tx " + theTx + " is not unique (more than 2 occurences): " + scriptHash);
	} else if ( history.length === 1 ) {
		if ( history[0].txId === theTx ) {
			console.log("We only found the parent in the history.  NOT yet spent (or spend faded).")
			return -1
		} else {
			console.error(  "ERROR: single scripthash " + scriptHash + " mapping is to wrong tx " + history[0].txId + ", instead of parent " + theTx);
			//throw new Error("10018: single scripthash " + scriptHash + " mapping is to wrong tx " + history[0].txId + ", instead of parent " + theTx);

			console.warn("BTW: it's blockheight is: ", history[0].blockHeight)
			alert("10018: single scripthash " + scriptHash + " mapping is to wrong tx " + history[0].txId + ", instead of parent " + theTx + "\nWe'll do our best");

			return history[0].blockHeight === 0 || history[0].blockHeight === -1 ? 0 : 1;
		}
	} else if ( history.length === 0 ) {
		console.error(  "ERROR: scripthash " + scriptHash + " maps to nothing, instead of parent " + theTx + " Faded parent?");
		//throw new Error("10019: scripthash " + scriptHash + " maps to nothing, instead of parent " + theTx);
		return -2;
	}

	// There are exactly 2 entries. Ignore the parent, and get the HEIGHT of the spend

	if ( history[0].txId === theTx ) {
		console.log("history[0] is the parent, so, history[1] should have the spend")

		return history[1].blockHeight === 0 || history[1].blockHeight === -1 ? 0 : 1;
	} else if ( history[1].txId === theTx ) {
		console.log("history[1] is the parent, so, history[0] should have the spend")

		return history[0].blockHeight === 0 || history[0].blockHeight === -1 ? 0 : 1;
	} else {
		console.error(  "ERROR: neither of two instances of scripthash " + scriptHash + " map to parent " + theTx);
		throw new Error("10020: neither of two instances of scripthash " + scriptHash + " map to parent " + theTx);
	}
}

/**
 * Check our DB for the next tx. If not in our DB, query provider.
 * MAY create a spentTxo record.
 *
 * @param {*} theDB
 * @param {*} dbFindTxoFromTxidAndIndex  DB-specific function
 * @param {*} dbFindNextTxFromSpentTxo   DB-specific function
 * @param {*} dbAddSpentTxo              DB-specific function
 * @param {*} theState
 * @param {*} theTx
 * @param {*} outputIdx
 */
//export /* */
async function findTheNextTx(theDB,
							dbFindTxoFromTxidAndIndex, dbFindNextTxFromSpentTxo, dbAddSpentTxo,
							theState, theTx, outputIdx,
							weBelieveItShouldExist = false) {
	//FIXME: should we shrink down the scope of this try? why should we?
	try {
		console.log("findTheNextTx(): Looking for SPENT TXO to follow - at output index " + outputIdx)

		// Let's use our tx db to find the next TX
		// find the tx that spends outputIdx of theTx

		// example: for a07fdb39d3ee60eccd807cdde0582b77f9115262cc7cc8bb84c9c7b1295a9710
		// output 0 was spent by 41860d0f59ca8d4c5602fce85fdcfe55f16f7844fc90e03475d0804359be3bde
		//
		// another example:  for 180bcb19eba5b18f7d26a760035cfc060879aac9128e5ba1a23ad2a39c1d0c23
		// output 0 was spent by 4fc20fdd7e2c225d1b735f2cc0d3c71f47a6c1facb6cdd256ad2291df54651dd

		// two steps:
		// 1) find TXO 0 for tc a07fdb39....   (txoTab record)
		// 2) find SPENT TXO                   (spentTxoTab record)
		//    That record tells us the next TX

		// 1) find txoTab record for tx a07fdb39....
		//      (each txo has a txid, outIndex, mode (, and unique primary key: txoid) )
		const txo = await dbFindTxoFromTxidAndIndex(theDB, theTx, outputIdx)

		var spentTxo = null
		var foundTx = null
		if ( txo && txo !== null ) {

			// 2) then find SPENT TXO with key from TXO
			console.log("findTheNextTx(): looking for nextTx. spentTxo with txoId of " + txo.txoid + " <===")
			spentTxo = await dbFindNextTxFromSpentTxo(theDB, txo.txoid)

			console.log("findTheNextTx(): ok. We found spent txo in db: ", spentTxo);
			foundTx = spentTxo ? spentTxo.txIdItFunds : null;
		} else {
			console.log("findTheNextTx(): found NO DB txo entry for tx ", theTx, " with out index", outputIdx, " <====== :(");
		}

		console.log("findTheNextTx(): BTW: foundTx is ", foundTx);
		console.log("findTheNextTx(): BTW: txo is ", txo);
		console.log("findTheNextTx(): BTW: spentTxo ", spentTxo);

//FIXME: check typeof txo?
		if ( txo === 'undefined' || txo === null || !spentTxo || spentTxo === 'undefined' || spentTxo === null ) {
			console.log("findTheNextTx(): ");
			console.log("findTheNextTx(): WE NEED TO QUERY WoC for this spend. We didn't create it. We don't have it.");
			console.log("   BTW: theState: ", theState)
			console.log("findTheNextTx(): will use script hash " + theState.scriptHash + " <=====");

			if ( theTx.startsWith('11111111111111111111111111111111') ) {
				console.warn("findTheNextTx(): Whoops. We were about to query the provider for a bogus Tx. <-----")
				return null
			}

			//NOTE: this uses WoC to find the next Tx (the one that spends an output)
			//console.log('\n\nLet\s check for txos - based on our output #0 scriptHash ' + theState.scriptHash)
			var txoObjects = await getSpentScript(theState.scriptHash, weBelieveItShouldExist);
			console.log("findTheNextTx(): txoObjects: ", txoObjects);
			var numTxs = txoObjects.length;
			console.log("findTheNextTx(): The scriptHash has this many TXOs: " + numTxs);
			let numFound = 0
			let parentHeight = 0;
			for ( var i = 0; i < numTxs; i++ ) {
				const candidate = txoObjects[i]
				if ( candidate.txId !== theTx ) {
					console.log("  --> This one fits the bill: " + candidate.txId
							+ "  with height " + candidate.blockHeight)
					foundTx = candidate.txId
					numFound++
				} else {
					console.log("  --> This one MATCHES the parent tx we're investigating: " + candidate.txId)
					console.log("(so, not it. It's the parent). parent tx has a height of: " + candidate.blockHeight)
					parentHeight = candidate.blockHeight
				}
			}
			if ( numFound === 0 && weBelieveItShouldExist ) {
				console.error("MAYBE we should check again (with a different provider)?")
				alert("We MIGHT have a provider inconsistency (one is stale). Will try again...")

				txoObjects = await getSpentScript(theState.scriptHash, weBelieveItShouldExist);
				console.log("findTheNextTx(): 2nd try: txoObjects: ", txoObjects);
				numTxs = txoObjects.length;
				console.log("findTheNextTx(): 2nd try: The scriptHash has this many TXOs: " + numTxs);
				numFound = 0
				parentHeight = 0;
				for ( var i = 0; i < numTxs; i++ ) {
					const candidate = txoObjects[i]
					if ( candidate.txId !== theTx ) {
						console.log("  2nd try: --> This one fits the bill: " + candidate.txId
								+ "  with height " + candidate.blockHeight)
						foundTx = candidate.txId
						numFound++
					} else {
						console.log("  2nd try: --> This one MATCHES the parent tx we're investigating: " + candidate.txId)
						console.log("  2nd try: (so, not it. It's the parent). parent tx has a height of: " + candidate.blockHeight)
						parentHeight = candidate.blockHeight
					}
				}
				if ( numFound === 0 && weBelieveItShouldExist ) {
					alert("Even after two tries/queries, we still don't see the spend/tx we expected it. Either "
						+ "it 'faded', or, we ended-up using the same (stale) Tx Provider both times?")
				}
			}

if ( numFound > 1 ) {
	const history = txoObjects
	const scriptHash = theState.scriptHash
	if ( history.length > 2 ) {
		alert("findTheNextTx(): attempting mitigation... THIS SHOULDN'T HAPPEN")

		// Bitails interesting-response MITIGATION

		// AFTER a re-org they may return two nearly-identical entries (same txId, but likely with different times)
		const txsInWhichWeSeeThisScriptHash = new Set()
		let duplicateTxIdCount = 0
		let indexWhereWeFoundADuplicate = null
		for ( let z = 0; z < history.length; z++ ) {
			let entry = history[z]
			const entrysTxId = entry.txId
			if ( txsInWhichWeSeeThisScriptHash.has(entrysTxId) ) {
				console.error("ah hah. we've already seen this txid: " + entrysTxId)
				duplicateTxIdCount++
				indexWhereWeFoundADuplicate = z
			} else {
				txsInWhichWeSeeThisScriptHash.add(entrysTxId)
			}
		}

		if ( duplicateTxIdCount > 1 ) {
			alert("We've only coded a mitigation for ONE duplicate in the history. We found " + duplicateTxIdCount
				+ ", so we can't deal with this right now. Please report this scriptHash: " + scriptHash
			)
		} else if ( history.length - duplicateTxIdCount < 3 ) {
			alert("if we take " + duplicateTxIdCount + " duplicates into account, we should be fine. CODE A work-around")
			console.error("removing entry " + indexWhereWeFoundADuplicate + " from history array ", history)
			history.splice(indexWhereWeFoundADuplicate, 1)
			console.error("NEW, shorter, history array ", history)
			alert("CHECK RESULTS of splice")
			numFound--
			txoObjects = history
		} else {
			console.error("There were " + duplicateTxIdCount + " history txId duplilcates. We still have a problem. Will throw an error.")
		}
	}
}

			console.log("findTheNextTx(): numFound from WoC query: " + numFound);
			//NOTE: IIRC: there may be multiple found because during testing
			//      we used to build identical root transactions. This will
			//      probably not happen anymore, since we've randomized
			//      their generation
			if ( numFound > 1 ) {
				alert("CODE ERROR: we think this should never happen again - since scriptHashes should only be found in history TWICE: when locked, and when spent")
				console.log("findTheNextTx(): Will try to eliminate some of the responses")
				let lowestHeightAtOrAboveParentHeight = 90000000;
				let bestResultIndex = -1;
				let numAtLowest = 0;
				let bestTxId = null;

				let bestHasPositiveHeight = false;
				for ( var j = 0; j < numTxs; j++ ) {
					let positiveHeight = false;
					const tx = txoObjects[j];
					const txId = tx.txId;
					const height = tx.blockHeight;
					console.log("  looking at tx " + j + " with height " + height + "...")
					if ( height > 0 ) {
						positiveHeight = true;
					}

					if ( txId !== theTx &&
							((height <= lowestHeightAtOrAboveParentHeight &&
							height >= parentHeight) || height === 0 || height === -1 )) {
						if ( bestHasPositiveHeight && !positiveHeight ) {
							console.log("DISREGARDING THIS one. It's height isn't great, and we've already got one with a good height")
							continue;
						}
						bestTxId = txId;
						if ( positiveHeight ) {
							bestHasPositiveHeight = true;
						}
						if ( height === lowestHeightAtOrAboveParentHeight
							|| height === -1 || height === 0 ) {
							numAtLowest++;
						} else {
							numAtLowest = 1;
							console.log("There's a new lowest: " + height);
							//if ( height !== -1 ) {
								lowestHeightAtOrAboveParentHeight = height;
							//}
						}
						console.log("new best tx: " + txId + ", height " + height + ", index " + j);
						bestResultIndex = j;
					}
				}

				if ( numAtLowest > 1 ) {
					console.error("findTheNextTx(): We still have too many txs to consider. FIXME: query tx-provider, and parse these.");
					return null;
				} else {
					console.log("findTheNextTx(): Great. We've whittled it down to 1 tx: " + bestTxId);
					foundTx = bestTxId;
				}
			}

			if ( txo && txo !== null ) {
				//NOTE we can't add any txos. we haven't loaded and decoded the nextTx
				console.log("findTheNextTx(): --> queried WoC, and found where the the spent txo leads");

				console.log("findTheNextTx(): --> adding spent txo record:  txoid/txoKeyId " + txo.txoid + " which leads to tx " + foundTx);
				if ( foundTx !== null ) {
					await dbAddSpentTxo(theDB, txo.txoid, foundTx)
				} else {
					console.warn("findTheNextTx(): Whoops. We were about to add a spentTxo record, with txIdItFunds of null.")
				}
			}

			//FIXME: if WoC goes crazy, might return 0 results. deal with it
		}

		return foundTx;
	} catch (error) {
		console.log('Failed in findTheNextTx')
		console.log('Failed on network: ' + NETWORK)
		console.error("stack: " + error.stack)
		printError(error) //needed?

		return null;
	}
}

//export /* */
async function findThePrevTx(theDB, currentTxId, dbFindPrevFromCurrent) {
	try {
		return await dbFindPrevFromCurrent(theDB, currentTxId);
	} catch (error) {
		console.log('Failed in findThePrevTx')
		console.log('Failed on network: ' + NETWORK)
		console.error("stack: " + error.stack)
		printError(error) //needed?

		return null;
	}
}

/**
 * Returns false, or the txoid that was spent
 */
//export /* */
async function doesDbHaveTxoSpend(theDB, tx, outIndex, dbFindTxoFromTxidAndIndex, dbFindNextTxFromSpentTxo) {
	console.log("doesDbHaveTxoSpend(): checking if we already have some knowledge of outIndex " + outIndex +
				" of tx: " + tx);
	let spentTxo = null
	const aTxo = await dbFindTxoFromTxidAndIndex(theDB, tx, outIndex);
	let haveRecordOfSpend = false;
	if ( aTxo && aTxo !== null ) {
		console.log("doesDbHaveTxoSpend(): BTW: this tx's output has txoid of ", aTxo.txoid);

		console.log("doesDbHaveTxoSpend(): was this spent? Do we have a spentTxo with txoId of " + aTxo.txoid + " <===");
		spentTxo = await dbFindNextTxFromSpentTxo(theDB, aTxo.txoid);

		console.log("doesDbHaveTxoSpend(): Ok. we found spent txo: ", spentTxo);
		haveRecordOfSpend = spentTxo && spentTxo.txIdItFunds;
		console.log("doesDbHaveTxoSpend(): haveRecordOfSpend is " + haveRecordOfSpend);
	} else {
		console.log("doesDbHaveTxoSpend(): db doesn't even have record of the txo. So, returning false.")
	}

	return spentTxo //haveRecordOfSpend ? aTxo.txoid : null;
}

/**
 * Using the private keys of a builder, generate an Emergency Builder Fee Reduction Authorization
 *
 * @param {*} builderPrivP - part P of private key of a builder
 * @param {*} builderPrivQ - part Q of private key of a builder
 * @param {*} voteForReduction - true/false - whether to vote for a Fee Reduction
 */
 function signABuilderVote(builderPrivP, builderPrivQ, voteForReduction) {
	console.log("========================")

	// calculate rabin PKH from priv p, q

	const builderRabinPubKeyBigInt = privKeyToPubKey2(builderPrivP, builderPrivQ)          // we use this to sign2()

	console.log("\nRabin public key (BigInt) (for signing content) (integer is helpful when debugging)= " + builderRabinPubKeyBigInt)
	//console.log("\nbuilderRabinPubKeyLE = int2Asm(rabin public key bigInt) (little-endian) = ", int2Asm( builderRabinPubKeyBigInt ))

	//NOTE: we use this (little-endian) to generate PKH - because this is what the contract receives
	const builderRabinPubKeyLE = int2Asm(builderRabinPubKeyBigInt)       // used for executing contract - when updating content
	const builderRabinPubKeyLEBuffer = Buffer.from(builderRabinPubKeyLE, 'hex');

	//console.warn("\n    endian-swapped: ", swapEndian( builderRabinPubKeyLE ) + "\n")

	const builderRabinPKH = bsv.crypto.Hash.sha256ripemd160(builderRabinPubKeyLEBuffer).toString('hex')
	//console.log('\nPublisher rabin PKH is ', builderRabinPKH, ", with length ", builderRabinPKH.length, '\n')
	//console.warn("\nabout to calculate a special signature...")

	// The well-known message contains the builder's rabin Public Key itself (formerly used the PKH - which was less secure)
	//const completeMessage = '0102030405060708' + builderRabinPKH + '060708090a0b0c0d0e0f';
	let completeMessage = '0102030405060708' + builderRabinPubKeyLE + '060708090a0b0c0d0e0f';

	if ( voteForReduction ) {
		console.log("The message-to-sign for this Yay vote is different than that for a Nay vote.")
		completeMessage = '112233' + completeMessage + '445566'
	} else {
		console.log("The message-to-sign for this Nay vote is different than that for a Yay vote.")
		completeMessage = '778899' + completeMessage + 'aabbcc'
	}
	console.log("\ncomplete message: ", completeMessage + "\n\n")

	const sigInfo = getSigParts( Buffer.from(completeMessage, "hex"),
								builderPrivP, builderPrivQ,
								builderRabinPubKeyBigInt)
	//console.log("BTW: complete msg to sign = ", completeMessage)
	console.log("BTW: sigInfo = ", sigInfo)

	//console.warn("\nCalculated a SPECIAL signature.\n")

	//console.log("\n\nsigInfo.sigHex: ", sigInfo.sigHex);
	//console.log("   sigHex REVERSE: ", swapEndian( sigInfo.sigHex) )

	console.log("\n\nCalculated Emergency Builder Fee Reduction Authorization for builder with\nRabin PKH of " + builderRabinPKH)
	console.log("------------->  You'll need the REVERSED sigHex, the paddingBytes, and the builderRabinPubKeyLE  <----")
	if ( !sigInfo.sigHex || sigInfo.sigHex === null ) {
		console.warn("  BUT, it's undefined, or null, so, won't bother")
	} else {
		console.log("    reversed sigHex: ", swapEndian( sigInfo.sigHex))
	}
	console.log("    paddingBytes: ", sigInfo.paddingBytes)
	console.log('    builderRabinPubKeyLE: ', builderRabinPubKeyLE)
	console.log("-------------- (unless using debugger) ---------------\n")

	return {
		sigInfo: sigInfo,
		pubkeyLE: builderRabinPubKeyLE  //FIXME: swapEndian()?
	}
}

// node utility needs module.exports, but abhors function export
module.exports = {
//export {
       generateLockingScript,

       generateBuilderPrepLockingScript,
       generateAppendLockingScript,
       generateEBFRALockingScript,
       generateContinueLockingScript,
       generateUpdateLockingScript,
       generateTransientLockingScript,
       generateBitGroupLockingScript,
       generateAdministerGroupLockingScript,
       generateAskBidLockingScript,
       generateDialogLockingScript,

       buildHardcodedBuilderPrepStateData,
       buildHardcodedAppendStateData,  //these aren't really much
       buildHardcodedEbfraStateData,
       buildHardcodedContinueStateData,  //<--- this one needs interaction :(
       buildHardcodedUpdateStateData,
       buildHardcodedTransientStateData,
       buildHardcodedBitGroupStateData,
       buildHardcodedAdministerGroupStateData,    // this had some interaction
       buildHardcodedAskBidStateData,
       buildHardcodedDialogStateData,

       buildAndSendBuilderPrepLockingTxWithThisInitialState,
       buildAndSendAppendLockingTxWithThisInitialState,
       buildAndSendEbfraLockingTxWithThisInitialState,
       buildAndSendContinueLockingTxWithThisInitialState,
       buildAndSendUpdateLockingTxWithThisInitialState,
       buildAndSendTransientLockingTxWithThisInitialState,
       buildAndSendBitGroupLockingTxWithThisInitialState,
       buildAndSendAdministerGroupLockingTxWithThisInitialState,
       buildAndSendAskBidLockingTxWithThisInitialState,
       buildAndSendDialogLockingTxWithThisInitialState,

       libBuildOnBuilderPrepOutput,
       libBuildOnAppendOutput,
       libClaimAndPublish,
               libApplyBuilderFeeReduction,
               libAnnounceBuilderFeeReductionAuth,
               libGetOptionsForAskBidSubMode,
               libBuildOnAskBid,  // might never use this
       libBuildOnUpdate,
       libBuildOnTransient,
       libBuildOnDialog,
       libBuildOnBitGroup,
       libBuildOnAdminGroup,

       validateRabinPrivKeys,
       verifyBase64,
       calcPayoutFromBlockAndGenesis,

       decomposeRawTx,
       parseOutputs,
       parseInputs,
       getUsefulInputParams,
       decodeScriptsToState,

       convertFromBase64,  // not sure if anyone needs this

       checkForExpectedUTXO,
       getScriptHashMinedBlockHeight,
       findTheNextTx,
       findThePrevTx,
       doesDbHaveTxoSpend,
       signABuilderVote
};