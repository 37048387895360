const { bsv } = require('scryptlib');


// WARNING: if changing keys because of "TOO LONG MEMPOOL", be sure to start an entirely
//          new funding tx - that DOESN'T build on a tx that won't confirm.

// 'official' key (circa August 2022)
// const key = 'cQwMjaDdfBcwN9frRx4Mh8GnS4hkiASSoANxdts3KZcfgiGYeSRG'
//btw, public address: mx1kK4zuCiWPqk85dckE8118s1YBFo9vQd
//btw, PKH: 6fb4f412ad3783f72609e391ccc295f863791f54de


// fill in private key on testnet in WIF here
// This had trouble on 10/6/2020. Tried again at noon on Feb 22, 2021. Abandoned at 1:20PM
// Picked up again Mar 20, 10am
//   abandoned again on Mar 24 2:30pm
//   returned Nov 29, 2021. hmm. immediate problem: too-long-mempool-chain
//const key = 'cVQMJ1RDAe8jVnvXUQMMaLLnqyVEA4acwmVx4HKicQ5PrGRa5fSs'
//btw, public address: msHtTyGVo8rjaSxnaVkwmD5Co151URrGan
//btw, PKH: 8128e7d54a00a3011180df0f8fae57829a1a1f65
//  Q <---

// This had trouble on 10/8/2020 - hadn't confirmed in a while. Picked this up again on Feb22, 2021,
// but a few hours later had "too long mempool" again
//    Resumed using at 2:30pm on Mar 24, 2021
const key = 'cUmHdLpf1bPFGrQRuLfC5AmiMYWFpG9nC5Xp4gwNUNj4mcA3Syhf'
//    Stopped using at 9:45am on June 22, 2021 - had trouble with new locking tx:
//      7dc0069d128930d496f273eb871af0c1ed17c8bb9c06f383aad5b47baaf4518c
// This may have issues/ancestors that haven't cleared
//btw, public address: moM78sM4JD6LP69yukvdu435Dxn4UfsJbt
//btw, PKH: 55e43a2edb9de30e47f138961915bdef66fcfe0d
// R <---

// This key had trouble on 10/10/2020 - went 50 txs since last confirmation
// Returned here on Nov 19. Abandoned on Feb 22 (mempool too long (~64 txs)
//         returned on Mar 3, 2021 (2:24pm)
//         Trouble again on Mar 16
//         stuck on a Continue on 12/5/21
//const key = 'cNL4Hw8YYFw58S2hPrMRYEWUFkExvnSUsjCFGxUPsQAFtyinTXVM'
//btw, pub address muE4dzmzchQWgVTHb1Ndvwhb7P8uGTBEwP
//btw, PKH: 965ffc35d54b0cfc5c2296f74c0ffb72d56d6822
// VISITOR <------

// This key had trouble on 10/13/2020 - 50 txs.
//    BTW: reverted back to this on Oct 26, 2020,
//          but maybe ran out of funds on Oct 28.
//          Back on Feb 22, 2021
//          trouble on Mar 3, 2021 (2:20pm)
//const key = 'cPX5kbfXoVTr1SUvRmFDhCdPR171FUda27TaZJ4A7bhdQ32hPYoY'
// btw, pub address: mwpGSvDZAxwhFPSjzTEsT9rw8LXcPmnnQj
// btw, PKH: b2c83cb36d3f7ca156c357c5804d6b36e8b51068
// OWNER <------

// This key should have no trouble. We've started using 1sat/B
// On Oct 26, 2020 I had mempool conflicts, so, reverted to prev key
// Returned to this on Oct 28.
// Abandoned this on Nov 19 because I set nLockTime to the future
// Returned at 1:20PM on Feb 22, 2021 - but increased DEFAULT_FEE_PER_KB from 1200 to 2040
//   Abandoned at 2pm - because I had neglected to start from a new locking/funding tx
// Returned on Mar 16 at 3:35pm
// Abandoned March 20, 10am
// returned on June 22, 2021 9:50am, and then 11:27am. But then generated a brand new one at 12:51 on June 22, 2021
//const key = 'cRhc4uDpEWFAQrHNPsMUCMEo1mKP5Aar7wc6pfbS5Wx7arUXBnGZ'
// btw, pub address: mr5ad79SGCyuXSr29CybBtKpVAaECgZbhv
// btw, PKH: 73dce4e4ca837a62712593f6892d47d51a7732e5
// S <----

// BRAND NEW: June 22, 2021
//const key = 'cVAp9XG6sRqBSkWr54hQYGaDiJ9hLbiNNfLT7xUvaL6NvaAQZjPz'  //'n1Ta2BGgQDaXUhthrJDeaSYhDsgCSyFyK8'

// BRAND NEW: June 22, 2021 2:03pm
//            Restarted 11/11/21
//            stopped on 11/29/21 (accidentally built into the future)
// BALANCE: 54mil sats?
//const key = 'cSpSHuWZKnWTAB5a462jf9RxdsjqSwZE7Hczic7ECryV1MZ9eRsG'  // muv26uKPcR2JTJBipzZ6zGsFKppS9CVdhA



// BRAND NEW 2:42pm
// started using again on 7/21/21 at 5:27pm
// stopped on 9/6/21 9:35am
// re-started on 10/22/21 at 4:11pm
// stopped on 11/11/2021 - accidentally broadcast a txyears in the future
//const key = 'cTGRstyFhWsSoXXR2wVKLbvNRAwbBZ8Y5pn7rTKgDfkV29wzXcKp' // n3p45o5YfXkBAPqBvB2rtngJDUTwhabaSY


// BRAND NEW 2:54pm
// stopped using 7/21/21 5:26pm
// returned on 9/6/21 9:35am
// stopped on 10/22/21 - even though it had funds
// returned on 11/29/21 1pmish  stoped 6:30pm ish (too long mempool chain)
//const key = 'cTNfLrp1HwemZERgJg4jyLz68abDFZxVHEirUijqNEjFdUr8fZdK'
//btw, public address mqDWe6Df4Yqyt8FZM3ycMNYb7rzcZeGvWJ
//btw, PKH: 6a64c56a8c7ceb4449cc344f9b24aca68dd8357b
//
// Use for 'B' - but may be stuck for now


if (!key) {
  genPrivKey()
}

function genPrivKey() {
  const newPrivKey = new bsv.PrivateKey.fromRandom('testnet')
  console.log(`Missing private key, generating a new one ...
Private key generated: '${newPrivKey.toWIF()}'
You can fund its address '${newPrivKey.toAddress()}' from some faucet and use it to complete the test
Example faucets are https://faucet.bitcoincloud.net and https://testnet.satoshisvision.network`)
  throw new Error(); //exit(0)
}

//export /* */
const privateKey = new bsv.PrivateKey.fromWIF(key)

/* */
module.exports = {
  privateKey,
  genPrivKey
}
/* */
