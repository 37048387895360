import React from 'react';

import { Button, Checkbox, Divider, Form, Icon,
    Message, Modal, Popup, Table } from 'semantic-ui-react'

import PostPopup         from './postPopup.js'

import { PAYLOAD_ESCAPE_FLAG } from './harnessConstants.js'

import {
    //saveEncryptedKeys,
    getEncryptedKeysFromPKH,
    //getP2PKHsJson,
    openDB,

    readSetting,
    saveSetting,

    //saveEncryptedP2PKHKey,
    //generateRabinPrivateKeyPlus,

    //allocateAvailableRabin,

    //encryptData,
    decryptData,
    //getRabinsJson,
    //getOfficialWalletJson,
    //findAllDomainsOfMine,

    //outdateDomainOfMine,

    //getAvailableRabinsCount,
    //getAvailableP2PKHsCount,

    //findAllMyBuiltDomains,

    //findLatestUTXO,

    getNextDialog,
    findNextTxFromProvider,

    //findADialog,
    findADialogById,
    modDialogFurthest,

    buildOnDialog,
    queryFetchDecodeTx,
    analyzeContentPrefixFlag,
    mySleep,
    simpleDecode,
} from './buildShizzle.js';

import {
    getBitcoinDomainName,
    encryptMessage,
    decryptContent,
} from './commonReact';

import './index.css';

import { getBulkUnspentScripts } from "./providers";

import * as eccryptoJS from 'eccrypto-js';
import { requestFullScreen, fetchPublishedVideo } from './commonFuncs.js';
// for private keys, for encrypting
const {  bsv  } = require('scryptlib');

// from https://stackoverflow.com/questions/40031688/javascript-arraybuffer-to-hex
// AND copied from surfer
function buf2hex(buffer) { // buffer is an ArrayBuffer
    return [...new Uint8Array(buffer)]
        .map(x => x.toString(16).padStart(2, '0'))
        .join('');
}

class DialogManager extends React.PureComponent {
    constructor(props) {
        super(props);

        this.fontFamily          = "verdana"

        //FIXME: the purples are currently duplicated in index.css
        this.bshzPurple             = '#8313e2';
        this.bshzYellow             = "#fff300";
        this.bshzLtPurp             = '#e7d0fb';
        this.bshzLightYellow        = "#fffccc";

        this.shizzleVerse           = <><b>ShizzleVerse</b><small>&trade;</small></>;

        this.refToIFrm              = React.createRef()

        // for email-like tracking
        //this.toRespondTo    =  new Set() //this.props.dialogsToRespondTo        //new Set()
        //this.yetToHearBack  =  new Set() //this.props.dialogsYetToHearBackFrom  //new Set()
        //this.areActive       =  new Set() //this.props.activeDialogs             //new Set()
        //this.areClosed        =  new Set() //this.props.closedDialogs             //new Set()

        this.state =  {
                        dialogRecords: null,
                        hideClosedDialogs: false,
                        relevantDialogsCount: 0,

                        spentRecs: null,

                        latestTxId: null,   //FIXME: take care to advance this properly after
                                            // building an end-of-the-line tx

                        selectedDomain: '',           //FIXME: rename to selected identity?

                        guestPostPriceForThisIdentity: 100000000,
                        maxMilliPenniesToComment: 0,

                        showSwirly: false,
                        swirlyText: '',


                        content: '',          // Current DISPLAYED content.
                        contentEncrypted: false,
                        outgoingContent: '',  // USER's message to send.   NOTE: TextArea doesn't like null
                        encryptDialogPost: true,


                        dialogId:   null,


                        showRespondToModal: false,
                        firstDialogTx:     null,
                        domainOfInitiator: null,

                        showNavigationModal: false,
                        domainOfOtherGuy:  null,    // <------- FIXME: use this instead of domainOfInitiator <---

                        authorName:         null,
                        byTheUser:          false,
                        postNum:            0,
                        isOwner:            false,

                        thisWillBeFinalPost:    false,

                        showPostPopup:          false,


                        mediaURL:           '',
        }

        this.calculateFurthestPostNum   = this.calculateFurthestPostNum.bind(this);

        this.getSpentFromId             = this.getSpentFromId.bind(this);

        this.getSharedKey               = this.getSharedKey.bind(this);
        this.maybeAlterContent          = this.maybeAlterContent.bind(this);
        this.maybeEncryptMsg            = this.maybeEncryptMsg.bind(this);
        this.postResponse               = this.postResponse.bind(this);
        this.handleSubmitPost           = this.handleSubmitPost.bind(this);
        this.ensureAtTip                = this.ensureAtTip.bind(this);

        this.closeDialogManager         = this.closeDialogManager.bind(this);
//        this.checkForDialogsOfOurIdentity = this.checkForDialogsOfOurIdentity.bind(this)

        this.buildDialogsList           = this.buildDialogsList.bind(this);
        this.dialogChosen               = this.dialogChosen.bind(this);

        this.checkAllFurthestUtxos      = this.checkAllFurthestUtxos.bind(this);

        this.seekProviderToFinalDialogPost = this.seekProviderToFinalDialogPost.bind(this);

        this.getListsStatusFromId       = this.getListsStatusFromId.bind(this);
        this.getInfoAboutDialog         = this.getInfoAboutDialog.bind(this);
        this.getAuthorNameAndPostNum    = this.getAuthorNameAndPostNum.bind(this);
        this.createDialogRecords        = this.createDialogRecords.bind(this);

        this.goTo0thPost                = this.goTo0thPost.bind(this);
        this.goBack                     = this.goBack.bind(this);
        this.goForward                  = this.goForward.bind(this);
        this.goToFurthestPost           = this.goToFurthestPost.bind(this);

        this.closeRespondToModal        = this.closeRespondToModal.bind(this);
        this.closeNavigationModal       = this.closeNavigationModal.bind(this);

        this.toggleCloseThisModal       = this.toggleCloseThisModal.bind(this);
        this.toggleHideClosedDialogs    = this.toggleHideClosedDialogs.bind(this);

        this.openPostPopup              = this.openPostPopup.bind(this);
        this.handlePostData             = this.handlePostData.bind(this);
        this.closePostPopup             = this.closePostPopup.bind(this);
    }

    closeDialogManager() {
        this.props.closeManager()
    }

    async toggleHideClosedDialogs() {

        // save change to settings (IDB)
        await saveSetting(await openDB(), 'hideClosedDialogs', !this.state.hideClosedDialogs)

        this.setState({hideClosedDialogs: !this.state.hideClosedDialogs})
    }

    /**
     * Reads from dialogTab - for state.dialogsOfInterest (which are static properties in this module)
     *
     * Called by componentDidMount(), and after building a new post
     *
     * @returns a SET of relevant records, suitable for perusaal
     */
    async createDialogRecords() {

        const db = await openDB()

        const dialogRecords = new Set()

        console.warn("DialogManager cDR(): dialogs of interest: ", this.props.dialogsOfInterest)
        for ( let i = 0; i < this.props.dialogsOfInterest.length; i++ ) {
            console.log("adding DIALOGUE of interest: " + this.props.dialogsOfInterest[i] + " <-----")

            const dialogRec = await findADialogById(db, this.props.dialogsOfInterest[i])
            console.log("    record of interest: ", dialogRec)

            dialogRecords.add( dialogRec )
        }

        return dialogRecords
    }

    async componentDidMount() {
        // If we got here because we just filled-out a survey, let's scroll back up to the top
        //window.scrollTo(0, 0)

        console.warn("dialogManager componentDidMount()")
        console.warn("There are " + this.props.getDialogsYetToHearBackFrom().length + " yetToHearBack (pending)")
        console.warn("There are " + this.props.getDialogsToRespondTo().length + " toRespondTo")
        console.warn("There are " + this.props.getActiveDialogs().length + " active")
        console.warn("There are " + this.props.getClosedDialogs().length + " closed")


        if ( !this.props.dialogCallbackToParent ) {
            alert("Code Error: DM needs a parent callback function")
            throw new Error("DM needs a parent callback function")
        }

        if ( this.props.parent && this.props.registerClient ){
            this.props.registerClient(this.props.parent, this, "dialogManager")
        }

        // get initial balance from app
        this.balancePennies = this.props.balancePennies
        this.bestUtxoPennies = this.props.bestUtxoPennies

        // get ready to receive wallet balance updates from app
        if ( this.props.returnBalanceCallback ) {
            this.props.returnBalanceCallback( this, this.recordBalance )
        }

        // soon we'll use this to build transactions
        if ( this.props.bundle ) {
            this.bundle = this.props.bundle
            // .p   .address   .pwd
        }

        const db = await openDB()
        // get the 'hideClosedDialogs' setting
        const hcd = await readSetting(db, "hideClosedDialogs")
        let hide = false
        if ( hcd !== null ) {
            hide = hcd.value

            console.warn("Retrieved hideClosedDialogs: ", hide)
        }

        const dialogRecords = await this.createDialogRecords()

        console.warn("cDM(): here are the records: ", dialogRecords)

        // If we're about to jump to a SINGLE dialog (not the summary page),
        // don't bother with checking ALL of the dialog UTXOs
        // REGARDLESS, both options need the dialogRecords to have been set.
        const finalProcessing = this.props.jumpToSingleDialog ?
                            async function() {
                                console.warn("DM cDM(): jump directly to dialogue " + this.props.jumpToSingleDialog)

                                this.dialogChosen(null, this.props.jumpToSingleDialog)
                            }
                        :
                            this.checkAllFurthestUtxos

        this.setState(  {
                            dialogRecords: Array.from(dialogRecords),
                            hideClosedDialogs: hide,
                        }, finalProcessing)

    } // componentDidMount()

    componentWillUnmount() {
        console.log("DIALOGUE MANAGER about to unmount. Will UNregister...")

        if ( this.props.parent && this.props.unregisterClient ) {
            this.props.unregisterClient(this.props.parent, this, "dialogManager")
        }


        // fix Warning: Can't perform a React state update on an unmounted component
        // see: https://stackoverflow.com/questions/53949393/cant-perform-a-react-state-update-on-an-unmounted-component
        this.setState = (state,callback)=>{
            return;
        };
    }

    /**
     * Performs a bulk query on the leading output of every dialog (using .furthestScriptHash)
     */
    async checkAllFurthestUtxos() {

        let isSpent = new Array( this.state.dialogRecords.length )

        let scriptHashesToQuery = new Array( this.state.dialogRecords.length )
        let mapsToStateRecIndex = new Array( this.state.dialogRecords.length )
        let mapsToDialogId =      new Array( this.state.dialogRecords.length )
        let querySize = 0
        for (let i = 0; i < this.state.dialogRecords.length; i++ ) {
            const rec = this.state.dialogRecords[i]
            const dialogId = rec.dialogId

            // make sure to not query any dialog that is already CLOSED
            if ( this.getListsStatusFromId( dialogId ).closed ) {
                console.warn("We need to EXCLUDE dialogue " + dialogId + " from our query of spent/unspent. We already know it's closed.")
                continue
            }

            scriptHashesToQuery[ querySize ] = rec.furthestScriptHash
            mapsToStateRecIndex[ querySize ] = i
            mapsToDialogId[querySize] = dialogId
            querySize++
        }
        scriptHashesToQuery.length = querySize
        mapsToStateRecIndex.length = querySize
        mapsToDialogId.length = querySize
        isSpent.length = querySize


//fixme: if more than 20 dialogs, do in batches
//ALSO: don't bother with CLOSED dialogs

        try {

            const results = await getBulkUnspentScripts( scriptHashesToQuery, "FOR VARIOUS DIALOGS", [], true )

            for ( let j = 0; j < results.length; j++ ) {
                console.log("result " + j + " for scripthash " + results[j].script + ":")
                console.log("    any error?: " + results[j].error)
                console.log("    unspent: ", results[j].unspent)

                const actualRecIndex = mapsToStateRecIndex[j]
                const actualDialog   = mapsToDialogId[j]
                if ( results[j].unspent.length === 0 ) {
                    console.warn("    THAT WAS SPENT - but note, we need to calc the real index")
                    console.log("      mapsToStateRecIndex[" + j + "] = " + actualRecIndex  + "  - dialogue " + actualDialog)

                    isSpent[j] = {
                                    dialogId: actualDialog,
                                    isSpent:  true,
                                 }
                } else {
                    console.log("    That is still unspent - but note, we'd need to calc the real index")
                    console.log("      mapsToStateRecIndex[" + j + "] = " + actualRecIndex )

                    isSpent[j] = {
                                    dialogId: actualDialog,
                                    isSpent:  false,
                                 }
                }
            }

            this.setState({spentRecs: isSpent})

        } catch (error) {
            console.error("Trouble getting bulk unspent status of dialogs: ", error)
            this.setState({spentRecs: null})
        }
    }

    /**
     * Derive a shared key from a private key (from the user's funding input), and a public key
     * (from the other dialog party's funding input)
     * @param {*} dtx
     * @param {*} dialogInfo
     * @param {*} p
     */
    getSharedKey(dtx, dialogInfo, p) {

        if (typeof dtx.dialogSentBy == 'undefined' ) {
            throw new Error("CODE ERROR trying to extract shared key. Undefined .dialogSentBy")
        }

        const authorIsOwner = dtx.dialogSentBy === 'owner'
        console.warn("sent by " + dtx.dialogSentBy)

        console.log("getSharedKey(): dialogInfo: ", dialogInfo)

        /////////////////////////////////////////////////////
        // CODE TO BE SHARED BY  ENCRYPTION and DECRYPTION ?

        let pubKeyToUse
        if ( !dialogInfo.weOwnThis ) { //!authorIsOwner ) {
                        //pubKeyToUse = dtx.dialogMostRecentOwnerPubKey
            pubKeyToUse = dialogInfo.ownerPubKey
            console.warn("gsk(): WE're not the owner, so, will use OWNER pubkey (and our private): " + pubKeyToUse)
        } else {
            pubKeyToUse = dialogInfo.visitorPubKey
                        //pubKeyToUse = dtx.dialogMostRecentVisitorPubKey
            console.warn("gsk(): WE're the owner, so, will use Visitor pubkey (and our private): " + pubKeyToUse)
        }

        const pubToUseBuffer = Buffer.from( pubKeyToUse.toString(), 'hex')
        //console.warn("pubToUseBuffer: ", pubToUseBuffer)

        //console.log("note 1b  this.props.bundle.p: ", this.props.bundle.p)
        const privKeyHex = new bsv.PrivateKey.fromWIF( p ) //this.props.bundle.p )

        //console.log("privKeyHex: ", privKeyHex.toHex())
        const privToUseBuffer = Buffer.from( privKeyHex.toHex(), 'hex')
        //console.warn("privToUseBuffer: ", privToUseBuffer)

        const sharedKey = eccryptoJS.derive( privToUseBuffer, pubToUseBuffer );
        //console.warn("shared Key: " + sharedKey.toString('hex'))

        //       Why not share?
        /////////////////////////////////////////////////////

        return sharedKey
        //FIXME: try/catch ?
    }

    /**
     * IF the content has a prefix flag which indicates it's encrypted, this will
     * attempt to decrypt it.
     *
     * NOTE: similar to ShizzleView's maybeDecryptContent()
     */
    async maybeAlterContent(state, dialogId, contentHex) {

        //WARNING: this assumes cipherText is found in input0Params
        const analysis = analyzeContentPrefixFlag( state )

        console.warn("MAYBE alter content: ", analysis)

        let contentAsHex = ''

        if ( analysis.needToDecrypt ) {
            console.warn("We could DECRYPT this content - for dialogue " + dialogId)

            const dialogInfo = this.getInfoAboutDialog(dialogId)

            const sharedKey = this.getSharedKey(state, dialogInfo, this.props.bundle.p)

            // decrypt the PAYLOAD (escape sequence has been stripped-away - and placed in analysis.contentToPresent)
            const clearText = await decryptContent( analysis.contentToPresent, sharedKey )

            if ( !clearText ) {
                alert("DM: maybeAlterContent(): decrypt failed. Pay attention to the content.")

                //FIXME: this is a confusing flag
                this.setState({contentEncrypted: false})

                contentAsHex = contentHex

            } else {
                this.setState({contentEncrypted: true})
                contentAsHex = Buffer.from(clearText, 'utf8').toString('hex')
            }

        } else {
            this.setState({contentEncrypted: false})
            contentAsHex = contentHex
        }

        let mediaType = ''
        let videoURL = ''
        let videoType = ''
        let videoBuff64 = ''
        let success = true
        let failurePoint = 0
        if ( analysis.contentAltered ) {
            //TODO: support other flags/formats
            if ( analysis.contentAttached ) {
                state.contentAttached = true
                state.attachedContent = analysis.attachedContent
                state.alteredContent  = Buffer.from( analysis.contentToPresent, 'utf8' ).toString('hex')

                contentAsHex = state.alteredContent
            } else if ( analysis.referencedMediaTxId !== null ) {

                const videoParams = await fetchPublishedVideo( analysis.referencedMediaTxId )

                state.referencedMediaTxId = analysis.referencedMediaTxId
                state.alteredContent  = Buffer.from( analysis.contentToPresent, 'utf8' ).toString('hex')

                contentAsHex = state.alteredContent

                success      = videoParams.success
                failurePoint = videoParams?.failurePoint

                if ( success ) {
                    mediaType   = videoParams?.mediaType
                    videoType   = videoParams?.videoType
                    videoBuff64 = videoParams?.videoBuff64
                    videoURL    = videoParams?.mediaURL
                }
            }
            state.contentAltered = true
        }

        const newState = JSON.parse( JSON.stringify( state ) )

        return {
                success:        success,
                failurePoint:   failurePoint,

                hexContent:     contentAsHex,
                newState:       newState,

                videoType:      videoType,
                videoBuff64:    videoBuff64,
                mediaType:      mediaType,
                videoURL:       videoURL,
               }

    } // maybeAlterContent

    async maybeEncryptMsg(yesEncrypt, dtx, dialogInfo, authorIsOwner, contentString, isActuallyHex = false) {
        let contentToSend

        if ( yesEncrypt ) {
            console.error("maybeEncryptMsg(): we should ENCRYPT")

            console.warn(" dtx: ", dtx)
            console.warn("  dialogMostRecentOwnerPubKey:    " + dtx.dialogMostRecentOwnerPubKey)
            console.warn("  dialogMostRecentVisitorPubKey: " + dtx.dialogMostRecentVisitorPubKey)


            let pubKeyToUse
            if ( !authorIsOwner ) {
                            //pubKeyToUse = dtx.dialogMostRecentOwnerPubKey
                pubKeyToUse = dialogInfo.ownerPubKey
                console.warn("mE(): Guest (us) is sending, so, will use OWNER pubkey: " + pubKeyToUse)
            } else {
                pubKeyToUse = dialogInfo.visitorPubKey
                            //pubKeyToUse = dtx.dialogMostRecentVisitorPubKey
                console.warn("mE(): Owner (us) is sending, so, will use Visitor pubkey: " + pubKeyToUse)
            }

            const pubToUseBuffer = Buffer.from( pubKeyToUse.toString(), 'hex')

            //console.log("note 1b  this.props.bundle.p: ", this.props.bundle.p)
            const privKeyHex = new bsv.PrivateKey.fromWIF( this.props.bundle.p )

            //console.log("privKeyHex: ", privKeyHex.toHex())
            const privToUseBuffer = Buffer.from( privKeyHex.toHex(), 'hex')
            //console.warn("privToUseBuffer: ", privToUseBuffer)

            const sharedKey = eccryptoJS.derive( privToUseBuffer, pubToUseBuffer );
            //console.warn("shared Key: " + sharedKey.toString('hex'))

            //       Share with self-posts
            /////////////////////////////////////////////////////


            console.warn("DM: Will encrypt this UTF8 message: " + contentString)

            const result = await encryptMessage( sharedKey, contentString, isActuallyHex)

            if ( !result.success ) {
                throw new Error("Failed to encrypt")
            }

            contentToSend = result.ciphertext
        } else {
            console.log("DM: Will use the CLEAR text (in hex)")
            if ( isActuallyHex ) {
                contentToSend = contentString
            } else {
                // convert utf string to a HEX string
                contentToSend = Buffer.from(contentString, 'utf8').toString('hex')
            }
        }

        return contentToSend

    } // maybeEncryptMsg()

    /**
     * Helps get us to the furthest post in the IDB. This does NOT query a provider
     *
     * @param {*} dtx
     * @param {*} dlgInfo
     * @param {*} dialogOutNum
     * @param {*} db
     * @param {*} finalProcessing
     * @returns
     */
    async ensureAtTip(dtx, dlgInfo, dialogOutNum, db, finalProcessing = async function() { }) {

        const postNum = dtx.outputStates[ dialogOutNum ].postNum
        const furthestPostNum = dlgInfo.furthestPostNum

        if ( postNum < furthestPostNum ) {
            console.warn("ensureAtTip(): we're at post " + postNum + ", but need to be at " + furthestPostNum)

            const success = await this.jumpToPostWhichFollows(  db,
                                                this.state.dialogId,
                                                furthestPostNum - 1,
                                                false,   // fwd, not reverse
                                                true,    // preserve outgoingContent <----- important for delayed call to handleSubmitPost()
                                                finalProcessing)

            console.warn("ensureAtTip(): we've just jumped. Success was " + success + ". We're about to ABANDON this thread, so that the jumpTo...(), can then do its finalProcessing, which will be to actually BUILD the post (hopefully)")
            console.warn("ensureAtTip(); regardless of success, " + success + ", returning back FALSE (to handleSubmitPost()), so it WON'T (hopefully) try an extra build")

            // we were not at the tip
            return false
        }

        // we were at the tip
        return true
    }

    /**
     * Before building a post, calls ensureAtTip() - which calls handleSubmitPost() AGAIN as finalProcessing,
     * after its setState() finishes
     *
     * NOTE: finalProcessing() defaults to blank function, but will be called after post succeeds, OR fails
     *
     * NOTE: two dummy params to allow direct calls, and .onClick() calls to co-exist
     */
    async handleSubmitPost( event,
                            blah = null,
                            finalProcessing = function() { }) {

        const dtx = this.state.dtx
        //console.log("handleSubmitPost(): Here's the latest dtx: ", dtx)

        const db = await openDB()

        let dialogOutNum = 0
        while ( dtx.outputStates[dialogOutNum]?.contract === 'transient' ) {
            dialogOutNum++
        }
        console.log("handleSubmitPost(): dialogOutNum: ", dialogOutNum)

        const dlgInfo = this.getInfoAboutDialog(this.state.dialogId)
        console.error("handleSubmitPost(): INFO ABOUT dialogue " + this.state.dialogId + ": ", dlgInfo)

        const me = this
        if ( ! await this.ensureAtTip(  dtx,
                                        dlgInfo,
                                        dialogOutNum,
                                        db,
                                        async function() {
                                            //console.warn("hsPost() final processing: btw: outgoingContent: " + this.state.outgoingContent)
                                            console.warn("We're FINALLY at the tip, doing our finalProcessing - calling handleSubmitPost() !")
                                            const success = await me.handleSubmitPost(null, null, null)
                                            console.warn("handleSubmitPost(): finalProcessing (calling handleSubmitPost()): success? " + success)
                                        }
                                      ) ) {

            console.warn("handleSubmitPost(): we're back from ensureAtTip(), but we weren't at tip, so, stop/return. FinalProcessing will soon pick-up where we've left off - once we're at the tip.")
            return
        } else {
            console.log("handleSubmitPost(): ensureAtTip succeeded! IF it had to jump to the end of the dialog, it's ALREADY run finalProcess().")
        }

        this.setState({disablePubButton: true})
        console.warn("handleSubmitPost(): encrypt? " + this.state.encryptDialogPost)

        //console.log("handleSubmitPost(): finalProcessing: ", finalProcessing)

        //event.preventDefault()

        console.log("handleSubmitPost(): We should build on txId: ", this.state.latestTxId)

        //NOT READY YET
        //        const prevBlockNum = dtx.outputStates[ nextOutputIdx ].blockNumInt    //blockNum
        //        console.warn("prev blockHeight: ", prevBlockNum)


        const dialogId = this.state.dialogId
        const isOwner = this.state.isOwner

        console.log("DTX is ", dtx)


        let stateOnWhichToBuild = dtx.outputStates[ dialogOutNum ]
        let relevantRabinPKH = isOwner ?
                                dtx.outputStates[ dialogOutNum ].ownerRabinPKH
                            :
                                dtx.outputStates[ dialogOutNum ].guestRabinPKH

        console.log("Will search for encrypted blob for rabin pkh: ", relevantRabinPKH)

        // THIS give us the authorization to unlock/spend the previous tx/output
        const obj = await getEncryptedKeysFromPKH(db, relevantRabinPKH);
        console.log("handleSubmitPost(): got encrypted json object: ", obj);


        // These MUST be derived from the PREVIOUS rabin
        let rabinPrivKeyP
        let rabinPrivKeyQ
        try {

            const plainBundle = await decryptData(obj.blob, this.props.bundle.pwd);

            //console.log("handleSubmitPost(): decrypted json string:", plainBundle);
            console.log("handleSubmitPost(): DECRYPTED bundle for rabin PKH " , relevantRabinPKH)

            const bundledObject = JSON.parse(plainBundle);

            rabinPrivKeyP = bundledObject.p
            rabinPrivKeyQ = bundledObject.q
            console.log("handleSubmitPost(): retrieved keys...")
        } catch ( error ) {
            console.error("handleSubmitPost(): Bad decrypt for rabin PKH " + relevantRabinPKH + ". Probably provided wrong password.");

            alert("there's something wrong. Failed to retrieve keys. Bad password?")
            //console.error("ERASE THIS: pwd: ", this.props.bundle.pwd)

            finalProcessing()
            return false
        }


        this.setState({ showSwirly: true,
                        swirlyText: 'Building, and broadcasting your Dialogue post...'
                      })

        console.log("handleSubmitPost(): about to build contract...")
        let newTxId = null
        let theTransientResults = null
        try {
            console.warn("Building on a Dialogue")

            //FIXME: ponder blockHeight policy - in contract, and in app
            //       We need to be careful to avoid a non-final transaction based on the minBlockNum
            //const blockHeightInt = dtx.outputStates[ dialogOutNum ].blockNumInt

            // The dialog contract almost enforces NOTHING about this
            // BUT: can be EMPTIED after maxBlock
            //      could accidentally create a future-spoofer by setting > maxBlock
            const blockHeightInt = dtx.outputStates[ dialogOutNum ].maxBlock - 365*36

            console.error("WARNING: building dialogue with GUESS-of-a block num: ", blockHeightInt)

            const finalPost = this.state.thisWillBeFinalPost
            console.warn("finalPost? " + finalPost)

            //FIXME: well, we really need to build on the most-advanced txid
            const latestTxIdOnWhichToBuild = this.state.latestTxId

            //FIXME: we can probably do this better - passing isOwner on user CLICK
            //alert("improvement can be made here. Building on dialogId " + dialogId)

            // at some point: decide on best policy, if any, wrt blockHeight
            console.error("DialogManager(): temporarily using blockHeightInt of " + blockHeightInt)

            let hex1_flag = ''
            let hex2_len = ''
            let hex3_attachment = ''
            let hex4_ref = ''
            if ( this.state.addPhotoToPost ) {
                console.warn("DM: handleSubmitPost(): looking to prepend image/attachment of size ", this.state.photoBlob.length , " bytes.")
                hex1_flag = Buffer.from( PAYLOAD_ESCAPE_FLAG +'IA' ).toString('hex')
                console.warn("hex1: ", hex1_flag)
                hex2_len      = this.state.photoBlob.length.toString(16)
                console.warn("DM: handleSubmitPost(): INITIAL attachmentLenHex: ", hex2_len)

                // use exactly 4 bytes (8 hex chars) for length
                while (hex2_len.length < 8) {
                    hex2_len = '0' + hex2_len;
                    //console.warn("handleSubmitPost():  attachmentLenHex: ", hex2_len)
                }
                console.warn("DM: handleSubmitPost(): FINAL attachmentLenHex: 0x" + hex2_len)

                console.warn("DM: here's the photoBlob (actually passed as an arrayBuffer from loaders): ", this.state.photoBlob)
                hex3_attachment = buf2hex(this.state.photoBlob)
                console.log("DM: photoHexString: " + hex3_attachment)
                console.warn("DM: BYTE length of: " + hex3_attachment.length / 2)
            } else if ( this.state.addVideoToPost ) {
                console.warn("DM: handleSubmitPost(): looking to prepend reference of media txId ", this.state.videoToAdd + ".")
                hex1_flag = Buffer.from( PAYLOAD_ESCAPE_FLAG +'MR' ).toString('hex')
                console.warn("DM: hex1: ", hex1_flag)

                hex4_ref      = this.state.videoToAdd
                if ( this.state.videoToAdd.length !== 64 ) {
                    alert("DM: Unexpected video TxId length (should be 64 chars): " + this.state.videoToAdd.length + " for txId " + this.state.videoToAdd + ". ABORTING post.")
                    return
                }
                console.warn("DM: handleSubmitPost(): hex4_ref: ", hex4_ref)
            }

            const contentAsHexString =  hex1_flag +                     // either blank, or addPhoto, or addVideo
                                        hex2_len + hex3_attachment +    // if photo attachment: len, and raw data
                                        hex4_ref +                      // if a/v recording reference: txId (64 chars for 32 bytes)
                                        Buffer.from(this.state.outgoingContent, 'utf8').toString('hex')

            let maybeEncryptedContent
            try {
                maybeEncryptedContent = await this.maybeEncryptMsg( this.state.encryptDialogPost, dtx, dlgInfo, isOwner, contentAsHexString, true )
            } catch (error) {
                console.error("DM: Error while encrypting: " + error)
                alert("DM: We couldn't encrypt your message, so, we won't post it")
                return
            }

            newTxId = await buildOnDialog(stateOnWhichToBuild,
                                    latestTxIdOnWhichToBuild,  //tx on which to build
                                    dialogOutNum,    // output on which we're building. IIRC, should always be 0
                                    false,   // p1 claimBounty         FIXME: try this, at some point
                                    true,   // p4 - publish T/F         FIXME: not needed?
                                    maybeEncryptedContent,  // p3

                                    isOwner ? 1 : 2, //whichParticipant,   // re-use the rabin
                                    blockHeightInt, //blockHeight,     // actual PKH to use (if not re-using?)

                                    finalPost, //finalPost,   //finalPost?   FIXME: what if it IS?

                                    rabinPrivKeyP,
                                    rabinPrivKeyQ,
                                    this.props.bundle.p  // bsv funding priv key
                            )

        } catch (error) {
            console.error(" error while building a post. stack: ", error)

            this.setState({showSwirly: false})

            if ( error.toString().includes("Network Error") ) {
                alert("We've experienced a Network Error while building a transaction. Please check your connection.")
            } else {
                alert("Error when building/broadcasting a Dialogue post. MAYBE you have MORE posts to load before you can post.")
            }

            this.setState({disablePubButton: false})

            finalProcessing()
            return false
        }

        console.warn("results of build: ", newTxId)
        let success = false

        if ( newTxId ) {

            let newTx

            if ( false ) {

                const rawTx = theTransientResults.rawTx
                newTx = simpleDecode( rawTx )

            } else {

                //FIXME: at some point take a hint from claimer's expressClaim(), by
                //       broadcasting, and decoding tx WITHOUT querying the network
                // 10/31/22: increased from 3100, then from 3400  - to 3800
                // 11/4/22:  increased from 3800 to 4000
                await mySleep(4000, "pausing to allow new tx to propagate...")

                //FIXME: add a modal/toast for pause?
                //       OR, just pretend it came in, and do it in the background - with a timer?

                do {
                    newTx = await queryFetchDecodeTx(newTxId, db)
                    if ( newTx === null || typeof newTx === 'number' ) {
                        console.error("I think we didn't pause long enough for propagation.")
                        await mySleep(2000, "Pausing again for propagation...")
                    }
                    console.warn("result of qFDTx(): ", newTx)

                    //FIXME: limit this loop at some point?
                } while ( newTx === null || typeof newTx === 'number' );

            }


            console.warn("Here's the DECODED new tx: ", newTx)


//FIXME: copied from lines 620+, 953. abstract?

            const content1 = newTx.input1Params?.content
            const content0 = newTx.input0Params.content

            const content = content0.length > 0 ? content0 : content1

            console.warn("content: ", content)

            const nameAndPost = this.getAuthorNameAndPostNum(newTx)

            console.log("advancing the view (and updating furthestPostNum to " + nameAndPost.postNum + ")")

            //  modify JUST that single dialog's furthestPostNum, then update our cache of records
            await modDialogFurthest(db, dialogId, nameAndPost.postNum, newTxId, nameAndPost.scriptHash)
            const newDialogRecords = await this.createDialogRecords()

            console.warn("cDM(): here are the NEW dialogue records: ", newDialogRecords)
            this.setState({dialogRecords: Array.from(newDialogRecords)})

            const dialogIsClosed = newTx.outputStates[0].contractSatoshis === "0000000000000000"

            if ( this.getListsStatusFromId( dialogId ).toRespondTo ) {
                console.warn("This dialogue HAD BEEN in the you-need-to-respond list. MAYBE moving to ACTIVE...")

                // spent, so, have PARENT move to the activeDialogs list (at surfer/parent)
                if ( dialogIsClosed ) {
                    console.log("because this post CLOSED the dialog, will remove from RESPOND list")
                    console.log("ABOUT to tell parent to remove dialogue " + dialogId + " from toRespondTo - since it's now closed...")
                    await this.props.dialogCallbackToParent("removeFromRespond", dialogId)
                } else {
                    console.log("ABOUT to tell parent to move dialogue " + dialogId + " from toRespondTo to active...")
                    await this.props.dialogCallbackToParent("fromRespondToActive", dialogId)
                }

                console.log("DM handleSubmitPost(): NOTE: we used PARENT to move this dialogue from 'to respond to' to ACTIVE, and saved the settings: " + dialogId)
            } else if ( this.getListsStatusFromId( dialogId ).yetToHearBack ) {
                if ( dialogIsClosed ) {
                    console.log("because this post CLOSED the dialog, will remove from the yetToHearBack list")
                    console.log("ABOUT to tell parent to remove dialogue " + dialogId + " from yetToHearBack - since it's now closed...")
                    await this.props.dialogCallbackToParent("removeFromHearBack", dialogId)
                } else {
                    console.warn("Will KEEP this dialogue (" + dialogId + ") in 'yetToHearBackFrom'. Our post doesn't change anything.")
                }
            } else if ( this.getListsStatusFromId( dialogId ).active ) {
                if ( dialogIsClosed ) {
                    console.log("because this post CLOSED the dialog, will remove from the active list")
                    console.log("ABOUT to tell parent to remove dialogue " + dialogId + " from active - since it's now closed...")
                    await this.props.dialogCallbackToParent("removeFromActive", dialogId)
                } else {
                    console.warn("Will KEEP this dialogue (" + dialogId + ") in 'active'. Our post doesn't change anything.")
                }
            }

            if ( dialogIsClosed ) {
                console.log("this new post CLOSED the dialog. Will add to CLOSED list")
                await this.props.dialogCallbackToParent("addToClosed", dialogId)
            }

            const contentResults = await this.maybeAlterContent(newTx, dialogId, content) // content may have been encrypted. If so, we decrypt it here
            this.setState({
                latestTxId:         newTxId,
                dtx:                contentResults.newState,    //potentially alterd version of newTx
                content:            contentResults.hexContent,
                outgoingContent:    '',

                mediaType:          contentResults.mediaType,
                mediaURL:           contentResults.videoURL,
                videoType:          contentResults.videoType,
                videoBuff64:        contentResults.videoBuff64,

                postNum:            nameAndPost.postNum,
                authorName:         nameAndPost.authorName,
                byTheUser:          true,

                dialogIsClosed:     dialogIsClosed,

                //encryptDialogPost:  true,   //preserve value chosen in postPopup - DON'T update here

                disablePubButton:   false,
                showSwirly:         false
            })

            success = true
        } else {
            console.error("We had trouble building a new post/tweet")

            this.setState({disablePubButton: false, showSwirly: false})

            alert("We're sorry. We had trouble broadcasting your Dialogue post.\n"
                + "One of your Transaction Providers may just be a little slow.\n"
                + "Please try again.")

            finalProcessing()
        }

        return success
    } // handleSubmitPost()

    toggleCloseThisModal() {
        console.warn("toggling 'thisWillBeFinalPost' to " + !this.state.thisWillBeFinalPost)

        this.setState({thisWillBeFinalPost: !this.state.thisWillBeFinalPost})
    }

    /**
     * Extract a few cached IDB record fields for a given dialog
     * @param {*} dialogId
     * @returns a few select fields
     */
    getInfoAboutDialog(dialogId) {

        for ( let i = 0; i < this.state.dialogRecords.length; i++ ) {
            //console.log("considering record " + i + ": ", this.state.dialogRecords[i])
            //console.log("  has dialogId of " + this.state.dialogRecords[i].dialogId)
            if ( this.state.dialogRecords[i].dialogId === dialogId ) {
                console.log("FOUND starting TxId: " + this.state.dialogRecords[i].startingTxId)
                console.log("  FWIW: record: ", this.state.dialogRecords[i])
                return {
                    dialogStartingTxId: this.state.dialogRecords[i].startingTxId,
                    domainOfOtherGuy:   this.state.dialogRecords[i].otherGuy,
                    furthestPostNum:    this.state.dialogRecords[i].furthestPostNum,

                    // new field(s) to help us quickly check for a new spend/post
                    dialogFurthestTxId: this.state.dialogRecords[i].furthestTxId,
                    //dialogFurthestScriptHash: this.state.dialogRecords[i].furthestScriptHash,

                    ownerPubKey:        this.state.dialogRecords[i].ownerPubKey,
                    visitorPubKey:      this.state.dialogRecords[i].visitorPubKey,

                    //NOTE: this assumes .name matches US
                    weOwnThis:          this.state.dialogRecords[i].isOwner
                }
            }
        }

        // show some error modal
        alert("CODE or DB error: couldn't find dialogue " + dialogId)
        // throw?
        throw new Error("CODE/LOGIC ERROR: can't find info on dialogId " + dialogId)
    }



    /**
     * Given an IDB-known txid, check provider for anything further - iterating to FINAL post
     * @param {*} furthestTxId    an existing dialog txid
     * @param {*} db
     *
     * @returns decoded form of the furthest dialog it can find
     *
     * SIDE-EFFECT: updated state.dialogRecords[]
     * ASSUMPTION: the provided furthestTxId is ALREADY within the IDB
     */
    async seekProviderToFinalDialogPost(furthestTxId, dialogId, db) {
        console.warn("Will query for our furthest tx for this dialog: ", furthestTxId)

        let furthestId = furthestTxId
        let furthestDtx  // first qFDTx() for this MUST succeed. FurthestTxId exists
        let i = 0
        let done = false
        let guestWasSeen = false
        do {

            // get the decodedTx for a txId we KNOW exists
    //FIXME: can we do this WITHOUT querying the change output?
            let furthestTx = await queryFetchDecodeTx(furthestId, db)
            if ( furthestTx === null || typeof furthestTx === 'number' ) {
                console.warn("seekProviderToFinalDialogPost() iteration " + i + " failed - for txId " + furthestId)
                alert("ERROR: Big query problem. Please report txId " + furthestId)
                throw new Error("Code or IDB Error - searching for the FURTHEST txid of dialog: " + furthestId)
            }
            console.log("got the next tx for this dialog: ", furthestTx)


            // Which output has a dialog?
            let outIdx = 0
            while ( furthestTx.outputStates[outIdx]?.contract === 'transient' ) {
                outIdx++
            }


            // decide if this should go to ACTIVE. Otherwise, it could get stuck in one of the pendings
            //  If we see guest post, set to active
            if ( furthestTx.dialogSentBy === 'guest' ) {
                guestWasSeen = true
            }

            //NOTE: the very first query might have the dialog on the 3rd or 4th output
            const nextTxId = await findNextTxFromProvider( furthestTx.outputStates[outIdx], furthestId );

            if ( nextTxId !== null ) {
                console.warn("Iteration " + i + ": we found a spend (from provider query) - returning a new txid: " + nextTxId + ". Will loop to get the raw hex, and decode it.")
                furthestId = nextTxId
                i++
            } else {
                console.warn("seekProviderToFinalDialogPost(): done after " + i + " successful (provider) spend queries")
                // provider spend-query failed. Use the previous result
                furthestDtx = furthestTx
                done = true
            }
        } while ( !done );


        // Which output has the dialog?
        let outIndex = 0
        while ( furthestDtx.outputStates[outIndex]?.contract === 'transient' ) {
            outIndex++
        }

        // Did we learn anything new from the provider?
        if ( i > 0 ) {
            console.warn("Will update records for furthestPostNum: "
                        + furthestDtx.outputStates[outIndex].postNum)

            //update records. This is a new approach. Keep things up-to-date like the other approach

            // update IDB with new furthestPostNum
            await modDialogFurthest(db, dialogId, furthestDtx.outputStates[outIndex].postNum, furthestId, furthestDtx.outputStates[outIndex].scriptHash)

            // update our CACHE of dialog summary records
            const newDialogRecords = await this.createDialogRecords()


            console.warn("seekProviderToFinalDialogPost(): here are the NEW dialogue records: ", newDialogRecords)
            this.setState({dialogRecords: Array.from(newDialogRecords)})
        }

        return  {
                    nextTx:     furthestDtx,
                    nextTxId:   furthestId,
                    numLoaded:  i,
                    guestWasSeen: guestWasSeen,
                }
    } // seekProviderToFinalDialogPost()

    async dialogChosen(e, dialogId) {
        this.setState({
                        showSwirly: true,
                        swirlyText: 'Please wait. Checking for new posts...'
                     })

        if (e?.type === 'click') {
            console.log('dialogChosen: Left click');
        } else if (e?.type === 'contextmenu') {
            console.log('dialogChosen: Right click');
        }

        // find the startingTxId, and other useful stuff
        const { dialogStartingTxId, dialogFurthestTxId, domainOfOtherGuy, furthestPostNum } = this.getInfoAboutDialog( dialogId )

        let isOwner = false
        for ( let i = 0; i < this.state.dialogRecords.length; i++ ) {
            console.error("considering record " + i + ": ", this.state.dialogRecords[i])
            console.log("  has dialogId of " + this.state.dialogRecords[i].dialogId)
            if ( this.state.dialogRecords[i].dialogId === dialogId ) {
                console.log("FOUND starting TxId: " + this.state.dialogRecords[i].startingTxId)
                isOwner = this.state.dialogRecords[i].isOwner
                break
            }
        }
        console.log("dialogChosen(): isOwner? " + isOwner)


        const db = await openDB()

        const { nextTx, nextTxId, numLoaded, guestWasSeen } = await this.seekProviderToFinalDialogPost(dialogFurthestTxId, dialogId, db)

        if ( numLoaded > 0 ) {
            alert("We downloaded " + numLoaded + " new posts you've not seen before.")
        }

        const txIdToJumpTo = nextTxId
        const finalTx      = nextTx

        const content1 = finalTx.input1Params?.content
        const content0 = finalTx.input0Params.content

        const content = content0.length > 0 ? content0 : content1

        // This might be the first time we're seeing this
        // It might not yet be reflected in the status, below
        const dialogIsClosed = finalTx.outputStates[0].contractSatoshis === "0000000000000000"


        const status = this.getListsStatusFromId( dialogId )



        // if dialog was just-now closed (or finally responded-to), we need to manipulate lists
        // (when we called seekProviderToFinalDialogPost(), above)
        const {
                notFoundSo_toRespondTo,
                notFoundSo_toHearBackFrom,
                toActive
              } = await this.maybeModifyDialogLists(dialogId, status, isOwner, dialogIsClosed, guestWasSeen)



        const nameAndPost = this.getAuthorNameAndPostNum(finalTx)
        const potentiallyAlteredContent = await this.maybeAlterContent(finalTx, dialogId, content)  // content may have been encrypted. If so, we decrypt it here

        if ( dialogIsClosed || status.closed ) {
            //FIXME: MAYBE just share this with active scenario, below
            this.setState({ showNavigationModal: true,

                            dialogId:           dialogId,
                            isOwner:            isOwner,
                            latestTxId:         txIdToJumpTo,
                            firstDialogTx:      dialogStartingTxId,
                            dtx:                potentiallyAlteredContent.newState, // potentially-altered version of finalTx,
                            content:            potentiallyAlteredContent.hexContent,
                            domainOfOtherGuy:   domainOfOtherGuy,

                            mediaType:          potentiallyAlteredContent.mediaType,
                            mediaURL:           potentiallyAlteredContent.videoURL,
                            videoType:          potentiallyAlteredContent.videoType,
                            videoBuff64:        potentiallyAlteredContent.videoBuff64,

                            postNum:            nameAndPost.postNum,

                            authorName:         nameAndPost.authorName,
                            byTheUser:          nameAndPost.byTheUser,

                            dialogIsClosed:     dialogIsClosed,

                            showSwirly:         false,
            })
            console.warn("dialogChosen: You chose the dialogue with an id of " + dialogId + " (CLOSED)")
        } else if ( status.active || toActive ) {
            this.setState({ showNavigationModal: true,

                            dialogId:           dialogId,
                            isOwner:            isOwner,
                            latestTxId:         txIdToJumpTo,
                            firstDialogTx:      dialogStartingTxId,
                            dtx:                potentiallyAlteredContent.newState, // potentially-altered version of finalTx,
                            content:            potentiallyAlteredContent.hexContent,
                            domainOfOtherGuy:   domainOfOtherGuy,

                            mediaType:          potentiallyAlteredContent.mediaType,
                            mediaURL:           potentiallyAlteredContent.videoURL,
                            videoType:          potentiallyAlteredContent.videoType,
                            videoBuff64:        potentiallyAlteredContent.videoBuff64,

                            postNum:            nameAndPost.postNum,

                            authorName:         nameAndPost.authorName,
                            byTheUser:          nameAndPost.byTheUser,

                            dialogIsClosed:     dialogIsClosed,

                            showSwirly:         false,
            })
            console.warn("dialogChosen: You chose the dialogue with an id of " + dialogId + " (active)")
        } else if ( status.toRespondTo || notFoundSo_toRespondTo ) {
            console.warn("dialogChosen: You chose the dialogue (with an id of " + dialogId + ") which should be in the 'toRespondTo' list.")
            this.setState({ showRespondToModal: true,

                                dialogId:           dialogId,
                                isOwner:            isOwner,
                                latestTxId:         txIdToJumpTo,
                                firstDialogTx:      dialogStartingTxId,
                                dtx:                potentiallyAlteredContent.newState, // potentially-altered version of finalTx,
                                content:            potentiallyAlteredContent.hexContent,
                                domainOfOtherGuy:   domainOfOtherGuy,

                                mediaType:          potentiallyAlteredContent.mediaType,
                                mediaURL:           potentiallyAlteredContent.videoURL,
                                videoType:          potentiallyAlteredContent.videoType,
                                videoBuff64:        potentiallyAlteredContent.videoBuff64,

                                postNum:            nameAndPost.postNum,

                                domainOfInitiator:  domainOfOtherGuy,
                                //outputOfDialog:     outIndex,

                                authorName:         nameAndPost.authorName,
                                byTheUser:          nameAndPost.byTheUser,

                                dialogIsClosed:     dialogIsClosed,

                                showSwirly:         false,
                            })
        } else if ( status.yetToHearBack || notFoundSo_toHearBackFrom ) {
            alert("dialogChosen: You chose the dialogue (with an id of " + dialogId + ") which you've not yet heard-back from. We recommend you don't post more messages until you get a response from the other party.")

            this.setState({ showNavigationModal: true,

                dialogId:           dialogId,
                isOwner:            isOwner,
                latestTxId:         txIdToJumpTo,
                firstDialogTx:      dialogStartingTxId,
                dtx:                potentiallyAlteredContent.newState, // potentially-altered version of finalTx,
                content:            potentiallyAlteredContent.hexContent,
                domainOfOtherGuy:   domainOfOtherGuy,

                mediaType:          potentiallyAlteredContent.mediaType,
                mediaURL:           potentiallyAlteredContent.videoURL,
                videoType:          potentiallyAlteredContent.videoType,
                videoBuff64:        potentiallyAlteredContent.videoBuff64,

                postNum:            nameAndPost.postNum,

                authorName:         nameAndPost.authorName,
                byTheUser:          nameAndPost.byTheUser,

                dialogIsClosed:     dialogIsClosed,

                showSwirly:         false,
            })
            console.warn("dialogChosen: You chose the dialogue with an id of " + dialogId + " (active)")

        } else {
            // closed
            alert("ERROR: You chose the dialogue with an id of " + dialogId + ", yet it wasn't found in any of FOUR lists, AND wasn't just-now found to be closed. REVIEW code logic.")

            throw new Error("CODE ERROR: dialogue " + dialogId + " wasn't found in ANY list, AND, wasn't just-now found to be closed.")
        }

        // see dialogModal (used by shizzleView)
        //   it now calls its this.props.dialogClickHandler( parent, startingTxId, dialogId),
        //   then it valls handleCancel()  to close the dialogModal

        e?.preventDefault()

    } // dialogChosen()

    async closeRespondToModal() {

        if ( this.props.jumpToSingleDialog ) {
            console.warn("Closing DialogManager - from a single dialogue")
            return this.props.closeManager()
        }

        // refresh the spent records
        await this.checkAllFurthestUtxos()

        this.setState({ showRespondToModal: false,

                        dialogId:           null,
                        isOwner:            false,
                        firstDialogTx:      null,
                        latestTxId:         null,
                        domainOfInitiator:  null,
                        content:            '',
                        outgoingContent:    '',
                        postNum:            0,
                        authorName:         null,
                        isOwner:            false,
                    })
    }

    async closeNavigationModal() {

        if ( this.props.jumpToSingleDialog ) {
            console.warn("Closing DialogManager - from this single dialogue")
            return this.props.closeManager()
        }

        // refresh the spent records
        await this.checkAllFurthestUtxos()

        this.setState({ showNavigationModal:    false,

                        dialogId:               null,
                        isOwner:                false,
                        firstDialogTx:          null,
                        latestTxId:             null,
                        domainOfOtherGuy:       null,
                        content:                '',
                        outgoingContent:        '',
                        postNum:                0,
                        authorName:             null,
                        isOwner:                false,
        })
    }

    getSpentFromId( id ) {
        //FIXME: can we just build these ONCE (this.nameOfSet) - instead of EVERY time this is called?
        const spentRecs = this.state.spentRecs
        console.log("getSpentFromId(): checking on dialogue " + id)
        //console.warn("spentRecs: ", spentRecs)

        for ( let i = 0; i < this.state.spentRecs?.length; i++ ) {
            if ( spentRecs[i].dialogId === id ) {
                console.warn("FOUND spentRec for dialogue " + id)
                return spentRecs[i].isSpent
            }
        }
        return false
    }

    getListsStatusFromId( id ) {
        // This is queried EACH time - since the lists are updated dynamically
        // (any time the user clicks to check for a new post - which might be the finalPost, or first response, etc)
        const yetToHearBack = new Set( this.props.getDialogsYetToHearBackFrom() )
        const toRespondTo   = new Set( this.props.getDialogsToRespondTo() )
        const active        = new Set( this.props.getActiveDialogs() )
        const closed        = new Set( this.props.getClosedDialogs() )

        let notFound =  !yetToHearBack.has( id ) &&
                        !toRespondTo.has( id ) &&
                        !active.has(id) &&
                        !closed.has(id)

        return {
            yetToHearBack: yetToHearBack.has( id ),
            toRespondTo:   toRespondTo.has( id ),
            active:        active.has( id ),
            closed:        closed.has( id ),
            notFound:      notFound,
        }
    }

    buildDialogsList() {

        if ( this.state.dialogRecords === null ) {
            console.error("buildDialogsList() abort")
            return null
        }

        //Q: is it better to build sets here, once, rather than call getListsStatusFromId() four times?
        //const yetToHearBack = new Set( this.props.dialogsYetToHearBackFrom )
        //const toRespondTo   = new Set( this.props.dialogsToRespondTo )
        //const active        = new Set( this.props.activeDialogs )
        //const closed        = new Set( this.props.closedDialogs )

        //console.log("buildDialogsList(): BTW: dialogsYetToHearBackFrom: ", this.props.dialogsYetToHearBackFrom)

        //console.log("buildDialogsList():  state.dialogRecords: ", this.state.dialogRecords)

        //FIXME: use colors to highlight status:  waitingToHear vs notResponded vs active vs closed

        //FIXME: build a <Table>, instead of a <List>

        //{rec.isOwner ? ' (you own this)' : null}

        let records
        if ( this.state.hideClosedDialogs && this.state.dialogRecords ) {
            const closedDialogsList = new Set( this.props.getClosedDialogs() )

            records = new Array ( this.state.dialogRecords.length )
            let r = 0
            for ( let i = 0; i < this.state.dialogRecords.length; i++ ) {
                const id = this.state.dialogRecords[i].dialogId
                if ( closedDialogsList.has( id ) ) {
                    console.log("buildDialogsList(): skipping dialogue " + id)
                    continue
                }
                records[r] = this.state.dialogRecords[i]
                r++
            }
            records.length = r
        } else {
            records = this.state.dialogRecords
        }
        this.setState({relevantDialogsCount: records.length})

        const dialogList = records.map((rec) =>
            <Table.Row className="hoverLink" key={rec.dialogId}
                    onClick={       (event) => this.dialogChosen(event, rec.dialogId)}
                    onContextMenu={ (event) => this.dialogChosen(event, rec.dialogId)}>

                <Table.Cell style={{padding:'8px 8px', border:'.1px solid'}}>{rec.dialogId}</Table.Cell>

                <Table.Cell style={{padding:'8px 8px', border:'.1px solid'}}>
                    <span style={{color:"blue"}}>{rec.otherGuy}</span>&nbsp;
                </Table.Cell>

                <Table.Cell style={{padding:'8px 8px', border:'.1px solid'}}>
                    {rec.furthestPostNum} {this.getSpentFromId( rec.dialogId ) ? <b style={{color:'red', fontSize:"1.3rem"}}>+</b> : <></>}
                </Table.Cell>

                <Table.Cell style={{padding:'8px 8px', border:'.1px solid'}}>
                    { this.getListsStatusFromId( rec.dialogId ).yetToHearBack ? <>waiting for <b>them</b></> : null }
                    { this.getListsStatusFromId( rec.dialogId ).toRespondTo ? <><b>you</b> need respond</> : null }
                    { this.getListsStatusFromId( rec.dialogId ).active ? "ACTIVE" : null }
                    { this.getListsStatusFromId( rec.dialogId ).closed ? "closed" : null }
                    { this.getListsStatusFromId( rec.dialogId ).notFound ? "???": null }
                </Table.Cell>

            </Table.Row>
        )

        return dialogList
    }

    postResponse() {

        this.handleSubmitPost()

        //FIXME: if we're building our first RESPONSE

        //FIXME: what if initiator builds MORE (and we still haven't responded) - we should fetch those too <--------

        //FIXME: we could add simple navigation, and checks
    }

    async goBack(e, bleh) {
        console.error("go back")
        await this.handleForward(true)
    }
    async goForward(e, bleh) {
        console.error("go fwd")
        const foundTx = await this.handleForward(false)

        if ( foundTx ) {
            // we found a tx, so, we could check the postNum, and if it's greater than the furthest, could update the IDB record
        } else {
            // we DIDN'T have a RECORD of a tx that FOLLOWS the current dialog tx
            // AND, we checked with the provider
        }
    }

    async goTo0thPost(e, bleh) {

        const db = await openDB()

        return await this.jumpToPostWhichFollows( db,
                                this.state.dialogId,
                                -1,    //FIXME: maybe go in reverse from 1?
                                false)
    }

    async goToFurthestPost(e, bleh) {

        const db = await openDB()

        const furthest = this.calculateFurthestPostNum()

        return await this.jumpToPostWhichFollows( db,
                                this.state.dialogId,
                                furthest - 1,
                                false)
    }

    /**
     * Jump to the LOGICAL next transaction (for the domainToVisit)
     * If parameter 'reverse' is true, walk BACKWARDS instead.
     *
     * @param {*} reverse
     * @returns
     */
    async handleForward(reverse = false) {
        console.log("FORWARD clicked. BTW: latestTxId is " + this.state.latestTxId)

        console.warn("Reverse? ", reverse)

        const odtx = this.state.dtx

        if (!odtx) {
            console.error("handleForward(): no decodedTx to render???")
            alert("ERROR: There's no decoded tx to calc fwd/prev.")
            //FIXME: throw?
            return false
        }

        //FIXME: if we're ALREADY at a finalPost, do we need special logic,
        //       or can we just search, as normal?

        console.error("FIXME: consider case of this being the startingTx. We'd want to look at the Dialogue output (below/next) - not default to 0")

        console.warn("handleForward() current address " + odtx.outputStates[0].address)

        let outIndex = 0
        while ( odtx.outputStates[outIndex]?.contract === 'transient' ) {
            outIndex++
        }
        console.log("handleForward(): outIndex: ", outIndex)

        let oldPostNum =  odtx.outputStates[ outIndex ].postNum

        const db = await openDB()

        return await this.jumpToPostWhichFollows( db,
                                this.state.dialogId,
                                oldPostNum,
                                reverse)
    }

    /**
     * Jumps to the Dialog post number which FOLLOWs oldPostNum, AND sets all
     * of the appropriate STATE variables, and THEN runs any finalProcessing()
     * FIXME: remove param 'preserveOutgoingContent'
     *
     * @param {*} db
     * @param {*} dialogId
     * @param {*} oldPostNum
     * @param {*} reverse
     * @param {*} preserveOutgoingContent  FIXME: remove this
     * @param {*} finalProcessing: function called on successful jump - following setState() at end
     * @returns true or false
     */
    async jumpToPostWhichFollows( db,
                            dialogId,
                            oldPostNum,           // we're seeking oldPostNum + 1
                            reverse = false,
                            preserveOutgoingContent = false,
                            finalProcessing = function() { }) {

        const results = await getNextDialog(db,
                                            dialogId,
                                            oldPostNum,
                                            reverse)

        let loadedFromProvider = false
        let nextTxId
        if (results === null) {
            console.warn("can't go forward (reverse = " + reverse + ") by simply searching an ordered list")

            if ( reverse ) {
                // don't bother querying a provider to find a tx which we should already have

                /////////// run finalProcessing()?
                return false
            }

            // NEW CODE:
            // Try to query PROVIDER(s) for the NEXT tx


            // we DIDN'T have a RECORD of a tx that FOLLOWS the current dialog tx
            // We should CHECK the tx output with the provider

            console.warn("jumpToPostWhichFollows(): should check on output of postNum " + this.state.postNum)
            console.error("jumpToPostWhichFollows(): OR    check on output of postNum " + oldPostNum + "?")

            console.warn("jumpToPostWhichFollows(): BTW: latestTxId: " + this.state.latestTxId + ". Does this MATCH the postNum for " + this.state.dialogId)

            console.warn("dtx: ", this.state.dtx)
            console.warn("buildable? ", this.state.dtx.buildable)
            console.warn("We should check with our provider - to see if the FURTHEST dialogue output was spent. ")

            const dtx = this.state.dtx

            // Which output has a dialog?
            let outIndex = 0
            while ( dtx.outputStates[outIndex]?.contract === 'transient' ) {
                outIndex++
            }

            /*
            if ( dtx.buildable[outIndex] === outIndex ) {
                console.warn("SUPPOSEDLY (without checking with provider), that output is NOT SPENT.")

                // We don't REALLY know how old that info is
                // let's check anyway
                console.warn("Our decoded tx claims that the next output is NOT SPENT, but, we don't know how old that info is")
//FIXME: find how old that info is. We keep this info
                console.error("IMPLEMENT: check how old that info is? REGARDLESS, will query provider anyway")
                //return false
            }
            */

            // NEW function to parallel findNextTx()
            // Recall, though, dialogs are different, so, may be simpler, and even take a different approach
            // We might even want to pass different params, since dialogTxoTab has different fields than txotab
            //     dialogTxoTab has these fields:
            //        dialogId
            //        postNum
            //        txid
            // This function is meant to be a more robust version of getNextDialog()
            // Meaning, if a db query fails, it'll then call a providerg
            console.log("jumpToPostWhichFollows(): findNextTxFromProvider() doesn't create any DB records, like findNextTx() does.")

            nextTxId = await findNextTxFromProvider( dtx.outputStates[outIndex], this.state.latestTxId );

            console.warn("jumpToPostWhichFollows(): Next txid: " + nextTxId)

            if ( nextTxId === null ) {
                console.warn("jumpToPostWhichFollows(): Well, the provider tells us that there is NO spend, so, we're done")
                /////////// run finalProcessing()?
                return false
            }

            loadedFromProvider = true

        } else {
            nextTxId = results.txid
        }
        console.warn("jumpToPostWhichFollows(): nextTxId = " + nextTxId)

        let nextTx = await queryFetchDecodeTx(nextTxId, db)
        if ( nextTx === null || typeof nextTx === 'number' ) {
            console.warn("Result of query for next tx: ", nextTx)
            alert("hmm. trouble getting nextTx: " + nextTxId)

            /////////// run finalProcessing()?
            return false;
        }
        console.warn("jumpToPostWhichFollows(): nextTx = ", nextTx)

//FIXME: copied from lines 620+ . abstract?

        const content1 = nextTx?.input1Params.content
        const content0 = nextTx.input0Params.content

        const content = content0.length > 0 ? content0 : content1




        // We got a brand-new tx from the provider
        // We may need to manipulate the dialogs lists
        if ( loadedFromProvider ) {

            const guestWasSeen = nextTx.dialogSentBy === 'guest'

            // This might be the first time we're seeing this
            // It might not yet be reflected in the status, below
            const dialogIsClosed = nextTx.outputStates[0].contractSatoshis === "0000000000000000"

            const status = this.getListsStatusFromId( dialogId )

            const isOwner = this.state.isOwner

            // if dialog was just-now closed (or finally responded-to), we need to manipulate lists
            const {
                notFoundSo_toRespondTo,
                notFoundSo_toHearBackFrom,
                toActive
            } = await this.maybeModifyDialogLists(dialogId, status, isOwner, dialogIsClosed, guestWasSeen)

            // use these 3 to set/modify WHICH modal to show: navigation, vs respondTo

            // This is a focusing/condensation of the logic in dialogChosen()
            const showNavModal = dialogIsClosed || status.closed || status.active || toActive || status.yetToHearBack || notFoundSo_toHearBackFrom
            const showRespondToModal = (status.toRespondTo || notFoundSo_toRespondTo) && !showNavModal

            if ( !showNavModal && !showRespondToModal ) {
                alert("ERROR: need to showNavigationModal, or showRespondToModal. Can't show neither.")
                throw new Error("Must show either the nav modal, or the respond-to modal.")
            }

            //FIXME: do this V
            // Q: can we combine these with setState() below?
            // sure: collect settings of these two BEFORE loadedFromProvider check
            this.setState({ showNavigationModal: showNavModal,
                            showRespondToModal: showRespondToModal,
                         })
        }

        console.warn("content: ", content)
        const potentiallyAlteredContent = await this.maybeAlterContent(nextTx, dialogId, content)  // content may have been encrypted. If so, we decrypt it here

        const nameAndPost = this.getAuthorNameAndPostNum(nextTx)

        // read IDB for updated 'furthest's
        const furthest = this.calculateFurthestPostNum()

        // Does this new tx extend BEYOND furthestPostNum?
        // If yes, we should:
        //   - update the IDB for this dialog
        //   - update the state.dialogRecords for this dialog
        //FIXME: any other IDB records we need?
        if ( nameAndPost.postNum > furthest ) {
            console.warn("jumpToPostWhichFollows(): we need to update IDB, and state.dialogRecords - setting furthest to " + nameAndPost.postNum)

            //FIXME: show a Toast - mentioning we've found a new post?

            // update IDB with new furthestPostNum
            await modDialogFurthest(db, dialogId, nameAndPost.postNum, nextTxId, nameAndPost.scriptHash)

            // update our CACHE of records
            const dialogRecords = await this.createDialogRecords()

            console.warn("jumpToPostWhichFollows(): here are UPDATED records: ", dialogRecords)
            //this.setState({dialogRecords: Array.from(dialogRecords)})
            this.setState({
                            latestTxId:         nextTxId,
                            dtx:                potentiallyAlteredContent.newState, // potentially-altered version of nextTx,
                            content:            potentiallyAlteredContent.hexContent,
                            //outgoingContent:    '', // <----------- We no-longer clear this. Why should navigating a DIALOG clear our outgoing message?

                            mediaType:          potentiallyAlteredContent.mediaType,
                            mediaURL:           potentiallyAlteredContent.videoURL,
                            videoType:          potentiallyAlteredContent.videoType,
                            videoBuff64:        potentiallyAlteredContent.videoBuff64,

                            postNum:            nameAndPost.postNum,
                            authorName:         nameAndPost.authorName,
                            byTheUser:          nameAndPost.byTheUser,

                            dialogRecords: Array.from(dialogRecords)  // <----- unlike scenario below, we're also updating this
                        }, finalProcessing)
        } else {
            // This one DOESN'T update dialogRecords.
            // We're here because we didn't need to consult the PROVIDER(s).
            // We had the needed IDB records
            this.setState({
                            latestTxId:         nextTxId,
                            dtx:                potentiallyAlteredContent.newState, // potentially-altered version of nextTx,
                            content:            potentiallyAlteredContent.hexContent,
                            //outgoingContent:    '', // <----------- We no-longer clear this. Why should navigating a DIALOG clear our outgoing message?

                            mediaType:          potentiallyAlteredContent.mediaType,
                            mediaURL:           potentiallyAlteredContent.videoURL,
                            videoType:          potentiallyAlteredContent.videoType,
                            videoBuff64:        potentiallyAlteredContent.videoBuff64,

                            postNum:            nameAndPost.postNum,
                            authorName:         nameAndPost.authorName,
                            byTheUser:          nameAndPost.byTheUser,
                        }, finalProcessing)
        }

        return true

    } // jumpToPostWhichFollows()

    async maybeModifyDialogLists(dialogId, status, isOwner, dialogIsClosed, guestWasSeen) {

        let notFoundSo_toRespondTo = false
        let notFoundSo_toHearBackFrom = false
        let toActive = false

        if ( status.notFound ) {
            console.warn("You chose the dialogue with an id of " + dialogId + ". It wasn't found in any of THREE lists, so, will check if guest was seen, then, if owner, and use that to decide which of two lists to add it to (active, toHearBack, or toRespondTo)")

            // Finally have surfer put dialog in a list

            if ( guestWasSeen && !dialogIsClosed ) {
                console.log("dialogChosen: guest was seen, so, added to the ACTIVE list")
                toActive = true
                await this.props.dialogCallbackToParent("addToActive", dialogId)
            } else if ( isOwner && !dialogIsClosed ) {
                console.log("dialogChosen: since we're the owner, added to the waiting-to-hear-back list")
                notFoundSo_toHearBackFrom = true

                await this.props.dialogCallbackToParent("addToHearBack", dialogId)
            } else if ( !dialogIsClosed ) {
                console.log("dialogChosen: since we're NOT the owner, added to the 'to-respond-to' list")
                notFoundSo_toRespondTo = true

                await this.props.dialogCallbackToParent("addToRespondTo", dialogId)
            }
        } else if ( !status.closed ) {
            console.log("maybeModifyDialogLists(): wasn't ALREADY closed...")
            if ( status.toRespondTo ) {
                console.log("maybeModifyDialogLists(): was in the toRespondTo list...")
                if ( dialogIsClosed ) {
                    console.log("dialogue is now closed, so, will removeFromRespond")
                    await this.props.dialogCallbackToParent("removeFromRespond", dialogId)
                } else if ( guestWasSeen ) {
                    console.log("dialogChosen: was already in toRespondTo, but guest was seen, so, will move from toRespondTo to ACTIVE list")
                    toActive = true
                    await this.props.dialogCallbackToParent("fromRespondToActive", dialogId)
                }

            } else if ( status.yetToHearBack ) {
                console.log("maybeModifyDialogLists(): was in the yetToHearBack list...")
                if ( dialogIsClosed ) {
                    console.log("dialogue is now closed, so, will removeFromHearBack")
                    await this.props.dialogCallbackToParent("removeFromHearBack", dialogId)
                } else if ( guestWasSeen ) {
                    console.log("dialogChosen: was already in yetToHearBack, but guest was seen, so, will move from yetToHearBack to ACTIVE list")
                    toActive = true
                    await this.props.dialogCallbackToParent("fromHearBackToActive", dialogId)
                }
            } else if ( status.active && dialogIsClosed ) {
                console.log("dialogue is now closed, so, will removeFromActive")
                await this.props.dialogCallbackToParent("removeFromActive", dialogId)
            }
        } else {
            console.log("maybeModifyDialogLists(): was ALREADY closed. Little to do, we think")
        }

        if ( dialogIsClosed ) {
            console.warn("This dialogue (" + dialogId + ") is now CLOSED. No more posts can be made to it")
            await this.props.dialogCallbackToParent("addToClosed", dialogId)
        }

        return {
                    notFoundSo_toRespondTo:     notFoundSo_toRespondTo,
                    notFoundSo_toHearBackFrom:  notFoundSo_toHearBackFrom,
                    toActive:                   toActive
               }
    }

//FIXME: rename param nextTx to dialogTx
    getAuthorNameAndPostNum(nextTx) {
        let newOutIndex = 0

        while ( nextTx.outputStates[newOutIndex]?.contract === 'transient' ) {
            newOutIndex++
        }
        console.log("getAuthorNameAndPostNum(): newOutIndex: ", newOutIndex)

        let isOwner
        let authorName
        if ( nextTx.dialogSentBy === 'owner' ) {
            authorName = nextTx.outputStates[newOutIndex].ownerName
            isOwner = true
        } else {
            authorName = nextTx.outputStates[newOutIndex].visitorName
            isOwner = false
        }

        const byTheUser = authorName === this.props.ourIdentity
        //FIXME: also mention if OWNER?
        if ( byTheUser ) {
            authorName = 'YOU (' + this.props.ourIdentity + ")"
        }

        //alert("getAuthorNameAndPost(): by the user: " + byTheUser)

        return {
            authorName: authorName,
            postNum:    nextTx.outputStates[ newOutIndex ].postNum,
            scriptHash: nextTx.outputStates[ newOutIndex ].scriptHash,
            byTheUser:  byTheUser
        }
    }

    calculateFurthestPostNum() {
        let furthest = -1
        for ( let i = 0; i < this.state.dialogRecords?.length; i++ ) {
            if ( this.state.dialogRecords[i].dialogId == this.state.dialogId ) {
                //console.log("THIS dialog " + this.state.dialogId + " has furthestPostNum of " + this.state.dialogRecords[i].furthestPostNum)
                furthest = this.state.dialogRecords[i].furthestPostNum
            }
        }

        return furthest
    }

    openPostPopup() {
        this.setState({showPostPopup: true})
    }

    // return back from postPopup with parameters for posting
    handlePostData(params) {
        console.warn("DMgr: Params received from postPopup: ", params)

        this.closePostPopup()

        // Use these parameters to submit the post/guest-post
        //  - setState from most of the parameters
        //  - AFTER state has rippled, call handleSubmitPost,
        //    using various state variable for building the post

        this.setState({
            //inviteToDialog:     params.inviteToDialog,

            photoName:          params.photoName,       // for display only
            photoBlob:          params.photoBlob,
            addPhotoToPost:     params.addPhotoToPost,
            photoToAdd:         params.photoToAdd,

            encryptDialogPost:  params.encryptSelfPost,

            videoToAdd:         params.videoToAdd,
            addVideoToPost:     params.addVideoToPost,
            videoURL:           params.videoURL,

            outgoingContent:    params.postText,
        }, this.handleSubmitPost )

        //NOTE: handleSubmitPost relies on (among others):
        //        state.encryptDialogPost
        //        state.outgoingContent
        //        props.bundle.pwd,   props.bundle.p ?  who cares?

        //FIXME: disable pub icon with state.disablePubButton? Hmm. maybe not

    }
    closePostPopup() {
        this.setState({showPostPopup: false})
    }

    render() {

        // ***  DEVICE-DEPENDENT STYLING  ***
        // For mobile, "detect" landscape screen mode
        const landscape = this.props.isMobile ? window.innerWidth > window.innerHeight : false
        // For mobile, shrink the About, Security, and Glossary modals
        const modalClassName   = this.props.isMobile ?
                                        (landscape ?
                                                "modalMobileLandscape"
                                            :
                                                "modalMobilePortrait"
                                        )
                                    :
                                        ""
        const modalContentClassName = this.props.isMobile ? "modalContentMobile" : ""
        const modalBottomClassName  = this.props.isMobile ? "modalBottomMobile"  : ""


        // We use a local callback (instead of a props.handleSignInOutClick)
        // because we have our own WalletVerifier to show
        const pwdCallback = this.props.citizenLevel > 0 ?
                                            this.signInOutCallback
                                        :
                                            null


        const thisDialogIsClosed = this.state.dialogIsClosed
        console.warn("thisDialogIsClosed: ", thisDialogIsClosed)
        console.warn(".contractSatoshis: ", this.state.dtx?.outputStates[0].contractSatoshis)

        //console.error("render(): here they are: ", this.state.dialogRecords)
        const dialogsList = this.buildDialogsList()

/* Not yet useful in this manager

        let gpPrice = ''
        if ( this.state.satsPerGuestPost > -1 ) {
            ////const penniesPerSat = this.props.bsvPriceUSD / 1000000
            //console.log("penniesPerSat: " + this.props.bsvPriceUSD / 1000000)
            console.log("satsPerPenny: " + 1000000 / this.props.bsvPriceUSD)

            //FIXME: move this WHOLE shebang to where we SET state.satsPerGuestPost?
            //       OR if we get an updated BSV price?

            const gpPriceInDollars = this.props.bsvPriceUSD * this.state.satsPerGuestPost / 100000000
            if ( gpPriceInDollars < 1.0 ) {
                const gpPriceInPennies = this.props.bsvPriceUSD * this.state.satsPerGuestPost / 1000000
                if ( gpPriceInPennies < 1.0 ) {
                    const gpPriceInMilliPennies = this.props.bsvPriceUSD * this.state.satsPerGuestPost / 1000
                    if ( gpPriceInMilliPennies < 1.0 ) {
                        gpPrice = <>less than a <b>milli</b>-penny (US)</>
                    } else {
                        gpPrice = <>approx {gpPriceInMilliPennies.toFixed(0)} <b>milli</b>-pennies (US)</>
                    }
                } else if ( gpPriceInPennies < 100 ) {
                    gpPrice = <>approx {gpPriceInPennies.toFixed(1)}¢ (US)</>
                }
            } else {
                gpPrice = <>approx ${gpPriceInDollars.toFixed(2)} (US)</>
            }
        }
*/

        // icon sizes: mini tiny small large big huge massive
        const feeExplainer = <Icon  size='large' style={{color: this.bshzPurple}} name='question circle outline' />
        const popUpFeeExplainerText = <>Domain owners can charge a fee for others to post/comment on their site - or even prohibit it altogether. Setting a fee may discourage spammers.</>
        const popupFeeExplainer = <Popup style={{backgroundColor: this.bshzLightYellow}} trigger={feeExplainer} wide="very" content={popUpFeeExplainerText} hideOnScroll/>

        //console.error("guestPostingCanHappen: " + this.state.guestPostingCanHappen)
        //console.error("satsPerGuestPost: " + this.state.satsPerGuestPost)
        //console.error("maxMilliPenniesToComment: " + this.state.maxMilliPenniesToComment)
        //console.error("selfDomainRequest: " + this.state.selfDomainRequest)
        //console.error("weHaveATransient: " + this.state.weHaveATransient)

//        const maxSats = satsFromMilliPennies(this.state.maxMilliPenniesToComment, this.props.bsvPriceUSD)
//        const tooRichForYou = this.state.guestPostingCanHappen && (this.state.satsPerGuestPost > maxSats)

        //FIXME: use table to place TextArea and checkbox side-by-side


        /*
        const maybeCommentThresholdModal = this.state.showFeeThresholdModal ?
                            <>
                                <FeeSettingsModal
                                    bsvPriceUSD={this.props.bsvPriceUSD}
                                    siteName={this.state.ourIdentity}
                                    closeMe={this.closeFeeSettingsModal}
                                    firstTime={this.state.feeSettingsFirstTime}
                                />
                            </>
                        :
                            <>
                            </>
        */



        //FIXME: ponder if the DialogManager should registerClient:
        //          registerClient={this.registerBRunnerClient}
        //          unregisterClient={this.unregisterBRunnerClient}
        //       Probably - so it can get periodic heartbeats/prompts to query subcription UTXOs


        const disablePrevButtton = this.state.postNum === 0

        const furthest = this.calculateFurthestPostNum()

        const disableFFwd = this.state.postNum === furthest

        const leftArrowButtonContent =  <>
                                            <Icon name='angle left'/>
                                        </>
        const rightArrowButtonContent = <>
                                            <Icon name='angle right'/>
                                        </>

        const revTo0Button             =  <>
                                            <Icon name='angle double left'/>
                                          </>
        const ffwdButton               =  <>
                                            <Icon name='angle double right'/>
                                          </>

        const postNum = this.state.postNum
        const maybeNavButtons = //furthest > 0 ?
                    <>
                        Post <b>{postNum}</b> of {furthest} &nbsp;<span style={{fontSize:'.8rem'}}>(dialogue Id: {this.state.dialogId})</span>
                        <br></br>
                        <Button disabled={disablePrevButtton} positive onClick={this.goBack} content={leftArrowButtonContent}/>
                        &nbsp; &nbsp; &nbsp; &nbsp;
                        <Button disabled={false} positive onClick={this.goForward} content={rightArrowButtonContent}/>
                    </>
        //        :
        //            null


        //console.log("ByTheUser: " + this.state.byTheUser)


        // This regards decoded content we're displaying
        const messageBackground = this.state.byTheUser ? 'white' : 'lightBlue'
        const decryptionMention = this.state.contentEncrypted ? <>(this content was <span style={{color:'red'}}>auto-decrypted</span>)<br></br></>
                                                            :
                                                                <>(this content appears as plain-text on the blockchain)<br></br></>

        const contentBorderColor = this.state.contentEncrypted ? 'red' : 'lightGrey'
        const authorColor = this.state.byTheUser? 'purple' : 'blue'

        const finalPostMention = this.state.thisWillBeFinalPost ?
                                        <>
                                            This will be the <b style={{color:'red'}}>FINAL</b> post of this Dialogue.

                                        </>
                                    :
                                        <></>



        const utf8Content = Buffer.from(this.state.content, 'hex').toString('utf8')


        const headerTitle = 'Post to your dialogue with ' + this.state.domainOfOtherGuy
        const introText = 'What would you like to say to '
        const introMentionNotEncrypted = this.state.domainOfOtherGuy + " (AND the whole world)?"
        const introMentionEncrypted    = this.state.domainOfOtherGuy + "?"
        const infoText = 'This dialog post will be visible to '
        const infoMentionNotEncrypted = 'anyone in the world.'
        const infoMentionEncrypted = 'yourself and ' + this.state.domainOfOtherGuy + '.'

        //FIXME: add/adapt these two properties
        //suitableForDialogInvite={this.state.suitableForDialogInvite}
        //dialogInvitee={this.state.dialogInvitee}
        const maybePostPopup = this.state.showPostPopup ?
                <>
                    <PostPopup  isMobile={this.props.isMobile}
                                close={this.closePostPopup}
                                processPost={this.handlePostData}

                                initialEncrypt={this.state.encryptDialogPost}
                                headerTitle={headerTitle}
                                introText={introText}
                                introMentionNotEncrypted={introMentionNotEncrypted}
                                introMentionEncrypted={introMentionEncrypted}
                                infoText={infoText}
                                infoMentionNotEncrypted={infoMentionNotEncrypted}
                                infoMentionEncrypted={infoMentionEncrypted}

                                bgColor='#c8c8ff'
                                allowEncryption={true}
                                privacyMention='Encrypt post (private to the dialog)'
                                />
                    <br></br>
                </>
                    :
                null

        let attachedImagePart = ''
        let referencedMediaPart = ''

        const dtx = this.state.dtx

        // copied from contentPresenter's render()
        if ( dtx?.contentAttached ) {
            console.log("content attached. real length:  ", dtx?.attachedContent.length/2)
            const attachmentBase64 = Buffer.from(dtx?.attachedContent, 'hex').toString('base64')
            console.log("content attached. base-64 length:  ", attachmentBase64.length)

            attachedImagePart =
                  `
                  <img src='data:image/png;base64,` + attachmentBase64 + `' alt='attached image' />
                  <br></br>
                  `
        } else if ( dtx?.referencedMediaTxId && dtx?.referencedMediaTxId !== '' ) {
            console.warn("This dialog tx references a Media tx: " + dtx.referencedMediaTxId)
            //console.warn("The state.mediaURL is (NOT CURRENTLY USED): " + this.state.mediaURL)

            // Note that we had to cut-off the codecs part of the videoType
            //     video/webm; codecs=opus,vp8
            // So, we just harcode to video/webm for now
            referencedMediaPart =
                    `
                    <video width='200' height='150' src='data:video/webm;base64,` + this.state.videoBuff64 + `' controls >
                    Your browser does not support the video tag.
                    </video>
                    <br></br>
                    `

            console.warn("HERE's the referencedMedia part (already fetched): " + referencedMediaPart)

        }
        const cPlus =
                `<html>
                   <body>` +
                    attachedImagePart +
                    referencedMediaPart +
                    utf8Content +
                   `</body>
                 </html>
                `

        const allowJsModalsAndForms = ' allow-scripts allow-modals allow-forms'
        // Don't allow guest-posts to use javascript, modals, nor forms
        const sandboxParams = "allow-popups-to-escape-sandbox allow-popups" //+ allowJsModalsAndForms

        const frameWidth  =  "100%"
        const frameHeight = "200"

        const cssWidth = '100%'
        const cssHeight = '212px'
        const contentBorderWidth = '6px'

        // background was: '#fee9ff'

        const fullScreenExplainerText = <>Full Screen</>
        const fullScreenExplainer =
                        <button onClick={() => requestFullScreen(this.refToIFrm)}
                                style={{height:'20px', width:'20px', margin:'0px', padding:'0px', cursor:'pointer'}}>
                            +
                        </button>
        //FIXED/HACK: popper prop (below) 'fixes' blurring/dimming of modal's video Popup
        //            see this: https://github.com/Semantic-Org/Semantic-UI-React/issues/1545
        const popUpFullScreen = <Popup style={{backgroundColor: this.bshzLightYellow}}
                                        trigger={fullScreenExplainer}
                                        wide="very"
                                        content={fullScreenExplainerText}
                                        popper={<div style={{ filter: 'none' }}></div>}
                                        hideOnScroll/>

        const iframeContent =   <iframe sandbox={sandboxParams} id="contentFrame" width={frameWidth} height={frameHeight}
                                    ref={this.refToIFrm}
                                    title="content frame" srcDoc={cPlus} style={{backgroundColor: messageBackground}}>
                                </iframe>
        const wrappedIframe =
                <>
                    <div className='scrollFull' style={{ height: cssHeight,
                                                        width: cssWidth,
                                                        borderWidth: contentBorderWidth,
                                                        borderStyle: 'solid',
                                                        borderColor: contentBorderColor}}>
                        {iframeContent}
                    </div>
                    {popUpFullScreen}
                </>

        // sizes: mini tiny small large big huge massive
        //FIXME: use a Container, or a Message?
        const maybeRespondToModal = this.state.showRespondToModal ?
                        <Modal dimmer='blurring' size='large' centered className={modalClassName}  open={true}
                                        style={{backgroundColor: this.bshzPurple, borderRadius: '20px', height: "auto"}}>

                            <Modal.Header style={{textAlign: 'center', backgroundColor: this.bshzPurple, borderRadius: '20px'}}>
                                <span style={{color: this.bshzYellow}}> Your empty-so-far dialogue with {this.state.domainOfInitiator.toUpperCase()} </span>
                            </Modal.Header>

                            <Modal.Content className={modalContentClassName} scrolling style={{backgroundColor: '#c8c8FF'}}>

                                {maybeNavButtons}
                                <br></br>
                                <br></br>

                                The Dialogue-creating (first) post by <b style={{color:'blue'}}>{this.state.domainOfInitiator.toUpperCase()}</b>:
                                {wrappedIframe}
                                <br></br>
                                txId: {this.state.latestTxId}
                                <br></br>
                                {decryptionMention}



                                <h3>
                                    Respond to this Dialogue with <b style={{color:'blue'}}>{this.state.domainOfInitiator.toUpperCase()}</b>: &nbsp;
                                    <Icon className="hoverLink" name='plus circle' onClick={this.openPostPopup} size='big' style={{color:'blue'}}/>
                                </h3>

                                (You've <b>not</b> yet responded to this dialogue)
                                {maybePostPopup}


                                <br></br>


                                <div style={{textAlign: 'center'}}>
                                        <Button negative onClick={this.closeRespondToModal} content="Back"/>
                                </div>

                            </Modal.Content>
                        </Modal>
                    :
                        null


        //FIXME: Even if we're not looking at the FINAL post, this dialog may be closed
        //       So, change to detecting this.state.dialogIsClosed
        //       Can detect in:
        //           - componentDidMount() ?
        //           - dialogChosen() - while seeking to end
        //           - navigation - finding a new post
        const maybeAddToDialog = thisDialogIsClosed ?
                                        <>
                                            <h2>This Dialogue has been CLOSED.</h2>
                                            No more posts can be made to it.
                                            <br></br>
                                            <br></br>
                                            If you wish to continue conversing with <b style={{color:'blue'}}>{this.state.domainOfOtherGuy?.toUpperCase()}</b>, you'll
                                            need to create a new Dialogue with them (if you don't already have one).
                                        </>
                                    :
                                        <>
                                            <h3>
                                                Post to this Dialog with <b style={{color:'blue'}}>{this.state.domainOfOtherGuy?.toUpperCase()}</b>:
                                                &nbsp;<Icon className="hoverLink" name='plus circle' onClick={this.openPostPopup} size='large' style={{color:'blue'}}/>
                                            </h3>


                                            {maybePostPopup}


                                            <Checkbox label="Make this the FINAL post" checked={this.state.thisWillBeFinalPost} onClick={this.toggleCloseThisModal}/>

                                            <br></br>
                                            {finalPostMention}

                                        </>

        const postMofN = <>Post <b style={{fontSize:'1.3rem'}}>{postNum}</b> of {furthest}</>
        const maybeNavigationModal = this.state.showNavigationModal ?
                        <Modal dimmer='blurring' size='fullscreen' centered className={modalClassName}  open={true}
                                        style={{backgroundColor: this.bshzPurple, borderRadius: '20px', height: "auto"}}>

                            <Modal.Header style={{textAlign: 'center', backgroundColor: this.bshzPurple, borderRadius: '20px'}}>
                                <span style={{color: this.bshzYellow}}> Your dialogue with {this.state.domainOfOtherGuy?.toUpperCase()} </span>
                            </Modal.Header>

                            <Modal.Content className={modalContentClassName} scrolling style={{backgroundColor: '#c8c8FF'}}>

                                <span style={{fontSize:'.8rem'}}>(dialogue Id: {this.state.dialogId})</span>
                                <br></br>

                                <Button disabled={disablePrevButtton} positive onClick={this.goTo0thPost} style={{width:"25px", margin:"2px", padding:"10px 15px 10px 10px"}}>{revTo0Button}</Button>
                                <Button disabled={disablePrevButtton} positive onClick={this.goBack}      style={{width:"25px", margin:"2px", padding:"10px 15px 10px 10px"}} content={leftArrowButtonContent}/>
                                &nbsp; &nbsp; {postMofN} &nbsp; &nbsp;
                                <Button disabled={false} positive onClick={this.goForward}              style={{width:"25px", margin:"2px", padding:"10px 25px 10px 0px"}} content={rightArrowButtonContent}/>
                                <Button disabled={disableFFwd} positive onClick={this.goToFurthestPost} style={{width:"25px", margin:"2px", padding:"10px 25px 10px 0px"}}>{ffwdButton}</Button>

                                <br></br>

                                Contents of this post by <b style={{color:authorColor}}> {this.state.authorName?.toUpperCase()}</b>:
                                {wrappedIframe}
                                <br></br>

                                txId: {this.state.latestTxId}
                                <br></br>
                                {decryptionMention}


                                {maybeAddToDialog}

                                <br></br>


                                <div style={{textAlign: 'center'}}>
                                        <Button negative onClick={this.closeNavigationModal} content="Back"/>
                                </div>

                            </Modal.Content>
                        </Modal>
                    :
                        null

        const pluralOrSingleDialog = this.state.relevantDialogsCount === 1 ? "Dialogue" : "Dialogues"
        const mainModal = this.props.jumpToSingleDialog ?
                <>
                </>
            :
                <>
                    <Modal dimmer='blurring' size='small' centered className={modalClassName}  open={true}
                                    style={{backgroundColor: this.bshzPurple, borderRadius: '20px', height: "auto"}}>

                        <Modal.Header style={{textAlign: 'center', backgroundColor: this.bshzPurple, borderRadius: '20px'}}>
                            <span style={{color: this.bshzYellow}}> Your Dialogues for {getBitcoinDomainName( this.props.ourIdentity)} </span>
                        </Modal.Header>

                        <Modal.Content className={modalContentClassName} scrolling style={{backgroundColor: this.bshzLtPurp}}>

                        <div style={{textAlign: 'center', color:'blue', fontSize: "1.3rem"}}>
                            We've found {this.state.relevantDialogsCount} {pluralOrSingleDialog}

                            <br></br>

                        </div>

                            <Table unstackable celled style={{borderSpacing:"0px", border:'none', backgroundColor:"#00000000"}} >
                                <Table.Header>
                                    <Table.Row>
                                        <Table.HeaderCell style={{backgroundColor:'blue', color:'white', padding:'8px 8px'}}>id</Table.HeaderCell>
                                        <Table.HeaderCell style={{backgroundColor:'blue', color:'white', padding:'8px 8px'}}>with</Table.HeaderCell>
                                        <Table.HeaderCell style={{backgroundColor:'blue', color:'white', padding:'8px 8px'}}>msgs</Table.HeaderCell>
                                        <Table.HeaderCell style={{backgroundColor:'blue', color:'white', padding:'8px 8px'}}>status</Table.HeaderCell>
                                    </Table.Row>
                                </Table.Header>
                                <Table.Body>
                                    {dialogsList}
                                </Table.Body>
                            </Table>

                            <Checkbox label="Hide closed dialogues" checked={this.state.hideClosedDialogs} onClick={this.toggleHideClosedDialogs}/>
                        <br></br>
                        <br></br>
                        Click on a Dialogue above, to view or post to it.
                        <br></br>
                        <br></br>
                        <div style={{textAlign: 'center'}}>
                                <Button positive onClick={this.closeDialogManager} content='BACK'/>
                        </div>

                        </Modal.Content>

                    </Modal>
                </>

        // This didn't matter:
        //     <List divided selection bulleted items={dialogsList}/>

        return(
            <>
            {mainModal}

            {maybeRespondToModal}
            {maybeNavigationModal}

            <Modal dimmer='blurring' size='mini' centered className={modalClassName}  open={this.state.showSwirly}
                            style={{backgroundColor: this.bshzPurple, borderRadius: '20px', height: "auto"}}>
                <Modal.Content className={modalContentClassName} scrolling style={{backgroundColor: this.bshzLtPurp}}>

                    <div style={{textAlign: 'center'}}>
                        <Icon size='large'  loading name='spinner' />
                    </div>

                    <div style={{textAlign: 'center'}}>
                        {this.state.swirlyText}
                    </div>

                </Modal.Content>
            </Modal>

            </>
        )
    }
}

export default DialogManager;
