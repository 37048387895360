"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var getOption_1 = require("./getOption");
function getTopType(options, random) {
    var topType = [];
    if (getOption_1.getOption('top', 'longHair', options)) {
        topType.push('LongHairBigHair', 'LongHairBob', 'LongHairBun', 'LongHairCurly', 'LongHairCurvy', 'LongHairDreads', 'LongHairFrida', 'LongHairFro', 'LongHairFroBand', 'LongHairMiaWallace', 'LongHairNotTooLong', 'LongHairShavedSides', 'LongHairStraight', 'LongHairStraight2', 'LongHairStraightStrand');
    }
    if (getOption_1.getOption('top', 'shortHair', options)) {
        topType.push('ShortHairDreads01', 'ShortHairDreads02', 'ShortHairFrizzle', 'ShortHairShaggy', 'ShortHairShaggyMullet', 'ShortHairShortCurly', 'ShortHairShortFlat', 'ShortHairShortRound', 'ShortHairShortWaved', 'ShortHairSides', 'ShortHairTheCaesar', 'ShortHairTheCaesarSidePart');
    }
    if (getOption_1.getOption('top', 'eyepatch', options)) {
        topType.push('Eyepatch');
    }
    if (getOption_1.getOption('top', 'hat', options)) {
        topType.push('Hat', 'WinterHat1', 'WinterHat2', 'WinterHat3', 'WinterHat4');
    }
    if (getOption_1.getOption('top', 'hijab', options)) {
        topType.push('Hijab');
    }
    if (getOption_1.getOption('top', 'turban', options)) {
        topType.push('Turban');
    }
    var pickedTopType = random.pickone(topType);
    if (false === random.bool(undefined !== options.topChance ? options.topChance : 100)) {
        return 'NoHair';
    }
    return pickedTopType;
}
exports.getTopType = getTopType;
