import React from 'react';

import { Container, Icon, Popup, Segment } from 'semantic-ui-react'

import './index.css';


class ShizzleWhy extends React.PureComponent {
    constructor(props) {
        super(props);

        this.shizzleVerse        = <><b>ShizzleVerse</b><small>&trade;</small></>;
        this.shizzleVerseNotBold = <>ShizzleVerse<small>&trade;</small></>;
        this.bitShizzle          = <><b>BitShizzle</b><small>&trade;</small></>;
        this.bitShizzleNotBold   = <>BitShizzle<small>&trade;</small></>;

        this.topic1_openProtocol    = "Permissionless Protocol";
        this.topic2_unlockedData    = "Unlocked Data";
        this.topic3_privateComms    = "Private Communications";
        this.topic4_trueOwnership   = "True Ownership";
        this.topic5_serverlessWeb   = "\"Serverless\" Websites";
        this.topic6_collabPreserved = "Collaboration Preserved";
        this.topic7_adFreeAlt       = "Ad-Free Alternative";
        this.topic8_spamResistant   = "Spam-Resistant";
        this.bshzPurple             = '#8313e2';
        this.bshzYellow             = "#fff300";
        this.bshzLtPurp             = '#e7d0fb';
        this.bshzLightYellow        = "#fffccc";

        this.browserExplanation =
        <>
            <p>
                The {this.bitShizzleNotBold} Bitcoin browser allows you to claim, explore, and build on Bitcoin identities and websites in the {this.shizzleVerseNotBold}.
            </p>
        </>
        this.websiteExplanation =
        <>
            <p>
                A {this.shizzleVerseNotBold} Bitcoin website can be claimed, built, and viewed using {this.bitShizzleNotBold}. Constructed of html, css, and javascript, it's limited only by your imagination.
            </p>
        </>
        this.identityExplanation =
        <>
            <p>
                A {this.shizzleVerseNotBold} Bitcoin identity is like a Twitter+Email account, except that it's built with Bitcoin, and you can <b>own</b> it.
            </p>
        </>

        this.topicAVerbage =
            <p>
                Built on the Bitcoin blockchain, the {this.shizzleVerse} is an <b>open</b>, fixed, protocol - one that anyone can use to publish on, communicate with, pay with, and build on top of (and all without any need for "permission"). No one can change or corrupt this protocol, as it's set in stone. {this.bitShizzle} is the&nbsp;
                <Popup style={{backgroundColor: this.bshzLightYellow}} trigger={<span style={{textDecoration: "underline"}}>Bitcoin Browser</span>} wide="very" content={this.browserExplanation} hideOnScroll/>
                &nbsp;which allows you to explore the {this.shizzleVerse}.
            </p>
        this.topicBVerbage =
            <p>
                Enforced by smart contracts, no one can take away your {this.shizzleVerse}&nbsp;
                <Popup style={{backgroundColor: this.bshzLightYellow}} trigger={<span style={{textDecoration: "underline"}}>Bitcoin identity</span>} wide="very" content={this.identityExplanation} hideOnScroll/>
                &nbsp;or&nbsp;
                <Popup style={{backgroundColor: this.bshzLightYellow}} trigger={<span style={{textDecoration: "underline"}}>website</span>} wide="very" content={this.websiteExplanation} hideOnScroll/>.
                &nbsp;No one can prevent you from using it to publish, communicate, make payments, or build on. No one can prevent you from re-selling it.
            </p>
        this.topicCVerbage =
            <p>
                Even though your published data may be sitting on the public blockchain, optional encryption means that your private messages will stay private from marketers and hackers.
            </p>
        this.topicDVerbage =
            <p>
                Spammers only profit because <i>traditional</i> email is free. Optional minimum-micro-tipping communications means spammers can't afford your time and attention.
            </p>
        this.topicEVerbage =
            <p>
                Powered by micro-payments, bloggers, podcasters, and artists can benefit <b>directly</b> from their fans - without resorting to selling ads.
            </p>
        this.topicFVerbage =
            <p>
                The {this.shizzleVerse} prevents platform lock-in. Your data can never be held hostage. Anyone can "index" (see and store) it, and anyone can serve it up. It's all sitting on the Bitcoin blockchain.
            </p>
        this.topicGVerbage =
            <p>
                Because your {this.shizzleVerse} website is built on top of Bitcoin, you won't need to run a web server. Bitcoin is always running.
            </p>
        this.topicHVerbage =
            <p>
                Bitcoin-native group communications can empower collaboration that doesn't disappear at the end of some annual contract.
            </p>

        this.state =  {
                        showGenericOkModal: false,
                        mostRecentTopic: 0,
                        label1: <h3 style={{height: "27px"}}>{this.topic1_openProtocol}</h3>,
                        label2: <h3 style={{height: "27px"}}>{this.topic2_unlockedData}</h3>,
                        label3: <h3 style={{height: "27px"}}>{this.topic3_privateComms}</h3>,
                        label4: <h3 style={{height: "27px"}}>{this.topic4_trueOwnership}</h3>,
                        label5: <h3 style={{height: "27px"}}>{this.topic5_serverlessWeb}</h3>,
                        label6: <h3 style={{height: "27px"}}>{this.topic6_collabPreserved}</h3>,
                        label7: <h3 style={{height: "27px"}}>{this.topic7_adFreeAlt}</h3>,
                        label8: <h3 style={{height: "27px"}}>{this.topic8_spamResistant}</h3>,

                        colorOpenProtocol: '#e7d0fb', //this.bshzLtPurp,
                        colorUnlockData:   '#e7d0fb', //this.bshzLtPurp,
                        colorPrivateComm:  '#e7d0fb', //this.bshzLtPurp,
                        colorTrueOwner:    '#e7d0fb', //this.bshzLtPurp,
                        colorSimpler:      '#e7d0fb', //this.bshzLtPurp,
                        colorCollab:       '#e7d0fb', //this.bshzLtPurp,
                        colorAdFree:       '#e7d0fb', //this.bshzLtPurp,
                        colorSpamResist:   '#e7d0fb', //this.bshzLtPurp,
                      }


        this.handleMouseEnter       = this.handleMouseEnter.bind(this);
        this.handleMouseLeave       = this.handleMouseLeave.bind(this);

        this.handleServerErrorModalOk = this.handleServerErrorModalOk.bind(this);

    }

    handleServerErrorModalOk() {
        this.setState({showServerErrorModal: false})
    }

    // user hovering over a segment
    handleMouseEnter(which) {
        console.log("Mouse entered " + which)
        // Open Protocol
        const blurb1 = <>The {this.shizzleVerseNotBold} is built using Bitcoin smart-contracts. Forthcoming javascript libraries will enable anyone to view, publish, and build on it - even without {this.bitShizzleNotBold}.</>

        // Unlocked Data
        const blurb2 = <>An optional 'serverless' viewer mode removes the dependence on a "Shizzle" provider.</>

        // Private Communications
        const blurb3 = <>There's no reason to sacrifice privacy when using Bitcoin. What you do with {this.bitShizzle} can be as private or public as you want it to be.</>

        // True Ownership
        const blurb4 = <>Once you own a {this.shizzleVerseNotBold} identity or website, it's yours to use as you see fit.</>

        // 'Serverless' Websites
        const blurb5 = <>{this.shizzleVerseNotBold} websites have no back-end. They live in the "Bitcoin Cloud". This means they're much simpler to construct, and there's nothing to maintain.</>

        // Collaboration Preserved
        const blurb6 = <>Anything written to the {this.shizzleVerseNotBold} is there forever.</>

        // Ad-Free Alternative
        const blurb7 = <><p>Since there is no company in charge of the {this.shizzleVerseNotBold}, you're not beholden to the whims of a monopoly who might decide to promote some product right next to your tweet.</p>
                        <p>At the same time, anyone inspired by your posts or website can choose to tip <b>you</b> any amount, at any time.</p>
                        </>

        // Spam-Resistant Communications
        const blurb8 = <><p>Unlike traditional e-mail, in the {this.shizzleVerseNotBold} <b>you</b> decide if you'll entertain solicitations. <b>You</b> set the price for your attention. Anyone who wants to reach you, must pay you that amount. Anyone who doesn't pay that amount, <b>can't</b> reach you. Even half a penny may be too rich for spammers who send out millions of emails each day.</p><p>Operating in the {this.shizzleVerseNotBold} is like waving good-bye to spammers.</p></>

        //console.log("ENTER: mostRecentTopic: ", this.state.mostRecentTopic + "   before setting NEW topic (which): ", which)
        if ( typeof this.state.mostRecentTopic !== 'undefined' && this.state.mostRecentTopic !== which ) {

            // the semantic ui <Popup>s on <Icon>s cause trouble: if we do a two-finger scroll away while
            // Popup is displayed, we miss the handleMouseLeave() callback.
            // We now keep track of mostRecentTopic - to clear it if/when this happens, and we later enter or leave
            this.handleMouseLeave(this.state.mostRecentTopic)
        }

        const anIcon  = <Icon link circular size='small' color='green' name='help' />

        // NOTE: "position" property of popups seems to be important for mobile
        //       Without it, some popups would not render. Even with it, the exact value was important.
        //       Values were determined empirically
        switch (which) {
            case 1:
                this.setState({ mostRecentTopic: 1, colorOpenProtocol: "white",
                                label1: <h3 style={{color: this.bshzPurple, height: "27px"}}>{this.topic1_openProtocol}  &nbsp;
                                            <Popup style={{backgroundColor: this.bshzLightYellow}} position="bottom left" trigger={anIcon} wide="very" content={blurb1} hideOnScroll/>
                                        </h3>})
                break;
            case 2:
                this.setState({ mostRecentTopic: 2, colorUnlockData: "white",
                                label2: <h3 style={{color: this.bshzPurple, height: "27px"}}>{this.topic2_unlockedData} &nbsp;
                                            <Popup style={{backgroundColor: this.bshzLightYellow}} position="top center" trigger={anIcon} wide="very" content={blurb2} hideOnScroll/>
                                        </h3>})
                break;
            case 3:
                this.setState({ mostRecentTopic: 3, colorPrivateComm: "white",
                                label3: <h3 style={{color: this.bshzPurple, height: "27px"}}>{this.topic3_privateComms} &nbsp;
                                            <Popup style={{backgroundColor: this.bshzLightYellow}} position="bottom left" trigger={anIcon} wide="very" content={blurb3} hideOnScroll/>
                                        </h3>})
                break;
            case 4:
                this.setState({ mostRecentTopic: 4, colorTrueOwner: "white",
                                label4: <h3 style={{color: this.bshzPurple, height: "27px"}}>{this.topic4_trueOwnership} &nbsp;
                                            <Popup style={{backgroundColor: this.bshzLightYellow}} position="top center" trigger={anIcon} wide="very" content={blurb4} hideOnScroll/>
                                        </h3>})
                break;
            case 5:
                this.setState({ mostRecentTopic: 5, colorSimpler: "white",
                                label5: <h3 style={{color: this.bshzPurple, height: "27px"}}>{this.topic5_serverlessWeb} &nbsp;
                                            <Popup style={{backgroundColor: this.bshzLightYellow}} position="top left" trigger={anIcon} wide="very" content={blurb5} hideOnScroll/>
                                        </h3>})
                break;
            case 6:
                this.setState({ mostRecentTopic: 6, colorCollab: "white",
                                label6: <h3 style={{color: this.bshzPurple, height: "27px"}}>{this.topic6_collabPreserved} &nbsp;
                                            <Popup style={{backgroundColor: this.bshzLightYellow}} position="top left" trigger={anIcon} wide="very" content={blurb6} hideOnScroll/>
                                        </h3>})
                break;
            case 7:
                this.setState({ mostRecentTopic: 7, colorAdFree: "white",
                                label7: <h3 style={{color: this.bshzPurple, height: "27px"}}>{this.topic7_adFreeAlt} &nbsp;
                                            <Popup style={{backgroundColor: this.bshzLightYellow}} position="top center" trigger={anIcon} wide="very" content={blurb7} hideOnScroll/>
                                        </h3>})
                break;
            case 8:
                this.setState({ mostRecentTopic: 8, colorSpamResist: "white",
                                label8: <h3 style={{color: this.bshzPurple, height: "27px"}}>{this.topic8_spamResistant} &nbsp;
                                            <Popup style={{backgroundColor: this.bshzLightYellow}} position="top center" trigger={anIcon} wide="very" content={blurb8} hideOnScroll/>
                                        </h3>})
                break;

            // sign-up button
            case 9:
                this.setState({invertSignUp: true})
                break;

            default:
                break;
        }
    }

    handleMouseLeave(which) {

       // console.log("LEAVE: mostRecentTopic: ", this.state.mostRecentTopic + "   before CLEARING topic (which): ", which)
        if ( typeof this.state.mostRecentTopic !== 'undefined' && this.state.mostRecentTopic !== which ) {
            //console.log("   LEAVE: new (which) has len ", which.length)

            // the semantic ui <Popup>s on <Icon>s cause trouble: if we do a two-finger scroll away while
            // Popup is displayed, we miss the handleMouseLeave() callback.
            // We now keep track of mostRecentTopic - to clear it if/when this happens, and we later enter or leave
            //alert("LEAVE: FOUND a problem. will correct. old " + this.state.mostRecentTopic + " != new ", which)
            this.handleMouseLeave(this.state.mostRecentTopic)
        }

        switch (which) {
            case 1:
                this.setState({colorOpenProtocol: this.bshzLtPurp, label1: <h3 style={{height: "27px"}}>{this.topic1_openProtocol}</h3>})
                break;
            case 2:
                this.setState({colorUnlockData: this.bshzLtPurp, label2: <h3 style={{height: "27px"}}>{this.topic2_unlockedData}</h3>})
                break;
            case 3:
                this.setState({colorPrivateComm: this.bshzLtPurp, label3: <h3 style={{height: "27px"}}>{this.topic3_privateComms}</h3>})
                break;
            case 4:
                this.setState({colorTrueOwner: this.bshzLtPurp, label4: <h3 style={{height: "27px"}}>{this.topic4_trueOwnership}</h3>})
                break;
            case 5:
                this.setState({colorSimpler: this.bshzLtPurp, label5: <h3 style={{height: "27px"}}>{this.topic5_serverlessWeb}</h3>})
                break;
            case 6:
                this.setState({colorCollab: this.bshzLtPurp, label6: <h3 style={{height: "27px"}}>{this.topic6_collabPreserved}</h3>})
                break;
            case 7:
                this.setState({colorAdFree: this.bshzLtPurp, label7: <h3 style={{height: "27px"}}>{this.topic7_adFreeAlt}</h3>})
                break;
            case 8:
                this.setState({colorSpamResist: this.bshzLtPurp, label8: <h3 style={{height: "27px"}}>{this.topic8_spamResistant}</h3>})
                break;

            // sign-up button
            case 9:
                this.setState({invertSignUp: false})
                break;

            default:
                break;
        }
    }

    render() {

        //NOTE: Segment onClick() handlers added to mitigate flaws in semantic ui onMouseX() detection on mobile
        //      Big improvement on mobile
        return(
            <>
                <p>The {this.shizzleVerse} is an expanding Bitcoin World Wide Web. Help grow it using {this.bitShizzle}. Surf it using {this.bitShizzle}.</p>
                <p>Here's why it's better than what you're used to:</p>
                        <Container text >
                            <Segment raised size={"small"} onClick={() => this.handleMouseEnter(1)}
                                            style={{backgroundColor: this.state.colorOpenProtocol}}
                                            onMouseEnter={() => this.handleMouseEnter(1)}
                                            onMouseLeave={() => this.handleMouseLeave(1)}>
                                {this.state.label1}
                                {this.topicAVerbage}
                            </Segment>

                            <Segment raised size={"small"} onClick={() => this.handleMouseEnter(4)}
                                            style={{backgroundColor: this.state.colorTrueOwner}}
                                            onMouseEnter={() => this.handleMouseEnter(4)}
                                            onMouseLeave={() => this.handleMouseLeave(4)}>
                                {this.state.label4}
                                {this.topicBVerbage}
                            </Segment>

                            <Segment raised size={"small"} onClick={() => this.handleMouseEnter(3)}
                                            style={{backgroundColor: this.state.colorPrivateComm}}
                                            onMouseEnter={() => this.handleMouseEnter(3)}
                                            onMouseLeave={() => this.handleMouseLeave(3)}>
                                {this.state.label3}
                                {this.topicCVerbage}
                            </Segment>

                            <Segment raised size={"small"} onClick={() => this.handleMouseEnter(8)}
                                            style={{backgroundColor: this.state.colorSpamResist}}
                                            onMouseEnter={() => this.handleMouseEnter(8)}
                                            onMouseLeave={() => this.handleMouseLeave(8)}>
                                {this.state.label8}
                                {this.topicDVerbage}
                            </Segment>

                            <Segment raised size={"small"} onClick={() => this.handleMouseEnter(7)}
                                            style={{backgroundColor: this.state.colorAdFree}}
                                            onMouseEnter={() => this.handleMouseEnter(7)}
                                            onMouseLeave={() => this.handleMouseLeave(7)}>
                                {this.state.label7}
                                {this.topicEVerbage}
                            </Segment>

                            <Segment raised size={"small"} onClick={() => this.handleMouseEnter(2)}
                                            style={{backgroundColor: this.state.colorUnlockData}}
                                            onMouseEnter={() => this.handleMouseEnter(2)}
                                            onMouseLeave={() => this.handleMouseLeave(2)}>
                                {this.state.label2}
                                {this.topicFVerbage}
                            </Segment>

                            <Segment raised size={"small"} onClick={() => this.handleMouseEnter(5)}
                                            style={{backgroundColor: this.state.colorSimpler}}
                                            onMouseEnter={() => this.handleMouseEnter(5)}
                                            onMouseLeave={() => this.handleMouseLeave(5)}>
                                {this.state.label5}
                                {this.topicGVerbage}
                            </Segment>

                            <Segment raised size={"small"} onClick={() => this.handleMouseEnter(6)}
                                            style={{backgroundColor: this.state.colorCollab}}
                                            onMouseEnter={() => this.handleMouseEnter(6)}
                                            onMouseLeave={() => this.handleMouseLeave(6)}>
                                {this.state.label6}
                                {this.topicHVerbage}
                            </Segment>

                        </Container>
            </>
        )
    }
}

export default ShizzleWhy;