// genericModal.js

// simple interface for simple modals

import React, {Component} from 'react';

import { Button, Modal } from 'semantic-ui-react'

class GenericModal extends React.Component {
  constructor(props) {
    super(props);

    this.handleTesterClick = this.openOnClick.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.openOnClick = this.openOnClick.bind(this)
    this.runAndClose = this.runAndClose.bind(this)

    this.state = {
	openModal: false
    };
  }

  openOnClick() {
    this.setState({openModal: true})
  }

  runAndClose() {
    if ( this.props.onClick ) {
      this.props.onClick()
    } else {
      console.warn("GenericModal: no 'onClick' property was defined")
    }
    this.closeModal()
  }

  closeModal() {
    this.setState({openModal: false})
  }

  render() {
    const title = this.props.title ? <Modal.Header className="modaledge">
                                      {this.props.title}
                                      <br></br>
                                    </Modal.Header>
                                  : null
    const mainText = this.props.mainText
    const openButton = this.props.openButton
    const yButton    = this.props.yButton ?  this.props.yButton : "OK"
    const noButton   = this.props.nButton ? <Button onClick={ this.closeModal } content={this.props.nButton} negative/> : null

    return (
      <>
      <button onClick={this.openOnClick}>{openButton}</button>

      <Modal size='mini' dimmer='blurring' open={this.state.openModal}>
                    {title}

                    <br></br> &nbsp; {mainText}<p></p>


                    <Modal.Actions className="modaledge">
                      <Button disabled={false} content={yButton} positive onClick={this.runAndClose}/>
                      {noButton}
                    </Modal.Actions>
                  </Modal>
      </>
          )
  }
}

// ========================================

export default GenericModal;
