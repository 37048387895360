import React from 'react';

import { Button, Checkbox, Divider, Icon, Modal, Popup, TextArea, Transition } from 'semantic-ui-react'

import ShizzleWhy      from './shizzleWhy';

import './index.css';
import { saveSetting, readSetting, openDB } from './buildShizzle';

function execAsync2( f, msecs ) {
    setTimeout(f, msecs)
}

class Footer extends React.PureComponent {
    constructor(props) {
        super(props);

        this.MAX_FEEDBACK_LEN    = 400;

        this.fontFamily          = "verdana"

        this.BitSchnitzelLLC     = <>BitSchnitzel LLC</>;
        this.shizzleVerse        = <><b>ShizzleVerse</b><small>&trade;</small></>;
        this.bitShizzle          = <><b>BitShizzle</b><small>&trade;</small></>;
        this.bitShizzleItalic    = <><b>Bit<i>Shizzle</i></b><small>&trade;</small></>;
        this.shizzleVerseNotBold = <>ShizzleVerse<small>&trade;</small></>;
        this.bitShizzleNotBold   = <>BitShizzle<small>&trade;</small></>;

        //FIXME: the purples are currently duplicated in index.css
        this.bshzPurple             = '#8313e2';
        this.bshzYellow             = "#fff300";
        this.bshzLtPurp             = '#e7d0fb';
        this.bshzLightYellow        = "#fffccc";


        this.state =  {
                        feedback: null,
                        feedbackSent: false,        // prevents user from 'contacting'/feedback mulltiple times
                        showWhy: false,
                        showTerms: false,
                        showPrivacy: false,
                        showSecurity: false,
                        showGlossary: false,
                        showContact: false,

                        showGenericOkModal: false,
                        showServerErrorModal: false,

                        understandsTermsBoxChecked: false,

                        showLeftMost: true,
                      }

        this.setUnderstandsTermsBox = this.setUnderstandsTermsBox.bind(this);
        this.getUnderstandTermsSetting = this.getUnderstandTermsSetting.bind(this);

        this.showWhyModal           = this.showWhyModal.bind(this);

        this.handleFeedback         = this.handleFeedback.bind(this);

        this.handleGenericModalOk   = this.handleGenericModalOk.bind(this);

        this.handleTermsClick       = this.handleTermsClick.bind(this);
        this.handleTermsOk          = this.handleTermsOk.bind(this);

        this.handlePrivacyClick     = this.handlePrivacyClick.bind(this);
        this.handlePrivacyOk        = this.handlePrivacyOk.bind(this);

        this.handleSecurityClick    = this.handleSecurityClick.bind(this);
        this.handleSecurityOk       = this.handleSecurityOk.bind(this);

        this.handleGlossaryClick    = this.handleGlossaryClick.bind(this);
        this.handleGlossaryOk       = this.handleGlossaryOk.bind(this);

        this.handleContactClick     = this.handleContactClick.bind(this);
        this.handleContactSend      = this.handleContactSend.bind(this);
        this.handleContactCancel    = this.handleContactCancel.bind(this);

        this.handleServerErrorModalOk = this.handleServerErrorModalOk.bind(this);

        this.maybeLeft              = this.maybeLeft.bind(this);
        this.maybeRight             = this.maybeRight.bind(this);
    }

    async setUnderstandsTermsBox() {
        console.warn("understands Terms checked OLD (about to be toggled): ", this.state.understandsTermsBoxChecked)
        const newValue = !this.state.understandsTermsBoxChecked
        this.setState({understandsTermsBoxChecked: newValue})

        // save this to IDB - when UNDERSTOOD is pressed
        if ( !newValue ) {
            console.log("UNsetting termsUnderstood")
            //alert("UNsetting termsUnderstood     following (old value) should be true: ", this.state.understandsTermsBoxChecked)
            await saveSetting(await openDB(), "termsUnderstood", false)
            //alert("check the IDB now. should be clear/false")
        } else {
            //alert("NOT yet setting. wait for "Understood" button to be pressed")
        }
    }
    async getUnderstandTermsSetting() {

        //FIXME: check the date. compare to here-hardcoded latest revision to Terms

        const settingRecord = await readSetting(await openDB(), "termsUnderstood")
        console.log("termsUnderstood record: ", settingRecord)

        return settingRecord
    }

    async componentDidMount() {
        let showTerms = false
        const settingRecord = await this.getUnderstandTermsSetting()
        console.log("FOOTER: componentDidMount() termsUnderstood record: ", settingRecord)
        if ( settingRecord === null ) {
            //alert("footer componentDidMount(). let's show Terms modal")
            showTerms = true

            this.setState({understandsTermsBoxChecked: false})  //understandsTermsBoxChecked
        } else {
            console.log("non-null setting for termsUnderstood record: ", settingRecord)
            if ( settingRecord.value ) {
                showTerms = false

                console.log("componentDidMount:  value for termsUnderstood is true. will turn OFF showTerms")
                this.setState({understandsTermsBoxChecked: true})
            } else {
                showTerms = true

                console.log("componentDidMount:  value for termsUnderstood is false. will turn ON showTerms")
                this.setState({understandsTermsBoxChecked: false})
            }
        }

        if ( this.props.enforceTerms ) {
            const me = this

            // wait a bit before showing the Terms of Use
            // IF redeeming, we're likely to first see a surfer 'Fee Settings Modal'
            execAsync2( async function() {
                // tell parent we're showing Terms (or not) - so, maybe disable a button
                me.props.footerFocus(showTerms)
                me.setState({showTerms: showTerms});
            }, 3700)
        }
    }

    componentWillUnmount() {
        console.log("footer about to unmount. Will UNregister...")
        this.props.footerFocus(false)
    
        //this.props.unregisterClient(this.props.parent, this, "onRamp")
    
        // fix Warning: Can't perform a React state update on an unmounted component
        // see: https://stackoverflow.com/questions/53949393/cant-perform-a-react-state-update-on-an-unmounted-component
        this.setState = (state,callback)=>{
            return;
        };
    }

    showWhyModal() {
        if ( this.state.showWhy ) {
            this.props.footerFocus(false)
            this.setState({showWhy: false})
        } else {
            this.props.footerFocus(true)
            this.setState({showWhy: true})
        }
    }

    handleFeedback(event, data) {
        const value = data.value.substr(0, this.MAX_FEEDBACK_LEN)
        console.log("got feedback: ", value)
        this.setState({feedback: value});
    }

    handleGenericModalOk() {
        this.props.footerFocus(false)
        this.setState({showGenericOkModal: false})
    }

    handleTermsClick() {
        // tell parent we're showing Terms - so, maybe disable a button
        this.props.footerFocus(true)

        this.setState({showTerms: true})
    }
    async handleTermsOk() {
        const boxChecked = this.state.understandsTermsBoxChecked
        console.log("SETTING termsUnderstood.   box checked: ", boxChecked)
        if ( boxChecked ) {
            //alert("SETTING termsUnderstood.")
            // save understandsTermsBoxChecked to IDB - when UNDERSTOOD is pressed
            await saveSetting(await openDB(), "termsUnderstood", true)
            //alert("Check setting now. should be true")
        } else {
            //alert("NOT setting box. MAYBE should clear IF enforced")
            //console.warn("NOT setting box. MAYBE we should clear IF enforcing?")
        }

        this.props.footerFocus(false)
        this.setState({showTerms: false})
    }

    handlePrivacyClick() {
        this.props.footerFocus(true)
        this.setState({showPrivacy: true})
    }
    handlePrivacyOk() {
        this.props.footerFocus(false)
        this.setState({showPrivacy: false})
    }

    handleSecurityClick() {
        this.props.footerFocus(true)
        this.setState({showSecurity: true})
    }
    handleSecurityOk() {
        this.props.footerFocus(false)
        this.setState({showSecurity: false})
    }

    handleGlossaryClick() {
        this.props.footerFocus(true)
        this.setState({showGlossary: true})
    }
    handleGlossaryOk() {
        this.props.footerFocus(false)
        this.setState({showGlossary: false})
    }

    handleContactClick() {
        this.props.footerFocus(true)
        this.setState({showContact: true})
    }
    async handleContactSend(e) {   // the user entered feedback, and clicked OK
        e.preventDefault();

        const feedback = this.state.feedback
        console.log("user hit SEND on Contact page. feedback: ", feedback)
        let feedbackSent = false
        if ( feedback !== null && feedback.length > 0 ) {
            feedbackSent = await this.props.sendEmail('', this.state.feedback)
        }

        //NOTE: when testing locally, does it not send (get 500?)
        const thankYouText = feedbackSent ? <>Thank you!</> : <>Thank you anyway</>

        // don't call yet. we're putting up the genericModal
        //this.props.footerFocus(false)

        this.setState({
            showContact: false,
            feedback: null,            // reset the feedback to null
            feedbackSent: feedbackSent,
            showGenericOkModal: true,
            genericModalTitle: thankYouText,
            genericModalContent: <>We appreciate any feedback or questions.</>,
            genericModalButton: <>Okay</>
        })
    }
    handleContactCancel() {
        console.log("user hit CANCEL on Contact page")
        this.props.footerFocus(false)
        this.setState({showContact: false})
    }

    handleServerErrorModalOk() {
        this.props.footerFocus(false)
        this.setState({showServerErrorModal: false})
    }

    maybeLeft() {
        console.log("maybeLeft()...")
        if ( !this.state.showLeftMost ) {
            console.log("  WAS right. Now left")
            this.setState({showLeftMost: true})
        }
    }
    maybeRight() {
        console.log("maybeRight()...")
        if ( this.state.showLeftMost ) {
            console.log("  WAS left. Now right")
            this.setState({showLeftMost: false})
        }
    }

    render() {

        const feedbackSent = this.state.feedbackSent

        const feedbackPlaceholder = 'Enter your question or comment'

        const genericModalTitle   = this.state.genericModalTitle
        const genericModalContent = this.state.genericModalContent
        const genericModalButton  = this.state.genericModalButton

        const contactActive = feedbackSent ? "auto" : "pointer"

        const aboutLink    = <a style={{cursor: "pointer", color: this.bshzPurple}} onClick={this.showWhyModal}>About</a>;
        const termsLink    = <a style={{cursor: "pointer", color: this.bshzPurple}} onClick={this.handleTermsClick}>Terms</a>;
        const privacyLink  = <a style={{cursor: "pointer", color: this.bshzPurple}} onClick={this.handlePrivacyClick}>Privacy</a>;
        const securityLink = <a style={{cursor: "pointer", color: this.bshzPurple}} onClick={this.handleSecurityClick}>Security</a>;
        const glossaryLink = <a style={{cursor: "pointer", color: this.bshzPurple}} onClick={this.handleGlossaryClick}>Glossary</a>;
        const contactLink =  <a style={{cursor: contactActive, color: this.bshzPurple}} onClick={this.handleContactClick}>Contact</a>

        const charCount = this.state.feedback !== null ?
                    <small>({this.state.feedback.length}/{this.MAX_FEEDBACK_LEN})</small>
                :
                    <small>(0/{this.MAX_FEEDBACK_LEN})</small>

        const feedback = this.state.feedback;
        const feedbackExists = feedback !== null && feedback.length > 4;

        // ***  DEVICE-DEPENDENT STYLING  ***
        // For mobile, "detect" landscape screen mode
        const landscape = this.props.isMobile ? window.innerWidth > window.innerHeight : false
        // For mobile, shrink the About, Security, and Glossary modals
        const modalClassName   = this.props.isMobile ?
                                        (landscape ?
                                                "modalMobileLandscape"
                                            :
                                                "modalMobilePortrait"
                                        )
                                    :
                                        ""
        const modalContentClassName = this.props.isMobile ? "modalContentMobile" : ""
        const modalBottomClassName  = this.props.isMobile ? "modalBottomMobile"  : ""

        const understoodButtonColor = this.state.understandsTermsBoxChecked ? "green" : "red"
        const understoodButtonLabel = !this.props.enforceTerms ?
                                                    this.state.understandsTermsBoxChecked ? "Confirmed" : "I do NOT agree"
                                                :
                                                    "Understood"

        const termsColor = this.state.understandsTermsBoxChecked ? "blue" : "red"

        const maybeLeftLink  = this.state.showLeftMost ?
                                <>&nbsp; &nbsp; {aboutLink}&nbsp;|&nbsp;</>
                            :
                                <></>

        const maybeRightLink = this.state.showLeftMost ?
                                <></>
                            :
                                <>|&nbsp;
                                {contactLink}</>

        const leftArrow  = this.state.showLeftMost ?
                                <></>
                            :
                                <><Icon link name='caret left'  color="purple" onClick={this.maybeLeft}/></>
        const rightArrow = this.state.showLeftMost ?
                                <><Icon link name='caret right' color="purple" onClick={this.maybeRight}/></>
                            :
                                <></>

        // icon sizes: mini tiny small large big huge massive
        const poweredByExplainer = <span style={{fontSize:'1.2rem'}}><Icon  size='small' style={{color: this.bshzPurple, verticalAlign:"10%"}} name='question circle' /></span>
        const poweredByExplainerText =  <>
                                            Both of these companies provide valuable services to the Bitcoin ecosystem.
                                            <br></br>
                                            Any action you undertake through this site uses their services - to read from, and write to the blockchain.
                                        </>
        const popupPoweredByExplainer = <Popup style={{backgroundColor: this.bshzLightYellow}} position="top center" trigger={poweredByExplainer} wide="very" content={poweredByExplainerText} hideOnScroll/>

        return(
            <>

            <div id="footer">


                {this.props.topOfFooter}
                <div style={{textAlign: "center", paddingBottom: 4, paddingTop: 18, color:this.bshzPurple}}>
                    Powered By: &nbsp;<a href='https://whatsonchain.com/about' target="_blank" rel="noopener noreferrer">WhatsOnChain</a>,
                    and <a href='https://bitails.io' target="_blank" rel="noopener noreferrer">Bitails</a> &nbsp;{popupPoweredByExplainer}
                </div>

                <Transition visible={this.props.jiggleFooter} animation='pulse' duration={500}>
                    <div id="flinks" style={{textAlign: "center", paddingBottom: 4}}>


                        {leftArrow}
                        {maybeLeftLink}

                        {termsLink}
                            &nbsp;|&nbsp;

                        {privacyLink}
                            &nbsp;|&nbsp;

                        {securityLink}
                            &nbsp;|&nbsp;

                        {glossaryLink}&nbsp;
                        {maybeRightLink}
                        {rightArrow}

                    </div>
                </Transition>

                <Divider style={{margin: 0}}/>

                <div style={{textAlign: "center"}}><small>&copy; 2022 BitSchnitzel LLC</small></div>


                <Modal size='small' open={this.state.showGenericOkModal && !this.state.showServerErrorModal} dimmer='blurring' style={{backgroundColor: this.bshzPurple, borderRadius: '20px'}}>
                <Modal.Header style={{textAlign: 'center', backgroundColor: this.bshzPurple, borderRadius: '20px'}}>
                    <span style={{color: this.bshzYellow}}>  {genericModalTitle}  </span>
                </Modal.Header>
                <Modal.Content style={{textAlign: 'center', backgroundColor: this.bshzLtPurp, borderRadius: '0px 0px 20px 20px'}}>
                    <span style={{fontSize: "16px"}}>{genericModalContent} </span>

                    <p></p>
                    <p></p>

                    <Button  color='green' onClick={this.handleGenericModalOk}>
                        <span style={{color: 'white'}}>
                        {genericModalButton}
                        </span>
                    </Button>

                </Modal.Content>
                </Modal>

                <Modal size='small' open={this.state.showTerms} dimmer='blurring' style={{backgroundColor: this.bshzPurple, borderRadius: '20px'}}>
                <Modal.Header style={{textAlign: 'center', backgroundColor: this.bshzPurple, borderRadius: '20px'}}>
                    <span style={{color: this.bshzYellow}}>Our Terms of Use</span>
                </Modal.Header>
                <Modal.Content style={{backgroundColor: this.bshzLtPurp, borderRadius: '0px 0px 20px 20px'}}>
                    <p>
                        {this.bitShizzle} does not monitor the nature of the content you publish.&nbsp;
                        <b>You are responsible for your actions</b>.
                        Anything you submit to the Bitcoin blockchain cannot be undone,
                        and could be traced back to you. Having said that, {this.bitShizzle} is
                        not obligated to display any content it deems obscene.
                    </p>
                    <p>
                        <b>You are responsible</b> for abiding by the laws of your country and locality.
                    </p>
                    <p>
                        <b>You are responsible</b> for safeguarding your password, your wallet, your browser, and your device.
                    </p>
                    <p>
                        {this.bitShizzle} is just a tool. It is not designed for breaking, nor circumventing laws. Use it wisely, and responsibly.
                    </p>
                    <p>
                        Any prices mentioned are approximate snapshots from a single exchange, and cannot be relied on.
                    </p>
                    <p>
                        The {this.bitShizzle} wallet is experimental. It may lose your funds, and claimed assets. Do not put more than $3's worth (USD) of Bitcoin in it.
                    </p>
                    <p style={{color: termsColor}}>If there are any terms you do not understand, or which you do not agree with, you may not use the {this.bitShizzle} browser (this site).</p>

                    <div style={{textAlign: 'center'}}>
                        <Checkbox label='I understand, and agree' checked={this.state.understandsTermsBoxChecked} onClick={this.setUnderstandsTermsBox}/>
                                &nbsp; &nbsp; &nbsp;
                        <Button  color={understoodButtonColor} onClick={this.handleTermsOk} disabled={this.props.enforceTerms && !this.state.understandsTermsBoxChecked}>
                            <span style={{color: 'white'}}>
                                {understoodButtonLabel}
                            </span>
                        </Button>
                    </div>

                </Modal.Content>
                </Modal>

                <Modal size='small' open={this.state.showPrivacy} dimmer='blurring' style={{backgroundColor: this.bshzPurple, borderRadius: '20px'}}>
                <Modal.Header style={{textAlign: 'center', backgroundColor: this.bshzPurple, borderRadius: '20px'}}>
                    <span style={{color: this.bshzYellow}}>Our Privacy Policy</span>
                </Modal.Header>
                <Modal.Content style={{backgroundColor: this.bshzLtPurp, borderRadius: '0px 0px 20px 20px'}}>
                    <p>{this.bitShizzle} will never sell nor share your data without your explicit consent.
                    </p>
                    <p>The only data that's sent to our servers are publicly-broadcasted transactions,
                        and anything you might explicitly send our way - like your email address, or comments/suggestions.
                    </p>
                    <p>In your device's browser local storage we save transaction data, and preferences
                        (to aid in displaying postings and sites),
                        and any password-encrypted keys you might direct us to keep.
                    </p>

                    <div style={{textAlign: 'center'}}>
                    <Button  color='green' onClick={this.handlePrivacyOk}>
                        <span style={{color: 'white'}}>
                        Okay
                        </span>
                    </Button>
                    </div>

                </Modal.Content>
                </Modal>

                <Modal size='small' open={this.state.showSecurity} dimmer='blurring'  className={modalClassName} style={{backgroundColor: this.bshzPurple, borderRadius: '20px'}}>
                <Modal.Header style={{textAlign: 'center', backgroundColor: this.bshzPurple, borderRadius: '20px'}}>
                    <span style={{color: this.bshzYellow}}>Security</span>
                </Modal.Header>
                <Modal.Content scrolling style={{backgroundColor: this.bshzLtPurp}}  className={modalContentClassName}>
                    <p>
                    Your {this.bitShizzle} accounts, and any Bitcoin they contain, are only as secure as
                    your device. {this.bitShizzle} recommends storing no more than three dollars worth of
                    Bitcoin in any address or asset. (You can do a <b>lot</b> with $3's worth of Bitcoin.)
                    </p>
                    <p>
                        If the keys to any asset(s) you claim or buy are compromised,
                        you will likely immediately lose control of your asset(s) forever.
                    </p>
                    <p>
                    The {this.bitShizzle} Browser is largely untested. Most {this.shizzleVerse} sites
                    you might visit are neither endorsed nor recommended by {this.bitShizzle}.
                    All but a few were claimed and built by individuals unknown to us. That's what
                    permissionless means. Anyone can claim and build a site - similar to the
                    Internet. And while any site may eventually be traced to an individual (perhaps
                    in a foreign country), and {this.shizzleVerse} sites you visit are sandboxed
                    within your browser, there is no telling where a given link might lead, nor what it might do.
                    </p>
                    <p>
                    <span style={{color: "red"}}>Exercise caution</span> when surfing. For now, consider it the Wild West.
                    </p>

                </Modal.Content>
                <Modal.Actions className={modalBottomClassName} style={{backgroundColor: this.bshzPurple, borderRadius: '0px 0px 20px 20px'}}>
                    <div style={{textAlign: 'center'}}>
                        <Button  color='green' onClick={this.handleSecurityOk}>
                            <span style={{color: 'white'}}>
                                Understood
                            </span>
                        </Button>
                    </div>
                </Modal.Actions>
                </Modal>


                <Modal size='small' open={this.state.showGlossary} dimmer='blurring' className={modalClassName} style={{backgroundColor: this.bshzPurple, borderRadius: '20px'}}>
                <Modal.Header style={{textAlign: 'center', backgroundColor: this.bshzPurple, borderRadius: '20px'}}>
                    <span style={{color: this.bshzYellow}}>Glossary</span>
                </Modal.Header>
                <Modal.Content scrolling style={{backgroundColor: this.bshzLtPurp}} className={modalContentClassName}>
                    <p>
                        There are plenty of new and confusing terms. Please let us know if there are any you think we
                        should add, or expand on.
                    </p>
                    <p>
                        <b>ShizzleVerse</b> - a world wide web of Bitcoin websites, and postings from, and communications
                        between Bitcoin identities
                    </p>
                    <p>
                        <b>BitShizzle</b> - a Bitcoin browser specially-crafted to help you explore the {this.shizzleVerse}
                    </p>
                    <p>
                        <b>Bitcoin browser</b> - a sand-boxed site, which traces and reads {this.shizzleVerse} Bitcoin
                           transactions, and decodes them into digestible sites and postings. <b>This website</b> is the first
                           Bitcoin {this.shizzleVerse} browser.
                    </p>
                    <p>
                        <b>blockchain</b> - the primary data structure used in Bitcoin, it allows timestamping of Bitcoin
                        transactions, and renders them effectively immutable, by making any tampering immediately evident.
                    </p>
                    <p>
                        <b>Bitcoin</b> - a creation of Satoshi Nakamoto, a peer-to-peer digital cash, which can ALSO
                        be used to convey data.
                    </p>
                    <p>
                        <b>micro-payments</b> - a prime feature of Bitcoin, these are payments so small (as small
                        as thousandths of a cent), that no traditional currency could possibly perform. Micro-payments
                        unlock use-cases which were previously not possible.
                    </p>
                    <p>
                        <b>smart contract</b> - a sophisticated Bitcoin feature which allows a transaction author to
                        dictate rules-of-spending
                    </p>
                    <p>
                        <b>bitcoin-native</b> - uses the lowest-level features of Bitcoin
                    </p>
                    <p>
                        <b>Bitcoin identity</b> - a higher-level construct, built using Bitcoin smart contracts, it
                        allows for individual expression, and common concepts of individual rights and ownership. Also, may
                        be referred to as a domain, or name
                    </p>
                    <p>
                        <b>Bitcoin website</b> - similar to a Bitcoin identity, but using a more expressive format (employing
                        internet technologies such as CSS, javascript, and html)
                    </p>
                    <p>
                        <b>permissionless</b> - when a protocol has rules that are set in stone, allows anyone to employ it
                        without requiring any authorization - other than to follow the rules and conditions as they have been set
                    </p>
                    <p>
                        <b>Satoshis</b> - the nick-name for the smallest unit of value in Bitcoin. Often abbreviated as 'sats'.
                        Each Bitcoin is equivalent to one hundred million satoshis. One can think of a satoshi as a US penny,
                        though, its current price is actually much lower.
                    </p>

                </Modal.Content>
                <Modal.Actions className={modalBottomClassName} style={{backgroundColor: this.bshzPurple, borderRadius: '0px 0px 20px 20px'}}>
                    <div style={{textAlign: 'center'}}>
                        <Button  color='green' onClick={this.handleGlossaryOk}>
                            <span style={{color: 'white'}}>
                                Thanks
                            </span>
                        </Button>
                    </div>
                </Modal.Actions>
                </Modal>

                <Modal size='small' open={this.state.showContact && !feedbackSent} dimmer='blurring' style={{backgroundColor: this.bshzPurple, borderRadius: '20px'}}>
                <Modal.Header style={{textAlign: 'center', backgroundColor: this.bshzPurple, borderRadius: '20px'}}>
                    <span style={{color: this.bshzYellow}}>Contact Us</span>
                </Modal.Header>
                <Modal.Content style={{backgroundColor: this.bshzLtPurp, borderRadius: '0px 0px 20px 20px'}}>
                    <p>
                        Found a bug? Don’t see your favorite feature? Is this all too confusing?
                    </p>
                    <p>
                        <b>Please</b> tell us about it.
                    </p>

                    <TextArea maxLength={this.MAX_FEEDBACK_LEN} style={{width: "100%", minHeight: 150}} placeholder={feedbackPlaceholder} onChange={this.handleFeedback}/>

                    {charCount}
                    <br></br><br></br>
                    <div style={{textAlign: 'center'}}>
                    <Button  disabled={!feedbackExists} color='green' onClick={this.handleContactSend}>
                        <span style={{color: 'white'}}>
                        Send
                        </span>
                    </Button>

                    <Button  color='red' onClick={this.handleContactCancel}>
                        <span style={{color: 'white'}}>
                        CANCEL
                        </span>
                    </Button>
                    </div>

                </Modal.Content>
                </Modal>


                <Modal size='large' centered className={modalClassName}  open={this.state.showWhy} style={{backgroundColor: this.bshzPurple, borderRadius: '20px'}}>
                <Modal.Header style={{textAlign: 'center', backgroundColor: this.bshzPurple, borderRadius: '20px'}}>
                    <span style={{color: this.bshzYellow}}> About the {this.shizzleVerse}</span>
                </Modal.Header>
                <Modal.Content className={modalContentClassName} scrolling style={{backgroundColor: "#EAEAEA"}}>

                    <ShizzleWhy />

                </Modal.Content>
                <Modal.Actions className={modalBottomClassName} style={{backgroundColor: this.bshzPurple, borderRadius: '0px 0px 20px 20px'}}>
                    <div style={{textAlign: 'center'}}>
                        <Button positive onClick={this.showWhyModal} content='Okay'/>
                    </div>
                </Modal.Actions>
                </Modal>


                <Modal size='small' open={this.state.showServerErrorModal} dimmer='blurring'>
                <Modal.Header style={{textAlign: 'center', backgroundColor: this.bshzPurple}}>
                    <span style={{color: this.bshzYellow}}>  SERVER ERROR  </span>
                </Modal.Header>
                <Modal.Content style={{textAlign: 'center', backgroundColor: this.bshzLtPurp}}>
                    <p>We're sorry. We had trouble communicating with the {this.bitShizzle} Server.</p>
                    <p> Please try again later.</p>
                    <p></p>
                    <p></p>

                    <Button  color='red' onClick={this.handleServerErrorModalOk}>
                        <span style={{color: 'white'}}>
                          Okay
                        </span>
                    </Button>

                </Modal.Content>
                </Modal>
            </div>
            </>
        )
    }
}

export default Footer;