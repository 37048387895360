import React from 'react';

import './quoteSlider.css';

export default class Slider extends React.PureComponent {
    constructor(props) {
        super(props);

        this.visibleText = "qsTextVisible"
        this.invisibleText = "qsTextInvisible"
        this.fadeIn = "qsFadeIn7"
        this.slideAway = "slideAway7"
        this.count = -1
        this.longInterval = 3500
        this.shortInterval = 1000
        this.finalInterval = 4500
        this.banner = "qsContainer"

        this.alreadyRestarting = false

        this.state = {
            overlay1Text: this.invisibleText,
            overlay2Text: this.invisibleText,
            overlay3Text: this.invisibleText,
            overlay4Text: this.invisibleText,
            overlay5Text: this.invisibleText,
            overlay6Text: this.invisibleText,
            fadeIn7Text:  this.invisibleText,
            slideAway7Text: this.invisibleText,
            props1Text:   '',
            props2Text:   '',
            props3Text:   '',
            props4Text:   '',
            props5Text:   '',
            props6Text:   '',
            props7Text:   '',
            props7bText:  '',
        }

        this.tick     = this.tick.bind(this);
        this.restart  = this.restart.bind(this);

        this.timerId  = null;
    }

    componentDidMount() {
        this.restart()
    }
    componentWillUnmount() {
        console.log("QUOTESLIDER about to unmount. "); //Will UNregister...")

        if ( this.timerId !== null ) {
            console.log("QUOTESLIDER: clearing timer")
            clearTimeout(this.timerId)
        }
        this.setState = (state,callback)=>{
            return;
        };
    }

    restart() {
        this.count = -1
        this.timerId = setTimeout(() => this.tick(), 750);
//console.warn("QUOTE SLIDER: restart()")
    }

    tick() {
        this.timerId = null;

        if ( this.alreadyRestarting ) {
            console.log("  CLEARING the restart...")
            this.alreadyRestarting = false;
        }
        this.count++
//console.warn("QS: tick " + this.count)

        // default each quote to invisible
        let overlay1Text = this.invisibleText
        let overlay2Text = this.invisibleText
        let overlay3Text = this.invisibleText
        let overlay4Text = this.invisibleText
        let overlay5Text = this.invisibleText
        let overlay6Text = this.invisibleText
        let fadeIn7Text  = this.invisibleText
        let slideAway7Text = this.invisibleText

        let props1Text  = ''
        let props2Text  = ''
        let props3Text  = ''
        let props4Text  = ''
        let props5Text  = ''
        let props6Text  = ''
        let props7Text  = ''
        let props7bText = ''

        const banner = document.getElementById(this.banner)
        if ( typeof banner === 'undefined' || banner === null ) {
            console.error("qs tick(): banner: ", banner)
            alert("Hmmm. What happened here?")
            return
        }
        const bannerClassList = banner.classList
        if ( typeof bannerClassList === 'undefined' || bannerClassList === null ) {
            console.error("qs tick(): bannerClassList: ", bannerClassList)
            alert("Whoops. What happened here?")
            return
        }
        switch( this.count % 14 ) {

            case 0:                   // DISPLAY first quote
                //console.log("Case 0")
                bannerClassList.remove("slideAway7")
                bannerClassList.add("slideIn1")

                overlay1Text = this.visibleText
                props1Text = this.props.s1Text

                this.timerId = setTimeout(() => this.tick(), this.longInterval);
                break;

            case 1:                   // REMOVE first quote
                //console.log("Case 1")
                bannerClassList.remove("slideIn1")
                bannerClassList.add("slideAway1")

                overlay1Text = this.visibleText
                props1Text = this.props.s1Text

                this.timerId = setTimeout(() => this.tick(), this.shortInterval);
                break;

            case 2:                   // Display second quote
                //console.log("Case 2")

                bannerClassList.remove("slideAway1")
                bannerClassList.add("slideIn2")

                overlay2Text = this.visibleText
                props2Text = this.props.s2Text

                this.timerId = setTimeout(() => this.tick(), this.longInterval);
                break;

            case 3:                   // REMOVE second quote
                //console.log("Case 3")
                bannerClassList.remove("slideIn2")
                bannerClassList.add("slideAway2")

                overlay2Text = this.visibleText
                props2Text = this.props.s2Text

                this.timerId = setTimeout(() => this.tick(), this.shortInterval);
                break;

            case 4:                   // DISPLAY third quote
                //console.log("Case 4")
                bannerClassList.remove("slideAway2")
                bannerClassList.add("slideIn3")

                overlay3Text = this.visibleText
                props3Text = this.props.s3Text


                this.timerId = setTimeout(() => this.tick(), this.longInterval);
                break;

            case 5:                   // REMOVE third quote
                //console.log("Case 5")
                bannerClassList.remove("slideIn3")
                bannerClassList.add("slideAway3")

                overlay3Text = this.visibleText
                props3Text = this.props.s3Text

                this.timerId = setTimeout(() => this.tick(), this.shortInterval);
                break;

            case 6:                   // DISPLAY fourth quote
                //console.log("Case 6")
                bannerClassList.remove("slideAway3")
                bannerClassList.add("slideIn4")

                overlay4Text = this.visibleText
                props4Text = this.props.s4Text

                this.timerId = setTimeout(() => this.tick(), this.longInterval);
                break;

            case 7:                   // REMOVE fourth quote
                //console.log("Case 7")
                bannerClassList.remove("slideIn4")
                bannerClassList.add("slideAway4")

                overlay4Text = this.visibleText
                props4Text = this.props.s4Text

                this.timerId = setTimeout(() => this.tick(), this.shortInterval);
                break;

            case 8:                   // DISPLAY fifth quote
                //console.log("Case 8")

                bannerClassList.remove("slideAway4")
                bannerClassList.add("slideIn5")

                overlay5Text = this.visibleText
                props5Text = this.props.s5Text

                this.timerId = setTimeout(() => this.tick(), this.longInterval);
                break;

            case 9:                   // REMOVE fifth quote
                //console.log("Case 9")
                bannerClassList.remove("slideIn5")
                bannerClassList.add("slideAway5")

                overlay5Text = this.visibleText
                props5Text = this.props.s5Text

                this.timerId = setTimeout(() => this.tick(), this.shortInterval);
                break;

            case 10:                   // DISPLAY sixth quote
                //console.log("Case 10")

                bannerClassList.remove("slideAway5")
                bannerClassList.add("slideIn6")

                overlay6Text = this.visibleText
                props6Text = this.props.s6Text

                this.timerId = setTimeout(() => this.tick(), this.longInterval);
                break;

            case 11:                   // REMOVE sixth quote
                //console.log("Case 11")
                bannerClassList.remove("slideIn6")
                bannerClassList.add("slideAway6")

                overlay6Text = this.visibleText
                props6Text = this.props.s6Text

                this.timerId = setTimeout(() => this.tick(), this.shortInterval);
                break;

            case 12:                   // DISPLAY seventh quote
                //console.log("Case 12")

                bannerClassList.remove("slideAway6")
                bannerClassList.add("fadeIn7")

                fadeIn7Text = this.fadeIn
                props7Text = this.props.s7Text

                this.timerId = setTimeout(() => this.tick(), this.finalInterval);
                break;

            case 13:                   // REMOVE seventh quote
                //console.log("Case 13")
                bannerClassList.remove("fadeIn7")
                bannerClassList.add("slideAway7")

                slideAway7Text = this.slideAway
                props7bText = this.props.s7Text

                // If it's not a "single-shot", start it all over again
                if ( ! this.props.singleShot ) {
                    this.timerId = setTimeout(() => this.tick(), this.shortInterval);
                }
                break;

            default:
                console.log("Case DEFAULT")
                window.alert("what??")
                break;
        }

        this.setState({
                overlay1Text: overlay1Text,     // these are the css styling  FIXME: rename them
                overlay2Text: overlay2Text,
                overlay3Text: overlay3Text,
                overlay4Text: overlay4Text,
                overlay5Text: overlay5Text,
                overlay6Text: overlay6Text,
                fadeIn7Text:  fadeIn7Text,
                slideAway7Text: slideAway7Text,

                props1Text:   props1Text,       // actual text
                props2Text:   props2Text,
                props3Text:   props3Text,
                props4Text:   props4Text,
                props5Text:   props5Text,
                props6Text:   props6Text,
                props7Text:   props7Text,
                props7bText:  props7bText,
        });
    }
    //Slider 2 Example text. Lorem ipsum dolor sit emet, consecteturr adipisicing elit.

    // IMPORTANT: qsTextInvisible is invisible. we use it for when we're REMOVING
    //            a 'fake' hover overlay effect class
    render() {
        if ( this.props.restart && this.props.singleShot && !this.alreadyRestarting ) {
            this.alreadyRestarting = true
            console.log("QUOTE SLIDER: restarting")
            this.restart()
        }

        // We re-arrange order of overlays, since the bottom/last one rendered seems
        // to have precedence (higher z-index), and can overwrite the lowest/first
        // while the bottom/last is receding
        // IOW: overlay1 switches from top/first-rendered to bottom/last-rendere
        //FIXME: perhaps we could have made qsTextInvisible smaller, and lower (below active text (qsTextVisible))
        let putOverlay1AtTop = true
        if ( this.count % 14 < 2  || this.count % 14 === 13 ) {
            //console.log("overlay1    top")
            putOverlay1AtTop = false
        } else {
            //console.log("overlay1    BOTTOM")
        }

        const topOverlay = putOverlay1AtTop ?
                    <div className="qsOverlay1">
                        <div className={this.state.overlay1Text}>
                            {this.state.props1Text}
                        </div>
                    </div>
                :
                    null
        const bottomOverlay = putOverlay1AtTop ?
                    null
                :
                    <div className="qsOverlay1">
                        <div className={this.state.overlay1Text}>
                        {this.state.props1Text}
                        </div>
                    </div>

        return (
            <>
                <div className="qsContainer" id={this.banner}>
                    <br></br>
                    <br></br>
                    <br></br>
                    <br></br>
                    <br></br>

                    {topOverlay}

                    <div className="qsOverlay2">
                        <div className={this.state.overlay2Text}>
                            {this.state.props2Text}
                        </div>
                    </div>
                    <div className="qsOverlay3">
                        <div className={this.state.overlay3Text}>
                            {this.state.props3Text}
                        </div>
                    </div>
                    <div className="qsOverlay4">
                        <div className={this.state.overlay4Text}>
                            {this.state.props4Text}
                        </div>
                    </div>
                    <div className="qsOverlay5">
                        <div className={this.state.overlay5Text}>
                            {this.state.props5Text}
                        </div>
                    </div>
                    <div className="qsOverlay6">
                        <div className={this.state.overlay6Text}>
                            {this.state.props6Text}
                        </div>
                    </div>
                    <div className="qsFadeIn7">
                        <div className={this.state.fadeIn7Text}>
                            {this.state.props7Text}
                        </div>
                    </div>
                    <div className="qsOverlay7">
                        <div className={this.state.slideAway7Text}>
                            {this.state.props7bText}
                        </div>
                    </div>

                    {bottomOverlay}
                </div>
            </>
        );
    }
}
