import React from 'react';

import { Button, Checkbox, Container, Divider, Dropdown, Form, Grid,
    Input, Menu, Message, Modal, Popup, Radio, TextArea } from 'semantic-ui-react'

import WalletFunder    from './walletFunder';
import WalletVerifier  from './walletVerifier';

import WalletExporter from './walletExporter';
import AssetManager   from './assetManager';

class WalletModal extends React.Component {
  constructor(props) {
    super(props);

    //FIXME: the purples are currently duplicated in index.css
    this.bshzPurple             = '#8313e2';
    this.bshzYellow             = "#fff300";
    this.bshzLtPurp             = '#e7d0fb';
    this.bshzLightYellow        = "#fffccc";

    this.showGenericModal            = this.showGenericModal.bind(this);
    this.handleGenericModalOk        = this.handleGenericModalOk.bind(this);
    this.accessWallet                = this.accessWallet.bind(this);
    this.handleExportClick           = this.handleExportClick.bind(this);
    this.closeExporter               = this.closeExporter.bind(this);
    this.handleAssetsClick           = this.handleAssetsClick.bind(this);
    this.closeAssetsManager          = this.closeAssetsManager.bind(this);

    this.setUserWantsToAddFunds      = this.setUserWantsToAddFunds.bind(this);
    this.toggleShowWalletFunderModal = this.toggleShowWalletFunderModal.bind(this);

    this.walletIsGood                = this.walletIsGood.bind(this);

    this.fullResetPerformed          = this.fullResetPerformed.bind(this);

    this.state = {
      showFunder: true,

      showExportModal: false,

      showAssetsModal: false,

      bounceFunder: false,  // kludge to force quick re-mount of funder - to trigger a re-analysis
    };
  }

  componentDidMount() {
    if ( !this.props.returnBalance ) {
      throw new Error("WalletModal needs a returnBalance property (function)")
    }
  }

//FIXME: rename sendOrReceive()?


  showGenericModal(title, text) {
    this.setState({showGenericOkModal: true, genModalTitle: title, genModalText: text})
  }
  handleGenericModalOk() {
    this.setState({showGenericOkModal: false, genModalTitle: '', genModalText: ''})
  }

  toggleShowWalletFunderModal() {
    if ( this.state.showFunder ) {
        //setUserWantsToAddFunds
        this.setState({showFunder: false, setUserWantsToAddFunds: false})
    } else {
        this.setState({showFunder: true})
    }
  }
  accessWallet() {
    // show wallet funder, but ALSO mention it's from Settings
    // kludge alert
    this.setState({bounceFunder: true},
          () => {this.setState({bounceFunder: false, showFunder: true}) }
    )
  }

  handleExportClick() {
    console.warn("handleExportClick()")
    //
    this.setState({showExportModal: true})
  }

  closeExporter() {
    console.warn("closeExporter()")
    this.setState({showExportModal: false})
  }

  handleAssetsClick() {
    console.warn("handleAssetsClick()")
    //
    this.setState({showAssetsModal: true})
  }
  closeAssetsManager() {
    console.warn("closeAssetManager()")
    this.setState({showAssetsModal: false})
  }

  async fullResetPerformed() {
    await this.props.changeCitizenLevel(0)

    // Full Reset was performed. close this walletModal
    this.props.closeModal()

  }

  //COPIED from onramp, then modified
  // Called-back by our WalletFunder - as props.walletIsSufficient()
  async walletIsGood(isSufficient, closeTheFunderModal = false, parent) {
      console.log("WalletModal: the user's wallet is sufficiently-funded? ", isSufficient)

      if ( closeTheFunderModal ) {
          //await this.props.changeCitizenLevel(3)

          console.log("WalletModal: walletIsGood(): closing Funder modal...")

          parent.toggleShowWalletFunderModal()
      } else {
          console.warn("walletModal: we USED TO set wallet level here to TWO (claiming it was insufficient, because we weren't CLOSING it. WHAT?!)")
          console.warn("WM: Setting the user's citizenLevel to 3 - since his wallet is sufficient")
          console.log("Compare this with onramp logic")
          //await this.props.changeCitizenLevel(2)
          //await this.props.changeCitizenLevel(3)
      }

      if ( this.props.citizenLevel === 2 ) {
        if ( !isSufficient ) {
          alert("WARNING: your wallet appears to be low on funding.\n\n"
              + "You'll need to add funds (not much) to be more than a VISITOR.")
        } else {
          await this.props.changeCitizenLevel(3)
          console.warn("walletModal INCREASED citizelLevel to 3 (from 2).")
        }
      } else {
        if ( !isSufficient ) {
          alert("WARNING: there's a good chance that your wallet is low on funding.\n\n"
              + "Until you add more funds to your wallet, you're likely to have a "
              + "degraded experience, and be not more than a VISITOR.")
        }
        console.log("walletModal NOT changing citizenLevel to 3 (since it wasn't at 2)")
      }
  }

  setUserWantsToAddFunds(value) { // value: true or false (whether to show cancel button)
    console.log("walletModal: setUserWantsToAddFunds(): ", value + " <=====")
    this.setState({showFunderCancelButton: value})
  }

  render() {
  
    //COPIED FROM ONRAMP

    // ***  DEVICE-DEPENDENT STYLING  ***
    // For mobile, "detect" landscape screen mode
    const landscape = this.props.isMobile ? window.innerWidth > window.innerHeight : false
    // For mobile, shrink the About, Security, and Glossary modals
    const modalClassName   = this.props.isMobile ?
                                    (landscape ?
                                            "modalMobileLandscape"
                                        :
                                            "modalMobilePortrait"
                                    )
                                :
                                    ""
    const modalContentClassName = this.props.isMobile ? "modalContentMobile" : ""
    const modalBottomClassName  = this.props.isMobile ? "modalBottomMobile"  : ""



    const walletExportModal = this.state.showExportModal ?
                <WalletExporter closeWalletExportModal={this.closeExporter}
                                isMobile={this.isMobile}
                  />
                : null;
    const assetsManagerModal = this.state.showAssetsModal ?
                <AssetManager closeAssetsModal={this.closeAssetsManager}
                              isMobile={this.isMobile}
                              bundle={this.props.bundle}
                              blockHeight={this.props.blockHeight}
                              bsvPriceUSD={this.props.bsvPriceUSD}
                  />
                : null

    //console.log("Wallet Modal render():  props.showWallet: ", this.props.showWallet)
    const maybeWalletFunder = this.props.mountFunder && !this.state.bounceFunder ?
            <>
                <WalletFunder   parent={this}
                                showFunder={this.state.showFunder}
                                closeFunder={this.toggleShowWalletFunderModal}
                                getOfficialWallet={this.props.getOfficialWalletRecord}

                                walletIsSufficient={this.walletIsGood}
                                setUserWillAddFunds={this.setUserWantsToAddFunds}
                                bundle={this.props.bundle}
                                bsvPriceUSD={this.props.bsvPriceUSD}
                                fromSettings={true}

                                returnBalance={this.props.returnBalance}
                                isMobile={this.props.isMobile}
                                />

            </>
          :
            <></>

    return <>

            <Modal size='small' centered className={modalClassName}  open={this.props.showWallet} style={{backgroundColor: this.bshzPurple, borderRadius: '20px'}}>
              <Modal.Header style={{textAlign: 'center', backgroundColor: this.bshzPurple, borderRadius: '20px'}}>
                <span style={{color: this.bshzYellow}}> Your Browser Wallet</span>
              </Modal.Header>
              <Modal.Content className={modalContentClassName} scrolling style={{backgroundColor: this.bshzLtPurp}}>

                You can manage the funds in your wallet here.
                <br></br>
                <Button positive onClick={this.accessWallet} content='Manage Funds'/>

                <br></br><br></br>

                You can manage your keys, and assets here. They're also a part of your wallet.
                <br></br>
                <Button positive onClick={this.handleAssetsClick} content='Manage Keys and Assets'/>

                <br></br><br></br>

                It's important to back-up your wallet. Not doing so leaves open the posibility
                of losing control of your assets.
                <br></br>
                <Button color="blue" style={{color:"yellow"}} onClick={this.handleExportClick} content='Backup Wallet'/>

                {walletExportModal}
                {assetsManagerModal}

              </Modal.Content>
              <Modal.Actions className={modalBottomClassName} style={{backgroundColor: this.bshzPurple, borderRadius: '0px 0px 20px 20px'}}>
                <div style={{textAlign: 'center'}}>
                    <Button negative onClick={this.props.closeModal} content='Back'/>
                </div>
              </Modal.Actions>
            </Modal>





            {maybeWalletFunder}




            <Modal size='small' centered className={modalClassName}  open={this.props.showVerifier} style={{backgroundColor: this.bshzPurple, borderRadius: '20px'}}>
                <Modal.Header style={{textAlign: 'center', backgroundColor: this.bshzPurple, borderRadius: '20px'}}>
                    <span style={{color: this.bshzYellow}}> Verify Your Wallet Password</span>
                </Modal.Header>
                <Modal.Content className={modalContentClassName} scrolling style={{backgroundColor: this.bshzLtPurp}}>

                    <WalletVerifier userRemembersPwd={this.props.userRemembersPwd}
                                    userDeletedWallet={this.props.userDeletedWallet}
                                    getOfficialWallet={this.props.getOfficialWalletRecord}
                                    showOkModal={this.showGenericModal}
                                    returnBundle={this.props.returnBundle}

                                    finishReset={this.fullResetPerformed}
                                    isMobile={this.props.isMobile}
                                    />

                </Modal.Content>
                <Modal.Actions className={modalBottomClassName} style={{backgroundColor: this.bshzPurple, borderRadius: '0px 0px 20px 20px'}}>
                    <div style={{textAlign: 'center'}}>

                        <Button negative onClick={this.props.closeModal} content='Cancel'/>
                    </div>
                </Modal.Actions>
            </Modal>


            <Modal size='small' centered className={modalClassName}  open={this.state.showGenericOkModal} style={{backgroundColor: this.bshzPurple, borderRadius: '20px'}}>
                <Modal.Header style={{textAlign: 'center', backgroundColor: this.bshzPurple, borderRadius: '20px'}}>
                    <span style={{color: this.bshzYellow}}> {this.state.genModalTitle}</span>
                </Modal.Header>
                <Modal.Content className={modalContentClassName} scrolling style={{backgroundColor: this.bshzLtPurp}}>

                    {this.state.genModalText}

                </Modal.Content>
                <Modal.Actions className={modalBottomClassName} style={{backgroundColor: this.bshzPurple, borderRadius: '0px 0px 20px 20px'}}>
                    <div style={{textAlign: 'center'}}>
                        <Button positive onClick={this.handleGenericModalOk} content='OK' autoFocus/>
                    </div>
                </Modal.Actions>
            </Modal>
          </>
  }
} // WalletModal

export default WalletModal;