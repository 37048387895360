
// shizzleIDB

import { Dexie } from 'dexie';

import { IDB_NAME } from './harnessConstants';

const shizzleDbName = IDB_NAME //"BitShizzle7"
// When redeeming a gift, we can't CREATE the DB without user permission,
// but we will want to know, in advance, if user has been here, and already
// granted permission
/*
export async function checkForDB() {

  const dbName = shizzleDbName //'BitShizzle7';
  const isExisting = (await window.indexedDB.databases()).map(db => db.name).includes(dbName);
  if ( isExisting ) {
    alert("old-school detection: EXISTS")
    return true
  } else {
    alert("old-school detection: does NOT exist")
    return false
  }

  // Always returns true ???

  /*
  if ( await Dexie.exists('BitShizzle7') ) {
    alert("our DB already exists")
    return true
  } else {
    alert("the DB does NOT already exist")
    return false
  }
  * /
}
*/

export async function justOpenShizzleDB() {
  console.log("justOpenShizzleDB(): JUST opening db...");

  // Had trouble with this on firefox
  //const databases = await Dexie.getDatabaseNames();
  //console.log("BTW: Here are the databases we see: " + databases);

  return await Dexie.exists(shizzleDbName).then( function (exists) {
    if ( exists ) {
      console.error("justOpenShizzleDB(): Our IDB EXISTS.")
      return Promise.resolve(true)
    } else {
      console.error("justOpenShizzleDB(): Our IDB DNE.")
      return Promise.resolve(false)
    }
  })

}

export async function openShizzleDB() {
    console.log("openShizzleDB(): opening db");

    // Had trouble with this on firefox.
    //const databases = await Dexie.getDatabaseNames();
    //console.log("BTW: Here are the databases we see: " + databases);

    let db = new Dexie(shizzleDbName);  // BitShizzle7

    db.version(10).stores({
      txtab:               '++tid, txid, address',  // tid is an auto-inc primary key - so host, and guest-post could have
                                                    // unique entries for the same txid
                                                // future: fee,rawTx,domain, status, root?
      txotab:              '++txoid, txid, address, [limb+owner], owner',//NOTE: need the auto-inc primary key to ensure we could have multiple entries (1 for each outIndex)
                                              //(now also limb, address, owner,) outIndex, mode
                                              // we need address indexed - since getNextAddress() does .orderBy('address')
                                              //,outIndex,sats,mode,spentTx  txid?'
                                              //FIXME: consider address, owner, limb. Should they be indexes?
                                              //FIXME: consider a compound index for txoTab: txoid+outIndex
                                              //LATEST: added owner as explicit index - to help us find start-of-branch (owner 0) - in findClosestToLimb()
      spentTxoTab:         'txoKeyId, txIdItFunds',  // helps with following line of transactions
                                              //FIXME: salt, and saltedHash of pwd a good idea?
      rabinTab:            'pkh',             // salt,saltedHashOfPwd,encryptedKeysBlob
                                              //  AND new field: (a)llocated - boolean (NOT indexable)
      builderRabinTab:     'pkh',             // IDENTICAL to rabinTab, but used when BUILDING append nodes/txs
      unconfirmedSpendTab: 'txoKeyId',        //, lastTimeProviderQueried
      rawTxTab:            'txid',            //, rawTx: the raw tx bytes - currently just for bogus Txs
                                              //  AND for appends that we express-claim
      decodedTxTab:        'txid',            // the decoded tx - saved in json

                              // compound index: limb+label (not owner)
                              //FIXME: since compound queries aren't working, maybe remove owner and label from index?
      labeledContentTab:   '++lid, [limb+label]', // shizzleAddress, (min)BlockNum, content. Note we could have many for the same limb, and for the same label
                                        //NOTE: we could have several IF we wanted to keep several (for historical browsing)
      danglingPathsTab:    'address, limb',   //owner, tx, lastChecked  FIXME: maybe index by address (in case we accidentally update it)?
                                          //FIXME: maybe we don't need a danglingID. Maybe we're just adding and deleting (not altering)
      externalTxTab:       'txid',            //, rawTx   - identical to rawTxTab, but for external (non-shizzle) txs
                                              //FIXME: keep track when last accessed (in separate tab?)?
      utxoTab:             '++utxoId, [limb+mode], scriptHash, tx, address',  //NOTE: need the auto-inc primary key (utxoId) to ensure we could have multiple entries (1 for each outIndex)
                                                   //address, scriptHash, owner, (, lastCheck?)
      subscriptionsManagementTabA: '[limb+owner]', // keep track when last we queried/managed UTXOs:
                                                   //   latest ClosestToLimbCall
      subscriptionsManagementTabB: '[limb+owner]', // keep track when last we queried/managed UTXOs:
                                                   //   latest GetDanglingPathsCall
      subscriptionsManagementTabC: '[limb+owner]', // keep track when last we queried/managed UTXOs:
                                                   //   latest GetLatestContinueCall
      p2pkhTab:                    'address',     // Just like rabinTab: salt,saltedHashOfPwd,encryptedKeysBlob
                                                  //  AND new field: (a)llocated - number (0: NOT allocated.  1: Allocated)
      /////////////  newer tables  //////////////
      officialP2pkhTab:            '++p2id, network, address',   // network = 't'est or 'm'ain    'Official' wallet: single key/address

      settingsAndAcksTab:          'name',  // state (true or false), dateSet

      genericKeyTab:               'pkh, asset',   // also: role (brab, krab, qrab, prab, trab, payout, pool)
                                  // Maybe for rabin: pkh
                                  //       for p2pkh: address (so it contains network too?)
                                  // Assumes we can deduce NETWORK from address
      ///////////////////////////////////////////

      publishedVideos:             'txId, created',  // desc, created (timestamp)
                                            // saved for referencing in posts
                                            // ponder saving thumbnails

      //QUESTION: ok to index based on 'hide' (which could change)?
      guestPostTab:                //'[limb+hide], txId',  // also: isValid, couldBuildDialog, lastCheckTime
                                   '++gpId, txId, [limb+hide]',  // also: isValid, couldBuildDialog, lastCheckTime
                                                                // NOTE: MAYBE we NEED a ++gpID because limb+hide isn't unique
                                                                // NOTE: hide must be integer (0, 1) - not boolean

      //selfPostTab:                 'txId, [limb+hide]',
      publishedDataTxTab:          'timestamp',   // {txId}, mimeType, description? name? data? encrypted? referenced?


      dialogTab:                   '++dialogId, [name+ownershipCount], startingTxId', // includes isOwner, and startingTxId. For finding and identifying a dialog
      dialogTxoTab:                '[dialogId+postNum]', // maybe model this after txotab. For presentation, and navigating

      domainsBuiltTab:              'name',  // (built by the USER) payoutPKH, rabinPKH, txid
      domainTab:                   '[name+ownerCount]', // (owned by the USER)  , approxBlockHeight (for rough understanding of expiration)
                                                        // we now include 'outdated' to signify if ownership has changed
                                                        // removed name as an individual index
                                                        // Also include startingTxId?
      subscriptionsTab:            '[name+ownerCount], name', // maybe should include startingTxId, and maybe mostRecentTxId?

      ip4Tab:                     'name'           // txAddress, ip4Mapping    - maps domain/name to an IPv4 address
    });

    db.open()

    return db;

    //EXAMPLE USE:
    /*
    var id  = await db.txtab.put( {txid: '5cfce14ccdb01342c3cb399504a9e31f0527385a0c6a5bdb32392d397f326cd4'});
    var id2 = await db.txotab.put({txoid: '9e58b4182c8b9a529f245c7b9eb821a48dcaffeba84c7e30839b8ccfefef1989', outIndex: 0, mode: 'z'});
    var id3 = await db.spentTxoTab.put({txoKeyId: '912c10c4a3cc01c7d8163317b2e8117dac1e1c58d750b7fbefd74af990480b7d'});
    console.log("shizDBTest: Got ids " + id + ", " + id2 + ", " + id3);
    */


/*
    //var db = new sqlite3.Database(':memory:');
    const db = new sqlite3.Database('/Users/bill/msvscode/NEWNEW/shizzleDb/shiz.db', sqlite3.OPEN_READWRITE, (err) => {
      if (err) {
          console.error(err.message);
      }
      console.log('Connected to the shizzle database.');
    });

    db.serialize(function() {
      //FIXME: consider adding 'WITHOUT ROWID'. Why would we need a rowid?
      db.run("CREATE TABLE IF NOT EXISTS txtab (txid TEXT PRIMARY KEY, rawTx TEXT, fee INT, domain INT, status INT, root TEXT)");
        //domain INT,   // 0: internal     1: external
        //status INT,   // 0: unconfirmed  1: confirmed
        //root TEXT");  // original contract-funding tx from which this was derived

      // Avoid autoincrement. see: https://www.sqlite.org/autoinc.html
      db.run("CREATE TABLE IF NOT EXISTS txotab (txoid INT PRIMARY KEY, txid TEXT NOT NULL, outIndex INT, sats INT, mode TEXT, spentTx TEXT)");

      db.run("CREATE TABLE IF NOT EXISTS spentTxoTab (txoKeyId INT PRIMARY KEY, txIdItFunds TEXT) WITHOUT ROWID");
    });

    // see this: https://stackoverflow.com/questions/56122812/async-await-sqlite-in-javascript
    db.run = util.promisify(db.run);
    db.get = util.promisify(db.get);
    db.all = util.promisify(db.all);

    return db;
*/
}

// erase ALL indexedDBs
export async function resetAll(db) {
  console.warn("RESETING ENTIRE Indexed DB")

  const databases = await Dexie.getDatabaseNames();

  // Delete ALL Indexed DBs
  databases.forEach(function (name) {
    if ( name === shizzleDbName ) {
      const db = new Dexie(name);
      console.warn("Will delete Indexed DB " + name)
      /* */
      db.delete().then(function() {
          console.log('Database successfully deleted: ', name);
          //alert("idb reset all done")
      }).catch(function (err) {
          console.error('Could not delete database: ', name, err);
          alert("IDB reset problem with db '" + name + "'")
      })
    } else {
      console.error("NOT erasing IDB named " + name + " - we don't recognize it")
    }
  });
}

export async function queryAll(db) {

  console.log("All unconfirmedSpendTab entries: ")
  await db.unconfirmedSpendTab.orderBy("txoKeyId").each(result => {
    console.log("    unconfirmedSpendTab entry -> " + JSON.stringify(result));
  });

  const unconfirmedArray = await db.decodedTxTab.orderBy("txid").filter(function (dtx) {
    const odtx = JSON.parse( dtx.decodedTx )
    const numOutputs = odtx.bogusOutputs.length
    //console.log("queryAll(): there are " + numOutputs + " outputs")
    let found = false
    for ( var i = 0; i < numOutputs; i++ ) {
      const isUnconfirmed = odtx.buildable[i] !== i && odtx.confirmedOutputs[i] == false;
      if ( isUnconfirmed ) {
        console.log("  found an unconfirmed output:  #" + i + " (base-0) of tx ", dtx.txid)
        found = true
      }
    }

    return found
  }).toArray();

  console.log("Here are the txs that have an unconfirmed output: ", unconfirmedArray)

  const bogusTxsArray = await db.decodedTxTab.orderBy("txid").filter(function (tx) {
          return tx.txid.startsWith('11111111111111111111111111111111')
        }).toArray();

  console.log("Here are the bogus txs that have been decoded: ", bogusTxsArray)

  //FIXME: should a decodedTx have its own txid in it?
}

export async function queryContentLabels(db) {

  console.log("content label entries: ")
  await db.labeledContentTab.orderBy("limb").each(result => {
    console.log("    limb/label entry -> " + JSON.stringify(result));
  });

}

export async function getDanglingPaths(db, limb) {
  return await db.danglingPathsTab.where("limb").equals(limb).toArray();
}

export async function getAllDanglingPaths(db) {
  return await db.danglingPathsTab.orderBy("address").toArray();  //FIXME: try ordering by limb too
}

export async function queryP2PKHs(db, official = false, network='t') {
  let str = '[';
  let i = 0;
  if ( !official ) {
    console.warn("queryP2PKHs() UN official...")
    await db.p2pkhTab.orderBy("address").each(result => {
      //console.log("    p2pkhTab entry -> " + JSON.stringify(result));
      if ( i !== 0 ) {
        str+= ',';
      }
      i++;
      str += JSON.stringify(result);
    });
  } else {
    console.warn("queryP2PKHs() official...")
    await db.officialP2pkhTab.orderBy("address").each(result => {
      //console.log("    officialP2pkhTab entry -> " + JSON.stringify(result));
      if ( i !== 0 ) {
        str+= ',';
      }
      i++;
      str += JSON.stringify(result);
    });
  }
  str += "]";

  return str;
}

export async function getIP4Mapping(db, name, ownerCount) {
  let results = await db.ip4Tab.where({name: name}).toArray()
  console.log("getIP4Mapping(): got " + results.length + " results looking for name " + name);

    if ( results && results != null && results.length > 0 ) {

      if ( ownerCount == results[0].ownerCount ) {
        return {
                ip4Mapping: results[0].ip4Mapping,
                txAddress: results[0].txAddress
        }
      } else {
        console.warn("getIP4Mapping(): found mapping with wrong owner count: " + results[0].ownerCount)
      }
    } else {
      console.warn("getIP4Mapping(): found no mapping for limb/domain " + name)
    }

    return null
}

export async function maybeUpdateIP4Mapping(db, name, ownerCount, txAddress, ip4Mapping) {
  console.log("maybeUpdateIP4Mapping(): might be adding DB entry/row (to ip4Tab) for tx address " + txAddress)
  if ( typeof ip4Mapping == 'undefined' ) {
    alert("maybeUpdateIP4Mapping(): Does this ever happen (an undefined mapping being passed here)?")
    console.error("maybeUpdateIP4Mapping(): undefined mapping?")
    return false
  }

  // first FIND if one matches domain/name

  let results = await db.ip4Tab.where({name: name}).toArray()
  console.log("maybeUpdateIP4Mapping(): got " + results.length + " results looking for name " + name);

  let replaceEntry = false
  if ( results && results != null && results.length > 0 ) {

    if ( ownerCount > results[0].ownerCount ) {
      // If the old ownerCount is older (smaller), candidate wins
      replaceEntry = true
      console.warn("maybeUpdateIP4Mapping(): candidate ownerCount is newer than current, so, will replace entry")
    } else if ( ownerCount === results[0].ownerCount && txAddress > results[0].txAddress ) {
      // compared address of candidate with current. 'newest' address wins
      console.warn("maybeUpdateIP4Mapping(): comparing old address " + results[0].txAddress
                + " with " + txAddress)
      replaceEntry = true
    }
  } else {
    console.warn("maybeUpdateIP4Mapping(): found no existing entries for domain " + name)
    replaceEntry = true
  }

  if ( replaceEntry ) {
    console.warn("maybeUpdateIP4Mapping(): placing new ip4Tab entry with address " + txAddress
              + " and ip4Mapping ", ip4Mapping)
    await db.ip4Tab.put( {name: name, ownerCount: ownerCount, txAddress: txAddress, ip4Mapping: ip4Mapping});
  }

  return replaceEntry
}

/**
 * Check p2pkhTab for any keys that have a non-zero value for new field 'alloc'.
 * Returns 'numToReturn' of them.
 *
 * NOTE: this will ALSO mark key(s) as allocated
 * @param {*} db
 * @param {*} numToReturn
 * @returns
 */
export async function findAndAllocateAvailableAddress(db, numToReturn = 1) {
  // Find all AVAILABLE p2pkh records that are not ALLOCATED

  //FIXME: could be more straightforward if we just ALSO index by 'alloc'
  let results = await db.p2pkhTab.filter(function (item) {
    return item.alloc === 0
  }).toArray();

  console.warn("findAndAllocateAvailableAddress(): results: ", results)
  const subResults = results.slice(0, numToReturn)
  console.warn("findAndAllocateAvailableAddress(): SUBresults: ", subResults)

  if ( subResults.length < numToReturn ) {
    console.error("findAndAllocateAvailableAddress(): ERROR: couldn't find enough records - only " + subResults.length + " of " + numToReturn)
    return null
  }

  for ( let i = 0; i < numToReturn; i++ ) {
    //console.log("    findAndAllocateAvailableAddress(): marking as allocated: " + subResults[i].address)
    await markEncryptedKeyAddressAllocated(db, subResults[i].address)
  }

  return subResults
}

//FIXME/NOTE: now duplicated by simpler addEncryptedWalletKey
//NOTE: when targeting p2pkhTab, sets new field {alloc: 0}
export async function addEncryptedP2PKHKey(db, address, encryptedBlob,
                          officialWallet = false, network='t',
                          alloc = 0) {
  console.log("addEncryptedP2PKHKey(): adding DB entry/row (to p2pkhTab) for address " + address
            + ". official: " + officialWallet + ", network: " + network + ", alloc: " + alloc)

  if ( !officialWallet ) {
    await db.p2pkhTab.put( {address: address, blob: encryptedBlob, alloc: alloc} );   //NEW: added alloc field
  } else {
    console.log("official wallet")
    if ( network !== 't' && network !== 'm' ) {
      throw new Error("Invalid network for official wallet: ", network)
    }
    console.log("saving official wallet...")
    await db.officialP2pkhTab.put( {address: address, network: network, blob: encryptedBlob} );
    console.log("saved official wallet...")
  }
}

//similar to deleteEncryptedWalletKeys()
export async function clearEncryptedP2PKHKeys(db, officialWallet = false, network='t') {
  console.log("clearEncryptedP2PKHKeys(): DB deleting ALL entries/rows of a p2pkh table. official? "
            + officialWallet + "   Network: " + network)

  let res
  if ( officialWallet ) {
    // official table has network field (though, not sure how useful that really is)
    res = await db.officialP2pkhTab.where( {network: network} ).delete();
  } else {
    res = await db.p2pkhTab.clear();
  }

  console.warn("Number of p2pkh records deleted: ", res)
}

export async function findAllEncryptedP2pkhKeys(db, officialWallet = false) {

  console.log("findAllEncryptedP2pkhKeys()")

  let results
  if ( !officialWallet ) {
    results = await db.p2pkhTab.toArray();
  } else {
    results = await db.officialP2pkhTab.toArray();
  }

  console.warn("findAllEncryptedP2pkhKeys(): results: ", results)

  if ( results && results !== null && results.length > 0 ) {
    return results;
  }

  return [];
}

/**
 * Note that it will alert and error if the key is ALREADY allocated
 * MAYBE it should fail/throw?
 * @param {*} db
 * @param {*} address
 * @param {*} tableVariant
 * @returns
 */
 export async function markEncryptedKeyAddressAllocated(db, address) {
  //console.log("markEncryptedKeyAddressAllocated(): first, FINDING DB entry/row (in rabinTab) for address " + address)

  if( !address ) {
    return null;
  }

  const results = await db.p2pkhTab.where({address: address}).toArray();
  //console.log("  ALL RESULTS, from looking for address to mark as allocated: ", results)

  if ( results && results !== null && results.length > 0 ) {
    // really, there should only be 1
    //FIXME: check for extras, and warn/error?
    const res = results[0];

    //console.error("DELETE THIS: markEncryptedKeyAddressAllocated(): found record for address " + address + ": ", res)
    if ( res.alloc !== 0 ) {
      alert("CODING ERROR: marking key/address allocated which is ALREADY allocated: ", address)
      console.error("CODING ERROR: marking key/address allocated which is ALREADY: ", address)
    }

    //console.log("   markEncryptedKeyAddressAllocated(): finally setting record as ALLOCATED")
    return await db.p2pkhTab.put( {address: address, blob: res.blob, alloc: 1} );
  }

  // nothing found
  return null;
}
// not used - YET
export async function findEncryptedKeyFromAddress(db, address) {
  if( !address ) {
    console.warn("findEncryptedKeyFromAddress(); no address to query for? balking")
    return null;
  }

  console.log("findEncryptedKeyFromAddress() for address of " + address)

  let results = await db.p2pkhTab.where({address: address}).toArray();
  console.log("findEncryptedKeyFromAddress(): BACK FROM query")

  if ( results && results !== null && results.length > 0 ) {
    return results[0];
  }

  return null;
}

export async function countAvailableRabins(db, tableVariant) {
  let count = 0;
  let table
  if ( tableVariant === null ) {
    table = db.rabinTab
  } else {
    table = db.builderRabinTab
  }
  await table.each(result => {
    //console.log("    rabinTab entry -> " + JSON.stringify(result));
    if ( result.alloc === 0 ) {
      count++
    }
  });

  return count;
}
export async function countAvailableP2PKHs(db) {
  let count = 0;
  await db.p2pkhTab.each(result => {
    //console.log("    p2pkh entry -> " + JSON.stringify(result));
    if ( result.alloc === 0 ) {
      count++
    }
  });

  return count;
}

export async function queryRabins(db, tableVariant) {
  let str = '[';
  let i = 0;
  let table
  if ( tableVariant === null ) {
    table = db.rabinTab
  } else {
    table = db.builderRabinTab
  }
  await table.orderBy("pkh").each(result => {
    //console.log("    rabinTab entry -> " + JSON.stringify(result));
    if ( i !== 0 ) {
      str+= ',';
    }
    i++;
    str += JSON.stringify(result);
  });
  str += "]";

  return str;
}
export async function queryOfficialWallet(db) {
  let str = '[';
  let i = 0;

  //FIXME: and the [address+network] ?
  await db.officialP2pkhTab.orderBy("p2id").each(result => {
    console.warn("    officialP2pkhTab entry -> " + JSON.stringify(result));
    if ( i !== 0 ) {
      str+= ',';
    }
    i++;
    str += JSON.stringify(result);
  });
  str += "]";

  return str;
}

//WARN/FIXME: duplicates more-general addEncryptedP2PKHKey() a little
export async function addEncryptedWalletKey(db, address, encryptedBlob, network='t') {
    console.log("addEncryptedWalletKey(): adding DB entry/row (to officialP2pkhTab) for address "
            + address + ", network " + network)

    await db.officialP2pkhTab.put( {address: address, network: network, blob: encryptedBlob} );
}

export async function deleteEncryptedWalletKeys(db, network='t') {
  console.log("deleteEncryptedWalletKeys(): Deleting DB ALL entries/rows of officialP2pkhTab - network " + network)

  const res = await db.officialP2pkhTab.where( {network: network} ).delete();
  console.warn("Number of (official) wallet keys deleted: ", res)
}

export async function clearEncryptedKeys(db, tableVariant) {
  console.log("clearRabinKeys(): clearing all rabin keys")

  if ( tableVariant === null ) {
    await db.rabinTab.clear( );
  } else {
    await db.builderRabinTab.clear( );
  }
}

/**
 * Check rabinTab for any keys that have a non-zero value for new field 'alloc'.
 * Returns 'numToReturn' of them.
 *
 * NOTE: this will ALSO mark key(s) as allocated
 * @param {*} db
 * @param {*} numToReturn
 * @returns
 */
export async function findAndAllocateAvailableRabin(db, numToReturn = 1) {
  // Find all AVAILABLE p2pkh records that are not ALLOCATED

  //FIXME: could be more straightforward if we just ALSO index by 'alloc'
  let results = await db.rabinTab.filter(function (item) {
    return item.alloc === 0
  }).toArray();

  //console.warn("findAndAllocateAvailableRabin(): results: ", results)
  const subResults = results.slice(0, numToReturn)
  //console.warn("findAndAllocateAvailableRabin(): SUBresults: ", subResults)

  if ( subResults.length < numToReturn ) {
    console.error("findAndAllocateAvailableRabin(): ERROR: couldn't find enough records - only " + subResults.length + " of " + numToReturn)
    return null
  }

  for ( let i = 0; i < numToReturn; i++ ) {
    //console.log("    findAndAllocateAvailableRabin(): marking as allocated: " + subResults[i].pkh)
    await markEncryptedKeyRabinAllocated (db, subResults[i].pkh)
  }

  return subResults
}
export async function addEncryptedKeys(db, pkh, encryptedBlob, tableVariant, alloc) {
  console.log("addEncryptedKeys(): adding DB entry/row (to rabinTab) for pkh " + pkh)

  if ( tableVariant === null ) {
    await db.rabinTab.put( {pkh: pkh, blob: encryptedBlob, alloc: alloc} );  //NEW: alloc field
  } else {
    await db.builderRabinTab.put( {pkh: pkh, blob: encryptedBlob} );
  }
}
/**
 * Note that it will alert and error if the key is ALREADY allocated
 * MAYBE it should fail/throw?
 * @param {*} db
 * @param {*} pkh
 * @param {*} tableVariant
 * @returns
 */
export async function markEncryptedKeyRabinAllocated(db, pkh, tableVariant = null) {
  //console.log("markEncryptedKeyRabinAllocated(): first, FINDING DB entry/row (in rabinTab) for pkh " + pkh)

  if( !pkh ) {
    return null;
  }

  //let results
  let table
  if ( tableVariant === null ) {
    table = db.rabinTab
    //results = await db.rabinTab.where({pkh: pkh}).toArray();
  } else {
    table = db.builderRabinTab
    //results = await db.builderRabinTab.where({pkh: pkh}).toArray();
  }

  const results = await table.where({pkh: pkh}).toArray();

  if ( results && results !== null && results.length > 0 ) {
    // really, there should only be 1
    //FIXME: check for extras, and warn/error?
    const res = results[0];

    //console.error("DELETE THIS: markEncryptedKeyRabinAllocated(): found record for pkh " + pkh + ": ", res)
    if ( res.alloc !== 0 ) {
      alert("CODING ERROR: marking key/pkh allocated which is ALREADY allocated: ", pkh)
      console.error("CODING ERROR: marking key/pkh allocated which is ALREADY: ", pkh)
    }

    return await table.put( {pkh: pkh, blob: res.blob, alloc: 1} );
  }

  // nothing found
  return null;
}

export async function findEncryptedKeysFromPKH(db, pkh, tableVariant) {
  if( !pkh ) {
    return null;
  }

  console.log("findEncryptedKeysFromPKH() for pkh of " + pkh)

  let results
  if ( tableVariant === null ) {
    results = await db.rabinTab.where({pkh: pkh}).toArray();
  } else {
    results = await db.builderRabinTab.where({pkh: pkh}).toArray();
  }

  if ( results && results !== null && results.length > 0 ) {
    return results[0];
  }

  return null;
}


//FIXME: awful name
export async function findEncryptedKeysFromAddress(db, address) {
  if( !address ) {
    return null;
  }

  console.log("findEncryptedKeysFromAddress() for address of " + address)

  let results = await db.officialP2pkhTab.where({address: address}).toArray();

  if ( results && results !== null && results.length > 0 ) {
    return results[0];
  }

  return null;
}


// guestPostTab:  '[limb+hide], txId'
export async function addGuestPostEntry(db,
                                        txId,
                                        ourDomainName, /*hide,*/
                                        otherDomain,
                                        weBuiltIt, /*otherDomainName,*/
                                        ranValidation,
                                        isValid,
                                        couldBuildDialog) {
  const checkTime = Math.floor(Date.now() / 1000)

  console.log("addGuestPostEntry(): txId: ", txId)
  console.log("addGuestPostEntry(): limb: ", ourDomainName)
  console.log("addGuestPostEntry(): weBuiltIt: ", weBuiltIt)
  console.log("addGuestPostEntry(): couldBuildDialog: ", couldBuildDialog)

  await db.guestPostTab.put( {txId: txId,                               // index
                              limb: ourDomainName,                      // index with 'hide'
                              hide: 0,                               // index with 'limb'
                                  weBuiltIt: weBuiltIt,
                                  //otherDomain: otherDomainName,
                                  isValid: isValid,
                                  couldBuildDialog: couldBuildDialog,
                                  otherLimb: otherDomain,
                                  ranValidation: ranValidation,
                              markedAsRead: false,
                              lastChecked: checkTime} );
}

export async function findGuestPostEntry(db, limb, showHidden) {

  console.log("findGuestPostEntry() with limb of " + limb + ", and showHidden " + showHidden)

  let results
  if ( showHidden ) {
    results = await db.guestPostTab.where({limb: limb}).toArray();
  } else {
    results = await db.guestPostTab.where({limb: limb, hide: 0}).toArray();
  }

  if ( results && results != null && results.length > 0 ) {
    console.log("findGuestPostEntry(): found " + results.length + " results");
    return results;
  }

  return [];
}
export async function findGuestPostEntryByTxId(db, txId) {

  console.log("findGuestPostEntryByTxId() with txId of " + txId)

  let results = await db.guestPostTab.where({txId: txId}).toArray();

  if ( results && results !== null && results.length > 0 ) {
    console.log("findGuestPostEntryByTxId(): returning ", results[0])
    return results[0];
  } else {
    console.log("findGuestPostEntryByTxId: hmm. well, returning null? here's results, BTW: ", results)
  }

  return null;
}

export async function editGuestPostEntry(db, txId,
                                         showHidden, isValid, couldBuildDialog, markAsRead, updateCheckTime) {

  console.warn("editGuestPostEntry() looking for entry with txId " + txId)

  let results = await db.guestPostTab.where({txId: txId}).toArray();

  if ( results && results != null && results.length > 0 ) {
    console.log("editGuestPostEntry(): btw: entry is ", results[0], ", but changing some fields ");

    let checkTime = results[0].lastChecked
    if ( updateCheckTime ) {
      checkTime = Math.floor(Date.now() / 1000)
    }
    await db.guestPostTab.put({
                                txId: txId,                           // index
                                limb: results[0].limb,                // index with 'hide'
                                hide: showHidden ? 0 : 1,                     // index with 'limb'
                                  weBuiltIt: results[0].weBuiltIt,
                                  //otherDomain: xxx,
                                  isValid: isValid,
                                  markedAsRead: markAsRead,
                                  couldBuildDialog: couldBuildDialog,
                                lastChecked: checkTime,
                              })
  } else {
    console.warn("editGuestPostEntry() found no entry with txId " + txId)
  }

}
export async function editGuestPostHideFlag(db, txId, hide) {
  console.warn("editGuestPostHideFlag() looking for entry with txId " + txId)

  let results = await db.guestPostTab.where({txId: txId}).toArray();
  if ( results && results != null && results.length > 0 ) {
    await db.guestPostTab.put({
                                txId: txId,                           // index
                                limb:               results[0].limb,  // index with 'hide'
                                hide: hide ? 1 : 0,                           // index with 'limb'
                                  weBuiltIt:        results[0].weBuiltIt,
                                  //otherDomain: xxx,
                                  isValid:          results[0].isValid,
                                  markedAsRead:     results[0].markAsRead,
                                  couldBuildDialog: results[0].couldBuildDialog,
                                lastChecked:        results[0].lastChecked,
                              })
  } else {
    console.warn("editGuestPostHideFlag() found no entry with txId " + txId)
  }
}
export async function editGuestPostReadFlag(db, txId, alreadyReadThis) {
  console.warn("editGuestPostReadFlag() looking for entry with txId " + txId)

  let results = await db.guestPostTab.where({txId: txId}).toArray();
  if ( results && results != null && results.length > 0 ) {
    await db.guestPostTab.put({
                                txId: txId,                           // index
                                limb:               results[0].limb,  // index with 'hide'
                                hide:               results[0].hide,  // index with 'limb'
                                  weBuiltIt:        results[0].weBuiltIt,
                                  //otherDomain: xxx,
                                  isValid:          results[0].isValid,
                                  markedAsRead:     alreadyReadThis,
                                  couldBuildDialog: results[0].couldBuildDialog,
                                lastChecked:        results[0].lastChecked,
                              })
  } else {
    console.warn("editGuestPostReadFlag() found no entry with txId " + txId)
  }
}
export async function editGuestPostValidFlag(db, txId, isValid, updateCheckTime) {
  console.warn("editGuestPostValidFlag() looking for entry with txId " + txId)

  let results = await db.guestPostTab.where({txId: txId}).toArray();
  if ( results && results != null && results.length > 0 ) {
    let checkTime = results[0].lastChecked
    if ( updateCheckTime ) {
      checkTime = Math.floor(Date.now() / 1000)
    }

    await db.guestPostTab.put({
                                txId: txId,                           // index
                                limb:               results[0].limb,  // index with 'hide'
                                hide:               results[0].hide,  // index with 'limb'
                                  weBuiltIt:        results[0].weBuiltIt,
                                  //otherDomain: xxx,
                                  isValid:          isValid,
                                  markedAsRead:     results[0].markAsRead,
                                  couldBuildDialog: results[0].couldBuildDialog,
                                lastChecked:        checkTime,
                              })
  } else {
    console.warn("editGuestPostValidFlag() found no entry with txId " + txId)
  }
}
export async function editGuestPostCouldBuildDialogFlag(db, txId, couldBuildDialog) {
  console.warn("editGuestPostCouldBuildDialogFlag() looking for entry with txId " + txId)

  let results = await db.guestPostTab.where({txId: txId}).toArray();
  if ( results && results != null && results.length > 0 ) {
    await db.guestPostTab.put({
                                txId: txId,                           // index
                                limb:               results[0].limb,  // index with 'hide'
                                hide:               results[0].hide,  // index with 'limb'
                                  weBuiltIt:        results[0].weBuiltIt,
                                  //otherDomain: xxx,
                                  isValid:          results[0].isValid,
                                  markedAsRead:     results[0].markAsRead,
                                  couldBuildDialog: couldBuildDialog,
                                lastChecked:        results[0].lastChecked,
                              })
  } else {
    console.warn("editGuestPostCouldBuildDialogFlag() found no entry with txId " + txId)
  }
}
export async function editGuestPostLastCheckedTime(db, txId, lastCheckedTime) {
  console.warn("editGuestPostLastCheckedTime() looking for txId " + txId)

  let results = await db.guestPostTab.where({txId: txId}).toArray();
  if ( results && results != null && results.length > 0 ) {
    await db.guestPostTab.put({
                                txId: txId,                           // index
                                limb:               results[0].limb,  // index with 'hide'
                                hide:               results[0].hide,  // index with 'limb'
                                  weBuiltIt:        results[0].weBuiltIt,
                                  //otherDomain: xxx,
                                  isValid:          results[0].isValid,
                                  markedAsRead:     results[0].markAsRead,
                                  couldBuildDialog: results[0].couldBuildDialog,
                                lastChecked:        lastCheckedTime,
                              })
  } else {
    console.warn("editGuestPostLastCheckedTime() found no entry with txId " + txId)
  }
}
export async function removeGuestPostEntry(db, txId) {
  console.warn("removeGuestPostEntry() looking to delete entry with txId " + txId)
  await db.guestPostTab.where({txId: txId})?.delete();
}


export async function addSetting(db, name, value) {
  const lastSet = Math.floor(Date.now() / 1000)
  console.log("addSetting(): adding DB entry/row (to settingsAndAcksTab) for setting named "
          + name + " - with value: " + value)

  // REPLACE if already exists
  await db.settingsAndAcksTab.put( {name: name, value: value, dateSet: lastSet} );
}

export async function removeSetting(db, name) {

  console.log("removeSetting(): removing DB entry/row (of settingsAndAcksTab) for setting named " + name)

  await db.settingsAndAcksTab.where({name: name}).delete();
}

/**
 * Returns NULL if no such setting yet
 *
 * @param {*} db
 * @param {*} name
 * @returns
 */
export async function findSetting(db, name) {
  if( !name ) {
    return null;
  }

  console.log("findSetting() with name of '" + name + "'")
  console.log("findSetting() name has length '" + name.length + "'")
  console.log("typeof name: ", typeof name)

  let results = await db.settingsAndAcksTab.where({name: name}).toArray();

  if ( results && results !== null && results.length > 0 ) {
    console.log("findSetting(): asked for " + name + ", returning ", results[0])
    return results[0];
  } else {
    console.log("findSetting:  asked for " + name + ", hmm. well, returning null? here's results, BTW: ", results)
  }

  return null;
}

export async function getAllSettings(db) {

  console.log("getAllSettings(): ")

  let results = await db.settingsAndAcksTab.toArray();
  console.log("getAllSettings(): got " + results.length + " results");

  if ( results && results != null && results.length > 0 ) {
    return results;
  }

  return [];
}

export async function addVideo(db, txId, description) {
  const lastSet = Math.floor(Date.now() / 1000)
  console.log("addVideo(): adding DB entry/row (to publishedVideos) with txId " + txId)

  await db.publishedVideos.put( {txId: txId, desc: description, created: lastSet} );
}
export async function removeVideo(db, txId) {
  console.log("removeVideo(): removing DB entry/row (of publishedVideos) for txId " + txId)

  return await db.publishedVideos.where({txId: txId}).delete();
}
export async function getVideoRecord(db, txId) {

  console.log("getVideoRecord() with txId of " + txId)

  let results = await db.publishedVideos.where({txId: txId}).toArray();

  if ( results && results !== null && results.length > 0 ) {
    console.log("getVideoRecord(): returning ", results[0])
    return results[0];
  } else {
    console.log("getVideoRecord: hmm. well, returning null. here's results, BTW: ", results)
  }

  return null;
}
export async function getAllVideoTxIds(db) {
  const all = await db.publishedVideos.orderBy('created').toArray()

  return all
}

////////////
export async function addBuiltDomain(db, name, payoutPKH, builderRabinPKH, txid) {
  //const now = Math.floor(Date.now() / 1000)
  console.log("addBuiltDomain(): adding DB entry/row (to domainsBuiltTab) for asset named " + name)

  return await db.domainsBuiltTab.put( {name: name, payout: payoutPKH, rabin: builderRabinPKH, txid: txid}); //, builtOn: now} );
}

export async function findBuiltDomain(db, name) {
  if( !name ) {
    return null;
  }

  console.log("findBuiltDomain() with name of " + name)

  let results = await db.domainsBuiltTab.where({name: name}).toArray();

  if ( results && results !== null && results.length > 0 ) {
    return results[0];
  }

  return null;
}
// All domains BUILT by ME
export async function getAllMyBuiltDomains(db) {

  console.log("getAllMyBuiltDomains() retrieving ALL domainsBuiltTab records...")


  let results = await db.domainsBuiltTab.orderBy('name').toArray();

  console.warn("getAllMyBuiltDomains(): results: ", results)

  if ( results && results !== null && results.length > 0 ) {
    return results;
  }

  return [];
}
export async function clearBuiltDomains(db) {
  console.log("clearBuiltDomains(): clearing all BUILT domains (domainsBuiltTab): name, payoutPKH, rabin")

  await db.domainsBuiltTab.clear( );
}


// ------------------------------------------------ //

export async function updateLatestClosestToLimbCall(db, limb, owner) {
  const lastCheck = Math.floor(Date.now() / 1000)
  console.log("updateMostRecentClosestToLimbCall(): Adding/updating entry for limb " + limb + ", owner " + owner)

  await db.subscriptionsManagementTabA.put( {limb: limb, owner: owner, lastCheck: lastCheck});
}

export async function updateLatestGetDanglingPathsCall(db, limb, owner) {
  const lastCheck = Math.floor(Date.now() / 1000)
  console.log("updateMostRecentGetDanglingPathsCall(): Adding/updating entry for limb " + limb + ", owner " + owner)

  await db.subscriptionsManagementTabB.put( {limb: limb, owner: owner, lastCheck: lastCheck});
}

export async function updateLatestGetLatestContinueCall(db, limb, owner) {
  const lastCheck = Math.floor(Date.now() / 1000)
  console.log("updateLatestGetLatestContinueCall(): Adding/updating entry for limb " + limb + ", owner " + owner)

  await db.subscriptionsManagementTabC.put( {limb: limb, owner: owner, lastCheck: lastCheck});
}

// ------------------------------------------------ //

export async function getLatestClosestToLimbCall(db, limb, owner) {
  console.log("getLatestClosestToLimbCall() for limb " + limb + ", owner " + owner)

  let results
  if ( owner !== 0 ) {
    results = await db.subscriptionsManagementTabA.where({limb: limb, owner: owner})
  } else {
    results = await db.subscriptionsManagementTabA.where({limb: limb})
//FIXME: chose the one with the latest owner, or the latest time
  }

  console.warn("getLatestClosestToLimbCall(): results: ", results)

  return results
}

export async function getLatestGetDanglingPathsCall(db, limb, owner) {
  console.log("getLatestGetDanglingPathsCall() for limb " + limb + ", owner " + owner)

  let results
  if ( owner !== 0 ) {
    results = await db.subscriptionsManagementTabB.where({limb: limb, owner: owner})
  } else {
    results = await db.subscriptionsManagementTabB.where({limb: limb})
    //FIXME: chose the one with the latest owner, or the latest time
  }

  console.warn("getLatestGetDanglingPathsCall(): results: ", results)

  return results
}

export async function getLatestGetLatestContinueCall(db, limb, owner) {
  console.log("getLatestGetLatestContinueCall() for limb " + limb + ", owner " + owner)

  let results
  if ( owner !== 0 ) {
    results = await db.subscriptionsManagementTabC.where({limb: limb, owner: owner})
  } else {
    results = await db.subscriptionsManagementTabC.where({limb: limb})
    //FIXME: chose the one with the latest owner, or the latest time
  }

  console.warn("getLatestGetLatestContinueCall(): results: ", results)

  return results
}

// ------------------------------------------------ //

export async function addInternalTx(db, txidToInsert, rootTx, address) {
    console.log("addInternalTx(): Adding DB entry/row (to txtab) for txid " + txidToInsert + "\n")

    //FIXME: use .add()?
    await db.txtab.put( {txid: txidToInsert, address: address});

/*
    const rawtx = null  // for now
    const fee = 11      // bogus. needed?
    const external = 0  // internal
    const confirmed = 0 // unconfirmed
    const dbString  = "INSERT INTO txtab VALUES ('"  + txid + "',"
                    + "NULL,"
                    + fee + ","
                    + external + ","
                    + confirmed + ","
                    + "'" +rootTx + "')"
    //console.log("\tDBString: " + dbString)
    await db.run(dbString);
*/
}

export async function registerSubscription(db, name, ownerCount) {  //, startingTxId) {
  console.log("registerSubscription(): Adding DB entry/row (to subscriptionsTab) for name " + name
            + ", ownerCount " + ownerCount + "\n")

  //FIXME: use .add()?
  //FIXME: .put() safer? or, should we fail-fast?
  await db.subscriptionsTab.put({name: name,
                                 ownerCount: ownerCount})
                          //startingTxId: startingTxId,
                          //mostRecentTxId: mostRecentTxId,
                          //outDated: outDated})
}

export async function unRegisterSubscription(db, name) {
  console.log("unRegisterSubscription(): Removing DB entry/row (from subscriptionsTab) for name " + name + "\n")

  await db.subscriptionsTab.where({name: name}).delete()
}

export async function findAllSubscriptions(db) {

  console.log("findAllSubscriptions() ")
            //+ "  and ownerCount " + ownerCount)

  let results = await db.subscriptionsTab.toArray();
  console.log("findAllSubscriptions(): got " + results.length + " results");

  if ( results && results != null && results.length > 0 ) {
    return results;
  }

  return [];
}

export async function findASubscription(db, name, ownerCount) {

  console.log("findASubscription() name " + name
            + ", and ownerCount " + ownerCount)

  let results = await db.subscriptionsTab.where({name: name, ownerCount: ownerCount}).toArray();
  console.log("findASubscription(): got " + results.length + " results");

  if ( results && results != null && results.length > 0 ) {
    //console.log("findASubscription(): btw: startingTxId is ", results[0].startingTxId);

    if ( results.length > 1 ) {
      throw new Error("52531: found too many subscriptions")
    }

    //FIXME: why an array? if so, return [] further below
    return results;
  }

  return null;
}

export async function registerDomainOwnership(db, name, ownerCount,
                                              approxBlockHeightWhenRegistered,
                                              outdated = false,
                                              forAFriend = false,
                                              fromFriend = null) {  //, startingTxId ) {
  console.log("registerDomainOwnership(): Adding DB entry/row (to domainTab) for name " + name
            + ", ownerCount " + ownerCount + "\n")

  if ( forAFriend && fromFriend ) {
    throw new Error("CODE ERROR: a domain can't be FOR and FROM a friend")
  }

  //FIXME: use .add()?
  //FIXME: .put() safer? or, should we fail-fast?
  await db.domainTab.put({name: name,
                          ownerCount: ownerCount,
                          approxBlockHeight: approxBlockHeightWhenRegistered,
                          forFriend: forAFriend,
                          fromFriend: fromFriend,  //null or name
                          outdated: outdated
                        })
                          //startingTxId: startingTxId,

  //FIXME: quicker to search by txid (startingTxId)?
  return await findDomain(db, name, ownerCount)
}

export async function unRegisterDomain(db, name) {  //, startingTxId, outDated = false) {
  console.log("unRegisterDomain(): REMOVING DB entry/row (from domainTab) for name " + name + "\n")

  await db.domainTab.where({name: name}).delete()
}

export async function findDomain(db, name, ownerCount) {

  console.log("findDomain() need domainTab record with name of " + name
            + "  and ownerCount " + ownerCount)

  let results = await db.domainTab.where({name: name, ownerCount: ownerCount}).toArray()
  console.log("findDomain(): got " + results.length + " results");

  if ( results && results != null && results.length > 0 ) {
    //console.log("findDomain(): btw: startingTxId is ", results[0].startingTxId);

    if ( results.length > 1 ) {
      throw new Error("52532: found too many domains")
    }

    //FIXME: why an array? if so, return [] further below
    return results;
  }

  return null;
}

// All domains registered owned by ME
export async function getAllDomains(db) {

  console.log("getAllDomains() retrieving ALL domainTab records...")

  let results = await db.domainTab.toCollection().sortBy('name')
  // when we had 'name' ALSO as an index, we used this:
  // let results = await db.domainTab.orderBy('name').toArray();

  console.warn("getAllDomains(): results: ", results)

  if ( results && results !== null && results.length > 0 ) {
    return results;
  }

  return [];
}
export async function clearAllDomains(db) {
  console.log("clearAllDomains(): clearing all CLAIMED domains - name, ownerCount")

  await db.domainTab.clear( );
}

//FIXME: do we even need to FIND the old record? meh
export async function markDomainOutdated(db, name, ownerCount) {

  console.log("markDomainOutdated() need domainTab record with name of " + name
            + "  and ownerCount " + ownerCount)

  let results = await db.domainTab.where({name: name, ownerCount: ownerCount}).toArray()
  console.log("findDomain(): got " + results.length + " results");

  if ( results && results != null && results.length > 0 ) {
    //console.log("findDomain(): btw: startingTxId is ", results[0].startingTxId);

    if ( results.length > 1 ) {
      throw new Error("52732: found too many domains")
    }

    console.log("results before OUTDATING: ", results[0])

    results = await db.domainTab.put({name: name,
      ownerCount: ownerCount,
      approxBlockHeight: results[0].approxBlockHeight,
      forFriend: results[0].forFriend,
      outdated: true
    })

    return results;
  }

  return null;
}

export async function createDialog(db, ownerName, ownerCount, visitorName, visitorCount, startingTxId,
                                  furthestTxId,
                                  furthestScriptHash,
                                  ownerPubKey = null,
                                  visitorPubKey = null) {
  console.log("createDialog(): Adding DB entry/row (to dialogTab) for name " + ownerName
            + ", ownershipCount " + ownerCount
            + ", AND visitor name " + visitorName
            + ", visitor ownershipCount " + visitorCount
            + ", both with startingTxId " + startingTxId
            + ", and furthestTxId " + furthestTxId + "\n")

  if ( typeof ownerPubKey == 'undefined' ) {
    throw new Error("owner pubkey should be defined")
  }
  if ( typeof visitorPubKey == 'undefined' ) {
    throw new Error("visitor pubkey should be defined")
  }
  if ( typeof furthestTxId == 'undefined' ) {
    throw new Error("furthestTxId must be defined")
  }
  if ( typeof furthestScriptHash == 'undefined' ) {
    throw new Error("furthestScriptHash must be defined")
  }

  if ( ( ownerPubKey === null && visitorPubKey !== null )
      ||
       ( ownerPubKey !== null && visitorPubKey === null )
     ) {
    alert("CODING ERROR: when creating a dialog, we need BOTH visitor AND owner to have keys that are either null, or not null (but defined)")
    throw new Error("Coding error: inconsistent pubKeys while trying to create a Dialog")
  }

  if ( furthestTxId === null ) {
    alert("CODING ERROR: when creating a dialog, we need a non-null furthestTxId")
    throw new Error("Coding error: null furthestTxId")
  }
  if ( furthestScriptHash === null ) {
    alert("CODING ERROR: when creating a dialog, we need a non-null furthestScriptHash")
    throw new Error("Coding error: null furthestScriptHash")
  }

  //FIXME: use .add()?
  //FIXME: .put() safer? or, should we fail-fast?
  await db.dialogTab.put({name: ownerName,
                          ownershipCount: ownerCount,
                          startingTxId: startingTxId,
                          furthestTxId: furthestTxId,
                          otherGuy: visitorName,
                          isOwner: true,
                          furthestPostNum: 0,
                          furthestScriptHash: furthestScriptHash,

                          ownerPubKey: ownerPubKey,
                          visitorPubKey: visitorPubKey
                        })
  await db.dialogTab.put({name: visitorName,
                          ownershipCount: visitorCount,
                          startingTxId: startingTxId,
                          furthestTxId: furthestTxId,
                          otherGuy: ownerName,
                          isOwner: false,
                          furthestPostNum: 0,
                          furthestScriptHash: furthestScriptHash,

                          ownerPubKey: ownerPubKey,
                          visitorPubKey: visitorPubKey
                        })

  //FIXME: quicker to search by txid?
  const twoDialogs = await findDialogByStartingTxId(db, startingTxId)
  if ( twoDialogs.length !== 2 ) {
    throw new Error("44056: expected two dialogs: ", twoDialogs)
  }
  console.log("createDialog(): found twoDialogs: ", twoDialogs)
  const dialogA = twoDialogs[0]
  const dialogB = twoDialogs[1]

  if ( dialogA.name === ownerName ) {
    const result =  { ownerDialogId: dialogA.dialogId,
                      visitorDialogId: dialogB.dialogId
                    }
    console.log("createDialog(): The first result is the owner, so, returning this: ", result)
    return result
  } else if ( dialogB.name === ownerName ) {
    const result =  { ownerDialogId: dialogB.dialogId,
                      visitorDialogId: dialogA.dialogId
                    }
    console.log("createDialog(): The second result is the owner, so, returning this: ", result)
    return result
  } else {
    console.error("We don't understand the dialog id results: ", twoDialogs)
    throw new Error("44057: CODING ERROR: bad dialog id results")
  }
}

export async function clearAllDialogs(db) {
  console.log("clearAllDialogs(): DB deleting ALL entries in dialogTab, and dialogTxoTab")


  const res = await db.dialogTab.clear();
  console.warn("Results of dialogTab deletion: ", res)

  const res2 = await db.dialogTxoTab.clear();
  console.warn("Results of dialogTxoTab deletion: ", res2)
}

/**
 * Updates TWO dialog fields:
 *     furthestPostNum
 *     furthestTxId
 */
export async function modADialogFurthest(db, dialogId, newFurthestPostNum, newFurthestTxId, newFurthestScriptHash) {

  let results = await db.dialogTab.where({dialogId: dialogId}).toArray();

  console.log("modADialogFurthest(): got " + results.length + " results");

  if ( results && results != null && results.length > 0 ) {
    console.log("modADialogFurthest(): btw: furthestPostNum is ", results[0].furthestPostNum, ", but changing to " + newFurthestPostNum);

    //FIXME: check for length > 1? throw?
    //       NO: it's the primary key, so, can't happen

    await db.dialogTab.put({dialogId:         results[0].dialogId,
                            name:             results[0].name,
                            ownershipCount:   results[0].ownershipCount,
                            startingTxId:     results[0].startingTxId,
                            furthestTxId:     newFurthestTxId,         // <--
                            otherGuy:         results[0].otherGuy,
                            isOwner:          results[0].isOwner,
                            furthestPostNum:  newFurthestPostNum,      // <--
                            furthestScriptHash: newFurthestScriptHash, // <--

                            ownerPubKey:      results[0].ownerPubKey,
                            visitorPubKey:    results[0].visitorPubKey
                          })
    return true;
  }

  return false
}

/**
 * Returns all dialogs it can find where the Owner or Visitor has a certain name,
 * AND the ownership count of that Owner or Visitor has a certain value.
 * @param {*} db
 * @param {*} ownerOrVisitorName
 * @param {*} ownerOrVisitorCount
 * @returns
 */
export async function findDialog(db, ownerOrVisitorName, ownerOrVisitorCount) {

  console.log("findDialog() need dialogTab record with owner/visitor of " + ownerOrVisitorName
            + "  with count " + ownerOrVisitorCount)

  let results = await db.dialogTab.where({name: ownerOrVisitorName, ownershipCount: ownerOrVisitorCount}).toArray();
  console.log("findDialog(): got " + results.length + " results");

  if ( results && results != null && results.length > 0 ) {
    console.log("findDialog(): btw: startingTxId is ", results[0].startingTxId);

    // Two assets could start countless dialogs together
    //if ( results.length > 1 ) {
    //  throw new Error("52533: found too many dialogs")
    //}

    //FIXME: why an array? if so, return [] further below
    return results;
  }

  return []
}

export async function findDialogById(db, dialogId) {

  console.log("findDialogById() need dialogTab record with dialogId of " + dialogId)

  let results = await db.dialogTab.where({dialogId: dialogId}).toArray();
  console.log("findDialogById(): got " + results.length + " results");

  if ( results && results != null && results.length > 0 ) {
//FIXME: check for length > 1? throw?
    console.log("findDialogById(): btw: startingTxId is ", results[0].startingTxId);
    return results[0];
  }

  return null;
}

/**
 * Expect to find TWO results
 *
 * @param {*} db
 * @param {*} startingTxId
 * @returns
 */
export async function findDialogByStartingTxId(db, startingTxId) {

  console.log("findDialogByStartingTxId() need dialogTab record with startingTxId of " + startingTxId)

  let results = await db.dialogTab.where({startingTxId: startingTxId}).toArray();
  console.log("findDialogByStartingTxId(): got " + results.length + " results: ", results);

  if ( results && results != null && results.length > 0 ) {

    //NOTE that two dialogIds can/will currently exist for the same startingTxId
    //     (for each member)

    console.log("findDialogByStartingTxId(): btw: startingTxId is ", results[0].startingTxId);

    if ( results.length > 2 ) {
      throw new Error("52534: found too many dialogs: " + results.length + " for staringTxId of " + startingTxId)
    }

    //FIXME: why an array? if so, return [] further below
    return results;
  }

  return null;
}

export async function findAllDialogs(db, ownerOrVisitorName, ownerOrVisitorCount) {

  console.log("findAllDialogs() need ALL dialogTab records with owner/visitor of " + ownerOrVisitorName
            + "  with ownership count " + ownerOrVisitorCount)

  let results = await db.dialogTab.where({name: ownerOrVisitorName, ownershipCount: ownerOrVisitorCount}).toArray();
  console.log("findAllDialogs(): got " + results.length + " results");

  if ( results && results != null && results.length > 0 ) {
    console.log("findAllDialogs(): btw: startingTxId is ", results[0].startingTxId);
    return results;
  }

  return [];
}

//FIXME: probably should add postNum, and maybe startingTxId, and plenty more
//       We're trying to make it easy to traverse a given Dialog - back and forth
export async function addDialogTxo(db, dialogId, postNum, txid) {
  console.warn("addDialogTxo(): Adding DB entry/row (to dialogTxoTab) for dialogId " + dialogId
            + ", postNum " + postNum
            + ", txid " + txid + "\n")

  //FIXME: use .add()?
  //FIXME: .put() safer? or, should we fail-fast?
  await db.dialogTxoTab.put({dialogId: dialogId, postNum: postNum, txid: txid});
}

export async function addDialogTxos(db, dialogIdA, dialogIdB, postNum, txid) {
  console.warn("addDialogTxos(): Adding DB entry/row (to dialogTxoTab) for dialogId " + dialogIdA
            + ", and dialogId " + dialogIdB
            + ", for postNum " + postNum
            + ", txid " + txid + "\n")

  await db.dialogTxoTab.put({dialogId: dialogIdA, postNum: postNum, txid: txid});
  await db.dialogTxoTab.put({dialogId: dialogIdB, postNum: postNum, txid: txid});
}

export async function findDialogTxo(db, dialogId) {

  console.log("findDialogTxo() need dialogtTxoTab record with dialogId of " + dialogId )

  let results = await db.dialogTxoTab.where({dialogId: dialogId}).toArray();
  console.log("findDialogTxo(): got " + results.length + " results");

  if ( results && results != null && results.length > 0 ) {
    console.log("findDialogTxo(): btw: txid is ", results[0].txid);
    return results[0];
  }

  return null;
}

/**
 *
 * @param {*} db
 * @param {*} limb
 * @param {*} address
 * @param {*} owner
 * @param {*} txid          - transactionID to which this refers
 * @param {*} outIndex      - the output index to which this refers
 * @param {*} modeOfOutput  - the mode of the output
 */
export async function addTxo(db, limb, address, owner, txid, outIndex, modeOfOutput) {
    console.log("addTxo(): Adding DB entry/row (to txotab) for limb " + limb
              + ", address " + address + ", owner " + owner
              + ", txid " + txid + ", outIndex " + outIndex
              + ", and mode " + modeOfOutput + "\n")

    //FIXME: use .add()?
    //FIXME: .put() safer? or, should we fail-fast?
    await db.txotab.put({limb: limb, address: address, owner: owner, txid: txid, outIndex: outIndex, mode: modeOfOutput});
/*
    //txotab (txid TEXT NOT NULL, outIndex INT, sats INT, mode TEXT, spentTx TEXT)
    const sats = 55  // for now
    const spentTx = 'NULL'      // bogus. needed?

    // avoid autoincrement. use NULL for INT primary key. see: https://www.sqlite.org/autoinc.html
    const dbString  = "INSERT INTO txotab VALUES (NULL, '"  + txid + "',"
                    + outIndex + ","
                    + sats + ","
                    + "'" + mode + "',"
                    //+ "'" + spentTx + "')"  //FIXME: use this
                    + "" + spentTx + ")"
    //console.log("\tDBString: " + dbString)
    await db.run(dbString);
*/
}

export async function addLabeledContent(db, addNotPut, ownerNo, limb, label, address, blockNum, content, lid) {
  console.log("addLabeledContent(): Adding DB entry/row (to labeledContentTab) for limb " + limb
            + ", label " + label + ", address " + address + "  - with content of length " + content.length + "\n")

  //NOTE: Attempt to spot how/where nulls are being added to the end of labels
  let labelLen = label.length
  if ( label.charCodeAt(labelLen - 1) === 0 ) {
    console.warn("Are there nulls at the end of the label? " + label.charCodeAt(labelLen - 2) )
    while ( label.charCodeAt(labelLen - 1) === 0 ) {
      console.log("removing one null at end of label. Length was " + labelLen)
      label = label.substring(0, labelLen-1)
      labelLen = label.length
    }
    console.log("    Now label has length " + labelLen + ": ", label)
    console.warn("Heads-up: read the log. We had nulls at the end of our label. "
                + "Though, they should have been removed just now.")
  }

  if ( addNotPut ) {
    console.log(" ADDING record (don't know what the lid will be)")
    await db.labeledContentTab.add({            limb: limb,  label: label,  owner: ownerNo,  address: address,  blockNum: blockNum, content: content});
  } else {
    console.log(" PUTTING (replacing) record - with lid of " + lid)
    await db.labeledContentTab.put({  lid: lid, limb: limb,  label: label,  owner: ownerNo,  address: address,  blockNum: blockNum, content: content});
  }
}

// add or advance a dangling path
// NOTE: if a tx has multiple contract outputs, most single spends would ADD a dangling path
//       Typically, only T1/T5 (and maybe Continue) would REPLACE a dangling path. Pay attention.
export async function addDanglingPath(db, limb, tx, ownerNo, address) {
  const lastCheck = Math.floor(Date.now() / 1000)
  console.log("addDanglingPath(): Adding DB entry/row (to danglingPathsTab) for limb " + limb
            + ", owner " + ownerNo + ", address " + address + ", tx of " + tx + ", lastChecked " + lastCheck + "\n")

  console.log("ADDING dangle record: " + address)
  await db.danglingPathsTab.put({ address: address,  limb: limb,  tx: tx,  owner: ownerNo,  lastCheck: lastCheck});
}

/**
 * Add a record to utxoTab
 * @param {*} db
 * @param {*} limbs
 * @param {*} tx
 * @param {*} ownerNo
 * @param {*} addresses
 * @param {*} scriptHashes
 * @param {*} modes
 */
export async function addUTXO(db, limbs, tx, ownerNo, addresses, scriptHashes, modes) {
  const lastCheck = Math.floor(Date.now() / 1000)

  for ( let i = 0; i < scriptHashes.length; i++) {
    if (scriptHashes[i] !== null ) {
      console.warn("addUTXO(): Adding entry (to utxoTab) for limb " + limbs[i]
                + ", owner " + ownerNo + ", outIndex " + i + ", for address " + addresses[i]
                + ", tx of " + tx + ", scriptHash of " + scriptHashes[i]
                + ", lastChecked " + lastCheck + "\n")

      await db.utxoTab.put({limb: limbs[i],
                            scriptHash: scriptHashes[i],
                            tx: tx,
                            address: addresses[i],
                            idx: i,
                            mode: modes[i],
                            owner: ownerNo,
                            lastCheck: lastCheck});
    }
  }
}

export async function addSpentTxo(db, txoKeyId, txid) {
  //console.log("\nAdding DB entry/row (to spentTxoTab) for txid " + txid + "\n")

  //FIXME: use .add()?
  await db.spentTxoTab.put({txoKeyId: txoKeyId, txIdItFunds: txid});

  /*
  // spentTxoTab (txoKeyId INT, txIdItFunds TEXT)
  const dbString  = "INSERT INTO spentTxoTab VALUES (" + txoKeyId + ","
                  + "'" + txid + "')"

  //console.log("\taddSpentTxo(): DBString: " + dbString)
  await db.run(dbString);
  */
}


//FIXME: is this the right approach (silently not adding to the IDB)?
//       createDialog() takes a different approach: throwing an error

export async function addRawTx(db, txid, rawTx) {
    console.log("addRawTx(): adding DB entry/row (to rawTxTab) for txid " + txid)

    if ( typeof rawTx !== 'undefined' ) {
      await db.rawTxTab.put( {txid: txid, rawTx: rawTx} );
    } else {
      console.error(("addRawTx(): IGNORING request to put an undefined rawTx"))
      alert("addRawTx() ignoring undefine")
    }
}

export async function addExternalTx(db, txid, rawTx) {
  console.log("addExternalTx(): adding DB entry/row (to externalTxTab) for txid " + txid)

  if ( typeof rawTx !== 'undefined' ) {
    await db.externalTxTab.put( {txid: txid, rawTx: rawTx} );
  } else {
    console.error(("addExternalTx(): IGNORING request to put an undefined rawTx"))
  }
}

export async function addDecodedTx(db, txid, decodedTx) {
  console.log("addDecodedTx(): adding DB entry/row (to decodedTxTab) for txid " + txid)

  if ( typeof decodedTx !== 'undefined' ) {
    await db.decodedTxTab.put( {txid: txid, decodedTx: JSON.stringify(decodedTx)} );
  } else {
    console.error(("addDecodedTx(): IGNORING request to put an undefined decodedTx for tx ", txid))
    alert("addDecodedTx() ignoring undefine")
  }
}

export async function recordQueryForUnconfirmedTxoSpend(db, txoKeyId, lastTimeProviderQueried) {


  //FIXME: make sure we're updating/replacing, not adding a record if it already exists

  //FIXME: is this the right approach (silently not adding to the IDB)?
//       createDialog() takes a different approach: throwing an error
  if ( typeof txoKeyId !== 'undefined' && typeof lastTimeProviderQueried !== 'undefined' ) {
    await db.unconfirmedSpendTab.put({txoKeyId: txoKeyId, lastTimeProviderQueried: lastTimeProviderQueried});
  } else {
    console.error(("recordQueryForUnconfirmedTxoSpend(): IGNORING request to put an undefined txoKeyId or lastTimeProviderQueried"))
    alert("recordQueryForUnconfirmedTxoSpend() ignoring undefine")
  }
}

export async function getUnconfirmedTxoSpendQueryTime(db, txoKeyId) {
  let result = await db.unconfirmedSpendTab.where('txoKeyId').equals(txoKeyId).toArray();
  if ( result && result != null && result.length > 0 ) {
    console.log("getUnconfirmedTxoSpendQueryTime(): retrieved ", result[0]);
    return result[0].lastTimeProviderQueried;
  } else {
    return null
  }
}

export async function removeUnconfirmedTxoSpend(db, txoKeyId) {
  if ( txoKeyId !== null ) {
    await db.unconfirmedSpendTab.where({txoKeyId: txoKeyId}).delete();
  }
}

export async function removeRawTx(db, txid) {
  if ( txid !== null ) {
    await db.rawTxTab.where({txid: txid}).delete();
    console.log("removeRawTx(): deleted with txid of " + txid)
  }
}
export async function removeDecodedTx(db, txid) {
  if ( txid !== null ) {
    await db.decodedTxTab.where({txid: txid}).delete();
    console.log("removeDecodedTx(): deleted with txid of " + txid)
  }
}

//FIXME: maybe use this if we're removing faded txs?
async function removeTxo(db, txoid) {
  if ( txoid !== null ) {
    await db.txotab.where({txoid: txoid}).delete();
    console.log("removeTxo(): deleted with txoid of " + txoid)
  }
}

export async function removeDanglingPath(db, address) {
  if ( address !== null ) {
    const res = await db.danglingPathsTab.where({address: address}).delete();
    console.log("removeDanglingPath(): deleted with address of " + address + ". Results: ", res)
    return res
  }
  return 0
}

async function removeTx(db, txid) {
  if ( txid !== null ) {
    await db.txtab.where({txid: txid}).delete();
    console.log("removeTx(): deleted with txid of " + txid)
  }
}

export async function removeFadedTx(txid, db) {
  console.log("============= removing faded tx " + txid + " (and descendants) ================")

  // Did this tx fund anything else?
  // We'll delete the 'furthest/youngest' descendants first (IOW: recursively)
  await recursivelyDeleteFadedTxs(db, txid, false);


  // delete any spentTxoTab entries that spend INTO this txId we're trying to fade


  // NEW: delete spentTxoTab entries which feed into this tx-to-fade
  // spentTxosWhichFeedThisTxToFade
  const res = await db.spentTxoTab.where('txIdItFunds').equals(txid)?.delete();
  console.error("removeFadedTx(): BTW: we removed ", res , " spentTxoTab entries which fed INTO that tx <----")


  // delete the decoded tx, so we can re-calculate/reset various member fields that depend on spent txos
  console.log("Done with all descendants of spends. Now removing DECODED tx, and txTab record for " + txid)
  await removeDecodedTx(db, txid)
  await removeTx(db, txid)
  //NOTE: not deleting raw tx (THIS tx). decoded, and txtab can be re-derived/saved from this
//FIXME: BUT if it was truly faded, we probably want/need to, right?
  console.log("============= DONE with faded tx " + txid + " ================")
}

async function recursivelyDeleteFadedTxs(db, txid, deleteBaseTx) {
  // 1) Identify every txo of the txid
  // 2) recursively delete (with descendants) any that were spent
  // 3) delete each txo
  // 4) delete the txid, decoded, raw

  console.warn("recursivelyDeleteFadedTxs(): should be deleting descendants of txid " + txid)
  console.log("recursivelyDeleteFadedTxs(): deleteBaseTx ? " + deleteBaseTx)
  const descendantCollection = await db.txotab.where({txid: txid})
  const descendants = await descendantCollection.toArray();
  console.log(  "rDFT():    txotab entries of txid ", txid + ": ", descendants)

  // each txo of a txid
  for ( let d in descendants ) {
    console.log("rDFT():        descendant/txotab ", descendants[d])
    console.log("rDFT():            must delete descendants of txoid " + descendants[d].txoid)
    await spentTxoTabFunc(db, descendants[d].txoid)

    console.log("recursivelyDeleteFadedTxs(): done with the spentTxoTabFunc() of txo " + descendants[d].txoid)
  }

  console.log("recursivelyDeleteFadedTxs(): DONE with descendants. NOW deleting the actual txos of " + txid)
  await descendantCollection.delete()

  console.log("recursivelyDeleteFadedTxs(): DONE with txos. Now deleting rawTx, decodedTx, and txTab record of " + txid)
  if ( deleteBaseTx ) {
    await removeRawTx(db, txid)
    console.log("    removed raw. now decoded...")
    await removeDecodedTx(db, txid)
    console.log("    removed decoded. now tx...")
    await removeTx(db, txid)
    console.log("    DONE removing other records of " + txid)
  }
}

/**
 * Find every spentTxoTab (children) of a txo, recursively delete their descendants,
 * then delete them.
 * @param {*} db
 * @param {*} txoid
 */
//FIXME: rename to spentTxoTabDescendantDeletionFunc()
async function spentTxoTabFunc(db, txoid) {
  console.log("sTTF(): search for spentTxoTab where txoKeyId is " + txoid)
  let descendantCollection = await db.spentTxoTab.where({txoKeyId: txoid})
  const descendants = await descendantCollection.toArray();
  console.log(" ")
  console.log("sTTF(): have collection (descendants of txoKeyId " + txoid
            + ") of size " + descendants.length)
  console.log("   descendants: ", descendants)

  // End when there are no descendants
  if ( !descendants.length ) {
    console.warn("Returning now. Invalid collection.")
    return
  }
  console.log(  "        spentTxoTabFunc() descendants of txo " + txoid + ": ", descendants)

  for ( let d in descendants ) {
    console.warn("          spentTxoTabFunc() descendant ", descendants[d])
    console.warn("            must delete descendants of txoKeyId "    + descendants[d].txoKeyId)
    console.warn("            must delete descendants of txIdItFunds " + descendants[d].txIdItFunds)
    await recursivelyDeleteFadedTxs(db, descendants[d].txIdItFunds, true);

    // Delete any unconfirmed spend record
    console.log("         spentTxoTabFunc(): recursively deleted that txIdItFunds. now deleting any unconfirmed txo spend with key of ", descendants[d].txoKeyId)
    await removeUnconfirmedTxoSpend(db, descendants[d].txoKeyId)
  }

  // now that all descendants of this txo have been removed, we can remove the spentTxoTab records for it
  console.log("sTTFunc():    Recursively deleted descendants. Now, DELETING " + descendantCollection.length
        + " spentTxoTab records for where txoKeyId is "
        + txoid + ":  ", descendants)
  await descendantCollection.delete()
}

export async function findTxoFromTxidAndIndex(db, txId, outIdx) {

  console.log("findTxoFromTxidAndIndex() need txotab record with txid of " + txId + ", and outIndex of " + outIdx)

  let results2 = await db.txotab.where({txid: txId}).filter(function (item) {
                                        //WARNING: we currently need the '==', not '==='
                                        //         Perhaps somewhere we're inserting a string, and
                                        //         elsewhere inserting a number
                                        const ret = item.outIndex == outIdx;

                                        //console.log("       " + item.outIndex + " == " + outIdx + " ? returning " + ret);
                                        return ret;
                                        //return item.outIndex == outIdx;
                                      }).toArray();
  console.log("findTxoFromTxidAndIndex(): got " + results2.length + " results (results2)");

  if ( results2 && results2 != null && results2.length > 0 ) {
    console.log("findTxoFromTxidAndIndex(): btw: txoid is ", results2[0].txoid);
    return results2[0];
  }

  return null;

  //const dbString = "SELECT rowid AS txoid, txid, outIndex, mode FROM txotab WHERE txid = '"
   //             + txid + "' AND outIndex = " + outIndex;
  //console.log("findTxoFromTxidAndIndex():   dbString is:\n\t" + dbString)

  //return await db.get(dbString);
}

//FIXME: what if label has special character that screws-up this search
//FIXME: then we'd want to escape/encode the label, maybe (or keep it in base64)
export async function findContentFromLimbAndLabel(db, ownerNo, limb, label) {

  console.log("findContentFromLimbAndLabel() need labeledContentTab record with limb of " + limb + ", and label of '" + label + "'")
  console.log("   (for owner #" + ownerNo + ")")

  //let results0 = await db.labeledContentTab.where('[limb+label]').equals([limb, label]);
  //let results1 = await db.labeledContentTab.where({limb: limb, label: label});
  let results2 = await db.labeledContentTab.where({limb: limb}).filter(function (item) {
                                        console.log("     CONSIDERING one record...  label: '" + item.label + "', limb: " + item.limb + ", owner: " + item.owner)

                                        //FIXME: do we still need this replace?

                                        //NOTE: we found item.label terminated with multiple nulls (doubling the length)
                                        return (item.label.replace(/\0/g, '') === label) && (item.owner === ownerNo);
                                      }).toArray();
  //console.log("findContentFromLimbAndLabel(): got " + results0.length + " results (results0)");
  //console.log("findContentFromLimbAndLabel(): got " + results1.length + " results (results1)");
  console.log("findContentFromLimbAndLabel(): got " + results2.length + " results (results2)");

  if ( results2 && results2 != null && results2.length > 0 ) {
    console.log("findContentFromLimbAndLabel(): btw: label is ", results2[0].label);
    console.log("findContentFromLimbAndLabel(): btw: address is ", results2[0].address);
    return results2[0];
  }

  return null;
}

export async function findNextTxFromSpentTxo(db, txoid) {
  console.log("findNextTxFromSpentTxo() looking for spentTxoTab record with txoKeyId of " + txoid)

  //NOTE: field name differs from console app

  let result = await db.spentTxoTab.where('txoKeyId').equals(txoid).toArray();

  console.log("findNextTxFromSpentTxo(): result: ", result);
  console.log("result has length " + result.length)

  //FIXME: what if result is null?
  if ( result && result != null && result.length > 0 ) {
    return result[0];
  } else {
    return null;
  }

  //const dbString = "SELECT txoKeyId, txIdItFunds FROM spentTxoTab WHERE txoKeyId = '"
    //            + txoid + "'"
  //console.log("findNextTxFromSpentTxo():   dbString is:\n\t" + dbString)

  //return await db.get(dbString);
}

//FIXME: rename findPrevFromSpentTxo()?
export async function findPrevFromCurrent(db, currentTxId) {
  console.log("findPrevFromCurrent(): looking for spentTxoTab entry with txIdItFunds of " + currentTxId);

  let spentTxoResult = await db.spentTxoTab.where('txIdItFunds').equals(currentTxId).toArray();

  console.log("findPrevFromCurrent(): spentTxoResult: ", spentTxoResult);
  console.log("findPrevFromCurrent(): spentTxoResult has length " + spentTxoResult.length)
  if ( spentTxoResult.length > 0 ) {
    console.log("findPrevFromCurrent(): parent txoKeyId/txoid: " + spentTxoResult[0].txoKeyId);
    const txoid = spentTxoResult[0].txoKeyId;

    let txotabResult = await db.txotab.where({txoid: txoid}).toArray();

    console.log("findPrevFromCurrent(): got txotab: ", txotabResult.length, "results")
    console.log("findPrevFromCurrent(): txoTab results: ", txotabResult);

    //FIXME: now that we've fixed spentTxoTabFunc(), we may not need this try of the 2nd result
    //FIXME: maybe put this all in a loop - in case we ever need to try spentTxoResult[2].txoKeyId ?
    if ( txotabResult.length === 0 && spentTxoResult.length > 1 ) {
      console.warn("findPrevFromCurrent(): that 0th one didn't work. Will try spentTxoResult[1]: " + spentTxoResult[1].txoKeyId)

      const txoid2 = spentTxoResult[1].txoKeyId;

      txotabResult = await db.txotab.where({txoid: txoid2}).toArray();

      console.log("findPrevFromCurrent(): 2nd try: got txotab: ", txotabResult.length, "results")
      console.log("findPrevFromCurrent(): 2nd try: txoTab results: ", txotabResult);

      alert("findPrevFromCurrent(): we resorted to looking at the 2nd result. Should the first be " +
            "deleted? Was it the result of an incomplete cleanup after deleting a faded, or bogus tx? " +
            "Also, check if there are even more than 2 results.")
    }

    if ( txotabResult && txotabResult != null && txotabResult.length > 0 ) {
      console.log("findPrevFromCurrent(): got outIndex " + txotabResult[0].outIndex +
          " of tx " + txotabResult[0].txid);
      return txotabResult[0].txid;
    }
  }

  //NOTE: if we don't have it (here in the DB), we just give up.
  //      We could see if WoC API is helpful. Might not be.
  //      Or, we could do a more sophisticated search starting from earlier
  //      points in the asset. Eventually, we may need to solve some of this.
  console.log("findPrevFromCurrent(): returning null");
  return null;
}

export async function getLatestAuction(db, limbName) {
  console.log("getLatestAuction() for " + limbName)

  // Find all dangling paths (for this limb) that have exactly 6 slashes  ( bshz://abc/{owner#}/{QC}/A/{op#} )
  let results = await db.danglingPathsTab.where({limb: limbName}).filter(function (item) {
                                        //console.log("    getLatestAuction(): CONSIDERING one record: '", item)

                                        const parts = item.address.split("/", 8)
                                        if ( parts.length !== 7 ) {
                                          //console.log("         skipping this one. needs exactly 7 parts (6 slashes), but has " + parts.length + " parts")
                                          return false
                                        }
                                        //console.log("      maybe this one. parts[5]: " + parts[5])
                                        return parts[5] === 'A';
                                      }).toArray();

  // Choose the dangling path with the largest operationsCount
  // That should be the 'latest' auction
  //FIXME: ponder ownerCount, and QuarterlyCount. Are they culled? quick enough?
  if ( results && results != null && results.length > 0 ) {
    let bestIndex = 0
    let largestQCVal = 0
    let largestOwner = 0
    let largestOpsVal = 0
    console.log("getLatestAuction(): there are " + results.length + " prelim results")
    for ( let i in results ) {
      console.log("getLatestAuction(): considering ", results[i])
      const parts = results[i].address.split("/", 8)
      //console.log("    final part is " + parts[4] + " <----")
      const ownerNum = parseInt( parts[3] )
      const qcVal = parseInt( parts[4] )
      const opsVal = parseInt( parts[6])

      //console.log("getLatestAuction(): owner: " + ownerNum)
      //console.log("                    QC: " + qcVal)
      //console.log("                    ops: " + opsVal)

      // always use the latest owner
      if ( ownerNum > largestOwner ) {
        console.log("owner > largest")
        bestIndex = i
        largestOwner = ownerNum
        largestQCVal = qcVal
        largestOpsVal = opsVal
      }

      // for the latest owner, use the latest quarterly
      if ( qcVal > largestQCVal ) {
        console.log("qc > largest")
        bestIndex = i
        largestQCVal = qcVal
        largestOpsVal = opsVal
        //console.log("    new best index: " + i + ", with value " + val)
      }

      // for the latest quarterly, use the latest operations count
      if ( opsVal > largestOpsVal ) {
        console.log("ops > largest")
        bestIndex = i
        largestOpsVal = opsVal
      }
    }
    //console.log("getLatestAuction(): using item [" + bestIndex + "]: ", results[bestIndex]);
    return results[bestIndex];
  }

  return null;
}

//NOTE: assumption that we've already checked for exact claimed asset
//      using get/findLatestContinue()
export async function findClosestToLimb(db, targetName) {
  console.log("findClosestToLimb() checking txotab for " + targetName)

  let possibilities = []
  const targetLen = targetName.length
  for (let i = 0; i < targetLen; i++ ) {
    possibilities[i] = targetName.substring(0, i+1)
  }
  console.log("findClosestToLimb:  possibilities: ", possibilities)

  // good clauses in general
  //equals(targetName).or('limb').equals('ggv').filter(function (item) {

  // setup a query for each PARTIAL name on the path to targetName
/**
  let results = await db.danglingPathsTab.where('limb').anyOf(possibilities).filter(function (item) {   //<---- removed this. Now, trying txoTab
    console.log("    findClosestToLimb(): CONSIDERING one record: '", item)

    const parts = item.address.split("/", 20)
    const partsLen = parts.length
    //console.warn("This entry has " + partsLen + " parts: ", item.address)
    if ( partsLen != 4 && partsLen != 5 ) {
      console.log("    findClosestToLimb: wrong parts length: ", item.limb)
      return false
    }
    console.log("    findClosestToLimb: correct parts length (4 or 5): ", item.address)
    return true;
  }).toArray();
/**/
  //const results = await db.txotab.where({limb: targetName, owner: 0}).sortBy('address')

  // This works -  BECAUSE we sort by address
  const results = await db.txotab.where({owner: 0}).sortBy('address')

  // This could work - but MAYBE it needs different follow-on logic.
  //                   We'd prob want to ALSO take the largest owner
  //const results = await db.txotab.where('limb').anyOf(possibilities).sortBy('address')

  console.warn("findClosestToLimb(): so far, we have results: ", results)
  if ( results && results != null && results.length > 0 ) {
    let longestResIdx = -1
    let longestLen = 0

//FIXME: can/should we short-circuit if we know it's as long as we could expect?

    // loop over every result, taking the longest,
    // BUT,
    // only care about bshz://{domain}/0, and MAYBE bshz://{domain-part}/1/0
    // anything after that (later owner version) has been claimed
    //
    for ( let i in results ) {
      const limb = results[i].limb
      const limbLen = limb.length

      //console.log("  Let's consider results[" + i + "].limb: ", limb )

      if ( limb === targetName.substring(0, limbLen) ) {
        console.warn("    candidate " + limb + "  IS a substring of target " + targetName)
      } else {
//        console.warn("    candidate " + limb + "  is NOT a substring of target " + targetName + "  XXXX  skipping this one")
        continue;
      }

      //console.warn("        findClosestToLimb: possibility: ", results[i].limb)
      if ( limbLen > longestLen ) {
        //WARNING: watch out. If ownerNum is 1, then, the limb MUST be a substring of the target

        longestResIdx = i
        longestLen = results[i].limb.length   // This line was missing
        console.log("          idx " + i + " is now the longest. len: " + longestLen + "   limb: ", results[i].limb)
      }
    }

    if ( longestResIdx > -1 ) {
      console.warn("findClosestToLimb(): returning result " + longestResIdx + ": ", results[ longestResIdx ])
      return results[ longestResIdx ]
    }
  }

  // NOTHING?
  console.error("IDB: findClosestToLimb(): Does this even make sense? As long as we have the second fan-out, we should get something")
  return null
}

/**
 * Re-implemented using utxoTab, and the .mode field, sorting by address
 *
 * @param {*} db
 * @param {*} limbName
 */
export async function getLatestContinue2(db, limbName) {
  console.log("getLatestContinue2() for " + limbName)

  const results1 = await db.utxoTab.where({limb: limbName, mode: 'p'}).sortBy('address')

  //console.warn("getLastContinue2(): unsorted results: ", await results1.toArray())
  //let results2 = await results1.sortBy('address')
  //const resultsLen = results.length

  console.warn("getLastContinue2(): sorted: ", results1)

  const resLen = results1.length
  if ( resLen == 0 ) {
    return null
  }
  return results1[ resLen - 1 ]
}

/**
 * sorts by address - regardless of mode
 * WARNING: no mention of owner
 * NOTE: let's ignore EbfraSpeaker
 *
 * @param {*} db
 * @param {*} limbName
 */
 export async function getLatestUTXO(db, limbName, ignoreEbfra = true) {
  console.log("getLatestUTXO() for " + limbName)

  const results1 = await db.utxoTab.where({limb: limbName}).sortBy('address')

  //console.warn("getLastContinue2(): unsorted results: ", await results1.toArray())
  //let results2 = await results1.sortBy('address')
  //const resultsLen = results.length

  console.warn("getLatestUTXO(): sorted: ", results1)

  const resLen = results1.length
  console.log("getLatestUTXO(): resLen = " + resLen)
  if ( resLen == 0 ) {
    return null
  }

  if ( ignoreEbfra && results1[ resLen - 1].address.endsWith("ebfraSpeaker") ) {

    //NOTE: mitigation of multiple ebfraSpeakers in list
    let indexToUse = resLen -1
    do {
      indexToUse--
      console.error("   DECREMENTING index to: " + indexToUse + "...")
    } while ( indexToUse >=0 && results1[ indexToUse ].address.endsWith("ebfraSpeaker") );

    console.error("FINAL INDEX: ", indexToUse)
    if ( indexToUse >= 0 ) {
      console.error("Finally returning entry: ", results1[ indexToUse])
      return results1[ indexToUse ]
    } else {
      return null
    }

    if ( resLen - 2 >= 0 ) {
      console.log("getLatestUTXO() skipping EbfraSpeaker")
      return results1[ resLen - 2 ]
    } else {
      console.error("getLatestUTXO() skipping EbfraSpeaker leaves us with no UTXOs?")
      alert("UNEXPECTED result. No latest UTXOs for asset " + limbName + "?")
      return null
    }
  } else {
    console.error("WATCH OUT: blindly returing the previous entry - without checking for ebfraSpeaker")
    return results1[ resLen - 1 ]
  }
}

export async function getLatestContinue(db, limbName) {
  console.log("getLatestContinue() for " + limbName)

  // Find all dangling paths (for this limb) that have exactly 4 slashes  ( bshz://abc/{owner#}/{QC} )
  let results = await db.danglingPathsTab.where({limb: limbName}).filter(function (item) {
                                        //console.log("    getLatestContinue(): CONSIDERING one record: '", item)

                                        const parts = item.address.split("/", 8)
                                        if ( parts.length !== 5 && parts.length !== 6 && parts.length !== 7 ) {  // could be normal post, or potentialSale, or auction
                                          //console.log("         skipping this one. needs exactly 5-7 parts (4-6 slashes), but has " + parts.length + " parts")
                                          return false
                                        }
                                        //console.log("      maybe this one. final part: " + parts[4])
                                        return true;
                                      }).toArray();

  /**
    console.log("getLatestContinue(): BEFORE any mangling else, got " + results.length + " results:",results);
    results = [
                  { address: "bshz://ba/0/"},
                  { address: "bshz://ba/0/vote1"},
                  { address: "bshz://ba/0/vote2"},
                  { address: "bshz://ba/1/2"},
                  { address: "bshz://ba/1/2/1"},
                  { address: "bshz://ba/1/2/2"},
                  { address: "bshz://ba/1/2/2/vote1"},  //6: vote on top of potentialSale
                  { address: "bshz://ba/1/2/3"},
                  { address: "bshz://ba/1/3"},
                  { address: "bshz://ba/1/3/vote1"},
                  { address: "bshz://ba/1/3/1"},         //10: potentialSale should wipe-out a vote FIXME: ensure decode does that
                  { address: "bshz://ba/1/3/1/vote2"},
                  { address: "bshz://ba/1/3/2"},
                  { address: "bshz://ba/1/3/2/vote3"},
                  { address: "bshz://ba/1/4"},
                  { address: "bshz://ba/1/4/vote4"},
                  { address: "bshz://ba/1/3/vote5"},      //16: DROP
                  { address: "bshz://ba/1/4/2/vote4"},
                  { address: "bshz://ba/1/4/1/vote5"},    //18: DROP
                  { address: "bshz://ba/1/3/3/vote6"},    //19: DROP
                  { address: "bshz://ba/0/5/4/vote7"},    //20: DROP
                  { address: "bshz://ba/1/5/U/9"},        //21 NOT
                  { address: "bshz://ba/116/40295/U/0"},  //22 NOT
                  { address: "bshz://ba/116/40295/U/1"},  //23 NOT
                  { address: "bshz://ba/1/6/U/1/T/1"},    //24 NOT
                  { address: "bshz://ba/2/1"},
                  { address: "bshz://ba/2/2"},
                  { address: "bshz://ba/2/1/vote4"},      //27 DROP
                  { address: "bshz://ba/2/2/vote3"},
                  { address: "bshz://ba/2/5/vote2"},
                  { address: "bshz://ba/2/6"},
                  { address: "bshz://ba/1/7"},            //31 DROP

                  { address: "bshz://ba/3/4/A/0"}},
              ]
    /**/

  console.log("getLatestContinue(): got " + results.length + " results:", results);

  // Note that we (further below) filter-out U, and A - if [5] is U or A

  // Choose the dangling path with the largest QuarterlyCount
  // That should be the 'latest' continue
  if ( results && results != null && results.length > 0 ) {
    // changed from 0. This way we're actively deciding (testing our logic)
    let bestIndex = -1
    let largestQcVal = -1
    let largestOwner = -1

    let largestPotentialSaleCounter = 0
    let largestVoteNum = 0
    console.log("getLatestContinue(): considering " + results.length + " results")
    for ( let i in results ) {
      console.log("getLatestContinue(): Now let's consider candidate result #" + i + ": ", results[i].address)
      const parts = results[i].address.split("/", 8)
      //console.log("    final part is " + parts[4] + " <----")
      const ownerNum = parseInt( parts[3] )
      const qcVal = parseInt( parts[4] )
      let potentialSaleCounter = 0
      let thisIsCurrentBest = false

      if ( parts.length >= 6 && ( parts[5] === 'U' || parts[5] === 'A') ) {
        console.warn("TOSSING a dangle that might be a BitGroup, Update, Transient, or Auction:", parts)
        continue
      }

      // always use the latest owner
      if ( ownerNum > largestOwner ) {
        console.log("  New best (" + i + ") has larger ownerNum: " + ownerNum)
        bestIndex = i
        largestOwner = ownerNum
        largestQcVal = qcVal
        thisIsCurrentBest = true
      }

      // for the latest owner, use the latest quarterly
      if ( ownerNum === largestOwner && qcVal > largestQcVal ) {
        console.log("  New best (" + i + ") has larger QC: " + qcVal)
        bestIndex = i
        largestQcVal = qcVal
        thisIsCurrentBest = true
      }

      // consider txs that involve specifying/clearing a potentialSale
      //     OR
      //   applying an EBFRA
      //     OR
      //   both
      if ( parts.length === 5 ) {
        console.warn("This has 5 parts. Maybe it's a vote applied to P:", results[i].address)
        if ( parts[4].startsWith("vote") ) {
          console.warn("This IS a vote")
          const voteNum = parts[4].substring(4)
          console.warn("  voteNum is " + voteNum)

          if ( thisIsCurrentBest ) {
            console.warn("     getLatestContinue(): new best (" + i
                  + ") , so, the largest voteNum is now this: " + largestVoteNum)
            console.log(" and resetting lPSC")
            largestVoteNum = voteNum
            largestPotentialSaleCounter = 0 // or -1
          } else if ( ownerNum === largestOwner  && voteNum > largestVoteNum ) {
            console.log(" new best (" + i + ") has larger vote num: " + voteNum)
            console.log(" and resetting lPSC")
            largestVoteNum = voteNum
            largestPotentialSaleCounter = 0 // or -1
            bestIndex = i
            thisIsCurrentBest = true
            console.log("    new best index: " + i + ", with voteNum " + voteNum)
          } else {
            console.log("    entry with index: " + i + ", had voteNum " + voteNum
                      + ", but that's not the biggest (" + largestVoteNum + ")")
          }

        } else {
          console.warn("This is NOT a vote. maybe just larger because of QC")

          if ( thisIsCurrentBest ) {
            console.warn("     getLatestContinue(): new best (" + i
                        + ") , but we'll reset the largestPotentialSaleCounter, and largestVoteNum")
            largestPotentialSaleCounter = 0
            largestVoteNum = 0
          }
        }
      } else if ( parts.length === 6 ) {
        potentialSaleCounter = parseInt( parts[5] )
        console.warn(" --- 6 parts ---  that means either vote or potentialSale, right?")
        if ( parts[5] === "U" ) {
          console.warn("TOSSING this one. It's an Update, or BGroup")
        } else if ( parts[5].startsWith("vote") ) {
          console.error("This is NOT a potentialSale URL: ", results[i].address)
          const voteNum = parts[5].substring(4)
          console.warn("  voteNum is " + voteNum)


          //compare vote, instead of salesCounter
          if ( thisIsCurrentBest ) {
            console.warn("     getLatestContinue(): new best (" + i
                      + ") , so, the largest vote is now this: " + voteNum)
            largestVoteNum = voteNum
            console.log(" and resetting lPSC")
            largestPotentialSaleCounter = 0 // or -1
          } else if ( ownerNum === largestOwner && qcVal === largestQcVal && voteNum > largestVoteNum ) {
            console.warn("     getLatestContinue():   new best (" + i + ") has larger voteNum: " + voteNum)
            bestIndex = i
            largestVoteNum = voteNum
            console.log(" and resetting lPSC")
            largestPotentialSaleCounter = 0 // or -1
          } else {
            console.log("    entry with index: " + i + " couldn't hack it. Maybe either the owner, or QC, or voteNum were too low")
          }


        } else {
          console.warn("    getLatestContinue(): we need to look at the potentialSaleCounter - get the latest")

          if ( thisIsCurrentBest ) {
            console.warn("     getLatestContinue(): new best (" + i
                    + ") , so, the largest potential sale counter is now this: " + potentialSaleCounter)
            largestPotentialSaleCounter = potentialSaleCounter
            console.log(" and resetting voteNum")
            largestVoteNum = 0 // or -1
          } else if ( ownerNum === largestOwner && qcVal === largestQcVal
                    && potentialSaleCounter > largestPotentialSaleCounter ) {
            console.warn("     getLatestContinue():   new best (" + i + ") has larger pSC: " + potentialSaleCounter)
            bestIndex = i
            largestPotentialSaleCounter = potentialSaleCounter
            console.log(" and resetting largest voteNum")
            largestVoteNum = 0 // or -1
          } else {
            console.log("    entry with index: " + i + " not good enough. Either Owner, QC, or  pSC ("
                      + potentialSaleCounter + ") not good enough")
          }
        }
      } else if ( parts.length === 7 ) {
        console.warn("This has 7 parts. Maybe it's a potentialSale PLUS a vote applied:", results[i].address)
        console.warn("handling potentialSale PLUS vote. parts:", parts)
        if ( parts[6].startsWith("vote") ) {
          console.warn("This DOES look like a vote applied to potential sale")

          potentialSaleCounter = parseInt( parts[5] )
          const voteNum = parts[6].substring(4)
          console.warn("  pSC is " + potentialSaleCounter + ", and voteNum is " + voteNum)

          console.warn("    getLatestContinue(): we need to look at the potentialSaleCounter - get the latest")

          if ( thisIsCurrentBest ) {
            console.warn("     getLatestContinue(): new best (" + i
                      + ") , so, the largest potential sale counter is now this: " + potentialSaleCounter)
            largestPotentialSaleCounter = potentialSaleCounter

            console.warn("     getLatestContinue(): new best (" + i + ") , so, the largest vote is now this: " + voteNum)
            largestVoteNum = voteNum
          } else if ( ownerNum === largestOwner && qcVal === largestQcVal
                    && potentialSaleCounter > largestPotentialSaleCounter ) {
            console.warn("     getLatestContinue():   new best (" + i + ") has larger pSC: " + potentialSaleCounter)
            bestIndex = i
            largestPotentialSaleCounter = potentialSaleCounter
            largestVoteNum = voteNum
          } else if ( ownerNum === largestOwner && qcVal === largestQcVal
                    && potentialSaleCounter === largestPotentialSaleCounter
                    && voteNum > largestVoteNum ) {
            console.log(" new best (" + i + ") has larger vote num: " + voteNum)
            bestIndex = i
            largestVoteNum = voteNum
            console.log("    new best index: " + i + ", with voteNum " + voteNum)
          } else {
            console.log("    entry with index: " + i
                      + " couldn't cut it. Prob owner or QC, or potentialSaleCounter, or voteNum were too low")
          }

        } else {
          console.warn("  hmm. parts[6] does NOT start with vote? ", parts)
          console.error("Now what? Not what we had thought. do nothing? <---")
          alert("Surprising result for " + results[i].address + " Please report this.")
        }

      } else if ( parts.length > 7 ) {
        console.warn("TOSSING this one. It's got too many parts. Could be an Update, BGroup, transient, AUCTION?")
      } else if ( thisIsCurrentBest ) {
        alert("Not clear what to do now with result for " + results[i].address + ". It's the best? Maybe report this.")

        console.warn("BTW: parts.len is ", parts.len + "   : ", parts)
        console.error("CHECK ON voteNum? ")
        // this new current best might not have a potentialSaleCounter
        largestPotentialSaleCounter = 0
        largestVoteNum = 0
        console.warn("     getLatestContinue(): since new best has no pSC, the largest potential sale counter is now 0")
      } else {
        console.warn("BTW: NOT current best. parts.len is ", parts.len + "   : ", parts)
      }

    } // for loop - each candidate dangle

    console.log("getLatestContinue(): using item [" + bestIndex + "]: ", results[bestIndex]);
    if ( bestIndex === -1 ) {
      return null
    }

    //return {tx: "7e0fe99f1daa41c9db459684ef9717073f4f79ac6c2558cad44b76ac26da579a", address: "bshz://ba/0/vote1"}
    return results[bestIndex];
  }

  console.warn("getLatestContinue(): There are NO txs for a CLAIMED " + limbName + ". We need to find the unclaimed tx...")
  let simpleResult = await db.danglingPathsTab.where("limb").equals(limbName).toArray();

  if ( simpleResult && simpleResult !== null && simpleResult.length > 0 ) {
    // We think we should only be here if the asset is unclaimed, in
    // which case we should only get a SINGLE result: bshz://{asset}/0
    if ( simpleResult.length > 1 ) {
      // It's possible that if we're testing with multiple locking scripts,
      // in the same DB, we'll have collisions
      console.error("getLatestContinue(): We think there are too many SIMPLE dangling paths for limb " + limbName
            + ". (Collision between separately-funded (independent) txs in the same DB?)  Will return null.")
      console.log("getLatestContinue(): danglingPaths query results: ", simpleResult)
      return null
    }

    return simpleResult[0]
  }

  // maybe we haven't gotten around to scanning the dangles?
  console.error("getLatestContinue(): Not sure how we've found no result for limb '" + limbName
            + "'. Perhaps we're examining the pre-root, or some random tx (outside the Shizzle tree).")

  return null;
}

export async function getRawTx(db, txid) {
  console.log("getRawTx() looking for record with txid of " + txid)

  let result = await db.rawTxTab.where('txid').equals(txid).toArray();

  console.log("getRawTx(): result: ", result);
  console.log("result has length " + result.length)
  //FIXME: if length is longer than 1, warn (other places too) <-----

  if ( result[0] && result[0] !== null ) {
    return result[0].rawTx
  }
  return null;
}

//FIXME: rename getTxidFromShizzleAddress(), or getTxidFromShizzlePath())
export async function getTxidFromAddress(db, address) {
  console.log("getTxidFromAddress() looking for txtab record with SHIZZLE address of " + address)

  let result = await db.txtab.where('address').equals(address).toArray();

  console.log("getTxidFromAddress(): result: ", result);
  console.log("result has length " + result.length)
  //FIXME: if length is longer than 1, warn (other places too) <-----

  if ( result[0] && result[0] !== null ) {
    return result[0].txid
  }
  return null;
}

// searches for the next LOGICAL tx for an asset/limb
//FIXME: maybe owner is an unnecesary complication
//       The ordering would handle owner justs fine
export async function getNextAddress(db, limb, address, owner, rvs) {
  console.log("getNextAddress() looking for TXOTAB record AFTER SHIZZLE address of " + address
            + "  (limb " + limb + ", owner " + owner + ")  reverse? " + rvs)

  const res1 = await db.txotab.where({limb: limb, owner: owner})
  const res2 = rvs ?
                await res1.reverse()
              :
                res1
  const results = await res2.sortBy("address")

  console.warn("getNextAddress(): results sorted: ", results)
  let idx = null
  for ( let i = 0; i < results.length; i++ ) {
    if ( results[i].address === address ) {
      console.log("getNextAddress(): found address at position " + i)
      idx = i
      break;
    }
  }
  if ( idx === null ) {
    throw new Error("28337: non-existent address: " + address)
  }

  if ( idx > 0 ) {
    console.log("getNextAddress(): BEFORE: ", results[idx-1])
  }

  // It's not enough to take the next utxo
  // That next UTXO might simply be the next output in the SAME tx
  // Ensure that we take the next UTXO that belongs to a DIFFERENT tx
  const currentTxId = results[idx].txid
  for ( let i = 0; i < 5; i++ ) {
    if ( idx + i + 1 < results.length ) {
      if ( results[idx + i + 1].txid !== currentTxId ) {
        console.log("getNextAddress(): AFTER: ", results[idx+i+1])
        return results[idx + i + 1]
      } else {
        console.log("getNextAddress(): whoops. result " + (idx+i+1)
              + " is from the same tx (next output). skipping")
      }
    }
  }
  console.log("getNextAddress(): nothing found. must be the latest.")
  return null;
}


// searches for the next LOGICAL tx/post for a given dialog (in the specified direction: fwd/rvs)
export async function findNextDialog(db, dialogId, postNum, rvs) {

  console.log("findNextDialog() looking for DialogTxoTab record AFTER dialogId " + dialogId
            + "'s postNum " + postNum + " reverse? " + rvs)

  const postToFind = postNum + (rvs ? -1 : 1)
  console.warn("looking for postNum " + postToFind)
  const results = await db.dialogTxoTab.where({dialogId: dialogId, postNum: postToFind}).toArray()

  console.warn("findNextDialog(): results: ", results)
  if ( results[0] && results[0] !== null ) {
    return results[0]
  }
  return null;
}

/**
 * Queries internal DB for record of raw (non-shizzle) transaction
 */
export async function getExternalTx(db, txid) {
  console.log("getExternalTx() looking for EXTERNAL tx record with txid of " + txid)

  let result = await db.externalTxTab.where('txid').equals(txid).toArray();

  console.log("getExternalTx(): result for " + txid + ": ", result);
  console.log("result has length " + result.length)

  if ( result[0] && result[0] !== null ) {
    return result[0].rawTx
  }
  return null;
}

export async function removeUtxoByScriptHash(db, scriptHash) {
  console.log("removeUtxoByScriptHash() DELETING utxoTab entry with scriptHash of " + scriptHash)

/*
      await db.utxoTab.put({limb: limbs[i],
                            address: addresses[i],
                            tx: tx,
                            scriptHash: scriptHashes[i],
                            idx: i,
                            owner: ownerNo,
                            lastCheck: lastCheck});
*/

  return await db.utxoTab.where({scriptHash: scriptHash}).delete();
}

export async function getDecodedTx(db, txid) {
  console.log("getDecodedTx() looking for record with txid of " + txid)

  let result = await db.decodedTxTab.where('txid').equals(txid).toArray();

  //console.log("getDecodedTx(): result: ", result);
  console.log("result has length " + result.length)

  if ( result[0] && result[0] !== null ) {
    return JSON.parse( result[0].decodedTx )
  }
  return null;
}

/*
module.exports = {
  openShizzleDB,

  queryAll,
  queryContentLabels,
  getDanglingPaths,
  getAllDanglingPaths,

  registerSubscription,
  findAllSubscriptions,
  registerDomainOwnership,
  findDomain,
  createDialog,
  findDialog,
  findAllDialogs,
  addDialogTxo,
  addDialogTxos,
  findDialogTxo,

  addInternalTx,
  addTxo,
  addLabeledContent,
  addDanglingPath,
  addUTXO,
  addSpentTxo,
  addRawTx,
  addExternalTx,
  addDecodedTx,

  recordQueryForUnconfirmedTxoSpend,
  getUnconfirmedTxoSpendQueryTime,
  removeUnconfirmedTxoSpend,

  removeFadedTx,

  queryP2PKHs,
  addEncryptedP2PKHKey,
  clearEncryptedP2PKHKeys,
  findEncryptedKeyFromAddress,

  queryRabins,
  queryOfficialWallet,
  addEncryptedWalletKey,
  deleteEncryptedWalletKeys,
  clearEncryptedKeys,
  addEncryptedKeys,
  findEncryptedKeysFromPKH,
  findEncryptedKeysFromAddress,

  findTxoFromTxidAndIndex,
  findContentFromLimbAndLabel,
  findNextTxFromSpentTxo,
  findPrevFromCurrent,

  getLatestContinue2,
  getLatestContinue,
    updateLatestClosestToLimbCall,
    updateLatestGetDanglingPathsCall,
    updateLatestGetLatestContinueCall,
    getLatestClosestToLimbCall,
    getLatestGetDanglingPathsCall,
    getLatestGetLatestContinueCall,

  findClosestToLimb,
  getLatestAuction,

  getRawTx,
  getTxidFromAddress,
  getNextAddress,
  getExternalTx,
  removeUtxoByScriptHash,
  getDecodedTx,

  removeDanglingPath,

  //removeRawTx,
  removeDecodedTx  // now used by recursivelyDeleteDescendantSpends()
  //removeTxo,
  //removeTx
}
*/
