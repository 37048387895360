import React from 'react';

import { Button, Icon, Modal } from 'semantic-ui-react';

import BackgroundRunner from './backgroundRunner.js';
import { IDB_NAME, RUNNER_INTERVAL_MS } from './harnessConstants.js';

// Multiple exclusive Views - never mounted concurrently

import LinkJumper       from './linkJumper.js';
import ShizzleNav       from './shizzleNav.js';
import ShizzleView      from './shizzleView.js';
import ShizzleAd        from './shizzleAd.js';
import OnRamp           from './onramp.js';
import Surfer           from './surfer.js';
import ShizzleHome      from './shizzleHome';
import OverScreen       from './overScreen.js';

import WalletModal      from './walletModal.js';
import MinerPayments     from './minerPayments.js';

import { persistUtxoUnFrozen, getFrozenUtxos } from './commonFuncs.js';
import { getCurrentBlockInfo, getCurrentPriceInfo, justOpenDB, openDB, getP2PKHsJson, readSetting } from './buildShizzle.js';

const { initProviders, fetchUtxos } = require('./providers.js')


//====================================================================

// from https://stackoverflow.com/questions/2090551/parse-query-string-in-javascript
function getQueryVariable(variable) {
  console.log("gQV() for ", variable)
  var query = window.location.search.substring(1);
  console.log("gQV() query: ", query)
  var vars = query.split('&');
  console.log("gQV() vars: ", vars)

  for (var i = 0; i < vars.length; i++) {
      var pair = vars[i].split('=');
      if (decodeURIComponent(pair[0]) === variable) {
          console.log("gQV() returning something for " + variable + ": ", pair[1])
          return decodeURIComponent(pair[1]);
      }
  }
  console.log('Query variable %s not found', variable);
  return ''
}

class BitShizzleApp extends React.Component {
  constructor(props) {
    super(props);

    this.BitSchnitzelLLC     = <>BitSchnitzel LLC</>;
    this.shizzleVerse        = <><b>ShizzleVerse</b><small>&trade;</small></>;
    this.bitShizzle          = <><b>BitShizzle</b><small>&trade;</small></>;
    this.bitShizzleItalic    = <><b>Bit<i>Shizzle</i></b><small>&trade;</small></>;
    this.shizzleVerseNotBold = <>ShizzleVerse<small>&trade;</small></>;
    this.bitShizzleNotBold   = <>BitShizzle<small>&trade;</small></>;

    //FIXME: the purples are currently duplicated in index.css
    this.bshzPurple             = '#8313e2';
    this.bshzYellow             = "#fff300";
    this.bshzLtPurp             = '#e7d0fb';
    this.bshzLightYellow        = "#fffccc";


    this.handleSearchString         = this.handleSearchString.bind(this);
    this.dbStuff                    = this.dbStuff.bind(this);
    this.finishAppSetup             = this.finishAppSetup.bind(this);
    this.couldNotFindDB             = this.couldNotFindDB.bind(this);
    this.handleDbWritingNotOk       = this.handleDbWritingNotOk.bind(this);

    this.sendEmail                  = this.sendEmail.bind(this);
    this.changeCitizenLevel         = this.changeCitizenLevel.bind(this);
    this.checkCitizenLevel          = this.checkCitizenLevel.bind(this);
    this.getOfficialWalletRecord    = this.getOfficialWalletRecord.bind(this);

    this.signInOutCallback          = this.signInOutCallback.bind(this);

    this.homePageFromLinkJumper     = this.homePageFromLinkJumper.bind(this);
    this.openHomePage               = this.openHomePage.bind(this);
    this.openPreOnRamp              = this.openPreOnRamp.bind(this);
    this.openShizzleNav             = this.openShizzleNav.bind(this);
    this.openShizzleView            = this.openShizzleView.bind(this);
    this.openOnRamp                 = this.openOnRamp.bind(this);
    this.openSurferFromHome         = this.openSurferFromHome.bind(this);
    this.openSurferFromOnRamp       = this.openSurferFromOnRamp.bind(this);

    this.receiveBackgroundRunnerRef = this.receiveBackgroundRunnerRef.bind(this);

    this.checkNetworkAtUserRequest  = this.checkNetworkAtUserRequest.bind(this);
    this.periodicCallFromBRunner    = this.periodicCallFromBRunner.bind(this);

    this.handleNetworkError         = this.handleNetworkError.bind(this);
    this.openOver                   = this.openOver.bind(this);
    this.closeOver                  = this.closeOver.bind(this);
    this.getOverScreenRef           = this.getOverScreenRef.bind(this);

    this.setWalletModal             = this.setWalletModal.bind(this);
    this.handleWalletModalOk        = this.handleWalletModalOk.bind(this);

    this.returnBundle               = this.returnBundle.bind(this);
    this.userRemembersPwd           = this.userRemembersPwd.bind(this);
    this.userDeletedWallet          = this.userDeletedWallet.bind(this);

    this.rejectionHandler           = this.rejectionHandler.bind(this);

    this.recordBalance              = this.recordBalance.bind(this);
    this.recordBalanceCallback      = this.recordBalanceCallback.bind(this);
    this.recordBalanceFromOnRamp    = this.recordBalanceFromOnRamp.bind(this);

    // callback function
    this.setBalanceForOnRamp = null

    // WARNING: side-effects of overriding the current blockHeight:
    //          - price is queried only once (at startup)
    //          - blockHeight stays FIXED at that override value
    const blockHeightOverrideParam = getQueryVariable("blockHeightOverride")  // overrides Actual, and Hardcoded blockHeights when building

    this.balancePennies      = 0.0
    this.bestUtxoPennies     = 0.0

    this.authToken = ''

    console.log("APP: window.theApp: ", window.theApp)
    console.log("APP: window.location: ", window.location)
    console.warn("APP: window.location.search: ", window.location.search)

    // do this so we can easily handle network errors from anywhere
    // FIXME: set this up in an environment-agnostic way (for Node.js too)
    window.theApp = this
    console.log("NOW: window.theApp: ", window.theApp)

    const windowURL = window.location.href // returns the absolute URL of a page
    let giftParam = getQueryVariable("gift")  // triggers giftRedeemerModal
    const search = window.location.search
    const pathName = window.location.pathname
    console.warn("APP: our URL (may be useful for innerFrame logic): ", windowURL)
    console.warn("APP: search: ", search)
    console.warn("APP: pathname: ", pathName)

    let tempParam = getQueryVariable("onramp")
    console.log("THE - tempParam (onramp) is ", tempParam)
    if ( (typeof tempParam != 'undefined' && tempParam !== null && tempParam !== '') ) {
      console.log("tempParam (onramp) is ACTIVE. tempParam: ", tempParam)
    } else {
      tempParam = ''
    }

    const authToken = getQueryVariable("authToken")
    const appId     = getQueryVariable("appId")

    if ( pathName === '/hcashAgree' && authToken !== '' && pathName === '/hcashAgree' && appId !== '' ) {
      console.error("Found a login success re-direct with authToken. pathName: ", pathName)
      console.error("    appId: ", appId)
      //window.alert("Found a login success re-direct with authToken, appId")

      //save authToken in state?
      // This is set once, when INITIALLY rendering the page
      // no need to update/re-render. It won't update
      this.authToken = authToken
    }

    const windowPathname = window.location.pathname
    const pathSplit = windowPathname.split('/')

    console.log("APP: window pathname split : ", pathSplit)

    let minerAddress = ''
    let initialViewMode = 0
    let linkJumpPath = ''
    let linkJumpRHS=''
    let idbPermsRequired = true
    if ( search.startsWith("?j=") || search.startsWith("?jump=") ) {
      console.warn("We're being asked to jump to a domain")
      let {mode, parts, rhs} = this.handleSearchString(search)

      console.warn("ivm: ", mode)
      console.warn("parts: ", parts)
      console.warn("rhs: ", rhs)

      initialViewMode = 200 //mode
      linkJumpPath = parts
      linkJumpRHS = rhs

      idbPermsRequired = false

    } else
    if ( search.startsWith("?url") ) {
      console.log("  query url is ", search)
      const firstE = search.indexOf("=")
      console.log("firstE is ", firstE)
    } else if ( search.startsWith("?ad") ) {
      console.log("using query param to select AD")
      console.warn("init 16")
      initialViewMode = 16
    } else if ( pathSplit[1] === 'view' ) {
      console.warn("init 1")
      initialViewMode = 1
    } else if ( pathSplit[1] === 'nav' ) {
      console.warn("init 3")
      initialViewMode = 3
    } else if ( pathSplit[1] === 'ramp' ) {
      console.warn("init 6")
      initialViewMode = 6
    } else if ( pathSplit[1] === 'ad' ) {
      console.warn("init 16")
      initialViewMode = 16
    } else if ( pathSplit[1] === 'miner' || search.startsWith("?miner") ) {

      minerAddress = getQueryVariable("miner")
      console.warn("miner address; ", minerAddress)
      console.warn("init 100")
      initialViewMode = 100
    }

    console.warn("APP:   initial 'viewMode': " + initialViewMode)

    this.state = {

      idbPermsRequired: idbPermsRequired,  // if link-jumping, we don't REQUIRE idb

      linkJumpPath: linkJumpPath,
      linkJumpRHS: linkJumpRHS,

      network:  't',
      mountFunder: false,
      citizenLevel: 0, // increases as the user fulfils 3 steps: generate wallet, fund it, claim an asset
      // cycle through four values - to avoid ever having both
      // ShizzleView and ShizzleNav instantiated at the same time
      //   0: ShizzleView
      //   1: transitioning to ShizzleNav
      //   2: ShizzleNav
      //   3: transitioning to ShizzleView
      masterViewMode: initialViewMode,

tempParam: tempParam,

      backgroundRunner: null,

      blockHeight: 0,           // gets set in componentDidMount(), then periodically
      blockHeightOverride: blockHeightOverrideParam,

      showOver: false,
      overRef: null,

      lastPriceQueryBlockHeight: 0,   //helps limit how often we query for price
      bsvMarketPriceUSD: 0.0,

      showWalletModal: false,
      showVerifier: false,

      giftParam: giftParam,
      minerAddress: minerAddress,

      haveIdbPermission: false,
      showDbTerms: false,
      showFarewell: false,
    };

    // cache of UTXOs
    window.localUTXOs = []

  } // constructor()

  // parse the url search string - to understand what the user requires
  // Initially:
  //     ?j={domain}/{ownerCount}/{quarterlyCount}/U/{periodCount}/{transient5Count}/{transient4Count}/{transient3Count}/{transient2Count}/{transient1Count}
  handleSearchString(search) {
    const equalsIndex = search.indexOf("=")
    console.warn("search string:", search)
    console.warn("equalsIndex = ", equalsIndex)
    console.warn("substring():", search.substring(equalsIndex+1))
    console.warn("substr():", search.substr(equalsIndex+1))

    const rhs = search.substr(equalsIndex+1)
    const parts = rhs.split("/")
    console.warn("rhs parts: ", parts)

    return { mode: 0, parts: parts, rhs: rhs} // default to home, for now
  }

  openShizzleNav() {
    console.warn("BitShizzleApp INITIATING switch to shizzleNav (unmounting shizzleView)")
    this.setState({ masterViewMode: 2 })
  }

  openShizzleView() {
    console.warn("BitShizzleApp INITIATING switch to shizzleView (unmounting shizzleNav)")
    this.setState({ masterViewMode: 4 })
  }

  homePageFromLinkJumper() {
    this.setState({idbPermsRequired: true, masterViewMode: 0}, this.dbStuff)   //openHomePage(null, null, true, false)})
  }
  //NOTE the unmentioned part: we drop the citizen level
  openHomePage(e, v, fromAd = false, dropCitizenLevel = false) {
    console.warn("BitShizzleApp INITIATING switch to shizzleHome (unmounting whatever) dropCitizenLevel: ", dropCitizenLevel)

    if ( fromAd ) {
      console.log("APP: openHomePage(): setting masterViewMode to 0")
      this.setState({ masterViewMode: 0 })
    } else if ( dropCitizenLevel ) {
      console.log("APP: openHomePage(): setting masterViewMode to -1 (came from surfer), citizenLEvel to 1")
      this.setState({ masterViewMode: -1, citizenLevel: 1 })
    } else {
      console.log("APP: openHomePage(): setting masterViewMode to -1 (came from surfer)")

      //WARNING: this used to be 0
      this.setState({ masterViewMode: -1 })
    }
  }

  //FIXME: use 'this' instead of self
  openPreOnRamp(self, dropCitizenLevel = true) {

    console.warn("BitShizzleApp INITIATING pre-switch to onRamp (unmounting whatever)")

    if ( dropCitizenLevel ) {
      self.setState({ masterViewMode: 5, citizenLevel: 1 })
    } else {
      self.setState({ masterViewMode: 5})
    }
  }

  openOnRamp() {
    console.warn("BitShizzleApp INITIATING switch to onRamp (unmounting whatever)")
    this.setState({ masterViewMode: 6 })
  }

  openSurferFromHome(domain, ownerCount, fromGiftRedeemer = false) {
    console.warn("BitShizzleApp INITIATING switch to surfer (unmounting whatever) - with domain " + domain + ", ownerCount " + ownerCount)
    if ( fromGiftRedeemer ) {
      this.setState({ masterViewMode: 8, surfDomain: domain, ourOwnerCount: ownerCount, surfFrom: 'home', mountFunder: true })
    } else {
      this.setState({ masterViewMode: 8, surfDomain: domain, ourOwnerCount: ownerCount, surfFrom: 'home' })
    }
  }
  openSurferFromOnRamp(domain, ownerCount) {
    console.warn("BitShizzleApp INITIATING switch to surfer (unmounting whatever) - with domain " + domain + ", ownerCount " + ownerCount)
    this.setState({ masterViewMode: 8, surfDomain: domain, ourOwnerCount: ownerCount, surfFrom: 'onRamp' })
  }

  // Take note when BackgroundRunner has mounted
  receiveBackgroundRunnerRef(ref) {
    console.warn("BitShizzleApp  receiveBackgroundRunnerRef(): ", ref)

    this.setState({backgroundRunner: ref})
  }

  // us is ref to this
  registerBRunnerClient(us, clientRef, name) {
    console.warn("registerBRunnerClient(): new client: " + name)

    const bg = us.state.backgroundRunner

    console.warn("registering client " + name)
    bg.addBackgroundRunnerClient(bg, clientRef, name)
  }

  // us is ref to this
  unregisterBRunnerClient(us, clientRef, name) {
    console.warn("unregisterBRunnerClient(): REMOVING client: " + name)

    const bg = us.state.backgroundRunner

    console.warn("UNregistering client " + name)
    bg.removeBackgroundRunnerClient(bg, clientRef, name)

    // Now that one client has been removed,
    // initiate MOUNTING of the other
    let viewMode = us.state.masterViewMode

    console.log("view mode WAS " + viewMode)
    viewMode = viewMode + 1                    // original intent was to toggle between view and nav
    console.log("view mode IS NOW " + viewMode)

    if ( viewMode === 5 ) {
      console.log("Wrapping view mode back to 1")
      viewMode = 1
    }

    us.setState({masterViewMode: viewMode})
  }


  // called by onRamp or surfer - giving us a 'setBalanceForOnRamp()' callback
  recordBalanceCallback(parent, callbackFunction) {
    console.warn("APP: setting callback function to tell onramp or surfer the balance")
    this.setBalanceForOnRamp = callbackFunction

    console.warn("APP: recordBalanceCallback: BTW: balance: " + this.balancePennies)
    callbackFunction(this.balancePennies, this.bestUtxoPennies)
  }

  // WalletModal told us the balance (in US pennies)
  recordBalance(balance, bestUtxoPennies) {

    console.warn("BitShizzleApp: got balance report of " + balance + " US pennies (from WalletModal)")

    this.balancePennies = balance
    this.bestUtxoPennies = bestUtxoPennies

    if ( this.setBalanceForOnRamp ) {
      console.log("reporting balance to onramp (or SURFER)... (a callback to client-registered function)")
      this.setBalanceForOnRamp( balance, bestUtxoPennies )
    } else {
      console.error("We couldn't tell onramp the balance, because we're not there. It has yet to give us a callback.")
    }
  }

  recordBalanceFromOnRamp(balance, bestUtxoPennies) {
    console.warn("onramp told us the balance. We'll hold onto this.")
    this.balancePennies = balance
    this.bestUtxoPennies = bestUtxoPennies
  }

  // Moved from shizzleHome, so it could also be used by anything with a Footer
  async sendEmail(userEmail, optionalFeedback) {
    const url = "/subscribe/"
    const postBody = JSON.stringify({
        "email": userEmail,
        "from": "homepage",
        "feedback": optionalFeedback
    })
    console.warn("sending/POSTing with userEmail: ", userEmail)

    console.warn("sending/POSTing to url: ", url)
    console.warn("sending/POSTing this subscription request: ", postBody)

    //NOTE: is there a limitation with fetch() - can't XS post?
    //      We do fine elsewhere - reaching WoC
    const response = await fetch(url, {
        method: 'POST',
        //mode: 'cors',
        headers: {
            'Content-type': 'application/json'
        },
        body: postBody
    });

    console.warn("Posted email address")
    let sendSucceeded = false
    try{
        const resData = await response.json();
        console.log("BTW: response.status: ", response.status)
        console.log("BTW: response.json: ", resData)
        console.log("BTW: response: ", response)
        sendSucceeded = true
    } catch (anError ) {
        console.warn("Error sending to server: ", anError)
        // open special modal to let user know it failed at server
//FIXME: need ServerErrorModal
//        this.setState({showServerErrorModal: true})
    }

    return sendSucceeded
  }

  //    0: user has no wallet
  //    1: user has a wallet (official wallet/address)
  //    2: password verified (logged-in)
  //    3: user wallet has sufficient balance
  //    4: user has claimed asset(s)
  //FIXME: rename to checkCitizenShouldBeLevel1()?
  async checkCitizenLevel() {

    const db = await openDB();
    // check if there's any 'official' wallet PKH/address (and on which network)
    const p2pkhJSON = await getP2PKHsJson(db, true)

    let json
//FIXME: try/catch
    if ( p2pkhJSON?.length > 0 ) {
      json = JSON.parse( p2pkhJSON )
    } else {
      return 0
    }

    console.warn("checkCitizenLevel(): Here's the official JSON we see: ", p2pkhJSON, " <----")

    let found = false
    for ( var i = 0; i < json.length; i++ ) {
      console.log("  entry " + i + ": ", json[i])
      if ( json[i].network === 't' ) {
        console.warn("checkCitizenLevel(): FOUND entry with network 't'! address: ", json[i].address)
        found = true
      }
    }
    if ( found )
      return 1
    else
      return 0
  }

  async getOfficialWalletRecord() {
    console.warn("getOfficialWalletRecord(): here we go...")

    const db = await openDB();
    // check if there's any 'official' wallet PKH/address (and on which network)
    const p2pkhJSON = await getP2PKHsJson(db, true)

    let json
//FIXME: try/catch
    if ( p2pkhJSON?.length > 0 ) {
      json = JSON.parse( p2pkhJSON )
    } else {
      return null
    }

    console.warn("getOfficialWalletRecord(): Here's the official JSON we see: ", p2pkhJSON, " <----")

    let entryWeFound = null
    for ( var i = 0; i < json.length; i++ ) {
      console.log("  entry " + i + ": ", json[i])
      if ( json[i].network === 't' ) {
        console.warn("getOfficialWalletRecord(): FOUND entry with network 't'! address: ", json[i].address)

        entryWeFound = json[i]//.address
        break
      }
    }

    if ( entryWeFound !== null ) {
        // NOTE: fetchUTXOs() ALSO checks window.localUTXOs, but,
        //       we don't want to overwrite window.localUTXOs blindly
        //       so, we check here too
        console.warn("Is NOW a good time to INITIALIZE window.localUTXOs?")
        if ( window.localUTXOs.length !== 0 ) {
          console.warn("    localUTXOs ALREADY has values: ", window.localUTXOs)
        } else {
          console.warn("    window.localUTXOs[] is empty, so, let's initialize with a fresh scan.  entryWeFound: ", entryWeFound)
          const utxos = await fetchUtxos(entryWeFound.address, true, true, "app's getOfficialWalletRecord")

          // LOAD persisted FROZEN utxos
          const frozenUtxos = await getFrozenUtxos()

          console.warn("NOTE: we intend to mark " + frozenUtxos.length + " utxos as frozen...")
          console.log("       frozenUtxos: ", frozenUtxos)
          console.log("       fetched utxos: ", utxos)

          // Mod FRESH utxos with FROZEN
          for ( const freezie of frozenUtxos) {
            let found = false
            for ( const utxo of utxos ) {
              if ( utxo.txId === freezie.txId && utxo.outputIndex === freezie.outputIndex ) {
                utxo.FROZEN = true
                found = true
                console.warn("Found and handled one frozen utxo: " + freezie.txId + " : " + freezie.outputIndex)
                break
              }
            }
            if ( !found ) {
              console.warn("NOTE: We didn't find this IDB-cached frozen utxo in a fresh query: ", freezie)
              console.log("Most likely, it was spent/used it from another device/browser.")
              alert("NOTE: We didn't find this IDB-cached 'frozen' coin (of " + freezie.satoshis
                  + " sats) in a fresh query of your wallet:\n\n"
                  + freezie.txId + " : " + freezie.outputIndex
                  + "\n\nMost likely, you spent/used it from another device/browser."
                )
              // REMOVE this mystery utxo from IDB-cached list of frozen coins
              // It must've been spent from another device/browser
              await persistUtxoUnFrozen(freezie)
            }
          }

          console.log("    here are the utxos to add: ", utxos)
          for ( const utxo of utxos) {
            window.localUTXOs.push(utxo)
            console.log("      added one...")
          }

          console.warn("    NOW localUTXOs: ", window.localUTXOs)
        }
    } else {
      console.error("Found no wallet entry.")
    }

    return entryWeFound
  }

  // 0: has no wallet               (meaning nothing in IDB)
  // 1: has a wallet                (IDB now has an 'officialP2pkhTab' entry)
  // 2: password entered/verified   (unlocked)
  // 3: good wallet                 sufficient funds (NOTE: we could know this much earlier, but that changes this order)
  // 4: user owns an asset
  //FIXME: change to calculateCitizenLevel()
  //       call something to CHECK on (read) IDB for OFFICIAL key entry
  async changeCitizenLevel(level) {
    console.log("changeCitizenLevel(): setting level to ", level, " - from ", this.state.citizenLevel)
    this.setState({citizenLevel: level})
  }

  async componentWillUnmount() {
    window.removeEventListener("unhandledrejection", this.rejectionHandler);
  }

  rejectionHandler(event) {
      console.warn(`UNHANDLED PROMISE REJECTION: ${event.reason}`);
      console.warn('more info: ', event)
      //event.preventDefault();

      if ( event.reason.message.includes("Network Error") ) {
        alert("We've experienced a Network Error. Please check your connection")

        //FIXME: this let's the user know WHY things aren't working (no network connection).
        //       They will still need to refresh once the issue clears.
        //       IOW: we don't currently handle most network errors cleanly.
        this.handleNetworkError()   // slide-out a modal

      } else {
        alert("UPR. reason: " + event.reason)
      }
      //alert("UPR. prevent default")
      //throw new Error("hmmm?")
      event.preventDefault()
  }

  async componentDidMount() {

    // Transaction Providers: WhatsOnChain, Bitails, and one day JungleBus (mainnet only)
    initProviders()

    // check with user - if they might accidentally go back, or away
    // We may have had trouble with this on FireFox. Don't recall
//    window.onbeforeunload = function() { return "Your work will be lost."; };

    window.addEventListener("unhandledrejection", this.rejectionHandler);

    // Detect if device is mobile or desktop
    //
    // see: https://vhudyma-blog.eu/detect-mobile-device-in-react/
    let hasTouchScreen = false;
    if ("maxTouchPoints" in navigator) {
      hasTouchScreen = navigator.maxTouchPoints > 0;
    } else if ("msMaxTouchPoints" in navigator) {
      hasTouchScreen = navigator.msMaxTouchPoints > 0;
    } else {
      const mQ = window.matchMedia && matchMedia("(pointer:coarse)");
      if (mQ && mQ.media === "(pointer:coarse)") {
        hasTouchScreen = !!mQ.matches;
      } else if ("orientation" in window) {
        hasTouchScreen = true; // deprecated, but good fallback
      } else {
        // Only as a last resort, fall back to user agent sniffing
        var UA = navigator.userAgent;
        hasTouchScreen =
          /\b(BlackBerry|webOS|iPhone|IEMobile)\b/i.test(UA) ||
          /\b(Android|Windows Phone|iPad|iPod)\b/i.test(UA);
      }
    }

    this.isMobile = hasTouchScreen;

    if ( !this.state.idbPermsRequired ) {
      console.error("APP: componentDidMount(): IDB perms not required. breaking here")
      return
    }

    await this.dbStuff()
  }

  async dbStuff() {

    console.log("APP: componentDidMount(): We're about to check for an IDB...")

    const dbName = IDB_NAME //'BitShizzle7';

    try {
      const isExisting = (await window.indexedDB.databases()).map(db => db.name).includes(dbName);
      if ( isExisting ) {
        console.log("old-school detection: EXISTS")
      } else {
        console.log("old-school detection: does NOT exist. Let's get permission to write to it. window: ", window)
        this.setState({showDbTerms: true})
        return
      }

    } catch (error) {
      // FIREFOX doesn't like window.indexedDB.database()
      // see: https://stackoverflow.com/questions/22956440/cannot-open-indexeddb-in-firefox
      // and: https://ckon.wordpress.com/2016/02/14/mutation-operation-error/
      //
      // This will detect it for firefox:
      if (window.indexedDB) {
        console.log("Your (mozilla?) environment supports IndexedDB. Will now check for the BitShizzle IDB...");

        const justDBExists = await justOpenDB()
        console.error("componentDidMount() FF: justDBExists: ", justDBExists)

        if ( !justDBExists ) {
          console.warn("IDB supported, but our IDB does NOT yet exist. Let's ask for permission...")
          this.couldNotFindDB()
        } else {
          console.log("IDB supported, AND our DB exists. Will finish-up...")
          await this.finishAppSetup()
        }

      } else {
        // Indexed DB is not supported

        console.error("ERROR: old-school detection: does NOT exist. Nor does window.indexedDB. We think your browser doesn't support indexedDB.")

        alert("ERROR: We think your browser doesn't support IndexedDB - which is necessary to use this site")

        //this.setState({showDbTerms: true})
      }

      return
    } // catch

    console.log("APP: cDM() moving on - because IDB exists (we must have already gotten permission to create, and write to it).")
    console.warn("moving on. (non-mozilla) normal case)")
    await this.finishAppSetup()
  } // componentDidMount

  couldNotFindDB() {
    console.error("We couldn't find the BitShizzle IDB. We'll ask for permission...");
    this.setState({showDbTerms: true})
  }

  // only run if user has given permission to write to IDB
  async finishAppSetup() {
    console.warn("finishAppSetup(): IDB EXISTS (version 1/10) opening IDB...")
    const db = await openDB();
    console.warn("finishAppSetup(): db opened: ", db)

    let cLevel
    try {
      cLevel = await this.checkCitizenLevel()
    } catch (error) {
      console.error("IDB is probably blank for this site/version. We'll ask for PERMISSION to write to the IDB...")
      this.setState({showDbTerms: true})
      return
    }
    console.warn("bitShizzleApp  finishAppSetup(): setState of citizenLevel to " + cLevel)

    // setting state.haveIdbPermission to true will cause:
    //   - IDB permission modal to NOT display
    //   - display/mount the 'normal' components, including the <BackgroundRunner>
    // Once the <BackgroundRunner> has mounted, it'll begin periodic calls (immediately)
    this.setState({citizenLevel: cLevel, showDbTerms: false, haveIdbPermission: true})
  }

  handleDbWritingNotOk() {
    console.error("handleDbWritingNotOk(): the user has declined to grant IDB-writing permission")
    this.setState({showFarewell: true, showDbTerms: false})
  }

  async checkNetworkAtUserRequest() {
    // if it returns true, the network condition has been resolved
    return await this.periodicCallFromBRunner(this.state.backgroundRunner, false)
  }

  // we'll use this to slide-away the overScreen when the network condition clears
  getOverScreenRef(overRef) {
    console.warn("App: got ref to overScreen <---")
    this.setState({overRef: overRef})
  }

  handleNetworkError() {
    console.error("SKIPPING NETWORK-ERROR HANDLING")
    return
    if ( this.state.backgroundRunner ) {

      const bgRunner = this.state.backgroundRunner

      // Detemine if the network condition has CHANGED (if it HAD been clear)
      if ( ! bgRunner.reviewNetworkStatus() ) {
        // new event - network error
        bgRunner.setNetworkError() // this will shorten the background check period to 10secs, from 60secs
        this.openOver()
      } else {
        console.log("Nothing new. There's STILL a network error")
      }

    } else {
      console.error("it's too early to handle a network error - we don't yet have a bgRunner")
      alert("ERROR: it's too early to handle a network error - we don't yet have a bgRunner")
    }
  }

  async periodicCallFromBRunner(bgRunner, queryPrice = true) {

    let networkCleared = false

      console.warn("periodicCallFromBRunner(): APP can/should periodically do things NOW.")

      //let queryPrice = true
      let currentBlockHeight

      try {

        console.warn("pCFBR(): this.state.blockHeightOverride: ", this.state.blockHeightOverride)

        // empower query param '?blockHeightOverride' to override the querying-for-blockHeight
        // from providers, and use the provided query param value instead
        if ( this.state.blockHeightOverride !== '' /*|| this.blockHeightOverride !== ''*/ ) {
          console.warn("state.blockHeightOverride = ", this.state.blockHeightOverride)
          console.warn("typeof state.blockHeightOverride = ", (typeof this.state.blockHeightOverride))

          currentBlockHeight = parseInt( this.state.blockHeightOverride )
          console.error("setting currentBlockHeight to OVERRIDDEN value: ", currentBlockHeight)
        } else {
          currentBlockHeight = await getCurrentBlockInfo()
          console.error("setting currentBlockHeight to value: ", currentBlockHeight)
        }

        // network connection fine. Check if it HAD been in error (condition has changed)
        if ( bgRunner.reviewNetworkStatus() ) {
          console.warn("APP: network issue now cleared. CLOSING overscreen...")
          networkCleared = true

          bgRunner.clearNetworkError()

          // start sliding-away the overScreen
          // Once it's DONE sliding away, it'll let us know,
          // and ask us to fully close/remove it
          //FIXME: say something?
          if ( this.state.overRef ) {
            console.error("Network now clear, so, narrowing (hiding) overscreen")
            this.state.overRef.narrowScreen()
          }

        }

        console.log("APP BTW: we've got the up-to-date block height: " + currentBlockHeight)

        if ( currentBlockHeight < this.state.lastPriceQueryBlockHeight + 72 ) {
          console.warn("periodicCallFromBRunner(): won't query price yet. blocks: ", currentBlockHeight)
          console.warn("periodicCallFromBRunner():   last queried at: ", this.state.lastPriceQueryBlockHeight)
        } else {
          console.warn("It's time to query for price")
          queryPrice = true
        }

        if ( currentBlockHeight < this.state.blockHeight ) {
            alert("WARNING: blockHeight provider MIGHT be experiencing a re-org.\n\nOld height: "
                    + this.state.blockHeight + "  Newly-reported height: " + currentBlockHeight)
            //FIXME: allow user to acknowledge a re-org at some point
        } else {
            this.setState({blockHeight: currentBlockHeight})
        }
      } catch (error) {
        console.warn("APP periodicCallFromBRunner(): Trouble getting current block: ", error)

        // detect network issues - alert sub-modules
        if ( error.toString().includes("Network Error") ) {
          //alert("NEW LOGIC: immediately detect network issues. Light-up an alarm")
          queryPrice = false

          console.warn("informing backgroundRunner... ")
          console.log(" bg: ", bgRunner)

          this.handleNetworkError()
        }
      }

      // if we're overriding the blockHeight, we *might* need
      // to ensure that we query the price at least once
      if ( queryPrice || this.state.lastPriceQueryBlockHeight === 0 ) {
        try {
          const currentPriceInfo = await getCurrentPriceInfo()

          //console.error("APP BTW: we've got the BlockChair up-to-date price info: ", currentPriceInfo)
          //console.error("APP BTW: data: ", currentPriceInfo.data)
          console.warn("APP BTW: price info: ", currentPriceInfo)

          this.setState({ bsvMarketPriceUSD: currentPriceInfo,
                          lastPriceQueryBlockHeight: currentBlockHeight})

        } catch (error) {
          console.error("APP periodicCallFromBRunner(): Trouble getting current price info: ", error)
          alert("TEMP CODE ALERT: trouble getting price info...")
        }
      }

      return networkCleared
  }

  kludge(showAd) {
    /*
    console.error("kludge: showAd? " + showAd)
    //WARNING: Kludge to remove the NORMAL css for shizzleAds
    //         ShizzleAd css is loaded first, so, normally gets overwritten
    const head = document.getElementsByTagName('HEAD')[0]
    // console.error("here's the head: ", head)
    const children = head.childNodes
    // console.error("Its children: ", children)
    for ( let i = 0; i < children.length; i++ ) {
      const childNodeName = children[i].nodeName
      // console.warn(" a child: ", childNodeName)
      if ( childNodeName === 'STYLE' ) {
        console.log("    style: ", children[i])
        const first = children[i].firstChild.nodeValue
        //console.log("    first element: ", first)
        if ( first.startsWith('uniqueNormal') && showAd ) {
          console.error("UNIQUE NORMAL - removing it <---")
          children[i].remove()
        } else if ( first.startsWith('uniqueAd') && !showAd ) {
          console.error("UNIQUE AD - removing it <---")
          children[i].remove()
        } else if ( first.startsWith('unique') ) {
          console.error("starts like this: ", first)
          console.error("showAd: ", showAd)
        }
      }
      
    }
/*
    //WARNING: Kludge to remove the NORMAL css for shizzleAds
    //         ShizzleAd css is loaded first, so, normally gets overwritten
    const head = document.getElementsByTagName('HEAD')[0]
    // console.error("here's the head: ", head)
    const children = head.childNodes
    // console.error("Its children: ", children)
    let uniqAd = null
    let preservedNorm = null
    let styleCount = 0
    let foundInCount = -1
    for ( let i = 0; i < children.length; i++ ) {
      const childNodeName = children[i].nodeName
      // console.warn(" a child: ", childNodeName)
      if ( childNodeName === 'STYLE' ) {
        styleCount++
        console.log("    style: ", children[i])
        const first = children[i].firstChild.nodeValue
        //console.log("    first element: ", first)
        if ( first.startsWith('uniqueAd') ) {
          console.error("UNIQUE Ad - soon, will be removing it <---")
          uniqAd = children[i]
          //children[i].remove()
          foundInCount = styleCount
        } else if ( first.startsWith('uniqueNormal') ) {
          console.error("UNIQUE NORMAL found. preserve? <---")
          preservedNorm = children[i]
        }
      }
    }
    if ( uniqAd !== null ) {
      if ( foundInCount === styleCount ) {
        console.error("REMOVING unique ad <---")
        head.removeChild( uniqAd )
        //uniqAd.remove()
        if ( preservedNorm !== null ) {
          console.error("restoring norm...")
          head.appendChild( preservedNorm )
        }
        const head2 = document.getElementsByTagName('HEAD')[0]
        console.error("here's the head NOW: ", head2)
      } else {
        console.warn("won't remove, since found in count " + foundInCount)
      }
    }
*/
  }

  openOver() {
    console.log("bshzApp: openOver(): setting showOver: true")
    this.setState({showOver: true})
  }
  closeOver() {
    console.log("bshzApp: closeOver(): setting showOver: false")
    this.setState({showOver: false})
  }

  setWalletModal() {
    console.warn("App: setWalletModal(): setting state: showWalletModal")
    console.log("App: btw: showVerifier: " + this.state.showVerifier)
    console.log("App: btw: mountFunder: " + this.state.mountFunder)

    this.setState({showWalletModal: true})
  }
  handleWalletModalOk() {
    console.log("app: close wallet modal")
    this.setState({showWalletModal: false, showVerifier: false})
  }

  // called by WalletModal (or via onRamp, shizzleHome, surfer)
  // Gives us the vital wallet info
  returnBundle(theBundle) {
    //console.error("Got a bundle: ", theBundle)
    //this.bundle = theBundle
    this.setState({bundle: theBundle})
  }

  async signInOutCallback(parent) {
    console.warn("APP: signInOutCallback(). parent: ", parent)
    console.warn("APP: signInOutCallback(). parent.state: ", parent.state)

    if ( this.state.citizenLevel === 1 ) {
        console.warn("APP: we should SHOW the verifier")
        this.setState({showVerifier: true})
    } else if ( this.state.citizenLevel > 1 ) {
        console.warn("APP: We should FORGET official key record, disappear the verifier, and unmount the walletModal (and its funder).")

        this.setState({showVerifier: false, mountFunder: false})

        await this.changeCitizenLevel(1)
    }
  }

  //adapted from onramp
  async userRemembersPwd() {
    console.log("APP: user has demonstrated knowledge of valid pwd")

    await this.changeCitizenLevel(2)
    this.setState({showVerifier: false, mountFunder: true})
  }
  async userDeletedWallet() {
    console.log("APP: user has DELETED his wallet")
    console.log("APP: props: ", this.props)
    await this.changeCitizenLevel(0)
    this.setState({showVerifier: false})
  }

  render() {
    console.log("app render()")
    // ***  DEVICE-DEPENDENT STYLING  ***
    // For mobile, "detect" landscape screen mode
    const landscape = this.isMobile ? window.innerWidth > window.innerHeight : false
    // For mobile, shrink the About, Security, and Glossary modals
    const modalClassName   = this.isMobile ?
                                    (landscape ?
                                            "modalMobileLandscape"
                                        :
                                            "modalMobilePortrait"
                                    )
                                :
                                    ""
    const modalContentClassName = this.isMobile ? "modalContentMobile" : ""
    const modalBottomClassName  = this.isMobile ? "modalBottomMobile"  : ""

    const showGeneric     = this.state.masterViewMode === 0
    const showShizzleView = this.state.masterViewMode === 1
    const showShizzleNav  = this.state.masterViewMode === 3
    const showOnRamp      = this.state.masterViewMode === 6
    const showSurfer      = this.state.masterViewMode === 8
    const showShizzleAd   = this.state.masterViewMode === 16
    const showMinerPage   = this.state.masterViewMode === 100
    const showLinkJumper  = this.state.masterViewMode === 200

    if ( showShizzleAd ) {
      console.error("will show AD for now. onRamp? ", showOnRamp)
    } else {
      console.warn("will show OTHER-THAN-AD for now. state.masterViewMode: ", this.state.masterViewMode)
    }

    const shzJump = showLinkJumper ?
                  <LinkJumper parent={this}
                              path={this.state.linkJumpPath}
                              rhs={this.state.linkJumpRHS}
                              isMobile={this.isMobile}

                              handleJumpToHome={this.homePageFromLinkJumper}
                            />
              :
                  null

    // Once the BackgroundRunner has mounted, instantiate ShizzleView, and ShizzleNav
    // They'll then, soon-after, register themselves with the backgroundRunner
    const shzView = this.state.backgroundRunner !== null && showShizzleView ?
                  <ShizzleView  parent={this}
                                blockHeight={this.state.blockHeight}
                                show={showShizzleView}
                                handleSwitchToShizzleNav={this.openShizzleNav}
                                registerClient={this.registerBRunnerClient}
                                unregisterClient={this.unregisterBRunnerClient}

                                guestPostWeAreAPartyToCallback={() => {alert("APP: shizzleView need not have a callback in this instance")}}
                                />
              :
                  null

    const shzNav = this.state.backgroundRunner !== null && showShizzleNav ?
                  <ShizzleNav   parent={this}
                                blockHeight={this.state.blockHeight}
                                show={showShizzleNav}
                                handleSwitchToShizzleView={this.openShizzleView}
                                registerClient={this.registerBRunnerClient}
                                unregisterClient={this.unregisterBRunnerClient}/>
              :
                  null

    const shzAd = showShizzleAd ?
                <ShizzleAd  parent={this}
                            show={showShizzleAd}
                            handleJumpToHome={this.openHomePage}
                            campaign={this.state.masterViewMode}/>
              :
                null


    const logInOutCallback = this.state.citizenLevel > 0 ?
                                        this.signInOutCallback
                                    :
                                        null
    // a null callback means the icon won't show in masthead
    const walletCallback = this.state.citizenLevel > 1 ?
                                        this.setWalletModal
                                    :
                                        null

    // note:
    // getOfficialWalletRecord used by GiftRedeemers's WalletVerifier
    // handleJumpToSurfer is used by GiftRedeemer as a final step
    const shzGen = showGeneric ?
                <>
                <ShizzleHome parent={this}
                             citizenLevel={this.state.citizenLevel}

                             checkCitizenLevel={this.checkCitizenLevel}
                             changeCitizenLevel={this.changeCitizenLevel}

                             tempParam={this.state.tempParam}
                             authToken={this.authToken}
                             show={showGeneric}
                             handleJumpToOnRamp={this.openOnRamp}
                             isMobile={this.isMobile}
                             sendEmail={this.sendEmail}

                             handleSignInOutClick={logInOutCallback}
                             handleWalletClick={walletCallback}

                             giftParam={this.state.giftParam}

                             getOfficialWalletRecord={this.getOfficialWalletRecord}
                             returnBundle={this.returnBundle}
                             handleJumpToSurfer={this.openSurferFromHome}

                             blockHeight={this.state.blockHeight}
                             bsvPriceUSD={this.state.bsvMarketPriceUSD}
                             />
                </>
              :
                null

    const shzOnRamp = showOnRamp ?
                <>
                <OnRamp parent={this}
                        show={showOnRamp}
                        citizenLevel={this.state.citizenLevel}
                        checkCitizenLevel={this.checkCitizenLevel}
                        changeCitizenLevel={this.changeCitizenLevel}
                        getOfficialWalletRecord={this.getOfficialWalletRecord}
                        isMobile={this.isMobile}
                        sendEmail={this.sendEmail}
                        bsvPriceUSD={this.state.bsvMarketPriceUSD}

                        returnBundle={this.returnBundle}

                        handleWalletClick={walletCallback}

                        bundle={this.state.bundle}

                        returnBalanceCallback={this.recordBalanceCallback}
                        returnBalanceToApp={this.recordBalanceFromOnRamp}

                        disableButtons={this.state.showWalletModal}

                        handleJumpToSurfer={this.openSurferFromOnRamp}

                        blockHeight={this.state.blockHeight}

                        handleJumpToHome={this.openHomePage}
                />
                </>
              :
                null

    const shzSurf = showSurfer ?
                <>
                  <Surfer parent={this}
                        show={showSurfer}
                        citizenLevel={this.state.citizenLevel}

                        changeCitizenLevel={this.changeCitizenLevel}
                        getOfficialWalletRecord={this.getOfficialWalletRecord}
                        isMobile={this.isMobile}
                        sendEmail={this.sendEmail}
                        bsvPriceUSD={this.state.bsvMarketPriceUSD}

                        handleWalletClick={walletCallback}

                        bundle={this.state.bundle}

                        returnBalanceCallback={this.recordBalanceCallback}

                        disableButtons={this.state.showWalletModal}

                        handleJumpBackToOnRamp={this.openPreOnRamp}

                        blockHeight={this.state.blockHeight}

                        ourDomain={this.state.surfDomain}
                        ourOwnerCount={this.state.ourOwnerCount}
                        surfFrom={this.state.surfFrom}
                        registerClient={this.registerBRunnerClient}
                        unregisterClient={this.unregisterBRunnerClient}
                        balancePennies={this.balancePennies}
                        bestUtxoPennies={this.bestUtxoPennies}

                        handleJumpToHome={this.openHomePage}
                  />
                </>
              :
                <></>

    //                       blockHeight={this.state.blockHeight}
    const maybeMinerPage = showMinerPage ?
                <>
                  <MinerPayments
                      isMobile={this.isMobile}
                      bsvPriceUSD={this.state.bsvMarketPriceUSD}
                      minerAddress={this.state.minerAddress}
                  />
                </>
              :
                <></>

    const maybeShowOverScreen = this.state.showOver ?
              <>
                <OverScreen self={this}
                            checkStatus={this.checkNetworkAtUserRequest}
                            giveRefToParent={this.getOverScreenRef}
                            closeOv={this.closeOver}
                            />
              </>
            :
              <></>

    // The walletModal has a verifier, and a funder.
    // If we need either, mount the WalletModal
    // NOTE: the APP delays mounting the funder (mountFunder) until the pwd is verified
    //       Annoyingly, this is to delay checking the funding until after pwd verification
    //       (to preserve the order of citizenship levels)
    const maybeWalletModal = this.state.citizenLevel > 0 && (this.state.showVerifier || this.state.mountFunder)?
        <>
        <WalletModal showWallet={this.state.showWalletModal}
                     closeModal={this.handleWalletModalOk}
                     isMobile={this.isMobile}

                     getOfficialWalletRecord={this.getOfficialWalletRecord}
                     citizenLevel={this.state.citizenLevel}
                     changeCitizenLevel={this.changeCitizenLevel}

                     bsvPriceUSD={this.state.bsvMarketPriceUSD}
                     bundle={this.state.bundle}

                     showVerifier={this.state.showVerifier}
                     returnBundle={this.returnBundle}
                     userRemembersPwd={this.userRemembersPwd}
                     mountFunder={this.state.mountFunder}
                     userDeletedWallet={this.userDeletedWallet}

                     returnBalance={this.recordBalance}

                     blockHeight={this.state.blockHeight}
                    />
        </>
          :
        <></>

    // The BackgroundRunner gets instantiated before the two views
    const rendering = this.state.haveIdbPermission ?
      <>
        <BackgroundRunner runnerCheckInterval={RUNNER_INTERVAL_MS}
                          networkCheckInterval={10000}
                          periodicCall={this.periodicCallFromBRunner}
                          handleRef={this.receiveBackgroundRunnerRef}
                          />

        {shzAd}
        {shzView}
        {shzNav}

        {shzJump}

        {shzOnRamp}
        {shzSurf}
        {shzGen}

        {maybeWalletModal}

        {maybeShowOverScreen}

        {maybeMinerPage}
      </>
    :
      this.state.idbPermsRequired ?
      // no IDB-writing permission
      <>
        <Modal size='small' open={this.state.showDbTerms} dimmer='blurring' className={modalClassName} style={{backgroundColor: this.bshzPurple, borderRadius: '20px'}}>
            <Modal.Header style={{textAlign: 'center', backgroundColor: this.bshzPurple, borderRadius: '20px'}}>
                <span style={{color: this.bshzYellow}}>BitShizzle needs permission</span>
            </Modal.Header>
            <Modal.Content scrolling style={{backgroundColor: this.bshzLtPurp}} className={modalContentClassName}>

            <div>
              <b style={{color:"blue"}}>Welcome</b>. In order for this site (<span style={{color:"blue"}}>{this.bitShizzle}</span>) to
              work as a Bitcoin browser, we need to keep track of preferences, settings, and Bitcoin blockchain data that we'll
              fetch. To do this, we need your permission to save information to your browser database.
              <br></br>
              <br></br>
              We <b>don't</b> send this information anywhere.
              <br></br>
              <br></br>
              These are <b>not</b> cookies that are used to track you. We <b>don't</b> use cookies, and we <b>don't</b> track you.
              <br></br>
              <br></br>
              Our server only does two things:<br></br>
			        &nbsp; 1) send this site to your browser (and we've already done that)<br></br>
			        &nbsp; 2) accept feedback from you if you click to ‘Contact’ us at the bottom of the page
            </div>
            <br></br>
            <div style={{color:"blue", fontSize:"1.3rem"}}>
              May we write to your browser database?
              <br></br>
              <br></br>
            </div>
            <div>
              <Button  positive onClick={this.finishAppSetup} >
                YES. It's okay to write to my browser database.
              </Button>
              <Button  negative onClick={this.handleDbWritingNotOk} >
                NO, thanks. I'm done here.
              </Button>
            </div>

          </Modal.Content>
        </Modal>

        <Modal size='small' open={this.state.showFarewell} dimmer='blurring' className={modalClassName} style={{backgroundColor: this.bshzPurple, borderRadius: '20px'}}>
            <Modal.Header style={{textAlign: 'center', backgroundColor: this.bshzPurple, borderRadius: '20px'}}>
                <span style={{color: this.bshzYellow}}> Farewell <Icon name='frown' size='large' style={{color:"yellow"}}/> </span>
            </Modal.Header>
            <Modal.Content scrolling style={{backgroundColor: this.bshzLtPurp}} className={modalContentClassName}>

            We're sorry.<br></br><br></br>

            <div>
              If you ever change your mind, feel free to come back at anytime to learn about the {this.bitShizzle} Bitcoin browser.
            </div>

          </Modal.Content>
        </Modal>

      </>
    :
      <>
        {shzJump}
      </>
    return ( rendering );

  }
}
export default BitShizzleApp;
