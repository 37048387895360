import React from 'react';

import { Button, Checkbox, Divider, Form, Icon, Input, Modal } from 'semantic-ui-react'

import './index.css';

import Toast    from './toast';

import  {
            saveSetting,
            readSetting,
            deleteSetting,

            openDB,
            decryptData,

            deleteEncryptedWallet,      // official
            deleteEncryptedP2PKHKeys,   // builder payout
            deleteEncryptedKeys,        // publisher rabins, and builder rabins

            deleteAllDialogs,

            deleteAllDomainsOfMine,
            deleteBuiltDomains,

            getRabinsJson,
        } from './buildShizzle';

import {
    resetAll,
} from "./shizzleIDB"

class WalletVerifier extends React.PureComponent {
    constructor(props) {
        super(props);

        // will use this to force focus when mounted
        //this.readyButton = React.createRef();

        this.bitShizzle          = <><b>BitShizzle</b><small>&trade;</small></>;

        //FIXME: the purples are currently duplicated in index.css
        this.bshzPurple             = '#8313e2';
        this.bshzYellow             = "#fff300";
        this.bshzLtPurp             = '#e7d0fb';
        this.bshzLightYellow        = "#fffccc";

        this.state = {
            understandsWalletRiskBoxChecked: false,
            pwd:                    '',
            showPasswordInputField: false,

            showGreatModal:         false,
            showDeleteModal:        false,

            showResetModal:         false,

            isAnExpert:             false,

            showQuickToast:         false,
            toastText:              '',
            toastDuration:          800,
            suppressLoader:         false,
        }

        this.setWalletRiskUnderstoodSetting = this.setWalletRiskUnderstoodSetting.bind(this);
        this.acknowledgePasswordTerms       = this.acknowledgePasswordTerms.bind(this);
        this.handlePwdChange                = this.handlePwdChange.bind(this)
        this.useEnteredPasswordToVerify     = this.useEnteredPasswordToVerify.bind(this);
        this.closeGreatModal                = this.closeGreatModal.bind(this);
        this.getWalletRiskUnderstoodSetting = this.getWalletRiskUnderstoodSetting.bind(this);

        this.handleDeleteClick              = this.handleDeleteClick.bind(this);
        this.deleteTheWallet                = this.deleteTheWallet.bind(this);
        this.cancelDelete                   = this.cancelDelete.bind(this);

        this.handleEraseEverythingClick     = this.handleEraseEverythingClick.bind(this);
        this.closeFullReset                 = this.closeFullReset.bind(this);
        this.performFullReset               = this.performFullReset.bind(this);

        this.modExpertSetting               = this.modExpertSetting.bind(this);

        this.copyWalletAddressToClipboard   = this.copyWalletAddressToClipboard.bind(this);

        this.quickToast                     = this.quickToast.bind(this);
        this.quickToastDone                 = this.quickToastDone.bind(this);
    }

    // step 1 of 3 (the 'I agree' checkbox)
    async setWalletRiskUnderstoodSetting() {

        console.warn("understands wallet risk   OLD check value (about to be toggled): ", this.state.understandsWalletRiskBoxChecked)

        // IF this checkbox is toggled to true, we advance to step 2 (the 'I understand' button is enabled)
        const newValue = !this.state.understandsWalletRiskBoxChecked
        this.setState({understandsWalletRiskBoxChecked: newValue})

        // here, save 'false' setting to IDB - ONLY on checkbox un-click
        if ( !newValue ) {
            const db = await openDB()

            console.log("UNsetting walletRiskUnderstood")
            await saveSetting(db, "walletRiskUnderstood", false)

            this.setState({showPasswordInputField: false})
        } else {

            //NEW BEHAVIOR: don't save 'walletRiskUnderstood' setting as 'true' until user ALSO clicks the 'I understand' button,
            //              which we've just-now enabled (indirectly) via state.understandsWalletRiskBoxChecked

        }

    }

    async modExpertSetting() {
        // we read this in componentDidMount()
        //const settingRecord = await readSetting(await openDB(), "isAnExpert")
        //console.log("modExpertSetting(): current/old isAnExpert record: ", settingRecord)

        const db = await openDB()
        if ( !this.state.isAnExpert ) {
            console.log("setting isAnExpert to true")
            await saveSetting(db, "isAnExpert", true)
            this.setState({ isAnExpert: true })
        } else {
            console.log("setting isAnExpert to false")
            await saveSetting(db, "isAnExpert", false)
            this.setState({ isAnExpert: false })
        }
    }

    async getWalletRiskUnderstoodSetting() {

        //FIXME: check the date. compare to here-hardcoded latest revision to policy

        const settingRecord = await readSetting(await openDB(), "walletRiskUnderstood")
        console.log("walletRiskUnderstood record: ", settingRecord)

        return settingRecord
    }

    async componentDidMount() {

        const walletRecord = await this.props.getOfficialWallet();
        console.log("official wallet record: ", walletRecord)
        console.log("official wallet address: ", walletRecord.address)
        this.setState({officialAddress: walletRecord.address})


        const settingRecord = await this.getWalletRiskUnderstoodSetting()
        console.log("wallet verifier: componentDidMount() wallet risk understood record: ", settingRecord)
        if ( settingRecord === null ) {
            console.log("null setting for walletRiskUnderstood record")
            this.setState({understandsWalletRiskBoxChecked: false})
        } else {
            if ( settingRecord.value ) {
                this.setState({understandsWalletRiskBoxChecked: true, showPasswordInputField: true})

                //NOTE: this only works on the first try (when mounted)
                //this.readyButton.current.focus();

                // focus on password input field
                //HACK: delay first - to let input field be enabled
                //     this.pwdInput.focus()
                setTimeout(() => this.pwdInput.focus(), 10)
            } else {
                this.setState({understandsWalletRiskBoxChecked: false})
            }
        }

        const expertRecord = await readSetting(await openDB(), "isAnExpert")
        console.log("componentDidMount(): current isAnExpert record: ", settingRecord)
        if ( expertRecord === null ) {
            console.log("null setting for isAnExpert record")
            this.setState({isAnExpert: false})
        } else {
            this.setState({isAnExpert: expertRecord.value})
        }

        document.getElementById('verifyButton')?.scrollIntoView({
            behavior: 'smooth'
        })
    }

    // show a 'toast' for a specified period
    quickToast(toastText, duration=2000, withoutLoader = false) {
        console.warn("quickToast()")
        this.setState({showQuickToast: true, toastText: toastText, toastDuration: duration, suppressLoader: withoutLoader})
        // once expired, quickToastDone() will be called
    }
    // called-back by toast, once it's expired
    quickToastDone(self) {
        self.setState({showQuickToast: false, suppressLoader: false})

        // now that it's expired, do NOTHING
    }
    async copyWalletAddressToClipboard() {
        try {
            await navigator.clipboard.writeText(this.state.officialAddress);

            this.quickToast("Address copied to clipboard", 900, true)
        } catch (error ) {
            alert("SORRY. We couldn't copy your wallet address.\nPlease copy it MANUALLY - then paste and save it somewhere.")
        }
    }

    //NOTE: this was later adapted/used in walletImporter
    async useEnteredPasswordToVerify(event) {
        event.preventDefault();

        // temp instrumentation to double-check that all keys can be decrypted
        const checkEachKey = false

        const walletRecord = await this.props.getOfficialWallet();

        console.warn("useEnteredPasswordToVerify(): attempt to decrypt wallet. record: ", walletRecord)

        try {
            const plainBundle = await decryptData(walletRecord.blob, this.state.pwd);

            //console.warn("useEnteredPasswordToVerify(): plainBundle", plainBundle)

            // .p,   .address
            const bundledObject = JSON.parse(plainBundle);

            bundledObject.pwd = this.state.pwd

            this.props.returnBundle( bundledObject )

            //console.warn("useEnteredPasswordToVerify(): bundledObject", bundledObject)

            //FIXME: anything else to check? verify the priv hashes to address? needed?

            if ( !this.state.isAnExpert ) {
                this.setState({showGreatModal: true})
            }

            if ( checkEachKey ) {
                console.log("Let's get the rabin records...")
                const rabinRecords = await getRabinsJson(await openDB());
                console.log("here is the RABIN record content string: ", rabinRecords)

                let numAllocated = 0
                let numNotAllocated = 0
                const contents = JSON.parse(rabinRecords)
                contents.forEach(element => {
                    console.log("element.pkh: ", element.pkh);
                    //console.log("element.blob: ", element.blob);
                    console.log("element.alloc: ", element.alloc);
                    if ( element.alloc === 1 ) {
                        numAllocated++
                    } else {
                        numNotAllocated++
                    }
                })
                console.log("num allocated: " + numAllocated)
                console.log("num NOT allocated: " + numNotAllocated)
                alert("WalletVerifier(): check console")

                let goodDecrypts = 0
                let badDecrypts = 0
                for ( var j = 0; j < contents.length; j++ ) {
                    //const pkh = contents[j].pkh;
                    console.warn("try to decrypt rabin " + j + " of " + contents.length + "....")

                    // does this pkh entry already have a record? Don't overwrite
        //            let x = await getEncryptedKeysFromPKH(db, pkh);
        //            console.log("record for given pkh (", pkh, "): ", x);
        /*
                    if ( x && x !== null ) {
                      console.log("There's already an entry for pkh " + pkh + ". SKIPPING");
                      alert("skipping?")
                    } else {
                      console.log("IMPORTING record for pkh " + pkh);
                      saveEncryptedKeys(db, pkh, contents[j].blob, variant, contents[j].alloc);
                      successfulImports++;
        */
                    try {
                        //FIXME: this requires the WALLET pwd - not the FILE pwd
                        const plainBundle = await decryptData(contents[j].blob, this.state.pwd);

                        //console.log("importRabinKeys(): decrypted json string:", plainBundle);
        
                        const bundledObject = JSON.parse(plainBundle);
                        //console.log("decrypted bundle: ", bundledObject)
                        goodDecrypts++
                    } catch (error) {
                        console.error("importRabinKeys(): Bad decrypt. Probably provided wrong password.");
                       // alert("trouble while importing a Publisher key")
                        //break;
                        badDecrypts++
                    }

            
        //            }
                }
                console.error("Good decrypts: " + goodDecrypts)
                console.error("Bad decrypts: " + badDecrypts)
                alert("Done checking ALL keys (allocated and not)")
            }

            if ( this.state.isAnExpert ) {
                // Since the user has entered his password, scroll down for more options
                window.scrollBy(0, 300)

                await this.props.userRemembersPwd();
            }

        } catch (error) {
            console.warn("Wrong password? ", error)
            this.props.showOkModal("Wrong Password", <>You've entered the <span style={{color: "red"}}>wrong password</span> for
                                                        your browser wallet, OR, <span style={{color: "red"}}>you're not using
                                                        https://</span>. Please check, and try again.</>)
            this.setState({pwd: ''});
        }
    }

    async closeGreatModal() {
        this.setState({showGreatModal: false})

        // Since the user has entered his password, scroll down for more options
        window.scrollBy(0, 300)

        await this.props.userRemembersPwd();
    }

    // step 2 of 3 (the 'I understand' button)
    async acknowledgePasswordTerms() {
        console.log("acknowledgePasswordTerms(): enable password field")

        // NEW BEHAVIOR:
        //   If user checked 'I agree', and then clicked the "I understand" button,
        //   we set the 'walletRiskUnderstood' setting, and enable the password field.

        //FIXME: rename to enablePasswordField
        this.setState({showPasswordInputField: true})

        const db = await openDB()
        console.log("setting walletRiskUnderstood")
        await saveSetting(db, "walletRiskUnderstood", true)

        // User pressed "I understand..." button, so, set focus on the password input field
        this.pwdInput.focus()
    }

    handlePwdChange(event) {
        //console.log("handlePwdChange(): pwd now set to ", event.target.value);

        this.setState({pwd: event.target.value});

        document.getElementById('verifyButton')?.scrollIntoView({
            behavior: 'smooth'
        })
    }

    handleEraseEverythingClick() {
        this.setState({showResetModal: true})
    }
    closeFullReset() {
        this.setState({showResetModal: false})
    }
    performFullReset() {
        try {
            // DON'T check with user - if they want to be careful about reloading.
            // RELOAD no-matter what (further down)
            window.onbeforeunload = null;

            resetAll()
            alert("We've removed everything our site had saved to your browser. If you've got a backup, you should be able to restore it at any time. If you're done using BitShizzle, you should close this browser tab.")

            // closes modal
            this.closeFullReset()

            // reset citizenLevel back to 0
            this.props.finishReset()

            // force reload - since we've wiped the IDB
            // (clear all state)
            window.location.reload()
        } catch (error) {
            console.error("Trouble performing full IndexedDB reset: ", error)
            alert("We had a problem resetting/erasing your browser's Indexed DB")
        }
    }

    handleDeleteClick() {
        //alert("Implement: Delete Wallet")
        this.setState({showDeleteModal: true})
    }

    async deleteTheWallet() {
        try {
            const db = await openDB();

            // delete 'official' funding key
            await deleteEncryptedWallet(db)

            // also delete builder payout keys
            await deleteEncryptedP2PKHKeys(db)

            // also delete rabinTab entries
            await deleteEncryptedKeys(db)

            // also delete BUILDER rabin entries (builderRabinTab)
            //await deleteEncryptedKeys(db, "builder")

            await deleteAllDomainsOfMine(db)

            await deleteBuiltDomains(db)

            await deleteAllDialogs(db)

            await deleteSetting(db, "activeDialogs")
            await deleteSetting(db, "dialogsToRespondTo")
            await deleteSetting(db, "dialogsYetToHearBackFrom")
            await deleteSetting(db, "closedDialogs")

            alert("Your wallet has been deleted. You may now create (or import/restore) a new wallet.")

            this.props.userDeletedWallet()
        } catch (error) {
            console.error("Trouble deleting wallet: ", error)
            alert("We had a problem deleting your wallet")
        }

        this.setState({showDeleteModal: false})
    }
    cancelDelete() {
        this.setState({showDeleteModal: false})
    }

    render() {

        // copied from onRamp:
        // ***  DEVICE-DEPENDENT STYLING  ***
        // For mobile, "detect" landscape screen mode
        const landscape = this.props.isMobile ? window.innerWidth > window.innerHeight : false
        // For mobile, shrink the About, Security, and Glossary modals
        const modalClassName   = this.props.isMobile ?
                                        (landscape ?
                                                "modalMobileLandscape"
                                            :
                                                "modalMobilePortrait"
                                        )
                                    :
                                        ""
        const modalContentClassName = this.props.isMobile ? "modalContentMobile" : ""
        const modalBottomClassName  = this.props.isMobile ? "modalBottomMobile"  : ""


        const disableCheckButton = this.state.pwd.length < 8 || !this.state.understandsWalletRiskBoxChecked || !this.state.showPasswordInputField;

        // step 3 of 3 (the password)
        const pwdFormColor = this.state.showPasswordInputField ? 'black' : 'grey'
        const passwordForm =
                <>
                    <Form style={{color:pwdFormColor}}>
                        Please enter your password (at least 8 chars):<br></br>
                        <Input value={this.state.pwd} style={{width:'300px'}}
                                placeholder="Enter your wallet password"
                                onChange={this.handlePwdChange} autoFocus
                                disabled={!this.state.showPasswordInputField}>
                            <input  ref={(input) => { this.pwdInput = input; }}
                                    style={{borderRadius: '50px'}} />
                        </Input>
                        <br></br>
                        <Button id='verifyButton' disabled={disableCheckButton} field="checkButton" onClick={this.useEnteredPasswordToVerify} positive>
                            VERIFY my password
                        </Button>
                    </Form>
                </>

        const warningColor = this.state.understandsWalletRiskBoxChecked ?
                                "blue"
                            :
                                "red"
        const expertColor = this.state.isAnExpert ? "black" : "#b0b0b0"
        const verifierText =
                <>
                    <p>
                        You've created a browser wallet, but, before we can fund it, we need to verify that you've properly recorded your password. Without a properly-recorded
                        password, you'll never be able to actually <i>use</i> your wallet. And keep in mind that {this.bitShizzle} never has access to
                        your password. <b>It never leaves your browser</b>, so, we can't ever help you decrypt your wallet should you forget or lose your password.
                    </p>
                    <p> <span style={{color: "red"}}><b>WARNING: </b></span>
                        Your encrypted wallet is stored in <b>this browser</b>. Unless you copy it to another device or browser,
                        you'll only be able to access it from <b>this</b> particular browser on <b>this device</b>. And unless you copy it to another device,
                        if this device is ever lost or damaged, you'll lose access to your Bitcoin, and any assets you've claimed, <b>forever</b>.
                    </p>
                    <p>
                        <span style={{color: "red"}}><b>WARNING: </b></span>Do not put more than a small amount of Bitcoin in this wallet
                        (worth no more than $3 USD). It is experimental, and could lose your funds <b>forever</b>.
                    </p>
                    <p>
                        <span style={{color: "red"}}><b>WARNING: </b></span>If you use your device to visit sketchy sites, you risk the
                        security of your wallet.
                    </p>
                    <Divider />
                    <span style={{color: warningColor}}>By checking this box, you certify that you've read the warnings above, and you agree that you
                        understand that {this.bitShizzle} does NOT have your password, nor wallet keys, and so {this.bitShizzle} can't ever help
                        you recover a password, nor wallet key.</span> <br></br>
                    <div style={{textAlign: 'center'}}>
                        <Checkbox label='I agree' checked={this.state.understandsWalletRiskBoxChecked} onClick={this.setWalletRiskUnderstoodSetting}/>
                            &nbsp; &nbsp; &nbsp;
                        <Button  ref={this.readyButton} color='green' onClick={this.acknowledgePasswordTerms} disabled={!this.state.understandsWalletRiskBoxChecked || this.state.showPasswordInputField}>
                            <span style={{color: 'white'}}>
                                I understand no one can help with my password
                            </span>
                        </Button>
                    </div>
                    {passwordForm}

                    <Divider />

                    If you've lost the keys to your old wallet, and you'd like to create a
                    new one, first, you'll need to delete your old wallet.
                    <br></br>

                    <Button color="grey" onClick={this.handleDeleteClick}
                            disabled={!this.state.understandsWalletRiskBoxChecked}>
                        <span style={{color: 'white'}}>
                            Delete Wallet
                        </span>
                    </Button>
                    &nbsp; &nbsp; <span style={{color: "red"}}>This <b>cannot</b> be undone</span>
                    <br></br>
                    <br></br>
                    If you wish to perform a Full Reset (removing EVERYTHING {this.bitShizzle} has saved to your browser),
                    you can do that as well.
                    <br></br>
                    <Button color="grey" onClick={this.handleEraseEverythingClick}
                            disabled={!this.state.understandsWalletRiskBoxChecked}>
                        <span style={{color: 'white'}}>
                            FULL Reset
                        </span>
                    </Button>
                    &nbsp; &nbsp; <span style={{color: "red"}}>This <b>cannot</b> be undone</span>

                    <Divider />

                    <span style={{color: expertColor}}>
                        I am an advanced user, and don't need much help navigating around. &nbsp; &nbsp;
                        <Checkbox label='Expert User' checked={this.state.isAnExpert} onClick={this.modExpertSetting}/>
                    </span>
                </>

        const maybeQuickToast = this.state.showQuickToast ?
                <>
                <Toast  durationMillis={this.state.toastDuration}
                    displayText={this.state.toastText}
                    noLoader={this.state.suppressLoader}
                    done={this.quickToastDone}
                    parent={this}/>
                </>
            :
                <></>

        const wocUrl = "https://test.whatsonchain.com/address/" + this.state.officialAddress;
        // avoid tab-napping
        // see: https://stackoverflow.com/questions/17711146/how-to-open-link-in-new-tab-on-html#17711167
        const wocLink = <a href={wocUrl} target="_blank" rel="noopener noreferrer" >{this.state.officialAddress}</a>

        return(
                <>
                    {verifierText}

                    {maybeQuickToast}

                    <Modal size='small' centered className={modalClassName}  open={this.state.showGreatModal} style={{backgroundColor: this.bshzPurple, borderRadius: '20px'}}>
                        <Modal.Header style={{textAlign: 'center', backgroundColor: this.bshzPurple, borderRadius: '20px'}}>
                            <span style={{color: this.bshzYellow}}> Correct Password </span>
                        </Modal.Header>
                        <Modal.Content className={modalContentClassName} scrolling style={{backgroundColor: this.bshzLtPurp}}>

                            <span style={{color: "blue", fontSize: "2rem"}}>Great!</span>
                            <p>We were able to decrypt your wallet using your password. Now you can put away your written-down password - somewhere safe.</p>
                            <p>Your wallet address is <b>{wocLink}</b> &nbsp; <Icon name='clone outline' onClick={this.copyWalletAddressToClipboard}/></p>
                            <p>This address is not a secret. It's okay if others see it.</p>
                            <p>Now it's time for the next step: <b>funding</b> your wallet</p>

                        </Modal.Content>
                        <Modal.Actions className={modalBottomClassName} style={{backgroundColor: this.bshzPurple, borderRadius: '0px 0px 20px 20px'}}>
                            <div style={{textAlign: 'center'}}>
                                <Button positive onClick={this.closeGreatModal} content='OK' autoFocus/>
                            </div>
                        </Modal.Actions>
                    </Modal>

                    <Modal size='small' centered className={modalClassName}  open={this.state.showDeleteModal} style={{backgroundColor: this.bshzPurple, borderRadius: '20px'}}>
                        <Modal.Header style={{textAlign: 'center', backgroundColor: this.bshzPurple, borderRadius: '20px'}}>
                            <span style={{color: this.bshzYellow}}> Delete My Wallet </span>
                        </Modal.Header>
                        <Modal.Content className={modalContentClassName} scrolling style={{backgroundColor: this.bshzLtPurp}}>

                            <p></p>
                                <span style={{color: "red", fontSize: "2rem"}}>
                                    Are you <b>sure</b>?
                                </span>
                            <p></p>

                            Are you <b>sure</b> you want to delete your wallet? If you haven't already backed it up,
                            you'll <b style={{color: "red"}}>never</b> be able to restore it, and spend the coins it contains.

                        </Modal.Content>
                        <Modal.Actions className={modalBottomClassName} style={{backgroundColor: this.bshzPurple, borderRadius: '0px 0px 20px 20px'}}>
                            <div style={{textAlign: 'center'}}>
                                <Button negative onClick={this.cancelDelete} content='Never Mind'/>
                                <Button negative onClick={this.deleteTheWallet} content='I Understand. DELETE my wallet.'/>
                            </div>
                        </Modal.Actions>
                    </Modal>


                    <Modal size='small' centered className={modalClassName}  open={this.state.showResetModal} style={{backgroundColor: this.bshzPurple, borderRadius: '20px'}}>
                        <Modal.Header style={{textAlign: 'center', backgroundColor: this.bshzPurple, borderRadius: '20px'}}>
                            <span style={{color: this.bshzYellow}}> Erase EVERYTHING - Full Reset </span>
                        </Modal.Header>
                        <Modal.Content className={modalContentClassName} scrolling style={{backgroundColor: this.bshzLtPurp}}>

                            <p></p>
                                <span style={{color: "red", fontSize: "2rem"}}>
                                    Are you <b>sure</b>?
                                </span>
                            <p></p>

                            Are you <b>sure</b> you want to erase EVERYTHING from {this.bitShizzle} - including any wallet? If you haven't already backed it up,
                            you'll <b style={{color: "red"}}>never</b> be able to restore it, and spend the coins it contains, nor use any domains/assets you've claimed.

                        </Modal.Content>
                        <Modal.Actions className={modalBottomClassName} style={{backgroundColor: this.bshzPurple, borderRadius: '0px 0px 20px 20px'}}>
                            <div style={{textAlign: 'center'}}>
                                <Button negative onClick={this.closeFullReset} content='Never Mind'/>
                                <Button negative onClick={this.performFullReset} content='I Understand. Delete EVERYTHING.'/>
                            </div>
                        </Modal.Actions>
                    </Modal>
                </>
        )
    }
}

export default WalletVerifier;
