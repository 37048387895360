import React from 'react';

import { Button, Checkbox, Divider, Form, Icon, Input, Modal, Radio, Segment } from 'semantic-ui-react'

import './index.css';

import { readSetting, saveSetting, openDB } from './buildShizzle.js';

import {  satsFromMilliPennies, calcPenniesFromSats, calcFromMilliPennies } from './commonReact';


class FeeSettingsModal extends React.PureComponent {
    constructor(props) {
        super(props);

        this.handleModalOk                    = this.handleModalOk.bind(this);
        this.adjustGuestPostPrice             = this.adjustGuestPostPrice.bind(this)
        this.adjustFeeThreshold               = this.adjustFeeThreshold.bind(this)

        this.closeGuestPostPriceAdjustorModal = this.closeGuestPostPriceAdjustorModal.bind(this);
        this.closeFeeThresholdModal           = this.closeFeeThresholdModal.bind(this);

        this.setNewFeeThreshold               = this.setNewFeeThreshold.bind(this);
        this.setNewGPPrice                    = this.setNewGPPrice.bind(this);

        this.handleFeeLimitChange             = this.handleFeeLimitChange.bind(this);

        this.handleGPPriceChange              = this.handleGPPriceChange.bind(this);
        this.handlePermissionChange           = this.handlePermissionChange.bind(this);

        this.BitSchnitzelLLC     = <>BitSchnitzel LLC</>;
        this.shizzleVerse        = <><b>ShizzleVerse</b><small>&trade;</small></>;
        this.bitShizzle          = <><b>BitShizzle</b><small>&trade;</small></>;
        this.bitShizzleItalic    = <><b>Bit<i>Shizzle</i></b><small>&trade;</small></>;
        this.shizzleVerseNotBold = <>ShizzleVerse<small>&trade;</small></>;
        this.bitShizzleNotBold   = <>BitShizzle<small>&trade;</small></>;

        //FIXME: the purples are currently duplicated in index.css
        this.bshzPurple             = '#8313e2';
        this.bshzYellow             = "#fff300";
        this.bshzLtPurp             = '#e7d0fb';
        this.bshzLightYellow        = "#fffccc";

        this.GUEST_POSTS_PROHIBITED = 4294967295  // aka 4-byte max-int

        const penniesPerSat = this.props.bsvPriceUSD / 1000000
        console.log("US PENNIES per sat: " + penniesPerSat)
        console.log("sats per US Penny: " + 1000000 / this.props.bsvPriceUSD)

        this.state = {
            showFeeThresholdAdjustmentModal: false,
            showGuestPostPriceAdjustmentModal: false,

            allowGuestPosts: false,
            guestPostPriceForThisIdentity: this.GUEST_POSTS_PROHIBITED,
            gpPrice: this.GUEST_POSTS_PROHIBITED,   // the TEMPORARY value while user is considering adjusting the setting

            maxMilliPenniesToComment: 0,
            tempMaxToComment: 0,                    // the TEMPORARY value while user is considering adjusting the setting

            invalidLimit: false,
            invalidPrice: false,
        }
    }

    async componentDidMount() {

        console.warn("CommentFeeThresholdModal componentDidMount()")

        let guestPostPriceForThisIdentity
        let maxMilliPenniesToComment

        const gpp2= await readSetting(await openDB(), "gpPriceFor_" + this.props.siteName + "_" + this.props.siteOwnerCount)
        const gpp = await readSetting(await openDB(), "gpPriceFor_" + this.props.siteName)
        const mmptc = await readSetting(await openDB(), "maxMilliPenniesToComment")

        let somethingUninitialized = false
        if ( (gpp2 === null && gpp === null) || mmptc === null ) {
            somethingUninitialized = true
        }

        // we're *migrating* to use a more-concise gpPriceFor_{site}_{siteOwnerCount}
        if ( gpp2 === null ) {

            if ( gpp === null ) {
                console.warn("You haven't declared how much others must pay to comment on YOUR site."
                    + "\n\nWe'll declare it PROHIBITED for now.")

                guestPostPriceForThisIdentity = this.GUEST_POSTS_PROHIBITED;
            } else {
                guestPostPriceForThisIdentity = gpp.value
            }
        } else {
            guestPostPriceForThisIdentity = gpp2.value
        }

        if ( mmptc === null ) {
            console.warn("You haven't declared how much you're willing to pay to comment on another site."
                + "\n\nWe'll set it to ZERO, for now."
            )

            maxMilliPenniesToComment = 0
        } else {
            maxMilliPenniesToComment = mmptc.value
        }

        const allowGuestPosts = guestPostPriceForThisIdentity !== this.GUEST_POSTS_PROHIBITED
        this.setState({
                        somethingUninitialized:   somethingUninitialized,

                        maxMilliPenniesToComment: maxMilliPenniesToComment,
                        tempMaxToComment:         maxMilliPenniesToComment,

                        guestPostPriceForThisIdentity: guestPostPriceForThisIdentity,
                        gpPrice: guestPostPriceForThisIdentity,  // temp - for changes
                        allowGuestPosts: allowGuestPosts,
                    })
    }

    // needed because we may call handleNoClaim() from componentWillMount(), above
    componentWillUnmount() {
        console.log("commentFeeThresholdModal about to unmount. ")

        // fix Warning: Can't perform a React state update on an unmounted component
        // see: https://stackoverflow.com/questions/53949393/cant-perform-a-react-state-update-on-an-unmounted-component
        this.setState = (state,callback)=>{
            return;
        };
    }

    async handleModalOk() {
        const db = await openDB()

        await saveSetting(db, "gpPriceFor_" + this.props.siteName + "_" + this.props.siteOwnerCount, this.state.guestPostPriceForThisIdentity)
        await saveSetting(db, "maxMilliPenniesToComment", this.state.maxMilliPenniesToComment)

        this.props.closeMe()
    }

    adjustFeeThreshold() {
        console.log("adjustFeeThreshold()")
        this.setState({showFeeThresholdAdjustmentModal: true})
    }
    closeFeeThresholdModal() {
        this.setState({showFeeThresholdAdjustmentModal: false})
    }
    handleFeeLimitChange(event) {
        const re = /^[0-9\b]+$/;
        console.log("fee limit input: " + event.target.value)
        if (event.target.value === '' || re.test(event.target.value)) {
          if ( event.target.value.length < 11 && Number(event.target.value) < 4294967295) {
            const lenLessThan1 = event.target.value.length < 1
            this.setState({tempMaxToComment: event.target.value,
                invalidLimit: lenLessThan1,
            })
          }
        }
    }
    setNewFeeThreshold() {
        console.log("setNewFeeThreshold()")
        this.setState({ maxMilliPenniesToComment: this.state.tempMaxToComment,
                        somethingUninitialized: false
                })
        this.closeFeeThresholdModal()
    }

    adjustGuestPostPrice() {
        console.log("adjustGuestPostPrice")
        let gpPrice
        if ( !this.state.allowGuestPosts ) {
            gpPrice = this.GUEST_POSTS_PROHIBITED
        } else {
            gpPrice = this.state.guestPostPriceForThisIdentity
        }
        this.setState({showGuestPostPriceAdjustmentModal: true,
                    gpPrice: gpPrice})
    }
    closeGuestPostPriceAdjustorModal() {
        this.setState({showGuestPostPriceAdjustmentModal: false})
    }
    handleGPPriceChange(event) {
        const re = /^[0-9\b]+$/;
        console.log("input: " + event.target.value)
        if (event.target.value === '' || re.test(event.target.value)) {
          if ( event.target.value.length < 11 && Number(event.target.value) < 4294967295) {
            const lenLessThan1 = event.target.value.length < 1
            this.setState({gpPrice: event.target.value,
                invalidPrice: lenLessThan1,
            })
          }
        }
    }
    setNewGPPrice() {
        console.log("setNewGPPrice()")

        if ( this.state.allowGuestPosts ) {
            this.setState({ guestPostPriceForThisIdentity: this.state.gpPrice,
                            somethingUninitialized: false
                        })
        } else {
            this.setState({ guestPostPriceForThisIdentity: this.GUEST_POSTS_PROHIBITED,
                            somethingUninitialized: false
                        })
        }
        this.closeGuestPostPriceAdjustorModal()
    }

    /**
     * When checking/un-checking the 'allow guest-posts' box - in the adjustor
     */
    handlePermissionChange (event, data) {
        const field = data.field;
        const value = data.value;
        const checked = data.checked;

        console.log("handlePermissionChange(): allow guest posts: " + checked)

        const newPrice = checked ? 1000 : this.GUEST_POSTS_PROHIBITED

        this.setState({ allowGuestPosts: checked,
                        gpPrice: newPrice
        });

    }

    render() {
        console.warn("CommentFeeThresholdModal  render()")

        // ***  DEVICE-DEPENDENT STYLING  ***
        // For mobile, "detect" landscape screen mode
        const landscape = this.props.isMobile ? window.innerWidth > window.innerHeight : false
        // For mobile, shrink the About, Security, and Glossary modals
        const modalClassName   = this.props.isMobile ?
                                        (landscape ?
                                                "modalMobileLandscape"
                                            :
                                                "modalMobilePortrait"
                                        )
                                    :
                                        ""
        const modalContentClassName = this.props.isMobile ? "modalContentMobile" : ""
        const modalBottomClassName  = this.props.isMobile ? "modalBottomMobile"  : ""


        const extraMention = this.state.guestPostPriceForThisIdentity === this.GUEST_POSTS_PROHIBITED ?
                        <></>
                    :
                        <>
                            {calcPenniesFromSats(this.state.guestPostPriceForThisIdentity, this.props.bsvPriceUSD)}
                        </>
        const gpPriceMention = this.state.guestPostPriceForThisIdentity === this.GUEST_POSTS_PROHIBITED ?
                        <>
                            visitors are <span style={{color: "red", fontSize: "1.4rem",}}>prohibited</span> from posting
                        </>
                    :
                        <>
                            <span style={{color: "blue", fontSize: "1.4rem",}}>{this.state.guestPostPriceForThisIdentity}</span> satoshis
                            <br></br>
                            ({extraMention})
                        </>

        let gpPriceEquiv = <></>
        let gpAdjustor = <>hmm</>
        if ( this.state.showGuestPostPriceAdjustmentModal ) {
            let gpProhibited = this.state.gpPrice === this.GUEST_POSTS_PROHIBITED

            if ( !gpProhibited ) {

                console.log("penniesPerSat: " + this.props.bsvPriceUSD / 1000000)
                console.log("satsPerPenny: " + 1000000 / this.props.bsvPriceUSD)

                gpPriceEquiv = <>({calcPenniesFromSats(this.state.gpPrice, this.props.bsvPriceUSD)})</>

            } else {
                console.log("Guest-posting will be prohibited")
            }

            const adjustorColor1 = this.state.allowGuestPosts ? "blue" : 'grey'
            const adjustorColor2 = this.state.allowGuestPosts ? "black" : 'grey'
            const adjustorValue = this.state.allowGuestPosts ? this.state.gpPrice : ''
            gpAdjustor =
                    <>
                        <div style={{color: adjustorColor1}}> &nbsp; How many satoshis must <b>visitors pay you</b> for each post on your domain?
                                <br></br>
                                &nbsp; &nbsp; <Input type="text" value={adjustorValue}
                                        disabled={!this.state.allowGuestPosts}
                                        onChange={this.handleGPPriceChange}
                                        style={{width:"125px"}}/> <span style={{color: adjustorColor2}}>satoshis &nbsp; &nbsp;{gpPriceEquiv}</span>
                        </div>
                    </>
        }

        let limitPriceEquiv = <></>
        let roughLimit = <></>
        if ( this.state.showFeeThresholdAdjustmentModal) {
            limitPriceEquiv = <>{calcFromMilliPennies(this.state.tempMaxToComment, this.props.bsvPriceUSD)}</>
        } else {
            roughLimit = <>{calcFromMilliPennies(this.state.maxMilliPenniesToComment, this.props.bsvPriceUSD)}</>
        }

        const introWords = this.state.somethingUninitialized ?
                            <>
                                Before you can chat with others, we need to know your
                            </>
                        :
                            <>
                                Your ability to chat with others is affected by your
                            </>
        const firstTimeMention = this.props.firstTime ?
                <>
                    <div style={{fontSize:"1.6rem", color:"blue"}}>
                        <b style={{color:"red"}}>Right now</b> is an important time to review your Fee Settings
                    </div>
                    Partly because one of the settings cannot be changed for 'a while', after
                    your second post on a site, it's best to review the settings <b>before</b> your
                    second post on a site - particularly the <span style={{color:"blue"}}>"price others must pay"</span> setting.
                    <Divider />
                </>
            :
                <></>
        return(
                <>
                    <Modal size='small' centered className={modalClassName}  open={true} style={{backgroundColor: this.bshzPurple, borderRadius: '20px'}}>
                        <Modal.Header style={{textAlign: 'center', backgroundColor: this.bshzPurple, borderRadius: '20px'}}>
                            <span style={{color: this.bshzYellow}}> Chatting with Others </span>
                        </Modal.Header>
                        <Modal.Content className={modalContentClassName} scrolling style={{backgroundColor: this.bshzLtPurp}}>
                            {firstTimeMention}
                            <div>
                                {introWords} <b style={{color:"blue"}}>Commenting/Posting Fee Preferences</b>.
                                There are two of them.
                                <br></br>
                                <br></br>
                                <div style={{padding: "10px"}}>
                                    <Segment style={{backgroundColor: "#f8f0f8"}}>
                                    What will you charge for others to post on <b>your</b> domain, <span style={{color:"blue"}}><b>'{this.props.siteName.toUpperCase()}'</b></span>?
                                    <div style={{padding: "10px"}}>
                                        <b style={{fontSize: "1.4rem"}}>The price others must pay: &nbsp;</b>{gpPriceMention}
                                        <br></br>
                                        <button onClick={this.adjustGuestPostPrice} style={{backgroundColor: this.bshzLtPurp}}>Adjust this setting </button>
                                        <br></br>
                                        (NOTE: changes to this setting may take a long while to take effect)
                                    </div>
                                    </Segment>
                                </div>
                                <br></br>
                                <div style={{padding: "10px"}}>
                                    <Segment style={{backgroundColor: "#f8f0f8"}}>
                                    What is the <b>most</b> you'd be willing to pay
                                    for the privilege of commenting/posting on the sites of <b>others</b>? This setting is
                                    meant to help prevent you from accidentally spending a lot of money at a site that
                                    charges an exorbitant amount to post.
                                    <div style={{padding: "10px"}}>
                                        <b style={{fontSize: "1.4rem"}}>The max price you would pay: &nbsp;</b><span style={{color: "blue", fontSize: "1.4rem",}}>{roughLimit}</span>
                                        <br></br>
                                        ({this.state.maxMilliPenniesToComment} milli-pennies (US), or, {satsFromMilliPennies(this.state.maxMilliPenniesToComment, this.props.bsvPriceUSD).toFixed(0)} sats)
                                        <br></br>
                                        <button onClick={this.adjustFeeThreshold} style={{backgroundColor: this.bshzLtPurp}}>Adjust this setting </button>
                                    </div>
                                    </Segment>
                                </div>

                            </div>

                        </Modal.Content>
                        <Modal.Actions className={modalBottomClassName} style={{backgroundColor: this.bshzPurple, borderRadius: '0px 0px 20px 20px'}}>
                            <div style={{textAlign: 'center'}}>
                                <Button positive onClick={this.handleModalOk} content='OK'/>
                            </div>
                        </Modal.Actions>
                    </Modal>


                    <Modal size='small' centered className={modalClassName}  open={this.state.showFeeThresholdAdjustmentModal} style={{backgroundColor: this.bshzPurple, borderRadius: '20px'}}>
                        <Modal.Header style={{textAlign: 'center', backgroundColor: this.bshzPurple, borderRadius: '20px'}}>
                            <span style={{color: this.bshzYellow}}> Adjust the Fee Limit </span>
                        </Modal.Header>
                        <Modal.Content className={modalContentClassName} scrolling style={{backgroundColor: this.bshzLtPurp}}>

                            Let's adjust the <b>Fee Limit</b> - the most you're willing to pay to comment on the sites of others...
                            <br></br>
                            <br></br>
                            <div style={{color: "blue"}}> &nbsp; &nbsp; How many milli-pennies (maximum) would <b>you pay</b> to post on someone else's site?
                                <br></br>
                                &nbsp; &nbsp; <Input type="text" value={this.state.tempMaxToComment}
                                        onChange={this.handleFeeLimitChange}
                                        style={{width:"125px"}}/> milli-pennies &nbsp; &nbsp;({limitPriceEquiv} US, or, {satsFromMilliPennies(this.state.tempMaxToComment, this.props.bsvPriceUSD).toFixed(0)} sats)
                            </div>
                            <br></br>
                            <Button positive disabled={this.state.invalidLimit} onClick={this.setNewFeeThreshold} content='Use this limit'/>

                        </Modal.Content>
                        <Modal.Actions className={modalBottomClassName} style={{backgroundColor: this.bshzPurple, borderRadius: '0px 0px 20px 20px'}}>
                            <div style={{textAlign: 'center'}}>
                                <Button negative onClick={this.closeFeeThresholdModal} content='Back'/>
                            </div>
                        </Modal.Actions>
                    </Modal>


                    <Modal size='small' centered className={modalClassName}  open={this.state.showGuestPostPriceAdjustmentModal} style={{backgroundColor: this.bshzPurple, borderRadius: '20px'}}>
                        <Modal.Header style={{textAlign: 'center', backgroundColor: this.bshzPurple, borderRadius: '20px'}}>
                            <span style={{color: this.bshzYellow}}> Adjust Your Commenting Price </span>
                        </Modal.Header>
                        <Modal.Content className={modalContentClassName} scrolling style={{backgroundColor: this.bshzLtPurp}}>

                            Let's adjust the <b>price you'll charge</b> for others to comment on your site/domain/identity: <span style={{color:"blue", fontSize:"1.3rem"}}>{this.props.siteName.toUpperCase()}</span>
                            <br></br>
                            <br></br>
                            <div style={{padding: "10px"}}>
                                <span style={{color:"blue"}}>Will you allow strangers to post/comment on your site/domain/identity?</span>
                                <br></br>
                                &nbsp; <span style={{color: "black"}}>No &nbsp; <Radio toggle checked={this.state.allowGuestPosts}
                                      field='allowGuestPosts' onChange={this.handlePermissionChange}/> &nbsp; Allow visitors to post</span>
                            </div>
                            <br></br>
                            <br></br>
                            {gpAdjustor}
                            <br></br>
                            <Button positive disabled={this.state.invalidPrice} onClick={this.setNewGPPrice} content='Use this price/setting'/>

                        </Modal.Content>
                        <Modal.Actions className={modalBottomClassName} style={{backgroundColor: this.bshzPurple, borderRadius: '0px 0px 20px 20px'}}>
                            <div style={{textAlign: 'center'}}>
                                <Button negative onClick={this.closeGuestPostPriceAdjustorModal} content='Back'/>
                            </div>
                        </Modal.Actions>
                    </Modal>
                </>
        )
    }
}

export default FeeSettingsModal;
