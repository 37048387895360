"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var getOption_1 = require("./getOption");
function getEyeType(options, random) {
    var eyeType = [];
    if (getOption_1.getOption('eyes', 'close', options)) {
        eyeType.push('Close');
    }
    if (getOption_1.getOption('eyes', 'cry', options)) {
        eyeType.push('Cry');
    }
    if (getOption_1.getOption('eyes', 'default', options)) {
        eyeType.push('Default');
    }
    if (getOption_1.getOption('eyes', 'dizzy', options)) {
        eyeType.push('Dizzy');
    }
    if (getOption_1.getOption('eyes', 'roll', options)) {
        eyeType.push('EyeRoll');
    }
    if (getOption_1.getOption('eyes', 'happy', options)) {
        eyeType.push('Happy');
    }
    if (getOption_1.getOption('eyes', 'hearts', options)) {
        eyeType.push('Hearts');
    }
    if (getOption_1.getOption('eyes', 'side', options)) {
        eyeType.push('Side');
    }
    if (getOption_1.getOption('eyes', 'squint', options)) {
        eyeType.push('Squint');
    }
    if (getOption_1.getOption('eyes', 'surprised', options)) {
        eyeType.push('Surprised');
    }
    if (getOption_1.getOption('eyes', 'wink', options)) {
        eyeType.push('Wink');
    }
    if (getOption_1.getOption('eyes', 'winkWacky', options)) {
        eyeType.push('WinkWacky');
    }
    return random.pickone(eyeType);
}
exports.getEyeType = getEyeType;
